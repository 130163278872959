import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
const extraMsg = 'Please try again. If the issue persists please contact tech support.';

const initialState = {
	data: [],
	loading_data: false,
	success_data: null,
	error_data: null,
    error: null,
    success: null,
    error_delete: null,
    success_delete: null,
    entryData: null,
    error_checking_property: null,
    success_checking_property: null,
    checking_property: null,
    history: [],
    success_history: null,
    error_history: null,
    success_create_history: null,
    error_create_history: null,
    loading_history: false,
    all_potential_reviewers: [],
    
    error_pdf: null,
    success_pdf: null,
    loading_pdf: true,
    pdf_buffer: null,

    error_approvers: null,
    success_approvers: null,
    final_approvers: null,
    signing_url: null
};

// GET_COMMISSION_SPLITS

const getCommissionSplitsStart = (state, action) => {
    return updateObject(state, {
        error_data: null,
        success_data: null,
        loading_data: true,
        data: []
    })
}

const getCommissionSplitsSuccess = (state, action) => {
    return updateObject(state, {
        success_data: 'Success',
        loading_data: false,
        data: action.response
    })
}

const getCommissionSplitsFail = (state, action) => {
    return updateObject(state, {
        error_data: action.err,
        loading_data: false,
    })
}

// CREATE_COMMISSION_SPLIT

const createCommissionSplitStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        entryData: null
    })
}

const createCommissionSplitSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        entryData: action.response
    })
}

const createCommissionSplitFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_COMMISSION_SPLITS

const deleteCommissionSplitStart = (state, action) => {
    return updateObject(state, {
        error_delete: null,
        success_delete: null
    })
}

const deleteCommissionSplitSuccess = (state, action) => {
    return updateObject(state, {
        success_delete: 'Success'
    })
}

const deleteCommissionSplitFail = (state, action) => {
    return updateObject(state, {
        error_delete: action.err
    })
}

// UPDATE_COMMISSION_SPLIT

const updateCommissionSplitStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateCommissionSplitSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const updateCommissionSplitFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE

const getCommissionSplitsPropertyExistenceStart = (state, action) => {
    return updateObject(state, {
        error_checking_property: null,
        success_checking_property: null
    })
}

const getCommissionSplitsPropertyExistenceSuccess = (state, action) => {
    return updateObject(state, {
        success_checking_property: 'Success',
        checking_property: action.response
    })
}

const getCommissionSplitsPropertyExistenceFail = (state, action) => {
    return updateObject(state, {
        error_checking_property: action.err
    })
}

// CREATE_COMMISSION_SPLITS_HISTORY

const createCommissionSplitsHistoryStart = (state, action) => {
    return updateObject(state, {
        error_create_history: null,
        success_create_history: null
    })
}

const createCommissionSplitsHistorySuccess = (state, action) => {
    return updateObject(state, {
        success_create_history: 'Success'
    })
}

const createCommissionSplitsHistoryFail = (state, action) => {
    return updateObject(state, {
        error_create_history: action.err
    })
}

// GET_COMMISSION_SPLITS_HISTORY

const getCommissionSplitsHistoryStart = (state, action) => {
    return updateObject(state, {
        error_history: null,
        success_history: null,
        loading_history: true
    })
}

const getCommissionSplitsHistorySuccess = (state, action) => {
    return updateObject(state, {
        success_history: 'Success',
        history: action.response,
        loading_history: false
    })
}

const getCommissionSplitsHistoryFail = (state, action) => {
    return updateObject(state, {
        error_history: action.err,
        loading_history: false
    })
}

// GET_COMMISSION_SPLITS_REVIEWERS

const getCommissionSplitsReviewersStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getCommissionSplitsReviewersSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        all_potential_reviewers: action.response
    })
}

const getCommissionSplitsReviewersFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_COMMISSION_SPLITS_PDF

const createCommissionSplitsPdfStart = (state, action) => {
    return updateObject(state, {
        error_pdf: null,
        success_pdf: null,
        loading_pdf: true,
        pdf_buffer: null
    })
}

const createCommissionSplitsPdfSuccess = (state, action) => {
    return updateObject(state, {
        success_pdf: 'Successfully loaded PDF',
        loading_pdf: false,
        pdf_buffer: action.response
    })
}

const createCommissionSplitsPdfFail = (state, action) => {
    return updateObject(state, {
        error_pdf: action.err,
        loading_pdf: false
    })
}

// GET_COMMISSION_SPLITS_APPROVERS

const getCommissionSplitsApproversStart = (state, action) => {
    return updateObject(state, {
        error_approvers: null,
        success_approvers: null,
        final_approvers: null
    })
}

const getCommissionSplitsApproversSuccess = (state, action) => {
    return updateObject(state, {
        success_approvers: 'Success',
        final_approvers: action.response
    })
}

const getCommissionSplitsApproversFail = (state, action) => {
    return updateObject(state, {
        error_approvers: action.err
    })
}

const setCommissionSplitsPropertyExistence = (state, action) => {
    return updateObject(state, {
        checking_property: null
    })
}

// GET_CS_SIGNING_URL

const getCsSigningUrlStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        signing_url: null
    })
}

const getCsSigningUrlSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        signing_url: action.response ? action.response.url : "",
    })
}

const getCsSigningUrlFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_COMMISSION_SPLIT

const getCommissionSplitStart = (state, action) => {
    return updateObject(state, {
        entryData_error: null,
        entryData_success: null,
        entryData: null
    })
}

const getCommissionSplitSuccess = (state, action) => {
    return updateObject(state, {
        entryData_success: 'Success',
        entryData: action.response
    })
}

const getCommissionSplitFail = (state, action) => {
    return updateObject(state, {
        entryData_error: action.err
    })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		
        case actionTypes.GET_COMMISSION_SPLITS_START:
            return getCommissionSplitsStart(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_SUCCESS:
            return getCommissionSplitsSuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_FAIL:
            return getCommissionSplitsFail(state, action);

        case actionTypes.CREATE_COMMISSION_SPLIT_START:
            return createCommissionSplitStart(state, action);
        case actionTypes.CREATE_COMMISSION_SPLIT_SUCCESS:
            return createCommissionSplitSuccess(state, action);
        case actionTypes.CREATE_COMMISSION_SPLIT_FAIL:
            return createCommissionSplitFail(state, action);

        case actionTypes.DELETE_COMMISSION_SPLIT_START:
            return deleteCommissionSplitStart(state, action);
        case actionTypes.DELETE_COMMISSION_SPLIT_SUCCESS:
            return deleteCommissionSplitSuccess(state, action);
        case actionTypes.DELETE_COMMISSION_SPLIT_FAIL:
            return deleteCommissionSplitFail(state, action);

        case actionTypes.UPDATE_COMMISSION_SPLIT_START:
            return updateCommissionSplitStart(state, action);
        case actionTypes.UPDATE_COMMISSION_SPLIT_SUCCESS:
            return updateCommissionSplitSuccess(state, action);
        case actionTypes.UPDATE_COMMISSION_SPLIT_FAIL:
            return updateCommissionSplitFail(state, action);

        case actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_START:
            return getCommissionSplitsPropertyExistenceStart(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_SUCCESS:
            return getCommissionSplitsPropertyExistenceSuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_PROPERTY_EXISTENCE_FAIL:
            return getCommissionSplitsPropertyExistenceFail(state, action);
        case actionTypes.SET_COMMISSION_SPLITS_PROPERTY_EXISTENCE:
            return setCommissionSplitsPropertyExistence(state, action);

        case actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_START:
            return createCommissionSplitsHistoryStart(state, action);
        case actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_SUCCESS:
            return createCommissionSplitsHistorySuccess(state, action);
        case actionTypes.CREATE_COMMISSION_SPLITS_HISTORY_FAIL:
            return createCommissionSplitsHistoryFail(state, action);

        case actionTypes.GET_COMMISSION_SPLITS_HISTORY_START:
            return getCommissionSplitsHistoryStart(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_HISTORY_SUCCESS:
            return getCommissionSplitsHistorySuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_HISTORY_FAIL:
            return getCommissionSplitsHistoryFail(state, action);

        case actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_START:
            return getCommissionSplitsReviewersStart(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_SUCCESS:
            return getCommissionSplitsReviewersSuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_REVIEWERS_FAIL:
            return getCommissionSplitsReviewersFail(state, action);

        case actionTypes.CREATE_COMMISSION_SPLITS_PDF_START:
            return createCommissionSplitsPdfStart(state, action);
        case actionTypes.CREATE_COMMISSION_SPLITS_PDF_SUCCESS:
            return createCommissionSplitsPdfSuccess(state, action);
        case actionTypes.CREATE_COMMISSION_SPLITS_PDF_FAIL:
            return createCommissionSplitsPdfFail(state, action);

        case actionTypes.GET_COMMISSION_SPLITS_APPROVERS_START:
            return getCommissionSplitsApproversStart(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_APPROVERS_SUCCESS:
            return getCommissionSplitsApproversSuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLITS_APPROVERS_FAIL:
            return getCommissionSplitsApproversFail(state, action);

        case actionTypes.GET_CS_SIGNING_URL_START:
            return getCsSigningUrlStart(state, action);
        case actionTypes.GET_CS_SIGNING_URL_SUCCESS:
            return getCsSigningUrlSuccess(state, action);
        case actionTypes.GET_CS_SIGNING_URL_FAIL:
            return getCsSigningUrlFail(state, action);

        case actionTypes.GET_COMMISSION_SPLIT_START:
            return getCommissionSplitStart(state, action);
        case actionTypes.GET_COMMISSION_SPLIT_SUCCESS:
            return getCommissionSplitSuccess(state, action);
        case actionTypes.GET_COMMISSION_SPLIT_FAIL:
            return getCommissionSplitFail(state, action);

		default:
			return state;
	}
};

export default reducer;