import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import ListingList from "./ListingList";
import ListingForm from "./ListingForm";

import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

const Listing = (props) => {
    const [tab, setTab] = useState('List');
    const [listingData, setListingData] = useState(null);
    const [msgsForAdd, setMsgsForAdd] = useState({error:null,success:null});
    const [openedAsk, setOpenedAsk] = useState(false);

    const { listingsData, dataObj, getListings, canEdit, canDelete, contactsData, getContacts,
        createListing, updateListing, deleteListing, error, success,
        propertyAction, setListingsForAdd, listingsForAdd } = props;

    const addForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newData = { ...data, id:listingsForAdd.length };
        const newList = [ ...listingsForAdd, newData ];
        
        setListingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Added Lease'});
    }
    const updateForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...listingsForAdd ].map((l,i) => {
            if (i === data.id) return data;
            return l;
        })
        setListingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Updated Lease'});
    }
    const deleteForAdd = (index) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...listingsForAdd ].filter((f,i) => {
            if (i === index) return false;
            return true;
        })
        setListingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Deleted Lease'});
    }

    useEffect(() => {
        if (dataObj) {
            if (dataObj.id) {
                getListings(dataObj.id);
            }
        }
    }, [])

    const showForm = (tab === 'View' || tab === 'Add' || tab === 'Edit');

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {props.propertyAction !== 'View' && <Button
                    color={tab === 'Add' ? 'success' : 'primary'}
                    onClick={() => {
                        setTab('Add');
                    }}
                >
                    <i className="fa fa-plus"></i> Add Listing
                </Button>}
                <hr />
                {tab === 'List' && <ListingList
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    listingsData={propertyAction !== 'Add' ? listingsData : listingsForAdd}
                    listingData={listingData}
                    setListingData={setListingData}
                    setTab={setTab}
                />}
                {showForm &&
            <Dialog
                open={showForm ? true : false}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
          <div className="dialog-wrapper">
                <ListingForm
                    listingData={listingData}
                    action={tab}
                    setTab={setTab}
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    canDelete={propertyAction !== 'Add' ? canDelete : true}
                    getContacts={getContacts}
                    contactsData={contactsData}
                    setMsgsForAdd={setMsgsForAdd}

                    updateListing={propertyAction !== 'Add' ? updateListing : updateForAdd}
                    createListing={propertyAction !== 'Add' ? createListing : addForAdd}
                    deleteListing={propertyAction !== 'Add' ? deleteListing : deleteForAdd}
                    getListings={() => {
                        if (propertyAction !== 'Add') {
                            getListings(dataObj.id);
                        }
                    }}
                    propertyID={dataObj.id}
                    error={propertyAction !== 'Add' ? error : msgsForAdd.error}
                    success={propertyAction !== 'Add' ? success : msgsForAdd.success}
                    contactLoading={props.contactLoading}

                    setOpenedAsk={setOpenedAsk}
                    showCloseBtn={true}

                    propertyAction={propertyAction}
                />
            </div>
        </Dialog>
                }
            </GridItem>
            {openedAsk && <CustomDialogClose
                        ok_go={() => {
                            setTab('List');
                        }}
                        open={openedAsk}
                        close={() => {
                            setOpenedAsk(false);
                        }}
                    />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        error: p.error,
        success: p.success,
        listingsData: p.listingsData,
        listingData: p.listingData,
        loading: p.loading,
        contactsData: c.contactsData,
        contactLoading: c.loading,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getListings: (property_id) => {
          dispatch(actions.getListings(property_id));
      },
      getListing: (id) => {
          dispatch(actions.getListing(id));
      },
      createListing: (data) => {
          dispatch(actions.createListing(data));
      },
      updateListing: (data) => {
          dispatch(actions.updateListing(data));
      },
      deleteListing: (id) => {
          dispatch(actions.deleteListing(id));
      },
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Listing);
