import React, { useState, useEffect } from 'react';
import ERTLTab1 from './ERTLTab1';
import ERTLTab2 from './ERTLTab2';
import ERTLTab3 from './ERTLTab3';
import ERTLTab4 from './ERTLTab4';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { clone } from 'shared/utility';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { ListItem, List, ListItemText, ListItemIcon } from '@material-ui/core';
import { checkSectionCompleteness } from './ERTLLogic';
import { data } from '../ERTLObj';
import Notification from '../../../components/Notification/Notification';

const ERTLMultiForm = (props) => {
	const {
		ertlFormData,
		templateEdited,
		setTemplateEdited,
		selectedTemplate,
		templateText,
		contactProperties,
	} = props;
	const [currForm, setCurrForm] = useState(1);
	const [propertyChosen, setPropertyChosen] = useState(null);
	const [contactsChosen, setContactsChosen] = useState([]);
	const [ownerData, setOwnerData] = useState([]);
	const [chosenOwners, setChosenOwners] = useState([]);
	const [notification, setNotification] = useState('');
	const { ownerNames, setOwnerNames } = props;

	useEffect(() => {
		if (props.saveDraft) {
			// save draft
			saveDraft();
			props.setSaveDraft(false);
		}
	}, [props.saveDraft]);

	const updateData = (field, value) => {
		const data = clone(props.ertlFormData);
		data[field] = value;
		props.setErtlFormData(data);
	};

	const disableForward = false;

	const sections = [
		{ name: 'Property & Owner Data' },
		{ name: 'Agent Contact Data' },
		{ name: 'Other Data' },
	];

	const saveDraft = () => {
		const newTemplate = templateEdited ? templateText : null;
		const ddata = clone(ertlFormData);
		ddata.templateData = templateText;
		if (props.ertlData) {
			if (props.ertlData.id) {
				const data = {
					data: ddata,
					id: props.ertlData.id,
					template_id: selectedTemplate !== -1 ? selectedTemplate : null,
				};
				props.updateEntry(data, newTemplate);
				setNotification('Updated draft');
				if (props.setTab) props.setTab('Drafts');
				if (props.setParentTab) props.setParentTab('Drafts');
				return;
			}
		}
		props.createEntry(
			{
				data: ddata,
				status: 0,
				template_id: selectedTemplate !== -1 ? selectedTemplate : null,
			},
			newTemplate
		);
	};

	return (
		<div>
			<div style={{ marginBottom: 10 }}>
				<Button
					style={{ marginRight: 20 }}
					onClick={() => {
						setCurrForm(currForm - 1);
					}}
					disabled={currForm === 1 ? true : false}
				>
					<ArrowBack /> Previous
				</Button>
				<Button
					onClick={() => {
						if (currForm < 3) return setCurrForm(currForm + 1);
						props.setMainTab('full');
					}}
					disabled={disableForward}
				>
					Next <ArrowForward />
				</Button>
			</div>
			<GridContainer>
				<GridItem xs={12} sm={9} md={9}>
					{currForm === 1 && (
						<ERTLTab1
							ertlFormData={props.ertlFormData}
							setErtlFormData={props.setErtlFormData}
							updateData={updateData}
							getPropertyNames={props.getPropertyNames}
							propertyNames={props.propertyNames}
							contactNames={props.contactNames}
							getContactNames={props.getContactNames}
							propertyChosen={propertyChosen}
							setPropertyChosen={setPropertyChosen}
							contactsChosen={contactsChosen}
							setContactChosen={setContactsChosen}
							ownerData={ownerData}
							setOwnerData={setOwnerData}
							chosenOwners={chosenOwners}
							setChosenOwners={setChosenOwners}
							propertyOwnersContacts={props.propertyOwnersContacts}
							getPropertyOwnersContacts={props.getPropertyOwnersContacts}
							addPropertyToContact={props.addPropertyToContact}
							prefill_property_id={props.prefill_property_id}
							reset_prefill_property_id={props.reset_prefill_property_id}
							saveDraft={saveDraft}
						/>
					)}

					{currForm === 2 && (
						<ERTLTab3
							ertlFormData={props.ertlFormData}
							setErtlFormData={props.setErtlFormData}
							updateData={updateData}
							getPropertyNames={props.getPropertyNames}
							propertyNames={props.propertyNames}
							contactNames={props.contactNames}
							getContactNames={props.getContactNames}
							getContacts={props.getContacts}
							contactsData={props.contactsData}
							contactData={props.contactData}
							getContact={props.getContact}
							propertyChosen={propertyChosen}
							setPropertyChosen={setPropertyChosen}
							contactsChosen={contactsChosen}
							setContactChosen={setContactsChosen}
						/>
					)}

					{currForm === 3 && (
						<ERTLTab4
							ertlFormData={props.ertlFormData}
							setErtlFormData={props.setErtlFormData}
							updateData={updateData}
							approvers={props.approvers}
						/>
					)}
				</GridItem>
				<GridItem xs={12} sm={3} md={3}>
					{/* Tracker */}
					<List component='nav'>
						{sections.map((s, i) => {
							const check = checkSectionCompleteness(
								props.ertlFormData,
								s.name
							);
							const sectionComplete = check.complete
								? 'fa-check'
								: 'fa-times';
							return (
								<ListItem
									key={`li-${i}`}
									button
									onClick={() => {
										setCurrForm(i + 1);
									}}
								>
									<ListItemIcon>
										{
											<i
												className={`fas ${sectionComplete} font-22 ${
													currForm - 1 === i ? 'bold red-text' : ''
												}`}
											></i>
										}
									</ListItemIcon>
									<ListItemText
										inset
										primary={
											<div
												className={currForm - 1 === i ? 'bold red-text' : ''}
											>
												{s.name}
											</div>
										}
									/>
								</ListItem>
							);
						})}
					</List>
				</GridItem>
			</GridContainer>

			<hr />
			<Button
				style={{ marginRight: 20 }}
				onClick={() => {
					setCurrForm(currForm - 1);
				}}
				disabled={currForm === 1 ? true : false}
			>
				<ArrowBack /> Previous
			</Button>
			<Button
				onClick={() => {
					if (currForm < 3) return setCurrForm(currForm + 1);
					props.setMainTab('full');
				}}
				disabled={disableForward}
			>
				Next <ArrowForward />
			</Button>
			<hr />
			{props.origin !== 'approver' && (
				<div className='bottom-dweller'>
					<Button
						color='white'
						onClick={() => {
							saveDraft();
						}}
						style={{ marginTop: 10, marginRight: 20 }}
					>
						<i className='fas fa-save'></i> SAVE DRAFT
					</Button>
				</div>
			)}

			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						setNotification('');
					}}
					message={notification}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	const p = state.crm_properties;
	const c = state.crm_contacts;
	const com = state.crm_companies;
	return {
		propertyNames: p.propertyNames,
		propertyContacts: p.propertyContacts,
		propertyData: p.propertyData,
		contactData: c.contactData,
		contactsData: c.contactsData,
		contactNames: c.contactNames,
		propertyOwnersContacts: p.propertyOwnersContacts,
		approvers: state.ertl_entries.approvers
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPropertyNames: () => {
			dispatch(actions.getPropertyNames());
		},
		getPropertyContacts: (contact_id) => {
			dispatch(actions.getPropertyContacts(contact_id));
		},
		getContactNames: (contacts) => {
			dispatch(actions.getContactNames(contacts));
		},
		getContacts: (params) => {
			dispatch(actions.getContacts(params));
		},
		getContact: (id) => {
			dispatch(actions.getContact(id));
		},
		createEntry: (data, newTemplate) => {
			dispatch(actions.createEntry(data, newTemplate));
		},
		getPropertyOwnersContacts: (id) => {
			dispatch(actions.getPropertyOwnersContacts(id));
		},
		addPropertyToContact: (data) => {
			dispatch(actions.addPropertyToContact(data));
		},
		updateEntry: (data, newTemplate) => {
			dispatch(actions.updateEntryStatus(data, newTemplate));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ERTLMultiForm);
