import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDialogWithProperty from 'components/CustomDialog/CustomDialogWithProperty';
import { clone } from "shared/utility";
import PMAElement from './PMAElement';
import { createAddressObject } from '../../CRM/Properties/PropertiesLogic';

const PMAMulti3 = (props) => {
    const { formData, setFormData, updateDataObj, propertyChosen, setPropertyChosen,
    getPropertyNames } = props;
    const [addProperty, setAddProperty] = useState(false);

    const chooseProperty = (propertyChosen) => {
        const p = propertyChosen;
        const e = clone(props.formData);
        const address = createAddressObject(propertyChosen);
        e.property.address = address.street1;
        e.property.address2 = address.street1;
        e.property.city = address.city;
        e.property.state = address.state;
        e.property.zip = address.zip;
        e.property.id = p.id;
        setFormData(e);
        if (propertyChosen.owner_id) {
            // get owners
            props.getContactNames(propertyChosen.owner_id);
        }
    }

    useEffect(() => {
        if (props.prefill_property_data) {
            chooseProperty(props.prefill_property_data);
            props.reset_prefill_data();
        }
    }, []);

    useEffect(() => {
        if (propertyChosen) {
            chooseProperty(propertyChosen);
        }
    }, [propertyChosen]);


    const renderElement = (field) => {
        return <PMAElement 
            updateDataObj={updateDataObj}
            data={formData}
            setData={setFormData}
            field={field}
        />
    }

    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setAddProperty(true);
                        }}
                    >SELECT OR CREATE PROPERTY</Button>
                    <hr />
                    <center><h3>Property</h3></center>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                    {formData.property ? 
                        formData.property.address ? 
                            <div>
                                {renderElement('property.address')}
                                {renderElement('property.address2')}
                                {renderElement('property.city')}
                                {renderElement('property.state')}
                                {renderElement('property.zip')}
                            </div>
                        :
                            <span><em>Click "Property" to add property</em></span>
                    : 
                        ''}
                </GridItem>
            </GridContainer>
            
            {addProperty && <CustomDialogWithProperty
                propertyType={addProperty}
                open={addProperty}
                close={() => {
                    setAddProperty(false);
                }}
                choose={(chosen) => {
                    // open property
                    
                    setPropertyChosen(chosen);
                    setAddProperty(false);
                }}
                create_and_choose={(chosen) => {
                    
                    setPropertyChosen(chosen);
                    setAddProperty(false);
                }}
            />}
        </div>
    );
}

export default PMAMulti3;