import { put } from 'redux-saga/effects';
import axios from '../axios/axios-docusign';
import * as actions from '../actions/docusign';

export function* createDocusignSaga(action) {
    yield put(actions.createDocusignStart());
    try {
        const response = yield axios.post('/create', action.data);
        if (response) {
            if (response) {
              
            }
          }
        yield put(actions.createDocusignSuccess());
    } catch (e) {
        
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createDocusignFail(e.response.data));
            }
          }
        yield put(actions.createDocusignFail(e));
    }
}