import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	eventsData: [],
	logHistory: [],
	loading: false,
	success: null,
	error: null,
	contactList: [],
};

// GET_DIALPAD_CONTACTS

const getDialpadContactsStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
	});
};

const getDialpadContactsSuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		// success: 'Success',
		contactList: action.response.contactList,
	});
};

const getDialpadContactsFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

// IMPORT_DIALPAD_CONTACTS

const importDialpadContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const importDialpadContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Contact Imported Successfully.',
	});
};

const importDialpadContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LOG_HISTORY

const getLogHistoryStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		error: null,
		success: null,
	});
};

const getLogHistorySuccess = (state, action) => {
	return updateObject(state, {
		loading: false,
		// success: 'Success',
		logHistory: action.response.logHistory,
	});
};

const getLogHistoryFail = (state, action) => {
	return updateObject(state, {
		loading: false,
		error: action.err,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_DIALPAD_CONTACTS_START:
			return getDialpadContactsStart(state, action);
		case actionTypes.GET_DIALPAD_CONTACTS_SUCCESS:
			return getDialpadContactsSuccess(state, action);
		case actionTypes.GET_DIALPAD_CONTACTS_FAIL:
			return getDialpadContactsFail(state, action);
		case actionTypes.IMPORT_DIALPAD_CONTACTS_START:
			return importDialpadContactsStart(state, action);
		case actionTypes.IMPORT_DIALPAD_CONTACTS_SUCCESS:
			return importDialpadContactsSuccess(state, action);
		case actionTypes.IMPORT_DIALPAD_CONTACTS_FAIL:
			return importDialpadContactsFail(state, action);
		case actionTypes.GET_LOG_HISTORY_START:
			return getLogHistoryStart(state, action);
		case actionTypes.GET_LOG_HISTORY_SUCCESS:
			return getLogHistorySuccess(state, action);
		case actionTypes.GET_LOG_HISTORY_FAIL:
			return getLogHistoryFail(state, action);
		default:
			return state;
	}
};

export default reducer;
