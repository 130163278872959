import React, { useEffect, useState } from 'react'
import Button from "components/CustomButtons/Button.jsx";
import OnboardingPdfModal from './OnboardingPdfModal';
import NoticeToEmployeeTemplate from './NoticeToEmployeeTemplate';
import pdfTemplate from './OnboardingObj';

const NoticeToEmployee = (props) => {

    const { pdfData, getObPdf, createNteEntry, updateOnboardingEntry, currentEntry, setTab, userData } = props;

    const [openedPdfModal, setOpenedPdfModal] = useState(false);
    const [localPdfData, setLocalPdfData] = useState(null);

    const [formData, setFormData] = useState({
        employee_name: userData.names.fullName || '',
        employer_representative: '',
        start_date: '',
        employer_legal_name: 'VII Commercial Corp.',
        employer_staffing_agency: 'No',
        employer_other_names: 'illi Commercial Real Estate',
        main_office_address: '17547 Ventura Blvd, #304 Encino, CA 91316',
        employers_mailing_address: '',
        employer_phone_number: '818.501.2212',
        other_entity: {
            name: '',
            main_office_address: '',
            mailing_address: '',
            phone_number: '',
        },
        wage_information: {
            rates_of_pay: '',
            overtime_pay: '',
            rate_by: 'empty',
            other_by: '',
            written_agreement: '',
            if_written: '',
            allowances: '',
            regular_payday: '',
        },
        workers_compensation: {
            insurance_carriers_name: '',
            address: '',
            phone_number: '',
            policy_number: '',
            self_insured: '',
            self_insured_cert_num: '',
        },
        paid_sick_leave: {
            check_box: 'empty',
            exemption: '',
        },
    });

    useEffect(() => {
        if (pdfData) {
            setLocalPdfData(pdfData)
        }
    }, [pdfData])

    const openPdfModal = () => {
        setOpenedPdfModal(true);
    }

    return (
        <div>
            <NoticeToEmployeeTemplate
                formData={formData}
                setFormData={setFormData}
            />
            <Button
                color="rose"
                onClick={() => {
                    createNteEntry({ data: JSON.stringify(formData), onboarding_id: currentEntry.id });
                    updateOnboardingEntry({data: {notice: 1}, id: currentEntry.id, status: 1, approver_id: currentEntry.approver_id})
                    let scroll =  document.getElementsByClassName('Dashboard-mainPanel-2');
                    if (scroll.length > 0) {
                        scroll[0].scrollIntoView();
                    }
                    setTab('Overview')
                }}
                style={{ marginTop: 10 }}
            >
                Submit
            </Button>
            {openedPdfModal === false ?
                <Button
                    color="rose"
                    onClick={() => {
                        let template = pdfTemplate(formData);
                        getObPdf({ data: template });
                        openPdfModal(true);
                    }}
                    style={{ marginTop: 10 }}
                >
                    Generate PDF
                </Button>
                : localPdfData && <OnboardingPdfModal
                    pdfData={localPdfData}
                    open={openedPdfModal}
                    close={() => {
                        setOpenedPdfModal(false);
                        setLocalPdfData(null);
                    }}
                />
            }
        </div>
    )

}

export default NoticeToEmployee;