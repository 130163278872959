import React, { useEffect, useState } from "react";
import { IconButton } from "@material-ui/core";
import { find, get, set } from "lodash";

import Button from "components/CustomButtons/Button.jsx";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import { clone } from "shared/utility";
import { required } from "shared/validation";
import { generateCommissionSplitData } from '../RSheetsLogic';
import { parseFloatString, theVal, twoDecimals } from "../../../shared/utility";
import { commissionCalc } from "./CommissionLogic";
import { getUser } from "../../../shared/authValidation";

const CommissionSplits = (props) => {
    const {
        data, // all data
        updateDataObj, // updates a field's value e.g. updateDataObj(field, value);
        getContact,
        setData,
        lesseeLabel = 'Lessee',
        lessorLabel = 'Lessor',
        hideLessorOutsideBroker,
    } = props;
    
    const [addAgent, setAddAgent] = useState(false);
    const [addAgent2, setAddAgent2] = useState(false);

    const hasLesseeAssociate = get(data, 'illi_represented_lessee', '') !== 'No'
    const hasLessorAssociate = get(data, 'illi_represented_lessor', '') !== 'No'

    useEffect(() => {
        generateCommissionSplitData(data, setData)
    }, [])

    const buildAgentTableData = (type) => {
        let dataField;
        let field;

        if (type === "Lessor") {
            dataField = data.commission_splits_agent;
            field = "commission_splits_agent";
        } else if (type === "Lessee") {
            dataField = data.commission_splits_agent2;
            field = "commission_splits_agent2";
        }
        let tableData = dataField.map((user, index) => {
            return [
                <div>{user.Name} {user?.dba_name ? `DBA ${user.dba_name} ` : ''}
                {user?.dba_active == 0 && <span style={{color: 'red',fontWeight: 'bold'}}>(removed)</span>}
                </div>,
                <CustomInput
                    required
                    validate={[required]}
                    value={user.Percentage}
                    type="number"
                    onChange={(e) => {
                        let newSplits = clone(dataField);
                        newSplits[index].Percentage = e.target.value;
                        updateDataObj(field, newSplits);
                    }}
                />,
                <IconButton
                    onClick={() => {
                        let newSplits = clone(dataField);
                        const idToRemove = get(newSplits, `[${index}].id`, '');
                        const newData = clone(data);

                        const contact_ids = get(newData, `chosen.${field}.contact`, []).filter(id => id !== idToRemove);
                        set(newData, `chosen.${field}.contact`, contact_ids);
                        newSplits.splice(index, 1);

                        newData[field] = newSplits;
                        setData(newData)
                    }}
                >
                    <i className="fas fa-trash-alt red-text"></i>
                </IconButton>,
            ];
        });

        const total =
            dataField
                .map((acc) => {
                    if (acc.Percentage === "" || isNaN(acc.Percentage)) {
                        acc.Percentage = "0";
                        return acc;
                    } else {
                        return acc;
                    }
                })
                .reduce((acc, curr) => {
                    return acc + parseInt(curr.Percentage);
                }, 0) + "%";

        const totalText = () => (total === "100%" ? total : <span className="red-text">{total}</span>);

        tableData.push(["Total", totalText(), ""]);

        return tableData;
    };

    // LESSOR TABLE
    let lessorTableData = [
        [
            `${lessorLabel} Referral ${
                data.referral_firm_name || data.referral_firm_lastname
                    ? `- ${data.referral_firm_name} ${data.referral_firm_lastname}`
                    : ""
            }
            ${data.referral_firm_contact_dba_name ? `DBA ${data.referral_firm_contact_dba_name }` : ''}
            ${data.referral_firm_contact_dba_active === 0 ? ' (removed)' : ''}
            `,
            data.referral_firm_fee_amount_choice === "Value"
                ? data.referral_firm_fee_amount
                : get(data, 'referral_firm_fee_amount_percentage', '') + " %",
        ]
    ];
    if (!hideLessorOutsideBroker) {
        data.lessor_outside_broker.forEach(ob => {
            lessorTableData.push([
                `Lessor Outside Broker - ${ob.company_name ? theVal(ob.company_name) : theVal(ob.first_name) + ' ' + theVal(ob.last_name)}`,
                 theVal(ob.commission) + " %",
            ])
        })
    }

    // LESSEE TABLE
    let lesseeTableData = [
        [
            `${lesseeLabel} Referral ${
                data.lessee_referral_firm_name || data.lessee_referral_firm_lastname
                    ? `- ${data.lessee_referral_firm_name} ${data.lessee_referral_firm_lastname}`
                    : ""
            }  
            ${data.lessee_referral_firm_contact_dba_name ? `DBA ${data.lessee_referral_firm_contact_dba_name }` : ''}
            ${data.lessee_referral_firm_contact_dba_active === 0 ? ' (removed)' : ''}
            `,
            data.lessee_referral_firm_fee_amount_choice === "Value"
                ? data.lessee_referral_firm_fee_amount
                : get(data, 'lessee_referral_firm_fee_amount_percentage', '') + " %",
        ]
    ];

    lesseeTableData = lesseeTableData.concat(data.outside_broker.map(ob => {
        return [
            `Outside Broker - ${ob.company_name ? theVal(ob.company_name) : theVal(ob.first_name) + ' ' + theVal(ob.last_name)}`,
             theVal(ob.commission) + " %",
        ];
    }));

    console.log('lesseeTableData', lesseeTableData);

    const all_commission_calculations = commissionCalc.calculate(data);
    console.log('all_commission_calculations', all_commission_calculations);
    

    return (
        <div>
            <GridContainer>
                {/* LESSEE */}
                <GridItem xs={12} sm={12} md={12}>
                    <div className="boxed">
                            <GridItem xs={12} sm={12} md={12}>
                                <h3 style={{textTransform: 'uppercase'}}>{lesseeLabel}</h3>
                            </GridItem>
                        {hasLesseeAssociate && <>
                            <GridItem xs={12} sm={12} md={12}>
                                {data.commission_splits_agent2 && data.commission_splits_agent2.length > 0 && (
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Name", "Percentage", "Delete"]}
                                        tableData={buildAgentTableData("Lessee")}
                                    />
                                )}
                            </GridItem>
                            <GridItem container justify="flex-end" xs={12} sm={12} md={12}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setAddAgent2(true);
                                    }}
                                >
                                    <i className="fa fa-plus"></i> ADD ASSOCIATE
                                </Button>
                            </GridItem>
                        </>}
                        <GridItem xs={12} sm={8} md={8}>
                            <hr />
                            <h3>OUTSIDE BROKERS</h3>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Percentage", "Value"]}
                                tableData={all_commission_calculations.lessee_outside_brokers.map(lob => {
                                    return [
                                        lob.name,
                                        lob.percentage !== 'N/A' ? twoDecimals(lob.percentage * 100) + ' %' : 'N/A',
                                        '$' + twoDecimals(lob.value)
                                    ];
                                })}
                            />
                            <hr />
                            <h3>REFERRALS</h3>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Percentage", "Value"]}
                                tableData={all_commission_calculations.lessee_referrals.map(lr => {
                                    return [
                                        lr.name,
                                        lr.percentage !== 'N/A' ? twoDecimals(lr.percentage * 100) + ' %' : 'N/A',
                                        '$' + twoDecimals(lr.value)
                                    ];
                                })}
                            />
                        </GridItem>
                    </div>
                </GridItem>

                {/* LESSOR */}
                <GridItem xs={12} sm={12} md={12}>
                    <div className="boxed">
                            <GridItem xs={12} sm={12} md={12}>
                                <h3 style={{textTransform: 'uppercase'}}>{lessorLabel}</h3>
                            </GridItem>
                        {hasLessorAssociate && <>
                            <GridItem xs={12} sm={12} md={12}>
                                {data.commission_splits_agent && data.commission_splits_agent.length > 0 && (
                                    <Table
                                        tableHeaderColor="primary"
                                        tableHead={["Name", "Percentage", "Delete"]}
                                        tableData={buildAgentTableData("Lessor")}
                                    />
                                )}
                            </GridItem>
                            <GridItem container justify="flex-end" xs={12} sm={12} md={12}>
                                <Button
                                    color="primary"
                                    onClick={() => {
                                        setAddAgent(true);
                                    }}
                                >
                                    <i className="fa fa-plus"></i> ADD ASSOCIATE
                                </Button>
                            </GridItem>
                        </>}
                        <GridItem xs={12} sm={8} md={8}>
                            <hr />
                            <h3>OUTSIDE BROKERS</h3>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Percentage", "Value"]}
                                tableData={all_commission_calculations.lessor_outside_brokers.map(lob => {
                                    return [
                                        lob.name,
                                        lob.percentage !== 'N/A' ? twoDecimals(lob.percentage * 100) + ' %' : 'N/A',
                                        '$' + twoDecimals(lob.value)
                                    ];
                                })}
                            />
                            <hr />
                            <h3>REFERRALS</h3>
                            <Table
                                tableHeaderColor="primary"
                                tableHead={["Name", "Value"]}
                                tableData={all_commission_calculations.lessor_referrals.map(lr => {
                                    return [
                                        lr.name,
                                        lr.percentage !== 'N/A' ? twoDecimals(lr.percentage * 100) + ' %' : 'N/A',
                                        '$' + twoDecimals(lr.value)
                                    ];
                                })}
                            />
                        </GridItem>
                    </div>
                </GridItem>

                {addAgent && (
                    <CustomDialogChooser
                        showContactCompaniesTab
                        singleContactOnly
                        no_property
                        open={addAgent}
                        close={() => {
                            setAddAgent(false);
                        }}
                        choose={(the_contact_data, filler, dba) => {
                            if (the_contact_data?.signers && the_contact_data?.signers.length > 0) {
                                the_contact_data = the_contact_data.signers[0];
                            }
                            getContact(the_contact_data.id);
                            let newSplits = clone(data.commission_splits_agent);
                            newSplits.push({
                                id: the_contact_data.id,
                                Name: `${the_contact_data.first_name} ${the_contact_data.last_name}`,
                                Percentage: "0",
                                dba_name: dba?.name,
                                dba_id: dba?.id,
                                dba_type: 'contact',
                            });

                            const newData = clone(data);
                            newData.commission_splits_agent = newSplits

                            const contact_ids = get(newData, `chosen.commission_splits_agent.contact`, [])
                            contact_ids.push(the_contact_data.id);
                            set(newData, `chosen.commission_splits_agent.contact`, contact_ids)
                            setData(newData)

                            // updateDataObj("commission_splits_agent", newSplits);
                            setAddAgent(false);
                        }}
                        create_and_choose={(success_data) => {
                            getContact(success_data.id);
                            let newSplits = clone(data.commission_splits_agent);
                            newSplits.push({
                                id: success_data.id,
                                Name: success_data.name,
                                Percentage: "0",
                            });

                            const newData = clone(data);
                            newData.commission_splits_agent = newSplits;

                            const contact_ids = get(newData, `chosen.commission_splits_agent.contact`, []);
                            contact_ids.push(success_data.id);
                            set(newData, `chosen.commission_splits_agent.contact`, contact_ids);
                            setData(newData);
                            // updateDataObj("commission_splits_agent", newSplits);
                            setAddAgent(false);
                        }}
                        peopleType="agents"
                        title="Select Contact"
                        label="Search Contacts"
                    />
                )}
                {addAgent2 && (
                    <CustomDialogChooser
                        showContactCompaniesTab
                        singleContactOnly
                        no_property
                        open={addAgent2}
                        close={() => {
                            setAddAgent2(false);
                        }}
                        choose={(the_contact_data, filler, dba) => {
                            if (the_contact_data?.signers && the_contact_data?.signers.length > 0) {
                                the_contact_data = the_contact_data.signers[0];
                            }
                            getContact(the_contact_data.id);
                            let newSplits = clone(data.commission_splits_agent2);
                            newSplits.push({
                                id: the_contact_data.id,
                                Name: `${the_contact_data.first_name} ${the_contact_data.last_name}`,
                                Percentage: "0",
                                dba_name: dba?.name,
                                dba_id: dba?.id,
                                dba_type: 'contact',
                            });

                            const newData = clone(data);
                            newData.commission_splits_agent2 = newSplits;

                            const contact_ids = get(newData, `chosen.commission_splits_agent2.contact`, []);
                            contact_ids.push(the_contact_data.id);
                            set(newData, `chosen.commission_splits_agent2.contact`, contact_ids);
                            setData(newData)

                            // updateDataObj("commission_splits_agent2", newSplits);
                            setAddAgent2(false);
                        }}
                        create_and_choose={(success_data) => {
                            getContact(success_data.id);
                            let newSplits = clone(data.commission_splits_agent2);
                            newSplits.push({
                                id: success_data.id,
                                Name: success_data.name,
                                Percentage: "0",
                            });

                            const newData = clone(data);
                            newData.commission_splits_agent2 = newSplits;

                            const contact_ids = get(newData, `chosen.commission_splits_agent2.contact`, []);
                            contact_ids.push(success_data.id);
                            set(newData, `chosen.commission_splits_agent2.contact`, contact_ids);
                            setData(newData)

                            // updateDataObj("commission_splits_agent2", newSplits);
                            setAddAgent2(false);
                        }}
                        peopleType="agents"
                        title="Select Contact"
                        label="Search Contacts"
                    />
                )}
            </GridContainer>
        </div>
    );
};

export default CommissionSplits;

export const CommissionSplitsAssociateFull = (props) => {
    let showValue = false;
    const usr = getUser();
    if (usr.permObj.hasOwnProperty('Routing view_associate_splits')) {
        showValue = true;
    }

    if (!showValue) return null;
    const acc = props.all_commission_calculations;
    let lessee_tot = 0;
    let lessee_perc_tot = 0;
    let lessor_tot = 0;
    let lessor_perc_tot = 0;
        
    return <div className="boxed mt-20">
        <div style={{marginTop:20}}>
            <h4 style={{ textDecoration: "underline" }}>ASSOCIATE COMMISSION SPLITS LESSEE</h4>
        </div>
        <div className="the-table" style={{marginTop:20}}>
            <table>
                <tbody>
                    <tr>
                        <td className="col-30">
                            <strong>Name</strong>
                        </td>
                        <td className="col-30">
                            <strong>Percentage</strong>
                        </td>
                        <td className="col-40">
                            <strong>Value</strong>
                        </td>
                    </tr>
                    {acc.associate_commission_splits_lessee.map((c, i) => {
                        const whole_perc = c.perc * 100;
                        lessee_tot += c.value;
                        lessee_perc_tot += whole_perc;
                        return (
                            <tr key={`${i}-agent`}>
                                <td className="col-30">{c.name}</td>
                                <td className="col-30">{twoDecimals(parseFloatString(c.perc * 100))} %</td>
                                <td className="col-40">${twoDecimals(c.value)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="col-30">
                            <strong>Total</strong>
                        </td>
                        <td className="col-30">{twoDecimals(lessee_perc_tot)} %</td>
                        <td className="col-40">${twoDecimals(lessee_tot)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div style={{marginTop:20}}>
            <h4 style={{ textDecoration: "underline" }}>ASSOCIATE COMMISSION SPLITS LESSOR </h4>
        </div>
        <div className="the-table" style={{marginTop:20}}>
            <table>
                <tbody>
                    <tr>
                        <td className="col-30">
                            <strong>Name</strong>
                        </td>
                        <td className="col-30">
                            <strong>Percentage</strong>
                        </td>
                        <td className="col-40">
                            <strong>Value</strong>
                        </td>
                    </tr>
                    {acc.associate_commission_splits_lessor.map((c, i) => {
                        const whole_perc = c.perc * 100;
                        lessor_tot += c.value;
                        lessor_perc_tot += whole_perc;
                        return (
                            <tr key={`${i}-agent`}>
                                <td className="col-30">{c.name}</td>
                                <td className="col-30">{twoDecimals(parseFloatString(c.perc * 100))} %</td>
                                <td className="col-40">${twoDecimals(c.value)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="col-30">
                            <strong>Total</strong>
                        </td>
                        <td className="col-30">{twoDecimals(lessor_perc_tot)} %</td>
                        <td className="col-40">${twoDecimals(lessor_tot)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}
