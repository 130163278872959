import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { clone } from 'shared/utility';
const extraMsg = 'Please try again. If the issue persists please contact tech support.';

const initialState = {
    // Regular Query
    contactsData: [],
    contactData: null,
    contactData2: null, // to be able to add contacts within the contact object, while contactData is open
    agentData: [],
    agentDataCount: null,
    emailContacts: [],

    insertId: null,
    
    // Names Only
    illi_contacts: [],
    own_contacts: [],
    own_shared_contacts: [],

    addressData: null,
    partnersData: null,
    contactsCount: null,
    error: null,
    success: null,
    success_data: null,
    loading: false,
    contactOptions: null,
    contactShares: [],
    contactUsernames: [],
    contactNames: [],
    fileList: [],
    downloadedFile: [],
    properties: [],
    contact_properties: [],
    new_property: null,

    contactChangesData: null,
    contactChangesSuccess: null,
    contactChangesError: null,
    contactChangesLoading: false,

    // {
    //     contact_id: [ entities ]
    // }
    contact_entities: {},
	updated_contact_entity: {},

    contactDbas: [],

    error_crm_data: null,
    success_crm_data: null,
    original_crm_data: null,
    loading_crm_data: null
}

const clearContacts = (state, action) => {
    return updateObject(state, clone(initialState));
}

// GET_CONTACT_NAMES

const getContactNamesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getContactNamesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got Contact Names',
        contactNames: action.response
    })
}

const getContactNamesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}


// GET ALL CONTACTS

const getContactsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        contactsData: [],
        illi_contacts: [],
        own_contacts: [],
        own_shared_contacts: [],
        contactsCount: null,
        error: null
    });
}

const getContactsSuccess = (state, action) => {
    if (action.response.illi_contacts) {
        return updateObject(state, {
            loading: false,
            illi_contacts: action.response.illi_contacts,
            own_contacts: action.response.own_contacts,
            own_shared_contacts: action.response.own_shared_contacts
        });
    }
    return updateObject(state, {
        loading: false,
        contactsData: action.response.contactsData,
        contactsCount: action.response.count,
        agentData: action.response.agentData,
        agentDataCount: action.response.agentDataCount
    });
}

const getContactsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: 'Failed to get contacts. ' + extraMsg
    });
}

// GET CONTACT

const getContactStart = (state, action) => {
    return updateObject(state, {
        insertId: null,
        loading: true,
        error: null,
        success: null,
        contactData: null
    });
}

const getContactSuccess = (state, action) => {
    if (action.isEmailContacts) {
        return updateObject(state, {
            loading: false,
            emailContacts: action.response
        });
    }
    return updateObject(state, {
        loading: false,
        contactData: action.response,
    });
}

const getContactFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    });
}

// CREATE CONTACT

const createContactStart = (state, action) => {
    return updateObject(state, {
        insertId: null,
        error: null,
        success: null,
        success_data: null
    });
}

const createContactSuccess = (state, action) => {    
    const illi_number = action.illi_number ?
        `# ${action.illi_number}.`
        :
        '';
    return updateObject(state, {
        insertId: action.illi_number,
        success: `Successfully created contact. ${illi_number}`,
        success_data: action.response
    });
}

const createContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    });
}

// DELETE CONTACT

const deleteContactStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    });
}

const deleteContactSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted contact. '
    });
}

const deleteContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    });
}

// GET ADDRESSES

const getAddressesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getAddressesSuccess = (state, action) => {
    return updateObject(state, {
        // success: 'Loaded Address',
        addressData: action.response
    })
}

const getAddressesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        addressData: null
    })
}

// UPDATE CONTACT

const updateContactStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        success_data: null
    })
}

const updateContactSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully updated contact.',
        success_data: action.updatedContactObj
    })
}

const updateContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// OPTIONS

const getContactOptionsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: null
    })
}

const getContactOptionsSuccess = (state, action) => {
    
    return updateObject(state, {
        loading: false,
        // success: 'Loaded options',
        contactOptions: action.response ?
            {
                vendorOptions: action.response.vendorListOptions.map(v => v[0]),
                useTypeOptions: action.response.useTypeOptions.map(v => v[0])
            }
            :
            null
    });
}

const getContactOptionsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    });
}

// GET PARTNERS

const getPartnersStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: null
    })
}

const getPartnersSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        partnersData: action.response
    })
}

const getPartnersFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    })
}

// GET CONTACT SHARES

const getContactSharesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    })
}

const getContactSharesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        contactShares: action.response
    })
}

const getContactSharesFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    })
}

// GET CONTACT USERNAMES

const getContactUsernamesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null
    })
}

const getContactUsernamesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        contactUsernames: action.response
    })
}

const getContactUsernamesFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    })
}

// POST CONTACT SHARE

const createContactShareStart = (state, action) => {
    return updateObject(state, {
        error: null
    })
}

const createContactShareSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Shared contact'
    })
}

const createContactShareFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE CONTACT SHARE

const deleteContactShareStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteContactShareSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Deleted contact'
    })
}

const deleteContactShareFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const clearContactNames = (state, action) => {
    return updateObject(state, {
        contactNames: []
    })
}

// CREATE_CRM_FILE

const createCrmFileStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const createCrmFileSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully uploaded document',
        loading: false
    })
}

const createCrmFileFail = (state, action) => {
    return updateObject(state, {
        error: 'Failed to upload document',
        loading: false
    })
}

// GET_CRM_FILE

const getCrmFileStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getCrmFileSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully downloaded crm file.',
        downloadedFile: action.response
    })
}

const getCrmFileFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_CRM_FILE_LIST

const getCrmFileListStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const getCrmFileListSuccess = (state, action) => {
    return updateObject(state, {
        // success: 'Successfully retrieved file list',
        loading: false,
        fileList: action.response
    })
}

const getCrmFileListFail = (state, action) => {
    return updateObject(state, {
        error: 'Failed to retrieve file list',
        loading: false
    })
}

// DELETE_CRM_FILE

const deleteCrmFileStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteCrmFileSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted CRM file.'
    })
}

const deleteCrmFileFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// ADD_PROPERTY_TO_CONTACT

const addPropertyToContactStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const addPropertyToContactSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully added property',
        new_property: action.response
    })
}

const addPropertyToContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// REMOVE_PROPERTY_TO_CONTACT

const removePropertyToContactStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const removePropertyToContactSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully removed property'
    })
}

const removePropertyToContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_PROPERTY_TO_CONTACT

const getPropertyToContactStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const getPropertyToContactSuccess = (state, action) => {
    if (action.data_type === 'property') {
        return updateObject(state, {
            success: 'Successfully got contacts',
            contact_properties: action.response,
            loading: false
        })
    }
    return updateObject(state, {
        success: 'Successfully got properties',
        properties: action.response,
        loading: false
    })
}

const getPropertyToContactFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// GET_CONTACT_DATA

const getContactDataStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getContactDataSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        contactData2: action.response
    })
}

const getContactDataFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CLEAR FILE LIST 

const clearFileList = (state, action) => {
    return updateObject(state, {
        fileList: []
    })
}

// CLEAR MESSAGES 

const clearCRMMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

// GET_CONTACT_CHANGES

const getContactChangesStart = (state, action) => {
    return updateObject(state, {
        contactChangesError: null,
        contactChangesSuccess: null,
        contactChangesData: null,
		contactChangesLoading: true,
    })
}

const getContactChangesSuccess = (state, action) => {
    return updateObject(state, {
        contactChangesSuccess: 'Success',
        contactChangesData: action.response,
		contactChangesLoading: false,
    })
}

const getContactChangesFail = (state, action) => {
    return updateObject(state, {
        contactChangesError: action.err,
        contactChangesSuccess: null,
        contactChangesData: null,
		contactChangesLoading: false,
    })
}

// SAVE_CONTACT_CHANGES

const saveContactChangesStart = (state, action) => {
    return updateObject(state, {
        contactChangesError: null,
        contactChangesSuccess: null
    })
}

const saveContactChangesSuccess = (state, action) => {
    return updateObject(state, {
        contactChangesSuccess: 'Success'
    })
}

const saveContactChangesFail = (state, action) => {
    return updateObject(state, {
        contactChangesError: action.err
    })
}

const setContactChanges = (state, action) => {
    return updateObject(state, {
        contactChangesError: null,
        contactChangesSuccess: null,
        contactChangesData: null
    })
}

// GET_CONTACT_ENTITIES

const getContactEntitiesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        contact_entities: []
    })
}

const getContactEntitiesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        contact_entities: action.response
    })
}

const getContactEntitiesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        contact_entities: []
    })
}

// CREATE_CONTACT_ENTITY

const createContactEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createContactEntitySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const createContactEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_CONTACT_ENTITY

const deleteContactEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteContactEntitySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const deleteContactEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_UPDATED_CONTACT_ENTITY

const getUpdatedContactEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
		updated_contact_entity: {}
    })
}

const getUpdatedContactEntitySuccess = (state, action) => {
    console.log("tth ~ getUpdatedContactEntitySuccess ~ action:", action)
    return updateObject(state, {
        success: 'Success',
		updated_contact_entity: action.response
    })
}

const getUpdatedContactEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
		updated_contact_entity: {}
    })
}

// GET_CONTACT_DBAS

const getContactDbasStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        contactDbas: []
    })
}

const getContactDbasSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        contactDbas: action.response
    })
}

const getContactDbasFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        contactDbas: []
    })
}

// CREATE_CONTACT_DBA

const createContactDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createContactDbaSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully added a DBA',
        contactDbas: [...state.contactDbas, {
            id: action.response.id,
            name: action.response.name,
            contact_id: action.response.contact_id
        }]
    })
}

const createContactDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_CONTACT_DBA

const updateContactDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateContactDbaSuccess = (state, action) => {
    const {id, name, contact_id} = action.response;

    return updateObject(state, {
        success: 'Successfully updated DBA',
        contactDbas: state.contactDbas.map((dba) => {
            if (dba.id === parseInt(id)) {
                return { id, name, contact_id }
            }

            return dba;
        })
    })
}

const updateContactDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_CONTACT_DBA

const deleteContactDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteContactDbaSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        contactDbas: state.contactDbas.filter(dba => dba.id !== parseInt(action.response.id))
    })
}

const deleteContactDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_CONTACT_COMPANIES

const getContactCompaniesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        contactCompanies: []
    })
}

const getContactCompaniesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        contactCompanies: action.response
    })
}

const getContactCompaniesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        contactCompanies: []
    })
}

// GET_CRM_DATA

const getCrmDataStart = (state, action) => {
    return updateObject(state, {
        error_crm_data: null,
        success_crm_data: null,
        original_crm_data: null
    })
}

const getCrmDataSuccess = (state, action) => {
    return updateObject(state, {
        success_crm_data: 'Success',
        original_crm_data: action.response
    })
}

const getCrmDataFail = (state, action) => {
    return updateObject(state, {
        error_crm_data: action.err
    })
}

// UPDATE_CRM_DATA

const updateCrmDataStart = (state, action) => {
    return updateObject(state, {
        error_crm_data: null,
        success_crm_data: null
    })
}

const updateCrmDataSuccess = (state, action) => {
    return updateObject(state, {
        success_crm_data: 'Success'
    })
}

const updateCrmDataFail = (state, action) => {
    return updateObject(state, {
        error_crm_data: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_CONTACTS_START:
            return getContactsStart(state, action);
        case actionTypes.GET_CONTACTS_SUCCESS:
            return getContactsSuccess(state, action);
        case actionTypes.GET_CONTACTS_FAIL:
            return getContactsFail(state, action);

        case actionTypes.GET_CONTACT_START:
            return getContactStart(state, action);
        case actionTypes.GET_CONTACT_SUCCESS:
            return getContactSuccess(state, action);
        case actionTypes.GET_CONTACT_FAIL:
            return getContactFail(state, action);

        case actionTypes.CREATE_CONTACT_START:
            return createContactStart(state, action);
        case actionTypes.CREATE_CONTACT_SUCCESS:
            return createContactSuccess(state, action);
        case actionTypes.CREATE_CONTACT_FAIL:
            return createContactFail(state, action);

        case actionTypes.DELETE_CONTACT_START:
            return deleteContactStart(state, action);
        case actionTypes.DELETE_CONTACT_SUCCESS:
            return deleteContactSuccess(state, action);
        case actionTypes.DELETE_CONTACT_FAIL:
            return deleteContactFail(state, action);

        case actionTypes.GET_ADDRESSES_START:
            return getAddressesStart(state, action);
        case actionTypes.GET_ADDRESSES_SUCCESS:
            return getAddressesSuccess(state, action);
        case actionTypes.GET_ADDRESSES_FAIL:
            return getAddressesFail(state, action);

        case actionTypes.UPDATE_CONTACT_START:
            return updateContactStart(state, action);
        case actionTypes.UPDATE_CONTACT_SUCCESS:
            return updateContactSuccess(state, action);
        case actionTypes.UPDATE_CONTACT_FAIL:
            return updateContactFail(state, action);

        case actionTypes.GET_CONTACT_OPTIONS_START:
            return getContactOptionsStart(state, action);
        case actionTypes.GET_CONTACT_OPTIONS_SUCCESS:
            return getContactOptionsSuccess(state, action);
        case actionTypes.GET_CONTACT_OPTIONS_FAIL:
            return getContactOptionsFail(state, action);

        case actionTypes.GET_PARTNERS_START:
            return getPartnersStart(state, action);
        case actionTypes.GET_PARTNERS_SUCCESS:
            return getPartnersSuccess(state, action);
        case actionTypes.GET_PARTNERS_FAIL:
            return getPartnersFail(state, action);

        case actionTypes.GET_CONTACT_USERNAMES_START:
            return getContactUsernamesStart(state, action);
        case actionTypes.GET_CONTACT_USERNAMES_SUCCESS:
            return getContactUsernamesSuccess(state, action);
        case actionTypes.GET_CONTACT_USERNAMES_FAIL:
            return getContactUsernamesFail(state, action);

        case actionTypes.GET_CONTACT_SHARES_START:
            return getContactSharesStart(state, action);
        case actionTypes.GET_CONTACT_SHARES_SUCCESS:
            return getContactSharesSuccess(state, action);
        case actionTypes.GET_CONTACT_SHARES_FAIL:
            return getContactSharesFail(state, action);

        case actionTypes.CREATE_CONTACT_SHARE_START:
            return createContactShareStart(state, action);
        case actionTypes.CREATE_CONTACT_SHARE_SUCCESS:
            return createContactShareSuccess(state, action);
        case actionTypes.CREATE_CONTACT_SHARE_FAIL:
            return createContactShareFail(state, action);

        case actionTypes.DELETE_CONTACT_SHARE_START:
            return deleteContactShareStart(state, action);
        case actionTypes.DELETE_CONTACT_SHARE_SUCCESS:
            return deleteContactShareSuccess(state, action);
        case actionTypes.DELETE_CONTACT_SHARE_FAIL:
            return deleteContactShareFail(state, action);

        case actionTypes.GET_CONTACT_NAMES_START:
            return getContactNamesStart(state, action);
        case actionTypes.GET_CONTACT_NAMES_SUCCESS:
            return getContactNamesSuccess(state, action);
        case actionTypes.GET_CONTACT_NAMES_FAIL:
            return getContactNamesFail(state, action);
        case actionTypes.CLEAR_CONTACT_NAMES:
            return clearContactNames(state, action);

        case actionTypes.CREATE_CRM_FILE_START:
            return createCrmFileStart(state, action);
        case actionTypes.CREATE_CRM_FILE_SUCCESS:
            return createCrmFileSuccess(state, action);
        case actionTypes.CREATE_CRM_FILE_FAIL:
            return createCrmFileFail(state, action);

        case actionTypes.GET_CRM_FILE_START:
            return getCrmFileStart(state, action);
        case actionTypes.GET_CRM_FILE_SUCCESS:
            return getCrmFileSuccess(state, action);
        case actionTypes.GET_CRM_FILE_FAIL:
            return getCrmFileFail(state, action);

        case actionTypes.GET_CRM_FILE_LIST_START:
            return getCrmFileListStart(state, action);
        case actionTypes.GET_CRM_FILE_LIST_SUCCESS:
            return getCrmFileListSuccess(state, action);
        case actionTypes.GET_CRM_FILE_LIST_FAIL:
            return getCrmFileListFail(state, action);

        case actionTypes.DELETE_CRM_FILE_START:
            return deleteCrmFileStart(state, action);
        case actionTypes.DELETE_CRM_FILE_SUCCESS:
            return deleteCrmFileSuccess(state, action);
        case actionTypes.DELETE_CRM_FILE_FAIL:
            return deleteCrmFileFail(state, action);

        case actionTypes.ADD_PROPERTY_TO_CONTACT_START:
            return addPropertyToContactStart(state, action);
        case actionTypes.ADD_PROPERTY_TO_CONTACT_SUCCESS:
            return addPropertyToContactSuccess(state, action);
        case actionTypes.ADD_PROPERTY_TO_CONTACT_FAIL:
            return addPropertyToContactFail(state, action);

        case actionTypes.REMOVE_PROPERTY_TO_CONTACT_START:
            return removePropertyToContactStart(state, action);
        case actionTypes.REMOVE_PROPERTY_TO_CONTACT_SUCCESS:
            return removePropertyToContactSuccess(state, action);
        case actionTypes.REMOVE_PROPERTY_TO_CONTACT_FAIL:
            return removePropertyToContactFail(state, action);

        case actionTypes.GET_PROPERTY_TO_CONTACT_START:
            return getPropertyToContactStart(state, action);
        case actionTypes.GET_PROPERTY_TO_CONTACT_SUCCESS:
            return getPropertyToContactSuccess(state, action);
        case actionTypes.GET_PROPERTY_TO_CONTACT_FAIL:
            return getPropertyToContactFail(state, action);

        case actionTypes.GET_CONTACT_DATA_START:
            return getContactDataStart(state, action);
        case actionTypes.GET_CONTACT_DATA_SUCCESS:
            return getContactDataSuccess(state, action);
        case actionTypes.GET_CONTACT_DATA_FAIL:
            return getContactDataFail(state, action);

        case actionTypes.CLEAR_CONTACTS:
            return clearContacts(state, action);

        case actionTypes.CLEAR_FILE_LIST:
            return clearFileList(state, action);
        
        case actionTypes.CLEAR_CRM_MESSAGES:
            return clearCRMMessages(state, action);

        case actionTypes.GET_CONTACT_CHANGES_START:
            return getContactChangesStart(state, action);
        case actionTypes.GET_CONTACT_CHANGES_SUCCESS:
            return getContactChangesSuccess(state, action);
        case actionTypes.GET_CONTACT_CHANGES_FAIL:
            return getContactChangesFail(state, action);

        case actionTypes.SAVE_CONTACT_CHANGES_START:
            return saveContactChangesStart(state, action);
        case actionTypes.SAVE_CONTACT_CHANGES_SUCCESS:
            return saveContactChangesSuccess(state, action);
        case actionTypes.SAVE_CONTACT_CHANGES_FAIL:
            return saveContactChangesFail(state, action);

        case actionTypes.SET_CONTACT_CHANGES:
            return setContactChanges(state, action);

        case actionTypes.GET_CONTACT_ENTITIES_START:
            return getContactEntitiesStart(state, action);
        case actionTypes.GET_CONTACT_ENTITIES_SUCCESS:
            return getContactEntitiesSuccess(state, action);
        case actionTypes.GET_CONTACT_ENTITIES_FAIL:
            return getContactEntitiesFail(state, action);

        case actionTypes.CREATE_CONTACT_ENTITY_START:
            return createContactEntityStart(state, action);
        case actionTypes.CREATE_CONTACT_ENTITY_SUCCESS:
            return createContactEntitySuccess(state, action);
        case actionTypes.CREATE_CONTACT_ENTITY_FAIL:
            return createContactEntityFail(state, action);

        case actionTypes.DELETE_CONTACT_ENTITY_START:
            return deleteContactEntityStart(state, action);
        case actionTypes.DELETE_CONTACT_ENTITY_SUCCESS:
            return deleteContactEntitySuccess(state, action);
        case actionTypes.DELETE_CONTACT_ENTITY_FAIL:
            return deleteContactEntityFail(state, action);

		case actionTypes.GET_UPDATED_CONTACT_ENTITY_START:
			return getUpdatedContactEntityStart(state, action);
		case actionTypes.GET_UPDATED_CONTACT_ENTITY_SUCCESS:
			return getUpdatedContactEntitySuccess(state, action);
		case actionTypes.GET_UPDATED_CONTACT_ENTITY_FAIL:
			return getUpdatedContactEntityFail(state, action);

        case actionTypes.GET_CONTACT_DBAS_START:
            return getContactDbasStart(state, action);
        case actionTypes.GET_CONTACT_DBAS_SUCCESS:
            return getContactDbasSuccess(state, action);
        case actionTypes.GET_CONTACT_DBAS_FAIL:
            return getContactDbasFail(state, action);

        case actionTypes.CREATE_CONTACT_DBA_START:
            return createContactDbaStart(state, action);
        case actionTypes.CREATE_CONTACT_DBA_SUCCESS:
            return createContactDbaSuccess(state, action);
        case actionTypes.CREATE_CONTACT_DBA_FAIL:
            return createContactDbaFail(state, action);

        case actionTypes.UPDATE_CONTACT_DBA_START:
            return updateContactDbaStart(state, action);
        case actionTypes.UPDATE_CONTACT_DBA_SUCCESS:
            return updateContactDbaSuccess(state, action);
        case actionTypes.UPDATE_CONTACT_DBA_FAIL:
            return updateContactDbaFail(state, action);
            
        case actionTypes.DELETE_CONTACT_DBA_START:
            return deleteContactDbaStart(state, action);
        case actionTypes.DELETE_CONTACT_DBA_SUCCESS:
            return deleteContactDbaSuccess(state, action);
        case actionTypes.DELETE_CONTACT_DBA_FAIL:
            return deleteContactDbaFail(state, action);

        case actionTypes.GET_CONTACT_COMPANIES_START:
            return getContactCompaniesStart(state, action);
        case actionTypes.GET_CONTACT_COMPANIES_SUCCESS:
            return getContactCompaniesSuccess(state, action);
        case actionTypes.GET_CONTACT_COMPANIES_FAIL:
            return getContactCompaniesFail(state, action);

        case actionTypes.GET_CRM_DATA_START:
            return getCrmDataStart(state, action);
        case actionTypes.GET_CRM_DATA_SUCCESS:
            return getCrmDataSuccess(state, action);
        case actionTypes.GET_CRM_DATA_FAIL:
            return getCrmDataFail(state, action);

        case actionTypes.UPDATE_CRM_DATA_START:
            return updateCrmDataStart(state, action);
        case actionTypes.UPDATE_CRM_DATA_SUCCESS:
            return updateCrmDataSuccess(state, action);
        case actionTypes.UPDATE_CRM_DATA_FAIL:
            return updateCrmDataFail(state, action);

        default: return state;
    }
};

export default reducer;
