import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../../components/CustomButtons/Button';
import { typeDescriptions } from './RSheetsLogic';

const RSheetsTypeOptions = (props) => {
    return <div>
        <Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
            <div className="dialog-wrapper text-center">
                <h4>Choose a Routing Sheet Type to Add</h4>
                {Object.keys(typeDescriptions).map(status => {
                    return <div key={status}>
                        <Button color="rose"
                            onClick={() => {
                                props.addNewSheet(status);
                                props.close();
                            }}
                        >{typeDescriptions[status]}</Button>
                    </div>
                })}
            </div>
        </Dialog>
    </div>
}

export default RSheetsTypeOptions;