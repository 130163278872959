import React from "react";
import { get, isEmpty } from 'lodash';

import Table from "components/Table/Table.jsx";
import Button from 'components/CustomButtons/Button';

const TABLE_HEADER = ["Contact", "Company", "Property"];

const DialPadMergeSummary = (props) => {
    const { contactToUpdate, companyToUpdate, onClose, propertyToUpdate } = props;

    const tableData = TABLE_HEADER.map((header) => {
        let value = '';

        if (header === TABLE_HEADER[0]) {
            if (!contactToUpdate) {
                value = 'SKIPPED'
            } else {
                const fullName = `${get(contactToUpdate, 'data.first_name', '')} ${get(contactToUpdate, 'data.last_name', '')}`
                value = `${fullName} (${get(contactToUpdate, 'action', '')})`;
            }
        } else if (header === TABLE_HEADER[1]) {
            if (isEmpty(companyToUpdate)) {
                value = 'SKIPPED'
            } else {
                value = `${get(companyToUpdate, 'data.name', '')} (${companyToUpdate.action})`;
            }
        } else if (header === TABLE_HEADER[2]) {
            if (isEmpty(propertyToUpdate)) {
                value = 'SKIPPED'
            } else {
                value = `${get(propertyToUpdate, 'data.name', '')} (${propertyToUpdate.action})`
            }
        }

        return [
            <div style={{ textAlign: "right" }}>
                <strong>{header}</strong>
            </div>,
            value,
        ];
    });

    return (
        <div>
            <h3>Merge Summary</h3>
            <Table tableData={tableData} />
            <div className='text-center' style={{ marginTop: '15px' }}>
                <Button color="primary" onClick={onClose}>CLOSE</Button>
            </div>
        </div>
    );
};

export default DialPadMergeSummary;
