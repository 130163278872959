import * as actionTypes from './actionTypes';

// GET ALL CONTACTS USING PARAMS

export const getContacts = (params) => {
    return {
        type: actionTypes.GET_CONTACTS,
        params
    }
}

export const getContactsSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACTS_SUCCESS,
        response
    }
}

export const getContactsFail = (err) => {
    return {
        type: actionTypes.GET_CONTACTS_FAIL,
        err
    }
}

export const getContactsStart = () => {
    return {
        type: actionTypes.GET_CONTACTS_START
    }
}

// GET CONTACT

export const getContact = (id, email, include_files) => {
    return {
        type: actionTypes.GET_CONTACT,
        id,
        email,
        include_files
    }
}

export const getContactSuccess = (response, isEmailContacts) => {
    return {
        type: actionTypes.GET_CONTACT_SUCCESS,
        response,
        isEmailContacts
    }
}

export const getContactFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_FAIL,
        err
    }
}

export const getContactStart = () => {
    return {
        type: actionTypes.GET_CONTACT_START
    }
}

// CREATE CONTACT

export const createContact = (data, 
        partnersData, 
        addressData, 
        appointmentsData, 
        sharesData, 
        formDataObj, 
        message, 
        companiesForAdd, 
        propertiesForAdd, 
        entitiesData, 
        dbasForAdd
    ) => {
    return {
        type: actionTypes.CREATE_CONTACT,
        data,
        partnersData,
        addressData,
        appointmentsData,
        sharesData,
        formDataObj,
        message,
        companiesForAdd,
        propertiesForAdd,
        entitiesData,
        dbasForAdd
    }
}

export const createContactStart = () => {
    return {
        type: actionTypes.CREATE_CONTACT_START
    }
}

export const createContactSuccess = (response, illi_number) => {
    return {
        type: actionTypes.CREATE_CONTACT_SUCCESS,
        response,
        illi_number
    }
}

export const createContactFail = (err) => {
    return {
        type: actionTypes.CREATE_CONTACT_FAIL,
        err
    }
}

// UPDATE CONTACT

export const updateContact = (data, partnersData, addressData) => {
    return {
        type: actionTypes.UPDATE_CONTACT,
        data,
        partnersData,
        addressData
    }
}

export const updateContactStart = () => {
    return {
        type: actionTypes.UPDATE_CONTACT_START
    }
}

export const updateContactSuccess = (response, updatedContactObj) => {
    return {
        type: actionTypes.UPDATE_CONTACT_SUCCESS,
        response,
        updatedContactObj
    }
}

export const updateContactFail = (err) => {
    return {
        type: actionTypes.UPDATE_CONTACT_FAIL,
        err
    }
}

// DELETE CONTACT

export const deleteContact = (contact_id) => {
    return {
        type: actionTypes.DELETE_CONTACT,
        contact_id
    }
}

export const deleteContactStart = () => {
    return {
        type: actionTypes.DELETE_CONTACT_START
    }
}

export const deleteContactSuccess = (response) => {
    return {
        type: actionTypes.DELETE_CONTACT_SUCCESS,
        response
    }
}

export const deleteContactFail = (err) => {
    return {
        type: actionTypes.DELETE_CONTACT_FAIL,
        err
    }
}

// GET ADDRESSES

export const getAddresses = (contact_id) => {
    return {
        type: actionTypes.GET_ADDRESSES,
        contact_id
    }
}

export const getAddressesStart = () => {
    return {
        type: actionTypes.GET_ADDRESSES_START,
    }
}

export const getAddressesSuccess = (response) => {
    return {
        type: actionTypes.GET_ADDRESSES_SUCCESS,
        response
    }
}

export const getAddressesFail = (err) => {
    return {
        type: actionTypes.GET_ADDRESSES_FAIL,
        err
    }
}

// GET OPTIONS

export const getContactOptions = () => {
    return {
        type: actionTypes.GET_CONTACT_OPTIONS
    }
}

export const getContactOptionsStart = () => {
    return {
        type: actionTypes.GET_CONTACT_OPTIONS_START
    }
}

export const getContactOptionsSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_OPTIONS_SUCCESS,
        response
    }
}

export const getContactOptionsFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_OPTIONS_FAIL,
        err
    }
}

// GET PARTNERS

export const getPartners = (contact_id) => {
    return {
        type: actionTypes.GET_PARTNERS,
        contact_id
    }
}

export const getPartnersStart = () => {
    return {
        type: actionTypes.GET_PARTNERS_START
    }
}

export const getPartnersSuccess = (response) => {
    return {
        type: actionTypes.GET_PARTNERS_SUCCESS,
        response
    }
}

export const getPartnersFail = (err) => {
    return {
        type: actionTypes.GET_PARTNERS_FAIL,
        err
    }
}

// GET CONTACT SHARES

export const getContactShares = (contact_id) => {
    return {
        type: actionTypes.GET_CONTACT_SHARES,
        contact_id
    }
}

export const getContactSharesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_SHARES_START,
    }
}

export const getContactSharesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_SHARES_SUCCESS,
        response
    }
}

export const getContactSharesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_SHARES_SUCCESS,
        err
    }
}

// GET CONTACT USERNAMES

export const getContactUsernames = () => {
    return {
        type: actionTypes.GET_CONTACT_USERNAMES
    }
}

export const getContactUsernamesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_USERNAMES_START
    }
}

export const getContactUsernamesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_USERNAMES_SUCCESS,
        response
    }
}

export const getContactUsernamesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_USERNAMES_FAIL,
        err
    }
}

// CREATE CONTACT SHARE

export const createContactShare = (data) => {
    return {
        type: actionTypes.CREATE_CONTACT_SHARE,
        data
    }
}

export const createContactShareStart = () => {
    return {
        type: actionTypes.CREATE_CONTACT_SHARE_START
    }
}

export const createContactShareSuccess = (response) => {
    return {
        type: actionTypes.CREATE_CONTACT_SHARE_SUCCESS,
        response
    }
}

export const createContactShareFail = (err) => {
    return {
        type: actionTypes.CREATE_CONTACT_SHARE_FAIL,
        err
    }
}

// DELETE CONTACT SHARE

export const deleteContactShare = (share_id) => {
    return {
        type: actionTypes.DELETE_CONTACT_SHARE,
        share_id
    }
}

export const deleteContactShareStart = () => {
    return {
        type: actionTypes.DELETE_CONTACT_SHARE_START
    }
}

export const deleteContactShareSuccess = (response) => {
    return {
        type: actionTypes.DELETE_CONTACT_SHARE_SUCCESS,
        response
    }
}

export const deleteContactShareFail = (err) => {
    return {
        type: actionTypes.DELETE_CONTACT_SHARE_FAIL,
        err
    }
}

export const getContactNames = (data) => {
    return {
        type: actionTypes.GET_CONTACT_NAMES,
        data
    }
}

export const getContactNamesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_NAMES_START
    }
}

export const getContactNamesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_NAMES_SUCCESS,
        response
    }
}

export const getContactNamesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_NAMES_FAIL,
        err
    }
}

export const clearContactNames = () => {
    return {
        type: actionTypes.CLEAR_CONTACT_NAMES
    }
}

// CREATE_CRM_FILE

export const createCrmFile = (data) => {
    return {
        type: actionTypes.CREATE_CRM_FILE,
        data
    }
}

export const createCrmFileStart = () => {
    return {
        type: actionTypes.CREATE_CRM_FILE_START
    }
}

export const createCrmFileSuccess = (response) => {
    return {
        type: actionTypes.CREATE_CRM_FILE_SUCCESS,
        response
    }
}

export const createCrmFileFail = (err) => {
    return {
        type: actionTypes.CREATE_CRM_FILE_FAIL,
        err
    }
}

// GET_CRM_FILE

export const getCrmFile = (data) => {
    return {
        type: actionTypes.GET_CRM_FILE,
        data
    }
}

export const getCrmFileStart = () => {
    return {
        type: actionTypes.GET_CRM_FILE_START
    }
}

export const getCrmFileSuccess = (response) => {
    return {
        type: actionTypes.GET_CRM_FILE_SUCCESS,
        response
    }
}

export const getCrmFileFail = (err) => {
    return {
        type: actionTypes.GET_CRM_FILE_FAIL,
        err
    }
}

// GET_CRM_FILE_LIST

export const getCrmFileList = (data) => {
    return {
        type: actionTypes.GET_CRM_FILE_LIST,
        data
    }
}

export const getCrmFileListStart = () => {
    return {
        type: actionTypes.GET_CRM_FILE_LIST_START
    }
}

export const getCrmFileListSuccess = (response) => {
    return {
        type: actionTypes.GET_CRM_FILE_LIST_SUCCESS,
        response
    }
}

export const getCrmFileListFail = (err) => {
    return {
        type: actionTypes.GET_CRM_FILE_LIST_FAIL,
        err
    }
}

// DELETE_CRM_FILE

export const deleteCrmFile = (data) => {
    return {
        type: actionTypes.DELETE_CRM_FILE,
        data
    }
}

export const deleteCrmFileStart = () => {
    return {
        type: actionTypes.DELETE_CRM_FILE_START
    }
}

export const deleteCrmFileSuccess = (response) => {
    return {
        type: actionTypes.DELETE_CRM_FILE_SUCCESS,
        response
    }
}

export const deleteCrmFileFail = (err) => {
    return {
        type: actionTypes.DELETE_CRM_FILE_FAIL,
        err
    }
}

// ADD_PROPERTY_TO_CONTACT 

export const addPropertyToContact = (data, data_type) => {
    return {
        type: actionTypes.ADD_PROPERTY_TO_CONTACT,
        data,
        data_type
    }
}

export const addPropertyToContactStart = () => {
    return {
        type: actionTypes.ADD_PROPERTY_TO_CONTACT_START
    }
}

export const addPropertyToContactSuccess = (response) => {
    return {
        type: actionTypes.ADD_PROPERTY_TO_CONTACT_SUCCESS,
        response
    }
}

export const addPropertyToContactFail = (err) => {
    return {
        type: actionTypes.ADD_PROPERTY_TO_CONTACT_FAIL,
        err
    }
}

// REMOVE_PROPERTY_TO_CONTACT 

export const removePropertyToContact = (data, data_type, data_type_id) => {
    return {
        type: actionTypes.REMOVE_PROPERTY_TO_CONTACT,
        data,
        data_type,
        data_type_id
    }
}

export const removePropertyToContactStart = () => {
    return {
        type: actionTypes.REMOVE_PROPERTY_TO_CONTACT_START
    }
}

export const removePropertyToContactSuccess = (response) => {
    return {
        type: actionTypes.REMOVE_PROPERTY_TO_CONTACT_SUCCESS,
        response
    }
}

export const removePropertyToContactFail = (err) => {
    return {
        type: actionTypes.REMOVE_PROPERTY_TO_CONTACT_FAIL,
        err
    }
}

// GET_PROPERTY_TO_CONTACT 

export const getPropertyToContact = (data, data_type) => {
    return {
        type: actionTypes.GET_PROPERTY_TO_CONTACT,
        data,
        data_type
    }
}

export const getPropertyToContactStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_TO_CONTACT_START
    }
}

export const getPropertyToContactSuccess = (response, data_type) => {
    return {
        type: actionTypes.GET_PROPERTY_TO_CONTACT_SUCCESS,
        response,
        data_type
    }
}

export const getPropertyToContactFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_TO_CONTACT_FAIL,
        err
    }
}

// GET_CONTACT_DATA 

export const getContactData = (data) => {
    return {
        type: actionTypes.GET_CONTACT_DATA,
        data
    }
}

export const getContactDataStart = () => {
    return {
        type: actionTypes.GET_CONTACT_DATA_START
    }
}

export const getContactDataSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_DATA_SUCCESS,
        response
    }
}

export const getContactDataFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_DATA_FAIL,
        err
    }
}

export const clearContacts = () => {
    return {
        type: actionTypes.CLEAR_CONTACTS
    }
}

export const clearFileList = () => {
    return {
        type: actionTypes.CLEAR_FILE_LIST
    }
}

export const clearCRMMessages = () => {
    return {
        type: actionTypes.CLEAR_CRM_MESSAGES
    }
}

// GET_CONTACT_CHANGES 

export const getContactChanges = (data) => {
    return {
        type: actionTypes.GET_CONTACT_CHANGES,
        data
    }
}

export const getContactChangesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_CHANGES_START
    }
}

export const getContactChangesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_CHANGES_SUCCESS,
        response
    }
}

export const getContactChangesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_CHANGES_FAIL,
        err
    }
}

// SAVE_CONTACT_CHANGES 

export const saveContactChanges = (data) => {
    return {
        type: actionTypes.SAVE_CONTACT_CHANGES,
        data
    }
}

export const saveContactChangesStart = () => {
    return {
        type: actionTypes.SAVE_CONTACT_CHANGES_START
    }
}

export const saveContactChangesSuccess = (response) => {
    return {
        type: actionTypes.SAVE_CONTACT_CHANGES_SUCCESS,
        response
    }
}

export const saveContactChangesFail = (err) => {
    return {
        type: actionTypes.SAVE_CONTACT_CHANGES_FAIL,
        err
    }
}

export const setContactChanges = (data) => {
    return {
        type: actionTypes.SET_CONTACT_CHANGES,
        data
    }
}

// GET_CONTACT_ENTITIES 

export const getContactEntities = (data) => {
    return {
        type: actionTypes.GET_CONTACT_ENTITIES,
        data
    }
}

export const getContactEntitiesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_ENTITIES_START
    }
}

export const getContactEntitiesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_ENTITIES_SUCCESS,
        response
    }
}

export const getContactEntitiesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_ENTITIES_FAIL,
        err
    }
}

// CREATE_CONTACT_ENTITY 

export const createContactEntity = (data) => {
    return {
        type: actionTypes.CREATE_CONTACT_ENTITY,
        data
    }
}

export const createContactEntityStart = () => {
    return {
        type: actionTypes.CREATE_CONTACT_ENTITY_START
    }
}

export const createContactEntitySuccess = (response) => {
    return {
        type: actionTypes.CREATE_CONTACT_ENTITY_SUCCESS,
        response
    }
}

export const createContactEntityFail = (err) => {
    return {
        type: actionTypes.CREATE_CONTACT_ENTITY_FAIL,
        err
    }
}

// DELETE_CONTACT_ENTITY 

export const deleteContactEntity = (data) => {
    return {
        type: actionTypes.DELETE_CONTACT_ENTITY,
        data
    }
}

export const deleteContactEntityStart = () => {
    return {
        type: actionTypes.DELETE_CONTACT_ENTITY_START
    }
}

export const deleteContactEntitySuccess = (response) => {
    return {
        type: actionTypes.DELETE_CONTACT_ENTITY_SUCCESS,
        response
    }
}

export const deleteContactEntityFail = (err) => {
    return {
        type: actionTypes.DELETE_CONTACT_ENTITY_FAIL,
        err
    }
}

// GET_UPDATED_CONTACT_ENTITY

export const getUpdatedContactEntity = (data) => {
    return {
        type: actionTypes.GET_UPDATED_CONTACT_ENTITY,
        data
    }
}

export const getUpdatedContactEntityStart = () => {
    return {
        type: actionTypes.GET_UPDATED_CONTACT_ENTITY_START
    }
}

export const getUpdatedContactEntitySuccess = (response) => {
    return {
        type: actionTypes.GET_UPDATED_CONTACT_ENTITY_SUCCESS,
        response
    }
}

export const getUpdatedContactEntityFail = (err) => {
    return {
        type: actionTypes.GET_UPDATED_CONTACT_ENTITY_FAIL,
        err
    }
}

// GET_CONTACT_DBAS 

export const getContactDbas = (data) => {
    return {
        type: actionTypes.GET_CONTACT_DBAS,
        data
    }
}

export const getContactDbasStart = () => {
    return {
        type: actionTypes.GET_CONTACT_DBAS_START
    }
}

export const getContactDbasSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_DBAS_SUCCESS,
        response
    }
}

export const getContactDbasFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_DBAS_FAIL,
        err
    }
}

// CREATE_CONTACT_DBA 

export const createContactDba = (data) => {
    return {
        type: actionTypes.CREATE_CONTACT_DBA,
        data
    }
}

export const createContactDbaStart = () => {
    return {
        type: actionTypes.CREATE_CONTACT_DBA_START
    }
}

export const createContactDbaSuccess = (response) => {
    return {
        type: actionTypes.CREATE_CONTACT_DBA_SUCCESS,
        response
    }
}

export const createContactDbaFail = (err) => {
    return {
        type: actionTypes.CREATE_CONTACT_DBA_FAIL,
        err
    }
}

// UPDATE_CONTACT_DBA 

export const updateContactDba = (data) => {
    return {
        type: actionTypes.UPDATE_CONTACT_DBA,
        data
    }
}

export const updateContactDbaStart = () => {
    return {
        type: actionTypes.UPDATE_CONTACT_DBA_START
    }
}

export const updateContactDbaSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_CONTACT_DBA_SUCCESS,
        response
    }
}

export const updateContactDbaFail = (err) => {
    return {
        type: actionTypes.UPDATE_CONTACT_DBA_FAIL,
        err
    }
}

// DELETE_CONTACT_DBA 

export const deleteContactDba = (data) => {
    return {
        type: actionTypes.DELETE_CONTACT_DBA,
        data
    }
}

export const deleteContactDbaStart = () => {
    return {
        type: actionTypes.DELETE_CONTACT_DBA_START
    }
}

export const deleteContactDbaSuccess = (response) => {
    return {
        type: actionTypes.DELETE_CONTACT_DBA_SUCCESS,
        response
    }
}

export const deleteContactDbaFail = (err) => {
    return {
        type: actionTypes.DELETE_CONTACT_DBA_FAIL,
        err
    }
}

// GET_CONTACT_COMPANIES 
export const getContactCompanies = (data) => {
    return {
        type: actionTypes.GET_CONTACT_COMPANIES,
        data
    }
}

export const getContactCompaniesStart = () => {
    return {
        type: actionTypes.GET_CONTACT_COMPANIES_START
    }
}

export const getContactCompaniesSuccess = (response) => {
    return {
        type: actionTypes.GET_CONTACT_COMPANIES_SUCCESS,
        response
    }
}

export const getContactCompaniesFail = (err) => {
    return {
        type: actionTypes.GET_CONTACT_COMPANIES_FAIL,
        err
    }
}

// GET_CRM_DATA 

export const getCrmData = (data) => {
    return {
        type: actionTypes.GET_CRM_DATA,
        data
    }
}

export const getCrmDataStart = () => {
    return {
        type: actionTypes.GET_CRM_DATA_START
    }
}

export const getCrmDataSuccess = (response) => {
    return {
        type: actionTypes.GET_CRM_DATA_SUCCESS,
        response
    }
}

export const getCrmDataFail = (err) => {
    return {
        type: actionTypes.GET_CRM_DATA_FAIL,
        err
    }
}

// UPDATE_CRM_DATA 

export const updateCrmData = (data) => {
    return {
        type: actionTypes.UPDATE_CRM_DATA,
        data
    }
}

export const updateCrmDataStart = () => {
    return {
        type: actionTypes.UPDATE_CRM_DATA_START
    }
}

export const updateCrmDataSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_CRM_DATA_SUCCESS,
        response
    }
}

export const updateCrmDataFail = (err) => {
    return {
        type: actionTypes.UPDATE_CRM_DATA_FAIL,
        err
    }
}