import React, { useState } from 'react';
import { defaultTo, get, isEqual } from 'lodash';
import { Contacts } from "@material-ui/icons";
import IconButton from '@material-ui/core/IconButton';

import { clone } from 'shared/utility';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInputValidate from 'components/CustomInput/CustomInputValidate.jsx';
import NotificationAsk from 'components/Notification/NotificationAsk';
import { Tooltip } from '@material-ui/core';

const retrieveDbaData = (dba_data, dba_id) => {
    console.log('dba_data', dba_data, dba_id)
    let dbaObj = null;
    if (dba_data && dba_id) {
        dba_data.forEach(dd => {
            if (dd.id === dba_id) dbaObj = dd;
        })
    }
    return dbaObj;
}

const AddressContactItem = (props) => {
    console.log('AddressContactItem', props);
    const {
        entity,
        canEdit,
        setOpenedEditContact,
        setOpenedEditCompany,
        setContactID,
        setCompanyID,
        action,
        entitiesData,
        setEntitiesData,
        deleteCompanyEntity,
        deleteContactEntity,
        fromType,
    } = props;
    const [openedAskModal, setOpenedAskModal] = useState(false);

    const contactData = entity.contact_data;
    const companyData = entity.company_data;
    const dba_id = entity.dba_id;
    const dbaData = retrieveDbaData(entity.dba_data, dba_id);
    const dbaName = dbaData ? ` dba ${dbaData.name} ${!dbaData.active ? '(removed)' : ''}` : '';
    const companyName = entity.entity_company_id ? get(companyData, 'name', '') + dbaName : get(companyData, 'name', '');
    let name = !entity.entity_company_id ?
        get(contactData, 'first_name', '') +
        ' ' +
        get(contactData, 'last_name', '') + dbaName
    :
        get(contactData, 'first_name', '') +
        ' ' +
        get(contactData, 'last_name', '');

    const secDbaData = retrieveDbaData(entity.secondary_dba_data, entity.secondary_dba_id);
    const secDbaName = secDbaData ? ` dba ${secDbaData.name} ${!secDbaData.active ? '(removed)' : ''}` : '';
    name = secDbaData ? name + secDbaName : name;

    const address = get(contactData, 'addresses', []).find(
        (address) => address.type === 'M'
    );
    
    const retrieveCompanyData = (field) => {
        let value = '';
        if (entity.company_contacts) {
            entity.company_contacts.forEach(cc => {
                if (cc.contact_id === entity.entity_id) {
                    if (cc[field]) value = cc[field];
                }
            })
        }
        return value;
    }

    const company_address1 = get(companyData, 'mailing_address1', '');
    const company_address2 = get(companyData, 'mailing_address2', '');
    const company_city = get(companyData, 'mailing_city', '');
    const company_state = get(companyData, 'mailing_state', '');
    const company_zip = get(companyData, 'mailing_zip', '');

    const address1 = get(address, 'street1', '');
    const address2 = get(address, 'street2', '');

    const city = get(address, 'city', '');
    const zip = get(address, 'zip', '');
    const state = get(address, 'state', '');
    const phone = get(contactData, 'main_phone', '');

    const company_phone = retrieveCompanyData('phone');
    const company_email = retrieveCompanyData('email');
    const company_notes = retrieveCompanyData('notes');

    const otherPhone = defaultTo(
        get(contactData, 'mobile_phone', null),
        get(contactData, 'home_phone', '')
    );
    const fax = get(contactData, 'fax', '');
    const email = get(contactData, 'email', '');

    const handleDelete = () => {
        if (action === 'Edit') {
            if (fromType === 'COMPANY') {
                deleteCompanyEntity(entity.id);
            } else if (fromType === 'CONTACT') {
                deleteContactEntity(entity.id);
            }
        } else if (action === 'Add') {
            let newEntities = clone(entitiesData);
            newEntities = newEntities.filter((e) => !isEqual(e, entity));
            setEntitiesData(newEntities);
        }
    };

    const handleOpenEntity = (entity_type_to_open) => {
        console.log('opening', entity, entity_type_to_open);
        if (entity_type_to_open === 'company') {
            if (entity.entity_company_id) {
                console.log('opening - c');
                // open Company
                setCompanyID(entity.entity_company_id);
                setOpenedEditCompany(true);
            }
        } else {
            // open Contact
            setOpenedEditContact(true);
            setContactID(entity.entity_id);
        }
    }


    return (
        <div style={{ fontSize: 16 }} className='boxed'>
            {canEdit && (
                <div style={{ float: 'right' }}>
                    {entity.entity_company_id && <Tooltip title="Open Company">
                        <IconButton onClick={() => {
                            handleOpenEntity('company');
                        }}>
                            <i className='fas fa-user-plus'></i>
                        </IconButton>
                    </Tooltip>}
                    <Tooltip title="Open Contact">
                        <IconButton
                            onClick={handleOpenEntity}
                        >
                            <Contacts />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Delete">
                        <IconButton
                            onClick={() => {
                                setOpenedAskModal(true);
                            }}
                        >
                            <i className='fas fa-trash-alt red-text'></i>
                        </IconButton>
                    </Tooltip>
                </div>
            )}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4>{entity.entity_company_id ? 'Company Contact' : 'Contact'}</h4>
                </GridItem>
                {entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <span
                        className='hoverable2'
                        onClick={() => {
                            handleOpenEntity('company');
                        }}
                    >
                        <CustomInputValidate
                            label='Company Name'
                            value={companyName}
                            readonly
                        />
                    </span>
                </GridItem>}
                <GridItem xs={12} sm={4} md={4}>
                    <span
                        className='hoverable2'
                        onClick={handleOpenEntity}
                    >
                        <CustomInputValidate label='Name' value={name} readonly />
                    </span>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}></GridItem>
                {(company_email || company_notes || company_phone) && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label="Company Contact's Phone Number" value={company_phone} readonly />
                </GridItem>}
                {(company_email || company_notes || company_phone) && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label="Company Contact's Email" value={company_email} readonly />
                </GridItem>}
                {(company_email || company_notes || company_phone) && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label="Company Contact's Notes" value={company_notes} readonly />
                </GridItem>}

                {/* Company */}
                {entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Address 1' value={company_address1} readonly />
                </GridItem>}
                {entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='City' value={company_city} readonly />
                </GridItem>}
                {entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='State' value={company_state} readonly />
                </GridItem>}
                {entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='Zip' value={company_zip} readonly />
                </GridItem>}
                {entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Address 2' value={company_address2} readonly />
                </GridItem>}


                {/* Non Company */}
                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Address 1' value={address1} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='City' value={city} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='State' value={state} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate label='Zip' value={zip} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Address 2' value={address2} readonly />
                </GridItem>}


                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Phone Number' value={phone} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label='Other Number'
                        value={otherPhone}
                        readonly
                    />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Fax' value={fax} readonly />
                </GridItem>}
                {!entity.entity_company_id && <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate label='Email' value={email} readonly />
                </GridItem>}
            </GridContainer>

            {openedAskModal && (
                <NotificationAsk
                    open={openedAskModal}
                    title='Remove Contact'
                    message='Are you sure you want to remove this contact?'
                    success_button_text='REMOVE'
                    close={() => setOpenedAskModal(false)}
                    success={handleDelete}
                />
            )}
        </div>
    );
};

export default AddressContactItem;
