import { clone } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';

import Button from 'components/CustomButtons/Button.jsx';
import * as actions from '../../store/actions';
import ImporterContactsList from './ImporterContactsList';
import ContactsImporter from './ContactsImporter/ContactsImporter';
import Loader from '../../components/Loader/Loader';
import GridContainer from '../../components/Grid/GridContainer';
import GridItem from '../../components/Grid/GridItem';

const Importer = (props) => {
	const { contactList, getEvents, getGoogleContacts, importContacts, loading, googleLoading,
		success, googleContactList } = props;

	const [filteredContactList, setFilteredContactList] = useState(contactList);
	const [googleList, setGoogleList] = useState([]);
	const [contactsToImport, setContactsToImport] = useState([]);

	const [message, setMessage] = useState(null);

	const [openedModal, setOpenedModal] = useState(false);
	const [openedContactImportModal, setOpenedContactImportModal] = useState(false);

	const [alreadyImportedList, setAlreadyImportedList] = useState([]);

	useEffect(() => {
		setFilteredContactList(contactList);
	}, [contactList])

	useEffect(() => {
		const newGoogleContactList = JSON.parse(JSON.stringify(googleList));
		setFilteredContactList(newGoogleContactList.map((gc,i) => {
			gc.primary_phone = gc.phone;
			gc.primary_email = gc.email;
			gc.id = i;
			return gc;
		}));
	}, [googleList]);

	useEffect(() => {
		setGoogleList(clone(googleContactList));
	}, [googleContactList])

	useEffect(() => {
		let newFilteredContactList = clone(filteredContactList);
		newFilteredContactList = newFilteredContactList.filter(contact => {
            return !alreadyImportedList.some(ail => ail.id === contact.id);
            // !alreadyImportedList.includes(contact.id)
        });
		setFilteredContactList(newFilteredContactList);
	}, [alreadyImportedList])

	useEffect(() => {
		if (props.importType === 'dialpad') {
			getEvents();
		} else if (props.importType === 'google') {
			getGoogleContacts();
		}
	}, []);

	const openModal = () => setOpenedModal(true);
	const closeModal = () => {
		setOpenedModal(false);
		setMessage(null);
	};

    console.log('Importer', contactsToImport, props);

	return (
		<div>
			{(!loading && !googleLoading) && <Button 
				onClick={() => {
					setOpenedContactImportModal(true);
				}} 
				color='primary'
				disabled={contactsToImport.length > 0 ? false : true}
			>
                {contactsToImport.length === 0 && <span>Select Contact(s) to Import</span>}
				{contactsToImport.length > 0 && <span>Import {contactsToImport.length} Contact(s)</span>}
			</Button>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {(loading || googleLoading) && <div>
                        <Loader />
                    </div>}
                </GridItem>
            </GridContainer>
			<ImporterContactsList
				data={filteredContactList}
				getEvents={getEvents}
				handleSetContactToImport={(contact) => { 
                    const hasContacts = contactsToImport.some(c => c.id === contact.id);
                    if (hasContacts) {
                        setContactsToImport(contactsToImport.filter(c => {
                            return c.id !== contact.id;
                        })) 
                    } else {
                        setContactsToImport(contactsToImport.concat([contact])) 
                    }
                }}
				contactsToImport={contactsToImport}
				importContacts={importContacts}
				loading={loading || googleLoading ? true : false}
				openModal={openModal}
				setMessage={setMessage}
			/>

			{(message || success) && (
				<Dialog open={openedModal} maxWidth='sm' fullWidth={true}>
					<div style={{ textAlign: 'center', marginTop: 20, padding: 20 }}>{message || success}</div>

					<div style={{ textAlign: 'center', padding: 20 }}>
						<Button color='primary' onClick={closeModal}>
							OK
						</Button>
					</div>
				</Dialog>
			)}

			{openedContactImportModal &&  (
				<ContactsImporter
					contactsToImport={contactsToImport}
					setContactsToImport={setContactsToImport}
					open={openedContactImportModal}
					onClose={() => { 
                        setContactsToImport([]);
                        setOpenedContactImportModal(false)
                    }}
					setAlreadyImportedList={setAlreadyImportedList}
					alreadyImportedList={alreadyImportedList}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		contactList: state.dialpad.contactList,
		googleContactList: state.googleContacts.contactList,
		googleLoading: state.googleContacts.loading,
		loading: state.dialpad.loading,
		success: state.dialpad.success,
		error: state.dialpad.error,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEvents: (params) => dispatch(actions.getDialpadContacts(params)),
		importContacts: (data) => dispatch(actions.importDialpadContacts(data)),
		getGoogleContacts: () => dispatch(actions.getGoogleContacts()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Importer);
