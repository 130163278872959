import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	entriesData: [],
	listingHistory: [],
	error: null,
	success: null,
	loading: false,
	listingPdf: null,
	entryData_success: null,
	entryData_error: null,
	entryData: null
};

// GET_LISTING_ENTRIES

const getListingEntriesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getListingEntriesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully gotten listing entries',
		entriesData: action.response,
	});
};

const getListingEntriesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_LISTING_ENTRY

const createListingEntryStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const createListingEntrySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
	});
};

const createListingEntryFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// UPDATE_LISTING_ENTRY

const updateListingEntryStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const updateListingEntrySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
	});
};

const updateListingEntryFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// DELETE_LISTING_ENTRY

const deleteListingEntryStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteListingEntrySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
	});
};

const deleteListingEntryFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LISTING_PDF

const getListingPdfStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getListingPdfSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		listingPdf: action.response.data,
	});
};

const getListingPdfFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LISTING_HISTORY

const getListingHistoryStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getListingHistorySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		listingHistory: action.response.listing_history,
	});
};

const getListingHistoryFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_LISTING_ENTRY

const getListingEntryStart = (state, action) => {
    return updateObject(state, {
        entryData_error: null,
        entryData_success: null
    })
}

const getListingEntrySuccess = (state, action) => {
    return updateObject(state, {
        entryData_success: 'Success',
		entryData: action.response
    })
}

const getListingEntryFail = (state, action) => {
    return updateObject(state, {
		entryData_error: action.err
    })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_LISTING_ENTRIES_START:
			return getListingEntriesStart(state, action);
		case actionTypes.GET_LISTING_ENTRIES_SUCCESS:
			return getListingEntriesSuccess(state, action);
		case actionTypes.GET_LISTING_ENTRIES_FAIL:
			return getListingEntriesFail(state, action);

		case actionTypes.CREATE_LISTING_ENTRY_START:
			return createListingEntryStart(state, action);
		case actionTypes.CREATE_LISTING_ENTRY_SUCCESS:
			return createListingEntrySuccess(state, action);
		case actionTypes.CREATE_LISTING_ENTRY_FAIL:
			return createListingEntryFail(state, action);

		case actionTypes.UPDATE_LISTING_ENTRY_START:
			return updateListingEntryStart(state, action);
		case actionTypes.UPDATE_LISTING_ENTRY_SUCCESS:
			return updateListingEntrySuccess(state, action);
		case actionTypes.UPDATE_LISTING_ENTRY_FAIL:
			return updateListingEntryFail(state, action);

		case actionTypes.DELETE_LISTING_ENTRY_START:
			return deleteListingEntryStart(state, action);
		case actionTypes.DELETE_LISTING_ENTRY_SUCCESS:
			return deleteListingEntrySuccess(state, action);
		case actionTypes.DELETE_LISTING_ENTRY_FAIL:
			return deleteListingEntryFail(state, action);

		case actionTypes.GET_LISTING_PDF_START:
			return getListingPdfStart(state, action);
		case actionTypes.GET_LISTING_PDF_SUCCESS:
			return getListingPdfSuccess(state, action);
		case actionTypes.GET_LISTING_PDF_FAIL:
			return getListingPdfFail(state, action);
		case actionTypes.GET_LISTING_HISTORY_START:
			return getListingHistoryStart(state, action);
		case actionTypes.GET_LISTING_HISTORY_SUCCESS:
			return getListingHistorySuccess(state, action);
		case actionTypes.GET_LISTING_HISTORY_FAIL:
			return getListingHistoryFail(state, action);

		case actionTypes.GET_LISTING_ENTRY_START:
			return getListingEntryStart(state, action);
		case actionTypes.GET_LISTING_ENTRY_SUCCESS:
			return getListingEntrySuccess(state, action);
		case actionTypes.GET_LISTING_ENTRY_FAIL:
			return getListingEntryFail(state, action);

		default:
			return state;
	}
};

export default reducer;
