import { useState, useEffect } from "react";
import { capitalizeAll, removeWhitespace } from "shared/utility";
import {
    min1, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max20, max50, max100, max250, max500
} from "shared/validation";
import contactFields, { addressFields, otherAddressFields, partnerFields, translateBinary } from "./ContactsObj";
import moment from 'moment';
const validate = {
    min1, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max20, max50, max100, max250, max500
}

export const compareAddress = (beforeData, afterData, contact_id) => {
    let deletes = [];
    let updates = [];
    let adds = [];

    // OTHER ADDTYPE
    beforeData.forEach(bd => {
        // check Other address type
        if (bd.type === 'O' && afterData.other) {
            let exists = false;
            afterData.other.forEach(ad => {
                if (bd.id === ad.id) exists = true;
            })
            if (!exists) {
                deletes.push(bd);
            } else {
                updates.push(bd);
            }
        }
    })
    afterData.other.forEach(ad => {
        if (!ad.id) {
            const ad_copy = { ...ad };
            delete ad_copy.id;
            ad_copy.type = 'O';
            ad_copy.contact_id = contact_id;
            adds.push(ad_copy);
        }
    });

    // NOT OTHER ADDTYPE
    ['accounting', 'business', 'legal', 'mailing',
        'property_management'].forEach(addType => {
            if (afterData[addType].id) {
                updates.push(afterData[addType]);
            } else {
                const ad_copy = {
                    ...afterData[addType],
                    type: addType[0].toUpperCase(),
                    contact_id
                };
                delete ad_copy.id;
                adds.push(ad_copy);
            }
        })

    return {
        deletes,
        updates,
        adds
    }
}

export const comparePartners = (beforeData, afterData, contact_id) => {
    let deletes = [];
    let updates = [];
    let adds = [];
    // get for deletions
    beforeData.forEach(bd => {
        let exists = false;
        afterData.forEach(ad => {
            if (bd.id === ad.id) exists = true;
        })
        if (!exists) {
            deletes.push(bd);
        } else {
            updates.push(bd);
        }
    })
    afterData.forEach(ad => {
        if (!ad.id) {
            const new_ad = { ...ad, contact_id };
            delete new_ad.id;
            adds.push(new_ad);
        }
    })
    
    return {
        deletes,
        updates,
        adds
    }
}

export const contactSubTypeDecider = (contactType, vendorList) => {
    let options = [];
    if (contactType.indexOf('Property Owner') !== -1) {
        options.push('Property Owner - Lessor');
        options.push('Property Owner - Seller');
    }
    if (contactType.indexOf('Lessee') !== -1) {
        options.push('Lessee');
        options.push('Sub Lessee');
    }
    if (contactType.indexOf('Real Estate Agent') !== -1) {
        options.push('Real Estate Agent - Selling Agent');
        options.push('Real Estate Agent - Leasing Agent');
        options.push('Real Estate Agent - Tenant Rep');
    }
    if (contactType.indexOf('Real Estate Broker') !== -1) {
        options.push('Real Estate Broker - Selling Agent');
        options.push('Real Estate Broker - Leasing Agent');
        options.push('Real Estate Broker - Tenant Rep');
    }
    if (contactType.indexOf('Vendor') !== -1) {
        options = options.concat(vendorList.map(v => {
            return `Vendor - ${v}`;
        }));
    }
    return options;
}

export const returnSubtype = (subtypes, subtypeField) => {
    const subtypesArr = subtypes ? subtypes.split(',') : [];
    let subtype = '';
    subtypesArr.forEach(sa => {
        if (subtypeField !== 'Vendor') {
            if (sa.indexOf(subtypeField) !== -1) {
                subtype = sa;
            }
        } else {
            const exemptions = ['Property Owner', 'Lessee', 'Real Estate Agent', 'Real Estate Broker'];
            let found = false;
            exemptions.forEach(e => {
                if (sa.indexOf(e) !== -1) found = true;
            })
            if (!found) {
                subtype = sa;
            }
        }
    })
    return subtype;
}

export const cleanSubtypes = (dataObj) => {
    const newData = { ...dataObj };
    
    if (dataObj.contact_subtype && dataObj.contact_type) {
        const contact_types = dataObj.contact_type.split(',');
        const contact_subtypes = dataObj.contact_subtype.split(',');
        let new_subtypes = [];
        contact_types.forEach(ct => {
            contact_subtypes.forEach(st => {
                if (st.indexOf(ct) !== -1) {
                    if (new_subtypes.indexOf(st) === -1) new_subtypes.push(st);
                }
            })
        })
        newData.contact_subtype = new_subtypes.join(',');
        
    }
    return newData;
}

const getValidations = (f, val) => {
    if (!contactFields[f]) return 
    if (!contactFields[f].validations) return null;
    const validations = contactFields[f].validations.map(v => {
        if (!validate[v]) 
        return validate[v];
    });
    
    const errors = validations.map(v => {
        if (v) {
            const result = v(val);
            if (result) return result;
        }
        return null;
    }).filter(v => {
        if (!v) return false;
        return true;
    });
    if (errors.length > 0) return errors.join(', ');
    return '';
}

const getAddressValidations = (add) => {
    let errorsAll = [];
    if (add) {
        Object.keys(add).forEach(field => {
            if (!addressFields.fields[field]) return 
            if (!addressFields.fields[field].validations) return 
            const errors = addressFields.fields[field].validations.map(v => {
                
                const e = validate[v](add[field]);
                if (e) {
                    return `${field.toUpperCase().replace('_', ' ')} - ${e}`;
                }
                return '';
            }).filter(e => e ? true : false);
            errorsAll = errorsAll.concat(errors);
        })
    }
    if (errorsAll.length > 0) return errorsAll.join(', ');
    return '';
}

const getPartnerValidations = (pd) => {
    let errorsAll = [];
    if (pd) {
        Object.keys(pd).forEach(field => {
            if (field !== 'id' && field !== 'contact_id') {
                
                const errors = partnerFields[field].validations.map(v => {
                    return validate[v](pd[field]);
                }).filter(e => e ? true : false);
                errorsAll = errorsAll.concat(errors);
            }
        })
    }
    if (errorsAll.length > 0) return errorsAll.join(', ');
    return '';
}

export const useSaveContacts = (props) => {

    const [reviewObj, setReviewObj] = useState({
        errors: {},
        addressErrors: {},
        otherAddressErrors: {},
        partnersErrors: {}
    });

    const [finalErrorCount, setFinalErrorCount] = useState(0);

    const { dataObj, addressData, partnersData, madeChanges, action,
        currentAddressData, currentPartnersData, updateContact } = props;

    const TIME = 300000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (madeChanges) {
                
                const newReviewObj = {};

                const errors = {};
                let errorCount = 0;

                // ALL OTHERS
                const main_info_arr = ['first_name', 'middle_initial', 'last_name',
                    'email', 'birthdate', 'use_type', 'salutation',
                    'spouse_first_name', 'title', 'website_url',
                    'department'];

                const phone_numbers_arr = ['main_phone', 'home_phone', 'mobile_phone',
                    'fax', 'other_phone', 'assistant_phone'];

                const preferences_arr = ['do_not_call', 'looking_to_buy', 'opted_out_of_email',
                    'preferred_contact_method', 'price_range', 'preferred_contact_time',
                    'category'];

                const information_arr = ['last_attempted_contact_date', 'last_contacted_date',
                    'is_past_client', 'lead_source_category', 'lead_status',
                    'email_bounced_reason', 'lead_source', 'lead_source_detail',
                    'notes', 'referral_source', 'relocating_time_frame',
                    'assistant_email', 'assistant_name', 'company_name', 'company_represented'];

                const combined_arr = main_info_arr
                    .concat(phone_numbers_arr)
                    .concat(preferences_arr)
                    .concat(information_arr);

                combined_arr.forEach(f => {
                    errors[f] = getValidations(f, dataObj[f]);
                    if (errors[f]) errorCount++;
                });
                if (dataObj.birthdate) {
                    const isRealDate = moment(dataObj.birthdate, "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors.birthdate = 'Birthdate must be a valid MM/DD/YYYY formatted date';
                        errorCount++;
                    }
                }
                if (dataObj.last_attempted_contact_date) {
                    const isRealDate = moment(dataObj.last_attempted_contact_date, "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors.last_attempted_contact_date = 'Last attempted contact date must be a valid MM/DD/YYYY formatted date';
                        errorCount++;
                    }
                }
                if (dataObj.last_contacted_date) {
                    const isRealDate = moment(dataObj.last_contacted_date, "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors.last_contacted_date = 'Last contacted date must be a valid MM/DD/YYYY formatted date';
                        errorCount++;
                    }
                }
                newReviewObj.errors = errors;

                // ADDRESS
                const addressErrors = {};
                ['mailing', 'accounting', 'business', 'legal', 'property_management'].forEach(addType => {
                    const err = getAddressValidations(addressData[addType]);
                    if (err) {
                        addressErrors[addType] = err;
                        errorCount++;
                    }
                })
                newReviewObj.addressErrors = addressErrors;

                const otherAddressErrors = {};
                addressData.other.forEach((add, i) => {
                    const index = `add_${i}`;
                    otherAddressErrors[index] = getAddressValidations(add);
                    if (otherAddressErrors[index]) errorCount++;
                })
                newReviewObj.otherAddressErrors = otherAddressFields;

                // PARTNERS
                const partnerErrors = {};
                partnersData.forEach((pd, i) => {
                    
                    const err = getPartnerValidations(pd);
                    if (err) {
                        partnerErrors[`pd_${i}`] = err;
                        errorCount++;
                    }
                })
                newReviewObj.partnersErrors = partnerErrors;
                
                setReviewObj(newReviewObj);
                setFinalErrorCount(errorCount);
                if (errorCount === 0 && action === 'Edit') {
                    doUpdate();
                }
            }
        }, TIME);

        return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [madeChanges, dataObj]);

    const doUpdate = () => {
        const binariedData = translateBinary(dataObj);
        binariedData.first_name = capitalizeAll(binariedData.first_name);
        binariedData.last_name = capitalizeAll(binariedData.last_name);

        const partnersUpdateData = comparePartners(currentPartnersData,
            partnersData,
            dataObj.id);
        const addressUpdateData = compareAddress(currentAddressData,
            addressData,
            dataObj.id);

        updateContact(
            removeWhitespace(cleanSubtypes(binariedData)),
            partnersUpdateData,
            addressUpdateData
        );
    }

};

export const saveContactNow = (props) => {

    let reviewObj = {
        errors: {},
        addressErrors: {},
        otherAddressErrors: {},
        partnersErrors: {}
    }

    let finalErrorCount = 0;

    const { dataObj, addressData, partnersData, madeChanges, action,
        currentAddressData, currentPartnersData, updateContact } = props;

    

    const doUpdate = () => {
        const binariedData = translateBinary(dataObj);
        binariedData.first_name = capitalizeAll(binariedData.first_name);
        binariedData.last_name = capitalizeAll(binariedData.last_name);

        const partnersUpdateData = comparePartners(currentPartnersData,
            partnersData,
            dataObj.id);
        const addressUpdateData = compareAddress(currentAddressData,
            addressData,
            dataObj.id);

        updateContact(
            removeWhitespace(cleanSubtypes(binariedData)),
            partnersUpdateData,
            addressUpdateData
        );
    }

    if (madeChanges) {
        
        let newReviewObj = {};

        let errors = {};
        let errorCount = 0;

        // ALL OTHERS
        const main_info_arr = ['first_name', 'middle_initial', 'last_name',
            'email', 'birthdate', 'use_type', 'salutation',
            'spouse_first_name', 'title', 'website_url',
            'department'];

        const phone_numbers_arr = ['main_phone', 'home_phone', 'mobile_phone',
            'fax', 'other_phone', 'assistant_phone'];

        const preferences_arr = ['do_not_call', 'looking_to_buy', 'opted_out_of_email',
            'preferred_contact_method', 'price_range', 'preferred_contact_time',
            'category'];

        const information_arr = ['last_attempted_contact_date', 'last_contacted_date',
            'is_past_client', 'lead_source_category', 'lead_status',
            'email_bounced_reason', 'lead_source', 'lead_source_detail',
            'notes', 'referral_source', 'relocating_time_frame',
            'assistant_email', 'assistant_name', 'company_name', 'company_represented'];

        const combined_arr = main_info_arr
            .concat(phone_numbers_arr)
            .concat(preferences_arr)
            .concat(information_arr);

        combined_arr.forEach(f => {
            errors[f] = getValidations(f, dataObj[f]);
            if (errors[f]) errorCount++;
        });
        if (dataObj.birthdate) {
            const isRealDate = moment(dataObj.birthdate, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.birthdate = 'Birthdate must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }
        if (dataObj.last_attempted_contact_date) {
            const isRealDate = moment(dataObj.last_attempted_contact_date, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.last_attempted_contact_date = 'Last attempted contact date must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }
        if (dataObj.last_contacted_date) {
            const isRealDate = moment(dataObj.last_contacted_date, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.last_contacted_date = 'Last contacted date must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }
        newReviewObj.errors = errors;

        // ADDRESS
        const addressErrors = {};
        

        ['mailing', 'accounting', 'business', 'legal', 'property_management'].forEach(addType => {
            const err = getAddressValidations(addressData[addType]);
            if (err) {
                addressErrors[addType] = err;
                errorCount++;
            }
        })
        newReviewObj.addressErrors = addressErrors;

        const otherAddressErrors = {};
        addressData.other.forEach((add, i) => {
            const index = `add_${i}`;
            otherAddressErrors[index] = getAddressValidations(add);
            if (otherAddressErrors[index]) errorCount++;
        })
        newReviewObj.otherAddressErrors = otherAddressFields;

        // PARTNERS
        const partnerErrors = {};
        partnersData.forEach((pd, i) => {
            
            const err = getPartnerValidations(pd);
            if (err) {
                partnerErrors[`pd_${i}`] = err;
                errorCount++;
            }
        })
        newReviewObj.partnersErrors = partnerErrors;
        
        reviewObj = newReviewObj;
        finalErrorCount = errorCount;
        if (errorCount === 0 && action === 'Edit') {
            doUpdate();
        }
    }
};