import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import ERTLFormWizard from "containers/ERTL/FormWizard/ERTLFormWizard";
import ERTLFormTemplates from "containers/ERTL/FormTemplates/ERTLFormTemplates";
import ERTLList from "../../containers/ERTL/FormWizard/ERTLList";
import ERTLHistory from "containers/ERTL/ERTLEntries/ERTLHistory";
import ERTLPending from "containers/ERTL/ERTLEntries/ERTLPending";
import ERTLPendingRegular from "../../containers/ERTL/ERTLEntries/ERTLPendingRegular";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.jsx";
import * as actions from "../../store/actions";
import Notification from "components/Notification/Notification";
import Loader from "components/Loader/Loader";
import { getUser } from "shared/authValidation";
import { getParameterByName } from "shared/utility";
import ERTLPendingToReview from "../../containers/ERTL/ERTLEntries/ERTLPendingToReview";

class ERTLPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "Drafts",
            success: "",
            error: "",
            message: null,
            offset: 0,
            rows: 10,
            ertlData: null,
            origin: "Drafts",
            userPerms: {},
            prefill_property_id: null
        };
    }

    setERTLData = (ertlData) => {
        this.setState({ ertlData });
    };

    setTab = (tab) => {
        this.setState({ tab });
    };

    componentDidMount() {
        this.props.getEntriesByStatus(0, true);
        const user = getUser();
        if (user) {
            if (user.permObj) {
                this.setState({
                    userPerms: user.permObj,
                });
            }
        }
        const action = getParameterByName('p', window.location.href);
        if (action === 'new') {
            const permitted = checkPermissions("ERTL");
            if (permitted) {
                const property_id = getParameterByName('property_id', window.location.href);
                if (property_id) {
                    this.setState(
                        {
                            ertlData: null,
                            origin: "Drafts",
                            prefill_property_id: parseInt(property_id)
                        },
                        () => {
                            this.setTab(
                                "Form Fill Wizard"
                            );
                        }
                    );
                }
            }
        } else if (action === 'open') {
            const permitted = checkPermissions("ERTL");
            if (permitted) {
                const ertl_id = getParameterByName('ertl_id', window.location.href);
                if (ertl_id) {
                    this.props.getErtlEntry(ertl_id);
                }
            }
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.entryData !== this.props.entryData) {
            if (prevProps.entryData === null && this.props.entryData) {
                this.setState(
                    {
                        tab: "Form Fill Wizard",
                    },
                    () => {
                        this.setERTLData(
                            this.props.entryData
                        );
                        window.history.replaceState(null, null, '/ertl');
                    }
                );
            }
        }
        if (prevProps.success !== this.props.success) {
            if (!prevProps.success) {
                this.setState({ success: this.props.success });
            }
        }
        if (prevProps.error !== this.props.error) {
            if (!prevProps.error) {
                this.setState({ error: this.props.error });
            }
        }
        if (prevProps.entry_success !== this.props.entry_success) {
            if (
                prevProps.entry_success === null &&
                this.props.entry_success === "ENTRY Created"
            ) {
                // Close tab
                this.setState({ message: "ERTL Entry saved" });
                this.props.getEntriesByStatus(0, true);
                this.setTab("Drafts");
            }
            if (
                prevProps.entry_success === null &&
                this.props.entry_success === "ERTL Submitted for Review"
            ) {
                this.setState({ message: this.props.entry_success });
                this.props.getEntriesByStatus(0, true);
                this.setTab("Drafts");
            }
            if (
                this.props.entry_success === "OK" ||
                this.props.entry_success === "Successfully deleted Entry"
            ) {
                this.props.getEntriesByStatus(0, true);
            }
        }
    }

    setRows = (rows) => {
        this.setState({ rows });
    };

    setOffset = (offset) => {
        this.setState({ offset });
    };

    render() {
        const permitted = checkPermissions("ERTL");
        if (!permitted) {
            return <Redirect to="/dashboard" />;
        }

        const permissions = checkPermissionsActions("ERTL");
        console.log('ERTL permissions', permissions);

        const { tab } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                {permissions.fill_form && (
                                    <CardIcon
                                        color={
                                            tab === "Form Fill Wizard" ||
                                            tab === "Drafts"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("Drafts");
                                            this.props.getEntriesByStatus(
                                                0,
                                                true
                                            );
                                        }}
                                        className="hovered"
                                    >
                                        <strong>My Drafts</strong>
                                    </CardIcon>
                                )}
                                {(permissions.approve || permissions.approve_level_1) && (
                                    <CardIcon
                                        color={
                                            tab === "All Drafts"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("All Drafts");
                                            this.props.getEntriesByStatus(
                                                0,
                                                false
                                            );
                                        }}
                                        className="hovered"
                                    >
                                        <strong>All Drafts</strong>
                                    </CardIcon>
                                )}
                                {permissions.edit_form_template && (
                                    <CardIcon
                                        color={
                                            tab === "Form Templates"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("Form Templates");
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Templates</strong>
                                    </CardIcon>
                                )}
                                {
                                    <CardIcon
                                        color={
                                            tab === "Pending To Review"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("Pending To Review");
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Pending Owners Review</strong>
                                    </CardIcon>
                                }
                                {
                                    <CardIcon
                                        color={
                                            tab === "Pending"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("Pending");
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Pending My Approval</strong>
                                    </CardIcon>
                                }
                                {/* {
                                    <CardIcon
                                        color={
                                            tab === "Pending"
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab("Pending");
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Pending Final Approval</strong>
                                    </CardIcon>
                                } */}
                                <CardIcon
                                    color={
                                        tab === "History" ? "success" : "rose"
                                    }
                                    onClick={() => {
                                        this.setTab("History");
                                    }}
                                    className="hovered"
                                >
                                    <strong>History</strong>
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                {(tab === "Drafts" || tab === "All Drafts") &&
                                    this.props.statusEntries && (
                                        <div>
                                            <Button
                                                color="primary"
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            ertlData: null,
                                                            origin: "Drafts",
                                                        },
                                                        () => {
                                                            this.setTab(
                                                                "Form Fill Wizard"
                                                            );
                                                        }
                                                    );
                                                }}
                                            >
                                                ADD NEW ERTL ENTRY
                                            </Button>
                                            <hr />
                                            {this.props.statusEntries && (
                                                <div>
                                                    {this.props.statusEntries
                                                        .length === 0 && (
                                                        <div className="text-center mt-20">
                                                            <em>
                                                                No ERTL Drafts
                                                            </em>
                                                        </div>
                                                    )}
                                                    {this.props.statusEntries
                                                        .length > 0 && (
                                                        <ERTLList
                                                            setTab={this.setTab}
                                                            ertlList={
                                                                this.props
                                                                    .statusEntries
                                                            }
                                                            getTemplate={
                                                                this.props
                                                                    .getTemplate
                                                            }
                                                            deleteErtlEntry={
                                                                this.props
                                                                    .deleteErtlEntry
                                                            }
                                                            setERTLData={(
                                                                data
                                                            ) => {
                                                                this.setState(
                                                                    {
                                                                        tab: "Form Fill Wizard",
                                                                        origin: "Drafts",
                                                                    },
                                                                    () => {
                                                                        this.setERTLData(
                                                                            data
                                                                        );
                                                                    }
                                                                );
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            )}
                                        </div>
                                    )}

                                {tab === "Form Fill Wizard" && (
                                    <Dialog
                                        open={tab === "Form Fill Wizard"}
                                        onClose={() => {
                                            this.setTab("");
                                            this.setState({
                                                prefillPropertyID: null
                                            });
                                        }}
                                        keepMounted
                                        maxWidth={false}
                                        fullWidth={true}
                                        fullScreen={true}
                                    >
                                        <div className="dialog-wrapper">
                                            <ERTLFormWizard
                                                getTemplates={
                                                    this.props.getTemplates
                                                }
                                                templatesData={
                                                    this.props.templatesData
                                                }
                                                templateData={
                                                    this.props.templateData
                                                }
                                                templatesCount={
                                                    this.props.templatesCount
                                                }
                                                createTemplate={
                                                    this.props.createTemplate
                                                }
                                                getEntries={
                                                    this.props.getEntries
                                                }
                                                createEntry={
                                                    this.props.createEntry
                                                }
                                                updateEntry={
                                                    this.props.updateEntry
                                                }
                                                entriesData={
                                                    this.props.entriesData
                                                }
                                                getEntryPdf={
                                                    this.props.getEntryPdf
                                                }
                                                pdfData={this.props.pdfData}
                                                setParentTab={this.setTab}
                                                ertlData={this.state.ertlData}
                                                origin={this.state.origin}
                                                prefill_property_id={this.state.prefill_property_id}
                                                reset_prefill_property_id={() => {
                                                    this.setState({
                                                        prefill_property_id: null
                                                    })
                                                }}
                                                getErtlApprovers={this.props.getErtlApprovers}
                                                signReview={() => {
                                                    this.props.getErtlUrl(this.state.ertlData.id);
                                                    //setShowURL(true);
                                                }}
                                            />
                                        </div>
                                    </Dialog>
                                )}

                                {tab === "Form Templates" && (
                                    <ERTLFormTemplates
                                        getTemplates={this.props.getTemplates}
                                        createTemplate={
                                            this.props.createTemplate
                                        }
                                        templatesData={this.props.templatesData}
                                        templatesCount={
                                            this.props.templatesCount
                                        }
                                        rows={this.state.rows}
                                        offset={this.state.offset}
                                        setRows={this.setRows}
                                        setOffset={this.setOffset}
                                    />
                                )}
                                {tab === "History" && (
                                    <ERTLHistory permissions={permissions} />
                                )}
                                {tab === "Pending To Review" && (
                                    <ERTLPendingToReview
                                        getTemplate={this.props.getTemplate}
                                        setERTLData={(data) => {
                                            this.setState(
                                                {
                                                    tab: "Form Fill Wizard",
                                                    origin: "Agent Review",
                                                },
                                                () => {
                                                    this.setERTLData(data);
                                                }
                                            );
                                        }}
                                    />
                                )}
                                {tab === "Pending" && <ERTLPending 
                                        setERTLData={(data) => {
                                            this.setState(
                                                {
                                                    tab: "Form Fill Wizard",
                                                    origin: "approver",
                                                },
                                                () => {
                                                    this.setERTLData(data);
                                                }
                                            );
                                        }}
                                />}
                                {/* {tab === "Pending Regular" && (
                                    <ERTLPendingRegular />
                                )} */}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                {this.state.message && (
                    <Notification
                        open={this.state.message ? true : false}
                        close={() => {
                            this.setState({ message: null });
                        }}
                        message={this.state.message}
                    />
                )}

                {this.props.entry_loading && (
                    <Notification
                        open={this.props.entry_loading}
                        no_ok_button={true}
                        message={
                            <div className="mt-20">
                                <Loader />
                            </div>
                        }
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        entry_error: state.ertl_entries.error,
        entry_success: state.ertl_entries.success,
        entry_loading: state.ertl_entries.loadingCreate,
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,
        templatesData: state.ertl_templates.templatesData,
        templateData: state.ertl_templates.templateData,
        templatesCount: state.ertl_templates.templatesCount,
        entriesData: state.ertl_entries.entriesData,
        statusEntries: state.ertl_entries.statusEntries,
        pdfData: state.ertl_entries.pdfData,
        entryData: state.ertl_entries.entryData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTemplates: (data) => {
            dispatch(actions.getTemplates(data));
        },
        getTemplate: (id) => {
            dispatch(actions.getTemplate(id));
        },
        createTemplate: (data, title) => {
            dispatch(actions.createTemplate(data, title));
        },
        getEntries: (data) => {
            dispatch(actions.getEntries(data));
        },
        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getEntryPdf: async (data) => {
            dispatch(actions.getEntryPdf(data));
        },
        createEntry: (data, newTemplate) => {
            dispatch(actions.createEntry(data, newTemplate));
        },
        updateEntryStatus: (data) => {
            dispatch(actions.updateEntryStatus(data));
        },
        updateEntry: (data, newTemplate) => {
            dispatch(actions.updateEntryStatus(data, newTemplate));
        },
        deleteTemplate: (id) => {
            dispatch(actions.deleteTemplate(id));
        },
        deleteErtlEntry: (id) => {
            dispatch(actions.deleteErtlEntry(id));
        },
        getErtlEntry: (data) => {
            dispatch(actions.getErtlEntry(data));
        },
        getErtlApprovers: () => {
            dispatch(actions.getErtlApprovers());
        },
        getErtlUrl: (id) => {
            dispatch(actions.getErtlUrl(id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ERTLPage);
