import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';

const CompaniesAskModal = (props) => {
    const name = props.companyData ? `${props.companyData.first_name ? props.companyData.first_name : ''} 
        ${props.companyData.last_name ? props.companyData.last_name : ''}` : '';
    const companyID = props.companyData ? props.companyData.id : '';

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(props.success);

    useEffect(() => {
        if (prevSuccess === null && props.success === 'Successfully deleted company. ') {
            props.getCompanies();
            props.clearMessages();
            props.close();
            props.closeForm();
            return;
        }
    }, [props.success]);

    return (
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>
                    <div style={{ textAlign: 'center' }}>Are you sure you want to delete this Company?</div>
                </DialogTitle>

                <div style={{ textAlign: 'center', padding: 20 }}>
                    <div style={{ textAlign: 'center', marginBottom: 20 }}>
                        {name}
                    </div>

                    <Button
                        color="danger"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            if (companyID) props.deleteCompany(companyID);
                            props.setNotification('Successfully deleted company.');
                            props.close();
                            props.getCompanies();
                            props.setOpenedForm(null);         
                        }}
                    >
                        YES, DELETE
                    </Button>
                    <Button
                        color="white"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        CANCEL
                    </Button>
                </div>
            </Dialog>
    )
}

export default CompaniesAskModal;