import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Datetime from "react-datetime";
import Delete from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import listingFields, { initialListingData } from './ListingModel';
import { formatDate2, formatDateForBackend, capitalize } from "shared/utility";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListingModal from "./ListingModal";
import TextField from '@material-ui/core/TextField';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import moment from 'moment';
import {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
import Close from '@material-ui/icons/Close';
import FilesForm from './FilesForm';
import { useSaveListing } from './ListingLogic';
import { Tabs, Tab } from "@material-ui/core";
import { clone } from 'shared/utility';
import { getUser } from '../../../../shared/authValidation';
const validate = {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}

const getValidations = (f) => {
    return listingFields[f].validations.map(v => validate[v])
}
const ListingForm = (props) => {
    const { listingData, action, setTab, canEdit, canDelete, setMsgsForAdd, setUserType,
        createListing, updateListing, propertyID, getListings, deleteListing,
        error, success } = props;
    const [data, setData] = useState(initialListingData);
    const [formDataObj, setFormDataObj] = useState(null);
    const [openedSearchModal, setOpenedSearchModal] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [peopleType, setPeopleType] = useState(null);
    const [people, setPeople] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [madeChanges, setMadeChanges] = useState(false);
    const [curTab, setCurTab] = useState('Info');

    useSaveListing({
        madeChanges,
        updateListing,
        data,
        setMadeChanges
    })

    const updateDataObj = (field, value) => {
        const newData = clone(data);
        newData[field] = value;
        setData(newData);
        setMadeChanges(true);
    }

    const cleanData = (data) => {
        const newListingData = clone(data);
        ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
            if (newListingData[d]) {
                newListingData[d] = formatDateForBackend(newListingData[d]);
            } else {
                delete newListingData[d];
            }
        });
        if (newListingData.other_data) {
            newListingData.other_data = JSON.stringify(newListingData.other_data);
        }
        if (newListingData.extension_fee === '0') {
            newListingData.extension_fee_amount = ' ';
        }
        if(formDataObj){
            newListingData['formDataObj'] = formDataObj;
        }
        return newListingData;
    }

    const doUpdate = () => {
        updateListing(cleanData(data));
        setMadeChanges(false);
    }

    const doAdd = () => {
        const newData = clone(data);
        delete newData.id;
        newData.property_id = propertyID;
        createListing(cleanData(newData));
        setMadeChanges(false);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevError = usePrevious(error);
    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (prevError === null && error) {
            setErrorMsg(error);
        }
    }, [error]);

    useEffect(() => {
        if (prevSuccess !== success && success && prevSuccess !== undefined) {
            setSuccessMsg(success);
            if (action === 'Add') {
                setData(clone(initialListingData));
                getListings();
                setTab('List');
                if (props.user) {
                    if (props.user.type === 'A') {
                        if (setUserType) setUserType('own');
                    }
                }
            }
        }
    }, [success]);

    useEffect(() => {
        if (data) {
            let errors = '';
            ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
                const dFormatted = capitalize(d.toLowerCase().split('_').join(' '));
                if (data[d]) {
                    const isRealDate = moment(data[d], "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors += `${dFormatted} must be a valid MM/DD/YYYY formatted date. `;
                    }
                }
                if (d === 'start_date' || d === 'expiration_date') {
                    if (!data[d]) {
                        errors += `${dFormatted} is required. `;
                    }
                }
            });
            setErrorMsg(errors);
        }
    })

    useEffect(() => {
        if (listingData && (action === 'Edit' || action === 'View')) {
            const newListingData = clone(listingData);
            // reformat to MM/DD/YYYY
            ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
                if (newListingData[d]) {
                    newListingData[d] = formatDate2(newListingData[d]);
                }
            })
            if (newListingData.extension_fee === 0 || newListingData.extension_fee) {
                newListingData.extension_fee = newListingData.extension_fee.toString();
            }
            if (!newListingData.other_data) newListingData.other_data = {
                agents: []
            }
            if (typeof newListingData.other_data === 'string') {
                newListingData.other_data = JSON.parse(newListingData.other_data);
            }
            setData(newListingData);
        } else if (listingData && action === 'Add') {
            setData(clone(initialListingData));
            setSuccessMsg('');
        }
    }, [action]);

    useEffect(() => {
        setMsgsForAdd({ error: null, success: null });
        setSuccessMsg('');
    }, [])

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            props.setOpenedAsk(true);
        } else {
            getListings();
            setTab('List');
            if (props.user) {
                if (props.user.type === 'A') {
                    if (setUserType) setUserType('own');
                }
            }
        }
    }

    const getTabIndex = () => {
        if (curTab === 'Info') {
            return 0;
        }
        if (curTab === 'Files') {
            return 1;
        }
    }

    let show_illi_owned_message = false;
    const user_logged_in = getUser();
    if (user_logged_in) {
        if (user_logged_in.type) {
            if (user_logged_in.type === 'A') {
                if (props.listingData) {
                    if (props.listingData.property_user_id === 1) {
                        show_illi_owned_message = true;
                    }
                }
            }
        }
    }

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className="inline-block">
                        {<div
                            className="inline-block blue-text hoverable mr-20"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Tooltip title='Back to Listing List' placement='top'>
                                <ArrowBack />
                            </Tooltip>
                        </div>}
                        {action !== 'Add' && <div
                            className={`${action === 'View' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('View');
                            }}
                        >
                            <Tooltip
                                title="View Listing"
                                placement="top"
                            >
                                <Visibility />
                            </Tooltip>
                        </div>}
                        {(action !== 'Add' && canEdit) && <div
                            className={`${action === 'Edit' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('Edit');
                            }}
                        >
                            <Tooltip
                                title="Edit Listing"
                                placement="top"
                            >
                                <Edit />
                            </Tooltip>
                        </div>}
                        {action} Listing 
                            {props.property_name ? 
                            <span>for <strong>{`${props.property_name}`}</strong></span> : ''}
                            {data.name ? 
                            <span>for <strong>{`${data.name}`}</strong></span> : ''}
                    </h3>
                    {(action !== 'Add' && canDelete) && <span
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            float: 'right',
                            fontSize: 11,
                            fontWeight: 'bold'
                        }}
                        className="red-text hoverable"
                    >DELETE LISTING</span>}
                    {show_illi_owned_message && <div>
					    {<div><strong>illi Company Data</strong></div>}
                    </div>}
                </GridItem>
                {<Tabs
                    TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
                    style={{ marginBottom: 10 }}
                    value={getTabIndex()}
                >
                    <Tab
                        onClick={() => {
                            setCurTab('Info');
                        }}
                        style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={0}
                        label='Listing Info'
                        icon={<i className="fas fa-info-circle font-22"></i>}
                    />
                    {props.propertyAction !== 'Add' && <Tab
                        onClick={() => {
                            setCurTab('Files');
                        }}
                        style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={1}
                        label='Files'
                        icon={<i className="fas fa-file font-22"></i>}
                    />}
                </Tabs>}
            </GridContainer>
            {curTab === 'Info' &&
                <div className={action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
                    <GridContainer>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.start_date && <div className="tiny-label">Start Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Start Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('start_date', stringDate);;
                                        }
                                        updateDataObj('start_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.start_date ? data.start_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.expiration_date && <div className="tiny-label">Expiration Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Expiration Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('expiration_date', stringDate);;
                                        }
                                        updateDataObj('expiration_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.expiration_date ? data.expiration_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.expiration_alert_date && <div className="tiny-label">Expiration Alert Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Expiration Alert Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('expiration_alert_date', stringDate);;
                                        }
                                        updateDataObj('expiration_alert_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.expiration_alert_date ? data.expiration_alert_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.extension_date && <div className="tiny-label">Extension Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Extension Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('extension_date', stringDate);;
                                        }
                                        updateDataObj('extension_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.extension_date ? data.extension_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <CustomSelect
                                label={listingFields.type.label}
                                options={listingFields.type.options}
                                choose={(e, n) => {
                                    updateDataObj('type', e);
                                }}
                                default={data.type}
                            />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <hr />
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className="carded">
                                <h4>{data.other_data.agents.length} Agent(s)</h4>
                                {action !== 'View' && <Button color="primary"
                                    onClick={() => {
                                        setPeopleType('agents');
                                        setOpenedSearchModal(true);
                                    }}
                                ><i className="fa fa-plus"></i> Add Agent</Button>}
                                <List>
                                    {data.other_data.agents.map((a, i) => {
                                        return <ListItem
                                            button
                                            key={`agent-${i}`}
                                        >
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText inset primary={a.name} />
                                            <ListItemSecondaryAction>
                                                <Delete
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        updateDataObj('other_data', {
                                                            ...data.other_data,
                                                            agents: data.other_data.agents.filter(ag => {
                                                                if (ag.id === a.id) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            })
                                                        });
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })}
                                </List>
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={8} md={8}>
                            <div className="custom-textfield">
                                <TextField
                                    label="Fee Schedule"
                                    multiline
                                    rowsMax="4"
                                    value={data.fee_schedule}
                                    onChange={(e) => {
                                        updateDataObj('fee_schedule', e.target.value)
                                    }}
                                    className=""
                                    margin="normal"
                                    validate={getValidations('fee_schedule')}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div style={{ marginTop: 20 }}>
                                <CustomRadio
                                    label='Extension Fee?,Yes,No'
                                    options={['1', '0']}
                                    onChange={(e) => {
                                        updateDataObj('extension_fee', e.target.value);
                                    }}
                                    value={data.extension_fee}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            {data.extension_fee === '1' && <CustomInput
                                label="Extension Fee Amount"
                                value={data.extension_fee_amount ? data.extension_fee_amount : ''}
                                onChange={(e) => {
                                    updateDataObj('extension_fee_amount', e.target.value);
                                }}
                                validate={getValidations('extension_fee_amount')}
                            />}
                        </GridItem>
                        {!props.loadingListing && <GridItem xs={3} sm={12} md={12}>
                            {(errorMsg && !successMsg) && <div className="red-text" style={{ margin: 20 }}>{errorMsg}</div>}
                            {successMsg && <div className="green-text" style={{ margin: 20 }}>{successMsg}</div>}
                        </GridItem>}
                        {props.loadingListing && <Loader />}
                        {(!props.loadingListing && (action === 'Edit' || action === 'Add')) && <GridItem xs={12} sm={12} md={12}>
                            <Button color="primary"
                                disabled={errorMsg ? true : false}
                                onClick={() => {
                                    if (action === 'Edit') {
                                        doUpdate();
                                    } else {
                                        doAdd();
                                    }
                                }}
                                className="mr-20"
                            >{
                                    action === 'Edit' ? 'Update Listing' : 'Add New Listing'
                                }</Button>
                            <Button color="white"
                                onClick={() => {
                                    cancel();
                                }}
                            >CANCEL</Button>
                        </GridItem>}
                    </GridContainer>

                    {openedSearchModal && <CustomDialog
                        open={openedSearchModal}
                        close={() => { setOpenedSearchModal(false) }}
                        choose={(the_contact_data) => {
                            const newObj = clone(data.other_data);
                            newObj[peopleType] = data.other_data[peopleType].concat([{
                                id: the_contact_data.id,
                                name: the_contact_data.name
                            }])
                            updateDataObj('other_data', newObj);
                            setOpenedSearchModal(false)
                        }}
                        create_and_choose={(success_data) => {
                            const newObj = clone(data.other_data);
                            newObj[peopleType] = data.other_data[peopleType].concat([success_data])
                            updateDataObj('other_data', newObj);
                            setOpenedSearchModal(false)
                        }}
                        peopleType={peopleType}
                        title={`Select ${peopleType}`}
                        label={`Search ${peopleType}`}
                        current=""
                    />}
                </div>
            }

            {curTab === 'Files' &&
                <FilesForm
                    listingData={props.listingData}
                    fileList={props.fileList}
                    getCrmFileList={props.getCrmFileList}
                    getCrmFile={props.getCrmFile}
                    createCrmFile={props.createCrmFile}
                    deleteCrmFile={props.deleteCrmFile}
                    contactSuccess={props.contactSuccess}
                    contactError={props.contactError}
                    contactLoading={props.contactLoading}
                    downloadedFile={props.downloadedFile}
                    formDataObj={formDataObj}
                    setFormDataObj={setFormDataObj}
                    action={action}
                />
            }

            {openedModal && <div>
                <ListingModal
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    data={data}
                    setTab={setTab}
                    error={error}
                    success={success}
                    prevSuccess={prevSuccess}
                    prevError={prevError}
                    deleteListing={deleteListing}
                    listingID={data.id}
                    getListings={getListings}
                />
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
	const p = state.crm_properties;
	return {
		loadingListing: p.loadingListing,
	}
}

export default connect(mapStateToProps, null)(ListingForm);