
import jwt_decode from 'jwt-decode';

const ertlFields = {
    notes: {
        type: 'array',
        label: 'Notes'
    },
    template_name: {
        type: 'input',
        label: 'Template name',
    },
    final_approver: {
        type: 'input',
        label: 'Final Approver'
    },
    final_approver_email: {
        type: 'input',
        label: 'Final Approver Email'
    },
    final_approver_phone: {
        type: 'input',
        label: 'Final Approver Phone'
    },
    final_approver_title: {
        type: 'input',
        label: 'Final Approver Title'
    },
    // PROPERTY 
    property_address1: {
        type: 'input',
        label: 'Property Address 1'
    },
    property_address2: {
        type: 'input',
        label: 'Property Address 2'
    },
    property_city: {
        type: 'input',
        label: 'Property City'
    },
    property_state: {
        type: 'input',
        label: 'Property State',
    },
    property_zipcode: {
        type: 'input',
        label: 'Property Zipcode',
    },
    // PROPERTY OWNERS
    property_owners: {
        type: 'array'
    },
    owner_company_name: {
        type: 'input',
        label: 'Company'
    },
    owner_address1: {
        type: 'input',
        label: 'Address 1'
    },
    owner_address2: {
        type: 'input',
        label: 'Address 2'
    },
    owner_city: {
        type: 'input',
        label: 'City'
    },
    owner_state: {
        type: 'input',
        label: 'State'
    },
    owner_zipcode: {
        type: 'input',
        label: 'Zipcode'
    },
    // AGENTS 
    agents: {
        type: 'array'
    },
    // OTHER DATA 
    made_by_and_between: {
        type: 'input',
        label: 'Made by and Between',
        validations: ['required']
    },
    name_of_place: {
        type: 'input',
        label: 'Name of Place',
        validations: ['required']
    },
    total_current_vacant_space: {
        type: 'input',
        label: 'Total Current Vacant Space',
        validations: ['required']
    },
    total_sqft_footage: {
        type: 'number',
        label: 'Total Sq Footage',
        validations: ['required']
    },
    minimum_suite_size_available_for_lease: {
        type: 'input',
        label: 'Minimum Suite Size Available for Lease',
        validations: ['required']
    },
    initial_term_begin: {
        type: 'date',
        label: 'Initial Term of Agreement Begins',
        validations: ['required']
    },
    initial_term_expiration: {
        type: 'date',
        label: 'Initial Term Expiration',
        validations: ['required']
    },
    approved_lease_terms: {
        type: 'number',
        label: 'Approved Lease Terms',
        validations: ['required'],
        prefix: 'Rental Rate at least $ ',
        suffix: ' per SF per month.'
    },
    approved_lease_terms_lease_type: {
        type: 'select',
        label: 'Approved Lease Terms - Lease Type',
        validations: ['required'],
        options: ['Gross', 'Modified Gross', 'Full Service Gross', 'NNN'],
    }
}

const ertlFields2 = {
    template_name: {
        type: 'input',
        label: 'Template Name'
    },
    property_id: {
        type: 'input',
        label: 'Property ID',
        validations: []
    },
    contact_ids: {
        type: 'input',
        label: 'Contact IDs',
        validations: []
    },
    p1_date: {
        type: 'date',
        label: 'Date',
        validations: ['required']
    },
    p1_company_name: {
        type: 'input',
        label: 'Contact Company Name',
        validations: ['required']
    },
    p1_contact_address1: {
        type: 'input',
        label: 'Contact Address 1',
        validations: ['required']
    },
    p1_contact_address2: {
        type: 'input',
        label: 'Contact Address 2'
    },
    p1_contact_city: {
        type: 'input',
        label: 'Contact City',
        validations: ['required']
    },
    p1_contact_state: {
        type: 'input',
        label: 'Contact State',
        validations: ['required']
    },
    p1_contact_zipcode: {
        type: 'input',
        label: 'Contact Zipcode',
        validations: ['required']
    },
    p1_property_address1: {
        type: 'input',
        label: 'Property Address 1',
        validations: ['required']
    }, 
    p1_property_address2: {
        type: 'input',
        label: 'Property Address 2'
    },
    p1_property_city: {
        type: 'input',
        label: 'Property City',
        validations: ['required']
    },
    p1_property_state: {
        type: 'input',
        label: 'Property State'
    },
    p1_property_zipcode: {
        type: 'input',
        label: 'Property Zipcode',
        validations: ['required']
    },
    p1_dear_contact_names: {
        type: 'input',
        label: 'Contact Names',
        validations: ['required']
    },
    p1_contact_2_name: {
        type: 'input',
        label: 'Agent Name',
        validations: ['required']
    },
    p1_writer_phone: {
        type: 'input',
        label: 'Main Phone',
        validations: ['required']
    },
    p1_writer_email: {
        type: 'input',
        label: 'Main Email',
        validations: ['required']
    },
    p1_writer_name: {
        type: 'input',
        label: 'Main Name',
        validations: ['required']
    },
    p1_writer_title: {
        type: 'input',
        label: 'Main Title'
    },
    p1_cc: {
        type: 'input',
        label: 'Main CC - Contact Agent',
        validations: ['required']
    },


    p2_property_address1: {
        type: 'input',
        label: 'Property Address 1',
        validations: ['required']
    },
    p2_property_address2: {
        type: 'input',
        label: 'Property Address 2',
        validations: ['required']
    },
    p2_property_state: {
        type: 'input',
        label: 'Property State',
        validations: ['required']
    },
    p2_property_zipcode: {
        type: 'input',
        label: 'Property Zipcode',
        validations: ['required']
    },
    p2_date_as_of: {
        type: 'date', // default current date
        label: 'Effective Date',
        validations: ['required']
    },
    p2_by_and_between: {
        type: 'input',
        label: 'Made By and Between',
        validations: ['required']
    },
    p2_building_located_at_1: {
        type: 'input', // property street1,
        label: 'Property Street 1',
        validations: ['required']
    },
    p2_building_located_at_2: {
        type: 'input', // property street1,
        label: 'Property Street 2',
        validations: ['required']
    },
    p2_property_city: {
        type: 'input', // property city
        label: 'Property City',
        validations: ['required']
    },
    p2_property_state: {
        type: 'input', // property state
        label: 'Property State',
        validations: ['required']
    },
    p2_name_of_shopping_center: {
        type: 'input', //
        label: 'Name of Shopping Center',
        validations: ['required']
    },
    p2_property_sq_ft: {
        type: 'input',
        label: 'Total Sq. Footage of Shopping Center',
        validations: ['required']
    },
    p2_total_current_vacant_space: {
        type: 'input',
        label: 'Total Current Vacant Space at the Shopping Center',
        validations: ['required']
    },
    p2_minimum_suite_size: {
        type: 'input',
        label: 'Minimum Suite Size Available for Lease',
        validations: ['required']
    },
    p2_approved_lease_terms: {
        type: 'input',
        label: 'Approved Lease Terms',
        validations: ['required'],
        prefix: 'Rental Rate at least $ ',
        suffix: ' per SF per month.'
    },
    p2_approved_lease_type: {
        type: 'select',
        label: 'Approved Lease Terms - Lease Type',
        options: ['Gross', 'Modified Gross', 'Full Service', 'NNN'],
        validations: ['required']
    },
    p2_begin_date: {
        type: 'date',
        label: 'The initial term of this Agreement shall begin on',
        validations: ['required']
    },
    p2_end_date: {
        type: 'date',
        label: 'Initial term expiration',
        validations: ['required']
    },

    p3_owner_title: {
        type: 'input',
        label: 'Owner Title',
        validations: ['required']
    },  
    p3_owner_contact_names: {
        type: 'input',
        label: 'Owner Names (separate by comma)',
        validations: ['required']
    },
    p3_owner_contact_titles: {
        type: 'input',
        label: 'Owner Titles (separate by comma)',
        validations: ['required']
    },
    p3_owner_contact_address1: {
        type: 'input',
        label: 'Owner Address 1',
        validations: ['required']
    },
    p3_owner_contact_address2: {
        type: 'input',
        label: 'Owner Address 2',
        validations: ['required']
    },
    p3_owner_contact_city: {
        type: 'input',
        label: 'Owner City',
        validations: ['required']
    },
    p3_owner_contact_state: {
        type: 'input',
        label: 'Owner State',
        validations: ['required']
    },
    p3_owner_contact_zipcode: {
        type: 'input',
        label: 'Owner Zipcode',
        validations: ['required']
    },
    p3_owner_contact_tel: {
        type: 'input',
        label: 'Owner Telephone',
    },
    p3_owner_contact_emails: {
        type: 'input',
        label: 'Owner Emails (separate by comma)',
        validations: ['required']
    },
    p3_owner_contact_fax: {
        type: 'input',
        label: 'Owner Fax'
    }
}

export default ertlFields;

const token = localStorage.getItem('token');
let decoded = {};
if (token) {
    decoded = jwt_decode(token);
    
}

const defaults = {
    p1_writer_phone: '(818)514-2204',
    p1_writer_name: 'CRS Dev',
    p1_writer_email: 'dev@sap-developers.com',
    p1_writer_title: 'President',
    p1_contact_2_name: decoded.name ? decoded.name.split(' ')[0] : '',
    p1_cc: decoded.name ? decoded.name : '',
}
const initialERTLdat = {};
Object.keys(ertlFields).forEach(f => {
    if (ertlFields[f].type === 'array') {
        initialERTLdat[f] = [];
    } else {
        initialERTLdat[f] = '';
    }
})
export const initialERTLdata = initialERTLdat;

export const getStatusType = (status) => {
    const statusObj = {
        0: 'Draft', // My Drafts tab
        1: 'Pending Owner Approval', // Pending To Review tab
        2: 'Pending Docusign Signature', // Pending tab
        7: 'Pending Level 1 Approver', // Pending tab - Level 1 Approver
        8: 'Rejected by Level 1 Approver', // Pending tab -  Level 1 Approver
        3: 'Pending Final Approver Signature', // Pending Final Approver
        4: 'Approved by Final Approver/Signed', // Completed tab - Fully Executed
        9: 'Rejected by Final Approver',
        5: 'Owner Declined',
        6: 'Modified by Approver'
    }
    if (statusObj[status]) return statusObj[status];
    return null;
}