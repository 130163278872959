import React from 'react';
import ReactTable from 'react-table';
import { formatDate2, numberWithCommas } from 'shared/utility';

const OfferList = (props) => {
    const { offersData, setOfferData, setTab } = props;
    const data = offersData ? offersData.map((l, key) => { // map users to table rows
        return ({
            id: l.id,
            offer_amount: '$' + numberWithCommas(l.offer_amount),
            finance_contingencies: l.finance_contingencies,
            inspection_contingencies: l.finance_contingencies,
            offer_type: l.offer_type,
            offer_status: l.offer_status,
            date_created: formatDate2(l.date_created),
            date_modified: formatDate2(l.date_modified),
            offerData: l
        })
    }) : [];

    const columns = [
        {
            Header: "Offer ID #",
            accessor: "id" 
        },
        {
            Header: "Date Created",
            accessor: "date_created"
        },
        {
            Header: "Date Modified",
            accessor: "date_modified"
        },
        {
            Header: "Offer Amount",
            accessor: "offer_amount"
        },
        {
            Header: "Offer Type",
            accessor: "offer_type"
        },
        {
            Header: "Offer Status",
            accessor: "offer_status"
        },
    ]

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          
                          if (rowInfo) {
                            if (rowInfo.original) {
                              setOfferData(rowInfo.original.offerData)
                              if (props.canEdit) {
                                setTab('Edit');
                              } else {
                                setTab('View');
                              }
                            }
                          }
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
            />
        </div>
    )
}

export default OfferList;