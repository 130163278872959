import React from 'react';
import { useCallback } from 'react';
import { get } from 'lodash';
import { clone } from '../../../shared/utility';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';

import { checkOutsideBrokerOrReferralFirmFields } from '../RSheetsLogic';
import Brokers from '../CommonCustomElements/Brokers';

export const LESSOR_OUTSIDE_BROKER_FIELDS = [
	'lessor_outside_broker_company_name',
	'lessor_outside_broker_name',
	'lessor_outside_broker_lastname',
	'lessor_outside_broker_address1',
	'lessor_outside_broker_city',
	'lessor_outside_broker_state',
	'lessor_outside_broker_zip',
	'lessor_outside_broker_contact',
	'lessor_outside_broker_fax',
	'lessor_outside_broker_main_phone',
	'lessor_outside_broker_email',
	'lessor_outside_broker_taxid',
	'lessor_outside_broker_w9',
	'lessor_outside_broker_w9_upload',
	'lessor_outside_broker_commission',
];
export const LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS = ['lessor_outside_broker_fax'];

export const LESSOR_REFERRAL_FIRM_FIELDS = [
	'referral_firm_company_name',
	'referral_firm_name',
	'referral_firm_lastname',
	'referral_firm_address1',
	'referral_firm_city',
	'referral_firm_state',
	'referral_firm_zip',
	'referral_firm_contact',
	'referral_firm_fax',
	'referral_firm_main_phone',
	'referral_firm_email',
	'referral_firm_taxid',
	'referral_firm_w9',
	'referral_firm_w9_upload',
	'referral_firm_fee_amount_choice',
	'referral_firm_fee_amount',
	'referral_firm_fee_amount_percentage'
];
export const LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS = ['referral_firm_fax', 'referral_firm_fee_amount_choice']

const Step5 = (props) => {
	const {
		setQueryFor,
		getContacts,
		setOpenedContactModal,
		renderElement,
		data,
		setData
	} = props;

	const areOutsideBrokerFieldsRequired = checkOutsideBrokerOrReferralFirmFields(data, LESSOR_OUTSIDE_BROKER_FIELDS, LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS);
	const areReferralFirmFieldsRequired = checkOutsideBrokerOrReferralFirmFields(data, LESSOR_REFERRAL_FIRM_FIELDS, LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS);

	const renderOutsideBrokerFields = useCallback(() => {
		if (!data.lessor_outside_broker_id) {
            return null;
        }
		return LESSOR_OUTSIDE_BROKER_FIELDS.map((lfield) => {
			const extraValidations = (!LESSOR_OUTSIDE_BROKE_NON_REQUIRED_FIELDS.includes(lfield) && areOutsideBrokerFieldsRequired) ? ['required'] : []
			if (lfield === 'lessor_outside_broker_w9_upload') {
				if (data.outside_broker_w9 === 'Yes') {
					return (
						<GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
							{renderElement(lfield)}
						</GridItem>
					);
				} else {
					return null;
				}
			}
			let labelOverride = null;
			if (data.lessor_outside_broker_id) {
				// is a company
				if (data.lessor_outside_broker_id.toString().indexOf('c') !== -1) {
					if (lfield === 'lessor_outside_broker_lastname') return null;
					if (lfield === 'lessor_outside_broker_name') {
						labelOverride = 'Company Name';
					}
				}
			}
			return (
				<GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
					{renderElement(lfield, null, null, null, labelOverride, extraValidations)}
				</GridItem>
			);
		})
	}, [data])

	const renderReferralFirmFields = useCallback(() => {
		return LESSOR_REFERRAL_FIRM_FIELDS.map((lfield) => {
			const extraValidations = (!LESSOR_REFERRAL_FIRM_NON_REQUIRED_FIELDS.includes(lfield) && areReferralFirmFieldsRequired) ? ['required'] : []

			if (data.referral_firm_id) {
				// is a company
				if (data.referral_firm_id.toString().indexOf("c") !== -1 && get(data, 'referral_firm_company_only', false)) {
                    if (lfield === 'referral_firm_name') return null;
                    if (lfield === 'referral_firm_lastname') return null;
                }
            }

			if (!lfield) {
				return <GridItem xs={12} sm={3} md={3}></GridItem>;
			}
			if (data.referral_firm_w9 !== 'Yes') {
				if (lfield === 'referral_firm_w9_upload') return null;
			}
			return (
				<GridItem xs={12} sm={3} md={3} key={`lfield-${lfield}`}>
					{renderElement(lfield, null, null, null, null, extraValidations)}
				</GridItem>
			);
		})
	}, [data])

	return (
		<GridContainer>
			{/* Lessor */}
			<GridItem xs={12} sm={12} md={12}>
				<Button
					color='primary'
					onClick={() => {
						setQueryFor('lessors');
						getContacts({
							names_only: 'All',
						});
						setOpenedContactModal(true);
					}}
				>
					ADD SUB-LESSOR
				</Button>
			</GridItem>
			{data.lessors.length === 0 && <div className="red-text" style={{margin:20}}>Sub Lessor Required</div>}
			{renderElement('lessors')}

			<GridItem xs={12} sm={12} md={12}>
				<hr />
			</GridItem>
			<GridItem xs={12} sm={12} md={12}>
				{renderElement('lessor_contacts', undefined, undefined, undefined, undefined, undefined, undefined, true)}
				<hr />
			</GridItem>

			{<Brokers 
				data={data}
				setData={setData}
				outside_brokers_field_name="lessor_outside_broker" 
				outside_brokers_field_label="SUB-LESSOR"
			/>}

			<GridItem xs={12} sm={12} md={12}>
				<hr />
				<h4>
					<strong>SUB-LESSOR REFERRAL</strong>
				</h4>
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				{!data.referral_firm_id && <Button
					color='primary'
					onClick={() => {
						setQueryFor('referral_firm');
						props.getContacts({
							names_only: 'All',
						});
						setOpenedContactModal(true);
					}}
				>
					SELECT REFERRAL
				</Button>}
				{data.referral_firm_id && (!data?.lessor_referrals || !data?.lessor_referrals.length) && <Button
					color='success'
					onClick={() => {
						const newData = clone(data);
						['address1','city','company_name','contact','email','fax','fee_amount',
						'fee_amount_choice','fee_amount_percentage','lastname',
						'main_phone','name','state','taxid','w9','w9_upload','zip','id'].forEach(fieldy => {
							const field = `referral_firm_${fieldy}`;
							newData[field] = '';
						})
						setData(newData);
					}}
				>
					REMOVE REFERRAL
				</Button>}
			</GridItem>

			{data.referral_firm_id ? renderReferralFirmFields() : null}
		</GridContainer>
	);
};

export default Step5;
