import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import MeetingList from "./MeetingList";
import MeetingForm from "./MeetingForm";

import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

const Meeting = (props) => {
    const [tab, setTab] = useState('List');
    const [meetingData, setMeetingData] = useState(null);
    const [msgsForAdd, setMsgsForAdd] = useState({error:null,success:null});
    const [openedAsk, setOpenedAsk] = useState(false);

    const { meetingsData, dataObj, getMeetings, canEdit, canDelete, contactsData, getContacts,
        createMeeting, updateMeeting, deleteMeeting, error, success,
        propertyAction, setMeetingsForAdd, meetingsForAdd } = props;

    const addForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newData = { ...data, id:meetingsForAdd.length };
        const newList = [ ...meetingsForAdd, newData ];
        
        setMeetingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Added Meeting'});
    }
    const updateForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...meetingsForAdd ].map((l,i) => {
            if (i === data.id) return data;
            return l;
        })
        
        setMeetingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Updated Meeting'});
    }
    const deleteForAdd = (index) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...meetingsForAdd ].filter((f,i) => {
            if (i === index) return false;
            return true;
        })
        setMeetingsForAdd(newList);
        setMsgsForAdd({error:null,success:'Deleted Meeting'});
    }

    useEffect(() => {
        if (dataObj) {
            if (dataObj.id) {
                getMeetings(dataObj.id);
            }
        }
    }, [])

    const showForm = (tab === 'View' || tab === 'Add' || tab === 'Edit');

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {/* <Button
                    color={(tab === 'List' || tab === 'Edit' || tab === 'View') ? 'success' : 'primary'}
                    onClick={() => {
                        getMeetings(dataObj.id);
                        setTab('List');
                    }}
                    style={{marginRight:10}}
                >
                    View / Edit Meetings
                </Button> */}
                {props.propertyAction !== 'View' && <Button
                    color={tab === 'Add' ? 'success' : 'primary'}
                    onClick={() => {
                        setTab('Add');
                    }}
                >
                    <i className="fa fa-plus"></i> Add Meeting
                </Button>}
                <hr />
                {tab === 'List' && <MeetingList
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    meetingsData={propertyAction !== 'Add' ? meetingsData : meetingsForAdd}
                    meetingData={meetingData}
                    setMeetingData={setMeetingData}
                    setTab={setTab}
                />}
                {showForm &&
            <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
              <div className="dialog-wrapper">
                <MeetingForm
                    meetingData={meetingData}
                    action={tab}
                    setTab={setTab}
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    canDelete={propertyAction !== 'Add' ? canDelete : true}
                    getContacts={getContacts}
                    contactsData={contactsData}
                    setMsgsForAdd={setMsgsForAdd}

                    updateMeeting={propertyAction !== 'Add' ? updateMeeting : updateForAdd}
                    createMeeting={propertyAction !== 'Add' ? createMeeting : addForAdd}
                    deleteMeeting={propertyAction !== 'Add' ? deleteMeeting : deleteForAdd}
                    getMeetings={() => {
                        if (propertyAction !== 'Add') {
                            getMeetings(dataObj.id);
                        }
                    }}
                    propertyID={dataObj.id}
                    error={propertyAction !== 'Add' ? error : msgsForAdd.error}
                    success={propertyAction !== 'Add' ? success : msgsForAdd.success}
                    contactLoading={props.contactLoading}

                    setOpenedAsk={setOpenedAsk}
                    showCloseBtn={true}

                    propertyAction={propertyAction}
                />
                </div>
            </Dialog>
            }
            </GridItem>
            {openedAsk && <CustomDialogClose
                        ok_go={() => {
                            setTab('List');
                        }}
                        open={openedAsk}
                        close={() => {
                            setOpenedAsk(false);
                        }}
                    />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        error: p.error,
        success: p.success,
        meetingsData: p.meetingsData,
        meetingData: p.meetingData,
        loading: p.loading,
        contactsData: c.contactsData,
        contactLoading: c.loading,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getMeetings: (property_id) => {
          dispatch(actions.getMeetings(property_id));
      },
      getMeeting: (id) => {
          dispatch(actions.getMeeting(id));
      },
      createMeeting: (data) => {
          dispatch(actions.createMeeting(data));
      },
      updateMeeting: (data) => {
          dispatch(actions.updateMeeting(data));
      },
      deleteMeeting: (id) => {
          dispatch(actions.deleteMeeting(id));
      },
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Meeting);
