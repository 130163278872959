import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import TRTemplatesList from "./TRTemplatesList";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import TRTemplatesForm from "./TRTemplatesForm";
import Notification from "components/Notification/Notification";

const TRTemplates = props => {
    const [entryData, setEntryData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [openedForm, setOpenedForm] = useState(null);
    const [formAction, setFormAction] = useState(null);
    const [notification, setNotification] = useState(null);
    const [startUpdate, setStartUpdate] = useState(false);

    const reload = () => {
        props.getTrTemplates();
    }

    useEffect(() => {
        if (props.templateData) {
            setEntryData(props.templateData);
            setFormData(props.templateData.data);
        }
    }, [props.templateData]);

    useEffect(() => {
        console.log('starting update', props.success_update, startUpdate);
        if (props.success_update && startUpdate) {
            reload();
            setStartUpdate(false);
        }
    }, [props.success_update, startUpdate]);

    useEffect(() => {
        reload();
    }, []);

    return <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button color="primary"
                    onClick={() => {
                        setOpenedForm(true);
                        setFormAction('add');
                        setEntryData(null);
                        setFormData(null);
                    }}
                >ADD NEW</Button>
                {<TRTemplatesList 
                    data={props.data}
                    deleteTrTemplate={props.deleteTrTemplate}
                    updateTrTemplate={(obj) => {
                        setStartUpdate(true);
                        props.updateTrTemplate(obj)
                    }}
                    openEntry={(entryData) => {
                        setEntryData(entryData);
                        setFormData(entryData.data);
                        setOpenedForm(true);
                        setFormAction('edit');
                    }}
                    reload={reload}
                    success_delete={props.success_delete}
                    error_delete={props.error_delete}
                    setNotification={setNotification}
                />}
                
            </GridItem>
        </GridContainer>

        {openedForm && <TRTemplatesForm 
            entryData={entryData}
            formData={formData}
            formAction={formAction}
            close={() => {
                setOpenedForm(false);
                reload();
            }}
        />}

        {notification && <Notification 
            open={notification ? true : false}
            close={() => {
                setNotification(null);
            }}
            message={notification}
        />}
    </div>
}

const mapStateToProps = state => {
    const tr = state.tr_templates;
	return {
        templateData: tr.templateData,
        data: tr.templates,
        success_delete: tr.success_delete,
        error_delete: tr.error_delete,
        success_update: tr.success_update,
        error_update: tr.error_update,
        success: tr.success,
        error: tr.error
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getTrTemplates: (data) => {
            dispatch(actions.getTrTemplates(data));
        },
        createTrTemplate: (data) => {
            dispatch(actions.createTrTemplate(data));
        },
        deleteTrTemplate: (data) => {
            dispatch(actions.deleteTrTemplate(data));
        },
        updateTrTemplate: (data) => {
            dispatch(actions.updateTrTemplate(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TRTemplates);