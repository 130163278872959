import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import * as actions from '../../store/actions';
import Table from "../../components/Table/Table";
import { IconButton } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import CustomSimpleDialog from "../../components/CustomDialog/CustomSimpleDialog";
import NotificationAsk from "../../components/Notification/NotificationAsk";
import Loader from "../../components/Loader/Loader";
import Notification from "../../components/Notification/Notification";
import CustomInput from "../../components/CustomInput/CustomInputValidate";
import { clone, formatDollar, handleError, parseFloatString, round2dec } from "../../shared/utility";
import axios from "store/axios/axios-gci";
import GCIHistory from "../../containers/GCI/GCIHistory";

class GCIPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 'gci',
      openedDeleteModal: null,
      openedDeleteModal2: null,
      editMode: null,
      addMode: false,
      notification: '',
      entries: [],
      newObj: [],

      percentage: '',
      threshold: '',
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error && this.props.error) {
        this.setState({
            notification: this.props.error
        })
    }
    if (prevProps.entries !== this.props.entries) {
        this.setState({
            entries: this.props.entries ? clone(this.props.entries) : []
        })
    }
  }

  componentDidMount() {
    this.props.getGciEntries();
  }

  validate = (obj) => {
    const { index } = obj;
    let errors = [];
    if (obj.mode === 'add') {
        // Add mode 
        const last_threshold = this.props.entries[this.props.entries.length - 1] ? 
            parseFloatString(this.props.entries[this.props.entries.length - 1].threshold) 
        : 
            null;
        const last_percentage = this.props.entries[this.props.entries.length - 1] ? 
            parseFloatString(this.props.entries[this.props.entries.length - 1].percentage) 
        : 
            null;
        if (last_threshold >= parseFloatString(this.state.threshold)) {
            errors.push('Threshold cannot be less than or equal to the threshold entry before this entry');
        } 
        if (last_percentage >= parseFloatString(this.state.percentage)) {
            errors.push('Percentage cannot be less than or equal to the percentage entry before this entry');
        } 
    } else {
        // Edit mode 
        const last_threshold = this.props.entries[index - 1] ? 
            parseFloatString(this.props.entries[index - 1].threshold) 
        : 
            null;
        const last_percentage = this.props.entries[index - 1] ? 
            parseFloatString(this.props.entries[index - 1].percentage) 
        : 
            null;

        const next_threshold = this.props.entries[index + 1] ? 
            parseFloatString(this.props.entries[index + 1].threshold) 
        : 
            null;
        const next_percentage = this.props.entries[index + 1] ? 
            parseFloatString(this.props.entries[index + 1].percentage) 
        : 
            null;
        if (index !== 0) {
            if (last_threshold >= parseFloatString(this.state.threshold)) {
                errors.push('Threshold cannot be less than or equal to the threshold entry before this entry');
            } 
        }
        if (last_percentage >= parseFloatString(this.state.percentage)) {
            errors.push('Percentage cannot be less than or equal to the percentage entry before this entry');
        } 
        if (next_threshold) {
            if (next_threshold <= parseFloatString(this.state.threshold)) {
                errors.push('Threshold cannot be less than or equal to the threshold entry after this entry');
            }
        }
        if (next_percentage) {
            if (next_percentage <= parseFloatString(this.state.percentage)) {
                errors.push('Percentage cannot be less than or equal to the percentage entry after this entry');
            }
        }
    }
    const error = errors.join(', ');
    return error;
  }

  setNotification = (msg) => {
    this.setState({
        notification: msg
    })
  }

  getUsers = async (gci_id) => {
    try {
        const results = await axios.get('/users?id='+gci_id); 
        if (typeof results.data === 'object') {
            return results.data;
        }
    } catch(e) {
        handleError(e);
    }
    return [];
  }

  render() {
    const permitted = checkPermissions("GCI");
    if (!permitted) {
        return <Redirect to="/dashboard" />;
    }

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>


                <CardIcon
                  color={this.state.tab === 'gci' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'gci'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>Entries</strong>
                </CardIcon>

                <CardIcon
                  color={this.state.tab === 'history' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({tab:'history'})
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>History</strong>
                </CardIcon>

              </CardHeader>
              <CardBody>

                  {this.state.tab === 'gci' && <GridContainer>
                    <GridItem xs={12} sm={8} md={8}>
                        <h2>Gross Commission Indicator</h2>
                        {this.props.loading && <Loader center={true} />}
                        {!this.props.loading && <Table
                            tableHeaderColor="primary"
                            tableHead={[
                                    'Commission Levels',
                                    'Percentage',
                                    'Threshold',
                                    'Actions'
                                ]}
                                tableData={this.state.entries.map((e,index) => {
                                    if (this.state.editMode) {
                                        if (this.state.editMode.id === e.id) {
                                            
                                            return [
                                                'Level ' + e.commission_level,
                                                <CustomInput 
                                                    label="Percentage"
                                                    value={this.state.percentage}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            percentage: e.target.value
                                                        })
                                                    }}
                                                    isDollar={true}
                                                    noDollarSign={true}
                                                    endAdornment="%"
                                                    autoFocus={true}
                                                />,
                                                index !== 0 && <CustomInput 
                                                    label="Threshold"
                                                    value={this.state.threshold}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            threshold: e.target.value
                                                        })
                                                    }}
                                                    isDollar={true}
                                                    noDollarSign={true}
                                                    startAdornment="$"
                                                />,
                                                <div>
                                                    <IconButton
                                                        style={{marginRight:20}}
                                                        disabled={((!this.state.threshold || !this.state.percentage) && index !== 0) ? true : false}
                                                        onClick={async () => {
                                                            const errorMessage = this.validate({
                                                                threshold: this.state.threshold,
                                                                percentage: this.state.percentage,
                                                                mode: 'edit',
                                                                index
                                                            });
                                                            if (errorMessage) {
                                                                return this.setState({notification:errorMessage});
                                                            }
                                                            const newObj = {
                                                                id: this.state.editMode.id,
                                                                commission_level: this.state.editMode.commission_level,
                                                                threshold: `${formatDollar(parseFloatString(this.state.threshold))}`,
                                                                percentage: `${round2dec(parseFloatString(this.state.percentage))}%`
                                                            };
                                                            const users = await this.getUsers(e.id);
                                                            if (users.length > 0) {
                                                                return this.setState({
                                                                    openedAskModal: newObj
                                                                });
                                                            }
                                                            this.setState({
                                                                editMode: null,
                                                                threshold: '',
                                                                percentage: ''
                                                            })
                                                            this.props.updateGciEntry(newObj)
                                                        }}
                                                    >
                                                        <i className="fa fa-check"></i>
                                                    </IconButton>
                                                    <IconButton
                                                        style={{marginRight:20}}
                                                        onClick={() => {
                                                            this.setState({
                                                                editMode: null,
                                                                threshold: '',
                                                                percentage: ''
                                                            })
                                                        }}
                                                    >
                                                        <i className="fa fa-times"></i>
                                                    </IconButton>
                                                </div>
                                            ]
                                        }
                                    }
                                    return [
                                        'Level ' + e.commission_level,
                                        e.percentage,
                                        e.threshold,
                                        <div>
                                            <IconButton
                                                style={{marginRight:20}}
                                                onClick={() => {
                                                    this.setState({
                                                        editMode: e,
                                                        threshold: parseFloatString(e.threshold),
                                                        percentage: parseFloatString(e.percentage)
                                                    })
                                                }}
                                            >
                                                <i className="fa fa-edit"></i>
                                            </IconButton>
                                            {index === (this.props.entries.length - 1) && <IconButton
                                                onClick={async () => {
                                                    const users = await this.getUsers(e.id);
                                                    if (users.length > 0) {
                                                        return this.setState({
                                                            openedDeleteModal2: e
                                                        })
                                                    }
                                                    this.setState({
                                                        openedDeleteModal: e
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-trash red-text"></i>
                                            </IconButton>}
                                        </div>
                                    ]
                                }).concat([this.state.newObj])}
                            />}
                            <Button
                                style={{marginBottom:20}}
                                color="rose"
                                onClick={() => {
                                    this.setState({
                                        percentage: '',
                                        threshold: '',
                                        newObj: [
                                            'Level ' + (this.state.entries.length + 1),
                                                <CustomInput 
                                                    label="Percentage"
                                                    value={this.state.percentage}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            percentage: e.target.value
                                                        })
                                                    }}
                                                    isDollar={true}
                                                    noDollarSign={true}
                                                    endAdornment="%"
                                                />,
                                                <CustomInput 
                                                    label="Threshold"
                                                    value={this.state.threshold}
                                                    onChange={(e) => {
                                                        this.setState({
                                                            threshold: e.target.value
                                                        })
                                                    }}
                                                    isDollar={true}
                                                    noDollarSign={true}
                                                    startAdornment="$"
                                                />,
                                                <div>
                                                    <IconButton
                                                        style={{marginRight:20}}
                                                        onClick={() => {
                                                            if (!this.state.threshold || !this.state.percentage) {
                                                                return this.setNotification('Threshold and Percentage are required')
                                                            }
                                                            const errorMessage = this.validate({
                                                                threshold: this.state.threshold,
                                                                percentage: this.state.percentage,
                                                                mode: 'add'
                                                            });
                                                            if (!errorMessage) {
                                                                this.setState({
                                                                    newObj: [],
                                                                    threshold: '',
                                                                    percentage: ''
                                                                })
                                                                const newObj = {
                                                                    commission_level: this.state.entries.length + 1,
                                                                    threshold: `${formatDollar(parseFloatString(this.state.threshold))}`,
                                                                    percentage: `${round2dec(parseFloatString(this.state.percentage))}%`
                                                                };
                                                                this.props.createGciEntry(newObj)
                                                            } else {
                                                                this.setState({
                                                                    notification: errorMessage
                                                                })
                                                            }
                                                        }}
                                                    >
                                                        <i className="fa fa-check"></i>
                                                    </IconButton>
                                                    <IconButton
                                                        style={{marginRight:20}}
                                                        onClick={() => {
                                                            this.setState({
                                                                newObj: [],
                                                                threshold: '',
                                                                percentage: ''
                                                            })
                                                        }}
                                                    >
                                                        <i className="fa fa-times"></i>
                                                    </IconButton>
                                                </div>
                                            ]
                                    })
                                }}
                            ><i className="fa fa-plus"></i> ADD LEVEL</Button>
                    </GridItem>
                  </GridContainer>}

                  {this.state.tab === 'history' && <div>
                    <GCIHistory />
                  </div>}
                  
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

        {this.state.openedDeleteModal && <div>
            <NotificationAsk
                title="Are you sure?" 
                message="Do you want to delete this GCI Entry?"
                open={true}
                close={() => {
                    this.setState({
                        openedDeleteModal: null
                    });
                }}
                success={() => {
                    this.props.deleteGciEntry(this.state.openedDeleteModal);
                    this.setState({
                        openedDeleteModal: null
                    });
                }}
            />
        </div>}

        {this.state.openedDeleteModal2 && <div>
            <NotificationAsk
                title="Are you sure?" 
                message="Do you want to delete this GCI Entry? Users have been assigned to this Level. By Deleting this level, all users assigned to this level will automatically be downgraded one level"
                open={true}
                close={() => {
                    this.setState({
                        openedDeleteModal2: null
                    });
                }}
                success={() => {
                    this.props.deleteGciEntry(this.state.openedDeleteModal2);
                    this.setState({
                        openedDeleteModal2: null
                    });
                }}
            />
        </div>}

        {this.state.openedAskModal && <div>
            <NotificationAsk
                title="Are you sure?" 
                message="All Users Set with this level will be adjusted, continue?"
                open={true}
                close={() => {
                    this.setState({
                        openedAskModal: null
                    });
                }}
                success={() => {
                    this.props.updateGciEntry(this.state.openedAskModal);
                    this.setState({
                        editMode: null,
                        threshold: '',
                        percentage: '',
                        openedAskModal: null
                    })
                }}
            />
        </div>}

        {this.state.notification && <Notification 
            open={true}
            close={() => {
                this.setState({
                    notification: ''
                })
            }}
            message={this.state.notification}
        />}
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        entries: state.gci.entries,
        error: state.gci.error,
        success: state.gci.success,
        loading: state.gci.loading
    }
}

const mapDispatchToProps = dispatch => {
  return {
    getGciEntries: (data) => {
        dispatch(actions.getGciEntries(data));
    },
    createGciEntry: (data) => {
        dispatch(actions.createGciEntry(data));
    },
    updateGciEntry: (data) => {
        dispatch(actions.updateGciEntry(data));
    },
    deleteGciEntry: (data) => {
        dispatch(actions.deleteGciEntry(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(GCIPage);
