import * as actionTypes from './actionTypes';


// GET_DIALPAD_CONTACTS 

export const getDialpadContacts = (data) => {
    return {
        type: actionTypes.GET_DIALPAD_CONTACTS,
        data
    }
}

export const getDialpadContactsStart = () => {
    return {
        type: actionTypes.GET_DIALPAD_CONTACTS_START
    }
}

export const getDialpadContactsSuccess = (response) => {
    return {
        type: actionTypes.GET_DIALPAD_CONTACTS_SUCCESS,
        response
    }
}

export const getDialpadContactsFail = (err) => {
    return {
        type: actionTypes.GET_DIALPAD_CONTACTS_FAIL,
        err
    }
}

// IMPORT_DIALPAD_CONTACTS

export const importDialpadContacts = (data) => {
    return {
        type: actionTypes.IMPORT_DIALPAD_CONTACTS,
        data
    }
}

export const importDialpadContactsStart = () => {
    return {
        type: actionTypes.IMPORT_DIALPAD_CONTACTS_START
    }
}

export const importDialpadContactsSuccess = (response) => {
    return {
        type: actionTypes.IMPORT_DIALPAD_CONTACTS_SUCCESS,
        response
    }
}

export const importDialpadContactsFail = (err) => {
    return {
        type: actionTypes.IMPORT_DIALPAD_CONTACTS_FAIL,
        err
    }
}

// GET_LOG_HISTORY 

export const getLogHistory = (data) => {
    return {
        type: actionTypes.GET_LOG_HISTORY,
        data
    }
}

export const getLogHistoryStart = () => {
    return {
        type: actionTypes.GET_LOG_HISTORY_START
    }
}

export const getLogHistorySuccess = (response) => {
    return {
        type: actionTypes.GET_LOG_HISTORY_SUCCESS,
        response
    }
}

export const getLogHistoryFail = (err) => {
    return {
        type: actionTypes.GET_LOG_HISTORY_FAIL,
        err
    }
}

// GET_GOOGLE_CONTACTS 

export const getGoogleContacts = (data) => {
    return {
        type: actionTypes.GET_GOOGLE_CONTACTS,
        data
    }
}

export const getGoogleContactsStart = () => {
    return {
        type: actionTypes.GET_GOOGLE_CONTACTS_START
    }
}

export const getGoogleContactsSuccess = (response) => {
    return {
        type: actionTypes.GET_GOOGLE_CONTACTS_SUCCESS,
        response
    }
}

export const getGoogleContactsFail = (err) => {
    return {
        type: actionTypes.GET_GOOGLE_CONTACTS_FAIL,
        err
    }
}
