import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import ContactsForm from 'containers/CRM/Contacts/ContactsForm';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "components/CustomButtons/Button.jsx";

const PropertiesEditContact = (props) => {
    const [action, setAction] = useState('View');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }

    const prevContactID = usePrevious(props.contactID);

    useEffect(() => {
        if (prevContactID !== props.contactID && props.contactID) {
            props.getContact(props.contactID);
        }
    }, [props.contactID]);

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);

    }, [props.success]);

    const clearMessages = () => {
        setErrorMsg('');
        setSuccessMsg('');
    }

    const openForm = (newAction) => {
        setAction(newAction);
    }

    console.log('PropertiesEditContact', props);

    return (
        <div>
            <Dialog
            open={props.open}
            keepMounted
            onClose={props.close}
            maxWidth={false}
            fullWidth={true}
        >
            <div style={{padding:20}}>
            {props.contactData && <ContactsForm
                noDelete={true}
                action={props.autoEdit ? 'Edit' : action}
                setAction={setAction}
                closeForm={() => {
                    props.close();
                }}
                openForm={openForm}
                contactData={props.contactData}
                propertyContacts={props.propertyContacts}
                getContacts={() => { }}
                contactOptions={props.contactOptions}
                addressData={props.addressData}
                getAddresses={props.getAddresses}
                partnersData={props.partnersData}
                getPartnersData={props.getPartnersData}
                deleteContact={props.deleteContact}
                openAskModal={() => { }}
                updateContact={props.updateContact}
                getContactShares={props.getContactShares}
                getContactUsernames={props.getContactUsernames}
                createContactShare={props.createContactShare}
                deleteContactShare={props.deleteContactShare}
                contactShares={props.contactShares}
                contactUsernames={props.contactUsernames}
                success={successMsg}
                error={errorMsg}
                clearMessages={clearMessages}
                prefill={props.prefill}
                choose={props.choose}
                showCompanyAssigned={props.showCompanyAssigned}
                getCrmFileList={props.getCrmFileList}
            />}
                <div className="text-center">
                    <Button color="primary" onClick={() => { props.close() }}>CLOSE</Button>
                </div>
            </div>
            </Dialog>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        error: state.crm_contacts.error,
        success: state.crm_contacts.success,
        contactsData: state.crm_contacts.contactsData,
        contactOptions: state.crm_contacts.contactOptions,
        contactsCount: state.crm_contacts.contactsCount,
        contactData: state.crm_contacts.contactData,
        addressData: state.crm_contacts.addressData,
        partnersData: state.crm_contacts.partnersData,
        contactShares: state.crm_contacts.contactShares,
        contactUsernames: state.crm_contacts.contactUsernames,
        success_data: state.crm_contacts.success_data,
        propertyContacts: state.crm_properties.propertyContacts
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      },
      getContact: (contact_id) => {
            dispatch(actions.getContact(contact_id));
        },
      createContact: (data, partnersData, addressData) => {
          dispatch(actions.createContact(data, partnersData, addressData));
      },
      updateContact: (data, partnersData, addressData) => {
        dispatch(actions.updateContact(data, partnersData, addressData));
      },
      deleteContact: (id) => {
        dispatch(actions.deleteContact(id));
      },
      getAddresses: (contact_id) => {
        dispatch(actions.getAddresses(contact_id));
      },
      getPartnersData: (contact_id) => {
        dispatch(actions.getPartners(contact_id));
      },
      getContactOptions: () => {
        dispatch(actions.getContactOptions());
      },
      getContactShares: (contact_id) => {
        dispatch(actions.getContactShares(contact_id));
      },
      getContactUsernames: () => {
        dispatch(actions.getContactUsernames());
      },
      createContactShare: (data) => {
        dispatch(actions.createContactShare(data));
      },
      deleteContactShare: (share_id) => {
        dispatch(actions.deleteContactShare(share_id));
      },
      getContacts: (params) => {
        dispatch(actions.getContacts(params));
      },
      getContact: (id) => {
        dispatch(actions.getContact(id));
      },
      getPropertyContacts: (contact_id) => {
        dispatch(actions.getPropertyContacts(contact_id));
      },
      createContact: (data, partnersData, addressData, appointmentsData, sharesData, 
        formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd) => {
        dispatch(actions.createContact(data, partnersData, addressData, appointmentsData, sharesData, 
          formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd));
      },
      updateContact: (data, partnersData, addressData) => {
        dispatch(actions.updateContact(data, partnersData, addressData));
      },
      deleteContact: (id) => {
        dispatch(actions.deleteContact(id));
      },
      getAddresses: (contact_id) => {
        dispatch(actions.getAddresses(contact_id));
      },
      getPartnersData: (contact_id) => {
        dispatch(actions.getPartners(contact_id));
      },
      getContactOptions: () => {
        dispatch(actions.getContactOptions());
      },
      getContactShares: (contact_id) => {
        dispatch(actions.getContactShares(contact_id));
      },
      getContactUsernames: () => {
        dispatch(actions.getContactUsernames());
      },
      createContactShare: (data) => {
        dispatch(actions.createContactShare(data));
      },
      deleteContactShare: (share_id) => {
        dispatch(actions.deleteContactShare(share_id));
      },
      createCrmFile: (data) => {
        dispatch(actions.createCrmFile(data));
      },
      getCrmFileList: (data) => {
        dispatch(actions.getCrmFileList(data));
      },
      getCrmFile: (data) => {
        dispatch(actions.getCrmFile(data));
      },
      deleteCrmFile: (data) => {
        dispatch(actions.deleteCrmFile(data));
      },
      clearContacts: () => {
        dispatch(actions.clearContacts());
      },
      clearFileList: () => {
        dispatch(actions.clearFileList());
      },
      clearCRMMessags: () => {
        dispatch(actions.clearCRMMessages());
      },

      getContacts: (params) => {
        dispatch(actions.getContacts(params));
      },
      getContact: (id) => {
        dispatch(actions.getContact(id));
      },
      getPropertyContacts: (contact_id) => {
        dispatch(actions.getPropertyContacts(contact_id));
      },
      createContact: (data, partnersData, addressData, appointmentsData, sharesData, 
        formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd) => {
        dispatch(actions.createContact(data, partnersData, addressData, appointmentsData, sharesData, 
          formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd));
      },
      updateContact: (data, partnersData, addressData) => {
        dispatch(actions.updateContact(data, partnersData, addressData));
      },
      deleteContact: (id) => {
        dispatch(actions.deleteContact(id));
      },
      getAddresses: (contact_id) => {
        dispatch(actions.getAddresses(contact_id));
      },
      getPartnersData: (contact_id) => {
        dispatch(actions.getPartners(contact_id));
      },
      getContactOptions: () => {
        dispatch(actions.getContactOptions());
      },
      getContactShares: (contact_id) => {
        dispatch(actions.getContactShares(contact_id));
      },
      getContactUsernames: () => {
        dispatch(actions.getContactUsernames());
      },
      createContactShare: (data) => {
        dispatch(actions.createContactShare(data));
      },
      deleteContactShare: (share_id) => {
        dispatch(actions.deleteContactShare(share_id));
      },
      createCrmFile: (data) => {
        dispatch(actions.createCrmFile(data));
      },
      getCrmFileList: (data) => {
        dispatch(actions.getCrmFileList(data));
      },
      getCrmFile: (data) => {
        dispatch(actions.getCrmFile(data));
      },
      deleteCrmFile: (data) => {
        dispatch(actions.deleteCrmFile(data));
      },
      clearContacts: () => {
        dispatch(actions.clearContacts());
      },
      clearFileList: () => {
        dispatch(actions.clearFileList());
      },
      clearCRMMessags: () => {
        dispatch(actions.clearCRMMessages());
      }
      
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(PropertiesEditContact);