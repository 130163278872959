import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Contacts from "containers/CRM/Contacts/Contacts";
import ContactsForm from "containers/CRM/Contacts/ContactsForm";
import * as actions from '../../store/actions';
import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

class CRMContactsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'View',
      contactData: null,
      formDataObj: null,
      offset: 0,
      rows: 10,
      sort_by: 'created_date',
      sort_direction: 'DESC',
      filters: {
        first_name: '',
        last_name: '',
        email: '',
        main_phone: '',
        contact_type: ''
      },
      success: '',
      error: '',
      openedAsk: false,
    }
  }

  setOpenedAsk = (openedAsk) => {
    this.setState({ openedAsk });
  }

  setFilters = (field, value) => {
    const newFilters = { ...this.state.filters };
    newFilters[field] = value;
    this.setState({ filters: newFilters });
  }

  resetFilters = () => {
    this.setState({
      filters: {
        first_name: '',
        last_name: '',
        email: '',
        main_phone: '',
        contact_type: ''
      }
    })
  }

  setRows = (rows) => {
    this.setState({ rows });
  }

  setOffset = (offset) => {
    this.setState({ offset });
  }

  setFormDataObj = (formDataObj) => {
    this.setState({ formDataObj });
  }

  setSort = (sort_by, sort_direction) => { this.setState({ sort_by, sort_direction }) }

  getContactsParams = (shouldReset) => {
    const get = () => {
      const params = {
        offset: this.state.offset,
        rows: this.state.rows,
        sort_by: this.state.sort_by,
        sort_direction: this.state.sort_direction
      };
      Object.keys(this.state.filters).forEach(field => {
        if (this.state.filters[field]) {
          params[field] = this.state.filters[field];
        }
      })
      this.props.getContacts(params);
    }

    if (shouldReset) {
      this.setState({ offset: 0 }, () => {
        get();
      })
    } else {
      get();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.offset !== this.state.offset || prevState.rows !== this.state.rows) {
      this.getContactsParams();
    }
    if (prevState.sort_by !== this.state.sort_by || prevState.sort_direction !== this.state.sort_direction) {
      this.getContactsParams();
    }
    if (prevProps.success !== this.props.success) {
        this.setState({ success: this.props.success });
    }
    if (prevProps.error !== this.props.error) {
        this.setState({ error: this.props.error });
    }
  }

  clearMessages = () => {
    this.setState({ error: null, success: null });
  }

  closeTab = () => {
    this.setState({ tab: '' });
  }

  openTab = (tab, subTab) => {
    const n = { tab };
    if (subTab) n.subTab = subTab;
    this.setState(n);
  }

  closeForm = () => {
    this.props.history.push('/crm/contacts?p=view')
    this.setState({ tab: 'View' })
  }

  componentDidMount() {
    this.props.getContactOptions();
    const params = new URLSearchParams(this.props.location.search);
		const p = params.get('p'); // bar
		if (p === 'new') {
			this.setState({ tab: 'Add' });
      //this.props.clearContacts();
      this.props.clearFileList();
      this.setFormDataObj(null);
		}
  }

  render() {
    
    const crmPermitted = checkPermissions('CRM');
    if (!crmPermitted) {
      return <Redirect to="/dashboard" />;
    }
    const crmAllowed = checkPermissionsActions('CRM');
    

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon
                  color={this.state.tab === 'Add' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({ tab: 'Add' });
                    this.props.clearContacts();
                    this.props.clearFileList();
                    this.setFormDataObj(null);
                  }}
                  className="hovered hovered-shadow"
                >
                  <i className="fas fa-plus"></i> <strong>Add Contact</strong>
                </CardIcon>
              </CardHeader>
              <CardBody>
                {this.state.tab === 'View' &&
                  <div>
                    <Contacts
                      location_search={this.props.location.search}
                      getContacts={this.getContactsParams}
                      getContact={this.props.getContact}
                      createContact={this.props.createContact}
                      updateContact={this.props.updateContact}
                      deleteContact={this.props.deleteContact}
                      contactsData={this.props.contactsData}
                      addressData={this.props.addressData}
                      getAddresses={this.props.getAddresses}
                      partnersData={this.props.partnersData}
                      getPartnersData={this.props.getPartnersData}
                      contactsCount={this.props.contactsCount}
                      agentData={this.props.agentData} // agent contacts
                      agentDataCount={this.props.agentDataCount} // agent contacts count
                      contactData={this.props.contactData}
                      contactOptions={this.props.contactOptions}
                      propertyContacts={this.props.propertyContacts}
                      getPropertyContacts={this.props.getPropertyContacts}

                      getContactShares={this.props.getContactShares}
                      getContactUsernames={this.props.getContactUsernames}
                      createContactShare={this.props.createContactShare}
                      deleteContactShare={this.props.deleteContactShare}
                      contactShares={this.props.contactShares}
                      contactUsernames={this.props.contactUsernames}

                      formDataObj={this.state.formDataObj}
                      setFormDataObj={this.setFormDataObj}
                      createCrmFile={this.props.createCrmFile}
                      deleteCrmFile={this.props.deleteCrmFile}
                      getCrmFileList={this.props.getCrmFileList}
                      getCrmFile={this.props.getCrmFile}
                      fileList={this.props.fileList}
                      downloadedFile={this.props.downloadedFile}

                      rows={this.state.rows}
                      offset={this.state.offset}
                      sortDirection={this.state.sort_direction}
                      setRows={this.setRows}
                      setOffset={this.setOffset}
                      error={this.state.error}
                      success={this.state.success}
                      loading={this.props.loading}
                      clearMessages={this.clearMessages}
                      filters={this.state.filters}
                      setFilters={this.setFilters}
                      resetFilters={this.resetFilters}
                      setSort={this.setSort}
                      sortBy={this.state.sort_by}

                      crmAllowed={crmAllowed}

                      get_contacts={this.props.getContacts}
                      clearCRMMessages={this.props.clearCRMMessages}
                      location={this.props.location}
                      closeFrom={() => {
                        
                        // this.props.history.push('/crm/contacts?p=view')
                      }}
                    />
                  </div>}
                {this.state.tab === 'Add' &&
                  <Dialog
                    open={this.state.tab === 'Add'}
                    keepMounted
                    maxWidth={false}
                    fullWidth={true}
                    fullScreen={true}
                  >
                    <div className="dialog-wrapper">
                      <ContactsForm
                        action="Add"
                        success_data={this.props.success_data}
                        dontCloseFormOnAdd={true}
                        closeForm={this.closeForm}
                        retrievedContactData={this.props.contactData}
                        contactsData={this.props.contactsData}
                        // contactData={this.props.contactData}
                        formDataObj={this.state.formDataObj}
                        setFormDataObj={this.setFormDataObj}
                        contactOptions={this.props.contactOptions}
                        createContact={this.props.createContact}
                        partnersData={this.props.partnersData}
                        getPartnersData={this.props.getPartnersData}
                        getContactUsernames={this.props.getContactUsernames}
                        getContact={this.props.getContact}
                        getContactShares={this.props.getContactShares}
                        contactUsernames={this.props.contactUsernames}
                        success={this.state.success}
                        error={this.state.error}
                        loading={this.props.loading}
                        clearMessages={this.clearMessages}
                        crmAllowed={crmAllowed}
                        getContacts={this.props.getContacts}
                        fileList={this.props.fileList}
                        setOpenedAsk={this.setOpenedAsk}
                        showCloseBtn={true}
                        deleteCrmFile={this.props.deleteCrmFile}
                        getAddresses={this.props.getAddresses}
                      />
                    </div>
                  </Dialog>
                }
              </CardBody>
            </Card>
          </GridItem>

          {this.state.openedAsk && <CustomDialogClose
            ok_go={() => {
              this.closeForm();
            }}
            open={this.state.openedAsk}
            close={() => {
              this.setOpenedAsk(false);
            }}
          />}
        </GridContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.crm_contacts.error,
    success: state.crm_contacts.success,
    success_data: state.crm_contacts.success_data,
    loading: state.crm_contacts.loading,
    contactsData: state.crm_contacts.contactsData,
    contactOptions: state.crm_contacts.contactOptions,
    contactsCount: state.crm_contacts.contactsCount,
    agentData: state.crm_contacts.agentData,
    agentDataCount: state.crm_contacts.agentDataCount,
    contactData: state.crm_contacts.contactData,
    addressData: state.crm_contacts.addressData,
    partnersData: state.crm_contacts.partnersData,
    contactShares: state.crm_contacts.contactShares,
    contactUsernames: state.crm_contacts.contactUsernames,
    propertyContacts: state.crm_properties.propertyContacts,
    fileList: state.crm_contacts.fileList,
    downloadedFile: state.crm_contacts.downloadedFile,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getContacts: (params) => {
      dispatch(actions.getContacts(params));
    },
    getContact: (id) => {
      dispatch(actions.getContact(id));
    },
    getPropertyContacts: (contact_id) => {
      dispatch(actions.getPropertyContacts(contact_id));
    },
    createContact: (data, partnersData, addressData, appointmentsData, sharesData, 
      formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd) => {
      dispatch(actions.createContact(data, partnersData, addressData, appointmentsData, sharesData, 
        formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd));
    },
    updateContact: (data, partnersData, addressData) => {
      dispatch(actions.updateContact(data, partnersData, addressData));
    },
    deleteContact: (id) => {
      dispatch(actions.deleteContact(id));
    },
    getAddresses: (contact_id) => {
      dispatch(actions.getAddresses(contact_id));
    },
    getPartnersData: (contact_id) => {
      dispatch(actions.getPartners(contact_id));
    },
    getContactOptions: () => {
      dispatch(actions.getContactOptions());
    },
    getContactShares: (contact_id) => {
      dispatch(actions.getContactShares(contact_id));
    },
    getContactUsernames: () => {
      dispatch(actions.getContactUsernames());
    },
    createContactShare: (data) => {
      dispatch(actions.createContactShare(data));
    },
    deleteContactShare: (share_id) => {
      dispatch(actions.deleteContactShare(share_id));
    },
    createCrmFile: (data) => {
      dispatch(actions.createCrmFile(data));
    },
    getCrmFileList: (data) => {
      dispatch(actions.getCrmFileList(data));
    },
    getCrmFile: (data) => {
      dispatch(actions.getCrmFile(data));
    },
    deleteCrmFile: (data) => {
      dispatch(actions.deleteCrmFile(data));
    },
    clearContacts: () => {
      dispatch(actions.clearContacts());
    },
    clearFileList: () => {
      dispatch(actions.clearFileList());
    },
    clearCRMMessags: () => {
      dispatch(actions.clearCRMMessages());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMContactsPage);
