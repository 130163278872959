import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Page, Document } from 'react-pdf';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;


const OnboardingPdfModal = (props) => {
    const { pdfData, open, close } = props;

    const downloadPdf = (pdfName, buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], { type: "application/pdf" });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = pdfName;
        link.click();
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div style={{ margin: 20 }}>
                    <OBPdfViewer pdfData={pdfData} />
                    <br />
                    <Button color="rose" onClick={() => { close() }}>Close</Button>
                    <Button color="rose" onClick={() => { downloadPdf('NoticeToEmployee.pdf', pdfData.data) }}>Download</Button>
                </div>
            </Dialog>
        </div>
    )
}

const OBPdfViewer = (props) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);
    const loadPages = () => Array(numPages).fill(0).map((_, i) => <Page key={i} pageNumber={i + 1} width={1000} />)

    

    return (
        <div>
            <Document
                file={props.pdfData}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                {loadPages()}
            </Document>
            <p>Page {pageNumber} of {numPages}</p>
        </div>
    );
}

export default OnboardingPdfModal;