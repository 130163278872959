export const access_applicable_groups = [
    'Accounting',
    'Accounts Payable PM',
    'Accounts Receivable PM',
    'After Hour Emergency VM',
    'Agent Email List',
    'Air Finals',
    'Brokerage',
    'Commissions',
    'Corporate Payables',
    'Credit Check Request',
    'Docusign',
    'Done Deals',
    'Executives',
    'Former Employees',
    'illi Employees',
    'illi Tech Support',
    'illiSignatures',
    'illiteam',
    'Insurance Inbox',
    'ISG',
    'IT Support Dist List',
    'Marketing Department',
    'New Listing',
    'PM Fax and VM',
    'PM Leasing Agents',
    'PM-LOIs',
    'Property Management',
    'Recover Socal',
    'Sales Faxes and VMs',
    'SFusers',
    'Sign Request',
    'Sitting Vacancies',
    'Supplies',
    'Support staff',
    'Survey',
    'Top Tenants Form',
    'Web Leads'
];

export const required_access = [
    'Splashtop', 'BizBuySell', 'Crexi', 'MLS', 'Brevitas', 'Office Space'
];

export const userData = {
    // GENERAL
    role: '', // ?
    department: '', // Support, Agent, Property Management
    DRE: '', // 40 characters max
    is_illi_employee: '', // Yes, No
    is_user_replacing_an_existing_user: '', // Yes, No, Maybe
    this_user_is_replacing: '', // 40 characters max 
    lets_sign_it: '', // Yes, No 
    signature_style: '', // Agent, Property Management 
    illi_university: '', // Yes, No - REQUIRED
    property_base: '', // Yes, No 

    // LINKS 
    current_listings_link: '', // 200 characters 
    facebook_page_link: '', // 200 characters 
    linkedin_link: '', // 200 characters
    twitter_link: '', // 200 characters 

    // IT 
    will_need_workstation: '', // Yes 
    will_need_microsoft_office: '', // Yes
    will_need_adobe_acrobat: '', // Yes, No 
    will_need_physical_phone: '', // Yes, No 
    needs_access_to_the_following_printers: '',
    // Lexmark Brokerage, Lexmark Property Management, Lexmark HR

    // ACCESS 
    selected_applicable_groups: [], // separated by comma
    selected_required_access: [], // separated by comma

    // NOTES
    notes: '',

    // PERSONAL INFO 
    street1: '',
    street2: '',
    city: '',
    state: '',
    zipcode: ''
};