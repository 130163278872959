import React, { useEffect, useState, useRef } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDialogWithProperty from "components/CustomDialog/CustomDialogWithProperty";
import CustomDialogChooser from "../../../components/CustomDialog/CustomDialogChooser";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import { clone } from "shared/utility";
import ERTLelement from "./ERTLelement";
import {
    Checkbox,
    Dialog,
    DialogTitle,
    IconButton
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { concat, get, isEmpty, update } from "lodash";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { createAddressObject } from "../../CRM/Properties/PropertiesLogic";
import Notification from "../../../components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import CustomCRMEditor from "../../../components/CustomDialog/CustomCRMEditor";
import { createOwnerName } from "./ERTLLogic";

const use = (val) => {
    return val ? val : "";
};

const ERTLTab1 = (props) => {
    const [addContact, setAddContact] = useState(false);
    const [deleteContact, setDeleteContact] = useState(null);
    const [gettingPropertyOwners, setGettingPropertyOwners] = useState(false);
    const [owners, setOwners] = useState([]);
    const [ownerAddresses, setOwnerAddresses] = useState([]);
    const [chosenOwners, setChosenOwners] = useState([]);

    const [addProperty, setAddProperty] = useState(false);
    const [askAddContactsToProperty, setAskAddContactsToProperty] = useState(null);
    const [openedOwnerModal, setOpenedOwnerModal] = useState(false);
    const [prefilling, setPrefilling] = useState(false);
    const [edit, setEdit] = useState(null);

    const isChecked = (key) => {
        let exists = false;
        if (chosenOwners) {
            chosenOwners.forEach(po => {
                if (po.key === key) exists = true;
            })
        }
        return exists;
    }

    const {
        propertyChosen,
        setPropertyChosen,
        ertlFormData,
        setErtlFormData,
        updateData,
        getPropertyOwnersContacts,
        propertyOwnersContacts,
    } = props;

    console.log('ERTLTab1', props, ownerAddresses, owners, chosenOwners)

    const closeOwnerModal = () => setOpenedOwnerModal(false);

    const renderOwners = () => {
        if (isEmpty(owners)) {
            return <p>This property has no owners</p>;
        } else {
            return (
            <div className="grayscaled">
                <div>
                    <h4>CHOOSE OWNER COMPANY/CONTACT</h4>
                    {owners.map((owner, index) => {
                        console.log('opwners..', owner, index);
                        const firstName = get(owner, "first_name", "");
                        const lastName = get(owner, "last_name", "");
                        const companyName = get(owner, "company_name", "");

                        const fullName = firstName + " " + lastName + " " + (companyName ? ` - ${companyName}` : '');
                        const key = `${owner.id}${owner.company_id ? `_${owner.company_id}` : ''}`;
                        
                        return (
                            <div key={`ccc-${index}`}>
                                <div>
                                    <Checkbox
                                        edge="start"
                                        checked={isChecked(key)}
                                        onClick={() => selectOwner(owner)} key={`owner-${index}`}
                                    />
                                    <label>{fullName}</label>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
            );
        }
    };

    useEffect(() => {
        if (prefilling) {
            if (props.propertyData) {
                setPropertyChosen(props.propertyData);
                window.history.replaceState(null, null, '/ertl');
                props.reset_prefill_property_id();
                setPrefilling(false);
            }
        }
    }, [props.propertyData, prefilling]);

    useEffect(() => {
        if (props.prefill_property_id) {
            setPrefilling(true);
            props.getProperty(props.prefill_property_id)
        }
    }, []);

    // CHOOSING PROPERTY
    const choose = (chosen) => {
        // open property
        const address = createAddressObject(chosen);
        console.log('chosen', chosen, address);
        const newData = clone(ertlFormData);
        newData.property_address1 = address.street1;
        newData.property_address2 = address.street2;
        const additionalFields = [
            'address_is_range','street_range1','street_range2',
            'address2_is_range','street2_range1','street2_range2',
        ];
    
        additionalFields.forEach(af => {
            newData[`property_${af}`] = address[af];
        })
        newData.property_city = address.city;
        newData.property_state = address.state;
        newData.property_zipcode = address.zip;
        newData.property_id = chosen.id;
        setGettingPropertyOwners(true);
        getPropertyOwnersContacts(chosen.id);
        setErtlFormData(newData);
        setAddProperty(false);
    }

    // CHOOSING OWNER(S) FOR POPUP PROPERTY ADDER
    const selectOwner = (owner) => {
        const key = `${owner.id}${owner.company_id ? `_${owner.company_id}` : ''}`;
        let found = false;
        chosenOwners.forEach(co => {
            if (co.key === key) {
                found = true;
            }
            if (co.key.indexOf('_') !== -1) {
                const company_id = co.key.split('_')[1];
                if (company_id === owner.company_id) {
                    found = true;
                }
            }
        })
        console.log('choosing owner', owner, found);
        if (!found) {
            if (owner.company_id) {
                // OWNER IS A COMPANY
                const obj = {
                    key: `${owner.id}_${owner.company_id}`,
                    first_name: owner.first_name,
                    last_name: owner.last_name,
                    email: owner.email,
                    title: owner.title,
                    phone: owner.main_phone,
                    contact_id: owner.id,
                    company_id: owner.company_id,
                    company_name: owner.company_name,
                    company_dbas: owner.company_dbas,
                    company_dba: '',
                    company_dba_id: '',
    
                    street1: owner.company_street1,
                    street2: owner.company_street2,
                    city: owner.company_city,
                    state: owner.company_state,
                    zip: owner.company_zip
                };

                if (owner.cc_id) {
                    obj.company_contact_id = owner.cc_id;
                    obj.email = owner.cc_email;
                    obj.title = owner.cc_title;
                    obj.phone = owner.cc_phone;
                }

                setChosenOwners(chosenOwners.filter(co => {
                    if (co.key.indexOf('_') !== -1) {
                        const company_id = co.key.split('_')[1];
                        if (company_id == owner.company_id) {
                            return false;
                        }
                    }
                    return true;
                }).concat([
                    obj
                ]))
            } else {
                // OWNER IS A CONTACT 
                const obj = {
                    key: `${owner.id}`,
                    first_name: owner.first_name,
                    last_name: owner.last_name,
                    email: owner.email,
                    title: owner.title,
                    phone: owner.main_phone,
                    contact_id: owner.id,
                    contact_dbas: owner.contact_dbas,
                    contact_dba: '',
                    contact_dba_id: '',
    
                    street1: '',
                    street2: '',
                    city: '',
                    state: '',
                    zip: ''
                };
                // Extract Main Address
                if (owner.addresses) {
                    owner.addresses.forEach(add => {
                        if (add.type === 'M') {
                            obj.street1 = add.street1;
                            obj.street2 = add.street2;
                            obj.city = add.city;
                            obj.state = add.state;
                            obj.zip = add.zip;
                        }
                    })
                }
                setChosenOwners(chosenOwners.concat([
                    obj
                ]))
            }
        } else {
            setChosenOwners(chosenOwners.filter(c => {
                if (c.key === key) return false;
                return true;
            }))
        }
    };

    // CHOOSE PROPERTY OWNER VIA CUSTOM DIALOG CHOOSER 
    // ASK IF TO BE ADDED TO PROPERTY AS OWNER
    const choosePropertyOwner = (chosen, c1, dbaObj) => {
        console.log('chosen property owner', chosen, c1, dbaObj);
        const newData = clone(ertlFormData);
        if (chosen.first_name) {
            // CONTACT TYPE
            const contactObj = {
                key: `${chosen.id}`,
                first_name: chosen.first_name,
                last_name: chosen.last_name,
                email: chosen.email,
                title: chosen.title,
                phone: chosen.main_phone,
                contact_id: chosen.id,
                contact_dbas: chosen.dbas,
                contact_dba: '',
                contact_dba_id: '',

                street1: '',
                street2: '',
                city: '',
                state: '',
                zip: ''
            };
            if (chosen.addresses) {
                chosen.addresses.forEach(oa => {
                    if (oa.type === 'M') {
                        if (oa.street1) contactObj.street1 = oa.street1;
                        if (oa.street2) contactObj.street2 = oa.street2;
                        if (oa.city) contactObj.city = oa.city;
                        if (oa.state) contactObj.state = oa.state;
                        if (oa.zip) contactObj.zip = oa.zip;
                    }
                })
            }
            if (dbaObj) {
                contactObj.contact_dba = dbaObj.name;
                contactObj.contact_dba_id = dbaObj.id;
            }
            newData.property_owners = newData.property_owners.concat([contactObj]);
            let arr = newData.made_by_and_between ? newData.made_by_and_between.split(' and ') : [];
            arr.push(createOwnerName(contactObj));
            newData.made_by_and_between = arr.filter(a => {
                if (a) return true
            }).join(' and ');
            setErtlFormData(newData);
            setAskAddContactsToProperty({
                property_id: ertlFormData.property_id,
                contact_id: chosen.id,
                contact_type: 'OWN'
            });
        } else {
            // ------------
            // COMPANY TYPE 
            // ------------
            if (chosen.signers.length > 0) {
                const chosen_contact = chosen.signers[0];
                const company = chosen.company_data;

                const contactObj = {
                    key: `${chosen_contact.id}${company ? `_${company.id}` : ''}`,
                    first_name: chosen_contact.first_name,
                    last_name: chosen_contact.last_name,
                    contact_id: chosen_contact.id,
                    company_id: company ? company.id : '',
                    company_name: company ? company.name : '',
                    title: chosen_contact.title,
                    email: chosen_contact.email,
                    phone: chosen_contact.main_phone,
                    company_dba_id: '',
                    company_dba: '',
                    company_dbas: [],

                    street1: company.mailing_address1,
                    street2: company.mailing_address2,
                    city: company.mailing_city,
                    state: company.mailing_state,
                    zip: company.mailing_zip
                }
                if (chosen_contact.company_dats) {
                    const cd = chosen_contact.company_dats;
                    contactObj.title = cd.title;
                    contactObj.phone = cd.phone;
                    contactObj.email = cd.email;
                    contactObj.company_contact_id = cd.id;
                }
                if (chosen.dba_data) {
                    contactObj.company_dbas = chosen.dba_data;
                }
                if (chosen.dba_id) {
                    contactObj.company_dba_id = chosen.dba_id;
                    if (chosen.dba_data) {
                        contactObj.company_dbas = chosen.dba_data;
                        chosen.dba_data.forEach(dd => {
                            if (dd.id === chosen.dba_id && dd.active === 1) {
                                contactObj.company_dba = dd.name;
                            }
                        })

                    }
                }
                newData.property_owners = newData.property_owners.concat([contactObj]);
                let arr = newData.made_by_and_between ? newData.made_by_and_between.split(' and ').filter(f => {
                    if (f) return true;
                }) : [];
                arr.push(createOwnerName(contactObj));
                newData.made_by_and_between = arr.join(' and ');
                setErtlFormData(newData);
                setAskAddContactsToProperty({
                    property_id: ertlFormData.property_id,
                    company_id: company.id,
                    company_type: 'OWN'
                });
            }
        }
        setAddContact(false);
    }

    useEffect(() => {
        if (props.propertyOwners && gettingPropertyOwners) {
            if (props.propertyOwners.length > 0) {
                const addresses = {};
                props.propertyOwners.forEach(ppo => {
                    if (ppo.company_id) {
                        // COMPANY TYPE
                        const key = `c${ppo.company_id}`;
                        if (!addresses.hasOwnProperty(key)) {
                            addresses[key] = ppo;
                        }
                    } else {
                        // CONTACT TYPE 
                        const key = `${ppo.id}`;
                        if (!addresses.hasOwnProperty(key)) {
                            addresses[key] = ppo;
                        }
                    }
                });
                setOwnerAddresses(Object.keys(addresses).map(f => {
                    return addresses[f];
                }));
                setOwners(props.propertyOwners);
                setOpenedOwnerModal(true);
                setGettingPropertyOwners(false);
            }
        }
    }, [props.propertyOwners, gettingPropertyOwners]);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setAddProperty(true);
                        }}
                    >
                        SELECT OR CREATE PROPERTY
                    </Button>
                    <hr />
                    <h3 className="text-center">Property Data</h3>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {!ertlFormData.property_id && (
                        <div className="text-center">
                            <em>
                                Click "Select or Create Property" to add
                                property
                            </em>
                        </div>
                    )}
                </GridItem>
            </GridContainer>
            {ertlFormData.property_id && (
                <GridContainer>
                    <GridItem xs={4} sm={4} md={4}>
                        <ERTLelement
                            ertlFormData={ertlFormData}
                            field="property_address1"
                            update={updateData}
                        />
                        <ERTLelement
                            ertlFormData={ertlFormData}
                            field="property_address2"
                            update={updateData}
                        />
                         <ERTLelement
                            ertlFormData={ertlFormData}
                            field="property_zipcode"
                            update={updateData}
                        />
                    </GridItem>
                    <GridItem xs={4} sm={4} md={4}>
                        <ERTLelement
                            ertlFormData={ertlFormData}
                            field="property_city"
                            update={updateData}
                        />
                        <ERTLelement
                            ertlFormData={ertlFormData}
                            field="property_state"
                            update={updateData}
                        />
                    </GridItem>
                </GridContainer>
            )}

            {ertlFormData.property_id && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <h3 className="text-center">Owner Contact Data</h3>
                    {ertlFormData.property_owners && <div>
                        {ertlFormData.property_owners.length === 0 && (
                            <div className="text-center">
                                <em>
                                    Click "Add Contact" to add
                                    owner contact
                                </em>
                            </div>
                        )}
                    </div>}
                    <Button
                        color="primary"
                        onClick={() => {
                            setAddContact(true);
                        }}
                    >
                        ADD CONTACT       
                    </Button>
                </GridItem>
                {ertlFormData.property_owners.map((po,i) => {
                    return <GridItem xs={12} sm={6} md={6} key={`po-${i}`}>
                        <IconButton
                            onClick={() => {
                                setDeleteContact(po);
                            }}
                            style={{float:'right'}}
                        >
                            <i className="fa fa-trash"></i>
                        </IconButton>
                        <div className="boxed">
                            <div>#{i+1}</div>
                            {po.company_id ? <h4>COMPANY CONTACT</h4> : <h4>CONTACT</h4>}
                            <CustomInput 
                                label="First Name"
                                value={po.first_name}
                                onClick={() => {
                                    if (po.contact_id) {
                                        setEdit({
                                            value: po.first_name,      
                                            type: 'contact',       
                                            field: 'first_name',      
                                            fieldType: 'input',  
                                            label: 'First Name',      
                                            id: po.contact_id,         
                                            chooseVal: (chosenVal) => {
                                                const cloned = clone(ertlFormData);
                                                cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                    if (j === i) {
                                                        x.first_name = chosenVal;
                                                    }
                                                    return x;
                                                }); 
                                                setErtlFormData(cloned);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                            />
                            <CustomInput 
                                label="Last Name"
                                value={po.last_name}
                                onClick={() => {
                                    setEdit({
                                        value: po.last_name,      
                                        type: 'contact',       
                                        field: 'last_name',      
                                        fieldType: 'input',  
                                        label: 'last Name',      
                                        id: po.contact_id,         
                                        chooseVal: (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.last_name = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null); 
                                        }
                                    });
                                }}
                            />
                            <CustomInput 
                                label="Title"
                                value={po.title}
                                onClick={(e) => {
                                    if (po.company_contact_id) {
                                        setEdit({
                                            value: po.title,      
                                            type: 'company_contact',       
                                            field: 'title',      
                                            fieldType: 'input',  
                                            label: 'Title',      
                                            id: po.company_contact_id,         
                                            chooseVal: (chosenVal) => {
                                                const cloned = clone(ertlFormData);
                                                cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                    if (j === i) {
                                                        x.title = chosenVal;
                                                    }
                                                    return x;
                                                }); 
                                                setErtlFormData(cloned);
                                                setEdit(null); 
                                            }
                                        });
                                    } else {
                                        setEdit({
                                            value: po.title,      
                                            type: 'contact',       
                                            field: 'title',      
                                            fieldType: 'input',  
                                            label: 'Title',      
                                            id: po.contact_id,         
                                            chooseVal: (chosenVal) => {
                                                const cloned = clone(ertlFormData);
                                                cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                    if (j === i) {
                                                        x.title = chosenVal;
                                                    }
                                                    return x;
                                                }); 
                                                setErtlFormData(cloned);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                            />
                            <CustomInput 
                                label="Email"
                                value={po.email}
                                onClick={(e) => {
                                    if (po.company_contact_id) {
                                        setEdit({
                                            value: po.email,      
                                            type: 'company_contact',       
                                            field: 'email',      
                                            fieldType: 'input',  
                                            label: 'Email',      
                                            id: po.company_contact_id,         
                                            chooseVal: (chosenVal) => {
                                                const cloned = clone(ertlFormData);
                                                cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                    if (j === i) {
                                                        x.email = chosenVal;
                                                    }
                                                    return x;
                                                }); 
                                                setErtlFormData(cloned);
                                                setEdit(null); 
                                            }
                                        });
                                    } else {
                                        setEdit({
                                            value: po.email,      
                                            type: 'contact',       
                                            field: 'email',      
                                            fieldType: 'input',  
                                            label: 'Email',      
                                            id: po.contact_id,         
                                            chooseVal: (chosenVal) => {
                                                const cloned = clone(ertlFormData);
                                                cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                    if (j === i) {
                                                        x.email = chosenVal;
                                                    }
                                                    return x;
                                                }); 
                                                setErtlFormData(cloned);
                                                setEdit(null); 
                                            }
                                        });
                                    }
                                }}
                            />
                            {po.company_name && <h4 style={{marginTop:40}}>COMPANY</h4>}
                            {po.company_name && <CustomInput 
                                label="Company Name"
                                value={po.company_name}
                                onClick={(e) => {
                                    setEdit({
                                        value: po.company_name,      
                                        type: 'company',       
                                        field: 'name',      
                                        fieldType: 'input',  
                                        label: 'Company Name',      
                                        id: po.company_id,         
                                        chooseVal: (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.company_name = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null); 
                                        }
                                    });
                                }}
                            />}
                            {po.company_dbas && <div>
                                {po.company_dbas.length > 0 && <div>
                                    <CustomSelect 
                                        options={[''].concat(po.company_dbas)}
                                        default={po.company_dba_id ? po.company_dba_id : ''}
                                        indx="id"
                                        desc="name"
                                        choose={(indx) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((pox,ix) => {
                                                if (ix === i) {
                                                    po.company_dbas.forEach(poc => {
                                                        if (poc.id === indx) {
                                                            pox.company_dba_id = indx;
                                                            pox.company_dba = poc.name;
                                                        }
                                                    })
                                                }
                                                return pox;
                                            })

                                            // UPDATE Made by and Between
                                            const chosenOnes = cloned.property_owners ? cloned.property_owners : [];
                                            const arr = chosenOnes.map(c => {
                                                return createOwnerName(c);
                                            })
                                            cloned.made_by_and_between = arr.join(' and ');

                                            setErtlFormData(cloned);
                                        }}
                                        label="Choose Company DBA"
                                    />
                                </div>}
                            </div>}

                            {po.contact_dbas && <div>
                                {po.contact_dbas.length > 0 && <div>
                                    <CustomSelect 
                                        options={[''].concat(po.contact_dbas)}
                                        default={po.contact_dba_id ? po.contact_dba_id : ''}
                                        indx="id"
                                        desc="name"
                                        choose={(indx) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((pox,ix) => {
                                                if (ix === i) {
                                                    po.contact_dbas.forEach(poc => {
                                                        if (poc.id === indx) {
                                                            pox.contact_dba_id = indx;
                                                            pox.contact_dba = poc.name;
                                                        }
                                                    })
                                                }
                                                return pox;
                                            })

                                            // UPDATE Made by and Between
                                            const chosenOnes = cloned.property_owners ? cloned.property_owners : [];
                                            const arr = chosenOnes.map(c => {
                                                return createOwnerName(c);
                                            })
                                            cloned.made_by_and_between = arr.join(' and ');

                                            setErtlFormData(cloned);
                                        }}
                                        label="Choose Contact DBA"
                                    />
                                </div>}
                            </div>}

                            <div className="boxed" style={{marginTop:40}}>
                                <h4>ADDRESS</h4>
                                <CustomInput 
                                    label="Street1"
                                    value={po.street1}
                                    onClick={(e) => {
                                        const t = po;
                                        const chooseVal = (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.street1 = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null);
                                        }
                                        if (t.contact_id && !t.company_id) {
                                            // CONTACT ONLY
                                            setEdit({
                                                value: t.street1,      
                                                type: 'contact',       
                                                field: 'street1',      
                                                fieldType: 'input',  
                                                label: 'Address 1',      
                                                id: t.contact_id,         
                                                chooseVal
                                            })
                                        } else {
                                            // COMPANY WITH CONTACT 
                                            setEdit({
                                                value: t.street1,      
                                                type: 'company',       
                                                field: 'mailing_address1',      
                                                fieldType: 'input',  
                                                label: 'Address',      
                                                id: t.company_id,         
                                                chooseVal
                                            })
                                        }
                                    }}
                                />
                                <CustomInput 
                                    label="Street2"
                                    value={po.street2}
                                    onClick={(e) => {
                                        const t = po;
                                        const chooseVal = (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.street2 = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null);
                                        }
                                        if (t.contact_id && !t.company_id) {
                                            // CONTACT ONLY
                                            setEdit({
                                                value: t.street2,      
                                                type: 'contact',       
                                                field: 'street2',      
                                                fieldType: 'input',  
                                                label: 'Address 2',      
                                                id: t.contact_id,         
                                                chooseVal
                                            })
                                        } else {
                                            // COMPANY WITH CONTACT 
                                            setEdit({
                                                value: t.street2,      
                                                type: 'company',       
                                                field: 'mailing_address2',      
                                                fieldType: 'input',  
                                                label: 'Address 2',      
                                                id: t.company_id,         
                                                chooseVal
                                            })
                                        }
                                    }}
                                />
                                <CustomInput 
                                    label="City"
                                    value={po.city}
                                    onClick={(e) => {
                                        const t = po;
                                        const chooseVal = (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.city = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null);
                                        }
                                        if (t.contact_id && !t.company_id) {
                                            // CONTACT ONLY
                                            setEdit({
                                                value: t.city,      
                                                type: 'contact',       
                                                field: 'city',      
                                                fieldType: 'input',  
                                                label: 'City',      
                                                id: t.contact_id,         
                                                chooseVal
                                            })
                                        } else {
                                            // COMPANY WITH CONTACT 
                                            setEdit({
                                                value: t.city,      
                                                type: 'company',       
                                                field: 'mailing_city',      
                                                fieldType: 'input',  
                                                label: 'City',      
                                                id: t.company_id,         
                                                chooseVal
                                            })
                                        }
                                    }}
                                />
                                <CustomInput 
                                    label="State"
                                    value={po.state}
                                    onClick={(e) => {
                                        const t = po;
                                        const chooseVal = (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.state = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null);
                                        }
                                        if (t.contact_id && !t.company_id) {
                                            // CONTACT ONLY
                                            setEdit({
                                                value: t.state,      
                                                type: 'contact',       
                                                field: 'state',      
                                                fieldType: 'states',  
                                                label: 'State',      
                                                id: t.contact_id,         
                                                chooseVal
                                            })
                                        } else {
                                            // COMPANY WITH CONTACT 
                                            setEdit({
                                                value: t.state,      
                                                type: 'company',       
                                                field: 'mailing_state',      
                                                fieldType: 'states',  
                                                label: 'State',      
                                                id: t.company_id,         
                                                chooseVal
                                            })
                                        }
                                    }}
                                />
                                <CustomInput 
                                    label="Zip"
                                    value={po.zip}
                                    onClick={(e) => {
                                        const t = po;
                                        const chooseVal = (chosenVal) => {
                                            const cloned = clone(ertlFormData);
                                            cloned.property_owners = cloned.property_owners.map((x,j) => {
                                                if (j === i) {
                                                    x.zip = chosenVal;
                                                }
                                                return x;
                                            }); 
                                            setErtlFormData(cloned);
                                            setEdit(null);
                                        }
                                        if (t.contact_id && !t.company_id) {
                                            // CONTACT ONLY
                                            setEdit({
                                                value: t.zip,      
                                                type: 'contact',       
                                                field: 'zip',      
                                                fieldType: 'input',  
                                                label: 'Zip',      
                                                id: t.contact_id,         
                                                chooseVal
                                            })
                                        } else {
                                            // COMPANY WITH CONTACT 
                                            setEdit({
                                                value: t.zip,      
                                                type: 'company',       
                                                field: 'mailing_zip',      
                                                fieldType: 'input',  
                                                label: 'Zip',      
                                                id: t.company_id,         
                                                chooseVal
                                            })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </GridItem>
                })}
            </GridContainer>}

            {deleteContact !== null && <NotificationAsk
                open={deleteContact !== null ? true : false}
                close={() => {
                    setDeleteContact(null);
                }}
                title="Are you sure?"
                message="Do you want to remove this owner contact?"
                success={() => {
                    const newData = clone(ertlFormData);
                    newData.property_owners = newData.property_owners.filter(po => {
                        if (po.contact_id === deleteContact.contact_id) {
                            return false;
                        }
                        return true;
                    })
                    setErtlFormData(newData);
                    setDeleteContact(null)
                }}
            />}

            {addContact && <CustomDialogChooser 
                open={addContact}
                close={() => {
                    setAddContact(false);
                }}
                choose={choosePropertyOwner}
                showContactCompaniesTab={true}
                create_and_choose={choosePropertyOwner}
                title="Property Owner"
                label="Property Owner"
                no_property={true}
                showFilters={true}
                showContactTypes={true}
                forceContact={true}
            />}

            {addProperty && (
                <CustomDialogWithProperty
                    label="Filter Properties"
                    title="Select Property"
                    propertyType={addProperty}
                    open={addProperty}
                    close={() => {
                        setAddProperty(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    theContactData={props.contactData}
                />
            )}

            {openedOwnerModal && (
                <Dialog
                    open={openedOwnerModal}
                    keepMounted
                    onClose={closeOwnerModal}
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <DialogTitle>
                        <div className="close-btn" onClick={closeOwnerModal}>
                            <Close />
                        </div>
                        <div style={{ textAlign: "center" }}>
                            Property's Owners
                        </div>
                    </DialogTitle>
                    <div style={{ margin: "0 20px" }}>
                        {renderOwners()}

                        <div className="text-center">
                            <Button
                                color="primary"
                                style={{ marginRight: 20 }}
                                onClick={() => {
                                    const cloned = clone(ertlFormData);
                                    cloned.property_owners = chosenOwners;
                                    const arr = chosenOwners.map(c => {
                                        return createOwnerName(c);
                                    })
                                    cloned.made_by_and_between = arr.join(' and ');
                                    setErtlFormData(cloned);
                                    setOpenedOwnerModal(false);
                                }}
                                disabled={chosenOwners.length === 0 ? true : false}
                            >
                                CHOOSE
                            </Button>
                            <Button
                                color="white"
                                style={{ marginRight: 10 }}
                                onClick={closeOwnerModal}
                            >
                                CANCEL
                            </Button>
                        </div>
                    </div>
                </Dialog>
            )}

            {edit !== null && <CustomCRMEditor 
                {...edit}
                close={() => {
                    setEdit(null)
                }}
            />}

            {askAddContactsToProperty !== null && <NotificationAsk 
                open={true}
                close={() => {
                    setAskAddContactsToProperty(null);
                }}
                title="Add as Property Owner?"
                message="Do you want to add this contact/company to the property as an owner?"
                success={() => {
                    if (askAddContactsToProperty.company_id) {
                        props.createCompanyProperties(askAddContactsToProperty)
                    } else {
                        props.addPropertyToContact(askAddContactsToProperty, 'property')
                    }
                    setAskAddContactsToProperty(null);
                    if (props.saveDraft) props.saveDraft();
                }}
                success_btn_text="YES"
            />}
        </div>
    );
};

const mapStateToProps = (state) => {
	const p = state.crm_properties;
	return {
		propertyData: p.propertyData,
        propertyOwners: p.propertyOwnersContacts
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProperty: (data) => {
            dispatch(actions.getProperty(data));
		},
        addPropertyToContact: (data, h) => {
            dispatch(actions.addPropertyToContact(data, h));
        },
        createCompanyProperties: (data, h) => {
            dispatch(actions.createCompanyProperties(data, h));
        }
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(ERTLTab1);
