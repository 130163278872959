import React, { useState, useEffect } from "react";
import { clone } from "shared/utility";
import PMADataModal from "./PMADataModal";
import { allowAdditionalSignature, fieldTypes } from "../PMAObj";
import { required } from "../../../shared/validation";
import { commatize, formatDate3 } from "../../../shared/utility";
import { getDifferences } from "containers/PMA/FormWizard/PMALogic";
import CloseComponent from "../../../components/Close/Close";
import CustomInputValidate from "components/CustomInput/CustomInputValidate";
import PMAExhibit from "./PMAExhibit";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import { includes } from "lodash";
import Button from "../../../components/CustomButtons/Button";
import { IconButton } from "@material-ui/core";
import { createPMAAddress, getSignatureInitialsDifference } from "../FormWizard/PMALogic";
import { generateOwnerIDs } from '../FormWizard/PMALogic';
import originalTextData from "../PMABaseTemplateText";
import NotificationAsk from "../../../components/Notification/NotificationAsk";

function addDashes(fvalue) {
    let f_val = fvalue.replace(/\D[^\.]/g, "");
    fvalue = f_val.slice(0, 3) + "-" + f_val.slice(3, 6) + "-" + f_val.slice(6);
    return fvalue;
}

const BaseTemplate = (props) => {
    const { formData, setFormData } = props;
    const { openModal, templateText } = props;
    const [openedDataModal, setOpenedDataModal] = useState(false);
    const [openedDataField, setOpenedDataField] = useState("");
    const [shouldShow, setShouldShow] = useState(true);
    const [hideSectionModal, setHideSectionModal] = useState(null);

    const ownerIDs = formData ? generateOwnerIDs(formData) : {};

    console.log('BaseTemplate', props);

    useEffect(() => {
        if (props.isTemplateForm) {
            //setShouldShow(false);
        }
    }, []);

    const openDataModal = (field) => {
        if (props.action === "Edit" || props.action === "Add") {
            if (!props.isTemplateForm) {
                setOpenedDataField(field);
                setOpenedDataModal(true);
            }
        }
    };

    const formInput = (
        field,
        addDollarSign,
        addComma,
        addPercent,
        addTheDashes
    ) => {
        let fieldSplit = field.split(".");
        let newField =
            fieldSplit.length > 1
                ? props.formData[fieldSplit[0]][fieldSplit[1]]
                : props.formData[field];
        const model =
            fieldSplit.length > 1
                ? fieldTypes[fieldSplit[0]][fieldSplit[1]]
                : fieldTypes[field];
        if (props.action === "template") {
            return (
                <span className="field-gray">{model ? model.label : ""}</span>
            );
        }
        if (addTheDashes) {
            return (
                <span
                    className={`hovered line-block ${
                        newField ? "green-block" : "red-block"
                    }`}
                    style={{
                        display: "inline-block",
                        margin: "10px 10px 0",
                        minWidth: 100,
                        minHeight: 30,
                        textIndent: 0
                    }}
                    onClick={() => {
                        openDataModal(field);
                    }}
                >
                    {!newField && (
                        <span className="gray-text">
                            {model ? model.label : ""}
                        </span>
                    )}
                    {newField && (
                        <span>
                            <strong>{addDashes(newField)}</strong>
                        </span>
                    )}
                </span>
            );
        }
        return (
            <span
                className={`hovered line-block ${
                    newField ? "green-block" : "red-block"
                }`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                    textIndent: 0
                }}
                onClick={() => {
                    openDataModal(field);
                }}
            >
                {!newField && (
                    <span className="gray-text">
                        {model ? model.label : ""}
                    </span>
                )}
                {newField && (
                    <span>
                        <strong>
                            {addDollarSign ? "$ " : ""}
                            {addComma
                                ? `${commatize(newField)}${
                                      addPercent ? "%" : ""
                                  }`
                                : `${newField}${addPercent ? "%" : ""}`}
                        </strong>
                    </span>
                )}
            </span>
        );
    };

    const formInputOwner = (field, addDollarSign, addComma, addPercent) => {
        let fieldSplit = field.split(".");
        const owner = formData.owner
            ? formData.owner.company_name
                ? formData.owner.company_name
                : formData.owner.name
            : "";
        const model =
            fieldSplit.length > 1
                ? fieldTypes[fieldSplit[0]][fieldSplit[1]]
                : fieldTypes[field];
        if (props.action === "template") {
            return (
                <span className="field-gray">{model ? model.label : ""}</span>
            );
        }
        return (
            <span
                className={`hovered line-block ${
                    owner ? "green-block" : "red-block"
                }`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                }}
                onClick={() => {
                    openDataModal(field);
                }}
            >
                {!owner ? "Owner" : ""}
                {owner}
            </span>
        );
    };

    const formInput2 = (field, owner_data, label, addTheDashes) => {
        const newField = owner_data[field];
        return (
            <span
                className={`hovered line-block green-block`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                    textIndent: 0
                }}
                onClick={() => {
                    if (props.setCurrForm && props.setTab) {
                        if (props.action === "Edit" || props.action === "Add") {
                            props.setTab("wizard");
                            props.setCurrForm(1);
                        }
                    }
                }}
            >
                {!newField && <span className="gray-text">{label}</span>}
                {newField && (
                    <span>
                        <strong>
                            {addTheDashes ? addDashes(newField) : newField}
                        </strong>
                    </span>
                )}
            </span>
        );
    };

    const formInputOwner2 = (field, owner_data, label) => {
        const newField = owner_data.company_name
            ? owner_data.company_name
            : owner_data.name;
        return (
            <span
                className={`hovered line-block green-block`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                    textIndent: 0
                }}
                onClick={() => {
                    if (props.setCurrForm && props.setTab) {
                        if (props.action === "Edit" || props.action === "Add") {
                            props.setTab("wizard");
                            props.setCurrForm(1);
                        }
                    }
                }}
            >
                {!newField && <span className="gray-text">{label}</span>}
                {newField && (
                    <span>
                        <strong>{newField}</strong>
                    </span>
                )}
            </span>
        );
    };

    const textData = props.templateData;

    useEffect(() => {
        const newFormData = clone(formData);
        if (
            textData &&
            textData.additional_signatures &&
            Array.isArray(textData.additional_signatures)
        ) {
            newFormData.additional_signatures = clone(
                textData.additional_signatures
            );
        } else {
            newFormData.additional_signatures = [];
        }
        if (
            textData &&
            textData.additional_initials &&
            Array.isArray(textData.additional_initials)
        ) {
            newFormData.additional_initials = clone(
                textData.additional_initials
            );
        } else {
            newFormData.additional_initials = [];
        }
        setFormData(newFormData);
    }, [textData]);

    const text = {};
    Object.keys(textData).forEach((f) => {
        text[f] = `${textData[f]}`;
    });

    const textInputs = {};
    const style =
        props.origin === "view"
            ? {}
            : { borderBottom: "1px solid rgb(251 251 251)" };

    let changedSections = [];
    if (props.historyDiffData) {
        props.historyDiffData.forEach((hdd) => {
            if (hdd) {
                if (hdd.templateDataChanges) {
                    if (Object.keys(hdd.templateDataChanges).length > 0) {
                        Object.keys(hdd.templateDataChanges).forEach((hddt) => {
                            if (changedSections.indexOf(hddt) === -1) {
                                changedSections.push(hddt);
                            }
                        });
                    }
                }
            }
        });
    }

    const getHistoryDiffs = (history, f, shouldShow) => {
        const author = props.origin === "approver" ? "Approver" : "";
        if (changedSections.indexOf(f) === -1) {
            return getDifferences(
                textData[f],
                templateText[f],
                shouldShow,
                author
            );
        }
        const lastHistory =
            history.length > 0 ? history[history.length - 1] : null;

        return (
            <div>
                {(props.origin === "edit" || props.origin === "approver") &&
                    getDifferences(
                        lastHistory ? lastHistory.templateText[f] : textData[f],
                        templateText[f],
                        shouldShow,
                        author
                    )}
                {history
                    .map((h, i) => {
                        if (h.templateDataChanges) {
                            if (
                                Object.keys(h.templateDataChanges).length === 0
                            ) {
                                return null;
                            }
                        }
                        let previous = textData[f];
                        if (i !== 0) {
                            previous = history[i - 1].templateText[f];
                        }
                        return (
                            <div
                                key={`${i}-history`}
                                className="tiny-boxed parentable"
                            >
                                <small className="gray-text author-block">
                                    {formatDate3(h.created_date)} MODIFIED BY{" "}
                                    {h.author}
                                </small>
                                {getDifferences(
                                    previous,
                                    h.templateText[f],
                                    shouldShow,
                                    h.author
                                )}
                            </div>
                        );
                    })
                    .reverse()}
            </div>
        );
    };

    Object.keys(originalTextData).forEach((f) => {
        if (f === "additional_signatures" || f === "additional_initials") {
        } else {
            let val = !props.historyDiffData
                ? getDifferences(templateText[f], textData[f], shouldShow)
                : "";
            if (!val) {
                //val = originalTextData[f] ? originalTextData[f] : '';
                //val = textData[f] ? textData[f] : '';
                // templateText = before - gathered from Template chosen
                // textData = after - gather from template data of current PMA entry
                val = getDifferences(templateText[f], textData[f], shouldShow);
            }
            const historyVal = props.historyDiffData
                ? getHistoryDiffs(props.historyDiffData, f, shouldShow)
                : "";
            textInputs[f] = (
                <span
                    key={`input-${f}`}
                    style={style}
                    onClick={() => {
                        if (props.templateOnClick) {
                            return props.templateOnClick(f);
                        }
                        if (props.origin !== "view") {
                            if (
                                props.action === "Edit" ||
                                props.action === "Add"
                            )
                                openModal(f);
                        }
                    }}
                    className="hoverable gray-hover bluish-hover text-input"
                >
                    {val}
                    {historyVal}
                </span>
            );
        }
    });

    const renderText = (field) => {
        let content = textInputs[field] ? textInputs[field] : '';
        if (field === 'manager_and_acceptance_title') {
            console.log('content', content, field, textInputs, textData, originalTextData);
        }
        if (!content) {
            if (originalTextData) {
                if (originalTextData[field]) {
                    content = originalTextData[field];
                }
            }
        }
        return content;
    };

    const renderSignaturesAndInitials = (field) => {
        const {
            additional_signatures,
            additional_initials,
            owner,
            other_owners,
        } = formData;
        const getSignatureDifferent = getSignatureInitialsDifference(
            field,
            templateText["additional_signatures"],
            textData["additional_signatures"],
            shouldShow
        );
        const getInitialsDifferent = getSignatureInitialsDifference(
            field,
            templateText["additional_initials"],
            textData["additional_initials"],
            shouldShow
        );

        const signaturesAndInitials = [];

        if (!includes(allowAdditionalSignature, field)) return "";
        if (!additional_initials || !additional_initials) return "";

        if (includes(additional_signatures, field)) {
            signaturesAndInitials.push(
                <div
                    className="font-18"
                    style={{
                        color: `${
                            getSignatureDifferent.isAdded ? "red" : "black"
                        }`,
                        marginBottom: "1rem",
						marginTop: "1rem",
                    }}
                    key={`${field}-1a`}
                >
                    Owner's Signatures
                    {(props.action === 'Edit' || props.action === 'Add') && (
                            <IconButton
                                onClick={() => {
                                    const newFormData = clone(formData);
                                    newFormData.additional_signatures =
                                        newFormData.additional_signatures.filter(
                                            (f) => f !== field
                                        );
                                    setFormData(newFormData);
                                    if (
                                        includes(
                                            textData.additional_signatures,
                                            field
                                        )
                                    ) {
                                        const newTextData = clone(textData);
                                        newTextData.additional_signatures =
                                            newTextData.additional_signatures.filter(
                                                (f) => f !== field
                                            );
                                        props.setTemplateData(newTextData);
                                    }
                                }}
                            >
                                <i className="fa fa-times"></i>
                            </IconButton>
                        )}
                </div>
            );
        }

        if (includes(additional_initials, field)) {
            signaturesAndInitials.push(
                <div
                    className="font-18"
                    style={{
                        color: `${
                            getInitialsDifferent.isAdded ? "red" : "black"
                        }`,
                        marginBottom: "1rem",
						marginTop: '1rem',
                    }}
                    key={`${field}-1`}
                >
                    Owner's Initials
                    {(props.action === 'Edit' || props.action === 'Add') && (
                            <IconButton
                                onClick={() => {
                                    const newFormData = clone(formData);
                                    newFormData.additional_initials =
                                        newFormData.additional_initials.filter(
                                            (f) => f !== field
                                        );
                                    setFormData(newFormData);
                                    if (
                                        includes(
                                            textData.additional_initials,
                                            field
                                        )
                                    ) {
                                        const newTextData = clone(textData);
                                        newTextData.additional_initials =
                                            newTextData.additional_initials.filter(
                                                (f) => f !== field
                                            );
                                        props.setTemplateData(newTextData);
                                    }
                                }}
                            >
                                <i className="fa fa-times"></i>
                            </IconButton>
                        )}
                </div>
            );
        }

        if (getSignatureDifferent.isRemoved) {
            signaturesAndInitials.push(
                <div
                    className="font-18"
                    style={{
                        padding: 5,
                        backgroundColor: "#ffecec",
                        color: "red",
                        marginBottom: "1rem",
                    }}
                >
                    Owner's Signature
                </div>
            );
        }

        if (getInitialsDifferent.isRemoved) {
            signaturesAndInitials.push(
                <div
                    className="font-18"
                    style={{
                        padding: 5,
                        backgroundColor: "#ffecec",
                        color: "red",
                        marginBottom: "1rem",
                    }}
                >
                    Owner's Initials
                </div>
            );
        }

        return signaturesAndInitials;
    };

    const generateOwners = () => {
        const owners = {};
        owners[formData.owner.company_name] = 1;
        formData.other_owners.forEach((oo, i) => {
            owners[oo.company_name] = 1;
        });
        return (
            <span
                className={`hovered line-block green-block`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                }}
                onClick={() => {
                    if (props.setCurrForm && props.setTab) {
                        if (props.action === "Edit" || props.action === "Add") {
                            props.setTab("wizard");
                            props.setCurrForm(1);
                        }
                    }
                }}
            >
                {Object.keys(owners).join(", ")}
            </span>
        );
    };

    const renderOwnerNames = () => {
        console.log('renderOwnerNames', ownerIDs);
        let ownerNames = [];
        if (ownerIDs) {
            Object.keys(ownerIDs).forEach(email => {
                if (formData.owner) {
                    if (formData.owner.email === email) {
                        if (ownerNames.indexOf(formData.owner.name) === -1) {
                            ownerNames.push(formData.owner.name);
                        }
                    }
                    if (formData.other_owners) {
                        if (formData.other_owners.length > 0) {
                            formData.other_owners.forEach(oo => {
                                if (oo.email === email) {
                                    if (ownerNames.indexOf(oo.name) === -1) {
                                        ownerNames.push(oo.name);
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }
        if (ownerNames.length > 0) {
            return <strong>{ownerNames.join(', ')}</strong>;
        }
        return null;
    }

    const getSectionStyle = (section) => {
        console.log('getting section style', section, textData);
        if (section) {
            if (textData) {
                if (textData[section] === 'hide') {
                    return {
                        display: 'none'
                    }
                }
            }
        }
        return {}
    }

    const renderSectionRemover = (section) => {
        if (!props.editTemplateMode) return null;
        return <span className="hoverable" onClick={() => {
            setHideSectionModal(section);
        }}><i className="fa fa-trash"></i></span>
    }

    const hiddenSections = textData ? Object.keys(textData).filter(d => {
        if (textData[d] === 'hide') {
            return true;
        }
    }) : [];

    console.log('PPPPMABAse', templateText);

    return (
        <div style={{ padding: "40px 60px" }} className="legible-text no-ol">
            <CloseComponent close={props.close} />
            {props.alert}
            <PMADataModal
                open={openedDataModal}
                close={() => {
                    setOpenedDataModal(false);
                }}
                field={openedDataField}
                formData={props.formData}
                setFormData={props.setFormData}
                saveDraft={props.saveDraft}
                updateDataObj={props.updateDataObj}
            />
            <br />
            {(props.action === "template" || props.isTemplateForm) && (
                <div style={{ maxWidth: 400, width: "100%" }}>
                    <CustomInputValidate
                        value={textData.title}
                        onChange={(e) => {
                            props.updateTemplateObj("title", e.target.value);
                        }}
                        label="Template Title"
                        validate={[required]}
                    />
                </div>
            )}
            <br />
            <div className="text-center">
                <img
                    src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                    style={{ width: "100px", height: "110px" }}
                />
                <br />
                <small>{renderText("cover_address")}</small>
            </div>
            <br />
            <p className="font-18 nline">{formInput("date")}</p>
            <br />
            <p className="font-18 nline">{renderText("cover0")}</p>
            <br />
            {renderSignaturesAndInitials("cover0")}
            <p className="font-18 nline">{renderText("cover1")}</p>
            {renderSignaturesAndInitials("cover1")}
            <br />
            <p className="font-18 nline">{renderText("cover2")}</p>
            {renderSignaturesAndInitials("cover2")}
            <br />
            <p className="font-18 nline">{renderText("cover3")}</p>
            {renderSignaturesAndInitials("cover3")}
            <br />
            <p className="font-18 nline">{renderText("cover4")}</p>
            {renderSignaturesAndInitials("cover4")}
            <br />
            <p className="font-18 nline">{renderText("cover5")}</p>
            {renderSignaturesAndInitials("cover5")}
            <br />
            <br />
            <p className="font-18 nline">
                {renderText("cover6_1")}
                <br />
                {renderText("cover6_2")}
                <br />
                {renderText("cover6_3")}
                <br />
            </p>
            <hr />
            <br />
            <div className="text-center">
                <img
                    src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                    style={{ width: "100px", height: "110px" }}
                />
            </div>
            <br />
            <div style={{ textAlign: "right", fontSize: 20 }}>
                <span
                    className="hoverable"
                    onClick={() => {
                        openDataModal("pma_id");
                    }}
                >
                    #{" "}
                    {formData.pma_id
                        ? formData.pma_id
                        : formData.id
                        ? formData.id
                        : ""}
                </span>
            </div>
            <h2>
                <center>
                    <div>
                        <b>{renderText('main_title')}</b>
                    </div>
                    <br />
                    <div>
                        <b>{createPMAAddress(formData) ? createPMAAddress(formData) : <span class="hovered line-block green-block" style={{display: 'inline-block', margin: '10px 10px 0px', minWidth: '100px', minHeight: '30px'}}>Property Address</span>}</b>
                    </div>
                </center>
            </h2>
            <br />
            <div>
                <div>
                    {renderText("a1")} <u>{renderText('a_agreement_title')}</u> {renderText("a2")}{" "}
                    {formInput("date")} {renderText("a3")} ("
                    <u>{renderText('a_manager_title')}</u>") {renderText('a_and')} {generateOwners()} <u>{renderText('a_owner_title')}</u>
                    {renderSignaturesAndInitials("a1")}
                </div>
                <br />
                <div className="text-center">
                    <u>{renderText('recitals')}</u>
                    <br />
                </div>
                <div>
                    <p>
                        {renderText("whereas_now")}{" "}
                        <strong>{createPMAAddress(formData) ? createPMAAddress(formData) : <span class="hovered line-block green-block" style={{display: 'inline-block', margin: '10px 10px 0px', minWidth: '100px', minHeight: '30px'}}>Property Address</span>}</strong> {renderText('b_property_title')}
                    </p>
                    {renderSignaturesAndInitials("whereas_now")}
                    <p>{renderText("whereas_now2")}</p>
                    {renderSignaturesAndInitials("whereas_now2")}
                    <div className="text-center">
                        <br />
                        <u>{renderText('b_agreement_title')}</u>
                        <br />
                    </div>
                </div>
            </div>
            <div>{renderText("now_therefore")}</div>
            {renderSignaturesAndInitials("now_therefore")}
            <br />
            <ol>
                <li 
                    style={getSectionStyle('manager_and_acceptance_section')}
                >
                    {renderSectionRemover('manager_and_acceptance_section')}
                    {renderText('n1')}
                    <span className="underlined2">
                        {renderText("manager_and_acceptance_title")}
                    </span>{" "}
                    {renderText("manager_and_acceptance")}
                    {renderSignaturesAndInitials("manager_and_acceptance")}
                </li>
                <br />
                <li
                    style={getSectionStyle('term_and_termination_section')}
                >
                    {renderSectionRemover('term_and_termination_section')}
                    {renderText('n2')}
                    <span className="underlined2">{renderText("term_and_termination_title")}</span>{" "}
                    {renderText("term_and_termination_p1")}{" "}
                    {formInput("commencement_date")}
                    {renderSignaturesAndInitials("term_and_termination_p1")}
                    {renderText("term_and_termination_p2")}
                    {renderSignaturesAndInitials("term_and_termination_p2")}
                </li>
                <br />
                <li
                    style={getSectionStyle('management_duties_section')}
                >
                    {renderSectionRemover('management_duties_section')}
                    {renderText('n3')}
                    <span className="underlined2">{renderText("management_duties_title")}</span>{" "}
                    {renderText("management_duties")}
                    {renderSignaturesAndInitials("management_duties")}
                    <br />
                    <br />
                    <ol type="a">
                        <li style={getSectionStyle('record_statements_collections_section')}>
                            {renderSectionRemover('management_duties_section')}
                            {renderText('n3a')}
                           <span className="underlined2">
                                {renderText("record_statements_collections_title")}
                            </span>{" "}
                            {renderText("record_statements_collections")}
                            {renderSignaturesAndInitials(
                                "record_statements_collections"
                            )}
                        </li>
                        <br style={getSectionStyle('record_statements_collections_section')} />


                        <li style={getSectionStyle('repair_maintenance_section')}>
                            {renderSectionRemover('repair_maintenance_section')}
                            {renderText('n3b')}{' '}
                            <span className="underlined2">
                                {renderText('repair_maintenance_title')}
                            </span>{" "}
                            {renderText("repair_maintenance")}
                            {renderSignaturesAndInitials("repair_maintenance")}
                        </li>
                        <br style={getSectionStyle('repair_maintenance_section')} />


                        <li style={getSectionStyle('insurance_proposals_section')}>
                            {renderSectionRemover('insurance_proposals_section')}
                            {renderText('n3c')}{' '}
                            <span className="underlined2">
                                {renderText('insurance_proposals_title')}
                            </span>{" "}
                            {renderText("insurance_proposals")}
                            {renderSignaturesAndInitials("insurance_proposals")}
                        </li>
                        <br style={getSectionStyle('insurance_proposals_section')} />


                        <li style={getSectionStyle('enforcement_section')}>
                            {renderSectionRemover('enforcement_section')}
                            {renderText('n3d')}{' '}
                            <span className="underlined2">{renderText('enforcement_title')}</span>{" "}
                            {renderText("enforcement")}
                            {renderSignaturesAndInitials("enforcement")}
                        </li>
                        <br style={getSectionStyle('enforcement_section')} />


                        <li style={getSectionStyle('payments_section')}>
                            {renderSectionRemover('payments_section')}
                            {renderText('n3e')}{' '}
                            <span className="underlined2">{renderText('payments_title')}</span>{" "}
                            {renderText("payments")}
                            {renderSignaturesAndInitials("payments")}
                        </li>
                        <br style={getSectionStyle('payments_section')} />


                        <li style={getSectionStyle('consultation_section')}>
                            {renderSectionRemover('consultation_section')}
                            {renderText('n3f')}{' '}
                            <span className="underlined2">{renderText('consultation_title')}</span>{" "}
                            {renderText("consultation")}
                            {renderSignaturesAndInitials("consultation")}
                        </li>
                        <br style={getSectionStyle('consultation_section')} />


                        <li style={getSectionStyle('security_deposits_section')}>
                            {renderSectionRemover('security_deposits_section')}
                            {renderText('n3g')}{' '}
                            <span className="underlined2">
                                {renderText('security_deposits_title')}
                            </span>{" "}
                            {renderText("security_deposits")}
                            {renderSignaturesAndInitials("security_deposits")}
                        </li>
                        <br style={getSectionStyle('security_deposits_section')} />


                        <li style={getSectionStyle('owner_distributions_section')}>
                            {renderSectionRemover('owner_distributions_section')}
                            {renderText('n3h')}{' '}
                            <span className="underlined2">
                                {renderText('owner_distributions_title')}
                            </span>{" "}
                            {renderText("owner_distributions")}
                            {renderSignaturesAndInitials("owner_distributions")}
                        </li>
                    </ol>
                </li>
                <br style={getSectionStyle('management_duties_section')} />


                <li style={getSectionStyle('leasing_responsibilities_section')}>
                    {renderSectionRemover('leasing_responsibilities_section')}
                    {renderText('n4')}{' '}
                    <span className="underlined2">
                        {renderText('leasing_responsibilities_title')}
                    </span>{" "}
                    {renderText("leasing_responsibilities_p1")}
                    {renderSignaturesAndInitials("leasing_responsibilities_p1")}
                    <br />
                    <br />
                    {renderText("leasing_responsibilities_p2")}
                    {renderSignaturesAndInitials("leasing_responsibilities_p2")}
                    <ul type="disc">
                        <li style={getSectionStyle('leasing_responsibilities_p3_section')}>
                            {renderSectionRemover('leasing_responsibilities_p3_section')}
                            {renderText('n4a')}{' '}
                            {renderText("leasing_responsibilities_p3")}
                            {renderSignaturesAndInitials(
                                "leasing_responsibilities_p3"
                            )}
                        </li>
                        <li style={getSectionStyle('leasing_responsibilities_p4_section')}>
                            {renderSectionRemover('leasing_responsibilities_p4_section')}
                            {renderText('n4b')}{' '}
                            {renderText("leasing_responsibilities_p4")}
                            {renderSignaturesAndInitials(
                                "leasing_responsibilities_p4"
                            )}
                        </li>
                        <li style={getSectionStyle('leasing_responsibilities_p5_section')}>
                            {renderSectionRemover('leasing_responsibilities_p5_section')}
                            {renderText('n4c')}{' '}
                            {renderText("leasing_responsibilities_p5")}
                            {renderSignaturesAndInitials(
                                "leasing_responsibilities_p5"
                            )}
                        </li>
                    </ul>
                </li>
                <br style={getSectionStyle('leasing_responsibilities_section')} />

                
                <li style={getSectionStyle('limitation_on_manager_authority_section')}>
                    {renderSectionRemover('limitation_on_manager_authority_section')}
                    {renderText('n5')}{' '}
                    <span className="underlined2">
                        {renderText('limitation_on_manager_authority_title')}
                    </span>{" "}
                    {renderText("limitation_on_manager_authority_p1")}
                    {renderSignaturesAndInitials(
                        "limitation_on_manager_authority_p1"
                    )}
                    <br />
                    <br />
                    <ol type="a">
                        <li style={getSectionStyle('limitation_on_manager_p2_section')}>
                            {renderSectionRemover('limitation_on_manager_p2_section')}
                            {renderText('n5a')}{' '}
                            {renderText("limitation_on_manager_authority_p2")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p2"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p3_section')}>
                            {renderSectionRemover('limitation_on_manager_p3_section')}
                            {renderText('n5b')}{' '}
                            {renderText("limitation_on_manager_authority_p3")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p3"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p4_section')}>
                            {renderSectionRemover('limitation_on_manager_p4_section')}
                            {renderText('n5c')}{' '}
                            {renderText("limitation_on_manager_authority_p4")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p4"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p5_section')}>
                            {renderSectionRemover('limitation_on_manager_p5_section')}
                            {renderText('n5d')}{' '}
                            {renderText("limitation_on_manager_authority_p5")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p5"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p6_section')}>
                            {renderSectionRemover('limitation_on_manager_p6_section')}
                            {renderText('n5e')}{' '}
                            {renderText("limitation_on_manager_authority_p6")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p6"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p7_section')}>
                            {renderSectionRemover('limitation_on_manager_p7_section')}
                            {renderText('n5f')}{' '}
                            {renderText("limitation_on_manager_authority_p7")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p7"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p8_section')}>
                            {renderSectionRemover('limitation_on_manager_p8_section')}
                            {renderText('n5g')}{' '}
                            {renderText("limitation_on_manager_authority_p8")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p8"
                            )}
                        </li>
                        <li style={getSectionStyle('limitation_on_manager_p9_section')}>
                            {renderSectionRemover('limitation_on_manager_p9_section')}
                            {renderText('n5h')}{' '}
                            {renderText("limitation_on_manager_authority_p9")}
                            {renderSignaturesAndInitials(
                                "limitation_on_manager_authority_p9"
                            )}
                        </li>
                    </ol>
                </li>
                <br style={getSectionStyle('limitation_on_manager_authority_section')} />


                <li style={getSectionStyle('owners_responsibilities_section')}>
                    {renderSectionRemover('owners_responsibilities_section')}
                    {renderText('n6')}{' '}
                    <span className="underlined2">
                        {renderText('owners_responsibilities_title')}
                    </span>{" "}
                    {renderText("owners_responsibilities_intro")}
                    {renderSignaturesAndInitials(
                        "owners_responsibilities_intro"
                    )}
                    <br />
                    <br />
                    <ol type="a">
                        <li
                            style={getSectionStyle('owners_responsibilities_generally_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_generally_section')}
                            {renderText('n6a')}{' '}
                            <span className="underlined2">{renderText('owners_responsibilities_generally_title')}</span>{" "}
                            {renderText("owners_responsibilities_generally")}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_generally"
                            )}
                        </li>
                        <br style={getSectionStyle('owners_responsibilities_generally_section')} />


                        <li
                            style={getSectionStyle('owners_responsibilities_insurance_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_insurance_section')}
                            {renderText('n6b')}{' '}
                            <span className="underlined2">{renderText('owners_responsibilities_insurance_title')}</span>{" "}
                            {renderText("owners_responsibilities_insurance")}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_insurance"
                            )}
                        </li>
                        <br style={getSectionStyle('owners_responsibilities_insurance_section')} />


                        <li
                            style={getSectionStyle('owners_responsibilities_legal_comp_n_disc_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_legal_comp_n_disc_section')}
                            {renderText('n6c')}{' '}
                            <span className="underlined2">
                                {renderText('owners_responsibilities_legal_comp_n_disc_title')}
                            </span>{" "}
                            {renderText(
                                "owners_responsibilities_legal_comp_n_disc"
                            )}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_legal_comp_n_disc"
                            )}
                        </li>
                        <br style={getSectionStyle('owners_responsibilities_legal_comp_n_disc_section')} />


                        <li
                            style={getSectionStyle('owners_responsibilities_security_depo_int_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_security_depo_int_section')}
                            {renderText('n6d')}{' '}
                            <span className="underlined2">
                                {renderText('owners_responsibilities_security_depo_int_title')}
                            </span>{" "}
                            {renderText(
                                "owners_responsibilities_security_depo_int"
                            )}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_security_depo_int"
                            )}
                        </li>
                        <br style={getSectionStyle('owners_responsibilities_security_depo_int_section')} />


                        <li
                            style={getSectionStyle('owners_responsibilities_owner_rep_p1_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_owner_rep_p1_section')}
                            {renderText('n6e')}{' '}
                            <span className="underlined2">
                                {renderText('owners_responsibilities_owner_rep_p1_title')}
                            </span>{" "}
                            {renderText("owners_responsibilities_owner_rep_p1")}{" "}
                            {renderOwnerNames()}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_owner_rep_p1"
                            )}
                            {renderText("owners_responsibilities_owner_rep_p2")}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_owner_rep_p2"
                            )}
                            {formInput(
                                "owner.phone",
                                false,
                                false,
                                false,
                                true
                            )}{" "}
                            {renderText("owners_responsibilities_owner_rep_p3")}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_owner_rep_p3"
                            )}
                        </li>
                        <br style={getSectionStyle('owners_responsibilities_owner_rep_p1_section')} />


                        <li
                            style={getSectionStyle('owners_responsibilities_tax_prep_section')}
                        >
                            {renderSectionRemover('owners_responsibilities_tax_prep_section')}
                            {renderText('n6f')}{' '}
                            <span className="underlined2">
                                {renderText('owners_responsibilities_tax_prep_title')}
                            </span>{" "}
                            {renderText("owners_responsibilities_tax_prep")}
                            {renderSignaturesAndInitials(
                                "owners_responsibilities_tax_prep"
                            )}
                        </li>
                    </ol>
                </li>
                <br style={getSectionStyle('owners_responsibilities_section')} />

                <li
                    style={getSectionStyle('owners_representations_section')}
                >
                    {renderSectionRemover('owners_representations_section')}
                    {renderText('n7')}{' '}
                    <span className="underlined2">
                        {renderText('owners_representations_title')}
                    </span>{" "}
                    {renderText("owners_representations")}
                    {renderSignaturesAndInitials("owners_representations")}
                </li>
                <br style={getSectionStyle('owners_representations_section')} />


                <li
                    style={getSectionStyle('management_fee_section')}
                >
                    {renderSectionRemover('management_fee_section')}
                    {renderText('n8')}{' '}
                    <span className="underlined2">{renderText('management_fee_title')}</span>
                    <br />
                    <br />
                    <ol type="a">
                        <li
                            style={getSectionStyle('management_fee_p1_section')}
                        >
                            {renderSectionRemover('management_fee_p1_section')}
                            {renderText('n8a')}{' '}
                            {renderText("management_fee_p1")}{" "}
                            {renderSignaturesAndInitials("management_fee_p1")}
                            {formData.monthly_fee_type
                                ? formData.monthly_fee_type === "Percentage"
                                    ? formInput(
                                          "monthly_fee_percentage",
                                          false,
                                          true,
                                          true
                                      )
                                    : formInput("monthly_fee_value", true, true)
                                : ""}{" "}
                            {renderText("management_fee_p2")}{" "}
                            {formInput("minimum_management_fee", true, true)}.{" "}
                            {renderSignaturesAndInitials("management_fee_p2")}
                            {renderText("management_fee_p3")}
                            {formData.monthly_fee_type
                                ? formData.monthly_fee_type === "Percentage"
                                    ? formInput(
                                          "monthly_fee_percentage",
                                          false,
                                          true,
                                          true
                                      )
                                    : formInput("monthly_fee_value", true, true)
                                : ""}{" "}
                            {renderSignaturesAndInitials("management_fee_p3")}
                            {renderText("management_fee_p4")}
                            {renderSignaturesAndInitials("management_fee_p4")}
                            <br />
                            <br />
                            {renderText("management_fee_p5")}
                            {renderSignaturesAndInitials("management_fee_p5")}
                        </li>
                        <br style={getSectionStyle('management_fee_p1_section')} />


                        <u>{renderText('additional_management_fee_schedule_title')}</u>
                        <br />
                        <br />
                        <li
                            style={getSectionStyle('add_manage_fee_sched_p1_section')}
                        >
                            {renderSectionRemover('add_manage_fee_sched_p1_section')}
                            {renderText('n8b')}{' '}
                            {renderText("add_manage_fee_sched_p1")}
                            {renderSignaturesAndInitials(
                                "add_manage_fee_sched_p1"
                            )}
                        </li>
                        <br style={getSectionStyle('add_manage_fee_sched_p1_section')} />
                        <br style={getSectionStyle('add_manage_fee_sched_p1_section')} />


                        <li style={getSectionStyle('add_manage_fee_sched_p2_section')}>
                            {renderSectionRemover('add_manage_fee_sched_p2_section')}
                            {renderText('n8c')}{' '}
                            {renderText("add_manage_fee_sched_p2")}
                            {renderSignaturesAndInitials(
                                "add_manage_fee_sched_p2"
                            )}
                        </li>
                        <br style={getSectionStyle('add_manage_fee_sched_p2_section')} />
                        <br style={getSectionStyle('add_manage_fee_sched_p2_section')} />


                        <li style={getSectionStyle('add_manage_fee_sched_p3_section')}>
                            {renderSectionRemover('add_manage_fee_sched_p3_section')}
                            {renderText('n8d')}{' '}
                            {renderText("add_manage_fee_sched_p3")}{" "}
                            {formInput("set_up_fee", true, true)}{" "}
                            {renderSignaturesAndInitials(
                                "add_manage_fee_sched_p3"
                            )}
                        </li>
                        <br style={getSectionStyle('add_manage_fee_sched_p3_section')} />
                        <br style={getSectionStyle('add_manage_fee_sched_p3_section')} />


                        <li style={getSectionStyle('add_manage_fee_sched_p4_section')}>
                            {renderSectionRemover('add_manage_fee_sched_p4_section')}
                            {renderText('n8e')}{' '}
                            {renderText("add_manage_fee_sched_p4")}
                            {renderSignaturesAndInitials(
                                "add_manage_fee_sched_p4"
                            )}
                        </li>
                        <br style={getSectionStyle('add_manage_fee_sched_p4_section')} />
                        <br style={getSectionStyle('add_manage_fee_sched_p4_section')} />


                        <li style={getSectionStyle('add_manage_fee_sched_p5_section')}>
                            {renderSectionRemover('add_manage_fee_sched_p5_section')}
                            {renderText('n8f')}{' '}
                            {renderText("add_manage_fee_sched_p5")}
                            {renderSignaturesAndInitials(
                                "add_manage_fee_sched_p5"
                            )}
                        </li>
                    </ol>
                    <br />
                </li>
                <li style={getSectionStyle('leasing_compensation_section')} >
                    {renderSectionRemover('leasing_compensation_section')}
                    {renderText('n9')}{' '}
                    <span className="underlined2">{renderText('leasing_compensation_title')}</span>{" "}
                    {renderText("leasing_comp")}
                    {renderSignaturesAndInitials("leasing_comp")}
                    <br />
                    <br />
                    <ol type="a">
                        <li style={getSectionStyle('leasing_comp_commissions_generally_section')}>
                            {renderSectionRemover('leasing_comp_commissions_generally_section')}
                            {renderText('n9a')}{' '}
                            <span className="underlined2">
                                {renderText('commissions_generally_title')}
                            </span>{" "}
                            {renderText("leasing_comp_commissions_generally")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_commissions_generally"
                            )}
                        </li>
                        <br />
                        <br />
                        <ol type="i">
                            <li style={getSectionStyle('leasing_comp_other_leases_section')}>
                                {renderSectionRemover('leasing_comp_other_leases_section')}
                                {renderText('n9a1')}{' '}
                                <span className="underlined2">
                                    {renderText('leases_other_than_month_to_month_title')}
                                </span>{" "}
                                {renderText("leasing_comp_other_leases")}
                                {renderSignaturesAndInitials(
                                    "leasing_comp_other_leases"
                                )}
                            </li>
                            <br style={getSectionStyle('leasing_comp_other_leases_section')} />


                            <li style={getSectionStyle('leasing_comp_m_to_m_lease_section')}>
                                {renderSectionRemover('leasing_comp_m_to_m_lease_section')}
                                {renderText('n9a2')}{' '}
                                <span className="underlined2">
                                    {renderText('leasing_comp_m_to_m_lease_title')}
                                </span>{" "}
                                {renderText("leasing_comp_m_to_m_lease")}
                                {renderSignaturesAndInitials(
                                    "leasing_comp_m_to_m_lease"
                                )}
                            </li>
                            <br style={getSectionStyle('leasing_comp_m_to_m_lease_section')} />


                            <li style={getSectionStyle('leasing_comp_renewals_extensions_section')}>
                                {renderSectionRemover('leasing_comp_renewals_extensions_section')}
                                {renderText('n9a3')}{' '}
                                <span className="underlined2">
                                    {renderText('leasing_comp_renewals_extensions_title')}
                                </span>{" "}
                                {renderText("leasing_comp_renewals_extensions")}
                                {renderSignaturesAndInitials(
                                    "leasing_comp_renewals_extensions"
                                )}
                            </li>
                            <br style={getSectionStyle('leasing_comp_renewals_extensions_section')} />
                        </ol>


                        <li style={getSectionStyle('leasing_comp_transaction_fees_section')}>
                            {renderSectionRemover('leasing_comp_transaction_fees_section')}
                            {renderText('n9b')}{' '}
                            <span className="underlined2">
                                {renderText('leasing_comp_transaction_fees_title')}
                            </span>{" "}
                            {renderText("leasing_comp_transaction_fees")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_transaction_fees"
                            )}
                        </li>
                        <br style={getSectionStyle('leasing_comp_transaction_fees_section')} />


                        <li style={getSectionStyle('leasing_comp_cooperating_brokers_section')}>
                            {renderSectionRemover('leasing_comp_cooperating_brokers_section')}
                            {renderText('n9c')}{' '}
                            <span className="underlined2">
                                {renderText('leasing_comp_cooperating_brokers_title')}
                            </span>{" "}
                            {renderText("leasing_comp_cooperating_brokers")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_cooperating_brokers"
                            )}
                        </li>
                        <br style={getSectionStyle('leasing_comp_cooperating_brokers_section')} />


                        <li style={getSectionStyle('leasing_comp_post_term_transactions_section')}>
                            {renderSectionRemover('leasing_comp_post_term_transactions_section')}
                            {renderText('n9d')}{' '}
                            <span className="underlined2">
                                {renderText('leasing_comp_post_term_transactions_title')}
                            </span>{" "}
                            {renderText("leasing_comp_post_term_transactions")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_post_term_transactions"
                            )}
                        </li>
                        <br style={getSectionStyle('leasing_comp_post_term_transactions_section')} />


                        <li style={getSectionStyle('leasing_comp_signage_permission_section')}>
                            {renderSectionRemover('leasing_comp_signage_permission_section')}
                            {renderText('n9e')}{' '}
                            <span className="underlined2">
                                {renderText('leasing_comp_signage_permission_title')}
                            </span>{" "}
                            {renderText("leasing_comp_signage_permission")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_signage_permission"
                            )}
                        </li>
                        <br style={getSectionStyle('leasing_comp_signage_permission_section')} />


                        <li style={getSectionStyle('leasing_comp_payments_to_manager_section')}>
                            {renderSectionRemover('leasing_comp_payments_to_manager_section')}
                            {renderText('n9f')}{' '}
                            <span className="underlined2">
                                {renderText('leasing_comp_payments_to_manager_title')}
                            </span>{" "}
                            {renderText("leasing_comp_payments_to_manager")}
                            {renderSignaturesAndInitials(
                                "leasing_comp_payments_to_manager"
                            )}
                        </li>
                        <br style={getSectionStyle('leasing_comp_payments_to_manager_section')} />
                    </ol> 
                </li>
                <br />
                <li style={getSectionStyle('indemnity_section')}>
                    {renderSectionRemover('indemnity_section')}
                    {renderText('n10')}{' '}
                    <span className="underlined2">{renderText('indemnity_title')}</span>{" "}
                    {renderText("indemnity")}
                    {renderSignaturesAndInitials("indemnity")}
                </li>
                <br style={getSectionStyle('indemnity_section')} />


                <li style={getSectionStyle('entire_agreement_section')}>
                    {renderSectionRemover('entire_agreement_section')}
                    {renderText('n11')}{' '}
                    <span className="underlined2">{renderText('entire_agreement_title')}</span>{" "}
                    {renderText("entire_agreement")}
                    {renderSignaturesAndInitials("entire_agreement")}
                </li>
                <br style={getSectionStyle('entire_agreement_section')} />

                <li style={getSectionStyle('attorneys_fees_section')}>
                    {renderSectionRemover('attorneys_fees_section')}
                    {renderText('n12')}{' '}
                    <span className="underlined2">{renderText('attorneys_fees_title')}</span>{" "}
                    {renderText("attorneys_fees")}
                    {renderSignaturesAndInitials("attorneys_fees")}
                </li>
                <br style={getSectionStyle('attorneys_fees_section')} />

                <li style={getSectionStyle('waiver_section')}>
                    {renderSectionRemover('waiver_section')}
                    {renderText('n13')}{' '}
                    <span className="underlined2">{renderText('waiver_title')}</span>{" "}
                    {renderText("waiver")}
                    {renderSignaturesAndInitials("waiver")}
                </li>
                <br style={getSectionStyle('waiver_section')} />


                <li 
                    style={getSectionStyle('additional_documents_section')}
                >
                    {renderSectionRemover('additional_documents_section')}
                    {renderText('n14')}{' '}
                    <span className="underlined2">{renderText('additional_documents_title')}</span>{" "}
                    {renderText("additional_documents")}
                    {renderSignaturesAndInitials("additional_documents")}
                </li>
                <br style={getSectionStyle('additional_documents_section')} />


                <li style={getSectionStyle('other_info_confidentiality_section')}>
                    {renderSectionRemover('other_info_confidentiality_section')}
                    {renderText('n15')}{' '}
                    <span className="underlined2">
                        {renderText('other_info_confidentiality_title')}
                    </span>{" "}
                    {renderText("other_info_confidentiality")}
                    {renderSignaturesAndInitials("other_info_confidentiality")}
                </li>
                <br style={getSectionStyle('other_info_confidentiality_section')} />


                <li style={getSectionStyle('manager_conflict_waiver_section')}>
                    {renderSectionRemover('manager_conflict_waiver_section')}
                    {renderText('n16')}{' '}
                    <span className="underlined2">
                        {renderText('manager_conflict_waiver_title')}
                    </span>{" "}
                    {renderText("manager_conflict_waiver")}
                    {renderSignaturesAndInitials("manager_conflict_waiver")}
                </li>
                <br style={getSectionStyle('manager_conflict_waiver_section')} />


                <li style={getSectionStyle('notices_section')}>
                    {renderSectionRemover('notices_section')}
                    {renderText('n17')}{' '}
                    <span className="underlined2">{renderText('notices_title')}</span>{" "}
                    {renderText("notices")}
                    {renderSignaturesAndInitials("notices")}
                    <div className="boxy">
                        <p>{renderText('c_manager_title')}</p>
                        <br />
                        <div>
                            {renderText("manager_data_company_name_division")}{" "}
                            {renderSignaturesAndInitials(
                                "manager_data_company_name_division"
                            )}
                        </div>
                        <div>
                            {renderText("manager_data_attn_person_title")}{" "}
                            {renderSignaturesAndInitials(
                                "manager_data_attn_person_title"
                            )}
                        </div>
                        <div>
                            {renderText("manager_data_address")}{" "}
                            {renderSignaturesAndInitials(
                                "manager_data_address"
                            )}
                        </div>
                        <div>
                            {renderText('c_tel_title')} {renderText("manager_data_phone_ext")} {" "}<br />
                            {renderText('c_fax_title')} {renderText("manager_data_phone_fax")} {" "}<br />
                            {renderSignaturesAndInitials("manager_data_phone_ext")}
                            {renderText('c_email_title')} {renderText("manager_data_email")} 
                            {renderSignaturesAndInitials("manager_data_email")}
                        </div>
                    </div>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <div className="boxy">
                                <p>{renderText('d_owner_title')}</p>
                                <div>
                                    {renderText('d_company_title')}{" "}
                                    {formInput("owner.company_name")}
                                </div>
                                <div>{renderText('d_name_title')} {formInput("owner.name")}</div>
                                <div>{renderText('d_title_title')} {formInput("owner.title")}</div>
                                <div>{renderText('d_address_title')} {formInput("owner.address")}</div>
                                <div>
                                    {renderText('d_tel_title')}{" "}
                                    {formInput(
                                        "owner.phone",
                                        false,
                                        false,
                                        false,
                                        true
                                    )}
                                </div>
                                <div>{renderText('d_email_title')} {formInput("owner.email")}</div>
                            </div>
                        </GridItem>
                        {formData.other_owners
                            ? formData.other_owners.map((oo, i) => {
                                  return (
                                      <GridItem xs={12} sm={6} md={6}>
                                          <div className="boxy">
                                              <p>{renderText('d_owner_title')}</p>
                                              <div>
                                                    {renderText('d_company_title')}{" "}
                                                  {formInput2(
                                                      "company_name",
                                                      oo
                                                  )}
                                              </div>
                                              <div>
                                                {renderText('d_name_title')} {formInput2("name", oo)}
                                              </div>
                                              <div>
                                                {renderText('d_title_title')}{" "}
                                                  {formInput2("title", oo)}
                                              </div>
                                              <div>
                                                {renderText('d_address_title')}{" "}
                                                  {formInput2("address", oo)}
                                              </div>
                                              <div>
                                                  {renderText('d_tel_title')}{" "}
                                                  {formInput2(
                                                      "phone",
                                                      oo,
                                                      "",
                                                      true
                                                  )}
                                              </div>
                                              <div>
                                                  {renderText('d_email_title')}{" "}
                                                  {formInput2("email", oo)}
                                              </div>
                                          </div>
                                      </GridItem>
                                  );
                              })
                            : null}
                    </GridContainer>
                </li>
                <br style={getSectionStyle('notices_section')} />

                <li style={getSectionStyle('no_partnership_section')}>
                    {renderSectionRemover('no_partnership_section')}
                    {renderText('n18')}{' '}
                    <span className="underlined2">{renderText('no_partnership_title')}</span>{" "}
                    {renderText("no_partnership")}
                    {renderSignaturesAndInitials("no_partnership")}
                </li>
                <br style={getSectionStyle('no_partnership_section')} />


                <li style={getSectionStyle('approval_section')}>
                    {renderSectionRemover('approval_section')}
                    {renderText('n19')}{' '}
                    <span className="underlined2">{renderText('approval_title')}</span>{" "}
                    {renderText("approval")}
                    {renderSignaturesAndInitials("approval")}
                </li>
                <br style={getSectionStyle('approval_section')} />


                <li style={getSectionStyle('limitation_of_liability_section')}>
                    {renderSectionRemover('limitation_of_liability_section')}
                    {renderText('n20')}{' '}
                    <span className="underlined2">
                        {renderText('limitation_of_liability_title')}
                    </span>{" "}
                    {renderText("limitation_of_liability")}
                    {renderSignaturesAndInitials("limitation_of_liability")}
                </li>
                <br style={getSectionStyle('limitation_of_liability_section')} />


                <li style={getSectionStyle('no_3rd_party_benef_section')}>
                    {renderSectionRemover('no_3rd_party_benef_section')}
                    {renderText('n21')}{' '}
                    <span className="underlined2">
                        {renderText('no_3rd_party_benef_title')}
                    </span>{" "}
                    {renderText("no_3rd_party_benef")}
                    {renderSignaturesAndInitials("no_3rd_party_benef")}
                </li>
                <br style={getSectionStyle('no_3rd_party_benef_section')} />


                <li style={getSectionStyle('severability_section')}>
                    {renderSectionRemover('severability_section')}
                    {renderText('n22')}{' '}
                    <span className="underlined2">{renderText('severability_title')}</span>{" "}
                    {renderText("severability")}
                    {renderSignaturesAndInitials("severability")}
                </li>
                <br style={getSectionStyle('severability_section')} />


                <li style={getSectionStyle('counterpart_facsimiles_section')}>
                    {renderSectionRemover('counterpart_facsimiles_section')}
                    {renderText('n23')}{' '}
                    <span className="underlined2">
                        {renderText('counterpart_facsimiles_title')}
                    </span>{" "}
                    {renderText("counterpart_facsimiles")}
                    {renderSignaturesAndInitials("counterpart_facsimiles")}
                </li>
                <br style={getSectionStyle('counterpart_facsimiles_section')} />


                <li style={getSectionStyle('governing_law_section')}>
                    {renderSectionRemover('governing_law_section')}
                    {renderText('n24')}{' '}
                    <span className="underlined2">{renderText('governing_law_title')}</span>{" "}
                    {renderText("governing_law")}
                    {renderSignaturesAndInitials("governing_law")}
                </li>
                <br style={getSectionStyle('governing_law_section')} />


                <li style={getSectionStyle('time_of_essence_section')}>
                    {renderSectionRemover('time_of_essence_section')}
                    {renderText('n25')}{' '}
                    <span className="underlined2">{renderText('time_of_essence_title')}</span>{" "}
                    {renderText("time_of_essence")}
                    {renderSignaturesAndInitials("time_of_essence")}
                </li>
                <br style={getSectionStyle('time_of_essence_section')} />


                <li style={getSectionStyle('successors_assigns_section')}>
                    {renderSectionRemover('successors_assigns_section')}
                    {renderText('n26')}{' '}
                    <span className="underlined2">{renderText('successors_assigns_title')}</span>{" "}
                    {renderText("successors_assigns")}
                    {renderSignaturesAndInitials("successors_assigns")}
                </li>
                <br style={getSectionStyle('successors_assigns_section')} />


                <li style={getSectionStyle('interpretation_section')}>
                    {renderSectionRemover('interpretation_section')}
                    {renderText('n27')}{' '}
                    <span className="underlined2">{renderText('interpretation_title')}</span>{" "}
                    {renderText("interpretation")}
                    {renderSignaturesAndInitials("interpretation")}
                </li>
                <br style={getSectionStyle('interpretation_section')} />


                <li style={getSectionStyle('managers_employees_section')}>
                    {renderSectionRemover('managers_employees_section')}
                    {renderText('n28')}{' '}
                    <span className="underlined2">{renderText('managers_employees_title')}</span>{" "}
                    {renderText("managers_employees")}
                    {renderSignaturesAndInitials("managers_employees")}
                </li>
                <br style={getSectionStyle('managers_employees_section')} />


                <li style={getSectionStyle('arbitration_of_disputes_section')}>
                    {renderSectionRemover('arbitration_of_disputes_section')}
                    {renderText('n29')}{' '}
                    <span className="underlined2">
                        {renderText('arbitration_of_disputes_title')}
                    </span>{" "}
                    {renderText("arbitration_of_disputes")}
                    {renderSignaturesAndInitials("arbitration_of_disputes")}
                </li>
            </ol>
            <div>
                {renderText("in_witness_whereof")}
                {renderSignaturesAndInitials("in_witness_whereof")}
                <br />
                <br />
                <center>
                    <table
                        style={{
                            padding: "20px",
                            border: "3px solid rgb(0, 0, 0)",
                        }}
                    >
                        <colgroup>
                            <col span="1" style={{ width: "50%" }} />
                            <col span="1" style={{ width: "50%" }} />
                            <col span="1" style={{ width: "15%" }} />
                        </colgroup>
                        <tbody>
                            <tr>
                                <th>{renderText('e_owner_title')}</th>
                                <th>{renderText('e_manager_title')}</th>
                            </tr>
                            <tr>
                                <td></td>
                                <td>
                                    {renderText('f_illi_title')}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div style={{ opacity: 0 }}>{renderText('by_one')}</div>
                                </td>
                                <td>
                                    <div style={{ opacity: 0 }}>{renderText('by_two')}</div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ verticalAlign: "top" }}>
                                    <div className="mb-20">
                                        <div>
                                            {renderText('g_owner_title')}{" "}
                                            {formData.owner.company_name
                                                ? formInput(
                                                      "owner.company_name"
                                                  )
                                                : formInput("owner.name")}
                                        </div>
                                        <div>
                                            {renderText('g_name_title')} {formInput("owner.name")}
                                        </div>
                                        <div>
                                            {renderText('g_title_title')} {formInput("owner.title")}
                                        </div>
                                    </div>
                                    {formData.other_owners.map((oo, i) => {
                                        return (
                                            <div
                                                className="mb-20"
                                                key={`oo-${i}`}
                                            >
                                                <hr />
                                                <div>
                                                    {renderText('g_owner_title')}{" "}
                                                    {formData.owner.company_name
                                                        ? formInput2(
                                                              "company_name",
                                                              oo
                                                          )
                                                        : formInput2(
                                                              "name",
                                                              oo
                                                          )}
                                                </div>
                                                <div>
                                                    {renderText('g_name_title')}{" "}
                                                    {formInput2("name", oo)}
                                                </div>
                                                <div>
                                                    {renderText('g_title_title')}{" "}
                                                    {formInput2("title", oo)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </td>
                                <td style={{ verticalAlign: "top" }}>
                                    <div className="mb-20">
                                        <div>
                                            {renderText('h_president_title')} {props.formData.president}
                                        </div>
                                        <div>{renderText('its_president')}</div>
                                        <div>{renderText('president_date')}</div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </center>
            </div>
            <div>
                <PMAExhibit
                    formData={props.formData}
                    setFormData={props.setFormData}
                />

                {(props.editTemplateMode && hiddenSections.length > 0) && <div className="boxed mt-20">
                    <h3>Sections Removed</h3>
                    {hiddenSections.map((h,i) => {
                        const field = h.split('_section')[0];
                        const fieldName = `${field}_title`;
                        const name = originalTextData[fieldName] ? originalTextData[fieldName] : field.split('_').join(' ').toUpperCase();
                        return <div className="list-entry" key={`hsfield-${i}`}>{name} <Button color="white" size="sm"
                            onClick={() => {
                                props.updateTemplateObj(h, '');
                            }}
                        >RETURN TO TEMPLATE</Button></div>
                    })}
                </div>}
            </div>

            {hideSectionModal && <div>
                <NotificationAsk 
                    title="Remove this section?"
                    message="Are you sure you want to remove this section?"
                    open={hideSectionModal ? true : false}
                    close={() => {
                        setHideSectionModal(null);
                    }}
                    success={() => {
                        props.updateTemplateObj(hideSectionModal, 'hide');
                        setHideSectionModal(null);
                    }}
                />    
            </div>}
        </div>
    );
};

export default BaseTemplate;
