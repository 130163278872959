import React, { useState } from "react";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import PaymentScheduleForm from "./PaymentScheduleForm";
import { getPaymentAmount, shouldGetPaymentAmount } from '../RSheetsLogic';
import { Dialog } from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { Close } from "@material-ui/icons";
import { clone } from "../../../shared/utility";

const paymentScheduleOptions = [
    "",
    "i. Due in Full",
    "ii. Due in Full Upon Lessee Obtaining its License",
    "iii. Due in Full Upon Removal of Contingencies",
    "iv. First Half Due Now",
    "v. First Half Due Upon Contingency Waiver",
    "vi. First Half Due Upon Lease Execution",
    "vii. Second Half Due Upon Lessee Opening for Business",
    "viii. Second Half Due Upon Contingency Removal",
    "ix. Second Half Due Upon Rent Commencement",
    "x. Second Half Due Upon Lease Commencement",
    "xi. Second Half Due On Later of Rent Commencement or Opening of Business",
    "xii. Second Half Due As Owner Receives Rent From Tenant",
    "xiii. Other -- Input Box To Specify",
];

const getOptions = (existingInvoices) => {
    let options = clone(paymentScheduleOptions);
    let hasFull = false;
    let hasFirstHalf = false;
    let hasSecondHalf = false;
    if (existingInvoices) {
        existingInvoices.forEach(ei => {
            if (ei.data) {
                if (ei.data.entries) {
                    ei.data.entries.forEach(eie => {
                        if (eie.option) {
                            if (eie.option.indexOf('First Half') !== -1) {
                                hasFirstHalf = true;
                            }
                            if (eie.option.indexOf('Second Half') !== -1) {
                                hasSecondHalf = true;
                            }
                            if (eie.option.indexOf('Full') !== -1) {
                                hasFull = true;
                            } 
                        }
                    })
                }
            }
        })
    }
    console.log('has it?', hasFull, hasFirstHalf, hasSecondHalf)
    const newOptions = options.filter(o => {
        if (hasFull || hasSecondHalf) {
            if (o.indexOf('First Half') !== -1 || o.indexOf('Second Half') !== -1 || o.indexOf('Full') !== -1) {
                return false;
            }
        }
        if (hasFirstHalf) {
            if (o.indexOf('First Half') !== -1 || o.indexOf('Full') !== -1) {
                return false;
            }
        }
        return true;
    });
    console.log('newOptions', newOptions);
    return newOptions;
}

const PaymentSchedule = (props) => {
    const {
        data,
        field,
        invoiceList,
        iv_index,
        total,
        totalPayment,
        handleUpdatePayment,
        payments,
        handleAddNewPayment,
        handleDeletePayment,
        updateDataObj,
    } = props;

    console.log('PaymentSchedule', props);

    const [editIndex, setEditIndex] = useState(null);
    const [openedAddPaymentSchedule, setOpenedAddPaymentSchedule] = useState(false);
    const [openedDeletePaymentSchedule, setOpenedDeletePaymentSchedule] = useState(-1);

    const isEditMode = (pm_index) => editIndex === pm_index;
    const setEditMode = (pm_index) => () => setEditIndex(pm_index);
    const closeEditMode = () => setEditIndex(null);

    const currentOptions = payments.map((pm) => pm.option).filter((op, index) => index !== editIndex);

    const addOptions = getOptions(data.invoices ? data.invoices : []);

    const getInvoices = () => { }

    return (
        <div className="bordered-list">
            <Button 
                color="primary"
                onClick={() => {
                    setOpenedAddPaymentSchedule(true);
                }}
            ><i className="fas fa-plus"></i> ADD ENTRY</Button>
            {openedAddPaymentSchedule && <Dialog 
                open={true}
                keepMounted  
                maxWidth="md" 
                fullWidth={true}
            >
                <div className="close-btn" onClick={() => { 
                    setOpenedAddPaymentSchedule(false);
                }}><Close /></div>
                <div className="text-center">
                    <h3>Add Entry</h3>
                </div>
                <div style={{padding:20}}>
                    <PaymentScheduleForm
                        currentOptions={addOptions}
                        data={data}
                        field={field}
                        handleAddNewPayment={handleAddNewPayment}
                        paymentScheduleOptions={paymentScheduleOptions}
                        totalPayment={totalPayment}
                        total={total}
                        invoiceList={invoiceList}
                        updateDataObj={updateDataObj}
                        setOpenedAddPaymentSchedule={setOpenedAddPaymentSchedule}
                        getInvoices={getInvoices}
                        pm_index={payments.length}
                    />
                </div>
            </Dialog>}
            <div style={{ padding: 20 }}>
                {payments.length === 0 && (
                    <li className="text-center">
                        <em>No entries</em>
                    </li>
                )}
                {payments.map((p, i) => {
                    if (isEditMode(i)) {
                        return (
                            <PaymentScheduleForm
                                closeEditMode={closeEditMode}
                                currentOptions={currentOptions}
                                data={data}
                                field={field}
                                initValues={p}
                                invoiceList={invoiceList}
                                handleAddNewPayment={handleAddNewPayment}
                                handleUpdatePayment={handleUpdatePayment}
                                paymentScheduleOptions={paymentScheduleOptions}
                                pm_index={i}
                                iv_index={iv_index}
                                total={total}
                                totalPayment={totalPayment}
                                updateDataObj={updateDataObj}
                                editMode
                                getInvoices={getInvoices}
                            />
                        );
                    }
                    
                    const shouldGetPayment = shouldGetPaymentAmount(p.option);
                    const paymentAmount = getPaymentAmount(data, p.option);
                    const displayOnly = i > 0 ? p.display_only ? 'DISPLAY ONLY' : null : null;
                    console.log('dis', displayOnly, i, p.display_only, p);
                    return (
                        <div className="boxed" key={`${i}-paymentss`}>
                            <GridContainer key={`pym-${i}`}>
                                <GridItem xs={12} sm={1} md={1}>
                                    <div style={{ marginTop: 33 }}>{`#${i + 1}`}</div>
                                </GridItem>
                                <GridItem xs={12} sm={2} md={2}>
                                    <CustomInput label="Payment Value" value={shouldGetPayment ? paymentAmount : p.value} readonly isDollar />
                                </GridItem>
                                <GridItem xs={12} sm={8} md={6}>
                                    <CustomInput label="Option" value={p.option} readonly />
                                </GridItem>
                                <GridItem xs={12} sm={6} md={2}>
                                    {displayOnly && <div>{displayOnly}</div>}
                                </GridItem>
                                <GridItem xs={12} sm={1} md={1}>
                                    <div className="hoverable mt-20" onClick={() => {
                                        console.log('deleting->i', i);
                                        setOpenedDeletePaymentSchedule(i)
                                    }}>
                                        <i className="fas fa-trash-alt red-text"></i>
                                    </div>
                                    <div className="hoverable mt-20" onClick={setEditMode(i)}>
                                        <i className="fa fa-edit"></i>
                                    </div>
                                </GridItem>
                            </GridContainer>
                            {p.option === "xiii. Other -- Input box to specify" && (
                                <GridContainer>
                                    <GridItem xs={12} sm={4} md={4}></GridItem>
                                    <GridItem xs={12} sm={7} md={7}>
                                        <div>
                                            <CustomInput label="Other Option" value={p.misc} readonly />
                                        </div>
                                    </GridItem>
                                </GridContainer>
                            )}
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    <div><strong>Notes :</strong> {p.notes}</div>
                                </GridItem>
                            </GridContainer>
                        </div>
                    );
                })}
            </div>

            {openedDeletePaymentSchedule !== -1 && <NotificationAsk 
                title="Are you sure?"
                message="Do you want to delete this entry?"
                open={true}
                close={() => {
                    setOpenedDeletePaymentSchedule(-1);
                }}
                success={() => {
                    handleDeletePayment(openedDeletePaymentSchedule);
                    setOpenedDeletePaymentSchedule(-1);
                }}
            />}
        </div>
    );
};

export default PaymentSchedule;
