import React from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';

const LeaseList = (props) => {
    const { leasesData, setLeaseData, setTab } = props;
    
    const data = leasesData ? leasesData.map((l, key) => { // map users to table rows
        const other_data = l.other_data ? 
                                (typeof l.other_data === 'string') ? 
                                    JSON.parse(l.other_data)
                                :
                                    l.other_data
                            : 
                                {agents:[],lessees:[],lessors:[]}
        return ({
            id: l.id,
            start_date: l.start_date ? formatDate2(l.start_date) : '', 
            expiration_date: l.expiration_date ? formatDate2(l.expiration_date) : '',
            expiration_alert_date: l.expiration_alert_date ? formatDate2(l.expiration_alert_date) : '',
            agents: other_data ? other_data.agents.map(a => {
                    return a.name;
                }).join(', ') : '',
            lessees: other_data ? other_data.lessees.map(a => {
                return a.name;
            }).join(', ') : '',
            lessors: other_data ? other_data.lessors.map(a => {
                return a.name;
            }).join(', ') : '',
            leaseData: l
        })
    }) : [];

    const columns = [
        {
            Header: "Lease ID #",
            accessor: "id" 
        },
        {
            Header: "Lease Start Date",
            accessor: "start_date"
        },
        {
            Header: "Lease Expiration Date",
            accessor: "expiration_date"
        },
        {
            Header: "Lease Expiration Alert Date",
            accessor: "expiration_alert_date"
        },
        {
            Header: "Agents",
            accessor: "agents"
        },
        {
            Header: "Lessees",
            accessor: "lessees"
        },
        {
            Header: "Lessors",
            accessor: "lessors"
        }
    ]

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                sorted={[
                    {
                     id: 'id',
                     desc: true
                    }
                ]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          
                          if (rowInfo) {
                            if (rowInfo.original) {
                              setLeaseData(rowInfo.original.leaseData)
                              if (props.canEdit) {
                                setTab('Edit');
                              } else {
                                setTab('View');
                              }
                            }
                          }
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
            />
        </div>
    )
}

export default LeaseList;