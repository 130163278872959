import { Dialog, DialogTitle, IconButton } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import ReactTable from "react-table";
import { LISTING_STATUS, STATUS, textData } from "./ListingObj";
import { Edit } from "@material-ui/icons";
import { clone, get } from "lodash";
import Button from "components/CustomButtons/Button.jsx";
import { canEdit, generateHTML } from "./ListingLogic";
import ListingPdfModal from "./ListingPdfModal";
import jwt_decode from "jwt-decode";
import { getUser } from "../../shared/authValidation";

const ListingList = (props) => {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);

    const [entryToView, setEntryToView] = useState(null);

    let viewPdfOnly = true;
    // Handle agent action
    const agentList = get(entryToView, "data.agentList", []);
    const user = getUser();
    const status = get(entryToView, "status", null);

    if (user && user.mail && status === LISTING_STATUS.PENDING) {
        agentList.forEach((agent) => {
            if (
                agent.primaryEmail === user.mail &&
                (!agent.approveStatus ||
                    agent.approveStatus === LISTING_STATUS.PENDING)
            ) {
                viewPdfOnly = false;
            }
        });
    } 

	const canApprove = canEdit(status, user, agentList);
	
    // let canApprove = false;
    // if (user && user.permObj) {
    //     if (
    //         user.permObj.hasOwnProperty("Listing approve level_4") &&
    //         status === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4
    //     )
    //         canApprove = true;
    // }

    const [localPdfData, setLocalPdfData] = useState(null);
    const [openPDFview, setOpenPDFview] = useState(false);

    const [localHTMLData, setLocalHTMLData] = useState(null);

    const [openedAskModal, setOpenedAskModal] = useState(null);

    const openAskModal = (listingForm) => setOpenedAskModal(listingForm);
    const closeAskModal = () => setOpenedAskModal(false);

    const openApproveModal = (entry) => {
        const htmlEntry = generateHTML(entry.data, textData);
        setLocalHTMLData(htmlEntry.html);
        setEntryToView(entry);
        props.getListingPdf({ id: 1, entry: htmlEntry.html });
        setOpenPDFview(true);
    };

    const handleFinalApprove = () => {
        if (!entryToView) return;
        const { updateListingEntry } = props;
        const newEntry = clone(entryToView);
        newEntry.status = LISTING_STATUS.APPROVED;
        updateListingEntry(newEntry);
    };

    const handleApprove = () => {
        if (!entryToView) return;
        const { updateListingEntry } = props;
        const newEntry = clone(entryToView);
        newEntry.status = LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_2;
        updateListingEntry(newEntry);
    };

    const handleDecline = () => {
        if (!entryToView) return;
        const { updateListingEntry } = props;
        const newEntry = clone(entryToView);
        newEntry.status = LISTING_STATUS.DECLINED;
        updateListingEntry(newEntry);
    };

    useEffect(() => {
        const newColumns = [
            {
                Header: "illi ID #",
                accessor: "id",
            },
            {
                Header: "Title",
                accessor: "title",
            },
            {
                Header: "Date Created",
                accessor: "date_created",
            },
        ];
        if (props.tab === LISTING_STATUS.DRAFT) {
            newColumns.push(
                ...[
                    {
                        Header: "Status",
                        accessor: "formattedStatus",
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                    },
                ]
            );
        } else if (
            props.tab === LISTING_STATUS.PENDING ||
            props.tab === LISTING_STATUS.APPROVED ||
            props.tab === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4
        ) {
            newColumns.push(
                ...[
                    {
                        Header: "Submitter",
                        accessor: "data.submitted",
                    },
                    {
                        Header: "Approvers",
                        accessor: "agentList",
                    },
                    {
                        Header: "Status",
                        accessor: "formattedStatus",
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                    },
                ]
            );
        } else if (props.tab === LISTING_STATUS.DECLINED) {
            newColumns.push(
                ...[
                    {
                        Header: "Submitter",
                        accessor: "data.submitted",
                    },
                    {
                        Header: "Approvers",
                        accessor: "agentList",
                    },
                    {
                        Header: "Declined By",
                        accessor: "declinedBy",
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                    },
                ]
            );
        }

        setColumns(newColumns);
    }, [props.tab]);

    useEffect(() => {
        const { pdfData } = props;
        if (pdfData && typeof pdfData === "string") {
            const parsedPdf = JSON.parse(pdfData);
            setLocalPdfData(parsedPdf);
        }
    }, [props.pdfData]);

    useEffect(() => {
        let listItems = [];
        if (props.tab === LISTING_STATUS.DRAFT) {
            listItems = props.data.map((entry) => {
                const agentNameList = entry.data.agentList
                    ? entry.data.agentList
                          .map((agent) => agent.name.fullName)
                          .join(", ")
                    : "";

                return {
                    ...entry,
                    formattedStatus: STATUS[entry.status],
                    agentList: agentNameList,
                    actions: (
                        <div>
                            <IconButton
                                onClick={() => {
                                    props.open(entry);
                                }}
                            >
                                <Edit />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    openAskModal(entry);
                                }}
                            >
                                <i className="fas fa-trash font-22" />
                            </IconButton>
                        </div>
                    ),
                };
            });
        } else if (
            props.tab === LISTING_STATUS.PENDING ||
            props.tab === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4
        ) {
            listItems = props.data.map((entry) => {
                const agentNameList = entry.data.agentList
                    ? entry.data.agentList
                          .map((agent) => agent.name.fullName)
                          .join(", ")
                    : "";

				const agents = get(entry, "data.agentList", []);
                const userCanEdit = canEdit(entry.status, user, agents);

                return {
                    ...entry,
                    agentList: agentNameList,
                    formattedStatus: STATUS[entry.status],
                    actions: (
                        <div>
                            <IconButton onClick={() => openApproveModal(entry)}>
                                <i className="fas fa-edit font-22" />
                            </IconButton>
                            {userCanEdit && (
                                <IconButton
                                    onClick={() => {
                                        props.open(entry);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            )}
                        </div>
                    ),
                };
            });
        } else if (props.tab === LISTING_STATUS.DECLINED) {
            listItems = props.data.map((entry) => {
                const declinedBy = entry.data.agentList
                    ? entry.data.agentList
                          .filter(
                              (agent) =>
                                  agent.approveStatus &&
                                  agent.approveStatus ===
                                      LISTING_STATUS.DECLINED
                          )
                          .map((agent) => agent.name.fullName)
                          .join(", ")
                    : "";

                const agentNameList = entry.data.agentList
                    ? entry.data.agentList
                          .map((agent) => agent.name.fullName)
                          .join(", ")
                    : "";

                const canSubmitAgain =
                    get(user, "id") === get(entry, "user_id");

                return {
                    ...entry,
                    agentList: agentNameList,
                    declinedBy,
                    actions: (
                        <div>
                            <IconButton onClick={() => openApproveModal(entry)}>
                                <i className="fa fa-eye" />
                            </IconButton>
                            {canSubmitAgain && (
                                <IconButton
                                    onClick={() => {
                                        props.open(entry);
                                    }}
                                >
                                    <Edit />
                                </IconButton>
                            )}
                        </div>
                    ),
                };
            });
        } else if (props.tab === LISTING_STATUS.APPROVED) {
            listItems = props.data.map((entry) => {
                const agentNameList = entry.data.agentList
                    ? entry.data.agentList
                          .map((agent) => agent.name.fullName)
                          .join(", ")
                    : "";

                return {
                    ...entry,
                    agentList: agentNameList,
                    actions: (
                        <div>
                            <IconButton onClick={() => openApproveModal(entry)}>
                                <i className="fa fa-eye" />
                            </IconButton>
                        </div>
                    ),
                };
            });
        }
        setData(listItems);
    }, [props.data]);

    const listItems = data.map((item) => {
        return {
            ...item,
            formattedStatus: STATUS[item.status],
        };
    });

    return (
        <div className="table-adjust2">
            <ReactTable
                filterable
                className="-striped -highlight"
                showPaginationTop
                showPaginationBottom
                resizable={true}
                defaultPageSize={10}
                pageSizeOptions={[10, 20, 30]}
                data={listItems}
                columns={columns}
            />

            {localPdfData && openPDFview && (
                <ListingPdfModal
                    action={props.tab}
                    pdfData={localPdfData}
                    htmlData={localHTMLData}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        setOpenPDFview(false);
                    }}
                    handleDecline={handleDecline}
                    handleApprove={handleApprove}
                    viewOnly={viewPdfOnly}
                    canApprove={canApprove}
                    handleFinalApprove={handleFinalApprove}
                />
            )}

            <Dialog open={openedAskModal ? true : false}>
                <DialogTitle>
                    Are you sure you want to delete this Listing Form?
                </DialogTitle>
                <div style={{ textAlign: "center", padding: 20 }}>
                    <Button
                        color="danger"
                        style={{ marginRight: 10 }}
                        onClick={() => {
                            if (openedAskModal.id) {
                                props.deleteListingEntry(openedAskModal.id);
                            }
                            closeAskModal();
                        }}
                    >
                        YES, DELETE
                    </Button>
                    <Button
                        color="white"
                        style={{ marginRight: 10 }}
                        onClick={closeAskModal}
                    >
                        CANCEL
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

export default ListingList;
