import React from "react";
import { useEffect } from 'react';
import { clone } from 'lodash';

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import Element from "../InvoiceElement";
import { invoiceFields } from '../InvoiceModel';

export const invoiceFormInfoFields = ["date", "title", "illi", "invoice"];

const InvoiceFormInfo = (props) => {
    const { formData, setFormData, updateDataObj } = props;

    useEffect(() => {
        const newFormData = clone(formData);
        if (!newFormData.invoice) {
            newFormData.invoice = '001';
            setFormData(newFormData);
        }
    }, [])

    const renderElement = (field, access) => {
        return (
            <Element
                updateDataObj={updateDataObj}
                fieldModels={invoiceFields}
                data={formData}
                setData={setFormData}
                field={field}
                access={access}
                permissions={props.permissions}
            />
                );
            };
        const renderFields = () => {
        return invoiceFormInfoFields.map((field, index) => (
            <React.Fragment key={`invoiceField-${index}`}>
                {renderElement(field)}
            </React.Fragment>
        ));
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderFields()}
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default InvoiceFormInfo;
