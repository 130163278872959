import { Dialog, DialogTitle } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React, { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Table from "components/Table/Table.jsx";
import CompaniesForm from "../CRM/Companies/CompaniesForm";
import { get, isEmpty } from "lodash";
import { clone } from "shared/utility";

const DialPadMergeCompany = (props) => {
    const {
        contactId,
        contactToImport,
        createCompany,
        getCompanies,
        getContact,
        contactData,
        companyOptions,
        loading,
        companyToUpdate,
        handleSkip,
        setCompany,
		goTo,
        contact,
    } = props;

    const [searchedValue, setSearchedValue] = useState("");
    const [options, setOptions] = useState([]);

    const [openedNewCompany, setOpenedNewCompany] = useState(false);

    useEffect(() => {
        if (contactToImport) {
            setSearchedValue(contactToImport.company_name);
        }
    }, [contactToImport]);


    useEffect(() => {
        if (contactId) {
            getContact(contactId);
        }
    }, [contactId]);

    useEffect(() => {
        if (searchedValue && searchedValue.length > 1) {
            getCompanies({
                name: searchedValue,
            });
        }
    }, [searchedValue]);

    const companyData = {
        name: get(contactToImport, "company_name", ""),
    };

    const contactToAdd = contact ? {
        contact_id: contactId,
        company_id: null,
        is_company: true,
        department: "",
        title: get(contactToImport, "job_title", ""),
        email: get(contactToImport, "primary_email", ""),
        phone: get(contactToImport, "primary_phone", ""),
        contact_data: contactData,
    } : null;


    const companyToImportInfoTable = get(contactToImport, 'company_name', null) && (
        <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
            <Table
                tableData={[["Company Name", "company_name"]].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        contactToImport[field[1]],
                    ];
                })}
            />
        </div>
    );

    return (
        <React.Fragment>
            {isEmpty(companyToUpdate) && (
                <div>
                    <Button color="primary" onClick={handleSkip}>
                        SKIP
                    </Button>
                </div>
            )}
            {companyToImportInfoTable}
            <Button
                className="outlined-btn"
                size="sm"
                onClick={() => {
                    setOpenedNewCompany(true);
                }}
            >
                <i className="fa fa-plus"></i> CREATE NEW COMPANY
            </Button>

            <h3 style={{ textAlign: "center" }}>Select Company</h3>

            <CustomInput
                label="Search Companies"
                value={searchedValue}
                onChange={(e) => setSearchedValue(e.target.value)}
            />

            <div style={{ textAlign: "center", padding: 20 }}>
                {options && (
                    <div
                        style={{
                            height: !searchedValue ? 100 : 300,
                            overflowY: "scroll",
                            padding: 10,
                            backgroundColor: "#f1f1f1",
                        }}
                    >
                        {loading && (
                            <div className="text-center">
                                <ClipLoader />
                            </div>
                        )}
                        {!searchedValue && (
                            <div style={{ marginTop: 20 }}>
                                <em>
                                    Enter letters or numbers from the company's name
                                    <br />
                                    you are searching for in the line above
                                </em>
                            </div>
                        )}

                        {!loading && searchedValue && (
                            <List component="nav">
                                {companyOptions.map((com, i) => {
                                    return (
                                        <ListItem button key={`company-option-${i}`}>
                                            <ListItemText inset primary={com.name} onClick={() => {
												setCompany(com)
												goTo(3)
												}} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </div>
                )}
            </div>

            <Dialog open={openedNewCompany} keepMounted maxWidth={false} fullWidth={true} fullScreen={true}>
                <div className="dialog-wrapper">
                    <CompaniesForm
                        action="Add"
                        closeForm={() => setOpenedNewCompany(false)}
                        createCompany={createCompany}
                        companyData={companyData}
                        contactToAdd={contactToAdd}
                    />
                </div>
            </Dialog>

            {/* <Dialog open={openedAskModal} keepMounted onClose={closeAskModal}>
                <DialogTitle>Are you sure to assign contact to this company?</DialogTitle>

                <div style={{ textAlign: "center", padding: 20 }}>
                    <h3>{contactData ? `${contactData.first_name} ${contactData.last_name}` : ""}</h3>
                    <Table
                        tableData={[
                            ["Department", "department"],
                            ["Title", "title"],
                            ["Phone", "phone"],
                            ["Email", "email"],
                        ].map((field, index) => {
                            return [
                                <div style={{ textAlign: "right" }}>
                                    <strong>{field[0]}</strong>
                                </div>,
                                renderInput(field[1]),
                            ];
                        })}
                    />

                    <Button color="danger" onClick={handleAssignContact}>
                        Confirm
                    </Button>
                    <Button onClick={closeAskModal}>Cancel</Button>
                </div>
            </Dialog> */}
        </React.Fragment>
    );
};

export default DialPadMergeCompany;
