
import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import contactFields, { addressFields, otherAddressFields, partnerFields, translateBinary } from "./ContactsObj";
import { FadeLoader } from 'react-spinners';
import { capitalizeAll, removeWhitespace } from "shared/utility";
import {
    min1, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max20, max50, max100, max250, max500
} from "shared/validation";
import moment from 'moment';
import { compareAddress, comparePartners, cleanSubtypes } from './ContactsLogic';
import { capitalize } from '../../../shared/utility';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';
const validate = {
    min1, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max20, max50, max100, max250, max500
}

const getValidations = (f,val) => {
    if (!contactFields[f]) return 
    if (!contactFields[f].validations) return null;
    const validations = contactFields[f].validations.map(v => {
        return validate[v];
    });
    const errors = validations.map(v => {
        const result = v(val);
        if (result) return result;
        return null;
    }).filter(v => {
        if (!v) return false;
        return true;
    });
    if (errors.length > 0) return errors.join(', ');
    return '';
}

const getAddressValidations = (add) => {
    let errorsAll = [];
    if (add) {
        Object.keys(add).forEach(field => {
            if (!addressFields.fields[field]) return 
            if (!addressFields.fields[field].validations) return 
            const errors = addressFields.fields[field].validations.map(v => {
                
                const e = validate[v](add[field]);
                if (e) {
                    return `${field.toUpperCase().replace('_',' ')} - ${e}`;
                }
                return '';
            }).filter(e => e ? true : false);
            errorsAll = errorsAll.concat(errors);
        })
    }
    if (errorsAll.length > 0) return errorsAll.join(', ');
    return '';
}

const getPartnerValidations = (pd) => {
    let errorsAll = [];
    if (pd) {
        Object.keys(pd).forEach(field => {
            if (field !== 'id' && field !== 'contact_id') {
                
                if (partnerFields[field]) {
                    const errors = partnerFields[field].validations.map(v => {
                        return validate[v](pd[field]);
                    }).filter(e => e ? true : false);
                    errorsAll = errorsAll.concat(errors);
                }
            }
        })
    }
    if (errorsAll.length > 0) return errorsAll.join(', ');
    return '';
}

const ContactsFormReview = (props) => {
    const [reviewObj, setReviewObj] = useState({
        errors: {},
        addressErrors: {},
        otherAddressErrors: {},
        partnersErrors: {}
    });
    const [finalErrorCount, setFinalErrorCount] = useState(0);
    const { dataObj, addressData, partnersData, formDataObj } = props;

    useEffect(() => {
        const newReviewObj = {};

        const errors = {};
        let errorCount = 0;

        if (!dataObj.first_name || !dataObj.last_name) {
            errorCount++;
            errors.name = 'First name and last name is required';
        }

        // ALL OTHERS
        const main_info_arr = ['first_name','middle_initial','last_name','company_name',
        'email','birthdate','use_type','salutation',
        'spouse_first_name','title','website_url',
        'department'];

        const phone_numbers_arr = ['main_phone','home_phone','mobile_phone',
        'fax','other_phone','assistant_phone'];

        const preferences_arr = ['do_not_call','looking_to_buy','opted_out_of_email',
        'preferred_contact_method','price_range','preferred_contact_time',
        'category'];

        const information_arr = ['last_attempted_contact_date','last_contacted_date',
        'is_past_client','lead_source_category','lead_status',
        'email_bounced_reason','lead_source','lead_source_detail',
        'notes','referral_source','relocating_time_frame',
        'assistant_email','assistant_name','company_represented'];

        const combined_arr = main_info_arr
            .concat(phone_numbers_arr)
            .concat(preferences_arr)
            .concat(information_arr);

        combined_arr.forEach(f => {
            errors[f] = getValidations(f, dataObj[f]);
            if (errors[f]) errorCount++;
        });
        if (dataObj.birthdate) {
            const isRealDate = moment(dataObj.birthdate, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.birthdate = 'Birthdate must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }
        if (dataObj.last_attempted_contact_date) {
            const isRealDate = moment(dataObj.last_attempted_contact_date, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.last_attempted_contact_date = 'Last attempted contact date must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }
        if (dataObj.last_contacted_date) {
            const isRealDate = moment(dataObj.last_contacted_date, "MM/DD/YYYY", true).isValid();
            if (!isRealDate) {
                errors.last_contacted_date = 'Last contacted date must be a valid MM/DD/YYYY formatted date';
                errorCount++;
            }
        }

        if (dataObj.exclusive_right_to_represent_buyer === 'Yes') {
            if (!dataObj.air_exclusive_right_to_represent_buyer) {
                errors.exclusive_right_to_represent_buyer = 'Please upload AIR exclusive right to represent buyer document';
                errorCount++;
            }
        }
        newReviewObj.errors = errors;

        // ADDRESS
        const addressErrors = {};
        ['mailing','accounting','business','legal','property_management'].forEach(addType => {
            const err = getAddressValidations(addressData[addType]);
            if (err) {
                addressErrors[addType] = err;
                errorCount++;
            }
        })
        newReviewObj.addressErrors = addressErrors;

        const otherAddressErrors = {};
        addressData.other.forEach((add,i) => {
            const index = `add_${i}`;
            otherAddressErrors[index] = getAddressValidations(add);
            if (otherAddressErrors[index]) errorCount++;
        })
        newReviewObj.otherAddressErrors = otherAddressFields;

        // PARTNERS
        const partnerErrors = {};
        partnersData.forEach((pd,i) => {
            
            const err = getPartnerValidations(pd);
            if (err) {
                partnerErrors[`pd_${i}`] = err;
                errorCount++;
            }
        })
        newReviewObj.partnersErrors = partnerErrors;
        
        setReviewObj(newReviewObj);
        setFinalErrorCount(errorCount);

        if (errorCount === 0 && props.action === 'Edit') {
            doUpdate();
            props.setMadeChanges(false);
            return;
        }
        if (errorCount === 0 && props.action === 'Add') {
            doCreate();
            setTimeout(() => {
                props.closeFormFinal();
            }, 1000)
            return;
        }
    }, []);

    const { errors, addressErrors, otherAddressErrors, partnersErrors } = reviewObj;
    const errorCount = finalErrorCount;

    
    

    const success = props.success ?
                        props.success.indexOf('Success') !== -1 ?
                            true
                            :
                            false
                        :
                        false;

    const doCreate = () => {
        const binariedData = translateBinary(dataObj);
        binariedData.first_name = capitalizeAll(binariedData.first_name);
        binariedData.last_name = capitalizeAll(binariedData.last_name);
        if (binariedData.metadata) {
            binariedData.metadata = JSON.stringify(binariedData.metadata);
        }
        props.createContact(
            removeWhitespace(cleanSubtypes(binariedData)), 
            partnersData, 
            addressData,
            props.appointmentsForAdd, 
            props.sharesForAdd, 
            formDataObj, 
            'no message', 
            props.companiesForAdd,
            props.propertiesForAdd,
            props.entitiesData,
            props.dbasForAdd
        );
    }

    const doUpdate = () => {
        const binariedData = translateBinary(dataObj);
        binariedData.first_name = capitalizeAll(binariedData.first_name);
        binariedData.last_name = capitalizeAll(binariedData.last_name);
        if (binariedData.metadata) {
            binariedData.metadata = JSON.stringify(binariedData.metadata);
        }
        const partnersUpdateData = comparePartners(props.currentPartnersData,
            partnersData,
            dataObj.id);
        const addressUpdateData = compareAddress(props.currentAddressData,
            addressData,
            dataObj.id);

        props.updateContact(
            removeWhitespace(cleanSubtypes(binariedData)),
            partnersUpdateData,
            addressUpdateData
        );
    }

    return (
    <Dialog
        open={true}
        keepMounted  
        maxWidth="sm" 
        fullWidth={true}
        onClose={() => {
            props.setOpenedReview(false);
        }}
    >
        <div className="dialog-wrapper-small text-center">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                <div className="close-btn" onClick={() => { props.setOpenedReview(false); }}><Close /></div>
                <h2>Contact</h2>
                {(errorCount > 0 && !success) ? <div>
                        <h4 style={{color:'red'}}>Please correct these errors before submitting.</h4>
                        <hr />
                    </div> : null}

                {(errorCount === 0 && !success) ? <div>
                        {(props.action === 'Edit' && !props.error) && <div>
                            <h4 className="success-text">Saving... </h4>
                            <div className="fade-loader-center">
                                <FadeLoader />
                            </div>
                        </div>}
                        <hr />
                    </div> : null}

                {Object.keys(errors).map(field => {
                    if (!errors[field]) return null;
                    const label = contactFields[field] ? 
                            contactFields[field].label ? 
                                contactFields[field].label
                            :
                                capitalize(field)
                        :
                            capitalize(field)
                    return <div key={field} style={{marginBottom:20}}>
                        <span style={{marginRight:20}}><strong>{label}</strong></span>
                        <span style={{color:'red'}}>{errors[field]}</span>
                    </div>
                })}
                {Object.keys(addressErrors).map(addType => {
                    if (addressErrors[addType]) {
                        return <div key={`${addType}_add`} className="boxed">
                        <h4><strong>{addType.toUpperCase().replace('_',' ')}</strong></h4>
                        <span style={{color:'red'}}>{addressErrors[addType]}</span>
                        </div>
                    }
                })}
                {Object.keys(otherAddressErrors).map(adds => {
                    if (otherAddressErrors[adds]) {
                        const num = parseInt(adds.split('_')[1]) + 1;
                        return <div key={`${adds}_add`} className="boxed">
                        <h4><strong>{`Other Address #${num}`}</strong></h4>
                        <span style={{color:'red'}}>{otherAddressErrors[adds]}</span>
                        </div>
                    }
                })}
                {Object.keys(partnersErrors).map(pds => {
                    if (partnersErrors[pds]) {
                        const num = parseInt(pds.split('_')[1]) + 1;
                        return <div key={`${pds}_add`} className="boxed">
                        <h4><strong>{`Partner #${num}`}</strong></h4>
                        <span style={{color:'red'}}>{partnersErrors[pds]}</span>
                        </div>
                    }
                })}
                    <div style={{marginTop:20}}>
                        <div className="success-text">
                            <h4>{props.success}</h4>
                        </div>
                        {!props.success && <div style={{color:'red'}}>
                            <h4>{props.error}</h4>
                        </div>}
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <div className="text-center">
                        <Button color="primary" onClick={() => {
                            props.setOpenedReview(false);
                            if (props.choose) {
                                if (!(errorCount > 0 && !success)) {
                                    props.choose(dataObj);
                                }
                            }
                        }}>OK</Button>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
    );
}

const mapDispatchToProps = dispatch => {
    return {
      clearCRMMessages: () => {
        dispatch(actions.clearCRMMessages());
      },
    }
  }
  
  export default connect(null, mapDispatchToProps)(ContactsFormReview);