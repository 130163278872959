import React, {useEffect, useState, useCallback} from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { clone } from '../../shared/utility';
import { getUser } from '../../shared/authValidation';
import ContactsNewCompany from '../../containers/CRM/Contacts/ContactsNewCompany';
import CompaniesForm from '../../containers/CRM/Companies/CompaniesForm';
import { IconButton } from '@material-ui/core';
import Notification from '../Notification/Notification';

const CustomDialogCompanyView = (props) => {
    const [formDataObj, setFormDataObj] = useState({});
    const [notification, setNotification] = useState('');
    
    useEffect(() => {
        props.getCompany(props.companyID);
    }, []);

    return <div>
                {props.open && (
						<Dialog
						open={props.open}
						keepMounted
						maxWidth={false}
						fullWidth={true}
						fullScreen={false}
					>
						<div className="dialog-wrapper">
							{props.companyData && <CompaniesForm
								action="Edit"
								closeForm={() => {
									props.close();
								}}
								// openForm={openForm}
								// openAskModal={openAskModal}
								success={props.success}
								updateCompany={props.updateCompany}
								// deleteCompany={props.deleteCompany}
								error={props.error}
								companyData={props.companyData}
								loading={props.loading}
								formDataObj={formDataObj}
								setFormDataObj={setFormDataObj}
								// setOpenedAsk={setOpenedAsk}
								showCloseBtn={true}
								notification={notification}
								setNotification={setNotification}
							/>}
						</div>
					</Dialog>
					)}

                    {notification && <Notification 
						open={notification ? true : false}
						close={() => { setNotification('') }}
						message={notification}
					/>}
    </div>
}

const mapStateToProps = state => {
    const c = state.crm_companies;
    return {
        options: c.companiesData,
		loading: c.loading,
		illi_companies: c.illi_companies,
		own_companies: c.own_companies,
		own_shared_companies: c.own_shared_companies,
		companyData: c.companyData,
		error: c.error,
		success: c.success,
		loading: c.loading
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getCompanies: (params) => {
            dispatch(actions.getCompanies(params));
        },
		getCompany: (id) => {
			dispatch(actions.getCompany(id));
		},
		updateCompany: (data) => {
			dispatch(actions.updateCompany(data));
		}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogCompanyView);