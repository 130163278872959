import React, { useEffect, useState } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import { getDateDiffs, commatize } from '../../../shared/utility';
import {
    calculateAbatedRanges_RS as calculateAbatedRanges,
    calculateStartingRent,
    makeTotalCalcs,
    makeAllRanges,
} from '../RSheetsLogic'
import moment from 'moment';

const Step3Sale = (props) => {
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);
    const { renderElement, data } = props;

    useEffect(() => {
        const calc = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges
        });
        setCalculatedAbatedRanges(calc);
        
    }, [data.lease_term, data.abated_rent_ranges]);

    const allRanges = makeAllRanges(data);

    const convert = (date) => {
        const darr = date.split('/');
        const y = darr[2];
        const m = darr[0];
        const d = darr[1];
        return `${y}-${m}-${d}`;
    }
    const getDateDifference = () => {
        let rent_commencement_is_more = false;
        if (data.rent_commencement && data.lease_start_date) {
            if (moment(data.rent_commencement, 'MM/DD/YYYY', true).isValid() && moment(data.lease_start_date, 'MM/DD/YYYY', true).isValid()) {
                const timestamp1 = moment(data.rent_commencement, 'MM/DD/YYYY', true).unix();
                const timestamp2 = moment(data.lease_start_date, 'MM/DD/YYYY', true).unix();
                if (timestamp1 > timestamp2) {
                    //rent_commencement_is_more = true;
                }
            }
        }
        const date1 = rent_commencement_is_more ? data.rent_commencement : data.lease_start_date;
        const date2 = data.lease_end_date;
        if (date1 && date2) {
            const diffObj = getDateDiffs(convert(date1), convert(date2));
            
            return <div>{diffObj.years} Years, {diffObj.months} Months, {diffObj.days} Days</div>
        }
        return null;
    }

    const checkIfExists = () => {
        let exists = false;
        if (data.lease_term) {
            if (data.lease_term.length > 0) {
                data.lease_term.forEach(dl => {
                    if (dl.ranges) {
                        
                        if (dl.ranges.length > 0) exists = true;
                    }
                })
            }
        }
        if (data.abated_rent_ranges) {
            if (data.abated_rent_ranges > 0) {
                data.abated_rent_ranges.forEach(ar => {
                    if (ar.ranges) {
                        
                        if (ar.ranges.length > 0) exists = true;
                    }
                })
            }
        }
        return exists;
    }

    const renderRentTotals = () => {
        const { rentCollected, the_abated_total, netCollected, 
            the_commissions_added, the_calculatedAR, netCommission } = makeTotalCalcs(allRanges, calculatedAbatedRanges, data);
        return <div>
            <h4>Gross Rent Collected : <span className="bold">$ {commatize(rentCollected, true)}</span></h4>
            <h4>Rent Abated : <span className="bold">$ ({commatize(the_abated_total, true)})</span></h4>
            <h4>Net Rent Collected : <span className="bold">$ {commatize(netCollected, true)}</span></h4>
            <h4>Gross Commission : <span className="bold">$ {commatize(the_commissions_added, true)}</span></h4>
            <h4>Abated Commission : <span className="bold">$ ({commatize(the_calculatedAR, true)})</span></h4>
            <h4>Net Commission : <span className="bold">$ {commatize(netCommission, true)}</span></h4>
        </div>
    }

    const warning = 'WARNING! Changing this value will erase the previous Lease Term and Abated Rent Range data. ';

    
    return <GridContainer>
        {/* Escrow Information */}
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_purchase_price')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_commission_rate')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_sale_commission')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_escrow_opening_date')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_escrow_closing_date')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_building_sqft')}
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
            {renderElement('sale_land_sqft')}
        </GridItem>
    </GridContainer>
}

export default Step3Sale;
