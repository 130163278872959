import React, { useEffect, useState } from "react";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { Dialog, IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { clone, formatDollar, parseFloatString } from "../../../shared/utility";
import NotificationAsk from "../../../components/Notification/NotificationAsk";

const InvoicePaymentLines = props => {
    const [openedAddPaymentLines, setOpenedAddPaymentLines] = useState(false);
    const [openedEditPaymentLines, setOpenedEditPaymentLines] = useState(-1);
    const [openedDeletePaymentLines, setOpenedDeletePaymentLines] = useState(-1);
    const [description, setDescription] = useState('');
    const [amount, setAmount] = useState('');

    const { updateDataObj, data, invoice_index } = props;

    const invoice_lines = data.invoices[invoice_index].data.line_items ? 
        data.invoices[invoice_index].data.line_items 
    : 
        [];

    console.log('InvoicePaymentLines', data, invoice_lines);

    return <>
        <Button
            color="primary"
            style={{marginBottom:20}}
            onClick={() => {
                setOpenedAddPaymentLines(true);
            }}
        ><i className="fas fa-plus"></i> ADD LINE ITEM</Button>

        {invoice_lines.length === 0 && <div className="text-center">
                <em>* No invoice line entries</em>
        </div>}

        {invoice_lines.length > 0 && <div className="boxed">
            <h4>Line Items</h4>

            {invoice_lines && invoice_lines.map((dil, i) => {
                return <GridContainer key={`dil-${i}`}>
                    <GridItem xs={12} sm={1} md={1}>
                        <div style={{ marginTop: 20 }}>{`#${i + 1}`}</div>
                    </GridItem>

                    <GridItem xs={12} sm={3} md={3}>
                        <div style={{ marginTop: 20 }}>{formatDollar(dil.amount)}</div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <div style={{ marginTop: 20 }}>{dil.description}</div>
                    </GridItem>
                    <GridItem xs={12} sm={2} md={2}>
                        <IconButton
                            style={{marginRight:20}}
                            onClick={() => {
                                setOpenedEditPaymentLines(i);
                                setDescription(dil.description);
                                setAmount(dil.amount);
                            }}
                        ><i className="fas fa-edit"></i></IconButton>
                        <IconButton
                            onClick={() => {
                                setOpenedDeletePaymentLines(i);
                            }}
                        ><i className="fas fa-trash"></i></IconButton>
                    </GridItem>
                </GridContainer>
            })}
        </div>}

        {/* ADD  */}
        {openedAddPaymentLines && <Dialog 
            open={true}
            keepMounted  
            maxWidth="sm" 
            fullWidth={true}
            >
            <div className="close-btn" onClick={() => { 
                setOpenedAddPaymentLines(false); 
                setAmount('');
                setDescription('');
            }}><Close /></div>
            <div style={{padding:20,marginTop:60}} className="text-center">

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="text-center">
                            <h3>Add Line Item</h3>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput 
                            label="Amount"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            isDollar={true}
                            noDollarSign={true}
                            startAdornment="$"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput 
                            label="Description"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <hr />
                        <Button className="mr-20" color="primary" disabled={description && amount ? false : true} onClick={() => { 
                            setOpenedAddPaymentLines(false); 
                            const newData = clone(data);
                            newData.invoices[invoice_index].data.line_items = invoice_lines.concat([{
                                amount: parseFloatString(amount),
                                description
                            }]);
                            updateDataObj('invoices', newData.invoices);
                            setAmount('');
                            setDescription('');
                        }}>ENTER LINE ITEM</Button>

                        <Button color="white" onClick={() => {
                            setOpenedAddPaymentLines(false);
                            setAmount('');
                            setDescription('');
                        }}>CANCEL</Button>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>}

        {/* EDIT  */}
        {openedEditPaymentLines !== -1 && <Dialog 
            open={true}
            keepMounted  
            maxWidth="sm" 
            fullWidth={true}
            >
            <div className="close-btn" onClick={() => { 
                setOpenedEditPaymentLines(-1); 
                setAmount('');
                setDescription('');
            }}><Close /></div>
            <div style={{padding:20,marginTop:60}} className="text-center">

                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <div className="text-center">
                            <h3>Update Line Item</h3>
                        </div>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput 
                            label="Amount"
                            value={amount}
                            onChange={(e) => {
                                setAmount(e.target.value);
                            }}
                            isDollar={true}
                            noDollarSign={true}
                            startAdornment="$"
                        />
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <CustomInput 
                            label="Description"
                            value={description}
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <hr />
                        <Button className="mr-20" color="primary" disabled={description && amount ? false : true} onClick={() => {
                            const newData = clone(data);
                            newData.invoices[invoice_index].data.line_items[openedEditPaymentLines] = {
                                amount: parseFloatString(amount),
                                description: description
                            }
                            updateDataObj('invoices', newData.invoices);
                            setOpenedEditPaymentLines(-1);
                            setAmount('');
                            setDescription('');
                        }}>UPDATE LINE ITEM</Button>

                        <Button color="white" onClick={() => {
                            setOpenedEditPaymentLines(-1);
                            setAmount('');
                            setDescription('');
                        }}>CANCEL</Button>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>}

        {openedDeletePaymentLines !== -1 && <NotificationAsk 
            title="Are you sure?"
            message="Do you want to delete this invoice line item?"
            open={true}
            close={() => {
                setOpenedDeletePaymentLines(-1);
            }}
            success={() => {
                const newData = clone(data);
                newData.invoices[invoice_index].data.line_items = invoice_lines.filter((dil,x) => {
                    if (x !== openedDeletePaymentLines) {
                        return true;
                    }
                });
                updateDataObj('invoices', newData.invoices);
                setOpenedDeletePaymentLines(-1);
            }}
        />}
    </>
}

export default InvoicePaymentLines;