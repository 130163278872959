import React, { useEffect, useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import ReactTable from 'react-table';
import axios from "store/axios/axios-gci";
import { clone, formatTimestamp, handleError } from "../../shared/utility";
import { IconButton } from "@material-ui/core";
import moment from "moment-timezone";
import CustomSimpleDialog from "../../components/CustomDialog/CustomSimpleDialog";
import GCIHistoryTable from "./GCIHistoryTable";

const GCI_HISTORY_STATUS = {
    ADD: 1,
    UPDATE: 2,
    DELETE: 3
}

const GCI_HISTORY = {}

Object.keys(GCI_HISTORY_STATUS).forEach(key => {
    GCI_HISTORY[GCI_HISTORY_STATUS[key]] = key;
})

const GCIHistory = props => {
    const [entries, setEntries] = useState([]);
    const [modal, setModal] = useState(null);

    const getHistory = async () => {
        try {
            const response = await axios.get('/history');
            setEntries(response.data);
        } catch(e) {
            handleError(e);
            alert('Error getting history');
        }
    }
    useEffect(() => {
        getHistory();
    }, []);

    const data = entries ? entries.map(e => {
        const data = e.data;
        const actionStr = GCI_HISTORY[e.action];
        let action = '';
        let id = '';
        if (actionStr === 'ADD') {
            const last = data.after[data.after.length - 1];
            action = `${actionStr} LEVEL ${last.commission_level}`;
            id = last.id;
        } else if (actionStr === 'UPDATE') {
            let a = null;
            data.after.forEach(da => {
                if (da.id == data.id) {
                    a = da;
                }
            })
            if (a) { 
                action = `${actionStr} LEVEL ${a.commission_level}`;
                id = a.id;
            }
        } else if (actionStr === 'DELETE') {
            let a = null;
            console.log('data', data)
            data.before.forEach(da => {
                if (da.id == data.id) {
                    a = da;
                }
            })
            if (a) {
                action = `${actionStr} LEVEL ${a.commission_level}`;
                id = a.id;
            }
        }
        return {
            date: formatTimestamp(e.date),
            user: e.name,
            action,
            actions: <div>
                <IconButton
                    onClick={() => {
                        const dat = clone(e.data);
                        dat.id = id;
                        dat.action = action;
                        console.log('dat', dat, e)
                        setModal(dat);
                    }}
                ><i className="fa fa-eye"></i></IconButton>
            </div>
        }
    }) : [];

    const columns = [
        {
            Header: "Date",
            accessor: "date"
        },
        {
            Header: "User",
            accessor: "user"
        },
        {
            Header: "Action",
            accessor: "action"
        },
        {
            Header: "Actions",
            accessor: "actions"
        },
    ]

    return <>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h1>History</h1>
                <ReactTable
                    filterable
                    className='-striped -highlight'
                    showPaginationTop
                    showPaginationBottom
                    resizable={false}
                    defaultPageSize={10}
                    pageSizeOptions={[10, 20, 30]}
                    data={data}
                    columns={columns}
                    getTrProps={(state, rowInfo) => {
                        if (rowInfo && rowInfo.row) {
                            return {
                                onClick: (e) => {
                                    if (rowInfo && rowInfo.original) {
                                        console.log('row', rowInfo);
                                    }
                                }
                            }
                        } else {
                            return {}
                        }
                    }}
                />
            </GridItem>
        </GridContainer>

        {modal && <CustomSimpleDialog 
            open={true}
            close={() => {
                setModal(null);
            }}
            content={<>
                <h2>{modal.action}</h2>
                <h3>Before Update</h3>
                <GCIHistoryTable 
                    tableData={modal.before}
                    id={modal.id}
                />
                <hr />
                <h3>After Update</h3>
                <GCIHistoryTable 
                    tableData={modal.after}
                    id={modal.id}
                />
            </>}
        />}
    </>
}

export default GCIHistory;