import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { Dialog } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Close, ArrowBack } from "@material-ui/icons";
import { defaultTRData } from "./TREntriesModel";
import { checkForChanges, cloneJ } from "shared/utility";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomDialogWithProperty from "components/CustomDialog/CustomDialogWithProperty";
import Notification from "components/Notification/Notification";
import NotificationAsk from "components/Notification/NotificationAsk";
import moment from "moment";
import NotificationFixedTop from "components/Notification/NotificationFixedTop";
import { getUser } from "shared/authValidation";
import checkPermissionsActions from 'shared/checkPermissionsActions';
import TREntriesFormWizard from "./TREntriesFormWizard";
import TREntriesFormFull from "./TREntriesFormFull";
import FormElement from "../TRElement";
import { clone } from "../../../shared/utility";
import { createAddressObject } from "../../CRM/Properties/PropertiesLogic";
import { generateSections, dontSaveArr } from "./TREntriesLogic";
import { get } from "lodash";
import { chooseContactCompanyObj } from "./TREntriesLogic";

const actionDict = {
    add: 'Add',
    edit: 'Edit',
    view: 'View'
}

const TREntriesForm = (props) => {
    const [tab, setTab] = useState('wizard');
    const [step, setStep] = useState(1);
    const [dataObj, setDataObj] = useState(defaultTRData);
    const [initialData, setInitialData] = useState(defaultTRData);
    const [openedPropertyModal, setOpenedPropertyModal] = useState(false);
    const [propertyOwner, setPropertyOwner] = useState(null);
    const tabIndex = tab === 'wizard' ? 0 : 1;
    const [isGettingPropertyContacts, setIsGettingPropertyContacts] = useState(false);
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [notificationTop, setNotificationTop] = useState('');
    const [notification, setNotification] = useState('');
    const [close, setClose] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openedChooseModal, setOpenedChooseModal] = useState(false);
    const [queryFor, setQueryFor] = useState({
        label: 'Tenants',
        type: 'tenants',
        title: 'Tenants'
    })
    const [notifyOnClose, setNotifyOnClose] = useState(false);
    const [prefilling, setPrefilling] = useState(false);

    useEffect(() => {
        console.log('loading', props);
        props.getTrApprovers();
        props.getPropertyUseType();
        if (props.formData) {
            const newData = cloneJ(props.formData);
            newData.notes = '';
            setDataObj(newData);
            setInitialData(cloneJ(newData));
        } else {
            const now = moment().format('MM/DD/YYYY');
            const newData = cloneJ(dataObj);
            newData.date = now;
            const current_user = getUser();
            console.log('current_user', current_user);
            newData.submitted_by_name = current_user.name;
            newData.submitted_by_id = current_user.id;
            setDataObj(newData);
        }
        if (props.prefill_property_id) {
            setPrefilling(true);
            props.getProperty(props.prefill_property_id);
        }
    }, [])

    useEffect(() => {
        if (props.propertyData && prefilling) {
            chooseProperty(props.propertyData);
            window.history.replaceState(null, null, '/tr');
            setPrefilling(false);
            props.reset_prefill_property_id();
        }
    }, [props.propertyData, prefilling]);

    // GATHER PROPERTY CONTACTS
    useEffect(() => {
        if (props.property_contacts) {
            if (props.property_contacts.length > 0 && isGettingPropertyContacts) {
                gatherPropertyContacts({
                   property_contacts: props.property_contacts,
                   updateDataObj 
                })
                setIsGettingPropertyContacts(false);
            }
        }
    }, [props.property_contacts]);

    useEffect(() => {
        console.log('success on close?', props.success, close)
        if (props.success && close) {
            setInitialData(cloneJ(dataObj));
            if (notifyOnClose) {
                props.setNotification(notifyOnClose);
            }
            props.close();
            setClose(false);
        }
        if (props.success && saving) {
            setInitialData(cloneJ(dataObj));
            setNotificationTop('Saved');
            setSaving(false);
        }
    }, [props.success, close]);

    const cancel = () => {
        if (props.tab === 'pending_signature') {
            return props.close();
        }
        // check if equal
        if (checkForChanges(initialData, dataObj)) {
            setNotificationAsk(true);
            return;
        }
        props.close();
    }

    const updateDataObj = (field, value) => {
        const newObj = cloneJ(dataObj);
        newObj[field] = value;
        setDataObj(newObj);
    }

    // CHOOSE PROPERTY
    const chooseProperty = (property) => {
        console.log('propertyChosen', property);
        const newDataObj = clone(dataObj);
        newDataObj.project_name = property.name;
        newDataObj.property_id = property.id;
        const address = createAddressObject(property);
        newDataObj.street1 = address.street1;
        newDataObj.street2 = address.street2;
        newDataObj.city = address.city;
        newDataObj.state = address.state;
        newDataObj.zip = address.zip;
        setDataObj(newDataObj);
    }

    // CHOOSE CONTACT / COMPANY
    const choose = (chosen, chosenType, chosenDBA, c3, c4) => {
        console.log('chosen', chosen, chosenType, chosenDBA, c3, c4);
        chooseContactCompanyObj({
            chosen,
            dataObj,
            updateDataObj,
            queryFor,
            chosenType,
            chosenDBA
        });
    }

    const renderElement = (field, readOnly) => {
        return <div className={(readOnly || props.viewOnly) ? 'gray-bg no-click' : ''}>
            <FormElement 
                field={field}
                updateDataObj={updateDataObj}
                dataObj={dataObj}
                setDataObj={setDataObj}
                options={field === 'type_of_property' ? props.property_type_options : null}
            />
        </div>
    }

    const save = (obj, newHistoryObj, shouldCloseForm, message) => {
        if (dontSaveArr.indexOf(props.listTab) !== -1) {
            return;
        }
        if (dataObj.tenants) {
            if (dataObj.tenants.length === 0) {
                return setNotification('Please select tenant(s) first in order to save');
            }
        }
        const sections = generateSections(dataObj);
        console.log('saving..', dataObj, sections, props);
        let errors = [];
        if (sections) {
            sections.forEach(s => {
                if (s.name === 'Property Information') {
                    if (s.errors.length > 0) {
                        errors = errors.concat(s.errors);
                    }
                }
            })
        }
        if (errors.length > 0) {
            return setNotification(errors.map((e,i) => {
                return <div key={`errorsy${i}`}>
                    {e}
                </div>
            }))
        }
        if (props.entryData) {
            // UPDATE
            const updateObj = cloneJ(props.entryData);
            updateObj.data = JSON.stringify(dataObj);
            if (obj) {
                Object.keys(obj).forEach(field => {
                    updateObj[field] = obj[field];
                })
            }
            setSaving(true);
            if (shouldCloseForm) {
                setClose(true);
            }
            props.updateTrEntry(updateObj);
        } else {
            // CREATE NEW
            const newObj = {
                data: JSON.stringify(dataObj),
                status: 1,
            };
            if (obj) {
                Object.keys(obj).forEach(field => {
                    newObj[field] = obj[field];
                })
            }
            setSaving(true);
            if (shouldCloseForm) {
                setClose(true);
            }
            props.createTrEntry(newObj);
        }

        if (newHistoryObj) {
            props.createTrHistory(newHistoryObj);
        }
    }

    const permissions = checkPermissionsActions('TR');
    console.log('TREntriesForm', props, dataObj, permissions);

    return <div>
        <Dialog
                open={true}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    <GridContainer>
                        <div
                            className="close-btn"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Close />
                        </div>
                        <GridItem xs={12} sm={12} md={12}>
                            <h3 className="text-center">{actionDict[props.action]} Tenant Rep</h3>
                            <div
                                style={{
                                    marginRight: 20,
                                    marginBottom: 10,
                                    display: "inline-block",
                                }}
                                onClick={() => {
                                    cancel();
                                }}
                                className="hoverable"
                            >
                                <Tooltip id="tooltip-top-start1" title="Back to List" placement="top">
                                    <ArrowBack />
                                </Tooltip>
                            </div>
                            <Tabs
                                TabIndicatorProps={{
                                    style: { background: "#da3b2f", borderBottom: "1px solid #da3b2f", color: "#da3b2f" },
                                }}
                                style={{ marginBottom: 10 }}
                                value={tabIndex}
                            >
                                {(
                                    <Tab
                                        onClick={() => {
                                            setTab("wizard");
                                        }}
                                        style={{ color: tabIndex === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                        tabIndex={0}
                                        label="Wizard"
                                        icon={<i className="fas fa-magic font-22"></i>}
                                    />
                                )}
                                {props.entryData &&
                                    <Tab
                                        onClick={() => {
                                            setTab("full");
                                        }}
                                        style={{ color: tabIndex === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                        tabIndex={1}
                                        label="Full Form"
                                        icon={<i className="fas fa-sticky-note font-22"></i>}
                                    />
                                }
                            </Tabs>
                            {tab === 'wizard' && <TREntriesFormWizard 
                                setTab={setTab}
                                tab={tab}
                                step={step}
                                setStep={setStep}
                                save={save}
                                dataObj={dataObj}
                                setDataObj={setDataObj}
                                updateDataObj={updateDataObj}
                                renderElement={renderElement}
                                setOpenedPropertyModal={setOpenedPropertyModal}
                                openedChooseModal={openedChooseModal}
                                setOpenedChooseModal={setOpenedChooseModal}
                                final_approvers={props.final_approvers}
                                listTab={props.listTab}
                                viewOnly={props.viewOnly}
                            />}

                            {tab === 'full' && <TREntriesFormFull 
                                setTab={setTab}
                                tab={tab}
                                step={step}
                                setStep={setStep}
                                save={save}
                                dataObj={dataObj}
                                setDataObj={setDataObj}
                                updateDataObj={updateDataObj}
                                entryData={props.entryData}
                                setEntryData={props.setEntryData}
                                setNotification={setNotification}
                                permissions={permissions}
                                listTab={props.listTab}
                                viewOnly={props.viewOnly}
                                getTrHistory={props.getTrHistory}
                            />}

                            {openedPropertyModal && <CustomDialogWithProperty
                                propertyOwner={propertyOwner}
                                open={openedPropertyModal}
                                close={() => {
                                    setOpenedPropertyModal(false);
                                }}
                                choose={(property) => { 
                                    chooseProperty(property);
                                    setOpenedPropertyModal(false);
                                }}
                                create_and_choose={(property) => { 
                                    chooseProperty(property);
                                    setOpenedPropertyModal(false);
                                }}
                                title="Search Property"
                                label="Search Property"
                                filter_message=""
                                load_agents={true}
                            />}

                            {openedChooseModal && <CustomDialogChooser
                                open={openedChooseModal}
                                close={() => {
                                    setOpenedChooseModal(false);
                                }}
                                choose={choose}
                                showContactCompaniesTab={true}
                                create_and_choose={choose}
                                title="Tenants"
                                label="Tenants"
                                no_property={true}
                                allowNoContacts={true}
                                showFilters={true}
                                showContactTypes={true}
                                forceContact={true}
                                exclusive_right_to_represent_buyer={true}
                                additional_description="Exclusive Right to Represent Buyers Only"
                            />}
                        </GridItem>
                    </GridContainer>
                </div>
        </Dialog>

        {notificationAsk && <NotificationAsk 
            title="Are you sure?"
            message="Any unsaved progress will be lost."
            open={notificationAsk}
            close={() => {
                setNotificationAsk(false);
            }}
            success={() => {
                setClose(true);
                save(null, null, true);
            }}
            success_button_text="SAVE & CLOSE"
            success2={() => {
                props.close();
            }}
            success2_button_text="CLOSE"
        />}

        {notificationTop && <NotificationFixedTop 
            message={notificationTop}
            open={notificationTop ? true : false}
            close={() => {
                setNotificationTop('');
            }}
            autoClose={3000}
        />}

        {notification && <Notification 
            message={notification}
            open={notification ? true : false}
            close={() => {
                setNotification('');
            }}
        />}
    </div>
}

const mapStateToProps = state => {
	return {
        property_type_options: get(state, 'crm_properties.options.use_type', []),
        success: state.tr_entries.success,
        error: state.tr_entries.error,
        final_approvers: state.tr_entries.final_approvers
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getProperty: (data) => {
            dispatch(actions.getProperty(data));
        },
        getPropertyUseType: () => {
			dispatch(actions.getOptions());
		},
        createTrEntry: (data) => {
            dispatch(actions.createTrEntry(data));
        },
        updateTrEntry: (data) => {
            dispatch(actions.updateTrEntry(data));
        },
        getTrApprovers: () => {
            dispatch(actions.getTrApprovers());
        },
        createTrHistory: (data) => {
            dispatch(actions.createTrHistory(data));
        },
        getTrHistory: (data) => {
            dispatch(actions.getTrHistory(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TREntriesForm);