import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import NotificationAsk from 'components/Notification/NotificationAsk';
import NotificationFixedTop from 'components/Notification/NotificationFixedTop';
import moment from 'moment';
import { IconButton, Dialog, Tooltip } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import Loader from 'components/Loader/Loader';
import { FormGroup, FormControlLabel } from '@material-ui/core';
import { Checkbox } from '@material-ui/core';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';

const TRTemplatesList = (props) => {
    const [askBeforeDelete, setAskBeforeDelete] = useState(null);
    const [startDelete, setStartDelete] = useState(false);
    const [notificationFixedTop, setNotificationFixedTop] = useState('');
    const { data, setNotification } = props;
    const final_data = data ? data.map((l, key) => { // map users to table rows
        const templates = data;
        return ({
            id: l.id,
            title: l.title,
            date: moment(l.created_date.replace('T',' ')).subtract(8, 'hours').format("MM/DD/YYYY h:mm a"),
            username: l.name,
            status: l.active,
            default: <div>
                <FormGroup row className="mt-15">
                     <FormControlLabel
                        control={
                        <Checkbox
                            checked={l.defaults == 1 ? true : false}
                            onChange={() => {
                                if (l.active === 0) {
                                    return setNotification('Disabled templates cannot be set to default')
                                }
                                props.updateTrTemplate({
                                    id: l.id,
                                    defaults: 1,
                                    updateDefault: true
                                }, true);
                            }}
                            color="default"
                        />
                        }
                        label="Default"
                    />
                </FormGroup>
            </div>,
            enable: <div>
                <CustomRadio
                    label=",Enable,Disable"
                    options={['1','0']}
                    onChange={(e, val) => {
                        // check all templates
                        
                        if (val == 0) {
                            if (l.defaults == 1) return setNotification('Default template cannot be disabled');
                            let found_enabled = false;
                            templates.forEach(t => {
                                if (t.id !== l.id) {
                                    if (t.active == 1) found_enabled = true;
                                }
                            })
                            
                            if (found_enabled) {
                                props.updateTrTemplate({
                                    active: val,
                                    id: l.id
                                }, true)
                            } else {
                                setNotification('At least one template should be enabled');
                            }
                        } else {
                            props.updateTrTemplate({
                                active: 1,
                                id: l.id
                            }, true)
                        }
                    }}
                    value={l.active ? l.active.toString() : '0'}
                />
            </div>,
            actions: <div>
                <IconButton onClick={() => {
                    props.openEntry(l);
                }}>
                    <i className="fa fa-edit"></i>
                </IconButton>

                <IconButton
                    onClick={() => {
                        setAskBeforeDelete(l);
                    }}
                >
                    <i className="fa fa-trash"></i>
                </IconButton>
            </div>
        })
    }) : [];

    const columns = [
        {
            Header: "Title",
            accessor: "title"
        },
        {
            Header: "User",
            accessor: "username"
        },
        {
            Header: "Date Created",
            accessor: "date"
        },
        {
            Header: "Default",
            accessor: "default"
        },
        {
            Header: 'Status',
            accessor: 'enable'
        },
        {
            Header: 'Actions',
            accessor: 'actions'
        }
    ]

    useEffect(() => {
        if (props.success_delete && startDelete) {
            setNotificationFixedTop('Entry deleted');
            props.reload();
            setStartDelete(false);
        }
        if (props.error_delete && startDelete) {
            setNotificationFixedTop('There was an error deleting the entry, please contact support if the issue persists.')
            setStartDelete(false);
        }
    }, [props.success_delete, props.error_delete]);

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                sorted={[
                    {
                     id: 'id',
                     desc: true
                    }
                ]}
                data={final_data} 
                columns={columns}
            />

            {askBeforeDelete && <NotificationAsk 
                title="Are you sure?"
                message="Do you want to delete this Template?"
                open={askBeforeDelete ? true : false}
                close={() => {
                    setAskBeforeDelete(null);
                }}
                success={() => {
                    setStartDelete(true);
                    props.deleteTrTemplate(askBeforeDelete.id);
                    setAskBeforeDelete(null);
                }}
            />}

            {notificationFixedTop && <NotificationFixedTop 
                message={notificationFixedTop}
                autoClose={4000}
                close={() => {
                    setNotificationFixedTop('');
                }}
            />}
        </div>
    )
}

export default TRTemplatesList;