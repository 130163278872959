import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const subLeaseFields = FIELDS.Sublease;

export const subFields = SUBFIELDS.Sublease;

export const sectionNames = SECTION.Sublease.names;

export const sectionRequirements = SECTION.Sublease.requirements;

export const initialData = getInitialData(subLeaseFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
