import React from 'react';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import Datetime from "react-datetime";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import { FormGroup, FormControlLabel } from '@material-ui/core';
import moment from 'moment';
import {min1, min6, min4, min10, email, required, 
    no_letters, no_special_chars, no_numbers, max4, 
    max10, max15, max20, max50, max100, max250, max500} from 'shared/validation';
import ertlFields from './ERTLModel';

const validate = {
	min1,
	min6,
	min4,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
    max20,
	max50,
	max100,
	max250,
	max500
};

const allValidated = (validations) => {
    let valid = true;
    if (validations) {
        if (validations.length > 0) {
            validations.forEach(v => {
                if (!v) valid = false;
            })
        }
    }
    
    return valid;
}

const ERTLelement = (props) => {
    const { ertlFormData, field, update, autoFocus } = props;
    const updateDataObj = update;
    const data = ertlFormData;
    const model = ertlFields;

    const getValidations = (f) => {
        if (!ertlFields[f].validations) return [];
        return ertlFields[f].validations.map((v) => validate[v]);
    };

    let element = null;
    if (!field) return null;
    
    if (model[field].type === 'input') {
        element = <CustomInput 
            autoFocus={autoFocus}
            label={model[field].label}
            value={data[field] ? data[field] : ''}
            onChange={(e) => {
                updateDataObj(field, e.target.value);
            }}
            validate={model[field].validations ? getValidations(field) : []}
            startAdornment={model[field].prefix || ''}
            endAdornment={model[field].suffix || ''}
        />
    } else if (model[field].type === 'number') {
        element = <CustomInput 
            autoFocus={autoFocus}
            label={model[field].label}
            value={data[field] ? data[field] : ''}
            onChange={(e) => {
                const number = e.target.value.replace(/[^0-9$.,]/g, '');
                updateDataObj(field, number);
            }}
            validate={model[field].validations ? getValidations(field) : []}
            startAdornment={model[field].prefix || ''}
            endAdornment={model[field].suffix || ''}
            is_number={true}
        />
    } else if (model[field].type === 'date') {
        let error = '';
        const validator = model[field].validations;
        if (validator) {
            if (validator.length > 0) {
                validator.forEach((valfield) => {
                    if (validate[valfield]) {
                        const val = validate[valfield];
                        if (val) {
                            const err = val(data[field]);
                            if (err) {
                                error = err;
                            }
                        }
                    }
                });
            }
        }
        element = <div className="custom relative">
            {data[field] && <div className="tiny-label">{model[field].label}</div>}
            <Datetime
                inputProps={{
                    placeholder: model[field].label,
                }}
                closeOnSelect={true}
                onChange={(e) => {
                    if (typeof e === 'object') {
                        const stringDate = e.format('MMMM D, YYYY');
                        return updateDataObj(field, stringDate);;
                    }
                    updateDataObj(field, e)
                }}
                timeFormat={false}
                value={data[field] ? moment(data[field]) : ''}                         
            />
            {error ? 
                <div className="red-text mt-10">Required</div>
            :
                null}
        </div>
    } else if (model[field].type === 'radio') {
        element = <CustomRadio
                    label={[model[field].label].concat(model[field].options).join(',')}
                    options={model[field].options}
                    onChange={(e) => {
                        updateDataObj(field, e.target.value);
                    }}
                    value={data[field]}
                />
    } else if (model[field].type === 'select') {
        let error = '';
        const validator = model[field].validations;
        if (validator) {
            if (validator.length > 0) {
                validator.forEach((valfield) => {
                    if (validate[valfield]) {
                        const val = validate[valfield];
                        if (val) {
                            const err = val(data[field]);
                            if (err) {
                                error = err;
                            }
                        }
                    }
                });
            }
        }
        element = <div>
                <CustomSelect
                    label={model[field].label}
                    options={model[field].options}
                    choose={(e, n) => {
                        updateDataObj(field, e);
                    }}
                    default={data[field]}
                />
                {error ? 
                <div className="red-text mt-10">Required</div>
            :
                null}
            </div>
    } else if (model[field].type === 'checkbox') {
        const dataArr = data[field] ? data[field].split(',') : [];
        element = 
        <div className="group-wrap">
            {model[field].label}
            <FormGroup row className="mt-15">
                    {model[field].options.map((f,i) => {
                        return <FormControlLabel
                        key={`fc-${i}`}
                        control={
                        <Checkbox
                            checked={dataArr.indexOf(f) !== -1}
                            onChange={() => {
                                const v = dataArr.indexOf(f) !== -1 ?
                                        dataArr.filter(c => {
                                            if (c === f) return false;
                                            return true;
                                        })
                                    :
                                        dataArr.concat([f]);
                                updateDataObj(field, v.join(','));
                            }}
                            color="default"
                        />
                        }
                        label={f}
                    />
                    })}
                </FormGroup>
            </div>

    }
    return element;
}

export default ERTLelement;