import React, { useState, useEffect } from "react";
import { get, isEmpty, set } from "lodash";

import { subFields } from "../RSheetsConsultingModel";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomRadio from "components/CustomRadio/CustomRadio";
import Button from "components/CustomButtons/Button";
import CustomInputStates from "components/CustomInput/CustomInputStates";

import { required } from "shared/validation";
import { clone } from "shared/utility";

import FileUploader from "../../CommonCustomElements/FileUploader";

import RSheetsEditArray from "../../RSheetsEditArray";
import { renderLabel } from "../../RSheetsFormElement";

const RADIO_FIELDS = [
    "guarantor",
    "llc_corp_verified",
    "spousal_consent",
    "can_we_reach_out_for_testimonial",
    "agency_disclosure",
];
const FILE_UPLOAD_FIELDS = ["upload_guarantor_id", "upload_sos", "upload_spousal_id", "upload_", "outside_broker_w9_upload"];
const LESSEE_FIELDS = [
    "company_name",
    "name",
    "last_name",
    "address1",
    "address2",
    "city",
    "state",
    "zip",
    "main_phone",
    "work_phone",
    "mobile_phone",
    "fax",
    "email",
    "agency_disclosure",
    "llc_corp_verified",
    "upload_sos",
    "spousal_consent",
    "upload_spousal_id",
    "guarantor",
    "guarantor_name",
    "upload_guarantor_id",
    "guarantor_spouse_name",
    "can_we_reach_out_for_testimonial",
    "can_we_reach_out_for_testimonial_comment",
]

const LesseesForm = (props) => {
    const { updateDataObj, updateDataMultiObj, data, index, obj } = props;

	const update = (field, value) => {
        const newData = clone(data);
        newData.lessees[index][field] = value;
        updateDataObj("lessees", newData.lessees);
    };

	const [editArray, setEditArray] = useState(false);
    const [dataFromArray, setDataFromArray] = useState({});
    const [uploadModalOpen, setUploadModalOpen] = useState(null);
    const [fieldObj, setFieldObj] = useState(null);
    const model = subFields.lessee;

    const isCompany = obj.id.toString().indexOf("c") !== -1;
    const field = "lessee_contacts";
    const agents = get(data, field, []).filter((agent) => {
        const company_id = get(agent, "company_id", "");
        return company_id.includes("c") && company_id === obj.id;
    });

    const element = (
        <div>
            <h4>Lessee Contact(s)</h4>
            {/* (Full Name, Address1,Address2,City,State,Zip,Phone,Fax,Email) */}
            <div className="bordered-list">
                <ul>
                    {agents.map((a, i) => {
                        return (
                            <li key={`adc-${i}`}>
                                <span
                                    className="red-text hoverable mr-20"
                                    onClick={() => {
                                        const idToRemove = get(agents, `[${i}].id`, "");
                                        const chosen = get(data, "chosen");
                                        const contact_ids = get(data, `chosen.${field}.contact`, []).filter(
                                            (id) => id !== idToRemove
                                        );
                                        set(chosen, `${field}.contact`, contact_ids);
                                        const newAgents = agents.filter((aa, ii) => {
                                            return aa.id !== a.id;
                                        });
                                        updateDataMultiObj({
                                            [field]: newAgents,
                                            chosen,
                                        });
                                    }}
                                >
                                    <i className="fas fa-trash hoverable"></i>
                                </span>
                                <span
                                    className="blue-text hoverable mr-20"
                                    onClick={() => {
                                        setDataFromArray({
                                            index: i,
                                            field: field,
                                            data: a,
                                        });
                                        setEditArray(true);
                                    }}
                                >
                                    <i className="fas fa-edit hoverable"></i>
                                </span>
                                <br />
                                <div>
                                    {renderLabel("name", a, "lessor", subFields, "First Name")}
                                    {renderLabel("last_name", a, "lessor", subFields, "Last Name")}
                                    {renderLabel("address1", a, "lessor", subFields)}
                                    {renderLabel("address2", a, "lessor", subFields)}
                                    {renderLabel("city", a, "lessor", subFields)}
                                    {renderLabel("state", a, "lessor", subFields)}
                                    {renderLabel("zip", a, "lessor", subFields)}
                                    {renderLabel("email", a, "lessor", subFields)}
                                    {renderLabel("main_phone", a, "lessor", subFields)}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                {editArray && (
                    <RSheetsEditArray
                        editArray={editArray}
                        setEditArray={setEditArray}
                        dataFromArray={dataFromArray}
                        setDataFromArray={setDataFromArray}
                        data={data}
                        updateDataObj={updateDataObj}
                    />
                )}
            </div>
        </div>
    );

    useEffect(() => {
        if (data && data.id && obj && obj.id) {
            let namesArr = [];
            let folderPathsArr = [];

            obj["upload_guarantor_id"] && namesArr.push(obj["upload_guarantor_id"]);
            obj["upload_sos"] && namesArr.push(obj["upload_sos"]);
            obj["upload_spousal_id"] && namesArr.push(obj["upload_spousal_id"]);

            if (data.outside_broker_w9 && data.outside_broker_w9_upload) {
                let pathArr = data.outside_broker_w9_upload.split("/");
                namesArr.push(pathArr[2]);
                folderPathsArr.push(data.outside_broker_w9_upload);
            }
            if (data.referral_firm_w9) {
                let pathArr = data.referral_firm_w9_upload.split("/");
                namesArr.push(pathArr[2]);
                folderPathsArr.push(data.referral_firm_w9_upload);
            }
            if (data.lessee_referral_firm_w9) {
                let pathArr = data.lessee_referral_firm_w9_upload.split("/");
                namesArr.push(pathArr[2]);
                folderPathsArr.push(data.lessee_referral_firm_w9_upload);
            }
            props.getRsheetFiles({
                rsId: data.id,
                lesseeId: obj.id,
                fileList: namesArr,
                folderPaths: folderPathsArr,
            });
        }
    }, []);

    const LESSEE_FIELDS_FILTERED = LESSEE_FIELDS.filter(lf => {
        console.log('filtering', lf, obj);
        if (obj.phone_type) {
            if (obj.phone_type === 'Work') {
                if (lf === 'mobile_phone' || lf === 'main_phone') return false;
            } else if (obj.phone_type === 'Mobile') {
                if (lf === 'work_phone' || lf === 'main_phone') return false;
            } else if (obj.phone_type === 'Main') {
                if (lf === 'work_phone' || lf === 'mobile_phone') return false;
            }
        } else {
            if (lf === 'work_phone' || lf === 'mobile_phone') return false;
        }
        return true;
    })

    return (
        <div className="boxed mt-20">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ textAlign: "right" }}>
                        <i
                            className="fas fa-trash red-text hoverable"
                            onClick={() => {
                                const idToRemove = get(data, `lessees[${index}].id`).toString();
                                const chosen = get(data, 'chosen', {});
                                if (idToRemove.includes('c')) {
                                    const company_ids = get(chosen, 'lessees.company', []).filter(id => id.toString() != idToRemove.substring(1));
                                    set(chosen, 'lessees.company', company_ids)
                                } else {
                                    const contact_ids = get(chosen, 'lessees.contact', []).filter(id => id != idToRemove);
                                    set(chosen, 'lessees.company', contact_ids)
                                }

								const lessee_contacts = get(data, 'lessee_contacts', []).filter(lc => {
                                    const company_id = get(lc, 'company_id', '');
                                    return !(company_id && company_id.includes("c") && company_id === idToRemove)
                                })

                                updateDataMultiObj({
                                    "lessees": data.lessees.filter((l, j) => {
                                        if (j === index) return false;
                                        return true;
                                    }),
									lessee_contacts,
                                    chosen,
                                });
                            }}
                        ></i>
                    </div>
                    <h4>
						<strong> Lessee {index + 1} {isCompany ? "(Company)" : ""}</strong>
                    </h4>
                </GridItem>
            </GridContainer>
            <GridContainer>
                {LESSEE_FIELDS_FILTERED.map((field, i) => {
                    let the_label = model[field].label;
                    if (obj.id) {
                        if (obj.id.toString().indexOf("c") !== -1) {
                            // COMPANY
                            if (field === "last_name") return null;
                            if (field === "name") return null;
                            if (field === "spousal_consent") return null;
                            if (field === "upload_spousal_id") return null;
                        } else {
                            if (field === "company_name") return null;
                            if (field === "upload_sos") return null;
                            if (field === "llc_corp_verified") return null;
                        }
                    }
                    const isRequired = model[field].validations
                        ? model[field].validations.indexOf("required") !== -1
                            ? true
                            : false
                        : false;
                    if (field === "can_we_reach_out_for_testimonial_comment") {
                        if (obj.can_we_reach_out_for_testimonial !== "Yes") return null;
                    }
                    if (field === "upload_spousal_id") {
                        if (obj.spousal_consent === "N/A") return null;
                    }
                    if (field === "guarantor_spouse_name") {
                        if (obj.spousal_consent === "N/A") return null;
                    }
                    if (field === 'guarantor_name' || field === 'guarantor_spouse_name' || field === 'upload_guarantor_id') {
                        if (get(obj, `guarantor`, "") !== 'Yes') return null
                    }

                    if (FILE_UPLOAD_FIELDS.indexOf(field) !== -1) {
                        return (
                            <GridItem xs={3} sm={3} md={3} key={`field-${field}-${i}`}>
                                <div className="boxed">
                                    <h4>{model[field].label}</h4>
                                    <FileUploader 
                                        update={(val) => {
                                            update(field, val);
                                        }}
                                        remove={() => {
                                            update(field, '');
                                        }}
                                        rs_filename={obj[field]}
                                    />
                                </div>
                            </GridItem>
                        );
                    }
                    if (RADIO_FIELDS.indexOf(field) !== -1) {
                        return (
                            <GridItem xs={3} sm={3} md={3} key={`field-${field}-${i}`}>
                                <div className={`${model[field].readonly ? "gray-bg-input" : ""}`}>
                                    <div className={`${isRequired && !data[field] ? "required-radio" : ""}`}>
                                        <CustomRadio
                                            label={[model[field].label].concat(model[field].options).join(",")}
                                            options={model[field].options}
                                            onChange={(e) => {
                                                update(field, e.target.value);
                                            }}
                                            value={obj[field]}
                                            readonly={model[field].readonly ? true : false}
                                        />
                                        {isRequired && !obj[field] && (
                                            <div className="red-text mb-20 small-text">Required</div>
                                        )}
                                    </div>
                                </div>
                            </GridItem>
                        );
                    }

                    if (model[field].type === "state") {
                        return (
                            <GridItem xs={3} sm={3} md={3} key={`field-${field}-${i}`}>
                                <CustomInputStates
                                    label={model[field].label}
                                    onChange={(val) => {
                                        update(field, val);
                                    }}
                                    value={obj[field]}
                                    validate={isRequired ? [required] : []}
                                />
                                {(!obj[field] && isRequired) && (
                                    <div className="red-text">
                                        <small>Required</small>
                                    </div>
                                )}
                            </GridItem>
                        );
                    }
                    return (
                        <GridItem xs={3} sm={3} md={3} key={`field-${field}-${i}`}>
                            <CustomInput
                                label={the_label}
                                value={obj[field]}
                                onChange={(e) => {
                                    update(field, e.target.value);
                                }}
                                validate={isRequired ? [required] : []}
                            />
                        </GridItem>
                    );
                })}
            </GridContainer>

			{isCompany && !isEmpty(agents) && element}
        </div>
    );
};

export default LesseesForm;
