import React, { useState, useEffect } from 'react';
import { LISTING_STATUS, textData } from './ListingObj';
import { ListingBaseTemplate } from './SharedComponents/ListingBaseTemplate';
import ListingDataModal from './SharedComponents/ListingDataModal';
import ListingModal from './SharedComponents/ListingModal';
import Button from 'components/CustomButtons/Button';
import moment from 'moment';
import { clone } from 'lodash';
import { generateHTML } from './ListingLogic';
import ListingPdfModal from './ListingPdfModal';

const ListingFullForm = (props) => {
	const {
		action,
		formData,
		setFormData,
		updateDataObj,
		closeForm,
		createListingEntry,
		pdfData,
		getListingPdf,
		updateListingEntry,
		parent_data,
		allowToSubmit,
		fieldModels,
	} = props;

	const [templateText, setTemplateText] = useState(textData);
	const [openedModal, setOpenedModal] = useState(false);
	const [openedField, setOpenedField] = useState(null);
	const [openedDataModal, setOpenedDataModal] = useState(false);
	const [openedDataField, setOpenedDataField] = useState('');
	const [shouldShow, setShouldShow] = useState(true);
	const [templateEdited, setTemplateEdited] = useState(false);

	const handleSubmitListing = () => {
		const status = allowToSubmit ? LISTING_STATUS.PENDING : LISTING_STATUS.DRAFT;
		if (action === 'Add') {
			const newData = {
				title: formData.title,
				data: formData,
				status,
			};
			if (props.property) {
				if (props.property.id) {
					newData.property_id = props.property.id;
				}
			}
			createListingEntry(newData);
		} else if (action === 'Edit') {
			const newData = clone(parent_data);
			newData.status = status;
			newData.data = clone(formData);
			newData.title = formData.title;
			if (props.property) {
				if (props.property.id) {
					newData.property_id = props.property.id;
				}
			}
			updateListingEntry(newData);			
		}
		closeForm();

	};

	const [localPdfData, setLocalPdfData] = useState(null);
	const [openPDFview, setOpenPDFview] = useState(false);

	const [localHTMLData, setLocalHTMLData] = useState(null);

	useEffect(() => {
		if (pdfData && typeof pdfData === 'string') {
			const parsedPdf = JSON.parse(pdfData);
			setLocalPdfData(parsedPdf);
		}
	}, [pdfData]);

	return (
		<div style={{ padding: 20, backgroundColor: '#fafafa' }} className='parentable'>
			<h3>
				<strong>LISTING FORM OUTPUT PREVIEW</strong>
			</h3>

			<ListingBaseTemplate
				formData={formData}
				setFormData={setFormData}
				setOpenedModal={setOpenedModal}
				shouldShow={shouldShow}
				templateText={templateText}
				textData={textData}
				setOpenedField={setOpenedField}
				fieldModels={fieldModels}
			/>

			<ListingModal
				open={openedModal}
				close={() => {
					setOpenedModal(false);
				}}
				field={openedField}
				templateText={templateText}
				setTemplateText={(newTemplateText) => {
					setTemplateEdited(true);
					setTemplateText(newTemplateText);
				}}
				updateDataObj={updateDataObj}
				formData={formData}
				setFormData={setFormData}
				fieldModels={fieldModels}
			/>
			<ListingDataModal
				formData={formData}
				setFormData={setFormData}
				open={openedDataModal}
				close={() => {
					setOpenedDataModal(false);
				}}
				field={openedDataField}
				fieldModels={fieldModels}
			/>

			<div className='bottom-dweller'>
				{(props.action === 'Add' || props.action === 'Edit') && (
					<React.Fragment>
						<Button
							color='white'
							className='mr-20'
							onClick={() => {
								if (action === 'Add') {
									const newData = {
										title: formData.title,
										status: LISTING_STATUS.DRAFT,
										data: formData,
									}
									if (props.property) {
										if (props.property.id) {
											newData.property_id = props.property.id;
										}
									}
									createListingEntry(newData);
								} else if (action === 'Edit') {
									const newData = clone(parent_data);
									newData.data = clone(formData);
									newData.title = formData.title;
									newData.status = LISTING_STATUS.DRAFT;
									if (props.property) {
										if (props.property.id) {
											newData.property_id = props.property.id;
										}
									}
									updateListingEntry(newData);
								}
								closeForm();
								// props.saveDraft(templateData);
							}}
							style={{ marginTop: 10 }}
						>
							<i className='fas fa-save'></i> Save Listing Draft
						</Button>
						<Button color='white' className='mr-20' onClick={handleSubmitListing} style={{ marginTop: 10 }}>
							<i className='fas fa-save'></i> Submit Listing
						</Button>
						<Button
							color='primary'
							className='mr-20'
							onClick={() => {
								const entry = generateHTML(formData, templateText);
								setLocalHTMLData(entry.html);
								getListingPdf({ id: 1, entry: entry.html });
								setOpenPDFview(true);
							}}
							style={{ marginTop: 10 }}
						>
							<i className='fas fa-save'></i> View PDF
						</Button>
					</React.Fragment>
				)}
			</div>

			{localPdfData && openPDFview && (
				<ListingPdfModal
					pdfData={localPdfData}
					htmlData={localHTMLData}
					open={true}
					close={() => {
						setLocalPdfData(null);
						setOpenPDFview(false);
					}}
				/>
			)}
		</div>
	);
};

export default ListingFullForm;
