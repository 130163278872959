import { put } from 'redux-saga/effects';
import { clone } from '../../shared/utility';
import axios from '../axios/axios-crm';
import * as actions from '../actions/crm_showings';
import { createCrmFileStart, createCrmFileSuccess } from '../actions/crm_contacts';

export function* getShowingsSaga(action) {
  yield put(actions.getShowingsStart());
  try {
    const response = yield axios.get('/showings?property_id='+action.data);
    
    yield put(actions.getShowingsSuccess(response.data));
  } catch (err) {
    yield put(actions.getShowingsFail(err));
  }
}

export function* getShowingSaga(action) {
  yield put(actions.getShowingStart());
  try {
      const response = yield axios.get('/showing?id='+action.data);
      yield put(actions.getShowingSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getShowingFail(e.response.data));
          }
        }
      yield put(actions.getShowingFail(e));
  }
}

export function* createShowingSaga(action) {
  
  yield put(actions.createShowingStart());

  let showing_id = null;

  // add showing
  try {
    let newAction = clone(action);
    delete newAction.data['formDataObj'];
    let formDataObj;
    if(action.data['formDataObj']) {
      formDataObj = action.data['formDataObj']
    }
    const response = yield axios.post('/showing', newAction.data);
    if (response) {
      if (response.data) {
        
        showing_id = response.data.insertId;
        if (formDataObj) {
          yield put(createCrmFileStart());
          let combinedFormData = new FormData();
          combinedFormData.append('SystemNumber', response.data.insertId);
          for (const [key, value] of Object.entries(formDataObj)) {
            for (var pair of value.entries()) {
              combinedFormData.append(pair[0], pair[1])
            }
          }
          const responseTwo = yield axios.post('/upload', combinedFormData);
          yield put(createCrmFileSuccess(responseTwo.data));
        }
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data) {
        
        return yield put(actions.createShowingFail(err.response.data));
      }
    }
    return yield put(actions.createShowingFail('Failed to add showing'));
  }

  if (!showing_id) return yield put(actions.createShowingFail('Failed to add showing'));

  yield put(actions.createShowingSuccess(showing_id));
}

export function* updateShowingSaga(action) {
  yield put(actions.updateShowingStart());

  try {
    let successMsg = '';

    const response = yield axios.put('/showing', action.data);
    if (response.data) {
      if (response.data.msg === 'OK') {
        successMsg += `Successfully updated showing. `;
      }
    }

    if (!successMsg) return yield put(actions.updateShowingFail('Something went wrong updating the Showing'));
    return yield put(actions.updateShowingSuccess(successMsg));
  } catch (err) {
    if (err.response) {
      if (err.response.data) {
        return yield put(actions.updateShowingFail(err.response.data));
      }
    }
    return yield put(actions.updateShowingFail('Failed to update Showing'));
  }
}

export function* deleteShowingSaga(action) {
  yield put(actions.deleteShowingStart());

  try {
    const response = yield axios.delete('/showing?id=' + action.data);
    if (response.data) {
      if (response.data.msg === 'OK') {
        return yield put(actions.deleteShowingSuccess('Successfully deleted showing.'));
      }
    }
    return yield put(actions.deleteShowingFail('Something went wrong deleting the Showing'));
  } catch (e) {
    if (e.response) {
      if (e.response.data) {
        return yield put(actions.deleteShowingFail(e.response.data));
      }
    }
    return yield put(actions.deleteShowingFail('Failed to delete Showing'));
  }
}