import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx"; 
import { formatDate2 } from "shared/utility"; 
import { Tooltip } from '@material-ui/core';
import ReactTable from 'react-table';
import Loader from "components/Loader/Loader";
import { Close } from '@material-ui/icons';
import ERTLFormWizard from '../FormWizard/ERTLFormWizard';
import { getHistoryDiffs } from 'containers/ERTL/FormWizard/ERTLLogic';
import { getStatusType } from '../FormWizard/ERTLModel';

const ERTLPending = (props) => {
    const [showURL, setShowURL] = useState(false);
    const [showView, setShowView] = useState(false);
    const [ertlData, setErtlData] = useState(null);
    const [templateData, setTemplateData] = useState(null);
    const [wait, setWait] = useState(false);
    const [delay, setDelay] = useState(false);
    const [historyDiffData, setHistoryDiffData] = useState(null);
    const [historyDiff, setHistoryDiff] = useState(false);

    const reload = () => {
        props.getEntriesByStatus({
            status: [7,3]
        })
    }

    useEffect(() => {
        reload();
    }, [])

    useEffect(() => {
        if (historyDiff) {
            if (props.historyData.length > 0) {
                const diffs = getHistoryDiffs(props.historyData);
                
                setHistoryDiffData(diffs);
            }
            setHistoryDiff(false);
        }
    }, [props.historyData]);

    const data = props.statusEntries ? props.statusEntries.map((l, key) => { // map users to table rows
        const d = l.data ? l.data : {};
        return ({
            id: l.id,
            created_date: formatDate2(l.created_date),
            owners: <div>
                {d.property_owners ? d.property_owners.map((n,i) => {
                    return <div key={`${n}-contact`}>
                        {n.first_name} {n.last_name}<br />
                    </div>
                }) : null}
            </div>,
            name: l.name,
            approver: <div>
                {d.final_approver ? d.final_approver : null}<br />
                {d.final_approver_email ? d.final_approver_email : null}
            </div>,
            status: getStatusType(l.status),
            data: l,
            actions: <div>
                        {/* {l.status === 3 && // Final Approver
                            <Tooltip
                                title="Sign ERTL entry"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getErtlUrl(l.id);
                                    setShowURL(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-edit font-22"></i>
                            </Button>
                          </Tooltip>
                        } */}
                        {(l.status === 7 || l.status === 3) && // Level 1 Approver
                            <Tooltip
                                title={l.status === 7 ? 'Level 1 Approver' : 'Sign ERTL entry'}
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getErtlHistory('id', l.id);
                                    setErtlData(l);
                                    setTemplateData(d.templateData)
                                    setShowView(true)
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                {l.status === 7 && <i className="fas fa-edit font-22"></i>}
                                {l.status === 3 && <i className="fas fa-ellipsis-v font-22"></i>}
                            </Button>
                          </Tooltip>
                        }
                        {
                            <Tooltip
                                title="View"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getErtlHistory('id', l.id);
                                    setHistoryDiff(true);
                                    setHistoryDiffData(null);

                                    setErtlData(l)
                                    setTemplateData(d.templateData)
                                    setShowView(true)
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-eye font-22"></i>
                            </Button>
                          </Tooltip>
                        }
                        </div>
        })
    }) : [];

    const columns = [
        {
            Header: "ERTL #",
            accessor: "id" 
        },
        {
            Header: "Date",
            accessor: "created_date"
        },
        {
            Header: "Submitter",
            accessor: "name"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Owners",
            accessor: "owners"
        },
        {
            Header: "Approver",
            accessor: "approver"
        },
        {
            Header: "Actions",
            accessor: "actions"
        }
    ]

    console.log('ERTLPending', props);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 style={{display:'inline-block',marginRight:10}}>Pending Final Approval</h3> <i className="fas fa-sync-alt hoverable" onClick={() => {
                    reload();
                }}></i>
                <div className="table-adjust23">
                    <ReactTable 
                        filterable
                        className='-striped' 
                        showPaginationTop 
                        showPaginationBottom 
                        resizable={true} 
                        defaultPageSize={10} 
                        pageSizeOptions={[10, 20, 30]}
                        data={data} 
                        columns={columns} 
                    />
                </div>
            </GridItem>

            {<Dialog
                fullWidth={true}
                maxWidth={false}
                open={showURL}
                onClose={() => { 
                    setTimeout(() => {
                        props.getEntriesByStatus({
                            status: 3
                        })
                        setShowURL(false) 
                    }, 500)
                }}
            >   
                <div className="close-btn mt-20 mb-20" onClick={() => { 
                    setDelay(true);
                    setTimeout(() => {
                        props.getEntriesByStatus({
                            status: 3
                        })
                        setShowURL(false) 
                        setDelay(false)
                    }, 10000) 
                }}><Close /></div>
                {(!props.ertlURL && !delay) && <Loader center={true} />}
                {delay && <div>
                        <Loader center={true} centerMessage="Checking status" />
                    </div>}
                {!delay && <iframe src={props.ertlURL} style={{width:'100%', height:900, overflow:'scroll'}}>
                </iframe>}
            </Dialog>}

            {<Dialog
                open={wait}
            >
                <div className="dialog-wrapper" style={{width:300}}>
                    <Loader />
                </div>    
            </Dialog>}

            {showView &&
              <Dialog
                  open={showView} 
                  close={() => {
                    setShowView(false);
                  }}
                  keepMounted  
                  maxWidth={false} 
                  fullWidth={true}
                  fullScreen={true}
                  
              >
                <div className="dialog-wrapper">
                  <div className="close-btn" onClick={() => { setShowView(false) }}><Close /></div>
                  <ERTLFormWizard
                    getTemplates={props.getTemplates}
                    templatesData={props.templatesData}
                    templateData={{data:templateData}}
                    templatesCount={props.templatesCount}
                    createTemplate={props.createTemplate}
                    getEntries={props.getEntries}
                    createEntry={props.createEntry}
                    updateEntry={props.updateEntry}
                    entriesData={props.entriesData}
                    getEntryPdf={props.getEntryPdf}
                    pdfData={props.pdfData}
                    setWait={setWait}
                    setParentTab={() => {
                        setShowView(false)
                    }}
                    reload={() => {
                        props.getEntriesByStatus({
                            status: [3,7]
                        })
                    }}
                    ertlData={ertlData}
                    origin="approver"
                    signReview={() => {
                        props.getErtlUrl(ertlData.id);
                        setShowView(false);
                        setShowURL(true);
                    }}
                    historyDiffData={historyDiffData}
                    historyData={props.historyData}
                  />
                </div>
              </Dialog>  
                }
        </GridContainer>
    )
}

const mapStateToProps = state => {
    return {
        statusEntries: state.ertl_entries.statusEntries,
        ertlURL: state.ertl_entries.ertlURL,

        entry_error: state.ertl_entries.error,
        entry_success: state.ertl_entries.success,
        entry_loading: state.ertl_entries.loadingCreate,
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,
        templatesData: state.ertl_templates.templatesData,
        templateData: state.ertl_templates.templateData,
        templatesCount: state.ertl_templates.templatesCount,
        entriesData: state.ertl_entries.entriesData,
        pdfData: state.ertl_entries.pdfData,
        historyData: state.ertl_entries.historyData,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getErtlHistory: (data, entry_id) => {
            dispatch(actions.getErtlHistory(data, entry_id));
        },
        getEntriesByStatus: (data, own) => {
            console.log('getting entries', data, own);
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getErtlUrl: (id) => {
            dispatch(actions.getErtlUrl(id));
        },
        getTemplates: (data) => { dispatch(actions.getTemplates(data)) },
        getTemplate: (id) => { dispatch(actions.getTemplate(id))},
        createTemplate: (data) => { dispatch(actions.createTemplate(data)); },
        getEntries: (data) => { dispatch(actions.getEntries(data)); },
        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getEntryPdf: async (data) => { dispatch(actions.getEntryPdf(data)); },
        createEntry: (data, newTemplate) => {
            dispatch(actions.createEntry(data, newTemplate));
        },
        updateEntryStatus: (data) => { dispatch(actions.updateEntryStatus(data)); },
        updateEntry: (data, newTemplate) => {
            dispatch(actions.updateEntryStatus(data, newTemplate));
        },
        deleteTemplate: (id) => {
            dispatch(actions.deleteTemplate(id));
        },
        deleteErtlEntry: (id) => {
            dispatch(actions.deleteErtlEntry(id));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ERTLPending);