import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { clone } from 'shared/utility';
import moment from 'moment';
import Element from '../ListingElement';

const use = (val) => {
	return val ? val : '';
};

const Step1 = (props) => {
	const {
		formData,
		setFormData,
		updateDataObj,
		listingHistory,
		fieldModels,
	} = props;

	const renderElement = (field, access) => {
		return (
			<Element
				updateDataObj={updateDataObj}
				data={formData}
				setData={setFormData}
				field={field}
				access={access}
				historyData={listingHistory}
				fieldModels={fieldModels}
			/>
		);
	};

	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<hr />
				</GridItem>
				<GridItem xs={12} sm={6} md={6}>
					{renderElement('title')}
					{renderElement('date')}
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default Step1;
