import * as actionTypes from './actionTypes';

// GET_TR_ENTRIES 

export const getTrEntries = (data) => {
    return {
        type: actionTypes.GET_TR_ENTRIES,
        data
    }
}

export const getTrEntriesStart = () => {
    return {
        type: actionTypes.GET_TR_ENTRIES_START
    }
}

export const getTrEntriesSuccess = (response) => {
    return {
        type: actionTypes.GET_TR_ENTRIES_SUCCESS,
        response
    }
}

export const getTrEntriesFail = (err) => {
    return {
        type: actionTypes.GET_TR_ENTRIES_FAIL,
        err
    }
}

// GET_TR_ENTRY 

export const getTrEntry = (data) => {
    return {
        type: actionTypes.GET_TR_ENTRY,
        data
    }
}

export const getTrEntryStart = () => {
    return {
        type: actionTypes.GET_TR_ENTRY_START
    }
}

export const getTrEntrySuccess = (response) => {
    return {
        type: actionTypes.GET_TR_ENTRY_SUCCESS,
        response
    }
}

export const getTrEntryFail = (err) => {
    return {
        type: actionTypes.GET_TR_ENTRY_FAIL,
        err
    }
}

// CREATE_TR_ENTRY 

export const createTrEntry = (data) => {
    return {
        type: actionTypes.CREATE_TR_ENTRY,
        data
    }
}

export const createTrEntryStart = () => {
    return {
        type: actionTypes.CREATE_TR_ENTRY_START
    }
}

export const createTrEntrySuccess = (response) => {
    return {
        type: actionTypes.CREATE_TR_ENTRY_SUCCESS,
        response
    }
}

export const createTrEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_TR_ENTRY_FAIL,
        err
    }
}

// UPDATE_TR_ENTRY 

export const updateTrEntry = (data) => {
    return {
        type: actionTypes.UPDATE_TR_ENTRY,
        data
    }
}

export const updateTrEntryStart = () => {
    return {
        type: actionTypes.UPDATE_TR_ENTRY_START
    }
}

export const updateTrEntrySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_TR_ENTRY_SUCCESS,
        response
    }
}

export const updateTrEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_TR_ENTRY_FAIL,
        err
    }
}

// DELETE_TR_ENTRY 

export const deleteTrEntry = (data) => {
    return {
        type: actionTypes.DELETE_TR_ENTRY,
        data
    }
}

export const deleteTrEntryStart = () => {
    return {
        type: actionTypes.DELETE_TR_ENTRY_START
    }
}

export const deleteTrEntrySuccess = (response) => {
    return {
        type: actionTypes.DELETE_TR_ENTRY_SUCCESS,
        response
    }
}

export const deleteTrEntryFail = (err) => {
    return {
        type: actionTypes.DELETE_TR_ENTRY_FAIL,
        err
    }
}

// GET_TR_APPROVERS 

export const getTrApprovers = (data) => {
    return {
        type: actionTypes.GET_TR_APPROVERS,
        data
    }
}

export const getTrApproversStart = () => {
    return {
        type: actionTypes.GET_TR_APPROVERS_START
    }
}

export const getTrApproversSuccess = (response) => {
    return {
        type: actionTypes.GET_TR_APPROVERS_SUCCESS,
        response
    }
}

export const getTrApproversFail = (err) => {
    return {
        type: actionTypes.GET_TR_APPROVERS_FAIL,
        err
    }
}

// CREATE_TR_PDF 

export const createTrPdf = (data) => {
    return {
        type: actionTypes.CREATE_TR_PDF,
        data
    }
}

export const createTrPdfStart = () => {
    return {
        type: actionTypes.CREATE_TR_PDF_START
    }
}

export const createTrPdfSuccess = (response) => {
    return {
        type: actionTypes.CREATE_TR_PDF_SUCCESS,
        response
    }
}

export const createTrPdfFail = (err) => {
    return {
        type: actionTypes.CREATE_TR_PDF_FAIL,
        err
    }
}

// CREATE_TR_HISTORY_PDF 

export const createTrHistoryPdf = (data) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_PDF,
        data
    }
}

export const createTrHistoryPdfStart = () => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_PDF_START
    }
}

export const createTrHistoryPdfSuccess = (response) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_PDF_SUCCESS,
        response
    }
}

export const createTrHistoryPdfFail = (err) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_PDF_FAIL,
        err
    }
}

// GET_TR_HISTORY 

export const getTrHistory = (data) => {
    return {
        type: actionTypes.GET_TR_HISTORY,
        data
    }
}

export const getTrHistoryStart = () => {
    return {
        type: actionTypes.GET_TR_HISTORY_START
    }
}

export const getTrHistorySuccess = (response) => {
    return {
        type: actionTypes.GET_TR_HISTORY_SUCCESS,
        response
    }
}

export const getTrHistoryFail = (err) => {
    return {
        type: actionTypes.GET_TR_HISTORY_FAIL,
        err
    }
}

// CREATE_TR_HISTORY 

export const createTrHistory = (data) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY,
        data
    }
}

export const createTrHistoryStart = () => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_START
    }
}

export const createTrHistorySuccess = (response) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_SUCCESS,
        response
    }
}

export const createTrHistoryFail = (err) => {
    return {
        type: actionTypes.CREATE_TR_HISTORY_FAIL,
        err
    }
}

// GET_TR_SIGNING_URL 

export const getTrSigningUrl = (data) => {
    return {
        type: actionTypes.GET_TR_SIGNING_URL,
        data
    }
}

export const getTrSigningUrlStart = () => {
    return {
        type: actionTypes.GET_TR_SIGNING_URL_START
    }
}

export const getTrSigningUrlSuccess = (response) => {
    return {
        type: actionTypes.GET_TR_SIGNING_URL_SUCCESS,
        response
    }
}

export const getTrSigningUrlFail = (err) => {
    return {
        type: actionTypes.GET_TR_SIGNING_URL_FAIL,
        err
    }
}