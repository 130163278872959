import { us_states_abbreviations } from 'shared/utility';
const companyFields = {
    // Main Info
    air_exclusive_right_to_represent_buyer: {
        label: 'Air Exclusive Right to Represent Buyer/Lessee',
        type: 'file',
        validations: []
    },
    exclusive_right_to_represent_buyer: {
        type: 'select',
        label: 'Exclusive Right to Represent Buyer',
        options: ['No','Yes'],
        validations: ['required']
    },
    contact_type: {
        type: 'multi-select',
        options: ['Property Owner', 'Buyer', 'Tenant', 'Vendor',
        'Property Manager', 'Real Estate Agent', 'Real Estate Broker','Other']
    },
    dre: {
        label: 'DRE# - Real Estate Agent',
        type: 'input',
        validations: ['no_letters']
    },
    w9: {
        label: 'W9',
        type: 'file',
        validations: []
    },
    dre_broker: {
        label: 'DRE# - Real Estate Broker',
        type: 'input',
        validations: ['no_letters']
    },
    contact_subtype: {
        type: 'multi-select',
        options: []
    },
    email: {
        type: 'input',
        label: 'Email',
        validations: ['min6','max100','email']
    },
    use_type: {
        type: 'select',
        label: 'Company Type',
        options: []
    },
    tin: {
        type: 'input',
        label: 'TIN Number',
        validations: ['min9']
    },
    entity_type: {
        type: 'input',
        label: 'Entity Type',
        validations: ['max45']
    },
    state_entity_established_in: {
        type: 'select',
        label: 'State Entity Established In',
        options: us_states_abbreviations
    },
    website_url: {
        type: 'input',
        label: 'Website URL',
        validations: ['min1','max100']
    },
    department: {
        type: 'input',
        label: 'Department',
        validations: ['min1','max50']
    },
    title: {
        type: 'input',
        label: 'Title',
        validations: ['min1','max50']
    },
    // Phone Numbers
    fax: {
        type: 'input',
        label: 'Fax',
        validations: ['min10','max20']
    },
    main_phone: {
        type: 'input',
        label: 'Main Phone',
        validations: ['min10','max50']
    },
    other_phone: {
        type: 'input',
        label: 'Other Phone',
        validations: ['min10','max50']
    },
    // Social Media 
    facebook: {
        type: 'input',
        label: 'Facebook',
        validations: ['max125']
    },
    twitter: {
        type: 'input',
        label: 'Twitter (X)',
        validations: ['max125']
    },
    linkedin: {
        type: 'input',
        label: 'Linkedin',
        validations: ['max125']
    },
    instagram: {
        type: 'input',
        label: 'Instagram',
        validations: ['max125']
    },
    // Preferences
    do_not_call: {
        type: 'select',
        label: 'Do Not Call',
        makeBinary: true,
        options: [{val:1,desc:'Yes'},{val:0,desc:'No'}]
    },
    looking_to_buy: {
        type: 'select',
        label: 'Looking to Buy?',
        makeBinary: true,
        options: [{val:1,desc:'Yes'},{val:0,desc:'No'}]
    },
    opted_out_of_email: {
        type: 'select',
        label: 'Opted Out of Email?',
        makeBinary: true,
        options: [{val:1,desc:'Yes'},{val:0,desc:'No'}]
    },
    preferred_contact_method: {
        type: 'select',
        label: 'Preferred Contact Method',
        options: ['Call', 'Text', 'Email', 'Mail']
    },
    price_range: {
        type: 'select',
        label: 'Price Range (Start and End)',
        options: ['$0 - $1M', '$1M - $2.5M', '$2.5M - $5M', '$5M and up']
    },
    preferred_contact_time: {
        type: 'time_picker',
        label: 'Preferred Contact Start Time'
    },
    preferred_contact_endtime: {
        type: 'time_picker',
        label: 'Preferred Contact End Time'
    },
    category: {
        type: 'select',
        label: 'Category',
        options: ['New','Research','Verified']
    },
    // Information
    last_attempted_contact_date: {
        type: 'date',
        label: 'Last Attempted Contact Date'
    },
    last_contacted_date: {
        type: 'date',
        label: 'Last Contacted Date'
    },
    is_past_client: {
        type: 'select',
        label: 'Is Past Client?',
        makeBinary: true,
        options: [{val:1,desc:'Yes'},{val:0,desc:'No'}]
    },
    lead_source_category: {
        type: 'select',
        label: 'Lead Type',
        options: ['Apartments','Retail','Office','Industrial','Hospitality',
            'Self Storage']
    },
    lead_status: {
        type: 'select',
        label: 'Lead Status',
        options: ['New','Research','Verified']
    },
    email_bounced_reason: {
        type: 'input',
        label: 'Email Bounced Reason',
        validations: ['min1','max250']
    },
    lead_source: {
        type: 'input',
        label: 'Lead Source',
        validations: ['min1','max250']
    },
    lead_source_detail: {
        type: 'input',
        label: 'Lead Source Detail',
        validations: ['min1','max250']
    },
    notes: {
        type: 'input',
        label: 'Lead Source',
        validations: ['min1','max500']
    },
    referral_source: {
        type: 'input',
        label: 'Referral Source',
        validations: ['max250']
    },
    relocating_time_frame: {
        type: 'input',
        label: 'Relocating Time Frame',
        validations: ['max250']
    },
    assistant_email: {
        type: 'input',
        label: 'Assistant Email',
        validations: ['email','max100']
    },
    assistant_name: {
        type: 'input',
        label: 'Assistant Name',
        validations: ['max50']
    },
    name: {
        type: 'input',
        label: 'Company Name',
        validations: ['max500','required']
    },
    company_represented: {
        type: 'input',
        label: 'Company Representative',
        validations: ['max50']
    },
}
export default companyFields;
export const partnerFields = {
    email: {
        type: 'input',
        label: 'Email',
        validations: ['email','max100']
    },
    first_name: {
        type: 'input',
        label: 'Firstname',
        validations: ['max50']
    },
    last_name: {
        type: 'input',
        label: 'Lastname',
        validations: ['max50']
    },
    phone: {
        type: 'input',
        label: 'Phone',
        validations: ['min10','max50','no_letters']
    },
    notes: {
        type: 'input',
        label: 'Notes',
        validations: ['min1','max500']
    }
};

export const addressFields = {
    fields: {
        name: {
            label: 'Name',
            validations: ['max50','no_numbers','no_special_chars']
        },
        company_name: {
            label: 'Company Name',
            validations: ['max50']
        },
        street1: {
            label: 'Address 1',
            validations: ['min5','max50']
        },
        street2: {
            label: 'Address 2',
            validations: ['min1','max50']
        },
        state: {
            label: 'State',
            options: us_states_abbreviations
        },
        city: {
            label: 'City',
            validations: ['no_numbers','no_special_chars']
        },
        zip: {
            label: 'Zipcode',
            validations: ['min5','max15','no_letters']
        },
        phone_number: {
            label: 'Phone Number',
            validations: ['max50','no_letters']
        },
        other_number: {
            label: 'Other Number',
            validations: ['min10','max50','no_letters']
        },
        fax: {
            label: 'Fax',
            validations: ['min10','max50','no_letters']
        },
        email: {
            label: 'Email',
            validations: ['email','min6','max100']
        }
    },
    types: {
        mailing: {
            fields: ['address1','address2','city','state','zip']
        },
        accountant: {
            fields: ['company_name','address1','address2','city','state','zip','email',
                    'phone','other_number','fax','name']
        },
        business: {
            fields: ['address1','address2','city','state','zip',
                    'phone']
        },
        legal: {
            fields: ['company_name','address1','address2','city','state','zip','email',
            'phone','other_number','fax','name']
        },
        pm: {
            fields: ['company_name','address1','address2','city','state','zip','email',
            'phone','other_number','fax','name']
        },
        other: {
            fields: ['name','company_name','phone','fax',
            'address1','address2','city','state','zip','email']
        }
    }
}

const dataObj = {};
Object.keys(companyFields).forEach(field => {
    dataObj[field] = '';
})
const addressTypes = ['mailing','accountant','business','legal','pm'];
addressTypes.forEach(addType => {
    const fields = addressFields.types[addType].fields;
    fields.forEach(f => {
        dataObj[`${addType}_${f}`] = '';
    })
});
export const companyDataObj = dataObj;


const initialAddressDat = {
    mailing: {},
    accounting: {},
    business: {},
    legal: {},
    property_management: {},
    other: []
};

export const addTypeDict = {
    M: 'mailing',
    B: 'business',
    A: 'accounting',
    L: 'legal',
    P: 'property_management',
    O: 'other'
};

export const initialAddressData = initialAddressDat;

export const otherAddressFields = {
    id: '',
    name: '',
    company_name: '',
    phone_number: '',
    fax: '',
    street1: '',
    street2: '',
    city: '',
    state: '',
    zip: '',
    email: ''
}

export const translateBinary = (dataObj) => {
    const newObj = { ...dataObj };
    Object.keys(companyFields).forEach(field => {
        
        if (companyFields[field].makeBinary) {
            if (newObj[field] === 'Yes') {
                newObj[field] = 1;
            } else {
                if (newObj[field] === 'No') {
                    newObj[field] = 0;
                } else {
                    newObj[field] = null;
                }
            }
        }
    })
    return newObj;
}


