import React from 'react';
import Button from "../../components/CustomButtons/Button.jsx";

const UserAdminModalNav = (props) => {
    const currentTab = props.currentTab;
    const setCurrentTab = props.setCurrentTab;
    return (
        <div>
            <Button
                color={currentTab === 'Basic Info' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('Basic Info');
                }}
            >
                Personal Information
            </Button>

            <Button
                color={currentTab === 'General' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('General');
                }}
            >
                User Information
            </Button>

            <Button
                color={currentTab === 'Links' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('Links');
                }}
            >
                Links
            </Button>

            <Button
                color={currentTab === 'IT' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('IT');
                }}
            >
                IT
            </Button>

            <Button
                color={currentTab === 'Access' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('Access');
                }}
            >
                Access
            </Button>

            <Button
                color={currentTab === 'Notes' ? 'success' : 'primary'}
                style={{marginRight:10}}
                onClick={() => {
                    setCurrentTab('Notes');
                }}
            >
                Notes
            </Button>
        </div>
    )
}

export default UserAdminModalNav;