import React, { useState } from 'react';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import PMAExhibitElements from './PMAExhibitElements';
import { Dialog } from '@material-ui/core';
import Button from '../../../components/CustomButtons/Button';

const PMAExhibit = (props) => {
    const [currentField, setCurrentField] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [currentLabel, setCurrentLabel] = useState('');
    const [currentOptions, setCurrentOptions] = useState(['Yes','No']);
    const [currentValue, setCurrentValue] = useState(null);
    const [openedModal, setOpenedModal] = useState(false);
    const { setFormData, formData } = props;

    const edit = (field, type, index, label, value) => {
        
        return;
        setCurrentField(field);
        setCurrentIndex(index);
        setCurrentType(type);
        setCurrentLabel(label);
        setCurrentValue(value ? value : null);
        setOpenedModal(true);
    }

    if (props.formData) {
        const fd = props.formData;
        if (fd.signing_ceremony) {
            if (fd.signing_ceremony.length > 0) {
                return <div>
                    <hr />
                    <div className="text-center">
                        <h3>Exhibit "A"</h3>
                    </div>
                    {fd.signing_ceremony.map((sc,ind) => {
                        const ed = sc.exhibit_data ? sc.exhibit_data : {};
                        if (!sc.exhibit_data) return null;
                        return <div key={sc.email}>
                            <h3><strong>{sc.name}</strong></h3>
                            <p><strong>Note:</strong> illi CRE converts all leases and documents to electronic documents and stores them securely in our Cloud Storage system that has multiple back-ups in various cities.  The Department of Real Estate and the Court Systems allow and accept electronic documents and the need for “inked” copies is in the past.</p>
                            <p>That said, some clients still prefer the original hard copies and we are happy to accommodate that and will forward those to you should you desire.  Please make an election by checking one of the boxes below to indicate whether you would like the original paper documents to be forwarded to you acknowledge that otherwise, we will shred the paper documents after storing them electronically.</p>
                            <br />
                            <input type="checkbox" checked={ed.paper_1} onClick={e => {
                                edit('paper_1', 'checkbox', ind, 'Convert paper documents to electronic files stored on the cloud and shred originals.', ed.paper_1);
                            }} /> Convert paper documents to electronic files stored on the cloud and shred originals.
                            <br /><br />
                            <input type="checkbox" checked={ed.paper_2} onClick={e => {
                                edit('paper_2', 'checkbox', ind, 'Convert paper documents to electronic files stored on the cloud and forward originals to Owner.');
                            }} /> Convert paper documents to electronic files stored on the cloud and forward originals to Owner.
                            <br />
                            <br />
                            <h3>Ownership Distribution</h3>
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <label>Owner/Member</label>
                                    <input className="form-control" placeholder="Owner/Member" value={ed.owner1_member} 
                                        onClick={() => {
                                            edit('owner1_member', 'input', ind, 'Owner/Member');
                                        }}
                                    />
                                    <label>Percentage</label>
                                    <input className="form-control" type="number" value={ed.owner1_percentage} placeholder="Percentage" />
                                    <label>Tax Identification Number</label>
                                    <input className="form-control" placeholder="Tax Identification Number" value={ed.owner1_tin} />
                                    <label>Electronic Distribution</label>
                                    <div className="radio">
                                        <label><input type="radio" name="owner1_electronic_distribution" value="Yes" checked={ed.owner1_ed === 'Yes' ? true : false} />Yes</label>
                                    </div>
                                    <div className="radio">
                                        <label><input type="radio" name="owner1_electronic_distribution" value="No" checked={ed.owner1_ed === 'No' ? true : false} />No</label>
                                    </div>

                                    {ed.owner1_ed === 'Yes' && <div id="bank_routing_number1">
                                        <label>Bank Routing Number</label>
                                        <input className="form-control" placeholder="Bank Routing Number" value={ed.owner1_bank_routing} />
                                    </div>}
                                    {ed.owner1_ed === 'Yes' && <div id="bank_accounting_number1">
                                        <label>Bank Account Number</label>
                                        <input className="form-control" placeholder="Bank Account Number" value={ed.owner1_bank_account} />
                                    </div>}
                                    {ed.owner1_ed === 'No' && <div id="mailing_address1">
                                        <label>Mailing Address</label>
                                        <input className="form-control" placeholder="Mailing Address" value={ed.owner1_mailing_address} />
                                    </div>}
                                </GridItem>
                                {ed.other_owners ? ed.other_owners.map((eoo,i) => {
                                    return <GridItem xs={12} sm={4} md={4} key={`eoo-${i}`}>
                                        <label>Owner/Member</label>
                                        <input className="form-control" placeholder="Owner/Member" value={eoo.member} />
                                        <label>Percentage</label>
                                        <input className="form-control" type="number" value={eoo.percentage} placeholder="Percentage" />
                                        <label>Tax Identification Number</label>
                                        <input className="form-control" placeholder="Tax Identification Number" value={eoo.tin} />
                                        <label>Electronic Distribution</label>
                                        <div className="radio">
                                            <label><input type="radio" value="Yes" checked={eoo.ed === 'Yes' ? true : false} />Yes</label>
                                        </div>
                                        <div className="radio">
                                            <label><input type="radio" value="No" checked={eoo.ed === 'No' ? true : false} />No</label>
                                        </div>

                                        {eoo.ed === 'Yes' && <div id="bank_routing_number1">
                                            <label>Bank Routing Number</label>
                                            <input className="form-control" placeholder="Bank Routing Number" value={eoo.bank_routing} />
                                        </div>}
                                        {eoo.ed === 'Yes' && <div id="bank_accounting_number1">
                                            <label>Bank Account Number</label>
                                            <input className="form-control" placeholder="Bank Account Number" value={eoo.bank_account} />
                                        </div>}
                                        {eoo.ed === 'No' && <div id="mailing_address1">
                                            <label>Mailing Address</label>
                                            <input className="form-control" placeholder="Mailing Address" value={eoo.mailing_address} />
                                        </div>}
                                    </GridItem>
                                }): null}
                            </GridContainer>
                            <br />
                            <br />
                            <div className="col-md-12">
                            <h3>Distribution Method</h3>
                            <div>
                                <label>Sweep all funds less reserve listed in the management agreement?</label>     
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds" value="Yes" checked={ed.sweep_all_funds === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds" value="No" checked={ed.sweep_all_funds === 'No' ? true : false} />No</label>
                                </div>
                            </div>
                            <div>
                                <label>Sweep all funds less reserve listed in the management agreement and reserves for property taxes and property insurance?</label>
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds2" value="Yes" checked={ed.sweep_all_funds2 === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds2" value="No" checked={ed.sweep_all_funds2 === 'No' ? true : false} />No</label>
                                </div>
                            </div>
                            <div>
                                <label>Sweep all funds less reserve listed in the management agreement, reserves for property taxes and property insurance, open payables and rents paid in advance?</label>
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds3" value="Yes" checked={ed.sweep_all_funds3 === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="sweep_all_funds3" value="No" checked={ed.sweep_all_funds3 === 'No' ? true : false} />No</label>
                                </div>
                            </div>
                            <hr />
                            <h3>Annual Business License</h3>
                            <div>
                                <label>Prepared by illi CRE?</label>
                                <div className="radio">
                                    <label><input type="radio" name="prepared_by_illi" value="Yes" checked={ed.prepared_by_illi === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="prepared_by_illi" value="No" checked={ed.prepared_by_illi === 'No' ? true : false} />No</label>
                                </div>
                                <label>City Business License Number</label>
                                <input className="form-control" id="city_business_license_number" placeholder="City Business License Number" value={ed.city_business_license_number} />
                            </div>
                            <hr />
                            <h3>State of California Statement of Information</h3>
                            <div>
                                <label>Filed by illi CRE?</label>
                                <div className="radio">
                                    <label><input type="radio" name="filed_by_illi" value="Yes" checked={ed.filed_by_illi === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="filed_by_illi" value="No" checked={ed.filed_by_illi === 'No' ? true : false} />No</label>
                                </div>
                                <label>California Corporation Number</label>
                                <input className="form-control" id="california_corporation_number" placeholder="California Corporation Number" value={ed.california_corporation_number} />
                            </div>
                            <hr />
                            <h3>Mortgage Payments</h3>
                            <div>
                                <label>Paid by illi CRE?</label>
                                <div className="radio">
                                    <label><input type="radio" name="paid_by_illi" value="Yes" checked={ed.paid_by_illi === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="paid_by_illi" value="No" checked={ed.paid_by_illi === 'No' ? true : false} />No</label>
                                </div>
                                <label>Mortgage Holder</label>
                                <input className="form-control" id="mortgage_holder" placeholder="Mortgage Holder" value={ed.mortgage_holder} />
                                <div>Please supply a copy of last mortgage statement</div>
                            </div>
                            <hr />
                            <h3>Prior Period data:</h3>
                            <div>
                                <label>Price Negotiated</label>
                                <input className="form-control" value={ed.prior_period_data_price_negotiated} placeholder="Price Negotiated" />
                                <br />
                                <label>Transfer prior periods of current year into illi CRE books?</label>
                                <br />
                                <label>Detailed by month by month?</label>
                                <div className="radio">
                                    <label><input type="radio" name="detailed_by_month_by_month" value="Yes" checked={ed.detailed_by_month_by_month === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="detailed_by_month_by_month" value="No" checked={ed.detailed_by_month_by_month === 'No' ? true : false} />No</label>
                                </div>
                                <br />
                                <label>Summary month by month?</label>
                                <div className="radio">
                                    <label><input type="radio" name="summary_month_by_month" value="Yes" checked={ed.summary_month_by_month === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="summary_month_by_month" value="No" checked={ed.summary_month_by_month === 'No' ? true : false} />No</label>
                                </div>
                            </div>	
                            <hr />
                            <h3>Prior Period CAM Reconciliation: Price negotiated</h3>				
                            <div>
                                <label>Price Negotiated</label>
                                <input className="form-control" value={ed.prior_period_cam_price_negotiated} placeholder="Price Negotiated" />
                                <br />
                                <label>Prepared by illi CRE?</label>
                                <div className="radio">
                                    <label><input type="radio" name="prepared_by_illi2" value="Yes" checked={ed.prepared_by_illi2 === 'Yes' ? true : false} />Yes</label>
                                </div>
                                <div className="radio">
                                    <label><input type="radio" name="prepared_by_illi2" value="No" checked={ed.prepared_by_illi2 === 'No' ? true : false} />No</label>
                                </div>
                            </div>
                        </div>
                        </div>
                    })}

                    {openedModal && <div>
                        <Dialog 
                            open={openedModal}
                            onClose={() => {
                                setOpenedModal(false);
                            }}
                            fullWidth={true}
                            maxWidth="md"
                        >
                            <div className="dialog-wrapper">
                                <PMAExhibitElements 
                                    options={currentOptions}
                                    field={currentField}
                                    type={currentType}
                                    formData={props.formData}
                                    setFormData={props.setFormData}
                                    index={currentIndex}
                                    label={currentLabel}
                                    value={currentValue}
                                />
                                <Button color="primary" onClick={() => {
                                    setOpenedModal(false);
                                }}>OK</Button>
                            </div>
                        </Dialog>
                    </div>}
                </div>
            }
        }
    }
    return null;
}

export default PMAExhibit;