import React from 'react';

const ContactBox = (props) => {
    const { obj, boxType } = props;
    console.log('ContactBox', props);
    const first_name = obj.first_name;
    const last_name = obj.last_name;
    const primary_phone = boxType === 'already-imported' ? obj.main_phone : obj.primary_phone;
    const primary_email = boxType === 'already-imported' ? obj.email : obj.primary_email;
    const company = boxType === 'already-imported' ? 
                        obj.companies ? 
                            obj.companies.map(c => {
                                if (c.company_data) {
                                    if (c.company_data.name) {
                                        return c.company_data.name;
                                    }
                                }
                                return '';
                            }).join(',')
                        : 
                            '' 
                    : 
                        obj.company;
    return <div className="boxed">
        First Name : <strong>{first_name}</strong><br />
        Last Name : <strong>{last_name}</strong><br />
        Phone : <strong>{primary_phone}</strong><br />
        Email : <strong>{primary_email}</strong><br />
        Company : <strong>{company}</strong><br />
    </div>
}

export default ContactBox; 