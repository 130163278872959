import { put } from 'redux-saga/effects';
import axios from '../axios/axios-crm';
import { clone } from 'shared/utility';
import * as actions from '../actions/crm_companies';
import { get } from 'lodash';

export function* getCompaniesSaga(action) {
	yield put(actions.getCompaniesStart());
	try {
		let url = '/companies?q=get';
		if (action.data) {
			const fields = Object.keys(action.data);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.data[f]) url += `&${f}=${action.data[f]}`;
			}
		}
		
		const response = yield axios.get(url);
		yield put(actions.getCompaniesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompaniesFail(e.response.data));
			}
		}
		yield put(actions.getCompaniesFail(e));
	}
}

export function* getCompanySaga(action) {
	yield put(actions.getCompanyStart());
	if (action.data) {
		if (typeof action.data !== 'object') {
			// single
			try {
				const response = yield axios.get('/company?id=' + action.data);
				if (response.data) {
					yield put(actions.getCompanySuccess(response.data));
				} else {
					yield put(actions.getCompanyFail('Not found'));
				}
			} catch (e) {
				if (e.response) {
					if (e.response.data) {
						return yield put(actions.getCompanyFail(e.response.data));
					}
				}
				yield put(actions.getCompanyFail(e));
			}
		} else {
			// multiple id's in an array
			let companies = [];
			for (let i = 0; i < action.data.length; i++) {
				const id = action.data[i].company_id;
				try {
					const response = yield axios.get('/company?id=' + id);
					companies.push(response.data);
				} catch (e) {
					
				}
			}
			yield put(actions.getCompanySuccess(companies, true));
		}
	}
}

export function* createCompanySaga(action) {
	console.log(action.relatedCompaniesForAdd);
	yield put(actions.createCompanyStart());
	try {
		
		if (action.data) {
			const data = clone(action.data);
			if (data.partners) {
				data.partners = JSON.stringify(data.partners);
			}
			if (data.appointments) {
				data.appointments = JSON.stringify(data.appointments);
			}
			if (data.user_id) {
				data.user_id = JSON.stringify(data.user_id);
			}
			if (data.addresses) {
				data.addresses = JSON.stringify(data.addresses);
			}
			const response = yield axios.post('/company', data);
			const new_company_response = yield axios.get('/company?id=' + response.data.insertId);
			const new_company = new_company_response.data;
			new_company.insertId = response.data.insertId;
			

			if (action.sharesForAdd && action.sharesForAdd.length > 0) {
				for (let i = 0; i < action.sharesForAdd.length; i++) {
					const share = clone(action.sharesForAdd[i]);
					share.company_id = new_company.insertId;
					delete share.name;
					yield axios.post('/company_share', share);
				}
			}

			if (action.contactsForAdd && action.contactsForAdd.length > 0) {
				for (let i = 0; i < action.contactsForAdd.length; i++) {
					const contact_company = clone(action.contactsForAdd[i]);
					contact_company.company_id = new_company.insertId;
					delete contact_company.contact_data;
					yield axios.post('/company_contacts', contact_company);
				}
			}

			if (action.entitiesData && action.entitiesData.length > 0) {
				for (let i = 0; i < action.entitiesData.length; i++) {
					const entity = clone(action.entitiesData[i]);
					entity.company_id = new_company.insertId;
					delete entity.contact_data;
					delete entity.company_data;
					delete entity.dba_data;

					yield axios.post('/company_entity', entity);
				}
			}

			if (action.dbasForAdd) {
				for (let i = 0; i < action.dbasForAdd.length; i++) {
					const dba = clone(action.dbasForAdd[i]);
					dba.company_id = new_company.insertId;
					yield axios.post('/company_dba', dba);
				}
			}

			if (action.relatedCompaniesForAdd) {
				for (let i = 0; i < action.relatedCompaniesForAdd.length; i++) {
					const relatedCompany = clone(action.relatedCompaniesForAdd[i]);
					relatedCompany.company_id = new_company.insertId;
					yield axios.post('/company_company', relatedCompany);
				}
			}
			const new_company_dbas = yield axios.get('/company_dbas?id=' + response.data.insertId);

			yield put(actions.createCompanySuccess(new_company, new_company_dbas.data));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createCompanyFail(e.response.data));
			}
		}
		yield put(actions.createCompanyFail(e));
	}
}

export function* updateCompanySaga(action) {
	
	yield put(actions.updateCompanyStart());
	try {
		const response = yield axios.put('/company', action.data);
		yield put(actions.updateCompanySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateCompanyFail(e.response.data));
			}
		}
		yield put(actions.updateCompanyFail(e));
	}
}

export function* deleteCompanySaga(action) {
	yield put(actions.deleteCompanyStart());
	try {
		const response = yield axios.delete('/company?id=' + action.data);
		yield put(actions.deleteCompanySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteCompanyFail(e.response.data));
			}
		}
		yield put(actions.deleteCompanyFail(e));
	}
}

export function* getCompanyContactsSaga(action) {
	yield put(actions.getCompanyContactsStart());
	try {
		const data = action.data;
		let params = '';
		if (data.company_id) {
			params = `?id=${data.company_id}`;
		} else if (data.contact_id) {
			params = `?contact_id=${data.contact_id}`;
		}
		const response = yield axios.get('/company_contacts' + params);
		const company_contacts = response.data;
		let cc = [];
		for (let i = 0; i < company_contacts.length; i++) {
			const c = company_contacts[i];
			// get contact info
			const company_response = yield axios.get('/company?id=' + c.company_id);
			c.company_data = company_response.data;
			// get company info
			const contact_response = yield axios.get('/contact?id=' + c.contact_id);
			c.contact_data = contact_response.data;
			// get dba info 
			if (c.dba_id) {
				const dbas_response = yield axios.get('/company_dbas?dba_id=' + c.dba_id);
				c.dba_data = dbas_response.data;
			}
			if (c.company_data && c.contact_data) {
				c.contact_data = contact_response.data;
				cc.push(c);
			}
		}
		yield put(actions.getCompanyContactsSuccess(cc));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompanyContactsFail(e.response.data));
			}
		}
		yield put(actions.getCompanyContactsFail(e));
	}
}

export function* createCompanyContactsSaga(action) {
	
	yield put(actions.createCompanyContactsStart());
	try {
		const response = yield axios.post('/company_contacts', action.data);
		yield put(actions.createCompanyContactsSuccess(response.data));
		if (action.data.is_company) {
			yield put(actions.getCompanyContacts({ company_id: action.data.company_id }));
		} else if (action.data.contact_id) {
			yield put(actions.getCompanyContacts({ contact_id: action.data.contact_id }));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createCompanyContactsFail(e.response.data));
			}
		}
		yield put(actions.createCompanyContactsFail(e));
	}
}

export function* deleteCompanyContactsSaga(action) {
	
	yield put(actions.deleteCompanyContactsStart());
	try {
		const response = yield axios.delete('/company_contacts?id=' + action.data.id);
		yield put(actions.deleteCompanyContactsSuccess(response.data));
		if (action.data.is_company) {
			yield put(actions.getCompanyContacts({ company_id: action.data.company_id }));
		} else if (action.data.contact_id) {
			yield put(actions.getCompanyContacts({ contact_id: action.data.contact_id }));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteCompanyContactsFail(e.response.data));
			}
		}
		yield put(actions.deleteCompanyContactsFail(e));
	}
}

export function* updateCompanyContactsSaga(action) {
	yield put(actions.updateCompanyContactsStart());
	try {
		const response = yield axios.put('/company_contacts', action.data);
		yield put(actions.updateCompanyContactsSuccess(response.data));
		if (action.data.is_company) {
			yield put(actions.getCompanyContacts({ company_id: action.data.company_id }));
		} else if (action.data.contact_id) {
			yield put(actions.getCompanyContacts({ contact_id: action.data.contact_id }));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateCompanyContactsFail(e.response.data));
			}
		}
		yield put(actions.updateCompanyContactsFail(e));
	}
}

export function* getCompanyUsernamesSaga(action) {
	yield put(actions.getCompanyUsernamesStart());
	try {
		const response = yield axios.get('/company_usernames?company_id=' + action.data);
		yield put(actions.getCompanyUsernamesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompanyUsernamesFail(e.response.data));
			}
		}
		yield put(actions.getCompanyUsernamesFail(e));
	}
}

export function* getCompanySharesSaga(action) {
	yield put(actions.getCompanySharesStart());
	try {
		const response = yield axios.get('/company_shares?company_id=' + action.data);
		yield put(actions.getCompanySharesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompanySharesFail(e.response.data));
			}
		}
		yield put(actions.getCompanySharesFail(e));
	}
}

export function* createCompanyShareSaga(action) {
	yield put(actions.createCompanyShareStart());
	try {
		const response = yield axios.post('/company_share', action.data);
		yield put(actions.createCompanyShareSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createCompanyShareFail(e.response.data));
			}
		}
		yield put(actions.createCompanyShareFail(e));
	}
}

export function* deleteCompanyShareSaga(action) {
	yield put(actions.deleteCompanyShareStart());
	try {
		const response = yield axios.delete('/company_share?id=' + action.data);
		yield put(actions.deleteCompanyShareSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteCompanyShareFail(e.response.data));
			}
		}
		yield put(actions.deleteCompanyShareFail(e));
	}
}

export function* getCompanyPropertiesSaga(action) {
    yield put(actions.getCompanyPropertiesStart());
	try {
		const data = action.data;
		let params = '';
		if (data.company_id) {
			params = `?id=${data.company_id}`;
		} else if (data.property_id) {
			params = `?property_id=${data.property_id}`;
		}
		const response = yield axios.get('/company_properties' + params);
		const company_properties = response.data;
		let cc = [];
		for (let i = 0; i < company_properties.length; i++) {
			const c = company_properties[i];
			// get company info
			const company_response = yield axios.get('/company?id=' + c.company_id);
			c.company_data = company_response.data;
			// get property info
			const property_response = yield axios.get('/property?id=' + c.property_id);
			c.property_data = property_response.data;
			cc.push(c);
		}
		yield put(actions.getCompanyPropertiesSuccess(cc));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompanyPropertiesFail(e.response.data));
			}
		}
		yield put(actions.getCompanyPropertiesFail(e));
	}
}

export function* deleteCompanyPropertiesSaga(action) {
    
	yield put(actions.deleteCompanyPropertiesStart());
	try {
		const response = yield axios.delete('/company_properties?id=' + action.data.id);
		yield put(actions.deleteCompanyPropertiesSuccess(response.data));
		if (action.data.is_company) {
			yield put(actions.getCompanyProperties({ company_id: action.data.company_id }));
		} else if (action.data.property_id) {
			yield put(actions.getCompanyProperties({ property_id: action.data.property_id }));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteCompanyPropertiesFail(e.response.data));
			}
		}
		yield put(actions.deleteCompanyPropertiesFail(e));
	}
}

export function* createCompanyPropertiesSaga(action) {
	yield put(actions.createCompanyPropertiesStart());
	try {
		const newData = clone(action.data);
		if (newData.hasOwnProperty('is_company')) delete newData.is_company;
		const response = yield axios.post('/company_properties', newData);
		yield put(actions.createCompanyPropertiesSuccess(response.data));
		if (action.data.is_company) {
			yield put(actions.getCompanyProperties({ company_id: action.data.company_id }));
		} else if (action.data.property_id) {
			yield put(actions.getCompanyProperties({ property_id: action.data.property_id }));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createCompanyPropertiesFail(e.response.data));
			}
		}
		yield put(actions.createCompanyPropertiesFail(e));
	}
}

export function* getCompanyChangesSaga(action) {
    yield put(actions.getCompanyChangesStart());
    try {
		let changesData = [];
		for (let i = 0; i < action.data.length; i++) {
			const company = action.data[i];
			let company_id = company.id;
			if (company.id.indexOf('c') !== -1) {
				company_id = company.id.split('c')[1];
			}
			const response = yield axios.get('/company?id='+company_id);
			const db_company = response.data;
			const fields = Object.keys(company);
			let changeDetected = false;
			const exempted_fields = ['id'];
			const company_fields = ['name','mailing_address1','mailing_address2',
				'mailing_city','mailing_state','mailing_zip','main_phone','fax','email'];
			const new_fields_keys = {
				'name': 'first_name',
				'mailing_address1' : 'address',
				'mailing_address2' : 'address2',
				'mailing_city' : 'city',
				'mailing_state' : 'state',
				'mailing_zip' : 'zip',
			}
			// id = company_id;
            // name = company - name;
            // first_name = signer - first_name;
            // last_name = signer - last_name;
            // address1 = company - mailing_address1;
            // address2 = company - mailing_address2;
            // city = company - mailing_city;
            // state = company - mailing_state;
            // zip = company - mailing_zip;
            // phone = company - main_phone;
            // fax = company - fax;
            // email = company - email;
			for (let j = 0; j < company_fields.length; j++) {
				const field = company_fields[j];
				if (exempted_fields.indexOf(field) === -1) {
					const new_company_field = new_fields_keys[field];
					const the_field = new_company_field ? new_company_field : field;
					if (field === 'name') {
						if (db_company.name !== company.first_name) {
							changeDetected = true;
						}
					}
					if (company[the_field]) {
						if (db_company[field] !== company[the_field]) {
							changeDetected = true;
						}
					}
				} 
			}
			if (changeDetected) {
				const before_obj = {
				  id: db_company.id,
				  name: db_company.name,
				}
				const after_obj = {
				  id: db_company.id,
				  name: company.name,
				}
				if (company.email) {
				  before_obj.email = db_company.email;
				  after_obj.email = company.email;
				}
				if (company.fax) {
					before_obj.fax = db_company.fax;
					after_obj.fax = company.fax;
				}
				if (company.address) {
				  before_obj.mailing_address1 = db_company.mailing_address1;
				  after_obj.mailing_address1 = company.address;
				}
				if (company.address2) {
					before_obj.mailing_address2 = db_company.mailing_address2;
					after_obj.mailing_address2 = company.address2;
				  }
				if (company.city) {
				  before_obj.mailing_city = db_company.mailing_city;
				  after_obj.mailing_city = company.city;
				}
				if (company.state) {
				  before_obj.mailing_state = db_company.mailing_state;
				  after_obj.mailing_state = company.state;
				}
				changesData.push({
				  before: before_obj,
				  after: after_obj
				});
			}
		}
        yield put(actions.getCompanyChangesSuccess(changesData));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCompanyChangesFail(e.response.data));
            }
          }
        yield put(actions.getCompanyChangesFail(e));
    }
}

export function* saveCompanyChangesSaga(action) {
    yield put(actions.saveCompanyChangesStart());
    try {
		for (let i = 0; i < action.data.length; i++) {
			const company = action.data[i];
			const update_company = {
			  id: company.id,
			  name: company.name
			};
			if (company.email) update_company.email = company.email; 
			if (company.main_phone) update_company.main_phone = company.main_phone;
			if (company.fax) update_company.fax = company.fax;
			['mailing_address1','mailing_address2','mailing_city', 'mailing_state','zip'].forEach(field => {
				if (company[field]) {
					update_company[field] = company[field];
				}
			});
			yield axios.put('/company', update_company);
		}
        yield put(actions.saveCompanyChangesSuccess('OK'));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.saveCompanyChangesFail(e.response.data));
            }
          }
        yield put(actions.saveCompanyChangesFail(e));
    }
}

export function* getCompanyContactsDetailSaga(action) {
    yield put(actions.getCompanyContactsDetailStart());
    try {
        const response = yield axios.get('/company_contacts_detail?company_id='+action.data);
        yield put(actions.getCompanyContactsDetailSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCompanyContactsDetailFail(e.response.data));
            }
          }
        yield put(actions.getCompanyContactsDetailFail(e));
    }
}

export function* getCompanyEntitiesSaga(action) {
	yield put(actions.getCompanyEntitiesStart());
	try {
		const response = yield axios.get('/company_entities?company_id=' + action.data);
		const response_data = response.data;
		if (response_data) {
			for (let i = 0; i < response_data.length; i++) {
				const entity = response_data[i];
				if (entity.type === 1) {
					// CONTACTS
					entity.company_contacts = [];
					if (entity.entity_company_id) {
						// gather company contacts data
						const company_contacts = yield axios.get(`/company_contacts?id=${entity.entity_company_id}`)
						if (company_contacts.data) {
							entity.company_contacts = company_contacts.data;
						}
					}
					if (entity.dba_id && entity.entity_id && !entity.entity_company_id) {
						// CONTACT ONLY
						const contact_dba_response = yield axios.get(`/contact_dbas?id=${entity.entity_id}`)
						if (contact_dba_response.data) {
							entity.dba_data = contact_dba_response.data;
						}
					} else if (entity.dba_id && entity.entity_id && entity.entity_company_id) {
						// COMPANY AND CONTACT
						const contact_dba_response = yield axios.get(`/company_dbas?id=${entity.entity_company_id}`)
						if (contact_dba_response.data) {
							entity.dba_data = contact_dba_response.data;
						}
						// pull contact dbas if secondary_dba_id is not null
						if (entity.secondary_dba_id) {
							const contact_dba_response = yield axios.get(`/contact_dbas?id=${entity.entity_id}`)
							if (contact_dba_response.data) {
								entity.secondary_dba_data = contact_dba_response.data;
							}
						}
					}
				} else {
					// COMPANY 
					const company_dba_response = yield axios.get(`/company_dbas?id=${entity.entity_id}`);
					entity.dba_data = company_dba_response.data;
				}
			}
		}
		yield put(actions.getCompanyEntitiesSuccess({
			[action.data]: response_data
		}));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCompanyEntitiesFail(e.response.data));
			}
		}
		yield put(actions.getCompanyEntitiesFail(e));
	}
}

export function* createCompanyEntitySaga(action) {
    yield put(actions.createCompanyEntityStart());
    try {
		const dba_data = get(action, 'data.dba_data', null);
		if (dba_data) delete action.data.dba_data;
        const response = yield axios.post('/company_entity', action.data);
        yield put(actions.createCompanyEntitySuccess(response.data));
		yield put(actions.getCompanyEntities(action.data.company_id));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createCompanyEntityFail(e.response.data));
            }
          }
        yield put(actions.createCompanyEntityFail(e));
    }
}

export function* deleteCompanyEntitySaga(action) {
    yield put(actions.deleteCompanyEntityStart());
    try {
        const response = yield axios.delete('/company_entity?id='+action.data);
        yield put(actions.deleteCompanyEntitySuccess(response.data));

		const companyId = get(response, 'data.company_id', null)
		if (companyId) {
			yield put(actions.getCompanyEntities(companyId));
		}

    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteCompanyEntityFail(e.response.data));
            }
          }
        yield put(actions.deleteCompanyEntityFail(e));
    }
}

export function* getUpdatedCompanyEntitySaga(action) {
    yield put(actions.getUpdatedCompanyEntityStart());
    try {
        const response = yield axios.get('/company?id='+action.data);
        yield put(actions.getUpdatedCompanyEntitySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getUpdatedCompanyEntityFail(e.response.data));
            }
          }
        yield put(actions.getUpdatedCompanyEntityFail(e));
    }
}

export function* getCompanyDbasSaga(action) {
    yield put(actions.getCompanyDbasStart());
    try {
        const response = yield axios.get('/company_dbas?id='+action.data);
        yield put(actions.getCompanyDbasSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCompanyDbasFail(e.response.data));
            }
          }
        yield put(actions.getCompanyDbasFail(e));
    }
}

export function* createCompanyDbaSaga(action) {
    yield put(actions.createCompanyDbaStart());
    try {
        const response = yield axios.post('/company_dba', action.data);
        yield put(actions.createCompanyDbaSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createCompanyDbaFail(e.response.data));
            }
          }
        yield put(actions.createCompanyDbaFail(e));
    }
}

export function* updateCompanyDbaSaga(action) {
    yield put(actions.updateCompanyDbaStart());
    try {
        const response = yield axios.put('/company_dba', action.data);
        yield put(actions.updateCompanyDbaSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateCompanyDbaFail(e.response.data));
            }
          }
        yield put(actions.updateCompanyDbaFail(e));
    }
}

export function* deleteCompanyDbaSaga(action) {
    yield put(actions.deleteCompanyDbaStart());
    try {
        const response = yield axios.delete('/company_dba?id='+action.data);
        yield put(actions.deleteCompanyDbaSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteCompanyDbaFail(e.response.data));
            }
          }
        yield put(actions.deleteCompanyDbaFail(e));
    }
}

export function* getCompanyCompaniesSaga(action) {
	yield put(actions.getCompanyCompaniesStart());
	try {
			const response = yield axios.get('/company_companies?id='+action.data);
			yield put(actions.getCompanyCompaniesSuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.getCompanyCompaniesFail(e.response.data));
					}
				}
			yield put(actions.getCompanyCompaniesFail(e));
	}
}

export function* createCompanyCompanySaga(action) {
	yield put(actions.createCompanyCompanyStart());
	try {
			const response = yield axios.post('/company_company', action.data);
			yield put(actions.createCompanyCompanySuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.createCompanyCompanyFail(e.response.data));
					}
				}
			yield put(actions.createCompanyCompanyFail(e));
	}
}

export function* updateCompanyCompanySaga(action) {
	yield put(actions.updateCompanyCompanyStart());
	try {
			const response = yield axios.put('/company_company', action.data);
			yield put(actions.updateCompanyCompanySuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.updateCompanyCompanyFail(e.response.data));
					}
				}
			yield put(actions.updateCompanyCompanyFail(e));
	}
}

export function* deleteCompanyCompanySaga(action) {
	yield put(actions.deleteCompanyCompanyStart());
	try {
			const response = yield axios.delete('/company_company?id='+action.data);
			yield put(actions.deleteCompanyCompanySuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.deleteCompanyCompanyFail(e.response.data));
					}
				}
			yield put(actions.deleteCompanyCompanyFail(e));
	}
}
