import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { clone } from 'shared/utility';
import Table from 'components/Table/Table';
import CustomDialogWithProperty from '../../../components/CustomDialog/CustomDialogWithProperty';
import { IconButton } from '@material-ui/core';
import { createAddressObject } from '../../CRM/Properties/PropertiesLogic';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const Step3 = (props) => {
	const [openedDialog, setOpenedDialog] = useState(false);
	const [prefilling, setPrefilling] = useState(false);
	const { formData, setFormData, reset_prefill_property_id } = props;

	useEffect(() => {
		if (props.prefill_property_id) {
			props.getProperty(props.prefill_property_id);
			setPrefilling(true);
		}
	}, []);

	useEffect(() => {
		if (prefilling && props.propertyData) {
			chooseProperty(props.propertyData);
			setPrefilling(false);
			reset_prefill_property_id();
		}
	}, [props.propertyData, prefilling]);

	const openDialog = () => setOpenedDialog(true);
	const closeDialog = () => setOpenedDialog(false);

	const chooseProperty = (data) => {
		const newData = clone(props.formData);
		newData.property_id = data.id;
		newData.property_project_name = data.name;
		const addressObj = createAddressObject(data);
		newData.property_street1 = addressObj.street1;
		newData.property_street2 = addressObj.street2;
		newData.property_city = addressObj.city;
		newData.property_state = addressObj.state;
		newData.property_zip = addressObj.zip;
		setFormData(newData);
	}

	return (
		<div>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={12}>
					{formData.property_id && <Button color='primary' onClick={openDialog}>
						SELECT PROPERTY
					</Button>}
					<hr />

					{formData.property_id && (
						<React.Fragment>
							<h3 className='text-center'>Property Data</h3>
							<IconButton onClick={() => {
								const newData = clone(formData);
								['property_id','property_project_name','property_street1',
								'property_street2','property_city','property_state','property_zip'].forEach(field => {
									newData[field] = '';
								})
								setFormData(newData);
							}} style={{ display: 'block', marginLeft: 'auto' }}>
								<i className='fas fa-trash-alt red-text'></i>
							</IconButton>
							<div style={{ lineHeight: 1.5 }} className='mt-20'>
								<Table
									tableData={[
										['Property Name', 'property_project_name'],
										['Address 1', 'property_street1'],
										['Address 2', 'property_street2'],
										['City', 'property_city'],
										['State', 'property_state'],
										['Zip', 'property_zip'],
									].map((m) => {
										
										console.log('m', m);
										return [
											<div style={{ textAlign: 'right' }}>
												<strong>{m[0]}</strong>
											</div>,
											formData[m[1]],
										];
									})}
								/>
							</div>
						</React.Fragment>
					)}
				</GridItem>
				<GridItem xs={12} sm={12} md={12}></GridItem>
			</GridContainer>

			<CustomDialogWithProperty
				open={openedDialog}
				close={closeDialog}
				title='Select Property'
				label='Search for Property'
				choose={(data) => {
					chooseProperty(data);
					closeDialog();
				}}
			/>
		</div>
	);
};

const mapStateToProps = state => {
	return {
        propertyData: state.crm_properties.propertyData
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getProperty: (data) => {
            dispatch(actions.getProperty(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Step3);
