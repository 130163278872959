import React, { useState } from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import { createTenantName } from "./TREntriesLogic";
import { IconButton } from "@material-ui/core";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { clone } from "../../../shared/utility";
import CustomInputStates from "../../../components/CustomInput/CustomInputStates";
import CustomCRMEditor from "../../../components/CustomDialog/CustomCRMEditor";

const Step2 = (props) => {
    const [askBeforeDelete, setAskBeforeDelete] = useState(null);
    const [edit, setEdit] = useState(null);

    const { dataObj, updateDataObj} = props;

    const updateTenant = (i, field, chosenVal, chosenName) => {
        updateDataObj('tenants', dataObj.tenants.map((tt,ii) => {
            if (ii === i) {
                tt[field] = chosenVal;
                if (chosenName) {
                    tt.dba_name = chosenName;
                }
                return tt;
            }
            return tt;
        }));
    }

    return <div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {!props.viewOnly && <Button color="primary" onClick={() => {
                    props.setOpenedChooseModal(true);
                }}>ADD TENANT(S)</Button>}
            </GridItem>
        </GridContainer>
        {dataObj.tenants.length === 0 && <div className="mt-20">
            0 Tenants
        </div>}
        {/* name: '',
        title: '',
        address: '',
        city: '',
        state: '',
        zip: '',
        phone: '',
        email: '' */}
        {dataObj.tenants ? dataObj.tenants.map((t,i) => {
            return <div className="boxed" key={`bx-${i}`}>
                <GridContainer>
                    {!props.viewOnly && <GridItem xs={12}>
                        <div style={{float:'right'}}>
                            <IconButton
                                onClick={() => {
                                    setAskBeforeDelete(i);
                                }}
                            >
                                <i className="fa fa-trash"></i>
                            </IconButton>
                        </div>
                    </GridItem>}
                    <GridItem xs={4}>
                        <div className="gray-bg no-click">
                            <CustomInput
                                label="Name"
                                value={createTenantName(t)}
                                readonly={true}
                            />
                        </div>
                    </GridItem>
                    {t.company_id && <GridItem xs={4}>
                        <CustomInput
                            label="Company Name"
                            value={t.name}
                            onClick={() => {
                                setEdit({
                                    value: t.name,      
                                    type: 'company',       
                                    field: 'name',      
                                    fieldType: 'input',  
                                    label: 'Company Name',      
                                    id: t.company_id, 
                                    chooseVal: (chosenVal) => { updateTenant(i, 'name', chosenVal); },
                                })
                            }}
                        />
                    </GridItem>}
                    {(t.company_id && t.dba_id) && <GridItem xs={4}>
                        <CustomInput
                            label="Company DBA"
                            value={t.dba_name}
                            onClick={() => {
                                setEdit({
                                    value: t.dba_name,      
                                    type: 'company_dba',       
                                    field: 'name',      
                                    fieldType: 'select',  
                                    label: 'Company DBA Name',      
                                    id: t.dba_id, 
                                    company_id: t.company_id,
                                    chooseVal: (chosenVal, chosenName) => { updateTenant(i, 'dba_id', chosenVal, chosenName); },
                                })
                            }}
                        />
                    </GridItem>}
                    {(!t.company_id && t.dba_id) && <GridItem xs={4}>
                        <CustomInput
                            label="Contact DBA"
                            value={t.dba_name}
                            onClick={() => {
                                setEdit({
                                    value: t.dba_name,      
                                    type: 'contact_dba',       
                                    field: 'name',      
                                    fieldType: 'select',  
                                    label: 'Contact DBA Name',      
                                    id: t.dba_id, 
                                    contact_id: t.contact_id,
                                    chooseVal: (chosenVal, chosenName) => { updateTenant(i, 'dba_id', chosenVal, chosenName); },
                                })
                            }}
                        />
                    </GridItem>}
                    {t.contact_id && <GridItem xs={4}>
                        <CustomInput
                            label="First Name"
                            value={t.first_name}
                            onClick={() => {
                                setEdit({
                                    value: t.first_name,      
                                    type: 'contact',       
                                    field: 'first_name',      
                                    fieldType: 'input',  
                                    label: 'First Name',      
                                    id: t.contact_id,         
                                    chooseVal: (chosenVal) => { updateTenant(i, 'first_name', chosenVal); },
                                })
                            }}
                        />
                    </GridItem>}
                    <GridItem xs={4}>
                        <CustomInput
                            label="Last Name"
                            value={t.last_name}
                            onClick={() => {
                                setEdit({
                                    value: t.last_name,      
                                    type: 'contact',       
                                    field: 'last_name',      
                                    fieldType: 'input',  
                                    label: 'Last Name',      
                                    id: t.contact_id,         
                                    chooseVal: (chosenVal) => { updateTenant(i, 'last_name', chosenVal); },
                                })
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Title"
                            value={t.title}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.title,      
                                        type: 'contact',       
                                        field: 'title',      
                                        fieldType: 'input',  
                                        label: 'Title',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'title', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.title,      
                                        type: 'company_contact',       
                                        field: 'title',      
                                        fieldType: 'input',  
                                        label: 'Title',      
                                        id: t.company_contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'title', chosenVal); } 
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Address 1"
                            value={t.address}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.address,      
                                        type: 'contact',       
                                        field: 'street1',      
                                        fieldType: 'input',  
                                        label: 'Address 1',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'address', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.address,      
                                        type: 'company',       
                                        field: 'mailing_address1',      
                                        fieldType: 'input',  
                                        label: 'Address',      
                                        id: t.company_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'address', chosenVal) }
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Address 2"
                            value={t.address2}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.address2,      
                                        type: 'contact',       
                                        field: 'street2',      
                                        fieldType: 'input',  
                                        label: 'Address 2',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'address2', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.address2,      
                                        type: 'company',       
                                        field: 'mailing_address2',      
                                        fieldType: 'input',  
                                        label: 'Address 2',      
                                        id: t.company_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'address2', chosenVal) }
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="City"
                            value={t.city}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.city,      
                                        type: 'contact',       
                                        field: 'city',      
                                        fieldType: 'input',  
                                        label: 'City',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'city', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.city,      
                                        type: 'company',       
                                        field: 'mailing_city',      
                                        fieldType: 'input',  
                                        label: 'City',      
                                        id: t.company_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'city', chosenVal) }
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="State"
                            value={t.state}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.state,      
                                        type: 'contact',       
                                        field: 'state',      
                                        fieldType: 'states',  
                                        label: 'State',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'state', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.state,      
                                        type: 'company',       
                                        field: 'mailing_state',      
                                        fieldType: 'states',  
                                        label: 'State',      
                                        id: t.company_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'state', chosenVal) }
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Zip"
                            value={t.zip}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.zip,      
                                        type: 'contact',       
                                        field: 'zip',      
                                        fieldType: 'input',  
                                        label: 'Zip',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'zip', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.zip,      
                                        type: 'company',       
                                        field: 'mailing_zip',      
                                        fieldType: 'input',  
                                        label: 'Zip',      
                                        id: t.company_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'zip', chosenVal) }
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Phone"
                            value={t.phone}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.phone,      
                                        type: 'contact',       
                                        field: 'main_phone',      
                                        fieldType: 'input',  
                                        label: 'Phone',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'phone', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.phone,      
                                        type: 'company_contact',       
                                        field: 'phone',      
                                        fieldType: 'input',  
                                        label: 'Phone',      
                                        id: t.company_contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'phone', chosenVal); } 
                                    })
                                }
                            }}
                        />
                    </GridItem>
                    <GridItem xs={4}>
                        <CustomInput
                            label="Email"
                            value={t.email}
                            onClick={() => {
                                if (t.contact_id && !t.company_id) {
                                    // CONTACT ONLY
                                    setEdit({
                                        value: t.email,      
                                        type: 'contact',       
                                        field: 'email',      
                                        fieldType: 'input',  
                                        label: 'Email',      
                                        id: t.contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'email', chosenVal) }
                                    })
                                } else {
                                    // COMPANY WITH CONTACT 
                                    setEdit({
                                        value: t.email,      
                                        type: 'company_contact',       
                                        field: 'email',      
                                        fieldType: 'input',  
                                        label: 'Email',      
                                        id: t.company_contact_id,         
                                        chooseVal: (chosenVal) => { updateTenant(i, 'email', chosenVal); } 
                                    })
                                }
                            }}
                        />
                    </GridItem>
                </GridContainer>
            </div>
        }) : null}

        {askBeforeDelete !== null && <NotificationAsk 
            title="Remove Tenant"
            message="Are you sure you want to remove this tenant?"
            open={askBeforeDelete !== null ? true : false}
            close={() => {
                setAskBeforeDelete(null);
            }}
            success={() => {
                updateDataObj('tenants', dataObj.tenants.filter((t,i) => {
                    if (i === askBeforeDelete) return false;
                    return true;
                }))
                setAskBeforeDelete(null);
            }}
        />}

        {edit !== null && <CustomCRMEditor 
            {...edit}
            close={() => {
                setEdit(null);
            }}
        />}
    </div>
}

export default Step2;