import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    entriesData: [],
    error: null,
    success: null,
    loading: false,
    invoicePdf: null,
    invoiceID: null,
    error_voiding: null,
    success_voiding: null,
    invoicePayments: null,
    success_payments: null,
    error_payments: null
};

// GET_INVOICE_ENTRIES

const getInvoiceEntriesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getInvoiceEntriesSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten invoice entries",
        entriesData: action.response,
    });
};

const getInvoiceEntriesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// CREATE_INVOICE_ENTRY

const createInvoiceEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        invoiceID: null
    });
};

const createInvoiceEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: "Success",
        invoiceID: action.response.insertId
    });
};

const createInvoiceEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// UPDATE_INVOICE_ENTRY

const updateInvoiceEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const updateInvoiceEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully updated invoice",
    });
};

const updateInvoiceEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// DELETE_INVOICE_ENTRY

const deleteInvoiceEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const deleteInvoiceEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully deleted",
    });
};

const deleteInvoiceEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_INVOICE_PDF

const getInvoicePdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        invoicePdf: null,
    });
};

const getInvoicePdfSuccess = (state, action) => {
    return updateObject(state, {
        success: "Success",
        invoicePdf: action.response,
    });
};

const getInvoicePdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// RESET INVOICE ID

const resetInvoiceID = (state, action) => {
    return updateObject(state, {
        invoiceID: null
    });
}

// CLEAR_INVOICE_PDF_DATA

const clearInvoicePdfData = (state, action) => {
    return updateObject(state, {
        invoicePdf: null
    })
}

// RESEND_INVOICE_EMAIL

const resendInvoiceEmailStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const resendInvoiceEmailSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Sent Invoice to email successfully'
    })
}

const resendInvoiceEmailFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// VOID_INVOICES

const voidInvoicesStart = (state, action) => {
    return updateObject(state, {
        error_voiding: null,
        success_voiding: null
    })
}

const voidInvoicesSuccess = (state, action) => {
    return updateObject(state, {
        success_voiding: 'Success'
    })
}

const voidInvoicesFail = (state, action) => {
    return updateObject(state, {
        error_voiding: action.err
    })
}

const clearInvoices = (state, action) => {
    return updateObject(state, {
        entriesData: []
    })
}

// GET_INVOICE_PAYMENTS

const getInvoicePaymentsStart = (state, action) => {
    return updateObject(state, {
        error_payments: null,
        success_payments: null,
        invoicePayments: null
    })
}

const getInvoicePaymentsSuccess = (state, action) => {
    return updateObject(state, {
        success_payments: 'Success',
        invoicePayments: action.response
    })
}

const getInvoicePaymentsFail = (state, action) => {
    return updateObject(state, {
        error_payments: action.err
    })
}

// UPDATE_INVOICE_PAYMENT

const updateInvoicePaymentStart = (state, action) => {
    return updateObject(state, {
        error_payments: null,
        success_payments: null
    })
}

const updateInvoicePaymentSuccess = (state, action) => {
    return updateObject(state, {
        success_payments: 'Success'
    })
}

const updateInvoicePaymentFail = (state, action) => {
    return updateObject(state, {
        error_payments: action.err
    })
}

// CREATE_INVOICE_PAYMENT

const createInvoicePaymentStart = (state, action) => {
    return updateObject(state, {
        error_payments: null,
        success_payments: null
    })
}

const createInvoicePaymentSuccess = (state, action) => {
    return updateObject(state, {
        success_payments: 'Success'
    })
}

const createInvoicePaymentFail = (state, action) => {
    return updateObject(state, {
        error_payments: action.err
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_INVOICE_ENTRIES_START:
            return getInvoiceEntriesStart(state, action);
        case actionTypes.GET_INVOICE_ENTRIES_SUCCESS:
            return getInvoiceEntriesSuccess(state, action);
        case actionTypes.GET_INVOICE_ENTRIES_FAIL:
            return getInvoiceEntriesFail(state, action);

        case actionTypes.CREATE_INVOICE_ENTRY_START:
            return createInvoiceEntryStart(state, action);
        case actionTypes.CREATE_INVOICE_ENTRY_SUCCESS:
            return createInvoiceEntrySuccess(state, action);
        case actionTypes.CREATE_INVOICE_ENTRY_FAIL:
            return createInvoiceEntryFail(state, action);

        case actionTypes.UPDATE_INVOICE_ENTRY_START:
            return updateInvoiceEntryStart(state, action);
        case actionTypes.UPDATE_INVOICE_ENTRY_SUCCESS:
            return updateInvoiceEntrySuccess(state, action);
        case actionTypes.UPDATE_INVOICE_ENTRY_FAIL:
            return updateInvoiceEntryFail(state, action);

        case actionTypes.DELETE_INVOICE_ENTRY_START:
            return deleteInvoiceEntryStart(state, action);
        case actionTypes.DELETE_INVOICE_ENTRY_SUCCESS:
            return deleteInvoiceEntrySuccess(state, action);
        case actionTypes.DELETE_INVOICE_ENTRY_FAIL:
            return deleteInvoiceEntryFail(state, action);

        case actionTypes.GET_INVOICE_PDF_START:
            return getInvoicePdfStart(state, action);
        case actionTypes.GET_INVOICE_PDF_SUCCESS:
            return getInvoicePdfSuccess(state, action);
        case actionTypes.GET_INVOICE_PDF_FAIL:
            return getInvoicePdfFail(state, action);

        case actionTypes.RESET_INVOICE_ID:
            return resetInvoiceID(state, action);

        case actionTypes.CLEAR_INVOICE_PDF_DATA:
            return clearInvoicePdfData(state, action);

        case actionTypes.RESEND_INVOICE_EMAIL_START:
            return resendInvoiceEmailStart(state, action);
        case actionTypes.RESEND_INVOICE_EMAIL_SUCCESS:
            return resendInvoiceEmailSuccess(state, action);
        case actionTypes.RESEND_INVOICE_EMAIL_FAIL:
            return resendInvoiceEmailFail(state, action);

        case actionTypes.VOID_INVOICES_START:
            return voidInvoicesStart(state, action);
        case actionTypes.VOID_INVOICES_SUCCESS:
            return voidInvoicesSuccess(state, action);
        case actionTypes.VOID_INVOICES_FAIL:
            return voidInvoicesFail(state, action);

        case actionTypes.CLEAR_INVOICES:
            return clearInvoices(state, action);

        case actionTypes.GET_INVOICE_PAYMENTS_START:
            return getInvoicePaymentsStart(state, action);
        case actionTypes.GET_INVOICE_PAYMENTS_SUCCESS:
            return getInvoicePaymentsSuccess(state, action);
        case actionTypes.GET_INVOICE_PAYMENTS_FAIL:
            return getInvoicePaymentsFail(state, action);

        case actionTypes.CREATE_INVOICE_PAYMENT_START:
            return createInvoicePaymentStart(state, action);
        case actionTypes.CREATE_INVOICE_PAYMENT_SUCCESS:
            return createInvoicePaymentSuccess(state, action);
        case actionTypes.CREATE_INVOICE_PAYMENT_FAIL:
            return createInvoicePaymentFail(state, action);

        case actionTypes.UPDATE_INVOICE_PAYMENT_START:
            return updateInvoicePaymentStart(state, action);
        case actionTypes.UPDATE_INVOICE_PAYMENT_SUCCESS:
            return updateInvoicePaymentSuccess(state, action);
        case actionTypes.UPDATE_INVOICE_PAYMENT_FAIL:
            return updateInvoicePaymentFail(state, action);

        default:
            return state;
    }
};

export default reducer;
