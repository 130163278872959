import { put } from 'redux-saga/effects';
import axios from '../axios/axios-crm';
import { clone } from 'shared/utility';
import * as actions from '../actions/crm_contacts';
import * as actions2 from '../actions/crm_companies';
import { forEach, get, isArray } from 'lodash';
import { getUserID } from '../../shared/authValidation';

export function* getContactsSaga(action) {
	yield put(actions.getContactsStart());
	try {
		let url = '/contacts?q=get';
		if (action.params) {
			const fields = Object.keys(action.params);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.params[f]) url += `&${f}=${action.params[f]}`;
			}
		}
		const response = yield axios.get(url);
		yield put(actions.getContactsSuccess(response.data));
	} catch (err) {
		yield put(actions.getContactsFail(err));
	}
}

export function* getContactSaga(action) {
	yield put(actions.getContactStart());
	try {
		if (action.email) {
			const url = '/contact?email_only=true&search_term=' + action.email;
			const response = yield axios.get(url);
			if (response.data)
				return yield put(actions.getContactSuccess(response.data, true));
			if (!response.data) return yield put(actions.getContactFail('Not found'));
		}

		if (isArray(action.id)) {
			let companyContactArr = [];
			let url;
			for (let i = 0; i < action.id.length; i++) {
				url = '/contact?id=' + action.id[i].contact_id;
				const response = yield axios.get(url);
				if (response.data) companyContactArr.push(response.data);
			}

			yield put(actions.getContactSuccess(companyContactArr));
			return;
		}

		let url = '/contact?id=' + action.id;
		const response = yield axios.get(url);
		const contactData = response.data;
		if (!contactData) return yield put(actions.getContactFail('Not found'));
		if (action.include_files) {
			if (contactData) {
				let name =
					contactData.id +
					'-' +
					contactData.first_name +
					' ' +
					contactData.last_name;
				const files_response = yield axios.get('/files', {
					params: {
						id: name,
						userId: getUserID(),
					},
				});
				if (files_response.data) {
					contactData.file_list = files_response.data;
				}
			}
		}
		// get contact DBAs 
		const response_dbas = yield axios.get('/contact_dbas?id='+contactData.id);
		if (response_dbas.data) {
			contactData.dbas = response_dbas.data;
		}
		yield put(actions.getContactSuccess(contactData));
	} catch (err) {
		yield put(actions.getContactFail(err));
	}
}

export function* getContactDataSaga(action) {
	yield put(actions.getContactDataStart());
	try {
		const response = yield axios.get('/contact?id=' + action.data);
		if (response.data) yield put(actions.getContactDataSuccess(response.data));
		if (!response.data) yield put(actions.getContactDataFail('Not found'));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactDataFail(e.response.data));
			}
		}
		yield put(actions.getContactDataFail(e));
	}
}

export function* createContactSaga(action) {
	yield put(actions.createContactStart());

	let contact_id = null;
	let contact_data = null;
	let illi_number = null;

	const actionData = clone(action.data);
	['street1', 'street2', 'city', 'state', 'zip'].forEach((f) => {
		if (actionData.hasOwnProperty(f)) {
			delete actionData[f];
		}
	});

	// add contact
	try {
		let formDataObj = clone(action['formDataObj']);

		const response = yield axios.post('/contact', actionData);
		if (response) {
			if (response.data) {
				contact_id = response.data.insertId;
				illi_number = response.data.insertId;
				if (formDataObj) {
					yield put(actions.createCrmFileStart());
					let combinedFormData = new FormData();
					combinedFormData.append('SystemNumber', contact_id);
					for (const [key, value] of Object.entries(formDataObj)) {
						for (var pair of value.entries()) {
							combinedFormData.append(pair[0], pair[1]);
						}
					}
					const responseTwo = yield axios.post('/upload', combinedFormData);
					// prevent messages if on Create
					//yield put(actions.createCrmFileSuccess(responseTwo.data));
				}

				if (action.data.illi_number) illi_number = action.data.illi_number;
				contact_data = {
					id: contact_id,
					name: response.data.name,
				};
			}
		}
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				//yield put(actions.createCrmFileFail('Failed to create crm file.'))
				return yield put(actions.createContactFail(err.response.data));
			}
		}
		//yield put(actions.createCrmFileFail('Failed to create crm file.'))
		return yield put(actions.createContactFail('Failed to add contact'));
	}

	if (!contact_id)
		return yield put(actions.createContactFail('Failed to add contact'));

	// add address
	if (action.addressData) {
		const ad = action.addressData;
		let addressEntries = [];
		const arr = [
			'mailing',
			'accounting',
			'business',
			'legal',
			'property_management',
		];
		for (let j = 0; j < arr.length; j++) {
			const addType = arr[j];
			const newEntry = {
				contact_id,
				type: addType[0].toUpperCase(),
			};
			if (ad[addType]) {
				const entryArr = Object.keys(ad[addType]);
				for (let i = 0; i < entryArr.length; i++) {
					const field = entryArr[i];
					newEntry[field] = ad[addType][field];
				}
				addressEntries.push(newEntry);
			}
		}
		// others
		if (ad.other) {
			if (ad.other.length > 0) {
				for (let x = 0; x < ad.other.length; x++) {
					const entry = ad.other[x];
					const newEntry = {
						contact_id,
						type: 'O',
					};
					const objFields = Object.keys(entry);
					for (let j = 0; j < objFields.length; j++) {
						const field = objFields[j];
						newEntry[field] = entry[field];
					}
					addressEntries.push(newEntry);
				}
			}
		}

		try {
			for (let a = 0; a < addressEntries.length; a++) {
				const newAdd = { contact_id, ...addressEntries[a] };
				if (newAdd.hasOwnProperty('id')) delete newAdd.id;
				const addResponse = yield axios.post('/address', newAdd);
				if (addResponse) {
					if (addResponse.data) {
					}
				}
			}
		} catch (err) {
			if (err.response) {
				if (err.response.data) {
					return yield put(actions.createContactFail(err.response.data));
				}
			}
			return yield put(actions.createContactFail('Failed to add addresses'));
		}
	}

	if (action.partnersData) {
		try {
			const pds = action.partnersData;
			for (let i = 0; i < pds.length; i++) {
				const newPartner = { contact_id, ...pds[i] };
				if (newPartner.hasOwnProperty('id')) delete newPartner.id;
				const pdResponse = yield axios.post('/partner', newPartner);
				if (pdResponse) {
					if (pdResponse.data) {
					}
				}
			}
		} catch (err) {
			return yield put(actions.createContactFail('Failed to add partners'));
		}
	}

	if (action.appointmentsData) {
		try {
			for (let i = 0; i < action.appointmentsData.length; i++) {
				const appointmentData = action.appointmentsData[i];
				const { summary, description, start, end, name } = appointmentData;
				const formattedData = {
					summary,
					description: `${contact_id} --- ${name} --- ${description}`,
					dateTimeStart: new Date(start).toISOString(),
					timeZoneStart: Intl.DateTimeFormat().resolvedOptions().timeZone,
					dateTimeEnd: new Date(end).toISOString(),
					timeZoneEnd: Intl.DateTimeFormat().resolvedOptions().timeZone,
					colorId: '1',
					creator: {
						displayName: 'Admin',
					},
				};
				const apptResponse = yield axios.post('/appointment', formattedData);
				if (apptResponse) {
					if (apptResponse.data) {
					}
				}
			}
		} catch (err) {
			return yield put(
				actions.createContactFail('Failed to add all appointments')
			);
		}
	}

	if (action.companiesForAdd) {
		if (action.companiesForAdd.length > 0) {
			try {
				for (let i = 0; i < action.companiesForAdd.length; i++) {
					const contactCompany = clone(action.companiesForAdd[i]);
					contactCompany.contact_id = contact_id;
					if (contactCompany.dba_data) delete contactCompany.dba_data;
					const resp = yield axios.post('/company_contacts', contactCompany);
					console.log('resp', resp);
				}
			} catch (err) {
				console.log('error adding company contact', err);
				if (err.response) {
					if (err.response.data) {
						console.log(err.response.data);
					}
				}
			}
		}
	}

	if (action.propertiesForAdd) {
		if (action.propertiesForAdd.length > 0) {
			try {
				for (let i = 0; i < action.propertiesForAdd.length; i++) {
					const contactProperty = clone(action.propertiesForAdd[i]);
					contactProperty.contact_id = contact_id;
					const resp = yield axios.post(
						'/property_to_contact',
						contactProperty
					);
					console.log('resp', resp);
				}
			} catch (err) {
				console.log('error adding company contact', err);
				if (err.response) {
					if (err.response.data) {
						console.log(err.response.data);
					}
				}
			}
		}
	}

	if (action.sharesData) {
		try {
			for (let i = 0; i < action.sharesData.length; i++) {
				const asd = action.sharesData[i];
				const newShare = clone(asd);
				newShare.contact_id = contact_id;
				delete newShare.name;
				const asdResponse = yield axios.post('/contact_share', newShare);
				if (asdResponse) {
					if (asdResponse.data) {
					}
				}
			}
		} catch (err) {
			return yield put(actions.createContactFail('Failed to add all shares'));
		}
	}

	if (action.entitiesData) {
		try {
			for (let i = 0; i < action.entitiesData.length; i++) {
				const entity = action.entitiesData[i];
				const newEntity = clone(entity);
				newEntity.contact_id = contact_id;
				delete newEntity.contact_data;
				delete newEntity.company_data;
				delete newEntity.dba_data;

				const entityResponse = yield axios.post('/contact_entity', newEntity);
			}
		} catch(err) {
			return yield put(actions.createContactFail('Failed to add contact entities'));
		}
	}

	if (action.dbasForAdd) {
		try {
			for (let i = 0; i < action.dbasForAdd.length; i++) {
				const dba = action.dbasForAdd[i];
				const newDba = clone(dba);
				newDba.contact_id = contact_id;
				if (newDba.id) delete newDba.id;
				yield axios.post('/contact_dba', newDba);
			}
		} catch(err) {
			return yield put(actions.createContactFail('Failed to add contact entities'));
		}
	}
 
	let url = '/contact?id=' + contact_id;
	const response_get_contact = yield axios.get(url);
	const newContactData = response_get_contact.data;
	// get companies 
	const response_get_companies = yield axios.get('/company_contacts?contact_id=' + contact_id);
	newContactData.companies = response_get_companies.data;
	for (let i = 0; i < newContactData.companies.length; i++) {
		const cc = newContactData.companies[i];
		const response_get_company = yield axios.get('/company?id=' + cc.company_id);
		cc.company_data = response_get_company.data;
	}
	// get dbas 
	const response_get_dbas = yield axios.get('/contact_dbas?id=' + contact_id);
	newContactData.dbas = response_get_dbas.data;
	console.log('newContactData', newContactData);
	yield put(
		actions.createContactSuccess(newContactData, illi_number)
	);
}

export function* updateContactSaga(action) {
	yield put(actions.updateContactStart());

	try {
		let successMsg = '';

		const actionData = clone(action.data);
		['street1', 'street2', 'city', 'state', 'zip'].forEach((f) => {
			if (actionData.hasOwnProperty(f)) {
				delete actionData[f];
			}
		});

		const response = yield axios.put('/contact', actionData);
		if (response.data) {
			if (response.data.msg === 'OK') {
				successMsg += `Successfully updated contact. `;
			}
		}

		if (action.partnersData) {
			const { adds, deletes, updates } = action.partnersData;
			for (let a = 0; a < adds.length; a++) {
				const res = yield axios.post('/partner', adds[a]);
			}
			for (let d = 0; d < deletes.length; d++) {
				const res = yield axios.delete('/partner?id=' + deletes[d].id);
			}
			for (let u = 0; u < updates.length; u++) {
				const res = yield axios.put('/partner', updates[u]);
			}
			successMsg += `Successfully updated partners. `;
		}

		if (action.addressData) {
			const { adds, deletes, updates } = action.addressData;
			for (let a = 0; a < adds.length; a++) {
				const res = yield axios.post('/address', adds[a]);
			}
			for (let d = 0; d < deletes.length; d++) {
				const res = yield axios.delete('/address?id=' + deletes[d].id);
			}
			for (let u = 0; u < updates.length; u++) {
				const res = yield axios.put('/address', updates[u]);
			}
			successMsg += `Successfully updated addressses. `;
		}

		if (!successMsg)
			return yield put(
				actions.updateContactFail('Something went wrong updating the Contact')
		);

    // retrieve updated contact 
    const contact_id = actionData.id;
    let url = '/contact?id=' + contact_id;
    const response_get_contact = yield axios.get(url);
    const updatedContactData = response_get_contact.data;
    // get companies 
    const response_get_companies = yield axios.get('/company_contacts?contact_id=' + contact_id);
    updatedContactData.companies = response_get_companies.data;
    for (let i = 0; i < updatedContactData.companies.length; i++) {
      const cc = updatedContactData.companies[i];
      const response_get_company = yield axios.get('/company?id=' + cc.company_id);
      cc.company_data = response_get_company.data;
    }
    console.log('updatedContactData', updatedContactData);
		return yield put(actions.updateContactSuccess(successMsg, updatedContactData));
	} catch (err) {
    console.log('err', err, err.response);
		if (err.response) {
			if (err.response.data) {
				return yield put(actions.updateContactFail(err.response.data));
			}
		}
		return yield put(actions.updateContactFail('Failed to update Contact'));
	}
}

export function* deleteContactSaga(action) {
	yield put(actions.deleteContactStart());

	try {
		const response = yield axios.delete('/contact?id=' + action.contact_id);
		if (response.data) {
			if (response.data.msg === 'OK') {
				return yield put(
					actions.deleteContactSuccess('Successfully deleted contact.')
				);
			}
		}
		return yield put(
			actions.deleteContactFail('Something went wrong deleting the Contact')
		);
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteContactFail(e.response.data));
			}
		}
		return yield put(actions.deleteContactFail('Failed to delete Contact'));
	}
}

export function* getAddressesSaga(action) {
	yield put(actions.getAddressesStart());

	try {
		const response = yield axios.get('/addresses?id=' + action.contact_id);
		if (response.data) {
			return yield put(actions.getAddressesSuccess(response.data));
		}
		return yield put(
			actions.getAddressesFail('Something went wrong getting the Addresses')
		);
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getAddressesFail(e.response.data));
			}
		}
		return yield put(actions.getAddressesFail('Failed to delete Contact'));
	}
}

export function* getPartnersSaga(action) {
	yield put(actions.getPartnersStart());

	try {
		const response = yield axios.get('/partners?id=' + action.contact_id);
		if (response.data) {
			return yield put(actions.getPartnersSuccess(response.data));
		}
		return yield put(
			actions.getPartnersFail('Something went wrong getting the Addresses')
		);
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getPartnersFail(e.response.data));
			}
		}
		return yield put(actions.getPartnersFail('Failed to delete Contact'));
	}
}

export function* getContactOptionsSaga(action) {
	yield put(actions.getContactOptionsStart());

	try {
		const response = yield axios.get('/contact_options');
		if (response.data) {
			return yield put(actions.getContactOptionsSuccess(response.data));
		}
		return yield put(actions.getContactOptionsFail('Failed to get options'));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactOptionsFail(e.response.data));
			}
		}
		return yield put(actions.getContactOptionsFail('Failed to get options'));
	}
}

export function* getContactSharesSaga(action) {
	yield put(actions.getContactSharesStart());

	try {
		const response = yield axios.get(
			'/contact_shares?contact_id=' + action.contact_id
		);
		if (response.data) {
			return yield put(actions.getContactSharesSuccess(response.data));
		}
		return yield put(
			actions.getContactSharesFail('Failed to get contact shares')
		);
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactSharesFail(e.response.data));
			}
		}
		return yield put(
			actions.getContactSharesFail('Failed to get contact shares')
		);
	}
}

export function* getContactUsernamesSaga(action) {
	yield put(actions.getContactUsernamesStart());

	try {
		const response = yield axios.get('/contact_usernames');
		if (response.data) {
			return yield put(actions.getContactUsernamesSuccess(response.data));
		}
		return yield put(actions.getContactUsernamesFail('Failed to get users'));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactUsernamesFail(e.response.data));
			}
		}
		return yield put(actions.getContactUsernamesFail('Failed to get users'));
	}
}

export function* createContactShareSaga(action) {
	yield put(actions.createContactShareStart());

	try {
		const response = yield axios.post('/contact_share', action.data);
		if (response.data) {
			return yield put(actions.createContactShareSuccess(response.data));
		}
		return yield put(actions.createContactShareFail('Failed to share contact'));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createContactShareFail(e.response.data));
			}
		}
		return yield put(actions.createContactShareFail('Failed to share contact'));
	}
}

export function* deleteContactShareSaga(action) {
	yield put(actions.deleteContactShareStart());

	try {
		const response = yield axios.delete('/contact_share?id=' + action.share_id);
		if (response.data) {
			return yield put(actions.deleteContactShareSuccess(response.data));
		}
		return yield put(
			actions.deleteContactShareFail('Failed to delete sharing of contact')
		);
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteContactShareFail(e.response.data));
			}
		}
		return yield put(
			actions.deleteContactShareFail('Failed to delete sharing of contact')
		);
	}
}

export function* getContactNamesSaga(action) {
	yield put(actions.getContactNamesStart());
	const ids = action.data;
	const ids_arr = action.data ? action.data.split(',') : [];
	let contactNames = [];
	for (let i = 0; i < ids_arr.length; i++) {
		const id = ids_arr[i];
		try {
			const response = yield axios.get('/contact?id=' + id);
			if (response.data) {
				contactNames.push(response.data);
			}
		} catch (e) {
			if (e.response) {
				if (e.response.data) {
				}
			}
		}
	}
	yield put(actions.getContactNamesSuccess(contactNames));
}

export function* createCrmFileSaga(action) {
	yield put(actions.createCrmFileStart());
	try {
		const response = yield axios.post('/upload', action.data);
		yield put(actions.createCrmFileSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createCrmFileFail(e.response.data));
			}
		}
		yield put(actions.createCrmFileFail(e));
	}
}

export function* getCrmFileSaga(action) {
	yield put(actions.getCrmFileStart());
	try {
		if (Array.isArray(action.data.name) && Array.isArray(action.data.id)) {
			let file_responses = [];
			for (let i = 0; i < action.data.name.length; i++) {
				const name = action.data.name[i];
				const id = action.data.id[i];
				const response = yield axios.get(
					'/download?filename=' + name + '&id=' + id
				);
				file_responses.push({
					filename: action.data.name[i],
					data: response.data,
				});
			}
			yield put(actions.getCrmFileSuccess(file_responses));
		} else {
			const response = yield axios.get(
				'/download?filename=' + action.data.name + '&id=' + action.data.id
			);
			yield put(actions.getCrmFileSuccess(response.data));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCrmFileFail(e.response.data));
			}
		}
		yield put(actions.getCrmFileFail(e));
	}
}

export function* getCrmFileListSaga(action) {
	yield put(actions.getCrmFileListStart());
	try {
		const id = action.data.id;
		const userId = action.data.userId;

		const response = yield axios.get('/files', {
			params: {
				id: id,
				userId: userId,
			},
		});
		yield put(actions.getCrmFileListSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getCrmFileListFail(e.response.data));
			}
		}
		yield put(actions.getCrmFileListFail(e));
	}
}

export function* deleteCrmFileSaga(action) {
	yield put(actions.deleteCrmFileStart());
	try {
		const response = yield axios.delete('/delete?id=' + action.data);
		yield put(actions.deleteCrmFileSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteCrmFileFail(e.response.data));
			}
		}
		yield put(actions.deleteCrmFileFail(e));
	}
}

export function* addPropertyToContactSaga(action) {
	yield put(actions.addPropertyToContactStart());
	try {
		const newData = clone(action.data);
		if (newData.hasOwnProperty('from_property')) delete newData.from_property;
		const response = yield axios.post('/property_to_contact', newData);
		yield put(actions.addPropertyToContactSuccess(response.data));
		if (action.data_type === 'property') {
			yield put(actions.getPropertyToContact(newData.property_id, 'property'));
		} else {
			yield put(actions.getPropertyToContact(newData.contact_id));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.addPropertyToContactFail(e.response.data));
			}
		}
		yield put(actions.addPropertyToContactFail(e));
	}
}

export function* removePropertyToContactSaga(action) {
	yield put(actions.removePropertyToContactStart());
	try {
		const response = yield axios.delete(
			'/property_to_contact/?id=' + action.data
		);
		yield put(actions.removePropertyToContactSuccess(response.data));
		if (action.data_type === 'property' && action.data_type_id) {
			yield put(actions.getPropertyToContact(action.data_type_id, 'property'));
		} else if (action.data_type_id) {
			yield put(actions.getPropertyToContact(action.data_type_id));
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.removePropertyToContactFail(e.response.data));
			}
		}
		yield put(actions.removePropertyToContactFail(e));
	}
}

export function* getPropertyToContactSaga(action) {
	yield put(actions.getPropertyToContactStart());
	try {
		if (action.data_type === 'property') {
			// property
			const response = yield axios.get(
				'/property_to_contact?property_id=' + action.data
			);
			yield put(
				actions.getPropertyToContactSuccess(response.data, action.data_type)
			);
		} else {
			const response = yield axios.get(
				'/property_to_contact?id=' + action.data
			);
			yield put(
				actions.getPropertyToContactSuccess(response.data, action.data_type)
			);
		}
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getPropertyToContactFail(e.response.data));
			}
		}
		yield put(actions.getPropertyToContactFail(e));
	}
}

export function* getContactChangesSaga(action) {
	yield put(actions.getContactChangesStart());
	try {
		let changes = [];
		if (!action.data) throw 'No data';
		for (let i = 0; i < action.data.length; i++) {
			const contact = action.data[i];
			let main_address = null;
			if (contact.id) {
				const response = yield axios.get('/contact?id=' + contact.id);
				const db_contact = response.data;
				const fields = Object.keys(contact);
				let changeDetected = false;
				const exempted_fields = ['id', 'address', 'city', 'state', 'zip'];
				const address_fields = ['address', 'city', 'state', 'zip'];
				for (let j = 0; j < fields.length; j++) {
					const field = fields[j];
					if (exempted_fields.indexOf(field) === -1) {
						if (contact[field]) {
							if (db_contact[field] !== contact[field]) {
								changeDetected = true;
							}
						}
					} else if (address_fields.indexOf(field) !== -1) {
						const addresses = db_contact.addresses;
						if (addresses) {
							if (addresses.length > 0) {
								addresses.forEach((add) => {
									if (add.type === 'M') {
										main_address = add;
									}
								});
								if (field === 'address') {
									if (contact[field]) {
										if (contact[field] !== main_address.street1) {
											changeDetected = true;
										}
									}
								} else {
									if (contact[field]) {
										if (contact[field] !== main_address[field]) {
											changeDetected = true;
										}
									}
								}
							}
						}
					}
				}
				if (changeDetected) {
					const before_obj = {
						id: db_contact.id,
						first_name: db_contact.first_name,
						last_name: db_contact.last_name,
					};
					const after_obj = {
						id: db_contact.id,
						first_name: contact.first_name,
						last_name: contact.last_name,
					};
					if (contact.email) {
						before_obj.email = db_contact.email;
						after_obj.email = contact.email;
					}
					if (contact.address) {
						before_obj.address = main_address ? main_address.street1 : '';
						after_obj.address = contact.address ? contact.address : '';
					}
					if (contact.city) {
						before_obj.city = main_address ? main_address.city : '';
						after_obj.city = contact ? contact.city : '';
					}
					if (contact.state) {
						before_obj.state = main_address ? main_address.state : '';
						after_obj.state = contact ? contact.state : '';
					}
					if (contact.main_phone) {
						before_obj.main_phone = db_contact.main_phone
							? db_contact.main_phone
							: '';
						after_obj.main_phone = contact ? contact.main_phone : '';
					}
					changes.push({
						before: before_obj,
						after: after_obj,
					});
				}
			}
		}
		yield put(actions.getContactChangesSuccess(changes));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactChangesFail(e.response.data));
			}
		}
		yield put(actions.getContactChangesFail(e));
	}
}

export function* saveContactChangesSaga(action) {
	yield put(actions.saveContactChangesStart());
	try {
		for (let i = 0; i < action.data.length; i++) {
			const contact = action.data[i];
			const update_contact = {
				id: contact.id,
				first_name: contact.first_name,
				last_name: contact.last_name,
			};
			if (contact.email) update_contact.email = contact.email;
			if (contact.main_phone) update_contact.main_phone = contact.main_phone;
			if (contact.fax) update_contact.fax = contact.fax;
			yield axios.put('/contact', update_contact);
			if (contact.address) {
				const resp = yield axios.get('/contact?id=' + contact.id);
				let main_address = null;
				if (resp.data) {
					resp.data.addresses.forEach((add) => {
						if (add.type === 'M') {
							// main only
							main_address = add;
						}
					});
				}
				if (main_address) {
					const new_address = {
						id: main_address.id,
						contact_id: contact.id,
						street1: contact.address,
						type: 'M',
					};
					if (contact.city) new_address.city = contact.city;
					if (contact.state) new_address.state = contact.state;
					if (contact.zip) new_address.zip = contact.zip;
					yield axios.put('/address', new_address);
				} else {
					const new_address = {
						contact_id: contact.id,
						street1: contact.address,
						type: 'M',
					};
					if (contact.city) new_address.city = contact.city;
					if (contact.state) new_address.state = contact.state;
					if (contact.zip) new_address.zip = contact.zip;
					yield axios.post('/address', new_address);
				}
			}
		}
		yield put(actions.saveContactChangesSuccess('OK'));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.saveContactChangesFail(e.response.data));
			}
		}
		yield put(actions.saveContactChangesFail(e));
	}
}

export function* getContactEntitiesSaga(action) {
	yield put(actions.getContactEntitiesStart());
	try {
		const response = yield axios.get('/contact_entities?contact_id=' + action.data);
		const response_data = response.data;
		if (response_data) {
			for (let i = 0; i < response_data.length; i++) {
				const entity = response_data[i];
				if (entity.type === 1) {
					// CONTACT
					entity.company_contacts = [];
					if (entity.entity_company_id) {
						// gather company contacts data
						const company_contacts = yield axios.get(`/company_contacts?id=${entity.entity_company_id}`)
						if (company_contacts.data) {
							entity.company_contacts = company_contacts.data;
						}
					}
					if (entity.dba_id && entity.entity_id && !entity.entity_company_id) {
						// CONTACT ONLY
						const contact_dba_response = yield axios.get(`/contact_dbas?id=${entity.entity_id}`)
						if (contact_dba_response.data) {
							entity.dba_data = contact_dba_response.data;
						}
					} else if (entity.dba_id && entity.entity_id && entity.entity_company_id) {
						// CONTACT AND COMPANY 
						const company_dba_response = yield axios.get(`/company_dbas?id=${entity.entity_company_id}`)
						if (company_dba_response.data) {
							entity.dba_data = company_dba_response.data;
						}
						// pull contact dbas if secondary_dba_id is not null
						if (entity.secondary_dba_id) {
							const contact_dba_response = yield axios.get(`/contact_dbas?id=${entity.entity_id}`)
							if (contact_dba_response.data) {
								entity.secondary_dba_data = contact_dba_response.data;
							}
						}
					}
				} else {
					// COMPANY 
					const company_dba_response = yield axios.get(`/company_dbas?id=${entity.entity_id}`);
					entity.dba_data = company_dba_response.data;
				}
			}
		}
		yield put(actions.getContactEntitiesSuccess({
			[action.data]: response_data
		}));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getContactEntitiesFail(e.response.data));
			}
		}
		yield put(actions.getContactEntitiesFail(e));
	}
}

export function* createContactEntitySaga(action) {
    yield put(actions.createContactEntityStart());
    try {
		const dba_data = get(action, 'data.dba_data', null);
		if (dba_data) delete action.data.dba_data;
        const response = yield axios.post('/contact_entity', action.data);
        yield put(actions.createContactEntitySuccess(response.data));
		yield put(actions.getContactEntities(action.data.contact_id));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createContactEntityFail(e.response.data));
            }
          }
        yield put(actions.createContactEntityFail(e));
    }
}

export function* deleteContactEntitySaga(action) {
    yield put(actions.deleteContactEntityStart());
    try {
        const response = yield axios.delete('/contact_entity?id='+action.data);
        yield put(actions.deleteContactEntitySuccess(response.data));
		
		const contactId = get(response, 'data.contact_id', null)
		if (contactId) {
			yield put(actions.getContactEntities(contactId));
		}

    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteContactEntityFail(e.response.data));
            }
          }
        yield put(actions.deleteContactEntityFail(e));
    }
}

export function* getUpdatedContactEntitySaga(action) {
    yield put(actions.getUpdatedContactEntityStart());
    try {
        const response = yield axios.get('/contact?id='+action.data);
        yield put(actions.getUpdatedContactEntitySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getUpdatedContactEntityFail(e.response.data));
            }
          }
        yield put(actions.getUpdatedContactEntityFail(e));
    }
}

export function* getContactDbasSaga(action) {
	yield put(actions.getContactDbasStart());
	try {
			const response = yield axios.get('/contact_dbas?id='+action.data);
			yield put(actions.getContactDbasSuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.getContactDbasFail(e.response.data));
					}
				}
			yield put(actions.getContactDbasFail(e));
	}
}

export function* createContactDbaSaga(action) {
	yield put(actions.createContactDbaStart());
	try {
			const response = yield axios.post('/contact_dba', action.data);
			yield put(actions.createContactDbaSuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.createContactDbaFail(e.response.data));
					}
				}
			yield put(actions.createContactDbaFail(e));
	}
}

export function* updateContactDbaSaga(action) {
	yield put(actions.updateContactDbaStart());
	try {
			const response = yield axios.put('/contact_dba', action.data);
			yield put(actions.updateContactDbaSuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.updateContactDbaFail(e.response.data));
					}
				}
			yield put(actions.updateContactDbaFail(e));
	}
}

export function* deleteContactDbaSaga(action) {
	yield put(actions.deleteContactDbaStart());
	try {
			const response = yield axios.delete('/contact_dba?id='+action.data);
			yield put(actions.deleteContactDbaSuccess(response.data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.deleteContactDbaFail(e.response.data));
					}
				}
			yield put(actions.deleteContactDbaFail(e));
	}
}

export function* getContactCompaniesSaga(action) {
	yield put(actions.getContactCompaniesStart());
	try {
			const response = yield axios.get('/contact_companies?id='+action.data);
			const {data} = response;
			
			if (data) {
				for (let i = 0; i < data.length; i++) {
					const company = yield axios.get('/company?id='+data[i].company_id);
					data[i].company_data = company?.data;
				}
			}
			
			yield put(actions.getContactCompaniesSuccess(data));
	} catch (e) {
			if (e.response) {
					if (e.response.data) {
						return yield put(actions.getContactCompaniesFail(e.response.data));
					}
				}
			yield put(actions.getContactCompaniesFail(e));
	}
}

export function* getCrmDataSaga(action) {
    yield put(actions.getCrmDataStart());
    try {
		let params = [];
		Object.keys(action.data).forEach(f => {
			params.push(`${f}=${action.data[f]}`);
		});
        const response = yield axios.get('/crm_data?'+params.join('&'));
        yield put(actions.getCrmDataSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCrmDataFail(e.response.data));
            }
          }
        yield put(actions.getCrmDataFail(e));
    }
}

export function* updateCrmDataSaga(action) {
    yield put(actions.updateCrmDataStart());
    try {
        const response = yield axios.put('/crm_data', action.data);
        yield put(actions.updateCrmDataSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateCrmDataFail(e.response.data));
            }
          }
        yield put(actions.updateCrmDataFail(e));
    }
}