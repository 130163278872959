import React from "react";
import { useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "../../../shared/utility";
import Table from "components/Table/Table";
import { Dialog } from "@material-ui/core";
import Notification from "../../../components/Notification/Notification";
import Datetime from "react-datetime";
import {
	min1,
	required,
	no_letters,
	max50,
} from "shared/validation";
import { useEffect } from "react";
import NotificationAsk from "../../../components/Notification/NotificationAsk";

export const PropertiesFormSpaces = (props) => {
	const { updateDataObj, dataObj, setMadeChanges, propertyUseTypes } = props;

	const initialData = {
		total_sqft: "",
		price_per_sqft: "",
		unit_number_address: "",
		lease_type: "",
		flat_price: "",
		is_flat_price: "",
		use_type: "",
		use_type_other: "",
		vacant: "",
		date_available: "", // shown if vacant = no,
		monthly_amount: "",
		sub_lease: "",

		min_divisible: "",
		max_contigous: "",
		build_out: ""
	};

	const [openForm, setOpenForm] = useState(false);
	const [action, setAction] = useState(false);
	const [formData, setFormData] = useState(initialData);
	const [originalFormData, setOriginalFormData] = useState(initialData);
	const [index, setIndex] = useState(-1);
	const [openAskBeforeDelete, setOpenAskBeforeDelete] = useState(false);
	const [validSqft, setValidSqft] = useState(true);
	const [useTypes, setUseTypes] = useState([]);
	const [notification, setNotification] = useState('');
	const [vacationHasChangedFromNoToYes, setVacationHasChangedFromNoToYes] = useState(false);
	const [startSendingMarketing, setStartSendingMarketing] = useState(false);

	useEffect(() => {
		if (originalFormData.vacant === 'No' && formData.vacant === 'Yes') {
			setVacationHasChangedFromNoToYes(true);
		}
	}, [formData]);

	useEffect(() => {
        if (props.success_send_to_marketing && startSendingMarketing) {
            setNotification('illi Marketing has been notified');
            setStartSendingMarketing(false);
        }
    }, [startSendingMarketing, props.success_send_to_marketing]);

	useEffect(() => {
		let useTypes = propertyUseTypes.unit_use_type.concat([]).sort();
		useTypes.push('Other');
		setUseTypes(useTypes);
	}, []);

	const renderElement = (field, label, validations, uppercase, isCurrency) => {
		return (
			<CustomInput
				value={formData[field]}
				label={label}
				onChange={(e) => {
					const newData = clone(formData);
					newData[field] = e.target.value;
					setFormData(newData);
				}}
				validate={validations}
				uppercase={uppercase ? true : false}
				isCurrency={isCurrency ? true : false}
				startAdornment={isCurrency ? '$' : ''}
			/>
		);
	};

	const checkValidity = () => {
		let passed = true;
		const unit_number_address = formData.unit_number_address ? formData.unit_number_address.trim() : "";
		if (!unit_number_address) passed = false;
		if (!formData.total_sqft) passed = false;
		if (!formData['is_flat_price']) {
			// Price per sqft is required
			if (!formData.price_per_sqft) passed = false;
		} else {
			// Flat Price is required
			if (!formData.flat_price) passed = false;
		}
		return passed;
	};

	const update = (field, value) => {
		const newData = clone(formData);
		newData[field] = value;
		setFormData(newData);
	}

	return (
		<div
			style={{ width: "100%" }}
			className={
				props.action === "View"
					? "no-click lightgray-bg enclosure"
					: "enclosure"
			}
		>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h3>
						{dataObj.spaces && dataObj.spaces.length
							? dataObj.spaces.length
							: 0}{" "}
						Space(s)
					</h3>
					{props.action !== "View" && (
						<Button
							color="primary"
							onClick={() => {
								if (!dataObj.total_building_sqft) {
									return setNotification('Total Building Square Feet is required before you add a Space. Please enter one in the Main tab.');
								}
								setAction("Add");
								setFormData(clone(initialData));
								setOriginalFormData(clone(initialData));
								setIndex(-1);
								setOpenForm(true);
							}}
						>
							<i className="fas fa-plus"></i> ADD SPACE
						</Button>
					)}
				</GridItem>
			</GridContainer>

			{dataObj.spaces && (
				<GridContainer>
					{dataObj.spaces.map((s, i) => {
						return (
							<GridItem xs={12} sm={4} md={4} key={`${i}-spaces`}>
								<div className="boxed">
									{props.action !== "View" && (
										<Button
											className="mr-20"
											color="primary"
											onClick={() => {
												setFormData(clone(s));
												setOriginalFormData(clone(s));
												setIndex(i);
												setAction("Edit");
												setOpenForm(true);
											}}
										>
											<i className="fas fa-edit"></i> EDIT
										</Button>
									)}

									{props.action !== "View" && (
										<Button
											color="danger"
											onClick={() => {
												setIndex(i);
												setOpenAskBeforeDelete(true);
											}}
										>
											<i className="fas fa-trash"></i> DELETE
										</Button>
									)}

									<div style={{ lineHeight: 1.5 }} className="mt-20">
										<Table
											tableData={[
												["Total Square Feet", "total_sqft"],
												["Price Per Square Feet ($)", "price_per_sqft"],
												["Flat Price ($)", "flat_price"],
												["Price Type", "is_flat_price"],
												["Space Address / Unit Number", "unit_number_address"],
												["Lease Type", "lease_type"],
												["Monthly Amount ($)", "monthly_amount"],
												["Sub Lease", "sub_lease"],
												["Use Type", "use_type"],
												["Use Type - Other", "use_type_other"],
												["Vacant", "vacant"],
												["Date Available", "date_available"],
												["Min Divisible", 'min_divisible'],
												["Max Contigous", 'max_contigous'],
												["Build Out", 'build_out']
											]
											.filter(m => {
												if (s.is_flat_price && m[0] === 'Price Per Square Feet') return false;
												if (!s.is_flat_price && m[0] === 'Flat Price') return false;
												if (m[0] === 'Use Type - Other' && s.use_type !== 'Other') return false;
												if (m[0] === 'Date Available' && s.vacant !== 'No') return false; // if vacant == no, show date available
												if (m[0] === 'Monthly Amount ($)' && (s.lease_type !== 'MG' && s.lease_type !== 'NNN')) {
													return false;
												}
												return true;
											})
											.map((m) => {
												let value = s[m[1]];
												let field_desc = m[0];
												if (m[0] === 'Price Type') {
													if (s.is_flat_price) {
														value = 'Flat Price';
													} else {
														value = 'Price Per Square Feet'
													}
												}
												if (m[0] === 'Monthly Amount ($)') {
													if (s.lease_type === 'NNN') {
														field_desc = "NNN Amount ($)";
													} else if (s.lease_type === 'MG') {
														field_desc = "MG Amount ($)";
													} else if (s.lease_type === 'FSG') {
														field_desc = "FSG Amount ($)";
													}
												}
												return [
													<div style={{ textAlign: "right" }}>
														<strong>{field_desc}</strong>
													</div>,
													value,
												];
											})}
										/>
									</div>
								</div>
							</GridItem>
						);
					})}
				</GridContainer>
			)}

			{openForm && (
				<Dialog
					open={openForm}
					onClose={() => {
						setOpenForm(false);
					}}
					fullWidth={true}
					maxWidth="md"
				>
					<div className="dialog-wrapper">
						<h3>{action} Space</h3>
						<GridContainer>
							<GridItem xs={12} sm={3} md={3}>
								{renderElement("unit_number_address", "Space Address / Unit Number", [
									min1,
									required,
								])}
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomInput
									value={formData["total_sqft"]}
									label={"Total Square Feet"}
									onChange={(e) => {
										const value = e.target.value;

										const otherSpacesSqft = dataObj.spaces.reduce((totalSpaceSqft, current) => parseFloat(current.total_sqft) + totalSpaceSqft, 0);
										const totalSpaceSqft = otherSpacesSqft + parseFloat(value) ;
										const totalBuildingSqft = dataObj.total_building_sqft ? parseFloat(dataObj.total_building_sqft) : 0;
										if (totalSpaceSqft > totalBuildingSqft) {
											setValidSqft(false);
										} else {
											setValidSqft(true);
										}

										const newData = clone(formData);
										newData["total_sqft"] = e.target.value;
										setFormData(newData);
									}}
									validate={[
										min1,
										max50,
										no_letters,
										required
									]}
									uppercase={false}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomInput
									value={formData.min_divisible}
									label="Min Divisible"
									onChange={(e) => {
										const newData = clone(formData);
										newData.min_divisible = e.target.value;
										setFormData(newData);
									}}
									number_only={true}
								/>
								<small>* Must be less or equal of total square feet of this space</small>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomInput
									value={formData.max_contigous}
									label="Max Contigous"
									onChange={(e) => {
										const newData = clone(formData);
										newData.max_contigous = e.target.value;
										setFormData(newData);
									}}
									number_only={true}
								/>
								<small>* Must be less or equal of property's total square feet</small>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomRadio
									label="Build Out,Yes,No"
									options={["Yes", "No"]}
									onChange={(e) => {
										const newData = clone(formData);
										newData.build_out = e.target.value;
										setFormData(newData);
									}}
									value={formData.build_out}
								/>
							</GridItem>

							{!formData['is_flat_price'] && <GridItem xs={12} sm={3} md={3}>
								{renderElement("price_per_sqft", "Price Per Square Feet", [
									min1,
									max50
								], false, 'is_currency')}
								{!formData.price_per_sqft && <div className="red-text">
									<small>Required</small>
								</div>}
							</GridItem>}
							{formData['is_flat_price'] && <GridItem xs={12} sm={3} md={3}>
								{renderElement("flat_price", "Flat Price", [
									min1,
									max50
								], false, 'is_currency')}
								{!formData.flat_price && <div className="red-text">
									<small>Required</small>
								</div>}
							</GridItem>}
							<GridItem xs={12} sm={3} md={3}>
								<CustomRadio
									label="Price Type,Flat Price,Price Per Square Feet"
									options={["Yes", ""]}
									onChange={(e) => {
										const newData = clone(formData);
										newData["is_flat_price"] = e.target.value;
										if (newData.is_flat_price) {
											// render Price per Sq Ft. blank
											newData.price_per_sqft = '';
										} else {
											// render Flat price blank
											newData.flat_price = '';
										}
										setFormData(newData);
									}}
									value={formData.is_flat_price}
								/>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<div style={{opacity: formData.use_type === 'Residential' ? 0 : 1}}><CustomSelect
									label="Lease Type"
									choose={(e, n) => {
										const newData = clone(formData);
										newData["lease_type"] = e;
										if (e !== 'MG' && e !== 'NNN') {
											newData.monthly_amount = '';
										}
										setFormData(newData);
									}}
									options={props.propertyUseTypes.lease_type}
                                    default={formData['lease_type']}
								/>
									{(formData.lease_type === 'MG' || formData.lease_type === 'NNN') && <CustomInput
										value={formData.monthly_amount ? formData.monthly_amount : ''}
										label="Monthly Amount"
										onChange={(e) => {
											const newData = clone(formData);
											newData.monthly_amount = e.target.value;
											setFormData(newData);
										}}
										isCurrency={true}
										startAdornment='$'
									/>}
									<CustomRadio
										label="Sub Lease,Yes,No"
										options={["Yes", "No"]}
										onChange={(e) => {
											const newData = clone(formData);
											newData.sub_lease = e.target.value;
											setFormData(newData);
										}}
										value={formData.sub_lease}
									/>
								</div>
							</GridItem>
							<GridItem xs={12} sm={3} md={3}>
								<CustomSelect
									label="Use Type"
									choose={(e, n) => {
										const newData = clone(formData);
										newData["use_type"] = e;
										if (e === 'Residential') {
											newData["lease_type"] = '';
										}
										setFormData(newData);
									}}
									options={useTypes}
                                    default={formData['use_type']}
								/>
							</GridItem>
							{formData["use_type"] === "Other" && (
								<GridItem xs={12} sm={3} md={3}>
									{renderElement("use_type_other", "Use Type - Other", [required])}
								</GridItem>
							)}
							<GridItem xs={12} sm={3} md={3}>
								<CustomRadio
									label="Vacant,Yes,No"
									options={["Yes", "No"]}
									onChange={(e) => {
										const newData = clone(formData);
										newData["vacant"] = e.target.value;
										if (e.target.value === 'No') {
											newData.date_available = '';
										}
										setFormData(newData);
									}}
									value={formData.vacant}
								/>
								{formData.vacant === "No" && <div>
								<div className="custom relative">
									{formData.date_available && <div className="tiny-label">Date Available</div>}
									<Datetime
										inputProps={{
											placeholder: "Date Available",
										}}
										closeOnSelect={true}
										onChange={(e) => {
											if (typeof e === 'object') {
												const stringDate = e.format('MM/DD/YYYY');
												return update('date_available', stringDate);;
											}
											updateDataObj('date_available', e)
										}}
										timeFormat={false}
										value={formData.date_available}
									/>
								</div>
								</div>}
							</GridItem>
						</GridContainer>
						<p>Total building square feet: {dataObj.total_building_sqft}</p>
						<hr />
						<p className={`${!validSqft ? 'error-message' : ''}`}>* Total spaces' square feet cannot exceed total building square feet.</p>
						{action === "Add" && (
							<Button
								color="success"
								className="mr-20"
								onClick={() => {
									if (checkValidity()) {
										setMadeChanges(true)
										updateDataObj(
											"spaces",
											dataObj.spaces.concat([clone(formData)])
										);
										setOpenForm(false);
									}
								}}
								disabled={!validSqft}
							>
								ADD
							</Button>
						)}
						{action === "Edit" && (
							<Button
								color="primary"
								className="mr-20"
								onClick={() => {
									if (checkValidity()) {
										setMadeChanges(true);
										const newDataObj = clone(dataObj);
										newDataObj.spaces = dataObj.spaces.map((dr, j) => {
											if (j === index) {
												return clone(formData);
											}
											return dr;
										});
										props.setDataObj(newDataObj);
										setOpenForm(false);
									}
								}}
								disabled={!validSqft}
							>
								UPDATE
							</Button>
						)}
						<Button
							color="white"
							onClick={() => {
								setOpenForm(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}

			{openAskBeforeDelete && (
				<Dialog
					maxWidth="sm"
					fullWidth={true}
					open={openAskBeforeDelete}
					onClose={() => {
						setOpenAskBeforeDelete(false);
					}}
				>
					<GridContainer>
						<GridItem xs={12} sm={12} md={12}>
							<div className="dialog-wrapper text-center">
								<h4>Are you sure you want to delete this space?</h4>
								<Button
									color="danger"
									className="mr-20"
									onClick={() => {
										updateDataObj(
											"spaces",
											dataObj.spaces.filter((ds, j) => {
												if (j === index) return false;
												return true;
											})
										);
										setMadeChanges(true)
										setOpenAskBeforeDelete(false);
									}}
								>
									OK DELETE
								</Button>
								<Button
									color="white"
									onClick={() => {
										setOpenAskBeforeDelete(false);
									}}
								>
									CANCEL
								</Button>
							</div>
						</GridItem>
					</GridContainer>
				</Dialog>
			)}

			{notification && <Notification 
						open={notification ? true : false}
						close={() => {
							setNotification('');
						}}
						message={notification}
					/>}

			{vacationHasChangedFromNoToYes && <NotificationAsk 
				open={vacationHasChangedFromNoToYes ? true : false}
				close={() => {
					setVacationHasChangedFromNoToYes(false);
				}}
				title="Notify illi Marketing?"
				message="Send notification that vacancy info of this space has been updated?"
				success={() => {
					if (props.sendToMarketing) {
						setStartSendingMarketing(true);
						props.sendToMarketing({
							subject: 'illi Marketing Notification',
							message: `Property ${dataObj.name} has updated vacancy info.`
						});
						setVacationHasChangedFromNoToYes(false);
					}
				}}
				success_button_text="Send"
			/>}
		</div>

		
	);
};
