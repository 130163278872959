import List from "@material-ui/core/List";
import { Dialog } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { clone, get } from "lodash";
import React, { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Table from 'components/Table/Table.jsx';
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";

import ContactsForm from "../CRM/Contacts/ContactsForm";

const DialPadMergeContact = (props) => {
    const {
        contactOptions,
        contactToImport,
        createContact,
        getContacts,
        illi_contacts,
        onContactClick,
        loading,
        handleSkip,
        contactToUpdate,
        setContactToUpdate,
        goTo,
    } = props;

    const [searchedValue, setSearchedValue] = useState(null);
    const [options, setOptions] = useState([]);

    const [openedNewContact, setOpenedNewContact] = useState(false);

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const clearMessages = () => {
        setSuccess("");
        setError("");
    };

    const searchContact = (the_choice) => {
        getContacts({
            names_only: "All",
            search_term: the_choice,
        });
    };

    useEffect(() => {
        if (searchedValue && searchedValue.length > 1) {
            searchContact(searchedValue);
        }
    }, [searchedValue]);

    useEffect(() => {
        if (get(contactToImport, 'first_name', null)) {
            setSearchedValue(get(contactToImport, 'first_name', ''));
        }
    }, [contactToImport]);

    useEffect(() => {
        if (illi_contacts) {
            const newOptions = illi_contacts.map((co) => {
                const ct = clone(co);
                ct.name =
                    co.first_name || co.last_name
                        ? `${co.first_name ? co.first_name : ""} ${co.last_name ? co.last_name : ""}`
                        : co.company_name
                        ? co.company_name
                        : "";
                return ct;
            });
            setOptions(newOptions);
        }
    }, [illi_contacts]);

    const contactToImportInfoTable = (
		<div style={{ lineHeight: 1.5 }} className='mt-20 boxed'>
			<Table
				tableData={[
					['First Name', 'first_name'],
					['Last Name', 'last_name'],
					['Company', 'company_name'],
					['Phone', 'primary_phone'],
					['Email', 'primary_email'],
				].map((field) => {
					return [
						<div style={{ textAlign: 'right' }}>
							<strong>{field[0]}</strong>
						</div>,
						contactToImport[field[1]],
					];
				})}
			/>
		</div>
	)


    return (
        <React.Fragment>
            {!contactToUpdate && (
                <div>
                    <Button color="primary" onClick={handleSkip}>
                        SKIP
                    </Button>
                </div>
            )}

            {contactToImportInfoTable}

            <Button className="outlined-btn" size="sm" onClick={() => setOpenedNewContact(true)}>
                <i className="fa fa-plus"></i> CREATE NEW CONTACT
            </Button>

            <h3 style={{ textAlign: "center" }}>Select Contact To Merge</h3>

            <CustomInput
                label="Search Contacts"
                value={searchedValue}
                onChange={(e) => setSearchedValue(e.target.value)}
            />

            <div style={{ textAlign: "center", padding: 20 }}>
                {options && (
                    <div
                        style={{
                            height: !searchedValue ? 100 : 300,
                            overflowY: "scroll",
                            padding: 10,
                            backgroundColor: "#f1f1f1",
                        }}
                    >
                        {loading && (
                            <div className="text-center">
                                <ClipLoader />
                            </div>
                        )}
                        {!searchedValue && (
                            <div style={{ marginTop: 20 }}>
                                <em>
                                    Enter letters or numbers from the contact's name
                                    <br />
                                    you are searching for in the line above
                                </em>
                            </div>
                        )}

                        {!loading && searchedValue && (
                            <List component="nav">
                                {options.map((o, i) => {
                                    const desc = o.contact_type ? (
                                        o.contact_subtype ? (
                                            <span>
                                                {`${o.name}`}
                                                &nbsp;
                                                <small>
                                                    <em>({o.contact_type})</em>
                                                </small>
                                                &nbsp;
                                                <small>
                                                    <em>({o.contact_subtype})</em>
                                                </small>
                                            </span>
                                        ) : (
                                            <span>
                                                {`${o.name}`}&nbsp;
                                                <small>
                                                    <em>({o.contact_type})</em>
                                                </small>
                                            </span>
                                        )
                                    ) : (
                                        o.name
                                    );
                                    return (
                                        <ListItem button key={`contact-option-${i}`} onClick={() => onContactClick(o)}>
                                            <ListItemText inset primary={desc} />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        )}
                    </div>
                )}
            </div>

            <Dialog open={openedNewContact} keepMounted maxWidth={false} fullWidth={true} fullScreen={true}>
                <div className="dialog-wrapper">
                    <ContactsForm
                        action="Add"
                        closeForm={() => setOpenedNewContact(false)}
                        contactData={null}
                        contactOptions={contactOptions}
                        createContact={(data) => {
							createContact(data);
							setContactToUpdate({
                                action: 'CREATE',
                                data: data
                            })
						}}
                        // partnersData={props.partnersData}
                        // getPartnersData={props.getPartnersData}
                        success={success}
                        error={error}
                        clearMessages={clearMessages}
                        // peopleType={props.peopleType}
                        getContacts={getContacts}
                        prefill={{
                            first_name: get(contactToImport, 'first_name', ''),
                            last_name: get(contactToImport, 'last_name' , ''),
                            email: get(contactToImport, 'primary_email', ''),
                            main_phone: get(contactToImport, 'primary_phone', ''),
                        }}
                    />
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default DialPadMergeContact;
