import { put } from 'redux-saga/effects';
import axios from '../axios/axios-listing';
import * as actions from '../actions/listing';

export function* getListingEntrySaga(action) {
	yield put(actions.getListingEntryStart());
	try {
		const response = yield axios.get('/entry?id='+action.data);
		yield put(actions.getListingEntrySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getListingEntryFail(e.response.data));
			}
		}
		yield put(actions.getListingEntryFail(e));
	}
}

export function* getListingEntriesSaga(action) {
	yield put(actions.getListingEntriesStart());
	try {
		let params = [];
		if (action.data) {
			Object.keys(action.data).forEach((field) => {
				params.push(`${field}=${action.data[field]}`);
			});
		}
		const response = yield axios.get('/entries?q=true' + `${params.length > 0 ? '&' : ''}` + params.join('&'));
		yield put(actions.getListingEntriesSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getListingEntriesFail(e.response.data));
			}
		}
		yield put(actions.getListingEntriesFail(e));
	}
}

export function* createListingEntrySaga(action) {
	yield put(actions.createListingEntryStart());
	try {
		const response = yield axios.post('/entry', action.data);
		yield put(actions.createListingEntrySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.createListingEntryFail(e.response.data));
			}
		}
		yield put(actions.createListingEntryFail(e));
	}
}

export function* updateListingEntrySaga(action) {
	yield put(actions.updateListingEntryStart());
	try {
		const response = yield axios.put('/entry', action.data);
		yield put(actions.updateListingEntrySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.updateListingEntryFail(e.response.data));
			}
		}
		yield put(actions.updateListingEntryFail(e));
	}
}

export function* deleteListingEntrySaga(action) {
	yield put(actions.deleteListingEntryStart());
	try {
		const response = yield axios.delete('/entry?id=' + action.data);
		yield put(actions.deleteListingEntrySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.deleteListingEntryFail(e.response.data));
			}
		}
		yield put(actions.deleteListingEntryFail(e));
	}
}

export function* getListingPdfSaga(action) {
	yield put(actions.getListingPdfStart());
	try {
		const response = yield axios.post(`/entry/pdf/${action.data.id}`, {
			htmlTemplate: action.data.entry,
		});
		yield put(actions.getListingPdfSuccess(response));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getListingPdfFail(e.response.data));
			}
		}
		yield put(actions.getListingPdfFail(e));
	}
}

export function* getListingHistorySaga(action) {
    yield put(actions.getListingHistoryStart());
    try {
        const response = yield axios.get('/history?listing_id='+action.data);
        yield put(actions.getListingHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getListingHistoryFail(e.response.data));
            }
          }
        yield put(actions.getListingHistoryFail(e));
    }
}
