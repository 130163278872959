const offerFields = {
    offer_amount: {
        type: 'input',
        label: 'Offer Amount',
        validations: ['no_letters','min1','max100','required']
    },
    finance_contingencies: {
        type: 'text',
        label: 'Finance Contingencies',
        validations: ['min1','max250']
    },
    inspection_contingencies: {
        type: 'text',
        label: 'Inspection Contingencies',
        validations: ['min1','max250']
    },
    offer_type: {
        type: 'select',
        label: 'Offer Type',
        options: ['Initial Offer', 'Counter Offer Lessor', 'Counter Offer Lessee',
            'Counter Offer Buyer', 'Counter Offer Seller']
    },
    offer_status: {
        type: 'select',
        label: 'Offer Status',
        options: ['Accepted', 'Rejected', 'Countered']
    },
    offer_from: {
        type: 'select',
        label: 'Offer From',
        options: ['New', 'Existing']
    }
}

export default offerFields;

export const initialOfferData = {
    id: '',
    offer_amount: '',
    finance_contingencies: '',
    inspection_contingencies: '',
    offer_type: '',
    offer_status: ''
}