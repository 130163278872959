import React from 'react';
import { useEffect, useState } from 'react';
import { useGoogleLogout } from 'react-google-login';
import { googleClientID } from '../../shared/devStatus';
import logo from 'assets/img/white-illi-logo.png';
import { GoogleLogout } from 'react-google-login';

const UserLogout = (props) => {
    const [loggedOut, setLoggedOut] = useState(false);

    const onLogoutSuccess = () => {
        
        setLoggedOut(true);
    }
    const onFailure = () => {
        
        setLoggedOut(false);
    }
    const { signOut } = useGoogleLogout({
        clientId: googleClientID,
        onFailure,
        onLogoutSuccess,
    });

    useEffect(() => {
        signOut();
        localStorage.removeItem('token');
        // remove auth header for future request
        props.setAuthToken(false);
        props.clearUsername();
        props.logoutUser();
    }, []);

    useEffect(() => {

    }, [loggedOut])

    const logoutWindow = <a href="#"
        target="_blank"
        style={{color:'white'}}
        onClick={() => {
            window.open('https://www.google.com/accounts/Logout', '_blank', 'location=yes,height=570,width=520,scrollbars=yes,status=yes');
        }}>
    Click here to log out of all Google Accounts / Change User</a>

    if (loggedOut) {
        return (
            <div style={{padding:'100px 20px',backgroundColor:'black', height: '100vh'}} className="text-center">
                {/* <Redirect to="/pages/login-page" /> */}
                <img src={logo} style={{width:100}} />
                <h1 style={{color:'white'}}>Logged Out</h1><br />
                {logoutWindow}
                <br /><br />
                <a href="/" style={{color:'white'}} onClick={() => {
                    window.location.href = '/';
                }}>Click here to log in</a>
            </div>
        );
    } else {
        return (
            <div style={{padding:'100px 20px',backgroundColor:'black', height: '100vh'}} className="text-center">
                {/* <Redirect to="/pages/login-page" /> */}
                <img src={logo} style={{width:100}} /><br /><br />
                <GoogleLogout
                    clientId={googleClientID}
                    buttonText="Logout"
                    onLogoutSuccess={() => {
                        setTimeout(() => {
                            window.location.href = '/';
                        }, 5000)
                    }}
                >
                </GoogleLogout><br /><br />
                {logoutWindow}
                <br /><br />
                <a href="#" style={{color:'white'}} onClick={(e) => {
                    signOut();
                    setTimeout(() => {
                        window.location.href = '/';
                    }, 5000)
                }}>Click here to log in...</a>
            </div>
        );
    }
}

export default UserLogout;