import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.jsx";
import { cloneDeep } from "lodash";

const CustomDialogWithInput = (props) => {
    const {
        open,
        close,
        field,
        formData,
        setFormData,
        fieldModels,
        updateDataObj,
        element: Element,
    } = props;

    const renderElement = () => {
        if (!field) return null;
        return (
            <Element
                updateDataObj={updateDataObj}
                data={formData}
                setData={setFormData}
                field={field}
                fieldModels={fieldModels}
            />
        );
    };

    return (
        <div>
            <Dialog
                open={open}
                keepMounted
                onClose={close}
                maxWidth="md"
                fullWidth={true}
            >
                <div
                    style={{ margin: 20, height: "60vh" }}
                    className="date-overflow"
                >
                    <h4 className="text-center">
                        {fieldModels[field] ? fieldModels[field].label : ""}
                    </h4>
                    {renderElement(field)}
                    <br />
                    <div className="text-center">
                        <Button color="rose" onClick={close}>
                            OK
                        </Button>
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

export default CustomDialogWithInput;
