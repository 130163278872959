import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { Page, Text, View, Document, StyleSheet } from 'react-pdf';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const TRPDFPreview = (props) => {
    const { pdfData, open, close } = props;
    const downloadPDF = () => {
        const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdfData.data).buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `tr-${new Date().getTime()}.pdf`);
        document.body.appendChild(link);
        link.click();
    }
    return (
        <div>
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div style={{ margin: 20 }} className="text-center">
                    {pdfData && <div>
                        <Button color="white" onClick={() => { close() }}>CLOSE</Button>
                    </div>}
                    <PdfViewer pdfData={pdfData} />
                    <br />

                    <div className="text-center bottom-dweller">
                        <Button color="success" 
                            style={{marginRight:20}}
                            onClick={() => { 
                                downloadPDF();
                            }}
                        >DOWNLOAD PDF</Button>
                        <Button color="white" 
                            onClick={() => { 
                                close() 
                            }}
                        >CLOSE</Button>
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

const PdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        
        setNumPages(numPages);
    };
    // const loadPages = () => Array(numPages).fill(0).map((_, i) => <Page key={i} pageNumber={i + 1} width={1000}  />)
    
    return (
        <Document
            file={props.pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            <Page pageNumber={pageNumber} width={1000} />
            <div className="text-center">Page {pageNumber} of {numPages}</div>
            <hr />
            <Button
                onClick={() => {
                    setPageNumber(pageNumber - 1)
                }}
                disabled={pageNumber === 1 ? true : false}
                className="mr-20"
            ><i className="fas fa-arrow-left"></i></Button>
            <Button
                onClick={() => {
                    setPageNumber(pageNumber + 1)
                }}
                disabled={pageNumber === numPages ? true : false}
            ><i className="fas fa-arrow-right"></i></Button>
        </Document>
    );
}

export default TRPDFPreview;