import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from 'store/actions';
import { getAppointments } from "../../../store/actions/crm_appointments";
import GridItem from "../../../components/Grid/GridItem.jsx";
import ContactsAppointmentsTable from "./ContactsAppointmentsTable";

const ContactsAppointments = ({
	contactsData,
	getContacts,
	upcomingState,
	setUpcomingState,
	getAppointments,
	contactId,
	contactName,
	createModal,
	setCreateModal,
	edit,
	crm_appointments: { appointmentsData, loading, success },
	canEdit,
	getContact
}) => {
	const [delState, setDelState] = useState(false);
	const [updateState, setUpdateState] = useState(false);
	const [createState, setCreateState] = useState(false);
	const currentDate = new Date().getDate();
	const pastYear = new Date().setDate(currentDate - 365);
	const timeParams = {
		timeMin: upcomingState
			? new Date().toISOString()
			: new Date(pastYear).toISOString(),
		timeMax: upcomingState ? null : new Date().toISOString(),
	};

	const appointmentsDataArr = appointmentsData ? appointmentsData : [];

	useEffect(() => {
		getAppointments(timeParams);
	}, []);

	useEffect(() => {
		if (success) {
			getAppointments(timeParams);
		}
	}, [success]);

	useEffect(() => {
		if (delState || updateState || createState) {
			setTimeout(() => {
				getAppointments(timeParams);
				setDelState(false);
				setUpdateState(false);
				setCreateState(false);
			}, 700);
		}
	}, [delState, updateState, createState]);
	return (
		<div>
			{loading ? (
				<h3>Loading...</h3>
			) : (
				<GridItem>
					<ContactsAppointmentsTable
						upcomingState={upcomingState}
						setUpcomingState={setUpcomingState}
						getAppointments={getAppointments}
						contactsData={contactsData}
						getContacts={getContacts}
						contactId={contactId}
						createModal={createModal}
						setCreateModal={setCreateModal}
						contactName={contactName}
						setCreateState={setCreateState}
						setUpdateState={setUpdateState}
						setDelState={setDelState}
						edit={edit}
						tableData={appointmentsDataArr}
						tableHeaderColor="primary"
						tableHead={[
							<strong>Title</strong>,
							<strong>Description</strong>,
							<strong>Start</strong>,
							<strong>End</strong>,
							<div></div>,
						]}
						canEdit={canEdit}
						getContact={getContact}
					/>
					{appointmentsDataArr.length === 0 && (
						<p className="text-center" style={{ marginTop: 20 }}>
							<em>No appointments</em>
						</p>
					)}
				</GridItem>
			)}
		</div>
	);
};

ContactsAppointments.propTypes = {
	getAppointments: PropTypes.func.isRequired,
	crm_appointments: PropTypes.object.isRequired,
	contactId: PropTypes.number,
	contactName: PropTypes.string,
	edit: PropTypes.bool,
};

const mapStateToProps = (state) => ({
	crm_appointments: state.crm_appointments,
});

const mapDispatchToProps = (dispatch) => {
	return {
		getAppointments: (data) => {
			dispatch(actions.getAppointments(data));
		},
		getContact: (data, emails) => {
			dispatch(actions.getContact(data, emails));
		}
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(
	ContactsAppointments
);
