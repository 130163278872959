import React from "react";
import { clone, isValidEmail } from "shared/utility";
import { textData } from "../ERTLObj";
import { commatize, formatDollar } from "../../../shared/utility";

export const statusDescriptions = {
    0: 'Draft', // First status
    1: 'Pending Owner Review', // After initial submission all Owners must submit
    2: 'Approved', // Everyone Approved status changes to 2
    3: '' //
}

const dat = (val) => {
    if (!val) return '';
    return val;
}

export const createOwnerName = (data) => {
    let name = '';
    if (data.company_id) {
        // COMPANY NAME AND DBA 
        name += `${data.company_name ? data.company_name : ''} ${data.company_dba ? `DBA ${data.company_dba}` : ''}`;
    } else {
        // CONTACT FIRST NAME AND LAST NAME AND DBA
        name += `${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''} ${data.contact_dba ? `DBA ${data.contact_dba}` : ''}`;
    }
    return name;
}

export const checkERTLemails = (data) => {
    let validEmails = true;
    let emails = [];
    const property_owners = data.property_owners;
    if (property_owners) {
        property_owners.forEach(a => {
            if (a.email) {
                if (emails.indexOf(a.email) === -1 && data.final_approver_email !== a.email) {
                    emails.push(a.email);
                }
            } else {
                validEmails = false;
            }
        })
    }
    if (data.final_approver_email) emails.push(data.final_approver_email);
    // Check if complete 
    if (data.property_owners) {
        if (data.property_owners.length + 1 !== emails.length) {
            validEmails = false;
        }
    }
    return validEmails;
}

export const generateHTML = (ertlFormData, textInputs, current_id) => {
    // obj template
    const tabs = {
        signHereTabs: [],
        initialHereTabs: [],
        dateSignedTabs: [],
    };

    // multiple owners, 1 broker, each has the tab object
    const broker_name = ertlFormData.final_approver;
    const masterObj = {
        [ertlFormData.final_approver_email]: clone(tabs)
    };
    const owner_name_dictionary = {
        [ertlFormData.final_approver]: ertlFormData.final_approver_email
    };
    const owner_names = ertlFormData.property_owners
        ? ertlFormData.property_owners.map(po => {
            const the_name = `${po.first_name ? po.first_name : ''} ${po.last_name ? po.last_name : ''}`;
            owner_name_dictionary[the_name] = po.email;
            return the_name;
        })
        : [];

    const property_owner_emails = ertlFormData.property_owners.map((po,i) => {
        masterObj[po.email] = clone(tabs);
        return po.email;
    });

    const owner_titles = ertlFormData.property_owners
        ? ertlFormData.property_owners.map((e) => e.title)
        : [];

    console.log('owner_name_dictionary', owner_name_dictionary)

    const renderInitials = (count) => {
        let ownersHTML = "<div>OWNER INITIALS : ";
        property_owner_emails.forEach((n, i) => {
            const tab = `**${count}_${i + 1}_I**`;
            masterObj[n].initialHereTabs.push({ anchorString: tab });
            ownersHTML += `<span style="border-bottom:1px solid #ccc;width:60px;margin-right:20px">
                <span class="white-text">${tab}</span>
            </span>`;
        });
        ownersHTML += "</div>";

        let brokerHTML = "<div>BROKER INITIALS : ";
        const tab = `**${count}_i_1_I**`;
        masterObj[ertlFormData.final_approver_email].initialHereTabs.push({ anchorString: tab });
        brokerHTML += `<span style="border-bottom:1px solid #ccc;width:60px;margin-right:60px">
            <span class="white-text">${tab}</span>
        </span>`;
        brokerHTML += `</div>`;

        const html = `<div class="rrow">
            <div class="rcolumn">${ownersHTML}</div>
            <div class="rcolumn">${brokerHTML}</div>
        </div>`;
        return html;
    };

    const renderSignature = (count, target, addendum) => {
        let htmlContent = "<span>";
        const tab = `**${count}_s_1_I_${target}_${addendum}**`;
        console.log('moo', target, owner_name_dictionary[target], owner_name_dictionary, masterObj, masterObj[owner_name_dictionary[target]]);
        masterObj[owner_name_dictionary[target]].signHereTabs.push({ anchorString: tab });
        htmlContent += `<span style="border-bottom:1px solid #ccc;width:60px;margin-right:60px"><span class="white-text">${tab}</span></span>`;
        htmlContent += `</span>`;
        return htmlContent;
    };

    const renderSignature2 = (count, target, addendum) => {
        let htmlContent = "<span>";
        const tab = `**${count}_s_1_I_${target}_${addendum}**`;
        console.log('moo', target, owner_name_dictionary[target], owner_name_dictionary, masterObj, masterObj[owner_name_dictionary[target]]);
        masterObj[owner_name_dictionary[target]].signHereTabs.push({ anchorString: tab });
        htmlContent += `<span style="position:relative"><span style="position:absolute;bottom:-20px;left:0;border-bottom:1px solid #ccc;width:70px" class="white-text">${tab}</span>`;
        htmlContent += `</span></span>`;
        return htmlContent;
    };

    const renderSignedDate = (count, target, addendum) => {
        let htmlContent = "<span>";
        const tab = `**${count}_d_1_I_${addendum ? addendum : ""}**`;
        masterObj[owner_name_dictionary[target]].dateSignedTabs.push({ anchorString: tab });
        htmlContent += `<span style="border-bottom:1px solid #ccc;width:30px;margin-right:60px"><span class="white-text">${tab}</span></span>`;
        htmlContent += `</span>`;
        return htmlContent;
    };

    const formInput = (field, underline, isDollar, thousands) => {
        if (ertlFormData[field] && ertlFormData[field] !== undefined) {
            if (isDollar) {
                return `<strong>${formatDollar(ertlFormData[field])}</strong>`;
            }
            let val = ertlFormData[field];
            if (thousands) {
                val = commatize(val);
            }
            if (underline)
                return `<span style="border-bottom: 1px solid #484848"><strong>${val}</strong></span>`;
            return ertlFormData[field];
        }
        return "";
    };

    const renderID = () => {
        if (current_id) return `<div id="ertl-number">#${current_id}</div>`;
        return `<div id="ertl-number"></div>`;
    };

    const property_owner_fullnames = `<span>${ertlFormData.property_owners.map((po,i) => {
        return `${po.first_name} ${po.last_name}`
    }).join(', ')}</span>`;

    const property_owner_phones = `<span>${ertlFormData.property_owners.map((po,i) => {
        return po.phone;
    }).join(', ')}</span>`;

    const html = `
    <html>
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;1,400&display=swap" rel="stylesheet">
        <style type="text/css">
        .underlined2,
        .underline {
            border-bottom: 1px solid #484848;
        }
        .margin-top {
            margin-top: 50px;
        }
    
        .left-margin {
            margin-left: 50px;
        }
    
        body {
            margin-right: 50px;
            font-size: 13px;
            color: #484848;
        }
    
        .center {
            margin-left: auto;
            margin-right: auto;
        }
    
        .pageFooter-first {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1rem
        }
    
        .tab {
            margin-left: 10px;
        }
    
        .pb_before {
            page-break-before: always !important;
        }
    
        ol {
            counter-reset: list;
            padding-left: 1em;
        }
    
        table {
            border: solid #000;
            border-collapse: collapse;
            font-size: 10px;
        }
    
        table td + td {
            border-left: 2px solid #000;
            padding-left: 10px;
        }
        .page-break {
            page-break-after:always;
        }
        .text-center {
            text-align:center !important;
        }
        hr {
            border-bottom: 1px solid #ccc;
        }
        .white-text {
            color: white;
        }
        .pdfheader {
            width: 100%;
            margin: 0 auto;
        }
        .rcolumn {
            float: left;
            width: 50%;
          }
          
          /* Clear floats after the columns */
          .rrow:after {
            content: "";
            display: table;
            clear: both;
          }
          .the-table {
            padding: 20px; 
            border: 3px solid rgb(0, 0, 0);
          }
          table.the-table {
            table-layout: fixed;
          }
          .the-table th,
          .the-table td {
              padding: 15px 20px;
              width: 50%;
          }
          .border-left {
            border-left: 2px solid rgb(0, 0, 0);
          }
          .border-right {
            border-right: 2px solid rgb(0, 0, 0);
          }
          .very-small {
              font-size: 5px;
          }
          .small {
              font-size: 10px;
          }
          p {
              margin-bottom:5px;
              margin-top: 5px;
          }
          #ertl-number {
              text-align: right;
          }
          body {
                font-family: 'Open Sans', sans-serif;
                font-size: 10px;
                line-height: 1.5;
          }
          .times p,
          .times div {
                font-family: "Times New Roman", Times, serif !important;
          }
          .font-14 {
              font-size: 14px;
          }
          .font-10 {
              font-size: 10px;
          }
          .small {
              font-size: 9px;
          }
          .row {
            display: flex;
          }
          .column-50 {
            flex: 50%;
          }
          .column-25 {
            flex: 25%;
          }
          .column-33 {
            flex: 33%;
          }
        </style>
    </head>
    
    <body>
        <div class="">
            <div class="times font-14">
                <div class="" id="pageHeader-first"> 
                    <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png' width="100" height="110" /> 
                </div>
                ${renderID()}
                <br />
                <div>${formInput("p1_date")}</div>
                <br />
                <br />
                <div class="row">
                    ${ertlFormData.property_owners.map((po,i) => {
                        if (po.company_id) {
                            // COMPANY
                            return `<div class="column-25">
                                <div>${createOwnerName(po)}</div>
                                <div>c/o ${po.first_name} ${po.last_name}</div>
                                <div>${po.street1} ${po.street2}</div>
                                <div>${po.city},${po.state} ${po.zip}</div>
                            </div>`
                        } else {
                            // CONTACT
                            return `<div class="column-25">
                                <br />
                                <div>${createOwnerName(po)}</div>
                                <div>${po.street1} ${po.street2}</div>
                                <div>${po.city},${po.state} ${po.zip}</div>
                            </div>`
                        }
                    }).join('')}
                </div>

                <br />
                <br />
                <div><strong>${textInputs.t1}${formInput("property_address1")} ${formInput("property_address2")}, ${formInput("property_city")} ${formInput("property_zipcode")} -- ${textInputs.t2}</strong></div>
                <br />
                <div>${textInputs.t3}<span>${ertlFormData.property_owners.map((po,i) => {
                    return `${po.first_name}`
                }).join(', ')}</span>,</div>
                <br />
                <p>${textInputs.t4}</p>
                <p>${textInputs.t5}</p>
                <p>${textInputs.t6}</p>
                <p>${textInputs.t7}<span>${ertlFormData.agents ? ertlFormData.agents.map(a => {
                    return a.name;
                }).join(', ') : ''}</span>${
        textInputs.t8
    }${formInput("final_approver_phone")}${textInputs.t9}${formInput(
        "final_approver_email"
    )}</p>
                <br />
                <p>${textInputs.t10}</p>
                <p><b>${textInputs.t11}</b> ${textInputs.t12}</p>
                <br /><span class="white-text">${renderSignature(
                    0,
                    broker_name,
                    "C"
                )}</span>
                <p>${formInput("final_approver")}</p>
                <br />
                <p>${textInputs.t14}<span>${ertlFormData.agents ? ertlFormData.agents.map(a => {
                    return a.name;
                }).join(', ') : ''}</span></p>
                <br />
                <br />
                <br />
                <center class="font-10">
                    <p>${textInputs.t15}</p>
                    <p>${textInputs.t16}</p>
                    <p>${textInputs.t17}</p>
                </center>
            </div>
            <div style="page-break-after:always;"></div>

            <div class="text-center">
                <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png' width="100" height="110" style="margin-bottom:20px"/> 
            </div>
            <center>
                <b>${textInputs.t18}</b>
                <br /><br />
                <div>${
                    textInputs.t19
                }<span class="underline"><strong>${formInput(
        "property_address1"
    )} ${formInput("p2_property_address2")}, ${formInput(
        "property_city"
    )}, ${formInput("p2_property_state")} ${formInput(
        "property_zipcode"
    )}</strong></span></div>
            </center>
            <br />
            <p>${textInputs.t20} ${formInput("p2_date_as_of", true)} ${
        textInputs.t21
    }</p>
            <p>${textInputs.t22} ${formInput("made_by_and_between", true)} ${
        textInputs.t23
    }
            ${textInputs.t24} ${textInputs.t25}</p>
            <p>${textInputs.t26} ${formInput(
        "property_address1",
        true
    )} ${formInput("property_address2", true)} ${textInputs.t27}
             ${formInput("property_city", true)}, ${formInput(
        "property_state",
        true
    )} ${textInputs.t28}</p>
            <p>${textInputs.t29}</p>
            <p>${textInputs.t30}</p>
            <ol>
                <li>
                    <p><b class="underlined2">${textInputs.t31}</b>${
        textInputs.t32
    }</p>
                    <p>${textInputs.t33} ${formInput(
        "name_of_place",
        true
    )}</p>
                    <p>${textInputs.t34} ${formInput(
        "total_sqft_footage",
        true,
        null,
        true
    )} ${textInputs.t35}</p>
                    <p>${textInputs.t36} ${formInput(
        "total_current_vacant_space",
        true,
        null,
        true
    )} ${textInputs.t37}</p>
                    <p>${textInputs.t38} ${formInput(
        "minimum_suite_size_available_for_lease",
        true,
        null,
        true
    )} ${textInputs.t39}</p>
                    <p>${textInputs.t40} ${formInput(
        "approved_lease_terms",
        true,
        true,
        true
    )} ; ${formInput("approved_lease_terms_lease_type", true)} ${textInputs.t41}</p>
                </li>
                <br />
                <li>
                    <div><b class="underlined2">${textInputs.t42}</b>${
        textInputs.t43
    } ${formInput("initial_term_begin", true)} ${textInputs.t44}</div>
                    <div>${textInputs.t45}${formInput("initial_term_expiration", true)} ${
        textInputs.t46
    }</div>
                    <div>${textInputs.t47}${textInputs.t48}</div>
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t49}</b>${
        textInputs.t50
    }${textInputs.t51}${textInputs.t52}
                    ${textInputs.t53}${textInputs.t54}${textInputs.t55}${
        textInputs.t56
    }
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t57}</b> ${
        textInputs.t58
    }${textInputs.t59}${textInputs.t60}
                </li>
                <br />
                <br />
                ${renderInitials(1)}
                <div class="text-center small" style="margin-top:20px">Page 1</div>
                <div style="page-break-after:always;"></div>

                <li>
                    <b class="underlined2">${textInputs.t61}</b> ${
        textInputs.t62
    }${textInputs.t63}
                    ${textInputs.t64}${textInputs.t65}${textInputs.t66}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t67}</b> ${
        textInputs.t68
    }${textInputs.t69}${textInputs.t70}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t71}</b> ${
        textInputs.t72
    }${textInputs.t73}${textInputs.t74}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t75}</b> ${
        textInputs.t76
    }${textInputs.t77}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t78}</b> ${
        textInputs.t79
    }${textInputs.t80}${textInputs.t81}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t82}</b> ${
        textInputs.t83
    } ${textInputs.t84}${textInputs.t85}
                </li>
                <br />
                ${renderInitials(2)}
                <div class="text-center small" style="margin-top:10px">Page 2</div>
                <div style="page-break-after:always;"></div>


                <li>
                    <b class="underlined2">${textInputs.t86}</b> ${
        textInputs.t87
    }${textInputs.t88}${textInputs.t89}${textInputs.t90}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t91}</b> ${
        textInputs.t92
    }${textInputs.t93}${textInputs.t94}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t95}</b> ${
        textInputs.t96
    }
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t97}</b> ${
        textInputs.t98
    }${textInputs.t99}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t100}</b> ${
        textInputs.t101
    }
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t102}</b> ${
        textInputs.t103
    }${textInputs.t104}${textInputs.t105}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t106}</b> ${
        textInputs.t107
    }${textInputs.t108}
                </li>
                <br />
                <li>
                    <b class="underlined2">${textInputs.t109}</b> ${
        textInputs.t110
    }${textInputs.t114}${textInputs.t115}
                    ${textInputs.t116} ${textInputs.t117}${textInputs.t118}${
        textInputs.t119
    }
                    ${textInputs.t120}${textInputs.t121}${textInputs.t122}${
        textInputs.t123
    }${textInputs.t124}
                </li>
                <br />
                ${renderInitials(3)}
                <div class="text-center small" style="margin-top:20px">Page 3</div>
                <div style="page-break-after:always;"></div>

                <li>
                    <b class="underlined2">${textInputs.t125}</b> ${
        textInputs.t126
    }${textInputs.t127}
                    ${textInputs.t128}${textInputs.t129}${textInputs.t130}${
        textInputs.t131
    }${textInputs.t132}
                </li>
                <br />
            </ol>
            <p><b>${textInputs.t133}${textInputs.t134}${textInputs.t135}${
        textInputs.t136
    }</b></p>
            <p><b>${textInputs.t137}</b></p>

            <p>${textInputs.t138}${textInputs.t139}${textInputs.t140}</p>
            <p>${textInputs.t141}</p>

            <center>
                <table class="the-table">
                    <thead>
                        <tr>
                            <th>${textInputs.t156}</th>
                            <th class="border-left">${textInputs.t157}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div><b>${formInput(
                                    "made_by_and_between",
                                    true
                                )}, 
                                ${textInputs.t158}</b></div>
                            </td>
                            <td style="padding-left:20px">
                                <div>${textInputs.t159}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rrow">
                                    ${owner_names
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                <strong>By : </strong><br /><br /><span class="very-small">${renderSignature2(
                                                    i + 1,
                                                    on,
                                                    "A"
                                                )}</span>
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t160
                                }</strong> ${renderSignature(
        0,
        broker_name,
        "A"
    )} </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rrow">
                                    ${owner_names
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                ${
                                                    i === 0
                                                        ? "<strong>Name :</strong>"
                                                        : ""
                                                } ${on}
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t161
                                }</strong> ${formInput("p1_writer_name")}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>  
                                <div class="rrow">
                                    ${owner_titles
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                ${
                                                    i === 0
                                                        ? "<strong>Title :</strong>"
                                                        : ""
                                                } ${on ? on : ''}
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${textInputs.t162} :</strong> ${textInputs.t168}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <div class="rrow">
                                        ${owner_names
                                            .map((on, i) => {
                                                const propo = ertlFormData.property_owners[i];
                                                return `
                                                <div class="rcolumn">
                                                    ${renderSignedDate(
                                                        i + 1,
                                                        on,
                                                        "ownerA"
                                                    )}<br />
                                                    <div style="margin-top:10px"><strong>Signature Date:</strong></div>
                                                    <div style="margin-top:20px">
                                                        <strong>Address:</strong><br />${dat(propo.street1)} ${dat(propo.street2)} ${dat(propo.city)}, ${dat(propo.state)} ${dat(propo.zip)}<br> 
                                                        <strong>Tel:</strong><br />${dat(propo.phone)}<br>
                                                        <strong>Email:</strong><br />${dat(propo.email)}<br>
                                                    </div>
                                                </div>
                                            `;
                                            })
                                            .join("")}
                                    </div>
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t163
                                }</strong> ${renderSignedDate(
                                    1,
                                    broker_name,
                                    "brokerA"
                                )}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${textInputs.t164}</strong><br />${
                                textInputs.t169
                            } </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                               
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${textInputs.t165}</strong> ${
                                    textInputs.t171
                                }</div>
                                <div><strong>${textInputs.t166}</strong> ${
                                    textInputs.t170
                                }</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
            <br />
            <br />
            ${renderInitials(5)}
            <div class="text-center small" style="margin-top:20px">Page 4</div>
            <div class="page-break"></div>

            <div class="text-center">
                <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png' width="100" height="110" style="margin-bottom:20px"/> 
            </div>
            <center><strong><p>${textInputs.t142}</p></strong></center>
            <p>${textInputs.t143}${textInputs.t144}${textInputs.t145}</p>
            <p><b class="underlined2">${textInputs.t146}</b> ${
        textInputs.t147
    }${textInputs.t148}${textInputs.t149}</p>
            <p><b class="underlined2">${textInputs.t150}</b> ${
        textInputs.t151
    }${textInputs.t152}</p>
            <p><b class="underlined2">${textInputs.t153}</b> ${
        textInputs.t154
    }</p>
            <br />
            <br />
            <center>
                <table class="the-table">
                    <thead>
                        <tr>
                            <th>${textInputs.t156}</th>
                            <th class="border-left">${textInputs.t157}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <div><b>${formInput("made_by_and_between")}, 
                                    ${textInputs.t158}</b></div>
                            </td>
                            <td style="padding-left: 20px">
                                <div>${textInputs.t159}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rrow">
                                    ${owner_names
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                <strong>By : </strong><br /><br /><span class="very-small">${renderSignature2(
                                                    i + 1,
                                                    on,
                                                    "B"
                                                )}</span>
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t160
                                }</strong> ${renderSignature(
                                    0,
                                    broker_name,
                                    "B"
                                )} </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div class="rrow">
                                    ${owner_names
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                ${
                                                    i === 0
                                                        ? "<strong>Name :</strong>"
                                                        : ""
                                                } ${on}
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t161
                                }</strong> ${formInput("final_approver")}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>  
                                <div class="rrow">
                                    ${owner_titles
                                        .map((on, i) => {
                                            return `
                                            <div class="rcolumn">
                                                ${
                                                    i === 0
                                                        ? "<strong>Title :</strong>"
                                                        : ""
                                                } ${on ? on : ''}
                                            </div>
                                        `;
                                        })
                                        .join("")}
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${textInputs.t162} :</strong> ${
        textInputs.t168
    }</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>
                                    <div class="rrow">
                                        ${owner_names
                                            .map((on, i) => {
                                                return `
                                                <div class="rcolumn">
                                                    ${renderSignedDate(
                                                        i + 1,
                                                        on,
                                                        "ownerB"
                                                    )}<br />
                                                    <div style="margin-top:10px"><strong>Signature Date:</strong></div>
                                                </div>
                                            `;
                                            })
                                            .join("")}
                                    </div>
                                </div>
                            </td>
                            <td style="padding-left:20px">
                                <div><strong>${
                                    textInputs.t163
                                }</strong> ${renderSignedDate(
                                    1,
                                    broker_name,
                                    "brokerB"
                                )}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
            <br />
            ${renderInitials(6)}
            <div class="text-center small" style="margin-top:20px">Page 5</div>
            
        </div>
    </body>
    </html>
    `;

    return { html, tabs: masterObj };
};

const sections = [
    { name: "Property Data" },
    { name: "Property Owner Contact Data" },
    { name: "Agent Contact Data" },
    { name: "Other Data" },
];

export const checkSectionCompleteness = (d, section) => {
    let complete = true;
    let errors = [];
    if (section === "Property & Owner Data") {
        [

            'property_address1',
            'property_city',
            'property_state',
            'property_zipcode',

        ].forEach((f) => {
            if (!d[f]) {
                complete = false;
            }
        });
        if (!complete) errors.push('Missing required fields');

        if (d.property_owners) {
            if (d.property_owners.length === 0) {
                complete = false;
                errors.push('Property owners are required');
            } else {
                const emails = d.property_owners.map(po => po.email);
                emails.forEach((e) => {
                    if (!isValidEmail(e)) {
                        complete = false;
                        errors.push(`${e} is not a valid email`);
                    }
                });
                const titles = d.property_owners.map(po => po.title);
                titles.forEach((e) => {
                    if (!e) {
                        complete = false;
                        if (errors.indexOf('Titles are required') === -1) {
                            errors.push('Titles are required');
                        }
                    }
                });
            }
        }

    } else if (section === "Agent Contact Data") {
        if (d.agents) {
            if (d.agents.length === 0) {
                complete = false;
                errors.push('Agents are required');
            }
        }
    } else if (section === "Other Data") {
        [
            'made_by_and_between',
            'name_of_place',
            'total_current_vacant_space',
            'total_sqft_footage',
            'minimum_suite_size_available_for_lease',
            'initial_term_begin',
            'initial_term_expiration',
            'approved_lease_terms',
            'approved_lease_terms_lease_type',

            'final_approver',
            'final_approver_email',
            'final_approver_phone',
            'final_approver_title'
        ].forEach((f) => {
            if (!d[f]) {
                complete = false;
                console.log('err', f);
                if (errors.indexOf('Missing values in Other Data tab') === -1) {
                    errors.push('Missing values in Other Data tab');
                }
            }
        });
    }
    console.log('section completeness', section, complete, errors);
    return { complete, errors };
};

export const getHistoryDiffs = (arr2) => {
    // sort ascending
    const arr = arr2.concat([]).sort((a, b) => (a.id > b.id ? 1 : -1));
    const points = [];
    arr.forEach((a, i) => {
        const templateText = a.json_data ? a.json_data.templateData : {};
        const previousText =
            i !== 0
                ? arr[i - 1].json_data
                    ? arr[i - 1].json_data.templateData
                    : null
                : null;
        const obj = {
            created_date: a.created_date,
            id: a.id,
            templateText,
            author: a.action === 3 || a.action === 4 ? "Approver" : "Submitter",
            templateDataChanges: {},
        };
        // get diffs from original template
        Object.keys(templateText).forEach((field) => {
            let previousTextData = clone(textData);
            if (i !== 0) {
                previousTextData = previousText;
            }
            if (templateText[field] !== previousTextData[field]) {
                obj.templateDataChanges[field] = templateText[field];
            }
        });
        points.push(obj);
    });
    return points;
};

export const removeSplitter = (word) => {
    return word.split("_|_")[0];
};

export const getDifferences = (original, newstring, shouldShowIt, author) => {
    if (newstring === undefined) return null;
    if (original === newstring) return newstring;
    // make sure duplicate words are tracked accurately
    let instances1 = {};
    let instances2 = {};
    console.log('getting difference => 1', original);
    console.log('getting difference => 2', newstring);
    const o_arr = original
        ? original
              .split(" ")
              .filter((r) => r !== "")
              .map((r, i) => {
                  if (!instances1.hasOwnProperty(r)) {
                      instances1[r] = [1];
                      return `${r}_|_${0}`;
                  } else {
                      instances1[r].push(1);
                      return `${r}_|_${instances1[r].length - 1}`;
                  }
              })
        : [];
    const n_arr = newstring
        ? newstring
              .split(" ")
              .filter((r) => r !== "")
              .map((r, i) => {
                  if (!instances2.hasOwnProperty(r)) {
                      instances2[r] = [1];
                      return `${r}_|_${0}`;
                  } else {
                      instances2[r].push(1);
                      return `${r}_|_${instances2[r].length - 1}`;
                  }
              })
        : [];
    let newarr = [];
    let erased = [];
    n_arr.forEach((n) => {
        if (o_arr.indexOf(n) === -1) {
            newarr.push(n);
        }
    });
    o_arr.forEach((o) => {
        if (n_arr.indexOf(o) === -1) {
            erased.push(o);
        }
    });
    return (
    <>
        <div style={{display: 'inline-block'}}>
            {erased.length > 0 && shouldShowIt && (
                <div>
                    <br />
                    <span
                        style={{
                            display: "inline-block",
                            padding: 5,
                            backgroundColor: "#ffecec",
                            marginTop: 5,
                        }}
                    >
                        {o_arr.map((nn, i) => {
                            const addedStyles = { color: "black" };
                            if (
                                erased.indexOf(nn) !== -1 &&
                                erased.length > 0 &&
                                shouldShowIt
                            ) {
                                addedStyles.color = "red";
                                addedStyles.fontWeight = "bold";
                            }
                            return (
                                <span key={`${nn}-n-${i}`} style={addedStyles}>
                                    {removeSplitter(nn)}{" "}
                                </span>
                            );
                        })}
                    </span>
                </div>
            )}
            {n_arr.map((nn, i) => {
                const addedStyles = { color: "black" };
                if (newarr.indexOf(nn) !== -1 && shouldShowIt) {
                    addedStyles.color = author
                        ? author === "Approver"
                            ? "#147ae3"
                            : "red"
                        : "red";
                    addedStyles.fontWeight = "bold";
                    addedStyles.fontSize = 19;
                    addedStyles.textDecoration = "underline";
                }
                return (
                    <span key={`${nn}-s-${i}`} style={addedStyles}>
                        {removeSplitter(nn)}{" "}
                    </span>
                );
            })}
        </div>
    </>
    );
};

export const checkERTLrequirements = (ertlFormData) => {
    let errors = [];
    // check for required fields
    let isComplete = true;
    [
        { name: "Property & Owner Data" },
        { name: "Agent Contact Data" },
        { name: "Other Data" },
    ].forEach((section) => {
        const passObj = checkSectionCompleteness(
            ertlFormData,
            section.name
        );
        const errorsArr = passObj.errors;
        const { complete } = passObj;
        if (errorsArr.length > 0) {
            errors = errors.concat(errorsArr);
        }
        if (!complete) isComplete = false;
    });
    if (!isComplete) {
        errors.push("Please fill up all required fields");
    }
    // check for required emails, names
    const names = ertlFormData.property_owners ? ertlFormData.property_owners.map(a => {
        return `${a.first_name ? a.first_name : ''} ${a.last_name ? a.last_name : ''}`;
    }) : [];
    const emails = ertlFormData.property_owners ? ertlFormData.property_owners.map(a => {
        return a.email;
    }) : [];
    const titles = ertlFormData.property_owners ? ertlFormData.property_owners.map(a => {
        return a.title;
    }) : [];
    if (emails.length === 0)
        errors.push(
            "Valid Owner Emails are required"
        );
    emails.forEach((e) => {
        if (!isValidEmail(e)) {
            errors.push(
                `${e} is not a valid email`
            );
        }
    });
    if (emails.length !== names.length) {
        errors.push(
            "All owner emails are required"
        );
    }
    if (titles.length !== names.length) {
        errors.push("Titles are required");
    }
    const validEmails = checkERTLemails(ertlFormData);
    if (!validEmails) errors.push('Please check if there are duplicate emails');

    return errors;
}