import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.jsx";
import { formatDate2 } from "shared/utility";
import { DialogContent, Tooltip } from "@material-ui/core";
import ReactTable from "react-table";
import Loader from "components/Loader/Loader";
import { getStatusType } from "../FormWizard/ERTLModel";
import download from "downloadjs";
import ERTLFormWizard from "../FormWizard/ERTLFormWizard";
import { Close, Edit } from "@material-ui/icons";
import { getHistoryDiffs } from "containers/ERTL/FormWizard/ERTLLogic";

const ERTLPendingToReview = (props) => {
    const [view, setView] = useState(false);
    const [historyDiffData, setHistoryDiffData] = useState(null);
    const [downloadPDF, setDownloadPDF] = useState(false);
    const [showView, setShowView] = useState(false);
    const [ertlData, setErtlData] = useState(null);
    const [templateData, setTemplateData] = useState(null);
    const [origin, setOrigin] = useState(null);
    const [historyDiff, setHistoryDiff] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);

    const openModal = () => setOpenedModal(true);
    const closeModal = () => setOpenedModal(false);

    const reload = () => {
        props.getEntriesByStatus({
            status: 1,
        });
    }

    useEffect(() => {
        reload();
    }, []);

    useEffect(() => {
        if (downloadPDF) {
            download(props.pdfHistoryData, `ertl_.pdf`, "application/pdf");
            setDownloadPDF(false);
        }
    }, [props.pdfHistoryData]);

    useEffect(() => {
        if (view) {
            // get most recent historyData
            if (props.historyData.length > 0) {
                const h = props.historyData[0];
                setDownloadPDF(true);
                props.getErtlPdf(h.id);
            }
            setView(false);
        } else if (historyDiff) {
            if (props.historyData.length > 0) {
                const diffs = getHistoryDiffs(props.historyData);

                setHistoryDiffData(diffs);
            }
            setHistoryDiff(false);
        }
    }, [props.historyData]);

    const data = props.statusEntries
        ? props.statusEntries.map((l, key) => {
              // map users to table rows
              const d = l.data ? l.data : {};

              let allComplete = false;
              if (d.owners_review) {
                if (d.owners_review.length > 0) {
                    allComplete = true;
                    d.owners_review.forEach(or => {
                        if (!or.reviewed) {
                            allComplete = false;
                        }
                    });
                }
              }

              return {
                  id: l.id,
                  created_date: formatDate2(l.created_date),
                  modified_date: formatDate2(l.modified_date),
                  owners: (
                      <div>
                          {d.property_owners ? d.property_owners.map((a,i) => {
                            return <div key={`po-${i}`}>
                                {a.first_name} {a.last_name}<br />
                                {a.email}
                            </div>
                          }) : null}
                      </div>
                  ),
                  name: l.name,
                  status: allComplete ? 'All Owners Submitted' : getStatusType(l.status),
                  approver: (
                      <div>
                          {d.final_approver ? d.final_approver : null}
                          <br />
                          {d.final_approver_email ? d.final_approver_email : null}
                      </div>
                  ),
                  data: l,
                  actions: (
                      <div>

                          {!allComplete && (
                              <Tooltip
                                  title="Resend Review Link"
                                  placement="top"
                              >
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          props.sendErtlReviewLink(l.id);
                                          openModal();
                                      }}
                                      color="primary"
                                  >
                                      <i className="fa fa-paper-plane"></i>
                                  </Button>
                              </Tooltip>
                          )}

                          {allComplete && (
                              <Tooltip title="Edit" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          props.setERTLData(l);
                                          if (l.template_id)
                                              props.getTemplate(l.template_id);
                                      }}
                                      color="primary"
                                  >
                                      <Edit />
                                  </Button>
                              </Tooltip>
                          )}

                          <Tooltip title="View" placement="top">
                              <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() => {
                                      props.getErtlHistory("id", l.id);
                                      setHistoryDiff(true);
                                      setHistoryDiffData(null);
                                      setErtlData(l);
                                      setTemplateData(d.templateData);
                                      setShowView(true);
                                      setOrigin("view");
                                  }}
                                  color="primary"
                              >
                                  <i className="fas fa-eye font-22"></i>
                              </Button>
                          </Tooltip>

                          <Tooltip title="Download PDF" placement="top">
                              <Button
                                  justIcon
                                  round
                                  simple
                                  onClick={() => {
                                      props.getErtlHistory("id", l.id);
                                      setView(true);
                                  }}
                                  color="primary"
                              >
                                  <i className="fas fa-arrow-down font-22"></i>
                              </Button>
                          </Tooltip>
                      </div>
                  ),
              };
          })
        : [];

    const columns = [
        {
            Header: "ERTL #",
            accessor: "id",
        },
        {
            Header: "Date Started",
            accessor: "created_date",
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Submitter",
            accessor: "name",
        },
        {
            Header: "Owners",
            accessor: "owners",
        },
        {
            Header: "Approver",
            accessor: "approver",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ];

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 style={{ display: "inline-block", marginRight: 10 }}>
                    Pending Owners Review
                </h3>{" "}
                <i
                    className="fas fa-sync-alt hoverable"
                    onClick={() => {
                        reload();
                    }}
                ></i>
                <div className="table-adjust23">
                    <ReactTable
                        filterable
                        className="-striped"
                        showPaginationTop
                        showPaginationBottom
                        resizable={false}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 20, 30]}
                        data={data}
                        columns={columns}
                    />
                </div>
            </GridItem>

            {openedModal && props.entry_success && (
                <Dialog
                    open={openedModal}
                    close={closeModal}
                    keepMounted
                    maxWidth="sm"
                    fullWidth={true}
                >
                    <div style={{ textAlign: "center", padding: 20 }}>
                        {props.entry_success}
                        <div>
                            <Button onClick={closeModal}>CLOSE</Button>
                        </div>
                    </div>
                </Dialog>
            )}

            {showView && (
                <Dialog
                    open={showView}
                    onClose={() => {
                        setShowView(false);
                    }}
                    keepMounted
                    maxWidth={false}
                    fullWidth={true}
                    fullScreen={true}
                >
                    <div className="dialog-wrapper">
                        <div
                            className="close-btn"
                            onClick={() => {
                                setShowView(false);
                            }}
                        >
                            <Close />
                        </div>
                        <ERTLFormWizard
                            getTemplates={props.getTemplates}
                            templatesData={props.templatesData}
                            templateData={{ data: templateData }}
                            templatesCount={props.templatesCount}
                            createTemplate={props.createTemplate}
                            getEntries={props.getEntries}
                            createEntry={props.createEntry}
                            updateEntry={props.updateEntry}
                            entriesData={props.entriesData}
                            getEntryPdf={props.getEntryPdf}
                            pdfData={props.pdfData}
                            setParentTab={() => {
                                setShowView(false);
                            }}
                            reload={() => {
                                props.getEntries("pending");
                            }}
                            ertlData={ertlData}
                            origin={origin}
                            historyDiffData={historyDiffData}
                        />
                    </div>
                </Dialog>
            )}
        </GridContainer>
    );
};

const mapStateToProps = (state) => {
    return {
        entriesData: state.ertl_entries.entriesData,
        ertlURL: state.ertl_entries.ertlURL,
        historyData: state.ertl_entries.historyData,
        pdfHistoryData: state.ertl_entries.pdfHistoryData,

        entry_error: state.ertl_entries.error,
        entry_success: state.ertl_entries.success,
        entry_loading: state.ertl_entries.loadingCreate,
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,
        templatesData: state.ertl_templates.templatesData,
        templateData: state.ertl_templates.templateData,
        templatesCount: state.ertl_templates.templatesCount,
        pdfData: state.ertl_entries.pdfData,
        statusEntries: state.ertl_entries.statusEntries,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEntries: (data, own) => {
            dispatch(actions.getEntries(data, own));
        },
        getErtlHistory: (data, entry_id) => {
            dispatch(actions.getErtlHistory(data, entry_id));
        },
        getErtlPdf: (data) => {
            dispatch(actions.getErtlPdf(data));
        },

        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getErtlUrl: (id) => {
            dispatch(actions.getErtlUrl(id));
        },
        getTemplates: (data) => {
            dispatch(actions.getTemplates(data));
        },
        getTemplate: (id) => {
            dispatch(actions.getTemplate(id));
        },
        createTemplate: (data) => {
            dispatch(actions.createTemplate(data));
        },
        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getEntryPdf: async (data) => {
            dispatch(actions.getEntryPdf(data));
        },
        createEntry: (data, newTemplate) => {
            dispatch(actions.createEntry(data, newTemplate));
        },
        updateEntryStatus: (data) => {
            dispatch(actions.updateEntryStatus(data));
        },
        updateEntry: (data, newTemplate) => {
            dispatch(actions.updateEntryStatus(data, newTemplate));
        },
        deleteTemplate: (id) => {
            dispatch(actions.deleteTemplate(id));
        },
        deleteErtlEntry: (id) => {
            dispatch(actions.deleteErtlEntry(id));
        },
        sendErtlReviewLink: (id) => {
            dispatch(actions.sendErtlReviewLink(id));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ERTLPendingToReview);
