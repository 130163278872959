import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
  userList: null,
  error: null,
  loading: false,
  syncSuccess: null,
  popupMessage: null,
  memberMessage: null,
  usernameCheck: null,
  groupList: [],
  memberList: [],
  user_error: null,
  user_success: null
}

const getWorkspaceUsersStart = (state, action) => {
  return updateObject(state, {
    userList: null,
    error: null,
    loading: true
  });
}

const getWorkspaceUsersSuccess = (state, action) => {
  return updateObject(state, {
    userList: action.userList,
    loading: false
  });
}

const getWorkspaceUsersFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.err
  });
}

const syncUsersStart = (state, action) => {
    return updateObject(state, {
        syncSuccess: null
    });
}

const syncUsersSuccess = (state, action) => {
    return updateObject(state, {
        syncSuccess: 'Successfully synced'
    });
}

const syncUsersFail = (state, action) => {
    return updateObject(state, {
        syncSuccess: 'Something went wrong, please check again'
    });
}

// CREATE WORKSPACE USER

const createWorkspaceUserStart = (state, action) => {
  return updateObject(state, {
    popupMessage: null
  });
}

const createWorkspaceUserSuccess = (state, action) => {
  
  const updateObj = {
    popupMessage: action.response.msg === 'OK' ? 'Successfully Created New User' : ''
  };
  if (action.response.msg) {
    if (action.response.user) {
      updateObj.userList =  state.userList.concat([action.response.user]);
    }
  }
  
  return updateObject(state, updateObj);
}

const createWorkspaceUserFail = (state, action) => {
  return updateObject(state, {
    popupMessage: action.err
  });
}

// DELETE WORKSPACE USER

const deleteWorkspaceUserStart = (state, action) => {
  return updateObject(state, {
    popupMessage: null
  });
}

const deleteWorkspaceUserSuccess = (state, action) => {
  const r = action.response;
  if (r.msg === 'OK') {
    return updateObject(state, {
      userList: state.userList ? state.userList.filter(sul => {
        if (r.user_id) {
          if (r.user_id === sul.id) return false;
        }
        return true;
      })
        : null,
      popupMessage: 'Successfully deleted user.'
    });
  }
  return updateObject(state, {
    popupMessage: 'Failed to delete user, please try again.'
  })
}

const deleteWorkspaceUserFail = (state, action) => {
  return updateObject(state, {
    popupMessage: 'Failed to delete user, please contact tech support if the issue persists.'
  });
}

// UPDATE WORKSPACE USER

const updateWorkspaceUserStart = (state, action) => {
  return updateObject(state, {
    popupMessage: null
  });
}

const updateWorkspaceUserSuccess = (state, action) => {
  const user = action.response.user;
  const userObj = {
    popupMessage: 'Successfully updated user.'
  };
  if (user) {
    userObj.userList = state.userList.map(ul => {
      if (ul.id === user.id) {
        return user;
      }
      return ul;
    });
  }
  return updateObject(state, userObj);
}

const updateWorkspaceUserFail = (state, action) => {
  return updateObject(state, {
    popupMessage: 'Unable to update user. If the issue persists contact tech support.'
  });
}

const checkUsernameStart = (state, action) => {
  return updateObject(state, {
    usernameCheck: null
  });
}

const checkUsernameSuccess = (state, action) => {
  return updateObject(state, {
    usernameCheck: action.response.msg
  });
}

const checkUsernameFail = (state, action) => {
  return updateObject(state, {
    usernameCheck: '0'
  });
}

const clearUsername = (state, action) => {
  return updateObject(state, {
    usernameCheck: null
  });
}

// GET WORKSPACE GROUPS

const getWorkspaceGroupsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    popupMessage: null,
    groupList: []
  });
}

const getWorkspaceGroupsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    groupList: action.groupList
  });
}

const getWorkspaceGroupsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    popupMessage: 'Error loading groups.',
    groupList: []
  });
}

// CREATE WORKSPACE GROUP 

const createWorkspaceGroupStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    popupMessage: null
  });
}

const createWorkspaceGroupSuccess = (state, action) => {
  if (action.response) {
    const newGroup = { ...action.response };
    newGroup.directMembersCount = '0';
    return updateObject(state, {
      loading: false,
      popupMessage: 'Successfully created group',
      groupList: state.groupList.concat([newGroup])
    });
  } else {
    return updateObject(state, {
      loading: false,
      popupMessage: 'Successfully created group'
    });
  }
}

const createWorkspaceGroupFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    popupMessage: 'Group couldnt be created, please try again.'
  });
}

// DELETE WORKSPACE GROUP 

const deleteWorkspaceGroupStart = (state, action) => {
  return updateObject(state, {
    popupMessage: null,
  });
}

const deleteWorkspaceGroupSuccess = (state, action) => {
  return updateObject(state, {
    popupMessage: 'Successfully deleted Workspace Group',
    groupList: state.groupList.filter(g => g.id !== action.response)
  });
}

const deleteWorkspaceGroupFail = (state, action) => {
  return updateObject(state, {
    popupMessage: 'Something went wrong and the group was not deleted, please try again.',
  });
}

// UPDATE WORKSPACE GROUP 

const updateWorkspaceGroupStart = (state, action) => {
  return updateObject(state, {
    popupMessage: null,
  });
}

const updateWorkspaceGroupSuccess = (state, action) => {
  const updatedGroup = action.response;
  return updateObject(state, {
    popupMessage: 'Successfully updated Workspace Group',
    groupList: state.groupList.map(g => {
      if (g.id === updatedGroup.id) {
        return updatedGroup;
      } 
      return g;
    })
  });
}

const updateWorkspaceGroupFail = (state, action) => {
  return updateObject(state, {
    popupMessage: 'Something went wrong and the group was not updated, please try again.',
  });
}

// GET ALL MEMBERS OF A GROUP

const getWorkspaceGroupMembersStart = (state, action) => {
  return updateObject(state, {
    memberMessage: null,
    memberList: null,
  })
}

const getWorkspaceGroupMembersSuccess = (state, action) => {
  return updateObject(state, {
    memberList: action.memberList,
    memberMessage: null
  });
}

const getWorkspaceGroupMembersFail = (state, action) => {
  return updateObject(state, {
    memberMessage: 'Failed to get group members'
  });
} 

// DELETE WORKSPACE GROUP MEMBER

const deleteWorkspaceGroupMemberStart = (state, action) => {
  return updateObject(state, {
    memberMessage: null
  });
}

const deleteWorkspaceGroupMemberSuccess = (state, action) => {
  const newState = {
    memberMessage: null,
    memberList: state.memberList ? state.memberList.filter(m => {
      if (m.email === action.memberEmail) {
        return false;
      }
      return true;
    }) : []
  };
  if (action.groupKey) {
    newState.groupList = state.groupList ? state.groupList.map(sg => {
      if (sg.id === action.groupKey) {
        sg.directMembersCount = sg.directMembersCount ? parseInt(sg.directMembersCount) - 1 : 0;
      }
      return sg;
    }) : state.groupList;
  }
  return updateObject(state, newState);
}

const deleteWorkspaceGroupMemberFail = (state, action) => {
  return updateObject(state, {
    memberMessage: 'Failed to delete member'
  });
}

// CREATE WORKSPACE GROUP MEMBER 

const createWorkspaceGroupMemberStart = (state, action) => {
  return updateObject(state, {
    memberMessage: null
  });
}

const createWorkspaceGroupMemberSuccess = (state, action) => {
  const newState = {
    memberList: state.memberList ? state.memberList.concat([action.response]) : [action.response]
  };
  if (action.groupKey) {
    // modify group list
    newState.groupList = state.groupList ? state.groupList.map(sg => {
      if (sg.id === action.groupKey) {
        sg.directMembersCount = sg.directMembersCount ? parseInt(sg.directMembersCount) + 1 : 1;
        return sg;
      }
      return sg;
    }) : [];
  }
  return updateObject(state, newState);
}

const createWorkspaceGroupMemberFail = (state, action) => {
  return updateObject(state, {
    memberMessage: 'Failed to delete member'
  });
}

// CREATE_USER_FROM_WORKSPACE

const createUserFromWorkspaceStart = (state, action) => {
  return updateObject(state, {
      user_error: null,
      user_success: null
  })
}

const createUserFromWorkspaceSuccess = (state, action) => {
  return updateObject(state, {
      user_success: 'Success'
  })
}

const createUserFromWorkspaceFail = (state, action) => {
  return updateObject(state, {
      user_error: action.err
  })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_WORKSPACE_USERS_START:
          return getWorkspaceUsersStart(state, action);
        case actionTypes.GET_WORKSPACE_USERS_SUCCESS:
          return getWorkspaceUsersSuccess(state, action);
        case actionTypes.GET_WORKSPACE_USERS_FAIL:
          return getWorkspaceUsersFail(state, action);

        case actionTypes.SYNC_USERS_START:
            return syncUsersStart(state, action);
        case actionTypes.SYNC_USERS_SUCCESS:
            return syncUsersSuccess(state, action);
        case actionTypes.SYNC_USERS_FAIL:
            return syncUsersFail(state, action);

        case actionTypes.CREATE_WORKSPACE_USER_START:
            return createWorkspaceUserStart(state, action);
        case actionTypes.CREATE_WORKSPACE_USER_SUCCESS:
          return createWorkspaceUserSuccess(state, action);
        case actionTypes.CREATE_WORKSPACE_USER_FAIL:
          return createWorkspaceUserFail(state, action);

        case actionTypes.DELETE_WORKSPACE_USER_START:
          return deleteWorkspaceUserStart(state, action);
        case actionTypes.DELETE_WORKSPACE_USER_SUCCESS:
          return deleteWorkspaceUserSuccess(state, action);
        case actionTypes.DELETE_WORKSPACE_USER_FAIL:
          return deleteWorkspaceUserFail(state, action);

        case actionTypes.UPDATE_WORKSPACE_USER_START:
          return updateWorkspaceUserStart(state, action);
        case actionTypes.UPDATE_WORKSPACE_USER_SUCCESS:
          return updateWorkspaceUserSuccess(state, action);
        case actionTypes.UPDATE_WORKSPACE_USER_FAIL:
          return updateWorkspaceUserFail(state, action);

        case actionTypes.GET_WORKSPACE_GROUPS_START:
          return getWorkspaceGroupsStart(state, action);
        case actionTypes.GET_WORKSPACE_GROUPS_SUCCESS:
          return getWorkspaceGroupsSuccess(state, action);
        case actionTypes.GET_WORKSPACE_GROUPS_FAIL:
          return getWorkspaceGroupsFail(state, action);

        case actionTypes.CHECK_USERNAME_START:
          return checkUsernameStart(state, action);
        case actionTypes.CHECK_USERNAME_SUCCESS:
          return checkUsernameSuccess(state, action);
        case actionTypes.CHECK_USERNAME_FAIL:
          return checkUsernameFail(state, action);

        case actionTypes.CREATE_WORKSPACE_GROUP_START:
          return createWorkspaceGroupStart(state, action);
        case actionTypes.CREATE_WORKSPACE_GROUP_SUCCESS:
          return createWorkspaceGroupSuccess(state, action);
        case actionTypes.CREATE_WORKSPACE_GROUP_FAIL:
          return createWorkspaceGroupFail(state, action);

        case actionTypes.DELETE_WORKSPACE_GROUP_START:
          return deleteWorkspaceGroupStart(state, action);
        case actionTypes.DELETE_WORKSPACE_GROUP_SUCCESS:
          return deleteWorkspaceGroupSuccess(state, action);
        case actionTypes.DELETE_WORKSPACE_GROUP_FAIL:
          return deleteWorkspaceGroupFail(state, action);

        case actionTypes.UPDATE_WORKSPACE_GROUP_START:
          return updateWorkspaceGroupStart(state, action);
        case actionTypes.UPDATE_WORKSPACE_GROUP_SUCCESS:
          return updateWorkspaceGroupSuccess(state, action);
        case actionTypes.UPDATE_WORKSPACE_GROUP_FAIL:
          return updateWorkspaceGroupFail(state, action);

        case actionTypes.GET_WORKSPACE_GROUP_MEMBERS_START:
          return getWorkspaceGroupMembersStart(state, action);
        case actionTypes.GET_WORKSPACE_GROUP_MEMBERS_SUCCESS:
          return getWorkspaceGroupMembersSuccess(state, action);
        case actionTypes.GET_WORKSPACE_GROUP_MEMBERS_FAIL:
          return getWorkspaceGroupMembersFail(state, action);

        case actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_START:
          return deleteWorkspaceGroupMemberStart(state, action);
        case actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_SUCCESS:
          return deleteWorkspaceGroupMemberSuccess(state, action);
        case actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_FAIL:
          return deleteWorkspaceGroupMemberFail(state, action);

        case actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_START:
          return createWorkspaceGroupMemberStart(state, action);
        case actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_SUCCESS:
          return createWorkspaceGroupMemberSuccess(state, action);
        case actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_FAIL:
          return createWorkspaceGroupMemberFail(state, action);

        case actionTypes.CLEAR_USERNAME:
          return clearUsername(state, action);

        case actionTypes.CREATE_USER_FROM_WORKSPACE_START:
          return createUserFromWorkspaceStart(state, action);
        case actionTypes.CREATE_USER_FROM_WORKSPACE_SUCCESS:
            return createUserFromWorkspaceSuccess(state, action);
        case actionTypes.CREATE_USER_FROM_WORKSPACE_FAIL:
            return createUserFromWorkspaceFail(state, action);

        default: return state;
    }
};

export default reducer;
