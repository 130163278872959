import React, { useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";
import CustomDialogChooser from '../../../components/CustomDialog/CustomDialogChooser';
import NotificationAsk from '../../../components/Notification/NotificationAsk';

const ERTLTab3 = (props) => {
    const [deleteContact, setDeleteContact] = useState(null);
    const [addContact, setAddContact] = useState(false);
    const { ertlFormData, setErtlFormData } = props;

    const choose = (cd) => {
        console.log('the_contact_data chosen', cd);
        if (cd.first_name) {
            const newData = clone(ertlFormData);
            newData.agents = newData.agents.concat([
                {
                    contact_id: cd.id,
                    name: `${cd.first_name} ${cd.last_name}`,
                    email: cd.email
                }
            ])
            setErtlFormData(newData);
        }
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <Button
                    color="primary"
                    onClick={() => {
                        setAddContact(true);
                    }}
                >ADD AGENT</Button>
                <hr />
                <h3>Agent Contact Data</h3>
            </GridItem>
            {ertlFormData.agents ? ertlFormData.agents.map((ag,i) => {
                return <GridItem xs={4} sm={4} md={4} key={`${i}-agent`}>
                    <div className="boxed">
                        <div className="red-text" style={{textAlign:'right'}}>
                            <i className="hoverable fas fa-trash" 
                                onClick={() => {
                                    setDeleteContact(ag);
                                }}
                            ></i>
                        </div>
                        <strong>Name :</strong> {ag.name}<br />
                        <strong>Email :</strong> {ag.email}
                    </div>
                </GridItem>
            }) : null}
            <GridItem xs={4} sm={4} md={4}></GridItem>

            {addContact && <CustomDialogChooser 
                open={addContact}
                close={() => {
                    setAddContact(false);
                }}
                choose={choose}
                showContactCompaniesTab={true}
                create_and_choose={choose}
                title="Agents"
                label="Agents"
                no_property={true}
                showFilters={true}
                showContactTypes={true}
                forceContact={true}
            />}

            {deleteContact !== null && <NotificationAsk 
                open={deleteContact !== null ? true : false}
                close={() => {
                    setDeleteContact(null);
                }}
                title="Are you sure?"
                message="Do you want to remove this agent?"
                success={() => {
                    const newData = clone(ertlFormData);
                    newData.agents = newData.agents.filter(ag => {
                        if (ag.name !== deleteContact.name) return true;
                    })
                    setErtlFormData(newData);
                    setDeleteContact(null);
                }}
            />}
        </GridContainer>
    )
}

export default ERTLTab3;