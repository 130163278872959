import React, { useState, useEffect } from "react";

import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import { get, set } from "lodash";

import { clone } from "shared/utility";
import { Dialog, DialogTitle } from '@material-ui/core';


const DialPadCompanyMergeReview = (props) => {
    const {
        company,
        companyForm,
        goTo,
        setCompanyForm,
        updateCompany,
        contactToImport,
        contactToUpdate,
        setCompanyToUpdate,
        contactId,
        getContact,
        contactData,
        createCompanyContacts,
    } = props;

    const [openedAskModal, setOpenedAskModal] = useState(false);
    const [assigningCompleted, setAssigningCompleted] = useState(false)

    const [contactCompanyForm, setContactCompanyForm] = useState({
        contact_id: "",
        company_id: "",
        department: "",
        title: "",
        phone: "",
        email: "",
    });

    useEffect(() => {
        if (!companyForm.name || companyForm.name.toString().trim() === "") {
            const newCompanyForm = clone(companyForm);
            newCompanyForm.name = get(company, `name`, "");;
            setCompanyForm(newCompanyForm);
        }
    }, [])

    useEffect(() => {
        if (contactData) {
            const newContactCompanyForm = clone(contactCompanyForm);
            newContactCompanyForm.contact_id = contactData.id;
            newContactCompanyForm.email = contactData.email;
            newContactCompanyForm.phone = contactData.main_phone;
            setContactCompanyForm(newContactCompanyForm);
        }
    }, [contactData]);

    const openAskModal = () => {
        setOpenedAskModal(true);
        const newContactCompanyForm = clone(contactCompanyForm);
        newContactCompanyForm.company_id = company.id;
        setContactCompanyForm(newContactCompanyForm);
        getContact(contactId);
    };

    const closeAskModal = () => setOpenedAskModal(false);

    const handleMerge = () => {
        const newCompany = clone(company);
        Object.keys(companyForm).forEach((key) => {
            newCompany[key] = companyForm[key];
        });
        updateCompany(newCompany);

        setCompanyToUpdate({
            action: "MERGE",
            data: newCompany,
        });

        goTo(4);
    };

    const renderCompanyInput = (field) => {
        return (
            <input
                className="input-on-table"
                value={companyForm[field]}
                onChange={(e) => {
                    const { value } = e.target;
                    const newCompanyForm = clone(companyForm);
                    newCompanyForm[field] = value;
                    setCompanyForm(newCompanyForm);
                }}
            />
        );
    };

    const renderContactCompanyInput = (field) => {
        return (
            <input
                className="input-on-table"
                value={contactCompanyForm[field]}
                onChange={(e) => {
                    const { value } = e.target;
                    const newContactCompanyForm = clone(contactCompanyForm);
                    newContactCompanyForm[field] = value;
                    setContactCompanyForm(newContactCompanyForm);
                }}
            />
        );
    };

    const contactToImportInfoTable = contactToUpdate && !assigningCompleted && (
        <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
            <Table
                tableData={[
                    ["First Name", "first_name"],
                    ["Last Name", "last_name"],
                    ["Company", "company_name"],
                    ["Phone", "primary_phone"],
                    ["Email", "primary_email"],
                ].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        contactToImport[field[1]],
                    ];
                })}
            />
            <Button onClick={() => openAskModal()} size="sm">
                Assign contact
            </Button>
        </div>
    );

    const handleAssignContact = () => {
        createCompanyContacts(contactCompanyForm);
        setCompanyToUpdate({
            action: "MERGED",
            data: company,
        });
        setAssigningCompleted(true);
        closeAskModal();
    };

    return (
        <div>
            {contactToImportInfoTable}

            <h3>Before</h3>
            <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
                <Table
                    tableData={[["Company name", "name"]].map((field) => {
                        return [
                            <div style={{ textAlign: "right" }}>
                                <strong>{field[0]}</strong>
                            </div>,
                            get(company, `${[field[1]]}`, ""),
                        ];
                    })}
                />
            </div>

            <h3>After</h3>
            <Table
                tableData={[["Company name", "name"]].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        renderCompanyInput(field[1]),
                    ];
                })}
            />

            <div style={{ textAlign: "center" }}>
                <Button color="primary" onClick={handleMerge}>
                    Save
                </Button>
            </div>

            {openedAskModal && (
                <Dialog open={openedAskModal} keepMounted onClose={closeAskModal}>
                    <DialogTitle>Are you sure to assign contact to this company?</DialogTitle>

                    <div style={{ textAlign: "center", padding: 20 }}>
                        <h3>{contactData ? `${contactData.first_name} ${contactData.last_name}` : ""}</h3>
                        <Table
                            tableData={[
                                ["Department", "department"],
                                ["Title", "title"],
                                ["Phone", "phone"],
                                ["Email", "email"],
                            ].map((field, index) => {
                                return [
                                    <div style={{ textAlign: "right" }}>
                                        <strong>{field[0]}</strong>
                                    </div>,
                                    renderContactCompanyInput(field[1]),
                                ];
                            })}
                        />

                        <Button color="danger" onClick={handleAssignContact}>
                            Confirm
                        </Button>
                        <Button onClick={closeAskModal}>Cancel</Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default DialPadCompanyMergeReview;
