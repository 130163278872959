import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Companies from "containers/CRM/Companies/Companies";
import CompaniesForm from "containers/CRM/Companies/CompaniesForm";
import * as actions from '../../store/actions';
import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

class CRMCompaniesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'View',
      companyData: null,
      formDataObj: null,
      offset: 0,
      rows: 10,
      sort_by: 'created_date',
      sort_direction: 'DESC',
      filters: {
        name: '',
        email: '',
        main_phone: '',
        contact_type: ''
      },
      success: '',
      error: '',
      openedAsk: false,
    }
  }

  setOpenedAsk = (openedAsk) => {
    this.setState({ openedAsk });
  }

  setFilters = (field, value) => {
    const newFilters = { ...this.state.filters };
    newFilters[field] = value;
    this.setState({ filters: newFilters });
  }

  resetFilters = () => {
    this.setState({
      filters: {
        name: '',
        email: '',
        main_phone: '',
        contact_type: ''
      },
    })
  }

  setRows = (rows) => {
    this.setState({ rows });
  }

  setOffset = (offset) => {
    this.setState({ offset });
  }

  setFormDataObj = (formDataObj) => {
    this.setState({ formDataObj });
  }

  setSort = (sort_by, sort_direction) => { this.setState({ sort_by, sort_direction }) }

  getCompaniesParams = (shouldReset) => {
    const get = () => {
      const params = {
        offset: this.state.offset,
        rows: this.state.rows,
        sort_by: this.state.sort_by,
        sort_direction: this.state.sort_direction
      };
      Object.keys(this.state.filters).forEach(field => {
        if (this.state.filters[field]) {
          params[field] = this.state.filters[field];
        }
      })
      this.props.getCompanies(params);
    }

    if (shouldReset) {
      this.setState({ offset: 0 }, () => {
        get();
      })
    } else {
      get();
    }

  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.offset !== this.state.offset || prevState.rows !== this.state.rows) {
      this.getCompaniesParams();
    }
    if (prevState.sort_by !== this.state.sort_by || prevState.sort_direction !== this.state.sort_direction) {
      this.getCompaniesParams();
    }
    if (prevProps.success !== this.props.success) {
        this.setState({ success: this.props.success });
    }
    if (prevProps.error !== this.props.error) {
        this.setState({ error: this.props.error });
    }
  }

  clearMessages = () => {
    this.setState({ error: null, success: null });
  }

  closeTab = () => {
    this.setState({ tab: '' });
  }

  openTab = (tab, subTab) => {
    const n = { tab };
    if (subTab) n.subTab = subTab;
    this.setState(n);
  }

  closeForm = () => {
    this.props.history.push('/crm/companies?p=view')
    this.setState({ tab: 'View' })
  }

  // componentDidMount() {
  //   this.props.getContactOptions();
  //   const params = new URLSearchParams(this.props.location.search);
	// 	const p = params.get('p'); // bar
	// 	if (p === 'new') {
	// 		this.setState({ tab: 'Add' });
  //     this.props.clearContacts();
  //     this.props.clearFileList();
  //     this.setFormDataObj(null);
	// 	}
  // }

  render() {
    
    const crmPermitted = checkPermissions('CRM');
    if (!crmPermitted) {
      return <Redirect to="/dashboard" />;
    }
    const crmAllowed = checkPermissionsActions('CRM');
    

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon
                  color={this.state.tab === 'Add' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({ tab: 'Add' });
                    // this.props.clearContacts();
                    // this.props.clearFileList();
                    this.setFormDataObj(null);
                  }}
                  className="hovered hovered-shadow"
                >
                  <i className="fas fa-plus"></i> <strong>Add Company</strong>
                </CardIcon>
              </CardHeader>
              <CardBody>
                {this.state.tab === 'View' &&
                  <div>
                    <Companies
                      location_search={this.props.location.search}
                      companyData={this.props.companyData}
                      getCompanies={this.getCompaniesParams}
                      getCompany={this.props.getCompany}
                      createCompany={this.props.createCompany}
                      updateCompany={this.props.updateCompany}
                      deleteCompany={this.props.deleteCompany}
                      companiesData={this.props.companiesData}
                      companiesCount={this.props.companiesCount}
                      agentData={this.props.agentData} 
                      agentDataCount={this.props.agentDataCount}
                      formDataObj={this.state.formDataObj}
                      setFormDataObj={this.setFormDataObj}
                      rows={this.state.rows}
                      offset={this.state.offset}
                      sortDirection={this.state.sort_direction}
                      setRows={this.setRows}
                      setOffset={this.setOffset}
                      error={this.state.error}
                      success={this.state.success}
                      loading={this.props.loading}
                      clearMessages={this.clearMessages}
                      filters={this.state.filters}
                      setFilters={this.setFilters}
                      resetFilters={this.resetFilters}
                      setSort={this.setSort}
                      sortBy={this.state.sort_by}
                      clearCRMMessages={this.props.clearCRMMessages}
                      location={this.props.location}
                      closeFrom={() => {
                        
                        this.props.history.push('/crm/companies?p=view')
                      }}
                      createCrmFile={this.props.createCrmFile}
                      deleteCrmFile={this.props.deleteCrmFile}
                      getCrmFileList={this.props.getCrmFileList}
                      getCrmFile={this.props.getCrmFile}
                      fileList={this.props.fileList}
                      downloadedFile={this.props.downloadedFile}
                      contactError={this.props.contactError}
                      contactSuccess={this.props.contactSuccess}
                      contactLoading={this.props.contactLoading}
                      contactOptions={this.props.contactOptions}
                    />
                  </div>}
                {this.state.tab === 'Add' &&
                  <Dialog
                    open={this.state.tab === 'Add'}
                    keepMounted
                    maxWidth={false}
                    fullWidth={true}
                    fullScreen={true}
                  >
                    <div className="dialog-wrapper">
                      <CompaniesForm
                        action="Add"
                        success_data={this.props.success_data}
                        dontCloseFormOnAdd={true}
                        closeForm={this.closeForm}
                        getCompanies={this.props.getCompanies}
                        getCompany={this.props.getCompany}
                        companiesData={this.props.companiesData}
                        formDataObj={this.state.formDataObj}
                        setFormDataObj={this.setFormDataObj}
                        createCompany={this.props.createCompany}
                        success={this.state.success}
                        error={this.state.error}
                        loading={this.props.loading}
                        clearMessages={this.clearMessages}
                        crmAllowed={crmAllowed}
                        fileList={this.props.fileList}
                        setOpenedAsk={this.setOpenedAsk}
                        showCloseBtn={true}
                        deleteCrmFile={this.props.deleteCrmFile}
                        contactError={this.props.contactError}
                        contactSuccess={this.props.contactSuccess}
                        contactLoading={this.props.contactLoading}
                        contactOptions={this.props.contactOptions}
                      />
                    </div>
                  </Dialog>
                }
              </CardBody>
            </Card>
          </GridItem>

          {this.state.openedAsk && <CustomDialogClose
            ok_go={() => {
              this.closeForm();
            }}
            open={this.state.openedAsk}
            close={() => {
              this.setOpenedAsk(false);
            }}
          />}
        </GridContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.crm_companies.error,
    success: state.crm_companies.success,
    success_data: state.crm_companies.success_data,
    loading: state.crm_companies.loading,
    companiesData: state.crm_companies.companiesData,
    companiesCount: state.crm_companies.companiesCount,
    agentData: state.crm_companies.agentData,
    agentDataCount: state.crm_companies.agentDataCount,
    companyData: state.crm_companies,

    fileList: state.crm_contacts.fileList,
    downloadedFile: state.crm_contacts.downloadedFile,
    contactError: state.crm_contacts.error,
		contactSuccess: state.crm_contacts.success,
		contactLoading: state.crm_contacts.loading,
    contactOptions: state.crm_contacts.contactOptions
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCompanies: (params) => {
      dispatch(actions.getCompanies(params));
    },
    getCompany: (id) => {
      dispatch(actions.getCompany(id));
    },
    createCompany: (data, sharesData, contactsData, entitiesData, dbasForAdd, relatedCompaniesForAdd) => {
      dispatch(actions.createCompany(data, sharesData, contactsData, entitiesData, dbasForAdd, relatedCompaniesForAdd));
    },
    updateCompany: (data, partnersData, addressData) => {
      dispatch(actions.updateCompany(data, partnersData, addressData));
    },
    deleteCompany: (id) => {
      dispatch(actions.deleteCompany(id));
    },  
    createCrmFile: (data) => {
      dispatch(actions.createCrmFile(data));
    },
    getCrmFileList: (data) => {
      dispatch(actions.getCrmFileList(data));
    },
    getCrmFile: (data) => {
      dispatch(actions.getCrmFile(data));
    },
    deleteCrmFile: (data) => {
      dispatch(actions.deleteCrmFile(data));
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMCompaniesPage);
