import axios from 'axios';
import base from './axiosBaseUrl';

const instance = axios.create({
    baseURL: base+'/api/contact-import'
})

instance.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization =  token;
  }
  return config;
});

instance.interceptors.response.use(function (response) {
    // Do something with response data
    return response;
  }, function (error) {
    // Do something with response error
    if(error.response.status === 401) {
      
      window.location.href = '/pages/login-page';
    }
    return Promise.reject(error);
});

export default instance;
