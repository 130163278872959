import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { Page, Document } from "react-pdf";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.jsx";
import ClipLoader from "react-spinners/ClipLoader";
import { get, isEmpty } from "lodash";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PMAPdfModal = (props) => {
    const { pdfData, open, close, file } = props;

    const [tab, setTab] = useState("Show Exhibit A");

    // const pdf = pdfData && !isEmpty(pdfData) ? pdfData : file;
    

    const exhibitPdf = get(file, "exhibitPdf", null);
    const pmaPdf = get(file, "pmaPdf", null);

    const downloadPdf = (pdfName, buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], {
            type: "application/pdf",
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(pdfBlob);
        link.download = pdfName;
        link.click();
    };

    const printPdf = (buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], {
            type: "application/pdf",
        });
        let pdfFrame = document.body.appendChild(
            document.createElement("iframe")
        );
        pdfFrame.style.display = "none";
        pdfFrame.onload = () => void pdfFrame.contentWindow.print();
        pdfFrame.src = window.URL.createObjectURL(pdfBlob);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div style={{ margin: 20 }}>
                    {!file && <PdfViewer pdfData={pdfData} />}
                    {pmaPdf && tab === "Show Exhibit A" && (
                        <PdfViewer pdfData={pmaPdf} />
                    )}
                    {exhibitPdf && tab === "Show PMA" && (
                        <PdfViewer pdfData={exhibitPdf} />
                    )}
                    <br />
                    {
                        <div className="text-center">
                            <Button
                                color="white"
                                className="mr-20"
                                onClick={() => {
                                    close();
                                }}
                            >
                                Close
                            </Button>
                            <Button
                                color="rose"
                                className="mr-20"
                                onClick={() => {
                                    if (file && tab === "Show Exhibit A") {
                                        downloadPdf("PMA.pdf", pmaPdf.data);
                                    } else if (file && tab === "Show PMA") {
                                        downloadPdf("ExhibitA.pdf", exhibitPdf.data);
                                    } else {
                                        downloadPdf("PMA.pdf", pdfData.data);
                                    }
                                }}
                            >
                                Download
                            </Button>
                            <Button
                                color="primary"
                                className="mr-20"
                                onClick={() => {
                                    if (file && tab === "Show Exhibit A") {
                                        printPdf(pmaPdf.data);
                                    } else if (file && tab === "Show PMA") {
                                        printPdf(exhibitPdf.data);
                                    } else {
                                        printPdf(pdfData.data);
                                    }
                                    printPdf(pdfData.data);
                                }}
                            >
                                Print
                            </Button>
                            {file && (
                                <Button
                                    onClick={() => {
                                        if (tab === "Show PMA") {
                                            setTab("Show Exhibit A");
                                        } else {
                                            setTab("Show PMA");
                                        }
                                    }}
                                >
                                    {tab}
                                </Button>
                            )}
                        </div>
                    }
                </div>
            </Dialog>
        </div>
    );
};

const PdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        
        setNumPages(numPages);
    };

    const renderNav = () => {
        return (
            <div className="text-center">
                <div>
                    Page {pageNumber} of {numPages}
                </div>
                <hr />
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber - 1);
                    }}
                    disabled={pageNumber === 1 ? true : false}
                    className="mr-20"
                >
                    <i className="fas fa-arrow-left"></i>
                </Button>
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber + 1);
                    }}
                    disabled={pageNumber === numPages ? true : false}
                >
                    <i className="fas fa-arrow-right"></i>
                </Button>
            </div>
        );
    };
    
    return (
        <Document file={props.pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            {numPages && (
                <div>
                    {renderNav()}
                    <div className="light-border">
                        <Page pageNumber={pageNumber} width={1000} />
                    </div>
                    {renderNav()}
                </div>
            )}
            {!numPages && (
                <div className="mb-20 mt-20 text-center">
                    <ClipLoader />
                </div>
            )}
        </Document>
    );
};

export default PMAPdfModal;
