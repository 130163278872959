export const getCompanyPermissions = (companyShares, dataObj, user_id, action, isEmployee) => {
	if (user_id === dataObj.user_id) return true;
	if (dataObj.user_id === 1 && isEmployee) return true;
	let result = false;
	if (companyShares) {
		if (companyShares.length > 0) {
			for (let i = 0; i < companyShares.length; i++) {
				const cs = companyShares[i];
				if (cs.user_id === user_id) {
					if (cs.access === 'F') {
						if (action === 'delete') return true;
						if (action === 'edit') return true;
					}
					if (cs.access === 'E') {
						if (action === 'edit') return true;
					}
				}
			}
		}
	}
	return result;
};

export const contactSubTypeDecider = (contactType, vendorList) => {
    let options = [];
    if (contactType.indexOf('Property Owner') !== -1) {
        options.push('Property Owner - Lessor');
        options.push('Property Owner - Seller');
    }
    if (contactType.indexOf('Lessee') !== -1) {
        options.push('Lessee');
        options.push('Sub Lessee');
    }
    if (contactType.indexOf('Real Estate Agent') !== -1) {
        options.push('Real Estate Agent - Selling Agent');
        options.push('Real Estate Agent - Leasing Agent');
        options.push('Real Estate Agent - Tenant Rep');
    }
    if (contactType.indexOf('Real Estate Broker') !== -1) {
        options.push('Real Estate Brokerage - Selling Agent');
        options.push('Real Estate Brokerage - Leasing Agent');
        // options.push('Real Estate Broker - Tenant Rep'); - Removed per https://www.wrike.com/workspace.htm?acc=554950#/inbox/work_item/1250277700
    }
    if (contactType.indexOf('Vendor') !== -1) {
        options = options.concat(vendorList.map(v => {
            return `Vendor - ${v}`;
        }));
    }
    return options;
}