import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "components/CustomButtons/Button.jsx";
import { IconButton } from '@material-ui/core';
import CustomDialogWithProperty from '../../../components/CustomDialog/CustomDialogWithProperty';
import Notification from 'components/Notification/Notification';
import NotificationAsk from 'components/Notification/NotificationAsk';
import { FadeLoader } from 'react-spinners';
import { getUser } from '../../../shared/authValidation';
import crmObj from '../crmObj';
import { clone } from '../../../shared/utility';
import { createAddressObject } from '../Properties/PropertiesLogic';

const CompaniesProperties = (props) => {
    const loggedInUser = getUser();
    const [properties, setProperties] = useState([]);
    const [message, setMessage] = useState('');
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [dataToDelete, setDataToDelete] = useState(null);
    const [addProperty, setAddProperty] = useState(false);

    const { canEdit, canDelete } = props;
    

    const choose = (chosen, typeObj) => {
        console.log('choosing prop', typeObj);
        const newObj = {
            company_id: props.dataObj ? props.dataObj.id : null,
            property_id: chosen.id,
            is_company: true
        };
        if (typeObj) {
            if (typeObj.acronymType) newObj.company_type = typeObj.acronymType;
            if (typeObj.subType) newObj.subtype = typeObj.subType;
            if (typeObj.theType) {
                const currentContactType = props.dataObj.contact_type;
                console.log('choosing prop - ', crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]);
                if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                    const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                    if (shouldExistContactType) {
                        let arr = currentContactType ? currentContactType.split(',') : [];
                        if (arr.indexOf(shouldExistContactType) === -1) {
                            arr.push(shouldExistContactType)
                            const new_contact_type = arr.join(',');
                            const newDataObj = clone(props.dataObj);
                            newDataObj.contact_type = new_contact_type;
                            if (shouldExistContactType === 'Other') {
                                if (typeObj.subType) {
                                    newDataObj.contact_type_other = newDataObj.contact_type_other ? 
                                            (newDataObj.contact_type_other + ',' + typeObj.subType)
                                        :
                                            typeObj.subType
                                }
                            }
                            // update current frontend form
                            props.setDataObj(newDataObj);
                            
                            if (props.dataObj.id) {
                                // update backend contact data
                                props.updateCompany({
                                    id: props.dataObj.id,
                                    contact_type: new_contact_type
                                });
                            }
                        }
                    }
                }
            }
        }
        // open property 
        props.createCompanyProperties(newObj)
        setAddProperty(false);
    };

    useEffect(() => {
        
        if ((props.action === 'Edit' || props.action === 'View') && props.dataObj) {
            if (props.dataObj.id) {
                props.getCompanyProperties({
                    company_id: props.dataObj.id,
                    is_company: true
                });
            }
        }
    }, [])

    useEffect(() => {
        if (props.companyPropertiesData) {
            setProperties(props.companyPropertiesData);
        }
    }, [props.companyPropertiesData]);

    return (
        <GridContainer>
            <GridItem xs={6} sm={6} md={6}>
                <h3 className="red-text">Properties :</h3>
                {props.loading && <div className="fade-loader-center">
                    <FadeLoader />
                </div>}
                {!props.loading && <div>
                    <h4>{properties.length} {properties.length === 1 ? 'Property' : 'Properties'}:</h4>
                    {properties.map((cd, i) => {
                        console.log('cd', cd)
                        const cc = cd.property_data;
                        let the_contact_type = cd.company_type ? crmObj.typeDefinitions[cd.company_type] : '';
                        let the_subtype = cd.subtype ? ` - ${cd.subtype}`: ``;
                        // Label Property Manager differently
                        if (the_subtype.indexOf('Property Manager') !== -1) {
                            the_contact_type = 'Property Manager';
                            the_subtype = '';
                        }
                        const property = createAddressObject(cc);
                        return <div key={`${i}-contact-cont`} style={{ fontSize: 16 }} className="boxed">
                            <strong>Project Name</strong>: {cc.name}<br />
                            <strong>Contact Type</strong>: {the_contact_type} {the_subtype}<br />
                            <strong>Street 1:</strong> {property.street1}<br />
                            {property.street2 && <div><strong>Street 2:</strong> {property.street2}</div>}
                            <strong>City:</strong> {cc.city}<br />
                            <strong>Zip:</strong> {cc.zip}<br />
                            {canDelete && <IconButton
                                    style={{textAlign:'right'}}
                                    onClick={() => {
                                        const data = {
                                            id: cd.id,
                                            property_id: cd.property_data.id,
                                            company_id: cd.company_data.id,
                                            is_company: true
                                        }
                                        setDataToDelete(data);
                                        setNotificationAsk('Are you sure you want to delete property association - ', cc.name);
                                    }}
                                >
                                    <i className="fas fa-trash-alt red-text"></i>
                                </IconButton>}
                        </div>
                })}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {(canEdit && props.action !== 'View') && <Button color="primary" onClick={() => {
                    setAddProperty(true);
                }}><i className="fas fa-plus"></i> Add Property</Button>}
            </GridItem>

            
            
            {message && <Notification 
                open={message ? true : false}
                close={() => { setMessage('') }}
                message={message}
            />}
            {notificationAsk && <NotificationAsk 
                open={notificationAsk ? true : false}
                close={() => { 
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                success={() => {
                    props.deleteCompanyProperties(dataToDelete)
                    setNotificationAsk('');
                    setDataToDelete(null);
                }}
                title="Are you sure?"
                message={notificationAsk}
                success_button_text="Yes, Delete"
            />}

            {addProperty && <CustomDialogWithProperty 
                options={[]}
                indx="id"
                desc="name"
                label="Add Property"
                title="Add Property"
                propertyType={addProperty}
                open={addProperty ? true : false}
                close={() => {
                    setAddProperty(false);
                }}
                askForType={true}
                crmType="Company"
                choose={choose}
                create_and_choose={choose}
                loggedInUser={loggedInUser}
            />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const c = state.crm_companies
    return {
        contactsData: state.crm_contacts.contactsData,
        companyPropertiesData: state.crm_companies.companyPropertiesData,
        companyContact: state.crm_contacts.contactData,
        success: c.success,
        loading: c.loading
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyProperties: (data) => {
            dispatch(actions.getCompanyProperties(data));
        },
        createCompanyProperties: (data) => {
            dispatch(actions.createCompanyProperties(data));
        },
        deleteCompanyProperties: (data) => {
            dispatch(actions.deleteCompanyProperties(data));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesProperties);

