const crmObj = {
    contactOrCompanyPropertyTypes: [
        'Owner', // OWN - Property Owner
        'Manager', // OTHER - Subtype = Property Manager
        'Trustee', // OTHER - Subtype = Trustee
        'Fiduciary', // OTHER - Subtype = Fiduciary
        'Other', // OTHER - Subtype= whatever was typed input
        'Real Estate Agent', // REA - Subtype = Agent
        'Real Estate Broker' // REA - Subtype = Broker
    ],
    contactOrCompanyPropertySubTypes: {
        'Owner': {
            type: 'OWN',
            subtype: '',
            contactType: 'Property Owner'
        },
        'Manager': {
            type: 'OTHER',
            subtype: 'Property Manager',
            contactType: 'Property Manager'
        }, 
        'Trustee': {
            type: 'OTHER',
            subtype: 'Trustee',
            contactType: 'Other'
        },
        'Fiduciary': {
            type: 'OTHER',
            subtype: 'Fiduciary',
            contactType: 'Other'
        },
        'Real Estate Agent': {
            type: 'REA',
            subtype: 'Agent',
            contactType: 'Real Estate Agent'
        },
        'Real Estate Broker': {
            type: 'REA',
            subtype: 'Broker',
            contactType: 'Real Estate Broker'
        },
        'Other': {
            type: 'OTHER',
            subtype: '',
            contactType: 'Other'
        }
    },
    typeDefinitions: {
        'OWN': 'Property Owner',
        'OTHER': 'Other',
        'REA': 'Real Estate'
    }
}

export default crmObj;