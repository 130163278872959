import * as actionTypes from './actionTypes';

export const clearPmaMessages = () => {
    return {
        type: actionTypes.CLEAR_PMA_MESSAGES
    }
}

// GET_PMA_ENTRIES

export const getPmaEntries = (data) => {
    return {
        type: actionTypes.GET_PMA_ENTRIES,
        data
    }
}

export const getPmaEntriesStart = () => {
    return {
        type: actionTypes.GET_PMA_ENTRIES_START
    }
}

export const getPmaEntriesSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_ENTRIES_SUCCESS,
        response
    }
}

export const getPmaEntriesFail = (err) => {
    return {
        type: actionTypes.GET_PMA_ENTRIES_FAIL,
        err
    }
}

// GET_PMA_ENTRY 

export const getPmaEntry = (data) => {
    return {
        type: actionTypes.GET_PMA_ENTRY,
        data
    }
}

export const getPmaEntryStart = () => {
    return {
        type: actionTypes.GET_PMA_ENTRY_START
    }
}

export const getPmaEntrySuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_ENTRY_SUCCESS,
        response
    }
}

export const getPmaEntryFail = (err) => {
    return {
        type: actionTypes.GET_PMA_ENTRY_FAIL,
        err
    }
}

// CREATE_PMA_ENTRY

export const createPmaEntry = (data) => {
    return {
        type: actionTypes.CREATE_PMA_ENTRY,
        data
    }
}

export const createPmaEntryStart = () => {
    return {
        type: actionTypes.CREATE_PMA_ENTRY_START
    }
}

export const createPmaEntrySuccess = (response, pmaData) => {
    return {
        type: actionTypes.CREATE_PMA_ENTRY_SUCCESS,
        response,
        pmaData
    }
}

export const createPmaEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_PMA_ENTRY_FAIL,
        err
    }
}

// UPDATE_PMA_ENTRY

export const updatePmaEntry = (data) => {
    return {
        type: actionTypes.UPDATE_PMA_ENTRY,
        data
    }
}

export const updatePmaEntryStart = () => {
    return {
        type: actionTypes.UPDATE_PMA_ENTRY_START
    }
}

export const updatePmaEntrySuccess = (response, successType, pmaData) => {
    return {
        type: actionTypes.UPDATE_PMA_ENTRY_SUCCESS,
        response,
        successType,
        pmaData
    }
}

export const updatePmaEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_PMA_ENTRY_FAIL,
        err
    }
}

// DELETE_PMA_ENTRY 

export const deletePmaEntry = (data) => {
    return {
        type: actionTypes.DELETE_PMA_ENTRY,
        data
    }
}

export const deletePmaEntryStart = () => {
    return {
        type: actionTypes.DELETE_PMA_ENTRY_START
    }
}

export const deletePmaEntrySuccess = (response) => {
    return {
        type: actionTypes.DELETE_PMA_ENTRY_SUCCESS,
        response
    }
}

export const deletePmaEntryFail = (err) => {
    return {
        type: actionTypes.DELETE_PMA_ENTRY_FAIL,
        err
    }
}

// GET_PMA_PDF

export const getPmaPdf = (data) => {
    return {
        type: actionTypes.GET_PMA_PDF,
        data
    }
}

export const getPmaPdfStart = () => {
    return {
        type: actionTypes.GET_PMA_PDF_START
    }
}

export const getPmaPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_PDF_SUCCESS,
        response
    }
}

export const getPmaPdfFail = (err) => {
    return {
        type: actionTypes.GET_PMA_PDF_FAIL,
        err
    }
}

// GET_PMA_HISTORY 

export const getPmaHistory = (data) => {
    return {
        type: actionTypes.GET_PMA_HISTORY,
        data
    }
}

export const getPmaHistoryStart = () => {
    return {
        type: actionTypes.GET_PMA_HISTORY_START
    }
}

export const getPmaHistorySuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_HISTORY_SUCCESS,
        response
    }
}

export const getPmaHistoryFail = (err) => {
    return {
        type: actionTypes.GET_PMA_HISTORY_FAIL,
        err
    }
}

// GET_PMA_APPROVERS 

export const getPmaApprovers = (data) => {
    return {
        type: actionTypes.GET_PMA_APPROVERS,
        data
    }
}

export const getPmaApproversStart = () => {
    return {
        type: actionTypes.GET_PMA_APPROVERS_START
    }
}

export const getPmaApproversSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_APPROVERS_SUCCESS,
        response
    }
}

export const getPmaApproversFail = (err) => {
    return {
        type: actionTypes.GET_PMA_APPROVERS_FAIL,
        err
    }
}

// GET_PMA_SIGNING_URL 

export const getPmaSigningUrl = (data) => {
    return {
        type: actionTypes.GET_PMA_SIGNING_URL,
        data
    }
}

export const getPmaSigningUrlStart = () => {
    return {
        type: actionTypes.GET_PMA_SIGNING_URL_START
    }
}

export const getPmaSigningUrlSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_SIGNING_URL_SUCCESS,
        response
    }
}

export const getPmaSigningUrlFail = (err) => {
    return {
        type: actionTypes.GET_PMA_SIGNING_URL_FAIL,
        err
    }
}

// GET_PMA_HISTORY_PDF 

export const getPmaHistoryPdf = (data) => {
    return {
        type: actionTypes.GET_PMA_HISTORY_PDF,
        data
    }
}

export const getPmaHistoryPdfStart = () => {
    return {
        type: actionTypes.GET_PMA_HISTORY_PDF_START
    }
}

export const getPmaHistoryPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_HISTORY_PDF_SUCCESS,
        response
    }
}

export const getPmaHistoryPdfFail = (err) => {
    return {
        type: actionTypes.GET_PMA_HISTORY_PDF_FAIL,
        err
    }
}

// GET_PMA_TEMPLATES 

export const getPmaTemplates = (data) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATES,
        data
    }
}

export const getPmaTemplatesStart = () => {
    return {
        type: actionTypes.GET_PMA_TEMPLATES_START
    }
}

export const getPmaTemplatesSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATES_SUCCESS,
        response
    }
}

export const getPmaTemplatesFail = (err) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATES_FAIL,
        err
    }
}

// GET_PMA_TEMPLATE 

export const getPmaTemplate = (data) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATE,
        data
    }
}

export const getPmaTemplateStart = () => {
    return {
        type: actionTypes.GET_PMA_TEMPLATE_START
    }
}

export const getPmaTemplateSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATE_SUCCESS,
        response
    }
}

export const getPmaTemplateFail = (err) => {
    return {
        type: actionTypes.GET_PMA_TEMPLATE_FAIL,
        err
    }
}

// CREATE_PMA_TEMPLATE 

export const createPmaTemplate = (data) => {
    return {
        type: actionTypes.CREATE_PMA_TEMPLATE,
        data
    }
}

export const createPmaTemplateStart = () => {
    return {
        type: actionTypes.CREATE_PMA_TEMPLATE_START
    }
}

export const createPmaTemplateSuccess = (response) => {
    return {
        type: actionTypes.CREATE_PMA_TEMPLATE_SUCCESS,
        response
    }
}

export const createPmaTemplateFail = (err) => {
    return {
        type: actionTypes.CREATE_PMA_TEMPLATE_FAIL,
        err
    }
}

// UPDATE_PMA_TEMPLATE 

export const updatePmaTemplate = (data, no_message) => {
    return {
        type: actionTypes.UPDATE_PMA_TEMPLATE,
        data,
        no_message
    }
}

export const updatePmaTemplateStart = () => {
    return {
        type: actionTypes.UPDATE_PMA_TEMPLATE_START
    }
}

export const updatePmaTemplateSuccess = (response, no_message) => {
    return {
        type: actionTypes.UPDATE_PMA_TEMPLATE_SUCCESS,
        response,
        no_message
    }
}

export const updatePmaTemplateFail = (err) => {
    return {
        type: actionTypes.UPDATE_PMA_TEMPLATE_FAIL,
        err
    }
}

// DELETE_PMA_TEMPLATE 

export const deletePmaTemplate = (data) => {
    return {
        type: actionTypes.DELETE_PMA_TEMPLATE,
        data
    }
}

export const deletePmaTemplateStart = () => {
    return {
        type: actionTypes.DELETE_PMA_TEMPLATE_START
    }
}

export const deletePmaTemplateSuccess = (response) => {
    return {
        type: actionTypes.DELETE_PMA_TEMPLATE_SUCCESS,
        response
    }
}

export const deletePmaTemplateFail = (err) => {
    return {
        type: actionTypes.DELETE_PMA_TEMPLATE_FAIL,
        err
    }
}

// RESEND_PMA_APPROVER_NOTIFICATION 

export const resendPmaApproverNotification = (data) => {
    return {
        type: actionTypes.RESEND_PMA_APPROVER_NOTIFICATION,
        data
    }
}

export const resendPmaApproverNotificationStart = () => {
    return {
        type: actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_START
    }
}

export const resendPmaApproverNotificationSuccess = (response) => {
    return {
        type: actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_SUCCESS,
        response
    }
}

export const resendPmaApproverNotificationFail = (err) => {
    return {
        type: actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_FAIL,
        err
    }
}

// GET_PMA_FILE 

export const getPmaFile = (data) => {
    return {
        type: actionTypes.GET_PMA_FILE,
        data
    }
}

export const getPmaFileStart = () => {
    return {
        type: actionTypes.GET_PMA_FILE_START
    }
}

export const getPmaFileSuccess = (response) => {
    return {
        type: actionTypes.GET_PMA_FILE_SUCCESS,
        response
    }
}

export const getPmaFileFail = (err) => {
    return {
        type: actionTypes.GET_PMA_FILE_FAIL,
        err
    }
}

// VOID_PMA_ENTRY 

export const voidPmaEntry = (data) => {
    return {
        type: actionTypes.VOID_PMA_ENTRY,
        data
    }
}

export const voidPmaEntryStart = () => {
    return {
        type: actionTypes.VOID_PMA_ENTRY_START
    }
}

export const voidPmaEntrySuccess = (response) => {
    return {
        type: actionTypes.VOID_PMA_ENTRY_SUCCESS,
        response
    }
}

export const voidPmaEntryFail = (err) => {
    return {
        type: actionTypes.VOID_PMA_ENTRY_FAIL,
        err
    }
}