import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const saleFields = FIELDS.Sale;

export const subFields = SUBFIELDS.Sale;

export const sectionNames = SECTION.Sale.names;

export const sectionRequirements = SECTION.Sale.requirements;

export const initialData = getInitialData(saleFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
