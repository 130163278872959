import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FadeLoader } from "react-spinners";
import compose from "recompose/compose";
import clone from "lodash/clone";
import { Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons

// core components
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import { email, required, no_letters } from "shared/validation";

import * as actions from "../../store/actions/index.js";
import { SnackbarContent } from '@material-ui/core';

const validate = {
    email,
    required,
    no_letters,
};

const ERTLOwnerLogin = (props) => {
    const { classes, loading, clientError } = props;

    const [values, setValues] = useState({
        email: "",
        ertlNumber: "",
    });

    const { email, ertlNumber } = values;

    return (
        <div className={classes.content}>
            <div className={classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <form>
                            <Card login>
                                <CardHeader
                                    className={`${classes.cardHeader} ${classes.textCenter}`}
                                    color="primary"
                                >
                                    <img
                                        src="/assets/white-illi-logo.png"
                                        style={{ width: 100 }}
                                    />
                                    <h1
                                        className={classes.cardTitle}
                                        style={{ fontSize: 24, marginTop: 20 }}
                                    >
                                        illi Owner Login
                                    </h1>
                                    <div className={classes.socialLine}>
                                        {[].map((prop, key) => {
                                            return (
                                                <Button
                                                    color="transparent"
                                                    justIcon
                                                    key={key}
                                                    className={
                                                        classes.customButtonClass
                                                    }
                                                >
                                                    <i className={prop} />
                                                </Button>
                                            );
                                        })}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    <div
                                        style={{
                                            textAlign: "center",
                                            paddingTop: 20,
                                        }}
                                    >
                                        <CustomInput
                                            label="Email"
                                            value={email}
                                            onChange={(e) => {
                                                const newValues = clone(values);
                                                newValues.email =
                                                    e.target.value;
                                                setValues(newValues);
                                            }}
                                            validate={[
                                                validate.email,
                                                validate.required,
                                            ]}
                                        />
                                        <CustomInput
                                            label="ERTL Number"
                                            value={ertlNumber}
                                            onChange={(e) => {
                                                const newValues = clone(values);
                                                newValues.ertlNumber =
                                                    e.target.value;
                                                setValues(newValues);
                                            }}
                                            validate={[
                                                validate.no_letters,
                                                validate.required,
                                            ]}
                                        />
                                    </div>
                                </CardBody>
                                <CardFooter
                                    className={classes.justifyContentCenter}
                                >
                                    {loading && (
                                        <FadeLoader
                                            sizeUnit={"px"}
                                            size={150}
                                            color={"#123abc"}
                                            loading={true}
                                        />
                                    )}
                                    {!loading && (
                                        <Button
                                            color="success"
                                            size="lg"
                                            block
                                            type="button"
                                        >
                                            REVIEW
                                        </Button>
                                    )}
                                </CardFooter>

                                {clientError && (
                                    <div
                                        style={{
                                            marginLeft: "10px",
                                            marginRight: "10px",
                                        }}
                                    >
                                        <br />
                                        <SnackbarContent
                                            message={clientError}
                                            close
                                            color="danger"
                                        />
                                    </div>
                                )}
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        clientError: state.ertl_entries.clientError,
    };
};

export default compose(
    withStyles(loginPageStyle),
    connect(mapStateToProps)
)(ERTLOwnerLogin);
