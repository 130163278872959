import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { clone } from "lodash";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { createDocusignObj, dontSaveArr, generatePDF, generatePDFThankYou } from "./TREntriesLogic";
import { generateSections } from "./TREntriesLogic";
import Button from "components/CustomButtons/Button";
import TRPDFPreview from "./TRPDFPreview";
import { statusDescriptionsDict } from "./TREntriesLogic";
import Loader from "../../../components/Loader/Loader";

const TREntriesFormAction = props => {
    const [openedPDFPreview, setOpenedPDFPreview] = useState(false);
    const [loadedPDF, setLoadedPDF] = useState(false);
    const [loading, setLoading] = useState(false);

    const { data, history, listTab, entryData } = props;
    console.log('listTab', listTab)

    useEffect(() => {
        console.log('preview pdf -', props.pdf_buffer, loadedPDF);
        if (props.pdf_buffer && loadedPDF) {
            setOpenedPDFPreview(true);
            setLoadedPDF(false);
            // set PDF buffer to null
        }
    }, [props.pdf_buffer, loadedPDF]);

    const permissionsArr = props.permissions ? Object.keys(props.permissions) : [];

    const submit = (action, newDocusignObj) => {
        if (newDocusignObj) {
            props.save(newDocusignObj, {
                action: statusDescriptionsDict[action],
                message: data.notes ? data.notes : '',
                json_data: JSON.stringify(data)
            },
            true, action)
        } else {
            props.save({
                status: statusDescriptionsDict[action]
            }, {
                action: statusDescriptionsDict[action],
                message: data.notes ? data.notes : '',
                json_data: JSON.stringify(data)
            },
            true, action)
        }
    }

    console.log('preview pdf', openedPDFPreview);

    const dontSave = dontSaveArr.indexOf(props.listTab) !== -1 ? true : false;
    if (dontSave) return null;

    if (loading) return <Loader center={true} />

    return <div>
            {/* {
                <div style={{marginBottom:20}}>
                    <TextField
                        style={{ width: "100%" }}
                        id="multiline-flexible-notes"
                        label="Internal Notes"
                        multiline
                        rowsMax="4"
                        value={data.notes}
                        onChange={(e) => {
                            props.updateDataObj("notes", e.target.value);
                        }}
                        margin="normal"
                    />
                    <hr />
                </div>
            } */}

            {listTab === 'my_drafts' && <Button
                color="primary"
                className="mr-20"
                onClick={() => {
                    setLoadedPDF(true);
                    props.createTrPdf({
                        htmlContent: generatePDFThankYou(data)
                    });
                }}
            >
                PREVIEW THANK YOU LETTER PDF
            </Button>}

            {listTab === 'my_drafts' && <Button
                color="primary"
                className="mr-20"
                onClick={() => {
                    setLoadedPDF(true);
                    props.createTrPdf({
                        htmlContent: generatePDF(data)
                    });
                }}
            >
                PREVIEW AGREEMENT PDF
            </Button>}

            {(listTab === 'pending_my_approval' && props.tr_id) && <Button
                color="primary"
                className="mr-20"
                onClick={() => {
                    setLoadedPDF(true);
                    props.createTrHistoryPdf({
                        entry_id: props.tr_id
                    });
                }}
            >
                VIEW DOCUSIGNED PDF
            </Button>}

            {(!props.viewOnly && listTab !== 'pending_signature')  && (
                <Button
                    color="white"
                    className="mr-20"
                    onClick={() => {
                        props.save();
                    }}
                >
                    SAVE
                </Button>
            )}

            {(!props.viewOnly && listTab !== 'pending_signature') && (
                <Button
                    color="success"
                    className="mr-20"
                    onClick={() => {
                        const sections = generateSections(data, history);
                        let errors = [];
                        sections.forEach(section => {
                            if (section.errors.length > 0) {
                                errors = errors.concat(clone(section.errors));
                            }
                        })
                        const emails = data.tenants.map(c => c.email).concat([
                            data.final_approver_email
                        ]);
                        console.log('email check', emails);
                        const emailsObj = {};
                        let hasDuplicateEmails = false;
                        emails.forEach(em => {
                           if (emailsObj[em]) {
                                hasDuplicateEmails = true;
                           } else { 
                                emailsObj[em] = 1;
                           }
                        })
                        if (hasDuplicateEmails) errors.push('All emails must be unique');
                        console.log('errors', errors);
                        if (errors.length > 0) {
                            return props.setNotification(errors.map((e,i) => {
                                return <div key={`n-${i}`}>
                                    {e}
                                </div>
                            }))
                        }
                        
                        if (props.entryData) {
                            if (entryData.status === 1) {
                                // Send to Tenants for Signature first
                                const newDocusignObj = createDocusignObj(data);
                                submit('Submitted to Tenants for Signature', newDocusignObj);
                            } else if (entryData.status === 4) {
                                // Submitted to Approver Level 1
                                submit('Pending Final Approval');
                            }
                        }

                        setLoading(true);
                    }}
                >
                    {props.entryData.status === 4 ? 'APPROVE' : 'SUBMIT'}
                </Button>
            )}

            {!props.viewOnly &&
                (listTab !== "my_drafts" && listTab !== 'pending_signature') &&
                (permissionsArr.indexOf('approve_level_2') !== -1 || permissionsArr.indexOf('approve_level_1') !== -1) &&
                (
                <Button
                    color="rose"
                    className="mr-20"
                    onClick={() => {
                        if (permissionsArr.indexOf('approve_level_2') !== -1) {
                            submit('Rejected by Final Approver');
                        } else if (permissionsArr.indexOf('approve_level_1') !== -1) {
                            submit('Rejected by Approver Level 1');
                        }
                        setLoading(true);
                    }}
                >
                    DENY
                </Button>
            )}

            {openedPDFPreview && (
                <TRPDFPreview
                    close={() => {
                        setOpenedPDFPreview(false);
                    }}
                    open={openedPDFPreview}
                    pdfData={props.pdf_buffer ? props.pdf_buffer : null}
                />
            )}
    </div>
}

const mapStateToProps = (state) => {
    return {
        error_pdf: state.tr_entries.error_pdf,
        success_pdf: state.tr_entries.success_pdf,
        pdf_buffer: state.tr_entries.pdfData,
        loading_pdf: state.tr_entries.loading_pdf,
        tr_history: state.tr_entries.tr_history
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTrPdf: (params) => {
            dispatch(actions.createTrPdf(params));
        },
        createTrHistoryPdf: (data) => {
            dispatch(actions.createTrHistoryPdf(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TREntriesFormAction);