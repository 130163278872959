import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { importerFieldNames, importerFields } from './PropertiesObj';
import { analyzeDiffs, analyzeImportData, createAfterProperty } from './PropertiesLogic';
import Notification from 'components/Notification/Notification';
import readXlsxFile from 'read-excel-file';
import Table from '../../../components/Table/Table.jsx';
import { Dialog } from '@material-ui/core';
import { Close, Check } from '@material-ui/icons';
import PropertiesImporterDialog from './PropertiesImporterDialog';
import ClipLoader from "react-spinners/ClipLoader";
import { clone } from '../../../shared/utility';

const exists = (dat) => {
    return dat ? dat : '';
}

const PropertiesImporter = (props) => {
    const [data, setData] = useState([]);
    const [skippedData, setSkippedData] = useState([])
    const [alertMsg, setAlertMsg] = useState('');
    const [tableData, setTableData] = useState([]);
    const [showImports, setShowImports] = useState(false);
    const [submissionData, setSubmissionData] = useState([]);
    const [openedChooseModal, setOpenedChooseModal] = useState(null);
    const [checkMode, setCheckMode] = useState(false);
    const [issues, setIssues] = useState(0); // counter of issues
    const [finalized, setFinalized] = useState(false);
    const [corrected, setCorrected] = useState([]);
    const [filterIssues, setFilterIssues] = useState(false);
    const { import_data, setImportProperties } = props;

    // determines if importing is already done
    let isImporting = false;
    console.log('PropertiesImporter', props, data, tableData);
    if (import_data) {
        if (data.length > 1) {
            if ((data.length - 1) !== import_data.checks.length) {
                isImporting = true;
            }
        }
    }

    const handleSkip = (property, index) => {
        if (import_data) console.log('import data before', import_data.checks, index);
        let newData = clone(data);
        newData.splice(index, 1);
        setData(newData);

        const new_import_data = clone(import_data);
        if (new_import_data.checks) {
            new_import_data.checks.splice(index-1, 1);
            console.log('import data after', new_import_data.checks);
            setImportProperties(new_import_data);
        }
        
        let newSkippedData = clone(skippedData);
        newSkippedData.push(property);
        setSkippedData(newSkippedData)
    }

    useEffect(() => {
        let newIssues = 0;
        if (data.length > 0) {

            // ERROR CHECKING
            let errors = [];
            // check if template has more than 1 rows
            if (data.length < 2) errors.push('There needs to be more than 1 row');
            // check if template has 15 columns
            let correctColumns = true;
            for (let i = 0; i < data.length; i++) {
                let row = data[i];
                if (row) {
                    if (row.length > 21 || row.length < 19) {
                        correctColumns = false;
                        break;  
                    }
                }
            }
            if (!correctColumns) errors.push('Each row needs to have columns');
            // check if any of the data has spaces between agents
            let agent_pass = true;
            for (let i = 1; i < data.length; i++) {
                const d = data[i];
                const agent1 = d[15];
                const agent2 = d[16];
                const agent3 = d[17];
                const agent4 = d[18];
                if (agent1 || agent2 || agent3 || agent4) {
                    let totals = 0;
                    
                    if (agent1) totals += 1;
                    if (agent2) totals += 2;
                    if (agent3) totals += 3;
                    if (agent4) totals += 4;

                    if (totals !== 10 && totals !== 6 && totals !== 3 && totals !== 1) {
                        agent_pass = false;
                        break;
                    }
                }
            }
            if (!agent_pass) errors.push('Data for agents must not contain a blank between them, e.g. "John Smith, <Blank>, Juan Smith"');

            // check if minimum requirement is met, like Name
            // let project_names = [];
            // let duplicate_project_names = [];
            // for (let i = 0; i < data.length; i++) {
            //     const d = data[i];
            //     if (!d[2]) {
            //         if (errors.indexOf('Project name is missing in one of the import entries') === -1) errors.push('Project name is missing in one of the import entries');
            //     } else {
            //         if (project_names.indexOf(d[2]) === -1) {
            //             project_names.push(d[2]);
            //         } else {
            //             if (duplicate_project_names.indexOf(d[2]) === -1) duplicate_project_names.push(d[2]);
            //         }
            //     }
            // }
            // if (duplicate_project_names.length > 0) {
            //     errors.push(`These project names have duplicates in the import file (${duplicate_project_names.join(',')})`)
            // }

            // check if there are agents who do not exist in contacts table
            let missing_in_contacts_table = [];
            if (import_data) {
                if (import_data.checks) {
                    for (let i = 0; i < import_data.checks.length; i++) {
                        const idc = import_data.checks[i];
                        if (idc.agents_not_in_contacts) {
                            if (idc.agents_not_in_contacts.length > 0) {
                                idc.agents_not_in_contacts.forEach(agent => {
                                    if (missing_in_contacts_table.indexOf(agent) === -1) {
                                        missing_in_contacts_table.push(agent);
                                    }
                                });
                            }
                        }
                    }
                }
            }
            if (missing_in_contacts_table.length > 0) {
                errors.push(missing_in_contacts_table.join(',') + ' is/are non-existent contact(s), please add them to your contacts first.');
            }

            if (errors.length > 0) {
                setAlertMsg(errors.join(', '));
                return;
            }

            // let filtered_import_data = [];
            // if (import_data) {
            //     if (import_data.checks) {
            //         data.filter((d,i) => {
            //             if (i !== 0) return true; // remove header row
            //         }).forEach((d,i) => {
            //             if (import_data.checks[i]) {
            //                 const property_check = clone(import_data.checks[i]);
            //                 property_check.index = i;
            //                 console.log('check', property_check);
            //                 const after_property = createAfterProperty(d);
            //                 // get exact matches
            //                 const exact_match_found_properties = property_check.found_properties.filter(before_property => {
            //                     const analyzed = analyzeDiffs(before_property, after_property);
            //                     if (analyzed.different_fields.length === 0) {
            //                         return true;
            //                     }
            //                 });
            //                 if (exact_match_found_properties.length === 1) {
            //                     property_check.found_properties = exact_match_found_properties; // leave only 1
            //                 }
            //                 filtered_import_data.push(property_check);
            //             } 
            //         });
            //     }
            // }

            let newSubmissionData = [];
            setTableData(data.filter((d,i) => {
                if (i !== 0) return true; // remove header row
            }).map((d,i) => {
                const range1 = exists(d[importerFields.indexOf('street_range1')]);
                const range2 = exists(d[importerFields.indexOf('street_range2')]);
                const range21 = exists(d[importerFields.indexOf('street2_range1')]);
                const range22 = exists(d[importerFields.indexOf('street2_range2')]);
                let agents = [];
                let msg = '';
                let hasBeenCorrected = false;
                let property_check = null;
                let hasIssues = false;

                if (import_data) {
                    if (import_data.checks) {
                        if (import_data.checks[i]) {
                            property_check = clone(import_data.checks[i]);
                            property_check.index = i;
                            const after_property = createAfterProperty(d);
                            hasIssues = analyzeImportData(property_check, after_property);
                        }
                    }
                }

                if (corrected.indexOf(i) !== -1) {
                    hasBeenCorrected = true;
                }

                if (hasIssues) newIssues++;

                newSubmissionData.push({
                    data: d
                })

                return [
                    i+1,
                    d[importerFields.indexOf('illi_number')],
                    d[importerFields.indexOf('pm_number')],
                    d[importerFields.indexOf('name')],
                    <div key={`dd0${i+1}`}>
                        {range1} - {range2}<br />
                        <div key={`dd1${i+1}`}>{exists(d[importerFields.indexOf('street1')])}</div>
                    </div>,
                    <div key={`dd1${i+1}`}>
                        {range21} - {range22}<br />
                        <div key={`dd2${i+1}`}>{exists(d[importerFields.indexOf('street2')])}</div>
                    </div>,
                    <div key={`d1${i+1}`}>
                        <div key={`dd3${i+1}`}>{exists(d[importerFields.indexOf('city')])}</div>
                        <div key={`dd4${i+1}`}>{exists(d[importerFields.indexOf('state')])}</div>
                        <div key={`dd5${i+1}`}>{exists(d[importerFields.indexOf('zip')])}</div>
                    </div>,
                    d[importerFields.indexOf('use_type')],
                    d[importerFields.indexOf('total_building_sqft')],
                    d[importerFields.indexOf('property_is_for')],
                    agents.map((a,i) => {
                        return <div key={`${i}-agent`}>
                            {a}
                        </div>
                    }),
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        {checkMode && <div>
                            {hasIssues && <div 
                                style={{color: !hasBeenCorrected ? 'red' : 'green'}} 
                                className="hoverable" onClick={() => {
                                    setOpenedChooseModal({property_check, originalData: d, index: i});
                                }}
                            >{hasBeenCorrected ? <Check /> : <Close />}<br />
                                {hasBeenCorrected ? 'Issue corrected' : 'Issue found'}
                            </div>}
                            {!hasIssues && <div style={{color:'green'}}><Check /></div>}
                        </div>}
                        <Button color="primary" 
                            onClick={() => handleSkip(d, i+1)}
                            style={{marginLeft:10}}
                        >Skip</Button>
                    </div>,
                    hasIssues ? true : false
                ];

            }));

            setSubmissionData(newSubmissionData);
            setIssues(newIssues);
        }
    }, [data, import_data]);

    console.log('PropertiesImporter', { 
        props, data, import_data, issues, tableData, corrected, isImporting, finalized, submissionData
    });

    const columns = [
        <strong>#</strong>,
        <strong>illi #</strong>,
        <strong>PMA #</strong>,
        <strong>Project Name</strong>,
        <strong>Address 1 Range</strong>,
        <strong>Address 2 Range</strong>,
        <strong>City, State, Zip</strong>,
        <strong>Company Type</strong>,
        <strong>Total Sqft</strong>,
        <strong>Property Is For</strong>,
        <strong>Agents</strong>,
        <strong>Actions</strong>
    ];

    let completed_import = false;
    if (import_data) {
        if (import_data.imported && import_data.error_imports) {
            const total_import_attempts = import_data.imported.length + import_data.error_imports.length;
            if (total_import_attempts === tableData.length) {
                completed_import = true;
            }
        }
    }

    return (
    <div style={{paddingBottom:40}}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h1>Properties Importer</h1>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {tableData.length === 0 && <div>
                    <div style={{marginBottom:20}}>
                        <strong>Import from Template</strong>
                    </div>
                    <input 
                        type="file" 
                        id="excel-input" 
                        onChange={() => {
                            readXlsxFile(document.querySelector('#excel-input').files[0]).then((rows) => {
                                setTableData([]);
                                setSubmissionData([]);
                                setData(rows.filter((r,i) => {
                                    // if (i > 3) {
                                    //     return false;
                                    // }
                                    return true;
                                }));
                            }).catch(e => {
                                setAlertMsg('Errors detected in file, please ensure you are using the correct template format');
                            });
                        }}
                    />
                </div>}
                {tableData.length > 0 && <div>
                    <Button
                        color="white"
                        onClick={() => {
                            window.location.reload();
                        }}
                        style={{marginRight:20}}
                    >RESET</Button>
                    {!checkMode && <Button
                        color="success"
                        onClick={() => {
                            setCheckMode(true);
                            setFinalized(true);
                            props.importProperties(clone(data), true); // true means check only
                        }}
                        style={{marginRight:20}}
                    >START CHECK</Button>}
                    {isImporting && <span>
                        <ClipLoader />
                    </span>}
                    {(!isImporting && (corrected.length === issues)) && <span>
                        {(finalized && submissionData.length > 0) && <Button
                            color="rose"
                            onClick={() => {
                                props.importProperties(clone(data));
                                setShowImports(true);
                            }}
                        >START IMPORT</Button>}
                    </span>}
                </div>}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <a href="/templates/properties-importer-template-2b-tb.xlsx" 
                        download
                        style={{textDecoration:'underline',color:'#da3a2f'}}
                    >Download Import Template</a>
            </GridItem>

            {alertMsg && <Notification 
                message={alertMsg}
                open={alertMsg ? true : false}
                close={() => {
                    window.location.reload();
                    setAlertMsg('');
                }}
            />}

            {tableData.length > 0 && <GridItem xs={12} sm={12} md={12}>
                <div style={{paddingBottom:20}}>
                    <div className="text-center">{tableData.length} Import Entries Loaded</div>
                    {import_data && <div>
                        {import_data.checks && <div style={{textAlign:'center'}}>
                            {import_data.checks.length} Property(s) Checked
                        </div>}
                    </div>}
                    {issues > 0 && <div style={{color:'red',textAlign:'center'}}>{issues} Issue(s) Found</div>}
                    {corrected.length > 0 && <div style={{color:'green',textAlign:'center'}}>{corrected.length} Issue(s) Corrected</div>}
                    {(!isImporting && checkMode && issues > 0) && <div style={{textAlign:'center'}}>
                        {!filterIssues && <Button color="rose" onClick={() => {
                            setFilterIssues(true);
                        }}>SHOW ISSUES ONLY </Button>}
                        {filterIssues && <Button color="primary" onClick={() => {
                            setFilterIssues(false);
                        }}>SHOW ALL</Button>}
                    </div>}
                </div>
                <div style={{height:500,overflowY:'scroll'}} className="striped">
                    <Table 
                        tableHead={columns}
                        tableData={tableData.filter(td => {
                            if (filterIssues) {
                                if (td[11]) {
                                    return false;
                                } else {
                                    return true;
                                }
                            } else {
                                return true;
                            }           
                        }).map(m => {
                            const mm = clone(m);
                            mm.pop();
                            return mm;
                        })}
                    />  
                </div>  
            </GridItem>}

            {showImports && <Dialog
                open={showImports}
                onClose={() => {
                    setShowImports(false);
                    setTableData([]);
                    setData([]);
                }}
                fullWidth={true}
                maxWidth="md"
            >
                <div className="dialog-wrapper">
                    <div className="close-btn" onClick={() => { 
                        if (completed_import) window.location.reload();
                    }}><Close /></div>
                    <GridContainer>
                        <GridItem xs={6} sm={12} md={12}>
                            <div className="text-center">
                                <h3>Import Result(s)</h3>
                            </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <div className="text-center">
                                {props.import_data && <div>
                                    {props.import_data.imported && <div>
                                        <h4>
                                            {props.import_data.imported.length}
                                            {props.import_data.imported.length === 1 ? ' Property' : ' Properties'} Imported
                                        </h4>
                                        {props.import_data.imported.map((im, i) => {
                                            return <div key={`${i}-import`}>{im}.</div>;
                                        })}
                                    </div>}
                                </div>}
                                
                            </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <div className="text-center">
                                <h4>{skippedData.length}{" "}{skippedData.length === 1 ? "Property" : 'Properties'}{" "}Skipped</h4>
                                {skippedData.map((pro, i) => <div key={`${i}-skip`}>{pro[importerFields.indexOf("name")]}</div>)}
                            </div>
                        </GridItem>
                        <GridItem xs={4} sm={4} md={4}>
                            <div className="text-center">
                                {props.import_data && <div>
                                    {props.import_data.error_imports && <div>
                                        <h4>
                                            {props.import_data.error_imports.length}
                                            {props.import_data.error_imports.length === 1 ? ' Property' : ' Properties'} Not Imported
                                        </h4>
                                        {props.import_data.error_imports.map((im, i) => {
                                            return <div key={`${i}-import`}>{data[im] ? data[im][2] : '1'} not imported.</div>;
                                        })}
                                    </div>}    
                                </div>}
                            </div>
                        </GridItem>
                        <GridItem xs={6} sm={12} md={12}>
                            {!completed_import && <div style={{textAlign:'center'}}>
                                <ClipLoader />   
                            </div>}
                            {completed_import && <div className="text-center" style={{marginTop:20}}>
                                <Button onClick={() => {
                                    window.location.reload();
                                }}>CLOSE</Button>
                            </div>}
                        </GridItem>
                    </GridContainer>
                </div>
            </Dialog>}

            {openedChooseModal && <PropertiesImporterDialog 
                open={openedChooseModal ? true : false}
                close={() => {
                    setOpenedChooseModal(null);
                }}
                data={openedChooseModal}
                originalData={data}
                setOriginalData={setData}
                corrected={corrected}
                setCorrected={setCorrected}
                importerFields={importerFields}
                importerFieldNames={importerFieldNames}
            />}
        </GridContainer>
    </div>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    return {
        error: p.import_error,
        success: p.import_success,
        import_data: p.import_data
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      importProperties: (arr, checkOnly) => {
          dispatch(actions.importProperties(arr, checkOnly));
      },
      setImportProperties: (new_import_data) => {
        dispatch(actions.setImportProperties(new_import_data));
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(PropertiesImporter);