import Button from "components/CustomButtons/Button.jsx";
import { textData } from "containers/ERTL/ERTLObj";
import jwt_decode from "jwt-decode";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { clone } from "shared/utility";
import ERTLFullForm from "./FormWizard/ERTLFullForm";
import { generateHTML } from "./FormWizard/ERTLLogic";
import { initialERTLdata } from "./FormWizard/ERTLModel";
import ERTLPdfModal from "./FormWizard/ERTLPdfModal";

const ERTLOwnerFullForm = (props) => {
    const [templateText, setTemplateText] = useState(textData);
    const [editedObj, setEditedObj] = useState({});
    const [tab, setTab] = useState("wizard");
    const [ertlFormData, setErtlFormData2] = useState(clone(initialERTLdata));
    const [ertlEntryData, setErtlEntryData] = useState(null);
    const [activityTracker, setActivityTracker] = useState(false);
    const [templateEdited, setTemplateEdited] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(-1);
    const [saveDraft, setSaveDraft] = useState(false);

    const [openPDFview, setOpenPDFview] = useState(false);
    const [localPdfData, setLocalPdfData] = useState(null);
    const [localTabsData, setLocalTabsData] = useState(null);
    const [localHTMLData, setLocalHTMLData] = useState(null);
    const [message, setMessage] = useState('');

    const setErtlFormData = (newData) => {
        setActivityTracker(true);
        setErtlFormData2(newData);
    };

    const integrateData = () => {
        try {
            const newData = clone(ertlFormData);
            if (props.ertlData) {
                const data = props.ertlData.data;
                Object.keys(data).forEach((field) => {
                    newData[field] = data[field];
                });
                const token = localStorage.getItem("token");
                if (token) {
                    const decoded = jwt_decode(token);
                    console.log('decoded');
                    if (decoded.name) {
                        if (!newData.p1_cc) newData.p1_cc = decoded.name;
                        if (!newData.p1_contact_2_name)
                            newData.p1_contact_2_name = decoded.name
                                ? decoded.name.split(" ")[0]
                                : "";
                    }
                }
                setErtlFormData2(newData);
                setErtlEntryData(props.ertlData);
            }
        } catch(e) {
            console.log('e', e)
        }
    };

    useEffect(() => {
        try {
            const token = localStorage.getItem("token");
            const decoded = jwt_decode(token);
            if (decoded) {
                console.log('decoded', decoded);
            }
        } catch(e) {
            console.log('error', e);
        }
        // if approver go to tab Full Form
        if (props.origin === "approver" || props.origin === "view") {
            setTab("full");
        }
        // set defaults
        if (!props.ertlData) {
            const newData = clone(ertlFormData);
            const now = moment().format("MMMM D, YYYY");
            newData.p2_date_as_of = now;
            newData.p1_date = now;
            const token = localStorage.getItem("token");
            if (token) {
                const decoded = jwt_decode(token);

                if (decoded.name) {
                    if (!newData.p1_cc) newData.p1_cc = decoded.name;
                    if (!newData.p1_contact_2_name)
                        newData.p1_contact_2_name = decoded.name
                            ? decoded.name.split(" ")[0]
                            : "";
                }
            }
            setErtlFormData2(newData);
        } else {
            integrateData();
        }
    }, []);


    useEffect(() => {
        if (props.pdfData && typeof props.pdfData === "string") {
            const pdfData = JSON.parse(props.pdfData);

            setLocalPdfData(pdfData);
        }
    }, [props.pdfData]);

    const handleUpdateEntry = () => {
        const ddata = clone(ertlFormData);
        ddata.templateData = templateText;

        const entry = generateHTML(ertlFormData, templateText);

        if (props.ertlData.id) {
            const data = {
                data: ddata,
                token: props.token,
                html: entry.html,
                tabs: entry.tabs,
            };

            props.updateClientErtl(data);
            setMessage('Submitted');
            //props.historyPush(`/`);
        }
    };

    const current_id = props.ertlData ? props.ertlData.id : null;

    console.log('ERTLOwnerFullForm templates', props, props.templateData, templateText)

    if (message) {
        return <div style={{textAlign:'center',marginTop:40}}>
            <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png'
                style={{marginBottom:40,width:100}} />
            <h3>{message}</h3>
        </div>
    }

    return (
        <div>
            <div style={{ paddingBottom: 100 }}>
                <ERTLFullForm
                    templatesData={props.templatesData}
                    templateData={props.templateData}
                    templateText={templateText}
                    setTemplateText={setTemplateText}
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                    editedObj={editedObj}
                    setEditedObj={setEditedObj}
                    createTemplate={props.createTemplate}
                    getTemplates={props.getTemplates}
                    createEntry={props.createEntry}
                    updateEntry={props.updateEntry}
                    getEntries={props.getEntries}
                    getEntryPdf={props.getEntryPdf}
                    pdfData={props.pdfData}
                    ertlData={props.ertlData}
                    ertlEntryData={ertlEntryData}
                    setTab={setTab}
                    setParentTab={props.setParentTab}
                    deleteEntry={props.deleteEntry}
                    origin="owner"
                    signReview={props.signReview}
                    reload={props.reload}
                    setWait={props.setWait}
                    templateEdited={templateEdited}
                    setTemplateEdited={setTemplateEdited}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    historyDiffData={props.historyDiffData}
                    saveDraft={saveDraft}
                    setSaveDraft={setSaveDraft}
                    hideSelectTemplate
                    clientEmail={props.clientEmail}
                />
            </div>
            <div className="bottom-dweller ">
                <Button color="success" onClick={handleUpdateEntry}>
                    SUBMIT
                </Button>
                <Button
                    color="primary"
                    className="ml-20"
                    onClick={() => {
                        const entry = generateHTML(
                            ertlFormData,
                            templateText,
                            current_id
                        );
                        setLocalTabsData(entry.tabs);
                        setLocalHTMLData(entry.html);
                        const obj = {
                            id: 1,
                            entry: entry.html,
                            tabs: entry.tabs,
                        };

                        props.getEntryPdf(obj);
                        setOpenPDFview(true);
                    }}
                >
                    VIEW PDF
                </Button>
            </div>

            {openPDFview && (
                <ERTLPdfModal
                    pdfData={localPdfData}
                    tabsData={localTabsData}
                    htmlData={localHTMLData}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        setOpenPDFview(false);
                    }}
                    ertlFormData={ertlFormData}
                    ertlEntryData={props.ertlEntryData}
                    ertlData={props.ertlData}
                    createEntry={props.createEntry}
                    updateEntry={props.updateEntry}
                />
            )}
        </div>
    );
};

export default ERTLOwnerFullForm;
