import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { DialogContent, TextField } from '@material-ui/core';
import Datetime from 'react-datetime';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { addressFields } from '../ERTLObj';
import ertlFields from 'containers/ERTL/FormWizard/ERTLModel';
import ERTLelement from '../FormWizard/ERTLelement';
import { cloneDeep } from 'lodash';

const ERTLDataModal = (props) => {
    const { field, ertlFormData, setErtlFormData } = props;
    let fieldSplit = field.split('.');
    let newField = fieldSplit.length > 1 ? ertlFormData[fieldSplit[0]][fieldSplit[1]] : ertlFormData[field];

    const updateData = (field, value) => {
        const data = cloneDeep(ertlFormData);
        data[field] = value;
        props.setErtlFormData(data);
    }

    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="md"
                fullWidth={true}
            >
                <div style={{ margin: 20, height: '60vh' }} className="date-overflow">
                    <h4 className="text-center">{ertlFields[field] ? ertlFields[field].label : ''}</h4>
                    <ERTLelement 
                        ertlFormData={ertlFormData}
                        field={field}
                        update={updateData}
                        autoFocus={true}
                    />
                    <br />
                    <Button color="rose" onClick={() => { props.close() }}>OK</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default ERTLDataModal;