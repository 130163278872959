import React from "react";
import ReactTable from "react-table";

const RSheetsList = (props) => {
    const { columns, data } = props;
    const defaultSorted = props.defaultSorted
        ? props.defaultSorted
        : [
              {
                  id: "date",
                  desc: true,
              },
          ];

    return (
        <div className="react-table-hover-gray">
            <ReactTable
                defaultSorted={defaultSorted}
                data={data}
                className="-striped"
                showPaginationTop
                showPaginationBottom
                columns={columns}
                resizable
                defaultPageSize={10}
                pageSizeOptions={[10, 20, 30]}
                minRows={10}
                filterable
            />
        </div>
    );
};

export default RSheetsList;
