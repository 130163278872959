import React from "react";
import { has, isEmpty } from "lodash";
import { Dialog, DialogContent, DialogTitle, Modal } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import Button from 'components/CustomButtons/Button.jsx';


const ListingHistoryModal = (props) => {
    const { history, isOpen, close } = props;
    // const { data } = history;

    if (isEmpty(history)) return null;

    return (
        <Dialog
            open={isOpen}
            onClose={close}
            keepMounted
            // maxWidth={false}
            // fullWidth={false}
            fullWidth={true}
        >
            <DialogTitle>
                <div className="text-center">History</div>
            </DialogTitle>

            <div style={{ padding: "0 20px 20px" }}>
                {history.map((i) => (
                    <div>
                        <span
                            style={{
                                display: "inline-block",
                                marginRight: "2rem",
                            }}
                        >
                            {moment(i.created_date).format("MM/DD/YY h:mm a")}
                        </span>
                        {i.value}
                    </div>
                ))}
            </div>
            <div style={{ textAlign: "center", padding: 20 }}>
                <Button
                    color="white"
                    style={{ marginRight: 10 }}
                    onClick={close}
                >
                    OK
                </Button>
            </div>
        </Dialog>
    );
};

export default ListingHistoryModal;
