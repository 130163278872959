import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import PropertiesForm from "containers/CRM/Properties/PropertiesForm";
import PropertiesList from "containers/CRM/Properties/PropertiesList";
import * as actions from "../../store/actions";
import Dialog from "@material-ui/core/Dialog";
import CustomDialogClose from "components/CustomDialog/CustomDialogClose";
import { clone } from 'shared/utility';
import { initialPropertyData } from "../../containers/CRM/Properties/PropertiesObj";

class CRMPropertiesPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			action: "Add",
			tab: "View",
			subTab: 'Main',
			success: "",
			error: "",
			rows: 10,
			offset: 0,
			filters: {
				name: "",
				street1: "",
				city: "",
				state: "",
				use_type: "",
				illi_represented: "",
				illi_number: ""
			},
			sort_by: "created_date",
			sort_direction: "DESC",
			openedAsk: false,
			propertyData: JSON.parse(JSON.stringify(initialPropertyData))
		};
	}

	setAction = (action) => {
		this.setState({ action })
	}

	setSubTab = (subTab) => {
		this.setState({ subTab });
	}

	setOpenedAsk = (openedAsk) => {
		this.setState({ openedAsk });
	};

	getProperties = (shouldReset) => {
		const get = () => {
			const { rows, offset, sort_by, sort_direction } = this.state;
			const newParams = { rows, offset, sort_by, sort_direction };
			Object.keys(this.state.filters).forEach((field) => {
				if (this.state.filters[field]) {
					newParams[field] = this.state.filters[field];
				}
			});
			this.props.getProperties(newParams);
		};
		if (shouldReset) {
			this.setState({ offset: 0 }, () => {
				get();
			});
		} else {
			get();
		}
	};

	setRows = (rows) => {
		this.setState({ rows });
	};

	setOffset = (offset) => {
		this.setState({ offset });
	};

	setFilters = (field, val) => {
		this.setState({
			filters: { ...this.state.filters, [field]: val },
		});
	};

	resetFilters = () => {
		this.setState({
			filters: {
				name: "",
				street1: "",
				city: "",
				state: "",
				use_type: "",
				illi_represented: "",
			},
		});
	}

	setSort = (sort_by, sort_direction) => {
		this.setState({ sort_by, sort_direction });
	};

	setMessage = (msg, msgType) => {
		this.setState({
			[msgType]: msg,
		});
	};

	clearMessages = () => {
		this.setMessage("", "success");
		this.setMessage("", "error");
	};

	setTab = (tab) => {
		this.setState({ tab });
	};

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.success !== this.props.success) {
			this.setMessage(this.props.success, "success");
		}
		if (prevProps.error !== this.props.error) {
			this.setMessage(this.props.error, "error");
		}
		if (
			prevState.sort_by !== this.state.sort_by ||
			prevState.sort_direction !== this.state.sort_direction ||
			prevState.offset !== this.state.offset ||
			prevState.rows !== this.state.rows
		) {
			this.getProperties();
		}
		if (prevProps.propertyData !== this.props.propertyData) {
			this.setState({
				propertyData: this.props.propertyData
			})
		}
	}

	closeForm = () => {
		this.setState({ tab: "View" });
	};

	render() {
		
		const crmPermitted = checkPermissions("CRM");
		if (!crmPermitted) {
			return <Redirect to="/dashboard" />;
		}
		const crmAllowed = checkPermissionsActions("CRM");
		

		return (
			<div style={{ marginTop: 20 }}>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						<Card>
							<CardHeader color="rose" icon>
								<CardIcon
									color={this.state.tab === "Add" ? "success" : "rose"}
									onClick={() => {
										this.setState({
											propertyData: initialPropertyData
										});
										this.setTab("Add");
										this.setSubTab("Main");
									}}
									className="hovered hovered-shadow"
								>
									<i className="fas fa-plus"></i> <strong>Add Property</strong>
								</CardIcon>
							</CardHeader>
							<CardBody>
								{this.state.tab === "View" && (
									<PropertiesList
										history={this.props.history}
										propertiesData={this.props.propertiesData}
										propertyData={this.props.propertyData}
										updateProperty={this.props.updateProperty}
										deleteProperty={this.props.deleteProperty}
										getProperty={this.props.getProperty}
										getProperties={this.getProperties}
										rows={this.state.rows}
										offset={this.state.offset}
										sortDirection={this.state.sort_direction}
										sortBy={this.state.sort_by}
										filters={this.state.filters}
										setFilters={this.setFilters}
										resetFilters={this.resetFilters}
										setOffset={this.setOffset}
										setRows={this.setRows}
										setSort={this.setSort}
										loading={this.props.loading}
										contactLoading={this.props.contactLoading}
										success={this.state.success}
										error={this.state.error}
										clearMessages={this.clearMessages}
										getContacts={this.props.getContacts}
										contactsData={this.props.contactsData}
										contactData={this.props.contactData}
										getContact={this.props.getContact}
										contactNames={this.props.contactNames}
										clearContactNames={this.props.clearContactNames}
										getContactNames={this.props.getContactNames}
										getPropertyShares={this.props.getPropertyShares}
										getPropertyUsernames={this.props.getPropertyUsernames}
										getPropertyUseType={this.props.getPropertyUseType}
										propertyUseTypes={this.props.propertyUseTypes}
										createPropertyShare={this.props.createPropertyShare}
										deletePropertyShare={this.props.deletePropertyShare}
										propertyShares={this.props.propertyShares}
										propertyUsernames={this.props.propertyUsernames}
										crmAllowed={crmAllowed}
										fileList={this.props.fileList}
										getCrmFileList={this.props.getCrmFileList}
										getCrmFile={this.props.getCrmFile}
										deleteCrmFile={this.props.deleteCrmFile}
										downloadedFile={this.props.downloadedFile}
										createCrmFile={this.props.createCrmFile}
										setOpenedAsk={this.setOpenedAsk}
										showCloseBtn={true}
										contactError={this.props.contactError}
										contactSuccess={this.props.contactSuccess}
										checkPropertyExistingAddress={
											this.props.checkPropertyExistingAddress
										}
										existingAddress={this.props.existingAddress}
									/>
								)}
								{this.state.tab === "Add" && (
									<Dialog
										open={this.state.tab === "Add"}
										keepMounted
										maxWidth={false}
										fullWidth={true}
										fullScreen={true}
									>
										<div className="dialog-wrapper">
											<PropertiesForm
												shouldLoadEdit={true}
												success_data={this.props.success_data}
												action={this.state.action}
												setAction={this.setAction}
												propertyData={this.state.propertyData}
												getProperty={this.props.getProperty}
												propertySuccessData={this.props.propertySuccessData}
												setMessage={this.setMessage}
												clearMessages={this.clearMessages}
												error={this.state.error}
												success={this.state.success}
												createProperty={this.props.createProperty}
												loading={this.props.loading}
												contactLoading={this.props.contactLoading}
												getContacts={this.props.getContacts}
												getContact={this.props.getContact}
												contactsData={this.props.contactsData}
												contactData={this.props.contactData}
												contactNames={this.props.contactNames}
												getContactNames={this.props.getContactNames}
												updateProperty={this.props.updateProperty}
												getProperties={this.getProperties}
												clearContactNames={this.props.clearContactNames}
												closeForm={this.closeForm}
												getPropertyUsernames={this.props.getPropertyUsernames}
												propertyUsernames={this.props.propertyUsernames}
												propertyUseTypes={this.props.propertyUseTypes}
												getCrmFileList={this.props.getCrmFileList}
												getCrmFile={this.props.getCrmFile}
												getPropertyUseType={this.props.getPropertyUseType}
												downloadedFile={this.props.downloadedFile}
												createCrmFile={this.props.createCrmFile}
												deleteCrmFile={this.props.deleteCrmFile}
												fileList={this.props.fileList}
												checkPropertyExistingAddress={
													this.props.checkPropertyExistingAddress
												}
												existingAddress={this.props.existingAddress}
												setTab={this.setSubTab}
												tab={this.state.subTab}
												getPropertyShares={this.props.getPropertyShares}
											/>
										</div>
									</Dialog>
								)}
							</CardBody>
						</Card>
					</GridItem>

					{this.state.openedAsk && (
						<CustomDialogClose
							ok_go={() => {
								this.closeForm();
							}}
							open={this.state.openedAsk}
							close={() => {
								this.setOpenedAsk(false);
							}}
						/>
					)}
				</GridContainer>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const s = state.crm_properties;
	const c = state.crm_contacts;
	return {
		error: s.error,
		success: s.success,
		success_data: s.success_data,
		contactError: c.error,
		contactSuccess: c.success,
		contactLoading: c.loading,
		propertyData: s.propertyData,
		propertyUseTypes: s.options,
		propertiesData: s.propertiesData,
		loading: s.loading,
		contactsData: c.contactsData,
		contactData: c.contactData,
		contactNames: c.contactNames,
		propertyShares: s.propertyShares,
		propertyUsernames: c.contactUsernames,
		fileList: c.fileList,
		downloadedFile: c.downloadedFile,
		existingAddress: s.existingAddress,
		propertySuccessData: s.success_data
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProperties: (params) => {
			dispatch(actions.getProperties(params));
		},
		getProperty: (id) => {
			dispatch(actions.getProperty(id));
		},
		deleteProperty: (id) => {
			dispatch(actions.deleteProperty(id));
		},
		createProperty: (data, otherData) => {
			dispatch(actions.createProperty(data, otherData));
		},
		updateProperty: (data) => {
			dispatch(actions.updateProperty(data));
		},
		getContacts: (params) => {
			dispatch(actions.getContacts(params));
		},
		getContact: (id) => {
			dispatch(actions.getContact(id));
		},
		getContactNames: (data) => {
			dispatch(actions.getContactNames(clone(data)));
		},
		getPropertyShares: (property_id) => {
			
			dispatch(actions.getPropertyShares(property_id));
		},
		getPropertyUsernames: () => {
			dispatch(actions.getContactUsernames());
		},
		getPropertyUseType: () => {
			dispatch(actions.getOptions());
		},
		createPropertyShare: (data) => {
			
			dispatch(actions.createPropertyShare(data));
		},
		deletePropertyShare: (share_id) => {
			dispatch(actions.deletePropertyShare(share_id));
		},
		clearContactNames: () => {
			dispatch(actions.clearContactNames());
		},

		getCrmFileList: (data) => {
			dispatch(actions.getCrmFileList(data));
		},
		createCrmFile: (data) => {
			dispatch(actions.createCrmFile(data));
		},
		getCrmFile: (data) => {
			dispatch(actions.getCrmFile(data));
		},
		deleteCrmFile: (data) => {
			dispatch(actions.deleteCrmFile(data));
		},
		checkPropertyExistingAddress: (data) => {
			dispatch(actions.checkPropertyExistingAddress(data));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CRMPropertiesPage);