import React, { useState, useEffect } from "react";
import CustomInput from "../CustomInput/CustomInputValidate";
import CustomSimpleDialog from "../CustomDialog/CustomSimpleDialog";
import Button from "../CustomButtons/Button";
import Datetime from "react-datetime";
import Notification from "../Notification/Notification";
import moment from "moment";

const CustomDate = props => {
    const [openedDate, setOpenedDate] = useState(false);
    const [modalDate, setModalDate] = useState('');
    const [notification, setNotification] = useState(false);

    console.log('CustomDate', props);

    useEffect(() => {
        if (props.value) {
            setModalDate(props.value);
        }
    }, []);

    return <div
        onFocus={() => {
            setOpenedDate(true);
        }}
    >
        <CustomInput
            label={props.label}
            value={props.value}
            onChange={() => {}}
            onClick={() => {
                setOpenedDate(true);
            }}
            endAdornment={props.endAdornment}
        />

        {openedDate && <div>
            <CustomSimpleDialog 
                minHeight={400}
                open={true}
                close={() => {
                    setOpenedDate(false);
                }}
                content={<div className="text-center">
                    <h4>{props.label}</h4>
                    <Datetime
                        inputProps={{
                            placeholder: props.label,
                            autoFocus: true,
                        }}
                        closeOnSelect={true}
                        onChange={(e) => {
                            if (typeof e === "object") {
                                const stringDate = e.format("MM/DD/YYYY");
                                return setModalDate(stringDate);
                            }
                            setModalDate(e);
                        }}
                        timeFormat={false}
                        value={modalDate}
                    />
                    <Button
                        style={{marginTop:20,marginRight:20}}
                        color="rose"
                        onClick={() => {
                            if (props.condition) {
                                const passed = props.condition(modalDate);
                                if (passed === true) {
                                    props.onChange(modalDate);
                                    setOpenedDate(false);
                                } else if (typeof passed === 'string') {
                                    return setNotification(passed);
                                } else {
                                    return setNotification('Invalid date');
                                }
                            } else {
                                if (props.shouldBeAfterDate) {
                                    let passed = false;
                                    if (modalDate) {
                                        if (moment(modalDate, 'MM/DD/YYYY').isValid()) {
                                            const theDate = moment(modalDate, 'MM/DD/YYYY').valueOf();
                                            if (moment(props.shouldBeAfterDate, 'MM/DD/YYYY').isValid()) {
                                                const beforeDate = moment(props.shouldBeAfterDate, 'MM/DD/YYYY').valueOf();
                                                if (beforeDate < theDate) {
                                                    passed = true;
                                                }
                                            }
                                        }
                                    }
                                    if (!passed) {
                                        return setNotification('Please enter a valid date after ' + props.shouldBeAfterDate);
                                    }
                                }
                                props.onChange(modalDate);
                                setOpenedDate(false);
                            }
                        }}
                    >OK</Button> <Button
                        style={{marginTop:20,marginRight:20}}
                        color="white"
                        onClick={() => {
                            setOpenedDate(false);
                        }}
                    >CANCEL</Button>
                </div>}
                no_ok_button={true}
            />
        </div>}

        {notification && <Notification 
            open={true}
            close={() => {
                setNotification('');
            }}
            message={notification}
        />}
    </div>
}

export default CustomDate;