import React, { useState, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { textData } from "../ERTLObj";
import moment from "moment";
import BaseTemplate from "./ERTLBaseTemplate";
import ERTLDataModal from "./ERTLDataModal";
import ERTLModal from "./ERTLModal";
import { clone } from "shared/utility";
import { formatDate2 } from "../../../shared/utility";
import { getDifferences } from "../FormWizard/ERTLLogic";

const ERTLDisplayForm = (props) => {
    const {
        currentId,
        editedObj,
        setEditedObj,
        setTemplateEdited,
        templateText,
        setTemplateText,
        ertlFormData,
        setErtlFormData,
        selectedTemplate,
        setSelectedTemplate,
        templatesData,
        origin,
        historyDiffData,
        hideSelectTemplate,
        templateOriginal
    } = props;

    console.log('ERTLDisplayForm', props);

    const [openedModal, setOpenedModal] = useState(false);
    const [openedField, setOpenedField] = useState(null);
    const [openedDataModal, setOpenedDataModal] = useState(false);
    const [openedDataField, setOpenedDataField] = useState("");
    const [shouldShow, setShouldShow] = useState(true);
    const [clonedTemplateText, setClonedTemplateText] = useState(templateText);
    const [templatesArr, setTemplatesArr] = useState([
        {
            id: -1,
            name: "Base Template",
            data: JSON.stringify(textData),
            date_created: null,
        },
    ]);

    const openModal = (fieldObj, field) => {
        setOpenedField(fieldObj, field);
        setOpenedModal(true);
    };

    const openDataModal = (field) => {
        setOpenedDataField(field);
        setOpenedDataModal(true);
    };

    useEffect(() => {
        if (templatesData) {
            setTemplatesArr(
                templatesData
                    .map((t) => {
                        return {
                            id: t.id,
                            name: t.title,
                            title: t.title,
                            active: t.active,
                            defaults: t.defaults,
                            data: JSON.stringify(t.data),
                            date_created: formatDate2(t.created_date),
                        };
                    })
                    .filter((f) => {
                        if (f.active === 0) return false;
                        return true;
                    })
                    .sort(function (a, b) {
                        if (a.title.toLowerCase() < b.title.toLowerCase())
                            return -1;
                        if (a.title.toLowerCase() > b.title.toLowerCase())
                            return 1;
                        return 0;
                    })
            );
        }
    }, [templatesData]);

    useEffect(() => {
        console.log('templateTexzxt', templateText);
        if (templateText) {
            setClonedTemplateText(templateText);
        }
    }, [templateText]);

    if (!templateText) {
        return <div>Loading...</div>;
    } else {
        console.log('ERTLDisplayForm 1', ertlFormData.templateData);
        return (
            <div>
                <ERTLModal
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    field={openedField}
                    templateText={templateText}
                    setTemplateText={(newTemplateText) => {
                        setTemplateEdited(true);
                        setTemplateText(newTemplateText);
                    }}
                    setEditedObj={setEditedObj}
                    editedObj={editedObj}
                    currentId={currentId}
                />
                <ERTLDataModal
                    open={openedDataModal}
                    close={() => {
                        setOpenedDataModal(false);
                    }}
                    field={openedDataField}
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                />
                <br />
                <div style={{ padding: 20, backgroundColor: "#fafafa" }}>
                    <h3>
                        <strong>FORM OUTPUT PREVIEW</strong>
                    </h3>
                    {!hideSelectTemplate && (
                        <GridContainer>
                            {/* <Button
                            color={shouldShow ? 'white' : 'rose'}
                            onClick={() => {
                                setShouldShow(!shouldShow);
                            }}
                            style={{ marginBottom: 10 }}
                        >
                            {shouldShow ? 'HIDE CHANGE INDICATORS' : 'SHOW CHANGE INDICATORS'}
                        </Button> */}
                            <GridItem xs={12} sm={12} md={3}>
                                {
                                    <CustomSelect
                                        label="Select Template"
                                        name="templates"
                                        options={templatesArr}
                                        indx="id"
                                        desc="name"
                                        choose={(e, n) => {
                                            for (
                                                let i = 0;
                                                i < templatesArr.length;
                                                i++
                                            ) {
                                                const t = templatesArr[i];
                                                if (t.id === e) {
                                                    const cloned = clone(ertlFormData);
                                                    if (cloned.templateData) {
                                                        const cloned_t = JSON.parse(clone(t.data));
                                                        cloned_t.template_id = t.id;
                                                        cloned.templateData = cloned_t;
                                                        setErtlFormData(cloned);
                                                    }
                                                    // setTemplateText(
                                                    //     JSON.parse(t.data)
                                                    // );
                                                }
                                            }
                                            // setSelectedTemplate(e);
                                            
                                        }}
                                        default={ertlFormData.templateData ? ertlFormData.templateData.template_id : ''}
                                    />
                                }
                            </GridItem>
                        </GridContainer>
                    )}
                    {
                        <BaseTemplate
                            ertlFormData={ertlFormData}
                            openDataModal={openDataModal}
                            // textData={props.origin === 'owner' ? clonedTemplateText : templateOriginal} // "textData" IS BEFORE TEMPLATE
                            textData={templateOriginal} // "textData" IS BEFORE TEMPLATE
                            openModal={openModal}
                            getDifferences={getDifferences}
                            templateText={templateText} // AFTER TEMPLATE
                            shouldShow={shouldShow}
                            origin={origin}
                            ertl_id={currentId}
                            historyDiffData={historyDiffData}
                        />
                    }
                </div>
            </div>
        );
    }
};

export default ERTLDisplayForm;
