import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { Dialog } from '@material-ui/core';
import Button from 'components/CustomButtons/Button';

const Time = (props) => {
    const [hour, setHour] = useState('12');
    const [minutes, setMinutes] = useState('00');
    const [ampm, setAMPM] = useState('AM');

    useEffect(() => {
        const arr = props.time.split(' ');
        const _ampm = arr[1];
        const arr2 = arr[0].split(':');
        const _hour = arr2[0];
        const _minute = arr2[1];
        setHour(_hour);
        setMinutes(_minute);
        setAMPM(_ampm);
    }, []);

    return (
    <Dialog
        open={props.open}
        onClose={() => {
            props.close();
        }}
        fullWidth={true}
    >
        <div className="dialog-wrapper">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className="text-center">{props.label}</h3>
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                    <CustomSelect
                        label="Hour"
                        options={['01','02','03','04','05','06','07','08','09','10',
                        '11','12']}
                        choose={(e, n) => {
                            setHour(e);
                        }}
                        default={hour}
                    />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                    <CustomSelect
                        label="Minutes"
                        options={["00","01","02","03","04","05","06","07","08","09","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31","32","33","34","35","36","37","38","39","40","41","42","43","44","45","46","47","48","49","50","51","52","53","54","55","56","57","58","59"]}
                        choose={(e, n) => {
                            setMinutes(e);
                        }}
                        default={minutes}
                    />
                </GridItem>
                <GridItem xs={4} sm={4} md={4}>
                    <CustomSelect
                        label="AM/PM"
                        options={['AM','PM']}
                        choose={(e, n) => {
                            setAMPM(e);
                        }}
                        default={ampm}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                        <Button color="rose"
                            onClick={() => {
                                props.setTime(`${hour}:${minutes} ${ampm}`);
                            }}
                        >OK</Button>
                        <Button color="white"
                            onClick={() => {
                                props.close();
                            }}
                        >CANCEL</Button>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
    )
}

export default Time;