import React, { useEffect, useState, useRef } from 'react';
import { clone } from 'shared/utility';
import { formatDateForBackend } from "shared/utility";

export const useSaveLease = (props) => {
    const { madeChanges, updateLease, data, setMadeChanges } = props;

    const TIME = 300000;

    const cleanData = (data) => {
        const newLeaseData = clone(data);
        ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
            if (newLeaseData[d]) {
                newLeaseData[d] = formatDateForBackend(newLeaseData[d]);
            } else {
                delete newLeaseData[d];
            }
        });
        if (newLeaseData.other_data) {
            newLeaseData.other_data = JSON.stringify(newLeaseData.other_data);
        }
        return newLeaseData;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (madeChanges) {
                updateLease(cleanData(data));
                setMadeChanges(false);
            }
        }, TIME);
        return () => clearInterval(interval);
    }, [madeChanges, data]);
}