import { Tab } from '@material-ui/core';
import { SettingsApplications, SettingsBackupRestore } from '@material-ui/icons';
import React from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';

const ListingList = (props) => {
    const { listingsData, setListingData, setTab } = props;
    const data = listingsData ? listingsData.map((l, key) => { // map users to table rows
        const other_data = l.other_data ? 
                                (typeof l.other_data === 'string') ? 
                                    JSON.parse(l.other_data)
                                :
                                    l.other_data
                            : 
                                {agents:[]}
        return ({
            id: l.id,
            start_date: l.start_date ? formatDate2(l.start_date) : '', 
            expiration_date: l.expiration_date ? formatDate2(l.expiration_date) : '',
            expiration_alert_date: l.expiration_alert_date ? formatDate2(l.expiration_alert_date) : '',
            agents: other_data ? other_data.agents.map(a => {
                return a.name;
            }).join(', ') : '',
            listingData: l
        })
    }) : [];

    const columns = [
        {
            Header: "Listing ID #",
            accessor: "id" 
        },
        {
            Header: "Listing Start Date",
            accessor: "start_date"
        },
        {
            Header: "Listing Expiration Date",
            accessor: "expiration_date"
        },
        {
            Header: "Listing Expiration Alert Date",
            accessor: "expiration_alert_date"
        },
        {
            Header: "Agents",
            accessor: "agents"
        }
    ]

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          
                          if (rowInfo) {
                            if (rowInfo.original) {
                              setListingData(rowInfo.original.listingData)
                              if (props.canEdit) {
                                setTab('Edit');
                              } else {
                                setTab('View');
                              }
                            }
                          }
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
            />
        </div>
    )
}

export default ListingList;