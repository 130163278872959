import { put } from 'redux-saga/effects';
import axios from '../axios/axios-ertl';
import * as actions from '../actions/ertl_templates';
import { clone } from 'shared/utility';


export function* getTemplatesSaga(action) {
  yield put(actions.getTemplatesStart());
  try {
    const response = yield axios.get('/templates');
    
    yield put(actions.getTemplatesSuccess(response.data));
  } catch (err) {
    yield put(actions.getTemplatesFail(err));
  }
}

export function* getTemplateSaga(action) {
  yield put(actions.getTemplateStart());
  try {
      
      const response = yield axios.get('/template?id='+action.data);
      yield put(actions.getTemplateSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getTemplateFail(e.response.data));
          }
        }
      yield put(actions.getTemplateFail(e));
  }
}

export function* createTemplateSaga(action) {
  yield put(actions.createTemplateStart());
  // add entry
  try {
    const body = clone(action.data);
    if (action.title) {
      body.title = action.title;
    }
    const response = yield axios.post('/template', body);
    if (response) {
      if (response.data) {
        
        yield put(actions.createTemplateSuccess('Template Created'));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data) {
        
        return yield put(actions.createTemplateFail(err.response.data));
      }
    }
    return yield put(actions.createTemplateFail('Failed to add entry'));
  }
}

export function* deleteTemplateSaga(action) {

  yield put(actions.deleteTemplateStart());

  try {
    const response = yield axios.delete('/template?id=' + action.id);
    if (response.data) {
      if (response.data.msg === 'OK') {
        return yield put(actions.deleteTemplateSuccess('Successfully deleted template.'));
      }
    }
    return yield put(actions.deleteTemplateFail('Something went wrong deleting the Template'));
  } catch (e) {
    if (e.response) {
      if (e.response.data) {
        return yield put(actions.deleteTemplateFail(e.response.data));
      }
    }
    return yield put(actions.deleteTemplateFail('Failed to delete Template'));
  }
}

export function* updateErtlTemplateSaga(action) {
  yield put(actions.updateErtlTemplateStart());
  try {
      const response = yield axios.put('/template', action.data);
      yield put(actions.updateErtlTemplateSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateErtlTemplateFail(e.response.data));
          }
        }
      yield put(actions.updateErtlTemplateFail(e));
  }
}


