import React from 'react';
import { Checkbox  } from "@material-ui/core";
import { permsDetailedData } from "../../shared/permsObj";
import GridItem from '../../components/Grid/GridItem';
import GridContainer from '../../components/Grid/GridContainer';

const UserRolesMatrix = props => {
    const { data, setData } = props;
    console.log('data', data)
    return <>
        {Object.keys(permsDetailedData).map((subCategory,x) => {
            return <div key={subCategory}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <h3>{subCategory}</h3>
                    </GridItem>
                    {permsDetailedData[subCategory].map((pm,i) => {
                        return <GridItem xs={12} sm={6} md={6} key={`${x}-checkbox-${i}`} className="grayed"><Checkbox 
                            checked={data.indexOf(pm.id) !== -1 ? true : false}
                            onChange={() => {
                                    if (data.indexOf(pm.id) === -1) {
                                        setData(data.concat([pm.id]));
                                    } else {
                                        setData(data.filter((d) => d !== pm.id));
                                    }
                                }}
                            /> - {pm.name}
                        </GridItem>
                    })}
                </GridContainer>
            </div>
        })}
    </>
}

export default UserRolesMatrix;