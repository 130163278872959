import React from 'react';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import { clone } from 'shared/utility';

const PMAExhibitElements = (props) => {
    const { field, type, setFormData, formData, index, options, label, 
        // only on checkbox
        value } = props;

    const exhibit_data = formData.signing_ceremony ? 
        formData.signing_ceremony[index] ? 
            formData.signing_ceremony[index].exhibit_data ? 
                formData.signing_ceremony[index].exhibit_data
            :
                {} 
        : 
            {} 
    : 
        {};
    const updateExhibitData = (field, value) => {
        const newData = clone(formData);
        if (newData) {
            if (newData.signing_ceremony) {
                if (newData.signing_ceremony[index]) {
                    if (newData.signing_ceremony[index].exhibit_data) {
                        newData.signing_ceremony[index].exhibit_data[field] = value;
                    }
                }
            }
        }
        
        setFormData(newData);
    }
    
    return <div>
        {type === 'input' && <CustomInput 
                                value={exhibit_data[field]}
                                onChange={(e) => {
                                    updateExhibitData(field, e.target.value);
                                }}
                                label={label}
                            />}
        {type === 'radio' && <div className="mt-20">
                <CustomRadio
                    initialValue={exhibit_data[field]}
                    label={`${label},${options.join(',')}`}
                    options={options}
                    onChange={(e) => {
                        updateExhibitData(field, e.target.value);
                    }}
                    value={exhibit_data[field]}
                />
            </div>}
        {type === 'checkbox' && <div className="mt-20">
                            <div className="radio">
                                <label><input 
                                    type="checkbox" 
                                    onChange={(e) => {
                                        updateExhibitData(field, !exhibit_data[field]);
                                    }}
                                    checked={exhibit_data[field]} />{label}</label>
                            </div>
        </div>}
        <hr />
    </div>
}

export default PMAExhibitElements;