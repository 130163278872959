import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { formatDate3 } from "shared/utility";
import { Tooltip } from "@material-ui/core";
import Button from "../../../components/CustomButtons/Button";
import { connect } from "react-redux";
import * as actions from "store/actions";
import download from "downloadjs";
import { getUser } from "../../../shared/authValidation";
import Loader from "components/Loader/Loader";
import { renderExhibitA } from "../PMAObj";
import { get, has } from "lodash";

const extractDate = (date) => {
    if (date) {
        const arr = date.split('T')[0].split('-');
        return `${arr[1]}${arr[2]}${arr[0]}`;
    }
    return '';
}

const historyAction = {
    1: "Pending owners signature", // 0 signers
    2: "Owner Signed, Pending approver signature", // 1 signed
    3: "Owner and approver signed", // pending illi
    4: "Rejected by owner",
    5: "Rejected by approver",
    6: "Owner filled up Exhibit A",
    7: "Owner voided for editing",
    8: "Resent to owner(s) for signature"
};

const sleep = (time) => {
    return new Promise((resolve) => setTimeout(resolve, time));
};

const downloadPdf = (pdfName, buffer) => {
    const pdfBlob = new Blob([new Uint8Array(buffer)], {
        type: "application/pdf",
    });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(pdfBlob);
    link.download = pdfName;
    link.click();
};

const PMAHistory = (props) => {
    const [currentData, setCurrentData] = useState(null);
    const [downloadPDF, setDownloadPDF] = useState(false);

    useEffect(() => {
        setCurrentData(null);
        reload();
    }, []);

    useEffect(() => {
        if (downloadPDF) {
            if (props.error) {
                
                alert('File not found');
            }
        }
    }, [props.error]);

    const downloadFile = async () => {
        if ((props.historyPDF && downloadPDF) && (currentData.pma_number && currentData.date)) {
            if (has(props.historyPDF, "pmaPdf")) {
                await sleep(1000);
                downloadPdf(`PMA-${currentData.pma_number}-${currentData.date}.pdf`, props.historyPDF.pmaPdf.data);
            }
            if (has(props.historyPDF, "exhibitPdf")) {
                await sleep(1000);
                if (props.historyPDF.exhibitPdf) {
                    downloadPdf(
                        `PMA-${currentData.pma_number}-${currentData.date}-ExhibitA.pdf`,
                        props.historyPDF.exhibitPdf.data
                    );
                }
            }
            setDownloadPDF(false);
            setCurrentData(null);
        }
    };

    useEffect(() => {
        downloadFile();
    }, [props.historyPDF]);

    const reload = () => {
        const user = getUser();
        
        if (user) {
            if (user.permObj) {
                if (
                    user.permObj.hasOwnProperty("PMA approve level_1") ||
                    user.permObj.hasOwnProperty("PMA approve level_2")
                ) {
                    return props.getPmaHistory("all");
                }
            }
        }
        props.getPmaHistory("own");
    };

    const { historyData, permissions } = props;
    

    const data = historyData
        ? historyData.map((l, key) => {
              // map users to table rows
              const dat = l.json_data;
              
              let signed = [];
              if (dat.signing_ceremony) {
                  if (dat.signing_ceremony.length > 0) {
                      dat.signing_ceremony.forEach((sc) => {
                          if (sc.signed === 1) signed.push(sc.name);
                      });
                  }
              }
              const signers = signed.join(", ");
              return {
                  id: l.entry_id,
                  pma_id: l.json_data
                      ? l.json_data.pma_id
                          ? l.json_data.pma_id
                          : ""
                      : "",
                  created_date: formatDate3(l.date_created),
                  action: historyAction[l.action],
                  property: dat.property ? dat.property.address : "",
                  signers,
                  name: l.name,
                  data: l,
                  actions: (
                      <div>
                          {
                              <div>
                                  <Tooltip title="Download PDF" placement="top">
                                      <Button
                                          justIcon
                                          round
                                          simple
                                          onClick={() => {
                                              const signing_ceremony = get(
                                                  l,
                                                  "json_data.signing_ceremony",
                                                  []
                                              );
                                              const exhibitA =
                                                  renderExhibitA(
                                                      signing_ceremony
                                                  );

                                              props.getPmaHistoryPdf({
                                                  entry_id: l.id,
                                                  exhibitA,
                                              });
                                              setCurrentData({
                                                date: extractDate(l.date_created),
                                                pma_number: l.json_data.pma_id ? l.json_data.pma_id : l.json_data.id
                                              });
                                              setDownloadPDF(true);
                                          }}
                                          color="primary"
                                          className="mr-20"
                                      >
                                          <i className="fas fa-arrow-down font-22"></i>
                                      </Button>
                                  </Tooltip>
                              </div>
                          }
                      </div>
                  ),
              };
          })
        : [];

    const columns = [
        {
            Header: "PMA#",
            accessor: "pma_id",
            minWidth: 60,
        },
        {
            Header: "Date",
            accessor: "created_date",
            minWidth: 100,
        },
        {
            Header: "Action",
            accessor: "action",
        },
        {
            Header: "Property",
            accessor: "property",
        },
        {
            Header: "Signed",
            accessor: "signers",
        },
        {
            Header: "User",
            accessor: "name",
        },
        {
            Header: "Actions",
            accessor: "actions",
        },
    ];

    return (
        <div>
            <h3 style={{ display: "inline-block" }} className="mr-20">
                History
            </h3>
            <span>
                <i
                    className="fas fa-sync-alt hoverable"
                    onClick={() => {
                        reload();
                    }}
                ></i>
            </span>
            {props.loading && <Loader />}
            {!props.loading && (
                <div className="table-adjust23">
                    <ReactTable
                        filterable
                        className="-striped"
                        showPaginationTop
                        showPaginationBottom
                        resizable={true}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 20, 30]}
                        data={data}
                        columns={columns}
                        sorted={[
                            {
                                id: "id",
                                desc: true,
                            },
                        ]}
                    />
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        historyData: state.pma.historyData,
        pdfHistoryData: state.pma.pdfHistoryData,
        error: state.pma.error,
        success: state.pma.success,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPmaHistory: (type) => {
            dispatch(actions.getPmaHistory(type));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PMAHistory);
