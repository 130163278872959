import React, { useEffect, useState, useRef } from 'react';
import { clone } from 'shared/utility';
import { formatDateForBackend } from "shared/utility";

export const useSaveListing = (props) => {
    const { madeChanges, updateListing, data, setMadeChanges } = props;

    const TIME = 300000;

    const cleanData = (data) => {
        const newListingData = { ...data };
        ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
            if (newListingData[d]) {
                newListingData[d] = formatDateForBackend(newListingData[d]);
            } else {
                delete newListingData[d];
            }
        });
        if (newListingData.other_data) {
            newListingData.other_data = JSON.stringify(newListingData.other_data);
        }
        if (newListingData.extension_fee === '0') {
            newListingData.extension_fee_amount = ' ';
        }
        return newListingData;
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (madeChanges) {
                updateListing(cleanData(data));
                setMadeChanges(false);
            }
        }, TIME);
        return () => clearInterval(interval);
    }, [madeChanges, data]);
}