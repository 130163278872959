import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import { useEffect, useState } from "react";
import ClipLoader from "react-spinners/ClipLoader";

import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import { Dialog, DialogTitle } from "@material-ui/core";
import PropertiesForm from "../CRM/Properties/PropertiesForm";
import { clone } from "shared/utility";
import { isEmpty } from "lodash";

// const CONTACT_TYPES = {
// 	'Owner': 'OWN',
// 	'Buyer': 'OTHER',
// 	'Tenant': 'OTHER',
// 	'Vendor': 'OTHER',
// 	'Property Manager': 'OTHER',
// 	'Real Estate Agent': 'REA',
// 	'Real Estate Broker': 'OTHER',
// 	'Other': 'OTHER'
// };

const DialPadMergeProperty = (props) => {
    const {
        addPropertyToContact,
        checkPropertyExistingAddress,
        clearContactNames,
        contactData,
        contactId,
        createProperty,
        getContact,
        getProperties,
        getPropertyUseType,
        getPropertyUsernames,
        propertyOptions,
        loading,
        propertyToUpdate,
        setPropertyToUpdate,
        handleSkip,
		setProperty,
        goTo,
    } = props;

    const [searchedValue, setSearchedValue] = useState("");
    const [options, setOptions] = useState([]);

    const [openedNewProperty, setOpenedNewProperty] = useState(false);

    const [success, setSuccess] = useState("");
    const [error, setError] = useState("");

    const [tab, setTab] = useState("Main");

    const clearMessages = () => {
        setSuccess("");
        setError("");
    };


    useEffect(() => {
        if (searchedValue && searchedValue.length > 1) {
            getProperties({
                name: searchedValue,
            });
        }
    }, [searchedValue]);

    return (
        <React.Fragment>
            {isEmpty(propertyToUpdate) && (
                <div>
                    <Button color="primary" onClick={handleSkip}>
                        SKIP
                    </Button>
                </div>
            )}

            <Button
                className="outlined-btn"
                size="sm"
                onClick={() => {
                    setOpenedNewProperty(true);
                }}
            >
                <i className="fa fa-plus"></i> CREATE NEW PROPERTY
            </Button>

            <h3 style={{ textAlign: "center" }}>Select Property</h3>

            <CustomInput
                label="Search Properties"
                value={searchedValue}
                onChange={(e) => setSearchedValue(e.target.value)}
            />

            <div style={{ textAlign: "center", padding: 20 }}>
                {options && (
                    <div
                        style={{
                            height: !searchedValue ? 100 : 300,
                            overflowY: "scroll",
                            padding: 10,
                            backgroundColor: "#f1f1f1",
                        }}
                    >
                        {loading && (
                            <div className="text-center">
                                <ClipLoader />
                            </div>
                        )}
                        {!searchedValue && (
                            <div style={{ marginTop: 20 }}>
                                <em>
                                    Enter letters or numbers from the property's name
                                    <br />
                                    you are searching for in the line above
                                </em>
                            </div>
                        )}

                        {!loading && searchedValue && (
                            <List component="nav">
                                {propertyOptions.data &&
                                    propertyOptions.data.map((prop, i) => {
                                        return (
                                            <ListItem
                                                button
                                                key={`property-option-${i}`}
                                                onClick={() => {
                                                    setProperty(prop);
                                                    goTo(5);
                                                }}
                                            >
                                                <ListItemText inset primary={prop.street1} />
                                                {/* <Button onClick={() => openAskModal(prop)} size='sm'>
													Assign contact
												</Button> */}
                                            </ListItem>
                                        );
                                    })}
                            </List>
                        )}
                    </div>
                )}
            </div>

            <Dialog open={openedNewProperty} keepMounted maxWidth={false} fullWidth={true} fullScreen={true}>
                <div className="dialog-wrapper">
                    <PropertiesForm
                        action="Add"
                        checkPropertyExistingAddress={checkPropertyExistingAddress}
                        closeDialog={() => {
                            setOpenedNewProperty(false);
                        }}
                        closeForm={() => setOpenedNewProperty(false)}
                        createProperty={createProperty}
                        clearContactNames={clearContactNames}
                        clearMessages={clearMessages}
                        error={error}
                        getPropertyUseType={getPropertyUseType}
                        getPropertyUsernames={getPropertyUsernames}
                        setTab={setTab}
                        success={success}
                        tab={tab}
                    />
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default DialPadMergeProperty;
