import * as actionTypes from './actionTypes';

// GET_TR_TEMPLATES 

export const getTrTemplates = (data) => {
    return {
        type: actionTypes.GET_TR_TEMPLATES,
        data
    }
}

export const getTrTemplatesStart = () => {
    return {
        type: actionTypes.GET_TR_TEMPLATES_START
    }
}

export const getTrTemplatesSuccess = (response) => {
    return {
        type: actionTypes.GET_TR_TEMPLATES_SUCCESS,
        response
    }
}

export const getTrTemplatesFail = (err) => {
    return {
        type: actionTypes.GET_TR_TEMPLATES_FAIL,
        err
    }
}

// CREATE_TR_TEMPLATES 

export const createTrTemplate = (data) => {
    return {
        type: actionTypes.CREATE_TR_TEMPLATE,
        data
    }
}

export const createTrTemplateStart = () => {
    return {
        type: actionTypes.CREATE_TR_TEMPLATE_START
    }
}

export const createTrTemplateSuccess = (response) => {
    return {
        type: actionTypes.CREATE_TR_TEMPLATE_SUCCESS,
        response
    }
}

export const createTrTemplateFail = (err) => {
    return {
        type: actionTypes.CREATE_TR_TEMPLATE_FAIL,
        err
    }
}

// UPDATE_TR_TEMPLATE 

export const updateTrTemplate = (data) => {
    return {
        type: actionTypes.UPDATE_TR_TEMPLATE,
        data
    }
}

export const updateTrTemplateStart = () => {
    return {
        type: actionTypes.UPDATE_TR_TEMPLATE_START
    }
}

export const updateTrTemplateSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_TR_TEMPLATE_SUCCESS,
        response
    }
}

export const updateTrTemplateFail = (err) => {
    return {
        type: actionTypes.UPDATE_TR_TEMPLATE_FAIL,
        err
    }
}

// DELETE_TR_TEMPLATE 

export const deleteTrTemplate = (data) => {
    return {
        type: actionTypes.DELETE_TR_TEMPLATE,
        data
    }
}

export const deleteTrTemplateStart = () => {
    return {
        type: actionTypes.DELETE_TR_TEMPLATE_START
    }
}

export const deleteTrTemplateSuccess = (response) => {
    return {
        type: actionTypes.DELETE_TR_TEMPLATE_SUCCESS,
        response
    }
}

export const deleteTrTemplateFail = (err) => {
    return {
        type: actionTypes.DELETE_TR_TEMPLATE_FAIL,
        err
    }
}