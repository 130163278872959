import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.jsx";
import ERTLPdfModal from "./ERTLPdfModal";
import ERTLDisplayForm from "../ERTLSharedComponents/ERTLDisplayForm";
import { checkERTLemails, checkERTLrequirements, generateHTML } from "./ERTLLogic";
import { isValidEmail } from "shared/utility";
import { Dialog, IconButton } from "@material-ui/core";
import { clone } from "shared/utility";
import { checkSectionCompleteness } from "./ERTLLogic";
import { cloneJ } from "../../../shared/utility";
import { TextField } from "@material-ui/core";
import moment from "moment";
import { getUser } from "../../../shared/authValidation";
import Table from "components/Table/Table.jsx";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import Loader from "../../../components/Loader/Loader";
import { get } from "lodash";

const ERTLFullForm = (props) => {
    const {
        currentId,
        editedObj,
        setEditedObj,
        templateText,
        templatesData,
        setTemplateText,
        ertlFormData,
        setErtlFormData,
        templateEdited,
        setTemplateEdited,
        selectedTemplate,
        setSelectedTemplate,
        hideSelectTemplate,

        success_update,
    } = props;
    const [localPdfData, setLocalPdfData] = useState(null);
    const [startDownloadSignedPdf, setStartDownloadSignedPdf] = useState(false);
    const [localTabsData, setLocalTabsData] = useState(null);
    const [localHTMLData, setLocalHTMLData] = useState(null);
    const [alertMsg, setAlertMsg] = useState("");
    const [openPDFview, setOpenPDFview] = useState(false);
    const [templateOriginal, setTemplateOriginal] = useState({}); // for BEFORE comparisons
    const [startUpdate, setStartUpdate] = useState(false);
    const [notes, setNotes] = useState('');
    const [deleteNotes, setDeleteNotes] = useState(null);

    useEffect(() => {
        console.log('starting update', startUpdate, success_update);
        if (startUpdate && success_update) {
            if (success_update === 'Success') {
                props.close();
            }
            setStartUpdate(false);
        }
    }, [success_update, startUpdate]);

    useEffect(() => {
        if (props.saveDraft) {
            // save draft
            saveDraft();
            props.setSaveDraft(false);
        }
    }, [props.saveDraft]);

    useEffect(() => {
        console.log('figuring out', templatesData, ertlFormData);
        if (templatesData) {
            if (ertlFormData) {
                if (ertlFormData.templateData) {
                    console.log('setting template data', ertlFormData)
                    setTemplateText(ertlFormData.templateData);
                    return;
                } else {
                    // get default
                    props.templatesData.forEach((td) => {
                        if (td.defaults === 1) {
                            if (td.data) {
                                const clonedTD = clone(td);
                                const cloned = clone(ertlFormData);
                                clonedTD.data.template_id = td.id;
                                cloned.templateData = clonedTD.data;
                                setErtlFormData(cloned);
                                setTemplateText(td.data);
                                if (td.id) setSelectedTemplate(td.id);
                            }
                        }
                    });
                }
            }
        }
    }, [templatesData, ertlFormData]);

    useEffect(() => {
        if (ertlFormData) {
            if (ertlFormData.templateData) {
                setTemplateOriginal(ertlFormData.templateData);
            }
        }
    }, [ertlFormData]);

    useEffect(() => {
        if (props.pdfData && typeof props.pdfData === "string") {
            const pdfData = JSON.parse(props.pdfData);

            setLocalPdfData(pdfData);
        }
    }, [props.pdfData]);

    useEffect(() => {
        if (props.pdfHistoryData) {
            if (startDownloadSignedPdf) {
                setLocalPdfData(props.pdfHistoryData);
            }
        }
    }, [props.pdfHistoryData]);

    const compareTemplates = (templateText, originalTemplate) => {
        let hasChanged = false;
        const keys = Object.keys(templateText);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            if (originalTemplate[key] && templateText[key]) {
                if (originalTemplate[key] !== templateText[key]) {
                    console.log('this key has changed', key, originalTemplate[key], templateText[key]);
                    hasChanged = true;
                    break;
                }
            }
        }
        return hasChanged;
    };

    const templatesChanged = compareTemplates(
        templateText,
        props.templateData ? props.templateData.data : {}
    );
    const current_id = props.ertlData ? props.ertlData.id : null;

    const saveDraft = () => {
        const newTemplate = templateEdited ? templateText : null;
        const ddata = clone(ertlFormData);
        ddata.templateData = templateText;
        if (props.ertlData) {
            if (props.ertlData.id) {
                const data = {
                    data: ddata,
                    status: 0,
                    id: props.ertlData.id,
                    template_id:
                        selectedTemplate !== -1 ? selectedTemplate : null,
                };

                props.updateEntry(data, newTemplate);
                props.setTab("Drafts");
                props.setParentTab("Drafts");
                return;
            }
        }

        props.createEntry(
            {
                data: ddata,
                status: 0,
                template_id: selectedTemplate !== -1 ? selectedTemplate : null,
            },
            newTemplate
        );
    };

    let templateOriginalFinal = clone(templateOriginal);
    if (ertlFormData.templateData && props.templatesData) {
        console.log('ERTLFullForm found template 1', templateOriginalFinal);
        const template = props.templatesData.find(td => td.id === ertlFormData.templateData.template_id);
        if (template) {
            console.log('ERTLFullForm found template 2', template);
            templateOriginalFinal = template.data;
        }
    }

    const submitERTLforOwnerApproval = () => {
        const errors = checkERTLrequirements(ertlFormData);

        if (errors.length > 0) {
            setAlertMsg(errors.join(", "));
            return;
        }

        const entry = generateHTML(
            ertlFormData,
            templateText
        );

        const newTemplate = templateEdited
            ? templateText
            : null;
        const ddata = clone(ertlFormData);
        ddata.templateData = templateText;
        if (props.ertlData) {
            if (props.ertlData.id) {
                // UPDATE EXISTING
                const data = {
                    data: ddata,
                    status: 1,
                    id: props.ertlData.id,
                    template_id:
                        selectedTemplate !== -1
                            ? selectedTemplate
                            : null,
                    html: entry.html,
                    tabs: entry.tabs,
                };

                return props.updateEntry(
                    data,
                    newTemplate
                );
            }
        }

        // CREATE NEW AND SUBMIT FOR SIGNING
        props.createEntry(
            {
                data: ddata,
                status: 1,
                template_id:
                    selectedTemplate !== -1
                        ? selectedTemplate
                        : null,
                html: entry.html,
                tabs: entry.tabs,
            },
            newTemplate
        );
        props.setTab("Drafts");
        props.setParentTab("Drafts");
    };

    const resubmitWithChanges = () => {
        const errors = checkERTLrequirements(ertlFormData);
        if (errors.length > 0) {
            setAlertMsg(errors.join(", "));
            return;
        }

        const entry = generateHTML(
            ertlFormData,
            templateText
        );

        const newTemplate = templateEdited
            ? templateText
            : null;
        const ddata = clone(ertlFormData);
        ddata.templateData = templateText;
        if (props.ertlData) {
            if (props.ertlData.id) {
                // UPDATE EXISTING
                const data = {
                    data: ddata,
                    status: 6,
                    id: props.ertlData.id,
                    envelope_id:
                        props.ertlData.envelope_id,
                    submitter_id:
                        props.ertlData.user_id,
                    template_id:
                        selectedTemplate !== -1
                            ? selectedTemplate
                            : null,
                    html: entry.html,
                    tabs: entry.tabs,
                };

                props.updateEntry(
                    data,
                    newTemplate
                );
                if (props.setWait) {
                    props.setWait(true);
                }
                props.setParentTab();
                return;
            }
        }
    };

    const resendToOwners = () => {
        setStartUpdate(true);
        const cloned = cloneJ(ertlFormData);
        if (cloned.owners_review) {
            // reset every owner back to review mode 
            cloned.owners_review.forEach(or => {
                or.reviewed = false;
            })
        }
        cloned.templateData = templateText;
        const updateObj = {
            id: props.ertlData.id,
            status: 1,
            data: JSON.stringify(cloned),
            resendReviewLink: true
        };
        props.updateErtlEntry(updateObj);
    }

    const approveAndSendForSignature = () => {
        setStartUpdate(true);
        const cloned = cloneJ(ertlFormData);
        if (cloned.owners_review) {
            // reset every owner back to review mode 
            cloned.owners_review.forEach(or => {
                or.reviewed = false;
            })
        }
        cloned.templateData = templateText;

        const entry = generateHTML(
            ertlFormData,
            templateText,
            current_id
        );
        const updateObj = {
            id: props.ertlData.id,
            status: 2,
            data: JSON.stringify(cloned),
            docusignObj: entry
        };
        props.updateErtlEntry(updateObj);
    };

    const sendToFinalApprover = () => {
        const errors = checkERTLrequirements(ertlFormData);
        if (errors.length > 0) {
            setAlertMsg(errors.join(", "));
            return;
        }

        const entry = generateHTML(
            ertlFormData,
            templateText
        );

        const newTemplate = templateEdited
            ? templateText
            : null;
        const ddata = clone(ertlFormData);
        ddata.templateData = templateText;
        if (props.ertlData) {
            if (props.ertlData.id) {
                // UPDATE EXISTING
                const data = {
                    data: ddata,
                    status: 3,
                    id: props.ertlData.id
                };

                props.updateEntry(
                    data,
                    newTemplate
                );
                setTimeout(() => {
                    props.close();
                }, 1000);
                return;
            }
        }
    }

    console.log('ERTLFullForm', props);

    const status = get(props, 'ertlData.status', null);

    return (
        <div
            style={{ padding: 20, backgroundColor: "#fafafa" }}
            className="parentable"
        >
            <div className="boxed">
                <TextField
                    style={{ width: "100%" }}
                    id="multiline-flexible-notes"
                    label="Notes"
                    multiline
                    rowsMax="4"
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value);
                    }}
                    margin="normal"
                />
                <Button
                    color="primary"
                    onClick={() => {
                        if (notes) {
                            const cloned = clone(ertlFormData);
                            if (!cloned.notes) {
                                cloned.notes = [];
                            }
                            if (!props.clientEmail) {
                                const user = getUser();
                                cloned.notes.push({
                                    date: moment().format('lll'),
                                    user: {
                                        id: user.id,
                                        name: user.name
                                    },
                                    notes
                                });
                            } else {
                                let name = '';
                                cloned.property_owners.forEach(po => {
                                    if (po.email === props.clientEmail) {
                                        cloned.notes.push({
                                            date: moment().format('lll'),
                                            user: {
                                                id: -1,
                                                name: `${po.first_name} ${po.last_name}`
                                            },
                                            notes
                                        });
                                    }
                                })
                            }
                            setErtlFormData(cloned);
                            setNotes('');
                        }
                    }}
                >ADD NOTES</Button>

                {ertlFormData.notes && <div>
                    {ertlFormData.notes.length > 0 && <Table
                        tableHeaderColor="primary"
                        tableHead={['Date','User','Notes','Action']}
                        tableData={ertlFormData.notes.map((n,i) => {
                            const user = getUser();
                            let action = null;
                            if (user && n.user) {
                                if (user.id === n.user.id) {
                                    action = <IconButton
                                        onClick={() => {
                                            setDeleteNotes(i);
                                        }}
                                    ><i className="fa fa-trash"></i></IconButton>
                                }
                            } else if (props.clientEmail) {
                                let name = '';
                                if (ertlFormData.property_owners) {
                                    if (ertlFormData.property_owners.length > 0) {
                                        ertlFormData.property_owners.forEach(po => {
                                            if (po.email === props.clientEmail) name = `${po.first_name} ${po.last_name}`;
                                        })
                                    }
                                }
                                if (name === n.user.name) {
                                    action = <IconButton
                                        onClick={() => {
                                            setDeleteNotes(i);
                                        }}
                                    ><i className="fa fa-trash"></i></IconButton>
                                }
                            }
                            return [
                                n.date,
                                n.user.name,
                                n.notes,
                                <div>
                                    {action}
                                </div>
                            ];
                        }).reverse()}
                        coloredColls={[1]}
                        colorsColls={["primary"]}
                    />}
                </div>}

                {deleteNotes !== null && <NotificationAsk 
                    open={true}
                    close={() => {
                        setDeleteNotes(null);
                    }}
                    success={() => {
                        const cloned = clone(ertlFormData);
                        cloned.notes = cloned.notes.filter((nn,ii) => {
                            if (ii === deleteNotes) return false;
                            return true;
                        })
                        setErtlFormData(cloned);
                        setDeleteNotes(null);
                    }}
                    success_button_text="Yes, Delete"
                    title="Are you sure?"
                    message="Do you want to delete this note?"
                />}
            </div>
            <hr />
            <ERTLDisplayForm
                currentId={current_id}
                editedObj={editedObj}
                setEditedObj={setEditedObj}
                templateText={templateText}
                setTemplateText={setTemplateText}
                ertlFormData={ertlFormData}
                setErtlFormData={setErtlFormData}
                templatesData={props.templatesData}
                setTemplateEdited={setTemplateEdited}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                is_approver={props.origin === "approver"}
                origin={props.origin}
                historyDiffData={props.historyDiffData}
                hideSelectTemplate={hideSelectTemplate}
                templateOriginal={templateOriginalFinal}
            />
            {props.origin !== "view" && (
                <div className="bottom-dweller">

                    {props.origin !== "approver" &&
                        props.origin !== "Agent Review" && (
                            <Button
                                color="white"
                                onClick={() => {
                                    saveDraft();
                                }}
                                style={{ marginTop: 10, marginRight: 20 }}
                            >
                                <i className="fas fa-save"></i> SAVE ERTL DRAFT
                            </Button>
                        )}


                    {props.origin !== "approver" &&
                        props.origin !== "Agent Review" && (
                            <Button
                                color="white"
                                onClick={submitERTLforOwnerApproval}
                                style={{ marginTop: 10, marginRight: 20 }}
                            >
                                SUBMIT ERTL FOR OWNER APPROVAL
                            </Button>
                        )}

                    {/* LEVEL 1 APPROVER  */}
                    {props.origin === "approver" &&
                        templatesChanged && (
                            <Button
                                color="success"
                                onClick={resubmitWithChanges}
                                style={{ marginTop: 10, marginRight: 20 }}
                            >
                                RE-SUBMIT WITH CHANGES
                            </Button>
                        )}

                    {props.origin === "approver" &&
                        templatesChanged && (
                            <Button
                                className="mr-20"
                                color="white"
                                onClick={() => {
                                    setTemplateText(props.templateData.data);
                                }}
                            >
                                DISCARD CHANGES / REVERT
                            </Button>
                        )}
                    {props.origin === "approver" &&
                        status === 3 &&
                        !templatesChanged && (
                            <Button
                                className="mr-20"
                                color="primary"
                                onClick={() => {
                                    props.signReview();
                                }}
                            >
                                SIGN OR DECLINE
                            </Button>
                        )}
                    {(props.origin === "approver" && status === 7) &&
                        (
                            <Button
                                className="mr-20"
                                color="primary"
                                onClick={sendToFinalApprover}
                            >
                                SEND TO FINAL APPROVER
                            </Button>
                        )}

                    {/* AGENT REVIEW WHERE OWNERS HAVE ALL SIGNED */}
                    {props.origin === "Agent Review" && (
                        <Button
                            disabled={props.loading_update ? true : false}
                            color="success"
                            onClick={approveAndSendForSignature}
                            style={{ marginTop: 10, marginRight: 20 }}
                        >
                            APPROVE AND SEND FOR SIGNATURE
                        </Button>
                    )}
                    {props.origin === "Agent Review" && (
                        <Button
                            disabled={props.loading_update ? true : false}
                            color="primary"
                            onClick={resendToOwners}
                            style={{ marginTop: 10, marginRight: 20 }}
                        >
                            RESEND TO OWNERS
                        </Button>
                    )}


                    {/* PREVIEW */}
                    {props.origin !== "approver" && (
                        <Button
                            color="white"
                            onClick={() => {
                                const entry = generateHTML(
                                    ertlFormData,
                                    templateText,
                                    current_id
                                );
                                setLocalTabsData(entry.tabs);
                                setLocalHTMLData(entry.html);
                                const obj = {
                                    id: 1,
                                    entry: entry.html,
                                    tabs: entry.tabs,
                                };

                                props.getEntryPdf(obj);
                                setOpenPDFview(true);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            VIEW PDF
                        </Button>
                    )}
                    {(props.origin === "approver" && props.historyData.length > 0) && (
                        <Button
                            color="white"
                            onClick={() => {
                                if (props.historyData) {
                                    if (props.historyData.length > 0) {
                                        setStartDownloadSignedPdf(true);
                                        props.getErtlPdf(props.historyData[props.historyData.length - 1].id);
                                        setOpenPDFview(true);
                                    }
                                }
                                
                            }}
                        >
                            VIEW SIGNED PDF
                        </Button>
                    )}


                    {startUpdate && <Loader center={true} />}
                </div>
            )}

            {localPdfData && openPDFview && (
                <ERTLPdfModal
                    pdfData={localPdfData}
                    tabsData={localTabsData}
                    htmlData={localHTMLData}
                    open={true}
                    close={() => {
                        setLocalPdfData(null);
                        setStartDownloadSignedPdf(false);
                        setOpenPDFview(false);
                    }}
                    ertlFormData={ertlFormData}
                    ertlEntryData={props.ertlEntryData}
                    ertlData={props.ertlData}
                    createEntry={props.createEntry}
                    updateEntry={props.updateEntry}
                    startDownloadSignedPdf={startDownloadSignedPdf}
                />
            )}

            {alertMsg && (
                <Dialog
                    open={alertMsg ? true : false}
                    onClose={() => {
                        setAlertMsg("");
                        // props.setTab('Drafts');
                        // props.setParentTab('Drafts');
                    }}
                >
                    <div style={{ padding: 40 }}>
                        <div className="text-center">{alertMsg}</div>
                        <div className="text-center mt-20">
                            <Button
                                color="primary"
                                onClick={() => {
                                    setAlertMsg("");
                                    // props.setTab('Drafts');
                                    // props.setParentTab('Drafts');
                                }}
                            >
                                OK
                            </Button>
                        </div>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default ERTLFullForm;
