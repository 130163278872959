import { useState, useEffect, useRef } from "react";
import React from "react";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import Button from "../../../components/CustomButtons/Button";
import Notification from "../../../components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import { get } from "lodash";
import Loader from "../../../components/Loader/Loader";
import { ClipLoader } from "react-spinners";

// REQUIRED PROPS
// --------------
// update - function
// remove - function
// field - string field name
// rs_filename <optional>

const FileUploader = (props) => {
    const [file, setFile] = useState(null);
    const [fileUpload, setFileUpload] = useState(false);
    const [notification, setNotification] = useState('');
    const [ask, setAsk] = useState(false);

    const handleChange = (event) => {
        setFile(event.target.files[0])
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevFileUploaded = usePrevious(props.file_uploaded);
    const prevError = usePrevious(props.error_upload);

    useEffect(() => {
        if (prevFileUploaded === null && props.file_uploaded) {
            if (props.update && fileUpload) {
                props.update(`${props.file_uploaded},${props.date_file_uploaded}`);
                setFileUpload(false);
                setFile(null);
            }
        }
    }, [props.file_uploaded]);

    useEffect(() => {
        if (prevError === null && props.error_upload) {
            setNotification('Something went wrong uploading the file, please contact tech support if it persists');
        }
    }, [props.error_upload]);

    let rs_file = '';
    let rs_id = '';
    let rs_date = '';
    const rs_filename = get(props, 'rs_filename', null);
    if (rs_filename) {
        const rs_arr = rs_filename.split(',')
        if (rs_arr.length > 1) {
            rs_file = rs_arr[1];
            rs_id = rs_arr[0];
            rs_date = rs_arr[2] ? rs_arr[2] : '';
        }
    }
    
    if (props.loading) return <div style={{margin:10}}><ClipLoader /></div>;

    return <div style={{position:'relative'}}>
        {(!rs_file && props.update) && <div>
            <div style={{marginBottom:10}}><input 
                type="file"
                onChange={handleChange}
                style={{width:'100%'}}
            /></div>
            {file && <Button size={props.button_size ? 'lg' : 'sm'} color="primary" className="mr-20" onClick={() => {
                setFileUpload(true);
                props.createFileUpload({
                    type: 'rs',
                    file
                });
            }}>UPLOAD</Button>}
            {props.close && <Button color="white" onClick={() => {
                props.close();
            }}>CANCEL</Button>}
        </div>}
        {rs_file && <div>
        {props.remove && <div style={{position:'absolute',top:'-20px',right:5}}><i className="fa fa-trash red-text hoverable" style={{float:'right'}} onClick={() => {
            setAsk(true);
        }}></i></div>}

        {!props.hide_filename && <div style={{overflow:'hidden'}}><em>{rs_file}</em></div>}
        {(!props.hide_date && rs_date) && <div style={{overflow:'hidden'}}><em><small>Uploaded {rs_date}</small></em></div>}

        <Button size={props.button_size ? 'lg' : 'sm'} color="success" onClick={() => {
            props.getFileUpload({ id:rs_id, type: 'rs' });
        }}>DOWNLOAD</Button>
        </div>}

        {notification && <Notification 
            open={notification ? true : false}
            close={() => { setNotification(''); }}
            message={notification}
        />}

        {ask && <NotificationAsk 
            open={ask}
            close={() => { setAsk(false) }}
            title="Are you sure?"
            message={`Do you want to remove this file "${rs_file}" ?`}
            success={() => {
                props.remove();
                setAsk(false);
            }}
        />}
    </div>
}

const mapStateToProps = state => {
    return {
        loading: state.files.loading,
        error_upload: state.files.error_upload,
        success_upload: state.files.success_upload,
        file_uploaded: state.files.file_uploaded,
        date_file_uploaded: state.files.date_file_uploaded
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFileUpload: (data) => {
            dispatch(actions.createFileUpload(data));
        },
        getFileUpload: (data) => {
            dispatch(actions.getFileUpload(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);