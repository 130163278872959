import React, { useState } from 'react';
import { get, isEqual } from 'lodash';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip } from '@material-ui/core';
import { clone } from 'shared/utility';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInputValidate from 'components/CustomInput/CustomInputValidate.jsx';
import NotificationAsk from 'components/Notification/NotificationAsk';

const retrieveDbaData = (dba_data, dba_id) => {
    let dbaObj = null;
    if (dba_data && dba_id) {
        dba_data.forEach(dd => {
            if (dd.id === dba_id) dbaObj = dd;
        })
    }
    return dbaObj;
}

const AddressCompanyItem = (props) => {
	console.log('AddressCompanyItem', props);
	const {
		entity,
		canEdit,
		setOpenedEditCompany,
		setCompanyID,
		action,
		entitiesData,
		setEntitiesData,
		deleteContactEntity,
		deleteCompanyEntity,
		fromType,
	} = props;
	const [openedAskModal, setOpenedAskModal] = useState(false);

	const companyData = entity.company_data;
	const dbaData = entity.dba_data;
	const dba_id = entity.dba_id;
	const dbaObj = retrieveDbaData(dbaData, dba_id);
	const dbaName = dbaObj ? ` dba ${dbaObj.name} ${!dbaObj.active ? '(removed)' : ''}` : '';

	const companyName = get(companyData, 'name', '') + dbaName;
	const address1 = get(companyData, 'mailing_address1', '');
	const city = get(companyData, 'mailing_city', '');
	const zip = get(companyData, 'mailing_zip', '');
	const state = get(companyData, 'mailing_state', '');
	const address2 = get(companyData, 'mailing_address2', '');
	const phone = get(companyData, 'main_phone', '');
	const otherPhone = get(companyData, 'other_phone', '');
	const fax = get(companyData, 'fax', '');
	const email = get(companyData, 'email', '');

	const handleDelete = () => {
		if (action === 'Edit') {
			deleteContactEntity(entity.id);
			if (fromType === 'COMPANY') {
				deleteCompanyEntity(entity.id);
			} else if (fromType === 'CONTACT') {
				deleteContactEntity(entity.id);
			}
		} else if (action === 'Add') {
			let newEntities = clone(entitiesData);
			newEntities = newEntities.filter((e) => !isEqual(e, entity));
			setEntitiesData(newEntities);
		}
	};

	return (
		<div style={{ fontSize: 16 }} className='boxed'>
			{canEdit && (
				<div style={{ float: 'right' }}>
					<Tooltip title="Open Company">
						<IconButton
							onClick={() => {
								setOpenedEditCompany(true);
								setCompanyID(entity.entity_id);
							}}
						>
							<i className='fas fa-user-plus'></i>
						</IconButton>
					</Tooltip>
					<Tooltip title="Delete">
						<IconButton
							onClick={() => {
								setOpenedAskModal(true);
							}}
						>
							<i className='fas fa-trash-alt red-text'></i>
						</IconButton>
					</Tooltip>
				</div>
			)}
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
                    <h4>Company</h4>
                </GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<span
						className='hoverable2'
						onClick={() => {
							setOpenedEditCompany(true);
							setCompanyID(entity.entity_id);
						}}
					>
						<CustomInputValidate
							label='Company Name'
							value={companyName}
							readonly
						/>
					</span>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}></GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate label='Address 1' value={address1} readonly />
				</GridItem>
				<GridItem xs={12} sm={2} md={2}>
					<CustomInputValidate label='City' value={city} readonly />
				</GridItem>
				<GridItem xs={12} sm={2} md={2}>
					<CustomInputValidate label='State' value={state} readonly />
				</GridItem>
				<GridItem xs={12} sm={2} md={2}>
					<CustomInputValidate label='Zip' value={zip} readonly />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate label='Address 2' value={address2} readonly />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate label='Phone Number' value={phone} readonly />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate
						label='Other Number'
						value={otherPhone}
						readonly
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate label='Fax' value={fax} readonly />
				</GridItem>
				<GridItem xs={12} sm={4} md={4}>
					<CustomInputValidate label='Email' value={email} readonly />
				</GridItem>
			</GridContainer>

			{openedAskModal && (
				<NotificationAsk
					open={openedAskModal}
					title='Remove Company'
					message='Are you sure you want to remove this company?'
					success_button_text='REMOVE'
					close={() => setOpenedAskModal(false)}
					success={handleDelete}
				/>
			)}
		</div>
	);
};

export default AddressCompanyItem;
