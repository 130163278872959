import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import defaultTemplate from './TRTemplatesModel';
import Button from "components/CustomButtons/Button";
import { Dialog, IconButton } from "@material-ui/core";
import { clone } from "shared/utility";
import { ArrowBack, Close } from "@material-ui/icons";
import { get } from "lodash";
import { cloneJ } from "../../../shared/utility";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import NotificationFixedTop from "../../../components/Notification/NotificationFixedTop";
import { checkForChanges } from "../../../shared/utility";
import { TRBaseTemplate } from "../TRBaseTemplate";
import { defaultTRData } from "../Entries/TREntriesModel";
import CloseComponent from "../../../components/Close/Close";
import { TextField } from "@material-ui/core";

const TRTemplatesForm = props => {
    const [data, setData] = useState(defaultTemplate);
    const [initialData, setInitialData] = useState(defaultTemplate);
    const [notificationTop, setNotificationTop] = useState('');
    const [saving, setSaving] = useState(false);
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [close, setClose] = useState(false);
    const [openedField, setOpenedField] = useState(null);
    const [openedTextForm, setOpenedTextForm] = useState(false);

    const renderElement = (field, label = '') => {
        const fieldArr = field.split('.');
        if (fieldArr.length > 1) {
            const type = fieldArr[0];
            const fieldy = fieldArr[1];
            return <CustomInput 
                label={label}
                value={data[type][fieldy]}
                onChange={(e) => {
                    const c = clone(data);
                    c[type][fieldy] = e.target.value;
                    setData(c);
                }}
            />
        } else {
            const fieldy = fieldArr[0];
            return <CustomInput 
                label={label}
                value={data[fieldy]}
                onChange={(e) => {
                    const c = clone(data);
                    c[fieldy] = e.target.value;
                    setData(c);
                }}
            />
        }
    }

    const cancel = () => {
        // check if equal
        if (checkForChanges(initialData, data)) {
            setNotificationAsk(true);
            return;
        }
        props.close();
    }

    useEffect(() => {
        if (props.formData) {
            setData(props.formData);
            setInitialData(cloneJ(props.formData));
        }
    }, [props.formData]);

    useEffect(() => {
        if (saving) {
            if (props.success_create || props.success_update) {
                setNotificationTop('Template Saved');
                setSaving(false);
                if (close) props.close();
            }
        }
    }, [saving, props.templateData, props.success_create, props.success_update]);

    const save = (shouldClose) => {
        if (!data.title) {
            return setNotificationTop('Title is required');
        }
        const id = get(props, 'entryData.id', null);
        if (id) {
            if (shouldClose) setClose(true)
            setSaving(true);
            props.updateTrTemplate({
                id,
                data: JSON.stringify(data),
                title: data.title
            })
        } else {
            if (shouldClose) setClose(true)
            setSaving(true);
            props.createTrTemplate({
                data: JSON.stringify(data),
                title: data.title
            })
        }
    }

    console.log('Form', props);

    return <div>
    <Dialog
        open={true}
        keepMounted
        maxWidth={false}
        fullWidth={true}
        fullScreen={true}
    >
        <div className="dialog-wrapper pb-200">
            <div
                className="close-btn"
                onClick={() => {
                    cancel();
                }}
            >
                <Close />
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <IconButton
                        onClick={() => {
                            cancel();
                        }}
                    >
                        <ArrowBack />
                    </IconButton>
                    <br /><br />
                    <Button
                        color="primary"
                        onClick={() => {
                            save();
                        }}
                    >SAVE</Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div style={{marginBottom:20,marginTop:20}}>
                        <strong>
                            {renderElement('title', 'Title')}
                        </strong>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>

            {<TRBaseTemplate 
                data={defaultTRData}
                templateData={data}
                templateText={defaultTemplate} // before template data (for comparison)
                action="Edit"
                openModal={(field) => {
                    setOpenedField(field);
                    setOpenedTextForm(true);
                }}
            />}
        </div>

        {notificationAsk && <NotificationAsk 
            title="Are you sure?"
            message="Any unsaved progress will be lost."
            open={notificationAsk}
            close={() => {
                setNotificationAsk(false);
            }}
            success={() => {
                setClose(true);
                save(true);
            }}
            success_button_text="SAVE & CLOSE"
            success2={() => {
                props.close();
            }}
            success2_button_text="CLOSE"
        />}

        {notificationTop && <NotificationFixedTop 
            message={notificationTop}
            open={notificationTop ? true : false}
            close={() => {
                setNotificationTop('');
            }}
            autoClose={3000}
        />}

        {openedTextForm && (
				<Dialog
					open={openedTextForm}
					onClose={() => {
						setOpenedTextForm(false);
					}}
					maxWidth='md'
					fullWidth={true}
				>
					<div className='dialog-wrapper'>
						<CloseComponent
							close={() => {
								setOpenedTextForm(false);
							}}
						/>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<TextField
									label=''
									multiline
									rowsMax='4'
									value={data[openedField]}
									onChange={(e) => {
										const newTemplateData = clone(data);
										newTemplateData[openedField] = e.target.value;
										setData(newTemplateData);
									}}
									style={{ width: '100%' }}
									margin='normal'
								/>
								<div>
								</div>
								<div className='mr-20'>
									<Button
										color='primary'
										onClick={() => {
											setOpenedTextForm(false, data);
										}}
									>
										OK
									</Button>
								</div>
							</GridItem>
						</GridContainer>
					</div>
				</Dialog>
			)}
    </Dialog>
    </div>
}

const mapStateToProps = state => {
    const tr = state.tr_templates;
	return {
        templateData: tr.templateData,
        success_create: tr.success_create,
        error_create: tr.error_create,
        success_delete: tr.success_delete,
        error_delete: tr.error_delete,
        success_update: tr.success_update,
        error_update: tr.error_update,
	}
}

const mapDispatchToProps = dispatch => {
    return {
        createTrTemplate: (data) => {
            dispatch(actions.createTrTemplate(data));
        },
        updateTrTemplate: (data) => {
            dispatch(actions.updateTrTemplate(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TRTemplatesForm);