import React, { useEffect, useState, useRef } from 'react';
import { clone } from 'shared/utility';
import { formatDateForBackend } from "shared/utility";

export const useSaveOffer = (props) => {
    const { madeChanges, updateOffer, data, setMadeChanges } = props;

    const TIME = 300000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (madeChanges) {
                updateOffer(data);
                setMadeChanges(false);
            }
        }, TIME);
        return () => clearInterval(interval);
    }, [madeChanges, data]);
}