import React from "react";
import PropTypes from "prop-types";
import { Redirect, Link } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import dashboardStyle from "assets/jss/material-dashboard-pro-react/views/dashboardStyle";
import illi_image from "assets/img/illicrmimage.jpg";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { Tooltip } from "@material-ui/core";
import { Group, Help } from "@material-ui/icons";
const allowBPHistory = false;

class Dashboard extends React.Component {
  state = {
    value: 0,
    isLoggedIn: true
  };
  handleChange = (event, value) => {
    this.setState({ value });
  };
  handleChangeIndex = index => {
    this.setState({ value: index });
  };

  componentDidMount() {
    const token = localStorage.getItem('token');
    
    if (token) {
      this.setState({isLoggedIn:true});
    } else {
      this.setState({isLoggedIn:false});
    }
  }

  render() {
    const { classes } = this.props;
    if (!this.state.isLoggedIn) {
      return <Redirect to="/pages/login-page" />;
    }
    const titleCSS = {
      marginBottom: 20,
      textAlign: 'center',
      color: 'white',
      background: 'linear-gradient(60deg, #da3a2f, #da3a2f)',
      boxShadow: '0 12px 20px -10px rgba(71, 161, 218, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(71, 161, 218, 0.2)',
    }
    return (
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <div style={titleCSS}><strong><h2 style={{paddingTop:'30px',paddingBottom:'30px',color:'white'}}>Welcome to illi Portal v1.0</h2></strong></div>
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {<Card product className={classes.cardHover}>
              <CardHeader image className={classes.cardHeaderHover}>
                <Link to="/crm/contacts?p=new">
                  <img src={illi_image} alt="..." />
                </Link>
              </CardHeader>
              <CardBody>
                <div className={classes.cardHoverUnder}>
                  <Tooltip
                    id="tooltip-top"
                    title="Properties"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/crm/properties">
                      <Button color="success" simple justIcon>
                        <i className="fas fa-building"></i>
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Leases"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/crm/leases">
                      <Button color="warning" simple justIcon>
                        <i className="fas fa-credit-card"></i>
                      </Button>
                    </Link>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Listings"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/crm/listings">
                      {<Button color="transparent" simple justIcon>
                        <i className="fas fa-clipboard-list"></i> 
                      </Button>}
                    </Link>
                  </Tooltip>
                  <Tooltip
                    id="tooltip-top"
                    title="Meetings"
                    placement="bottom"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <Link to="/crm/meetings">
                      <Button color="transparent" simple justIcon>
                        <Group className={classes.underChartIcons} />
                      </Button>
                    </Link>
                  </Tooltip>
                </div>
                <h4 className={classes.cardProductTitle}>
                  <Link to="/BP?p=new">
                    Enter New Contact
                  </Link>
                </h4>
                <p className={classes.cardProductDesciprion}>
                  Enter a new contact into the CRM
                </p>
              </CardBody>
              <CardFooter product>
                <div className={classes.price}>
                  <h4></h4>
                </div>
                <div className={`${classes.stats} ${classes.productStats}`}>
                    <Help />
                  <a href="mailto:" target="_top">
                    Get Help with This
                  </a>
                </div>
              </CardFooter>
            </Card>}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(dashboardStyle)(Dashboard);
