import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import OnboardingPendingList from "containers/Onboarding/PendingList";
import OnboardingOverview from 'containers/Onboarding/OnboardingOverview';
import OnboardingNTEFullForm from '../../containers/Onboarding/NoticeToEmployee';

import * as actions from '../../store/actions';
class OnboardingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'Pending',
      currentEntry: null,
      success: '',
      error: '',
      topPage: null,
    }
  }

  setTab = (tab) => {
    this.setState({ tab });
  }

  setCurrentEntry = (currEntry) => {
    this.setState(currEntry);
  }

  componentDidUpdate(prevProps, prevState) {
    
    if (prevProps.success !== this.props.success) {
      if (!prevProps.success) {
        this.setState({ success: this.props.success });
      }
    }
    if (prevProps.error !== this.props.error) {
      if (!prevProps.error) {
        this.setState({ error: this.props.error });
      }
    }
  }

  componentDidMount() {
    this.props.getOnboardings();
  }

  render() {
    
    const obPermitted = checkPermissions('Onboarding');
    if (!obPermitted) {
      return <Redirect to="/dashboard" />;
    }

    const permissions = checkPermissionsActions('Onboarding');
    

    const { tab } = this.state;

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                {permissions.submit && <CardIcon
                  color={tab === 'Pending' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Pending');
                  }}
                  className="hovered"
                >
                  <strong>Pending</strong>
                </CardIcon>}
              </CardHeader>
              <CardBody>
                {tab === 'Pending' && <OnboardingPendingList
                  entriesData={this.props.entriesData}
                  setTab={this.setTab}
                  setCurrentEntry={this.setCurrentEntry}
                />}
                {tab === 'Onboarding' && <OnboardingOverview
                  currentEntry={this.state.currentEntry}
                  setTab={this.setTab}
                  setCurrentEntry={this.setCurrentEntry}
                  pdfData={this.props.pdfData}
                  getObPdf={this.props.getObPdf}
                  createNteEntry={this.props.createNteEntry}
                  updateOnboardingEntry={this.props.updateOnboardingEntry}
                  getObWorkspaceUser={this.props.getObWorkspaceUser}
                  userData={this.props.userData}
                />}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.ob.error,
    success: state.ob.success,
    entriesData: state.ob.entriesData,
    pdfData: state.ob.pdfData,
    userData: state.ob.userData,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getObWorkspaceUser: (data) => dispatch(actions.getObWorkspaceUser(data)),
    getUser: (id) => dispatch(actions.getUser(id)),
    getOnboardings: () => {dispatch(actions.getOnboardings());},
    getObPdf: (data) => { dispatch(actions.getObPdf(data)); },
    createNteEntry: (data) => { dispatch(actions.createNteEntry(data)); },
    updateOnboardingEntry: (data) => { dispatch(actions.updateOnboardingEntry(data)); },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);
