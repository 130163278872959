import React from 'react';

const SortButton = (props) => {
	let handleClick = (direction) => {
		props.setSort(props.sortBy, direction);
	};

	const colorDown = props.sortBy === props.currentSortBy ? (props.sortDirection === 'DESC' ? 'black' : '#ccc') : '#ccc';
	const colorUp = props.sortBy === props.currentSortBy ? (props.sortDirection === 'ASC' ? 'black' : '#ccc') : '#ccc';

	const styleDown = {
		position: 'absolute',
		top: -30,
		left: 19,
		fontSize: 22,
		color: colorDown
	};

	const styleUp = {
		position: 'absolute',
		top: -20,
		left: 5,
		fontSize: 22,
		color: colorUp
	};

	

	return (
		<div className='parentable' style={{display: 'inline-block'}}>
			<span className='hoverable' onClick={() => handleClick('DESC')} style={styleDown}>
				<i className='fas fa-sort-down'></i>
			</span>
			<span className='hoverable' onClick={() => handleClick('ASC')} style={styleUp}>
				<i className='fas fa-sort-up'></i>
			</span>
		</div>
	);
};

export default SortButton;
