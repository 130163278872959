import React, { useState, useEffect } from "react";
import IconButton from "@material-ui/core/IconButton";
import { Close, Edit, Business, Contacts } from "@material-ui/icons";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { get, isEmpty } from "lodash";

import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomInputStates from "components/CustomInput/CustomInputStates.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomDialogCompanyView from "components/CustomDialog/CustomDialogCompanyView";

import ContactsFormPartners from "./ContactsFormPartners";
import CompaniesEditContact from "../Companies/CompaniesEditContact";

import AddressContactItem from "../shared/AddressContactItem";
import AddressCompanyItem from "../shared/AddressCompanyItem";

import { exists, clone } from "shared/utility";

import {
    email,
    min1,
    min5,
    min6,
    min10,
    max15,
    max50,
    max100,
    no_letters,
    no_numbers,
    no_special_chars,
} from "shared/validation";

const validate = {
    email,
    min1,
    min5,
    min6,
    min10,
    max15,
    max50,
    max100,
    no_letters,
    no_numbers,
    no_special_chars,
};

export const CONTACT_ENTITY_TYPE = {
    ACCOUNTANT: "A",
    LEGAL_INFO: "L",
    PROPERTY_MANAGEMENT: "P",
};

const AddressFields = (props) => {
    const { addressData, addType, updateAddress, addressIndex, addressFields } = props;
    const { types } = props.addressFields;

    const getValidations = (f) => {
        return addressFields.fields[f].validations.map((v) => validate[v]);
    };

    const hasField = (fieldName) => {
        if (types[addType] && types[addType].fields.indexOf(fieldName) !== -1) return true;
        return false;
    };

    return (
        <GridContainer>
            {hasField("company_name") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Company Name"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].company_name
                                : addressData[addType][addressIndex].company_name
                        }
                        onChange={(e) => {
                            updateAddress("company_name", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("company_name")}
                    />
                </GridItem>
            )}
            {hasField("name") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Name"
                        value={
                            !exists(addressIndex) ? addressData[addType].name : addressData[addType][addressIndex].name
                        }
                        onChange={(e) => {
                            updateAddress("name", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("name")}
                    />
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}></GridItem>
            {hasField("street1") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Address 1"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].street1
                                : addressData[addType][addressIndex].street1
                        }
                        onChange={(e) => {
                            updateAddress("street1", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("street1")}
                    />
                </GridItem>
            )}
            {hasField("city") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate
                        label="City"
                        value={
                            !exists(addressIndex) ? addressData[addType].city : addressData[addType][addressIndex].city
                        }
                        onChange={(e) => {
                            updateAddress("city", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("city")}
                    />
                </GridItem>
            )}
            {hasField("state") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputStates
                        onChange={(value) => {
                            updateAddress("state", value, addType, addressIndex);
                        }}
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].state
                                : addressData[addType][addressIndex].state
                        }
                    />
                </GridItem>
            )}
            {hasField("zip") && (
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInputValidate
                        label="Zip"
                        value={
                            !exists(addressIndex) ? addressData[addType].zip : addressData[addType][addressIndex].zip
                        }
                        onChange={(e) => {
                            updateAddress("zip", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("zip")}
                    />
                </GridItem>
            )}
            {hasField("street2") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Address 2"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].street2
                                : addressData[addType][addressIndex].street2
                        }
                        onChange={(e) => {
                            updateAddress("street2", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("street2")}
                    />
                </GridItem>
            )}
            <GridItem xs={12} sm={12} md={12}></GridItem>
            {hasField("phone_number") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Phone Number"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].phone_number
                                : addressData[addType][0].phone_number
                        }
                        onChange={(e) => {
                            updateAddress("phone_number", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("phone_number")}
                    />
                </GridItem>
            )}
            {hasField("other_number") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Other Number"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].other_number
                                : addressData[addType][addressIndex].other_number
                        }
                        onChange={(e) => {
                            updateAddress("other_number", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("other_number")}
                    />
                </GridItem>
            )}
            {hasField("fax") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Fax"
                        value={
                            !exists(addressIndex) ? addressData[addType].fax : addressData[addType][addressIndex].fax
                        }
                        onChange={(e) => {
                            updateAddress("fax", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("fax")}
                    />
                </GridItem>
            )}
            {hasField("email") && (
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInputValidate
                        label="Email"
                        value={
                            !exists(addressIndex)
                                ? addressData[addType].email
                                : addressData[addType][addressIndex].email
                        }
                        onChange={(e) => {
                            updateAddress("email", e.target.value, addType, addressIndex);
                        }}
                        validate={getValidations("email")}
                    />
                </GridItem>
            )}
        </GridContainer>
    );
};

const ContactsFormAddresses = (props) => {
    const {
        addressData,
        updateAddress,
        addressFields,

        contact_entities,
        createContactEntity,
        deleteContactEntity,
        getContactEntities,
        originalContactData,
        entitiesData,
        setEntitiesData,

        getUpdatedContactEntity,
        updatedContactEntity,

        getUpdatedCompanyEntity,
        updatedCompanyEntity,
    } = props;

    const [openedContactModal, setOpenedContactModal] = useState(false);
    const [entityType, setEntityType] = useState(null);

    const [openedEditCompany, setOpenedEditCompany] = useState(false);
    const [companyID, setCompanyID] = useState(null);

    const [openedEditContact, setOpenedEditContact] = useState(false);
    const [contactID, setContactID] = useState(null);

    useEffect(() => {
        if (originalContactData && originalContactData.id) {
            getContactEntities(originalContactData.id);
        }
    }, [originalContactData]);

    useEffect(() => {
        if (!isEmpty(updatedCompanyEntity) && props.action === "Add") {
            const newEntities = clone(entitiesData);
            const indexToEdit = newEntities.findIndex((e) => get(e, "company_data.id") === updatedCompanyEntity.id);
            if (indexToEdit !== -1) {
                newEntities[indexToEdit].company_data = updatedCompanyEntity;
                setEntitiesData(newEntities);
            }
        }
    }, [updatedCompanyEntity]);

    useEffect(() => {
        if (!isEmpty(updatedContactEntity) && props.action === "Add") {
            const newEntities = clone(entitiesData);
            const indexToEdit = newEntities.findIndex((e) => get(e, "contact_data.id") === updatedContactEntity.id);
            if (indexToEdit !== -1) {
                newEntities[indexToEdit].contact_data = updatedContactEntity;
                setEntitiesData(newEntities);
            }
        }
    }, [updatedContactEntity]);

    const choose = (chosen, obj1, contact_dba) => {
        console.log('choosing -<', chosen, obj1, contact_dba)
        const entityData = {
            entity_type: entityType,
        };

        if (originalContactData && originalContactData.id) {
            entityData.contact_id = originalContactData.id;
        }

        if (!chosen.company_data) {
            // Contact Chosen
            entityData.type = 1;
            entityData.entity_id = chosen.id;
            if (props.action === "Add") {
                entityData.contact_data = chosen;
            }
            const dba_id = get(contact_dba, 'id', null);
            if (dba_id) {
                entityData.dba_id = dba_id;
            }
            const dba_data = get(chosen, 'dbas', null);
            if (dba_data) {
                entityData.dba_data = dba_data;
            }
            // Contact with company
            if (chosen.company_id) {
                entityData.entity_company_id = chosen.company_id;
                // if user selected a company dba, make sure the dba exist
                if (chosen.company_dba_id && chosen.company_dbas.find(i => i.id === chosen.company_dba_id)) {
                    entityData.dba_id = chosen.company_dba_id;
                }
                // reassign contact dba to secondary_dba_id
                if (contact_dba?.id) {
                    entityData.secondary_dba_id = contact_dba.id;
                }
            }
        } else {
            if (chosen && isEmpty(chosen.signers)) {
                // Company with no Contact chosen
                entityData.type = 2;
                entityData.entity_id = get(chosen, "company_data.id");
                if (chosen.dba_id) entityData.dba_id = chosen.dba_id;
                if (chosen.dba_data) entityData.dba_data = chosen.dba_data;
                if (props.action === "Add") {
                    entityData.company_data = get(chosen, "company_data", {});
                }
            } else {
                // Company with Contact chosen
                entityData.type = 1;
                entityData.entity_id = get(chosen, "signers[0].id");
                entityData.entity_company_id = get(chosen, "company_data.id");
                if (chosen.dba_id) entityData.dba_id = chosen.dba_id;
                if (chosen.dba_data) entityData.dba_data = chosen.dba_data;
                if (props.action === "Add") {
                    entityData.contact_data = get(chosen, "signers[0]", {});
                    entityData.company_data = get(chosen, "company_data", {});
                }
            }
        }

        if (props.action === "Edit") {
            createContactEntity(entityData);
        } else if (props.action === "Add") {
            const newEntities = clone(entitiesData);
            newEntities.push(entityData);
            console.log('choosing newEntities', newEntities)
            setEntitiesData(newEntities);
        }

        setOpenedContactModal(false);
        setEntityType(null);
    };

    let accountantInfo = null;
    let legalInfo = null;
    let propManagementInfo = null;

    if (props.action === "Edit" || props.action === "View") {
        accountantInfo = contact_entities.find((entity) => entity.entity_type === CONTACT_ENTITY_TYPE.ACCOUNTANT);
        legalInfo = contact_entities.find((entity) => entity.entity_type === CONTACT_ENTITY_TYPE.LEGAL_INFO);
        propManagementInfo = contact_entities.find(
            (entity) => entity.entity_type === CONTACT_ENTITY_TYPE.PROPERTY_MANAGEMENT
        );
    } else if (props.action === "Add") {
        accountantInfo = entitiesData.find((entity) => entity.entity_type === CONTACT_ENTITY_TYPE.ACCOUNTANT);
        legalInfo = entitiesData.find((entity) => entity.entity_type === CONTACT_ENTITY_TYPE.LEGAL_INFO);
        propManagementInfo = entitiesData.find(
            (entity) => entity.entity_type === CONTACT_ENTITY_TYPE.PROPERTY_MANAGEMENT
        );
    }

    return (
        <div className={props.action === "View" ? "no-click lightgray-bg enclosure" : "enclosure"}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Mailing Address</h4>
                    <AddressFields
                        addressFields={addressFields}
                        updateAddress={updateAddress}
                        addressData={addressData}
                        addType="mailing"
                    />
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Accountant Info</h4>
                    {!accountantInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(CONTACT_ENTITY_TYPE.ACCOUNTANT);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {accountantInfo &&
                        (accountantInfo.type === 1 ? (
                            <AddressContactItem
                                entity={accountantInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                setContactID={setContactID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={accountantInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ))}
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Legal Info</h4>
                    {!legalInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(CONTACT_ENTITY_TYPE.LEGAL_INFO);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {legalInfo &&
                        (legalInfo.type === 1 ? (
                            <AddressContactItem
                                entity={legalInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setContactID={setContactID}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={legalInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ))}
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Property Management Info</h4>
                    {!propManagementInfo && (
                        <Button
                            color="primary"
                            onClick={() => {
                                setOpenedContactModal(true);
                                setEntityType(CONTACT_ENTITY_TYPE.PROPERTY_MANAGEMENT);
                            }}
                            disabled={props.action === "View"}
                        >
                            <i className="fa fa-plus"></i> ADD CONTACT/COMPANY
                        </Button>
                    )}
                    <br />
                    <br />
                    {propManagementInfo &&
                        (propManagementInfo.type === 1 ? (
                            <AddressContactItem
                                entity={propManagementInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditContact={setOpenedEditContact}
                                setContactID={setContactID}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ) : (
                            <AddressCompanyItem
                                entity={propManagementInfo}
                                canEdit={props.action !== "View"}
                                deleteContactEntity={deleteContactEntity}
                                setOpenedEditCompany={setOpenedEditCompany}
                                setCompanyID={setCompanyID}
                                action={props.action}
                                entitiesData={entitiesData}
                                setEntitiesData={setEntitiesData}
                                boxed
								                fromType="CONTACT"
                            />
                        ))}
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">{addressData.other.length} Other Addresses</h4>
                    <Button
                        color="primary"
                        onClick={() => {
                            updateAddress("street1", "", "other", -1);
                        }}
                        disabled={props.action === "View"}
                    >
                        <i className="fa fa-plus"></i> ADD OTHER ADDRESS
                    </Button>
                    {addressData.other.map((ad, i) => {
                        return (
                            <div key={`add${i}`} style={{ marginTop: 20 }} className="boxed">
                                <p>
                                    <span style={{ marginRight: 40 }}>Other Address #{i + 1}</span>
                                    <IconButton
                                        edge="end"
                                        color="danger"
                                        onClick={() => {
                                            updateAddress("delete", "", "", i);
                                        }}
                                        aria-label="Close"
                                    >
                                        <Close style={{ color: "red" }} />
                                    </IconButton>
                                </p>
                                <AddressFields
                                    addressFields={addressFields}
                                    updateAddress={updateAddress}
                                    addressData={addressData}
                                    addType="other"
                                    addressIndex={i}
                                />
                                <hr />
                            </div>
                        );
                    })}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <ContactsFormPartners
                        partnersData={props.partnersData}
                        updatePartners={props.updatePartners}
                        setPartnersData={props.setPartnersData}
                        action={props.action}
                        getContacts={props.getContacts}
                        getContact={props.getContact}
                        contactsData={props.contactsData}
                        contactData={props.contactData}
                    />
                </GridItem>
            </GridContainer>

            {openedContactModal && !(props.action === "View") && (
                <CustomDialogChooser
                    open={openedContactModal}
                    close={() => {
                        setOpenedContactModal(false);
                        setEntityType(null);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts"
                    allowNoContacts
                    no_property
                    withAddresses
                    singleContactOnly={true}
                    showContactCompaniesTab
                />
            )}

            {openedEditContact && (
                <CompaniesEditContact
                    open={openedEditContact}
                    close={() => {
                        setOpenedEditContact(false);
                        if (originalContactData && originalContactData.id && props.action === "Edit") {
                            getContactEntities(originalContactData.id);
                        } else {
                            getUpdatedContactEntity(contactID);
                        }
                    }}
                    contactId={contactID}
                />
            )}

            {openedEditCompany && (
                <CustomDialogCompanyView
                    open={openedEditCompany}
                    close={() => {
                        setOpenedEditCompany(false);
                        if (originalContactData && originalContactData.id && props.action === "Edit") {
                            getContactEntities(originalContactData.id);
                        } else {
                            getUpdatedCompanyEntity(companyID);
                        }
                    }}
                    companyID={companyID}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state, ownProps) => {
    const { originalContactData } = ownProps;
    const contact = state.crm_contacts;
    const company = state.crm_companies;
    return {
        contact_entities:
            originalContactData && originalContactData.id
                ? get(contact, `contact_entities.${originalContactData.id}`, [])
                : [],
        updatedCompanyEntity: company.updated_company_entity,
        updatedContactEntity: contact.updated_contact_entity,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createContactEntity: (data) => dispatch(actions.createContactEntity(data)),
        deleteContactEntity: (id) => dispatch(actions.deleteContactEntity(id)),
        getContactEntities: (contactId) => dispatch(actions.getContactEntities(contactId)),
        getUpdatedContactEntity: (contactId) => dispatch(actions.getUpdatedContactEntity(contactId)),
        getUpdatedCompanyEntity: (companyId) => dispatch(actions.getUpdatedCompanyEntity(companyId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactsFormAddresses);
