import React, { useEffect } from 'react';
import { Close } from '@material-ui/icons';

const NotificationFixedTop = (props) => {
    useEffect(() => {
        if (props.autoClose) {
            setTimeout(() => {
                props.close();
            }, props.autoClose);
        }
    });
    if (props.open) {
        return <div className="top-notification-dialog">
            {!props.no_ok_button && <div className="close-btn" onClick={() => { props.close(); }}><Close /></div>}
            <div style={{padding:20}} className="text-center">
                {props.message}
            </div>
        </div>
    } else {
        return <div></div>
    }
  }

  export default NotificationFixedTop;