import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "../../components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';

const GroupsAdminAskModal = (props) => {
    const groupName = props.groupData ? props.groupData.name : '';
    const groupID = props.groupData ? props.groupData.id : '';
    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogTitle>
            {/* <div style={{textAlign:'center'}}>Are you sure you want to delete {groupName} Group?</div> */}
            <div style={{textAlign:'center'}}>Sorry!</div>
        </DialogTitle>
            
        <div style={{textAlign:'center',padding:20}}>
            <div style={{marginBottom:20}}>
                Group delete is only available in Google Workspace at the moment.
            </div>
            <Button
                color="success"
                style={{marginRight:10}}
                onClick={() => {
                    //props.deleteWorkspaceGroup(groupID);
                    props.close();
                }}
            >
                OK
            </Button>
        </div>
        </Dialog>
    )
}

export default GroupsAdminAskModal;