import React from 'react';
import { Dialog, DialogContent, TextField, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Datetime from 'react-datetime';
import Button from "components/CustomButtons/Button.jsx";

const NTEDataModal = (props) => {
    const { field, formData, setFormData } = props;
    let fieldSplit = field.split('.');
    let newField = fieldSplit.length > 1 ? formData[fieldSplit[0]][fieldSplit[1]] : formData[field];
    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="md"
                fullWidth={true}
            >
                <div style={{ margin: 20 }}>
                    <div className="custom-textfield">
                        {field === 'start_date' ?
                            <DialogContent
                                style={{ height: '40vh' }}>
                                <Datetime
                                    inputProps={{
                                        placeholder: "Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        const newObj = { ...formData };
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            newObj[field] = stringDate;
                                        }
                                        setFormData(newObj);
                                    }}
                                    timeFormat={false}
                                />
                            </DialogContent>
                            : field === 'paid_sick_leave.check_box' ?
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Accrual Method</FormLabel>
                                    <RadioGroup
                                        aria-label="sick_leave_type"
                                        defaultValue="1"
                                        name="radio-buttons-group"
                                        value={formData.paid_sick_leave.check_box}
                                        onChange={(e) => {
                                            const newObj = { ...formData };
                                            newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                            setFormData(newObj);
                                        }}
                                    >
                                        <FormControlLabel value="1" control={<Radio />} label="Accrual Option 1" />
                                        <FormControlLabel value="2" control={<Radio />} label="Accrual Option 2" />
                                        <FormControlLabel value="3" control={<Radio />} label="Accrual Option 3" />
                                        <FormControlLabel value="4" control={<Radio />} label="Accrual Option 4" />
                                    </RadioGroup>
                                </FormControl>
                                : field === 'wage_information.rate_by' ?
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">Rate By: </FormLabel>
                                        <RadioGroup
                                            aria-label="sick_leave_type"
                                            defaultValue="1"
                                            name="radio-buttons-group"
                                            value={formData.wage_information.rate_by}
                                            onChange={(e) => {
                                                const newObj = { ...formData };
                                                newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                                setFormData(newObj);
                                            }}
                                        >
                                            <FormControlLabel value="1" control={<Radio />} label="Hour" />
                                            <FormControlLabel value="2" control={<Radio />} label="Shift" />
                                            <FormControlLabel value="3" control={<Radio />} label="Day" />
                                            <FormControlLabel value="4" control={<Radio />} label="Week" />
                                            <FormControlLabel value="5" control={<Radio />} label="Salary" />
                                            <FormControlLabel value="6" control={<Radio />} label="Piece rate" />
                                            <FormControlLabel value="7" control={<Radio />} label="Commission" />
                                        </RadioGroup>
                                    </FormControl>
                                    : field === 'employer_staffing_agency' ?
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">Staffing agency?</FormLabel>
                                            <RadioGroup
                                                aria-label="staffing_agency"
                                                defaultValue="No"
                                                name="radio-buttons-group"
                                                value={formData.employer_staffing_agency}
                                                onChange={(e) => {
                                                    const newObj = { ...formData };
                                                    newObj[fieldSplit[0]] = e.target.value;
                                                    setFormData(newObj);
                                                }}
                                            >
                                                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                <FormControlLabel value="No" control={<Radio />} label="No" />
                                            </RadioGroup>
                                        </FormControl>
                                        : field === 'wage_information.written_agreement' ?
                                            <FormControl component="fieldset">
                                                <FormLabel component="legend">Does a written agreement exist providing the rate(s) of pay?</FormLabel>
                                                <RadioGroup
                                                    aria-label="written_agreement"
                                                    defaultValue="No"
                                                    name="radio-buttons-group"
                                                    value={formData.wage_information.written_agreement}
                                                    onChange={(e) => {
                                                        const newObj = { ...formData };
                                                        newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                                        setFormData(newObj);
                                                    }}
                                                >
                                                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                    <FormControlLabel value="No" control={<Radio />} label="No" />
                                                </RadioGroup>
                                            </FormControl>
                                            : field === 'wage_information.if_written' ?
                                                <FormControl component="fieldset">
                                                    <FormLabel component="legend">Does a written agreement exist providing the rate(s) of pay?</FormLabel>
                                                    <RadioGroup
                                                        aria-label="written_agreement"
                                                        defaultValue="No"
                                                        name="radio-buttons-group"
                                                        value={formData.wage_information.if_written}
                                                        onChange={(e) => {
                                                            const newObj = { ...formData };
                                                            newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                                            setFormData(newObj);
                                                        }}
                                                    >
                                                        <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                        <FormControlLabel value="No" control={<Radio />} label="No" />
                                                    </RadioGroup>
                                                </FormControl>
                                                : field === 'workers_compensation.self_insured' ?
                                                    <FormControl component="fieldset">
                                                        <FormLabel component="legend">Self Insured?</FormLabel>
                                                        <RadioGroup
                                                            aria-label="self_insured"
                                                            defaultValue="No"
                                                            name="radio-buttons-group"
                                                            value={formData.workers_compensation.self_insured}
                                                            onChange={(e) => {
                                                                const newObj = { ...formData };
                                                                newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                                                setFormData(newObj);
                                                            }}
                                                        >
                                                            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                                                            <FormControlLabel value="No" control={<Radio />} label="No" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                    : <TextField
                                                        label={field}
                                                        multiline
                                                        rowsMax="4"
                                                        value={newField}
                                                        margin="normal"
                                                        onChange={(e) => {
                                                            const newObj = { ...formData };
                                                            if (fieldSplit.length > 1) {
                                                                newObj[fieldSplit[0]][fieldSplit[1]] = e.target.value;
                                                            } else {
                                                                newObj[field] = e.target.value;
                                                            }
                                                            setFormData(newObj);
                                                        }}
                                                    />}
                    </div>
                    <br />
                    <Button color="rose" onClick={() => { props.close() }}>OK</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default NTEDataModal;