import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const leaseAssignmentFeeFields = FIELDS.LeaseAssignmentFee;

export const subFields = SUBFIELDS.LeaseAssignmentFee;

export const sectionNames = SECTION.LeaseAssignmentFee.names;

export const sectionRequirements = SECTION.LeaseAssignmentFee.requirements;

const arrayTypes = [
	'multi-contact-agent',
	'multi-dates',
	'referral_firm',
	'lease_term_ranges',
	'payment_schedule',
	'lessees',
	'lessors',
	'assignors',
	'outside_brokers',
	'referral_firm',
	'multi-contact',
	'object_array',
	'spaces',
];
export const initialData = getInitialData(leaseAssignmentFeeFields, arrayTypes);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
