import React from "react";
import { useState, useEffect, useRef } from "react";
import ContactsList from "./ContactsList";
import ContactsAskModal from "./ContactsAskModal";
import ContactsForm from "./ContactsForm";
import { saveContactNow } from './ContactsLogic';

import Dialog from "@material-ui/core/Dialog";
import CustomDialogClose from "components/CustomDialog/CustomDialogClose";
import Notification from "../../../components/Notification/NotificationFixedTop";

const Contacts = (props) => {
	const [notification, setNotification] = useState('');
	const [openedAskModal, setOpenedAskModal] = useState(false);
	const [openedForm, setOpenedForm] = useState(null);
	const [contactData, setContactData] = useState(null);
	const [action, setAction] = useState(null);
	const [openedAsk, setOpenedAsk] = useState(false);
	const [saveContactsFormValues, setSaveContactsFormValues] = useState({
		cfDataObj: {},
		cfAddrData: {},
		cfPartnersData: {},
		madeChanges: false,
	});

	const prev_contact_data = usePrevious(props.contactData);

	function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

	const openForm = (action, cd) => {
		if (cd) setContactData(cd);
		setOpenedForm(true);
		setAction(action);
		props.getAddresses(cd.id);
		props.getPartnersData(cd.id);
	};

	const closeForm = () => {
		setContactData(null);
		setOpenedForm(null);
		setAction(null);
		
		props.closeFrom();
	};

	const openAskModal = (cd) => {
		
		setOpenedAskModal(true);
		setContactData(cd);
	};

	const closeAskModal = (cd) => {
		setOpenedAskModal(false);
		//setContactData(cd);
	};

	useEffect(() => {
		if (props.location_search) {
			// if query parameter has contact_id load and open the contact
			const params = new URLSearchParams(props.location_search);
			const contact_id = params.get('contact_id');
			if (contact_id) {
				props.getContact(contact_id);
			}
		}
		props.getContacts();
		props.clearMessages();
	}, []);

	useEffect(() => {
		if (!openedForm) {
			props.getContacts();
			props.clearMessages();
		}
	}, [openedForm]);

	useEffect(() => {
		if ((props.contactData !== prev_contact_data) && props.contactData) {
			const params = new URLSearchParams(props.location_search);
			const contact_id = params.get('contact_id')
			console.log('contact changed', props.contactData, contact_id);
			if (contact_id) {
				if (parseInt(contact_id) == props.contactData.id) {
					openForm('Edit',props.contactData);
					// change URL, remove contact_id query param
					window.history.replaceState(null, null, "/crm/contacts");
					setNotification('Successfully added contact.');
				}
			}
		}
	}, [props.contactData]);

	const dataIsComplete = contactData
		? (contactData.first_name || contactData.company_name || contactData.last_name)
			? true
			: false
		: false;

	

	return (
		<div>
			{!openedForm && (
				<ContactsList
					data={{
						contactsData: props.contactsData,
						contactsCount: props.contactsCount,
						agentData: props.agentData,
						agentDataCount: props.agentDataCount
					}}
					contactsCount={props.contactsCount}
					contactsData={props.contactsData}
					agentData={props.agentData}
					agentDataCount={props.agentDataCount}
					getContacts={props.getContacts}
					getPropertyContacts={props.getPropertyContacts}
					getPartnersData={props.getPartnersData}
					getAddresses={props.getAddresses}
					deleteContact={props.deleteContact}
					openForm={openForm}
					openAskModal={openAskModal}
					setRows={props.setRows}
					setOffset={props.setOffset}
					rows={props.rows}
					offset={props.offset}
					sortDirection={props.sortDirection}
					sortBy={props.sortBy}
					filters={props.filters}
					setFilters={props.setFilters}
					resetFilters={props.resetFilters}
					setSort={props.setSort}
					loading={props.loading}
					clearContacts={props.clearContacts}
					clearCRMMessages={props.clearCRMMessages}
				/>
			)}
			{dataIsComplete && openedForm && (
				<Dialog
					open={dataIsComplete && openedForm}
					keepMounted
					maxWidth={false}
					fullWidth={true}
					fullScreen={true}
				>
					<div className="dialog-wrapper">
						<ContactsForm
							action={action}
							closeForm={closeForm}
							openForm={openForm}
							contactData={contactData}
							retrievedContactData={props.contactData}
							propertyContacts={props.propertyContacts}
							getContacts={props.getContacts}
							contactOptions={props.contactOptions}
							addressData={props.addressData}
							getAddresses={props.getAddresses}
							partnersData={props.partnersData}
							getPartnersData={props.getPartnersData}
							deleteContact={props.deleteContact}
							deleteCrmFile={props.deleteCrmFile}
							openAskModal={openAskModal}
							updateContact={props.updateContact}
							getContactShares={props.getContactShares}
							getContactUsernames={props.getContactUsernames}
							createContactShare={props.createContactShare}
							deleteContactShare={props.deleteContactShare}
							contactShares={props.contactShares}
							contactUsernames={props.contactUsernames}
							success={props.success}
							error={props.error}
							loading={props.loading}
							clearMessages={props.clearMessages}
							getContact={props.getContact}
							contactsData={props.contactsData}
							getContactFiles={props.getContactFiles}
							formDataObj={props.formDataObj}
							setFormDataObj={props.setFormDataObj}
							getCrmFileList={props.getCrmFileList}
							fileList={props.fileList}
							createCrmFile={props.createCrmFile}
							getCrmFile={props.getCrmFile}
							downloadedFile={props.downloadedFile}
							setOpenedAsk={setOpenedAsk}
							showCloseBtn={true}
							crmAllowed={props.crmAllowed}
							get_contacts={props.get_contacts}
							setSaveContactsFormValues={setSaveContactsFormValues}
						/>
					</div>
				</Dialog>
			)}
			{openedAskModal && (
				<ContactsAskModal
					open={openedAskModal}
					close={closeAskModal}
					closeForm={closeForm}
					contactData={contactData}
					deleteContact={props.deleteContact}
					getContacts={props.getContacts}
					success={props.success}
					error={props.error}
					clearMessages={props.clearMessages}
				/>
			)}

			{openedAsk && (
				<CustomDialogClose
					ok_go={() => {
						closeForm();
					}}
					ok_save={() => {
						
						saveContactNow({
							dataObj: saveContactsFormValues.cfDataObj,
							addressData: saveContactsFormValues.cfAddressData,
							partnersData: saveContactsFormValues.cfPartnersData,
							madeChanges: saveContactsFormValues.madeChanges,
							action: action,
							currentAddressData: props.addressData,
							currentPartnersData: props.partnersData,
							updateContact: props.updateContact
						});
						closeForm();
					}}
					open={openedAsk}
					close={() => {
						setOpenedAsk(false);
					}}
				/>
			)}

			{notification && <Notification 
				open={notification ? true : false}
				close={() => { setNotification('') }}
				message={notification}
				autoClose={3000}
			/>}
		</div>
	);
};

export default Contacts;
