import { put } from 'redux-saga/effects';
import axios from '../axios/axios-tr';
import * as actions from '../actions/tr_entries';

export function* getTrEntriesSaga(action) {
    yield put(actions.getTrEntriesStart());
    try {
        const own = action.data.own ? '&own=true' : '';
        const status = action.data.status ? action.data.status.join(',') : '';
        const response = yield axios.get('/entries?status='+status+own);
        yield put(actions.getTrEntriesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getTrEntriesFail(e.response.data));
            }
          }
        yield put(actions.getTrEntriesFail(e));
    }
}

export function* getTrEntrySaga(action) {
  yield put(actions.getTrEntryStart());
  try {
      const response = yield axios.get('/tr_entry?id='+action.data);
      yield put(actions.getTrEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getTrEntryFail(e.response.data));
          }
        }
      yield put(actions.getTrEntryFail(e));
  }
}

export function* createTrEntrySaga(action) {
  yield put(actions.createTrEntryStart());
  try {
      const response = yield axios.post('/entry', action.data);
      yield put(actions.createTrEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createTrEntryFail(e.response.data));
          }
        }
      yield put(actions.createTrEntryFail(e));
  }
}


export function* updateTrEntrySaga(action) {
  yield put(actions.updateTrEntryStart());
  try {
      const response = yield axios.put('/entry', action.data);
      yield put(actions.updateTrEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateTrEntryFail(e.response.data));
          }
        }
      yield put(actions.updateTrEntryFail(e));
  }
}

export function* deleteTrEntrySaga(action) {
  yield put(actions.deleteTrEntryStart());
  try {
      const response = yield axios.delete('/entry?id='+action.data);
      yield put(actions.deleteTrEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deleteTrEntryFail(e.response.data));
          }
        }
      yield put(actions.deleteTrEntryFail(e));
  }
}

export function* getTrApproversSaga(action) {
  yield put(actions.getTrApproversStart());
  try {
      const response = yield axios.get('/approvers');
      yield put(actions.getTrApproversSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getTrApproversFail(e.response.data));
          }
        }
      yield put(actions.getTrApproversFail(e));
  }
}

export function* createTrPdfSaga(action) {
  yield put(actions.createTrPdfStart());
  try {
      const response = yield axios.post('/pdf', action.data);
      yield put(actions.createTrPdfSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createTrPdfFail(e.response.data));
          }
        }
      yield put(actions.createTrPdfFail(e));
  }
}

export function* createTrHistoryPdfSaga(action) {
  yield put(actions.createTrHistoryPdfStart());
  try {
      const response = yield axios.post('/history_pdf', action.data);
      yield put(actions.createTrHistoryPdfSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createTrHistoryPdfFail(e.response.data));
          }
        }
      yield put(actions.createTrHistoryPdfFail(e));
  }
}

export function* getTrHistorySaga(action) {
  yield put(actions.getTrHistoryStart());
  try {
      const response = yield axios.get('/history?tr_id='+action.data);
      yield put(actions.getTrHistorySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getTrHistoryFail(e.response.data));
          }
        }
      yield put(actions.getTrHistoryFail(e));
  }
}

export function* createTrHistorySaga(action) {
  yield put(actions.createTrHistoryStart());
  try {
      const response = yield axios.post('/history', action.data);
      yield put(actions.createTrHistorySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createTrHistoryFail(e.response.data));
          }
        }
      yield put(actions.createTrHistoryFail(e));
  }
}

export function* getTrSigningUrlSaga(action) {
  yield put(actions.getTrSigningUrlStart());
  try {
      const response = yield axios.get('/signing_url?id='+action.data);
      yield put(actions.getTrSigningUrlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getTrSigningUrlFail(e.response.data));
          }
        }
      yield put(actions.getTrSigningUrlFail(e));
  }
}