import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDialogChooser from '../../../components/CustomDialog/CustomDialogChooser';
import { clone } from 'shared/utility';
import Button from '../../../components/CustomButtons/Button';
import Notification from '../../../components/Notification/Notification';

const PMAMulti11 = (props) => {
    const [addContact, setAddContact] = useState(false);
    const [message, setMessage] = useState('');
    const [target, setTarget] = useState(false);
    const { formData, setFormData, approvers } = props;

    const choose = (the_contact_data) => {
        
        if (the_contact_data.company_data) {
            // company
            if (the_contact_data.signers) {
                const newData = clone(formData);
                if (!newData.addedCC) {
                    newData.addedCC = [];
                }
                const ss = the_contact_data.signers;
                ss.forEach(s => {
                    let found = false;
                    const s_data = s.company_dats;
                    newData.addedCC.forEach(acc => {
                        if (acc.id === s.id) {
                            found = true;
                        }
                    })
                    if (!found) {
                        newData.addedCC.push({
                            id: s.id,
                            name: (s.first_name ? s.first_name : '') + ' ' 
                            + (s.last_name ? s.last_name : ''),
                            email: s_data.email
                        })
                    }
                })
                let found_missing_email = false;
                newData.addedCC.forEach(acc => {
                    if (!acc.email) found_missing_email = true;
                })
                if (found_missing_email) return setMessage('Missing email');
                setFormData(newData);
                setAddContact(false);
            }
        } else {
            // contact 
            if (!the_contact_data.email) {
                setMessage('Email is required');
            } else {
                const newData = clone(formData);
                let found = false;
                if (newData.addedCC) {
                    newData.addedCC.forEach(cc => {
                        if (cc.id === the_contact_data.id) {
                            found = true;
                        } 
                    })
                } else {
                    newData.addedCC = [];
                }
                if (!found) newData.addedCC.push({
                    id: the_contact_data.id,
                    name: (the_contact_data.first_name ? the_contact_data.first_name : '') + ' ' 
                        + (the_contact_data.last_name ? the_contact_data.last_name : ''),
                    email: the_contact_data.email
                })
                setFormData(newData);
                setAddContact(false);
            }
        }
    }

    return (
        <div>
            <center><h3>Additional Notification Recipients</h3></center>
            <GridContainer>
                <GridItem xs={12} sm={12} md={9}>
                    <Button
                        color="primary"
                        onClick={() => {
                            setAddContact(true);
                        }}
                    >ADD ADDITIONAL RECIPIENT</Button>
                    <hr />
                    {formData.addedCC.map((cc,i) => {
                        return <div className="boxed" key={`${i}-addedCC`}>
                            <div>
                                Name : {cc.name}
                            </div>
                            <div>
                                Email : {cc.email}
                            </div>
                            <div style={{textAlign:'right'}}>
                                <span className="hoverable red-text"
                                    onClick={() => {
                                        const newData = clone(formData);
                                        newData.addedCC = newData.addedCC.filter(ccc => {
                                            if (ccc.id === cc.id) return false;
                                            return true;
                                        })
                                        setFormData(newData);
                                    }}
                                >
                                    <i className="fas fa-trash"
                                    ></i> REMOVE
                                </span>
                            </div>
                        </div>
                    })}
                </GridItem>
            </GridContainer>
            {addContact && <CustomDialogChooser
                open={addContact}
                close={() => { setAddContact(false) }}
                choose={choose}
                create_and_choose={choose}
                peopleType="owners"
                title="Select Recipients"
                label="Search Recipient"
                skipDba
            />}
            {message && <Notification 
                message={message}
                title="Alert"
                open={message ? true : false}
                close={() => { setMessage('') }}
            />}
        </div>
    );
}

export default PMAMulti11;