export const invoiceFields = {
    illi: {
        type: 'input',
        label: 'illi #',
        validations: ["no_letters"]
    },
    invoice: {
        type: 'input',
        label: 'Invoice #',
        validations: ["no_letters"]
    },
    title: {
        type: "input",
        label: "Title",
        validations: ["required"],
        required: true,
    },
    date: {
        type: "date",
        label: "Date",
        dateFormat: "LL"
    },
    terms: {
        type: "input",
        label: "Terms",
    },
    bill_to: {
        type: "input",
        label: "Bill To",
    },
    attn: {
        type: "input",
        label: "ATTN",
    },
    project: {
        type: "input",
        label: "Project",
    },
    property_address: {
        type: "input",
        label: "Property Address",
    },
    seller: {
        type: "input",
        label: "Lessor/Seller",
    },
    buyer: {
        type: "input",
        label: "Lessee/Buyer",
    },
    sqft: {
        type: "input",
        label: "SQFT",
    },
    consideration: {
        type: "input",
        label: "Consideration",
    },
    leasing_term: {
        type: "input",
        label: "Leasing Term",
    },
    agent: {
        type: "input",
        label: "Primary Agent",
    },
    total_month: {
        type: "input",
        label: "Total Months",
    },
    total_annual_rent: {
        type: "input",
        label: "Total Annual Rent",
    },
    total_rate: {
        type: "input",
        label: "Rate",
    },
    total_commission: {
        type: "input",
        label: "Commission",
    },
    balance: {
        type: "input",
        label: "Balance",
    },
    total_amount: {
        type: "input",
        label: "Total",
    },
};

export const termFields = {
    start: {
        type: 'date',
        label: 'Lease Start'
    },
    end: {
        type: 'date',
        label: 'Lease End',
    },
    monthly_rent: {
        type: 'input',
        label: 'Monthly Rent',
        validations: ["no_letters"]
    },
    months: {
        type: 'input',
        label: 'Months',
    },
    term_rent: {
        type: 'input',
        label: 'Term Rent',
    },
    rate: {
        type: 'input',
        label: 'Rate',
        validations: ["no_letters"]
    },
    commission: {
        type: 'input',
        label: 'Commission',
    }
}