import React from "react";
import PropTypes from "prop-types";
import { loginUser, checkUsername } from  '../../store/actions/user';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import SnackbarContent from "components/Snackbar/SnackbarContent.jsx";
import { GoogleLogin } from 'react-google-login';
import { googleClientID } from '../../shared/devStatus';
import { workspaceDomain } from "../../shared/devStatus";
import devStatus from "../../shared/devStatus";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import LockOpen from "@material-ui/icons/LockOpen";

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import TextFieldGroup from "../Components/textFieldGroup";

import loginPageStyle from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.jsx";

import logo from "assets/img/white-illi-logo.png";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      password: '',
      errors: {},
      message: '',
      showAdminLogin: false
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSuccess = (e) => {
    if (e.tokenId && e.profileObj) {
      const username = e.profileObj.email;
      const loginCredentials = {
        username,
        password: e.tokenId
      }
      this.props.dispatch(loginUser(loginCredentials));
    }
  }

  onFailure = (e) => {
    console.log('failed to log in', e);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value});
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.showAdminLogin) {
      const loginCredentials = {
        username: this.state.username,
        password: this.state.password
      }
      this.props.dispatch(loginUser(loginCredentials));
      return;
    }
    if (this.props.usernameCheck && (this.props.usernameCheck !== '0' && this.props.usernameCheck === this.state.username)) {
      if (this.props.usernameCheck.indexOf('@') === -1 && this.state.password) {

        const loginCredentials = {
          username: this.props.usernameCheck,
          password: this.state.password
        }
        this.props.dispatch(loginUser(loginCredentials));
      }
    } else {
      if (this.state.username) {
        this.props.dispatch(checkUsername(this.state.username));
        return;
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.message !== this.props.message) {
      if (this.props.message) {
        this.setState({
          message: this.props.message
        });
      } else {
        this.setState({message:''});
      }
    }
  }

  componentDidMount() {
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    });
    const is_admin = params.is_admin;
    const is_error = params.throw_error;
    if (is_admin === 'true') {
      if (is_error) {
        throw "Test Error";
      }
      this.setState({
        showAdminLogin: true
      })
    }
    if (window.gapi) {
      if (window.gapi.auth2) {
          if (window.gapi.auth2.getAuthInstance) {
              const auth2 = window.gapi.auth2.getAuthInstance();
              if (auth2 != null) {
                  auth2.signOut().then(
                      auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                  )
              }
          }
      }
    }
    setTimeout(
      function() {
        this.setState({ cardAnimaton: "" });
      }.bind(this),
      1
    );
    // make sure user is logged out
    if (window.gapi) {
        if (window.gapi.auth2) {
            if (window.gapi.auth2.getAuthInstance) {
                const auth2 = window.gapi.auth2.getAuthInstance();
                if (auth2 != null) {
                    auth2.signOut().then(
                        auth2.disconnect().then(console.log('LOGOUT SUCCESSFUL'))
                    )
                }
            }
        }
    }
    // localStorage.removeItem('token');
  }

  render() {
    const { classes, origin, loading, message, loggedInStatus } = this.props;
    if (origin && localStorage.getItem('token')) {
      if (loggedInStatus) {
        if (origin !== 'none') {
          return <Redirect to={`/${origin}`} />
        }
        return <Redirect to="/dashboard" />
      }
    }

    const usernameIsEmail = true;

    const showAdminLogin = this.state.showAdminLogin;
    const isStaging = devStatus === 'stage' ? true : false;

    return (
      <div className={classes.content}>
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={6} md={4}>
              <form onSubmit={this.onSubmit}>
                <Card login className={classes[this.state.cardAnimaton]}>
                  <CardHeader
                    className={`${classes.cardHeader} ${classes.textCenter}`}
                    color='primary'
                  >
                    <h4 className={classes.cardTitle}>Log in</h4>
                    <img src="/assets/white-illi-logo.png" style={{width:100}} />
                    <h1 className={classes.cardTitle} style={{fontSize:24,marginTop:20}}>Connect Portal</h1>
                    <div className={classes.socialLine}>
                      {[
                      ].map((prop, key) => {
                        return (
                          <Button
                            color="transparent"
                            justIcon
                            key={key}
                            className={classes.customButtonClass}
                          >
                            <i className={prop} />
                          </Button>
                        );
                      })}
                    </div>
                  </CardHeader>
                  <CardBody>
                  <div style={{textAlign:'center',paddingTop:20}}>
                    {showAdminLogin && <div>
                      <TextFieldGroup
                        placeholder="Username"
                        name="username"
                        value={this.state.username}
                        type="text"
                        onChange={this.onChange}
                      />
                    </div>}
                    {showAdminLogin && <div>
                    <TextFieldGroup
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                    />
                  </div>}
                  {!showAdminLogin && <div>
                    {usernameIsEmail && 
                        <GoogleLogin 
                          clientId={googleClientID}
                          buttonText="Login"
                          onSuccess={this.onSuccess}
                          onFailure={this.onFailure}
                          style={{marginTop:20}}
                          isSignedIn={true}
                          hostedDomain={workspaceDomain}
                        />
                      }
                  </div>}

                  </div>
                  </CardBody>
                  <CardFooter className={classes.justifyContentCenter}>
                    {loading && <FadeLoader
                        sizeUnit={"px"}
                        size={150}
                        color={'#123abc'}
                        loading={true}
                    />}

                    {(showAdminLogin && !loading) &&
                    <Button 
                      color="success" 
                      simple size="lg" 
                      block 
                      type="button" 
                      onClick={this.onSubmit}>
                      {(this.props.usernameCheck === this.state.username) ? 'LOG IN' : 'SUBMIT'}
                    </Button>
                    }

                     </CardFooter>
                     {
                       this.state.message &&
                       <div style={{marginLeft:'10px',marginRight:'10px'}}><br />
                         <SnackbarContent
                           message={this.state.message}
                           close
                           color="danger"
                         />
                       </div>
                     }
                  {isStaging && <div><Button color="transparent" simple size="sm" block>
                      <LockOpen />
                  <a href="#" onClick={() => {
                      window.location.href = "/pages/login-page?is_admin=true";
                  }}>
                    Login Override (Staging Only)
                  </a>
                   </Button></div>}
                </Card>
              </form>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = (state) => {
  return {
    origin: state.auth.origin,
    loading: state.auth.loading,
    message: state.auth.message,
    loggedInStatus: state.auth.loggedInStatus,
    usernameCheck: state.user.usernameCheck
  }
}

export default compose(
  withStyles(loginPageStyle),
  connect(mapStateToProps, null)
)(LoginPage);
