export const leaseFields = {
    start_date: {
        type: 'date',
        label: 'Start Date',
        validations: ['required']
    },
    expiration_date: {
        type: 'date',
        label: 'Expiration Date',
        validations: ['required']
    },
    expiration_alert_date: {
        type: 'date',
        label: 'Lease Expiration Date',
        validations: []
    },
    extension_date: {
        type: 'date',
        label: 'Lease Extension Date',
        validations: []
    },
    unit_number: {
        type: 'number',
        label: 'Unit Number',
        validations: ['no_letters']
    },
    exclusions: {
        type: 'text',
        label: 'Exclusions',
        validations: []
    },
    term: {
        type: 'date',
        label: 'Term',
        validations: []
    },

    agents: {
        type: 'multi',
        label: 'Lease Agents',
        validations: []
    },
    lessee: {
        type: 'multi',
        label: '',
        validations: []
    },
    lessor: {
        type: 'multi',
        label: 'Lessor',
        validations: []
    }
}

export const initialLeaseData = {
    id: '',
    start_date: '',
    expiration_date: '',
    expiration_alert_date: '',
    extension_date: '',
    other_data: {
        agents: [],
        lessees: [],
        lessors: [],
        unit_number: '',
        exclusions: '',
        term: '',
    }
}