import React from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../store/actions";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button";
import ListingList from "containers/Listing/ListingList";
import ListingForm from "containers/Listing/ListingForm";
import { defaultData, checkData } from "containers/Listing/ListingObj";
import { LISTING_STATUS } from "../../containers/Listing/ListingObj";
import { listingFields } from "../../containers/Listing/ListingModel";
import { getParameterByName } from "../../shared/utility";

class ListingSubmissionsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: "",
            openedForm: false,
            data: defaultData,
            action: "Add",
            parent_data: null,
            fieldModels: listingFields,
            prefill_property_id: null
        };
    }

    setTab = (tab) => {
        this.setState({ tab });
        // this.props.history.push(`/listing-submissions?tab=${tab}`);
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevState.tab !== this.state.tab) {
            this.props.getListingEntries({
                status: this.state.tab,
            });
        }

        if (
            prevProps.success !== this.props.success &&
            this.props.success === "Success"
        ) {
            this.props.getListingEntries({
                status: this.state.tab,
            });
        }

        if (prevProps.entryData !== this.props.entryData) {
            if (window.location.href.indexOf('p=open') !== -1) {
                if (this.props.entryData) {
                    this.setState({
                        openedForm: true,
                        data: checkData(
                            this.props.entryData.data
                        ),
                        action: "Edit",
                        parent_data: this.props.entryData,
                    });
                    window.history.replaceState(null, null, '/listings')
                }
            }
        }
    }

    addNewListing = (property_id) => {
        this.setState({
            openedForm: true,
            data: defaultData,
            action: "Add",
            parent_data: {},
            prefill_property_id: property_id
        });
    }

    componentDidMount() {
        const params = new URLSearchParams(this.props.location.search);
		const p = params.get('p');
        const property_id = params.get('property_id');
		if (p === 'new') {
			this.addNewListing(property_id);
            // remove parameter
            window.history.replaceState(null, null, "/listing");
		}
        const action = getParameterByName('p', window.location.href);
        if (action === 'new') {
            const permitted = checkPermissions("Listing");
            if (permitted) {
                const property_id = getParameterByName('property_id', window.location.href);
                if (property_id) {
                    this.addNewListing(parseInt(property_id));
                }
            }
        } else if (action === 'open') {
            const permitted = checkPermissions("Listing");
            console.log('getting listing entry 1', permitted);
            if (permitted) {
                const listing_id = getParameterByName('listing_id', window.location.href);
                if (listing_id) {
                    console.log('getting listing entry 2', listing_id);
                    this.props.getListingEntry(listing_id);
                }
            }
        }
    }

    setFieldModels = (newFieldModels) =>
        this.setState({ fieldModels: newFieldModels });

    render() {
        const listingPermitted = checkPermissions("Listing");
        if (!listingPermitted) {
            return <Redirect to="/dashboard" />;
        }

        const permissions = checkPermissionsActions("Listing");

        const { tab } = this.state;

        return (
            <div style={{ marginTop: 20 }}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                {
                                    <CardIcon
                                        color={
                                            tab === LISTING_STATUS.DRAFT
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab(LISTING_STATUS.DRAFT);
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Entries</strong>
                                    </CardIcon>
                                }
                                {
                                    <CardIcon
                                        color={
                                            tab === LISTING_STATUS.PENDING
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab(LISTING_STATUS.PENDING);
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Pending</strong>
                                    </CardIcon>
                                }
                                {
                                    <CardIcon
                                        color={
                                            tab ===
                                            LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab(
                                                LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4
                                            );
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Pending Final Approval</strong>
                                    </CardIcon>
                                }
                                {
                                    <CardIcon
                                        color={
                                            tab === LISTING_STATUS.APPROVED
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab(
                                                LISTING_STATUS.APPROVED
                                            );
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Approved</strong>
                                    </CardIcon>
                                }
                                {
                                    <CardIcon
                                        color={
                                            tab === LISTING_STATUS.DECLINED
                                                ? "success"
                                                : "rose"
                                        }
                                        onClick={() => {
                                            this.setTab(
                                                LISTING_STATUS.DECLINED
                                            );
                                        }}
                                        className="hovered"
                                    >
                                        <strong>Declined</strong>
                                    </CardIcon>
                                }
                            </CardHeader>
                            <CardBody>
                                {tab === LISTING_STATUS.DRAFT && (
                                    <div>
                                        <Button
                                            color="primary"
                                            className="mb-20"
                                            onClick={() => {
                                                this.addNewListing();
                                            }}
                                        >
                                            ADD NEW LISTING ENTRY
                                        </Button>
                                    </div>
                                )}

                                <div>
                                    <ListingList
                                        data={
                                            this.props.entriesData
                                                ? this.props.entriesData
                                                : []
                                        }
                                        tab={tab}
                                        open={(parent_data) => {
                                            this.setState({
                                                openedForm: true,
                                                data: checkData(
                                                    parent_data.data
                                                ),
                                                action: "Edit",
                                                parent_data,
                                            });
                                        }}
                                        deleteListingEntry={
                                            this.props.deleteListingEntry
                                        }
                                        getListingPdf={this.props.getListingPdf}
                                        pdfData={this.props.pdfData}
                                        updateListingEntry={
                                            this.props.updateListingEntry
                                        }
                                        getListingEntries={
                                            this.props.getListingEntries
                                        }
                                    />
                                </div>

                                {this.state.openedForm && (
                                    <div>
                                        <ListingForm
                                            tab={this.state.tab}
                                            data={this.state.data}
                                            action={this.state.action}
                                            open={this.state.openedForm}
                                            close={() => {
                                                this.setState({
                                                    openedForm: false,
                                                });
                                            }}
                                            parent_data={this.state.parent_data} // Edit Data
                                            createListingEntry={
                                                this.props.createListingEntry
                                            }
                                            getListingPdf={
                                                this.props.getListingPdf
                                            }
                                            pdfData={this.props.pdfData}
                                            updateListingEntry={
                                                this.props.updateListingEntry
                                            }
                                            getListingEntries={
                                                this.props.getListingEntries
                                            }
                                            getListingHistory={
                                                this.props.getListingHistory
                                            }
                                            listingHistory={
                                                this.props.listingHistory
                                            }
                                            fieldModels={this.state.fieldModels}
                                            setFieldModels={this.setFieldModels}
                                            prefill_property_id={this.state.prefill_property_id}
                                            reset_prefill_property_id={() => {
                                                this.setState({
                                                    prefill_property_id: null
                                                })
                                            }}
                                        />
                                    </div>
                                )}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        entriesData: state.listing.entriesData,
        pdfData: state.listing.listingPdf,
        success: state.listing.success,
        listingHistory: state.listing.listingHistory,
        entryData: state.listing.entryData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getListingEntries: (data) => {
            dispatch(actions.getListingEntries(data));
        },
        updateListingEntry: (data) => {
            dispatch(actions.updateListingEntry(data));
        },
        createListingEntry: (data) => {
            dispatch(actions.createListingEntry(data));
        },
        deleteListingEntry: (data) => {
            dispatch(actions.deleteListingEntry(data));
        },
        getListingPdf: (data) => {
            dispatch(actions.getListingPdf(data));
        },
        getListingHistory: (listingId) => {
            dispatch(actions.getListingHistory(listingId));
        },
        getListingEntry: (data) => {
            dispatch(actions.getListingEntry(data));
        }
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ListingSubmissionsPage);
