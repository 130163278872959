import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const noListingFields = FIELDS.NoListing;

export const subFields = SUBFIELDS.NoListing;

export const sectionNames = SECTION.NoListing.names;

export const sectionRequirements = SECTION.NoListing.requirements;

export const initialData = getInitialData(noListingFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
