import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import ReactTable from 'react-table';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { FadeLoader } from 'react-spinners';
import { formatDate3, clone } from 'shared/utility';
import MeetingForm from "./MeetingForm";
import { initialMeetingData } from './MeetingModel';
import CustomDialogWithProperty from 'components/CustomDialog/CustomDialogWithProperty';
import Button from '../../../../components/CustomButtons/Button';
import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';
import { getUser } from '../../../../shared/authValidation';
import { processMeeting } from './MeetingLogic';
import moment from 'moment';
import { IconButton } from '@material-ui/core';
import { Cached } from '@material-ui/icons';
import { getSubProperties } from '../../../../store/actions';

const MeetingMain = (props) => {
    const [tab, setTab] = useState('List');
    const [mainData, setMainData] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [openedPropertyModal, setOpenedPropertyModal] = useState(false);
    const [openedAsk, setOpenedAsk] = useState(false);
    const [user, setUser] = useState(null);
    const [userType, setUserType] = useState('own');

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);
    }, [props.success]);

    useEffect(() => {
        props.getSubProperties();
        const userPerms = getUser();
        if (userPerms) {
            setUser(userPerms)
        }
    }, []);

    let allowIlliDBAccess = false;
    if (user) {
        if (user.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (user.permObj) {
                if (user.permObj.hasOwnProperty('CRM agent_access_illi_db')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

    const filterSpecial = (dat) => {
        if (user) {
            if (user.type === 'A' && dat) {
                
                return dat.filter(d => {
                    if (d.user_id === 1) return false;
                    return true;
                })
            }
        }
        return dat;
    }

    const data = props.subProperties ? props.subProperties.map((l, key) => { // map users to table rows
        return ({
            id: l.id,
            property_name: l.property_name,
            start_datetime: l.start_datetime ? moment(l.start_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma') : '',
            end_datetime: l.end_datetime ? moment(l.end_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma') : '',
            invitees: l.other_data.invitees ? l.other_data.invitees.map(li => li.name).join(',') : '',
            outcome: l.outcome,
            mainData: l
        })
    }) : [];

    const columns = [
        {
            Header: "Property Name",
            accessor: "property_name"
        },
        {
            Header: "Meeting Start Date / Time",
            accessor: "start_datetime"
        },
        {
            Header: "Meeting End Date / Time",
            accessor: "end_datetime"
        },
        {
            Header: "Invitees",
            accessor: "invitees"
        },
        {
            Header: "Outcome",
            accessor: "outcome"
        }
    ]

    const table = <div className="table-adjust2">
        <ReactTable
            filterable
            className='-striped -highlight'
            showPaginationTop
            showPaginationBottom
            resizable={false}
            defaultPageSize={10}
            pageSizeOptions={[10, 20, 30]}
            data={data}
            columns={columns}
            getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                return {
                    onClick: (e) => {
                        if (rowInfo) {
                            if (rowInfo.original) {
                                const theMainData = rowInfo.original.mainData;
                                setMainData(theMainData)
                                if (theMainData.property_access === 'R') {
                                    setTab('View');
                                }
                                return setTab('Edit');
                            }
                        }
                    }
                }
                } else {
                return {}
                }
            }}
        />
    </div>

    let canEdit = mainData ?
            mainData.property_access === undefined ?
                true
            :
                mainData.property_access === 'R' ?
                    false
                :
                    true
        :
            true;

    let canDelete = mainData ?
        mainData.property_access === undefined ?
            true
        :
            mainData.property_access === 'R' || mainData.property_access === 'E' ?
                false
            :
                true
    :
        true;

    // if user is Agent and current tab is illi = ReadOnly
    let readOnly = false;
    if (user) {
        if (user.type !== 'E') {
            if (userType === 'illi') {
                canDelete = false;
                canEdit = false;
                readOnly = true;
            }
        }
    }

    const showForm = ((tab === 'View' || tab === 'Add' || tab === 'Edit') && mainData) ? true : false;

    const choose = (chosen) => {
        const md = clone(initialMeetingData);
        md.property_id = chosen.id;
        md.property_name = chosen.name;
        setMainData(md);
        setOpenedPropertyModal(false);
        setTab('Add');
    }

    return (
        <Card>
            <CardHeader color="rose" icon>
                  <CardIcon
                    color={tab === 'Add' ? 'success' : 'rose'}
                    onClick={() => {
                      setOpenedPropertyModal(true);
                      setTab('Add');
                    }}
                    className="hovered hovered-shadow"
                  >
                    <i className="fas fa-plus"></i> <strong>Add Meetings</strong>
                  </CardIcon>
            </CardHeader>
            <CardBody>
                <h1>Meetings</h1>
                <IconButton
                    onClick={() => {
                        props.getSubProperties();
                    }}
                >
                    <Cached />
                </IconButton>
                <hr />
                {user && <div>
                    {user.type !== 'E' && <div>
                        {allowIlliDBAccess && <Button 
                            onClick={() => { 
                                props.getSubProperties(true);
                                setUserType('illi');
                            }} 
                            style={{marginRight:20}} 
                            color={userType === 'illi' ? 'rose' : 'white'}
                        >ILLI DB</Button>}
                        <Button 
                            onClick={() => { 
                                props.getSubProperties(); 
                                setUserType('own');
                            }} 
                            color={userType === 'own' ? 'rose' : 'white'}
                        >MY DB</Button>
                    </div>}
                </div>}
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {(props.loading || !props.subProperties) && <div className="text-center">
                                <div style={{margin:'0 auto',display:'inline-block'}}><FadeLoader /></div>
                            </div>}
                        {(tab === 'List' && props.subProperties) && <div>
                            {props.subProperties.length > 0 && table}
                            {(props.subProperties.length === 0 && !props.loading) && <div>No Meetings found</div>}
                        </div>}

                    {showForm && <Dialog
                        open={showForm}
                        keepMounted
                        maxWidth={false}
                        fullWidth={true}
                        fullScreen={true}
                    >
                      <div className="dialog-wrapper">
                        {showForm && <MeetingForm
                            meetingData={mainData}
                            action={readOnly ? 'View' : tab}
                            setTab={(t) => {
                                if (t === 'List') {
                                    props.getSubProperties();
                                }
                                setTab(t);
                            }}
                            canEdit={canEdit}
                            canDelete={canDelete}
                            getContacts={props.getContacts}
                            contactsData={props.contactsData}
                            setMsgsForAdd={(e) => {
                                setSuccessMsg(e.success);
                                setErrorMsg(e.error);
                            }}

                            updateMeeting={props.updateMeeting}
                            createMeeting={props.createMeeting}
                            deleteMeeting={props.deleteMeeting}
                            getMeetings={() => {
                                //props.getMeeting(mainData.id);
                            }}
                            propertyID={mainData.property_id}
                            property_name={mainData.property_name}
                            error={errorMsg}
                            success={successMsg}

                            setOpenedAsk={setOpenedAsk}
                            showCloseBtn={true}

                            fileList={props.fileList}
                            downloadedFile={props.downloadedFile}
                            createCrmFile={props.createCrmFile}
                            getCrmFileList={props.getCrmFileList}
                            getCrmFile={props.getCrmFile}
                            deleteCrmFile={props.deleteCrmFile}
                            contactSuccess={props.contactSuccess}
                            contactError={props.contactError}
                            contactLoading={props.contactLoading}
                            user={user}
                            setUserType={setUserType}
                        />}
                        </div>
                    </Dialog>}

                        {openedAsk && <CustomDialogClose
                            ok_go={() => {
                                setTab('List');
                            }}
                            open={openedAsk}
                            close={() => {
                                setOpenedAsk(false);
                            }}
                        />}
                    </GridItem>
                </GridContainer>
            </CardBody>

            {openedPropertyModal && <CustomDialogWithProperty
                open={openedPropertyModal}
                close={() => {
                    setOpenedPropertyModal(false);
                }}
                create_and_choose={choose}
                choose={choose}
                title="Search Existing Property"
                label="Search Existing Property"
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        subProperties: p.subProperties,
        error: p.error,
        success: p.success,
        loading: p.loading,
        contactsData: c.contactsData,
        meetingsData: p.meetingsData,
        meetingData: p.meetingData,
        loading: p.loading,
        propertyNames: p.propertyNames,
        fileList: c.fileList,
        downloadedFile: c.downloadedFile,
        contactError: c.error,
        contactSuccess: c.success,
        contactLoading: c.loading
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getSubProperties: (illi_db) => {
            dispatch(actions.getSubProperties({
                single: 'meeting',
                plural: 'meetings'
            }, illi_db));
        },
        getMeetings: (property_id) => {
            dispatch(actions.getMeetings(property_id));
        },
        getMeeting: (id) => {
            dispatch(actions.getMeeting(id));
        },
        createMeeting: (data) => {
            dispatch(actions.createMeeting(data));
        },
        updateMeeting: (data) => {
            dispatch(actions.updateMeeting(data));
        },
        deleteMeeting: (id) => {
            dispatch(actions.deleteMeeting(id));
        },
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getPropertyNames: () => {
            dispatch(actions.getPropertyNames());
        },
        getCrmFileList: (data) => {
            dispatch(actions.getCrmFileList(data));
        },
        createCrmFile: (data) => {
            dispatch(actions.createCrmFile(data));
        },
        getCrmFile: (data) => {
            dispatch(actions.getCrmFile(data));
        },
        deleteCrmFile: (data) => {
            dispatch(actions.deleteCrmFile(data));
        }
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(MeetingMain);