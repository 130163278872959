export const listingFields = {
	title: {
		type: 'input',
		label: 'Title',
		validations: ['required'],
		required: true,
		showHistory: true,
	},
	date: {
		type: 'date',
		label: 'Date',
		validations: ['required'],
		required: true,
	},
	address: {
		type: 'input',
		label: 'Address',
		validations: ['min5', 'max250', 'required'],
		required: true,
	},
	illi: {
		type: 'input',
		label: 'illi Number',
		validations: ['required'],
	},
	submitted: {
		type: 'input',
		label: 'Submitted By',
		validations: ['required'],
		required: true,
	},
	tag: {
		type: 'input',
		label: 'Tagline/Title of Posting',
		validations: ['required'],
		required: true,
	},
	street: {
		type: 'input',
		label: 'Address',
	},
	city: {
		type: 'input',
		label: 'City',
	},
	state: {
		type: 'input',
		label: 'State',
	},
	zip: {
		type: 'input',
		label: 'Zip',
	},
	cross: {
		type: 'input',
		label: 'Cross Street Address',
	},
	signed: {
		type: 'select',
        label: 'ERTL Signed',
        options: ['Yes', 'No'],
        validations: ['required']
	},
	expire: {
		type: 'date',
		label: 'ERTL Expiration',
	},
	renew: {
		type: 'select',
        label: 'Auto Renewal',
        options: ['Yes', 'No'],
	},
	space_unit: {
		type: 'input',
		label: 'Space Unit Number',
	},
	space_sf: {
		type: 'input',
		label: 'Square Feet',
	},
	space_rent: {
		type: 'input',
		label: 'Rent Per SF',
	},
	space_date: {
		type: 'date',
		label: 'Date Available',
	},
	space_lease: {
		type: 'input',
		label: 'Lease Type',
	},
	space_nnn: {
		type: 'input',
		label: 'Monthly NNN',
	},
	space_sub: {
		type: 'input',
		label: 'Sub Lease',
	},
	space_min: {
		type: 'input',
		label: 'Min Divisible',
	},
	space_max: {
		type: 'input',
		label: 'Max Contiguous',
	},
	space_term: {
		type: 'input',
		label: 'Lease Term (Months)',
	},
	space_prop: {
		type: 'input',
		label: 'Property Type',
	},
	space_bo: {
		type: 'input',
		label: 'Build Out',
	},
	property_detail: {
		type: 'input',
		label: 'Property Details',
	},
	property_amenity: {
		type: 'input',
		label: 'Area Amenities',
	},
	property_parking: {
		type: 'input',
		label: 'Parking Details',
	},
	property_addition: {
		type: 'input',
		label: 'ADDITIONAL HIGHLIGHTS (SEARCH TAGS)',
	},

	// contact_agent1: {
	// 	type: 'input',
	// 	label: 'Primary Agent',
	// },
	// contact_agent2: {
	// 	type: 'input',
	// 	label: 'Agent 2',
	// },
	// contact_agent3: {
	// 	type: 'input',
	// 	label: 'Agent 3',
	// },
	// contact_agent4: {
	// 	type: 'input',
	// 	label: 'Agent 4',
	// },

	illi2: {
		type: 'input',
		label: 'illi Number',
	},

	project_project: {
		label: 'Project Name',
		type: 'input'
	},
	project_street: {
		type: 'input',
		label: 'Address',
	},
	project_city: {
		type: 'input',
		label: 'City',
	},
	project_state: {
		type: 'input',
		label: 'State',
	},
	project_zip: {
		type: 'input',
		label: 'Zip',
	},
	project_cross: {
		type: 'input',
		label: 'Cross Street Address',
	},
	project_signed: {
		type: 'select',
        label: 'ERTL Signed',
        options: ['Yes', 'No'],
        validations: ['required']
	},
	project_expire: {
		type: 'date',
		label: 'ERTL Expiration',
	},
	project_renew: {
		type: 'select',
        label: 'Auto Renewal',
        options: ['Yes', 'No'],
	},

	total_com: {
		type: 'input',
        label: 'Total Comm.',
		validations: ['no_letters', 'equal100'],
		required: true,
		canEdit: false,
	},

	contact2_ref: {
        type: 'input',
        label: 'Referrals'
    },
	contact2_total: {
        type: 'input',
        label: 'Total'
    },

	date2: {
        label: 'Signing Date',
        type: 'date',
    },
	sig: {
        label: 'Signature',
        type: 'input',
    },
};

export const getListingStatusType = (status) => {
    const statusObj = {
        1: 'Draft',
		2: 'Pending',
        3: 'Approved',
        4: 'Declined', 
    }
    if (statusObj[status]) return statusObj[status];
    return null;
}
