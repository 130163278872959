import * as actionTypes from './actionTypes';

export const getEntries = (data) => {
    return {
        type: actionTypes.GET_ENTRIES,
        data
    }
}

export const getEntriesStart = () => {
    return {
        type: actionTypes.GET_ENTRIES_START
    }
}

export const getEntriesSuccess = (data) => {
    return {
        type: actionTypes.GET_ENTRIES_SUCCESS,
        data
    }
}

export const getEntriesFail = (err) => {
    return {
        type: actionTypes.GET_ENTRIES_FAIL,
        err
    }
}

export const getEntriesByStatus = (data, own) => {
    return {
        type: actionTypes.GET_ENTRIES_BY_STATUS,
        data,
        own
    }
}

export const getEntriesByStatusStart = () => {
    return {
        type: actionTypes.GET_ENTRIES_BY_STATUS_START
    }
}

export const getEntriesByStatusSuccess = (data) => {
    return {
        type: actionTypes.GET_ENTRIES_BY_STATUS_SUCCESS,
        data
    }
}

export const getEntriesByStatusFail = (err) => {
    return {
        type: actionTypes.GET_ENTRIES_BY_STATUS_FAIL,
        err
    }
}

export const createEntry = (data, templateText) => {
    return {
        type: actionTypes.CREATE_ENTRY,
        data,
        templateText
    }
}

export const createEntryStart = () => {
    return {
        type: actionTypes.CREATE_ENTRY_START
    }
}

export const createEntrySuccess = (response) => {
    return {
        type: actionTypes.CREATE_ENTRY_SUCCESS,
        response
    }
}

export const createEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_ENTRY_FAIL,
        err
    }
}

export const updateEntryStatus = (data, templateText, template_id) => {
    return {
        type: actionTypes.UPDATE_ENTRY_STATUS,
        data,
        templateText,
        template_id
    }
}

export const updateEntryStatusStart = () => {
    return {
        type: actionTypes.UPDATE_ENTRY_STATUS_START
    }
}

export const updateEntryStatusSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_ENTRY_STATUS_SUCCESS,
        response
    }
}

export const updateEntryStatusFail = (err) => {
    return {
        type: actionTypes.UPDATE_ENTRY_STATUS_FAIL,
        err
    }
}

export const getEntryPdf = (data) => {
    return {
        type: actionTypes.GET_ENTRY_PDF,
        data
    }
}

export const getEntryPdfStart = () => {
    return {
        type: actionTypes.GET_ENTRY_PDF_START
    }
}

export const getEntryPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_ENTRY_PDF_SUCCESS,
        response
    }
}

export const getEntryPdfFail = (err) => {
    return {
        type: actionTypes.GET_ENTRY_PDF_FAIL,
        err
    }
}

// DELETE_ERTL_ENTRY 

export const deleteErtlEntry = (data) => {
    return {
        type: actionTypes.DELETE_ERTL_ENTRY,
        data
    }
}

export const deleteErtlEntryStart = () => {
    return {
        type: actionTypes.DELETE_ERTL_ENTRY_START
    }
}

export const deleteErtlEntrySuccess = (response) => {
    return {
        type: actionTypes.DELETE_ERTL_ENTRY_SUCCESS,
        response
    }
}

export const deleteErtlEntryFail = (err) => {
    return {
        type: actionTypes.DELETE_ERTL_ENTRY_FAIL,
        err
    }
}

// GET_ERTL_HISTORY 

export const getErtlHistory = (data, entry_id) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY,
        data,
        entry_id
    }
}

export const getErtlHistoryStart = () => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_START
    }
}

export const getErtlHistorySuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_SUCCESS,
        response
    }
}

export const getErtlHistoryFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_FAIL,
        err
    }
}

// GET_ERTL_PDF 

export const getErtlPdf = (data) => {
    return {
        type: actionTypes.GET_ERTL_PDF,
        data
    }
}

export const getErtlPdfStart = () => {
    return {
        type: actionTypes.GET_ERTL_PDF_START
    }
}

export const getErtlPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_PDF_SUCCESS,
        response
    }
}

export const getErtlPdfFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_PDF_FAIL,
        err
    }
}

// GET_ERTL_HISTORY_ENTRY 

export const getErtlHistoryEntry = (data) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_ENTRY,
        data
    }
}

export const getErtlHistoryEntryStart = () => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_ENTRY_START
    }
}

export const getErtlHistoryEntrySuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_ENTRY_SUCCESS,
        response
    }
}

export const getErtlHistoryEntryFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_HISTORY_ENTRY_FAIL,
        err
    }
}

// GET_ERTL_URL 

export const getErtlUrl = (data) => {
    return {
        type: actionTypes.GET_ERTL_URL,
        data
    }
}

export const getErtlUrlStart = () => {
    return {
        type: actionTypes.GET_ERTL_URL_START
    }
}

export const getErtlUrlSuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_URL_SUCCESS,
        response
    }
}

export const getErtlUrlFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_URL_FAIL,
        err
    }
}

// GET_CLIENT_ERTL 

export const getClientErtl = (data) => {
    return {
        type: actionTypes.GET_CLIENT_ERTL,
        data
    }
}

export const getClientErtlStart = () => {
    return {
        type: actionTypes.GET_CLIENT_ERTL_START
    }
}

export const getClientErtlSuccess = (response) => {
    return {
        type: actionTypes.GET_CLIENT_ERTL_SUCCESS,
        response
    }
}

export const getClientErtlFail = (err) => {
    return {
        type: actionTypes.GET_CLIENT_ERTL_FAIL,
        err
    }
}

// UPDATE_CLIENT_ERTL_ENTRY 

export const updateClientErtlEntry = (data) => {
    return {
        type: actionTypes.UPDATE_CLIENT_ERTL_ENTRY,
        data
    }
}

export const updateClientErtlEntryStart = () => {
    return {
        type: actionTypes.UPDATE_CLIENT_ERTL_ENTRY_START
    }
}

export const updateClientErtlEntrySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_CLIENT_ERTL_ENTRY_SUCCESS,
        response
    }
}

export const updateClientErtlEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_CLIENT_ERTL_ENTRY_FAIL,
        err
    }
}


// SEND_ERTL_REVIEW_LINK 

export const sendErtlReviewLink = (data) => {
    return {
        type: actionTypes.SEND_ERTL_REVIEW_LINK,
        data
    }
}

export const sendErtlReviewLinkStart = () => {
    return {
        type: actionTypes.SEND_ERTL_REVIEW_LINK_START
    }
}

export const sendErtlReviewLinkSuccess = (response) => {
    return {
        type: actionTypes.SEND_ERTL_REVIEW_LINK_SUCCESS,
        response
    }
}

export const sendErtlReviewLinkFail = (err) => {
    return {
        type: actionTypes.SEND_ERTL_REVIEW_LINK_FAIL,
        err
    }
}

// GET_ERTL_ENTRY 

export const getErtlEntry = (data) => {
    return {
        type: actionTypes.GET_ERTL_ENTRY,
        data
    }
}

export const getErtlEntryStart = () => {
    return {
        type: actionTypes.GET_ERTL_ENTRY_START
    }
}

export const getErtlEntrySuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_ENTRY_SUCCESS,
        response
    }
}

export const getErtlEntryFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_ENTRY_FAIL,
        err
    }
}

// GET_ERTL_APPROVERS 

export const getErtlApprovers = (data) => {
    return {
        type: actionTypes.GET_ERTL_APPROVERS,
        data
    }
}

export const getErtlApproversStart = () => {
    return {
        type: actionTypes.GET_ERTL_APPROVERS_START
    }
}

export const getErtlApproversSuccess = (response) => {
    return {
        type: actionTypes.GET_ERTL_APPROVERS_SUCCESS,
        response
    }
}

export const getErtlApproversFail = (err) => {
    return {
        type: actionTypes.GET_ERTL_APPROVERS_FAIL,
        err
    }
}

// UPDATE_ERTL 

export const updateErtl = (data) => {
    return {
        type: actionTypes.UPDATE_ERTL,
        data
    }
}

export const updateErtlStart = () => {
    return {
        type: actionTypes.UPDATE_ERTL_START
    }
}

export const updateErtlSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_ERTL_SUCCESS,
        response
    }
}

export const updateErtlFail = (err) => {
    return {
        type: actionTypes.UPDATE_ERTL_FAIL,
        err
    }
}
