import React from 'react';
import Table from "components/Table/Table.jsx";
import SortButton from "components/SortButton/SortButton.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ClipLoader from 'react-spinners/ClipLoader';

const CompaniesListTable = props => {
    
    const { 
        setSort,
        sortDirection,
        sortBy
    } = props;
    return <div>
        { <Table
          tableHeaderColor="primary"
		  tableHead={[
            '#',
            <strong>Company Name
                <SortButton
                    setSort={setSort}
                    sortBy='company_name'
                    sortDirection={sortDirection}
                    currentSortBy={sortBy}
                />
            </strong>,
            <strong>Main Phone</strong>,
			<strong>Created
				<SortButton
					setSort={setSort}
					sortBy='created_date'
					sortDirection={sortDirection}
                    currentSortBy={sortBy}
				/>
			</strong>
        ]}
          tableData={props.dataArr}
        />}
    </div>
}

export default CompaniesListTable;