import React, { useEffect, useState } from "react";
import { concat, get, set } from "lodash";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { Dialog, FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import * as actions from "store/actions";

import Button from "components/CustomButtons/Button";
import CustomDialogWithContact from "components/CustomDialog/CustomDialogWithContact";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomTextArea from "components/CustomTextArea/CustomTextArea.jsx";
import CustomInputStates from "../../components/CustomInput/CustomInputStates";
import CustomInput from "../../components/CustomInput/CustomInputValidate";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";

import { clone } from "shared/utility";
import {
    email,
    max10,
    max100,
    max15,
    max20,
    max250,
    max4,
    max50,
    max500,
    min1,
    min10,
    min4,
    min6,
    no_letters,
    no_numbers,
    no_special_chars,
    required,
	percentage,
} from "shared/validation";

import { checkIfRangesExist, shouldShowWarningAndResetRanges } from "./RSheetsLogic";
import RSheetsEditArray from "./RSheetsEditArray";

import Term from "./CommonCustomElements/Term/Term";
import TermForAbated from "./CommonCustomElements/Term/TermForAbated";

import {
    commissionFields,
    initialLesseeData as commissionInitLesseeData,
    initialLessorData as commissionInitLessorData,
    subFields as commissionSubFields,
} from "./Commission/RSheetsCommissionModel";
import {
    residentialFields,
    initialLesseeData as residentialInitLesseeData,
    initialLessorData as residentialInitLessorData,
    subFields as residentialSubFields,
} from "./Residential/RSheetsResidentialModel";
import {
    noListingFields,
    initialLesseeData as noListingInitLesseeData,
    initialLessorData as noListingInitLessorData,
    subFields as noListingSubFields,
} from "./NoListing/RSheetsNoListingModel";
import {
    initialLesseeData as leaseInitLesseeData,
    initialLessorData as leaseInitLessorData,
    subFields as leaseSubFields,
    leaseFields,
} from "./Lease/RSheetsLeaseModel";
import {
    initialLesseeData as subLeaseInitLesseeData,
    initialLessorData as subLeaseInitLessorData,
    subFields as subLeaseSubFields,
    subLeaseFields,
} from "./Sublease/RSheetsSubleaseModel";
import {
    initialLesseeData as tenantRepInitLesseeData,
    initialLessorData as tenantRepInitLessorData,
    subFields as tenantRepSubFields,
    tenantRepFields,
} from "./TenantRep/RSheetsTenantRepModel";
import {
    initialLesseeData as consultingInitLesseeData,
    initialLessorData as consultingInitLessorData,
    subFields as consultingSubFields,
    consultingFields,
} from "./Consulting/RSheetsConsultingModel";
import {
    initialLesseeData as leaseAssignmentFeeInitLesseeData,
    initialLessorData as leaseAssignmentFeeInitLessorData,
    subFields as leaseAssignmentFeeSubFields,
    leaseAssignmentFeeFields,
} from "./LeaseAssignmentFee/RSheetsLeaseAssignmentFeeModel";
import {
    initialLesseeData as saleInitLesseeData,
    initialLessorData as saleInitLessorData,
    subFields as saleSubFields,
    saleFields,
} from "./Sale/RSheetsSaleModel";
import { Suspense } from "react";
import FileUploader from "./CommonCustomElements/FileUploader";
import { extractContactAddress } from "../../shared/utility";
import CustomDate from "../../components/CustomDate/CustomDate";
import Notification from "../../components/Notification/Notification";
import NotificationAsk from "../../components/Notification/NotificationAsk";

const shouldWarn = (data) => {
    console.log('checking if data should warn', data);
    // warn if changing aspects of the lease term
    let warn = false;
    if (data.lease_term) {
        if (data.lease_term.length > 0) {
            warn = true;
        }
    }
    if (data.abated_rent_ranges) {
        if (data.abated_rent_ranges.length > 0) {
            warn = true;
        }
    }
    return warn;
}

const sheetObj = {
    Lease: leaseFields,
    Consulting: consultingFields,
    Commission: commissionFields,
    Residential: residentialFields,
    NoListing: noListingFields,
    SubLease: subLeaseFields,
    TenantRep: tenantRepFields,
    LeaseAssignmentFee: leaseAssignmentFeeFields,
    Sale: saleFields,
};

const sheetSubObj = {
    Lease: {
        initialLesseeData: leaseInitLesseeData,
        initialLessorData: leaseInitLessorData,
        subFields: leaseSubFields,
    },
    Commission: {
        initialLesseeData: commissionInitLesseeData,
        initialLessorData: commissionInitLessorData,
        subFields: commissionSubFields,
    },
    Residential: {
        initialLesseeData: residentialInitLesseeData,
        initialLessorData: residentialInitLessorData,
        subFields: residentialSubFields,
    },
    NoListing: {
        initialLesseeData: noListingInitLesseeData,
        initialLessorData: noListingInitLessorData,
        subFields: noListingSubFields,
    },
    SubLease: {
        initialLesseeData: subLeaseInitLesseeData,
        initialLessorData: subLeaseInitLessorData,
        subFields: subLeaseSubFields,
    },
    TenantRep: {
        initialLesseeData: tenantRepInitLesseeData,
        initialLessorData: tenantRepInitLessorData,
        subFields: tenantRepSubFields,
    },
    Consulting: {
        initialLesseeData: consultingInitLesseeData,
        initialLessorData: consultingInitLessorData,
        subFields: consultingSubFields,
    },
    LeaseAssignmentFee: {
        initialLesseeData: leaseAssignmentFeeInitLesseeData,
        initialLessorData: leaseAssignmentFeeInitLessorData,
        subFields: leaseAssignmentFeeSubFields,
    },
    Sale: {
        initialLesseeData: saleInitLesseeData,
        initialLessorData: saleInitLessorData,
        subFields: saleSubFields,
    },
};

const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500,
	percentage,
};

export const renderLabel = (field, data, subType, subFields, overwriteLabel = null) => {
    return (
        <div>
            <strong>{overwriteLabel ? overwriteLabel : get(subFields, `[${subType}][${field}].label`, '')}</strong> : {data[field]}
        </div>
    );
};

const RSheetsFormElement = (props) => {
    const {
        data,
        field,
        updateDataObj,
        updateDataMultiObj,
        sheetType,
        setData,
        access,
        warning,
        extraComponent, // commissionField - overwrite flat commission
        extraValidations = [],
        components,
        associatedWithCompany = null,
        notAssociatedWithCompany = false,
        calcs,
        canEditInvoiceRelatedData
    } = props;

    // const { FileLoading, FileUpload, InvoicePayment, Lessees, Lessors, Term } = components;
    const { InvoicePayment, Lessees, Lessors } = components;
    const subs = sheetSubObj[sheetType];
    const { initialLesseeData, initialLessorData, subFields } = subs;

    const [contactModalOpen, setContactModalOpen] = useState(false);
    const [input, setInput] = useState("");
    const [queryFor, setQueryFor] = useState("");
    const [editArray, setEditArray] = useState(false);
    const [dataFromArray, setDataFromArray] = useState({});
    const [uploadModalOpen, setUploadModalOpen] = useState(null);
    const [openExtraDialog, setOpenExtraDialog] = useState(false);
    const [fieldObj, setFieldObj] = useState(null);
    const [openedDate, setOpenedDate] = useState(false);
    const [modalDate, setModalDate] = useState("");
    const [oldValue, setOldValue] = useState({});
    const [notification, setNotification]  = useState('');
    const [notificationAsk, setNotificationAsk] = useState(null);
    const model = sheetObj[sheetType];

    const [modalData, setModalData] = useState({});

    const updateModalData = (field, value) => {
        const newModalData = clone(modalData);
        newModalData[field] = value;
        setModalData(newModalData);
    };

    const getValidations = (f) => {
        let validations = [];
        if (model[field].validations) {
            validations = sheetObj[sheetType][f].validations;
        }
        validations = concat(validations, extraValidations);
        return validations.map((v) => validate[v]);
    };

    useEffect(() => {
        setModalDate("");
    }, []);

    useEffect(() => {
        if (openedDate) {
            if (model[field] && model[`${field}_estimated`] && model[`${field}_estimated_tbd`]) {
                const newModalData = {
                    [field]: data[field],
                    [`${field}_estimated`]: data[`${field}_estimated`],
                    [`${field}_estimated_tbd`]: data[`${field}_estimated_tbd`],
                };
                setModalData(newModalData);
                setModalDate(newModalData[field]);
                setOldValue(newModalData);
            }
        }
    }, [openedDate]);

    const checkRentCommencement = () => {
        let pass = true;
        if (data.lease_start_date_estimated_tbd || data.rent_commencement_estimated_tbd) return true;
        if (data.rent_commencement && data.lease_start_date) {
            if (
                moment(data.rent_commencement, "MM/DD/YYYY", true).isValid() &&
                moment(data.lease_start_date, "MM/DD/YYYY", true).isValid()
            ) {
                const timestamp1 = moment(data.rent_commencement, "MM/DD/YYYY", true).unix();
                const timestamp2 = moment(data.lease_start_date, "MM/DD/YYYY", true).unix();
                if (timestamp2 > timestamp1) {
                    pass = false;
                }
            }
        }
        return pass;
    };

    const checkLeaseSignedDate = () => {
        let pass = true;
        if (data.lease_start_date_estimated_tbd) return true;
        if (data.lease_start_date && data.date_lease_signed) {
            if (
                moment(data.lease_start_date, "MM/DD/YYYY", true).isValid() &&
                moment(data.date_lease_signed, "MM/DD/YYYY", true).isValid()
            ) {
                const timestamp1 = moment(data.lease_start_date, "MM/DD/YYYY", true).unix();
                const timestamp2 = moment(data.date_lease_signed, "MM/DD/YYYY", true).unix();
                if (timestamp2 > timestamp1) {
                    pass = false;
                }
            }
        }
        return pass;
    };

    // Use for Date2 form submit. No warning or resetting ranges logic here
    const getNewModalData = () => {
        const newModalData = clone(modalData);
        newModalData[field] = modalDate;
        if (newModalData.lease_start_date_estimated) {
            newModalData.rent_commencement_estimated = "Rent Commencement Estimated";
            newModalData.lease_end_date = "";
            newModalData.occupancy_date_estimated = "Occupancy Date Estimated";
        }
        newModalData.lease_length = get(data, 'lease_length', '');
        return newModalData;
    };

    useEffect(() => {
        if (field === "lessee_contacts" || field === "lessor_contacts") {
            if (field === queryFor) {
                if (data[field]) {
                    let darr = clone(data[field]);
                    for (let i = 0; i < darr.length; i++) {
                        // found
                        if (darr[i].id == props.contactData?.id) {
                            if (props.contactData.addresses) {
                                for (let x = 0; x < props.contactData.addresses.length; x++) {
                                    const pcd = props.contactData.addresses[x];

                                    if (pcd.type === "M") {
                                        const newObj = clone(darr[i]);
                                        if (field === "lessee_contacts") {
                                            Object.keys(initialLesseeData).forEach((ild) => {
                                                if (ild !== "id" && ild !== "name") {
                                                    newObj[ild] = initialLesseeData[ild];
                                                }
                                            });
                                        } else if (field === "lessor_contacts") {
                                            Object.keys(initialLessorData).forEach((ild) => {
                                                if (ild !== "id" && ild !== "name") {
                                                    newObj[ild] = initialLesseeData[ild];
                                                }
                                            });
                                        }
                                        console.log('contact chosen for less...', props.contactData);
                                        newObj.name = props.contactData.first_name;
                                        newObj.last_name = props.contactData.last_name;
                                        newObj.contact_type = props.contactData.contact_type;
                                        newObj.address1 = pcd.street1;
                                        newObj.email = props.contactData.email;
                                        newObj.main_phone = props.contactData.main_phone;
                                        darr[i] = newObj;
                                    }
                                }
                            }
                        }
                    }

                    setQueryFor("");
                    updateDataObj(field, darr);
                }
            }
        } else if (field === "referral_firm") {
            if (field === queryFor) {
                if (data[field]) {
                    let darr = clone(data[field]);
                    for (let i = 0; i < darr.length; i++) {
                        // found
                        if (darr[i].id == props.contactData.id) {
                            const newObj = clone(darr[i]);
                            darr[i] = newObj;
                        }
                    }

                    setQueryFor("");
                    updateDataObj(field, darr);
                }
            }
        }
    }, [props.contactData]);

    let element = null;

    const fieldValidations = model[field]?.validations;
    let isRequired = fieldValidations?.includes("required") ?? false;
    if (extraValidations) {
        if (extraValidations.length > 0) {
            if (extraValidations.indexOf('required') !== -1) isRequired = true;
        }
    }

    if (!model[field]) return null;

    if (model[field].type === "textarea") {
        let the_label = model[field].label;
        if (props.overrideLabel) {
            the_label = props.overrideLabel;
        }
        element = (
            <CustomTextArea
                label={the_label}
                value={data[field] ? data[field] : ""}
                onChange={(e) => {
                    updateDataObj(field, e.target.value);
                }}
                validate={getValidations(field)}
            />
        );
    } else if (model[field].type === "input") {
        let the_label = model[field].label;
        if (props.overrideLabel) {
            the_label = props.overrideLabel;
        }
        const inputType = get(model, `[${field}].inputType`, '');
        const isCurrency = inputType === 'number' ? true : false;
        element = (
            <CustomInput
                label={the_label}
                value={data[field] ? data[field] : ""}
                onChange={(e) => {
                    updateDataObj(field, e.target.value);
                }}
                validate={getValidations(field)}
                readonly={model[field].readonly || access === "no" ? true : false}
                readonly_show_gray={true}
                startAdornment={model[field].prefix ? model[field].prefix : false}
                isDollar={model[field].dollar ? true : false}
                endAdornment={model[field].suffix ? model[field].suffix : false}
                isCurrency={isCurrency}
            />
        );
    } else if (model[field].type === "input2") {
        let the_label = model[field].label;
        if (props.overrideLabel) {
            the_label = props.overrideLabel;
        }
        element = (
            <>
                <CustomInput
                    label={the_label}
                    value={data[field] ? data[field] : ""}
                    onChange={(e) => {
                        if (warning && checkIfRangesExist(data)) {
                            setInput(e.target.value);
                            setOpenExtraDialog(true);
                        } else {
                            updateDataObj(field, e.target.value);
                        }
                    }}
                    validate={getValidations(field)}
                    readonly={model[field].readonly || access === "no" ? true : false}
                    readonly_show_gray={true}
                    startAdornment={model[field].prefix ? model[field].prefix : false}
                    isDollar={model[field].dollar ? true : false}
                    endAdornment={model[field].suffix ? model[field].suffix : false}
                />
                {openExtraDialog && warning && (
                    <Dialog
                        open={openExtraDialog}
                        onClose={() => {
                            setOpenExtraDialog(false);
                        }}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                        <div className="dialog-wrapper text-center">
                            <h3>{warning}</h3>
                            <hr />
                            <Button
                                className="mr-20"
                                color="primary"
                                onClick={() => {
                                    updateDataObj(field, input);
                                    setOpenExtraDialog(false);
                                }}
                            >
                                OK, UPDATE
                            </Button>
                            <Button
                                color="white"
                                onClick={() => {
                                    setOpenExtraDialog(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Dialog>
                )}
            </>
        );
    } else if (model[field].type === "date") {
        const date_is_valid = moment(data[field], "MM/DD/YYYY", true).isValid();
        const is_readonly =
            field === "lease_end_date" &&
            (data.lease_start_date_estimated || data.rent_commencement_estimated || data.occupancy_date_estimated);

        if (field === "lease_end_date" && is_readonly) {
            element = <CustomInput label={model[field].label} value={""} readonly_show_gray={true} readonly />;
        } else {
            const warn = shouldWarn(data);
            element = (
                <div className={`custom relative ${!is_readonly && isRequired && !data[field] ? "required-date" : ""}`}>
                    {data[field] && <div className="tiny-label">{model[field].label}</div>}
                    <div className="hoverable gray-text date-clear">
                        {data[field] && (
                            <small
                                onClick={() => {
                                    updateDataObj(field, "");
                                }}
                            >
                                clear
                            </small>
                        )}
                    </div>
                    <Datetime
                        inputProps={{
                            placeholder: model[field].label,
                            disabled: is_readonly,
                            style: {
                                color: !is_readonly ? "#999" : "#555",
                            },
                        }}
                        closeOnSelect={true}
                        onChange={(e) => {
                             let stringDate = e;
                            if (typeof e === "object") {
                                stringDate = e.format("MM/DD/YYYY");
                            }
                            if (field === 'lease_end_date') {
                                // prevent end date being before or equal to lease_start_date
                                if (moment(stringDate, "MM/DD/YYYY", true).isValid()) {

                                }
                            }

                            if (warn) {
                                setNotificationAsk([
                                    'Modifying will reset Lease Term and/or Abated Rent ranges',
                                    () => {
                                        updateDataMultiObj({
                                            lease_term: [],
                                            abated_rent_ranges: [],
                                            [field]: stringDate
                                        })
                                        setNotificationAsk(null);
                                    }
                                ])
                            } else {
                                updateDataObj(field, stringDate);
                            }

                            // if (warning && checkIfRangesExist(data)) {
                            //     setInput(stringDate);
                            //     return setOpenExtraDialog(true);
                            // } else {
                            //     return updateDataObj(field, stringDate);
                            // }
                        }}
                        timeFormat={false}
                        value={data[field] ? data[field] : ""}
                    />
                    {!is_readonly && isRequired && !data[field] && (
                        <div className="red-text">
                            <small>Required</small>
                        </div>
                    )}
                    {!is_readonly && !date_is_valid && data[field] && (
                        <div className="red-text mt-10">Invalid Date, please format to MM/DD/YYYY</div>
                    )}
                    {field === "lease_end_date" &&
                        !is_readonly &&
                        date_is_valid &&
                        moment(data.lease_end_date).isBefore(moment(data.lease_start_date)) && (
                            <div className="red-text mt-10">Lease End Date must be after Lease Start Date</div>
                        )}
                    {/* {field === "date_lease_signed" && (
                        <div>
                            {!checkLeaseSignedDate() && (
                                <div className="red-text">
                                    <small>Lease Signed Date must be before or equal to Lease Start Date</small>
                                </div>
                            )}
                        </div>
                    )} */}

                    {openExtraDialog && warning && (
                    <Dialog
                        open={openExtraDialog}
                        onClose={() => {
                            setOpenExtraDialog(false);
                        }}
                        fullWidth={true}
                        maxWidth="sm"
                    >
                        <div className="dialog-wrapper text-center">
                            <h3>{warning}</h3>
                            <hr />
                            <Button
                                className="mr-20"
                                color="primary"
                                onClick={() => {
                                    updateDataObj(field, input);
                                    setOpenExtraDialog(false);
                                }}
                            >
                                OK, UPDATE
                            </Button>
                            <Button
                                color="white"
                                onClick={() => {
                                    setOpenExtraDialog(false);
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </Dialog>
                )}
                </div>
            );
        }
    } else if (model[field].type === "date2") {
        const date_is_valid = moment(modalDate, "MM/DD/YYYY", true).isValid();
        const date_is_estimated = !!modalData[`${field}_estimated`];
        const estimatedField = model[`${field}_estimated`];
        const tbd = model[`${field}_estimated_tbd`];
        const tbdArr = modalData[`${field}_estimated_tbd`] ? modalData[`${field}_estimated_tbd`].split(",") : [];
        const estimatedArr = modalData[`${field}_estimated`] ? modalData[`${field}_estimated`].split(",") : [];
        const dataTbdArr = data[`${field}_estimated_tbd`] ? data[`${field}_estimated_tbd`].split(",") : [];

        const dateCompare = (start_date, end_date) => {
            let passed = true;
            if (start_date && end_date) {
                const start_date_mom = moment(start_date, 'MM/DD/YYYY');
                const end_date_mom = moment(end_date, 'MM/DD/YYYY');
                if (start_date_mom.isValid() && end_date_mom.isValid()) {
                    const sd_unix = start_date_mom.valueOf();
                    const ed_unix = end_date_mom.valueOf();
                    if (sd_unix >= ed_unix) {
                        passed = false;
                    }
                }
            }
            return passed;
        }
        console.log('RSheetsFormElement', props);
        element = (
            <div
                onFocus={() => {
                    setOpenedDate(true);
                }}
            >
                <CustomInput
                    label={model[field].label}
                    value={
                        data[`${field}_estimated`] && dataTbdArr.indexOf("TBD") !== -1
                            ? "TBD"
                            : data[field]
                            ? data[field]
                            : ""
                    }
                    onChange={() => {}}
                    onClick={() => {
                        setOpenedDate(true);
                        setModalDate(data[field] ? data[field] : "");
                    }}
                    endAdornment={
                        !!data[`${field}_estimated`] && data[field] && dataTbdArr.indexOf("TBD") === -1
                            ? "(Estimated)"
                            : ""
                    }
                />
                {openedDate && (
                    <Dialog
                        open={openedDate}
                        onClose={() => {
                            setOpenedDate(false);
                        }}
                        fullWidth={true}
                        maxWidth="md"
                    >
                        <form
                            className="dialog-wrapper"
                            style={{ minHeight: 400 }}
                            onSubmit={(e) => {
                                e.preventDefault();
                                const newModalData = getNewModalData()
                               
                                if (warning && shouldShowWarningAndResetRanges(oldValue, newModalData, data)) {
                                    return setOpenExtraDialog(true);
                                } else {
                                    updateDataMultiObj(newModalData);
                                    setOpenedDate(false);
                                }

                            }}
                        >
                            <h3>Enter {model[field].label}</h3>
                            {date_is_estimated ? (
                                <GridContainer>
                                    <GridItem xs={12} sm={6} md={6}>
                                        <Datetime
                                            inputProps={{
                                                placeholder: model[field].label,
                                                autoFocus: true,
                                                disabled: tbdArr.indexOf("TBD") !== -1,
                                                style: {
                                                    color: tbdArr.indexOf("TBD") !== -1 ? "#999" : "#555",
                                                },
                                            }}
                                            closeOnSelect={true}
                                            onChange={(e) => {
                                                if (typeof e === "object") {
                                                    const stringDate = e.format("MM/DD/YYYY");
                                                    return setModalDate(stringDate);
                                                }
                                                setModalDate(e);
                                                if (field === 'lease_start_date') {
                                                    const passed = dateCompare(data.lease_start_date, data.lease_end_date);
                                                    if (!passed) {
                                                        return setNotification('Lease start date and lease end date invalid.');
                                                    }
                                                }
                                                updateModalData(field, e);
                                            }}
                                            timeFormat={false}
                                            value={modalDate}
                                        />
                                    </GridItem>
                                    {tbd && (
                                        <GridItem xs={12} sm={6} md={6}>
                                            {tbd.label}
                                            <FormGroup row className="mt-15">
                                                {tbd.options.map((f, i) => {
                                                    return (
                                                        <FormControlLabel
                                                            key={`tbd-${i}`}
                                                            control={
                                                                <Checkbox
                                                                    checked={tbdArr.indexOf(f) !== -1}
                                                                    onChange={() => {
                                                                        const v =
                                                                            tbdArr.indexOf(f) !== -1
                                                                                ? tbdArr.filter((c) => {
                                                                                      if (c === f) return false;
                                                                                      return true;
                                                                                  })
                                                                                : tbdArr.concat([f]);
                                                                        updateModalData(
                                                                            `${field}_estimated_tbd`,
                                                                            v.join(",")
                                                                        );
                                                                    }}
                                                                    color="default"
                                                                />
                                                            }
                                                            label={f}
                                                        />
                                                    );
                                                })}
                                            </FormGroup>
                                        </GridItem>
                                    )}
                                </GridContainer>
                            ) : (
                                <Datetime
                                    inputProps={{
                                        placeholder: model[field].label,
                                        autoFocus: true,
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === "object") {
                                            const stringDate = e.format("MM/DD/YYYY");
                                            return setModalDate(stringDate);
                                        }
                                        setModalDate(e);
                                    }}
                                    timeFormat={false}
                                    value={modalDate}
                                />
                            )}
                            <Button
                                className="mr-20"
                                type="submit"
                                color="primary"
                                disabled={date_is_estimated ? false : date_is_valid ? false : true}
                            >
                                UPDATE
                            </Button>
                            <Button
                                color="white"
                                type="button"
                                onClick={() => {
                                    setOpenedDate(false);
                                }}
                            >
                                Cancel
                            </Button>
                            <hr />
                            {!date_is_estimated && !date_is_valid && modalDate && (
                                <div className="red-text mt-10">Invalid Date, please format to MM/DD/YYYY</div>
                            )}
                            {estimatedField && tbdArr.indexOf("TBD") === -1 && (
                                <div className={`group-wrap to-right`}>
                                    {estimatedField.label}
                                    <FormGroup row className="mt-15">
                                        {estimatedField.options.map((f, i) => {
                                            return (
                                                <FormControlLabel
                                                    key={`fc-${i}`}
                                                    control={
                                                        <Checkbox
                                                            checked={estimatedArr.indexOf(f) !== -1}
                                                            onChange={() => {
                                                                const v =
                                                                    estimatedArr.indexOf(f) !== -1
                                                                        ? tbdArr.filter((c) => {
                                                                              if (c === f) return false;
                                                                              return true;
                                                                          })
                                                                        : estimatedArr.concat([f]);
                                                                updateModalData(`${field}_estimated`, v.join(","));
                                                            }}
                                                            color="default"
                                                        />
                                                    }
                                                    label={f}
                                                />
                                            );
                                        })}
                                    </FormGroup>
                                </div>
                            )}

                            {openExtraDialog && warning && (
                                <Dialog
                                    open={openExtraDialog}
                                    onClose={() => {
                                        setOpenExtraDialog(false);
                                    }}
                                    fullWidth={true}
                                    maxWidth="sm"
                                >
                                    <div className="dialog-wrapper text-center">
                                        <h3>{warning}</h3>
                                        <hr />
                                        <Button
                                            className="mr-20"
                                            color="primary"
                                            disabled={date_is_valid ? false : true}
                                            onClick={() => {
                                                const newModalData = getNewModalData(modalData)
                                                updateDataMultiObj(newModalData);
                                                setOpenedDate(false);
                                                setOpenExtraDialog(false);
                                            }}
                                        >
                                            OK, UPDATE
                                        </Button>
                                        <Button
                                            color="white"
                                            onClick={() => {
                                                setOpenExtraDialog(false);
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </div>
                                </Dialog>
                            )}
                        </form>
                    </Dialog>
                )}
                {isRequired && !data[field] && !data[`${field}_estimated_tbd`] && (
                    <div className="red-text">
                        <small>Required</small>
                    </div>
                )}
                {/* {field === "rent_commencement" && !checkRentCommencement() && (
                    <div className="red-text">Date lease signed has to be the same or after Lease Start Date.</div>
                )} */}
            </div>
        );
    } else if (model[field].type === 'date3') {
        const is_readonly =
            field === "lease_end_date" &&
            (data.lease_start_date_estimated || data.rent_commencement_estimated || data.occupancy_date_estimated);
        // LEASE END DATE
        if (field === "lease_end_date" && is_readonly) {
            element = <CustomInput label={model[field].label} value={""} readonly_show_gray={true} readonly />;
        } else {
            element = <>
                <CustomDate 
                    label={model[field].label}
                    value={data[field]}
                    onChange={(theDate) => {
                        const warn = shouldWarn(data);
                        if (warn) {
                            setNotificationAsk([
                                'Modifying will reset Lease Term and/or Abated Rent ranges',
                                () => {
                                    updateDataMultiObj({
                                        lease_term: [],
                                        abated_rent_ranges: [],
                                        [field]: theDate
                                    })
                                    setNotificationAsk(null);
                                }
                            ])
                        } else {
                            updateDataObj('lease_end_date', theDate);
                        }
                    }}
                    shouldBeAfterDate={data.lease_start_date}
                />
            </>
        }
    } else if (model[field].type === "radio") {
        const style = { display:'block' };
        if (field === 'rate_type' && data.flat_fee_commission === 'Yes') {
            style.display = 'none';
        }
        element = (
            <div className={`${model[field].readonly ? "gray-bg-input" : ""}`}
                style={style}
            >
                <div className={`${isRequired && !data[field] ? "required-radio" : ""}`}>
                    <CustomRadio
                        label={[model[field].label].concat(model[field].options).join(",")}
                        options={model[field].options}
                        onChange={(e) => {
                            // TO DO: should data manipulation be available?
                            // console.log('updating data', field, e.target.value);
                            // if (updateDataMultiObj && field === 'flat_fee_commission') {
                            //     if (e.target.value === 'Yes') {
                            //         updateDataMultiObj({
                            //             flat_fee_commission: 'Yes',
                            //             rate_type: ''
                            //         })
                            //         return;
                            //     }
                            // }
                            updateDataObj(field, e.target.value);
                        }}
                        value={data[field]}
                        readonly={model[field].readonly ? true : false}
                    />
                    {isRequired && !data[field] && <div className="red-text mb-20 small-text">Required</div>}
                </div>
            </div>
        );
    } else if (model[field].type === "select") {
        element = (
            <div className={`${isRequired && !data[field] ? "required-date" : ""}`}>
                <CustomSelect
                    label={model[field].label}
                    options={model[field].options}
                    choose={(e, n) => {
                        updateDataObj(field, e);
                    }}
                    default={data[field]}
                />
                {isRequired && !data[field] && <div className="red-text small-text">Required</div>}
            </div>
        );
    } else if (model[field].type === "checkbox") {
        let dataArr = data[field] ? data[field].split(",") : [];

        element = (
            <div className={`group-wrap`}>
                {model[field].label}
                <FormGroup row className="mt-15">
                    {model[field].options.map((f, i) => {
                        return (
                            <FormControlLabel
                                key={`fc-${i}`}
                                control={
                                    <Checkbox
                                        disabled={get(model, `${field}.readonly`, false)}
                                        checked={dataArr.indexOf(f) !== -1}
                                        onChange={() => {
                                            const v =
                                                dataArr.indexOf(f) !== -1
                                                    ? dataArr.filter((c) => {
                                                          if (c === f) return false;
                                                          return true;
                                                      })
                                                    : dataArr.concat([f]);
                                            updateDataObj(field, v.join(","));
                                        }}
                                        color="default"
                                    />
                                }
                                label={f}
                            />
                        );
                    })}
                </FormGroup>
                {isRequired && !data[field] && <div className="red-text small-text">Required</div>}
            </div>
        );
    } else if (model[field].type === "file_upload") {
        element = (
        <div className="boxed">
            <div style={{marginBottom:10}}>{model[field].label}</div>
            <FileUploader 
                rs_filename={data[field]}
                update={(val) => {
                    updateDataObj(field, val);
                }}
                remove={() => {
                    updateDataObj(field, '');
                }}
            />
        </div>
        )
        // let button_text = "Upload File";
        // if (
        //     (props.rsheetFiles &&
        //         props.rsheetFiles.data &&
        //         props.rsheetFiles.data[data[field].slice(data[field].lastIndexOf("/") + 1)]) ||
        //     (props.formDataObj &&
        //         props.formDataObj["w9"] &&
        //         props.formDataObj["w9"][data[field].slice(data[field].lastIndexOf("/") + 1)]) ||
        //     data[field]
        // )
        //     button_text = "Replace File";
        // element = (
        //     <div className="boxed">
        //         <h4>{model[field].label}</h4>
        //         <FileLoading
        //             formDataObj={props.formDataObj}
        //             objName={"w9"}
        //             fileName={data[field].slice(data[field].lastIndexOf("/") + 1)}
        //             rsheetFiles={props.rsheetFiles}
        //             success={props.success}
        //             error={props.error}
        //             loading={props.loading}
        //         />
        //         {/* TODO: Add Replace File if it is already added. */}
        //         <Button
        //             color="success"
        //             onClick={() => {
        //                 setUploadModalOpen(true);
        //                 setFieldObj(model[field]);
        //             }}
        //         >
        //             {button_text}
        //         </Button>
        //         {uploadModalOpen && (
        //             <FileUpload
        //                 index={-1}
        //                 uploadModalOpen={uploadModalOpen}
        //                 setUploadModalOpen={setUploadModalOpen}
        //                 fieldObj={model[field]}
        //                 field={field}
        //                 data={data}
        //                 updateDataObj={updateDataObj}
        //                 formDataObj={props.formDataObj}
        //                 setFormDataObj={props.setFormDataObj}
        //                 contactData={props.contactData}
        //                 createCrmFile={props.createCrmFile}
        //                 downloadedFiles={props.downloadedFiles}
        //                 success={props.success}
        //                 error={props.error}
        //             />
        //         )}
        //     </div>
        // );
    } else if (model[field].type === "multi-contact-agent") {
        const agents = data[field] ? clone(data[field]) : [];
        const options = props.contactsData
            ? props.contactsData
                  .filter((a) => {
                      let found = false;
                      agents.forEach((aa) => {
                          if (aa.id === a.id) found = true;
                      });
                      if (found) return false; // remove existing agents
                      return true;
                  })
                  .map((a) => {
                      return {
                          id: a.id,
                          name: a.company_name ? a.company_name : `${a.first_name} ${a.last_name}`,
                      };
                  })
            : [];
        element = (
            <div>
                <Button
                    color="primary"
                    onClick={() => {
                        props.getContacts({
                            names_only: "Agent",
                        });
                        setQueryFor(field)
                        setContactModalOpen(true);
                    }}
                    style={{ marginTop: 20 }}
                >
                    <i className="fas fa-plus font-22"></i> Add Associate
                </Button>
                <div style={{ display: "inline-block" }}>
                    {agents.map((a, i) => {
                        return (
                            <span key={`adc-${i}`} className="tiny-spans">
                                {a.name} {a.last_name} {a?.dba_name ? `DBA ${a.dba_name} ` : ' '} 
                                {a?.dba_active === 0 && <span style={{color: 'red'}}>(removed)&nbsp;</span>}
                                <span
                                    className="hoverable"
                                    onClick={() => {
                                        let commission_splits_agent = get(data, "commission_splits_agent", []);
                                        let commission_splits_agent2 = get(data, "commission_splits_agent2", []);

                                        const newAgents = agents.filter((aa, ii) => {
                                            // if (ii === i) return false;
                                            // return true;
											return aa.id !== a.id;
                                        });
                                        if (field === "illi_represented_lessee_agents") {
                                            commission_splits_agent2 = commission_splits_agent2.filter(
                                                (l) => l.id !== a.id
                                            );
                                        } else if (field === "illi_represented_lessor_agents") {
                                            commission_splits_agent = commission_splits_agent.filter(
                                                (l) => l.id !== a.id
                                            );
                                        }

                                        const chosen = get(data, 'chosen');
                                        const contact_ids = get(data, `chosen.${field}.contact`, []).filter(id => id !== a.id);
                                        set(chosen, `${field}.contact`, contact_ids);

                                        updateDataMultiObj({
                                            [field]: newAgents,
                                            commission_splits_agent,
                                            commission_splits_agent2,
                                            chosen,
                                        });
                                    }}
                                >
                                    <i className="fas fa-trash-alt red-text"></i>
                                </span>
                            </span>
                        );
                    })}
                </div>
                {contactModalOpen && (
                    <CustomDialogChooser
                        showContactCompaniesTab
                        singleContactOnly
                        no_property
                        no_new_contact={true}
                        open={contactModalOpen}
                        close={() => {
                            setContactModalOpen(false);
                        }}
                        choose={(the_contact_data, filler, dba) => {
                            let contact = the_contact_data;

                            console.log(contact, dba);
                            // check if user picked a contact from a company
                            if (contact.signers && contact.signers.length > 0) {
                                contact = contact.signers[0];
                            }

                            const newAgents = clone(agents);
                            const found = newAgents.find((ag) => ag.id === contact.id);
                            if (!found) {
                                newAgents.push({
                                    id: contact.id,
                                    name: contact.first_name,
                                    last_name: contact.last_name,
                                    dba_name: dba?.name,
                                    dba_id: dba?.id,
                                    dba_type: 'contact',
                                });
                            }

                            const newData = clone(data);
                            newData[field] = newAgents;

                            const contact_ids = get(newData, `chosen.${queryFor}.contact`, [])
                            contact_ids.push(contact.id);

                            set(newData, `chosen.${queryFor}.contact`, contact_ids);
                            setData(newData);
                            
                            // updateDataObj(field, newAgents);
                            setContactModalOpen(false);
                        }}
                        peopleType="agents"
                        title="Select Agent"
                        label="Search Agents"
                        current={agents.map((a) => a.name).join(",")}
                    />
                )}
            </div>
        );
    } else if (model[field].type === "multi-contact") {
        console.log('multi-contactt')
        let agents = data[field] ? clone(data[field]) : [];
        agents = agents.filter((agent) => {
            if (field === "lessee_contacts" || field === "lessor_contacts") {
                const company_id = get(agent, "company_id", "")
                if (notAssociatedWithCompany) {
                    const fromCompany =  company_id && company_id.includes("c");
                    return !fromCompany
                } else if (associatedWithCompany) {
                    const fromCompany =
                        associatedWithCompany &&
                        company_id.includes("c") && company_id === associatedWithCompany;
                    return !fromCompany;
                } 
                return true
            }
             else {
                return true
            }
        });

        const renderAgents = () => {
            const list = [];
            for (let index in agents) {
                const agent = agents[index];
                
                let notFilter = false
                if (field === "lessee_contacts" || field === "lessor_contacts") {
                    const company_id = get(agent, "company_id", "")
                    if (notAssociatedWithCompany) {
                        const fromCompany =  company_id && company_id.includes("c");
                        notFilter = !fromCompany
                    } else if (associatedWithCompany) {
                        const fromCompany =
                            associatedWithCompany &&
                            company_id.includes("c") && company_id === associatedWithCompany;
                            notFilter = !fromCompany;
                    } 
                    notFilter = true
                }
                 else {
                    notFilter = true
                }

                if (notFilter) {
                    list.push(<li key={`adc-${index}`}>
                    <span
                        className="red-text hoverable mr-20"
                        onClick={() => {
                            const idToRemove = get(agents, `[${index}].id`, '');
                            const chosen = get(data, 'chosen');
                            const contact_ids = get(data, `chosen.${field}.contact`, []).filter(id => id !== idToRemove);
                            set(chosen, `${field}.contact`, contact_ids);
                            const newAgents = agents.filter((aa, ii) => {
                                if (parseInt(ii) === parseInt(index)) return false;
                                return true;
                            });
                            updateDataMultiObj({
                                [field]: newAgents,
                                chosen,
                            });
                        }}
                    >
                        <i className="fas fa-trash hoverable"></i>
                    </span>
                    <span
                        className="blue-text hoverable mr-20"
                        onClick={() => {
                            setDataFromArray({
                                index: index,
                                field: field,
                                data: agent,
                            });
                            setEditArray(true);
                        }}
                    >
                        <i className="fas fa-edit hoverable"></i>
                    </span>
                    <br />
                    {field === "lessee_contacts" && (
                        <div>
                            {renderLabel("name", agent, "lessee", subFields, "First Name")}
                            {renderLabel("last_name", agent, "lessee", subFields, "Last Name")}
                            {renderLabel("address1", agent, "lessee", subFields)}
                            {renderLabel("address2", agent, "lessee", subFields)}
                            {renderLabel("city", agent, "lessee", subFields)}
                            {renderLabel("state", agent, "lessee", subFields)}
                            {renderLabel("zip", agent, "lessee", subFields)}
                            {renderLabel("email", agent, "lessee", subFields)}
                            {/* {renderLabel('contact_type', a, 'lessee')} */}
                            {renderLabel("main_phone", agent, "lessee_contacts", subFields)}
                        </div>
                    )}
                    {field === "lessor_contacts" && (
                        <div>
                            {renderLabel("name", agent, "lessor", subFields, "First Name")}
                            {renderLabel("last_name", agent, "lessor", subFields, "Last Name")}
                            {renderLabel("address1", agent, "lessor", subFields)}
                            {renderLabel("address2", agent, "lessor", subFields)}
                            {renderLabel("city", agent, "lessor", subFields)}
                            {renderLabel("state", agent, "lessor", subFields)}
                            {renderLabel("zip", agent, "lessor", subFields)}
                            {renderLabel("email", agent, "lessor", subFields)}
                            {renderLabel("main_phone", agent, "lessor", subFields)}
                        </div>
                    )}
                    {(field === "deal_generated_by" || field === "listings_procured_by") && (
                        <div>
                            <strong>First Name : </strong> {agent.name}
                            <br />
                            <strong>Last Name : </strong> {agent.last_name}
                            <br />
                        </div>
                    )}
                    {field !== "listings_procured_by" &&
                        field !== "deal_generated_by" &&
                        field !== "lessee_contacts" &&
                        field !== "lessor_contacts" && (
                            <div>
                                <strong>First Name : </strong> {agent.name}
                                <br />
                                <strong>Last Name : </strong> {agent.last_name}
                                <br />
                                <strong>Main Phone : </strong> {agent.main_phone}
                                <br />
                            </div>
                        )}
                </li>)
                }

            }

            return list;
        }

        const options = props.contactsData
            ? props.contactsData
                  .filter((a) => {
                      let found = false;
                      agents.forEach((aa) => {
                          if (aa.id === a.id) found = true;
                      });
                      if (found) return false; // remove existing agents
                      return true;
                  })
                  .map((a) => {
                      return {
                          id: a.id,
                          name: a.first_name,
                          last_name: a.last_name,
                          email: a.email,
                          address1: a.street1,
                          address2: a.street2,
                          main_phone: a.main_phone,
                      };
                  })
            : [];
        let label = "Add Contact";
        if (field === "deal_generated_by" || field === "listings_procured_by") {
            label = "Add illi Contact";
        }
        const choose = (the_contact_data, filler, dba) => {
            console.log('an agent was chosen', the_contact_data);
            if (the_contact_data?.signers && the_contact_data?.signers.length > 0) {
                the_contact_data = the_contact_data?.signers[0];
            }

            const newAgentObj = {
                id: the_contact_data.id,
                name: the_contact_data.first_name,
                last_name: the_contact_data.last_name,
                dba_name: dba?.name,
                dba_id: dba?.id,
                dba_type: 'contact',
                email: the_contact_data.email,
                main_phone: the_contact_data.main_phone,
                mobile_phone: the_contact_data.mobile_phone,
                work_phone: the_contact_data.work_phone,
            };
            const tdc = the_contact_data;
            if (tdc) {
                newAgentObj.phone_type = 'Main';
                if (tdc.overrideContactPhone) {
                    newAgentObj.phone_type = tdc.overrideContactPhone.type;
                }
            }
            const contactAddressObj = extractContactAddress(the_contact_data);
            Object.keys(contactAddressObj).forEach(cfieldz => {
                newAgentObj[cfieldz] = contactAddressObj[cfieldz];
            })
            const newAgents = clone(get(data, field, [])).concat([
                newAgentObj
            ]);
            props.getContact(the_contact_data.id);

            const newData = clone(data);
            newData[field] = newAgents;

            const contact_ids = get(newData, `chosen.${queryFor}.contact`, [])
            contact_ids.push(the_contact_data.id);

            set(newData, `chosen.${queryFor}.contact`, contact_ids);
            setData(newData);
            // updateDataObj(field, newAgents);
            setContactModalOpen(false);
        }
        element = (
            <div className="group-wrap">
                <h4>{model[field].label}</h4>
                <Button
                    color="primary"
                    onClick={() => {
                        props.getContacts({
                            names_only: "All",
                        });
                        setContactModalOpen(true);
                        setQueryFor(field);
                        // props.setQueryFor(field);
                    }}
                    style={{ marginTop: 20 }}
                >
                    <i className="fas fa-plus font-22"></i> {label}
                </Button>
                <hr />
                {/* (Full Name, Address1,Address2,City,State,Zip,Phone,Fax,Email) */}
                <div className="bordered-list">
                    <ul>
                        {agents.length === 0 && (
                            <li>
                                <em>No entries</em>
                            </li>
                        )}
                        {agents.map((a, i) => {
                            console.log('agents listedy', a, i);
                            let contactPhone = renderLabel("main_phone", a, field, subFields, 'Main Phone');
                            if (a.phone_type) {
                                if (a.phone_type === 'Work') {
                                    contactPhone = renderLabel("work_phone", a, field, subFields, 'Work Phone');
                                } else if (a.phone_type === 'Mobile') {
                                    contactPhone = renderLabel("mobile_phone", a, field, subFields, 'Mobile Phone');
                                }
                            }
                            return (
                                <li key={`adc-${i}`}>
                                    <span
                                        className="red-text hoverable mr-20"
                                        onClick={() => {
                                            const idToRemove = get(agents, `[${i}].id`, '');

                                            const chosen = get(data, 'chosen');
                                            const contact_ids = get(data, `chosen.${field}.contact`, []).filter(id => id !== idToRemove);
                                            set(chosen, `${field}.contact`, contact_ids);
                                            
                                            const newAgents = get(data, field, []).filter((aa, ii) => {
                                                // if (ii === i) return false;
                                                // return true;
                                                return aa.id !== a.id
                                            });

                                            updateDataMultiObj({
                                                [field]: newAgents,
                                                chosen,
                                            });
                                        }}
                                    >
                                        <i className="fas fa-trash hoverable"></i>
                                    </span>
                                    <span
                                        className="blue-text hoverable mr-20"
                                        onClick={() => {
                                            setDataFromArray({
                                                index: i,
                                                field: field,
                                                data: a,
                                            });
                                            setEditArray(true);
                                        }}
                                    >
                                        <i className="fas fa-edit hoverable"></i>
                                    </span>
                                    <br />
                                    {field === "lessee_contacts" && (
                                        <div>
                                            <div></div>
                                            {renderLabel("name", a, "lessee", subFields, "First Name")}
                                            {renderLabel("last_name", a, "lessee", subFields, "Last Name")}
                                            <div>
                                                <strong>DBA</strong> : {a?.dba_name} {a?.dba_active === 0 && 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>(removed)</span>}
                                            </div>
                                            {renderLabel("address1", a, "lessee", subFields)}
                                            {renderLabel("address2", a, "lessee", subFields)}
                                            {renderLabel("city", a, "lessee", subFields)}
                                            {renderLabel("state", a, "lessee", subFields)}
                                            {renderLabel("zip", a, "lessee", subFields)}
                                            {renderLabel("email", a, "lessee", subFields)}
                                            {/* {renderLabel('contact_type', a, 'lessee')} */}
                                            {contactPhone}
                                        </div>
                                    )}
                                    {field === "lessor_contacts" && (
                                        <div>
                                            {renderLabel("name", a, "lessor", subFields, "First Name")}
                                            {renderLabel("last_name", a, "lessor", subFields, "Last Name")}
                                            <div>
                                                <strong>DBA</strong> : {a?.dba_name} {a?.dba_active === 0 && 
                                                <span style={{color: 'red', fontWeight: 'bold'}}>(removed)</span>}
                                            </div>
                                            {renderLabel("address1", a, "lessor", subFields)}
                                            {renderLabel("address2", a, "lessor", subFields)}
                                            {renderLabel("city", a, "lessor", subFields)}
                                            {renderLabel("state", a, "lessor", subFields)}
                                            {renderLabel("zip", a, "lessor", subFields)}
                                            {renderLabel("email", a, "lessor", subFields)}
                                            {contactPhone}
                                        </div>
                                    )}
                                    {(field === "deal_generated_by" || field === "listings_procured_by") && (
                                        <div>
                                            <strong>First Name : </strong> {a.name}
                                            <br />
                                            <strong>Last Name : </strong> {a.last_name}
                                            <br />
                                            { a?.dba_name &&  <>
                                                <strong>DBA : </strong> {a.dba_name} {a?.dba_active === 0 &&
                                                <span style={{color: 'red', fontWeight: 'bold'}}>(removed)</span>}
                                                <br />
                                            </>}  
                                        </div>
                                    )}
                                    {field !== "listings_procured_by" &&
                                        field !== "deal_generated_by" &&
                                        field !== "lessee_contacts" &&
                                        field !== "lessor_contacts" && (
                                            <div>
                                                <strong>First Name : </strong> {a.name}
                                                <br />
                                                <strong>Last Name : </strong> {a.last_name}
                                                <br />
                                                {contactPhone}
                                                <br />
                                            </div>
                                        )}
                                </li>
                            );
                        })}
                    </ul>
                    {editArray && (
                        <RSheetsEditArray
                            editArray={editArray}
                            setEditArray={setEditArray}
                            dataFromArray={dataFromArray}
                            setDataFromArray={setDataFromArray}
                            data={data}
                            updateDataObj={updateDataObj}
                        />
                    )}
                </div>
                {contactModalOpen && (
                    <CustomDialogChooser
                        showContactCompaniesTab
                        singleContactOnly
                        no_property
                        open={contactModalOpen}
                        close={() => {
                            setContactModalOpen(false);
                        }}
                        choose={choose}
                        create_and_choose={choose}
                        title="Select Contact"
                        label="Search Contacts"
                        current={agents.map((a) => a.name).join(",")}
                    />
                )}
                {data[field] && <div>
                    {(isRequired && data[field].length === 0) && <div className="red-text">Required</div>}
                </div>}
            </div>
        );
    } else if (model[field].type === "lease_term_ranges") {
        element = <Suspense fallback={<div>Loading...</div>}>
                    <Term 
                        updateDataObj={updateDataObj} 
                        data={data} field={field} 
                        setData={setData} 
                        sheetType={sheetType}
                        calcs={calcs}
                    />
                  </Suspense>;
    } else if (model[field].type === "abated_rent_ranges") {
        element = <Suspense fallback={<div>Loading...</div>}>
                    <TermForAbated
                        updateDataObj={updateDataObj} 
                        data={data} 
                        field={field} 
                        setData={setData} 
                        sheetType={sheetType}
                        calcs={calcs}
                    />
                  </Suspense>;
    } else if (model[field].type === "lessors") {
        console.log('field lessor', field, model);
        element = (
            <Lessors
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                data={data}
                field={field}
                setData={setData}
                getRsheetFiles={props.getRsheetFiles}
                alternateLessorName={props.alternateLessorName}
            />
        );
    } else if (model[field].type === "lessees") {
        element = (
            <Lessees
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                data={data}
                field={field}
                setData={setData}
                formDataObj={props.formDataObj}
                setFormDataObj={props.setFormDataObj}
                createRsheetFiles={props.createRsheetFiles}
                getRsheetFiles={props.getRsheetFiles}
                rsheetFiles={props.rsheetFiles}
                success={props.success}
                loading={props.loading}
                error={props.error}
                alternateLesseeName={props.alternateLesseeName}
            />
        );
    } else if (field === "payment_schedule") {
        element = (
            <InvoicePayment
                commissionValue={get(extraComponent, "commissionValue", undefined)}
                setInput={setInput}
                input={input}
                updateDataObj={updateDataObj}
                data={data}
                field={field}
                canEditInvoiceRelatedData={canEditInvoiceRelatedData}
                saveRS={props.saveRS}
            />
        );
    } else if (model[field].type === "state") {
        element = (
            <CustomInputStates
                label={model[field].label}
                onChange={(val) => {
                    updateDataObj(field, val);
                }}
                value={data[field]}
                validate={isRequired ? [required] : []}
                readonly={model[field].readonly ? true : false}
            />
        );
    }
    return <>
        {element}
        {notification && <Notification 
            open={true}
            close={() => {
                setNotification('');
            }}
            message={notification}
        />}
        {notificationAsk && <NotificationAsk 
            open={true}
            close={() => {
                setNotificationAsk(null);
            }}
            success={() => {
                notificationAsk[1]();
            }}
            title="Are you sure?"
            message={notificationAsk[0]}
        />}
    </>
};

const mapStateToProps = (state) => {
    const c = state.crm_contacts;
    const r = state.rs;
    return {
        contactsData: c.contactsData,
        contactData: c.contactData,
        rsheetFiles: r.rsheetFiles,
        success: r.success,
        loading: r.loading,
        error: r.error,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getContact: (id) => {
            dispatch(actions.getContact(id));
        },
        createRsheetFiles: (params) => {
            dispatch(actions.createRsheetFiles(params));
        },
        getRsheetFiles: (params) => {
            dispatch(actions.getRsheetFiles(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsFormElement);
