import React, { useEffect, useState } from "react";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

import { connect } from 'react-redux';
import * as actions from '../../store/actions';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Clearfix from "components/Clearfix/Clearfix.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardAvatar from "components/Card/CardAvatar.jsx";

import userProfileStyles from "assets/jss/material-dashboard-pro-react/views/userProfileStyles.jsx";

import avatar from "assets/img/faces/marc.jpg";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { getUser } from "../../shared/authValidation";
import CustomInputStates from "../../components/CustomInput/CustomInputStates";
import CustomInputValidate from "../../components/CustomInput/CustomInputValidate";
import { clone, handleError } from "../../shared/utility";
import Notification from "../../components/Notification/Notification";
import axios from "store/axios/axios-crm";

function UserProfile(props) {
  const { classes } = props;
  const [user, setUser] = useState({});
  const [notification, setNotification] = useState('');

  useEffect(() => {
    props.getCalendars();
    const usr = getUser();
    setUser({
      name: usr.name,
      email: usr.mail,
      default_calendar: usr.default_calendar
    })
  }, []);

  const updateDefaultCalendar = async (calendar_id) => {
    try {
      await axios.put('/default_calendar', {
        calendar_id
      })
      setNotification('The default calendar has been updated. Log out and log back in if you want to update this page.');
    } catch(e) {
      handleError(e);
      setNotification('There was an error updating the default calendar');
    }
  }

  return (
    <div>
      {notification && <Notification 
        open={notification ? true : false}
        message={notification}
        close={() => {
          setNotification('');
        }}
      />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <PermIdentity />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>
                User Profile
              </h4>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <CustomInputValidate 
                    placeholder="Email address"
                    value={user.email}
                    onChange={() => {

                    }}
                    disabled={true}
                    label="Email address"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomInputValidate 
                    placeholder="Name"
                    value={user.name}
                    onChange={() => {

                    }}
                    disabled={true}
                    label="Name"
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <CustomSelect 
                    label="Select your Default Google Calendar"
                    options={props.calendars}
                    default={user.default_calendar}
                    indx="id"
                    desc="summary"
                    choose={(value) => {
                      console.log('chosen', value);
                      const newUser = clone(user);
                      newUser.default_calendar = value;
                      setUser(newUser);
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                </GridItem>
              </GridContainer>
              <Button color="rose" className={classes.updateProfileButton}
                onClick={() => {
                  if (user.default_calendar) updateDefaultCalendar(user.default_calendar);
                }}
              >
                Update Profile
              </Button>
              <Clearfix />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}


const mapStateToProps = state => {
  return {
    calendars: state.crm_appointments.calendars,
    error: state.crm_appointments.error,
    success: state.crm_appointments.success
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCalendars: () => dispatch(actions.getCalendars()),
    updateDefaultCalendar: (data) => {
      dispatch(actions.updateDefaultCalendar(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(userProfileStyles)(UserProfile));
