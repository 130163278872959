import React, { useState, useEffect, useCallback, useRef } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { Dialog } from "@material-ui/core";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import CommissionSplitsFormWizard from "./CommissionSplitsFormWizard";
import { Tab, Tabs, Tooltip } from "@material-ui/core";
import { Close, ArrowBack } from "@material-ui/icons";
import { defaultData } from "./CommissionSplitsModel";
import { checkForChanges, clone, cloneJ } from "../../shared/utility";
import CustomDialogChooser from "../../components/CustomDialog/CustomDialogChooser";
import CustomDialogWithProperty from "../../components/CustomDialog/CustomDialogWithProperty";
import CustomDialogWithContact from "../../components/CustomDialog/CustomDialogWithContact";
import Notification from "../../components/Notification/Notification";
import NotificationAsk from "../../components/Notification/NotificationAsk";
import FormElement from "./CommissionSplitsElement";
import { choosePropertyObj, chooseContactCompanyObj, gatherPropertyContacts, checkHasBeenCompletedBefore } from "./CommissionSplitsLogic";
import moment from "moment";
import NotificationFixedTop from "../../components/Notification/NotificationFixedTop";
import CommissionSplitsFormFull from "./CommissionSplitsFormFull";
import { getUser } from "../../shared/authValidation";
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Button from "../../components/CustomButtons/Button";

const actionDict = {
    add: 'Add',
    edit: 'Edit',
    view: 'View'
}

function useStateCallback(initialState) {
    const [state, setState] = useState(initialState);
    const cbRef = useRef(null); // init mutable ref container for callbacks
  
    const setStateCallback = useCallback((state, cb) => {
      cbRef.current = cb; // store current, passed callback in ref
      setState(state);
    }, []); // keep object reference stable, exactly like `useState`
  
    useEffect(() => {
      // cb.current is `null` on initial render, 
      // so we only invoke callback on state *updates*
      if (cbRef.current) {
        cbRef.current(state);
        cbRef.current = null; // reset callback after execution
      }
    }, [state]);
  
    return [state, setStateCallback];
  }

const CommissionSplitsForm = (props) => {
    const [tab, setTab] = useState('wizard');
    const [step, setStep] = useState(1);
    const [dataObj, setDataObj] = useStateCallback(defaultData);
    const [initialData, setInitialData] = useState(defaultData);
    const [openedPropertyModal, setOpenedPropertyModal] = useState(false);
    const [propertyOwner, setPropertyOwner] = useState(null);
    const tabIndex = tab === 'wizard' ? 0 : 1;
    const [isGettingPropertyContacts, setIsGettingPropertyContacts] = useState(false);
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [notificationTop, setNotificationTop] = useState('');
    const [notification, seetNotification] = useState('');
    const [close, setClose] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openedChooseModal, setOpenedChooseModal] = useState(false);
    const [openedChooseAssociateModal, setOpenedChooseAssociateModal] = useState(false);
    const [queryFor, setQueryFor] = useState({
        label: '',
        type: '',
        title: ''
    })
    const [notifyOnClose, setNotifyOnClose] = useState(false);
    const [prefilling, setPrefilling] = useState(false);
    const [openedAskForReferralType, setOpenedAskForReferralType] = useState(false);
    const [referralType, setReferralType] = useState(null);
    const { setNotification } = props;

    useEffect(() => {
        props.setCommissionSplitsPropertyExistence();
        props.getCommissionSplitsReviewers();
        props.getCommissionSplitsApprovers();
        console.log('loading', props)
        if (props.formData) {
            const newData = cloneJ(props.formData);
            newData.notes = '';
            setDataObj(newData);
            setInitialData(cloneJ(newData));
        } else {
            const now = moment().format('MM/DD/YYYY');
            const newData = cloneJ(dataObj);
            newData.date = now;
            const current_user = getUser();
            console.log('current_user', current_user);
            newData.submitted_by_name = current_user.name;
            newData.submitted_by_id = current_user.id;
            setDataObj(newData);
        }
        if (props.prefill_property_id) {
            setPrefilling(true);
            props.getProperty(props.prefill_property_id);
        }
    }, [])

    useEffect(() => {
        if (props.propertyData && prefilling) {
            chooseProperty(props.propertyData);
            window.history.replaceState(null, null, '/commission_splits');
            setPrefilling(false);
            props.reset_prefill_property_id();
        }
    }, [props.propertyData, prefilling]);

    // GATHER PROPERTY CONTACTS
    useEffect(() => {
        if (props.property_contacts) {
            if (props.property_contacts.length > 0 && isGettingPropertyContacts) {
                gatherPropertyContacts({
                   property_contacts: props.property_contacts,
                   updateDataObj 
                })
                setIsGettingPropertyContacts(false);
            }
        }
    }, [props.property_contacts]);

    useEffect(() => {
        console.log('success on close?', props.success, close)
        if (props.success && close) {
            if (notifyOnClose) {
                props.setNotification(notifyOnClose);
            }
            props.close();
            setClose(false);
        }
        if (props.success && saving) {
            setNotificationTop('Saved');
            setSaving(false);
        }
    }, [props.success, close]);

    const cancel = () => {
        if (props.tab === 'pending_signature') {
            return props.close();
        }
        // check if equal
        if (checkForChanges(initialData, dataObj)) {
            setNotificationAsk(true);
            return;
        }
        props.close();
    }

    const updateDataObj = (field, value, shouldSave) => {
        const newObj = cloneJ(dataObj);
        if (!newObj.hasOwnProperty('outside_brokers')) {
            newObj.outside_brokers = [];
        }
        newObj[field] = value;
        setDataObj(newObj, () => {
            if (shouldSave) save({
                [field]: value
            });
        });
    }

    // CHOOSE PROPERTY
    const chooseProperty = (property) => {
        console.log('propertyChosen', property);
        choosePropertyObj({ property, setDataObj, dataObj });
        // GET PROPERTY CONTACTS / AGENTS
        setIsGettingPropertyContacts(true);
        props.getPropertyToContact(property.id, "property", "others");
        props.getCommissionSplitsPropertyExistence(property.id);
        setOpenedPropertyModal(false);
    }

    // CHOOSE CONTACT / COMPANY
    const choose = (chosen, chosenType, chosenDBA, c3, c4) => {
        console.log('chosen', queryFor.type, chosen, chosenType, chosenDBA, c3, c4);
        if (queryFor.type === 'referrals' || queryFor.type === 'outside_brokers') {
            const obj = {
                chosen,
                queryFor,
                chosenType,
                chosenDBA
            };
            setOpenedAskForReferralType(cloneJ(obj));
        } else {
            const obj = {
                chosen,
                queryFor,
                chosenType,
                chosenDBA
            };
            setOpenedAskForReferralType(cloneJ(obj));
            // chooseContactCompanyObj({
            //     chosen,
            //     dataObj,
            //     updateDataObj,
            //     queryFor,
            //     chosenType,
            //     chosenDBA,
            //     setNotification
            // });
        }
        setOpenedChooseAssociateModal(false);
    }

    const renderElement = (field) => {
        return <FormElement 
            field={field}
            updateDataObj={updateDataObj}
            dataObj={dataObj}
        />
    }

    const save = (obj, newHistoryObj, shouldCloseForm, message, overrideEntryObj) => {
        setClose(shouldCloseForm ? true : false);
        let isComplete = true;
        ['property_id','project_name'].forEach(field => {
            if (!dataObj[field]) {
                isComplete = false;
            }
        })
        if (!isComplete) return setNotification('Please select a property with project name.');
        const newDataObj = clone(dataObj);
        // fix to why data sometimes doesn't update right away
        if (obj) {
            Object.keys(obj).forEach(f => {
                newDataObj[f] = obj[f];
            })
        }
        setInitialData(cloneJ(dataObj));
        if (newHistoryObj) {
            // action - required
            // message - optional
            const newHistories = [
                {
                    cs_id: props.entryData?.id,
                    action: newHistoryObj.action, 
                    json_data: JSON.stringify(newDataObj),
                    message: newHistoryObj.message
                }
            ]
            props.createCommissionSplitsHistory(newHistories);
        }
        if (props.entryData) {
            setSaving(true);
            // update
            console.log('saving...', newDataObj);
            const newData = {
                id: props.entryData.id,
                json_data: JSON.stringify(newDataObj),
                property_id: dataObj.property_id,
                title: dataObj.project_name
            };
            if (overrideEntryObj) {
                Object.keys(overrideEntryObj).forEach(field => {
                    newData[field] = overrideEntryObj[field];
                })
            }
            props.updateCommissionSplit(newData);
        } else {
            setSaving(true);
            // create
            props.createCommissionSplit({
                status: 1,
                json_data: JSON.stringify(dataObj),
                property_id: dataObj.property_id,
                title: dataObj.project_name
            })
        }
        if (message) setNotification(message)
    }

    const permissions = checkPermissionsActions('CommissionSplits');
    console.log('permissions', permissions);
    const hasBeenCompletedBefore = checkHasBeenCompletedBefore(props.history);

    console.log('CommissionSplitsForm', props, openedAskForReferralType);

    return <div>
        <Dialog
                open={true}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
                <div className="dialog-wrapper pb-200">
                    <GridContainer>
                        <div
                            className="close-btn"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Close />
                        </div>
                        <GridItem xs={12} sm={12} md={12}>
                            <h3 className="text-center">{actionDict[props.action]} Commission Splits</h3>
                            <div
                                style={{
                                    marginRight: 20,
                                    marginBottom: 10,
                                    display: "inline-block",
                                }}
                                onClick={() => {
                                    cancel();
                                }}
                                className="hoverable"
                            >
                                <Tooltip id="tooltip-top-start1" title="Back to List" placement="top">
                                    <ArrowBack />
                                </Tooltip>
                            </div>
                            <Tabs
                                TabIndicatorProps={{
                                    style: { background: "#da3b2f", borderBottom: "1px solid #da3b2f", color: "#da3b2f" },
                                }}
                                style={{ marginBottom: 10 }}
                                value={tabIndex}
                            >
                                {(
                                    <Tab
                                        onClick={() => {
                                            setTab("wizard");
                                        }}
                                        style={{ color: tabIndex === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                        tabIndex={0}
                                        label="Wizard"
                                        icon={<i className="fas fa-magic font-22"></i>}
                                    />
                                )}
                                {
                                    <Tab
                                        onClick={() => {
                                            setTab("full");
                                        }}
                                        style={{ color: tabIndex === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                        tabIndex={1}
                                        label="Full Form"
                                        icon={<i className="fas fa-sticky-note font-22"></i>}
                                    />
                                }
                            </Tabs>
                            {tab === 'wizard' && <CommissionSplitsFormWizard 
                                setTab={setTab}
                                dataObj={dataObj}
                                updateDataObj={updateDataObj}
                                setDataObj={setDataObj}
                                setPropertyOwner={setPropertyOwner}
                                setQueryFor={setQueryFor}
                                setOpenedPropertyModal={setOpenedPropertyModal}
                                setOpenedChooseModal={setOpenedChooseModal}
                                renderElement={renderElement}
                                action={props.action}
                                save={save}
                                step={step}
                                setStep={setStep}
                                all_potential_reviewers={props.all_potential_reviewers}
                                history={props.history}
                                createCommissionSplitsHistory={props.createCommissionSplitsHistory}
                                getCommissionSplitsHistory={props.getCommissionSplitsHistory}
                                entryData={props.entryData}
                                success_history={props.success_history}
                                updateCommissionSplit={props.updateCommissionSplit}
                                cancel={cancel}
                                final_approvers={props.final_approvers}
                                hasBeenCompletedBefore={hasBeenCompletedBefore}
                                setOpenedChooseAssociateModal={setOpenedChooseAssociateModal}
                                parentTab={props.tab} // List Tab
                            />}

                            {tab === 'full' && <CommissionSplitsFormFull 
                                action={props.action}
                                dataObj={dataObj}
                                step={step}
                                setStep={setStep}
                                setTab={setTab}
                                entryData={props.entryData}
                                save={save}
                                cancel={cancel}
                                setNotification={setNotification}
                                listTab={props.tab}
                                permissions={permissions}
                                history={props.history}
                                updateDataObj={updateDataObj}
                                parentTab={props.tab} // List Tab
                            />}

                            {openedPropertyModal && <CustomDialogWithProperty
                                propertyOwner={propertyOwner}
                                open={openedPropertyModal}
                                close={() => {
                                    setOpenedPropertyModal(false);
                                }}
                                choose={chooseProperty}
                                create_and_choose={chooseProperty}
                                title="Search Property"
                                label="Search Property"
                                filter_message=""
                                load_agents={true}
                                full_property_access_only={true}
                                check_for_duplicates="commission_splits"
                            />}

                            {openedChooseModal && <CustomDialogChooser
                                open={openedChooseModal}
                                close={() => {
                                    setOpenedChooseModal(false);
                                }}
                                choose={choose}
                                showContactCompaniesTab={true}
                                create_and_choose={choose}
                                title={queryFor.title}
                                label={queryFor.label}
                                no_property={true}
                                queryFor={queryFor.type}
                                allowNoContacts={true}
                                showFilters={true}
                                showContactTypes={true}
                                filterByContactTypes={queryFor.type === 'referrals' ? 
                                    // only these types for referrals
                                    ['Real Estate Broker','illi Associate','illi Employee']
                                : 
                                    null}
                                no_company={queryFor.type === 'contacts' ? true : false}
                                forceContact={queryFor.type === 'referrals' ? true : false}
                                additionalElements={[
                                    {
                                        type: 'select',
                                        options: ['', 'Lessee', 'Lessor'],
                                        required: true,
                                        label: 'Referral Type',
                                        variable: 'referral_type'
                                    }
                                ]}
                            />}

                            {openedChooseAssociateModal && <CustomDialogWithContact 
                                open={openedChooseAssociateModal}
                                close={() => {
                                    setOpenedChooseAssociateModal(false);
                                }}
                                choose={choose}
                                peopleType="agents"
                            />}
                        </GridItem>
                    </GridContainer>
                </div>
        </Dialog>

        {notificationAsk && <NotificationAsk 
            title="Are you sure?"
            message="Any unsaved progress will be lost."
            open={notificationAsk}
            close={() => {
                setNotificationAsk(false);
            }}
            success={() => {
                setClose(true);
                save(null, null, true);
            }}
            success_button_text="SAVE & CLOSE"
            success2={() => {
                props.close();
            }}
            success2_button_text="CLOSE"
        />}

        {notificationTop && <NotificationFixedTop 
            message={notificationTop}
            open={notificationTop ? true : false}
            close={() => {
                setNotificationTop('');
            }}
            autoClose={3000}
        />}

        {notification && <Notification
            message={notification}
            open={notification ? true : false}
            close={() => {
                setNotification('');
            }}
        />}

        {openedAskForReferralType && <Notification 
            no_ok_button={true}
            open={openedAskForReferralType ? true : false}
            close={() => {
                setOpenedAskForReferralType(null);
            }}
            message={<div className="text-center">
                <h3>SELECT REPRESENTATION</h3>
                <div style={{textAlign:'left'}}>
                    <CustomSelect 
                        options={openedAskForReferralType.queryFor.type === 'referrals' ? 
                            ['','Pre-splits','Lessor','Lessee'] : ['','Lessor','Lessee']}
                        label="Select a referral type"
                        // indx="name"
                        // desc="name"
                        choose={(e, n) => {
                            console.log('chosen', e);
                            setReferralType(e);
                        }}
                        default={referralType}
                    />
                </div>
                {referralType && <Button color="primary"
                    onClick={() => {
                        const cloned = cloneJ(openedAskForReferralType);
                        cloned.chosen.referral_type = referralType;
                        const obj = {
                            chosen: cloned.chosen,
                            dataObj,
                            updateDataObj,
                            queryFor: cloned.queryFor,
                            chosenType: cloned.chosenType,
                            chosenDBA: cloned.chosenDBA,
                            setNotification
                        }
                        chooseContactCompanyObj(obj);
                        setOpenedAskForReferralType(null);
                        setReferralType('');
                    }}
                >SUBMIT</Button>}
            </div>}
        />}
    </div>
}

const mapStateToProps = state => {
	return {
        property_options: state.crm_properties.options,
        updated_property: state.crm_properties.updated_property,
        property_contacts: state.crm_contacts.contact_properties ?
        state.crm_contacts.contact_properties.filter(cp => {
            // Agents only
            if (cp.contact_type === "REA") return true;
        }) : [],
        error: state.commission_splits.error,
        success: state.commission_splits.success,
        error_checking_property: state.commission_splits.error_checking_property,
        success_checking_property: state.commission_splits.success_checking_property,
        checking_property: state.commission_splits.checking_property,
        all_potential_reviewers: state.commission_splits.all_potential_reviewers,
        history: state.commission_splits.history,
        success_history: state.commission_splits.success_history,
        final_approvers: state.commission_splits.final_approvers,
        signing_url: state.commission_splits.signing_url,
        propertyData: state.crm_properties.propertyData
	}
}

const mapDispatchToProps = dispatch => {
    return {
        createCommissionSplit: (data) => {
            dispatch(actions.createCommissionSplit(data));
        },
        updateCommissionSplit: (data) => {
            dispatch(actions.updateCommissionSplit(data));
        },
        getPropertyToContact: (data, type, kind) => {
            dispatch(actions.getPropertyToContact(data, type, kind));
        },
        getCommissionSplitsPropertyExistence: (property_id) => {
            dispatch(actions.getCommissionSplitsPropertyExistence(property_id));
        },
        getCommissionSplitsReviewers: () => {
            dispatch(actions.getCommissionSplitsReviewers());
        },
        createCommissionSplitsHistory: (data, notifications) => {
            dispatch(actions.createCommissionSplitsHistory(data, notifications));
        },
        getCommissionSplitsHistory: (cs_id) => {
            dispatch(actions.getCommissionSplitsHistory(cs_id));
        },
        getCommissionSplitsApprovers: () => {
            dispatch(actions.getCommissionSplitsApprovers());
        },
        setCommissionSplitsPropertyExistence: () => {
            dispatch(actions.setCommissionSplitsPropertyExistence());
        },
        getCsSigningUrl: (data) => {
            dispatch(actions.getCsSigningUrl(data));
        },
        getProperty: (data) => {
            dispatch(actions.getProperty(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CommissionSplitsForm);