import * as actionTypes from './actionTypes';

// CREATE_DOCUSIGN

export const createDocusign = (data) => {
    return {
        type: actionTypes.CREATE_DOCUSIGN,
        data
    }
}

export const createDocusignStart = () => {
    return {
        type: actionTypes.CREATE_DOCUSIGN_START
    }
}

export const createDocusignSuccess = (response) => {
    return {
        type: actionTypes.CREATE_DOCUSIGN_SUCCESS,
        response
    }
}

export const createDocusignFail = (err) => {
    return {
        type: actionTypes.CREATE_DOCUSIGN_FAIL,
        err
    }
}