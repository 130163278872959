import React, { useEffect, useState } from 'react';
import { Cached } from "@material-ui/icons";
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { getUser } from '../../../shared/authValidation';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import CompaniesListTable from './CompaniesListTable';

const CompaniesList = (props) => {

    const [dataArr, setDataArr] = useState([]);
    const [userType, setUserType] = useState('companiesData');
    const [userPerms, setUserPerms] = useState(null);

    

    useEffect(() => {
        const user = getUser();
        if (user) {
            setUserPerms(user);
            if (user.type === 'A') {
                setUserType('agentData');
                props.getCompanies();
            }
        }
    }, []);

    useEffect(() => {
        const user = getUser();
        if (user) {
            const the_data = userType === 'agentData' ? props.agentData : props.data; 
            if (the_data) {
                setDataArr(
                    the_data.map((cd, i) => {
                        const open = () => { props.openForm('View', cd) };
                        return [
                            offset + (i + 1),
                            <div className="hoverable" onClick={() => {
                                open();
                            }}>
                                {cd.name}
                            </div>,
                            cd.main_phone ? cd.main_phone : 'N/A',
                            moment(cd.created_date).format('MM/DD/YYYY')
                        ]
                    }))
            }
        }
        
    }, [props.data, props.agentData]);

    const { rows, offset } = props;
    const page_num = Math.ceil((offset + 1) / rows);
    const page_count = Math.ceil(props[userType === 'companiesData' ? 'companiesCount' : 'agentDataCount'] / rows);

    

    const pages_links = isNaN(page_count) ? null : Array(page_count).fill().map((element, index) => index + 1).map(num => {
        if (page_count > 10) {
            const four = page_num + 4;
            const four2 = page_num - 4;
            if (num > four || num < four2) {
                return null;
            }
        }
        return (
            <span
                onClick={() => {
                    props.setOffset((num - 1) * rows);
                }}
                className={num === page_num ? 'tiny-box current' : 'hoverable tiny-box'} key={num} style={{
                    marginRight: -1
                }}>{num}</span>
        )
    });

    const showBack = page_num === 1 ? false : true;
    const showForward = page_num === page_count ? false : true;

    const countField = userType === 'companiesData' ? 'companiesCount' : 'agentDataCount';

    const renderNav = () => {
        return <div className="text-center">
            {dataArr.length > 0 && <p>Showing {offset + 1} to {(offset +
        dataArr.length)} of {props[countField] ? props[countField].toLocaleString() : ''} companies</p>}
    {dataArr.length > 0 && <div style={{marginTop:20}}>
            <span
                onClick={() => {
                    props.setOffset(0);
                }}
                style={{ borderRight: !showBack ? '1px solid #ccc' : '0' }}
                className="hoverable tiny-box">
                First
            </span>
            <span
                onClick={() => {
                    if (showBack) {
                        props.setOffset(((page_num - 1) - 1) * rows);
                    }
                }}
                style={{ opacity: showBack ? 1 : 0.2, marginRight: -1 }}
                className={showBack ? 'hoverable tiny-box' : 'tiny-box'}>
                Previous
            </span>
            {pages_links}
            {page_count > 10 ? ' . . . ' : ''}
            <span
                onClick={() => {
                    if (showForward) {
                        const offset = ((page_num + 1) - 1) * rows;
                        
                        props.setOffset(offset);
                    }
                }}
                style={{ opacity: showForward ? 1 : 0.2, marginRight: -1 }}
                className={showForward ? 'hoverable tiny-box' : 'tiny-box'}>
                Next
            </span>
            <span
                onClick={() => {
                    const offset = (page_count - 1) * rows;
                    
                    props.setOffset(offset);
                }}
                style={{ borderLeft: '1px solid #ccc' }}
                className="hoverable tiny-box">
                Last
            </span>
        </div>
    }
    </div>
    }

    let allowIlliDBAccess = false;
    if (userPerms) {
        if (userPerms.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (userPerms.permObj) {
                if (userPerms.permObj.hasOwnProperty('CRM agent_access_illi_db')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

    return (<div className="illi-table">
        <form onSubmit={(e) => {
            e.preventDefault();
            props.getCompanies(true);
        }}>
            <GridContainer>
                <GridItem xs={12} sm={2} md={2}>
                    <h3 style={{ display: 'inline-block' }}>Companies</h3><br />
                    <Button
                        size="sm"
                        onClick={() => {
                            props.resetFilters();
                            setTimeout(() => {
                                props.getCompanies();
                            }, 500);
                        }}
                        color="info"
                    >
                        <Cached /> Clear Search
                    </Button>
                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInput
                        label="Search by Company Name"
                        value={props.filters.name}
                        onChange={(e) => { props.setFilters('name', e.target.value) }}
                    />
                </GridItem>
                {/* <GridItem xs={12} sm={2} md={2}> */}
                {/* <CustomSelect
                        label="Search by Contact Type"
                        options={[''].concat(companiesFields.contact_type.options)}
                        choose={(e, n) => {
                            props.setFilters('contact_type', e)
                        }}
                        default={props.rows}
                    />
            </GridItem> */}
                <GridItem xs={12} sm={2} md={2}>
                    <CustomInput
                        label="Search by Main Phone"
                        value={props.filters.main_phone}
                        onChange={(e) => { props.setFilters('main_phone', e.target.value) }}
                    />
                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                    <Button color="warning" type="submit" className="outlined-btn">
                        SEARCH
                    </Button>
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {renderNav()}
                </GridItem>

            </GridContainer>
        </form>
        {userPerms && <div>
            {userPerms.type === 'A' && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {allowIlliDBAccess && <Button
                        style={{ marginRight: 20}}
                        color={userType === 'companiesData' ? 'primary' : 'white'}
                        onClick={() => {
                            props.setOffset(0);
                            props.getCompanies();
                            setUserType('companiesData');
                        }}
                    >illi Companies</Button>}
                    <Button
                        color={userType !== 'companiesData' ? 'primary' : 'white'}
                        onClick={() => {
                            props.setOffset(0);
                            props.getCompanies();
                            setUserType('agentData');
                        }}
                    >Your Companies</Button>
                    <hr />
                </GridItem>
            </GridContainer>}
        </div>}
        {!props.loading && <CompaniesListTable
            dataArr={dataArr}
            setSort={props.setSort}
            sortDirection={props.sortDirection}
            sortBy={props.sortBy}
            data={props.data}
        />}
        <div className="text-center">
            {props.loading && <div><ClipLoader /></div>}
            <em>{(props.data.length === 0 && !props.loading) ? 'No companies found' : ''}</em>
        </div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <CustomSelect
                    label="Rows per page"
                    options={[10, 15, 20, 25, 30]}
                    choose={(e, n) => {
                        props.setRows(e)
                    }}
                    default={rows}
                />
            </GridItem>
        </GridContainer>
        <div>
            {renderNav()}
        </div>
    </div>);
}


const mapDispatchToProps = dispatch => {
    return {
        clearContacts: () => {
            dispatch(actions.clearContacts());
        }
    }
}

export default connect(null, mapDispatchToProps)(CompaniesList);
