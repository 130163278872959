import React, { useEffect, useState } from "react";
import { clone, extractContactAddress } from "../../../shared/utility";
import { outside_brokers_fields } from "../RSheetsFieldsModel";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import Element from "components/Element";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import NotificationAsk from "../../../components/Notification/NotificationAsk";

const Brokers = props => {
    const { data, 
        setData, 
        outside_brokers_field_name, 
        outside_brokers_field_label 
    } = props;

    const list = data[outside_brokers_field_name] ? data[outside_brokers_field_name] : [];

    const [openedChooser, setOpenedChooser] = useState(false);
    const [askDelete, setAskDelete] = useState(null);

    const choose = (chosen, a, dba, c, d) => {
        console.log('chosen', chosen, a, dba, c, d);
        if (chosen.first_name) {
            // CONTACT
            const contact_address = extractContactAddress(chosen);

            const newObj = {
                company_id: '',
                company_name: '',
                contact_id: chosen.id,
                first_name: chosen.first_name,
                last_name: chosen.last_name,
                address1: contact_address.address1,
                address2: contact_address.address2,
                city: contact_address.city,
                state: contact_address.state,
                zip: contact_address.zip,
                agents: '',
                fax: chosen.fax,
                main_phone: chosen.main_phone,
                mobile_phone: chosen.mobile_phone,
                work_phone: chosen.work_phone,
                email: chosen.email,
                tax_id: '',
                w9: '',
                w9_file: '',
                outside_broker_commission: '',
                overrideContactPhone: chosen.overrideContactPhone 
            };

            if (dba) {
                newObj.dba_name = chosen?.dbas.find(i => i.id === dba.id)?.name;
                newObj.dba_id = dba.id;
                newObj.dba_type = 'contact';
                newObj.dba_active = 1;
            }

            // CHOSE A COMPANY
            if (chosen.company_dats) {
                const cdat = chosen.company_dats;
                if (cdat.email) {
                    newObj.email = cdat.email;
                    newObj.email_override = cdat.id;
                }
                if (cdat.phone) {
                    newObj.main_phone = cdat.phone;
                    newObj.main_phone_override = cdat.id;
                }
                if (cdat.company_data) {
                    if (cdat.company_data.id) {
                        newObj.company_id = cdat.company_data.id;
                        newObj.company_name = cdat.company_data.name;
                    }
                    // CHOSE A COMPANY DBA
                    if (chosen.company_dba_id) {
                        newObj.dba_name = chosen.company_dbas?.find(i => i.id === chosen.company_dba_id)?.name;
                        newObj.dba_id = chosen.company_dba_id;
                        newObj.dba_type = 'company';
                        newObj.dba_active = 1;
                    }
                }
            }

            const newData = clone(data);
            if (newData[outside_brokers_field_name]) {
                newData[outside_brokers_field_name].push(newObj);
            } else {
                newData[outside_brokers_field_name] = [newObj];
            }
            setData(newData);
        } else if (chosen.hasOwnProperty("company_data")) {
            const pc = chosen.company_data;
            const cont = chosen.signers ? chosen.signers[0] ? chosen.signers[0] : null : null;
            const dba = chosen.dba_id ? chosen?.dba_data.find(i => i.id === chosen.dba_id) : null;
            // COMPANY 
            const newObj = {
                company_id: pc.id,
                contact_id: cont ? cont.id : '',
                company_name: pc.name,
                first_name: cont ? cont.first_name : '',
                last_name: cont ? cont.last_name : '', 
                address1: pc.mailing_address1,
                address2: pc.mailing_address2,
                city: pc.mailing_city,
                state: pc.mailing_state,
                zip: pc.mailing_zip,
                agents: '',
                fax: pc.fax,
                main_phone: pc.main_phone,
                mobile_phone: pc.mobile_phone,
                work_phone: pc.work_phone,
                email: pc.email,
                tax_id: '',
                w9: '',
                w9_file: '',
                outside_broker_commission: ''
            }

            if (pc.tin) newObj.tax_id = pc.tin;
            if (pc.w9) {
                newObj.w9 = 'Yes';
                newObj.w9_file = pc.w9;
            }
                
            // COMPANY DBA
            if (dba) {
                newObj.dba_name = dba.name;
                newObj.dba_id = dba.id;
                newObj.dba_type = 'company';
                newObj.dba_active = 1;
            }

            // COMPANY CONTACT
            if (cont) {
                if (cont.company_dats) {
                    const cc = cont.company_dats;
                    if (cc.email) {
                        newObj.email = cc.email;
                        newObj.email_override = cc.id;
                    }
                    if (cc.phone) {
                        newObj.main_phone = cc.phone;
                        newObj.main_phone_override = cc.id;
                    }
                }
            }

            const newData = clone(data);
            if (newData[outside_brokers_field_name]) {
                newData[outside_brokers_field_name].push(newObj);
            } else {
                newData[outside_brokers_field_name] = [newObj];
            }
            setData(newData);
        }
    }
    
    return <>
        <GridItem xs={12} sm={12} md={12}>
            <h4><strong>{outside_brokers_field_label} OUTSIDE BROKER</strong></h4>
            <Button
                color="primary"
                style={{marginBottom:20}}
                onClick={() => {
                    setOpenedChooser(true);
                }}
            >
                ADD {outside_brokers_field_label} OUTSIDE BROKER
            </Button>
            <div className="group-wrap">
                {list.map((l,i) => {
                    return <div style={{marginBottom:20}} key={`outside-broker-${i}`} className="parentable">
                    <h4>#{i+1}</h4>
                    <div className="float-right">
                        <IconButton
                            onClick={() => {
                                setAskDelete(i);
                            }}
                        >
                            <Delete className="red" />
                        </IconButton>
                    </div>
                    <GridContainer>
                        {Object.keys(outside_brokers_fields).map(field => {
                            let isRequired = false;
                            // W9
                            let fileType = '';
                            if (l.w9 === 'Yes') {
                                if (field === 'w9_upload') isRequired = true;
                            } else {
                                if (field === 'w9_upload') return null;
                            }
                            if (field === 'w9_upload') fileType = 'rs';

                            // CONTACT OR COMPANY
                            if (!l.company_name && field === 'company_name') return null;
                            if (!l.first_name && field === 'first_name') return null;
                            if (!l.last_name && field === 'last_name') return null;

                            // DBA 
                            if (!l.dba_name && field === 'dba_name') return null;

                            // PHONE
                            if (l.overrideContactPhone) {
                                if (l.overrideContactPhone.type === 'Work') {
                                    if (field === 'Main') return null;
                                    if (field === 'Mobile') return null;
                                }
                                if (l.overrideContactPhone.type === 'Mobile') {
                                    if (field === 'Work') return null;
                                    if (field === 'Main') return null;
                                }
                                if (l.overrideContactPhone.type === 'Main') {
                                    if (field === 'Work') return null;
                                    if (field === 'Mobile') return null;
                                }
                            }

                            return <GridItem xs={12} sm={3} md={3} key={`ob-field-${field}-${i}`}>
                                <Element 
                                    data={l}
                                    setData={(obj) => {
                                        const newData = clone(data);
                                        newData[outside_brokers_field_name][i] = obj;
                                        setData(newData);
                                    }}
                                    field={field}
                                    model={outside_brokers_fields}
                                    isRequired={outside_brokers_fields[field].required || isRequired ? true : false}
                                    fileType={fileType}
                                />
                            </GridItem> 
                        })}
                    </GridContainer>
                </div>
                })}

                {list.length === 0 && <div className="text-center">
                    No outside brokers
                </div>}
            </div>
        </GridItem>

        {openedChooser && (
                <CustomDialogChooser
                    open={openedChooser}
                    close={() => {
                        setOpenedChooser(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts"
                    should_skip_company_contact={false}
                    showContactCompaniesTab={true}
                    no_property={true}
                    allowNoContacts={true}
                    allowMultipleContacts={false}
                />
            )}

        {askDelete !== null && <NotificationAsk
            open={askDelete === null ? false : true}
            close={() => { setAskDelete(null) }}
            title="Are you sure?"
            message="Are you sure you want to remove this outside broker?"
            success={() => {
                const newData = clone(data);
                newData[outside_brokers_field_name] = newData[outside_brokers_field_name].filter((ob,x) => {
                    if (x !== askDelete) return true;
                });
                setData(newData);
                setAskDelete(null);
            }}
            success_button_text="OK, DELETE"
        />}
    </>
}

export default Brokers;