import React, { useState } from 'react';
import { Dialog, DialogTitle, Tooltip } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { ArrowBack } from '@material-ui/icons';
import { clone } from 'lodash';

import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from 'components/Table/Table.jsx';
import DialPadMergeMain from './DialPadMergeMain';
import { useEffect } from 'react';

export default function DialPadContactImportModal(props) {
	const { close, contactsToImport, isOpen, open, showCloseBtn, setAlreadyImportedList, setContactsToImport } = props;

	const [openedSearchContactModal, setOpenedSearchContactModal] = useState(false);

	const [contactToImport, setContactToImport] = useState(null);

	const openSearchContactModal = (contact) => {
		setContactToImport(contact);
		setOpenedSearchContactModal(true);
	};
	const closeSearchContactModal = () => setOpenedSearchContactModal(false);

	const contactTables = contactsToImport.map((contact, index) => (
		<div style={{ lineHeight: 1.5 }} className='mt-20 boxed'>
			<Table
				tableData={[
					['First Name', 'first_name'],
					['Last Name', 'last_name'],
					['Company', 'company_name'],
					['Phone', 'primary_phone'],
					['Email', 'primary_email'],
				].map((field) => {
					return [
						<div style={{ textAlign: 'right' }}>
							<strong>{field[0]}</strong>
						</div>,
						contact[field[1]],
					];
				})}
			/>
			<Button
				color='primary'
				onClick={() => {
					openSearchContactModal(contact);
				}}
			>
				Merge with 
			</Button>
		</div>
	));

	useEffect(() => {
		return () => {
			if (setContactsToImport) {
				setContactsToImport([]);
			}
			setContactToImport(null);
		}
	}, [])

	return (
		<div className='dialog-wrapper'>
			<Dialog open={isOpen} fullScreen keepMounted onClose={close} maxWidth={false} fullWidth={true}>
				<div style={{ padding: 20 }}>
					{showCloseBtn && (
						<div
							className='close-btn'
							onClick={() => {
								close();
							}}
						>
							<Close />
						</div>
					)}

					<GridContainer>
						<GridItem xs={12} sm={10} md={10}>
							<h3 style={{ display: 'inline-block', marginRight: 40 }}>
								{
									<div
										style={{ marginRight: 20, marginBottom: 10 }}
										className='inline-block blue-text hoverable'
										onClick={() => {
											close();
										}}
									>
										<Tooltip id='tooltip-top-start1' title='Back to List' placement='top'>
											<ArrowBack />
										</Tooltip>
									</div>
								}
								Import Contact
							</h3>
						</GridItem>
						<GridItem xs={12} sm={10} md={10}>
							{contactTables}
						</GridItem>
					</GridContainer>
				</div>
			</Dialog>

			{contactToImport && (
				<DialPadMergeMain
					contactToImport={contactToImport}
					contactsToImport={contactsToImport}
					open={openedSearchContactModal}
					onClose={closeSearchContactModal}
					closeAll={() => {
						closeSearchContactModal()
						close()
					}}
					setAlreadyImportedList={setAlreadyImportedList}
				/>
			)}
		</div>
	);
}
