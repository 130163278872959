import React from 'react';
import { connect } from 'react-redux';

import Button from 'components/CustomButtons/Button.jsx';
import * as actions from '../../../store/actions';
import LogHistoryListTable from './LogHistoryListTable';
import { useEffect } from 'react';
import { useState } from 'react';
import { clone, isEmpty } from 'lodash';
import { Dialog } from '@material-ui/core';
import DialPadContactImportModal from '../DialPadContactImportModal';

const splitName = (fullName) => {
	const first_last = fullName.split(' ');
	const first = first_last[0];
	const last = first_last.slice(1).join(' ');
	return { first, last };
};

const LogHistory = (props) => {
	const { getLogHistory, loading, logHistory } = props;

	const [contactsToImport, setContactsToImport] = useState([]);
	const [isSelectAll, setIsSelectAll] = useState(false);

	const [openedModal, setOpenedModal] = useState(false);
	const [openedContactImportModal, setOpenedContactImportModal] = useState(false);

	const [message, setMessage] = useState(null);

	const openModal = () => setOpenedModal(true);
	const closeModal = () => {
		setOpenedModal(false);
		setMessage(null);
	};

	const openContactImportModal = () => setOpenedContactImportModal(true);
	const closeContactImportModal = () => setOpenedContactImportModal(false);

	const handleSetContactsToImport = (contact) => {
		let newContactsToImport = clone(contactsToImport);
		const has = newContactsToImport.findIndex((item) => item.log_id === contact.log_id);
		if (has === -1) {
			newContactsToImport.push(contact);
		} else {
			newContactsToImport = newContactsToImport.filter((item) => item.log_id !== contact.log_id);
		}
		setContactsToImport(newContactsToImport);
	};

	const handleSelectAll = (contactList) => {
		let newContactsToImport = clone(contactsToImport);

		const diff = [];

		contactList.forEach((contact) => {
			if (newContactsToImport.findIndex((item) => item.log_id === contact.log_id) === -1) {
				diff.push(contact);
			}
		});

		if (diff.length > 0) {
			newContactsToImport = newContactsToImport.concat(diff);
			setIsSelectAll(true);
		} else {
			contactList.forEach((contact) => {
				newContactsToImport = newContactsToImport.filter((item) => item.log_id !== contact.log_id);
			});
			setIsSelectAll(false);
		}

		setContactsToImport(newContactsToImport);
	};

	const formattedContactsToImport = contactsToImport.map((contact) => {
		const {first, last} = splitName(contact.name);
		return {
			company_name: '',
			first_name: first,
			id: contact.id,
			job_title: '',
			last_name: last,
			primary_email: contact.email,
			primary_phone: contact.phone,
		};
	});

	useEffect(() => {
		getLogHistory();
	}, []);

	const handleImportContacts = () => {
		openModal(true);
		if (!isEmpty(contactsToImport)) {
			// importContacts(contactsToImport);
			// setContactsToImport([]);
			// getEvents({
			// 	type: 1,
			// });

			openContactImportModal();
		} else {
			setMessage('Please select at least one contact to import.');
		}
	};

	return (
		<div>
			<Button onClick={handleImportContacts} color='primary'>
				Import
			</Button>
			<LogHistoryListTable
				contactsToImport={contactsToImport}
				data={logHistory}
				handleSelectAll={handleSelectAll}
				handleSetContactsToImport={handleSetContactsToImport}
				isSelectAll={isSelectAll}
				loading={loading}
				setIsSelectAll={setIsSelectAll}
				openContactImportModal={openContactImportModal}
				openModal={openModal}
				setContactsToImport={setContactsToImport}
				setMessage={setMessage}
			/>

			{message && (
				<Dialog open={openedModal} maxWidth='sm' fullWidth={true}>
					<div style={{ textAlign: 'center', marginTop: 20, padding: 20 }}>{message || 'success'}</div>

					<div style={{ textAlign: 'center', padding: 20 }}>
						<Button color='primary' onClick={closeModal}>
							OK
						</Button>
					</div>
				</Dialog>
			)}

			{!isEmpty(contactsToImport) && (
				<DialPadContactImportModal
					showCloseBtn
					close={closeContactImportModal}
					contactsToImport={formattedContactsToImport}
					isOpen={openedContactImportModal}
					open={openContactImportModal}
				/>
			)}
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		loading: state.dialpad.loading,
		logHistory: state.dialpad.logHistory,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getLogHistory: () => dispatch(actions.getLogHistory()),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(LogHistory);
