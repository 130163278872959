import React, { useState } from "react";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import { createAfterProperty, analyzeDiffs } from './PropertiesLogic';
import { useEffect } from "react";
import { clone } from "../../../shared/utility";
import Button from "../../../components/CustomButtons/Button";

const PropertiesImporterDialog = (props) => {
    const {importerFields, importerFieldNames } = props;

    const [correction, setCorrection] = useState([]); // list of fields needing correction
    const originalData = props.data.originalData ? props.data.originalData : [];
    const index = props.data.index;
    const [propertySelected, setPropertySelected] = useState(null);
    const [showPropertySelect, setShowPropertySelect] = useState(false);

    const findFoundProperties = () => {
        if (props.data) {
            if (props.data.property_check) {
                const checks = props.data.property_check;
                if (checks.found_properties) {
                    return checks.found_properties;
                }
            }
        }
        return [];
    }

    useEffect(() => {
        let chooseOne = false;
        let hasIssues = false;
        if (props.data) {
            if (props.data.property_check) {
                const checks = props.data.property_check;
                if (checks.found_properties) {
                    if (checks.found_properties.length > 0) {
                        if (checks.found_properties.length > 1) {
                            chooseOne = true;
                        } else {
                            const existing_property = checks.found_properties[0];
                            // analyze if there are differences
                            const after_property = createAfterProperty(originalData);
                            const analyzed = analyzeDiffs(existing_property, after_property);
                            if (analyzed.different_fields.length > 0) {
                                hasIssues = true;
                            }
                            let newData = [];
                            let newIndex = props.data.property_check.index;
                            if (props.data.differences) {
                                props.data.differences.forEach(pd => {
                                    newData.push(pd);
                                })
                            }
                            setCorrection(clone(newData));
                            if (existing_property) setPropertySelected(existing_property);
                        }
                    }
                }
            }
        }
        if (chooseOne) {
            setShowPropertySelect(true);
        }
    }, []);

    useEffect(() => {
        if (propertySelected) {
            let newCorrections = [];
            const newProperty = createAfterProperty(originalData);
            const idc = analyzeDiffs(propertySelected, newProperty);
            if (idc.different_fields) {
                if (idc.different_fields.length > 0) {
                    for (let x = 0; x < idc.different_fields.length; x++) {
                        const iddf = idc.different_fields[x];
                        let original_data = null;
                        if (idc.existing_fields) {
                            for (let y = 0; y < idc.existing_fields.length; y++) {
                                if (idc.existing_fields[y].field === iddf.field) {
                                    original_data = idc.existing_fields[y].value;
                                }
                            }
                        }
                        newCorrections.push({
                            index,
                            original_data,
                            new_data: iddf.value,
                            field: iddf.field
                        })
                    }
                }
            }
            setCorrection(newCorrections);
        }
    }, [propertySelected]);

    const close = () => {
        props.close();
    }

    const propertiesToSelect = findFoundProperties();
    
    let allCorrected = true;
    {importerFieldNames.forEach((fieldName, x) => {
        let fieldValue = props.data.originalData ? props.data.originalData[x] : '';
        correction.forEach(c => {
            if (c.field === importerFields[x]) {
                fieldValue = c.chosen;
                if (!fieldValue || fieldValue == 0) {
                    allCorrected = false;
                }
            }
        })
    })} 

    return <Dialog
        open={props.open}
        onClose={() => {
            close();
        }}
        fullWidth={true}
        maxWidth="md"
    >
        <div className="dialog-wrapper">
            <div className="close-btn" onClick={() => { 
                close();
            }}><Close /></div>
            <GridContainer>
                <GridItem xs={6} sm={12} md={12}>
                    {<div>
                        <h3>Existing Property Selected</h3>
                        {propertiesToSelect.length > 1 && <div>Found Possible Duplicates, Select a Property to Update</div>}
                        {propertiesToSelect.map((m,i) => {
                            return <div key={`${i}-property`} className="property-block hoverable" style={{
                                backgroundColor: m.id === (propertySelected ? propertySelected.id : null) ?
                                    '#ccc' : 'white'
                            }}
                                onClick={() => {
                                    setPropertySelected(m);
                                    setShowPropertySelect(false);
                                }}
                            >
                                #: <strong>{i+1}</strong><br />
                                Project Name: <strong>{m.name}</strong><br />
                                Address 1 Range Start: <strong>{m.street_range1}</strong><br />
                                Address 1 Range End: <strong>{m.street_range2}</strong><br />
                                Address 1: <strong>{m.street1}</strong><br />
                                Address 2 Range Start: <strong>{m.street2_range1}</strong><br />
                                Address 2 Range End: <strong>{m.street2_range2}</strong><br />
                                Address 2: <strong>{m.street2}</strong>
                            </div>
                        })}
                    </div>}
                    {!showPropertySelect && <div>
                        <div className="text-center">
                            <h3>Property Issues for "{originalData[2]}"</h3>
                            <p>Choose one for each field</p>
                        </div>
                        {correction.map((d,i) => {
                            const index = importerFields.indexOf(d.field);
                            const label = importerFieldNames[index];
                            let chosen = '';
                            if (d.chosen) chosen = d.chosen;
                            if (d.field === 'street_range1' || d.field === 'street_range2') {
                                if (!d.original_data && !d.new_data) {
                                    return null;
                                }
                            }
                            if (d.field === 'street2_range1' || d.field === 'street2_range2') {
                                if (!d.original_data && !d.new_data) {
                                    return null;
                                }
                            }
                            let street_range1 = '';
                            let street_range2 = '';
                            let street2_range1 = '';
                            let street2_range2 = '';
                            if (d.field === 'street1') {
                                importerFieldNames.forEach((fieldName,x) => {
                                    const field = importerFields[x];
                                    if (field === 'street_range1') {
                                        const fieldValue = props.data.originalData ? props.data.originalData[x] : '';
                                        if (fieldValue) street_range1 = fieldValue;
                                    }
                                    if (field === 'street_range2') {
                                        const fieldValue = props.data.originalData ? props.data.originalData[x] : '';
                                        if (fieldValue) street_range2 = fieldValue;
                                    }
                                })
                            }
                            if (d.field === 'street2') {
                                importerFieldNames.forEach((fieldName,x) => {
                                    const field = importerFields[x];
                                    if (field === 'street2_range1') {
                                        const fieldValue = props.data.originalData ? props.data.originalData[x] : '';
                                        if (fieldValue) street2_range1 = fieldValue;
                                    }
                                    if (field === 'street2_range2') {
                                        const fieldValue = props.data.originalData ? props.data.originalData[x] : '';
                                        if (fieldValue) street2_range2 = fieldValue;
                                    }
                                })
                            }
                            return <div key={`property-${i}`} className="boxed">
                                <strong>{label}</strong>
                                <div className={`clickables ${chosen ? chosen === d.original_data ? 'chosen' : '' : ''}`} onClick={() => {
                                    const newData = clone(correction);
                                    newData[i].chosen = d.original_data;
                                    setCorrection(newData);
                                }}>
                                    Current Data : {d.original_data}
                                </div>
                                <div className={`clickables ${chosen ? chosen === d.new_data ? 'chosen' : '' : ''}`} onClick={() => {
                                    const newData = clone(correction);
                                    newData[i].chosen = d.new_data;
                                    setCorrection(newData);
                                }} style={{color:'green'}}>
                                    Import Data : 
                                    {street_range1 || street_range2 ? 
                                        `(Range) ${street_range1} - ${street_range2} ` 
                                    : 
                                        ``} 
                                    {street2_range1 || street2_range2 ? 
                                        `(Range) ${street2_range1} - ${street2_range2} ` 
                                    : 
                                        ``} 
                                    {d.new_data}
                                </div>
                            </div> 
                        })}
                        <div>
                            <div className="boxed">
                                <strong>The following will be the imported values for this property</strong>
                                {importerFieldNames.map((fieldName, x) => {
                                    let fieldValue = props.data.originalData ? props.data.originalData[x] : '';
                                    let hasBeenCorrected = false;
                                    let hasBeenChosen = true;

                                    correction.forEach(c => {
                                        if (c.field === importerFields[x]) {
                                            hasBeenCorrected = true;
                                            fieldValue = c.chosen;
                                            if (!fieldValue || fieldValue == 0) {
                                                hasBeenChosen = false;
                                            }
                                        }
                                    })
                                    if (fieldName === 'Exported Date') return null;
                                    if (fieldName === 'Property ID') return null;

                                    if (fieldName === 'Street1 Range Start') return null;
                                    if (fieldName === 'Street2 Range Start') return null;
                                    if (fieldName === 'Street1 Range End') return null;
                                    if (fieldName === 'Street2 Range End') return null;

                                    if (fieldName === 'Street1') {
                                        let r1_1 = '';
                                        let r1_2 = '';
                                        importerFields.forEach((f,y) => {
                                            if (f === 'street_range1') r1_1 = props.data.originalData ? props.data.originalData[y] : '';
                                            if (f === 'street_range2') r1_2 = props.data.originalData ? props.data.originalData[y] : '';
                                        })
                                        correction.forEach(c => {
                                            if (c.field === 'street_range1') {
                                                r1_1 = c.chosen;
                                            }
                                            if (c.field === 'street_range2') {
                                                r1_2 = c.chosen;
                                            }
                                        })
                                        fieldValue = `${r1_1 && r1_2 ? `(Range) ${r1_1}-${r1_2}` : ''} ${fieldValue}`;
                                    }
                                    if (fieldName === 'Street2') {
                                        let r2_1 = '';
                                        let r2_2 = '';
                                        importerFields.forEach((f,y) => {
                                            if (f === 'street2_range1') r2_1 = props.data.originalData ? props.data.originalData[y] : '';
                                            if (f === 'street2_range2') r2_2 = props.data.originalData ? props.data.originalData[y] : '';
                                        })
                                        correction.forEach(c => {
                                            if (c.field === 'street2_range1') {
                                                r2_1 = c.chosen;
                                            }
                                            if (c.field === 'street2_range2') {
                                                r2_2 = c.chosen;
                                            }
                                        })
                                        fieldValue = `${r2_1 && r2_2 ? `(Range) ${r2_1}-${r2_2}` : ''} ${fieldValue}`;
                                    }
                                    return <div key={`fild-${x}`} style={{textAlign:'left'}}>
                                        {fieldName} : {hasBeenCorrected ? 
                                            <strong style={{color:'green'}}>{fieldValue}</strong> 
                                        : 
                                            fieldValue}
                                        {!hasBeenChosen ? <strong style={{color:'red'}}>Needs Correction</strong> : ''}
                                    </div>
                                })}
                            </div>
                        </div>
                        <GridItem xs={12} md={12}>
                            <GridContainer>
                                <GridItem xs={6} md={6}>
                                    <div>
                                        <Button color="primary" 
                                        disabled={allCorrected ? false : true}
                                        onClick={() => {
                                            let originalData = clone(props.originalData); // all rows in import data, includes header row
                                              
                                            const currentData = originalData[index+1];
                                            correction.forEach(co => {
                                                const the_index = importerFields.indexOf(co.field);
                                                let chosen = '';
                                                if (co.chosen == 0) chosen = 0;
                                                if (co.chosen) chosen = co.chosen;
                                                currentData[the_index] = chosen;
                                            })
                                            if (propertySelected.id) currentData[importerFields.indexOf('property_id')] = propertySelected.id;
                                            props.setOriginalData(originalData);
                                            
                                            let corrected = clone(props.corrected); // array of integers (index corrected)
                                            if (corrected.indexOf(index) === -1) {
                                                corrected.push(index);
                                            }
                                            props.setCorrected(corrected);
                
                                            props.close();
                                        }}>SAVE AS UPDATE</Button>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} md={6}>
                                {<div>
                                    <Button color="rose" onClick={() => {
                                        let newData = clone(correction);
                                        let fieldsUpdated = [];
                                        for (let i = 0; i < newData.length; i++) {
                                            const nd = newData[i];
                                            if (nd.field === 'street_range1' || nd.field === 'street_range2') {
                                                newData[i].new_data = '';
                                                fieldsUpdated.push(nd.field);
                                            }
                                        }
                                        if (fieldsUpdated.indexOf('street_range1') === -1) {
                                            newData.push({
                                                field: 'street_range1',
                                                index,
                                                new_data: '',
                                                original_data: ''
                                            });
                                        }
                                        if (fieldsUpdated.indexOf('street_range2') === -1) {
                                            newData.push({
                                                field: 'street_range2',
                                                index,
                                                new_data: '',
                                                original_data: ''
                                            });
                                        }
                                        setCorrection(newData);
                                    }}>REMOVE ADDRESS 1 RANGE</Button>
                                </div>}
                                {<div>
                                    <Button color="rose" onClick={() => {
                                        let newData = clone(correction);
                                        let fieldsUpdated = [];
                                        for (let i = 0; i < newData.length; i++) {
                                            const nd = newData[i];
                                            if (nd.field === 'street2_range1' || nd.field === 'street2_range2') {
                                                newData[i].new_data = '';
                                                fieldsUpdated.push(nd.field);
                                            }
                                        }
                                        if (fieldsUpdated.indexOf('street2_range1') === -1) {
                                            newData.push({
                                                field: 'street2_range1',
                                                index,
                                                new_data: '',
                                                original_data: ''
                                            });
                                        }
                                        if (fieldsUpdated.indexOf('street2_range2') === -1) {
                                            newData.push({
                                                field: 'street2_range2',
                                                index,
                                                new_data: '',
                                                original_data: ''
                                            });
                                        }
                                        setCorrection(newData);
                                    }}>REMOVE ADDRESS 2 RANGE</Button>
                                </div>}
                            </GridItem>
                            </GridContainer>
                        </GridItem>
                    </div>}
                    <div>
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                    <div>
                                        <Button color="white"
                                        onClick={() => {
                                            let originalData = clone(props.originalData); // all rows in import data, includes header row
                                            const currentData = originalData[index+1]; // current row
                                            currentData[importerFields.indexOf('property_id')] = '';
                                            props.setOriginalData(originalData);
                                            let corrected = clone(props.corrected); // array of integers (index corrected)
                                            if (corrected.indexOf(index) === -1) {
                                                corrected.push(index);
                                            }
                                            props.setCorrected(corrected);
                                            // * uses all import data on this row
                                            props.close();
                                        }}>SAVE AS NEW</Button> <span style={{color:'red'}}>* create New property from this row's data</span>
                                    </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
}

export default PropertiesImporterDialog;