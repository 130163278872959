import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { get, set } from 'lodash';

import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";
import { ListItem, List, ListItemText, ListItemIcon, Dialog } from "@material-ui/core";

import { getUser } from "../../../shared/authValidation";
import { createAddress, clone } from "../../../shared/utility";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomDialogWithCompany from "components/CustomDialog/CustomDialogWithCompany";
import CustomDialogWithProperty from "components/CustomDialog/CustomDialogWithProperty";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomSelect from "components/CustomSelect/CustomSelect";
import NotificationFixedTop from "components/Notification/NotificationFixedTop";
import Notification from "components/Notification/Notification";
import SaveBack from "components/SaveBack/SaveBack";

import FormElement from "../RSheetsFormElement";
import { getTotalSqft, processCompany, checkAllChanges, checkChangesData } from '../RSheetsLogic';

import FileLoading from '../CommonCustomElements/FileLoading';
import FileUpload from '../CommonCustomElements/FileUpload';
import InvoicePayment from '../CommonCustomElements/InvoicePayment';
import CommissionAdjustment from '../CommonCustomElements/CommissionAdjustment';
import Documents from "../CommonCustomElements/Documents";

import { generateSections } from "./RSheetsTenantRepLogic";
import { sectionNames } from "./RSheetsTenantRepModel";
import RSheetsTenantRepFormActions from "./RSheetsTenantRepFormActions";

import Step1 from "./Step1"; // Property Information
import Step2 from "./Step2"; // Premises Information
import Step3 from "./Step3"; // Lease Information
import Step4 from "./Step4"; // Lessee
import Step5 from "./Step5"; // Lessor
import Step6 from "./Step6"; // Commission Splits

import Lessees from './CustomElements/Lessees';
import Lessors from './CustomElements/Lessors';

import Term from '../CommonCustomElements/Term/Term';
import { getRsheetLadderOptions } from "../RSheetsLogic";
import InternalReview from "../CommonCustomElements/InternalReview";
import Billing from "../CommonCustomElements/Billing";
import OtherReferrals from "../CommonCustomElements/OtherReferrals";
import PreSplitsForm from "../CommonCustomElements/PreSplitsForm";

const stepTitle = sectionNames;
const stepNames = {} 
Object.keys(sectionNames).forEach(num => {
    stepNames[sectionNames[num]] = parseInt(num);
})
console.log('stepNames Tenant Rep', stepNames);

const RSheetsTenantRepFormWizard = (props) => {
    const rsheetPerms = getRsheetLadderOptions();
    const levels = get(rsheetPerms, 'userPerms.approve', []);
    const max_approver_level = levels.length > 0 ? Math.max.apply(Math, levels) : null;

	const { data, updateDataObj, updateDataMultiObj, setData, propertyData, success, error, step, setStep, contactChangesData, companyChangesData, propertyChangesData, viewOnly, action, getNextRsIlliNumber, nextIlliNumber, isDuplicatingIlliNumber, isDeclined, setIsDeclined } = props;
    const [propertyDat, setPropertyDat] = useState(null);
    const [queryFor, setQueryFor] = useState("");
    const [propertyOwner, setPropertyOwner] = useState("illi");
    const [userPerms, setUserPerms] = useState({});
    const [notification, setNotificationFinal] = useState("");
    const [showTempNotification, setShowTempNotification] = useState(false);
    const [persistentNotification, setPersistentNotification] = useState("");
    const [notAutoSave, setNotAutoSave] = useState(false);
    const [updateStarted, setUpdateStarted] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);

	const [openedChooseCRMModal, setOpenedChooseCRMModal] = useState(false);
    const [openedChooseSpacesModal, setOpenedChooseSpacesModal] = useState(false);
    const [openedPropertyModal, setOpenedPropertyModal] = useState(false);
    const [openedContactModal, setOpenedContactModal] = useState(false);
    const [openedCompanyModal, setOpenedCompanyModal] = useState(false);

	let isAgent = false;
    const usr = getUser();
    if (usr.type === 'A') {
        isAgent = true;
    }

    const setNotification = (msg) => {
        if (showTempNotification) {
            setNotificationFinal(msg);
            setShowTempNotification(false);
            setTimeout(() => {
                setNotificationFinal("");
            }, 5000);
        }
    };

    useEffect(() => {
        const user = getUser();
        if (user) {
            if (user.permObj) {
                setUserPerms(user.permObj);
            }
        }
        if (action === 'Add') {
            getNextRsIlliNumber(5)
        }

        return () => {
            checkAllChanges(null, {
                getCompanyChanges: props.getCompanyChanges,
                getContactChanges: props.getContactChanges,
                getPropertyChanges: props.getPropertyChanges,
            });
        }
    }, []);

    useEffect(() => {
        if (nextIlliNumber) {
            const newData = clone(data);
            newData.illi_number = nextIlliNumber.toString();
            setData(newData);
        }
    }, [nextIlliNumber])

    useEffect(() => {
        if (isDuplicatingIlliNumber) {
            setNotification("Warning: This illi number has already been used.");
            setShowTempNotification(true);
        }
    }, [isDuplicatingIlliNumber]);

    useEffect(() => {
        if (updateStarted) {
            if (success) {
                if (success.indexOf("Created") !== -1) {
                    const id_arr = success.split("illi #");
                    const id = id_arr[1];
                    if (id) {
                        setNotification("Saved");
                        updateDataObj("id", id);
                    }
                    props.clearRSheetsMessages();
                    setNotAutoSave(false);
                    setUpdateStarted(false);
                } else {
                    if (notAutoSave) {
                        //setNotification('Successfully Saved');
                    }
                    if (success.indexOf("Updated") !== -1) {
                        setNotification("Saved");
                    }
                    setNotAutoSave(false);
                    props.clearRSheetsMessages();
                    setUpdateStarted(false);
                    props.setActivityTracker(false);
                }
            }
        }
    }, [success]);

    const decimal = () => {
        if (data) {
            if (data.commission_splits_agent) {
                const commissions = data.commission_splits_agent;
                commissions.map((user, index) => {
                    const num = Number(user.Percentage);
                    const fixedDecimal = num.toFixed(2);
                    commissions[index].Percentage = fixedDecimal.toString();
                });
            }
            if (data.commission_splits_agent2) {
                const commissions = data.commission_splits_agent2;
                commissions.map((user, index) => {
                    const num = Number(user.Percentage);
                    const fixedDecimal = num.toFixed(2);
                    commissions[index].Percentage = fixedDecimal.toString();
                });
            }
        }
    };

    const saveDraft = (savedText, overrideData) => {
        decimal();

        if (!data.id) {
            if (data.project_address && data.project_name) {
                setUpdateStarted(true);
                props.createRsheet({
                    json_data: JSON.stringify(data),
                    status: 1,
                    project_name: data.project_name,
                    type: 5,
                });
            } else {
                setShowTempNotification(true);
                setNotification("Please select a property to begin autosaving.");
            }
        } else {
            if (data.project_address && data.project_name) {
                setUpdateStarted(true);
                props.updateRsheet({
                    id: data.id,
                    json_data: JSON.stringify(overrideData ? overrideData : data),
                    project_name: data.project_name,
                    type: 5,
                    rs_type: data.rs_type,
                });
            } else {
                setShowTempNotification(true);
                setNotification("Please select a property to begin autosaving.");
            }
        }
        props.setFormDataObj({});
    };

    useEffect(() => {
        if (props.prefillPropertyObject) {
            setTimeout(() => {
                chooseProperty(props.prefillPropertyObject)
            }, 1000);
        }
        const saveInterval = setInterval(() => {
            if (data.id) {
                saveDraft();
            }
        }, 300000);

        return () => clearInterval(saveInterval);
    }, []);

    useEffect(() => {
        const newData = clone(data);
        newData.starting_rent = getTotalSqft(data);
        setData(newData);
    }, [data.total_sf_leased]);

    const setTheContact = (contactData, selectedContact) => {
        if (contactData) {
            const newData = clone(data);

            if (contactData && queryFor === "billing_information") {
                const pc = contactData;

                newData.billing_id = pc.id;
                newData.billing_company = pc.company_name;
                newData.billing_attention = pc.first_name;
                newData.billing_attention_lastname = pc.last_name;

                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newData.billing_address1 = m.street1;
                        newData.billing_address2 = m.street2;
                        newData.billing_city = m.city;
                        newData.billing_state = m.state;
                        newData.billing_zip = m.zip;
                        newData.billing_phone = pc.main_phone;
                        newData.billing_fax = pc.fax;
                        newData.billing_email = pc.email;
                    }
                }
                set(newData, `chosen.${queryFor}.contact`, pc.id);
                set(newData, `chosen.${queryFor}.company`, '');
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessors") {
                const pc = contactData;

                const newLessor = {
                    id: pc.id,
                    name: pc.first_name,
                    last_name: pc.last_name,
                    main_phone: pc.main_phone,
                    mobile_phone: pc.mobile_phone,
                    work_phone: pc.work_phone,
                    phone_type: get(selectedContact, 'overrideContactPhone.type', null),
                    fax: pc.fax,
                    email: pc.email,
                    agency_disclosure: "",
                    can_we_reach_out_for_testimonial: "",
                    can_we_reach_out_for_testimonial_comment: "",
                };

                // extract address
                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newLessor.address1 = m.street1;
                        newLessor.address2 = m.street2;
                        newLessor.city = m.city;
                        newLessor.state = m.state;
                        newLessor.zip = m.zip;
                    }
                }
                newData.lessors = newData.lessors.concat([newLessor]);
                const contact_ids = get(newData, `chosen.${queryFor}.contact`, []);
                contact_ids.push(pc.id);
                set(newData, `chosen.${queryFor}.contact`, contact_ids);
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessees") {
                const pc = contactData;

                const newLessee = {
                    id: pc.id,
                    name: pc.first_name,
                    last_name: pc.last_name,
                    main_phone: pc.main_phone,
                    mobile_phone: pc.mobile_phone,
                    work_phone: pc.work_phone,
                    phone_type: get(selectedContact, 'overrideContactPhone.type', null),
                    fax: pc.fax,
                    email: pc.email,
                    agency_disclosure: "",
                    can_we_reach_out_for_testimonial: "",
                    can_we_reach_out_for_testimonial_comment: "",
                    llc_corp_verified: "",
                    upload_sos: "",
                    spousal_consent: "",
                    upload_spousal_id: "",
                    guarantor: "",
                    guarantor_name: "",
                    upload_guarantor_id: "",
                    guarantor_spouse_name: "",
                };

                // extract address
                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newLessee.address1 = m.street1;
                        newLessee.address2 = m.street2;
                        newLessee.city = m.city;
                        newLessee.state = m.state;
                        newLessee.zip = m.zip;
                    }
                }
                newData.lessees = newData.lessees.concat([newLessee]);
                const contact_ids = get(newData, `chosen.${queryFor}.contact`, []);
                contact_ids.push(pc.id);
                set(newData, `chosen.${queryFor}.contact`, contact_ids);
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "outside_broker") {
                const pc = contactData;

                newData.outside_broker_id = pc.id;
                newData.outside_broker_name = pc.first_name;
                newData.outside_broker_lastname = pc.last_name;
                newData.outside_broker_main_phone = pc.main_phone;
                newData.outside_broker_fax = pc.fax;
                newData.outside_broker_email = pc.email;
                if (pc.file_list) {
                    if (pc.file_list.length > 0) {
                        pc.file_list.forEach((pfl) => {
                            const filename = pfl.name;
                            if (filename) {
                                if (filename.indexOf("W9") !== -1) {
                                    newData.outside_broker_w9 = "Yes";
                                    newData.outside_broker_w9_upload = `lessees/outside_broker/${filename}`;
                                }
                            }
                        });
                    }
                }

                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newData.outside_broker_address1 = m.street1;
                        newData.outside_broker_city = m.city;
                        newData.outside_broker_state = m.state;
                        newData.outside_broker_zip = m.zip;
                    }
                }
                set(newData, `chosen.${queryFor}.contact`, pc.id);
                set(newData, `chosen.${queryFor}.company`, '');
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessor_outside_broker") {
                const pc = contactData;

                newData.lessor_outside_broker_id = pc.id;
                newData.lessor_outside_broker_name = pc.first_name;
                newData.lessor_outside_broker_lastname = pc.last_name;
                newData.lessor_outside_broker_main_phone = pc.main_phone;
                newData.lessor_outside_broker_fax = pc.fax;
                newData.lessor_outside_broker_email = pc.email;

                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newData.lessor_outside_broker_address1 = m.street1;
                        newData.lessor_outside_broker_city = m.city;
                        newData.lessor_outside_broker_state = m.state;
                        newData.lessor_outside_broker_zip = m.zip;
                    }
                }
                if (pc.file_list) {
                    if (pc.file_list.length > 0) {
                        pc.file_list.forEach((pfl) => {
                            const filename = pfl.name;
                            if (filename) {
                                if (filename.indexOf("W9") !== -1) {
                                    newData.lessor_outside_broker_w9 = "Yes";
                                    newData.lessor_outside_broker_w9_upload = `lessees/outside_broker/${filename}`;
                                }
                            }
                        });
                    }
                }
                set(newData, `chosen.${queryFor}.contact`, pc.id);
                set(newData, `chosen.${queryFor}.company`, '');
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "referral_firm") {
                const pc = contactData;

                newData.referral_firm_id = pc.id;
                newData.referral_firm_name = pc.first_name;
                newData.referral_firm_lastname = pc.last_name;
                newData.referral_firm_main_phone = pc.main_phone;
                newData.referral_firm_fax = pc.fax;
                newData.referral_firm_email = pc.email;

                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newData.referral_firm_address1 = m.street1;
                        newData.referral_firm_city = m.city;
                        newData.referral_firm_state = m.state;
                        newData.referral_firm_zip = m.zip;
                    }
                }
                if (pc.file_list) {
                    if (pc.file_list.length > 0) {
                        pc.file_list.forEach((pfl) => {
                            const filename = pfl.name;
                            if (filename) {
                                if (filename.indexOf("W9") !== -1) {
                                    newData.referral_firm_w9 = "Yes";
                                    newData.referral_firm_w9_upload = `lessees/outside_broker/${filename}`;
                                }
                            }
                        });
                    }
                }
                set(newData, `chosen.${queryFor}.contact`, pc.id);
                set(newData, `chosen.${queryFor}.company`, '');
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessee_referral_firm") {
                const pc = contactData;

                newData.lessee_referral_firm_id = pc.id;
                newData.lessee_referral_firm_name = pc.first_name;
                newData.lessee_referral_firm_lastname = pc.last_name;
                newData.lessee_referral_firm_main_phone = pc.main_phone;
                newData.lessee_referral_firm_fax = pc.fax;
                newData.lessee_referral_firm_email = pc.email;

                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newData.lessee_referral_firm_address1 = m.street1;
                        newData.lessee_referral_firm_city = m.city;
                        newData.lessee_referral_firm_state = m.state;
                        newData.lessee_referral_firm_zip = m.zip;
                    }
                }
                if (pc.file_list) {
                    if (pc.file_list.length > 0) {
                        pc.file_list.forEach((pfl) => {
                            const filename = pfl.name;
                            if (filename) {
                                if (filename.indexOf("W9") !== -1) {
                                    newData.lessee_referral_firm_w9 = "Yes";
                                    newData.lessee_referral_firm_w9_upload = `lessees/outside_broker/${filename}`;
                                }
                            }
                        });
                    }
                }
                set(newData, `chosen.${queryFor}.contact`, pc.id);
                set(newData, `chosen.${queryFor}.company`, '');
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessee_contacts") {
                const pc = contactData;

                const newObj = {
                    id: pc.id,
                    name: pc.first_name,
                    last_name: pc.last_name,
                    email: pc.email,
                    contact_type: pc.contact_type,
                    main_phone: pc.main_phone,
                };
                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newObj.address1 = m.street1;
                        newObj.address2 = m.street2;
                        newObj.city = m.city;
                        newObj.state = m.state;
                        newObj.zip = m.zip;
                    }
                }
                if (newData.lessee_contacts) {
                    let found = false;
                    newData.lessee_contacts.forEach((nd, i) => {
                        if (nd.id === pc.id) {
                            newData.lessee_contacts[i] = newObj;
                            found = true;
                        }
                    });
                    if (!found) {
                        newData.lessee_contacts.push(newObj);
                    }
                }
                const contact_ids = get(newData, `chosen.${queryFor}.contact`, []);
                contact_ids.push(pc.id);
                set(newData, `chosen.${queryFor}.contact`, contact_ids);
                setData(newData)
                setQueryFor('')
                return;
            } else if (contactData && queryFor === "lessor_contacts") {
                const pc = contactData;
                const newObj = {
                    id: pc.id,
                    name: pc.first_name,
                    last_name: pc.last_name,
                    email: pc.email,
                    contact_type: pc.contact_type,
                    main_phone: pc.main_phone,
                };
                for (let i = 0; i < contactData.addresses.length; i++) {
                    const m = contactData.addresses[i];
                    if (m.type === "M") {
                        newObj.address1 = m.street1;
                        newObj.address2 = m.street2;
                        newObj.city = m.city;
                        newObj.state = m.state;
                        newObj.zip = m.zip;
                    }
                }
                if (newData.lessor_contacts) {
                    let found = false;
                    newData.lessor_contacts.forEach((nd, i) => {
                        if (nd.id === pc.id) {
                            newData.lessor_contacts[i] = newObj;
                            found = true;
                        }
                    });
                    if (!found) {
                        newData.lessor_contacts.push(newObj);
                    }
                }
                const contact_ids = get(newData, `chosen.${queryFor}.contact`, []);
                contact_ids.push(pc.id);
                set(newData, `chosen.${queryFor}.contact`, contact_ids);
                setData(newData)
                setQueryFor('')
                return;
            }
        }
    }

    const useFlatCommission = get(data, 'flat_fee_commission') === 'Yes' ? get(data, 'flat_fee_commission_amount') : null;

    const sections = generateSections(data, props.internalReviewers);

    const renderNav = () => {
        const section_count = Object.keys(sectionNames).length - 1;
        return (
            <div style={{ marginBottom: 10 }}>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        setStep(step - 1);
						checkAllChanges(data, {
							getCompanyChanges: props.getCompanyChanges,
							getContactChanges: props.getContactChanges,
							getPropertyChanges: props.getPropertyChanges,
						});
                        saveDraft();
                    }}
                    disabled={step === 1 ? true : false}
                >
                    <ArrowBack /> Previous
                </Button>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
						checkAllChanges(data, {
							getCompanyChanges: props.getCompanyChanges,
							getContactChanges: props.getContactChanges,
							getPropertyChanges: props.getPropertyChanges,
						});
                        saveDraft();
                        if (step < section_count) return setStep(step + 1);
                        props.setTab("full");
                    }}
                >
                    Next <ArrowForward />
                </Button>
                {!props.viewOnly && (
                    <Button
                        color="white"
                        className="mr-20"
                        onClick={() => {
							checkAllChanges(data, {
								getCompanyChanges: props.getCompanyChanges,
								getContactChanges: props.getContactChanges,
								getPropertyChanges: props.getPropertyChanges,
							});
                            setShowTempNotification(true);
                            saveDraft("Saved");
                        }}
                    >
                        SAVE
                    </Button>
                )}
            </div>
        );
    };

    const saveRS = (overrideData) => {
        checkAllChanges(data, {
            getCompanyChanges: props.getCompanyChanges,
            getContactChanges: props.getContactChanges,
            getPropertyChanges: props.getPropertyChanges,
        });
        setShowTempNotification(true);
        saveDraft("Saved", overrideData);
    }

    const renderElement = (field, access, warning, extraComponent, overrideLabel, extraValidations = [], associatedWithCompany, notAssociatedWithCompany, calcs) => {
        return (
            <FormElement
                field={field}
                data={data}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                formDataObj={props.formDataObj}
                setFormDataObj={props.setFormDataObj}
                setData={setData}
                sheetType="TenantRep"
                access={access}
                warning={warning}
                extraComponent={extraComponent}
                overrideLabel={overrideLabel}
                setQueryFor={setQueryFor}
                components={{
                    FileLoading: FileLoading,
                    FileUpload: FileUpload,
                    InvoicePayment: InvoicePayment,
                    Lessees: Lessees,
                    Lessors: Lessors,
                    Term: Term,
                }}
				extraValidations={extraValidations}
				associatedWithCompany={associatedWithCompany}
				notAssociatedWithCompany={notAssociatedWithCompany}
                calcs={calcs}
                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                saveRS={saveRS}
            />
        );
    };

    const choose = (chosen, filler, dba) => {
        if (chosen.hasOwnProperty("company_data")) {
            // company was chosen
            processCompany(chosen, data, queryFor, setData, setQueryFor);
            setOpenedContactModal(false);
        } else {
            // contact was chosen
            const obj = {
                contactId: chosen.id,
                dba_name: dba?.name,
                dba_id: dba?.id,
                dba_type: 'contact',
                overrideContactPhone: chosen.overrideContactPhone
            };
            setTheContact(chosen, obj);
            setOpenedContactModal(false);
        }
    };

    const finalActions = ["SUBMIT", "APPROVE / REJECT"];

	let hasChangesData = checkChangesData(
		props.propertyChangesData,
		props.contactChangesData,
		props.companyChangesData,
        data
	);

    let should_skip_company_contact = false;

    const chooseProperty = (chosen) => {
		// open property
		const newData = clone(data);
		newData.property_id = chosen.id;
		newData.illi_number = chosen.illi_number ? chosen.illi_number : chosen.id;
		newData.pm_number = chosen.pm_number;
		newData.street1 = chosen.street1;
		newData.project_name = chosen.name;
		newData.project_address = createAddress(chosen);
		newData.premises_address = chosen.street1;
		newData.unit_number = chosen.street2;
		newData.city = chosen.city;
		newData.state = chosen.state;
		newData.county = chosen.county;
		newData.zip = chosen.zip;
		newData.unit_number = "";
		newData.la_city_verification = chosen.la_city_verification === "1" ? "Yes" : "No";
		newData.total_sf_leased = "";
		newData.starting_rent = getTotalSqft(data);
		newData.spaces = chosen.spaces ? JSON.parse(JSON.stringify(chosen.spaces)) : [];

		set(newData, 'chosen.property', chosen.id)

		if (chosen.spaces) {
			if (chosen.spaces.length > 0) {
				if (chosen.spaces.length === 1) {
					// automatically choose space
					const chos = chosen.spaces[0];
					newData.unit_number = chos.unit_number_address;
					newData.total_sf_leased = chos.total_sqft;
					newData.flat_fee_space = get(chos, 'is_flat_price', '');
				} else {
					setOpenedChooseSpacesModal(true);
				}
			}
		}

		// property type --- use type in Property ?
		if (chosen.use_type) {
			newData.property_type = chosen.use_type;
		}

		setPropertyDat(chosen);
		setData(newData);
		setOpenedPropertyModal(false);
	}

	const chooseCompany = (chosen) => {
		// name -> bussiness_name
		// use_type -> type_of_bussiness
		const newData = clone(data);
		newData.business_name = chosen.name || '';
		newData.type_of_business = chosen.use_type || '';
		setData(newData);
		setOpenedCompanyModal(false);
	}

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={9} md={9}>
                    <h3>
                        <span className="font-10">
                            {step} of {Object.keys(sectionNames).length} -{" "}
                        </span>{" "}
                        {stepTitle[step]}
                    </h3>
                    {renderNav()}
                    <hr />

                    {!props.canEditInvoiceRelatedData && <Button
                        color="primary"
                        onClick={() => {
                            props.setOpenedInvoiceRelatedDataAsk(true);
                        }}
                    >
                        EDIT ROUTING SHEET INVOICE RELATED DATA
                    </Button>}

                    <div className={`${viewOnly ? 'lightgray-bg no-click enclosure' : ''}`}>
                        {/* Property Information */}
                        {step === stepNames['Property Information'] && (
                            <Step1
                                setOpenedChooseCRMModal={setOpenedChooseCRMModal}
                                renderElement={renderElement}
                                data={data}
                                propertyDat={propertyDat}
                                userPerms={userPerms}
                                setData={setData}
                                rsTypes={props.rsTypes}
                                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                            />
                        )}

                        {/* Premises Information */}
                        {step === stepNames['Premises Information'] && (
                            <Step2
                                renderElement={renderElement}
                                data={data}
                                setOpenedChooseSpacesModal={setOpenedChooseSpacesModal}
                                propertyDat={propertyDat}
                            />
                        )}

                        {/* Lease Information */}
                        {step === stepNames['Lease Information'] && (
                            <Step3
                                renderElement={renderElement}
                                updateDataObj={updateDataObj}
                                data={data}
                                propertyData={propertyData}
                                setData={setData}
								setOpenedCompanyModal={setOpenedCompanyModal}
                                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                            />
                        )}

                        {step === stepNames['Pre-Splits'] && <PreSplitsForm 
                            data={data}
                            setData={setData}
                        />}

                        {/* Lessee */}
                        {step === stepNames['Lessee'] && (
                            <>
                                <Step4
                                    setQueryFor={setQueryFor}
                                    getContacts={props.getContacts}
                                    setOpenedContactModal={setOpenedContactModal}
                                    renderElement={renderElement}
                                    data={data}
                                    setData={setData}
                                    canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                                />
                                {data.lessee_referral_firm_id && <OtherReferrals data={data} setData={setData} title="OTHER LESSEE REFERRAL(S)" name="lessee_referrals" />}
                            </>
                        )}

                        {/* Lessor */}
                        {step === stepNames['Lessor'] && (
                            <>
                                <Step5
                                    setQueryFor={setQueryFor}
                                    getContacts={props.getContacts}
                                    setOpenedContactModal={setOpenedContactModal}
                                    renderElement={renderElement}
                                    data={data}
                                    setData={setData}
                                    canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                                />
                                {data.referral_firm_id && <OtherReferrals data={data} setData={setData} title="OTHER LESSOR REFERRAL(S)" name="lessor_referrals" />}
                            </>
                        )}

                        {/* Commission Splits  */}
                        {step === stepNames['Commission Splits'] && (
                            <Step6
                                data={data}
                                updateDataObj={updateDataObj}
                                setData={setData}
                                getContacts={props.getContacts}
                                getContact={props.getContact}
                                contactsData={props.contactsData}
                                contactData={props.contactData}
                            />
                        )}

                        {step === stepNames['Commission Adjustments'] && (
                        <div className={!props.canEditInvoiceRelatedData ? 'gray-bg-input avoid-clicks' : ''}>
                            <CommissionAdjustment 
                                data={data}
                                updateDataObj={updateDataObj}
                                setData={setData}
                                renderElement={renderElement}
                                commissionValue={useFlatCommission}
                            />
                        </div>
                        )}

                        {/* Billing Information */}
                        {step === stepNames['Billing Information'] && (
                            <Billing 
                                data={data}
                                setData={setData}
                                renderElement={renderElement}
                                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
                            />
                        )}

                        {/* Copy of Invoice  */}
                        {step === stepNames['Copy of Invoice'] && (
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    {renderElement("copy_of_invoice")}
                                    {data.copy_of_invoice.length === 0 && <div className='red'>At least one invoice is required</div>}
                                    {data.copy_of_invoice && (
                                        <div>
                                            {data.copy_of_invoice.indexOf("Other") !== -1 && (
                                                <div>{renderElement("copy_of_invoice_other")}</div>
                                            )}
                                        </div>
                                    )}
                                </GridItem>
                            </GridContainer>
                        )}

                        {/* Payment Schedule  */}
                        {step === stepNames['Payment Schedule'] && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    {renderElement("payment_schedule")}
                                </GridItem>
                            </GridContainer>
                        )}

                        {/* Comments and Special Instruction  */}
                        {step === stepNames['Comments and Special Instructions'] && (
                            <GridContainer>
                                <GridItem xs={12} sm={12} md={12}>
                                    {renderElement("comments_and_special_instructions")}
                                </GridItem>
                            </GridContainer>
                        )}

                        {/* Documents  */}
                        {step === stepNames['Documents'] && (
                            <Documents
                                data={data}
                                updateDataObj={updateDataObj}   
                                setData={setData}                            
                            />
                        )}

                        {/* Internal Review */}
                        {step === stepNames['Internal Review'] && (
                            <InternalReview 
                                data={data}
                                updateDataObj={updateDataObj}   
                                setData={setData} 
                                rsheetData={props.rsheetData}
                                close={props.closeTheForm}
                            />
                        )}
                    </div>

                    {step === stepNames['SUBMIT'] && !viewOnly && (
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <RSheetsTenantRepFormActions
                                    data={data}
                                    setOpenedForm={props.setOpenedForm}
                                    formDataObj={props.formDataObj}
                                    setFormDataObj={props.setFormDataObj}
                                    updateDataObj={props.updateDataObj}
                                    level={props.level}
                                    getAdminData={props.getAdminData}
                                    setActivityTracker={props.setActivityTracker}
                                    hasSubmitted={hasSubmitted}
                                    setHasSubmitted={setHasSubmitted}
                                    tab={props.tab}
                                    closeTheForm={props.closeTheForm}
                                    historyPush={props.historyPush}
                                    save={props.save}
                                />
                            </GridItem>
                        </GridContainer>
                    )}

                    <hr />
                    {renderNav()}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {/* Tracker */}
                    <List component="nav">
                        {sections.map((s, i) => {
                            const name =
                                max_approver_level && i === sections.length - 1 ? (
                                    <strong>APPROVE</strong>
                                ) : s.name === "SUBMIT" ? (
                                    <strong>SUBMIT</strong>
                                ) : (
                                    s.name
                                );
                            if (s.name === 'SUBMIT') return null;
                            return (
                                <ListItem
                                    key={`li-${i}`}
                                    button
                                    onClick={() => {
                                        setStep(i + 1);
                                        if (!viewOnly) {
                                            checkAllChanges(data, {
                                                getCompanyChanges: props.getCompanyChanges,
                                                getContactChanges: props.getContactChanges,
                                                getPropertyChanges: props.getPropertyChanges,
                                            });
                                            saveDraft();
                                        }
                                    }}
                                >
                                    {finalActions.indexOf(s.name) === -1 && (
                                        <ListItemIcon>
                                            {s.complete ? (
                                                <i
                                                    className={`fas fa-check font-22 ${
                                                        step - 1 === i ? "bold red-text" : ""
                                                    }`}
                                                ></i>
                                            ) : (
                                                <i
                                                    className={`fas fa-times font-22 ${
                                                        step - 1 === i ? "bold red-text" : ""
                                                    }`}
                                                ></i>
                                            )}
                                        </ListItemIcon>
                                    )}
                                    <ListItemText
                                        inset
                                        primary={<div className={step - 1 === i ? "bold red-text" : ""}>{name}</div>}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </GridItem>
            </GridContainer>

            {openedChooseCRMModal && !viewOnly && (
                <Dialog
                    open={openedChooseCRMModal}
                    onClose={() => {
                        setOpenedChooseCRMModal(false);
                    }}
                >
                    <div className="dialog-wrapper">
                        <div
                            className="close-btn"
                            onClick={() => {
                                setOpenedChooseCRMModal(false);
                            }}
                        >
                            <Close />
                        </div>
                        <h4 className="text-center">Choose Property Database</h4>
                        <Button
                            color="primary"
                            className="mr-20"
                            onClick={() => {
                                setPropertyOwner("illi");
                                setOpenedChooseCRMModal(false);
                                setOpenedPropertyModal(true);
                            }}
                        >
                            illi Database
                        </Button>
                        {<Button
                            color="primary"
                            onClick={() => {
                                if (isAgent) {
                                    setPropertyOwner("own");
                                    setOpenedChooseCRMModal(false);
                                    setOpenedPropertyModal(true);
                                }
                            }}
                            style={{opacity: isAgent ? 1 : 0}}
                        >
                            Your Database
                        </Button>}
                    </div>
                </Dialog>
            )}

            {openedChooseSpacesModal && !viewOnly && (
                <Dialog
                    open={openedChooseSpacesModal}
                    onClose={() => {
                        setOpenedChooseSpacesModal(false);
                    }}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <div className="dialog-wrapper">
                        <div
                            className="close-btn"
                            onClick={() => {
                                setOpenedChooseSpacesModal(false);
                            }}
                        >
                            <Close />
                        </div>
                        <h4 className="text-center">Choose Property Space</h4>
                        <CustomSelect
                            options={data.spaces ? data.spaces.map((m) => m.unit_number_address) : []}
                            label="Property Space"
                            choose={(e, n) => {
                                const newData = clone(data);
                                newData.unit_number = e;
                                newData.flat_fee_space = get(e, 'is_flat_price', '');
                                let total_sf_leased_chosen = null;
                                let flat_fee_space = '';
                                data.spaces.forEach((ds) => {
                                    if (ds.unit_number_address === e) {
                                        total_sf_leased_chosen = ds.total_sqft;
                                        flat_fee_space = get(ds, 'is_flat_price', '');
                                    }
                                });
                                if (total_sf_leased_chosen) {
                                    newData.total_sf_leased = total_sf_leased_chosen;
                                }
                                newData.flat_fee_space = flat_fee_space;
                                setData(newData);
                                setOpenedChooseSpacesModal(false);
                            }}
                            default={data.unit_number}
                        />
                    </div>
                </Dialog>
            )}

            {openedPropertyModal && !viewOnly && (
                <CustomDialogWithProperty
                    propertyOwner={propertyOwner}
                    open={openedPropertyModal}
                    close={() => {
                        setOpenedPropertyModal(false);
                    }}
                    property_is_for_override={"Lease"}
					property_is_for={["Lease"]}
                    has_spaces={true}
                    choose={chooseProperty}
                    create_and_choose={chooseProperty}
                    title="Search Existing Property"
                    label="Search Existing Property"
                    filter_message="Any property for lease as long as there is at least 1 space that isn't multi-family or residential"
                    rs_type="Tenant Rep"
                />
            )}

            {openedCompanyModal && !viewOnly &&(
                <CustomDialogWithCompany
					open={openedCompanyModal}
                    close={() => setOpenedCompanyModal(false)}
                    title="Search Company"
                    label="Search Company"
                    choose={chooseCompany}
                    create_and_choose={chooseCompany}
                />
            )}

            {openedContactModal && !viewOnly && (
                <CustomDialogChooser
                    open={openedContactModal}
                    close={() => {
                        setOpenedContactModal(false);
                    }}
                    choose={choose}
                    create_and_choose={choose}
                    title="Select Contact or Company"
                    label="Search Contacts"
                    should_skip_company_contact={should_skip_company_contact}
                    showContactCompaniesTab={true}
                    prefill={
                        queryFor === "lessees"
                            ? {
                                  contact_type: "Lessee",
                                  contact_subtype: "Lessee",
                              }
                            : {}
                    }
                    no_property={true}
                    allowNoContacts={queryFor === 'lessees' || queryFor === 'lessors'}
                    queryFor={queryFor}
                />
            )}

            {notification && (
                <NotificationFixedTop
                    open={notification ? true : false}
                    close={() => {
                        setNotificationFinal("");
                    }}
                    message={notification}
                />
            )}

            {persistentNotification && (
                <Notification
                    open={persistentNotification ? true : false}
                    close={() => {
                        setPersistentNotification("");
                    }}
                    message={persistentNotification}
                />
            )}

			{hasChangesData && !isDeclined && (
				<div>
					<div>
						<SaveBack
							open={true}
							decline={() => setIsDeclined(true)}
							close={() => {
								props.setContactChanges(null);
								props.setPropertyChanges(null);
								props.setCompanyChanges(null);
							}}
                            data={data}
						/>
					</div>
				</div>
			)}
            
        </div>
    );
};

const mapStateToProps = (state) => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    const m = state.crm_companies;
    return {
        propertyData: p.propertyData,
        propertiesData: p.propertiesData.data,
        propertyNames: p.propertyNames,
        propertyContacts: p.propertyContacts,
        contactsData: c.contactsData,
        contactData: c.contactData,
        addressData: c.addressData,
        error: state.rs.error,
        success: state.rs.success,
        contactChangesData: c.contactChangesData,
        companyChangesData: c.companyChangesData,
		propertyChangesData: p.propertyChangesData,
        internalReviewers: state.rs.internal_reviewers_choices,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getCrmFileList: (data) => {
            dispatch(actions.getCrmFileList(data));
        },
        getProperty: (id) => {
            dispatch(actions.getProperty(id));
        },
        getProperties: (params) => {
            dispatch(actions.getProperties(params));
        },
        getPropertyNames: (type) => {
            dispatch(actions.getPropertyNames(type));
        },
        getPropertyContacts: (contact_id) => {
            dispatch(actions.getPropertyContacts(contact_id));
        },
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getContact: (id, email, include_files) => {
            dispatch(actions.getContact(id, email, include_files));
        },
        getContactChanges: (changes) => {
            dispatch(actions.getContactChanges(changes));
        },
        setContactChanges: (changes) => {
            dispatch(actions.setContactChanges(changes));
        },
        getCompanyChanges: (changes) => {
            dispatch(actions.getCompanyChanges(changes));
        },
        setCompanyChanges: (changes) => {
            dispatch(actions.setCompanyChanges(changes));
        },
        getPropertyChanges: (changes) => {
            dispatch(actions.getPropertyChanges(changes));
        },
        setPropertyChanges: (changes) => {
            dispatch(actions.setPropertyChanges(changes));
        },
        clearRSheetsMessages: () => {
            dispatch(actions.clearRSheetsMessages());
        },
        updateRsheet: (params) => {
            dispatch(actions.updateRsheet(params));
        },
        createRsheet: (data) => {
            dispatch(actions.createRsheet(data));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
        getAddresses: (contact_id) => {
            dispatch(actions.getAddresses(contact_id));
        },
        updateProperty: (data) => {
            dispatch(actions.updateProperty(data));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsTenantRepFormWizard);
