import { put } from 'redux-saga/effects';
import axios from '../axios/axios-contact-import';
import * as actions from '../actions/contact_import';

export function* getDialpadContactsSaga(action) {
	yield put(actions.getDialpadContactsStart());
	try {
		const response = yield axios.get('/dialpad/contacts');
		yield put(actions.getDialpadContactsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getDialpadContactsFail(e.response.data));
			}
		}
		yield put(actions.getDialpadContactsFail(e));
	}
}

export function* importDialpadContactsSaga(action) {
	yield put(actions.importDialpadContactsStart());
	try {
		const response = yield axios.post('/dialpad/import', action.data);
		yield put(actions.importDialpadContactsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.importDialpadContactsFail(e.response.data));
			}
		}
		yield put(actions.importDialpadContactsFail(e));
	}
}

export function* getLogHistorySaga(action) {
	yield put(actions.getLogHistoryStart());
	try {
		const response = yield axios.get('/dialpad/log-history');
		yield put(actions.getLogHistorySuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getLogHistoryFail(e.response.data));
			}
		}
		yield put(actions.getLogHistoryFail(e));
	}
}

export function* getGoogleContactsSaga(action) {
	yield put(actions.getGoogleContactsStart());
	try {
		const response = yield axios.get('/google/contacts');
		yield put(actions.getGoogleContactsSuccess(response.data));
	} catch (e) {
		if (e.response) {
			if (e.response.data) {
				return yield put(actions.getGoogleContactsFail(e.response.data));
			}
		}
		yield put(actions.getGoogleContactsFail(e));
	}
}
