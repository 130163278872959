import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import TREntries from "../../containers/TR/Entries/TREntries";
import TRTemplates from "../../containers/TR/Templates/TRTemplates";
import { getParameterByName } from "../../shared/utility";

class TRPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        tab: ''
    }
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
    const actionTab = getParameterByName('s', window.location.href);
    if (actionTab) {
        this.setState({tab:actionTab});
    }
  }

  render() {
    if (!checkPermissions('TR')) {
      return <Redirect to="/dashboard" />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />
    const authorizations = checkPermissionsActions('TR');

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>

            {authorizations.hasOwnProperty('fill_form') && <CardIcon
                color={this.state.tab === 'my_drafts' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'my_drafts'})
                    window.history.replaceState(null, null, '/tr?s=my_drafts');
                }}
                className="hovered hovered-shadow"
            >
                <strong>My Drafts</strong>
            </CardIcon>}

            {authorizations.hasOwnProperty('edit_template') && <CardIcon
                color={this.state.tab === 'templates' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'templates'})
                    window.history.replaceState(null, null, '/tr?s=edit_template');
                }}
                className="hovered hovered-shadow"
            >
                <strong>Templates</strong>
            </CardIcon>}

            {(authorizations.hasOwnProperty('approve_level_1') || 
            authorizations.hasOwnProperty('approve_level_2')) && <CardIcon
                color={this.state.tab === 'pending_signature' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'pending_signature'})
                    window.history.replaceState(null, null, '/tr?s=pending_signature');
                }}
                className="hovered hovered-shadow"
            >
                <strong>Pending Tenant Signature</strong>
            </CardIcon>}

            {authorizations.hasOwnProperty('approve_level_1') && <CardIcon
                color={this.state.tab === 'pending_my_approval' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'pending_my_approval'})
                    window.history.replaceState(null, null, '/tr?s=pending_my_approval');
                }}
                className="hovered hovered-shadow"
            >
                <strong>Pending My Approval</strong>
            </CardIcon>}

            {authorizations.hasOwnProperty('approve_level_2') && <CardIcon
                color={this.state.tab === 'pending_final_signature' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'pending_final_signature'})
                    window.history.replaceState(null, null, '/tr?s=pending_final_signature');
                }}
                className="hovered hovered-shadow"
            >
                <strong>Pending Final Signature</strong>
            </CardIcon>}

            <CardIcon
                color={this.state.tab === 'my_completed' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'my_completed'})
                    window.history.replaceState(null, null, '/tr?s=my_completed');
                }}
                className="hovered hovered-shadow"
            >
                <strong>My Completed</strong>
            </CardIcon>

            {authorizations.hasOwnProperty('approve_level_2') && <CardIcon
                color={this.state.tab === 'all_completed' ? 'success' : 'rose'}
                onClick={() => {
                    this.setState({tab:'all_completed'})
                    window.history.replaceState(null, null, '/tr?s=all_completed');
                }}
                className="hovered hovered-shadow"
            >
                <strong>All Completed</strong>
            </CardIcon>}

          </CardHeader>
          <CardBody>
            {(
                this.state.tab === 'my_drafts' ||
                this.state.tab === 'pending_my_approval' ||
                this.state.tab === 'pending_final_signature' ||
                this.state.tab === 'my_completed' ||
                this.state.tab === 'all_completed' ||
                this.state.tab === 'pending_signature'
            ) && <TREntries 
                tab={this.state.tab} 
                authorizations={authorizations}
            />}
            {this.state.tab === 'templates' && <TRTemplates />}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
    const entries = state.tr_entries;
    const templates = state.tr_templates;
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    },
    getTrTemplates: () => {

    },
    getTrEntries: () => {

    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TRPage);
