import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";

const Step1 = (props) => {
    const { renderElement } = props;
    return <div>
        <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement('date')}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {renderElement('location')}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {renderElement('type_of_property')}
            </GridItem>
        </GridContainer>
        <hr />
    </div>
}

export default Step1;