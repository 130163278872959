const permsObj = {
  Admin: {
    'User Permissions': [
      { id:1, name:'Edit', subCategory:'User Permissions', showLabel:false}
    ],
    'User Admin': [
      { id:2, name:'Edit', subCategory:'User Admin',showLabel:false}
    ],
    'GCI': [
      { id:53, name:'Edit', subCategory:'GCI',showLabel:false}
    ]
  },
  CRM: {
    'CRM': [
      { id:3, name:'Edit', subCategory:'CRM', showLabel:false},
      { id:16, name:'Number Edit (illi #)', subCategory:'CRM', showLabel:false},
      { id:33, name:'Property Importer', subCategory:'CRM', showLabel:false},
      { id:34, name:'Other Sources Importer', subCategory:'CRM', showLabel:false},
      { id:35, name:'Costar Importer', subCategory:'CRM', showLabel:false},
      { id:37, name:'Agent Access to illi Database', subCategory:'CRM', showLabel:false},
      { id:50, name:'Allow Property Add to Website', subCategory:'CRM', showLabel:false},
    ],
    'Dialpad': [
      { id:27, name:'View', subCategory:'CRM', showLabel:false},
    ]
  },
  Documents: {
    'ERTL': [
      { id:5, name:'Submit ERTL', subCategory:'ERTL', showLabel:false}, // SUBMITTER
      { id:25, name:'Level 1 Approver', subCategory:'ERTL', showLabel:false}, // LEVEL 1 APPROVER
      { id:6, name:'Sign and Final Approve ERTL', subCategory:'ERTL', showLabel:false}, // FINAL APPROVER
      { id:4, name:'Edit Template', subCategory:'ERTL', showLabel:false},
      { id:23, name:'Number Edit (illi #)', subCategory:'ERTL', showLabel:false},
      // { id:7, name:'Approve for Execution', subCategory:'ERTL', showLabel:false}
      // { id:48, name:'Level 1 Approver', subCategory:'ERTL', showLabel:false}
    ],
    'Routing': [
      { id:8, name:'Fill Form', subCategory:'Routing', showLabel:false},
      { id:9, name:'Level 1 Approver', subCategory:'Routing', showLabel:false},
      { id:10, name:'Level 2 Approver', subCategory:'Routing', showLabel:false},
      { id:11, name:'Level 3 Approver', subCategory:'Routing', showLabel:false},
      { id:12, name:'Level 4 Approver', subCategory:'Routing', showLabel:false},
      { id:24, name:'Number Edit (illi #)', subCategory:'Routing', showLabel:false},
      { id:48, name:'Admin Routing Sheet Access', subCategory:'Routing', showLabel:false},
      { id:52, name:'View Associate Splits', subCategory:'Routing', showLabel:false},
    ],
    'PMA': [
      { id:13, name:'Submit PMA', subCategory:'PMA', showLabel:false},
      { id:14, name:'See All PMA Status', subCategory:'PMA', showLabel:false},
      { id:15, name:'Sign and Final Approve PMA', subCategory:'PMA', showLabel:false},
      { id:20, name:'Edit Template', subCategory:'PMA', showLabel:false},
      { id:22, name:'Number Edit (illi #)', subCategory:'PMA', showLabel:false},
    ],
    'Listing Submissions': [
      { id:26, name:'Submit Listing', subCategory:'Listing Submissions', showLabel:false},
      { id:28, name:'Level 1 Approver', subCategory:'Listing Submissions', showLabel:false},
      { id:26, name:'Level 2 Approver', subCategory:'Listing Submissions', showLabel:false},
      { id:28, name:'Level 3 Approver', subCategory:'Listing Submissions', showLabel:false},
      { id:26, name:'Level 4 Approver', subCategory:'Listing Submissions', showLabel:false},
    ],
    'Invoice': [
      { id:32, name:'Edit', subCategory:'Invoice', showLabel:false},
      { id:36, name:'Edit/Delete Payment History', subCategory:'Invoice', showLabel:false},
      { id:38, name:'Approve', subCategory:'Invoice', showLabel:false},
      { id:51, name:'Enter Line Items', subCategory:'Invoice', showLabel:false}
    ],
    'Commission Splits': [
      { id:40, name:'Submit', subCategory:'Commission Splits', showLabel:false},
      { id:41, name:'Level 1 Approver', subCategory:'Commission Splits', showLabel:false},
      { id:42, name:'Level 2 Approver (Final Approver)', subCategory:'Commission Splits', showLabel:false},
      { id:43, name:'Final Signature', subCategory:'Commission Splits', showLabel:false},
      { id:49, name:'Override Associates Requirement for Submission', subCategory:'Commission Splits', showLabel:false}
    ],
    'Tenant Rep': [
      { id:44, name:'Fill Form', subCategory:'Tenant Rep', showLabel:false},
      { id:45, name:'Edit Templates', subCategory:'Tenant Rep', showLabel:false},
      { id:46, name:'Level 1 Approver', subCategory:'Tenant Rep', showLabel:false},
      { id:47, name:'Level 2 Approver (Final Signature)', subCategory:'Tenant Rep', showLabel:false},
    ]
  },
  Onboarding: {
    'Onboarding': [
      { id:17, name:'Submit', subCategory:'Onboarding', showLabel:false},
      { id:18, name:'Approve', subCategory:'Onboarding', showLabel:false},
    ]
  }
}

const permsDictionary = {};
const permsDetailed = {};
const permsSimpler = [];
Object.keys(permsObj).forEach((main) => {
  Object.keys(permsObj[main]).forEach((subgroup) => {
    permsObj[main][subgroup].forEach((perm) => {
      permsSimpler.push({
        id: perm.id,
        name: perm.subCategory + ' - ' + perm.name
      })
      if (permsDetailed.hasOwnProperty(perm.subCategory)) {
        permsDetailed[perm.subCategory].push({
          id: perm.id,
          name: perm.name
        });
      } else {
        permsDetailed[perm.subCategory] = [{
          id: perm.id,
          name: perm.name
        }];
      }
      permsDictionary[perm.id] = {
        subgroup,
        main,
        name: perm.name,
        hasDBs: perm.hasDBs
      }
    });
  });
});

export const permsDict = permsDictionary;

export const permsDetailedData = permsDetailed;

export const permsSimple = permsSimpler.sort(function(a, b) {
  return a.name - b.name;
});

console.log('permsSimple', permsSimple, permsDetailedData)

export const permsList = {
  hasDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? true : false),
  noDBs: Object.keys(permsDictionary).filter(perm_id => permsDictionary[perm_id].hasDBs ? false : true)
}

export default permsObj;
