import React from "react";
import { concat, get, set } from "lodash";
import { clone } from "shared/utility";
import moment from "moment";
import Datetime from "react-datetime";
import { connect } from "react-redux";
import { Dialog, FormControlLabel, FormGroup } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import * as actions from "store/actions";

import Button from "components/CustomButtons/Button";
import CustomDialogWithContact from "components/CustomDialog/CustomDialogWithContact";
import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomTextArea from "components/CustomTextArea/CustomTextArea.jsx";
import CustomInputStates from "components/CustomInput/CustomInputStates";
import CustomInput from "components/CustomInput/CustomInputValidate";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import FileUploader from "components/FileUploader/FileUploader";

import {
    email,
    max10,
    max100,
    max15,
    max20,
    max250,
    max4,
    max50,
    max500,
    min1,
    min10,
    min4,
    min6,
    no_letters,
    no_numbers,
    no_special_chars,
    required,
	percentage,
} from "shared/validation";

const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500,
	percentage,
};

const Element = props => {
    const { data, setData, field, model, access, extraValidations = [], isRequired, fileType } = props;

    const getValidations = (f) => {
        let validations = [];
        if (model[field].validations) {
            validations = sheetObj[sheetType][f].validations;
        }
        validations = concat(validations, extraValidations);
        if (isRequired) {
            validations = concat(validations, 'required');
        }
        return validations.map((v) => validate[v]);
    };

    const updateDataObj = (field, value) => {
        const newObj = clone(data);
        newObj[field] = value;
        setData(newObj);
    }

    if (!model[field]) return null;

    if (model[field].type === 'input') {
        let the_label = model[field].label;
        if (props.overrideLabel) {
            the_label = props.overrideLabel;
        }
        const inputType = get(model, `[${field}].inputType`, '');
        const isCurrency = inputType === 'number' ? true : false;

        const startAdornment = model[field].prefix ? 
            model[field].prefix 
        : 
            model[field].dollar ? 
                '$'
            :
                false;
        return (
            <CustomInput
                label={the_label}
                value={data[field] ? data[field] : ""}
                onChange={(e) => {
                    updateDataObj(field, e.target.value);
                }}
                validate={getValidations(field)}
                readonly={model[field].readonly || access === "no" ? true : false}
                readonly_show_gray={true}
                startAdornment={startAdornment}
                endAdornment={model[field].suffix ? model[field].suffix : false}
                isCurrency={isCurrency}
                disabled={model[field].readOnly ? true : false}
            />
        );
    } else if (model[field].type === 'select') {
        return (
            <div className={`${isRequired && !data[field] ? "required-date" : ""}`}>
                <CustomSelect
                    label={model[field].label}
                    options={model[field].options}
                    choose={(e, n) => {
                        if (model[field].readOnly) return;
                        updateDataObj(field, e);
                    }}
                    default={data[field]}
                />
                {isRequired && !data[field] && <div className="red-text small-text">Required</div>}
            </div>
        );
    } else if (model[field].type === 'radio') {
        const style = { display:'block' };
        return (
            <div className={`${model[field].readonly ? "gray-bg-input" : ""}`}
                style={style}
            >
                <div className={`${isRequired && !data[field] ? "required-radio" : ""}`}>
                    <CustomRadio
                        label={[model[field].label].concat(model[field].options).join(",")}
                        options={model[field].options}
                        onChange={(e) => {
                            updateDataObj(field, e.target.value);
                        }}
                        value={data[field]}
                        readonly={model[field].readonly ? true : false}
                    />
                    {isRequired && !data[field] && <div className="red-text mb-20 small-text">Required</div>}
                </div>
            </div>
        );
    } else if (model[field].type === 'file_upload') {
        return (
            <div className="boxed" style={{marginTop:10}}>
                <div style={{marginBottom:10}}>{model[field].label}</div>
                <FileUploader 
                    the_filename={data[field]}
                    update={(val) => {
                        updateDataObj(field, val);
                    }}
                    remove={() => {
                        updateDataObj(field, '');
                    }}
                    type={fileType}
                />
                {isRequired && !data[field] && <div className="red"><small style={{fontSize:10}}>Required</small></div>}
            </div>
            )
    } else if (model[field].type === 'state') {
        return (
            <CustomInputStates
                label={model[field].label}
                onChange={(val) => {
                    updateDataObj(field, val);
                }}
                value={data[field]}
                validate={isRequired ? [required] : []}
                readonly={model[field].readonly ? true : false}
            />
        );
    } else {
        return null;
    }
}

export default Element;