import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Table from "components/Table/Table.jsx";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.jsx";
import { IconButton } from '@material-ui/core';
import { clone } from "shared/utility";
import { getUser } from "shared/authValidation";

const dict = {
    R: 'Read only',
    F: 'Full access (Update, Delete)',
    E: 'Edit (Update only)'
}

const retrieveUsername = (id, usernames) => {
    let name = null;
    if (usernames && id) {
        usernames.forEach(u => {
            if (u.id === id) name = u.name;
        })
    }
    return name;
}

const ContactsSharing = (props) => {
    const [users, setUsers] = useState([]); // already shared
    const [search, setSearch] = useState('');
    const [userType, setUserType] = useState(null);
    const [update, setUpdate] = useState(null);

    useEffect(() => {
        if (props.dataObj.id) {
            props.getContactShares(props.dataObj.id);
        }
        if (props.getContactUsernames) {
            props.getContactUsernames();
        }
        if (props.action === 'Add') {
            setUsers(clone(props.sharesForAdd));
        }
        const user = getUser();
        if (user) setUserType(user.type);
    }, []);

    useEffect(() => {
        setUsers(clone(props.sharesForAdd));
    }, [props.sharesForAdd])

    useEffect(() => {
        const contactShares = props.contactShares ? props.contactShares : [];
        setUsers(clone(contactShares));
    }, [props.contactShares])

    useEffect(() => {
        console.log('updating..', update, props.success);
        if (update !== null) {
            if (props.success) {
                props.updateDataObj('connect_user_id', update);
                setUpdate(null);
            }
        }
    }, [props.success, update]);

    const usersArr = users.sort((a, b) => (a.name > b.name) ? 1 : -1).map((u,i) => {
            
            return [
                (userType === 'A' && u.user_id === 1) ? 'illi' : u.name,
                dict[u.access],
                <div className="red-text hoverable"
                    onClick={() => {
                        if (props.action !== 'Add') {
                            props.deleteContactShare(u.id);
                            setTimeout(() => {
                                props.getContactShares(props.dataObj.id);
                            }, 1000);
                        } else {
                            removeShare(i);
                        }
                    }}
                ><Close /></div>
            ]
        });

    

    const addShare = (obj) => {
        const newShareArr = clone(props.sharesForAdd);
        props.setSharesForAdd([ ...newShareArr, obj]);
    }

    const removeShare = (j) => {
        const newShareArr = clone(props.sharesForAdd);
        props.setSharesForAdd(newShareArr.filter((f,i) => {
            if (i !== j) return true;
        }))
    }

    const user = getUser();

    const userIsAdmin = user ? user.mail === 'dev@sap-developers.com' || user.mail === 'support@illicre.com' ? 
        true : false : false;

    console.log('ContactsSharing', props, user);

    const contactData = props.dataObj ? props.dataObj : {};

    const userConnectContact = contactData.connect_user_id ? retrieveUsername(contactData.connect_user_id, props.contactUsernames) : null;

    return (
        <GridContainer className={props.canEdit || props.action === 'Add' ? 'enclosure' : 'no-click lightgray-bg enclosure'}>
            <GridItem xs={12} sm={5} md={5}>
                {userConnectContact && <div>
                    <h4 className="bold">Connect User this contact is assigned to</h4>
                    {userConnectContact} {userIsAdmin && <IconButton className="red-text" onClick={() => {
                        if (contactData.id) {
                            props.updateContact({
                                id: contactData.id, 
                                connect_user_id: 0
                            });
                            setUpdate(0);
                        } else {
                            props.updateDataObj('connect_user_id', null);
                        }
                        
                    }}><Close /></IconButton>}
                </div>}
                {!userConnectContact && <div>
                    <h4 className="bold">No Connect User assigned to this contact</h4>
                </div>}
                <hr />
                <h4 className="bold">Users this contact {props.action === 'Add' ? 'will be' : 'is'} shared with</h4>
                <div className="illi-table">
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[<strong>Name</strong>,<strong>Access</strong>,<strong>Remove</strong>]}
                        tableData={usersArr}
                    />
                    {usersArr.length === 0 && <div className="text-center" 
                        style={{marginTop:20}}><em>None</em></div>}
                </div>
            </GridItem>
            <GridItem xs={12} sm={7} md={7} style={{display: props.canEdit || props.action === 'Add'  ? 
                                                                        'block' : 'none'}}>
                <CustomInput
                    label="Search Users"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                />
                <div style={{padding:20}}>
                    {props.contactUsernames ? 
                        props.contactUsernames.filter(f => {
                            let alreadyShared = false;
                            users.forEach(u => {
                                
                                if (u.user_id === f.id) {
                                    alreadyShared = true;
                                }
                            })
                            // Superusers / Testing Users filtered
                            if (f.id === 2) return false;
                            if (f.id === 1 && userType === 'E') return false;
                            if (alreadyShared) return false;
                            if (!search) return true;
                            const lowered = f.name.toLowerCase();
                            const s = search.toLowerCase();
                            if (lowered.indexOf(s) === 0) {
                                return true;
                            }
                            return false;
                        })
                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                        .map((m,i) => {
                            const name = m.id === 1 && userType === 'A' ? 'illi' : m.name;
                            return <div key={`cu-${i}`} className="tiny-box">
                                <Button 
                                    color="primary"
                                    size="sm"
                                    style={{marginRight:10}} 
                                    onClick={() => { 
                                        const newShare = {
                                            user_id: m.id,
                                            access: 'F'
                                        };
                                        if (props.action !== 'Add') {
                                            newShare.contact_id = props.dataObj.id;
                                            props.createContactShare(newShare);
                                            setTimeout(() => {
                                                props.getContactShares(props.dataObj.id);
                                            }, 1000);
                                        } else {
                                            newShare.name = m.name;
                                            addShare(newShare)
                                        }
                                    }}><Add /> Full</Button> 
                                <Button 
                                color="primary"
                                size="sm"
                                style={{marginRight:10}} 
                                onClick={() => { 
                                    const newShare = {
                                        user_id: m.id,
                                        access: 'E'
                                    };
                                    if (props.action !== 'Add') {
                                        newShare.contact_id = props.dataObj.id;
                                        props.createContactShare(newShare);
                                        setTimeout(() => {
                                            props.getContactShares(props.dataObj.id);
                                        }, 1000);
                                    } else {
                                        newShare.name = m.name;
                                        addShare(newShare)
                                    }
                                }}><Add /> Edit</Button> 
                                <Button 
                                color="primary"
                                size="sm"
                                style={{marginRight:10}} 
                                onClick={() => { 
                                    const newShare = {
                                        user_id: m.id,
                                        access: 'R'
                                    };
                                    if (props.action !== 'Add') {
                                        newShare.contact_id = props.dataObj.id;
                                        props.createContactShare(newShare);
                                        setTimeout(() => {
                                            props.getContactShares(props.dataObj.id);
                                        }, 1000);
                                    } else {
                                        newShare.name = m.name;
                                        addShare(newShare)
                                    }
                                }}><Add /> Read</Button> 

                                {/* EDIT MODE */}
                                {userIsAdmin && <span>
                                    {(contactData.id && (!contactData.connect_user_id && !m.contact_id)) && <Button 
                                    color="rose"
                                    size="sm"
                                    style={{marginRight:10}} 
                                    onClick={() => {
                                        props.updateContact({
                                            id: contactData.id,
                                            connect_user_id: m.id
                                        })
                                        setUpdate(m.id);
                                    }}><Add /> Connect</Button>}
                                </span>}

                                {/* ADD MODE */}
                                {userIsAdmin && <span>
                                    {(!contactData.id && (!contactData.connect_user_id && !m.contact_id)) && <Button 
                                    color="rose"
                                    size="sm"
                                    style={{marginRight:10}} 
                                    onClick={() => {
                                        props.updateDataObj('connect_user_id', m.id);
                                        setUpdate(m.id);
                                    }}><Add /> Connect</Button>}
                                    <span>{m.type === 'A' ? 'ASSOCIATE - ' : ''}</span>
                                </span>}

                                <span>{name}</span>
                            </div>
                        })
                    : 
                        null}
                </div>
            </GridItem>
        </GridContainer>
    )
}

export default ContactsSharing;