import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import CompaniesForm from 'containers/CRM/Companies/CompaniesForm';

const ContactsNewCompany = (props) => {
    const [action, setAction] = useState('View');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [tab, setTab] = useState('Main');
    const [notification, setNotification] = useState('');
    const [formDataObj, setFormDataObj] = useState({});
    const { success_data, create_and_choose, close } = props;

    const openForm = (newAction) => {
        setAction(newAction);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prev_success_data = usePrevious(props.success_data);

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);

    }, [props.success]);

    const clearMessages = () => {
        setErrorMsg('');
        setSuccessMsg('');
    }

    useEffect(() => {
        if (prev_success_data === null && success_data) {
            create_and_choose(success_data, props.success_data_dba);
            close();
        }
    }, [success_data, close, create_and_choose]);

    

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth={false}
        fullWidth={true}
    >
        <DialogTitle>
            <div className="close-btn" onClick={() => { props.close() }}><Close /></div>
            <div style={{textAlign:'center'}}></div>
        </DialogTitle>
            
        <div style={{padding:20}}>
            {
                <CompaniesForm
                    action="Add"
                    closeForm={() => {
                        props.close();
                    }}
                    openForm={openForm}
                    openAskModal={false}
                    success={props.success}
                    createCompany={props.createCompany}
                    updateCompany={() => { }}
                    deleteCompany={() => { }}
                    error={props.error}
                    companyData={{}}
                    loading={props.loading}
                    formDataObj={props.formDataObj}
                    setFormDataObj={props.setFormDataObj}
                    showCloseBtn={true}
                    notification={notification}
                    setNotification={setNotification}
                    prefill={(props.shouldPrefillCompany && props.prefill) ? props.prefill : {name:''}}
                />
            }
            <div className="text-center">
                <Button
                    color="rose"
                    style={{marginRight:10}}
                    onClick={() => {
                        props.close();
                    }}
                    className="text-center"
                >
                    CLOSE COMPANY
                </Button>
            </div>
        </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
      error: state.crm_companies.error,
      success: state.crm_companies.success,
      success_data: state.crm_companies.success_data,
      loading: state.crm_companies.loading,
      companiesData: state.crm_companies.companiesData,
      companiesCount: state.crm_companies.companiesCount,
      agentData: state.crm_companies.agentData,
      agentDataCount: state.crm_companies.agentDataCount,
      companyData: state.crm_companies,
      success_data_dba: state.crm_companies.success_data_dba
      // fileList: state.crm_companies.fileList,
      // downloadedFile: state.crm_companies.downloadedFile,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      getCompanies: (params) => {
        dispatch(actions.getCompanies(params));
      },
      getCompany: (id) => {
        dispatch(actions.getCompany(id));
      },
      createCompany: (data, partnersData, addressData, appointmentsData, sharesData, formDataObj) => {
        dispatch(actions.createCompany(data, partnersData, addressData, appointmentsData, sharesData, formDataObj));
      },
      updateCompany: (data, partnersData, addressData) => {
        dispatch(actions.updateCompany(data, partnersData, addressData));
      },
      deleteCompany: (id) => {
        dispatch(actions.deleteCompany(id));
      },    
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContactsNewCompany);