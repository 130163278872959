import { Checkbox } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import ReactTable from 'react-table';

const LogHistoryListTable = (props) => {
	const { contactsToImport, data, handleSetContactsToImport, handleSelectAll, isSelectAll, loading, setIsSelectAll } = props;

	const listItems = data.map((log, index) => {
		const { contact } = log.data;
		contact.log_id = log.id;
		const isChecked = contactsToImport.findIndex((item) => item.log_id === log.id) !== -1;
		return {
			index: <div style={{margin: '15px 0'}}>{index + 1}</div>,
			state: log.data.state,
			date_started: moment(log.data.date_started).format('MM Do YY, h:mm:ss a'),
			// duration: log.data.duration,
			...contact,
			action: (
				<div>
					<Checkbox color="default" onChange={() => handleSetContactsToImport(contact)} checked={isChecked} />
				</div>
			),
		};
	});

	const columns = [
		{
			Header: '#',
			accessor: 'index',
		},
		{
			Header: 'Name',
			accessor: 'name',
		},
		{
			Header: 'Phone',
			accessor: 'phone',
		},
		{
			Header: 'Email',
			accessor: 'email',
		},
		{
			Header: 'Type',
			accessor: 'type',
		},
		{
			Header: 'State',
			accessor: 'state',
		},
		{
			Header: 'Date/Time',
			accessor: 'date_started',
		},
		// {
		// 	Header: 'Duration',
		// 	accessor: 'duration',
		// },
		{
			Header: (
				<span>
					Select All <Checkbox checked={isSelectAll} />
				</span>
			),
			accessor: 'action',
			sortable: false,
			filterable: false,
			getHeaderProps: (state, rowInfo, column, instance) => {
				return {
					onClick: (e, handleOriginal) => {
						const { pageRows } = state;
						const contactList = [];
						pageRows.forEach((row) => {
							const contact = listItems[row._index];
							contactList.push(contact);
						});
						
						handleSelectAll(contactList);

						// IMPORTANT! React-Table uses onClick internally to trigger
						// events like expanding SubComponents and pivots.
						// By default a custom 'onClick' handler will override this functionality.
						// If you want to fire the original onClick handler, call the
						// 'handleOriginal' function.
						if (handleOriginal) {
							handleOriginal();
						}
					},
				};
			},
		}
	];

	return (
		<div className='table-adjust23'>
			<ReactTable
				loading={loading}
				filterable
				className='-striped'
				showPaginationTop
				showPaginationBottom
				resizable={true}
				defaultPageSize={10}
				pageSizeOptions={[10, 20, 30]}
				data={listItems}
				columns={columns}
			/>
		</div>
	);
};

export default LogHistoryListTable;
