import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Table from "components/Table/Table.jsx";
import Close from "@material-ui/icons/Close";
import Add from "@material-ui/icons/Add";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";
import { getUser } from "shared/authValidation";
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const dict = {
    R: 'Read only',
    F: 'Full access (Update, Delete)',
    E: 'Edit (Update only)'
}

const CompaniesSharing = (props) => {
    const [users, setUsers] = useState([]); // already shared
    const [search, setSearch] = useState('');
    const [userType, setUserType] = useState(null);

    useEffect(() => {
        if (props.dataObj.id) {
            props.getCompanyShares(props.dataObj.id);
        }
        if (props.getCompanyUsernames) {
            props.getCompanyUsernames();
        }
        if (props.action === 'Add') {
            setUsers(clone(props.sharesForAdd));
        }
        const user = getUser();
        if (user) setUserType(user.type);
    }, []);

    useEffect(() => {
        setUsers(clone(props.sharesForAdd));
    }, [props.sharesForAdd])

    useEffect(() => {
        const companyShares = props.companyShares ? props.companyShares : [];
        setUsers(clone(companyShares));
    }, [props.companyShares])

    const usersArr = users.sort((a, b) => (a.name > b.name) ? 1 : -1).map((u,i) => {
            
            return [
                (userType === 'A' && u.user_id === 1) ? 'illi' : u.name,
                dict[u.access],
                <div className="red-text hoverable"
                    onClick={() => {
                        if (props.action !== 'Add') {
                            props.deleteCompanyShare(u.id);
                            setTimeout(() => {
                                props.getCompanyShares(props.dataObj.id);
                            }, 1000);
                        } else {
                            removeShare(i);
                        }
                    }}
                ><Close /></div>
            ]
        });

    

    const addShare = (obj) => {
        const newShareArr = clone(props.sharesForAdd);
        props.setSharesForAdd([ ...newShareArr, obj]);
    }

    const removeShare = (j) => {
        const newShareArr = clone(props.sharesForAdd);
        props.setSharesForAdd(newShareArr.filter((f,i) => {
            if (i !== j) return true;
        }))
    }

    return (
        <GridContainer className={props.canEdit || props.action === 'Add' ? 'enclosure' : 'no-click lightgray-bg enclosure'}>
            <GridItem xs={12} sm={6} md={6}>
                <h4 className="bold">Users this company {props.action === 'Add' ? 'will be' : 'is'} shared with</h4>
                <div className="illi-table">
                    <Table
                        tableHeaderColor="primary"
                        tableHead={[<strong>Name</strong>,<strong>Access</strong>,<strong>Remove</strong>]}
                        tableData={usersArr}
                    />
                    {usersArr.length === 0 && <div className="text-center" 
                        style={{marginTop:20}}><em>None</em></div>}
                </div>
            </GridItem>
            <GridItem xs={12} sm={6} md={6} style={{display: props.canEdit || props.action === 'Add'  ? 
                                                                        'block' : 'none'}}>
                <CustomInput
                    label="Search Users"
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                />
                <div style={{padding:20}}>
                    {props.companyUsernames ? 
                        props.companyUsernames.filter(f => {
                            let alreadyShared = false;
                            users.forEach(u => {
                                
                                if (u.user_id === f.id) {
                                    alreadyShared = true;
                                }
                            })
                            // Superusers / Testing Users filtered
                            if (f.id === 2) return false;
                            if (f.id === 1 && userType === 'E') return false;
                            if (alreadyShared) return false;
                            if (!search) return true;
                            const lowered = f.name.toLowerCase();
                            const s = search.toLowerCase();
                            if (lowered.indexOf(s) === 0) {
                                return true;
                            }
                            return false;
                        })
                        .sort((a, b) => (a.name > b.name) ? 1 : -1)
                        .map((m,i) => {
                            const name = m.id === 1 && userType === 'A' ? 'illi' : m.name;
                            return <div key={`cu-${i}`} className="tiny-box">
                                <Button 
                                    color="primary"
                                    size="sm"
                                    style={{marginRight:20}} 
                                    onClick={() => { 
                                        const newShare = {
                                            user_id: m.id,
                                            access: 'F'
                                        };
                                        if (props.action !== 'Add') {
                                            newShare.company_id = props.dataObj.id;
                                            props.createCompanyShare(newShare);
                                            setTimeout(() => {
                                                props.getCompanyShares(props.dataObj.id);
                                            }, 1000);
                                        } else {
                                            newShare.name = m.name;
                                            addShare(newShare)
                                        }
                                    }}><Add /> Full</Button> 
                                <Button 
                                color="primary"
                                size="sm"
                                style={{marginRight:20}} 
                                onClick={() => { 
                                    const newShare = {
                                        user_id: m.id,
                                        access: 'E'
                                    };
                                    if (props.action !== 'Add') {
                                        newShare.company_id = props.dataObj.id;
                                        props.createCompanyShare(newShare);
                                        setTimeout(() => {
                                            props.getCompanyShares(props.dataObj.id);
                                        }, 1000);
                                    } else {
                                        newShare.name = m.name;
                                        addShare(newShare)
                                    }
                                }}><Add /> Edit</Button> 
                                <Button 
                                color="primary"
                                size="sm"
                                style={{marginRight:20}} 
                                onClick={() => { 
                                    const newShare = {
                                        user_id: m.id,
                                        access: 'R'
                                    };
                                    if (props.action !== 'Add') {
                                        newShare.company_id = props.dataObj.id;
                                        props.createCompanyShare(newShare);
                                        setTimeout(() => {
                                            props.getCompanyShares(props.dataObj.id);
                                        }, 1000);
                                    } else {
                                        newShare.name = m.name;
                                        addShare(newShare)
                                    }
                                }}><Add /> Read</Button> 
                                <span>{name}</span>
                            </div>
                        })
                    : 
                        null}
                </div>
            </GridItem>
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const c = state.crm_companies;
    return {
        error: c.error,
        success: c.success,
        companyUsernames: c.companyUsernames,
        companyShares: c.companyShares,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getCompanyShares: (id) => {
          dispatch(actions.getCompanyShares(id));
        },
        createCompanyShare: (data) => {
            dispatch(actions.createCompanyShare(data));
        },
        deleteCompanyShare: (id) => {
            dispatch(actions.deleteCompanyShare(id));
        },
        getCompanyUsernames: () => {
            dispatch(actions.getCompanyUsernames());
        }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(CompaniesSharing);