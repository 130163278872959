import React from "react";
import { Tooltip } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { ArrowBack } from "@material-ui/icons";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { qs } from "../../shared/utility";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

import * as actions from "../../store/actions";
import InvoiceForm from "../../containers/Invoices/InvoiceForm";
import InvoiceFormWizard from "../../containers/Invoices/InvoiceFormWizard";
import InvoiceList from "../../containers/Invoices/InvoiceList";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import { INVOICE_STATUS } from "../../containers/Invoices/InvoiceObj";

class InvoicesPage extends React.Component {
    state = {
        tab: "",
        openedForm: false,
        action: "Edit",
        parentData: null,
        openPDFitemID: null
    };

    setTab = (tab) => this.setState({ tab });

    openForm = () => this.setState({ openedForm: true });
    closeForm = () => this.setState({ openedForm: false });

    setParentData = (data) =>
        this.setState({ parentData: data, action: "Edit" });

    componentDidMount() {
        const { getInvoiceEntries } = this.props;
        const queryParams = qs(this.props.location.search);
        if (queryParams.tab) {
            if (INVOICE_STATUS[queryParams.tab.toUpperCase()]) {
                this.setTab(INVOICE_STATUS[queryParams.tab.toUpperCase()]);
                if (queryParams.invoice_id) {
                    this.setState({
                        openPDFitemID: queryParams.invoice_id
                    });
                }
            }
        }
    }
    
    render() {
        const {
            entriesData,
            invoicePdf,
            getInvoiceEntries,
            createInvoiceEntry,
            updateInvoiceEntry,
            deleteInvoiceEntry,
            getInvoicePdf,
            getRsheet,
            rsheetData,
            resetRsData,
            clearInvoicePdfData,
            clearRSheets,
            resendInvoiceEmail,
            success,
        } = this.props;
        const { action, openedForm, tab, parentData } = this.state;

        const pmaPermitted = checkPermissions("Invoice");
        if (!pmaPermitted) {
            return <Redirect to="/dashboard" />;
        }

        const permissions = checkPermissionsActions("Invoice");

        console.log('InvoicesPage', permissions);

        return (
            <div style={{ marginTop: 20 }}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color="rose" icon>
                                {/* <CardIcon
                                    color={
                                        tab === INVOICE_STATUS.DRAFT
                                            ? "success"
                                            : "rose"
                                    }
                                    onClick={() => {
                                        this.props.history.push('/invoices?tab=draft');
                                        this.setTab(INVOICE_STATUS.DRAFT);
                                    }}
                                    className="hovered"
                                >
                                    <strong>Draft</strong>
                                </CardIcon> */}
                                <CardIcon
                                    color={
                                        tab === INVOICE_STATUS.PENDING
                                            ? "success"
                                            : "rose"
                                    }
                                    onClick={() => {
                                        this.props.history.push('/invoices?tab=pending');
                                        this.setTab(INVOICE_STATUS.PENDING);
                                    }}
                                    className="hovered"
                                >
                                    <strong>Pending</strong>
                                </CardIcon>
                                <CardIcon
                                    color={
                                        tab === INVOICE_STATUS.APPROVED
                                            ? "success"
                                            : "rose"
                                    }
                                    onClick={() => {
                                        this.props.history.push('/invoices?tab=approved');
                                        this.setTab(INVOICE_STATUS.APPROVED);
                                    }}
                                    className="hovered"
                                >
                                    <strong>Approved</strong>
                                </CardIcon>
                                <CardIcon
                                    color={
                                        tab === INVOICE_STATUS.DECLINE
                                            ? "success"
                                            : "rose"
                                    }
                                    onClick={() => {
                                        this.props.history.push('/invoices?tab=declined');
                                        this.setTab(INVOICE_STATUS.DECLINE);
                                    }}
                                    className="hovered"
                                >
                                    <strong>Declined</strong>
                                </CardIcon>
                                <CardIcon
                                    color={
                                        tab === INVOICE_STATUS.VOIDED
                                            ? "success"
                                            : "rose"
                                    }
                                    onClick={() => {
                                        this.props.history.push('/invoices?tab=voided');
                                        this.setTab(INVOICE_STATUS.VOIDED);
                                    }}
                                    className="hovered"
                                >
                                    <strong>Voided</strong>
                                </CardIcon>
                            </CardHeader>
                            <CardBody>
                                <div>
                                    {/* <Button
                                        color="primary"
                                        className="mb-20"
                                        onClick={() => {
                                            this.openForm();
                                            this.setState({
                                                action: "Add",
                                            });
                                            resetRsData();
                                        }}
                                    >
                                        ADD NEW INVOICE
                                    </Button> */}
                                    {tab && <InvoiceList
                                        tab={tab}
                                        data={entriesData}
                                        openForm={this.openForm}
                                        setParentData={this.setParentData}
                                        parentData={this.state.parentData}
                                        deleteInvoiceEntry={deleteInvoiceEntry}
                                        getInvoiceEntries={getInvoiceEntries}
                                        getInvoicePdf={getInvoicePdf}
                                        pdfData={invoicePdf}
                                        updateInvoiceEntry={updateInvoiceEntry}
                                        openPDFitemID={this.state.openPDFitemID}
                                        resetInvoiceID={this.props.resetInvoiceID}
                                        resetOpenPDFitemID={() => {
                                            this.setState({
                                                openPDFitemID: null
                                            })
                                        }}
                                        clearInvoicePdfData={clearInvoicePdfData}
                                        resendInvoiceEmail={resendInvoiceEmail}
                                        success={success}
                                        permissions={permissions ? Object.keys(permissions) : []}

                                        createInvoicePayment={this.props.createInvoicePayment}
                                        updateInvoicePayment={this.props.updateInvoicePayment}
                                        getInvoicePayments={this.props.getInvoicePayments}
                                        invoicePayments={this.props.invoicePayments}
                                    />}
                                </div>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>

                <Dialog
                    open={openedForm}
                    onClose={this.closeForm}
                    fullWidth={true}
                    maxWidth={false}
                    fullScreen={true}
                >
                    <div className="dialog-wrapper">
                        <div
                            style={{ marginRight: 20, marginBottom: 10 }}
                            className="blue-text hoverable"
                            onClick={this.closeForm}
                        >
                            <Tooltip
                                id="tooltip-top-start1"
                                title="Back to List"
                                placement="top"
                            >
                                <ArrowBack />
                            </Tooltip>
                        </div>
                        <InvoiceForm
                            action={action}
                            parentData={parentData}
                            createInvoiceEntry={createInvoiceEntry}
                            updateInvoiceEntry={updateInvoiceEntry}
                            closeForm={this.closeForm}
                            getInvoiceEntries={getInvoiceEntries}
                            getInvoicePdf={getInvoicePdf}
                            pdfData={invoicePdf}
                            rsheetData={rsheetData}
                            getRsheet={getRsheet}
                            clearRSheets={clearRSheets}
                            listStatus={tab}
                            clearInvoicePdfData={clearInvoicePdfData}
                            permissions={permissions ? Object.keys(permissions) : []}
                            invoicePayments={this.props.invoicePayments}
                            error={this.props.error}
                            success={this.props.success}
                            tab={this.state.tab}
                        />
                    </div>
                </Dialog>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        entriesData: state.invoice.entriesData,
        error: state.invoice.error,
        success: state.invoice.success,
        loading: state.invoice.loading,
        invoicePdf: state.invoice.invoicePdf,
        rsheetData: state.rs.rsheetData,
        invoicePayments: state.invoice.invoicePayments,
        success_payments: state.invoice.success_payments,
        error_payments: state.invoice.error_payments
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getInvoiceEntries: (status) => {
            dispatch(actions.getInvoiceEntries(status));
        },
        createInvoiceEntry: (data) => {
            dispatch(actions.createInvoiceEntry(data));
        },
        updateInvoiceEntry: (data) => {
            dispatch(actions.updateInvoiceEntry(data));
        },
        deleteInvoiceEntry: (id) => {
            dispatch(actions.deleteInvoiceEntry(id));
        },
        getInvoicePdf: (html) => {
            dispatch(actions.getInvoicePdf(html));
        },
        clearInvoicePdfData: () => {
            dispatch(actions.clearInvoicePdfData());
        },
        resendInvoiceEmail: (data) => {
            dispatch(actions.resendInvoiceEmail(data))
        },
        getRsheet: (id) => dispatch(actions.getRsheet(id)),
        resetRsData: () => dispatch(actions.getRsheetStart()),
        resetInvoiceID: () => dispatch(actions.resetInvoiceID()),
        clearRSheets: () => dispatch(actions.clearRSheets()),

        getInvoicePayments: (data) => {
            dispatch(actions.getInvoicePayments(data));
        },
        createInvoicePayment: (data) => {
            dispatch(actions.createInvoicePayment(data));
        },
        updateInvoicePayment: (data) => {
            dispatch(actions.updateInvoicePayment(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicesPage);
