import React, { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Person from "@material-ui/icons/Person";
import Star from "@material-ui/icons/Star";
import { FadeLoader } from "react-spinners";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { clone, get } from "lodash";
import moment from 'moment';

const CustomDialogWithRoutingSheet = (props) => {
    const { open, close, choose, title, label, getRSList, routingSheets, canGenerateInvoiceOnly } = props;

    const [choice, setChoice] = useState(null);
    const [options, setOptions] = useState([]);

    const includesChoice = (text) => {
        if (!text) return false;
        return text.toLowerCase().includes(choice.toLowerCase());
    };

    useEffect(() => {
        getRSList({
            status: '4,9,10,8',
        })
    }, [])

    useEffect(() => {
        const opts = routingSheets.filter((rs) => {
            if (choice) {
                const address = get(rs, "json_data.premisesAddress", "");
                const cityStateZip = get(rs, "json_data.cityStateZip", "");
                if (
                    includesChoice(rs.project_name) ||
                    includesChoice(address) ||
                    includesChoice(cityStateZip)
                ) {
                    return true;
                }
            }
            return false;
        });
        setOptions(opts);
    }, [choice]);

    let filteredOptions = options;

    if (canGenerateInvoiceOnly) {
        filteredOptions = options.filter(rs => {
            const payment_schedule = get(rs, 'json_data.payment_schedule', []);
            let newPayments = []
            if (payment_schedule.length > 0 && !Array.isArray(payment_schedule[0])) {
                newPayments = [clone(payment_schedule)];
            } else {
                newPayments = clone(payment_schedule);
            }
            
            const currentInvoice = get(rs, 'json_data.currentInvoice', 0);

            return currentInvoice < newPayments.length;
        })
    }

    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="sm"
                fullWidth={true}
            >
                <DialogTitle>
                    <div className="close-btn" onClick={close}>
                        <Close />
                    </div>
                    <div style={{ textAlign: "center" }}>{title}</div>
                </DialogTitle>
                <div style={{ textAlign: "center", padding: 20 }}>
                    <CustomInput
                        label={props.label}
                        value={choice}
                        onChange={(e) => {
                            setChoice(e.target.value);
                        }}
                    />

                    {props.loading && (
                        <div>
                            <div
                                style={{
                                    margin: "0 auto",
                                    display: "inline-block",
                                }}
                            >
                                <FadeLoader />
                            </div>
                        </div>
                    )}
                    {!props.loading && (
                        <div>
                            <List component="nav">
                                {filteredOptions.map((o, i) => {
                                    const project_name = o.project_name;
                                    const created = moment(o.date_created).format('MM/DD/YYYY')

                                    return (
                                        <ListItem
                                            button
                                            key={
                                                props.indx
                                                    ? `${o[props.indx]}-${i}`
                                                    : `${o}-${i}`
                                            }
                                            onClick={() => {
                                                choose(o);
                                                close();
                                            }}
                                        >
                                            <ListItemText
                                                inset
                                                primary={project_name}
                                            />
                                            <ListItemSecondaryAction>
                                                {created}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>
                            {/* {options && <div style={{height: 300, overflowY: 'scroll', padding: 10, backgroundColor: '#f1f1f1'}}>
                            {options.length === 0 && <div><em>No existing {props.propertyType} properties</em></div>}
                            {options.length !== 0 && <List component="nav">
                                {options.map((o, i) => {
                                    const desc = props.desc ? o[props.desc] : o;
                                    return (
                                        <ListItem 
                                            button
                                            key={props.indx ? `${o[indx]}-${i}` : `${o}-${i}`}
                                            onClick={() => {
                                                // if (props.indx) {
                                                // 	return props.choose(o[indx]);
                                                // }
                                                props.choose(o);
                                            }}
                                        >
                                            {props.icon === 'person' && <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>}
                                            {(props.icon !== 'person' && props.icon) && <ListItemIcon>
                                                {props.icon}
                                            </ListItemIcon>}
                                            <ListItemText inset primary={desc} />
                                            <ListItemSecondaryAction>
                                                {current === desc && <Star />}
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    );
                                })}
                            </List>}
                        </div>} */}
                        </div>
                    )}
                    <Button
                        color="white"
                        style={{ marginRight: 10, marginTop: 20 }}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        CLOSE
                    </Button>
                </div>
            </Dialog>
        </div>
    );
};

CustomDialogWithRoutingSheet.propTypes = {
    title: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    choose: PropTypes.func.isRequired,
    indx: PropTypes.string,
    desc: PropTypes.string,
    current: PropTypes.string,
    icon: PropTypes.object,
};

const mapStateToProps = (state) => {
    const rs = state.rs;
    return {
        routingSheets: rs.rsheetsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getRSList: (data) => {
            dispatch(actions.getRsheets(data));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CustomDialogWithRoutingSheet);
