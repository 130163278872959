import React, { useEffect, useMemo, useState } from "react";
import { clone, get, isEmpty } from "lodash";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomTextArea from "components/CustomTextArea/CustomTextArea";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { currencyToNumber, formatDollar, parseFloatString } from "shared/utility";
import TextAreaField from "../../../components/CustomTextArea/CustomTextArea";

import {
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateLeaseTermTotal,
    isEstimatedLease,
    calculateAbatedRanges,
    calculateTermsTotal,
    isFlatFeeCommissionEnabled,
    newYearlyCalculation,
} from "../RSheetsLogic";
import { getUser } from "../../../shared/authValidation";
import { cloneJ, theVal } from "../../../shared/utility";

const getConsiderationOptions = (data, side) => {
    let options = [];
    console.log('getting consideration options', data, side);
    // ASSOCIATES
    const associates = data[`illi_represented_${side}_agents`] ? data[`illi_represented_${side}_agents`] : [];
    if (associates.length > 0) {
        // add associate only name
        associates.forEach(assoc => {
            const name = theVal(`${theVal(assoc.name)} ${theVal(assoc.last_name)}`);
            options.push({
                name,
                id: assoc.id,
                type: `Associate - ${name}`
            });
        })
        // add "illi - House Account - ()"
        associates.forEach(assoc => {
            const name = theVal(`${theVal(assoc.name)} ${theVal(assoc.last_name)}`);
            options.push({
                name: `illi - House Account - (${name})`,
                id: `house_account_${assoc.id}`,
                type: `illi - House Account - (${name})`
            });
        })
    }
    // REFERRAL
    if (side === 'lessee') {
        // first one
        if (data.lessee_referral_firm_id) {
            if (data.lessee_referral_firm_id) {
                const name = theVal(`${theVal(data.lessee_referral_firm_name)} ${theVal(data.lessee_referral_firm_lastname)} ${theVal(data.lessee_referral_firm_company_name)}`);
                options.push({
                    name,
                    id: data.lessee_referral_firm_id,
                    type: 'Referral - ' + name
                })
            }
        }
        // others
        if (data.lessee_referrals) {
            data.lessee_referrals.forEach(dlr => {
                const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                if (name) {
                    options.push({
                        name,
                        id: dlr.id,
                        type: 'Referral - ' + name
                    })
                }
            })
        }
    } else if (side === 'lessor') {
        // first one
        if (data.referral_firm_id) {
            if (data.referral_firm_id) {
                const name = theVal(`${theVal(data.referral_firm_name)} ${theVal(data.referral_firm_lastname)} ${theVal(data.referral_firm_company_name)}`);
                options.push({
                    name,
                    id: data.referral_firm_id,
                    type: 'Referral - ' + name
                })
            }
        }
        // others
        if (data.lessor_referrals) {
            data.lessor_referrals.forEach(dlr => {
                const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                if (name) {
                    options.push({
                        name,
                        id: dlr.id,
                        type: 'Referral - ' + name
                    })
                }
            })
        }
    }
    // OUTSIDE BROKERS
    let outside_brokers = [];
    if (side === 'lessor') {
        outside_brokers = cloneJ(data.lessor_outside_broker);
    } else if (side === 'lessee') {
        outside_brokers = cloneJ(data.outside_broker);
    }
    outside_brokers.forEach(dlob => {
        const id = dlob.contact_id ? 
            dlob.contact_id 
        : 
            dlob.company_id ? 
                dlob.company_id 
            : 
                '';
        const name = theVal(`${theVal(dlob.first_name)} ${theVal(dlob.last_name)} ${theVal(dlob.company_name)}`);
        if (name) {
            options.push({
                name,
                id,
                type: 'Outside Broker - ' + name
            })
        }
    })

    console.log('options created', options, data);
    return options;
}

const CommissionAdjustmentForm = (props) => {
    const {
        editMode,
        index,
        initValues,
        onAdd,
        onUpdate,
        readonly,
        lesseeLabel = "Lessee",
        lessorLabel = "Lessor",
        data
    } = props;

    const COMMISSION_ADJUSTMENT_OPTIONS = [lesseeLabel, lessorLabel];

    const [localData, setLocalData] = useState({
        option: "",
        value: "",
        comment: "",
        consideration: "",
        consideration_selection: ""
    });

    useEffect(() => {
        if (initValues) {
            setLocalData(initValues);
        }
    }, [initValues]);

    const update = (key, value) => {
        const newLocalData = clone(localData);
        newLocalData[key] = value;
        setLocalData(newLocalData);
    };

    let disabled = false;
    if (!localData.option || !localData.value || !localData.consideration) {
        disabled = true;
    }
    if (localData.consideration === 'Specific Individual') {
        if (!localData.consideration_selection) {
            disabled = true;
        }
    }

    const consideration_options = getConsiderationOptions(data, localData.option === 'Lessee' ? 'lessee' : 'lessor');

    console.log('localData', localData);

    return (
        <div className="boxed">
            <GridContainer>
                {(index === 0 || index) && (
                    <GridItem xs={12} sm={1} md={1}>
                        <div style={{ marginTop: 33 }}>{`#${index + 1}`}</div>
                    </GridItem>
                )}

                <GridItem xs={12} sm={4} md={4}>
                    <CustomSelect
                        label={"Select Option"}
                        options={COMMISSION_ADJUSTMENT_OPTIONS}
                        default={localData.option}
                        choose={(e, n) => {
                            update("option", e);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <CustomInput
                        label="Amount"
                        value={localData.value}
                        onChange={
                            !readonly
                                ? (e) => {
                                      update("value", e.target.value);
                                  }
                                : null
                        }
                        isDollar
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    <CustomRadio 
                        label="Effect,Entire Consideration,Specific Individual"
                        options={['Entire Consideration', 'Specific Individual']}
                        onChange={(e) => {
                            update("consideration", e.target.value);
                        }}
                        value={localData.consideration}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={4}>
                    {(localData.consideration === 'Specific Individual' && localData.option) && <div>
                        <CustomSelect
                            label={"Select One"}
                            options={consideration_options}
                            indx="type"
                            desc="type"
                            default={localData.consideration_selection}
                            choose={(e, n) => {
                                console.log('e', e, n)
                                update("consideration_selection", e);
                            }}
                        />
                        {!localData.consideration_selection && <div className="mt-20 red-text">Required</div>}
                    </div>}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomTextArea
                        label="Comment"
                        value={localData.comment}
                        onChange={
                            !readonly
                                ? (e) => {
                                      update("comment", e.target.value);
                                  }
                                : null
                        }
                        readonly={readonly}
                    />
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    {editMode ? (
                        <Button color="primary" className="mr-20" disabled={disabled} onClick={() => {
                            onUpdate(localData);
                        }}>
                            UPDATE
                        </Button>
                    ) : (
                        <Button
                            color="primary"
                            disabled={disabled}
                            className="mr-20"
                            onClick={() => {
                                console.log('adding', localData);
                                onAdd(localData);
                                setLocalData({
                                    option: "",
                                    value: "",
                                    comment: "",
                                    consideration: "",
                                    consideration_selection: ""
                                });
                            }}
                        >
                            ADD
                        </Button>
                    )}
                    <Button 
                        color="white" 
                        className="mr-20"
                        onClick={() => {
                            props.closeUpdate();
                        }}
                    >
                        CANCEL
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    );
};

const CommissionAdjustment = (props) => {
    console.log('CommissionAdjustment', props)
    const {
        commissionValue,
        data,
        renderElement,
        updateDataObj,
        lesseeLabel = "Lessee",
        lessorLabel = "Lessor",
    } = props;

    const [editIndex, setEditIndex] = useState(null);

    const isEditMode = (index) => editIndex === index;
    const setEditMode = (index) => () => setEditIndex(index);
    const closeEditMode = () => setEditIndex(null);

    const commission_adjustment = get(data, "commission_adjustment", null);
    const commission_adjustment_details = get(data, "commission_adjustment_details", []);

    const lease_term = get(data, "lease_term", []);
    const abated = get(data, "abated_rent_ranges", []);

	const isActualDays = get(data, "commission_based_off", "") === "Actual Days";

    // FIXME - DEPRECATED
    const isFlatFee = get(data, "flat_fee_commission", null) === "Yes";

    const isEstimated = isEstimatedLease(data);

    const flatFeeEnabled = isFlatFeeCommissionEnabled(data);

    const totalNetCommission = useMemo(() => {
        if (commissionValue) {
            return currencyToNumber(commissionValue);
        }
        if (isEstimated) {
            let grossCommission = 0;
            let abatedCommission = 0;

            const abated_ranges = calculateEstimatedAbatedRanges({
                lease_term: lease_term,
                abated_rent_ranges: abated,
            });
            const abatedTotal = calculateEstimatedTermsTotal(abated_ranges);

            if (abatedTotal) {
                abatedCommission = abatedTotal.totalCommission;
            }

            lease_term.forEach((term, index) => {
                const totalTermRent = calculateEstimatedTermsTotal(term.ranges, isFlatFee);
                grossCommission += totalTermRent.totalCommission;
            });
            const netCommission = grossCommission + abatedCommission;

            return netCommission;
        } else {
            const abatedRanges = calculateAbatedRanges({
                lease_term,
                abated,
            });

            let grossCommission = 0;
            let abatedCommission = newYearlyCalculation(abatedRanges, isEstimated, isActualDays).commission

            lease_term.forEach(term => {
                const totalTermRent = newYearlyCalculation(term.ranges, isEstimated, isActualDays);
                grossCommission += totalTermRent.commission
            })
            
            const net_commission = grossCommission - abatedCommission;

            return net_commission;
        }
    }, [lease_term, abated, isFlatFee]);

    const handleAddNewAdjustment = (adjustment) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails.push(adjustment);
        updateDataObj("commission_adjustment_details", newDetails);
    };

    const handleDeleteAdjustment = (index) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails.splice(index, 1);
        updateDataObj("commission_adjustment_details", newDetails);
    };

    const handleUpdateAdjustment = (index) => (newAdjustment) => {
        const newDetails = clone(commission_adjustment_details);
        newDetails[index] = newAdjustment;
        updateDataObj("commission_adjustment_details", newDetails);
        closeEditMode();
    };

    const renderAdjustments = () =>
        commission_adjustment_details.map((item, index) => {
            const consideration_options = getConsiderationOptions(data, item.option === 'Lessee' ? 'lessee' : 'lessor');
            if (isEditMode(index)) {
                return (
                    <CommissionAdjustmentForm
                        initValues={item}
                        index={index}
                        onUpdate={handleUpdateAdjustment(index)}
                        closeUpdate={closeEditMode}
                        lesseeLabel={lesseeLabel}
                        lessorLabel={lessorLabel}
                        editMode
                        data={data}
                        key={`caf-${index}`}
                        consideration_options={consideration_options}
                    />
                );
            } else {
                let consideration_selection = '';
                if (consideration_options) {
                    consideration_options.forEach(co => {
                        if (co.type === item.consideration_selection) {
                            consideration_selection = co.type;
                        }
                    })
                }
                return (
                    <div className="boxed" key={`${index}-adjustment`}>
                        <GridContainer>
                            <GridItem xs={12} sm={1} md={1}>
                                <div style={{ marginTop: 33 }}>{`#${index + 1}`}</div>
                            </GridItem>
                            <GridItem xs={12} sm={5} md={3}>
                                <CustomInput label="Option" value={item.option} readonly />
                            </GridItem>
                            <GridItem xs={12} sm={5} md={3}>
                                <CustomInput label="Value" value={formatDollar(parseFloatString(item.value))} readonly isDollar />
                            </GridItem>
                            <GridItem xs={12} sm={5} md={3}>
                                <div className="mt-20">{item.consideration}</div>
                                <div className="mt-20"><strong>{consideration_selection}</strong></div>
                            </GridItem>
                            <GridItem xs={12} sm={1} md={2}>
                                <div className="hoverable mt-20" onClick={() => handleDeleteAdjustment(index)}>
                                    <i className="fas fa-trash-alt red-text"></i>
                                </div>
                                <div className="hoverable mt-20" onClick={setEditMode(index)}>
                                    <i className="fa fa-edit"></i>
                                </div>
                            </GridItem>
                            <GridItem xs={12} sm={5} md={5}>
                                <CustomInput label="Comment" value={item.comment} readonly />
                            </GridItem>
                        </GridContainer>
                    </div>
                );
            }
        });

    const usr = getUser();
    const permObj = usr.permObj ? usr.permObj : {};
    const any = (arr, fn = Boolean) => arr.some(fn);
    const isApprover = any(Object.keys(permObj), x => {
        if (x.indexOf('Routing approve') !== -1) return true;
    });

    console.log('CommissionAdjustment', props, isApprover);

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {renderElement("commission_adjustment")}
            </GridItem>

            {(commission_adjustment === "Yes" && !isApprover) && <GridItem xs={12} sm={12} md={12}>
                    <div style={{marginBottom:20}} className="boxed">
                        <TextAreaField 
                            label="Commission Adjustment Notes"
                            value={data.commission_adjustment_notes ? data.commission_adjustment_notes : ''}
                            onChange={(e) => {
                                updateDataObj("commission_adjustment_notes", e.target.value)
                            }}
                        />
                    </div>
            </GridItem>}

            {(commission_adjustment === "Yes" && isApprover) && <GridItem xs={12} sm={12} md={12}>
                    <div style={{marginBottom:20}} className="boxed">
                        <h4><strong>Commission Adjustment Notes</strong></h4>
                        {data.commission_adjustment_notes ? data.commission_adjustment_notes : ''}
                    </div>
            </GridItem>}

            {(commission_adjustment === "Yes" && isApprover) && (
                <>
                    <GridItem xs={12} sm={12} md={12}>
                        <p style={{ fontWeight: "bold" }}>TOTAL NET COMMISSION: {formatDollar(totalNetCommission)}</p>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={12}>
                        <CommissionAdjustmentForm
                            onAdd={handleAddNewAdjustment}
                            lesseeLabel={lesseeLabel}
                            lessorLabel={lessorLabel}
                            data={data}
                        />
                    </GridItem>

                    {!isEmpty(commission_adjustment_details) && (
                        <GridItem xs={12} sm={12} md={12}>
                            <div className="boxed">{renderAdjustments()}</div>
                        </GridItem>
                    )}

                    <hr />
                    <GridItem xs={12} sm={12} md={12}>
                        <h4>
                            NEW Total NET Commission:{" "}
                            <strong>{formatDollar(totalNetCommission + calculateAdjustedCommission(data))}</strong>
                        </h4>
                    </GridItem>
                </>
            )}
        </GridContainer>
    );
};

export default CommissionAdjustment;
