import React from 'react';
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { get } from 'lodash';

const CustomRadio = (props) => {
  const { classes, options, label } = props;
  const labelArr = label.split(',');

  const labelsArr = props.label.split(',');
  let label1 = get(labelsArr, '[1]', '');
  let label2 = get(labelsArr, '[2]', '');
  let label3 = get(labelsArr, '[3]', '');

  if (label1 === 'Actual Days') label1 = 'Actual Days in a Month';
  if (label2 === 'Actual Days') label2 = 'Actual Days in a Month';
  if (label3 === 'Actual Days') label3 = 'Actual Days in a Month';

  return (
    <div
      className={
        classes.checkboxAndRadio +
        " " +
        classes.checkboxAndRadioHorizontal + 
        ` ${props.readonly ? 'no-click' : ''} `
      }
    >
      <div style={{marginBottom:'-5px',marginTop:'20px'}}>{label.split(',')[0]}</div>
      <br style={{display:props.add_break_to_label ? 'block' : 'none'}} />
      <FormControlLabel
        control={
            /*<span style={{paddingLeft:'12px'}}>{label.split(',')[0]}</span>*/
            <Radio
              checked={props.value == options[0]}
              onChange={(e) => {
                let checke = options[0];
                if (!isNaN(options[0])) {
                  checke = parseInt(options[0]);
                }
                props.onChange(e, checke, props.name);
              }}
              value={options[0]}
              name={props.name}
              aria-label="A"
              icon={
                <FiberManualRecord
                  className={classes.radioUnchecked}
                />
              }
              checkedIcon={
                <FiberManualRecord
                  className={classes.radioChecked}
                />
              }
              classes={{
                checked: classes.radio
              }}
            />
        }
        classes={{
          label: classes.label
        }}
        label={label1}
      />
    {labelArr.length > 2 && <FormControlLabel
      control={
        <Radio
          checked={props.value === options[1]}
          onChange={(e) => {
            let checke = options[1];
            if (!isNaN(options[1])) {
              checke = parseInt(options[1]);
            }
            props.onChange(e, checke, props.name);
          }}
          value={options[1]}
          name={props.name}
          aria-label="B"
          icon={
            <FiberManualRecord
              className={classes.radioUnchecked}
            />
          }
          checkedIcon={
            <FiberManualRecord
              className={classes.radioChecked}
            />
          }
          classes={{
            checked: classes.radio
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={label2}
    />}
    {labelArr.length > 3 && <FormControlLabel
      control={
        <Radio
          checked={props.value === options[2]}
          onChange={(e) => {
            let checke = options[2];
            if (!isNaN(options[2])) {
              checke = parseInt(options[2]);
            }
            props.onChange(e, checke, props.name);
          }}
          value={options[2]}
          name={props.name}
          aria-label="B"
          icon={
            <FiberManualRecord
              className={classes.radioUnchecked}
            />
          }
          checkedIcon={
            <FiberManualRecord
              className={classes.radioChecked}
            />
          }
          classes={{
            checked: classes.radio
          }}
        />
      }
      classes={{
        label: classes.label
      }}
      label={label3}
    />}
  </div>
  );
}

export default withStyles(regularFormsStyle)(CustomRadio);
