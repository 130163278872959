import React from 'react';
import { Close } from '@material-ui/icons';
const CloseComponent = (props) => {
    return <div className="top-dweller">
        <span onClick={() => {
            props.close();
        }}>
            <Close />
        </span>
    </div>
}

export default CloseComponent;