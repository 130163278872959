import { get, includes, isEmpty } from "lodash";
import moment from "moment";
import { commatize, addDashes } from "../../shared/utility";
import originalTextData from "./PMABaseTemplateText";
import { createPMAAddress, generateOwnerIDs } from "./FormWizard/PMALogic";
import _ from "lodash";

const textData2 = {};
Object.keys(originalTextData).forEach((field) => {
    if (field !== "additional_signatures" && field !== "additional_initials") {
        const text = originalTextData[field].replace(/ {1,}/g, " ");
        textData2[field] = text.trim().replace(/(\r\n|\n|\r)/gm, "");
    } else {
        textData2[field] = originalTextData[field];
    }
});

export const checkForChanges = (formData, originalData) => {
    console.log('checking for changes', formData, originalData);
    let hasChanged = false;
    if (!originalData) return hasChanged;
    Object.keys(formData).forEach(field => {
        const json1 = JSON.stringify(formData[field]);
        const json2 = JSON.stringify(originalData[field]);
        if (json1 !== json2) {
            console.log('checking for changes - changed', field);
            hasChanged = true;
        }
    });
    return hasChanged;
}

export const textData = textData2;

export const pmaStatus = {
    1: "Draft",
    2: "Pending owner signature",
    3: "Pending approver signature",
    4: "Rejected by owner / Expired",
    5: "Rejected by approver",
    6: "Approved",
};

export const allowAdditionalSignature = [
    "cover1",
    "cover2",
    "cover3",
    "cover4",
    "cover5",
    "a1",
    "whereas_now",
    "whereas_now2",
    "now_therefore",
    "manager_and_acceptance",
    "term_and_termination_p2",
    "management_duties",
    "record_statements_collections",
    "repair_maintenance",
    "insurance_proposals",
    "enforcement",
    "payments",
    "consultation",
    "security_deposits",
    "owner_distributions",
    "leasing_responsibilities_p1",
    "leasing_responsibilities_p2",
    "leasing_responsibilities_p3",
    "leasing_responsibilities_p4",
    "leasing_responsibilities_p5",
    "limitation_on_manager_authority_p2",
    "limitation_on_manager_authority_p3",
    "limitation_on_manager_authority_p4",
    "limitation_on_manager_authority_p5",
    "limitation_on_manager_authority_p6",
    "limitation_on_manager_authority_p7",
    "limitation_on_manager_authority_p8",
    "limitation_on_manager_authority_p9",
    "owners_responsibilities_intro",
    "owners_responsibilities_generally",
    "owners_responsibilities_insurance",
    "owners_responsibilities_legal_comp_n_disc",
    "owners_responsibilities_security_depo_int",
    "owners_responsibilities_owner_rep_p1",
    "owners_responsibilities_owner_rep_p2",
    "owners_responsibilities_owner_rep_p3",
    "owners_responsibilities_tax_prep",
    "owners_representations",
    "management_fee_p1",
    "management_fee_p2",
    "management_fee_p3",
    "management_fee_p4",
    "management_fee_p5",
    "add_manage_fee_sched_p1",
    "add_manage_fee_sched_p2",
    "add_manage_fee_sched_p3",
    "add_manage_fee_sched_p4",
    "add_manage_fee_sched_p5",
    "leasing_comp",
    "leasing_comp_commissions_generally",
    "leasing_comp_other_leases",
    "leasing_comp_m_to_m_lease",
    "leasing_comp_renewals_extensions",
    "leasing_comp_transaction_fees",
    "leasing_comp_cooperating_brokers",
    "leasing_comp_post_term_transactions",
    "leasing_comp_signage_permission",
    "leasing_comp_payments_to_manager",
    "indemnity",
    "entire_agreement",
    "attorneys_fees",
    "waiver",
    "additional_documents",
    "other_info_confidentiality",
    "manager_conflict_waiver",
    "notices",
    "manager_data_company_name_division",
    "manager_data_attn_person_title",
    "manager_data_address",
    "manager_data_phone_ext",
    "manager_data_email",
    "no_partnership",
    "approval",
    "limitation_of_liability",
    "no_3rd_party_benef",
    "severability",
    "counterpart_facsimiles",
    "governing_law",
    "time_of_essence",
    "successors_assigns",
    "interpretation",
    "managers_employees",
    "arbitration_of_disputes",
];

export const pmaFields = {
    name: {
        type: "input",
        label: "Name",
        validations: [
            "min1",
            "max50",
            "no_numbers",
            "no_special_chars",
            "required",
        ],
        required: true,
    },
    address: {
        type: "input",
        label: "Address 1",
        validations: ["min5", "max250", "required"],
        required: true,
    },
    address2: {
        type: "input",
        label: "Address 2",
        validations: ["min5", "max250"],
        // required: true,
    },
    city: {
        type: "input",
        label: "City",
        validations: ["min5", "max250"],
        // required: true,
    },
    state: {
        type: "input",
        label: "State",
        validations: ["min5", "max250"],
        // required: true,
    },
    zip: {
        type: "input",
        label: "Zip",
        validations: ["min5", "max250"],
        // required: true,
    },
    phone: {
        type: "input",
        label: "Phone Number",
        validations: ["max50", "no_letters", "required"],
        required: true,
    },
    email: {
        type: "input",
        label: "Email",
        validations: ["email", "min6", "max100", "required"],
        required: true,
    },
    required: {
        type: "input",
        label: "required",
        validations: ["required"],
        required: true,
    },
    acc_number: {
        type: "input",
        label: "Account Number",
        validations: ["max50", "no_letters", "required"],
        required: true,
    },
    state: {
        label: "State",
        options: [
            { name: "Alabama", abbreviation: "AL" },
            { name: "Alaska", abbreviation: "AK" },
            { name: "Arizona", abbreviation: "AZ" },
            { name: "Arkansas", abbreviation: "AR" },
            { name: "California", abbreviation: "CA" },
            { name: "Colorado", abbreviation: "CO" },
            { name: "Connecticut", abbreviation: "CT" },
            { name: "Delaware", abbreviation: "DE" },
            { name: "Florida", abbreviation: "FL" },
            { name: "Georgia", abbreviation: "GA" },
            { name: "Hawaii", abbreviation: "HI" },
            { name: "Idaho", abbreviation: "ID" },
            { name: "Illinois", abbreviation: "IL" },
            { name: "Indiana", abbreviation: "IN" },
            { name: "Iowa", abbreviation: "IA" },
            { name: "Kansas", abbreviation: "KS" },
            { name: "Kentucky", abbreviation: "KY" },
            { name: "Louisiana", abbreviation: "LA" },
            { name: "Maine", abbreviation: "ME" },
            { name: "Maryland", abbreviation: "MD" },
            { name: "Massachusetts", abbreviation: "MA" },
            { name: "Michigan", abbreviation: "MI" },
            { name: "Minnesota", abbreviation: "MN" },
            { name: "Mississippi", abbreviation: "MS" },
            { name: "Missouri", abbreviation: "MO" },
            { name: "Montana", abbreviation: "MT" },
            { name: "Nebraska", abbreviation: "NE" },
            { name: "Nevada", abbreviation: "NV" },
            { name: "New Hampshire", abbreviation: "NH" },
            { name: "New Jersey", abbreviation: "NJ" },
            { name: "New Mexico", abbreviation: "NM" },
            { name: "New York", abbreviation: "NY" },
            { name: "North Carolina", abbreviation: "NC" },
            { name: "North Dakota", abbreviation: "ND" },
            { name: "Ohio", abbreviation: "OH" },
            { name: "Oklahoma", abbreviation: "OK" },
            { name: "Oregon", abbreviation: "OR" },
            { name: "Pennsylvania", abbreviation: "PA" },
            { name: "Rhode Island", abbreviation: "RI" },
            { name: "South Carolina", abbreviation: "SC" },
            { name: "South Dakota", abbreviation: "SD" },
            { name: "Tennessee", abbreviation: "TN" },
            { name: "Texas", abbreviation: "TX" },
            { name: "Utah", abbreviation: "UT" },
            { name: "Vermont", abbreviation: "VT" },
            { name: "Virginia", abbreviation: "VA" },
            { name: "Washington", abbreviation: "WA" },
            { name: "West Virginia", abbreviation: "WV" },
            { name: "Wisconsin", abbreviation: "WI" },
            { name: "Wyoming", abbreviation: "WY" },
        ],
    },
    labels: {
        commencement_date: "Commencement Date",
        monthly_fee_percentage: "Monthly Fee Percentage",
        minimum_management_fee: "Minimum Management Fee",
        set_up_fee: "Set Up Fee",
        paper: "Electronic Distribution (Yes or No)",
        by: "By",
        by2: "By",
        president: "President",
        property: {
            address: "Property Address",
        },
        owner: {
            name: "Owner Name",
            title: "Title",
            company_name: "Company Name",
            representative: "Owner Representative",
            representative_position: "Representative Position",
            representative_phone: "Representative Phone",
            representative_email: "Representative Email",
            address: "Owner Address",
            phone: "Phone",
            email: "Email",
        },
        ownership_distribution: {
            owner_member: "Owner / Member",
            percentage: "Percentage",
            paper: "Electronic Distribution (Yes or No)",
            routing_number: "Bank Routing Number",
            account_number: "Bank Account Number",
            mailing_address: "Mailing Address",
            owner_member2: "Owner / Member",
            percentage2: "Percentage",
            paper2: "Electronic Distribution",
            routing_number2: "Bank Routing Number",
            account_number2: "Bank Account Number",
            mailing_address2: "Mailing Address",
            owner_member3: "Owner / Member",
            percentage3: "Percentage",
            paper3: "Electronic Distribution (Yes or No)",
            routing_number3: "Bank Routing Number",
            account_number3: "Bank Account Number",
            mailing_address3: "Mailing Address",
            tin: "Tax Identification Number",
            tin2: "Tax Identification Number",
            tin3: "Tax Identification Number",
        },
        distribution_method: {
            sweep_less_mgmt_agrmt:
                "Sweep all funds less reserve listed in the management agreement? (Yes or No)",
            sweep_less_reserves:
                "Sweep all funds less reserve listed in the management agreement and reserves for property taxes and property insurance? (Yes or No)",
            sweep_less_payables:
                "Sweep all funds less reserve listed in the management agreement, reserves for property taxes and property insurance, openpayables and rents paid in advance? (Yes or No)",
        },
        annual_business_license: {
            prep_by_illi: "Prepared by illi? (Yes or No)",
            city_business_license_num: "City Business License Number",
        },
        statement_of_information: {
            filed_by_illi: "Filed by illi CRE? (Yes or No)",
            california_corporation_number: "California Corporation Number",
        },
        mortgage_payments: {
            paid_by_illi: "Paid by illi CRE? (Yes or No)",
            mortgage_holder: "Mortgage Holder",
        },
        period_data: {
            detailed_month: "Detailed month by month? (Yes or No)",
            summary_month: "Summary month by month? (Yes or No)",
        },
        period_reconciliation: {
            prepared_by_illi: "Prepared by illi CRE: (Yes or No)",
        },
    },
};

export const initialFormData = {
    pma_id: "",
    date: moment().format("MMMM D, YYYY"),
    commencement_date: "",
    monthly_fee_percentage: "",
    monthly_fee_type: "",
    monthly_fee_value: "",
    minimum_management_fee: "",
    set_up_fee: "",
    paper: "", // paper or electronic
    sig_date1: "",
    sig_date2: "",
    president: "",
    president_email: "",
    property: {
        address: "",
        address1: "",
        city: "",
        state: "",
        zip: ""
    },
    owner: {
        name: "",
        title: "",
        company_name: "",
        representative: "",
        representative_position: "",
        representative_phone: "",
        representative_email: "",
        address: "",
        phone: "",
        email: "",
    },
    other_owners: [],
    ownership_distribution: {
        owner_member: "",
        percentage: "",
        paper: "",
        routing_number: "",
        account_number: "",
        mailing_address: "",
        owner_member2: "",
        percentage2: "",
        paper2: "",
        routing_number2: "",
        account_number2: "",
        mailing_address2: "",
        owner_member3: "",
        percentage3: "",
        paper3: "",
        routing_number3: "",
        account_number3: "",
        mailing_address3: "",
        tin: "",
        tin2: "",
        tin3: "",
    },
    distribution_method: {
        sweep_less_mgmt_agrmt: "",
        sweep_less_reserves: "",
        sweep_less_payables: "",
    },
    annual_business_license: {
        prep_by_illi: "",
        city_business_license_num: "",
    },
    statement_of_information: {
        filed_by_illi: "",
        california_corporation_number: "",
    },
    mortgage_payments: {
        paid_by_illi: "",
        mortgage_holder: "",
    },
    period_data: {
        detailed_month: "",
        summary_month: "",
        value: "",
    },
    period_reconciliation: {
        prepared_by_illi: "",
        value: "",
    },
    addedCC: [],
    additional_signatures: [],
    additional_initials: [],
};


export const fieldTypes = {
    other_owners: {
        label: "Other Owners",
        type: "array",
    },
    pma_id: {
        label: "PMA # (override only)",
        type: "input",
        validations: [],
    },
    date: {
        label: "Date",
        type: "date",
        validations: ["required"],
    },
    commencement_date: {
        label: "Agreement Commencement Date",
        type: "date",
        validations: ["required"],
    },
    monthly_fee_type: {
        label: "Select Monthly Fee Type",
        type: "radio",
        options: ["Percentage", "Dollar Value"],
        required: true,
    },
    monthly_fee_percentage: {
        label: "Monthly Fee Percentage",
        type: "input",
        validations: ["required"],
        number_only: true,
    },
    monthly_fee_value: {
        label: "Monthly Fee Dollar Value",
        type: "input",
        validations: ["required"],
        //number_only: true,
        is_dollar: true,
    },
    minimum_management_fee: {
        label: "Minimum Management Fee",
        type: "input",
        validations: ["required"],
        //number_only: true,
        is_dollar: true,
    },
    set_up_fee: {
        label: "Setup Fee",
        type: "input",
        validations: ["required"],
        //number_only: true,
        is_dollar: true,
    },
    paper: {
        label: "Select paper document conversion",
        type: "radio",
        options: [
            "Convert paper documents to electronic files stored on the cloud and shred originals.",
            "Convert paper documents to electronic files stored on the cloud and forward originals to Owner.",
        ],
        required: true,
    },
    sig_date1: "",
    sig_date2: "",
    president: "",
    president_email: "",
    property: {
        address: {
            label: "Address",
            type: "input",
            validations: ["required"],
        },
        address2: {
            label: "Address 2",
            type: "input",
            validations: [],
        },
        city: {
            label: "City",
            type: "input",
            validations: [],
        },
        state: {
            label: "State",
            type: "input",
            validations: [],
        },
        zip: {
            label: "Zip",
            type: "input",
            validations: [],
        },
    },
    owner: {
        name: {
            label: "Owner Name",
            type: "input",
            validations: ["required"],
        },
        title: {
            label: "Owner Title",
            type: "input",
            validations: ["required"],
        },
        // representative: {
        //   label: 'Owner Representative',
        //   type: 'input',
        //   validations: ['required']
        // },
        // representative_position: {
        //   label: 'Representative Position',
        //   type: 'input',
        //   validations: ['required']
        // },
        // representative_phone: {
        //   label: 'Representative Phone',
        //   type: 'input',
        //   validations: ['required']
        // },
        // representative_email: {
        //   label: 'Representative Email',
        //   type: 'input',
        //   validations: ['required']
        // },
        address: {
            label: "Owner Address",
            type: "input",
            validations: ["required"],
        },
        phone: {
            label: "Phone",
            type: "input",
            validations: ["required"],
        },
        email: {
            label: "Email",
            type: "input",
            validations: ["required"],
        },
    },
    ownership_distribution: {
        owner_member: {
            label: "Owner Member",
            type: "input",
            validations: ["required"],
        },
        percentage: {
            label: "Percentage",
            type: "input",
            validations: ["required"],
        },
        paper: {
            label: "Electronic Distribution?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        routing_number: {
            label: "Routing Number",
            type: "input",
            validations: ["max50", "no_letters", "required"],
        },
        account_number: {
            label: "Account Number",
            type: "input",
            validations: ["max50", "no_letters", "required"],
        },
        mailing_address: {
            label: "Mailing Address",
            type: "input",
            validations: ["required"],
        },

        owner_member2: {
            label: "Owner Member",
            type: "input",
        },
        percentage2: {
            label: "Percentage",
            type: "input",
        },
        paper2: {
            label: "Electronic Distribution?",
            type: "radio",
            options: ["Yes", "No"],
        },
        routing_number2: {
            label: "Routing Number",
            type: "input",
            validations: ["max50", "no_letters"],
        },
        account_number2: {
            label: "Account Number",
            type: "input",
            validations: ["max50", "no_letters"],
        },
        mailing_address2: {
            label: "Mailing Address",
            type: "input",
        },

        owner_member3: {
            label: "Owner Member",
            type: "input",
        },
        percentage3: {
            label: "Percentage",
            type: "input",
        },
        paper3: {
            label: "Electronic Distribution?",
            type: "radio",
            options: ["Yes", "No"],
        },
        routing_number3: {
            label: "Routing Number",
            type: "input",
            validations: ["max50", "no_letters"],
        },
        account_number3: {
            label: "Account Number",
            type: "input",
            validations: ["max50", "no_letters"],
        },
        mailing_address3: {
            label: "Mailing Address",
            type: "input",
        },

        tin: {
            label: "Tax Identification Number",
            type: "input",
            validations: ["required"],
        },
        tin2: {
            label: "Tax Identification Number",
            type: "input",
        },
        tin3: {
            label: "Tax Identification Number",
            type: "input",
        },
    },
    distribution_method: {
        sweep_less_mgmt_agrmt: {
            label: "Sweep all funds less reserve listed in the management agreement?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        sweep_less_reserves: {
            label: "Sweep all funds less reserve listed in the management agreement and reserves for property taxes and property insurance?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        sweep_less_payables: {
            label: "Sweep all funds less reserve listed in the management agreement; reserves for property taxes and property insurance; open payables and rents paid in advance?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
    },
    annual_business_license: {
        prep_by_illi: {
            label: "Annual Business License prepared By illi CRE?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        city_business_license_num: {
            label: "City Business License Number",
            type: "input",
            validations: ["required"],
        },
    },
    statement_of_information: {
        filed_by_illi: {
            label: "State of California Statement of Information prepared By illi CRE?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        california_corporation_number: {
            label: "California Corporation Number",
            type: "input",
            validations: ["required"],
        },
    },
    mortgage_payments: {
        paid_by_illi: {
            label: "Mortgage Payments Paid By illi CRE?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        mortgage_holder: {
            label: "Mortgage Holder",
            type: "input",
            validations: ["required"],
        },
    },
    period_data: {
        detailed_month: {
            label: "Transfer prior periods of current year into illi CRE books detailed month by month?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        value: {
            label: "Price negotiated",
            type: "input",
            validations: [],
        },
        summary_month: {
            label: "Transfer prior periods of current year into illi CRE books summary month by month?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
    },
    period_reconciliation: {
        prepared_by_illi: {
            label: "Prior Period CAM Reconciliation prepared by illi CRE?",
            type: "radio",
            options: ["Yes", "No"],
            required: true,
        },
        value: {
            label: "Price negotiated",
            type: "input",
            validations: [],
        },
    },
};

export const sectionNames = {
    1: "Owner Information",
    2: "Property",
    3: "Agreement Details",
    4: "Approver Details",
    5: "Additional Notifications",
};

export const sectionRequirements = {
    "Owner Information": [
        "date",
        "owner.name",
        "owner.address",
        "owner.phone",
        "owner.email",
    ],
    Property: ["property.address"],
    "Agreement Details": [
        "commencement_date",
        "minimum_management_fee",
        "set_up_fee",
    ],
    "Approver Details": ["president", "president_email"],
    "Additional Notifications": [],
};

const renderLine = (d) => {
    return `<span style="border-bottom: 1px solid black;min-width:100px;display:inline-block">${d}</span>`;
};

export const renderExhibitA = (signing_ceremony) => {
    if (isEmpty(signing_ceremony)) return '';

    const content = signing_ceremony.map((sc, ind) => {
        const ed = sc.exhibit_data ? sc.exhibit_data : {};

        if (!sc.exhibit_data) return "";

        const other_owners = ed.other_owners
            ? ed.other_owners.map((eoo, i) => {
                  return `<div class="column-33">
                <label>Owner/Member</label>
                <input class="form-control" placeholder="Owner/Member" value="${eoo.member}" />
                <label>Percentage</label>
                <input class="form-control" type="number" value="${eoo.percentage}" placeholder="Percentage" />
                <label>Tax Identification Number</label>
                <input class="form-control" placeholder="Tax Identification Number" value="${eoo.tin}" />
                <label>Electronic Distribution</label>
                <div class="radio">
                    <label><input type="radio" value="Yes" ${eoo.ed === 'Yes' ? 'checked' : ''} />Yes</label>
                </div>
                <div class="radio">
                    <label><input type="radio" value="No" ${eoo.ed === 'No' ? 'checked' : ''} />No</label>
                </div>

                ${eoo.ed === 'Yes' ? `<div id="bank_routing_number1">
                    <label>Bank Routing Number</label>
                    <input class="form-control" placeholder="Bank Routing Number" value="${eoo.bank_routing}" />
                </div>` : ''}
                ${eoo.ed === 'Yes' ? `<div id="bank_accounting_number1">
                    <label>Bank Account Number</label>
                    <input class="form-control" placeholder="Bank Account Number" value="${eoo.bank_account}" />
                </div>` : ''}
                ${eoo.ed === 'No' ? `<div id="mailing_address1">
                    <label>Mailing Address</label>
                    <input class="form-control" placeholder="Mailing Address" value={eoo.mailing_address} />
                </div>` : ''}
            </div>`;
              })
            : "";

        return (
            `<div>
                <hr />
                <div class="text-center">
                    <h3>Exhibit "A"</h3>
                </div>
                <h3>
                    <strong>${sc.name}</strong>
                </h3>
                <p>
                    <strong>Note:</strong> illi CRE converts all leases and
                    documents to electronic documents and stores them
                    securely in our Cloud Storage system that has multiple
                    back-ups in various cities. The Department of Real
                    Estate and the Court Systems allow and accept electronic
                    documents and the need for “inked” copies is in the
                    past.
                </p>
                <p>
                    That said, some clients still prefer the original hard
                    copies and we are happy to accommodate that and will
                    forward those to you should you desire. Please make an
                    election by checking one of the boxes below to indicate
                    whether you would like the original paper documents to
                    be forwarded to you acknowledge that otherwise, we will
                    shred the paper documents after storing them
                    electronically.
                </p>
                <br />
                <input type="checkbox" ${ed.paper_1 ? 'checked' : ''} />{" "}
                Convert paper documents to electronic files stored on the
                cloud and shred originals.
                <br />
                <br />
                <input type="checkbox" ${ed.paper_2 ? 'checked' : ''} />{" "}
                Convert paper documents to electronic files stored on the
                cloud and forward originals to Owner.
                <br />
                <br />
                <h3>Ownership Distribution</h3>
                <div class="row">
                    <div class="column-33">
                        <label>Owner/Member</label>
                        <input
                            class="form-control"
                            placeholder="Owner/Member"
                            value="${ed.owner1_member}"
                        />
                        <label>Percentage</label>
                        <input
                            class="form-control"
                            type="number"
                            value="${ed.owner1_percentage}"
                            placeholder="Percentage"
                        />
                        <label>Tax Identification Number</label>
                        <input
                            class="form-control"
                            placeholder="Tax Identification Number"
                            value="${ed.owner1_tin}"
                        />
                        <label>Electronic Distribution</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="owner1_electronic_distribution"
                                    value="Yes"
                                    ${ed.owner1_ed === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="owner1_electronic_distribution"
                                    value="No"
                                    ${ed.owner1_ed === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>

                        ${ed.owner1_ed === "Yes" ? `<div id="bank_routing_number1">
                                <label>Bank Routing Number</label>
                                <input
                                    class="form-control"
                                    placeholder="Bank Routing Number"
                                    value="${ed.owner1_bank_routing}"
                                />
                            </div>`
                        : ''}
                        ${ed.owner1_ed === "Yes" ? `<div id="bank_accounting_number1">
                                <label>Bank Account Number</label>
                                <input
                                    class="form-control"
                                    placeholder="Bank Account Number"
                                    value="${ed.owner1_bank_account}"
                                />
                            </div>` : ''}
                        ${ed.owner1_ed === "No" ? `<div id="mailing_address1">
                                <label>Mailing Address</label>
                                <input
                                    class="form-control"
                                    placeholder="Mailing Address"
                                    value="${ed.owner1_mailing_address}"
                                />
                            </div>` : ''}
                        </div>
                    ${other_owners}
                </div>
                <br />
                <br />
                <div class="col-md-12">
                    <h3>Distribution Method</h3>
                    <div>
                        <label>
                            Sweep all funds less reserve listed in the
                            management agreement?
                        </label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds"
                                    value="Yes"
                                    ${ed.sweep_all_funds === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds"
                                    value="No"
                                    ${ed.sweep_all_funds === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>
                            Sweep all funds less reserve listed in the
                            management agreement and reserves for property
                            taxes and property insurance?
                        </label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds2"
                                    value="Yes"
                                    ${ed.sweep_all_funds2 === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds2"
                                    value="No"
                                    ${ed.sweep_all_funds2 === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                    </div>
                    <div>
                        <label>
                            Sweep all funds less reserve listed in the
                            management agreement, reserves for property
                            taxes and property insurance, open payables and
                            rents paid in advance?
                        </label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds3"
                                    value="Yes"
                                    ${ed.sweep_all_funds3 === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="sweep_all_funds3"
                                    value="No"
                                    ${ed.sweep_all_funds3 === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                    </div>
                    <hr />
                    <h3>Annual Business License</h3>
                    <div>
                        <label>Prepared by illi CRE?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="prepared_by_illi"
                                    value="Yes"
                                    ${ed.prepared_by_illi === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="prepared_by_illi"
                                    value="No"
                                    ${ed.prepared_by_illi === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                        <label>City Business License Number</label>
                        <input
                            class="form-control"
                            id="city_business_license_number"
                            placeholder="City Business License Number"
                            value="${ed.city_business_license_number}"
                        />
                    </div>
                    <hr />
                    <h3>State of California Statement of Information</h3>
                    <div>
                        <label>Filed by illi CRE?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="filed_by_illi"
                                    value="Yes"
                                    ${ed.filed_by_illi === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="filed_by_illi"
                                    value="No"
                                    ${ed.filed_by_illi === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                        <label>California Corporation Number</label>
                        <input
                            class="form-control"
                            id="california_corporation_number"
                            placeholder="California Corporation Number"
                            value="${ed.california_corporation_number}"
                        />
                    </div>
                    <hr />
                    <h3>Mortgage Payments</h3>
                    <div>
                        <label>Paid by illi CRE?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="paid_by_illi"
                                    value="Yes"
                                    ${ed.paid_by_illi === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="paid_by_illi"
                                    value="No"
                                    ${ed.paid_by_illi === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                        <label>Mortgage Holder</label>
                        <input
                            class="form-control"
                            id="mortgage_holder"
                            placeholder="Mortgage Holder"
                            value="${ed.mortgage_holder}"
                        />
                        <div>
                            Please supply a copy of last mortgage statement
                        </div>
                    </div>
                    <hr />
                    <h3>Prior Period data:</h3>
                    <div>
                        <label>Price Negotiated</label>
                        <input
                            class="form-control"
                            value="${ed.prior_period_data_price_negotiated}"
                            placeholder="Price Negotiated"
                        />
                        <br />
                        <label>
                            Transfer prior periods of current year into illi
                            CRE books?
                        </label>
                        <br />
                        <label>Detailed by month by month?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="detailed_by_month_by_month"
                                    value="Yes"
                                    ${ed.detailed_by_month_by_month === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="detailed_by_month_by_month"
                                    value="No"
                                    ${ed.detailed_by_month_by_month === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                        <br />
                        <label>Summary month by month?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="summary_month_by_month"
                                    value="Yes"
                                    ${ed.summary_month_by_month === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="summary_month_by_month"
                                    value="No"
                                    ${ed.summary_month_by_month === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                    </div>
                    <hr />
                    <h3>
                        Prior Period CAM Reconciliation: Price negotiated
                    </h3>
                    <div>
                        <label>Price Negotiated</label>
                        <input
                            class="form-control"
                            value="${ed.prior_period_cam_price_negotiated}"
                            placeholder="Price Negotiated"
                        />
                        <br />
                        <label>Prepared by illi CRE?</label>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="prepared_by_illi2"
                                    value="Yes"
                                    ${ed.prepared_by_illi2 === "Yes" ? 'checked' : ''}
                                />
                                Yes
                            </label>
                        </div>
                        <div class="radio">
                            <label>
                                <input
                                    type="radio"
                                    name="prepared_by_illi2"
                                    value="No"
                                    ${ed.prepared_by_illi2 === "No" ? 'checked' : ''}
                                />
                                No
                            </label>
                        </div>
                    </div>
                </div>
            </div>`
        );
    });

    return content; 
};

export const pdfCover = (data, textDataOriginal) => {
    const { additional_signatures, additional_initials, other_owners } = data;
    const ownerIDs = generateOwnerIDs(data);
    const textData = {};
    Object.keys(textDataOriginal).forEach((fld) => {
        if (fld !== 'id') {
            if (fld !== "additional_initials" && fld !== "additional_signatures") {
                let convert = false;
                if (textDataOriginal[fld]) {
                    if (textDataOriginal[fld].indexOf("\n") !== -1) {
                        convert = true;
                        textData[fld] = textDataOriginal[fld].replace(
                            /[\r\n]/gm,
                            "<br />"
                        );
                    }
                }
                if (!convert) {
                    textData[fld] = textDataOriginal[fld];
                }
            } else {
                textData[fld] = textDataOriginal[fld];
            }
        }
    });

    const renderNormal = (value, addDollarSign, addComma, addPercent) => {
        if (!value) return ``;
        return ` ${addDollarSign ? "$ " : " "}${
            addComma
                ? `${commatize(value)} `
                : `${value}${addPercent ? "%" : ""}`
        } `;
    };

    const renderSignatureInitial = (field) => {
        const ownerSignature = `<span class="underlined2"><br/><span class="white-text">**_owner1_signature_**</span></span><br /><br />`;
        const ownerInitial = `<span class="initial"><br/><span class="white-text">**_owner1_initial_**</span></span><br /><br />`;

        const otherOwnerSig = (i) => {
            const doo = data.other_owners[i];
            const owner_id = ownerIDs[doo.email];
            return `<span class="underlined2"><br/><span class="white-text">**_owner${owner_id}_signature_**</span></span><br /><br />`;
        };
        const otherOwnerIni = (i) => {
            const doo = data.other_owners[i];
            const owner_id = ownerIDs[doo.email];
            return `<span class="initial"><br/><span class="white-text">**_owner${owner_id}_initial_**</span></span><br /><br />`;
        }

        let hasSig = false;
        let hasInit = false;

        let signaturesAndInitials = [];

        if (!includes(allowAdditionalSignature, field)) return "";

        if (additional_signatures && textData.additional_signatures) {
            if (
                includes(additional_signatures, field) ||
                includes(textData.additional_signatures, field)
            ) {
                hasSig = true;
            }
        }

        if (additional_initials && textData.additional_initials) {
            if (
                includes(additional_initials, field) ||
                includes(textData.additional_initials, field)
            ) {
                hasInit = true;
            }
        }

        if (hasInit || hasSig) {
            const owner = `<div style="display: inline-block; margin: 0 15px">
				${hasSig ? ownerSignature : ""}
				${hasInit ? ownerInitial : ""}
			</div>`;

            const otherOwners = other_owners.map((oo, index) => {
                return `<div style="display: inline-block; margin: 0 15px">
				${hasSig ? otherOwnerSig(index) : ""}
				${hasInit ? otherOwnerIni(index) : ""}
			</div>`;
            });

            signaturesAndInitials = `<div>
				${owner}
				${otherOwners.join("")}
			</div>`;
        }

        return signaturesAndInitials;
    };

    return `<html>
    <head>
      <style>
        .text-center {
          text-align: center;
        }
        .white-text {
          color: white;
        }
        .sign-table td {
          font-size: 12px;
          vertical-align: top;
        }
        .underlined {
          text-decoration: underline;
        }
        .underlined2 {
          border-bottom: 1px solid #ccc;
          min-width: 100px;
          display: inline-block;
		  margin-top: 40px;
        }
        .grayed {
          -webkit-filter: grayscale(1);
        }
        .special-ul li {
          margin-bottom: 10px;
        }
        .special-ul {
          line-height: 1.5;
        }
        ol,
        ul {
            padding-left: 20px;
        }
        .row {
          display: flex;
        }
        .column-50 {
          flex: 50%;
        }
        .column-25 {
          flex: 25%;
        }
        .column-33 {
          flex: 33%;
        }
        .boxy {
          padding: 10px;
          border: 1px solid #ccc;
          margin: 5px;
        }
		.initial {
			border-bottom: 1px solid #ccc;
          	display: inline-block;
			width: 20px;
			height: 20px;
			margin-top: 40px;
		}
		.form-control {
			display: block;
			margin-top: 5px;
			margin-bottom: 10px;
			padding: 5px;
			border: 1px solid #ccc;
		}
      </style>
    </head>
    <body>
      <div style="font-size: 14px; font-family:'Arial';">
        <div class="text-center">
            <h2>
                <img
                src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                style="width: 100px; height: 110px"
                />
            </h2>
            <small>${textData.cover_address}</small>
        </div>
        <br /><br />
        <p className="font-18">${renderNormal(data.date)}</p><br />
        <p className="font-18">${textData.cover0}</p>
        ${renderSignatureInitial("cover0")}
        <p className="font-18">${textData.cover1}</p>
        ${renderSignatureInitial("cover1")}
        <p className="font-18">${textData.cover2}</p>
        ${renderSignatureInitial("cover2")}
        <p className="font-18">${textData.cover3}</p>
        ${renderSignatureInitial("cover3")}
        <p className="font-18">${textData.cover4}</p>
        ${renderSignatureInitial("cover4")}
        <p className="font-18">${textData.cover5}</p><br />
        ${renderSignatureInitial("cover5")}
        <p className="font-18">
            ${textData.cover6_1}<br />
            ${textData.cover6_2}<br />
            ${textData.cover6_3}<br />
        </p>
      </div>
    </body>
</html>
      `;
}

const pdfTemplate = (data, textDataOriginal) => {
    const { additional_signatures, additional_initials, other_owners } = data;
    const ownerIDs = generateOwnerIDs(data);
    const signing_ceremony = get(data, "signing_ceremony", []);
    const textData = {};
    Object.keys(originalTextData).forEach((fld) => {
        if (fld !== 'id') {
            if (fld !== "additional_initials" && fld !== "additional_signatures") {
                let convert = false;
                if (textDataOriginal[fld]) {
                    if (textDataOriginal[fld].indexOf("\n") !== -1) {
                        convert = true;
                        textData[fld] = textDataOriginal[fld].replace(
                            /[\r\n]/gm,
                            "<br />"
                        );
                    }
                }
                if (!convert) {
                    textData[fld] = textDataOriginal[fld];
                }
            } else {
                textData[fld] = textDataOriginal[fld];
            }
        }
        if (!textData[fld]) {
            if (textDataOriginal[fld]) {
                textData[fld] = textDataOriginal[fld];
            } else {
                textData[fld] = '';
            }
        }
    });

    const render = (value, addDollarSign, addComma, addPercent) => {
        if (!value) return ``;
        return ` <strong> ${addDollarSign ? "$ " : " "}${
            addComma
                ? `${commatize(value)} `
                : `${value}${addPercent ? "%" : ""}`
        } </strong> `;
    };
    const renderNormal = (value, addDollarSign, addComma, addPercent) => {
        if (!value) return ``;
        return ` ${addDollarSign ? "$ " : " "}${
            addComma
                ? `${commatize(value)} `
                : `${value}${addPercent ? "%" : ""}`
        } `;
    };

    const renderOwnerNames = () => {
        let ownerNames = [];
        if (ownerIDs) {
            Object.keys(ownerIDs).forEach(email => {
                if (data.owner) {
                    if (data.owner.email === email) {
                        if (ownerNames.indexOf(data.owner.name) === -1) {
                            ownerNames.push(data.owner.name);
                        }
                    }
                    if (data.other_owners) {
                        if (data.other_owners.length > 0) {
                            data.other_owners.forEach(oo => {
                                if (oo.email === email) {
                                    if (ownerNames.indexOf(oo.name) === -1) {
                                        ownerNames.push(oo.name);
                                    }
                                }
                            })
                        }
                    }
                }
            })
        }
        if (ownerNames.length > 0) {
            return `<strong>${ownerNames.join(', ')}</strong>`;
        }
        return null;
    }

    const renderOwner = () => {
        const owners = {};
        owners[data.owner.company_name] = 1;
        data.other_owners.forEach((oo, i) => {
            owners[oo.company_name] = 1;
        });
        return `<span>${Object.keys(owners).join(", ")}</span>`;
    };

    const renderSignatureInitial = (field) => {
        const ownerSignature = `<span class="underlined2"><br/><span class="white-text">**_owner1_signature_**</span></span><br /><br />`;
        const ownerInitial = `<span class="initial"><br/><span class="white-text">**_owner1_initial_**</span></span><br /><br />`;

        const otherOwnerSig = (i) => {
            const doo = data.other_owners[i];
            const owner_id = ownerIDs[doo.email];
            return `<span class="underlined2"><br/><span class="white-text">**_owner${owner_id}_signature_**</span></span><br /><br />`;
        };
        const otherOwnerIni = (i) => {
            const doo = data.other_owners[i];
            const owner_id = ownerIDs[doo.email];
            return `<span class="initial"><br/><span class="white-text">**_owner${owner_id}_initial_**</span></span><br /><br />`;
        }

        let hasSig = false;
        let hasInit = false;

        let signaturesAndInitials = [];

        if (!includes(allowAdditionalSignature, field)) return "";

        if (additional_signatures && textData.additional_signatures) {
            if (
                includes(additional_signatures, field) ||
                includes(textData.additional_signatures, field)
            ) {
                hasSig = true;
            }
        }

        if (additional_initials && textData.additional_initials) {
            if (
                includes(additional_initials, field) ||
                includes(textData.additional_initials, field)
            ) {
                hasInit = true;
            }
        }

        if (hasInit || hasSig) {
            const owner = `<div style="display: inline-block; margin: 0 15px">
				${hasSig ? ownerSignature : ""}
				${hasInit ? ownerInitial : ""}
			</div>`;

            const otherOwners = other_owners.map((oo, index) => {
                return `<div style="display: inline-block; margin: 0 15px">
				${hasSig ? otherOwnerSig(index) : ""}
				${hasInit ? otherOwnerIni(index) : ""}
			</div>`;
            });

            signaturesAndInitials = `<div>
				${owner}
				${otherOwners.join("")}
			</div>`;
        }

        return signaturesAndInitials;
    };

    const getSectionStyle = (section) => {
        if (textData) {
            if (textData[section] === 'hide') {
                return 'style="display:none"'; 
            }
        }
        return '';
    }

    console.log('pdf textdata', textData);

    return `<html>
    <head>
      <style>
        .text-center {
          text-align: center;
        }
        .white-text {
          color: white;
        }
        .sign-table td {
          font-size: 12px;
          vertical-align: top;
        }
        .underlined {
          text-decoration: underline;
        }
        .underlined2 {
          border-bottom: 1px solid #ccc;
          min-width: 100px;
          display: inline-block;
		  margin-top: 40px;
        }
        .grayed {
          -webkit-filter: grayscale(1);
        }
        .special-ul li {
          margin-bottom: 10px;
        }
        .special-ul {
          line-height: 1.5;
        }
        ol,
        ul {
            padding-left: 20px;
        }
        .row {
          display: flex;
        }
        .column-50 {
          flex: 50%;
        }
        .column-25 {
          flex: 25%;
        }
        .column-33 {
          flex: 33%;
        }
        .boxy {
          padding: 10px;
          border: 1px solid #ccc;
          margin: 5px;
        }
		.initial {
			border-bottom: 1px solid #ccc;
          	display: inline-block;
			width: 20px;
			height: 20px;
			margin-top: 40px;
		}
		.form-control {
			display: block;
			margin-top: 5px;
			margin-bottom: 10px;
			padding: 5px;
			border: 1px solid #ccc;
		}
        .no-ol ol li {
            list-style-type: none;
            text-indent: -1.2em;
        }
        .no-ol ol li .boxy {
            text-indent: 0 !important;
        }
      </style>
    </head>
    <body>
      <div style="font-size: 13px; margin-right: 10px; margin-left: 10px;font-family:'Arial';" class="no-ol">
        <div class="text-center">
          <img
            src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
            style="width: 100px; height: 110px"
          />
        </div>
        <div style="font-size:12px;text-align:right">
            <div id="pma-number"></div>
        </div>
        <h2>
          <center>
            <div><b>${textData.main_title}</b></div>
            <br />
            <div><b>${render(data.property.address)} ${render(data.property.address2)} ${render(data.property.city)}, ${render(data.property.state)} ${render(data.property.zip)}</b></div>
          </center>
        </h2>
        <div>
          <div>
            ${textData.a1} ("<u>${textData.a_agreement_title}</u>") ${textData.a2} ${render(data.date)} ${textData.a3} ("<u>${textData.a_manager_title}</u>"), ${textData.a_and}
            <strong>${renderOwner()}</strong> ("<u>${textData.a_owner_title}</u>").
          </div>
          ${renderSignatureInitial("a1")}
          <br />
          <div class="text-center"><u>${textData.recitals}</u> <br /><br /></div>
          <div>
            <p>${textData.whereas_now} <strong>${createPMAAddress(data)}</strong> ${textData.b_property_title}</p>
            ${renderSignatureInitial("whereas_now")}
            <p>${textData.whereas_now2}</p>
            ${renderSignatureInitial("whereas_now2")}
            <div class="text-center">
              <br />
              <u>${textData.b_agreement_title}</u> <br /><br />
            </div>
          </div>
        </div>
        <div>
          ${textData.now_therefore}
          ${renderSignatureInitial("now_therefore")}
        </div>
        <br />
        <ol>
          <li ${getSectionStyle('manager_and_acceptance_section')}>
            ${textData.n1}${' '}
            <span class="underlined">${textData.manager_and_acceptance_title}</span> ${
                textData.manager_and_acceptance
            } 
            ${renderSignatureInitial("manager_and_acceptance")}
          </li>
          <br ${getSectionStyle('manager_and_acceptance_section')} />


          <li ${getSectionStyle('term_and_termination_section')}>
            ${textData.n2}${' '}
            <span class="underlined">${textData.term_and_termination_title}</span> ${textData.term_and_termination_p1} ${render(data.commencement_date)}${renderSignatureInitial("term_and_termination_p1")} ${textData.term_and_termination_p2}
            ${renderSignatureInitial("term_and_termination_p2")}
          </li>
          <br ${getSectionStyle('term_and_termination_section')} />


          <li ${getSectionStyle('management_duties_section')}>
            ${textData.n3}${' '}
            <span class="underlined">${textData.management_duties_title}</span> ${
                textData.management_duties
            }<br />
            ${renderSignatureInitial("management_duties")}
            <br />
            <ol type="a">
              <li ${getSectionStyle('record_statements_collections_section')}>
                ${textData.n3a}${' '}
                <span class="underlined">${textData.record_statements_collections_title}</span> ${
                    textData.record_statements_collections
                }
                ${renderSignatureInitial("record_statements_collections")}
              </li>
              <br ${getSectionStyle('record_statements_collections_section')} />


              <li ${getSectionStyle('repair_maintenance_section')}>
                ${textData.n3b}${' '}
                <span class="underlined">${textData.repair_maintenance_title}</span> ${
                    textData.repair_maintenance
                }
                ${renderSignatureInitial("repair_maintenance")}
              </li>
              <br ${getSectionStyle('repair_maintenance_section')} />


              <li ${getSectionStyle('insurance_proposal_section')}>
                ${textData.n3c}${' '}
                <span class="underlined">${textData.insurance_proposals_title}</span> ${
                    textData.insurance_proposals
                }
                ${renderSignatureInitial("insurance_proposals")}
              </li>
              <br ${getSectionStyle('insurance_proposal_section')} />


              <li ${getSectionStyle('enforcement_section')}>
                ${textData.n3d}${' '}
                <span class="underlined">${textData.enforcement_title}</span> ${
                    textData.enforcement
                }
                ${renderSignatureInitial("enforcement")}
              </li>
              <br ${getSectionStyle('enforcement_section')} />


              <li ${getSectionStyle('payments_section')}>
                ${textData.n3e}${' '}
                <span class="underlined">${textData.payments_title}</span> ${textData.payments}
                ${renderSignatureInitial("payments")}
              </li>
              <br ${getSectionStyle('payments_section')} />


              <li ${getSectionStyle('consultation_section')}>
                ${textData.n3f}${' '}
                <span class="underlined">${textData.consultation_title}</span> ${
                    textData.consultation
                }
                ${renderSignatureInitial("consultation")}
              </li>
              <br ${getSectionStyle('consultation_section')} />


              <li ${getSectionStyle('security_deposits_section')}>
                ${textData.n3g}${' '}
                <span class="underlined">${textData.security_deposits_title}</span> ${
                    textData.security_deposits
                }
                ${renderSignatureInitial("security_deposits")}
              </li>
              <br ${getSectionStyle('security_deposits_section')} />


              <li ${getSectionStyle('owner_distributions_section')}>
                ${textData.n3h}${' '}
                <span class="underlined">${textData.owner_distributions_title}</span> ${
                    textData.owner_distributions
                }
                ${renderSignatureInitial("owner_distributions")}
              </li>
            </ol>
          </li>
          <br ${getSectionStyle('management_duties_section')} />


          <li ${getSectionStyle('leasing_responsibilities_section')}>
            ${textData.n4}${' '}
            <span class="underlined">${textData.leasing_responsibilities_title}</span> ${
                textData.leasing_responsibilities_p1
            }<br />
            ${renderSignatureInitial("leasing_responsibilities_p1")}
            <br ${getSectionStyle('leasing_responsibilities_p2_section')} />
            ${textData.leasing_responsibilities_p2}
            ${renderSignatureInitial("leasing_responsibilities_p2")}
            <ul type="disc">
              <li
                ${getSectionStyle('leasing_responsibilities_p3_section')}
              >
              ${textData.n4a}${' '}
              ${textData.leasing_responsibilities_p3}
              ${renderSignatureInitial("leasing_responsibilities_p3")}
              </li>
              <li 
                ${getSectionStyle('leasing_responsibilities_p4_section')}
              >
              ${textData.n4b}${' '}
              ${textData.leasing_responsibilities_p4}
              ${renderSignatureInitial("leasing_responsibilities_p4")}
              </li>
              <li
                ${getSectionStyle('leasing_responsibilities_p5_section')}
              >
              ${textData.n4c}${' '}
              ${textData.leasing_responsibilities_p5}
              ${renderSignatureInitial("leasing_responsibilities_p5")}
              </li>
            </ul>
          </li>
          <br ${getSectionStyle('leasing_responsibilities_section')} />


          <li ${getSectionStyle('limitation_on_manager_authority_section')}>
            ${textData.n5}${' '}
            <span class="underlined">${textData.limitation_on_manager_authority_title}</span> ${textData.limitation_on_manager_authority_p1}
            ${renderSignatureInitial("limitation_on_manager_authority_p1")}
            <br />
            <br />
            <ol type="a">
              <li ${getSectionStyle('limitation_on_manager_authority_p2_section')}>
              ${textData.n5a}${' '}
              ${textData.limitation_on_manager_authority_p2}
              ${renderSignatureInitial("limitation_on_manager_authority_p2")}
              </li>
              <li ${getSectionStyle('limitation_on_manager_authority_p3_section')}>
              ${textData.n5b}${' '}
              ${textData.limitation_on_manager_authority_p3}
              ${renderSignatureInitial(
                  "limitation_on_manager_authority_p3"
              )}</li>
              <li ${getSectionStyle('limitation_on_manager_authority_p4_section')}>
              ${textData.n5c}${' '}
              ${textData.limitation_on_manager_authority_p4}
              ${renderSignatureInitial(
                  "limitation_on_manager_authority_p4"
              )}</li>
              <li ${getSectionStyle('limitation_on_manager_authority_p5_section')}>
              ${textData.n5d}${' '}
              ${textData.limitation_on_manager_authority_p5}
              ${renderSignatureInitial("limitation_on_manager_authority_p5")}
              </li>
              <li ${getSectionStyle('limitation_on_manager_authority_p6_section')}>
              ${textData.n5e}${' '}
              ${textData.limitation_on_manager_authority_p6}
              ${renderSignatureInitial("limitation_on_manager_authority_p6")}
              </li>
              <li ${getSectionStyle('limitation_on_manager_authority_p7_section')}>
              ${textData.n5f}${' '}
              ${textData.limitation_on_manager_authority_p7}
              ${renderSignatureInitial("limitation_on_manager_authority_p7")}
              </li>
              <li ${getSectionStyle('limitation_on_manager_authority_p8_section')}>
              ${textData.n5g}${' '}
              ${textData.limitation_on_manager_authority_p8}
              ${renderSignatureInitial("limitation_on_manager_authority_p8")}
              </li>
              <li ${getSectionStyle('limitation_on_manager_authority_p9_section')}>
              ${textData.n5h}${' '}
              ${textData.limitation_on_manager_authority_p9}
              ${renderSignatureInitial("limitation_on_manager_authority_p9")}
              </li>
            </ol>
          </li>
          <br ${getSectionStyle('limitation_on_manager_authority_section')} />


          <li ${getSectionStyle('owners_responsibilities_section')} >
            ${textData.n6}${' '}
            <span class="underlined">${textData.owners_responsibilities_title}</span> ${
                textData.owners_responsibilities_intro
            }<br />
            ${renderSignatureInitial("owners_responsibilities_intro")}
            <br />
            <ol type="a">
              <li ${getSectionStyle('owners_responsibilities_generally_section')} >
                ${textData.n6a}${' '}
                <span class="underlined">${textData.owners_responsibilities_generally_title}</span> ${
                    textData.owners_responsibilities_generally
                }
                ${renderSignatureInitial("owners_responsibilities_generally")}
              </li>
              <br ${getSectionStyle('owners_responsibilities_generally_section')} />


              <li ${getSectionStyle('owners_responsibilities_insurance_section')} >
                ${textData.n6b}${' '}
                <span class="underlined">${textData.owners_responsibilities_insurance_title}</span> ${
                    textData.owners_responsibilities_insurance
                }
                ${renderSignatureInitial("owners_responsibilities_insurance")}
              </li>
              <br ${getSectionStyle('owners_responsibilities_insurance_section')} />


              <li ${getSectionStyle('owners_responsibilities_legal_comp_n_disc_section')} >
                ${textData.n6c}${' '}
                <span class="underlined">${textData.owners_responsibilities_legal_comp_n_disc_title}</span> ${
                    textData.owners_responsibilities_legal_comp_n_disc
                }
                ${renderSignatureInitial("owners_responsibilities_legal_comp_n_disc")}
              </li>
              <br ${getSectionStyle('owners_responsibilities_legal_comp_n_disc_section')} />


              <li ${getSectionStyle('owners_responsibilities_security_depo_int_section')} >
                ${textData.n6d}${' '}
                <span class="underlined">${textData.owners_responsibilities_security_depo_int_title}</span> ${
                    textData.owners_responsibilities_security_depo_int
                }
                ${renderSignatureInitial("owners_responsibilities_security_depo_int")}
              </li>
              <br ${getSectionStyle('owners_responsibilities_security_depo_int_section')} />


              <li ${getSectionStyle('owners_responsibilities_owner_rep_p1_section')}>
                ${textData.n6e}${' '}
                <span class="underlined">${textData.owners_responsibilities_owner_rep_p1_title}</span> ${
                    textData.owners_responsibilities_owner_rep_p1
                }
                ${renderOwnerNames()}
                ${renderSignatureInitial("owners_responsibilities_owner_rep_p1")}
                ${textData.owners_responsibilities_owner_rep_p2}
                ${renderSignatureInitial(
                    "owners_responsibilities_owner_rep_p2"
                )}
                ${render(addDashes(data.owner.phone))}${textData.owners_responsibilities_owner_rep_p3}
                ${renderSignatureInitial(
                    "owners_responsibilities_owner_rep_p3"
                )}
              </li>
              <br ${getSectionStyle('owners_responsibilities_owner_rep_p1_section')} />


              <li ${getSectionStyle('owners_responsibilities_tax_prep_section')}>
                ${textData.n6f}${' '}
                <span class="underlined">${textData.owners_responsibilities_tax_prep_title}</span> ${
                    textData.owners_responsibilities_tax_prep
                }
                ${renderSignatureInitial("owners_responsibilities_tax_prep")}
              </li>
            </ol>
          </li>
          <br />

          <li ${getSectionStyle('owners_representations_section')}>
            ${textData.n7}${' '}
            <span class="underlined">${textData.owners_representations_title}</span> ${
                textData.owners_representations
            }
            ${renderSignatureInitial("owners_representations")}
          </li>
          <br ${getSectionStyle('owners_representations_section')} />


          <li ${getSectionStyle('management_fee_section')} >
            ${textData.n8}${' '}
            <span class="underlined">${textData.management_fee_title}</span> <br />
            <br />
            <ol type="a">
              <li ${getSectionStyle('management_fee_p1_section')} >
              ${textData.n8a}${' '}
              ${textData.management_fee_p1}
              ${renderSignatureInitial("management_fee_p1")}
              ${
                  data.monthly_fee_type
                      ? data.monthly_fee_type === "Percentage"
                          ? render(
                                data.monthly_fee_percentage,
                                false,
                                false,
                                true
                            )
                          : render(data.monthly_fee_value, true, true)
                      : ""
              }${textData.management_fee_p2}
                ${renderSignatureInitial("management_fee_p2")}
                ${render(data.minimum_management_fee, true, true)}
                ${textData.management_fee_p3}
                ${renderSignatureInitial("")}
                ${
                    data.monthly_fee_type
                        ? data.monthly_fee_type === "Percentage"
                            ? render(
                                  data.monthly_fee_percentage,
                                  false,
                                  false,
                                  true
                              )
                            : render(data.monthly_fee_value, true, true)
                        : ""
                }
                ${textData.management_fee_p4}
                ${renderSignatureInitial("management_fee_p4")}<br />
                <br />
                ${textData.management_fee_p5}
                ${renderSignatureInitial("management_fee_p5")}
              </li>
              <br ${getSectionStyle('management_fee_p1_section')} />


              <u>${textData.additional_management_fee_schedule_title}</u>
              <br />
              <br />

              <li ${getSectionStyle('add_manage_fee_sched_p1_section')} >
              ${textData.n8b}${' '}
              ${textData.add_manage_fee_sched_p1}
              ${renderSignatureInitial("add_manage_fee_sched_p1")}
              </li>
              <br ${getSectionStyle('add_manage_fee_sched_p1_section')} />
              <br ${getSectionStyle('add_manage_fee_sched_p1_section')} />


              <li ${getSectionStyle('add_manage_fee_sched_p2_section')} >
              ${textData.n8c}${' '}
              ${textData.add_manage_fee_sched_p2}
              ${renderSignatureInitial("add_manage_fee_sched_p2")}
              </li>
              <br ${getSectionStyle('add_manage_fee_sched_p2_section')} />
              <br ${getSectionStyle('add_manage_fee_sched_p2_section')} />


              <li ${getSectionStyle('add_manage_fee_sched_p3_section')} >
                ${textData.n8d}${' '}
                ${textData.add_manage_fee_sched_p3}${render(
                        data.set_up_fee,
                        true,
                        true
                    )}
                ${renderSignatureInitial("add_manage_fee_sched_p3")}
              </li>
              <br ${getSectionStyle('add_manage_fee_sched_p3_section')} />
              <br ${getSectionStyle('add_manage_fee_sched_p3_section')} />


              <li ${getSectionStyle('add_manage_fee_sched_p4_section')} >
                ${textData.n8e}${' '}
                ${textData.add_manage_fee_sched_p4}
                ${renderSignatureInitial("add_manage_fee_sched_p4")}
              </li>
              <br ${getSectionStyle('add_manage_fee_sched_p4_section')} />
              <br ${getSectionStyle('add_manage_fee_sched_p4_section')} />


              <li ${getSectionStyle('add_manage_fee_sched_p5_section')}>
                ${textData.n8f}${' '}
                ${textData.add_manage_fee_sched_p5}
                ${renderSignatureInitial("add_manage_fee_sched_p5")}
              </li>
            </ol>
            <br />
          </li>

          <li ${getSectionStyle('leasing_compensation_section')}>
            ${textData.n9}${' '}
            <span class="underlined">${textData.leasing_compensation_title}</span> ${
                textData.leasing_comp
            } ${renderSignatureInitial("leasing_comp")}<br />
            <br />
            <ol type="a">
              <li ${getSectionStyle('leasing_comp_commissions_generally_section')}>
                ${textData.n9a}${' '}
                <span class="underlined">${textData.commissions_generally_title}</span>
              ${textData.leasing_comp_commissions_generally}
              ${renderSignatureInitial("leasing_comp_commissions_generally")}
              </li>
              <br ${getSectionStyle('leasing_comp_commissions_generally_section')} />
              <br ${getSectionStyle('leasing_comp_commissions_generally_section')} />


              <ol type="i">
                <li ${getSectionStyle('leasing_comp_other_leases_section')}>
                    ${textData.n9a1}${' '}
                  <span class="underlined">${textData.leases_other_than_month_to_month_title}</span> ${
                      textData.leasing_comp_other_leases
                  }
                ${renderSignatureInitial("leasing_comp_other_leases")}
                </li>
                <br ${getSectionStyle('leasing_comp_other_leases_section')} />


                <li ${getSectionStyle('leasing_comp_m_to_m_lease_section')}>
                    ${textData.n9a2}${' '}
                  <span class="underlined">${textData.leasing_comp_m_to_m_lease_title}</span> ${
                      textData.leasing_comp_m_to_m_lease
                  }
                  ${renderSignatureInitial("leasing_comp_m_to_m_lease")}
                </li>
                <br ${getSectionStyle('leasing_comp_m_to_m_lease_section')} />


                <li ${getSectionStyle('leasing_comp_renewals_extensions_section')}>
                    ${textData.n9a3}${' '}
                  <span class="underlined">${textData.leasing_comp_renewals_extensions_title}</span> ${
                      textData.leasing_comp_renewals_extensions
                  }
                  ${renderSignatureInitial("leasing_comp_renewals_extensions")}
                </li>
                <br ${getSectionStyle('leasing_comp_renewals_extensions_section')} />
              </ol>


              <li ${getSectionStyle('leasing_comp_transaction_fees_section')}>
                ${textData.n9b}${' '}
                <span class="underlined">${textData.leasing_comp_transaction_fees_title}</span> ${
                    textData.leasing_comp_transaction_fees
                }
                ${renderSignatureInitial("leasing_comp_transaction_fees")}
              </li>
              <br ${getSectionStyle('leasing_comp_transaction_fees_section')} />


              <li ${getSectionStyle('leasing_comp_cooperating_brokers_section')}>
                ${textData.n9c}${' '}
                <span class="underlined">${textData.leasing_comp_cooperating_brokers_title}</span> ${
                    textData.leasing_comp_cooperating_brokers
                }
                ${renderSignatureInitial("leasing_comp_cooperating_brokers")}
              </li>
              <br ${getSectionStyle('leasing_comp_cooperating_brokers_section')} />


              <li ${getSectionStyle('leasing_comp_post_term_transactions_section')}>
                ${textData.n9d}${' '}
                <span class="underlined">${textData.leasing_comp_post_term_transactions_title}</span> ${
                    textData.leasing_comp_post_term_transactions
                }
                ${renderSignatureInitial("leasing_comp_post_term_transactions")}
              </li>
              <br ${getSectionStyle('leasing_comp_post_term_transactions_section')} />


              <li ${getSectionStyle('leasing_comp_signage_permission_section')}>
                ${textData.n9e}${' '}
                <span class="underlined">${textData.leasing_comp_signage_permission_title}</span> ${
                    textData.leasing_comp_signage_permission
                }
                ${renderSignatureInitial("leasing_comp_signage_permission")}
              </li>
              <br ${getSectionStyle('leasing_comp_signage_permission_section')} />


              <li ${getSectionStyle('leasing_comp_payments_to_manager_section')}>
                ${textData.n9f}${' '}
                <span class="underlined">${textData.leasing_comp_payments_to_manager_title}</span> ${
                    textData.leasing_comp_payments_to_manager
                }
                ${renderSignatureInitial("leasing_comp_payments_to_manager")}
              </li>
              <br ${getSectionStyle('leasing_comp_payments_to_manager_section')} />

            </ol>
          </li>
          <br />

          <li ${getSectionStyle('indemnity_section')}>
            ${textData.n10}${' '}
            <span class="underlined">${textData.indemnity_title}</span> ${textData.indemnity}
            ${renderSignatureInitial("indemnity")}
          </li>
          <br ${getSectionStyle('indemnity_section')} />

          <li ${getSectionStyle('entire_agreement_section')}>
            ${textData.n11}${' '}
            <span class="underlined">${textData.entire_agreement_title}</span> ${
                textData.entire_agreement
            }
            ${renderSignatureInitial("entire_agreement")}
          </li>
          <br ${getSectionStyle('entire_agreement_section')} />


          <li ${getSectionStyle('attorneys_fees_section')}>
            ${textData.n12}${' '}
            <span class="underlined">${textData.attorneys_fees_title}</span> ${
                textData.attorneys_fees
            }
            ${renderSignatureInitial("attorneys_fees")}
          </li>
          <br ${getSectionStyle('attorneys_fees_section')} />


          <li ${getSectionStyle('waiver_section')}>
            ${textData.n13}${' '}
            <span class="underlined">Waiver.</span> ${textData.waiver}
            ${renderSignatureInitial("waiver")}
          </li>
          <br ${getSectionStyle('waiver_section')} />


          <li ${getSectionStyle('additional_documents_section')}>
            ${textData.n14}${' '}
            <span class="underlined">${textData.additional_documents_title}</span> ${
                textData.additional_documents
            }
            ${renderSignatureInitial("additional_documents")}
          </li>
          <br ${getSectionStyle('additional_documents_section')} />


          <li ${getSectionStyle('other_info_confidentiality_section')}>
            ${textData.n15}${' '}
            <span class="underlined">${textData.other_info_confidentiality_title}</span> ${
                textData.other_info_confidentiality
            }
            ${renderSignatureInitial("other_info_confidentiality")}
          </li>
          <br ${getSectionStyle('other_info_confidentiality_section')} />


          <li ${getSectionStyle('manager_conflict_waiver_section')}>
            ${textData.n16}${' '}
            <span class="underlined">${textData.manager_conflict_waiver_title}</span> ${
                textData.manager_conflict_waiver
            }
            ${renderSignatureInitial("manager_conflict_waiver")}
          </li>
          <br ${getSectionStyle('manager_conflict_waiver_section')} />


          <li ${getSectionStyle('notices_section')}>
            ${textData.n17}${' '}
            <span class="underlined">${textData.notices_title}</span> ${textData.notices}
            ${renderSignatureInitial("notices")}
            <div style="page-break-before: always !important;font-size:12px"></div>
            <div class="row">
              <div class="column-33 boxy">
                  <div>${textData.c_manager_title}</div><br />
                  <div>${
                      textData.manager_data_company_name_division
                  } ${renderSignatureInitial(
                        "manager_data_company_name_division"
                    )}</div>
                  <div>${
                      textData.manager_data_attn_person_title
                  } ${renderSignatureInitial(
                        "manager_data_attn_person_title"
                    )}</div>
                  <div>${
                      textData.manager_data_address
                  } ${renderSignatureInitial("manager_data_address")}</div>
                  <div>${textData.c_tel_title} ${
                      textData.manager_data_phone_ext
                  } ${renderSignatureInitial("manager_data_phone_ext")}</div> 
                  <div>${textData.c_fax_title} ${
                      textData.manager_data_phone_fax
                  } ${renderSignatureInitial("manager_data_phone_fax")}</div> 
                  <div>${textData.c_email_title} ${
                      textData.manager_data_email
                  } ${renderSignatureInitial("manager_data_email")}</div>
              </div>
            </div>
            <div class="row">
              <div class="column-33 boxy">
                <div>${textData.d_owner_title}</div><br />
                <div>${textData.d_company_title} ${render(data.owner.company_name)}</div>
                <div>${textData.d_name_title} ${render(data.owner.name)}</div>
                <div>${textData.d_title_title} ${render(data.owner.title)}</div>
                <div>${textData.d_address_title} ${render(data.owner.address)}</div>
                <div>${textData.d_tel_title} ${render(addDashes(data.owner.phone))}</div> 
                <div>${textData.d_email_title} ${render(data.owner.email)}</div>
              </div>
              ${
                  data.other_owners
                      ? data.other_owners
                            .map((oo, i) => {
                                return `<div class="column-33 boxy">
                    <div>${textData.d_owner_title}</div><br />
                    <div>${textData.d_company_title} ${render(oo.company_name)}</div>
                    <div>${textData.d_name_title} ${render(oo.name)}</div>
                    <div>${textData.d_title_title} ${render(oo.title)}</div>
                    <div>${textData.d_address_title} ${render(oo.address)}</div>
                    <div>${textData.d_tel_title} ${render(addDashes(oo.phone))}</div>
                    <div>${textData.d_email_title} ${render(oo.email)}</div>
                  </div>`;
                            })
                            .join("")
                      : ""
              }
            </div>
          </li>
          <br ${getSectionStyle('notices_section')} />


          <li ${getSectionStyle('no_partnership')}>
            ${textData.n18}${' '}
            <span class="underlined">${textData.no_partnership_title}</span> ${
                textData.no_partnership
            }
            ${renderSignatureInitial("no_partnership")}
          </li>
          <br ${getSectionStyle('no_partnership')} />


          <li ${getSectionStyle('approval_section')}>
            ${textData.n19}${' '}
            <span class="underlined">${textData.approval_title}</span> ${textData.approval}
            ${renderSignatureInitial("approval")}
          </li>
          <br ${getSectionStyle('approval_section')} />


          <li ${getSectionStyle('limitation_of_liability_section')}>
            ${textData.n20}${' '}
            <span class="underlined">${textData.limitation_of_liability_title}</span> ${
                textData.limitation_of_liability
            }
            ${renderSignatureInitial("limitation_of_liability")}
          </li>
          <br ${getSectionStyle('limitation_of_liability_section')} />

          <li ${getSectionStyle('no_3rd_party_benef_section')}>
            ${textData.n21}${' '}
            <span class="underlined">${textData.no_3rd_party_benef_title}</span> ${
                textData.no_3rd_party_benef
            }
            ${renderSignatureInitial("no_3rd_party_benef")}
          </li>
          <br ${getSectionStyle('no_3rd_party_benef_section')} />


          <li ${getSectionStyle('severability_section')}>
            ${textData.n22}${' '}
            <span class="underlined">${textData.severability_title}</span> ${
                textData.severability
            }
            ${renderSignatureInitial("severability")}
          </li>
          <br ${getSectionStyle('severability_section')} />


          <li ${getSectionStyle('counterpart_facsimiles_section')}>
            ${textData.n23}${' '}
            <span class="underlined">${textData.counterpart_facsimiles_title}</span> ${
                textData.counterpart_facsimiles
            }
            ${renderSignatureInitial("counterpart_facsimiles")}
          </li>
          <br ${getSectionStyle('counterpart_facsimiles_section')} />


          <li ${getSectionStyle('governing_law_section')}>
            ${textData.n24}${' '}
            <span class="underlined">${textData.governing_law_title}</span> ${
                textData.governing_law
            }
            ${renderSignatureInitial("governing_law")}
          </li>
          <br ${getSectionStyle('governing_law_section')} />


          <li ${getSectionStyle('time_of_essence_section')}>
            ${textData.n25}${' '}
            <span class="underlined">${textData.time_of_essence_title}</span> ${
                textData.time_of_essence
            }
            ${renderSignatureInitial("time_of_essence")}
          </li>
          <br ${getSectionStyle('time_of_essence_section')} />


          <li ${getSectionStyle('successors_assigns_section')}>
            ${textData.n26}${' '}
            <span class="underlined">Successors and Assigns.</span> ${
                textData.successors_assigns
            }
            ${renderSignatureInitial("successors_assigns")}
          </li>
          <br ${getSectionStyle('successors_assigns_section')} />


          <li ${getSectionStyle('interpretation_section')}>
            ${textData.n27}${' '}
            <span class="underlined">${textData.interpretation_title}</span> ${
                textData.interpretation
            }
            ${renderSignatureInitial("interpretation")}
          </li>
          <br ${getSectionStyle('interpretation_section')} />


          <li ${getSectionStyle('managers_employees_section')}>
            ${textData.n28}${' '}
            <span class="underlined">Manager's Employees.</span> ${
                textData.managers_employees
            }
            ${renderSignatureInitial("managers_employees")}
          </li>
          <br ${getSectionStyle('managers_employees_section')} />


          <li ${getSectionStyle('arbitration_of_disputes_section')}>
            ${textData.n29}${' '}
            <span class="underlined">${textData.arbitration_of_disputes_title}</span> ${
                textData.arbitration_of_disputes
            }
            ${renderSignatureInitial("arbitration_of_disputes")}
          </li>
        </ol>
        <div style="page-break-before: always !important;font-size:12px">
          ${textData.in_witness_whereof}
          ${renderSignatureInitial("in_witness_whereof")}<br />
          <br />
          <center>
            <table style="padding: 20px; border: 3px solid rgb(0, 0, 0);" class="sign-table">
              <colgroup>
                <col span="1" style="width: 50%" />
                <col span="1" style="width: 50%" />
                <col span="1" style="width: 15%" />
              </colgroup>
              <tbody>
                <tr>
                  <th>Owner</th>
                  <th>Manager</th>
                </tr>
                <tr>
                <td style="vertical-align: top">
                    <div class="mb-20">
                        <div>${textData.g_owner_title} ${render(
                            data.owner.company_name
                                ? data.owner.company_name
                                : data.owner.name
                        )}</div><br /><br />
                        <div class="underlined2"><span class="white-text">**_owner1_signature_**</span></div><br /><br />
                        <div>${textData.g_name_title} ${render(data.owner.name)}</div><br />
                        <div>${textData.g_title_title} ${render(data.owner.title)}</div><br />
                        <div>Signature Date: <span class="white-text">**_owner1_signature_date_**</span></div><br /><br />
                    </div>
                      
                    ${data.other_owners.map((oo, i) => {
                        const owner_id = ownerIDs[oo.email];
                        return `<br /><br /><div class="mb-20">
                                    <div>${textData.g_owner_title} ${render(
                                        oo.company_name
                                            ? oo.company_name
                                            : oo.name
                                    )}</div><br /><br />
                                    <div class="underlined2"><span class="white-text">**_owner${owner_id}_signature_**</span></div><br /><br />
                                    <div>${textData.g_name_title} ${render(oo.name)}</div><br />
                                    <div>${textData.g_title_title} ${render(oo.title)}</div><br />
                                    <div>Signature Date: <span class="white-text">**_owner${owner_id}_signature_date_**</span></div><br /><br />
                                </div>`;
                    })}
                  </td>
                  <td style="vertical-align: top">
                    ${textData.f_illi_title}<br /><br />
                    <div class="mb-20">
                        <div class="underlined2"><span class="white-text">**_approver_signature_**</span></div><br /><br />
                        <div>${textData.h_president_title} ${render(data.president)}</div><br />
                        <div>${textData.its_president}</div><br />
                        <div>${textData.president_date}<span class="white-text">**_approver_signature_date_**</span></div><br /><br />
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td style="vertical-align: top">
                    <br /><br />
                  </td>
                </tr>
              </tbody>
            </table>
          </center>

		  <div>
			
				
			${renderExhibitA(signing_ceremony)}
		  </div>

        </div>
    </body>
</html>
      `;
};

export default pdfTemplate;
