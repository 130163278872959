import React, { useEffect, useState } from "react";
import { Dialog } from "@material-ui/core";
import { Check, Close } from "@material-ui/icons";
import { connect } from "react-redux";
import readXlsxFile from "read-excel-file";
import ClipLoader from "react-spinners/ClipLoader";

import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Notification from "components/Notification/Notification";
import Table from "components/Table/Table.jsx";
import * as actions from "store/actions";
import { clone } from "shared/utility";

import PropertiesImporterDialog from "../PropertiesImporterDialog";
import { analyzeImportData, createAfterProperty } from "../PropertiesLogic";
import { COSTAR_IMPORTER_FIELDS, COSTAR_IMPORTER_FIELD_NAMES } from "../PropertiesObj";
import { get, isEqual } from "lodash";

const exists = (dat) => {
    return dat ? dat : "";
};

const CoStarPropertiesImporter = (props) => {
    const [data, setData] = useState([]);
    const [alertMsg, setAlertMsg] = useState("");
    const [tableData, setTableData] = useState([]);
    const [showImports, setShowImports] = useState(false);
    const [submissionData, setSubmissionData] = useState([]);
    const [openedChooseModal, setOpenedChooseModal] = useState(null);

    // Mark checkmode true for now since we don't check any dupes
    const [checkMode, setCheckMode] = useState(true);
    const [issues, setIssues] = useState(0); // counter of issues
    const [finalized, setFinalized] = useState(false);
    const [corrected, setCorrected] = useState([]);
    const [filterIssues, setFilterIssues] = useState(false);
    const { import_data, getPropertyUseType } = props;

    // determines if importing is already done
    let isImporting = false;
    if (import_data) {
        if (tableData.length) {
            if (tableData.length !== import_data.checks.length) {
                isImporting = true;
            }
        }
    }

    useEffect(() => {
        let newIssues = 0;
        if (data.length > 0) {
            // ERROR CHECKING
            let errors = [];
            // check if template has more than 1 rows
            if (data.length < 2) errors.push("There needs to be more than 1 row");
            // check if template has 15 columns
            let correctColumns = true;

            const headerRow = get(data, "[0]", []);
            if (!isEqual(headerRow, COSTAR_IMPORTER_FIELD_NAMES)) {
                correctColumns = false;
                errors.push("Invalid columns");
            }

            if (!correctColumns) errors.push("Each row needs to have columns");
            // check if any of the data has spaces between agents
            let agent_pass = true;
            for (let i = 1; i < data.length; i++) {
                const d = data[i];
                const agent1 = d[15];
                const agent2 = d[16];
                const agent3 = d[17];
                const agent4 = d[18];
                if (agent1 || agent2 || agent3 || agent4) {
                    let totals = 0;

                    if (agent1) totals += 1;
                    if (agent2) totals += 2;
                    if (agent3) totals += 3;
                    if (agent4) totals += 4;

                    if (totals !== 10 && totals !== 6 && totals !== 3 && totals !== 1) {
                        agent_pass = false;
                        break;
                    }
                }
            }
            if (!agent_pass)
                errors.push(
                    'Data for agents must not contain a blank between them, e.g. "John Smith, <Blank>, Juan Smith"'
                );

            // check if there are agents who do not exist in contacts table
            let missing_in_contacts_table = [];
            if (import_data) {
                if (import_data.checks) {
                    for (let i = 0; i < import_data.checks.length; i++) {
                        const idc = import_data.checks[i];
                        if (idc.agents_not_in_contacts) {
                            if (idc.agents_not_in_contacts.length > 0) {
                                idc.agents_not_in_contacts.forEach((agent) => {
                                    if (missing_in_contacts_table.indexOf(agent) === -1) {
                                        missing_in_contacts_table.push(agent);
                                    }
                                });
                            }
                        }
                    }
                }
            }
            if (missing_in_contacts_table.length > 0) {
                errors.push(
                    missing_in_contacts_table.join(",") +
                        " is/are non-existent contact(s), please add them to your contacts first."
                );
            }

            if (errors.length > 0) {
                setAlertMsg(errors.join(", "));
                return;
            }

            let newSubmissionData = [];
            setTableData(
                data
                    .filter((d, i) => {
                        if (i !== 0) return true; // remove header row
                    })
                    .map((d, i) => {
                        // let hasBeenCorrected = false;
                        // let property_check = null;
                        // let hasIssues = false;

                        // if (import_data) {
                        //     if (import_data.checks) {
                        //         if (import_data.checks[i]) {
                        //             property_check = clone(import_data.checks[i]);
                        //             property_check.index = i;
                        //             const after_property = createAfterProperty(d);
                        //             hasIssues = analyzeImportData(property_check, after_property);
                        //         }
                        //     }
                        // }

                        // if (corrected.indexOf(i) !== -1) {
                        //     hasBeenCorrected = true;
                        // }

                        // if (hasIssues) newIssues++;

                        newSubmissionData.push({
                            data: d,
                        });

                        return [
                            i + 1,
                            d[COSTAR_IMPORTER_FIELDS.indexOf("street1")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("city")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("zip")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("name")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("use_type")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("owner_name")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("owner_phone")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("owner_contact")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("owner_address")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("owner_city_state_zip")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("total_land_sqft")],
                            d[COSTAR_IMPORTER_FIELDS.indexOf("total_building_sqft")],
                            false,
                        ];
                    })
            );

            setSubmissionData(newSubmissionData);
            setIssues(newIssues);
        }
    }, [data, import_data]);

    const columns = COSTAR_IMPORTER_FIELD_NAMES.map((col) => <strong>{col}</strong>);
    // columns.push(<strong>Actions</strong>)
    columns.unshift(<strong>#</strong>);

    let completed_import = false;
    if (import_data) {
        if (import_data.imported && import_data.error_imports) {
            const total_import_attempts = import_data.imported.length + import_data.error_imports.length;
            if (total_import_attempts === tableData.length) {
                completed_import = true;
            }
        }
    }

    return (
        <div style={{ paddingBottom: 40 }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h1>CoStar Properties Importer</h1>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {tableData.length === 0 && (
                        <div>
                            <div style={{ marginBottom: 20 }}>
                                <strong>Import from Template</strong>
                            </div>
                            <input
                                type="file"
                                id="excel-input"
                                onChange={() => {
                                    readXlsxFile(document.querySelector("#excel-input").files[0])
                                        .then((rows) => {
                                            setTableData([]);
                                            setSubmissionData([]);
                                            setData(
                                                rows.filter((r, i) => {
                                                    // if (i > 3) {
                                                    //     return false;
                                                    // }
                                                    return true;
                                                })
                                            );
                                        })
                                        .catch((e) => {
                                            setAlertMsg(
                                                "Errors detected in file, please ensure you are using the correct template format"
                                            );
                                        });
                                }}
                            />
                        </div>
                    )}
                    {tableData.length > 0 && (
                        <div>
                            <Button
                                color="white"
                                onClick={() => {
                                    window.location.reload();
                                }}
                                style={{ marginRight: 20 }}
                            >
                                RESET
                            </Button>
                            {!checkMode && (
                                <Button
                                    color="success"
                                    onClick={() => {
                                        setCheckMode(true);
                                        setFinalized(true);
                                        props.importProperties(clone(data), true); // true means check only
                                    }}
                                    style={{ marginRight: 20 }}
                                >
                                    START CHECK
                                </Button>
                            )}
                            {isImporting && (
                                <span>
                                    <ClipLoader />
                                </span>
                            )}
                            {/* {!isImporting && corrected.length === issues && (
                                <span>
                                    {finalized && submissionData.length > 0 && (
                                        <Button
                                            color="rose"
                                            onClick={() => {
                                                console.log("importing properties", data);
                                                props.importProperties(clone(data));
                                                setShowImports(true);
                                            }}
                                        >
                                            START IMPORT
                                        </Button>
                                    )}
                                </span>
                            )} */}
                            <Button
                                color="rose"
                                onClick={() => {
                                    props.importProperties(clone(data));
                                    setShowImports(true);
                                }}
                            >
                                START IMPORT
                            </Button>
                        </div>
                    )}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <a
                        href="/templates/properties-importer-template-costar-v1.xlsx"
                        download
                        style={{ textDecoration: "underline", color: "#da3a2f" }}
                    >
                        Download Import Template
                    </a>
                </GridItem>

                {alertMsg && (
                    <Notification
                        message={alertMsg}
                        open={alertMsg ? true : false}
                        close={() => {
                            window.location.reload();
                            setAlertMsg("");
                        }}
                    />
                )}

                {tableData.length > 0 && (
                    <GridItem xs={12} sm={12} md={12}>
                        <div style={{ paddingBottom: 20 }}>
                            <div className="text-center">{tableData.length} Import Entries Loaded</div>
                            {import_data && (
                                <div>
                                    {import_data.checks && (
                                        <div style={{ textAlign: "center" }}>
                                            {import_data.checks.length} Property(s) Checked
                                        </div>
                                    )}
                                </div>
                            )}
                            {issues > 0 && (
                                <div style={{ color: "red", textAlign: "center" }}>{issues} Issue(s) Found</div>
                            )}
                            {corrected.length > 0 && (
                                <div style={{ color: "green", textAlign: "center" }}>
                                    {corrected.length} Issue(s) Corrected
                                </div>
                            )}
                            {!isImporting && checkMode && issues > 0 && (
                                <div style={{ textAlign: "center" }}>
                                    {!filterIssues && (
                                        <Button
                                            color="rose"
                                            onClick={() => {
                                                setFilterIssues(true);
                                            }}
                                        >
                                            SHOW ISSUES ONLY{" "}
                                        </Button>
                                    )}
                                    {filterIssues && (
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                setFilterIssues(false);
                                            }}
                                        >
                                            SHOW ALL
                                        </Button>
                                    )}
                                </div>
                            )}
                        </div>
                        <div style={{ height: 500, overflowY: "scroll" }} className="striped">
                            <Table
                                tableHead={columns}
                                tableData={tableData
                                    .filter((td) => {
                                        if (filterIssues) {
                                            if (td[11]) {
                                                return false;
                                            } else {
                                                return true;
                                            }
                                        } else {
                                            return true;
                                        }
                                    })
                                    .map((m) => {
                                        const mm = clone(m);
                                        mm.pop();
                                        return mm;
                                    })}
                            />
                        </div>
                    </GridItem>
                )}

                {showImports && (
                    <Dialog
                        open={showImports}
                        onClose={() => {
                            setShowImports(false);
                            setTableData([]);
                            setData([]);
                        }}
                        fullWidth={true}
                        maxWidth="md"
                    >
                        <div className="dialog-wrapper">
                            <div
                                className="close-btn"
                                onClick={() => {
                                    if (completed_import) window.location.reload();
                                }}
                            >
                                <Close />
                            </div>
                            <GridContainer>
                                <GridItem xs={6} sm={12} md={12}>
                                    <div className="text-center">
                                        <h3>Import Result(s)</h3>
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <div className="text-center">
                                        {props.import_data && (
                                            <div>
                                                {props.import_data.imported && (
                                                    <div>
                                                        <h4>
                                                            {props.import_data.imported.length}
                                                            {props.import_data.imported.length === 1
                                                                ? " Property"
                                                                : " Properties"}{" "}
                                                            Imported
                                                        </h4>
                                                        {props.import_data.imported.map((im, i) => {
                                                            return <div key={`${i}-import`}>{im}.</div>;
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={6} md={6}>
                                    <div className="text-center">
                                        {props.import_data && (
                                            <div>
                                                {props.import_data.error_imports && (
                                                    <div>
                                                        <h4>
                                                            {props.import_data.error_imports.length}
                                                            {props.import_data.error_imports.length === 1
                                                                ? " Property"
                                                                : " Properties"}{" "}
                                                            Not Imported
                                                        </h4>
                                                        {props.import_data.error_imports.map((im, i) => {
                                                            return (
                                                                <div key={`${i}-import`}>
                                                                    {data[im] ? data[im][2] : "1"} not imported.
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </GridItem>
                                <GridItem xs={6} sm={12} md={12}>
                                    {!completed_import && (
                                        <div style={{ textAlign: "center" }}>
                                            <ClipLoader />
                                        </div>
                                    )}
                                    {completed_import && (
                                        <div className="text-center" style={{ marginTop: 20 }}>
                                            <Button
                                                onClick={() => {
                                                    window.location.reload();
                                                }}
                                            >
                                                CLOSE
                                            </Button>
                                        </div>
                                    )}
                                </GridItem>
                            </GridContainer>
                        </div>
                    </Dialog>
                )}

                {openedChooseModal && (
                    <PropertiesImporterDialog
                        open={openedChooseModal ? true : false}
                        close={() => {
                            setOpenedChooseModal(null);
                        }}
                        data={openedChooseModal}
                        originalData={data}
                        setOriginalData={setData}
                        corrected={corrected}
                        setCorrected={setCorrected}
                        importerFields={COSTAR_IMPORTER_FIELDS}
                        importerFieldNames={COSTAR_IMPORTER_FIELD_NAMES}
                    />
                )}
            </GridContainer>
        </div>
    );
};

const mapStateToProps = (state) => {
    const p = state.crm_properties;
    return {
        error: p.import_error,
        success: p.import_success,
        import_data: p.import_data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        importProperties: (arr) => {
            dispatch(actions.importCostarProperties(arr));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CoStarPropertiesImporter);
