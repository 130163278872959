import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';
import { clone } from 'shared/utility';
import { get } from 'lodash';
const extraMsg = 'Please try again. If the issue persists please contact tech support.';

const initialState = {
	// Regular Query
	companyData: null,
	error: null,
	success: null,
	success_data: null,
	success_data_dba: null,
	loading: false,
	companyContactsData: null,
	companyPropertiesData: null,
	companyUsernames: [],

	companiesData: [],
	companiesCount: 0,
	agentData: [],
	agentDataCount: 0,

	companyChangesData: null,
	companyChangesSuccess: null,
	companyChangesError: null,
	companyChangesLoading: false,

	contactsList: [],

	company_entities: {},
	updated_company_entity: {},

	companyDbas: [],
	loadingDBA: false,
	companyCompanies: [],
	loadingRelatedCompanies: false
};

const getCompaniesStart = (state, action) => {
	return updateObject(state, {
		loading: true,
		companiesData: [],
		error: null,
	});
};

const getCompaniesSuccess = (state, action) => {
	if (action.response.illi_companies) {
		return updateObject(state, {
			loading: false,
			illi_companies: action.response.illi_companies,
			own_companies: action.response.own_companies,
			own_shared_companies: action.response.own_shared_companies,
		});
	}
	return updateObject(state, {
		loading: false,
		companiesData: action.response.data,
		companiesCount: action.response.count,
		agentData: action.response.agentData,
		agentDataCount: action.response.agentDataCount,
	});
};

const getCompaniesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const getCompanyStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getCompanySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		companyData: action.response,
	});
};

const getCompanyFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE COMPANY

const createCompanyStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		success_data: null,
		success_data_dba: null
	});
};

const createCompanySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully created Company',
		success_data: action.response,
		success_data_dba: action.dba_response
	});
};

const createCompanyFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const updateCompanyStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const updateCompanySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully updated company',
	});
};

const updateCompanyFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const deleteCompanyStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteCompanySuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully deleted company',
	});
};

const deleteCompanyFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const getCompanyContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true
	});
};

const getCompanyContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		companyContactsData: action.response,
		loading: false
	});
};

const resetCompanyContacts = (state, action) => {
	return updateObject(state, {
		companyContactsData: [],
	});
}

const getCompanyContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false
	});
};

const createCompanyContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const createCompanyContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully added contact.',
	});
};

const createCompanyContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

const deleteCompanyContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteCompanyContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully deleted contact',
	});
};

const deleteCompanyContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// UPDATE_COMPANY_CONTACTS

const updateCompanyContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const updateCompanyContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully updated company contact',
	});
};

const updateCompanyContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_COMPANY_USERNAMES

const getCompanyUsernamesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getCompanyUsernamesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully gotten usernames',
		companyUsernames: action.response,
	});
};

const getCompanyUsernamesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_COMPANY_SHARES

const getCompanySharesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getCompanySharesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		companyShares: action.response,
	});
};

const getCompanySharesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_COMPANY_SHARE

const createCompanyShareStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const createCompanyShareSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully created company share',
	});
};

const createCompanyShareFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// DELETE_COMPANY_SHARE

const deleteCompanyShareStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteCompanyShareSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Successfully deleted company share',
	});
};

const deleteCompanyShareFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// GET_COMPANY_PROPERTIES

const getCompanyPropertiesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getCompanyPropertiesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		companyPropertiesData: action.response,
		loading: false
	});
};

const getCompanyPropertiesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false
	});
};

// DELETE_COMPANY_PROPERTIES

const deleteCompanyPropertiesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const deleteCompanyPropertiesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
	});
};

const deleteCompanyPropertiesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_COMPANY_PROPERTIES

const createCompanyPropertiesStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const createCompanyPropertiesSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
	});
};

const createCompanyPropertiesFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CLEAR MESSAGES

const clearCRMMessages = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

// GET_COMPANY_CHANGES

const getCompanyChangesStart = (state, action) => {
    return updateObject(state, {
        companyChangesError: null,
        companyChangesSuccess: null,
		companyChangesData: null,
		companyChangesLoading: true,
    })
}

const getCompanyChangesSuccess = (state, action) => {
    return updateObject(state, {
        companyChangesSuccess: 'Success',
		companyChangesData: action.response,
		companyChangesLoading: false,
    })
}

const getCompanyChangesFail = (state, action) => {
    return updateObject(state, {
        companyChangesError: action.err,
		companyChangesLoading: false,
    })
}

// SAVE_COMPANY_CHANGES

const saveCompanyChangesStart = (state, action) => {
    return updateObject(state, {
        companyChangesError: null,
        companyChangesSuccess: null
    })
}

const saveCompanyChangesSuccess = (state, action) => {
    return updateObject(state, {
        companyChangesSuccess: 'Success'
    })
}

const saveCompanyChangesFail = (state, action) => {
    return updateObject(state, {
        companyChangesError: action.err
    })
}

// SET_COMPANY_CHANGES

const setCompanyChangesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const setCompanyChangesSuccess = (state, action) => {
    return updateObject(state, {
        companyChangesData: action.data
    })
}

const setCompanyChangesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const setCompanyChanges = (state, action) => {
    return updateObject(state, {
        companyChangesError: null,
        companyChangesSuccess: null,
        companyChangesData: null
    })
}

// GET_COMPANY_CONTACTS_DETAIL

const getCompanyContactsDetailStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getCompanyContactsDetailSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
		contactsList: action.response
    })
}

const getCompanyContactsDetailFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_COMPANY_ENTITIES

const getCompanyEntitiesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
		company_entities: {}
    })
}

const getCompanyEntitiesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
		company_entities: action.response
    })
}

const getCompanyEntitiesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
		company_entities: {}
    })
}

// CREATE_COMPANY_ENTITY

const createCompanyEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createCompanyEntitySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const createCompanyEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_COMPANY_ENTITY

const deleteCompanyEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteCompanyEntitySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const deleteCompanyEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_UPDATED_COMPANY_ENTITY

const getUpdatedCompanyEntityStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
		updated_company_entity: {}
    })
}

const getUpdatedCompanyEntitySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
		updated_company_entity: action.response
    })
}

const getUpdatedCompanyEntityFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
		updated_company_entity: {}
    })
}

// GET_COMPANY_DBAS

const getCompanyDbasStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
		companyDbas: [],
		loadingDBA: true
    })
}

const getCompanyDbasSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
		companyDbas: action.response,
		loadingDBA: false
    })
}

const getCompanyDbasFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
		loadingDBA: false
    })
}

// CREATE_COMPANY_DBA

const createCompanyDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createCompanyDbaSuccess = (state, action) => {
	console.log('action', action);
    return updateObject(state, {
        success: 'Successfully created Company DBA',
		companyDbas: get(action, `response.company_dbas`, [])
    })
}

const createCompanyDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_COMPANY_DBA

const updateCompanyDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateCompanyDbaSuccess = (state, action) => {
	console.log('action?', action)
    return updateObject(state, {
        success: 'Successfully updated Company DBA',
		companyDbas: get(action, `response.company_dbas`, [])
    })
}

const updateCompanyDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_COMPANY_DBA

const deleteCompanyDbaStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteCompanyDbaSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted Company DBA',
		companyDbas: get(action, `response.company_dbas`, [])
    })
}

const deleteCompanyDbaFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_COMPANY_COMPANIES

const getCompanyCompaniesStart = (state, action) => {
	return updateObject(state, {
			error: null,
			success: null,
			loadingRelatedCompanies: true,
	})
}

const getCompanyCompaniesSuccess = (state, action) => {
	return updateObject(state, {
			success: 'Success',
			companyCompanies: action.response,
			loadingRelatedCompanies: false
	})
}

const getCompanyCompaniesFail = (state, action) => {
	return updateObject(state, {
			error: action.err,
			loadingRelatedCompanies: true,
	})
}

// CREATE_COMPANY_COMPANY

const createCompanyCompanyStart = (state, action) => {
	return updateObject(state, {
			error: null,
			success: null,
			loadingRelatedCompanies: true,
	})
}

const createCompanyCompanySuccess = (state, action) => {
	return updateObject(state, {
			success: 'Success',
			loadingRelatedCompanies: false,
			companyCompanies: action.response.companyCompanies
	})
}

const createCompanyCompanyFail = (state, action) => {
	return updateObject(state, {
			error: action.err,
			loadingRelatedCompanies: false,
	})
}

// UPDATE_COMPANY_COMPANY

const updateCompanyCompanyStart = (state, action) => {
	return updateObject(state, {
			error: null,
			success: null,
			loadingRelatedCompanies: true
	})
}

const updateCompanyCompanySuccess = (state, action) => {
	return updateObject(state, {
			success: 'Success',
			companyCompanies: action.response.companyCompanies,
			loadingRelatedCompanies: false
	})
}

const updateCompanyCompanyFail = (state, action) => {
	return updateObject(state, {
			error: action.err,
			loadingRelatedCompanies: false
	})
}

// DELETE_COMPANY_COMPANY

const deleteCompanyCompanyStart = (state, action) => {
	return updateObject(state, {
			error: null,
			success: null
	})
}

const deleteCompanyCompanySuccess = (state, action) => {
	return updateObject(state, {
			success: 'Success',
			companyCompanies: action.response.companyCompanies
	})
}

const deleteCompanyCompanyFail = (state, action) => {
	return updateObject(state, {
			error: action.err
	})
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_COMPANIES_START:
			return getCompaniesStart(state, action);
		case actionTypes.GET_COMPANIES_SUCCESS:
			return getCompaniesSuccess(state, action);
		case actionTypes.GET_COMPANIES_FAIL:
			return getCompaniesFail(state, action);

		case actionTypes.GET_COMPANY_START:
			return getCompanyStart(state, action);
		case actionTypes.GET_COMPANY_SUCCESS:
			return getCompanySuccess(state, action);
		case actionTypes.GET_COMPANY_FAIL:
			return getCompanyFail(state, action);

		case actionTypes.RESET_COMPANY_CONTACTS:
			return resetCompanyContacts(state, action);

		case actionTypes.CREATE_COMPANY_START:
			return createCompanyStart(state, action);
		case actionTypes.CREATE_COMPANY_SUCCESS:
			return createCompanySuccess(state, action);
		case actionTypes.CREATE_COMPANY_FAIL:
			return createCompanyFail(state, action);

		case actionTypes.UPDATE_COMPANY_START:
			return updateCompanyStart(state, action);
		case actionTypes.UPDATE_COMPANY_SUCCESS:
			return updateCompanySuccess(state, action);
		case actionTypes.UPDATE_COMPANY_FAIL:
			return updateCompanyFail(state, action);

		case actionTypes.DELETE_COMPANY_START:
			return deleteCompanyStart(state, action);
		case actionTypes.DELETE_COMPANY_SUCCESS:
			return deleteCompanySuccess(state, action);
		case actionTypes.DELETE_COMPANY_FAIL:
			return deleteCompanyFail(state, action);
		
		case actionTypes.RESET_COMPANY_CONTACTS:
			return resetCompanyContacts(state, action);

		case actionTypes.GET_COMPANY_CONTACTS_START:
			return getCompanyContactsStart(state, action);
		case actionTypes.GET_COMPANY_CONTACTS_SUCCESS:
			return getCompanyContactsSuccess(state, action);
		case actionTypes.GET_COMPANY_CONTACTS_FAIL:
			return getCompanyContactsFail(state, action);

		case actionTypes.CREATE_COMPANY_CONTACTS_START:
			return createCompanyContactsStart(state, action);
		case actionTypes.CREATE_COMPANY_CONTACTS_SUCCESS:
			return createCompanyContactsSuccess(state, action);
		case actionTypes.CREATE_COMPANY_CONTACTS_FAIL:
			return createCompanyContactsFail(state, action);

		case actionTypes.DELETE_COMPANY_CONTACTS_START:
			return deleteCompanyContactsStart(state, action);
		case actionTypes.DELETE_COMPANY_CONTACTS_SUCCESS:
			return deleteCompanyContactsSuccess(state, action);
		case actionTypes.DELETE_COMPANY_CONTACTS_FAIL:
			return deleteCompanyContactsFail(state, action);

		case actionTypes.UPDATE_COMPANY_CONTACTS_START:
			return updateCompanyContactsStart(state, action);
		case actionTypes.UPDATE_COMPANY_CONTACTS_SUCCESS:
			return updateCompanyContactsSuccess(state, action);
		case actionTypes.UPDATE_COMPANY_CONTACTS_FAIL:
			return updateCompanyContactsFail(state, action);

		case actionTypes.GET_COMPANY_USERNAMES_START:
			return getCompanyUsernamesStart(state, action);
		case actionTypes.GET_COMPANY_USERNAMES_SUCCESS:
			return getCompanyUsernamesSuccess(state, action);
		case actionTypes.GET_COMPANY_USERNAMES_FAIL:
			return getCompanyUsernamesFail(state, action);

		case actionTypes.GET_COMPANY_SHARES_START:
			return getCompanySharesStart(state, action);
		case actionTypes.GET_COMPANY_SHARES_SUCCESS:
			return getCompanySharesSuccess(state, action);
		case actionTypes.GET_COMPANY_SHARES_FAIL:
			return getCompanySharesFail(state, action);

		case actionTypes.CREATE_COMPANY_SHARE_START:
			return createCompanyShareStart(state, action);
		case actionTypes.CREATE_COMPANY_SHARE_SUCCESS:
			return createCompanyShareSuccess(state, action);
		case actionTypes.CREATE_COMPANY_SHARE_FAIL:
			return createCompanyShareFail(state, action);

		case actionTypes.DELETE_COMPANY_SHARE_START:
			return deleteCompanyShareStart(state, action);
		case actionTypes.DELETE_COMPANY_SHARE_SUCCESS:
			return deleteCompanyShareSuccess(state, action);
		case actionTypes.DELETE_COMPANY_SHARE_FAIL:
			return deleteCompanyShareFail(state, action);

		case actionTypes.GET_COMPANY_PROPERTIES_START:
			return getCompanyPropertiesStart(state, action);
		case actionTypes.GET_COMPANY_PROPERTIES_SUCCESS:
			return getCompanyPropertiesSuccess(state, action);
		case actionTypes.GET_COMPANY_PROPERTIES_FAIL:
			return getCompanyPropertiesFail(state, action);

		case actionTypes.DELETE_COMPANY_PROPERTIES_START:
			return deleteCompanyPropertiesStart(state, action);
		case actionTypes.DELETE_COMPANY_PROPERTIES_SUCCESS:
			return deleteCompanyPropertiesSuccess(state, action);
		case actionTypes.DELETE_COMPANY_PROPERTIES_FAIL:
			return deleteCompanyPropertiesFail(state, action);

		case actionTypes.CREATE_COMPANY_PROPERTIES_START:
			return createCompanyPropertiesStart(state, action);
		case actionTypes.CREATE_COMPANY_PROPERTIES_SUCCESS:
			return createCompanyPropertiesSuccess(state, action);
		case actionTypes.CREATE_COMPANY_PROPERTIES_FAIL:
			return createCompanyPropertiesFail(state, action);
            
		case actionTypes.CLEAR_COMPANY_CRM_MESSAGES:
			return clearCRMMessages(state, action);

		case actionTypes.GET_COMPANY_CHANGES_START:
			return getCompanyChangesStart(state, action);
		case actionTypes.GET_COMPANY_CHANGES_SUCCESS:
			return getCompanyChangesSuccess(state, action);
		case actionTypes.GET_COMPANY_CHANGES_FAIL:
			return getCompanyChangesFail(state, action);

		case actionTypes.SAVE_COMPANY_CHANGES_START:
			return saveCompanyChangesStart(state, action);
		case actionTypes.SAVE_COMPANY_CHANGES_SUCCESS:
			return saveCompanyChangesSuccess(state, action);
		case actionTypes.SAVE_COMPANY_CHANGES_FAIL:
			return saveCompanyChangesFail(state, action);

		case actionTypes.SET_COMPANY_CHANGES_START:
			return setCompanyChangesStart(state, action);
		case actionTypes.SET_COMPANY_CHANGES_SUCCESS:
			return setCompanyChangesSuccess(state, action);
		case actionTypes.SET_COMPANY_CHANGES_FAIL:
			return setCompanyChangesFail(state, action);

		case actionTypes.SET_COMPANY_CHANGES:
			return setCompanyChanges(state, action)

		case actionTypes.GET_COMPANY_CONTACTS_DETAIL_START:
			return getCompanyContactsDetailStart(state, action);
		case actionTypes.GET_COMPANY_CONTACTS_DETAIL_SUCCESS:
			return getCompanyContactsDetailSuccess(state, action);
		case actionTypes.GET_COMPANY_CONTACTS_DETAIL_FAIL:
			return getCompanyContactsDetailFail(state, action);

		case actionTypes.GET_COMPANY_ENTITIES_START:
			return getCompanyEntitiesStart(state, action);
		case actionTypes.GET_COMPANY_ENTITIES_SUCCESS:
			return getCompanyEntitiesSuccess(state, action);
		case actionTypes.GET_COMPANY_ENTITIES_FAIL:
			return getCompanyEntitiesFail(state, action);

		case actionTypes.CREATE_COMPANY_ENTITY_START:
			return createCompanyEntityStart(state, action);
		case actionTypes.CREATE_COMPANY_ENTITY_SUCCESS:
			return createCompanyEntitySuccess(state, action);
		case actionTypes.CREATE_COMPANY_ENTITY_FAIL:
			return createCompanyEntityFail(state, action);

		case actionTypes.DELETE_COMPANY_ENTITY_START:
			return deleteCompanyEntityStart(state, action);
		case actionTypes.DELETE_COMPANY_ENTITY_SUCCESS:
			return deleteCompanyEntitySuccess(state, action);
		case actionTypes.DELETE_COMPANY_ENTITY_FAIL:
			return deleteCompanyEntityFail(state, action);

		case actionTypes.GET_UPDATED_COMPANY_ENTITY_START:
			return getUpdatedCompanyEntityStart(state, action);
		case actionTypes.GET_UPDATED_COMPANY_ENTITY_SUCCESS:
			return getUpdatedCompanyEntitySuccess(state, action);
		case actionTypes.GET_UPDATED_COMPANY_ENTITY_FAIL:
			return getUpdatedCompanyEntityFail(state, action);

		case actionTypes.GET_COMPANY_DBAS_START:
			return getCompanyDbasStart(state, action);
		case actionTypes.GET_COMPANY_DBAS_SUCCESS:
			return getCompanyDbasSuccess(state, action);
		case actionTypes.GET_COMPANY_DBAS_FAIL:
			return getCompanyDbasFail(state, action);

		case actionTypes.CREATE_COMPANY_DBA_START:
			return createCompanyDbaStart(state, action);
		case actionTypes.CREATE_COMPANY_DBA_SUCCESS:
			return createCompanyDbaSuccess(state, action);
		case actionTypes.CREATE_COMPANY_DBA_FAIL:
			return createCompanyDbaFail(state, action);

		case actionTypes.UPDATE_COMPANY_DBA_START:
			return updateCompanyDbaStart(state, action);
		case actionTypes.UPDATE_COMPANY_DBA_SUCCESS:
			return updateCompanyDbaSuccess(state, action);
		case actionTypes.UPDATE_COMPANY_DBA_FAIL:
			return updateCompanyDbaFail(state, action);

		case actionTypes.DELETE_COMPANY_DBA_START:
			return deleteCompanyDbaStart(state, action);
		case actionTypes.DELETE_COMPANY_DBA_SUCCESS:
			return deleteCompanyDbaSuccess(state, action);
		case actionTypes.DELETE_COMPANY_DBA_FAIL:
			return deleteCompanyDbaFail(state, action);

		case actionTypes.GET_COMPANY_COMPANIES_START:
				return getCompanyCompaniesStart(state, action);
		case actionTypes.GET_COMPANY_COMPANIES_SUCCESS:
				return getCompanyCompaniesSuccess(state, action);
		case actionTypes.GET_COMPANY_COMPANIES_FAIL:
				return getCompanyCompaniesFail(state, action);

		case actionTypes.CREATE_COMPANY_COMPANY_START:
				return createCompanyCompanyStart(state, action);
		case actionTypes.CREATE_COMPANY_COMPANY_SUCCESS:
				return createCompanyCompanySuccess(state, action);
		case actionTypes.CREATE_COMPANY_COMPANY_FAIL:
				return createCompanyCompanyFail(state, action);

		case actionTypes.UPDATE_COMPANY_COMPANY_START:
				return updateCompanyCompanyStart(state, action);
		case actionTypes.UPDATE_COMPANY_COMPANY_SUCCESS:
				return updateCompanyCompanySuccess(state, action);
		case actionTypes.UPDATE_COMPANY_COMPANY_FAIL:
				return updateCompanyCompanyFail(state, action);

		case actionTypes.DELETE_COMPANY_COMPANY_START:
				return deleteCompanyCompanyStart(state, action);
		case actionTypes.DELETE_COMPANY_COMPANY_SUCCESS:
				return deleteCompanyCompanySuccess(state, action);
		case actionTypes.DELETE_COMPANY_COMPANY_FAIL:
				return deleteCompanyCompanyFail(state, action);

		default:
			return state;
	}
};

export default reducer;


