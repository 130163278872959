import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import compose from 'recompose/compose';

class Selector extends Component {
	constructor(props) {
		super(props);
		
		this.state = {
			selected: props.default ? props.default : '',
			open: props.open ? true : false,
			inputName: props.name
		};
	}

	componentDidUpdate(prevProps) {
		if (prevProps.default !== this.props.default) {
			this.setState({
				selected: this.props.default
			});
		}
	}

	open = (event) => {
		this.setState({
			open: true
		});
	};

	close = (event) => {
		this.setState({
			open: false
		});
	};

	handleSingle = (event) => {
		this.setState({selected: event.target.value, open: false});
		this.props.choose(event.target.value, this.props.name);
	};

	render() {
		const {classes, options, label, indx, desc} = this.props;
		let element = null;
		if (options.length > 0) {
			element = (
				<FormControl error={this.props.error} fullWidth className={classes.selectFormControl}>
					<InputLabel htmlFor='single-select' className={classes.selectLabel}>
						<div style={{textTransform: 'none', color: '#495057 important'}}>{label}</div>
					</InputLabel>
					<Select
						disabled={this.props.disabled ? true : false}
						open={this.state.open}
						style={this.props.customStyles}
						value={this.state.selected}
						onOpen={this.open}
						onClose={this.close}
						onChange={this.handleSingle}
						MenuProps={{className: classes.selectMenu}}
						classes={{select: classes.select}}
						name={this.state.inputName}
						inputProps={{
							name: 'singleSelect',
							id: 'single-select'
						}}
					>
						{options.map((opt, index) => {
							return (
								<MenuItem
									classes={{
										root: classes.selectMenuItem,
										selected: classes.selectMenuItemSelected
									}}
									key={index}
									value={indx ? opt[indx] : opt}
									style={{marginTop: '0px'}}
								>
									{desc ? opt[desc] : opt} {opt.addedText ? opt.addedText : ''} {opt.dblist ? opt.dblist : ''}
								</MenuItem>
							);
						})}
					</Select>
					{this.props.error && <FormHelperText>{this.props.error}</FormHelperText>}
				</FormControl>
			);
		}
		return element;
	}
}

Selector.defaultProps = {
	customStyles: {}
};

const mapStateToProps = (state) => {
	return {
		selected: state.selected
	};
};

export default compose(withStyles(extendedFormsStyle), connect(mapStateToProps, null))(Selector);
