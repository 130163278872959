import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
	contactList: [],
	success: null,
	error: null,
	loading: false
};

// GET_GOOGLE_CONTACTS

const getGoogleContactsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true
	});
};

const getGoogleContactsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Success',
		contactList: action.response.contactList,
		loading: false
	});
};

const getGoogleContactsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_GOOGLE_CONTACTS_START:
			return getGoogleContactsStart(state, action);
		case actionTypes.GET_GOOGLE_CONTACTS_SUCCESS:
			return getGoogleContactsSuccess(state, action);
		case actionTypes.GET_GOOGLE_CONTACTS_FAIL:
			return getGoogleContactsFail(state, action);

		default:
			return state;
	}
};

export default reducer;
