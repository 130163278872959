import * as actionTypes from './actionTypes';

export const getCompanies = (data) => {
	return {
		type: actionTypes.GET_COMPANIES,
		data,
	};
};

export const getCompaniesStart = () => {
	return {
		type: actionTypes.GET_COMPANIES_START,
	};
};

export const getCompaniesSuccess = (response) => {
	return {
		type: actionTypes.GET_COMPANIES_SUCCESS,
		response,
	};
};

export const getCompaniesFail = (err) => {
	return {
		type: actionTypes.GET_COMPANIES_FAIL,
		err,
	};
};

export const getCompany = (data) => {
	return {
		type: actionTypes.GET_COMPANY,
		data,
	};
};

export const getCompanyStart = () => {
	return {
		type: actionTypes.GET_COMPANY_START,
	};
};

export const getCompanySuccess = (response, is_array) => {
	return {
		type: actionTypes.GET_COMPANY_SUCCESS,
		response,
		is_array,
	};
};

export const getCompanyFail = (err) => {
	return {
		type: actionTypes.GET_COMPANY_FAIL,
		err,
	};
};

export const createCompany = (data, sharesForAdd, contactsForAdd, entitiesData, dbasForAdd, relatedCompaniesForAdd) => {
	return {
		type: actionTypes.CREATE_COMPANY,
		data,
		sharesForAdd,
		contactsForAdd,
		entitiesData,
		dbasForAdd,
		relatedCompaniesForAdd
	};
};

export const createCompanyStart = () => {
	return {
		type: actionTypes.CREATE_COMPANY_START,
	};
};

export const createCompanySuccess = (response, dba_response) => {
	return {
		type: actionTypes.CREATE_COMPANY_SUCCESS,
		response,
		dba_response
	};
};

export const createCompanyFail = (err) => {
	return {
		type: actionTypes.CREATE_COMPANY_FAIL,
		err,
	};
};

export const updateCompany = (data) => {
	return {
		type: actionTypes.UPDATE_COMPANY,
		data,
	};
};

export const updateCompanyStart = () => {
	return {
		type: actionTypes.UPDATE_COMPANY_START,
	};
};

export const updateCompanySuccess = (response) => {
	return {
		type: actionTypes.UPDATE_COMPANY_SUCCESS,
		response,
	};
};

export const updateCompanyFail = (err) => {
	return {
		type: actionTypes.UPDATE_COMPANY_FAIL,
		err,
	};
};

export const deleteCompany = (data) => {
	return {
		type: actionTypes.DELETE_COMPANY,
		data,
	};
};

export const deleteCompanyStart = () => {
	return {
		type: actionTypes.DELETE_COMPANY_START,
	};
};

export const deleteCompanySuccess = (response) => {
	return {
		type: actionTypes.DELETE_COMPANY_SUCCESS,
		response,
	};
};

export const deleteCompanyFail = (err) => {
	return {
		type: actionTypes.DELETE_COMPANY_FAIL,
		err,
	};
};

export const resetCompanyContacts = () => {
	return {
		type: actionTypes.RESET_COMPANY_CONTACTS
	}
}

export const getCompanyContacts = (data) => {
	return {
		type: actionTypes.GET_COMPANY_CONTACTS,
		data,
	};
};

export const getCompanyContactsStart = () => {
	return {
		type: actionTypes.GET_COMPANY_CONTACTS_START,
	};
};

export const getCompanyContactsSuccess = (response) => {
	return {
		type: actionTypes.GET_COMPANY_CONTACTS_SUCCESS,
		response,
	};
};

export const getCompanyContactsFail = (err) => {
	return {
		type: actionTypes.GET_COMPANY_CONTACTS_FAIL,
		err,
	};
};

export const createCompanyContacts = (data) => {
	return {
		type: actionTypes.CREATE_COMPANY_CONTACTS,
		data,
	};
};

export const createCompanyContactsStart = () => {
	return {
		type: actionTypes.CREATE_COMPANY_CONTACTS_START,
	};
};

export const createCompanyContactsSuccess = (response) => {
	return {
		type: actionTypes.CREATE_COMPANY_CONTACTS_SUCCESS,
		response,
	};
};

export const createCompanyContactsFail = (err) => {
	return {
		type: actionTypes.CREATE_COMPANY_CONTACTS_FAIL,
		err,
	};
};

export const deleteCompanyContacts = (data) => {
	return {
		type: actionTypes.DELETE_COMPANY_CONTACTS,
		data,
	};
};

export const deleteCompanyContactsStart = () => {
	return {
		type: actionTypes.DELETE_COMPANY_CONTACTS_START,
	};
};

export const deleteCompanyContactsSuccess = (response) => {
	return {
		type: actionTypes.DELETE_COMPANY_CONTACTS_SUCCESS,
		response,
	};
};

export const deleteCompanyContactsFail = (err) => {
	return {
		type: actionTypes.DELETE_COMPANY_CONTACTS_FAIL,
		err,
	};
};

// UPDATE_COMPANY_CONTACTS

export const updateCompanyContacts = (data) => {
	return {
		type: actionTypes.UPDATE_COMPANY_CONTACTS,
		data,
	};
};

export const updateCompanyContactsStart = () => {
	return {
		type: actionTypes.UPDATE_COMPANY_CONTACTS_START,
	};
};

export const updateCompanyContactsSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_COMPANY_CONTACTS_SUCCESS,
		response,
	};
};

export const updateCompanyContactsFail = (err) => {
	return {
		type: actionTypes.UPDATE_COMPANY_CONTACTS_FAIL,
		err,
	};
};

// GET_COMPANY_USERNAMES

export const getCompanyUsernames = (data) => {
	return {
		type: actionTypes.GET_COMPANY_USERNAMES,
		data,
	};
};

export const getCompanyUsernamesStart = () => {
	return {
		type: actionTypes.GET_COMPANY_USERNAMES_START,
	};
};

export const getCompanyUsernamesSuccess = (response) => {
	return {
		type: actionTypes.GET_COMPANY_USERNAMES_SUCCESS,
		response,
	};
};

export const getCompanyUsernamesFail = (err) => {
	return {
		type: actionTypes.GET_COMPANY_USERNAMES_FAIL,
		err,
	};
};

// GET_COMPANY_SHARES

export const getCompanyShares = (data) => {
	return {
		type: actionTypes.GET_COMPANY_SHARES,
		data,
	};
};

export const getCompanySharesStart = () => {
	return {
		type: actionTypes.GET_COMPANY_SHARES_START,
	};
};

export const getCompanySharesSuccess = (response) => {
	return {
		type: actionTypes.GET_COMPANY_SHARES_SUCCESS,
		response,
	};
};

export const getCompanySharesFail = (err) => {
	return {
		type: actionTypes.GET_COMPANY_SHARES_FAIL,
		err,
	};
};

// CREATE_COMPANY_SHARE

export const createCompanyShare = (data) => {
	return {
		type: actionTypes.CREATE_COMPANY_SHARE,
		data,
	};
};

export const createCompanyShareStart = () => {
	return {
		type: actionTypes.CREATE_COMPANY_SHARE_START,
	};
};

export const createCompanyShareSuccess = (response) => {
	return {
		type: actionTypes.CREATE_COMPANY_SHARE_SUCCESS,
		response,
	};
};

export const createCompanyShareFail = (err) => {
	return {
		type: actionTypes.CREATE_COMPANY_SHARE_FAIL,
		err,
	};
};

// DELETE_COMPANY_SHARE

export const deleteCompanyShare = (data) => {
	return {
		type: actionTypes.DELETE_COMPANY_SHARE,
		data,
	};
};

export const deleteCompanyShareStart = () => {
	return {
		type: actionTypes.DELETE_COMPANY_SHARE_START,
	};
};

export const deleteCompanyShareSuccess = (response) => {
	return {
		type: actionTypes.DELETE_COMPANY_SHARE_SUCCESS,
		response,
	};
};

export const deleteCompanyShareFail = (err) => {
	return {
		type: actionTypes.DELETE_COMPANY_SHARE_FAIL,
		err,
	};
};

// GET_COMPANY_PROPERTIES

export const getCompanyProperties = (data) => {
	return {
		type: actionTypes.GET_COMPANY_PROPERTIES,
		data,
	};
};

export const getCompanyPropertiesStart = () => {
	return {
		type: actionTypes.GET_COMPANY_PROPERTIES_START,
	};
};

export const getCompanyPropertiesSuccess = (response) => {
	return {
		type: actionTypes.GET_COMPANY_PROPERTIES_SUCCESS,
		response,
	};
};

export const getCompanyPropertiesFail = (err) => {
	return {
		type: actionTypes.GET_COMPANY_PROPERTIES_FAIL,
		err,
	};
};

// CREATE_COMPANY_PROPERTIES

export const createCompanyProperties = (data) => {
	return {
		type: actionTypes.CREATE_COMPANY_PROPERTIES,
		data,
	};
};

export const createCompanyPropertiesStart = () => {
	return {
		type: actionTypes.CREATE_COMPANY_PROPERTIES_START,
	};
};

export const createCompanyPropertiesSuccess = (response) => {
	return {
		type: actionTypes.CREATE_COMPANY_PROPERTIES_SUCCESS,
		response,
	};
};

export const createCompanyPropertiesFail = (err) => {
	return {
		type: actionTypes.CREATE_COMPANY_PROPERTIES_FAIL,
		err,
	};
};

// DELETE_COMPANY_PROPERTIES

export const deleteCompanyProperties = (data) => {
	return {
		type: actionTypes.DELETE_COMPANY_PROPERTIES,
		data,
	};
};

export const deleteCompanyPropertiesStart = () => {
	return {
		type: actionTypes.DELETE_COMPANY_PROPERTIES_START,
	};
};

export const deleteCompanyPropertiesSuccess = (response) => {
	return {
		type: actionTypes.DELETE_COMPANY_PROPERTIES_SUCCESS,
		response,
	};
};

export const deleteCompanyPropertiesFail = (err) => {
	return {
		type: actionTypes.DELETE_COMPANY_PROPERTIES_FAIL,
		err,
	};
};

export const clearCompanyCRMMessages = () => {
	return {
		type: actionTypes.CLEAR_COMPANY_CRM_MESSAGES,
	};
};

// GET_COMPANY_CHANGES 

export const getCompanyChanges = (data) => {
    return {
        type: actionTypes.GET_COMPANY_CHANGES,
        data
    }
}

export const getCompanyChangesStart = () => {
    return {
        type: actionTypes.GET_COMPANY_CHANGES_START
    }
}

export const getCompanyChangesSuccess = (response) => {
    return {
        type: actionTypes.GET_COMPANY_CHANGES_SUCCESS,
        response
    }
}

export const getCompanyChangesFail = (err) => {
    return {
        type: actionTypes.GET_COMPANY_CHANGES_FAIL,
        err
    }
}

// SAVE_COMPANY_CHANGES 

export const saveCompanyChanges = (data) => {
    return {
        type: actionTypes.SAVE_COMPANY_CHANGES,
        data
    }
}

export const saveCompanyChangesStart = () => {
    return {
        type: actionTypes.SAVE_COMPANY_CHANGES_START
    }
}

export const saveCompanyChangesSuccess = (response) => {
    return {
        type: actionTypes.SAVE_COMPANY_CHANGES_SUCCESS,
        response
    }
}

export const saveCompanyChangesFail = (err) => {
    return {
        type: actionTypes.SAVE_COMPANY_CHANGES_FAIL,
        err
    }
}

// SET_COMPANY_CHANGES 

export const setCompanyChanges = (data) => {
    return {
        type: actionTypes.SET_COMPANY_CHANGES,
        data
    }
}

export const setCompanyChangesStart = () => {
    return {
        type: actionTypes.SET_COMPANY_CHANGES_START
    }
}

export const setCompanyChangesSuccess = (response) => {
    return {
        type: actionTypes.SET_COMPANY_CHANGES_SUCCESS,
        response
    }
}

export const setCompanyChangesFail = (err) => {
    return {
        type: actionTypes.SET_COMPANY_CHANGES_FAIL,
        err
    }
}

// GET_COMPANY_CONTACTS_DETAIL 

export const getCompanyContactsDetail = (data) => {
    return {
        type: actionTypes.GET_COMPANY_CONTACTS_DETAIL,
        data
    }
}

export const getCompanyContactsDetailStart = () => {
    return {
        type: actionTypes.GET_COMPANY_CONTACTS_DETAIL_START
    }
}

export const getCompanyContactsDetailSuccess = (response) => {
    return {
        type: actionTypes.GET_COMPANY_CONTACTS_DETAIL_SUCCESS,
        response
    }
}

export const getCompanyContactsDetailFail = (err) => {
    return {
        type: actionTypes.GET_COMPANY_CONTACTS_DETAIL_FAIL,
        err
    }
}

// GET_COMPANY_ENTITIES 

export const getCompanyEntities = (data) => {
    return {
        type: actionTypes.GET_COMPANY_ENTITIES,
        data
    }
}

export const getCompanyEntitiesStart = () => {
    return {
        type: actionTypes.GET_COMPANY_ENTITIES_START
    }
}

export const getCompanyEntitiesSuccess = (response) => {
    return {
        type: actionTypes.GET_COMPANY_ENTITIES_SUCCESS,
        response
    }
}

export const getCompanyEntitiesFail = (err) => {
    return {
        type: actionTypes.GET_COMPANY_ENTITIES_FAIL,
        err
    }
}

// CREATE_COMPANY_ENTITY 

export const createCompanyEntity = (data) => {
    return {
        type: actionTypes.CREATE_COMPANY_ENTITY,
        data
    }
}

export const createCompanyEntityStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_ENTITY_START
    }
}

export const createCompanyEntitySuccess = (response) => {
    return {
        type: actionTypes.CREATE_COMPANY_ENTITY_SUCCESS,
        response
    }
}

export const createCompanyEntityFail = (err) => {
    return {
        type: actionTypes.CREATE_COMPANY_ENTITY_FAIL,
        err
    }
}

// DELETE_COMPANY_ENTITY 

export const deleteCompanyEntity = (data) => {
    return {
        type: actionTypes.DELETE_COMPANY_ENTITY,
        data
    }
}

export const deleteCompanyEntityStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_ENTITY_START
    }
}

export const deleteCompanyEntitySuccess = (response) => {
    return {
        type: actionTypes.DELETE_COMPANY_ENTITY_SUCCESS,
        response
    }
}

export const deleteCompanyEntityFail = (err) => {
    return {
        type: actionTypes.DELETE_COMPANY_ENTITY_FAIL,
        err
    }
}


// GET_UPDATED_COMPANY_ENTITY

export const getUpdatedCompanyEntity = (data) => {
    return {
        type: actionTypes.GET_UPDATED_COMPANY_ENTITY,
        data
    }
}

export const getUpdatedCompanyEntityStart = () => {
    return {
        type: actionTypes.GET_UPDATED_COMPANY_ENTITY_START
    }
}

export const getUpdatedCompanyEntitySuccess = (response) => {
    return {
        type: actionTypes.GET_UPDATED_COMPANY_ENTITY_SUCCESS,
        response
    }
}

export const getUpdatedCompanyEntityFail = (err) => {
    return {
        type: actionTypes.GET_UPDATED_COMPANY_ENTITY_FAIL,
        err
    }
}

// GET_COMPANY_DBAS 

export const getCompanyDbas = (data) => {
    return {
        type: actionTypes.GET_COMPANY_DBAS,
        data
    }
}

export const getCompanyDbasStart = () => {
    return {
        type: actionTypes.GET_COMPANY_DBAS_START
    }
}

export const getCompanyDbasSuccess = (response) => {
    return {
        type: actionTypes.GET_COMPANY_DBAS_SUCCESS,
        response
    }
}

export const getCompanyDbasFail = (err) => {
    return {
        type: actionTypes.GET_COMPANY_DBAS_FAIL,
        err
    }
}

// CREATE_COMPANY_DBA 

export const createCompanyDba = (data) => {
    return {
        type: actionTypes.CREATE_COMPANY_DBA,
        data
    }
}

export const createCompanyDbaStart = () => {
    return {
        type: actionTypes.CREATE_COMPANY_DBA_START
    }
}

export const createCompanyDbaSuccess = (response) => {
    return {
        type: actionTypes.CREATE_COMPANY_DBA_SUCCESS,
        response
    }
}

export const createCompanyDbaFail = (err) => {
    return {
        type: actionTypes.CREATE_COMPANY_DBA_FAIL,
        err
    }
}

// UPDATE_COMPANY_DBA 

export const updateCompanyDba = (data) => {
    return {
        type: actionTypes.UPDATE_COMPANY_DBA,
        data
    }
}

export const updateCompanyDbaStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_DBA_START
    }
}

export const updateCompanyDbaSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_COMPANY_DBA_SUCCESS,
        response
    }
}

export const updateCompanyDbaFail = (err) => {
    return {
        type: actionTypes.UPDATE_COMPANY_DBA_FAIL,
        err
    }
}

// DELETE_COMPANY_DBA 

export const deleteCompanyDba = (data) => {
    return {
        type: actionTypes.DELETE_COMPANY_DBA,
        data
    }
}

export const deleteCompanyDbaStart = () => {
    return {
        type: actionTypes.DELETE_COMPANY_DBA_START
    }
}

export const deleteCompanyDbaSuccess = (response) => {
    return {
        type: actionTypes.DELETE_COMPANY_DBA_SUCCESS,
        response
    }
}

export const deleteCompanyDbaFail = (err) => {
    return {
        type: actionTypes.DELETE_COMPANY_DBA_FAIL,
        err
    }
}

// GET_COMPANY_COMPANIES 

export const getCompanyCompanies = (data) => {
	return {
			type: actionTypes.GET_COMPANY_COMPANIES,
			data
	}
}

export const getCompanyCompaniesStart = () => {
	return {
			type: actionTypes.GET_COMPANY_COMPANIES_START
	}
}

export const getCompanyCompaniesSuccess = (response) => {
	return {
			type: actionTypes.GET_COMPANY_COMPANIES_SUCCESS,
			response
	}
}

export const getCompanyCompaniesFail = (err) => {
	return {
			type: actionTypes.GET_COMPANY_COMPANIES_FAIL,
			err
	}
}

// CREATE_COMPANY_COMPANY 

export const createCompanyCompany = (data) => {
	return {
			type: actionTypes.CREATE_COMPANY_COMPANY,
			data
	}
}

export const createCompanyCompanyStart = () => {
	return {
			type: actionTypes.CREATE_COMPANY_COMPANY_START
	}
}

export const createCompanyCompanySuccess = (response) => {
	return {
			type: actionTypes.CREATE_COMPANY_COMPANY_SUCCESS,
			response
	}
}

export const createCompanyCompanyFail = (err) => {
	return {
			type: actionTypes.CREATE_COMPANY_COMPANY_FAIL,
			err
	}
}

// UPDATE_COMPANY_COMPANY 

export const updateCompanyCompany = (data) => {
	return {
			type: actionTypes.UPDATE_COMPANY_COMPANY,
			data
	}
}

export const updateCompanyCompanyStart = () => {
	return {
			type: actionTypes.UPDATE_COMPANY_COMPANY_START
	}
}

export const updateCompanyCompanySuccess = (response) => {
	return {
			type: actionTypes.UPDATE_COMPANY_COMPANY_SUCCESS,
			response
	}
}

export const updateCompanyCompanyFail = (err) => {
	return {
			type: actionTypes.UPDATE_COMPANY_COMPANY_FAIL,
			err
	}
}

// DELETE_COMPANY_COMPANY 

export const deleteCompanyCompany = (data) => {
	return {
			type: actionTypes.DELETE_COMPANY_COMPANY,
			data
	}
}

export const deleteCompanyCompanyStart = () => {
	return {
			type: actionTypes.DELETE_COMPANY_COMPANY_START
	}
}

export const deleteCompanyCompanySuccess = (response) => {
	return {
			type: actionTypes.DELETE_COMPANY_COMPANY_SUCCESS,
			response
	}
}

export const deleteCompanyCompanyFail = (err) => {
	return {
			type: actionTypes.DELETE_COMPANY_COMPANY_FAIL,
			err
	}
}
