import React from 'react';
import Button from 'components/CustomButtons/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const ERTLActions = (props) => {
    return <div>
        <Button color="rose" 
            onClick={() => { 
                const obj = {
                    id: props.ertlEntryData.id,
                    template_id: null,
                    html: props.html,
                    tabs: props.localTabsData,
                    status: 1
                }
                
                // props.createERTL({
                    
                // })
            }}
        >SUBMIT</Button>
    </div>
}

const mapStateToProps = state => {
    return {
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,
    }
}
  
  const mapDispatchToProps = dispatch => {
    return {
      getListings: (property_id) => {
          dispatch(actions.getListings(property_id));
      },
      getListing: (id) => {
          dispatch(actions.getListing(id));
      },
      createListing: (data) => {
          dispatch(actions.createListing(data));
      },
      updateListing: (data) => {
          dispatch(actions.updateListing(data));
      },
      deleteListing: (id) => {
          dispatch(actions.deleteListing(id));
      },
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ERTLActions);