import * as actionTypes from '../actions/actionTypes';
import { updateObject, clone } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    error_delete: null,
    success_delete: null,
    entries: null,
    entryData: null,
    error_pdf: null,
    success_pdf: null,
    pdfData: null,
    error_history: null,
    success_history: null,
    tr_history: null,
    error_url: null,
    success_url: null,
    signing_url: null
}

// GET_TR_ENTRIES

const getTrEntriesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getTrEntriesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        entries: action.response
    })
}

const getTrEntriesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_TR_ENTRY

const getTrEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getTrEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const getTrEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_TR_ENTRY

const createTrEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createTrEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        entryData: action.response
    })
}

const createTrEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_TR_ENTRY

const updateTrEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateTrEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const updateTrEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_TR_ENTRY

const deleteTrEntryStart = (state, action) => {
    return updateObject(state, {
        error_delete: null,
        success_delete: null
    })
}

const deleteTrEntrySuccess = (state, action) => {
    return updateObject(state, {
        success_delete: 'Success'
    })
}

const deleteTrEntryFail = (state, action) => {
    return updateObject(state, {
        error_delete: action.err
    })
}

// GET_TR_APPROVERS

const getTrApproversStart = (state, action) => {
    return updateObject(state, {
        error_final_approvers: null,
        success_final_approvers: null,
        final_approvers: null
    })
}

const getTrApproversSuccess = (state, action) => {
    return updateObject(state, {
        success_final_approvers: 'Success',
        final_approvers: action.response
    })
}

const getTrApproversFail = (state, action) => {
    return updateObject(state, {
        error_final_approvers: action.err
    })
}

// CREATE_TR_PDF

const createTrPdfStart = (state, action) => {
    return updateObject(state, {
        error_pdf: null,
        success_pdf: null,
        pdfData: null
    })
}

const createTrPdfSuccess = (state, action) => {
    return updateObject(state, {
        success_pdf: 'Success',
        pdfData: action.response
    })
}

const createTrPdfFail = (state, action) => {
    return updateObject(state, {
        error_pdf: action.err
    })
}

// CREATE_TR_HISTORY_PDF

const createTrHistoryPdfStart = (state, action) => {
    return updateObject(state, {
        error_pdf: null,
        success_pdf: null,
        pdfData: null
    })
}

const createTrHistoryPdfSuccess = (state, action) => {
    return updateObject(state, {
        success_pdf: 'Success',
        pdfData: action.response.trPdf
    })
}

const createTrHistoryPdfFail = (state, action) => {
    return updateObject(state, {
        error_pdf: action.err
    })
}

// GET_TR_HISTORY

const getTrHistoryStart = (state, action) => {
    return updateObject(state, {
        error_history: null,
        success_history: null,
        tr_history: null
    })
}

const getTrHistorySuccess = (state, action) => {
    return updateObject(state, {
        success_history: 'Success',
        tr_history: action.response
    })
}

const getTrHistoryFail = (state, action) => {
    return updateObject(state, {
        error_history: action.err
    })
}

// CREATE_TR_HISTORY

const createTrHistoryStart = (state, action) => {
    return updateObject(state, {
        error_history: null,
        success_history: null
    })
}

const createTrHistorySuccess = (state, action) => {
    return updateObject(state, {
        success_history: 'Successfully created history'
    })
}

const createTrHistoryFail = (state, action) => {
    return updateObject(state, {
        error_history: action.err
    })
}

// GET_TR_SIGNING_URL

const getTrSigningUrlStart = (state, action) => {
    return updateObject(state, {
        error_url: null,
        success_url: null,
        signing_url: null
    })
}

const getTrSigningUrlSuccess = (state, action) => {
    return updateObject(state, {
        success_url: 'Success',
        signing_url: action.response ? action.response.url : null
    })
}

const getTrSigningUrlFail = (state, action) => {
    return updateObject(state, {
        error_url: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TR_ENTRIES_START:
            return getTrEntriesStart(state, action);
        case actionTypes.GET_TR_ENTRIES_SUCCESS:
            return getTrEntriesSuccess(state, action);
        case actionTypes.GET_TR_ENTRIES_FAIL:
            return getTrEntriesFail(state, action);

        case actionTypes.GET_TR_ENTRY_START:
            return getTrEntryStart(state, action);
        case actionTypes.GET_TR_ENTRY_SUCCESS:
            return getTrEntrySuccess(state, action);
        case actionTypes.GET_TR_ENTRY_FAIL:
            return getTrEntryFail(state, action);

        case actionTypes.CREATE_TR_ENTRY_START:
            return createTrEntryStart(state, action);
        case actionTypes.CREATE_TR_ENTRY_SUCCESS:
            return createTrEntrySuccess(state, action);
        case actionTypes.CREATE_TR_ENTRY_FAIL:
            return createTrEntryFail(state, action);

        case actionTypes.UPDATE_TR_ENTRY_START:
            return updateTrEntryStart(state, action);
        case actionTypes.UPDATE_TR_ENTRY_SUCCESS:
            return updateTrEntrySuccess(state, action);
        case actionTypes.UPDATE_TR_ENTRY_FAIL:
            return updateTrEntryFail(state, action);

        case actionTypes.DELETE_TR_ENTRY_START:
            return deleteTrEntryStart(state, action);
        case actionTypes.DELETE_TR_ENTRY_SUCCESS:
            return deleteTrEntrySuccess(state, action);
        case actionTypes.DELETE_TR_ENTRY_FAIL:
            return deleteTrEntryFail(state, action);

        case actionTypes.GET_TR_APPROVERS_START:
            return getTrApproversStart(state, action);
        case actionTypes.GET_TR_APPROVERS_SUCCESS:
            return getTrApproversSuccess(state, action);
        case actionTypes.GET_TR_APPROVERS_FAIL:
            return getTrApproversFail(state, action);

        case actionTypes.CREATE_TR_PDF_START:
            return createTrPdfStart(state, action);
        case actionTypes.CREATE_TR_PDF_SUCCESS:
            return createTrPdfSuccess(state, action);
        case actionTypes.CREATE_TR_PDF_FAIL:
            return createTrPdfFail(state, action);

        case actionTypes.CREATE_TR_HISTORY_PDF_START:
            return createTrHistoryPdfStart(state, action);
        case actionTypes.CREATE_TR_HISTORY_PDF_SUCCESS:
            return createTrHistoryPdfSuccess(state, action);
        case actionTypes.CREATE_TR_HISTORY_PDF_FAIL:
            return createTrHistoryPdfFail(state, action);

        case actionTypes.GET_TR_HISTORY_START:
            return getTrHistoryStart(state, action);
        case actionTypes.GET_TR_HISTORY_SUCCESS:
            return getTrHistorySuccess(state, action);
        case actionTypes.GET_TR_HISTORY_FAIL:
            return getTrHistoryFail(state, action);

        case actionTypes.CREATE_TR_HISTORY_START:
            return createTrHistoryStart(state, action);
        case actionTypes.CREATE_TR_HISTORY_SUCCESS:
            return createTrHistorySuccess(state, action);
        case actionTypes.CREATE_TR_HISTORY_FAIL:
            return createTrHistoryFail(state, action);

        case actionTypes.GET_TR_SIGNING_URL_START:
            return getTrSigningUrlStart(state, action);
        case actionTypes.GET_TR_SIGNING_URL_SUCCESS:
            return getTrSigningUrlSuccess(state, action);
        case actionTypes.GET_TR_SIGNING_URL_FAIL:
            return getTrSigningUrlFail(state, action);
        
        default:
            return state;
    }
}

export default reducer;