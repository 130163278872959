import { clone } from "lodash";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../store/actions/index";
import { FadeLoader } from "react-spinners";
import jwt_decode from "jwt-decode";
import ERTLOwnerFullForm from "../../containers/ERTL/ERTLOwnerFullForm";
import ERTLDisplayForm from "../../containers/ERTL/ERTLSharedComponents/ERTLDisplayForm";
import ERTLFormWizard from "../../containers/ERTL/FormWizard/ERTLFormWizard";

const ERTLOwnerReview = (props) => {
    const {
        clientErtl,
        getClientErtl,
        clientError,
        clientEmail,
        getTemplates,
        templatesData,
        getEntries,
        updateClientErtl,
        entriesData,
        getEntryPdf,
        pdfData,
    } = props;

    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(''); 

    useEffect(() => {
        const search = props.location.search;
        const params = new URLSearchParams(search);
       
        const jwt_token = params.get("token");
        if (jwt_token) {
            getClientErtl({
                token: jwt_token,
            });
            setToken(jwt_token);
        } else {
            props.history.push(`/`);
        }
    }, []);

    useEffect(() => {
        if (clientError) {
            props.history.push(`/review/ertl/done`);
        }
    }, [clientError]);

    useEffect(() => {
        if (clientErtl) {
            if(clientErtl.status === 1 
                && clientErtl?.data?.owners_review.every(reviewer => reviewer.reviewed === true)) {
                props.history.push(`/review/ertl/done`);
            }
            setLoading(false);
        }
    }, [clientErtl]);

    if (loading) {
        return (
            <div
                style={{
                    width: "100vw",
                    height: "100vh",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <FadeLoader />
                <p>Loading ERTL</p>
            </div>
        );
    } else {
        const historyDiffData = "";
        const templateData = clientErtl.data.templateData;

        return (
            <div>
                <ERTLOwnerFullForm
                    getErtlApprovers={props.getErtlApprovers}
                    token={token}
                    getTemplates={getTemplates}
                    templatesData={templatesData}
                    templateData={{ data: templateData }}
                    getEntries={getEntries}
                    updateClientErtl={updateClientErtl}
                    entriesData={entriesData}
                    getEntryPdf={getEntryPdf}
                    pdfData={pdfData}
                    historyPush={props.history.push}
                    ertlData={clientErtl}
                    clientEmail={clientEmail}
                />
            </div>
        );
    }
};

const mapStateToProps = (state) => {
    return {
        clientEmail: state.ertl_entries.clientEmail,
        clientErtl: state.ertl_entries.clientErtl,
        clientError: state.ertl_entries.clientError,
        templatesData: state.ertl_templates.templatesData,
        templatesCount: state.ertl_templates.templatesCount,

        statusEntries: state.ertl_entries.statusEntries,
        ertlURL: state.ertl_entries.ertlURL,

        entry_error: state.ertl_entries.error,
        entry_success: state.ertl_entries.success,
        entry_loading: state.ertl_entries.loadingCreate,
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,

        entriesData: state.ertl_entries.entriesData,
        pdfData: state.ertl_entries.pdfData,
        historyData: state.ertl_entries.historyData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getClientErtl: (data) => {
            dispatch(actions.getClientErtl(data));
        },
        getTemplates: (data) => {
            dispatch(actions.getTemplates(data));
        },

        getErtlHistory: (data, entry_id) => {
            dispatch(actions.getErtlHistory(data, entry_id));
        },
        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getErtlUrl: (id) => {
            dispatch(actions.getErtlUrl(id));
        },

        getTemplate: (id) => {
            dispatch(actions.getTemplate(id));
        },
        createTemplate: (data) => {
            dispatch(actions.createTemplate(data));
        },
        getEntries: (data) => {
            dispatch(actions.getEntries(data));
        },
        getEntriesByStatus: (data, own) => {
            dispatch(actions.getEntriesByStatus(data, own));
        },
        getEntryPdf: async (data) => {
            dispatch(actions.getEntryPdf(data));
        },
        updateClientErtl: (data, newTemplate) => {
            dispatch(actions.updateClientErtlEntry(data, newTemplate));
        },
        getErtlApprovers: () => {
            dispatch(actions.getErtlApprovers());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ERTLOwnerReview);
