import React, { useEffect, useState } from "react";
import ReactTable from "react-table";
import { formatDate2, convert } from "shared/utility";
import { Tooltip } from "@material-ui/core";
import { Edit, Close } from "@material-ui/icons";
import Loader from "components/Loader/Loader";
import Button from "../../../components/CustomButtons/Button";
import { pmaStatus } from "../PMAObj";
import { Dialog } from "@material-ui/core";
import { getUser } from "../../../shared/authValidation";
import Notification from "../../../components/Notification/Notification";
import moment from "moment";
import { createAddressObject } from "../../CRM/Properties/PropertiesLogic";
import { clone } from "../../../shared/utility";

const sameEmailCheck = (data) => {
    
    let allowed = false;
    if (data.signing_ceremony) {
        if (data.signing_ceremony.length > 0) {
            const user = getUser();
            
            if (user.permObj) {
                if (user.permObj.hasOwnProperty("PMA approve level_2")) {
                    allowed = true;
                }
            }
        }
    }
    return allowed;
};

const PMAList = (props) => {
    const {
        pmaEntries,
        setPmaData,
        setTab,
        deletePmaEntry,
        success,
        error,
        clearMessages,
        finalApproval,
        resendPmaApproverNotification,
        resendPmaApproverNotificationMessage,
        getPmaFile,
        voidPmaEntry,
        loggedInUser
    } = props;

    const [showURL, setShowURL] = useState(false);
    const [delay, setDelay] = useState(false);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(null);
    const [openedDeleteForm, setOpenedDeleteForm] = useState(false);
    const [userPerms, setUserPerms] = useState([]);
    const [resend, setResend] = useState(false);
    const [notification, setNotification] = useState("");
    const [openedVoidAndEditModal, setOpenedVoidAndEditModal] = useState(false);
    const [pmaToVoid, setPmaToVoid] = useState(null);

    useEffect(() => {
        const user = getUser();
        if (user) {
            if (user.permObj) {
                setUserPerms(user.permObj);
            }
        }
    }, []);

    useEffect(() => {
        if (success) {
            if (resend) {
                setNotification("Successfully resent PMA entry.");
                setResend(false);
            }
        }
    }, [success]);

    useEffect(() => {
        if (error === 'Failed to update PMA Entry.') {
            if (resend) {
                setNotification("There was an error with your PMA Entry. If the issue persists please contact technical support.");
                setResend(false);
            }
        }
    }, [error]);

    useEffect(() => {
        if (resendPmaApproverNotificationMessage) {
            if (resend) {
                setNotification(resendPmaApproverNotificationMessage);
                setResend(false);
            }
        }
    }, [resendPmaApproverNotificationMessage]);

    useEffect(() => {
        if (pmaEntries) {
            if (finalApproval) {
                const open_id = parseInt(finalApproval);
                let open_data = null;
                pmaEntries.forEach((l) => {
                    if (l.id === open_id) open_data = l;
                });
                if (open_data) {
                    if (open_data.data) {
                        if (sameEmailCheck(open_data.data)) {
                            props.getPmaSigningUrl(open_data.id);
                            setShowURL(true);
                        }
                    }
                }
            }
        }
    }, [pmaEntries]);

    const handleVoidAndEdit = (pma) => {
        setOpenedVoidAndEditModal(true);
        setPmaToVoid(pma);
    }

    const data = pmaEntries
        ? pmaEntries.filter(p => {
            if (props.tab === 'Entries') {
                if (p.type === 'A') {
                    if (loggedInUser) {
                        if (loggedInUser.type === 'E') {
                            // if employee is logged in and pma is agent then don't show it
                            return false;
                        }
                    }
                }
            }
            return true;
        }).map((l, key) => {
              // map users to table rows
              const d = l.data;
              
              let address = d.property
                  ? d.property.address
                      ? d.property.address
                      : ""
                  : "";
              if (d.property) {
                if (d.property.address2) {
                    const propClone = clone(d.property);
                    propClone.street1 = propClone.address;
                    propClone.street2 = propClone.address2;
                    const ao = createAddressObject(propClone);
                    address = `${ao.street1} ${ao.street2} ${ao.city}, ${ao.state} ${ao.zip}`;
                }
              }
              let signed = "";
              if (d) {
                  if (d.signing_ceremony) {
                      if (d.signing_ceremony.length > 0) {
                          let signers = [];
                          d.signing_ceremony.forEach((s) => {
                              if (s.name && s.signed === 1) {
                                  signers.push(s.name);
                              }
                          });
                          signed = signers.join(", ");
                      }
                  }
              }
              return {
                  id: l.id,
                  pma_number: d.pma_id
                      ? d.pma_id
                      : l.id
                      ? l.id
                      : "To be automatically assigned",
                  signed,
                  name: l.name,
                  address,
                  created_date: l.created_date.split('T')[0],
                  created_datetime: l.created_date,
                  modified_date: l.modified_date.split('T')[0],
                  modified_datetime: l.modified_date,
                  date_last_sent: l.date_last_sent,
                  status: pmaStatus[l.status],
                  expiry: l.expiry,
                  ertlData: l,
                  actions: (
                      <div>
                          {props.tab !== "Entries" && (
                              <Tooltip title="View" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          setPmaData(l);
                                          props.openFormFillWizard("View");
                                      }}
                                      color="primary"
                                  >
                                      <i className="fas fa-eye font-22"></i>
                                  </Button>
                              </Tooltip>
                          )}
                          {props.tab === "Pending Final Approval" &&
                              sameEmailCheck(l.data) && (
                                  <Tooltip title="Sign PMA" placement="top">
                                      <Button
                                          justIcon
                                          round
                                          simple
                                          onClick={() => {
                                              props.getPmaSigningUrl(l.id);
                                              setShowURL(true);
                                          }}
                                          color="primary"
                                      >
                                          <i className="fas fa-edit font-22"></i>
                                      </Button>
                                  </Tooltip>
                              )}
                          {props.tab === "Pending Final Approval" && (
                              <Tooltip title="Resend PMA Link" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          setResend(true);
                                          resendPmaApproverNotification(l.id);
                                      }}
                                      color="primary"
                                  >
                                      <i className="fas fa-envelope-open font-22"></i>
                                  </Button>
                              </Tooltip>
                          )}
                          {props.tab === "Entries" && (
                              <Tooltip title="Edit" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          setPmaData(l);
                                          props.openFormFillWizard("Edit");
                                      }}
                                      color="primary"
                                  >
                                      <Edit />
                                  </Button>
                              </Tooltip>
                          )}

                          {props.tab !== "Entries" && 
                            props.tab !== "Pending Exhibit A" && 
                            props.tab !== "Voided" &&
                              props.tab !== "Pending Final Approval" &&
                              props.tab !== "Completed PMAs" &&
                              l.envelope_id && (
                                  <Tooltip
                                      title="Resend Docusign Envelope"
                                      placement="top"
                                  >
                                      <Button
                                          justIcon
                                          round
                                          simple
                                          onClick={() => {
                                              setResend(true);
                                              props.updatePmaEntry({
                                                  id: l.id,
                                                  resend: true,
                                              });
                                          }}
                                          color="primary"
                                      >
                                          <i className="fas fa-paper-plane font-22"></i>
                                      </Button>
                                  </Tooltip>
                              )}
                            
                            {/********************************** 
                             * Disable Deleting if already sent 
                             * *********************************/}
                          {/* {(props.tab === "Entries" ||
                              (props.tab !== "Completed PMAs" && props.tab !== "Pending Exhibit A" && props.tab !== "Voided" &&
                                  (userPerms.hasOwnProperty(
                                      "PMA approve level_1"
                                  ) ||
                                      userPerms.hasOwnProperty(
                                          "PMA approve level_2"
                                      )))) && (
                              <Tooltip title="Delete" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          setOpenedAskBeforeDelete(l.id);
                                          setOpenedDeleteForm(true);
                                      }}
                                      color="primary"
                                  >
                                      <i className="fas fa-trash font-22"></i>
                                  </Button>
                              </Tooltip>
                          )} */}

                            {(props.tab === "Entries") && (
                              <Tooltip title="Delete" placement="top">
                                  <Button
                                      justIcon
                                      round
                                      simple
                                      onClick={() => {
                                          setOpenedAskBeforeDelete(l.id);
                                          setOpenedDeleteForm(true);
                                      }}
                                      color="primary"
                                  >
                                      <i className="fas fa-trash font-22"></i>
                                  </Button>
                              </Tooltip>
                          )}

                          {(props.tab === 'Pending' || props.tab === 'Pending Final Approval') && 
                            <Tooltip title="Void and Edit" placement="top">
                                <Button
                                    justIcon
                                    round
                                    simple
                                    onClick={() => {
                                        handleVoidAndEdit(l);
                                    }}
                                    color="primary"
                                >
                                    <Edit />
                                </Button>
                            </Tooltip>}
                      </div>
                  ),
              };
          })
        : [];

    const columns =
        props.tab.indexOf("Pending") === -1
            ? [
                {
                    Header: "#",
                    accessor: "id",
                    maxWidth: 50,
                },
                  {
                      Header: "PMA #",
                      accessor: "pma_number",
                      maxWidth: 100,
                  },
                  {
                      Header: "Property Address",
                      accessor: "address",
                      minWidth: 200,
                  },
                  {
                      Header: "Date Created",
                      accessor: "created_date",
                      maxWidth: 100,
                      Cell: (props) => {
                        console.log('propsv', props);
                        return (
                            <div>
                                {moment(props.value, 'YYYY-MM-DD').format("MM/DD/YYYY")}
                            </div>
                        );
                      },
                  },
                  {
                      Header: "Date Modified",
                      accessor: "modified_date",
                      maxWidth: 100,
                      Cell: (props) => {
                        return (
                            <div>
                                {moment(props.value, 'YYYY-MM-DD').format("MM/DD/YYYY")}
                            </div>
                        );
                      },
                  },
                  {
                      Header: "Status",
                      accessor: "status",
                  },
                  {
                      Header: "Actions",
                      accessor: "actions",
                  },
              ]
            : [
                  {
                      Header: "PMA #",
                      accessor: "pma_number",
                      maxWidth: 50,
                  },
                  {
                      Header: "Property Address",
                      accessor: "address",
                      minWidth: 200,
                  },
                  {
                      Header: "Date Created",
                      accessor: "created_datetime",
                      maxWidth: 150,
                      Cell: (props) => {
                        return (
                            <div>
                                {moment(props.value).format("MM/DD/YYYY h:mm a")}
                            </div>
                        );
                      },
                  },
                  {
                    Header: "Date Last Sent",
                    accessor: "date_last_sent",
                    minWidth: 150,
                    Cell: (props) => {
                      return (
                          <div>
                              {moment(props.value).format("MM/DD/YYYY h:mm a")}
                          </div>
                      );
                    },
                  },
                // -----------
                // Test Expiry 
                // Uncomment to view expiry dates
                // -----------
                //   {
                //     Header: "Expiry Date",
                //     accessor: "expiry",
                //     minWidth: 100,
                //     Cell: (props) => {
                //         const style = {};
                //         if (props.value) {
                //             const exp = moment(props.value).unix();
                //             const now = moment().unix();
                //             if (exp < now) style.color = 'red'
                //         }
                //       return (
                //           <div style={style}>
                //               {props.value ? moment(props.value).format("MM/DD/YYYY") : ''}
                //           </div>
                //       );
                //     },
                //   },
                  {
                      Header: "Have Signed",
                      accessor: "signed",
                  },
                  {
                      Header: "Status",
                      accessor: "status",
                  },
                  {
                      Header: "User",
                      accessor: "name",
                  },
                  {
                      Header: "Actions",
                      accessor: "actions",
                      minWidth: 150,
                  },
              ];

    return (
        <div>
            <h3 style={{ display: "inline-block", marginRight: 10 }}>
                {props.tab === "Entries" ? "Drafts" : props.tab}
            </h3>
            <i className="fas fa-sync-alt hoverable" onClick={props.reload}></i>
            {props.loading && <Loader />}
            {!props.loading && (
                <div className="table-adjust23 table-hoverable">
                    <ReactTable
                        filterable
                        className="-striped"
                        showPaginationTop
                        showPaginationBottom
                        resizable={true}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 20, 30]}
                        data={data}
                        columns={columns}
                    />
                </div>
            )}

            {
                <Dialog
                    fullWidth={true}
                    maxWidth={false}
                    open={showURL}
                    onClose={() => {
                        setTimeout(() => {
                            props.reload();
                            setShowURL(false);
                        }, 500);
                    }}
                >
                    <div
                        className="close-btn mt-20 mb-20"
                        onClick={() => {
                            setDelay(true);
                            setTimeout(() => {
                                window.location.href = "/pma?final_approval=s";
                            }, 10000);
                        }}
                    >
                        <Close />
                    </div>
                    {!props.pmaURL && !delay && <div style={{margin:'0 auto'}}>
                            <Loader />
                        </div>}
                    {delay && (
                        <div style={{margin:'0 auto'}}>
                            <Loader />
                            Checking status, please refresh as needed if records have not updated.
                        </div>
                    )}
                    {!delay && (
                        <iframe
                            src={props.pmaURL}
                            style={{
                                width: "100%",
                                height: 900,
                                overflow: "scroll",
                            }}
                        ></iframe>
                    )}
                </Dialog>
            }

            {openedAskBeforeDelete && (
                <Dialog
                    open={openedAskBeforeDelete}
                    onClose={() => {
                        setOpenedAskBeforeDelete(null);
                        setOpenedDeleteForm(false);
                    }}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <div style={{ padding: 40 }} className="text-center">
                        <h4>Are you sure you want to delete this PMA?</h4>
                        <hr />
                        <Button
                            color="success"
                            onClick={() => {
                                deletePmaEntry(openedAskBeforeDelete);
                                setOpenedDeleteForm(false);
                                setOpenedAskBeforeDelete(null);
                            }}
                        >
                            DELETE
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenedAskBeforeDelete(null);
                                setOpenedDeleteForm(false);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}

            {openedVoidAndEditModal && (
                <Dialog
                    open={openedVoidAndEditModal}
                    onClose={() => {
                        setOpenedVoidAndEditModal(false);
                        setPmaToVoid(null);
                    }}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <div style={{ padding: 40 }} className="text-center">
                        <h4>Are you sure you want to Void and Edit this PMA?</h4>
                        <hr />
                        <Button
                            color="success"
                            onClick={() => {
                                voidPmaEntry(pmaToVoid.id);
                                setOpenedVoidAndEditModal(false);
                                setPmaData(pmaToVoid);
                                props.openFormFillWizard("Edit");
                            }}
                        >
                            VOID AND EDIT
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenedVoidAndEditModal(false);
                                setPmaToVoid(null);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}

            {(notification ? true : false) && (
                <Notification
                    open={notification ? true : false}
                    close={() => {
                        if (notification === 'Successfully resent PMA entry.') {
                            props.reload();
                        }
                        setNotification("");
                        clearMessages();
                    }}
                    message={notification}
                />
            )}
        </div>
    );
};

export default PMAList;
