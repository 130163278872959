import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose, combineReducers} from 'redux';
import createSagaMiddleware from 'redux-saga';
import invariant from 'redux-immutable-state-invariant';

import {createBrowserHistory} from 'history';
import {Router, Route, Switch} from 'react-router-dom';
import authReducer from './store/reducers/auth';
import userPermsReducer from './store/reducers/userperms';
import settingsReducer from './store/reducers/settings';
import userReducer from './store/reducers/user';
import crmContactsReducer from './store/reducers/crm_contacts';
import crmCompaniesReducer from './store/reducers/crm_companies';
import crmShowingsReducer from './store/reducers/crm_showings';
import crmPropertiesReducer from './store/reducers/crm_properties';
import ertlTemplatesReducer from './store/reducers/ertl_templates';
import ertlEntriesReducer from './store/reducers/ertl_entries';
import crmAppointmentsReducer from './store/reducers/crm_appointments';
import pmaReducer from './store/reducers/pma';
import docusignReducer from './store/reducers/docusign';
import obReducer from './store/reducers/ob';
import rsReducer from './store/reducers/rs';
import listingReducer from './store/reducers/listing';
import invoiceReducer from './store/reducers/invoice';
import dialpadReducer from './store/reducers/dialpad';
import googleContactsReducer from './store/reducers/google_contacts';
import filesReducer from './store/reducers/files';
import commissionSplitsReducer from './store/reducers/commission_splits';
import trEntriesReducer from './store/reducers/tr_entries';
import trTemplatesReducer from './store/reducers/tr_templates';
import gciReducer from './store/reducers/gci';

import {watchAD} from './store/sagas/';
import indexRoutes from 'routes/index.jsx';
import 'assets/scss/material-dashboard-pro-react.css?v=1.2.0';
import devStatus from 'shared/devStatus';
import ErrorPage from './components/ErrorPage/ErrorPage';

let composeEnhancers = null || compose;

switch (devStatus) {
	case 'live':
	case 'stage':
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
		// composeEnhancers = null || compose;
		break;
	case 'local_stage':
	case 'local_live':
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
		break;
	default:
		composeEnhancers = null || compose;
}

const rootReducer = combineReducers({
	auth: authReducer,
	userPerms: userPermsReducer,
	settings: settingsReducer,
	user: userReducer,
	crm_contacts: crmContactsReducer,
	crm_showings: crmShowingsReducer,
	crm_properties: crmPropertiesReducer,
	crm_appointments: crmAppointmentsReducer,
	crm_companies: crmCompaniesReducer,
	pma: pmaReducer,
	docusign: docusignReducer,
	pma: pmaReducer,
	ertl_templates: ertlTemplatesReducer,
	ertl_entries: ertlEntriesReducer,
	ob: obReducer,
	rs: rsReducer,
	listing: listingReducer,
	invoice: invoiceReducer,
	dialpad: dialpadReducer,
	googleContacts: googleContactsReducer,
	files: filesReducer,
	commission_splits: commissionSplitsReducer,
	tr_entries: trEntriesReducer,
	tr_templates: trTemplatesReducer,
	gci: gciReducer
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(invariant(), sagaMiddleware)));

sagaMiddleware.run(watchAD);

const hist = createBrowserHistory();
const app = (
<ErrorPage>
	<Router history={hist}>
		<Switch>
			{indexRoutes.map((prop, key) => {
				return <Route path={prop.path} component={prop.component} key={key} />;
			})}
		</Switch>
	</Router>
</ErrorPage>
);
ReactDOM.render(<Provider store={store}>{app}</Provider>, document.getElementById('root'));
