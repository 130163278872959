import jwt_decode from 'jwt-decode';

const isUserLoggedIn = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    let totalSeconds = Math.round(((currentTime - decoded.exp)));
    totalSeconds = -totalSeconds > 0 ? -totalSeconds : totalSeconds;

    let hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    let minutes = Math.floor(totalSeconds / 60);
    let seconds = totalSeconds % 60;

    // If you want strings with leading zeroes:
    minutes = String(minutes).padStart(2, "0");
    hours = String(hours).padStart(2, "0");
    seconds = String(seconds).padStart(2, "0");

    if (decoded.exp < currentTime) {
      localStorage.removeItem('token');
      window.location.href = '/pages/login-page';
      return false;
    }

    let sections = [];
    Object.keys(decoded.permObj).forEach((p) => {
      const section = p.split(' ')[0];
      if (sections.indexOf(section) === -1) {
        sections.push(section);
      }
    });
    return sections;
  }
}

export const getUserID = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    
    return false;
  } else if (token) {
    const decoded = jwt_decode(token);
    // if employee (E) user id will be 1
    return decoded.type === 'E' ? 1 : decoded.id;
  }
}

export const getUser = (permissionStr) => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  } else if (token) {
    const user = jwt_decode(token);
    if (permissionStr) {
      console.log('getUser', user.permObj);
      const hasPermission = user ? 
        user.permObj ? 
                user.permObj.hasOwnProperty(permissionStr) ? 
                    true 
                : 
                    false 
            : 
                false
        :
            false;
      return hasPermission;
    }
    return user;
  }
}

export default isUserLoggedIn;
