import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import ContactsForm from 'containers/CRM/Contacts/ContactsForm';

const PropertiesNewContact = (props) => {
    const [success, setSuccess] = useState('');
    const [error, setError] = useState('');

    const clearMessages = () => {
        setSuccess('');
        setError('');
    }

    useEffect(() => {
        if (props.success) {
            setSuccess(props.success);
        }
        if (props.error) {
            setError(props.error);
        }
    }, [props.success, props.error]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
      }

    const prev_success_data = usePrevious(props.success_data);

    useEffect(() => {
        if (prev_success_data === null && props.success_data) {
            props.create_and_choose(props.success_data);
            props.close();
        }
    }, [props.success_data]);

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth={false}
        fullWidth={true}
    >

        <div style={{padding:20}}>
            <div style={{margin:'0 auto',maxWidth:1400}}>
                <ContactsForm
                    action="Add"
                    closeForm={props.close}
                    contactData={null}
                    contactOptions={props.contactOptions}
                    createContact={props.createContact}
                    partnersData={props.partnersData}
                    getPartnersData={props.getPartnersData}
                    success={success}
                    error={error}
                    clearMessages={clearMessages}
                    peopleType={props.peopleType}
                    getContacts={props.getContacts}
                    prefill={props.prefill}
                    showCompanyAssigned={props.showCompanyAssigned}
                    fromCustomDialogs={props.fromCustomDialogs}
                />
            </div>
            <div className="text-center">
                <Button
                    color="primary"
                    className="outlined-btn"
                    style={{marginRight:10}}
                    onClick={() => {
                        props.close();
                    }}
                >
                    CLOSE CREATE CONTACT FORM
                </Button>
            </div>
        </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    return {
        error: state.crm_contacts.error,
        success: state.crm_contacts.success,
        contactsData: state.crm_contacts.contactsData,
        contactOptions: state.crm_contacts.contactOptions,
        contactsCount: state.crm_contacts.contactsCount,
        contactData: state.crm_contacts.contactData,
        addressData: state.crm_contacts.addressData,
        partnersData: state.crm_contacts.partnersData,
        contactShares: state.crm_contacts.contactShares,
        contactUsernames: state.crm_contacts.contactUsernames,
        success_data: state.crm_contacts.success_data
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      },
      createContact: (data, partnersData, addressData, appointmentsData, sharesData, 
        formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd) => {
        dispatch(actions.createContact(data, partnersData, addressData, appointmentsData, sharesData, 
          formDataObj, message, companiesForAdd, propertiesForAdd, entitiesData, dbasForAdd));
      },
      updateContact: (data, partnersData, addressData) => {
        dispatch(actions.updateContact(data, partnersData, addressData));
      },
      deleteContact: (id) => {
        dispatch(actions.deleteContact(id));
      },
      getAddresses: (contact_id) => {
        dispatch(actions.getAddresses(contact_id));
      },
      getPartnersData: (contact_id) => {
        dispatch(actions.getPartners(contact_id));
      },
      getContactOptions: () => {
        dispatch(actions.getContactOptions());
      },
      getContactShares: (contact_id) => {
        dispatch(actions.getContactShares(contact_id));
      },
      getContactUsernames: () => {
        dispatch(actions.getContactUsernames());
      },
      createContactShare: (data) => {
        dispatch(actions.createContactShare(data));
      },
      deleteContactShare: (share_id) => {
        dispatch(actions.deleteContactShare(share_id));
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(PropertiesNewContact);