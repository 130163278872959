import React, { useState, useEffect } from "react";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import Button from "components/CustomButtons/Button";
import Step1 from "./Steps/Step1";
import Step2 from "./Steps/Step2";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { textData } from "./ListingObj";
import Step4 from "./Steps/Step4";
import Step3 from "./Steps/Step3";
import { generateSections } from "./ListingLogic";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import Step5 from "./Steps/Step5";

const ListingWizard = (props) => {
    const {
        agentList,
        setAgentList,
        ertl,
        setErtl,
        property,
        setProperty,
        setTab,
        formData,
        setFormData,
        updateDataObj,
        setAllowToSubmit,
        listingHistory,
        fieldModels,
        currForm,
        setCurrForm,
    } = props;

    const sections = generateSections(formData, setAllowToSubmit, fieldModels);

    const renderNav = () => {
        return (
            <div style={{ marginBottom: 10 }}>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        setCurrForm(currForm - 1);
                    }}
                    disabled={currForm === 1 ? true : false}
                >
                    <ArrowBack /> Previous
                </Button>
                <Button
                    className="mr-20"
                    onClick={() => {
                        if (currForm !== 5) return setCurrForm(currForm + 1);
                        setTab("full");
                    }}
                >
                    Next <ArrowForward />
                </Button>
                {props.activity && (
                    <Button
                        color="white"
                        onClick={() => {
                            props.setOpenLoading(true);
                            props.saveDraft();
                        }}
                    >
                        <i className="fas fa-save"></i> Save Draft
                    </Button>
                )}
            </div>
        );
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={8} sm={8} md={8}>
                    {renderNav()}
                    {currForm === 2 && (
                        <Step1
                            listingHistory={listingHistory}
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            fieldModels={fieldModels}
                        />
                    )}
                    {currForm === 1 && (
                        <Step2
                            ertl={ertl}
                            setErtl={setErtl}
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            fieldModels={fieldModels}
                            setCurrForm={setCurrForm}
                        />
                    )}
                    {currForm === 3 && (
                        <Step3
                            property={property}
                            setProperty={setProperty}
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            fieldModels={fieldModels}
                            prefill_property_id={props.prefill_property_id}
                            reset_prefill_property_id={props.reset_prefill_property_id}
                        />
                    )}
                    {currForm === 4 && (
                        <Step4
                            agentList={agentList}
                            setAgentList={setAgentList}
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            fieldModels={fieldModels}
                        />
                    )}
                    {currForm === 5 && (
                        <Step5
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            fieldModels={fieldModels}
                            agentList={agentList}
                        />
                    )}
                    {renderNav()}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {/* Tracker */}
                    <List component="nav">
                        {sections.map((section, index) => {
                            return (
                                <ListItem
                                    key={`li-${index}`}
                                    button
                                    onClick={() => {
                                        setCurrForm(index + 1);
                                    }}
                                >
                                    <ListItemIcon>
                                        {section.complete ? (
                                            <i
                                                className={`fas fa-check font-22 ${
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }`}
                                            ></i>
                                        ) : (
                                            <i
                                                className={`fas fa-times font-22 ${
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }`}
                                            ></i>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            <div
                                                className={
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }
                                            >
                                                {section.name}
                                            </div>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default ListingWizard;
