import React, { useState, useEffect, useRef } from 'react';
import propertyFields from './PropertiesObj';
import { importerFields } from './PropertiesObj';
import {
    min1, min2, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
const validate = {
    min1, min2, min6, min4, min5, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}
const getValidations = (f, val) => {
    if (!propertyFields[f]) return 
    if (!propertyFields[f].validations) return null;
    const validations = propertyFields[f].validations.map(v => {
        return validate[v];
    });
    const errors = validations.map(v => {
        const result = v(val);
        if (result) return result;
        return null;
    }).filter(v => {
        if (!v) return false;
        return true;
    });
    if (errors.length > 0) return errors.join(', ');
    return '';
}

const arrayHasNumber = (arr) => {
    let hasNumber = false;
    arr.forEach(a => {
        if (a) {
            if (!isNaN(a)) {
                hasNumber = true;
            }
        }
    })
    return hasNumber
}

export const getErrors = (dataObj, others, theContactData) => {
    const { leasesForAdd, listingsForAdd, meetingsForAdd, offersForAdd, showingsForAdd } = others;
    
    
    let errors = {};
    Object.keys(propertyFields).forEach(field => {
        if (propertyFields[field].validations) {
            const hasError = getValidations(field, dataObj[field]);
            if (hasError) errors[field] = hasError;
        }
    })

    // prevent submission if Street1 has "-" e.g. "123 - 125 Astor Drive" 
    if (dataObj.street1) {
        const street1_arr1 = dataObj.street1.split('-');
        const street1_arr2 = dataObj.street1.split('- ');
        const street1_arr3 = dataObj.street1.split(' - ');
        const error_message = 'Address should not have a dash with numbers before and/or after it (Signifying Address Range)';
        if (street1_arr1.length > 0) {
            if (arrayHasNumber(street1_arr1)) errors.street1 = error_message;
        }
        if (street1_arr2.length > 0) {
            if (arrayHasNumber(street1_arr2)) errors.street1 = error_message;
        }
        if (street1_arr3.length > 0) {
            if (arrayHasNumber(street1_arr3)) errors.street1 = error_message;
        }
    }
    // prevent submission if Street2 has "-" e.g. "123 - 125 Astor Drive" 
    if (dataObj.street2) {
        const street2_arr1 = dataObj.street2.split('-');
        const street2_arr2 = dataObj.street2.split('- ');
        const street2_arr3 = dataObj.street2.split(' - ');
        const error_message = 'Address should not have a dash with numbers before and/or after it (Signifying Address Range)';
        if (street2_arr1.length > 0) {
            if (arrayHasNumber(street2_arr1)) errors.street2 = error_message;
        }
        if (street2_arr2.length > 0) {
            if (arrayHasNumber(street2_arr2)) errors.street2 = error_message;
        }
        if (street2_arr3.length > 0) {
            if (arrayHasNumber(street2_arr3)) errors.street2 = error_message;
        }
    }
    // require address range if is_address_range === 'Yes'
    if (dataObj.address_is_range === 'Yes') {
        if (!dataObj.street_range1 || !dataObj.street_range2) {
            errors.street1 = 'Street Ranges are required if "Is Address Range" is set to Yes';
        }
        if (dataObj.street_range1 && dataObj.street_range2) {
            const r1 = dataObj.street_range1;
            const r2 = dataObj.street_range2;
            if (isNaN(r1) || isNaN(r2)) {
                errors.street1 = 'Street Ranges must be numbers only, whitespace/blanks are not permitted.';
            }
        }
    }
    
    if (Object.keys(errors).length > 0) return errors;
    return null;
}

export const useSaveProperties = (props) => {
    const { madeChanges, dataObj, action, updateProperty, theContactData,
    leasesForAdd, listingsForAdd, meetingsForAdd, offersForAdd, showingsForAdd,
    isAutoSave, setIsAutoSave, success, setMadeChanges } = props;

    const [errorData, setErrorData] = useState(null);

    const others = {
        leasesForAdd, listingsForAdd, meetingsForAdd, offersForAdd, showingsForAdd
    };

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (success && prevSuccess !== success) {
            if (success.indexOf('Success') !== -1) {
                setIsAutoSave(false);
            }
        }
    }, [success]);

    const TIME = 300000;

    useEffect(() => {
        const interval = setInterval(() => {
            if(madeChanges) {
                const errors = getErrors(dataObj, others, theContactData);
                setErrorData(errors);
                if (!errors) {
                    const newObj = { ...dataObj };
                    if (newObj.illi_represented === '1') {
                        newObj.illi_represented = 1;
                    } else if (newObj.illi_represented === '0') {
                        newObj.illi_represented = 0;
                    } else {
                        delete newObj.illi_represented;
                    }
                    if (action === 'Edit') {
                        setIsAutoSave(true);
                        updateProperty(newObj);
                        setMadeChanges(false);
                    }
                }
            }
        }, TIME);
        return () => clearInterval(interval);
    }, [madeChanges, dataObj]);
}

const checkProperty = (existingData, newData) => {
	let exists = false;
	let different = false;
	if (newData && existingData) {
		exists = true;
		if (newData != existingData) {
			different = true;
		}
	}
	return {
		exists, different
	}
}

export const fieldsForAnalyzing = [
    'illi_number',
    'pm_number',
    'name',
    'street_range1',
    'street_range2',
    'street1',

    'street2_range1',
    'street2_range2',
    'street2',
    'city',
    'state',
    'zip',

    'use_type',
    'total_building_sqft',
    'property_is_for',
];

export const createAfterProperty = (d) => {
    const exists = (dat) => {
        return dat ? dat : '';
    }
    const fields = fieldsForAnalyzing;
    const after_property = {};
    fields.forEach(field => {
        after_property[field] = exists(d[importerFields.indexOf(field)]);
    });
    return after_property;
}

export const analyzeDiffs = (existing_property, after_property) => {
    let existing_fields = [];
    let different_fields = [];
    const fields = fieldsForAnalyzing;
    for (let i = 0; i < fields.length; i++) {
        const field = fields[i];
        const check = checkProperty(existing_property[field], after_property[field]);
        if (check.different) different_fields.push({
            field,
            value: after_property[field]
        });
        if (check.exists) existing_fields.push({
            field,
            value: existing_property[field]
        });
    }
    return {
        existing_fields,
        different_fields
    }
}

export const analyzeImportData = (checks, after_property) => {
    let hasIssues = false;
    if (checks.found_properties) {
        if (checks.found_properties.length > 0) {
            hasIssues = true;
            // if (checks.found_properties.length > 0) {
            //     // filter found properties
            //     hasIssues = true;
            // } else {
            //     const existing_property = checks.found_properties[0];
            //     // analyze if there are differences
            //     const analyzed = analyzeDiffs(existing_property, after_property);
            //     if (analyzed.different_fields.length > 0) {
            //         hasIssues = true;
            //     }
            // }
        }
    }
    return hasIssues;
}

export const createAddressObject = (property) => {
    // used to create a normal address1, address2, city, state, zip object
    const obj =  {
        name: property.name ? property.name : '',
        street1: property.street1 ? property.street1 : '',
        street2: property.street2 ? property.street2 : '',
        city: property.city ? property.city : '',
        state: property.state ? property.state : '',
        zip: property.zip ? property.zip : ''
    };
    if (property.address_is_range === 'Yes') {
        const range = (property.street_range1 && property.street_range2) ? 
            `${property.street_range1}-${property.street_range2}` : ``;
        obj.street1 = `${range ? range + ' ' : ''}${property.street1}`;
    }
    if (property.address2_is_range === 'Yes') {
        const range = (property.street2_range1 && property.street2_range2) ? 
            `${property.street2_range1}-${property.street2_range2}` : ``;
        obj.street2 = `${range ? range + ' ' : ''}${property.street2}`;
    }
    return obj;
}

export const getPropertyPermissions = (propertyShares, dataObj, user_id, action) => {
    console.log('pp->>', propertyShares, dataObj, user_id, action);
	if (user_id === dataObj.user_id) return true;
	let result = false;
	if (propertyShares) {
		if (propertyShares.length > 0) {
			for (let i = 0; i < propertyShares.length; i++) {
				const cs = propertyShares[i];
				if (cs.user_id === user_id) {
					if (cs.access === "F") {
						if (action === "delete") return true;
						if (action === "edit") return true;
					}
					if (cs.access === "E") {
						if (action === "edit") return true;
					}
				}
			}
		}
	}
	return result;
};
