import React, { useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { getRsheetLadderOptions } from "./RSheetsLogic";
import { connect } from "react-redux";
import { get } from "lodash";

const RSheetsDenyModal = (props) => {
    const rsheetPerms = getRsheetLadderOptions();
    console.log('RSheetsDenyModal', props, rsheetPerms);
    const options = rsheetPerms.denials.filter(d => {
        // all Deny to Submitter is available
        if (d.status_to_change_to === 5) return true;
        const current_status = get(props, 'rsheetData.status', 0);
        if (rsheetPerms.denyObj[current_status]) {
            if (rsheetPerms.denyObj[current_status].indexOf(d.status_to_change_to) !== -1) {
                return true;
            }
        }
    });
    const [status, setStatus] = useState(options.length === 1 ? 5 : '');
    return <>
        <Dialog 
            open={props.open} 
            keepMounted 
            onClose={props.close} 
            maxWidth='sm' 
            fullWidth={true}
        >
            <DialogTitle>DENY ROUTING SHEET</DialogTitle>
            <div className="dialog-wrapper" style={{marginTop:0}}>
                <div style={{padding:20}}>
                    <CustomSelect 
                        options={options}
                        indx="status_to_change_to"
                        desc="description"
                        choose={(status_to_change_to) => {
                            setStatus(status_to_change_to);
                        }}
                        default={status}
                        label="Select one"
                    />
                </div>
                <div className="text-center">
                    <Button 
                        disabled={!status ? true : false}
                        color="primary"
                        onClick={() => {
                            props.choose(status);
                            props.close();
                        }}
                    >SUBMIT ROUTING SHEET DENIAL</Button>
                    <Button 
                        color="white"
                        onClick={() => {
                            props.close();
                        }}
                    >CANCEL</Button>
                </div>
            </div>
        </Dialog>
    </>
}

const mapStateToProps = (state) => {
    return {
        rsheetData: state.rs.rsheetData
    };
};

export default connect(mapStateToProps, null)(RSheetsDenyModal);