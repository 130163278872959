import CustomDialogChooser from "components/CustomDialog/CustomDialogChooser";
import React, { useState } from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";

import Button from "../../../components/CustomButtons/Button";
import { clone, get } from "lodash";

const Step3 = (props) => {
    const { renderElement, data, setData } = props;

    const handleChoose = (chosen) => {
        console.log('chosen', chosen);
        
        closeModal();
    };

    console.log('Step3', data);

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {!data.escrow_contact_id && <Button color="primary" onClick={() => {
                        props.setQueryFor('escrow');
                        props.setOpenedContactModal(true);
                    }}>
                        SELECT ESCROW CONTACT
                    </Button>}
                    {data.escrow_contact_id && <Button color="success" onClick={() => {
                        const newData = clone(data);
                        newData.escrow_contact_id = '';
                        newData.escrow_address = '';
                        newData.escrow_address_2 = '';
                        newData.escrow_city = '';
                        newData.escrow_company = '';
                        newData.escrow_email = '';
                        newData.escrow_officer_name = '';
                        newData.escrow_phone = '';
                        newData.escrow_state = '';
                        newData.escrow_zip = '';
                        setData(newData);
                    }}>
                        REMOVE ESCROW CONTACT
                    </Button>}
                </GridItem>
            </GridContainer>
            {data.escrow_contact_id && <GridContainer>
                {/* Escrow Information */}
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_officer_name")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_company")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_address")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_address_2")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_city")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_state")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_zip")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_phone")}
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {renderElement("escrow_email")}
                </GridItem>
            </GridContainer>}
        </div>
    );
};

export default Step3;
