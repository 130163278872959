import * as actionTypes from './actionTypes';

export const getTemplates = () => {
    return {
        type: actionTypes.GET_TEMPLATES
    }
}

export const getTemplatesStart = () => {
    return {
        type: actionTypes.GET_TEMPLATES_START
    }
}

export const getTemplatesSuccess = (data) => {
    return {
        type: actionTypes.GET_TEMPLATES_SUCCESS,
        data
    }
}

export const getTemplatesFail = (err) => {
    return {
        type: actionTypes.GET_TEMPLATES_FAIL,
        err
    }
}

// GET_TEMPLATE 

export const getTemplate = (data) => {
    return {
        type: actionTypes.GET_TEMPLATE,
        data
    }
}

export const getTemplateStart = () => {
    return {
        type: actionTypes.GET_TEMPLATE_START
    }
}

export const getTemplateSuccess = (response) => {
    return {
        type: actionTypes.GET_TEMPLATE_SUCCESS,
        response
    }
}

export const getTemplateFail = (err) => {
    return {
        type: actionTypes.GET_TEMPLATE_FAIL,
        err
    }
}

export const createTemplate = (data, title) => {
    return {
        type: actionTypes.CREATE_TEMPLATE,
        data,
        title
    }
}

export const createTemplateStart = () => {
    return {
        type: actionTypes.CREATE_TEMPLATE_START
    }
}

export const createTemplateSuccess = (response) => {
    return {
        type: actionTypes.CREATE_TEMPLATE_SUCCESS,
        response
    }
}

export const createTemplateFail = (err) => {
    return {
        type: actionTypes.CREATE_TEMPLATE_FAIL,
        err
    }
}

export const deleteTemplate = (id) => {
    return {
        type: actionTypes.DELETE_TEMPLATE,
        id
    }
}

export const deleteTemplateStart = () => {
    return {
        type: actionTypes.DELETE_TEMPLATE_START
    }
}

export const deleteTemplateSuccess = (data) => {
    return {
        type: actionTypes.DELETE_TEMPLATE_SUCCESS,
        data
    }
}

export const deleteTemplateFail = (err) => {
    return {
        type: actionTypes.DELETE_TEMPLATE_FAIL,
        err
    }
}

// UPDATE_ERTL_TEMPLATE 

export const updateErtlTemplate = (data) => {
    return {
        type: actionTypes.UPDATE_ERTL_TEMPLATE,
        data
    }
}

export const updateErtlTemplateStart = () => {
    return {
        type: actionTypes.UPDATE_ERTL_TEMPLATE_START
    }
}

export const updateErtlTemplateSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_ERTL_TEMPLATE_SUCCESS,
        response
    }
}

export const updateErtlTemplateFail = (err) => {
    return {
        type: actionTypes.UPDATE_ERTL_TEMPLATE_FAIL,
        err
    }
}
