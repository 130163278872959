import React, { useState, useEffect } from 'react';
import Button from '../../../components/CustomButtons/Button';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import { sectionNames, generateSections, dontSaveArr } from './TREntriesLogic';
import { ListItem, List, ListItemIcon, ListItemText } from '@material-ui/core';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';

const TREntriesFormWizard = props => {
    const { step, setStep, dataObj, setDataObj, updateDataObj, listTab } = props;
    const dontSave = dontSaveArr.indexOf(listTab) !== -1 ? true : false;
    const renderNav = () => {
        const section_count = Object.keys(sectionNames).length;
        return (
            <div style={{ marginBottom: 10 }}>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        props.save();
                        setStep(step - 1);
                    }}
                    disabled={step === 1 ? true : false}
                >
                    <ArrowBack /> Previous
                </Button>
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        props.save();
                        if (step < section_count) return setStep(step + 1);
                        props.setTab("full");
                    }}
                >
                    Next <ArrowForward />
                </Button>

                {!dontSave && (
                    <Button
                        color="white"
                        className="mr-20"
                        onClick={() => {
                            props.save();
                        }}
                    >
                        SAVE
                    </Button>
                )}
            </div>
        );
    };

    const sections = generateSections(props.dataObj);
    const finalActions = ["SUBMIT", "APPROVE / REJECT"];
    return <div>
    <GridContainer>
        <GridItem xs={12} sm={9} md={9}>
            <h3>
                <span className="font-10">
                    {step} of {Object.keys(sectionNames).length}
                </span>{" "}
                {sectionNames[step]}
            </h3>
            {renderNav()}
            <hr />
            {step === 1 && <Step1 
                dataObj={dataObj}
                renderElement={props.renderElement}
                setOpenedPropertyModal={props.setOpenedPropertyModal}
            />}
            {step === 2 && <Step2
                dataObj={dataObj}
                updateDataObj={updateDataObj}
                renderElement={props.renderElement}
                setOpenedChooseModal={props.setOpenedChooseModal}
                openedChooseModal={props.openedChooseModal}
                viewOnly={props.viewOnly}
            />}
            {step === 3 && <Step3 
                dataObj={dataObj}
                renderElement={props.renderElement}
                setDataObj={setDataObj}
                final_approvers={props.final_approvers}
                viewOnly={props.viewOnly}
            />}
            {renderNav()}
        </GridItem>
        <GridItem xs={12} sm={3} md={3}>
            {/* Tracker */}
            <List component="nav">
                {sections.map((s, i) => {
                    const name = s.name;
                    return (
                        <ListItem
                            key={`li-${i}`}
                            button
                            onClick={() => {
                                setStep(i + 1);
                            }}
                        >
                            {finalActions.indexOf(s.name) === -1 && (
                                <ListItemIcon>
                                    {s.complete ? (
                                        <i
                                            className={`fas fa-check font-22 ${
                                                step - 1 === i ? "bold red-text" : ""
                                            }`}
                                        ></i>
                                    ) : (
                                        <i
                                            className={`fas fa-times font-22 ${
                                                step - 1 === i ? "bold red-text" : ""
                                            }`}
                                        ></i>
                                    )}
                                </ListItemIcon>
                            )}
                            <ListItemText
                                inset
                                primary={<div className={step - 1 === i ? "bold red-text" : ""}>{name}</div>}
                            />
                        </ListItem>
                    );
                })}
            </List>
        </GridItem>
    </GridContainer>
</div>
}

export default TREntriesFormWizard;