import { put } from 'redux-saga/effects';
import axios from '../axios/axios-ob';
import * as actions from '../actions/ob';

export function* getOnboardingsSaga(action) {
    yield put(actions.getOnboardingsStart());
    try {
        const response = yield axios.get('/onboardings');
        yield put(actions.getOnboardingsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getOnboardingsFail(e.response.data));
            }
          }
        yield put(actions.getOnboardingsFail(e));
    }
}

export function* createNteEntrySaga(action) {
  yield put(actions.createNteEntryStart());
  try {
      const response = yield axios.post('/notice_to_employee_entry', action.data);
      yield put(actions.createNteEntrySuccess(response.data));
      if (response) {
        if (response.data) {
          
        }
      }
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createNteEntryFail(e.response.data));
          }
        }
      yield put(actions.createNteEntryFail(e));
  }
}

export function* updateOnboardingEntrySaga(action) {
  yield put(actions.updateOnboardingEntryStart());
  try {
      const response = yield axios.put('/onboarding/', action.data);
      yield put(actions.updateOnboardingEntrySuccess(response.data));
      if(response) {
        if(response.data) {
          
        }
      }
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateOnboardingEntryFail(e.response.data));
          }
        }
      yield put(actions.updateOnboardingEntryFail(e));
  }
}

export function* getObPdfSaga(action) {
  yield put(actions.getObPdfStart());
  try {
    const response = yield axios.post(`/onboarding/pdf`, action.data);
      yield put(actions.getObPdfSuccess(response));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getObPdfFail(e.response.data));
          }
        }
      yield put(actions.getObPdfFail(e));
  }
}

export function* getObWorkspaceUserSaga(action) {
  yield put(actions.getObWorkspaceUserStart());
  try {
    const response = yield axios.get('/notice_to_employee_entries/workspace_user/?username='+action.data);
      yield put(actions.getObWorkspaceUserSuccess(response));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getObWorkspaceUserFail(e.response.data));
          }
        }
      yield put(actions.getObWorkspaceUserFail(e));
  }
}