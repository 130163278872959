import React, { Component } from "react";
import { connect } from 'react-redux';
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";
import setAuthToken from 'shared/setAuthToken';
import { logoutUser, clearUsername } from 'store/actions/user';
import UserLogout from '../containers/UserAdmin/UserLogout';

class Logout extends Component {
  constructor() {
    super();
  }

  onLogoutSuccess = (e) => {
    
  }

  componentDidMount() {
    // localStorage.removeItem('token');
    // localStorage.removeItem('G_AUTHUSER_H');
    // localStorage.removeItem('G_ENABLED_IDPS');
    // const { signOut, loaded } = useGoogleLogout({
    //   clientId: googleClientID,
    //   onLogoutSuccess: this.onLogoutSuccess
    // })
    // signOut();
    // // remove auth header for future request
    // setAuthToken(false);
    // this.props.clearUsername();
    // this.props.logoutUser();
  }

  render() {
    return (
      <UserLogout 
        setAuthToken={setAuthToken}
        clearUsername={this.props.clearUsername}
        logoutUser={this.props.logoutUser}
      />
    )
  }
}

const mapStateToProps = (state) => {
  return {
    origin: state.auth.origin
  }
}

export default connect(mapStateToProps, { logoutUser, clearUsername })(Logout);
