import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
    error: null,
    success: null,
    loading: false,
    entriesData: [],
    entryData: null,
    pdfData: [],
    historyData: [],
    historyPDF: null,
    approver: [],
    signing_url: null,
    template: null,
    templates: [],
    file: null,
};

const clearPmaMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

// GET_PMA_ENTRIES

const getPmaEntriesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        entriesData: [],
        error: null,
        success: null,
    });
};

const getPmaEntriesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: "Successfully got Entries",
        entriesData: action.response,
    });
};

const getPmaEntriesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
    });
};

// GET_PMA_ENTRY

const getPmaEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getPmaEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten PMA entry",
        entryData: action.response,
    });
};

const getPmaEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// CREATE_PMA_ENTRY

const createPmaEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const createPmaEntrySuccess = (state, action) => {
    const obj = {
        success: action.response,
        error: null,
    };
    if (action.pmaData) {
        obj.entryData = action.pmaData;
    } 
    return updateObject(state, obj);
};

const createPmaEntryFail = (state, action) => {
    let error = null;
    if (action.err === "Invalid Email.") {
        error = action.err;
    }
    return updateObject(state, {
        error: error || "Failed to create PMA entry.",
        success: null,
    });
};

// UPDATE_PMA_ENTRY

const updatePmaEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const updatePmaEntrySuccess = (state, action) => {
    if (action.successType) {
        const obj = {
            success: "Successfully resent PMA entry.",
            error: null,
        };
        if (action.pmaData) {
            obj.entryData = action.pmaData;
        } 
        return updateObject(state, obj);
    }
    const obj = {
        success: action.response,
        error: null,
    }
    if (action.pmaData) {
        obj.entryData = action.pmaData;
    } 
    return updateObject(state, obj);
};

const updatePmaEntryFail = (state, action) => {
    return updateObject(state, {
        error: "Failed to update PMA Entry.",
        success: null,
    });
};

// DELETE_PMA_ENTRY

const deletePmaEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true,
    });
};

const deletePmaEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully deleted PMA",
        loading: false,
    });
};

const deletePmaEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
    });
};

// GET_PMA_PDF

const getPmaPdfStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        pdfData: [],
        error: null,
        success: null,
    });
};

const getPmaPdfSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: "Successfully got entry pdf",
        pdfData: action.response.data,
    });
};

const getPmaPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

const getPmaHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true,
    });
};

const getPmaHistorySuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully got PMA History",
        historyData: action.response,
        loading: false,
    });
};

const getPmaHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
    });
};

// GET_PMA_APPROVERS

const getPmaApproversStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getPmaApproversSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten PMA approvers.",
        approvers: action.response,
    });
};

const getPmaApproversFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_PMA_SIGNING_URL

const getPmaSigningUrlStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        signing_url: null,
    });
};

const getPmaSigningUrlSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten signing URL",
        signing_url: action.response ? action.response.url : "",
    });
};

const getPmaSigningUrlFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_PMA_HISTORY_PDF

const getPmaHistoryPdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getPmaHistoryPdfSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten PMA pdf",
        historyPDF: action.response,
    });
};

const getPmaHistoryPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_PMA_TEMPLATES

const getPmaTemplatesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getPmaTemplatesSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten PMA templates",
        templates: action.response,
    });
};

const getPmaTemplatesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_PMA_TEMPLATE

const getPmaTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const getPmaTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully gotten template",
        template: action.response,
    });
};

const getPmaTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// CREATE_PMA_TEMPLATE

const createPmaTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const createPmaTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully created template",
    });
};

const createPmaTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// UPDATE_PMA_TEMPLATE

const updatePmaTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const updatePmaTemplateSuccess = (state, action) => {
    const obj = {
        success: "Successfully updated template",
    };
    if (action.no_message)
        obj.success = "Successfully updated template, (no alert)";
    return updateObject(state, obj);
};

const updatePmaTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// DELETE_PMA_TEMPLATE

const deletePmaTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
    });
};

const deletePmaTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: "Successfully deleted template",
    });
};

const deletePmaTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// RESEND_PMA_APPROVER_NOTIFICATION

const resendPmaApproverNotificationStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        resendPmaApproverNotificationMessage: "",
    });
};

const resendPmaApproverNotificationSuccess = (state, action) => {
    return updateObject(state, {
        success: "Success",
        resendPmaApproverNotificationMessage: "Successfully resent PMA link",
    });
};

const resendPmaApproverNotificationFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// GET_PMA_FILE

const getPmaFileStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        file: null,
    });
};

const getPmaFileSuccess = (state, action) => {
    return updateObject(state, {
        success: "Success",
        file: action.response,
    });
};

const getPmaFileFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
    });
};

// VOID_PMA_ENTRY

const voidPmaEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const voidPmaEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const voidPmaEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CLEAR_PMA_MESSAGES:
            return clearPmaMessages(state, action);
        case actionTypes.GET_PMA_ENTRIES_START:
            return getPmaEntriesStart(state, action);
        case actionTypes.GET_PMA_ENTRIES_SUCCESS:
            return getPmaEntriesSuccess(state, action);
        case actionTypes.GET_PMA_ENTRIES_FAIL:
            return getPmaEntriesFail(state, action);
        case actionTypes.CREATE_PMA_ENTRY_START:
            return createPmaEntryStart(state, action);
        case actionTypes.CREATE_PMA_ENTRY_SUCCESS:
            return createPmaEntrySuccess(state, action);
        case actionTypes.CREATE_PMA_ENTRY_FAIL:
            return createPmaEntryFail(state, action);
        case actionTypes.UPDATE_PMA_ENTRY_START:
            return updatePmaEntryStart(state, action);
        case actionTypes.UPDATE_PMA_ENTRY_SUCCESS:
            return updatePmaEntrySuccess(state, action);

        case actionTypes.DELETE_PMA_ENTRY_START:
            return deletePmaEntryStart(state, action);
        case actionTypes.DELETE_PMA_ENTRY_SUCCESS:
            return deletePmaEntrySuccess(state, action);
        case actionTypes.DELETE_PMA_ENTRY_FAIL:
            return deletePmaEntryFail(state, action);

        case actionTypes.UPDATE_PMA_ENTRY_FAIL:
            return updatePmaEntryFail(state, action);
        case actionTypes.GET_PMA_PDF_START:
            return getPmaPdfStart(state, action);
        case actionTypes.GET_PMA_PDF_SUCCESS:
            return getPmaPdfSuccess(state, action);
        case actionTypes.GET_PMA_PDF_FAIL:
            return getPmaPdfFail(state, action);

        case actionTypes.GET_PMA_HISTORY_START:
            return getPmaHistoryStart(state, action);
        case actionTypes.GET_PMA_HISTORY_SUCCESS:
            return getPmaHistorySuccess(state, action);
        case actionTypes.GET_PMA_HISTORY_FAIL:
            return getPmaHistoryFail(state, action);

        case actionTypes.GET_PMA_ENTRY_START:
            return getPmaEntryStart(state, action);
        case actionTypes.GET_PMA_ENTRY_SUCCESS:
            return getPmaEntrySuccess(state, action);
        case actionTypes.GET_PMA_ENTRY_FAIL:
            return getPmaEntryFail(state, action);

        case actionTypes.GET_PMA_APPROVERS_START:
            return getPmaApproversStart(state, action);
        case actionTypes.GET_PMA_APPROVERS_SUCCESS:
            return getPmaApproversSuccess(state, action);
        case actionTypes.GET_PMA_APPROVERS_FAIL:
            return getPmaApproversFail(state, action);

        case actionTypes.GET_PMA_SIGNING_URL_START:
            return getPmaSigningUrlStart(state, action);
        case actionTypes.GET_PMA_SIGNING_URL_SUCCESS:
            return getPmaSigningUrlSuccess(state, action);
        case actionTypes.GET_PMA_SIGNING_URL_FAIL:
            return getPmaSigningUrlFail(state, action);

        case actionTypes.GET_PMA_HISTORY_PDF_START:
            return getPmaHistoryPdfStart(state, action);
        case actionTypes.GET_PMA_HISTORY_PDF_SUCCESS:
            return getPmaHistoryPdfSuccess(state, action);
        case actionTypes.GET_PMA_HISTORY_PDF_FAIL:
            return getPmaHistoryPdfFail(state, action);

        case actionTypes.GET_PMA_TEMPLATES_START:
            return getPmaTemplatesStart(state, action);
        case actionTypes.GET_PMA_TEMPLATES_SUCCESS:
            return getPmaTemplatesSuccess(state, action);
        case actionTypes.GET_PMA_TEMPLATES_FAIL:
            return getPmaTemplatesFail(state, action);

        case actionTypes.GET_PMA_TEMPLATE_START:
            return getPmaTemplateStart(state, action);
        case actionTypes.GET_PMA_TEMPLATE_SUCCESS:
            return getPmaTemplateSuccess(state, action);
        case actionTypes.GET_PMA_TEMPLATE_FAIL:
            return getPmaTemplateFail(state, action);

        case actionTypes.CREATE_PMA_TEMPLATE_START:
            return createPmaTemplateStart(state, action);
        case actionTypes.CREATE_PMA_TEMPLATE_SUCCESS:
            return createPmaTemplateSuccess(state, action);
        case actionTypes.CREATE_PMA_TEMPLATE_FAIL:
            return createPmaTemplateFail(state, action);

        case actionTypes.UPDATE_PMA_TEMPLATE_START:
            return updatePmaTemplateStart(state, action);
        case actionTypes.UPDATE_PMA_TEMPLATE_SUCCESS:
            return updatePmaTemplateSuccess(state, action);
        case actionTypes.UPDATE_PMA_TEMPLATE_FAIL:
            return updatePmaTemplateFail(state, action);

        case actionTypes.DELETE_PMA_TEMPLATE_START:
            return deletePmaTemplateStart(state, action);
        case actionTypes.DELETE_PMA_TEMPLATE_SUCCESS:
            return deletePmaTemplateSuccess(state, action);
        case actionTypes.DELETE_PMA_TEMPLATE_FAIL:
            return deletePmaTemplateFail(state, action);

        case actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_START:
            return resendPmaApproverNotificationStart(state, action);
        case actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_SUCCESS:
            return resendPmaApproverNotificationSuccess(state, action);
        case actionTypes.RESEND_PMA_APPROVER_NOTIFICATION_FAIL:
            return resendPmaApproverNotificationFail(state, action);

        case actionTypes.GET_PMA_FILE_START:
            return getPmaFileStart(state, action);
        case actionTypes.GET_PMA_FILE_SUCCESS:
            return getPmaFileSuccess(state, action);
        case actionTypes.GET_PMA_FILE_FAIL:
            return getPmaFileFail(state, action);

        case actionTypes.VOID_PMA_ENTRY_START:
            return voidPmaEntryStart(state, action);
        case actionTypes.VOID_PMA_ENTRY_SUCCESS:
            return voidPmaEntrySuccess(state, action);
        case actionTypes.VOID_PMA_ENTRY_FAIL:
            return voidPmaEntryFail(state, action);

        default:
            return state;
    }
};

export default reducer;
