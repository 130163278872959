import React from "react";
import devStatus from "../../shared/devStatus";

class ErrorPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, info) {
      console.log(error, info.componentStack);
    }
  
    render() {
      if (this.state.hasError && devStatus === 'live') {
        return <div id="content-centered" style={{textAlign:'center',paddingTop:100}}>
            <img
                src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                style={{ width: 60 }}
            />
            <p style={{marginTop:40,fontSize:'20px',lineHeight:'30px'}}>There has been an error in illi Connect.<br /><br /> Please email <a href="mailto:support@illicre.com">support@illicre.com</a> 
            <br />and provide details<br /> so we can assist you with this error.</p>
        </div>;
      }
  
      return this.props.children;
    }
  }

  export default ErrorPage;