import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";
import moment from "moment";
import Element from "../ListingElement";
import { listingFields } from "../ListingModel";
import { get } from "lodash";

const except = ["title", "date", "submitted", "total_com"];
export const requiredFields = (fieldModels) =>
    Object.keys(fieldModels).filter(
        (field) =>
            fieldModels[field] &&
            !except.includes(field) &&
            !field.includes("agent_com")
    );

const fieldsToRender = {
    title_and_location: [
        "tag",
        "street",
        "city",
        "state",
        "zip",
        "cross",
        "signed",
        "expire",
        "renew",
    ],
    space_detail: [
        "space_unit",
        "space_sf",
        "space_rent",
        "space_date",
        "space_lease",
        "space_nnn",
        "space_sub",
        "space_min",
        "space_max",
        "space_term",
        "space_prop",
        "space_bo",
    ],
    property_information: [
        "property_detail",
        "property_amenity",
        "property_parking",
        "property_addition",
    ],
    project_name_and_location: [
        "project_project",
        "project_city",
        "project_state",
        "project_zip",
        "project_cross",
        "project_signed",
        "project_expire",
        "project_renew",
    ],
};

const generateTitle = (text) => {
    return text.split("_").join(" ").toUpperCase();
};

const Step5 = (props) => {
    const { agentList, formData, setFormData, updateDataObj, fieldModels } =
        props;

    const agent_com_list = Object.keys(formData)
        .filter((key) => key.includes("agent_com"))
        .map((key) => formData[key]);

    useEffect(() => {
        const total = agent_com_list.reduce((prev, curr) => {
            return prev + parseFloat(curr);
        }, 0);

        const newFormData = clone(formData);
        newFormData.total_com = total.toString();
        setFormData(newFormData);
    }, [...agent_com_list]);

    const renderElement = (field, access) => {
        return (
            <Element
                updateDataObj={updateDataObj}
                data={formData}
                setData={setFormData}
                field={field}
                access={access}
                fieldModels={fieldModels}
            />
        );
    };

    const renderFields = () => {
        return Object.keys(fieldsToRender).map((key) => {
            const title = (
                <GridItem style={{ marginTop: 20 }} xs={12} sm={12} md={12}>
                    <p style={{ fontSize: 18 }}>{generateTitle(key)}</p>
                </GridItem>
            );
            const fieldList = fieldsToRender[key];
            const content = fieldList.map((field, index) => (
                <GridItem key={`field-${index}`} xs={12} sm={6} md={6}>
                    {renderElement(field)}
                </GridItem>
            ));
            return (
                <>
                    {title}
                    {content}
                </>
            );
        });
    };

    const renderCommission = () => {
        const comFields = Object.keys(formData).filter((key) =>
            key.includes("agent_com")
        );
        const fields = comFields.map((f) => (
            <Element
                updateDataObj={updateDataObj}
                data={formData}
                setData={setFormData}
                field={f}
                fieldModels={fieldModels}
            />
        ));
        fields.push(
            <Element
                updateDataObj={updateDataObj}
                data={formData}
                setData={setFormData}
                field={"total_com"}
                fieldModels={fieldModels}
            />
        );
        return fields;
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                </GridItem>

                {renderFields()}

                <GridItem style={{ marginTop: 20 }} xs={12} sm={6} md={6}>
                    <p style={{ fontSize: 18 }}>COMMISSIONS</p>
                    {renderCommission()}
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default Step5;
