import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { ArrowBack, Close } from "@material-ui/icons";
import { defaultData, LISTING_STATUS, textData } from "./ListingObj";
import ListingFullForm from "./ListingFullForm";
import ListingWizard from "./ListingWizard";
import { Dialog } from "@material-ui/core";
import { clone } from "../../shared/utility";
import jwt_decode from "jwt-decode";
import { hasIn, isEmpty } from "lodash";

const ListingForm = (props) => {
    const {
        listingHistory,
        getListingHistory,
        createListingEntry,
        pdfData,
        getListingPdf,
        action,
        data,
        updateListingEntry,
        parent_data,
        fieldModels,
        setFieldModels,
    } = props;

    const [tab, setTab] = useState("wizard");
    const [currForm, setCurrForm] = useState(1);


    const [formData, setFormData] = useState(defaultData);
    const [agentList, setAgentList] = useState([]);

    const [templateText, setTemplateText] = useState(textData);
    const [editedObj, setEditedObj] = useState({});

    const [activityTracker, setActivityTracker] = useState(false);
    const [openedAskBeforeClose, setOpenedAskBeforeClose] = useState(false);

    const [ertl, setErtl] = useState(null);
    const [property, setProperty] = useState(null);

    const [allowToSubmit, setAllowToSubmit] = useState(false);

    useEffect(() => {
        if (parent_data.data && parent_data.data.agentList) {
            const newAgentList = clone(parent_data.data.agentList);
            setAgentList(newAgentList);
        }
        if (parent_data) {
            getListingHistory(parent_data.id);
        }
    }, [parent_data]);

    useEffect(() => {
        const newFormData = clone(formData);
        agentList.forEach((agent, index) => {
            newFormData[`contact_agent${index + 1}`] = agent.name.fullName;
            // newFormData[`contact2_agent${index + 1}`] = agent.name.fullName;
            newFormData[`agent_com_${index + 1}`] = '0'
        });
        newFormData.agentList = agentList;
        setFormData(newFormData);

        if (!isEmpty(agentList)) {
            const newFieldModels = clone(fieldModels);
            agentList.forEach((agent, index) => {
                newFieldModels[`agent_com_${index + 1}`] = {
                    canOverwrite: false,
                    goToTab: () => goTo('wizard', 4),
                    type: "input",
                    label: `Agent ${index + 1} Comm. %`,
                    validations: ["no_letters", "required"],
                    required: true,
                };
                newFieldModels[`contact2_agent${index + 1}`] = {
                    type: "input",
                    canOverwrite: false,
                    goToTab: () => goTo('wizard', 4),
                    label: index === 0 ? "Primary Agent" : `Agent ${index + 1}`,
                };
                // newFieldModels[`contact_agent${index + 1}`] = {
                //     canOverwrite: false,
                //     goToTab: () => goTo('wizard', 4),
                //     type: "input",
                //     label: index === 0 ? "Primary Agent" : `Agent ${index + 1}`,
                // };
            });
            setFieldModels(newFieldModels);
        }
    }, [agentList]);

    useEffect(() => {
        if (action === "Edit" && data) {
            const newFormData = clone(data);
            // Object.keys(data).forEach((field) => {
            //     if (hasIn(newFormData, field) && data[field]) {
            //         newFormData[field] = data[field];
            //     }
            // });
            setFormData(newFormData);
        } else if (action === "Add") {
            const token = localStorage.getItem("token");
            if (token) {
                const decoded = jwt_decode(token);
                if (decoded.name) {
                    const newFormData = clone(formData);
                    newFormData.submitted = decoded.name;
                    setFormData(newFormData);
                }
            }
        }
    }, [action, data]);

    useEffect(() => {
        if (ertl && ertl.data) {
            const { data } = ertl;
            const address = `${data.p1_property_address1} ${data.p1_property_address2}`;
            const state = data.p1_property_state;
            const city = data.p1_property_city;
            const zip = data.p1_property_zipcode;
            const newFormData = clone(formData);
            newFormData.address = address;
            newFormData.state = state;
            newFormData.city = city;
            newFormData.zip = zip;
            newFormData.illi = ertl.id;
            newFormData.space_sf = data.p2_property_sq_ft;
            newFormData.space_lease = data.p2_approved_lease_type;
            newFormData.space_date = data.p2_begin_date;
            newFormData.signed = "Yes";
            setFormData(newFormData);
        }
    }, [ertl]);

    const goTo = (tab, form) => {
        setTab(tab);
        setCurrForm(form);
    }

    const getTabIndex = () => {
        if (tab === "wizard") return 0;
        if (tab === "full") return 1;
    };

    const updateDataObj = (field, val) => {
        const newData = clone(formData);
        newData[field] = val;
        setFormData(newData);
    };

    console.log('ListingForm', formData);

    return (
        <Dialog
            open={props.open}
            onClose={props.close}
            fullScreen={true}
            fullWidth={true}
            maxWidth={false}
        >
            <div className="dialog-wrapper">
                <div className="top-dweller">
                    <span
                        onClick={() => {
                            props.close();
                        }}
                    >
                        <Close />
                    </span>
                </div>
                <div>
                    <ArrowBack
                        className="hoverable"
                        onClick={() => {
                            props.close();
                        }}
                    />
                    <h2>
                        {props.action === "Edit" ? (
                            <div>
                                Edit Listing{" "}
                                <strong>{props.parent_data.title}</strong>
                            </div>
                        ) : (
                            "Add Listing"
                        )}
                    </h2>
                </div>
                {props.action !== "View" && (
                    <div>
                        <Tabs
                            TabIndicatorProps={{
                                style: {
                                    background: "#da3b2f",
                                    borderBottom: "1px solid #da3b2f",
                                    color: "#da3b2f",
                                },
                            }}
                            style={{ marginBottom: 10 }}
                            value={getTabIndex()}
                        >
                            {
                                <Tab
                                    onClick={() => {
                                        setTab("wizard");
                                    }}
                                    style={{
                                        color:
                                            getTabIndex() === 0
                                                ? "#da3b2f"
                                                : "rgba(0, 0, 0, 0.87)",
                                    }}
                                    tabIndex={0}
                                    label="Listing Submissions Wizard"
                                    icon={
                                        <i className="fas fa-magic font-22"></i>
                                    }
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setTab("full");
                                    }}
                                    style={{
                                        color:
                                            getTabIndex() === 1
                                                ? "#da3b2f"
                                                : "rgba(0, 0, 0, 0.87)",
                                    }}
                                    tabIndex={1}
                                    label="Full Form"
                                    icon={
                                        <i className="fas fa-newspaper font-22"></i>
                                    }
                                />
                            }
                        </Tabs>
                    </div>
                )}
                {tab === "wizard" && (
                    <ListingWizard
                        action={action}
                        ertl={ertl}
                        setErtl={setErtl}
                        property={property}
                        setProperty={setProperty}
                        formData={formData}
                        setFormData={setFormData}
                        updateDataObj={updateDataObj}
                        setTab={setTab}
                        agentList={agentList}
                        setAgentList={setAgentList}
                        setAllowToSubmit={setAllowToSubmit}
                        listingHistory={listingHistory}
                        fieldModels={fieldModels}
                        currForm={currForm}
                        setCurrForm={setCurrForm}
                        prefill_property_id={props.prefill_property_id}
                        reset_prefill_property_id={props.reset_prefill_property_id}
                    />
                )}
                {tab === "full" && (
                    <ListingFullForm
                        action={action}
                        ertl={ertl}
                        setErtl={setErtl}
                        formData={formData}
                        setFormData={setFormData}
                        updateDataObj={updateDataObj}
                        property={property}
                        setTab={setTab}
                        templateText={templateText}
                        setTemplateText={setTemplateText}
                        activityTracker={activityTracker}
                        setActivityTracker={setActivityTracker}
                        openedAskBeforeClose={openedAskBeforeClose}
                        setOpenedAskBeforeClose={setOpenedAskBeforeClose}
                        editedObj={editedObj}
                        setEditedObj={setEditedObj}
                        createListingEntry={createListingEntry}
                        closeForm={props.close}
                        pdfData={pdfData}
                        getListingPdf={getListingPdf}
                        updateListingEntry={updateListingEntry}
                        parent_data={parent_data}
                        agentList={agentList}
                        allowToSubmit={allowToSubmit}
                        fieldModels={fieldModels}
                    />
                )}
            </div>
        </Dialog>
    );
};

export default ListingForm;
