import { includes, isEqual } from 'lodash';
import React from 'react';
import { sectionNames, sectionRequirements } from '../PMAObj';

export const generateSections = (data) => {
    let sections = [];
    
    Object.values(sectionNames).forEach(v => {
        if (data) {
            let complete = true;
            if (v === 'Owner Information') {
                // check for owners
                if (!data.date) complete = false;
                if (!data.owner) complete = false;
                if (data.owner) {
                    const ow = data.owner;
                    if (!ow.name || !ow.company_name || !ow.email) complete = false;
                }
            } else if (v === 'Property') {
                if (!data.property) complete = false;
                if (data.property) {
                    if (!data.property.address) complete = false;
                }
            } else if (v === 'Agreement Details') {
                if (!data.commencement_date) complete = false;
                if (!data.monthly_fee_type) complete = false;
                if (data.monthly_fee_type) {
                    if (data.monthly_fee_type === 'Dollar Value') {
                        if (!data.monthly_fee_value) {
                            complete = false;
                        }
                    } else if (data.monthly_fee_type === 'Percentage') {
                        if (!data.monthly_fee_percentage) {
                            complete = false;
                        }
                    }
                } else {
                    complete = false;
                }
                if (!data.minimum_management_fee) {
                    complete = false;
                }
                if (!data.set_up_fee) complete = false;
            } else if (v === 'Additional Notifications') {
                if (data.addedCC) {
                    if (data.addedCC.length === 0) {
                        complete = false
                    }
                }
            }
            
            sections.push({
                name: v,
                complete
            });
        }
    })
    return sections;
}

export const removeSplitter = (word) => {
    return word.split('_|_')[0];
}

const addBreaks = (string) => {
    if (!string) return null;
    if (typeof string === 'number') return null;
    // adds line breaks 
    if (string.indexOf('<>') !== -1) {
        
    }
    if (string === '<br>') return <div style={{marginTop:20}}></div>;
    return <span 
        dangerouslySetInnerHTML={{__html: string.replace(/[\r\n]/gm, '<br />')}}
    />;
}

export const getDifferences = (original, newstring, shouldShowIt, author) => {
    // 
    // 
    if (original === newstring) return addBreaks(newstring);
    // make sure duplicate words are tracked accurately
    let instances1 = {};
    let instances2 = {};
    const o_arr = original ? original
        .split(/[\n\r\s]/gm)
        .map(r => {
            if (!r) return '<br>';
            return r;
        })
        .map((r, i) => {
            if (!instances1.hasOwnProperty(r)) {
                instances1[r] = [1];
                return `${r}_|_${0}`;
            } else {
                instances1[r].push(1);
                return `${r}_|_${instances1[r].length - 1}`;
            }

        }) : [];
    const n_arr = newstring ? newstring
        .split(/[\n\r\s]/gm)
        .map(r => {
            if (!r) return '<br>';
            return r;
        })
        .map((r, i) => {
            if (!instances2.hasOwnProperty(r)) {
                instances2[r] = [1];
                return `${r}_|_${0}`;
            } else {
                instances2[r].push(1);
                return `${r}_|_${instances2[r].length - 1}`;
            }
        }) : [];
    let newarr = [];
    let erased = [];
    n_arr.forEach(n => {
        const o_arr2 = o_arr.map(m => {
            return m;
            //return m.replace(/[\r\n]/gm, '');
        })
        if (o_arr2.indexOf(n) === -1) {
            newarr.push(n);
        }
    })
    o_arr.forEach(o => {
        const n_arr2 = n_arr.map(m => {
            return m;
            //return m.replace(/[\r\n]/gm, '');
        })
        if (n_arr2.indexOf(o) === -1) {
            erased.push(o);
        }
    })
    const sttr = Object.keys(instances1).join(' ');
    // 
    // 
    // 
    // 
    // 
    return <span>
        {n_arr.map((nn, i) => {
            const addedStyles = { color: 'black' };
            const nnew = nn;// .replace(/[\r\n]/gm, '');
            if (newarr.indexOf(nnew) !== -1 && shouldShowIt) {
                addedStyles.color = author ? 
                                        author === 'Approver' ? 
                                            '#147ae3' 
                                        : 
                                            'red' 
                                    : 
                                        'red';
                addedStyles.fontWeight = 'bold';
                addedStyles.fontSize = 19;
                addedStyles.textDecoration = 'underline';
            }
            return <span key={`${nn}-s-${i}`} style={addedStyles} className="n_arr">{addBreaks(removeSplitter(nn))} </span>
        })}
        {(erased.length > 0 && shouldShowIt) &&
            <span><br />
                <span style={{ display: 'inline-block', padding: 5, backgroundColor: '#ffecec', marginTop: 5 }}>
                    {o_arr.map((nn, i) => {
                        const addedStyles = { color: 'black' };
                        if (erased.indexOf(nn) !== -1 && (erased.length > 0 && shouldShowIt)) {
                            addedStyles.color = 'red';
                            addedStyles.fontWeight = 'bold';
                        }
                        return <span key={`${nn}-n-${i}`} style={addedStyles} className="o_arr">{addBreaks(removeSplitter(nn))} </span>
                    })}
                </span>
            </span>}
    </span>
}

export const getSignatureInitialsDifference = (field, original, newList, shouldShowIt) => {
    if (isEqual(original, newList)) {
        return false;
    } 
    if (!includes(original, field) && !includes(newList, field)) {
        return false;
    }
    if (includes(original, field) && includes(newList, field)) {
        return false;
    }
    if (!shouldShowIt) return false;

    if (includes(original, field) && !includes(newList, field)) {
        return {
            isRemoved: true,
        }
    } else {
        return {
            isAdded: true,
        }
    }

}

export const generateOwnerIDs = (data) => {
    const ownerIDs = {};
    let ownerIDnum = 1;
    if (data.owner) {
        if (data.owner.email) {
            ownerIDs[data.owner.email] = ownerIDnum; // owner 1 id is always 1
        }
    }
    if (data.other_owners) {
        if (data.other_owners.length > 0) {
            data.other_owners.forEach(doo => {
                if (!ownerIDs.hasOwnProperty(doo.email)) {
                    ownerIDnum++;
                    ownerIDs[doo.email] = ownerIDnum;
                }
            });
        }
    }
    return ownerIDs;
}

export const createPMAAddress = (data) => {
    console.log('creating address', data);
    let address = '';
    const property = data.property;
    if (property) {
        if (property.address) {
            address = `${property.address} `;
            if (property.address2) {
                address += `${property.address2} `;
            }
            if (property.city) {
                address += `${property.city}, `;
            }
            if (property.state) {
                address += `${property.state} `;
            }
            if (property.zip) {
                address += `${property.zip}`;
            }
        }
    }
    return address;
}
