import { put } from "redux-saga/effects";
import axios from "../axios/axios-files";
import * as actions from "../actions/files";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export function* createFileUploadSaga(action) {
    yield put(actions.createFileUploadStart());
    try {
        const { data } = action;
        const formData = new FormData();
        if (!data.file) throw "No file";
        const originalname = data.file.name;
        const newname = `${makeid(5)}-${originalname}`;
        formData.append("file", data.file, newname);
        formData.append("sfiletype", data.type);
        const response = yield axios.post('/upload', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
        yield put(actions.createFileUploadSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createFileUploadFail(e.response.data));
            }
        }
        yield put(actions.createFileUploadFail(e));
    }
}

const downloadFile = (res) => {
    const contentDisposition = res.headers['content-disposition'];
    const fileNameArr = contentDisposition.split(';')[1].split('=')[1].split('"');
    const url = window.URL.createObjectURL(new Blob([res.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${fileNameArr[1]}`);
    document.body.appendChild(link);
    link.click();
}

export function* getFileUploadSaga(action) {
    yield put(actions.getFileUploadStart());
    try {
        const response = yield axios.get('/download?id='+action.data.id+'&type='+action.data.type, {responseType: 'blob'});
        if (action.data.previewPhoto) {
          console.log('getting blob', response.data, action);
          const src = URL.createObjectURL(response.data);
          yield put(actions.getFileUploadSuccess("OK", {
            the_filename: action.data.the_filename,
            file_data: src
          }));
        } else {
          downloadFile(response);
          yield put(actions.getFileUploadSuccess("OK"));
        }
    } catch (e) {
        console.log('e', e);
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getFileUploadFail(e.response.data));
            }
          }
        yield put(actions.getFileUploadFail(e));
    }
}

export function* getFilesDataSaga(action) {
    yield put(actions.getFilesDataStart());
    try {
        const response = yield axios.get('/data?ids='+action.data);
        yield put(actions.getFilesDataSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getFilesDataFail(e.response.data));
            }
          }
        yield put(actions.getFilesDataFail(e));
    }
}