import React from "react";
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Person from "@material-ui/icons/Person";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import GridContainer from "components/Grid/GridContainer.jsx";
import Tooltip from "@material-ui/core/Tooltip";
import Close from "@material-ui/icons/Close";
import Delete from "@material-ui/icons/Delete";
import { Business } from "@material-ui/icons";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import CompaniesFormGeneral from "./CompaniesFormGeneral";
import CompaniesFormAddresses from "./CompaniesFormAddresses";
import CompaniesFormPreferences from "./CompaniesFormPreferences";
import CompaniesFormInformation from "./CompaniesFormInformation";
import CompaniesFormCompanies from "./CompaniesFormCompanies";
import CompaniesSharing from "./CompaniesSharing";
import CompaniesContacts from "./CompaniesContacts";
import CompaniesProperties from "./CompaniesProperties";
import { addressFields, initialAddressData, otherAddressFields, companyDataObj } from "./CompaniesObj";
import { getUserID, getUser } from "shared/authValidation";
import { formatDate2, clone } from "shared/utility";
import { Tabs, Tab } from "@material-ui/core";
import { getCompanyPermissions } from "./CompaniesLogic";
import FilesForm from "./FilesForm";
import Notification from "../../../components/Notification/Notification";
import { get } from "lodash";

const CompaniesForm = (props) => {
    const { contactForAdd, prefill } = props;

    const [dataObj, setDataObj] = useState(clone(companyDataObj));
    const [addressData, setAddressData] = useState(clone(initialAddressData));
    const [partnersData, setPartnersData] = useState([]);
    const [contactsForAdd, setContactsForAdd] = useState([]);
    const [dbasForAdd, setDbasForAdd] = useState([]);
    const [sharesForAdd, setSharesForAdd] = useState([]);
    const [currentTab, setCurrentTab] = useState("General");
    const [madeChanges, setMadeChanges] = useState(false);
    const [userId, setUserId] = useState(null);
    const [userPerms, setUserPerms] = useState(null);
    const [notification2, setNotification2] = useState(false);
	const [entitiesData, setEntitiesData] = useState([]);
    const [relatedCompaniesForAdd, setRelatedCompaniesForAdd] = useState([]);

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(props.success);

    const resetData = () => {
        setDataObj(clone(companyDataObj));
        setAddressData(clone(initialAddressData));
        if (props.action === "Edit") {
            props.getContacts();
        }
        setContactsForAdd([]);
        setDbasForAdd([]);
        setSharesForAdd([]);
        setRelatedCompaniesForAdd([]);
        setTimeout(() => {
            props.clearMessages();
            setCurrentTab("General");
        }, 2000);
    };

    // CURRENT COMPANY DATA
    useEffect(() => {
        const companyData = props.companyData;

        if (companyData) {
            if (companyData.name) {
                // set the values
                const newObj = {};
                Object.keys(companyData).forEach((field) => {
                    if (companyData[field] === "1") {
                        newObj[field] = "Yes";
                    } else if (companyData[field] === "0") {
                        newObj[field] = "No";
                    } else {
                        newObj[field] = companyData[field];
                    }
                });
                setDataObj(newObj);
            } else {
                //props.closeForm();
            }
        }
    }, [props.companyData]);

    useEffect(() => {
        if (!prevSuccess && props.success) {
            if (props.success.indexOf("Successfully created company") !== -1) {
                if (props.success_data && props.dontCloseFormOnAdd) {
                    const company_name = get(props.success_data, 'name', '');
                    const current_company_name = dataObj.name;
                    // reload if the update was the same company name
                    if (company_name === current_company_name) {
                        console.log('success_data', company_name, current_company_name);
                        window.location.href = `/crm/companies?company_id=${props.success_data.id}`;
                    }
                }
                resetData();
                setMadeChanges(false);
            }
        }
    }, [props.success]);

    useEffect(() => {
        if (props.action === 'Add' && props.success_data && props.dontCloseFormOnAdd) {
            const company_name = get(props.success_data, 'name', '');
            const current_company_name = dataObj.name;
            // Load company in Edit mode if update was same
            if (company_name === current_company_name) {
                window.location.href = `/crm/companies?company_id=${props.success_data.id}`;
            }
        }
    }, [props.success_data]);

    useEffect(() => {
        props.getContactOptions();
        const user_id = getUserID();
        setUserId(user_id);
        const userPermsObj = getUser();
        setUserPerms(userPermsObj);
        // for inserting companys with default company type e.g. used in Add and Insert Lessee
        if (props.peopleType) {
            // agents, lessees, lessors
            let company_type = dataObj.company_type;
            let addType = "";
            if (props.peopleType === "agents") addType = "Real Estate Agent";
            if (props.peopleType === "owners") addType = "Property Owner";
            if (props.peopleType === "lessors") addType = "Property Owner";
            if (props.peopleType === "lessees") addType = "Tenant";

            if (company_type.indexOf(addType) === -1 && addType) {
                if (company_type) {
                    company_type = company_type.split(",").concat([addType]).join(",");
                } else {
                    company_type = addType;
                }
            }

            let company_subtype = dataObj.company_subtype;
            if (props.peopleType === "lessors") {
                if (company_subtype.indexOf("Property Owner - Lessor") === -1) {
                    if (company_subtype) {
                        company_subtype = company_subtype.split(",").concat(["Property Owner - Lessor"]).join(",");
                    } else {
                        company_subtype = "Property Owner - Lessor";
                    }
                }
            }
            if (props.peopleType === "lessees") {
                if (company_subtype.indexOf("Tenant") === -1) {
                    if (company_subtype) {
                        company_subtype = company_subtype.split(",").concat(["Tenant"]).join(",");
                    } else {
                        company_subtype = "Tenant";
                    }
                }
            }

            updateDataObj("company_type", company_type, [{ field: "company_subtype", value: company_subtype }]);
        }
    }, []);

    useEffect(() => {
        if (prefill) {
            updateDataObj('name', prefill.name ? prefill.name : '');
            return;
        }
    }, [prefill]);

    const updatePartners = (field, value, partnerIndex) => {
        let partners = clone(partnersData);
        if (field === "delete") {
            return setPartnersData(
                partners.filter((p, i) => {
                    if (i === partnerIndex) return false;
                    return true;
                })
            );
        }
        if (partnerIndex !== -1 && partnerIndex !== undefined) {
            partners[partnerIndex][field] = value;
        } else {
            const newPartner = {
                id: "",
                email: "",
                first_name: "",
                last_name: "",
                phone: "",
                notes: "",
            };
            partners.push(newPartner);
        }
        setPartnersData(partners);
        setMadeChanges(true);
    };

    const updateAddress = (field, value, addType, addressIndex) => {
        const addObj = clone(addressData);
        if (field === "delete") {
            addObj.other = addObj.other.filter((a, i) => {
                if (i === addressIndex) {
                    return false;
                }
                return true;
            });
            return setAddressData(addObj);
        }
        if (addType !== "other") {
            addObj[addType][field] = value;
        } else {
            if (addressIndex !== -1 && addressIndex !== undefined) {
                addObj["other"][addressIndex][field] = value;
            } else {
                const newOtherAddress = clone(otherAddressFields);
                newOtherAddress.id = "";
                newOtherAddress[field] = value;
                addObj.other.push(newOtherAddress);
            }
        }
        setAddressData(addObj);
        setMadeChanges(true);
    };

    const updateDataObj = (field, value, arr) => {
        const newObj = clone(dataObj);
        newObj[field] = value;
        if (arr) {
            if (arr.length > 0) {
                arr.forEach((a) => {
                    newObj[a.field] = a.value;
                });
            }
        }
        setDataObj(newObj);
        setMadeChanges(true);
    };

    const original_name = props.action !== "Add" ? (dataObj ? (dataObj.name ? dataObj.name : "") : "") : "";

    const isEmployee = userPerms ? (userPerms.type ? (userPerms.type === "E" ? true : false) : false) : false;

    const canEdit = getCompanyPermissions(props.companyShares, dataObj, userId, "edit", isEmployee);
    const canDelete = getCompanyPermissions(props.companyShares, dataObj, userId, "delete", isEmployee);

    const getTabIndex = () => {
        if (props.action !== "Add") {
            const companyInfoArr = ["General", "Preferences", "Information", "Addresses", "Partners", "Submit"];
            if (companyInfoArr.indexOf(currentTab) !== -1) {
                if (props.action === "Add" && currentTab === "Submit") return 4;
                return 0;
            }
            if (currentTab === "Companies") {
                return 1;
            }
            if (currentTab === "Contacts") {
                return 2;
            }
            if (currentTab === "Properties") {
                return 3;
            }
            if (currentTab === "Share") {
                return 4;
            }
            if (currentTab === "Files") {
                return 5;
            }
            if (currentTab === "Save") {
                return 6;
            }
        } else {
            const companyInfoArr = ["General", "Preferences", "Information", "Addresses", "Partners", "Submit"];
            if (companyInfoArr.indexOf(currentTab) !== -1) {
                if (currentTab === "Submit") return 4;
                return 0;
            }
            if (currentTab === "Companies") {
                return 1;
            }
            if (currentTab === "Contacts") {
                return 2;
            }
            if (currentTab === "Share") {
                return 3;
            }
            if (currentTab === "Files") {
                return 4;
            }
            if (currentTab === "Save") {
                return 5;
            }
        }
    };

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            if (props.setSaveCompaniesFormValues) {
                props.setSaveCompaniesFormValues({
                    cfDataObj: dataObj,
                    cfAddressData: addressData,
                    cfPartnersData: partnersData,
                    madeChanges: madeChanges,
                });
            }
            return props.setOpenedAsk(true);
        }
        props.closeForm();
    };

    const updateSharesForAdd = (val) => {
        setSharesForAdd(val);
        setMadeChanges(true);
    };

    const theOriginal = props.companyData;
    let show_illi_owned_message = false;
    if (theOriginal) {
        if (theOriginal.user_id) {
            const the_user = getUser();
            const owner_id = theOriginal.user_id;
            if (the_user.type) {
                if (the_user.type === "A") {
                    if (owner_id) {
                        if (owner_id === 1) {
                            show_illi_owned_message = true;
                        }
                    }
                }
            }
        }
    }

    return (
        <div>
            {props.showCloseBtn && (
                <div
                    className="close-btn"
                    onClick={() => {
                        cancel();
                    }}
                >
                    <Close />
                </div>
            )}
            <GridContainer>
                <GridItem xs={12} sm={8} md={8}>
                    <h3 style={{ display: "inline-block", marginRight: 40 }}>
                        {
                            <div
                                style={{ marginRight: 20, marginBottom: 10 }}
                                className="inline-block blue-text hoverable"
                                onClick={() => {
                                    cancel();
                                }}
                            >
                                <Tooltip id="tooltip-top-start1" title="Back to List" placement="top">
                                    <ArrowBack />
                                </Tooltip>
                            </div>
                        }
                        {props.action !== "Add" && (
                            <div
                                className={`${
                                    props.action === "View" ? "orange" : "lightgray"
                                }-text inline-block mr-20 hoverable`}
                                onClick={() => {
                                    props.openForm("View", props.companyData);
                                }}
                            >
                                <Tooltip id="tooltip-top-start1" title="View Only Mode" placement="top">
                                    <Visibility />
                                </Tooltip>
                            </div>
                        )}
                        {props.action !== "Add" && canEdit && (
                            <div
                                className={`${
                                    props.action === "Edit" ? "blue" : "lightgray"
                                }-text inline-block mr-20 hoverable`}
                                onClick={() => {
                                    props.openForm("Edit", props.companyData);
                                }}
                            >
                                <Tooltip id="tooltip-top-start2" title="Edit Mode" placement="top">
                                    <Edit />
                                </Tooltip>
                            </div>
                        )}
                        {props.action !== "Add" && !props.noDelete && (
                            <div
                                className={`${
                                    props.action === "Edit" ? "blue" : "lightgray"
                                }-text inline-block mr-20 hoverable`}
                                onClick={() => {
                                    props.openAskModal(dataObj);
                                }}
                            >
                                <Tooltip id="tooltip-top-start2" title="Delete Company" placement="top">
                                    <Delete />
                                </Tooltip>
                            </div>
                        )}
                        {props.action === "Add" ? "Add Company" : <strong>{original_name}</strong>}
                    </h3>
                    {show_illi_owned_message && (
                        <p>
                            <div>
                                <strong>illi Company Data</strong>
                            </div>
                        </p>
                    )}
                </GridItem>
                <GridItem xs={12} sm={2} md={2}>
                    {props.action !== "Add" && (
                        <small className="gray-text">
                            <strong className="blue-text">Company #:</strong>{" "}
                            {props.companyData ? (props.companyData.illi_number ? "" : props.companyData.id) : ""}
                            <br />
                            <strong className="blue-text">Date Created:</strong>{" "}
                            {props.companyData ? formatDate2(props.companyData.created_date) : ""}
                            <br />
                            <strong className="blue-text">Date Last Modified:</strong>{" "}
                            {props.companyData ? formatDate2(props.companyData.modified_date) : ""}
							{props.action !== 'View' && (
								<Button
									color={"transparent"}
									style={{
										display: "flex",
										justifyContent: "center",
										paddingLeft: "0px",
										marginTop: "10px",
									}}
									onClick={() => {
										if (!dataObj.name || dataObj.name.trim() === "") {
											return setNotification2("Company name is required");
										}
                                        if (dataObj.exclusive_right_to_represent_buyer === 'Yes') {
                                            if (!dataObj.air_exclusive_right_to_represent_buyer) {
                                                const error_air = 'Please upload AIR exclusive right to represent buyer document';
                                                setNotification2(error_air);
                                                return;
                                            }
                                        }
										props.updateCompany(dataObj);
										props.setNotification("Successfully updated company.");
										setMadeChanges(false);
										setCurrentTab("General");
									}}
								>
									<span className="blue-text">
										<i className="fas fa-save mr-10"></i> Save
									</span>
								</Button>
							)}
                        </small>
                    )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}>
                    <br />
                    {props.action !== "Add" && (
                        <Tabs
                            TabIndicatorProps={{
                                style: { background: "#da3b2f", borderBottom: "1px solid #da3b2f", color: "#da3b2f" },
                            }}
                            style={{ marginBottom: 10 }}
                            value={getTabIndex()}
                        >
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("General");
                                    }}
                                    style={{ color: getTabIndex() === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={0}
                                    label="Company Info"
                                    icon={<PersonAddIcon />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Companies");
                                    }}
                                    style={{ color: getTabIndex() === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={1}
                                    label="Companies"
                                    icon={<PersonAddIcon />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Contacts");
                                    }}
                                    style={{ color: getTabIndex() === 2 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={2}
                                    label="Contacts"
                                    icon={<Person />}
                                />
                            }
                            {
                                <Tab
                                    tabIndex={3}
                                    style={{
                                        color: getTabIndex() === 3 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
                                    }}
                                    label="Properties"
                                    onClick={() => {
                                        setCurrentTab("Properties");
                                    }}
                                    icon={<Business />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Share");
                                    }}
                                    style={{ color: getTabIndex() === 4 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={4}
                                    label="Share"
                                    icon={<i className="fas fa-handshake font-22"></i>}
                                />
                            }
                            {
                                <Tab
                                    tabIndex={5}
                                    style={{
                                        color: getTabIndex() === 5 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
                                        // display: (props.action === 'Add' || canDelete) ? 'block' : 'none'
                                    }}
                                    label="Files"
                                    onClick={() => {
                                        setCurrentTab("Files");
                                    }}
                                    icon={<i className="fas fa-file font-22"></i>}
                                />
                            }
                        </Tabs>
                    )}
                    {props.action === "Add" && (
                        <Tabs
                            TabIndicatorProps={{
                                style: { background: "#da3b2f", borderBottom: "1px solid #da3b2f", color: "#da3b2f" },
                            }}
                            style={{ marginBottom: 10 }}
                            value={getTabIndex()}
                        >
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("General");
                                    }}
                                    style={{ color: getTabIndex() === 0 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={0}
                                    label="Company Info"
                                    icon={<PersonAddIcon />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Companies");
                                    }}
                                    style={{ color: getTabIndex() === 1 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={1}
                                    label="Companies"
                                    icon={<PersonAddIcon />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Contacts");
                                    }}
                                    style={{ color: getTabIndex() === 2 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={2}
                                    label="Contacts"
                                    icon={<Person />}
                                />
                            }
                            {
                                <Tab
                                    onClick={() => {
                                        setCurrentTab("Share");
                                    }}
                                    style={{ color: getTabIndex() === 3 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)" }}
                                    tabIndex={3}
                                    label="Share"
                                    icon={<i className="fas fa-handshake font-22"></i>}
                                />
                            }
                            {
                                <Tab
                                    tabIndex={4}
                                    style={{
                                        color: getTabIndex() === 4 ? "#da3b2f" : "rgba(0, 0, 0, 0.87)",
                                        display: props.action === "Add" ? "block" : "none",
                                    }}
                                    label="Save"
                                    onClick={() => {
                                        if (!dataObj.name || dataObj.name.trim() === "") {
                                            setNotification2("Company Name is required");
                                            return;
                                        }
                                        if (dataObj.exclusive_right_to_represent_buyer === 'Yes') {
                                            if (!dataObj.air_exclusive_right_to_represent_buyer) {
                                                const error_air = 'Please upload AIR exclusive right to represent buyer document';
                                                setNotification2(error_air);
                                                return;
                                            }
                                        }
                                        props.createCompany(dataObj, sharesForAdd, contactsForAdd, entitiesData, dbasForAdd, relatedCompaniesForAdd);
                                        if (!props.dontCloseFormOnAdd) {
                                            props.closeForm();
                                        }
                                    }}
                                    icon={<i className="fas fa-save font-22"></i>}
                                />
                            }
                        </Tabs>
                    )}
                </GridItem>
            </GridContainer>
            <div
                autoComplete="off"
                style={{ width: "100%", maxWidth: 1400 }}
                onSubmit={(e) => {
                    e.preventDefault();
                }}
            >
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {/* Tab Nav */}
                        <div style={{ marginBottom: 20 }}>
                            {["General", "Preferences", "Information", "Addresses"].map((t) => {
                                if (props.action === "Add" && t === "Submit") return null;
                                if (props.action === "View" && t === "Submit") return null;
                                if (
                                    currentTab === "Appointments" ||
                                    currentTab === "Contacts" ||
                                    currentTab === "Companies" ||
                                    currentTab === "Properties" ||
                                    currentTab === "Share" ||
                                    currentTab === "Properties" ||
                                    currentTab === "Files"
                                )
                                    return null;
                                return (
                                    <Button
                                        key={t}
                                        color={
                                            currentTab === t ? "success" : t === "Submit" ? "transparent" : "primary"
                                        }
                                        style={{ marginRight: 10 }}
                                        onClick={() => {
                                            setCurrentTab(t);
                                        }}
                                    >
                                        {t === "Submit" ? (
                                            <span className="blue-text">
                                                <i className="fas fa-save mr-10"></i> Save
                                            </span>
                                        ) : t === "Addresses" ? (
                                            "Addresses & Other Info"
                                        ) : (
                                            t
                                        )}
                                    </Button>
                                );
                            })}
                            {/* {props.action !== 'Add' && (
                                   currentTab !== 'Contacts'
                                && currentTab !== 'Share'
                                && currentTab !== 'Properties'
                                && currentTab !== 'Files'
                            ) && (
                                <Button
                                    color={'transparent'}
                                    style={{ marginRight: 10 }}
                                    onClick={() => {
                                        if (!dataObj.name || dataObj.name.trim() === '') {
                                            return setNotification2('Company name is required');
                                        }
                                        props.updateCompany(dataObj);
                                        props.setNotification('Successfully updated company.');
                                        setMadeChanges(false);
                                        setCurrentTab('General');
                                    }}>
                                    <span className="blue-text"><i className="fas fa-save mr-10"></i> Save</span>
                                </Button>
                            )} */}
                        </div>
                    </GridItem>
                </GridContainer>
                {currentTab === "General" && (
                    <CompaniesFormGeneral
                        dataObj={dataObj}
                        originalCompanyData={props.companyData}
                        companyOptions={props.companyOptions}
                        updateDataObj={updateDataObj}
                        action={props.action}
                        peopleType={props.peopleType}
                        crmAllowed={props.crmAllowed}
                        fileList={props.fileList}
                        getCrmFileList={props.getCrmFileList}
                        createCrmFile={props.createCrmFile}
                        deleteCrmFile={props.deleteCrmFile}
                        companyData={props.companyData}
                        getCrmFile={props.getCrmFile}
                        downloadedFile={props.downloadedFile}
                        loading={props.loading}
                        success={props.success}
                        error={props.error}
                        formDataObj={props.formDataObj}
                        setFormDataObj={props.setFormDataObj}
                        setMadeChanges={setMadeChanges}
                        contactOptions={props.contactOptions}
                    />
                )}
                {currentTab === "Addresses" && (
                    <CompaniesFormAddresses
                        addressData={addressData}
                        updateAddress={updateAddress}
                        otherAddressFields={otherAddressFields}
                        addressFields={addressFields}
                        initialAddressData={initialAddressData}
                        action={props.action}
                        getCompanies={props.getCompanies}
                        getCompany={props.getCompany}
                        companiesData={props.companiesData}
                        companyData={props.companyData}
                        updatePartners={updatePartners}
                        setPartnersData={setPartnersData}
                        updateDataObj={updateDataObj}
                        dataObj={dataObj}
                        partnersData={partnersData}
                        originalCompanyData={props.companyData}
                        entitiesData={entitiesData}
                        setEntitiesData={setEntitiesData}
                    />
                )}
                {currentTab === "Preferences" && (
                    <CompaniesFormPreferences dataObj={dataObj} updateDataObj={updateDataObj} action={props.action} />
                )}
                {currentTab === "Information" && (
                    <CompaniesFormInformation dataObj={dataObj} updateDataObj={updateDataObj} action={props.action} />
                )}
                {currentTab === "Contacts" && (
                    <CompaniesContacts
                        dataObj={dataObj}
                        updateDataObj={updateDataObj}
                        contactsForAdd={contactsForAdd}
                        setContactsForAdd={setContactsForAdd}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        action={props.action}
                        contactToAdd={contactForAdd}
                    />
                )}
                {currentTab === "Companies" && (
                    <CompaniesFormCompanies 
                        canEdit={canEdit}
                        canDelete={canDelete}
                        action={props.action}
                        dataObj={dataObj}
                        dbasForAdd={dbasForAdd}
                        setDbasForAdd={setDbasForAdd}
                        relatedCompaniesForAdd={relatedCompaniesForAdd}
                        setRelatedCompaniesForAdd={setRelatedCompaniesForAdd}
                    />
                )}
                {currentTab === "Properties" && (
                    <CompaniesProperties
                        dataObj={dataObj}
                        updateDataObj={updateDataObj}
                        setDataObj={setDataObj}
                        updateCompany={props.updateCompany}
                        canEdit={canEdit}
                        canDelete={canDelete}
                        action={props.action}
                    />
                )}
                {currentTab === "Share" && (
                    <CompaniesSharing
                        dataObj={dataObj}
                        action={props.action}
                        canEdit={canEdit}
                        sharesForAdd={sharesForAdd}
                        setSharesForAdd={updateSharesForAdd}
                    />
                )}
                {currentTab === "Files" && (
                    <FilesForm
                        action={props.action}
                        dataObj={dataObj}
                        canEdit={canEdit}
                        // sharesForAdd={sharesForAdd}
                        // setSharesForAdd={updateSharesForAdd}
                        // getContactFiles={props.getContactFiles}
                        getCrmFileList={props.getCrmFileList}
                        fileList={props.fileList}
                        createCrmFile={props.createCrmFile}
                        deleteCrmFile={props.deleteCrmFile}
                        getCrmFile={props.getCrmFile}
                        companyData={props.companyData}
                        downloadedFile={props.downloadedFile}
                        success={props.success}
                        error={props.error}
                        loading={props.loading}
                        formDataObj={props.formDataObj}
                        setFormDataObj={props.setFormDataObj}
                        contactError={props.contactError}
                        contactSuccess={props.contactSuccess}
                        contactLoading={props.contactLoading}
                    />
                )}
            </div>
            {madeChanges && (
                <div className="bottom-dweller text-center">
                    <div>Change(s) Detected, please make sure to save your company.</div>
                </div>
            )}
            {(notification2 ? true : false) && (
                <Notification
                    open={notification2 ? true : false}
                    close={() => {
                        setNotification2("");
                    }}
                    message={notification2}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        contactOptions: state.crm_contacts.contactOptions,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContactOptions: () => {
            dispatch(actions.getContactOptions());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesForm);
