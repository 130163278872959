import { Dialog, DialogTitle } from "@material-ui/core";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import { get } from "lodash";
import React, { useState } from "react";
import CustomSelect from "../../components/CustomSelect/CustomSelect.jsx";

const UserAdminModalExistingUser = (props) => {
    const { user } = props;
    const [isIlliEmployee, setIsIlliEmployee] = useState('');
    console.log('user', user);
    const username = get(user, 'name.fullName', '');
    const firstname = get(user, 'name.givenName', '');
    const lastname = get(user, 'name.familyName', '');
    const email = get(user, 'primaryEmail', '');
    const google_id = get(user, 'id', '');
    return <Dialog
        open={true}
        keepMounted
        onClose={props.close}
        maxWidth="md"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{ textAlign: 'center' }}>Add User ({username}) to Portal</div>
        </DialogTitle>
        <div className="dialog-wrapper">
            <GridContainer>
                <GridItem xs={12} md={12}>
                    <h4><strong>{firstname} {lastname}</strong></h4>
                    <h4 style={{marginBottom:20}}>{email}</h4>
                    <CustomSelect 
                        options={['Employee','Agent']}
                        default={isIlliEmployee}
                        choose={(is_illi_employee) => {
                            setIsIlliEmployee(is_illi_employee);
                        }}
                        label="Select User Type"
                        style={{marginBottom:20}}
                    />
                    <div className="text-center">
                        <Button style={{marginRight:20}}
                            disabled={!isIlliEmployee ? true : false}
                            color="primary"
                            onClick={() => {
                                props.createUserFromWorkspace({
                                    firstname,
                                    lastname,
                                    email,
                                    google_id,
                                    is_illi_employee: isIlliEmployee
                                });
                                props.close();
                            }}
                        >
                        ADD USER
                        </Button>
                        <Button color="white"
                            onClick={() => {
                                props.close();
                            }}
                        >CLOSE</Button>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
}

export default UserAdminModalExistingUser;