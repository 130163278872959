import React, { useState, useEffect, useRef } from 'react';
import PMAFullForm from './PMAFullForm';
import PMAMultiForm from './PMAMultiForm';
import Button from "components/CustomButtons/Button.jsx";
import { cloneDeep } from 'lodash';
import { initialFormData } from '../PMAObj';
import { Tabs, Tab } from '@material-ui/core';
import { Close } from "@material-ui/icons";
import { clone } from 'shared/utility';
import { Dialog } from '@material-ui/core';
import Notification from '../../../components/Notification/Notification';
import { textData, checkForChanges } from "../PMAObj";
import ClipLoader from 'react-spinners/ClipLoader';

function getDefault(arr) {
    let val = -1;
    if (arr) {
        arr.forEach((a) => {
            if (a.defaults === 1) {
                val = a.id;
            }
        });
    }
    return val;
}

const PMAFormWizard = (props) => {
    const { pmaData, setPmaData } = props;
    const [editedObj, setEditedObj] = useState({});
    const [tab, setTab] = useState('wizard');
    const [formData, setFormData2] = useState(cloneDeep(initialFormData));
    const [templateData, setTemplateData] = useState(textData);
    const [templateOriginal, setTemplateOriginal] = useState(clone(textData));
    const [templateChosen, setTemplateChosen] = useState(
        getDefault(props.templates)
    );
    
    const [currForm, setCurrForm] = useState(1);
    const [activity, setActivity] = useState(false);
    const [openedAskBeforeClose, setOpenedAskBeforeClose] = useState(false);
    const [openLoading, setOpenLoading] = useState(false);

    const [successMsg, setSuccessMsg] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [noAlert, setNoAlert] = useState(false);
    const [notification, setNotification] = useState('');
    const { clearMessages, error, success } = props;

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(props.success);
    const prevError = usePrevious(props.error);

    useEffect(() => {
        clearMessages();
    }, [props.success, props.error]);

    useEffect(() => {
        console.log('prev err', prevError, error)
        if (props.success) {
            if (prevSuccess === null && (props.success.indexOf('PMA created') !== -1
                || props.success.indexOf('Successfully updated pma entry') !== -1
                || props.success.indexOf('PMA draft created') !== -1
                || props.success.indexOf('Submitted') !== -1
                || props.success.indexOf('Updated PMA Entry.') !== -1)) {
                if (!noAlert) {
                    setSuccessMsg(props.success);
                    setOpenLoading(false);
                } else {
                    setNoAlert(false);
                }
                
            }
        }
        if (prevError === null && error === 'Failed to create PMA entry.') {
            setOpenLoading(false);
            setErrorMsg(error);
            clearMessages();
        }
        if (prevError === null && error === 'Invalid Email.') {
            setOpenLoading(false);
            setErrorMsg(error);
            clearMessages();
        }
    }, [success, error]);

    useEffect(() => {
        if (pmaData) {
            const newData = clone(pmaData.data);
            newData.id = pmaData.id;
            // check if addedCC is there 
            if (!newData.addedCC) {
                newData.addedCC = [];
            }
            setFormData2(newData);
        }
        if (props.action === 'View') {
            setTab('full');
        }
    }, [pmaData]);

    const setFormData = (newData) => {
        setFormData2(newData);
        setActivity(true);
    }

    const updateDataObj = (field, value) => {
        const newData = clone(formData);
        setActivity(true);
        if (field.indexOf('.') !== -1) {
            const field_arr = field.split('.');
            newData[field_arr[0]][field_arr[1]] = value;
        } else {
            newData[field] = value;
        }
        setFormData(newData);
    }

    const getTabIndex = () => {
        const obj = {
            wizard: 0,
            full: 1
        }
        return obj[tab];
    }

    const close = () => {
        props.closeFormFillWizard()
        props.reload();
    }

    const saveDraft = (_templateData, _templateChosen, hideAlert) => {
        // check if all owners have emails first
        let everyoneHasEmail = true;
        let errors = [];
        if (formData) {
            if (formData.owner) {
                if (!formData.owner.email) everyoneHasEmail = false;
            }
            if (formData.other_owners) {
                if (formData.other_owners.length > 0) {
                    formData.other_owners.forEach(oo => {
                        if (!oo.email) {
                            everyoneHasEmail = false;
                        }
                    })
                }
            }
            if (formData.property) {
                if (!formData.property.address) {
                    errors.push('Property is required.')
                }
            }
        }
        if (!everyoneHasEmail) errors.push('Owners are required and every owner must have an email.')
        if (errors.length > 0) {
            return setNotification(errors.join(', '));
        }

        if (formData.id) {
            const obj = { data: formData, status: 1, id: formData.id };
            const o = clone(obj);
            if (_templateData) {
                o.data.templateData = clone(_templateData);
                o.data.templateData.id = _templateChosen;
            }
            if (hideAlert) setNoAlert(true);
            props.updatePmaEntry(o);
        } else {
            const obj = { data: formData, status: 1 }
            const o = clone(obj);
            if (_templateData) {
                o.data.templateData = clone(_templateData);
                o.data.templateData.id = _templateChosen;
            }
            props.createPmaEntry(o);
        }
    }

    const closeAlert = () => {
        setSuccessMsg(null);
        setErrorMsg(null);
    }

    const moveToFullForm = () => {
        if (pmaData) {
            if (pmaData.id) {
                props.getPmaEntry(pmaData.id);
                setTimeout(() => {
                    setTab('full');
                    console.log('formData', formData);
                }, 1000);
                return;
            }
        }
        setNotification('Please save your PMA first');
    }

    console.log('PMAFormWizard', props);

    return (
        <div>
            <div className="top-dweller">
                <span onClick={() => {
                    // const hasChanged = checkForChanges(formData, pmaData ? pmaData.data : null);
                    if (!activity) {
                        close();
                    } else {
                        setOpenedAskBeforeClose(true);
                    }
                }}><Close /></span>
            </div>
            {props.action !== 'View' && <div>
                <Tabs
                    TabIndicatorProps={{style: {background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f'}}}
                    style={{marginBottom:10}}
                    value={getTabIndex()}>
                            {<Tab
                                onClick={() => {
                                    setTab('wizard');
                                }}
                                style={{color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'}}
                                tabIndex={0}
                                label='PMA Wizard'
                                icon={<i className="fas fa-magic font-22"></i>}
                            />}
                            {<Tab
                                onClick={() => {
                                    const hasChanged = checkForChanges(formData, pmaData.data);
                                    if (!hasChanged) {
                                        moveToFullForm();
                                    } else {
                                        setNotification('Please save your changes first');
                                    }
                                }}
                                style={{color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'}}
                                tabIndex={1}
                                label='Full Form'
                                icon={<i className="fas fa-newspaper font-22"></i>}
                            />}
                </Tabs>
            </div>}
            {tab === "wizard" &&
                <PMAMultiForm
                    formData={formData}
                    setFormData={setFormData}
                    setFormData2={setFormData2}
                    setTab={setTab}
                    updateDataObj={updateDataObj}
                    action={props.action}
                    saveDraft={saveDraft}
                    activity={activity}
                    close={close}
                    setOpenLoading={setOpenLoading}
                    currForm={currForm}
                    setCurrForm={setCurrForm}
                    moveToFullForm={moveToFullForm}
                    prefill_property_data={props.prefill_property_data}
                    reset_prefill_data={props.reset_prefill_data}
                />
            }
            {tab === "full" ?
                <PMAFullForm
                    close={close}
                    formData={formData}
                    setFormData={setFormData}
                    updateDataObj={updateDataObj}
                    templateData={templateData}
                    setTemplateData={setTemplateData}
                    templateChosen={templateChosen}
                    setTemplateChosen={setTemplateChosen}
                    templateOriginal={templateOriginal}
                    setTemplateOriginal={setTemplateOriginal}
                    editedObj={editedObj}
                    setEditedObj={setEditedObj}
                    createPmaEntry={props.createPmaEntry}
                    getPmaEntries={props.getPmaEntries}
                    entriesData={props.entriesData}
                    getPmaPdf={props.getPmaPdf}
                    pdfData={props.pdfData}
                    updatePmaEntry={props.updatePmaEntry}
                    success={props.success}
                    error={props.error}
                    clearMessages={props.clearMessages}
                    createDocusign={props.createDocusign}
                    parentTab={props.parentTab}
                    setParentTab={(tab) => {
                        setPmaData(null);
                        props.setParentTab(tab);
                    }}
                    setTab={(tab) => {
                        setTab(tab);
                    }}
                    currForm={currForm}
                    setCurrForm={setCurrForm}
                    action={props.action}
                    pmaURL={props.pmaURL}
                    getPmaSigningUrl={props.getPmaSigningUrl}
                    saveDraft={saveDraft}
                    setOpenLoading={setOpenLoading}
                    templates={props.templates}
                    file={props.file}
                    getPmaFile={props.getPmaFile}
                    pmaData={pmaData}
                    clearPmaFile={props.clearPmaFile}
                /> : tab === "full" && <p>No Forms Currently Available</p>}

                {openedAskBeforeClose && <Dialog
                            open={openedAskBeforeClose}
                            onClose={() => {
                                setOpenedAskBeforeClose(false);
                            }}
                            maxWidth="md"
                            fullWidth={true}
                        >
                        <div style={{padding:40}} className="text-center">
                            <h4>Are you sure you want to close this window?</h4>
                            <hr />
                            <Button className="mr-20" color="primary" onClick={() => {
                                saveDraft();
                                close();
                            }}>SAVE AND CLOSE</Button>
                            <Button className="mr-20" color="success" onClick={() => {
                                close();
                            }}>CLOSE WITHOUT SAVING</Button>
                            <Button color="white" onClick={() => {
                                setOpenedAskBeforeClose(false);
                            }}>CANCEL</Button>
                        </div>
                    </Dialog>}
                
                {notification && <Notification 
                        message={notification}
                        open={true}
                        close={() => {
                            setNotification('');
                        }}
                    />}

                {(!successMsg && openLoading) && <Notification 
                        message={<div className="text-center mt-20 mb-20"><ClipLoader /></div>}
                        open={true}
                        no_ok_button={true}
                        close={() => {
                            setOpenLoading(false);
                        }}
                    />}
                
                {successMsg &&
                    <Notification 
                        message={successMsg}
                        open={true}
                        close={() => {
                            closeAlert();
                            if (successMsg) {
                                if (successMsg.indexOf('Submitted') !== -1) {
                                    close();
                                }
                            }
                        }}
                    />}
                {errorMsg === 'Failed to create PMA entry.' && 
                    <Notification 
                        message="Error creating entry."
                        open={true}
                        close={() => {
                            closeAlert();
                        }}
                    />
                }
                {errorMsg === 'Invalid Email.' && 
                    <Notification 
                        message="Please make sure all owners and president have valid email."
                        open={true}
                        close={() => {
                            closeAlert();
                        }}
                    />
                }

        </div>
    )
}

export default PMAFormWizard;