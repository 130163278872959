import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../shared/utility';
const extraMsg = 'Please try again. If the issue persists please contact tech support.';

const initialState = {
	appointmentsData: [],
	appointmentData: null,
	appointmentsCount: null,
	loading: false,
	loadingAppointment: false,
	success: null,
	error: null,
	calendars: []
};

// GET_MEETINGS

const getAppointmentsStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loading: true,
	});
};

const getAppointmentsSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Appointments',
		loading: false,
		appointmentsData: action.response,
	});
};

const getAppointmentsFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loading: false,
	});
};

// GET_MEETING

const getAppointmentStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
	});
};

const getAppointmentSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Got Appointment',
		appointmentData: action.response,
	});
};

const getAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
	});
};

// CREATE_MEETING

const createAppointmentStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingAppointment: true
	});
};

const createAppointmentSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Created Appointment',
		loadingAppointment: false
	});
};

const createAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingAppointment: false
	});
};

// UPDATE_MEETING

const updateAppointmentStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingAppointment: true
	});
};

const updateAppointmentSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Updated Appointment',
		loadingAppointment: false
	});
};

const updateAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingAppointment: false
	});
};

// DELETE_MEETING

const deleteAppointmentStart = (state, action) => {
	return updateObject(state, {
		error: null,
		success: null,
		loadingAppointment: true
	});
};

const deleteAppointmentSuccess = (state, action) => {
	return updateObject(state, {
		success: 'Deleted Appointment',
		loadingAppointment: false
	});
};

const deleteAppointmentFail = (state, action) => {
	return updateObject(state, {
		error: action.err,
		loadingAppointment: false
	});
};

// GET_CALENDARS

const getCalendarsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
		calendars: []
    })
}

const getCalendarsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
		calendars: action.response
    })
}

const getCalendarsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_DEFAULT_CALENDAR

const updateDefaultCalendarStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateDefaultCalendarSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const updateDefaultCalendarFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_APPOINTMENTS_START:
			return getAppointmentsStart(state);
		case actionTypes.GET_APPOINTMENTS_SUCCESS:
			return getAppointmentsSuccess(state, action);
		case actionTypes.GET_APPOINTMENTS_FAIL:
			return getAppointmentsFail(action);

		case actionTypes.GET_APPOINTMENT_START:
			return getAppointmentStart(state);
		case actionTypes.GET_APPOINTMENT_SUCCESS:
			return getAppointmentSuccess(state, action);
		case actionTypes.GET_APPOINTMENT_FAIL:
			return getAppointmentFail(state, action);
		case actionTypes.GET_APPOINTMENT_CLEAR:
			return getAppointmentClear(state);

		case actionTypes.CREATE_APPOINTMENT_START:
			return createAppointmentStart(state);
		case actionTypes.CREATE_APPOINTMENT_SUCCESS:
			return createAppointmentSuccess(state);
		case actionTypes.CREATE_APPOINTMENT_FAIL:
			return createAppointmentFail(state, action);

		case actionTypes.UPDATE_APPOINTMENT_START:
			return updateAppointmentStart(state);
		case actionTypes.UPDATE_APPOINTMENT_SUCCESS:
			return updateAppointmentSuccess(state);
		case actionTypes.UPDATE_APPOINTMENT_FAIL:
			return updateAppointmentFail(state, action);

		case actionTypes.DELETE_APPOINTMENT_START:
			return deleteAppointmentStart(state);
		case actionTypes.DELETE_APPOINTMENT_SUCCESS:
			return deleteAppointmentSuccess(state);
		case actionTypes.DELETE_APPOINTMENT_FAIL:
			return deleteAppointmentFail(state, action);

		case actionTypes.GET_CALENDARS_START:
			return getCalendarsStart(state, action);
		case actionTypes.GET_CALENDARS_SUCCESS:
			return getCalendarsSuccess(state, action);
		case actionTypes.GET_CALENDARS_FAIL:
			return getCalendarsFail(state, action);

		case actionTypes.UPDATE_DEFAULT_CALENDAR_START:
			return updateDefaultCalendarStart(state, action);
		case actionTypes.UPDATE_DEFAULT_CALENDAR_SUCCESS:
			return updateDefaultCalendarSuccess(state, action);
		case actionTypes.UPDATE_DEFAULT_CALENDAR_FAIL:
			return updateDefaultCalendarFail(state, action);

		default:
			return state;
	}
};

export default reducer;
