import React, { useEffect, useState, useRef } from "react";
import { Dialog, Switch } from "@material-ui/core";
import { first, get } from "lodash";
import moment from "moment";

import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";

import {
	clone, getDateDiffs, commatize,
	getDateDiffs2,
    convert,
    convertUnixToDate,
    validateDates,
    validateRange,
    validateDatesAndRange,
    timestampToDate,
    formatDollar,
} from "shared/utility";

import {
	calculateAbatedRanges,
    calculateAbatedTable,
    calculateAbatedValues,
    calculateCommission,
    calculateTermsTotal,
    calculateYearAbatedRanges,
    isDateBetween,
    isSameOrBefore,
    calculateLeaseLogic_RS as calculateLeaseLogic,
    checkTermValidity,
    getAbatedTotal,
    createYearRows
} from "../../RSheetsLogic";

import TermForm from "./TermForm";

const TermAbated = (props) => {
    const ref = useRef(null);
    const [rangeError, setRangeError] = useState("");

    // FIXME - remove
    const [showRangeTable, setShowRangeTable] = useState(false);
    const [showRangesTable, setShowRangesTable] = useState(null);

    const [askDeleteRange, setAskDeleteRange] = useState(null);
    const {
        year_index,
        data,
        updateDataObj,
        setData,
        localData,
        setLocalData,
        editRange,
        setEditRange,
        scrollRef,
        handleScrollClick,
        setScrollRef,
    } = props;
    const year_index_num = parseInt(year_index);
    const i = year_index_num;
    const lease_term = get(data, "lease_term", []);
    const abated_term = data.abated_rent_ranges;

    const lt = data.abated_rent_ranges ? data.abated_rent_ranges[year_index_num] : null;
    const the_term = data.abated_rent_ranges;
    const field = "abated_rent_ranges";
    const termValidation = checkTermValidity(lt, data, field);

	const isActualDays = get(data, 'commission_based_off') === 'Actual Days';
    const yearTotalAbatedRanges = calculateYearAbatedRanges({ lease_term, year_abated: lt.ranges });
    const yearTotalAbatedValues = calculateTermsTotal(yearTotalAbatedRanges, {
		isActualDays,
	});
    const isEstimated = false;

	const isCPI = get(data, 'rate_type') === 'Fixed Percentage';

    useEffect(() => {
        const rangesTable = the_term.map((lt) => {
            return lt.ranges.map(() => {
                return { open: false };
            });
        });
        setShowRangesTable(rangesTable);
    }, [the_term]);

    useEffect(() => {
        if (scrollRef) {
            handleScrollClick(ref);
            setScrollRef(null);
        }
    }, [scrollRef]);

    if (!lt) return null;

    const isShowingRanges = (yearIndex, rangeIndex) =>
        get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, { open: false }).open;
    const showRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = true;
            setShowRangesTable(newShowRangesTable);
        }
    };

    const hideRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = false;
            setShowRangesTable(newShowRangesTable);
        }
    };

    const checkMonths = (yearIndex, isAbated = true) => {
        if (isEstimated) {
            if (!localData.month1 || !localData.month2 || !localData.day1 || !localData.day2) {
                return <div style={{ color: "red" }}>Month Start, Month End, Day Start and Day End are required!</div>;
            }
            const start = parseInt(get(localData, "month1", "").split(" ")[1]);
            const end = parseInt(get(localData, "month2", "").split(" ")[1]);

            const dayStart = parseInt(get(localData, "day1", "").split(" ")[1]);
            const dayEnd = parseInt(get(localData, "day2", "").split(" ")[1]);

            if (end < start) {
                return <div style={{ color: "red" }}>Month End must be after Month Start</div>;
            } else if (end === start && dayEnd <= dayStart) {
                return <div style={{ color: "red" }}>Day End must be after Day Start</div>;
            }
        }

        let yearRanges = isAbated
            ? get(abated_term, `[${yearIndex}].ranges`, [])
            : get(lease_term, `[${yearIndex}].ranges`, []);
        yearRanges = clone(yearRanges);
        if (editRange && editRange.year_index !== -1 && editRange.range_index !== -1) {
            yearRanges.splice(editRange.range_index, 1);
        }
        if (yearRanges.length >= 1 && editRange.range_index !== 0) {
            const firstRange = first(yearRanges);

            if (isEstimated) {
                if (isSameOrBefore(localData.month1, localData.day1, firstRange.month1, firstRange.day1)) {
                    return <div style={{ color: "red" }}>Month Start and Day Start must be after first range</div>;
                }
            } else {
                if (moment(localData.date1).isSameOrBefore(moment(firstRange.date1))) {
                    return <div style={{ color: "red" }}>Date Start must be after first range</div>;
                }
            }
        }

        let error = null;

        yearRanges.forEach((range) => {
            if (isEstimated) {
                if (
                    isDateBetween(
                        { month: localData.month1, day: localData.day1 },
                        { month: range.month1, day: range.day1 },
                        { month: range.month2, day: range.day2 },
                        true
                    )
                ) {
                    if (!error) {
                        error = (
                            <div style={{ color: "red" }}>
                                Month Start and Day Start must not in between other ranges
                            </div>
                        );
                    }
                }
            } else {
                if (isDateBetween(localData.date1, range.date1, range.date2)) {
                    if (!error) {
                        error = <div style={{ color: "red" }}>Date Start must not be between other ranges</div>;
                    }
                }
            }
        });

        return error;
    };

    let abated_total = 0;

    const tableData = calculateAbatedTable(yearTotalAbatedRanges);

    const { calcsy } = props;



    return (
        <div>
            {termValidation.errors && (
                <div className="red-text mt-20 mb-20 red-fixed-error">{termValidation.errors}</div>
            )}
            <div className="boxed" style={{ backgroundColor: "#fafafa" }}>
                {lt.ranges.length === 0 && (
                    <div className="text-center">
                        <em>No Ranges</em>
                    </div>
                )}
                {lt.ranges.map((ltt, j) => {
                    console.log('TermAbated', get(calcsy, `abated_term_calculations.years[${i}].ranges[${j}].ra`, [])
                    .map(atc => {
                        return [
                            atc.date1,
                            atc.date2,
                            isActualDays ? atc.actual_days_in_this_month : 30,
                            atc.days_in_range,
                            `${atc.commission_rate} %`,
                            atc.value
                        ]
                    }));

                    const calcsy_ltt = get(calcsy, `abated_term_calculations.years[${i}].ranges[${j}]`, {
                        value: '',
                        date1: '',
                        date2: ''
                    });
                    const value = calcsy_ltt.value;
                    const date1 = calcsy_ltt.date1;
                    const date2 = calcsy_ltt.date2;
                    const commissionRate = 0;
                    const { usePercentage, percent } = ltt;

                    let totalCommission = 0;
                    // const { value, date1, date2, commissionRate, usePercentage, percent } = ltt;
                    const isRequired = true;

                    const calcs = calculateLeaseLogic({
                        date1,
                        date2,
                        value,
                        data,
                    });

                    let allTotal = 0;
                    calcs.forEach((cc) => {
                        allTotal += cc.calcRounded;
                        abated_total += cc.calcRounded;
                    });

                    totalCommission += commissionRate ? commissionRate * 0.01 * allTotal : 0;
                    const year_start = lt.year_start;
                    const year_end = lt.year_end;
                    const dates_passed = validateDatesAndRange(localData.date1, localData.date2, year_start, year_end);
                    const dates_are_in_range = validateDatesAndRange(localData.date1, localData.date2, year_start, year_end);

                    let current_year_range = "";
                    if (year_start && year_end) {
                        current_year_range = `${timestampToDate(year_start * 1000)} - ${timestampToDate(
                            year_end * 1000
                        )}`;
                    }
                    let range_requirements_passed = true;
                    if (field === "lease_term") {
                        if (
                            !dates_passed ||
                            !dates_are_in_range ||
                            !localData.date1 ||
                            !localData.date2 ||
                            !localData.commissionRate ||
                            !localData.value
                        ) {
                            range_requirements_passed = false;
                        }
                    } else {
                        if (
                            !dates_passed ||
                            !dates_are_in_range ||
                            !localData.date1 ||
                            !localData.date2 ||
							(!localData.value && !(localData.usePercentage && localData.percent))
                        ) {
                            range_requirements_passed = false;
                        }
                    }

                    const abatedRanges = calculateAbatedValues({
                        lease_term,
                        abated_range: ltt,
                    });

                    const totalAbated = calculateTermsTotal(abatedRanges);

                    return (
                        <div key={`ranges-${j}=${i}`} ref={scrollRef === `${j}-${i}` ? ref : null}>
                            <h4 className="bold black-text mr-20" style={{ display: "inline-block" }}>
                                Range #{j + 1}
                            </h4>
                            {(editRange.range_index !== j || editRange.year_index !== i) && (
                                <Button
                                    color="rose"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: i,
                                            range_index: j,
                                        });
                                        setLocalData({
                                            value,
                                            date1,
                                            date2,
                                            commissionRate,
                                            usePercentage,
                                            percent,
											year_index: i,
											range_index: j,
                                        });
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-edit hoverable"></i> EDIT
                                    </span>
                                </Button>
                            )}
                            {editRange.range_index === j && editRange.year_index === i && (
                                <Button
                                    disabled={!checkMonths(i) && range_requirements_passed ? false : true}
                                    color="primary"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: -1,
                                            range_index: -1,
                                        });
                                        const newData = clone(data);
                                        newData[field][editRange.year_index].ranges[editRange.range_index] = {
                                            value: localData.value,
                                            date1: localData.date1,
                                            date2: localData.date2,
                                            commissionRate: localData.commissionRate,
                                            usePercentage: localData.usePercentage,
                                            percent: localData.percent,
                                        };
                                        setData(newData);
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-check hoverable"></i> SAVE
                                    </span>
                                </Button>
                            )}
                            {editRange.range_index === j && editRange.year_index === i && (
                                <Button
                                    color="white"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: -1,
                                            range_index: -1,
                                        });
                                        if (field === "lease_term") {
                                            if (!date1 || !date2 || !value || !commissionRate) {
                                                setAskDeleteRange({ j, i });
                                            }
                                        } else {
                                            if (!date1 || !date2 || !value) {
                                                setAskDeleteRange({ j, i });
                                            }
                                        }
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-times hoverable"></i> CANCEL
                                    </span>
                                </Button>
                            )}
                            <Button
                                color="white"
                                onClick={() => {
                                    setAskDeleteRange({ j, i });
                                }}
                            >
                                <span className="">
                                    <i className="fas fa-trash hoverable"></i> DELETE
                                </span>
                            </Button>
                            <div>
                                {(editRange.range_index !== j || editRange.year_index !== i) && (
                                    <div className="boxed mt-20 white-bg">
                                        <GridContainer>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Monthly $<br />
                                                    Value
                                                </div>
                                                <strong>{formatDollar(value)}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Date
                                                    <br />
                                                    Start
                                                </div>
                                                <strong>{calcsy_ltt.date1}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Date
                                                    <br />
                                                    End
                                                </div>
                                                <strong>{calcsy_ltt.date2}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Total
                                                    <br />
                                                    Commission
                                                </div>
                                                <strong>{formatDollar(calcsy_ltt.total_commission_value)}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Total
                                                    <br />
                                                    Abated
                                                    <br />
                                                    Value
                                                </div>
                                                <span
                                                    className="text-underline hovered black-text bold"
                                                    onClick={() => {
                                                        if (isShowingRanges(i, j)) {
                                                            hideRanges(i, j);
                                                        } else {
                                                            showRanges(i, j);
                                                        }
                                                    }}
                                                >
                                                    {formatDollar(calcsy_ltt.total_abated_value)}
                                                </span>
                                            </GridItem>
                                            {field === "lease_term" && (
                                                <GridItem xs={12} sm={2} md={2}>
                                                    <div>
                                                        Total <br />
                                                        Commission :
                                                    </div>
                                                    {totalCommission ? `$ ${commatize(totalCommission, true)}` : 0}
                                                </GridItem>
                                            )}
                                            <GridItem xs={12} sm={2} md={2}>
                                                {field === "lease_term" && (
                                                    <div>
                                                        Range's Total{" "}
                                                        {field === "lease_term" ? "Rent Collected" : "Abated Rent"} :
                                                        <br />{" "}
                                                        <span
                                                            className="text-underline hovered black-text bold"
                                                            onClick={() => {
                                                                setShowRangeTable(!showRangeTable);
                                                            }}
                                                        >
                                                            ${" "}
                                                            {allTotal ? (
                                                                <span>
                                                                    {field === "lease_term" ? "" : "-"}
                                                                    {commatize(allTotal, true)}
                                                                </span>
                                                            ) : (
                                                                0
                                                            )}
                                                        </span>
                                                    </div>
                                                )}
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                )}
                            </div>
                            {editRange && (
                                <div>
                                    {editRange.range_index === j && editRange.year_index === i && (
                                        <div
                                            className="boxed mt-20 white-bg"
                                            style={{
                                                backgroundColor: "white",
                                            }}
                                        >
                                            <TermForm
                                                value={localData.value}
                                                date1={localData.date1}
                                                date2={localData.date2}
                                                commissionRate={localData.commissionRate}
                                                flat_fee={localData.flat_fee}
                                                usePercentage={localData.usePercentage}
                                                percent={localData.percent}
                                                update={(fieldType, val) => {
                                                    const newLocalData = clone(localData);
                                                    newLocalData[fieldType] = val;
                                                    setLocalData(newLocalData);
                                                }}
                                                updateMultiFields={(obj) => {
                                                    const newLocalData = clone(localData);
                                                    Object.keys(obj).forEach((key) => {
                                                        newLocalData[key] = obj[key];
                                                    });
                                                    setLocalData(newLocalData);
                                                }}
                                                isRequired={isRequired}
                                                allTotal={allTotal}
                                                setShowRangeTable={setShowRangeTable}
                                                showRangeTable={showRangeTable}
                                                field={field}
                                                currentYear={i + 1}
                                                isNextRange={j !== 0 || i !== 0}
                                                isCPI={isCPI}
                                                isAbated
                                                currentIndex={j}
                                            />
                                            <hr />
                                            <em>
                                                * Requirements : Start date must be earlier than End date. All fields
                                                are required.
                                            </em>
                                            {!dates_passed && (
                                                <div style={{ color: "red" }}>
                                                    Invalid Date(s), valid MM/DD/YYYY date is required.
                                                </div>
                                            )}
                                            {!dates_are_in_range && (
                                                <div style={{ color: "red" }}>
                                                    Date Range must be within current year range ({current_year_range}).
                                                </div>
                                            )}
                                            {checkMonths(i)}
                                        </div>
                                    )}
                                </div>
                            )}
                            {isShowingRanges(i, j) && (
                                <Table
                                    tableHead={[
                                        "Start Date",
                                        "End Date",
                                        "Num of Days",
                                        "Days in Month",
                                        "Commission Value",
                                        "Total Abated Rent",
                                    ]}
                                    tableData={get(calcsy, `abated_term_calculations.years[${i}].ranges[${j}].ra`, [])
                                        .map(atc => {
                                            return [
                                                atc.date1,
                                                atc.date2,
                                                atc.days_in_range,
                                                isActualDays ? atc.actual_days_in_this_month : 30,
                                                // atc.days_in_range,
                                                //`${atc.commission_rate} %`,
                                                formatDollar(atc.commission_value),
                                                formatDollar(atc.value)
                                            ]
                                        })}
                                />
                            )}
                        </div>
                    );
                })}
                {lt.ranges.length > 0 && (
                    <div>
                        {
                            <h4>
                                Year {i + 1} Total Annual Amount Abated :
                                <strong>
                                    ({formatDollar(get(calcsy, `abated_term_calculations.years[${i}].year_total_abated_value`, 0), true)})
                                </strong>
                            </h4>
                        }
                    </div>
                )}
                {rangeError && <div className="red-text mt-20">{rangeError}</div>}
                <hr />
            </div>

            {askDeleteRange && (
                <Dialog
                    open={askDeleteRange ? true : false}
                    onClose={() => {
                        setAskDeleteRange(null);
                    }}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <div className="dialog-wrapper text-center">
                        <h4>Are you sure you want to delete this range?</h4>
                        <Button
                            color="primary"
                            onClick={() => {
                                const { i, j } = askDeleteRange;
                                const newTerm = clone(the_term);
                                newTerm[i].ranges = newTerm[i].ranges.filter((r, ii) => {
                                    if (ii !== j) return true;
                                });
                                updateDataObj(field, newTerm);
                                setAskDeleteRange(null);
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setAskDeleteRange(null);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default TermAbated;
