import { useEffect } from "react";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import { get } from "lodash";
import Loader from "../../../components/Loader/Loader";
import React from "react";
import ReactTable from "react-table";
import { statusDescriptions } from "../RSheetsLogic";
import moment from "moment";

const RSHistory = (props) => {
    console.log('RSHistory', props);
    const rs_type = props.rs_type ? props.rs_type : '';
    const data = props.history_list ? props.history_list.map(h => {
        const l = h.json_data ? h.json_data : {};
        const title = get(props, 'rsheetData.title', '') ? get(props, 'rsheetData.title', '') : '';
        console.log('title', title)
        let previous_ids = title.split('_');
        previous_ids.sort(function(a, b) {
            return a - b;
        });
        const revision_number = (previous_ids.indexOf(h.rs_id) + 1) ? (previous_ids.indexOf(h.rs_id) + 1) : '';
        return {
            id: h.id,
            rs_id: <div style={{height: '48px', lineHeight: '48px'}}>{h.rs_id}</div>,
            name: h.name,
            illi_number: l.illi_number,
            project_name: l.project_name,
            date_created: moment(h.timestamp.replace("T", " ")),
            title: l.title ? `${rs_type} - Revision ${revision_number}` : rs_type,
            status: statusDescriptions[h.status] ? statusDescriptions[h.status] : "",
            notes: h.notes
        }
    }).sort((a, b) => {
        if (a.id < b.id) {
          return -1;
        }
        if (a.id > b.id) {
          return 1;
        }
      
        // names must be equal
        return 0;
      }).filter(d => {
        if (d) return true;
    }) : [];
    const columns = [
        {
            Header: "id #",
            accessor: "rs_id",
            filterMethod: (filter, row) => {
                console.log('f', filter.value, row.id);
                if (filter.value) {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        if (row.id) {
                            if (row.id.toString().indexOf(filterValue) !== -1) {
                                return true;
                            }
                        }
                        return false;
                    } else {
                        return true;
                    }
                }
            },
        },
        {
            Header: "illi #",
            accessor: "illi_number",
            filterMethod: (filter, row) => {
                if (filter.value) {
                    var filterValue = filter.value.toLowerCase();
                    if (filterValue.length > 0) {
                        if (row.illi_number) {
                            if (row.illi_number.toString().indexOf(filterValue) !== -1) {
                                return true;
                            }
                        }
                        return false;
                    } else {
                        return true;
                    }
                }
            },
        },
        {
            Header: "Project Name",
            accessor: "project_name",
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) !== -1 : true;
            }
        },
        {
            Header: "User",
            accessor: "name",
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                return row[id] !== undefined ? String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) !== -1 : true;
            }
        },
        {
            Header: "Date / Time",
            accessor: "date_created",
            Cell: (props) => {
                return (
                    <div>
                        {props.value.format("MM/DD/YYYY h:mm a")}
                    </div>
                );
            },
            filterMethod: (filter, row, column) => {
                const id = filter.pivotId || filter.id;
                if (row[id]) {
                    const formattedDate = moment.unix(row[id]).subtract(8, 'h').format("MM/DD/YYYY h:mm a")
                    return formattedDate.toString().indexOf(filter.value.toLowerCase()) !== -1;
                }
                return false
            }
        },
        {
            Header: "Status",
            accessor: "status",
        },
        {
            Header: "Notes",
            accessor: "notes",
        },
    ];
    useEffect(() => {
        let rs_ids = [];
        const rs_id = get(props, 'rsheetData.id', null);
        if (rs_id) rs_ids.push(rs_id);
        const previous_ids = get(props, 'rsheetData.title', null);
        if (previous_ids) {
            rs_ids = rs_ids.concat(previous_ids.split('_'));
        }
        props.getRsHistories(rs_ids.join(','));
    }, []);

    return (
        <div>
            <h2>History</h2>
            <div className="react-table-hover-gray">
                <ReactTable
                    defaultSorted={[
                        {
                            id: "date",
                            asc: true,
                        },
                    ]}
                    data={data}
                    className="-striped"
                    showPaginationTop
                    showPaginationBottom
                    columns={columns}
                    resizable
                    defaultPageSize={10}
                    pageSizeOptions={[10, 20, 30]}
                    minRows={10}
                    filterable
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        history_list: state.rs.history_list,
        error_history: state.rs.error_history,
        success_history: state.rs.success_history,
        loading_history: state.rs.loading_history
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRsHistories: (data) => {
            dispatch(actions.getRsHistories(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSHistory);

