import React from "react";
import { outside_brokers_fields } from "../RSheetsFieldsModel";

export const calculateBrokerCommission = (field, data) => {
    let commission = 0;
    if (data) {
        if (data[field]) {
            if (Array.isArray(data[field])) {
                data[field].forEach(df => {
                    if (df.commission) {
                        commission += parseFloat(df.commission.replace(/\,/g,''));
                    }
                })
            }
        }
    }
    return commission;
}

export const brokersLogic = {
    validate: (field, data) => {
        // check for completeness, all required fields
        let pass = true;
        if (data[field]) {
            if (Array.isArray(data[field])) {
                data[field].forEach(df => {
                    // check if default required
                    Object.keys(outside_brokers_fields).forEach(field => {
                        if (outside_brokers_fields[field].required && !df[field]) {
                            pass = false;
                        }
                    })
                    // 
                    if (df.w9 === 'Yes') {
                        if (!df.w9_file) pass = false;
                    }
                    if (df.contact_id && (!df.first_name || !df.last_name)) {
                        pass = false;
                    } 
                    if (df.company_id && !df.company_name) {
                        pass = false;
                    } 
                })
            }
        }
        return pass;
    }
};

export const renderOutsideBrokersHTML = (props) => {
    const fields = Object.keys(outside_brokers_fields);

    const {
        data,
        title,
        field
    } = props;

    const list = data[field] ? data[field] : [];

    return `
    <style>
    .vtop {
        vertical-align:top;
      }
      
      .bordered-list ul {
        list-style-type: none;
        padding: 0;
        border: 1px solid #878787;
      }
      
      .bordered-list ul li {
        padding: 8px 16px;
        border-bottom: 1px solid #878787;
      }
      
      .bordered-list ul li:hover {
        background-color: #ccc;
      }
      
      .bordered-list ul li:last-child {
        border-bottom: none;
      }
      .list-group {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        border-radius: .25rem;
      }
      
      .list-group-item {
        position: relative;
        display: block;
        padding: .3rem 1rem;
        background-color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.125);
      }
      
      .list-group-item+.list-group-item {
        border-top-width: 0;
      }
    </style>
    <div class="group-wrap">
                <h4 style="text-decoration:underline">${title} - OUTSIDE BROKER</h4>
                ${list.map((l,i) => {
                    return `<div style="margin:20px">
                            <h5>#${i+1}</h5>
                            <ul class="list-group">
                                ${fields.map((fld,x) => {
                                    let prefix = '';
                                    let suffix = '';
                                    if (fld === 'tax_id') prefix = '';
                                    if (fld === 'w9_upload') return '';

                                    // CONTACT OR COMPANY
                                    if (!l.company_name && fld === 'company_name') return '';
                                    if (!l.first_name && fld === 'first_name') return '';
                                    if (!l.last_name && fld === 'last_name') return '';

                                    // DBA 
                                    if (!l.dba_name && fld === 'dba_name') return '';

                                    // PHONE
                                    if (l.overrideContactPhone) {
                                        if (l.overrideContactPhone.type === 'Work') {
                                            if (fld === 'main_phone') return '';
                                            if (fld === 'mobile_phone') return '';
                                        }
                                        if (l.overrideContactPhone.type === 'Mobile') {
                                            if (fld === 'work_phone') return '';
                                            if (fld === 'main_phone') return '';
                                        }
                                        if (l.overrideContactPhone.type === 'Main') {
                                            if (fld === 'work_phone') return '';
                                            if (fld === 'mobile_phone') return '';
                                        }
                                    } else {
                                        if (fld === 'work_phone') return '';
                                        if (fld === 'mobile_phone') return '';
                                    }
                                    // COMMISSION 
                                    if (fld === 'commission') {
                                        suffix = '%';
                                    }
                                    return `<li class="list-group-item">
                                        <strong>${outside_brokers_fields[fld].label}</strong> : ${prefix}${l[fld] !== undefined && l[fld] !== null ? l[fld] : ''}${suffix}
                                    </li>`
                                }).join('')}
                            </ul>
                        </div>`
                }).join('')}
            </div>`
}

const BrokersFullForm = (props) => {
    const fields = Object.keys(outside_brokers_fields);

    const {
        data,
        title,
        field
    } = props;

    const list = data[field] ? data[field] : [];

    return <>
            <div className="group-wrap">
                <h4 style={{ textDecoration: "underline" }}>{title} - OUTSIDE BROKER</h4>
                {list.map((l,i) => {
                    return <div style={{margin:20}} key={`ob-${i}`}>
                            <h5>#{i+1}</h5>
                            <ul className="list-group">
                                {fields.map((fld,x) => {
                                    let prefix = '';
                                    let suffix = '';
                                    if (fld === 'tax_id') prefix = '$ ';

                                    if (fld === 'w9_upload') return null;
                                    let isRequired = false;
                                    // W9
                                    let fileType = '';
                                    if (l.w9 === 'Yes') {
                                        if (fld === 'w9_upload') isRequired = true;
                                    }
                                    if (fld === 'w9_upload') fileType = 'rs';
                
                                    // CONTACT OR COMPANY
                                    if (!l.company_name && fld === 'company_name') return null;
                                    if (!l.first_name && fld === 'first_name') return null;
                                    if (!l.last_name && fld === 'last_name') return null;
                
                                    // DBA 
                                    if (!l.dba_name && fld === 'dba_name') return null;
                
                                    // PHONE
                                    if (l.overrideContactPhone) {
                                        if (l.overrideContactPhone.type === 'Work') {
                                            if (fld === 'main_phone') return null;
                                            if (fld === 'mobile_phone') return null;
                                        }
                                        if (l.overrideContactPhone.type === 'Mobile') {
                                            if (fld === 'work_phone') return null;
                                            if (fld === 'main_phone') return null;
                                        }
                                        if (l.overrideContactPhone.type === 'Main') {
                                            if (fld === 'work_phone') return null;
                                            if (fld === 'mobile_phone') return null;
                                        }
                                    } else {
                                        if (fld === 'work_phone') return null;
                                        if (fld === 'mobile_phone') return null;
                                    }
                                    // COMMISSION 
                                    if (fld === 'commission') {
                                        suffix = '%';
                                    }
                                    return <li className="list-group-item" key={`ob-${i}-${x}`}>
                                        <strong>{outside_brokers_fields[fld].label}</strong> : {prefix}{l[fld]}{suffix}
                                    </li>
                                })}
                            </ul>
                        </div>
                })}
            </div>
            
    </>
}

export default BrokersFullForm;