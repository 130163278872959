import React from "react";
import PropTypes from "prop-types";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import PagesHeader from "components/Header/PagesHeader.jsx";
import Footer from "components/Footer/Footer.jsx";

import pagesRoutes from "routes/ertl.jsx";

import pagesStyle from "assets/jss/material-dashboard-pro-react/layouts/pagesStyle.jsx";

import bgImage from "assets/img/register.jpeg";

// var ps;

class ERTL extends React.Component {
    render() {
        const { classes, ...rest } = this.props;
        return (
            <div>
                <Switch>
                    {pagesRoutes.map((prop, key) => {
                        const Component = prop.component;
                        const name = prop.name;

                        if (name === "ERTL Review Client Login") {
                            return (
                                <Route
                                    path={prop.path}
                                    render={(routeProps) => (
                                        <React.Fragment>
                                            <PagesHeader {...rest} />
                                            <div
                                                className={classes.wrapper}
                                                ref="wrapper"
                                            >
                                                <div
                                                    className={classes.fullPage}
                                                >
                                                    <Component
                                                        {...routeProps}
                                                    />
                                                </div>
                                                <Footer white />
                                                <div
                                                    className={
                                                        classes.fullPageBackground
                                                    }
                                                    style={{
                                                        backgroundImage:
                                                            "url(" +
                                                            bgImage +
                                                            ")",
                                                    }}
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    key={key}
                                />
                            );
                        } else {
                            return (
                                <Route
                                    path={prop.path}
                                    component={prop.component}
                                    key={key}
                                />
                            );
                        }
                    })}
                </Switch>
            </div>
        );
    }
}


export default withStyles(pagesStyle)(ERTL);
