import React, { useEffect, useState } from "react";
import { clone, cloneJ, createObjName, extractContactAddress, formatDollar, round2dec, theVal, twoDecimals } from "../../../shared/utility";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import Button from "../../../components/CustomButtons/Button";
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import Element from "components/Element";
import { Delete } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import CustomRadio from "components/CustomRadio/CustomRadio";
import termLogic from "./Term/TermLogicMain";
import { parseFloatString } from "../../../shared/utility";

const OutsideBrokersForm = props => {
    const { tableData } = props;
    return <>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-40"><strong>Outside Broker Name</strong></td>
                        <td className="col-30"><strong>Percentage</strong></td>
                        <td className="col-30"><strong>Value</strong></td>
                    </tr>
                    {tableData.map((l,i) => {
                        let percentage = '';
                        let value = '';
                        if (l.percentage !== 'N/A') {
                            percentage = twoDecimals(l.percentage * 100) + ' %';
                            value = `$${twoDecimals(l.value)}`;
                        } else {
                            percentage = 'N/A';
                            value = `$${twoDecimals(l.value)}`;
                        }
                        return <tr key={`row-${i}-ob`}>
                            <td className="col-40">{l.name}</td>
                            <td className="col-30">{percentage}</td>
                            <td className="col-30">{value}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </>
}

export const ReferralsForm = props => {
    const { tableData } = props;
    return <>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-40"><strong>Referral Name</strong></td>
                        <td className="col-30"><strong>Percentage</strong></td>
                        <td className="col-30"><strong>Value</strong></td>
                    </tr>
                    {tableData.map((l,i) => {
                        let percentage = '';
                        let value = '';
                        if (l.percentage !== 'N/A') {
                            percentage = twoDecimals(l.percentage * 100) + ' %';
                            value = `$${twoDecimals(l.value)}`;
                        } else {
                            percentage = 'N/A';
                            value = `$${twoDecimals(l.value)}`;
                        }
                        return <tr key={`row-${i}-ob`}>
                            <td className="col-40">{l.name}</td>
                            <td className="col-30">{percentage}</td>
                            <td className="col-30">{value}</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    </>
}

export default OutsideBrokersForm;
