import { put } from 'redux-saga/effects';
import axios from '../axios/axios-tr';
import * as actions from '../actions/tr_templates';

export function* getTrTemplatesSaga(action) {
    yield put(actions.getTrTemplatesStart());
    try {
        const response = yield axios.get('/templates?id='+action.data);
        yield put(actions.getTrTemplatesSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getTrTemplatesFail(e.response.data));
            }
          }
        yield put(actions.getTrTemplatesFail(e));
    }
}

export function* createTrTemplateSaga(action) {
    yield put(actions.createTrTemplateStart());
    try {
        const response = yield axios.post('/template', action.data);
        yield put(actions.createTrTemplateSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createTrTemplateFail(e.response.data));
            }
          }
        yield put(actions.createTrTemplateFail(e));
    }
}

export function* updateTrTemplateSaga(action) {
    yield put(actions.updateTrTemplateStart());
    try {
        const response = yield axios.put('/template', action.data);
        yield put(actions.updateTrTemplateSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateTrTemplateFail(e.response.data));
            }
          }
        yield put(actions.updateTrTemplateFail(e));
    }
}

export function* deleteTrTemplateSaga(action) {
    yield put(actions.deleteTrTemplateStart());
    try {
        const response = yield axios.delete('/template?id='+action.data);
        yield put(actions.deleteTrTemplateSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteTrTemplateFail(e.response.data));
            }
          }
        yield put(actions.deleteTrTemplateFail(e));
    }
}