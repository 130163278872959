import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import React from 'react';
import LogHistory from '../../containers/ContactsImport/LogHistory/LogHistory';
import Importer from '../../containers/ContactsImport/Importer';

const ContactsImportPage = (props) => {
	const { subTab, setSubTab } = props;

	return (
		<div style={{ marginTop: 20 }}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Card>
						<CardHeader color='rose' icon>
							<CardIcon
								color={subTab === 'DialPad' ? 'success' : 'rose'}
								onClick={() => {
									setSubTab('DialPad');
								}}
								className='hovered'
							>
								<strong>DialPad</strong>
							</CardIcon>
							<CardIcon
								color={subTab === 'Log History' ? 'success' : 'rose'}
								onClick={() => {
									setSubTab('Log History');
								}}
								className='hovered'
							>
								<strong>Log History</strong>
							</CardIcon>
							<CardIcon
								color={subTab === 'Google Contacts' ? 'success' : 'rose'}
								onClick={() => {
									setSubTab('Google Contacts');
								}}
								className='hovered'
							>
								<strong>Google Contacts</strong>
							</CardIcon>
						</CardHeader>
						<CardBody>
							{subTab === 'DialPad' && <Importer importType="dialpad" />}
							{subTab === 'Log History' && <LogHistory />}
							{subTab === 'Google Contacts' && <Importer importType="google" />}
						</CardBody>
					</Card>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default ContactsImportPage;
