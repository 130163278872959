import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";
import { textData, data } from '../ERTLObj';
import { Tooltip } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import CustomInput from '../../../components/CustomInput/CustomInputValidate';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import ERTLBaseTemplate from 'containers/ERTL/ERTLSharedComponents/ERTLBaseTemplate';
import { getDifferences } from '../FormWizard/ERTLLogic';
import Notification from '../../../components/Notification/Notification';
import { clone } from 'shared/utility';

const Modal = (props) => {
    const { currentId, setEditedObj, editedObj, setTemplateText, templateText, field } = props;
    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="md"
                fullWidth={true}
            >
                <div style={{ margin: 20 }}>
                    <div className="custom-textfield">
                        <TextField
                            label=""
                            multiline
                            rowsMax="4"
                            value={templateText[field]}
                            onChange={(e) => {
                                const newObj = { ...templateText };
                                newObj[field] = e.target.value;
                                
                                const newEditedObj = { ...editedObj };
                                newEditedObj[currentId] = { ...newObj }
                                setTemplateText(newObj);
                                setEditedObj(newEditedObj);
                            }}
                            margin="normal"
                        />
                    </div>
                    <br />
                    <Button color="rose" onClick={() => { props.close() }}>OK</Button>
                </div>
            </Dialog>
        </div>
    )
}

const ERTLEditTemplate = (props) => {
    const { currentId, editedObj, setEditedObj, templateText, setTemplateText } = props;
    const [openedModal, setOpenedModal] = useState(false);
    const [shouldShow, setShouldShow] = useState(true);
    const [openedField, setOpenedField] = useState('');
    const [templateTitle, setTemplateTitle] = useState('');
    const [notification, setNotification] = useState('');
    useEffect(() => {
        if (props.templateTitle) {
            setTemplateTitle(props.templateTitle);
        }
    }, []);

    const openModal = (field) => {
        setOpenedField(field);
        setOpenedModal(true);
    }

    

    return (
        <div>
            <div>
                <div
                    className="inline-block blue-text hoverable mr-20"
                    onClick={() => {
                        props.closeForm();
                    }}
                >
                    <Tooltip title='Back' placement='top'>
                        <ArrowBack />
                    </Tooltip>
                </div>
            </div>
            <Modal
                open={openedModal}
                close={() => {
                    setOpenedModal(false);
                }}
                field={openedField}
                templateText={templateText}
                setTemplateText={setTemplateText}
                setEditedObj={setEditedObj}
                editedObj={editedObj}
                currentId={currentId}
            />
            <br />
            <div style={{ padding: 20, backgroundColor: '#fafafa' }}>
                <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                        <h3><strong>TEMPLATE OUTPUT PREVIEW</strong></h3>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        {!props.noActions && <CustomInput
                            label="Template Title"
                            value={templateTitle}
                            onChange={(e) => {
                                setTemplateTitle(e.target.value)
                            }}
                            validate={[]}
                        />}
                    </GridItem>
                </GridContainer>
                <div><Button
                    color={shouldShow ? 'white' : 'rose'}
                    onClick={() => {
                        setShouldShow(!shouldShow);
                    }}
                    style={{ marginBottom: 10 }}
                >
                    {shouldShow ? 'HIDE CHANGE INDICATORS' : 'SHOW CHANGE INDICATORS'}
                </Button></div>
                <ERTLBaseTemplate 
                    ertlFormData={{}}
                    openDataModal={null}
                    textData={textData}
                    openModal={() => {}}
                    getDifferences={getDifferences}
                    templateText={templateText}
                    shouldShow={true}
                    origin="Edit Template"
                    templateOnClick={(f) => {
                        if (props.noActions) return;
                        openModal(f);
                    }}
                />
                <hr />
                {!props.noActions && 
            <div className="bottom-dweller">
                {!templateTitle && <div className="red-text">Template title is required.</div>}
                <Button
                    color="success"
                    onClick={() => {
                        if (templateTitle === 'Base Template') return setNotification('Please change the title name to other than Base Template.');
                        if (templateTitle === props.templateTitle) return setNotification('Duplicate titles are not allowed for new templates')
                        props.createTemplate(templateText, templateTitle);
                        props.closeForm();
                    }}
                    style={{ marginTop: 10 }}
                    disabled={templateTitle ? false : true}
                    className="mr-20"
                >
                    Save As New Template
                </Button>
                {props.templateTitle !== 'Base Template' && <Button
                    color="primary"
                    onClick={() => {
                        if (templateTitle === 'Base Template') return setNotification('Please change the title name to other than Base Template.');
                        const newData = clone(templateText);
                        newData.title = templateTitle;
                        props.updateErtlTemplate({
                            data: JSON.stringify(newData), 
                            title: templateTitle,
                            id: currentId
                        });
                        props.closeForm();
                    }}
                    style={{ marginTop: 10 }}
                    disabled={templateTitle ? false : true}
                    className="mr-20"
                >
                    Save
                </Button>}
            </div>}
            </div>

            {notification && <Notification 
                open={notification ? true : false}
                close={() => {
                    setNotification('');
                }}
                message={notification}
            />}
        </div>
    );

}

export default ERTLEditTemplate;