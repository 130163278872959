import * as actionTypes from '../actions/actionTypes';
import { updateObject, clone } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    templates: null,
    templateData: null,
    error_create: null,
    success_create: null,
    error_update: null,
    success_update: null,
    error_delete: null,
    success_delete: null
}

// GET_TR_TEMPLATES

const getTrTemplatesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        templates: null
    })
}

const getTrTemplatesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        templates: action.response
    })
}

const getTrTemplatesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_TR_TEMPLATES

const createTrTemplateStart = (state, action) => {
    return updateObject(state, {
        error_create: null,
        success_create: null,
        templateData: null
    })
}

const createTrTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success_create: 'Successfully created',
        templateData: action.response
    })
}

const createTrTemplateFail = (state, action) => {
    return updateObject(state, {
        error_create: action.err
    })
}

// UPDATE_TR_TEMPLATE

const updateTrTemplateStart = (state, action) => {
    return updateObject(state, {
        error_update: null,
        success_update: null,
        templateData: null
    })
}

const updateTrTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success_update: 'Success updated',
        templateData: action.response
    })
}

const updateTrTemplateFail = (state, action) => {
    return updateObject(state, {
        error_update: action.err
    })
}

// DELETE_TR_TEMPLATE

const deleteTrTemplateStart = (state, action) => {
    return updateObject(state, {
        error_delete: null,
        success_delete: null
    })
}

const deleteTrTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success_delete: 'Successfully deleted'
    })
}

const deleteTrTemplateFail = (state, action) => {
    return updateObject(state, {
        error_delete: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TR_TEMPLATES_START:
            return getTrTemplatesStart(state, action);
        case actionTypes.GET_TR_TEMPLATES_SUCCESS:
            return getTrTemplatesSuccess(state, action);
        case actionTypes.GET_TR_TEMPLATES_FAIL:
            return getTrTemplatesFail(state, action);

        case actionTypes.CREATE_TR_TEMPLATE_START:
            return createTrTemplateStart(state, action);
        case actionTypes.CREATE_TR_TEMPLATE_SUCCESS:
            return createTrTemplateSuccess(state, action);
        case actionTypes.CREATE_TR_TEMPLATE_FAIL:
            return createTrTemplateFail(state, action);

        case actionTypes.UPDATE_TR_TEMPLATE_START:
            return updateTrTemplateStart(state, action);
        case actionTypes.UPDATE_TR_TEMPLATE_SUCCESS:
            return updateTrTemplateSuccess(state, action);
        case actionTypes.UPDATE_TR_TEMPLATE_FAIL:
            return updateTrTemplateFail(state, action);

        case actionTypes.DELETE_TR_TEMPLATE_START:
            return deleteTrTemplateStart(state, action);
        case actionTypes.DELETE_TR_TEMPLATE_SUCCESS:
            return deleteTrTemplateSuccess(state, action);
        case actionTypes.DELETE_TR_TEMPLATE_FAIL:
            return deleteTrTemplateFail(state, action);
        
        default:
            return state;
    }
}

export default reducer;