import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import PropTypes from 'prop-types';
import PropertiesNewContact from 'containers/CRM/Properties/PropertiesNewContact';
import { clone } from '../../shared/utility';
import { getUser } from '../../shared/authValidation';
import { Tab, Tabs, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import contactFields from '../../containers/CRM/Contacts/ContactsObj';
import { contactSubTypeDecider } from 'containers/CRM/Contacts/ContactsLogic';
import CustomSelect from '../CustomSelect/CustomSelect';
import { Close } from '@material-ui/icons';

const CustomDialogWithContact = (props) => {
	const {
		indx,
		desc,
		open,
		close,
		title,
		label,
		current,
		loading,
		illi_contacts,
		own_contacts,
		own_shared_contacts,
		showContactTypes,
		userList,
		getWorkspaceUsers,
	} = props;

	const [choice, setChoice] = useState('');

	const [options, setOptions] = useState([]);

	useEffect(() => {
		const filters = ['name.fullName', 'primaryEmail'];
		let newOptions = clone(userList) || [];
		newOptions = newOptions.filter((option) => {
			let valid = false;
			filters.forEach((path) => {
				const value = _.get(option, path);
				if (value.toString().toLowerCase().includes(choice.toLowerCase())) {
					valid = true;
				}
			});
			return valid;
		});
		setOptions(newOptions);
	}, [userList, choice]);

	useEffect(() => {
		getWorkspaceUsers();
	}, []);

	const searchAPI = (the_choice) => {
		props.getContacts({
			names_only: 'All',
			search_term: the_choice,
		});
	};

	const renderContent = () => {
		return (
			<div>
				<DialogTitle>
					<div
						className='close-btn'
						onClick={() => {
							props.close();
						}}
					>
						<Close />
					</div>
					<div>{title}</div>
				</DialogTitle>
				<CustomInput label={label} value={choice} onChange={(e) => setChoice(e.target.value)} />

				<div style={{ textAlign: 'center', padding: 20 }}>
					<div
						style={{
							height: !choice ? 100 : 300,
							overflowY: 'scroll',
							padding: 10,
							backgroundColor: '#f1f1f1',
						}}
					>
						{loading && (
							<div className='text-center'>
								<ClipLoader />
							</div>
						)}
						{!choice && (
							<div style={{ marginTop: 20 }}>
								<em>
									Enter letters or numbers from the agent's name
									<br />
									you are searching for in the line above
								</em>
							</div>
						)}

						{!loading && choice && options && (
							<List>
								{options.map((user, i) => {
									return (
										<ListItem
											onClick={() => {
												props.choose(user);
											}}
											button
											key={`property-option-${i}`}
										>
											<ListItemText inset primary={user.name.fullName} />
										</ListItem>
									);
								})}
							</List>
						)}
					</div>
					<Button
						color='white'
						style={{ marginRight: 10, marginTop: 20 }}
						onClick={() => {
							props.close();
						}}
					>
						CLOSE
					</Button>
				</div>
			</div>
		);
	};

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
				<div style={{ textAlign: 'center', padding: 20 }}>{renderContent()}</div>
			</Dialog>
		</div>
	);
};

CustomDialogWithContact.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	choose: PropTypes.func.isRequired,
	indx: PropTypes.string,
	desc: PropTypes.string,
	current: PropTypes.string,
	icon: PropTypes.object,
};

const mapStateToProps = (state) => {
	const u = state.user;
	return {
		loading: u.loading,
		userList: u.userList,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getWorkspaceUsers: (params) => {
			dispatch(actions.getWorkspaceUsers(params));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogWithContact);
