import * as actionTypes from './actionTypes';

// GET ALL APPOINTMENTS

export const getAppointments = (params) => {
	return {
		type: actionTypes.GET_APPOINTMENTS,
		params
	};
};

export const getAppointmentsStart = () => {
	return {
		type: actionTypes.GET_APPOINTMENTS_START
	};
};

export const getAppointmentsSuccess = (response) => {
	return {
		type: actionTypes.GET_APPOINTMENTS_SUCCESS,
		response
	};
};

export const getAppointmentsFail = (err) => {
	return {
		type: actionTypes.GET_APPOINTMENTS_FAIL,
		err
	};
};

// GET APPOINTMENT BY EVENT ID

export const getAppointment = (id) => {
	return {
		type: actionTypes.GET_APPOINTMENT,
		id
	};
};

export const getAppointmentStart = () => {
	return {
		type: actionTypes.GET_APPOINTMENT_START
	};
};

export const getAppointmentSuccess = (response) => {
	return {
		type: actionTypes.GET_APPOINTMENT_SUCCESS,
		response
	};
};

export const getAppointmentFail = (err) => {
	return {
		type: actionTypes.GET_APPOINTMENT_FAIL,
		err
	};
};

export const getAppointmentClear = () => {
	return {
		type: actionTypes.GET_APPOINTMENT_CLEAR
	};
};

// CREATE APPOINTMENT

export const createAppointment = (data) => {
	return {
		type: actionTypes.CREATE_APPOINTMENT,
		data
	};
};

export const createAppointmentStart = () => {
	return {
		type: actionTypes.CREATE_APPOINTMENT_START
	};
};

export const createAppointmentSuccess = (response) => {
	return {
		type: actionTypes.CREATE_APPOINTMENT_SUCCESS,
		response
	};
};

export const createAppointmentFail = (err) => {
	return {
		type: actionTypes.CREATE_APPOINTMENT_FAIL,
		err
	};
};

// UPDATE APPOINTMENT

export const updateAppointment = (data) => {
	return {
		type: actionTypes.UPDATE_APPOINTMENT,
		data
	};
};

export const updateAppointmentStart = () => {
	return {
		type: actionTypes.UPDATE_APPOINTMENT_START
	};
};

export const updateAppointmentSuccess = (response) => {
	return {
		type: actionTypes.UPDATE_APPOINTMENT_SUCCESS,
		response
	};
};

export const updateAppointmentFail = (err) => {
	return {
		type: actionTypes.UPDATE_APPOINTMENT_FAIL,
		err
	};
};

// DELETE APPOINTMENT

export const deleteAppointment = (id) => {
	return {
		type: actionTypes.DELETE_APPOINTMENT,
		id
	};
};

export const deleteAppointmentStart = () => {
	return {
		type: actionTypes.DELETE_APPOINTMENT_START
	};
};

export const deleteAppointmentSuccess = (response) => {
	return {
		type: actionTypes.DELETE_APPOINTMENT_SUCCESS,
		response
	};
};

export const deleteAppointmentFail = (err) => {
	return {
		type: actionTypes.DELETE_APPOINTMENT_FAIL,
		err
	};
};

// GET_CALENDARS 

export const getCalendars = (data) => {
    return {
        type: actionTypes.GET_CALENDARS,
        data
    }
}

export const getCalendarsStart = () => {
    return {
        type: actionTypes.GET_CALENDARS_START
    }
}

export const getCalendarsSuccess = (response) => {
    return {
        type: actionTypes.GET_CALENDARS_SUCCESS,
        response
    }
}

export const getCalendarsFail = (err) => {
    return {
        type: actionTypes.GET_CALENDARS_FAIL,
        err
    }
}

// UPDATE_DEFAULT_CALENDAR 

export const updateDefaultCalendar = (data) => {
    return {
        type: actionTypes.UPDATE_DEFAULT_CALENDAR,
        data
    }
}

export const updateDefaultCalendarStart = () => {
    return {
        type: actionTypes.UPDATE_DEFAULT_CALENDAR_START
    }
}

export const updateDefaultCalendarSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_DEFAULT_CALENDAR_SUCCESS,
        response
    }
}

export const updateDefaultCalendarFail = (err) => {
    return {
        type: actionTypes.UPDATE_DEFAULT_CALENDAR_FAIL,
        err
    }
}
