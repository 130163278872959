import React from "react";
import { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { workspaceDomain } from 'shared/devStatus';
import Button from "../../components/CustomButtons/Button.jsx";

const GroupsAdminModal = (props) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [description, setDescription] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        const groupData = props.groupData;
        if (groupData) {
            setName(groupData.name);
            setEmail(groupData.email);
            setDescription(groupData.description);
        }
    }, []);

    useEffect(() => {
        
        let errors = [];
        if (!name) errors.push('Name is required.');
        if (!email) errors.push('Email is required.');
        if (name) {
            if (name.length > 200) {
                errors.push('Name cannot be more than 200 characters');
            }
        } 
        if (email) {
            if (email.length > 200) {
                errors.push('Email cannot be more than 200 characters');
            }
            if (email.indexOf(`@${workspaceDomain}`) === -1) {
                errors.push(`Email must contain the domain ${workspaceDomain}`);
            }
        }
        if (errors.length > 0) {
            setErrorMessage(errors.join(', '));
        } else {
            setErrorMessage('');
        }
    }, [name, description, email]);

    return (
    <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'center'}}>{props.action} Group</div>
        </DialogTitle>
        <form 
            autoComplete="off"
            style={{padding:20}} 
            onSubmit={(e) => { 
             e.preventDefault(); 
        }}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomInput
                        labelText="Name"
                        inputProps={{
                            value:name,
                            onChange: (e) => {
                                setName(e.target.value);
                            }
                        }}
                    />
                    <CustomInput
                        labelText="Description"
                        inputProps={{
                            value:description,
                            onChange: (e) => { 
                                setDescription(e.target.value);
                            }
                        }}
                    />
                    <CustomInput
                        labelText="Email"
                        inputProps={{
                            value:email,
                            onChange: (e) => { 
                                if (props.action !== 'Edit') {
                                    setEmail(e.target.value); 
                                }
                            },
                            readOnly: props.action == 'Edit' ? true : false
                        }}
                    />
                </GridItem>
            </GridContainer>
            

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    {props.action === 'Add' && <Button
                        color="success"
                        style={{marginRight:20}}
                        onClick={() => {
                            if (!errorMessage) {
                                props.createWorkspaceGroup({
                                    name,
                                    email,
                                    description
                                });
                                props.close();
                            }
                        }}
                        disabled={errorMessage ? true : false}
                    >
                        ADD
                    </Button>}
                    {props.action === 'Edit' && <Button
                        color="primary"
                        style={{marginRight:20}}
                        onClick={() => {
                            const groupDetails = {
                                name,
                                description,
                                email,
                                id: props.groupData.id
                            };
                            props.updateWorkspaceGroup(groupDetails);
                            props.close();
                        }}
                        disabled={errorMessage ? true : false}
                    >
                        UPDATE
                    </Button>}
                    <Button
                        color="white"
                        style={{marginRight:20}}
                        onClick={() => {
                            props.close();
                        }}
                    >
                        CANCEL
                    </Button>
                    {errorMessage && <div style={{color:'red',marginTop:20}}>
                        {errorMessage}
                    </div>}
                </GridItem>
            </GridContainer>
        </form>
    </Dialog>
    )
}

export default GroupsAdminModal;