import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const updateObj = (obj, field, value) => {
    const newCustomData = clone(obj);
    newCustomData[field] = value;
    return newCustomData;
}

// current_listings_link: '', // 200 characters 
// facebook_page_link: '', // 200 characters 
// linkedin_link: '', // 200 characters
// twitter_link: '', // 200 characters

const UserAdminLinks = (props) => {
    const setCustomData = props.setCustomData;
    const customData = props.customData;

    const update = (field, value) => {
        setCustomData(updateObj(customData, field, value));
    }

    return (
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    labelText="Current Listings Link"
                    inputProps={{
                        value: customData.current_listings_link,
                        onChange: (e) => {
                            update('current_listings_link', e.target.value);
                        }
                    }}
                />
                <CustomInput
                    labelText="Facebook Link"
                    inputProps={{
                        value: customData.facebook_page_link,
                        onChange: (e) => {
                            update('facebook_page_link', e.target.value)
                        }
                    }}
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput
                    labelText="Linkedin Link"
                    inputProps={{
                        value: customData.linkedin_link,
                        onChange: (e) => {
                            update('linkedin_link', e.target.value);
                        }
                    }}
                />
                <CustomInput
                    labelText="Twitter (X) Link"
                    inputProps={{
                        value: customData.twitter_link,
                        onChange: (e) => {
                            update('twitter_link', e.target.value)
                        }
                    }}
                />
            </GridItem>
        </GridContainer>
    );
}

export default UserAdminLinks;