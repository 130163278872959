import React, { useState } from 'react';
import { pdfjs } from 'react-pdf';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from 'react-pdf';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from "components/CustomButtons/Button.jsx";
import Close from "@material-ui/icons/Close";
import download from 'downloadjs';
import { base64toBlob, toBase64 } from "shared/utility";
import ERTLActions from './ERTLActions';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ERTLPdfModal = (props) => {
    const { pdfData, open, close } = props;
    
    const downloadPDF = () => {
        console.log('downloading data', pdfData.data, pdfData);
        if (pdfData.data) {
            const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdfData.data).buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ertl-entry-${new Date().getTime()}.pdf`);
            document.body.appendChild(link);
            link.click();
        } else {
            const url = window.URL.createObjectURL(new Blob([new Uint8Array(pdfData).buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ertl-entry-${new Date().getTime()}.pdf`);
            document.body.appendChild(link);
            link.click();
        }
        
    }
    return (
        <div className="parentable">
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div className="close-btn" onClick={() => { close() }}><Close /></div>
                <div style={{ margin: 20 }}>
                    <ERTLPdfViewer pdfData={pdfData} />
                    <br />
                    <div className="text-center bottom-dweller">
                        <Button color="white" 
                            style={{marginRight:20}}
                            onClick={() => { 
                                downloadPDF();
                            }}
                        >DOWNLOAD PDF</Button>
                        {/* <ERTLActions 
                            tabsData={props.tabsData}
                            htmlData={props.htmlData}
                        /> */}
                    </div>
                </div>
            </Dialog>
        </div>
    )
}

const ERTLPdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [delay, setDelay] = useState(false);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        setNumPages(numPages);
    };

    const setPageNumber2 = (num) => {
        setDelay(true);
        setTimeout(() => {
            setPageNumber(num);
            setTimeout(() => {
                setDelay(false);
            }, 100);
        }, 100);
    }

    const renderNav = () => {
        return <div className="text-center">
            <div>Page {pageNumber} of {numPages}</div>
            <hr />
            <Button
                onClick={() => {
                    setPageNumber2(pageNumber - 1)
                }}
                disabled={pageNumber === 1 ? true : false}
                className="mr-20"
            ><i className="fas fa-arrow-left"></i></Button>
            <Button
                onClick={() => {
                    setPageNumber2(pageNumber + 1)
                }}
                disabled={pageNumber === numPages ? true : false}
            ><i className="fas fa-arrow-right"></i></Button>
        </div>
    }

    
    return (
    <>
        {!delay && <Document
            file={props.pdfData}
            onLoadSuccess={onDocumentLoadSuccess}
        >
            {renderNav()}
            <div className="light-border">
                <Page pageNumber={pageNumber} width={1000} />
            </div>
            {renderNav()}
        </Document>}
    </>
    );
}

export default ERTLPdfModal;