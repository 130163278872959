import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";
import Button from "components/CustomButtons/Button.jsx";

class Maintenance extends React.Component {
  constructor(props) {
    super(props);

  }

  maintenance = (value) => {
    this.props.maintenance(value);
    setTimeout(() => {
      this.props.checkMaintenanceMode();
    }, 500)
  }

  componentDidMount() {
    this.props.checkMaintenanceMode();
  }

  render() {
    if (!checkPermissions('UserPerms')) {
      return <Redirect to="/dashboard" />;
    }
    
    let maintenanceMode = false;
    if (this.props.maintenanceMode === 'yes') maintenanceMode = true;

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>Maintenance Mode {maintenanceMode ? <span style={{color:'red'}}>ON</span> : <span style={{color:'green'}}>OFF</span>}</h1>
            <p>Toggle Maintenance Mode by clicking this button</p>
            {!maintenanceMode && <Button color="danger" onClick={() => {
              this.maintenance('yes');
            }}>TURN ON</Button>}
            {maintenanceMode && <Button color="success" onClick={() => {
              this.maintenance('no');
            }}>TURN OFF</Button>}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode
  }
}

const mapDispatchToProps = dispatch => {
  return {
    maintenance: (value) => {
      dispatch(actions.maintenanceMode(value));
    },
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Maintenance);
