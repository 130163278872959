const appointmentFields = {
    start_date: {
        type: 'date',
        label: 'Start Date',
        validations: ['required']
    },
    start_time: {
        type: 'time',
        label: 'Start Time',
        validations: ['required']
    },
    end_date: {
        type: 'date',
        label: 'End Date',
        validations: ['required']
    },
    end_time: {
        type: 'time',
        label: 'End Time',
        validations: []
    },
    invitees: {
        type:'multi'
    },
    summary: {
        type: 'text',
        label: 'Summary',
        validations: ['min1','max250']
    },
    description: {
        type: 'text',
        label: 'Description',
        validations: ['min1','max250']
    }
}

export default appointmentFields;

export const initialAppointmentData = {
    start_date: '',
    start_time: '09:00 AM',
    end_date: '',
    end_time: '10:00 AM',
    other_data: {
        invitees: []
    },
    summary: '',
    description: ''
}