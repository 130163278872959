import React from 'react';
import { clone } from 'shared/utility';
import { Dialog } from '@material-ui/core';

import GridItem from 'components/Grid/GridItem';
import GridContainer from 'components/Grid/GridContainer';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import Button from 'components/CustomButtons/Button';

const RSheetsEditArray = (props) => {
    const { editArray, setEditArray, dataFromArray, setDataFromArray, data, updateDataObj } = props;
    const { field } = dataFromArray;
    return <Dialog
        open={editArray}
        onClose={() => { setEditArray(false) }}
    >
        <div style={{padding:40}}>
            {(field !== 'lessee_contacts' && field !== 'lessor_contacts' && field !== 'assignor_contacts') && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <CustomInput 
                        label={field === 'referral_firm' ? 'Company Name' : 'First Name'}
                        value={dataFromArray ? dataFromArray.data.name : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.name = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem> 
                {field !== 'referral_firm' && <GridItem xs={12} sm={12} md={12}>
                    <CustomInput 
                        label={'Last Name'}
                        value={dataFromArray ? dataFromArray.data.last_name : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.last_name = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>}
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Main Phone"
                        value={dataFromArray ? dataFromArray.data.main_phone : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.main_phone = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}></GridItem>
            </GridContainer>}
            {(field === 'lessee_contacts' || field === 'lessor_contacts' || field === 'assignor_contacts') && <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="First Name"
                        value={dataFromArray ? dataFromArray.data.name : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.name = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Last Name"
                        value={dataFromArray ? dataFromArray.data.last_name : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.last_name = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Address 1"
                        value={dataFromArray ? dataFromArray.data.address1 : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.address1 = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Address 2"
                        value={dataFromArray ? dataFromArray.data.address2 : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.address2 = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="City"
                        value={dataFromArray ? dataFromArray.data.city : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.city = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="State"
                        value={dataFromArray ? dataFromArray.data.state : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.state = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Zip"
                        value={dataFromArray ? dataFromArray.data.zip : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.zip = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Email"
                        value={dataFromArray ? dataFromArray.data.email : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.email = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <CustomInput 
                        label="Main Phone"
                        value={dataFromArray ? dataFromArray.data.main_phone : ''}
                        onChange={(e) => {
                            const newDataFromArray = clone(dataFromArray);
                            if (dataFromArray) {
                                newDataFromArray.data.main_phone = e.target.value;
                            }
                            setDataFromArray(newDataFromArray);
                        }}
                    />
                </GridItem>
            </GridContainer>}

            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button className="mr-20" color="primary" onClick={() => {
                        const field = dataFromArray ? dataFromArray.field : '';
                        const index = dataFromArray ? dataFromArray.index : '';
                        if (field) {
                            let newData = clone(data[field]);
                            newData[index] = dataFromArray.data;
                            updateDataObj(field, newData);
                            setEditArray(false)
                            setDataFromArray({})
                        }
                    }}>
                        UPDATE
                    </Button>
                    <Button color="white" onClick={() => {
                        setEditArray(false);
                    }}>
                        CANCEL
                    </Button>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
}

export default RSheetsEditArray;
