import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';
import { Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import Button from '../../../components/CustomButtons/Button';
import NotificationAsk from '../../../components/Notification/NotificationAsk';

const ERTLList = (props) => {
    const {  ertlList, setERTLData, setTab } = props;
    const [askDelete, setAskDelete] = useState(null);

    const data = ertlList ? ertlList.map((l, key) => { // map users to table rows
        const d = l.data;
        const address = `${d.property_address1} ${d.property_address1} ${d.property_city} 
                            ${d.property_state} ${d.property_zipcode}`;
        return ({
            id: l.id,
            address,
            created_date: formatDate2(l.created_date),
            modified_date: formatDate2(l.modified_date),
            status: 'Draft',
            template: l.template_id ? 'Revised Template' : 'Original Template',
            ertlData: l,
            actions: <div>
                        {/* {
                            <Tooltip
                                title="View"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getRsheet(l.id);
                                    setViewOnly(true);
                                    setOpenedForm(true)
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-eye font-22"></i>
                            </Button>
                          </Tooltip>
                        } */}
                        {<Tooltip
                                title="Edit"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    setERTLData(l)
                                    if (l.template_id) props.getTemplate(l.template_id)
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <Edit />
                            </Button>
                          </Tooltip>
                        }
                        {<Tooltip
                                title="Delete"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    setAskDelete(l.id);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-trash font-22"></i>
                            </Button>
                          </Tooltip>
                        }
                        </div>
        })
    }) : [];

    const columns = [
        {
            Header: "#",
            accessor: "id" 
        },
        {
            Header: "Address",
            accessor: "address",
            minWidth: 200 
        },
        {
            Header: "Date Created",
            accessor: "created_date"
        },
        {
            Header: "Date Modified",
            accessor: "modified_date"
        },
        {
            Header: "Status",
            accessor: "status"
        },
        {
            Header: "Template Used",
            accessor: "template"
        },
        {
            Header: "Actions",
            accessor: "actions"
        }
    ]

    return (
        <div className="table-adjust23">
            <ReactTable 
                filterable
                className='-striped' 
                showPaginationTop 
                showPaginationBottom 
                resizable={true} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
            />

            {askDelete !== null && <NotificationAsk 
                open={true}
                close={() => {
                    setAskDelete(null);
                }}
                success={() => {
                    props.deleteErtlEntry(askDelete);
                    setAskDelete(null);
                }}
                title="Are you sure?"
                message="Do you want to delete this ERTL?"
                success_button_text="Yes, Delete"
            />}
        </div>
    )
}

export default ERTLList;