import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    loading: false,
}

// CREATE_DOCUSIGN

const createDocusignStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createDocusignSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Docusign Created'
    })
}

const createDocusignFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.CREATE_DOCUSIGN_START:
            return createDocusignStart(state, action);
        case actionTypes.CREATE_DOCUSIGN_SUCCESS:
            return createDocusignSuccess(state, action);
        case actionTypes.CREATE_DOCUSIGN_FAIL:
            return createDocusignFail(state, action);
        default:
            return state;
    }
};

export default reducer;