import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDialogContact from "components/CustomDialog/CustomDialogWithContact";
import CustomDialogCompany from "components/CustomDialog/CustomDialogWithCompany";
import Button from "components/CustomButtons/Button.jsx";
import Delete from "@material-ui/icons/Delete";
import { Dialog } from '@material-ui/core';
import * as actions from "store/actions";
import { connect } from "react-redux";
import { clone } from "shared/utility";
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Table from "components/Table/Table.jsx";
import crmObj from '../crmObj';

const PropertiesFormOther = (props) => {
    const { getPropertyToContact, contact_properties, addPropertyToContact, updateDataObj, 
            getCompanyProperties, company_properties,
            dataObj, success, error, contactsForAdd, setContactsForAddNow,
            companyForAdd, setCompanyForAddNow
    } = props;
    
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [openedSearchModal, setOpenedSearchModal] = useState(false); // Contact
    const [openedSearchModal2, setOpenedSearchModal2] = useState(false); // Company
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false); // Contact
    const [openedAskBeforeDelete2, setOpenedAskBeforeDelete2] = useState(false); // Company
    const [currentDelete, setCurrentDelete] = useState(null);

    useEffect(() => {
        console.log('PropertiesFormOther setting contact 2');
        if (contact_properties && props.action !== 'Add') {
            setContacts(contact_properties.filter(cp => {
                if (cp.contact_type === 'OTHER') return true;
                return false;
            }));
        }
    }, [contact_properties]);

    useEffect(() => {
        console.log('PropertiesFormOther setting companies 2');
        if (company_properties && props.action !== 'Add') {
            setCompanies(company_properties.filter(cp => {
                if (cp.company_type === 'OTHER') return true;
                return false;
            }));
        }
    }, [company_properties]);

    useEffect(() => {
        // Edit Mode
        if (dataObj.id) {
            getPropertyToContact(dataObj.id, 'property', 'others');
            getCompanyProperties({
                property_id: dataObj.id
            });
        } else {
            // Add Mode 
            setContacts(contactsForAdd.filter(f => {
                if (f.contact_type === 'OTHER') return true;
                return false;
            }))
            setCompanies(companyForAdd.filter(f => {
                if (f.company_type === 'OTHER') return true;
                return false;
            }))
        }
    }, []);

    useEffect(() => {
        if (loading2) {
            if (success === 'Successfully added property - contact') {
                getPropertyToContact(dataObj.id, 'property', 'others');
            }
            if (success === 'Successfully removed property - contact') {
                getPropertyToContact(dataObj.id, 'property', 'others');
            }
        }
    }, [success, loading2]);

    useEffect(() => {
        if (props.action === 'Add') {
            setContacts(contactsForAdd.filter(f => {
                if (f.contact_type === 'OTHER') return true;
                return false;
            }))
        }
    }, [contactsForAdd]);

    useEffect(() => {
        if (props.action === 'Add') {
            setCompanies(companyForAdd.filter(f => {
                if (f.company_type === 'OTHER') return true;
                return false;
            }))
        }
    }, [companyForAdd]);

    const choose_company = (company_data, typeObj) => {
        if (props.action !== 'Add') {
            console.log('choosing prop', typeObj);
            const newObj = {
                company_id: company_data.id,
                property_id: props.dataObj.id
            };
            if (typeObj) {
                if (typeObj.acronymType) newObj.company_type = typeObj.acronymType;
                if (typeObj.subType) newObj.subtype = typeObj.subType;
                if (typeObj.theType) {
                    const currentContactType = company_data.contact_type;
                    console.log('choosing prop - ', crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]);
                    if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                        const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                        if (shouldExistContactType) {
                            let arr = currentContactType ? currentContactType.split(',') : [];
                            if (arr.indexOf(shouldExistContactType) === -1) {
                                arr.push(shouldExistContactType)
                                const new_contact_type = arr.join(',');
                                const newDataObj = clone(company_data);
                                newDataObj.contact_type = new_contact_type;
                                if (shouldExistContactType === 'Other') {
                                    if (typeObj.subType) {
                                        newDataObj.contact_type_other = newDataObj.contact_type_other ? 
                                                (newDataObj.contact_type_other + ',' + typeObj.subType)
                                            :
                                                typeObj.subType
                                    }
                                }
                                // update current frontend form
                                //props.setDataObj(newDataObj);
                                
                                if (company_data.id) {
                                    // update backend contact data
                                    props.updateCompany({
                                        id: company_data.id,
                                        contact_type: new_contact_type
                                    });
                                }
                            }
                        }
                    }
                }
            }
            // open property 
            props.createCompanyProperties(newObj);
        } else {
            // Add Mode
            const newObj = {
                company_type: 'OTHER',
                company_id: company_data.id,
                company_data: company_data
            };
            if (typeObj) {
                if (typeObj.subType) {
                    newObj.subtype = typeObj.subType;
                }
            }
            const newCompanyForAdd = clone(companyForAdd).concat([
                newObj
            ]);
            setCompanyForAddNow(newCompanyForAdd);
        }
        setOpenedSearchModal(false)
    }

    const choose_contact = (the_contact_data, typeObj) => {
        if (props.action !== 'Add') {
            // save right away
            setLoading2(true);

            console.log('choosing prop', typeObj);
            const newObj = {
                contact_id: the_contact_data.id,
                property_id: dataObj.id
            };
            if (typeObj) {
                if (typeObj.acronymType) newObj.contact_type = typeObj.acronymType;
                if (typeObj.subType) newObj.subtype = typeObj.subType;
                if (typeObj.theType) {
                    const currentContactType = the_contact_data.contact_type;
                    console.log('choosing prop - ', crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]);
                    if (crmObj.contactOrCompanyPropertySubTypes[typeObj.theType]) {
                        const shouldExistContactType = crmObj.contactOrCompanyPropertySubTypes[typeObj.theType].contactType;
                        if (shouldExistContactType) {
                            let arr = currentContactType ? currentContactType.split(',') : [];
                            if (arr.indexOf(shouldExistContactType) === -1) {
                                arr.push(shouldExistContactType)
                                const new_contact_type = arr.join(',');
                                const newDataObj = clone(the_contact_data);
                                newDataObj.contact_type = new_contact_type;
                                if (shouldExistContactType === 'Other') {
                                    if (typeObj.subType) {
                                        newDataObj.contact_type_other = newDataObj.contact_type_other ? 
                                                (newDataObj.contact_type_other + ',' + typeObj.subType)
                                            :
                                                typeObj.subType
                                    }
                                }
                                
                                if (the_contact_data.id) {
                                    // update backend contact data
                                    props.updateContact({
                                        id: the_contact_data.id,
                                        contact_type: new_contact_type,
                                        contact_type_other: newDataObj.contact_type_other
                                    });
                                    
                                }
                            }
                        }
                    }
                }
            }
            // open property
            addPropertyToContact(newObj, 'property')
        } else {
            // Add Mode
            const newObj = {
                contact_type: 'OTHER',
                contact_id: the_contact_data.id,
                contact_data: the_contact_data
            };
            if (typeObj) {
                if (typeObj.subType) {
                    newObj.subtype = typeObj.subType;
                }
            }
            const newContactsForAdd = clone(contactsForAdd).concat([
                newObj
            ]);
            setContactsForAddNow(newContactsForAdd);
        }
        setOpenedSearchModal(false)
    }

    console.log('PropertiesFormOther', props, companies, contacts);

    return <div>
        <div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3>{contacts.length} Other Contact(s)</h3>
                    {props.action !== 'View' && <Button style={{marginBottom:20}} color="primary" onClick={() => {
                        setOpenedSearchModal(true);
                    }}><i className="fa fa-plus"></i> Add Contact</Button>}
                    <Table 
                        tableHead={['Primary Contact','Name','Type','Subtype','Actions']}
                        tableData={contacts.map((c,i) => {
                            const o = c.contact_data ? c.contact_data : {};
                            const name = o.name ? 
                                o.name 
                            : 
                                `${o.first_name ? o.first_name : ''} ${o.last_name ? o.last_name : ''}`;
                            let the_contact_type = c.contact_type ? crmObj.typeDefinitions[c.contact_type] : '';
                            let the_subtype = c.subtype ? c.subtype : '';
                            // Label Property Manager differently
                            if (the_subtype === 'Property Manager') {
                                the_contact_type = 'Property Manager';
                                the_subtype = '';
                            }
                            return [
                                <FormGroup row className="mt-15">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={dataObj.primary_other_contact === o.id ? true : false}
                                                onChange={() => {
                                                    updateDataObj('primary_other_contact', o.id);
                                                }}
                                                color="default"
                                            />
                                        }
                                        label=""
                                    />
                                </FormGroup>,
                                <div onClick={() => {
                                    
                                    props.setOpenedEditContact(true);
                                    props.setContactID(o.id);
                                }} className="hovered">
                                    {name}
                                </div>,
                                the_contact_type,
                                the_subtype,
                                props.action !== 'Add' ? <div 
                                    className="hoverable red-text"
                                    onClick={() => {
                                        if (props.action !== 'Add') {
                                            setOpenedAskBeforeDelete(true);
                                            setCurrentDelete(c);
                                        } else {
                                            setContactsForAddNow(contactsForAdd.filter((f) => {
                                                if (f.contact_data) {
                                                    if (f.contact_data.id === o.id) {
                                                        if (f.contact_type === 'OTHER') {
                                                            return false;
                                                        }
                                                    }
                                                }
                                                return true;
                                            }));
                                        }
                                    }}
                                >
                                    <Delete />
                                </div> : <div></div>
                            ]
                        })}
                    />
                </GridItem>

                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                    <h3>{companies.length} Other Company Owner(s)</h3>
                    {props.action !== 'View' && <Button style={{marginBottom:20}} color="primary" onClick={() => {
                        setOpenedSearchModal2(true);
                    }}><i className="fa fa-plus"></i> Add Company</Button>}
                    <Table 
                        tableHead={['Primary Contact','Name','Type','Subtype','Actions']}
                        tableData={companies.map((c,i) => {
                            const o = c.company_data ? c.company_data : {};
                            const name = o.name;
                            return [
                                <FormGroup row className="mt-15">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={dataObj.primary_other_contact === `c${o.id}` ? true : false}
                                                onChange={() => {
                                                    updateDataObj('primary_other_contact', `c${o.id}`);
                                                }}
                                                color="default"
                                            />
                                        }
                                        label=""
                                    />
                                </FormGroup>,
                                <div onClick={() => {
                                    props.setOpenedEditCompany(true);
                                    props.setCompanyID(o.id);
                                }} className="hovered">
                                    {name}
                                </div>,
                                c.company_type ? crmObj.typeDefinitions[c.company_type] : '',
                                c.subtype ? c.subtype : '',
                                props.action !== 'Add' ? <div 
                                    className="hoverable red-text"
                                    onClick={() => {
                                        if (props.action !== 'Add') {
                                            setOpenedAskBeforeDelete2(true);
                                            setCurrentDelete(c);
                                        } else {
                                            // setContactsForAddNow(contactsForAdd.filter((f) => {
                                            //     if (f.contact_data) {
                                            //         if (f.contact_data.id === o.id) {
                                            //             if (f.contact_type === 'OTHER') {
                                            //                 return false;
                                            //             }
                                            //         }
                                            //     }
                                            //     return true;
                                            // }));
                                        }
                                    }}
                                >
                                    <Delete />
                                </div> : <div></div>
                            ]
                        })}
                    />
                </GridItem>

                {openedSearchModal && <CustomDialogContact 
                    open={openedSearchModal}
                    close={() => { setOpenedSearchModal(false) }}
                    choose={choose_contact}
                    create_and_choose={choose_contact}
                    title="Select Contact"
                    label="Search Contacts"
                    showContactTypes={true}
                    askForType={true}
                    typeRestriction="OTHER"
                />}

                {openedSearchModal2 && <CustomDialogCompany
                    open={openedSearchModal2}
                    close={() => { setOpenedSearchModal2(false) }}
                    choose={choose_company}
                    create_and_choose={choose_company}
                    title="Select Company"
                    label="Search Companies"
                    showContactTypes={true}
                    askForType={true}
                    typeRestriction="OTHER"
                />}

                {openedAskBeforeDelete && <Dialog 
                    open={openedAskBeforeDelete}
                    onClose={() => {
                        setOpenedAskBeforeDelete(false);
                    }}
                >
                    <div className="dialog-wrapper text-center">
                        <h3>Are you sure you want to delete this contact ?</h3>
                        <Button color="primary" onClick={() => { 
                            if (currentDelete) {
                                if (currentDelete.id) {
                                    if (props.action !== 'Add') {
                                        setLoading2(true);
                                        props.removePropertyToContact(currentDelete.id, 'property', dataObj.id);
                                    }

                                }
                            }
                            setOpenedAskBeforeDelete(false) }}>YES, DELETE</Button>
                        <Button color="white" onClick={() => { setOpenedAskBeforeDelete(false) }}>CANCEL</Button>
                    </div>
                </Dialog>}

                {openedAskBeforeDelete2 && <Dialog 
                    open={openedAskBeforeDelete2}
                    onClose={() => {
                        setOpenedAskBeforeDelete2(false);
                    }}
                >
                    <div className="dialog-wrapper text-center">
                        <h3>Are you sure you want to delete this company ?</h3>
                        <Button color="primary" onClick={() => { 
                            if (currentDelete) {
                                if (currentDelete.id) {
                                    if (props.action !== 'Add') {
                                        setLoading2(true);
                                        props.deleteCompanyProperties({ id:currentDelete.id, property_id: dataObj.id });
                                    }

                                }
                            }
                            setOpenedAskBeforeDelete2(false) }}>YES, DELETE</Button>
                        <Button color="white" onClick={() => { setOpenedAskBeforeDelete2(false) }}>CANCEL</Button>
                    </div>
                </Dialog>}
            </GridContainer>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        contact_properties: state.crm_contacts.contact_properties,
        company_properties: state.crm_companies.companyPropertiesData ? state.crm_companies.companyPropertiesData : [],
        success: state.crm_contacts.success,
        error: state.crm_contacts.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPropertyToContact: (data, type, kind)  => {
            dispatch(actions.getPropertyToContact(data, type, kind));
        },
        removePropertyToContact: (data, data_type, data_type_id) => {
            dispatch(actions.removePropertyToContact(data, data_type, data_type_id));
        },
        addPropertyToContact: (data, data_type) => {
            dispatch(actions.addPropertyToContact(data, data_type));
        },
        getCompanyProperties: (data) => {
            dispatch(actions.getCompanyProperties(data));
        },
        createCompanyProperties: (data) => {
            dispatch(actions.createCompanyProperties(data));
        },
        deleteCompanyProperties: (data) => {
            dispatch(actions.deleteCompanyProperties(data));
        },
        updateCompany: (data, partnersData, addressData) => {
            dispatch(actions.updateCompany(data, partnersData, addressData));
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFormOther);