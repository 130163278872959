import React, { useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import Button from "../../components/CustomButtons/Button";
import CustomCRMEditor from "../../components/CustomDialog/CustomCRMEditor";
import CustomInput from "../../components/CustomInput/CustomInputValidate";

const Step1 = (props) => {
    const [edit, setEdit] = useState(null);

    const { renderElement, currentUserIsSubmitter, currentUserIsAnAgentApprover, hasBeenCompletedBefore,
        dataObj,
        updateDataObj
    } = props;
    return <div className={(currentUserIsAnAgentApprover || hasBeenCompletedBefore) ? 'no-click' : ''}>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {(currentUserIsSubmitter && !hasBeenCompletedBefore) && <Button color="primary" onClick={() => {
                    props.setOpenedChooseCRMModal(true);
                }}>SELECT PROPERTY</Button>}
            </GridItem>
        </GridContainer>
        {dataObj.street1 && <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement('date')}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomInput 
                    value={dataObj.project_name}
                    onClick={() => {
                        setEdit({
                            value: dataObj.project_name,      
                            type: 'property',       
                            field: 'name',      
                            fieldType: 'input',  
                            label: 'Project Name',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('project_name', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Project Name"
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement('illi_number')}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <CustomInput 
                    value={dataObj.address_is_range}
                    onClick={() => {
                        setEdit({
                            value: dataObj.address_is_range,      
                            type: 'property',       
                            field: 'address_is_range',      
                            fieldType: 'select',
                            options: ['','Yes','No'],  
                            label: 'Address 1 is Range?',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('address_is_range', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Address 1 is Range?"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                {dataObj.address_is_range === 'Yes' && 
                    <CustomInput 
                        value={dataObj.street_range1}
                        onClick={() => {
                            setEdit({
                                value: dataObj.street_range1,      
                                type: 'property',       
                                field: 'street_range1',      
                                fieldType: 'input',  
                                label: 'Street Range 1',      
                                id: dataObj.property_id,         
                                chooseVal: (chosenVal) => { updateDataObj('street_range1', chosenVal); setEdit(null); }
                            })    
                        }}
                        label="Street Range 1"
                    />}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                {dataObj.address_is_range === 'Yes' && 
                    <CustomInput 
                        value={dataObj.street_range2}
                        onClick={() => {
                            setEdit({
                                value: dataObj.street_range2,      
                                type: 'property',       
                                field: 'street_range2',      
                                fieldType: 'input',  
                                label: 'Street Range 2',      
                                id: dataObj.property_id,         
                                chooseVal: (chosenVal) => { updateDataObj('street_range2', chosenVal); setEdit(null); }
                            })    
                        }}
                        label="Street Range 2"
                    />}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput 
                    value={dataObj.street1}
                    onClick={() => {
                        setEdit({
                            value: dataObj.street1,      
                            type: 'property',       
                            field: 'street1',      
                            fieldType: 'input',  
                            label: 'Street 1',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('street1', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Street 1"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                <CustomInput 
                    value={dataObj.address2_is_range}
                    onClick={() => {
                        setEdit({
                            value: dataObj.address2_is_range,      
                            type: 'property',       
                            field: 'address2_is_range',      
                            fieldType: 'select',
                            options: ['','Yes','No'],  
                            label: 'Address 2 is Range?',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('address2_is_range', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Address 2 is Range?"
                />
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                {dataObj.address2_is_range === 'Yes' && 
                    <CustomInput 
                        value={dataObj.street2_range1}
                        onClick={() => {
                            setEdit({
                                value: dataObj.street2_range1,      
                                type: 'property',       
                                field: 'street2_range1',      
                                fieldType: 'input',  
                                label: 'Street 2 Range 1',      
                                id: dataObj.property_id,         
                                chooseVal: (chosenVal) => { updateDataObj('street2_range1', chosenVal); setEdit(null); }
                            })    
                        }}
                        label="Street 2 Range 1"
                    />}
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
                {dataObj.address2_is_range === 'Yes' && 
                    <CustomInput 
                        value={dataObj.street2_range2}
                        onClick={() => {
                            setEdit({
                                value: dataObj.street2_range2,      
                                type: 'property',       
                                field: 'street2_range2',      
                                fieldType: 'input',  
                                label: 'Street 2 Range 2',      
                                id: dataObj.property_id,         
                                chooseVal: (chosenVal) => { updateDataObj('street2_range2', chosenVal); setEdit(null); }
                            })    
                        }}
                        label="Street 2 Range 2"
                    />}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <CustomInput 
                    value={dataObj.street2}
                    onClick={() => {
                        setEdit({
                            value: dataObj.street2,      
                            type: 'property',       
                            field: 'street2',      
                            fieldType: 'input',  
                            label: 'Street 2',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('street2', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Street 2"
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput 
                    value={dataObj.city}
                    onClick={() => {
                        setEdit({
                            value: dataObj.city,      
                            type: 'property',       
                            field: 'city',      
                            fieldType: 'input',  
                            label: 'City',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('city', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="City"
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput 
                    value={dataObj.state}
                    onClick={() => {
                        setEdit({
                            value: dataObj.state,      
                            type: 'property',       
                            field: 'state',      
                            fieldType: 'states',  
                            label: 'State',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('state', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="State"
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomInput 
                    value={dataObj.zip}
                    onClick={() => {
                        setEdit({
                            value: dataObj.zip,      
                            type: 'property',       
                            field: 'zip',      
                            fieldType: 'input',  
                            label: 'Zip',      
                            id: dataObj.property_id,         
                            chooseVal: (chosenVal) => { updateDataObj('zip', chosenVal); setEdit(null); }
                        })    
                    }}
                    label="Zip"
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {renderElement('cross_streets')}
            </GridItem>
        </GridContainer>}

        {edit !== null && <CustomCRMEditor 
            {...edit}
            close={() => {
                setEdit(null)
            }}
        />}
    </div>
}

export default Step1;