import React from "react";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Button from "components/CustomButtons/Button";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { clone } from "../../../shared/utility";
import { isDateDifferenceCorrect } from "./TREntriesLogic";

const Step3 = (props) => {
    const { renderElement, final_approvers, setDataObj, dataObj } = props;
    const correctDates = isDateDifferenceCorrect(dataObj);
    return <div>
        <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement('period_commencing')}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                {renderElement('period_ending_on')}
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {!correctDates && <div className="error-text">Incorrect Dates</div>}
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <div>
                    <CustomSelect 
                        options={final_approvers ? final_approvers : []}
                        label="Select an approver"
                        indx="name"
                        desc="name"
                        choose={(e, n) => {
                            console.log('chosen', e);
                            if (final_approvers) {
                                const newData = clone(dataObj);
                                newData.final_approver_name = e;
                                final_approvers.forEach(f => {
                                    if (f.name === e) {
                                        newData.final_approver_email = f.email;
                                        newData.final_approver_position = f.position;
                                    }
                                })
                                setDataObj(newData);
                            }
                        }}
                        default={dataObj.final_approver_name}
                        error={!dataObj.final_approver_name ? true : false}
                    />
                </div>
            </GridItem>
        </GridContainer>
        <hr />
    </div>
}

export default Step3;