import React, { useEffect, useState } from "react";
import { get } from "lodash";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import Button from "../../../components/CustomButtons/Button";
import { DialogTitle, Dialog } from "@material-ui/core";
import FileUploader from "./FileUploader";
import { clone } from "../../../shared/utility";

const Documents = (props) => {
    const [openedFileUploader, setOpenedFileUploader] = useState(false);
    const [formFilename, setFormFilename] = useState('');
    const [formDescription, setFormDescription] = useState('');
    const [editDescription, setEditDescription] = useState(-1);

    const { data, updateDataObj, setData } = props;

    const docs = get(data, 'documents', []);
    return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <Button color="primary" onClick={() => {
                setOpenedFileUploader(true);
                setFormFilename('');
                setFormDescription('');
            }}><i className="fa fa-plus"></i> ADD DOCUMENT</Button>
        </GridItem>
        {docs.map((d,i) => {
            const { rs_filename, description } = d;
            const filename = rs_filename ? rs_filename.split(',')[1] : '';
            const filename_stripped = filename ? filename.split('-').length > 1 ? filename.split('-').slice(1) : filename : '';
            return <GridItem key={`docs-${i}`} xs={6} sm={4} md={4}>
                <div className="boxed">
                    <p><strong>{filename_stripped}</strong></p>
                    {editDescription !== i && <p style={{marginBottom:10}} className="hovered hover-bold" onClick={() => {
                        setEditDescription(i);
                        setFormDescription(description);
                    }}>{description}</p>}
                    {editDescription === i && <div style={{marginBottom:10}} className="boxed"><CustomInput 
                        autoFocus={true}
                        value={formDescription}
                        onChange={(e) => {
                            setFormDescription(e.target.value);
                        }}
                    /><Button color="primary" size="sm" onClick={() => {
                        updateDataObj('documents', data.documents.map((dd,jj) => {
                            if (jj === i) {
                                const newObj = clone(dd);
                                newObj.description = formDescription;
                                return newObj;
                            }
                            return dd;
                        }))
                        setFormDescription('');
                        setEditDescription(-1);
                    }}>UPDATE</Button></div>}
                    <FileUploader 
                        hide_filename={true}
                        rs_filename={rs_filename}
                        remove={() => {
                            updateDataObj('documents', data.documents.filter((df,j) => {
                                if (j !== i) return true;
                            }))
                        }}
                    />
                </div>
            </GridItem>
        })}

        {openedFileUploader && <Dialog
            open={true}
            maxWidth="sm"
            fullWidth={true}
        >
            <DialogTitle>NEW DOCUMENT UPLOAD</DialogTitle>
            <div style={{padding:20}}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <CustomInput autoFocus={true} label="Description" value={formDescription} onChange={(e) => {
                            setFormDescription(e.target.value);
                        }} />
                        <div style={{marginBottom:20,marginTop:20}}>
                            <FileUploader 
                                rs_filename={formFilename}
                                update={(val) => {
                                    const newDocs = [
                                        {
                                            rs_filename: val,
                                            description: formDescription
                                        }
                                    ];
                                    updateDataObj('documents', data.documents ? data.documents.concat(newDocs) : newDocs);
                                    setOpenedFileUploader(false);
                                }}
                                button_size="md"
                                close={() => { setOpenedFileUploader(false) }}
                            />
                        </div>
                    </GridItem>
                </GridContainer>
            </div>
        </Dialog>}
    </GridContainer>
}

export default Documents;