import { parseFloatString, cloneJ, theVal } from "../../../shared/utility";
import termLogic from "./Term/TermLogicMain";

const isValid = (str) => {
    if (str) {
        if (typeof str === 'string') {
            return true;
        }
    }
    return false;
}

export const commissionCalc = {
    calculate: (data) => {
        let net_commission = 0;
        let commission_distribution_lessee = 0;
        let commission_distribution_lessor = 0;
        let calculated_commission_distribution_lessee = 0;
        let calculated_commission_distribution_lessor = 0;

        const adjustments = data.commission_adjustment_details ? data.commission_adjustment_details : [];
        
        const dat = {
            gross_commission: 0,

            commission_distribution_lessee_percent: 0,
            commission_distribution_lessor_percent: 0, 
            commission_distribution_lessee: 0,
            commission_distribution_lessor: 0,

            commission_distribution_lessee_after_presplits: 0,
            commission_distribution_lessor_after_presplits: 0,

            commission_distribution_lessee_after_outside_brokers: 0,
            commission_distribution_lessor_after_outside_brokers: 0,

            commission_distribution_lessee_after_referrals: 0,
            commission_distribution_lessor_after_referrals: 0,

            lessee_outside_brokers: [],
            lessor_outside_brokers: [],

            lessee_referrals: [],
            lessor_referrals: [],

            associates_commissions: [],

            presplitCalculations: [],

            house: 0
        };
        // --------------------
        // Get gross commission 
        // aka net commission in Lease Information tab where it is not flat fee commission
        if (data.flat_fee_commission === "Yes") {
            if (data.flat_fee_commission_amount) {
                dat.gross_commission = parseFloatString(data.flat_fee_commission_amount);
            }
        } else {
            // get calculated 
            const calcs = termLogic.calculateLeaseTerm(data);
            console.log('calcs->', calcs);
            if (calcs) {
                if (calcs.net_commission) dat.gross_commission = calcs.net_commission;
            }
        }
        net_commission = dat.gross_commission;

        // ------------------
        // Get Distribution percents of each side
        if (data.commission_distribution_lessee) {
            dat.commission_distribution_lessee_percent = parseFloatString(data.commission_distribution_lessee) * 0.01;
            dat.commission_distribution_lessee = dat.gross_commission * dat.commission_distribution_lessee_percent;
            // Entire Consideration Adjustments for LESSEE
            adjustments.forEach(adj => {
                if (adj.option === 'Lessee') {
                    if (adj.consideration === 'Entire Consideration') {
                        dat.commission_distribution_lessee += parseFloatString(adj.value);
                    }
                }
            })
        }
        if (data.commission_distribution_lessor) {
            dat.commission_distribution_lessor_percent = parseFloatString(data.commission_distribution_lessor) * 0.01;
            dat.commission_distribution_lessor = dat.gross_commission * dat.commission_distribution_lessor_percent;
            // Entire Consideration Adjustment for LESSOR
            adjustments.forEach(adj => {
                if (adj.option === 'Lessor') {
                    if (adj.consideration === 'Entire Consideration') {
                        dat.commission_distribution_lessor += parseFloatString(adj.value);
                    }
                }
            })
        }

        console.log('commissionCalc 1', dat);

        // -------------------
        // Get Presplits
        if (data.presplits) {
            data.presplits.forEach(dp => {
                if (dp.presplit_applies_to === 'Both Lessor and Lessee') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = commission_distribution_lessor * remaining_net_commission;
                        dpClone.lessee_commission_distribution = commission_distribution_lessee * remaining_net_commission;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - (to_be_subtracted / 2);
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - (to_be_subtracted / 2);

                        if (
                            calculated_commission_distribution_lessee < (to_be_subtracted / 2) ||
                            calculated_commission_distribution_lessor < (to_be_subtracted / 2)
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            dpClone.lessee_remaining_commission = 0;
                        }
                        // add adjustments
                        // Percentage so to_be_subtracted should be where the adjustment takes place 
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 1', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                    if (adj.consideration_selection.indexOf(name) !== -1) {
                                        console.log('got here 1');
                                        dpClone.to_be_subtracted += parseFloatString(adj.value);
                                    }
                                }
                            }
                        })
                        console.log('got here 1', dpClone);
                        dat.presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = commission_distribution_lessor * remaining_net_commission;
                        dpClone.lessee_commission_distribution = commission_distribution_lessee * remaining_net_commission;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - (to_be_subtracted / 2);
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - (to_be_subtracted / 2);

                        if (
                            calculated_commission_distribution_lessee < (to_be_subtracted / 2) ||
                            calculated_commission_distribution_lessor < (to_be_subtracted / 2)
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                            dpClone.lessee_remaining_commission = 0;
                        }
                        // add adjustments
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 2', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                    if (adj.consideration_selection.indexOf(name) !== -1) {
                                        const value = parseFloatString(dpClone.dollar_value);
                                        dpClone.dollar_value = value + parseFloatString(adj.value);
                                    }
                                }
                            }
                        })
                        dat.presplitCalculations.push(dpClone)
                    }   
                } else if (dp.presplit_applies_to === 'Lessor Only') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission) - to_be_subtracted;
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission);

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - to_be_subtracted;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee;

                        if (
                            calculated_commission_distribution_lessor < to_be_subtracted
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                        }
                        // apply if specific individual Commission Adjustment
                        // Percentage so to_be_subtracted should be where the adjustment takes place 
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 3', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.option === 'Lessor') {
                                if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                        if (adj.consideration_selection.indexOf(name) !== -1) {
                                            dpClone.to_be_subtracted += parseFloatString(adj.value);
                                        }
                                    }
                                }
                            }
                        })
                        dat.presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission) - to_be_subtracted;
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission);

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor - to_be_subtracted;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee;

                        if (
                            calculated_commission_distribution_lessor < to_be_subtracted
                        ) {
                            dpClone.lessor_remaining_commission = 0;
                        }
                        // apply if specific individual Commission Adjustment
                        // Dollar Value so to_be_subtracted should be where the adjustment takes place 
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 4', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.option === 'Lessor') {
                                if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                        if (adj.consideration_selection.indexOf(name) !== -1) {
                                            const value = parseFloatString(dpClone.dollar_value);
                                            dpClone.dollar_value = value + parseFloatString(adj.value);
                                        }
                                    }
                                }
                            }
                        })
                        dat.presplitCalculations.push(dpClone)
                    }
                } else if (dp.presplit_applies_to === 'Lessee Only') {
                    if (dp.value_type === 'Percentage' && dp.percentage_value) {
                        const perc = parseFloat(dp.percentage_value) * 0.01;
                        const to_be_subtracted = net_commission * perc;
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission);
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission) - to_be_subtracted;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - to_be_subtracted;

                        if (
                            calculated_commission_distribution_lessee < to_be_subtracted
                        ) {
                            dpClone.lessee_remaining_commission = 0;
                        }
                        // add adjustments
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 5', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.option === 'Lessee') {
                                if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                        if (adj.consideration_selection.indexOf(name) !== -1) {
                                            dpClone.to_be_subtracted += parseFloatString(adj.value);
                                        }
                                    }
                                }
                            }
                        })
                        dat.presplitCalculations.push(dpClone)
                    } else if (dp.value_type === 'Dollar Value' && dp.dollar_value) {
                        const to_be_subtracted = parseFloatString(dp.dollar_value);
                        const remaining_net_commission = net_commission - to_be_subtracted;
                        const dpClone = cloneJ(dp);
                        dpClone.net_commission = net_commission;
                        dpClone.to_be_subtracted = to_be_subtracted;
                        dpClone.remaining_net_commission = remaining_net_commission;
                        dpClone.lessor_commission_distribution = (commission_distribution_lessor * net_commission);
                        dpClone.lessee_commission_distribution = (commission_distribution_lessee * net_commission) - to_be_subtracted;

                        dpClone.lessor_remaining_commission = calculated_commission_distribution_lessor;
                        dpClone.lessee_remaining_commission = calculated_commission_distribution_lessee - to_be_subtracted;

                        if (
                            calculated_commission_distribution_lessee < to_be_subtracted
                        ) {
                            dpClone.lessee_remaining_commission = 0;
                        }
                        // add Adjustments
                        const name = theVal(`${theVal(dpClone.first_name)} ${theVal(dpClone.last_name)} ${theVal(dpClone.company_name)}`);
                        console.log('looking for name... 6', name, adjustments);
                        adjustments.forEach(adj => {
                            if (adj.option === 'Lessee') {
                                if (adj.consideration === 'Specific Individual' && isValid(adj.consideration_selection)) {
                                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                        if (adj.consideration_selection.indexOf(name) !== -1) {
                                            const value = parseFloatString(dpClone.dollar_value);
                                            dpClone.dollar_value = value + parseFloatString(adj.value);
                                        }
                                    }
                                }
                            }
                        })
                        dat.presplitCalculations.push(dpClone)
                    }
                }
            })
        }

        // Start with the whole distribution values first
        dat.commission_distribution_lessor_after_presplits = dat.commission_distribution_lessor;
        dat.commission_distribution_lessee_after_presplits = dat.commission_distribution_lessee;
        // Deduct Presplits from distributed commission 
        dat.presplitCalculations.forEach(dp => {
            if (dp.presplit_applies_to === 'Both Lessor and Lessee') {
                // divide by 2 
                const each_subtraction = dp.to_be_subtracted / 2;
                dat.commission_distribution_lessee_after_presplits = dat.commission_distribution_lessee_after_presplits - each_subtraction;
                dat.commission_distribution_lessor_after_presplits = dat.commission_distribution_lessor_after_presplits - each_subtraction;
            } else if (dp.presplit_applies_to === 'Lessor Only') {
                dat.commission_distribution_lessor_after_presplits = dat.commission_distribution_lessor_after_presplits - dp.to_be_subtracted;
            
            } else if (dp.presplit_applies_to === 'Lessee Only') {
                dat.commission_distribution_lessee_after_presplits = dat.commission_distribution_lessee_after_presplits - dp.to_be_subtracted;
            }
        })

        // Now deduct the outside brokers and referrals from each side
        dat.commission_distribution_lessee_after_outside_brokers = dat.commission_distribution_lessee_after_presplits;
        dat.commission_distribution_lessor_after_outside_brokers = dat.commission_distribution_lessor_after_presplits;
        // ------
        // LESSEE
        // ------
        // OUTSIDE BROKER - are only % ?
        let lessee_deduction = 0;
        if (data.outside_broker) {
            if (data.outside_broker.length > 0) {
                data.outside_broker.forEach(dob => {
                    dob.name = theVal(`${theVal(dob.first_name)} ${theVal(dob.last_name)} ${theVal(dob.company_name)}`);
                    dob.percentage = parseFloatString(dob.commission) * 0.01;
                    dob.value = dob.percentage * dat.commission_distribution_lessee_after_presplits;
                    // check if there is Commission Adjustment for Lessee Outside Broker
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessee' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Outside Broker') !== -1) {
                                if (adj.consideration_selection.indexOf(dob.name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        dob.value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    lessee_deduction += dob.value;
                })
            }
        }
        dat.lessee_outside_brokers = cloneJ(data.outside_broker);
        dat.commission_distribution_lessee_after_outside_brokers = dat.commission_distribution_lessee_after_outside_brokers - lessee_deduction;
        // ------
        // LESSOR
        // ------
        // OUTSIDE BROKER - are only % ?
        let lessor_deduction = 0;
        if (data.lessor_outside_broker) {
            if (data.lessor_outside_broker.length > 0) {
                data.lessor_outside_broker.forEach(dob => {
                    dob.name = theVal(`${theVal(dob.first_name)} ${theVal(dob.last_name)} ${theVal(dob.company_name)}`);
                    dob.percentage = parseFloatString(dob.commission) * 0.01;
                    dob.value = dob.percentage * dat.commission_distribution_lessor_after_presplits;
                    // check if there is Commission Adjustment for Lessor Outside Broker
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessor' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Outside Broker') !== -1) {
                                if (adj.consideration_selection.indexOf(dob.name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        dob.value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    lessor_deduction += dob.value;
                })
            }
        }
        dat.lessor_outside_brokers = cloneJ(data.lessor_outside_broker);
        dat.commission_distribution_lessor_after_outside_brokers = dat.commission_distribution_lessor_after_outside_brokers - lessor_deduction;


        // *************
        // * REFERRALS *
        // *************
        // starting point
        dat.commission_distribution_lessee_after_referrals = dat.commission_distribution_lessee_after_outside_brokers;
        dat.commission_distribution_lessor_after_referrals = dat.commission_distribution_lessor_after_outside_brokers;
        // ------
        // LESSEE
        // ------
        // REFERRALS
        // Primary - 
        if (data.lessee_referral_firm_fee_amount_choice === 'Value') {
            const name = theVal(`${theVal(data.lessee_referral_firm_name)} ${theVal(data.lessee_referral_firm_lastname)} ${theVal(data.lessee_referral_firm_company_name)}`);
            let value = parseFloatString(data.lessee_referral_firm_fee_amount);
            // check if there is Lessee Referral Commission Adjustment
            adjustments.forEach(adj => {
                if (adj.option === 'Lessee' && isValid(adj.consideration_selection)) {
                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                        if (adj.consideration_selection.indexOf(name) !== -1) {
                            if (adj.consideration === 'Specific Individual') {
                                value += parseFloatString(adj.value);
                            }
                        }
                    }
                }
            })
            dat.lessee_referrals.push({
                name,
                percentage: 'N/A',
                value
            });
        } else if (data.lessee_referral_firm_fee_amount_choice === 'Percentage') {
            const name = theVal(`${theVal(data.lessee_referral_firm_name)} ${theVal(data.lessee_referral_firm_lastname)} ${theVal(data.lessee_referral_firm_company_name)}`);
            const percentage = parseFloatString(data.lessee_referral_firm_fee_amount_percentage) * 0.01;
            let value = percentage * dat.commission_distribution_lessee_after_presplits;
            // check if there is Lessee Referral Commission Adjustment
            adjustments.forEach(adj => {
                if (adj.option === 'Lessee' && isValid(adj.consideration_selection)) {
                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                        if (adj.consideration_selection.indexOf(name) !== -1) {
                            if (adj.consideration === 'Specific Individual') {
                                value += parseFloatString(adj.value);
                            }
                        }
                    }
                }
            })
            dat.lessee_referrals.push({
                name,
                percentage,
                value
            });
        }
        // Other -
        if (data.lessee_referrals) {
            data.lessee_referrals.forEach(dlr => {
                if (dlr.fee_amount_choice === 'Value') {
                    let value = parseFloatString(dlr.fee_amount);
                    const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                    // check if there is Lessee Referral Commission Adjustment
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessee' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                if (adj.consideration_selection.indexOf(name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    dat.lessee_referrals.push({
                        name,
                        percentage: 'N/A',
                        value
                    });
                } else if (dlr.fee_amount_choice === 'Percentage') {
                    const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                    const percentage = parseFloatString(dlr.fee_amount_percentage) * 0.01;
                    let value = percentage * dat.commission_distribution_lessee_after_presplits;
                    // check if there is Lessee Referral Commission Adjustment
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessee' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                if (adj.consideration_selection.indexOf(name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    dat.lessee_referrals.push({
                        name,
                        percentage,
                        value
                    });
                }
            })
        }
        let lessee_deduction2 = 0;
        dat.lessee_referrals.forEach(dlr => {
            lessee_deduction2 += dlr.value;
        })
        dat.commission_distribution_lessee_after_referrals = dat.commission_distribution_lessee_after_referrals - lessee_deduction2;


        // ------
        // LESSOR
        // ------
        // REFERRALS
        // Primary - 
        if (data.referral_firm_fee_amount_choice === 'Value') {
            let name = theVal(`${theVal(data.referral_firm_name)} ${theVal(data.referral_firm_lastname)} ${theVal(data.referral_firm_company_name)}`);
            let value = parseFloatString(data.referral_firm_fee_amount);
            // check if there is Lessor Adjustment
            adjustments.forEach(adj => {
                if (adj.option === 'Lessor' && isValid(adj.consideration_selection)) {
                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                        if (adj.consideration_selection.indexOf(name) !== -1) {
                            if (adj.consideration === 'Specific Individual') {
                                value += parseFloatString(adj.value);
                            }
                        }
                    }
                }
            })
            dat.lessor_referrals.push({
                name,
                percentage: 'N/A',
                value
            });
        } else if (data.referral_firm_fee_amount_choice === 'Percentage') {
            let name = theVal(`${theVal(data.referral_firm_name)} ${theVal(data.referral_firm_lastname)} ${theVal(data.referral_firm_company_name)}`);
            const percentage = parseFloatString(data.referral_firm_fee_amount_percentage) * 0.01;
            let value = percentage * dat.commission_distribution_lessor_after_presplits;
            // check if there is Lessor Adjustment
            adjustments.forEach(adj => {
                if (adj.option === 'Lessor' && isValid(adj.consideration_selection)) {
                    if (adj.consideration_selection.indexOf('Referral') !== -1) {
                        if (adj.consideration_selection.indexOf(name) !== -1) {
                            if (adj.consideration === 'Specific Individual') {
                                value += parseFloatString(adj.value);
                            }
                        }
                    }
                }
            })
            dat.lessor_referrals.push({
                name,
                percentage,
                value
            });
        }
        // Other -
        if (data.lessor_referrals) {
            data.lessor_referrals.forEach(dlr => {
                if (dlr.fee_amount_choice === 'Value') {
                    let value = parseFloatString(dlr.fee_amount);
                    const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                    // check if there is Lessor Adjustment
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessor' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                if (adj.consideration_selection.indexOf(name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    dat.lessor_referrals.push({
                        name,
                        percentage: 'N/A',
                        value
                    });
                } else if (dlr.fee_amount_choice === 'Percentage') {
                    const name = theVal(`${theVal(dlr.name)} ${theVal(dlr.lastname)} ${theVal(dlr.company_name)}`);
                    const percentage = parseFloatString(dlr.fee_amount_percentage) * 0.01;
                    let value = percentage * dat.commission_distribution_lessor_after_presplits;
                    // check if there is Lessor Adjustment
                    adjustments.forEach(adj => {
                        if (adj.option === 'Lessor' && isValid(adj.consideration_selection)) {
                            if (adj.consideration_selection.indexOf('Referral') !== -1) {
                                if (adj.consideration_selection.indexOf(name) !== -1) {
                                    if (adj.consideration === 'Specific Individual') {
                                        value += parseFloatString(adj.value);
                                    }
                                }
                            }
                        }
                    })
                    dat.lessor_referrals.push({
                        name,
                        percentage,
                        value
                    });
                }
            })
        }
        let lessor_deduction2 = 0;
        dat.lessor_referrals.forEach(dlr => {
            lessor_deduction2 += dlr.value;
        })
        dat.commission_distribution_lessor_after_referrals = dat.commission_distribution_lessor_after_referrals - lessor_deduction2;

        // Calculate what's left for the Associates
        // commission_splits_agent2 = %s of Lessee
        dat.associate_commission_splits_lessee = [];
        if (data.commission_splits_agent2) {
            data.commission_splits_agent2.forEach(csa => {
                const perc = parseFloatString(csa.Percentage) * 0.01;
                dat.associate_commission_splits_lessee.push({
                    name: csa.Name,
                    perc,
                    value: perc * dat.commission_distribution_lessee_after_referrals,
                    id: csa.id,
                    dba_type: csa.dba_type
                })
            })
        }
        // commission_splits_agent = %s of Lessor
        dat.associate_commission_splits_lessor = [];
        if (data.commission_splits_agent) {
            data.commission_splits_agent.forEach(csa => {
                const perc = parseFloatString(csa.Percentage) * 0.01;
                dat.associate_commission_splits_lessor.push({
                    name: csa.Name,
                    perc,
                    value: perc * dat.commission_distribution_lessor_after_referrals,
                    id: csa.id,
                    dba_type: csa.dba_type
                })
            })
        }

        console.log('commissionCalc', dat);

        return dat;
    }
}