import React, { useEffect, useState, useRef } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import { clone } from 'shared/utility';
import CustomDialog from 'components/CustomDialog/CustomDialogWithContact';
import CustomInput from 'components/CustomInput/CustomInputValidate';
import moment from 'moment';
import PMAElement from './PMAElement';
import Table from 'components/Table/Table.jsx';
import { Dialog } from '@material-ui/core';
import { getUser } from 'shared/authValidation';
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';
import { createAddressObject } from '../../CRM/Properties/PropertiesLogic';

const use = (val) => {
	return val ? val : '';
};

const PMAMulti1 = (props) => {
	const {
		formData,
		setFormData,
		setFormData2,
		contactData,
		getContact,
		contactNames,
		updateDataObj,
		tabId,
		setTabId,
	} = props;

	const [addContact, setAddContact] = useState(false);
	const [ownerNames, setOwnerNames] = useState([]);
	const [ownerObj, setOwnerObj] = useState(null);
	const [askWhatName, setAskWhatName] = useState(false);
	const [target, setTarget] = useState(false);
	const [userPerms, setUserPerms] = useState({});
	const [chosenDba, setChosenDba] = useState(null);

	const renderElement = (field, access) => {
		return (
			<PMAElement
				updateDataObj={updateDataObj}
				data={formData}
				setData={setFormData}
				field={field}
				access={access}
			/>
		);
	};

	const renderInput = (field, index) => {
		return (
			<input
				className='input-on-table'
				value={
					index === -1
						? formData.owner[field]
						: formData.other_owners[index][field]
						? formData.other_owners[index][field]
						: ''
				}
				onChange={(e) => {
					if (index === -1) {
						const newData = clone(formData);
						newData.owner[field] = e.target.value;
						setFormData(newData);
					} else {
						const newData = clone(formData);
						newData.other_owners[index][field] = e.target.value;
						setFormData(newData);
					}
				}}
			/>
		);
	};

	const renderInput2 = (field, index, fdd) => {
		console.log(fdd)
		const obj = index === -1 ? fdd.owner : fdd.other_owners[index];
		const value = obj[field] ? obj[field] : '';
		return (
			<div style={{display: 'flex'}}>
				<input
					className='input-on-table'
					value={value}
					onChange={(e) => {
						if (index === -1) {
							const newData = clone(fdd);
							newData.owner[field] = e.target.value;
							setFormData(newData);
						} else {
							const newData = clone(fdd);
							newData.other_owners[index][field] = e.target.value;
							setFormData(newData);
						}
					}}
				/>
				{value.includes(' dba ') && obj?.dba_active === 0 && 
				<div style={{
					fontSize: '1.1em',
					fontWeight: 'bold',
					color: 'red',
					whiteSpace: 'nowrap'
				}}>(dba removed)</div>}
			</div>
		);
	};

	useEffect(() => {
		
		if (!formData.date) {
			const newData = clone(formData);
			newData.date = moment().format('MMMM D, YYYY');
			setFormData2(newData);
		}
		const user = getUser();
		if (user) {
			if (user.permObj) {
				setUserPerms(user.permObj);
			}
		}
	}, []);

	useEffect(() => {
		
		if (contactData && tabId === 'Owner Information') {
			if (target) {
				const pc = contactData;
				createOwner(pc);
				props.updateDataCheck('contacts', contactData);
				setTarget(false);
			}
		}
	}, [contactData]);

	const createOwner = (pc, ownerType) => {
		if (ownerType === 'company') {
			const newData = clone(formData);
			const obj = pc;
			const company = obj.company_data;
			const signers = obj.signers;
			const dba = obj.dba_id ? obj.dba_data.find(i => i.id === obj.dba_id) : null;

            if (pc.property && pc.property.id) {
                const { property } = pc;
				const propertyObj = createAddressObject(property);
                newData.property = {
                    id: property.id,
                    address: propertyObj.street1,
					address2: propertyObj.street2,
					city: propertyObj.city,
					state: propertyObj.state,
					zip: propertyObj.zip
                };
            }
            console.log('singers', signers, company);
			signers.forEach((s) => {
				const s_data = s.company_dats; // company specific data
				let m_data = {};
				if (s.addresses) {
					s.addresses.forEach(sa => {
						if (sa.type === 'M') m_data = sa;
					})
				}
				const street1 = company.mailing_address1 ? `${company.mailing_address1} ` : '';
				const street2 = company.mailing_address2 ? `${company.mailing_address1} ` : '';
				const city = company.mailing_city ? ` ${company.mailing_city}, ` : '';
				const state = company.mailing_state ? ` ${company.mailing_state}` : '';
				const zip = company.mailing_zip ? ` ${company.mailing_zip}` : '';
				const the_name = `${s.first_name ? s.first_name : ''} ${s.last_name ? s.last_name : ''}`;
				const companyName = `${company.name} ${dba ? `dba ${dba.name}` : ''}`;
				const dba_id = dba?.id || null;
				if (!newData.owner.name) {
					// owner 1
					newData.owner.name = the_name;
					newData.owner.company_name = companyName;
					newData.owner.title = s_data.title;
					newData.owner.address = street1 + street2 + city + state + zip;
					newData.owner.phone = `${use(s_data.phone)}`;
					newData.owner.email = `${use(s_data.email)}`;
					newData.owner.id = s.id;
					newData.owner.company_id = company.id; // denotes company
					newData.owner.dba_id = dba_id;
				} else {
					// other owners
					if (!newData.other_owners) newData.other_owners = [];
					const ownerObject = {
						company_name: companyName,
						title: s_data.title,
						name: the_name,
						address: street1 + street2 + city + state + zip,
						phone: `${use(s_data.phone)}`,
						email: `${use(s_data.email)}`,
						id: s.id,
						company_id: company.id,
						dba_id
					};
					newData.other_owners.push(ownerObject);
				}
			});
			setFormData(newData);
		} else {
			// Contact
			const addresses = { M: {} };
			pc.addresses.forEach((pc) => {
				addresses[pc.type] = pc;
			});

			const newData = clone(formData);
			const owner_name = `${pc.first_name ? pc.first_name : ''} ${pc.last_name ? pc.last_name : ''} ${chosenDba ? `dba ${chosenDba.name}` : ''}`;
			const dba_id = chosenDba?.id || null;
			if (!newData.owner.name) {
				// owner 1
				newData.owner.name = owner_name;
				newData.owner.company_name = owner_name;
				newData.owner.title = pc.title;
				const street1 = addresses.M.street1 ? `${addresses.M.street1},` : '';
				const street2 = addresses.M.street2 ? ` ${addresses.M.street2},` : '';
				const city = addresses.M.city ? ` ${addresses.M.city},` : '';
				const state = addresses.M.state ? ` ${addresses.M.state}` : '';
				const zip = addresses.M.zip ? ` ${addresses.M.zip}` : '';
				newData.owner.address = street1 + street2 + city + state + zip;
				newData.owner.phone = `${use(pc.main_phone)}`;
				newData.owner.email = `${use(pc.email)}`;
				newData.owner.id = pc.id;
				newData.owner.company_id = null;
				newData.owner.dba_id = dba_id;
			} else {
				// other owners
				if (!newData.other_owners) newData.other_owners = [];
				const street1 = addresses.M.street1 ? `${addresses.M.street1},` : '';
				const street2 = addresses.M.street2 ? ` ${addresses.M.street2},` : '';
				const city = addresses.M.city ? ` ${addresses.M.city},` : '';
				const state = addresses.M.state ? ` ${addresses.M.state}` : '';
				const zip = addresses.M.zip ? ` ${addresses.M.zip}` : '';
				const ownerObject = {
					company_name: pc.company_name,
					title: pc.title,
					name: owner_name,
					address: street1 + street2 + city + state + zip,
					phone: `${use(pc.main_phone)}`,
					email: `${use(pc.email)}`,
					id: pc.id,
					company_id: null,
					dba_id,
				};
				newData.other_owners.push(ownerObject);
			}
			setFormData(newData);
		}
	};

	const choose = (the_contact_data, filler, theContactDba) => {
		if (the_contact_data.company_data) {
			// company has been chosen

			createOwner(the_contact_data, 'company');
		} else {
			// contact has been chosen
			setTabId('Owner Information');
			setTarget(true); // make sure owner is filled up once
			const ownerName = the_contact_data.name;
			const ownerID = the_contact_data.id;
			const newOwnerNames = clone(ownerNames).concat([{ id: ownerID, name: ownerName }]);
			setOwnerNames(newOwnerNames);
			setAddContact(false);
			setChosenDba(theContactDba);
			getContact(ownerID);
		}
	};

	return (
		<div>
			<GridContainer justify='center'>
				<GridItem xs={12} sm={12} md={12}>
					<Button
						color='primary'
						onClick={() => {
							setAddContact(true);
						}}
					>
						ADD OWNER
					</Button>
					<hr />
					<center>
						<h3>Owner(s) Information</h3>
					</center>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{renderElement('pma_id', userPerms.hasOwnProperty('PMA number edit') ? 'yes' : 'no')}
					{renderElement('date')}
					<hr />
					{!formData.owner.name && <strong>0 Owner(s)</strong>}
					{formData.owner.name && <strong>Owner(s)</strong>}
					{formData.owner.name && (
						<div style={{ lineHeight: 1.5 }} className='mt-20 boxed'>
							<h4 className='text-center'>Owner 1</h4>
							<Table
								tableData={[
									['Company Name', renderInput2('company_name', -1, formData)],
									['Representative Name', renderInput2('name', -1, formData)],
									['Title', renderInput2('title', -1, formData)],
									['Street Address', renderInput2('address', -1, formData)],
									['Phone', renderInput2('phone', -1, formData)],
									['Email', renderInput2('email', -1, formData)],
								].map((m) => {
									return [
										<div style={{ textAlign: 'right' }}>
											<strong>{m[0]}</strong>
										</div>,
										m[1],
									];
								})}
							/>
							<div style={{ textAlign: 'right' }}>
								{
									<span
										className='hoverable red-text'
										onClick={() => {
											const newData = clone(formData);
											let new_company_name = '';
											let new_name = '';
											let new_title = '';
											let new_address = '';
											let new_phone = '';
											let new_email = '';
											let new_id = '';
											if (formData.other_owners) {
												if (formData.other_owners.length > 0) {
													newData.other_owners = newData.other_owners.filter((oo, i) => {
														if (i === 0) {
															new_company_name = oo.company_name;
															new_name = oo.name;
															new_title = oo.title;
															new_address = oo.address;
															new_phone = oo.phone;
															new_email = oo.email;
															new_id = oo.id;
															return false;
														}
														return true;
													});
												}
											}
											newData.owner.company_name = new_company_name;
											newData.owner.name = new_name;
											newData.owner.title = new_title;
											newData.owner.address = new_address;
											newData.owner.phone = new_phone;
											newData.owner.email = new_email;
											newData.owner.id = new_id;
											setFormData(newData);
										}}
									>
										<i className='fas fa-trash'></i> REMOVE
									</span>
								}
							</div>
						</div>
					)}
					{formData.other_owners.map((r, j) => {
						return (
							<div style={{ lineHeight: 1.5 }} className='mt-20 boxed' key={`${j}-oo`}>
								<h4 className='text-center'>Owner {j + 2}</h4>
								<Table
									tableData={[
										['Company Name', 'company_name'],
										['Representative Name', 'name'],
										['Title', 'title'],
										['Street Address', 'address'],
										['Phone', 'phone'],
										['Email', 'email'],
									].map((m) => {
										return [
											<div style={{ textAlign: 'right' }}>
												<strong>{m[0]}</strong>
											</div>,
											renderInput2(m[1], j, formData),
										];
									})}
								/>
								<div style={{ textAlign: 'right' }}>
									<span
										className='hoverable red-text'
										onClick={() => {
											updateDataObj(
												'other_owners',
												formData.other_owners.filter((oo, x) => {
													if (x === j) return false;
													return true;
												})
											);
										}}
									>
										<i className='fas fa-trash'></i> REMOVE
									</span>
								</div>
							</div>
						);
					})}
				</GridItem>
			</GridContainer>

			{askWhatName && (
				<Dialog
					open={askWhatName}
					onClose={() => {
						setAskWhatName(false);
					}}
					fullWidth={true}
					maxWidth='sm'
				>
					<div
						className='dialog-wrapper'
						style={{ paddingLeft: 40, paddingRight: 40, marginTop: 20, marginBottom: 30 }}
					>
						<h3>Chosen Owner</h3>
						<strong>Name</strong> : {ownerObj.first_name} {ownerObj.last_name}
						<br />
						<br />
						<strong>Company Name</strong> : {ownerObj.company_name}
						<br />
						<br />
						<strong>Title</strong> : {ownerObj.title}
						<br />
						<br />
						<strong>Phone</strong> : {ownerObj.main_phone}
						<br />
						<br />
						<strong>Email</strong> : {ownerObj.email}
						<br />
						<br />
						<Button
							className='mr-20'
							color='primary'
							onClick={() => {
								createOwner(ownerObj, 'name');
								setAskWhatName(false);
							}}
						>
							OK
						</Button>
						<Button
							color='white'
							onClick={() => {
								setAskWhatName(false);
								setAddContact(true);
							}}
						>
							Cancel
						</Button>
					</div>
				</Dialog>
			)}

			{addContact && (
				<CustomDialogChooser
					open={addContact}
					close={() => {
						setAddContact(false);
					}}
					choose={choose}
					create_and_choose={choose}
					peopleType='owners'
					title='Select Contact or Company'
					label='Search Contacts'
					current={contactNames.map((a) => a.name).join(',')}
					forceChooseType={true}
				/>
			)}
		</div>
	);
};

export default PMAMulti1;
