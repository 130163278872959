import React from 'react';
import ertlFields from 'containers/ERTL/FormWizard/ERTLModel';
import { commatize, formatDate3 } from '../../../shared/utility';
import GridItem from '../../../components/Grid/GridItem';
import GridContainer from '../../../components/Grid/GridContainer';
import { createOwnerName } from '../FormWizard/ERTLLogic';

const dat = (val) => {
    if (val) return val;
    return '';
}

const isRequired = (d) => {
    if (d.validations) {
        if (d.validations.indexOf('required') !== -1) {
            return true;
        }
    }
    return false;
}

const BaseTemplate = (props) => {

    const {ertlFormData, openDataModal, textData, openModal, getDifferences, templateText, shouldShow} = props;

    console.log('text teplates', textData, templateText, ertlFormData)

    const formInput = (field, readOnly, simpleFormat, thousands) => {
        if (props.origin === 'view') readOnly = true;
        if (!ertlFormData.hasOwnProperty(field)) return <strong className="line-block">{field}</strong>;
        let fieldSplit = field.split('.');
        let newField = fieldSplit.length > 1 ? ertlFormData[fieldSplit[0]][fieldSplit[1]] : ertlFormData[field];
        if (field.indexOf('writer') !== -1) return <span>{newField}</span>
        if (field === 'p3_owner_contact_names' && !simpleFormat) {
            if (ertlFormData[field]) {
                const names = ertlFormData[field].split(',');
                const titles = ertlFormData['p3_owner_contact_titles'] ? 
                    ertlFormData['p3_owner_contact_titles'].split(',')
                :
                    [];
                return (
            <table style={{width:'100%'}}>
                <tbody>
                    <tr>
                        {names.map((name,i) => {
                            return <td key={`name-${i}`} style={{width:'50%'}}>
                                By :<br />

                                <span className="lightgray">__DOCUSIGN__</span><br />
                                <br />
                                Name : {name}<br />
                                <br />
                                Its : {titles[i]}
                                <br />
                                Signature Date : <span className="lightgray">__DOCUSIGN__</span>
                            </td>
                        })}
                    </tr>
                </tbody>
            </table>
                )
            }
        }
        
        return (
            <span
                className={`hovered line-block ${newField ? 
                'green-block' 
                    :   
                isRequired(ertlFields[field]) ?
                    'red-block' : ''}`}
                style={{ display: 'inline-block' }}
                onClick={() => {
                    if (readOnly) return
                    openDataModal(field);
                }}>
                    {!newField && <span className="gray-text">{ertlFields[field] ? ertlFields[field].label : ''}</span>}
                    {newField && <span><strong>{thousands ? commatize(newField) : newField}</strong></span>}
            </span>
        )
    }

    const text = {};
    Object.keys(textData).forEach(f => {
        text[f] = `${textData[f]}`;
    })

    const textInputs = {};
    const style = props.origin === 'view' ? {} : { borderBottom: '1px solid rgb(251 251 251)' }

    let changedSections = [];
    if (props.historyDiffData) {
        props.historyDiffData.forEach(hdd => {
            if (hdd) {
                if (hdd.templateDataChanges) {
                    if (Object.keys(hdd.templateDataChanges).length > 0) {
                        Object.keys(hdd.templateDataChanges).forEach(hddt => {
                            if (changedSections.indexOf(hddt) === -1) {
                                changedSections.push(hddt);
                            }
                        })
                    }
                }
            }
        })
    }

    console.log('ERTLBasetemplate', textData, templateText);

    const getHistoryDiffs = (history, f, shouldShow) => {
        const author = props.origin === 'approver' ? 'Approver' : '';
        if (changedSections.indexOf(f) === -1) {
            return getDifferences(textData[f], templateText[f], shouldShow, author) 
        }
        const lastHistory = history.length > 0 ? history[history.length - 1] : null;
        return <div>
            {(props.origin === 'edit' || props.origin === 'approver') && getDifferences(
                lastHistory ? lastHistory.templateText[f] : textData[f], 
                templateText[f], 
                shouldShow, 
                author)}
            {history.map((h,i) => {
                if (h.templateDataChanges) {
                    if (Object.keys(h.templateDataChanges).length === 0) {
                        return null;
                    }
                }
                let previous = textData[f];
                if (i !== 0) {
                    previous = history[i-1].templateText[f];
                }
                return <div key={`${i}-history`} className="tiny-boxed parentable">
                    <small className="gray-text author-block">{formatDate3(h.created_date)} MODIFIED BY {h.author}</small>
                    {getDifferences(previous ,h.templateText[f], shouldShow, h.author)}
                </div>
            }).reverse()}
        </div>
    }

    Object.keys(textData).forEach(f => {
        // console.log('ERTLBaseTemplate -> A', f, textData[f]);
        // console.log('ERTLBaseTemplate -> B', f, templateText[f]);
        textInputs[f] = <span
            key={`input-${f}`}
            style={style}
            onClick={() => {
                if (props.templateOnClick) {
                    return props.templateOnClick(f);
                }
                if (props.origin !== 'view') openModal(f);
            }}
            className="hoverable gray-hover bluish-hover text-input">
                {!props.historyDiffData ? 
                    getDifferences(textData[f], templateText[f], shouldShow) 
                : 
                    null}
                {props.historyDiffData ? 
                    getHistoryDiffs(props.historyDiffData, f, shouldShow) 
                : 
                    null}
            </span>;
    })

    const property_owner_fullnames = ertlFormData.property_owners ? <span className="hovered line-block green-block">{ertlFormData.property_owners.map((po,i) => {
        return `${po.first_name} ${po.last_name}`
    }).join(', ')}</span> : '';

    const property_owner_emails = ertlFormData.property_owners ? <span className="hovered line-block green-block">{ertlFormData.property_owners.map((po,i) => {
        return po.email;
    }).join(', ')}</span> : '';

    const property_owner_phones = ertlFormData.property_owners ? <span className="hovered line-block green-block">{ertlFormData.property_owners.map((po,i) => {
        return po.phone;
    }).join(', ')}</span> : '';

    return (
        <div>
            <div className="times-new-roman font-16">
                <div>
                    <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png'
                        style={{marginBottom:20,width:100}} /> 
                </div>
                <div>{formInput('p1_date')}</div>
                <div style={{textAlign:'right'}}>{props.ertl_id ? <span style={{fontSize:20}}>#{props.ertl_id}</span> : null}</div>
                <br />
                <br />
                <GridContainer>
                    {ertlFormData.property_owners ? ertlFormData.property_owners.map((po,i) => {
                        if (po.company_id) {
                            // COMPANY
                            return <GridItem xs={12} sm={12} md={3} key={`po-${i}`}>
                                <div>{createOwnerName(po)}</div>
                                <div>c/o {po.first_name} {po.last_name}</div>
                                <div>{po.street1} {po.street2}</div>
                                <div>{po.city},{po.state} {po.zip}</div>
                            </GridItem>
                        } else {
                            // CONTACT
                            return <GridItem xs={12} sm={12} md={3} key={`po-${i}`}>
                                <br />
                                <div>{createOwnerName(po)}</div>
                                <div>{po.street1} {po.street2}</div>
                                <div>{po.city},{po.state} {po.zip}</div>
                            </GridItem>
                        }
                    }) : null}
                </GridContainer>
                
                <br />
                <br />
                <div><strong>{textInputs.t1}{formInput('property_address1')} {formInput('property_address2')},{formInput('property_city')}{formInput('property_zipcode')} {textInputs.t2}</strong></div>
                <br />
                <div>{textInputs.t3}<span className="hovered line-block green-block">{ertlFormData.property_owners ? ertlFormData.property_owners.map((po,i) => {
                    return `${po.first_name}`
                }).join(', ') : null}</span></div>
                <br />
                <br />
                <p>{textInputs.t4}</p>
                <p>{textInputs.t5}</p>
                <p>{textInputs.t6}</p>
                <p>{textInputs.t7}<span className="hovered line-block green-block">{ertlFormData.agents ? ertlFormData.agents.map(a => {
                    return a.name;
                }).join(', ') : ''}</span>{textInputs.t8}{formInput('final_approver_phone')}{textInputs.t9}{formInput('final_approver_email')}</p>
                <br />
                <p>{textInputs.t10}</p>
                <p><b>{textInputs.t11}</b> {textInputs.t12}</p>
                <br />
                <p>{formInput('final_approver')}</p>
                <br />
                <p>{textInputs.t14}<span className="hovered line-block green-block">{ertlFormData.agents ? ertlFormData.agents.map(a => {
                    return a.name;
                }).join(', ') : ''}</span></p>
                <br />
                <center>
                    <p>{textInputs.t15}</p>
                    <p>{textInputs.t16}</p>
                    <p>{textInputs.t17}</p>
                </center>
            </div>
            <hr />
            <div className="text-center">
                <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png'
                     style={{marginBottom:20,width:100}} /> 
            </div>
            <center>
                <b>{textInputs.t18}</b>
                <br /><br />
                <div>{textInputs.t19}{formInput('property_address1')} {formInput('property_address2')},
                    {formInput('property_city')}, {formInput('property_state')} {formInput('property_zipcode')}</div>
            </center>
            <br />
            <br />
            <p>{textInputs.t20} {formInput('p2_date_as_of')} {textInputs.t21}</p>
            <p>{textInputs.t22} {formInput('made_by_and_between')} {textInputs.t23}
                {textInputs.t24} {textInputs.t25}</p>
            <br />
            <p>{textInputs.t26} {formInput('property_address1')} {formInput('property_address2')} {textInputs.t27}
                {formInput('property_city')}, {formInput('property_state')} {textInputs.t28}</p>
            <p>{textInputs.t29}</p>
            <p>{textInputs.t30}</p>
            <ol>
                <li>
                    <p><b className="underlined2">{textInputs.t31}</b>{textInputs.t32}</p>
                    <p>{textInputs.t33} {formInput('name_of_place')}</p>
                    <p>{textInputs.t34} {formInput('total_sqft_footage', null, null, true)} {textInputs.t35}</p>
                    <p>{textInputs.t36} {formInput('total_current_vacant_space', null, null, true)} {textInputs.t37}</p>
                    <p>{textInputs.t38} {formInput('minimum_suite_size_available_for_lease', null, null, true)} {textInputs.t39}</p>
                    <p>{textInputs.t40} ${formInput('approved_lease_terms', null, null, true)} ; {formInput('approved_lease_terms_lease_type')} {textInputs.t41}</p>
                </li>
                <br />
                <li>
                    <p><b className="underlined2">{textInputs.t42}</b>{textInputs.t43} {formInput('initial_term_begin')}{textInputs.t44}</p>
                    <p>{textInputs.t45}{formInput('initial_term_expiration')}{textInputs.t46}</p>
                    <p>{textInputs.t47}{textInputs.t48}</p>
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t49}</b>{textInputs.t50}{textInputs.t51}{textInputs.t52}
                    {textInputs.t53}{textInputs.t54}{textInputs.t55}{textInputs.t56}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t57}</b> {textInputs.t58}{textInputs.t59}{textInputs.t60}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t61}</b> {textInputs.t62}{textInputs.t63}
                    {textInputs.t64}{textInputs.t65}{textInputs.t66}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t67}</b> {textInputs.t68}{textInputs.t69}{textInputs.t70}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t71}</b> {textInputs.t72}{textInputs.t73}{textInputs.t74}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t75}</b> {textInputs.t76}{textInputs.t77}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t78}</b> {textInputs.t79}{textInputs.t80}{textInputs.t81}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t82}</b> {textInputs.t83} {textInputs.t84}{textInputs.t85}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t86}</b> {textInputs.t87}{textInputs.t88}{textInputs.t89}{textInputs.t90}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t91}</b> {textInputs.t92}{textInputs.t93}{textInputs.t94}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t95}</b> {textInputs.t96}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t97}</b> {textInputs.t98}{textInputs.t99}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t100}</b> {textInputs.t101}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t102}</b> {textInputs.t103}{textInputs.t104}{textInputs.t105}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t106}</b> {textInputs.t107}{textInputs.t108}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t109}</b> {textInputs.t110}{textInputs.t114}{textInputs.t115}
                    {textInputs.t116} {textInputs.t117}{textInputs.t118}{textInputs.t119}
                    {textInputs.t120}{textInputs.t121}{textInputs.t122}{textInputs.t123}{textInputs.t124}
                </li>
                <br />
                <li>
                    <b className="underlined2">{textInputs.t125}</b> {textInputs.t126}{textInputs.t127}
                    {textInputs.t128}{textInputs.t129}{textInputs.t130}{textInputs.t131}{textInputs.t132}
                </li>
            </ol>
            <br />
            <br />
            <p><b>{textInputs.t133}{textInputs.t134}{textInputs.t135}{textInputs.t136}</b></p><br />
            <p><b>{textInputs.t137}</b></p>
            <br />
            <p>{textInputs.t138}{textInputs.t139}{textInputs.t140}</p><br />
            <p>{textInputs.t141}</p>
            <br />
            <br />
            <center>
                <table style={{ padding: '20px', border: "3px solid rgb(0, 0, 0)" }}>
                    <colgroup>
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '15%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{textInputs.t156}</th>
                            <th>{textInputs.t157}</th>
                        </tr>
                        <tr>
                            <td>
                                <div><b>{formInput('made_by_and_between')}, 
                                    {textInputs.t158}</b></div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t159}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t160} <span className="lightgray">__DOCUSIGN__</span> </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                {ertlFormData.property_owners && <div>
                                    {ertlFormData.property_owners.map((po,i) => {
                                        return <div key={`po-info-${i}`} className="boxed">
                                            {dat(po.first_name)} {dat(po.last_name)}
                                            <br />
                                            <br />
                                            {textInputs.t164} {dat(po.street1)} {dat(po.street2)} {dat(po.city)} {dat(po.state)} {dat(po.zip)}<br />
                                            {textInputs.t165} {dat(po.phone)}<br />
                                            {textInputs.t167} {dat(po.email)}<br />
                                        </div>
                                    })}
                                </div>}
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t161} {formInput('final_approver')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>  
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t162} {textInputs.t168}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t163}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>

                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t164} {textInputs.t169} </div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t166} {textInputs.t170}</div>
                                <div>{textInputs.t165} {textInputs.t171}</div>
                                <div>Email: {formInput('final_approver_email', true)}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
            <br />
            <br />
            <hr />
            <div className="text-center">
                <img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png'
                     style={{marginBottom:20,width:100}} /> 
            </div>
            <center><p><strong className="underlined2">{textInputs.t142}</strong></p></center>
            <br />
            <p>{textInputs.t143}{textInputs.t144}{textInputs.t145}</p>
            <br />
            <p><b className="underlined2">{textInputs.t146}</b> {textInputs.t147}{textInputs.t148}{textInputs.t149}</p>
            <br />
            <p><b className="underlined2">{textInputs.t150}</b> {textInputs.t151}{textInputs.t152}</p>
            <br />
            <p><b className="underlined2">{textInputs.t153}</b> {textInputs.t154}</p>
            <br /><br />
            <center>
                <table style={{ padding: '20px', border: "3px solid rgb(0, 0, 0)" }}>
                    <colgroup>
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '50%' }} />
                        <col style={{ width: '15%' }} />
                    </colgroup>
                    <tbody>
                        <tr>
                            <th>{textInputs.t156}</th>
                            <th>{textInputs.t157}</th>
                        </tr>
                        <tr>
                            <td>
                                <div><b>{formInput('made_by_and_between')}, 
                                    {textInputs.t158}</b></div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t159}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>{textInputs.t160}</div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t160} <span className="lightgray">__DOCUSIGN__</span></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div>{property_owner_fullnames}</div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t161} {formInput('final_approver')}</div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                            </td>
                            <td style={{ paddingLeft: '20px' }}>
                                <div>{textInputs.t162} {formInput('final_approver_title')}</div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
        </div>
    );
}

export default BaseTemplate