import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

let initialState = {
  data: null,
  error: null,
  loading: false,
  tppMaintenanceStatus: null
}

const readSettingsStart = (state, action) => {
  return updateObject(state, {
    data: null,
    error: null,
    loading: true
  });
}

const readSettingsSuccess = (state, action) => {
  return updateObject(state, {
    data: action.data,
    loading: false
  });
}

const readSettingsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.err
  });
}

const updateSettingsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true
  });
}

const updateSettingsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false
  });
}

const updateSettingsFail = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.err
  });
}

const tppMaintenanceStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    tppMaintenanceStatus: null
  });
}

const tppMaintenanceSuccess = (state, action) => {
  
  let status = null;
  if (action.result) {
    if (action.result.action) {
      status = action.result.action === 'disable' ? 'no' : 'yes';
    }
  }
  // alert('Successfully Updated TPP maintenance - '+action.result.action);
  return updateObject(state, {
    loading: false,
    tppMaintenanceStatus: status
  });
}

const tppMaintenanceFail = (state, action) => {
  
  alert('Error setting TPP maintenance');
  return updateObject(state, {
    loading: false,
    tppMaintenanceStatus: null
  });
}

const tppMaintenanceCheckStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    tppMaintenanceStatus: null
  })
}

const tppMaintenanceCheckSuccess = (state, action) => {
  
  let status = null;
  if (action.response) {
    if (action.response.response) {
      if (action.response.response[0]) {
        if (action.response.response[0].hasOwnProperty('enabled')) {
          status = action.response.response[0].enabled === 0 ? 'no' : 'yes';
        }
      }
    }
  }
  return updateObject(state, {
    loading: false,
    tppMaintenanceStatus: status
  })
}

const tppMaintenanceCheckFail = (state, action) => {
  
  alert('Error checking TPP maintenance status');
  return updateObject(state, {
    loading: false,
    tppMaintenanceStatus: null
  })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.READ_SETTINGS_START:
          return readSettingsStart(state, action);
        case actionTypes.READ_SETTINGS_SUCCESS:
          return readSettingsSuccess(state, action);
        case actionTypes.READ_SETTINGS_FAIL:
          return readSettingsFail(state, action);
        case actionTypes.UPDATE_SETTINGS_START:
          return updateSettingsStart(state, action);
        case actionTypes.UPDATE_SETTINGS_SUCCESS:
          return updateSettingsSuccess(state, action);
        case actionTypes.UPDATE_SETTINGS_FAIL:
          return updateSettingsFail(state, action);
        case actionTypes.TPP_MAINTENANCE_START:
          return tppMaintenanceStart(state, action);
        case actionTypes.TPP_MAINTENANCE_SUCCESS:
          return tppMaintenanceSuccess(state, action);
        case actionTypes.TPP_MAINTENANCE_FAIL:
          return tppMaintenanceFail(state, action);
        case actionTypes.TPP_MAINTENANCE_CHECK_START:
          return tppMaintenanceCheckStart(state, action);
        case actionTypes.TPP_MAINTENANCE_CHECK_SUCCESS:
          return tppMaintenanceCheckSuccess(state, action);
        case actionTypes.TPP_MAINTENANCE_CHECK_FAIL:
          return tppMaintenanceCheckFail(state, action);
        default: return state;
    }
};

export default reducer;
