import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { access_applicable_groups, required_access } from './UserData';
const clone = (obj) => {
    return JSON.parse(JSON.stringify(obj));
}

const renderArr = (arr) => {
    return arr.map(value => {
        return {
            value
        }
    });
}

const updateObj = (obj, field, value) => {
    const newCustomData = clone(obj);
    newCustomData[field] = value;
    return newCustomData;
}

// selected_applicable_groups: '', // separated by comma
// selected_required_access: '', // separated by comma

const UserAdminAccess = (props) => {
    const setCustomData = props.setCustomData;
    const customData = props.customData;
    const selected_applicable_groups = customData.selected_applicable_groups;
    const selected_applicable_groups_arr = selected_applicable_groups.map(a => { return a.value; });
    const selected_required_access = customData.selected_required_access;
    const selected_required_access_arr = selected_required_access.map(a => { return a.value; });

    const update = (arr_name, field, value) => {
        const arr_string = customData[arr_name];
        const arr = arr_string.map(a => { return a.value; });
        if (value) {
            if (arr.indexOf(field) === -1) {
                // add field 
                const new_arr = arr.concat([field]);
                setCustomData(updateObj(customData, arr_name, renderArr(new_arr)));
            }
        } else {
            if (arr.indexOf(field) !== -1) {
                // remove field
                const new_arr = arr.filter(a => a !== field);
                setCustomData(updateObj(customData, arr_name, renderArr(new_arr)));
            }
        }
    }

    return (
    <div style={{height:300,overflowY:'scroll'}}>
        <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
                <hr />
                <p>Select all Applicable Groups to Join :</p>
                {props.groupList ? props.groupList.map(gfield => {
                    const field = gfield.name;
                    const exists = selected_applicable_groups_arr.indexOf(field) === -1 ? false : true;
                    return (
                        <FormGroup row key={field}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={exists}
                                    onChange={() => { update('selected_applicable_groups', field, !exists) }}
                                    color="default"
                                    />
                                }
                                label={field}
                            />
                        </FormGroup>
                    )
                }) : null}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <hr />
                <p>Check all Required Access :</p>
                {required_access.map(field => {
                    const exists = selected_required_access_arr.indexOf(field) === -1 ? false : true;
                    return (
                        <FormGroup row key={field}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={exists}
                                    onChange={() => { update('selected_required_access', field, !exists) }}
                                    color="default"
                                    />
                                }
                                label={field}
                            />
                        </FormGroup>
                    )
                })}
            </GridItem>
        </GridContainer>
    </div>
    );
}

export default UserAdminAccess;