import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import Button from "components/CustomButtons/Button.jsx";
import { IconButton, Tooltip } from '@material-ui/core';
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Notification from 'components/Notification/Notification';
import NotificationAsk from 'components/Notification/NotificationAsk';
import { clone } from 'shared/utility';
import { FadeLoader } from 'react-spinners';
import { getUser } from '../../../shared/authValidation';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Edit, Delete, Save, Close } from "@material-ui/icons";
import Loader from 'components/Loader/Loader';
import { divide, get } from 'lodash';
import CustomDialogWithCompany from 'components/CustomDialog/CustomDialogWithCompany';
import CustomNativeSelect from "components/CustomSelect/CustomNativeSelect.jsx";

const CompaniesFormCompanies = (props) => {
    const loggedInUser = getUser();
    const [openedNewDba, setOpenedNewDba] = useState(false);
    const [dbaName, setDbaName] = useState('');
    const [dbaId, setDbaId] = useState(null); // Edit Mode current DBA ID
    const [message, setMessage] = useState('');
    const [companyList, setCompanyList] = useState([]);
    const [notificationAsk, setNotificationAsk] = useState(false);
    const [openAddCompanyForm, setOpenAddCompanyForm] = useState(false);
    const [editRelatedCompany, setEditRelatedCompany] = useState(null);
    const [unlinkCompany, setUnlinkCompany] = useState(null);
    const [relatedCompanies, setRelatedCompanies] = useState([]);
    const { canEdit, 
        canDelete, 
        dataObj, 
        dbasForAdd,
        setDbasForAdd,
        companyDbas,
        relatedCompaniesForAdd,
        setRelatedCompaniesForAdd,
        companyCompanies
    } = props;

    useEffect(() => {
        if (dataObj.id) {
            props.getCompanyDbas(dataObj.id);
            props.getCompanyCompanies(dataObj.id);
        }
    }, []);

    useEffect(() => {
        setCompanyList(companyDbas.filter(dba => dba.active !== 0));
    }, [companyDbas]);

    useEffect(() => {
        setRelatedCompanies(companyCompanies);
    }, [companyCompanies]);

    const saveNewDba = () => {
        if (!dbaName) {
            setMessage('Please enter DBA name');
        } else {
            if (props.action === 'Edit') {
                props.createCompanyDba({
                    company_id: get(dataObj, `id`, null),
                    name: dbaName
                })
            } else if (props.action === 'Add') {
                setDbasForAdd(dbasForAdd.concat([{
                    name: dbaName,
                    id: dbasForAdd.length
                }]));
            }
            setOpenedNewDba(false);
            setDbaName('');
        }
    };

    console.log('CompaniesFormCompanies', props, dbasForAdd, companyList);

    const choose = (chosen, chosenType, dbaChosen, relationship) => {

        const data = {
            company_id: dataObj.id ? dataObj.id : null,
            company_name: dataObj.name ? dataObj.name : null,
            company_related_name: chosen ? chosen.name : null,
            company_related_id: chosen ? chosen.id : null,
            relationship,
            dba_id: dbaChosen ? dbaChosen.id : null,
            dba_name: dbaChosen ? dbaChosen.name: null
        };

        if (props.action === 'Add') {
            setRelatedCompaniesForAdd([...relatedCompaniesForAdd, {...data, id: Date.now()}])
        } else if (props.action === 'Edit') {
            props.createCompanyCompany(data);
        }
        setOpenAddCompanyForm(false);
    }

    const updateRelatedCompany = (relatedCompany) => {
        const {id, relationship} = relatedCompany;
        if (props.action === 'Add') {
            setRelatedCompanies(relatedCompaniesForAdd.map(item => item.id === id ? {...item, relationship} : item));
            setEditRelatedCompany(null);
        } else if (props.action === 'Edit') {
            props.updateCompanyCompany(relatedCompany);
            setEditRelatedCompany(null);
        }
    }

    const deleteRelatedCompany = (relatedCompany) => {
        if (props.action === 'Add') {
            setRelatedCompaniesForAdd(relatedCompaniesForAdd.filter(item => item.id !== relatedCompany.id));
        } else if (props.action === 'Edit') {
            setUnlinkCompany(relatedCompany);
        }
    }

    return (
    <>
        {props.loadingDBA && <Loader />}
        {props.action === 'Add' && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="red-text">{dbasForAdd.length} {dbasForAdd.length === 1 ? 'DBA' : 'DBAs'} :</h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                {openedNewDba && <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveNewDba();
                    }}
                >
                    <CustomInput
                        label="New DBA"
                        value={dbaName}
                        onChange={(e) => { 
                            setDbaName(e.target.value);
                        }}
                        autoFocus={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={saveNewDba}>
                                    <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                        <Save/>
                                    </Tooltip>
                                </IconButton>

                                <IconButton onClick={() => {
                                    setOpenedNewDba(false);
                                    setDbaName('');
                                }}>
                                    <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                        <Close />
                                    </Tooltip>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </form>}
                {dbasForAdd.sort((a, b) => (a.id > b.id ? -1 : 0)).map((dba,i) => {
                    if (!dba) return null;
                    const disabled = dbaId !== dba.id;
                    const save = () => {
                        if (!dbaName) {
                            setMessage('Please enter DBA name');
                        } else {
                            setDbasForAdd(dbasForAdd.map((cl,j) => {
                                if (j === i) {
                                    const newCL = clone(cl);
                                    newCL.name = dbaName;
                                    return newCL;
                                }
                                return cl;
                            }));
                            setDbaId(null);
                            setDbaName('');
                        }
                    }
                    return (
                    <form key={`company-dba-${i}`} onSubmit={(e) => {
                        e.preventDefault();
                        save();
                    }}>
                        {disabled && <CustomInput
                            label={`DBA # ${i+1}`}
                            value={dba.name}
                            disabled={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    <span>
                                        <IconButton onClick={() => {
                                            setDbaId(dba.id);
                                            setDbaName(dba.name);
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Edit DBA" placement="top">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>
                                    </span>
                                </InputAdornment>
                            }
                        />}
                        {!disabled && <CustomInput
                            label={`DBA # ${i+1}`}
                            value={disabled ? dba.name : dbaName}
                            onChange={(e) => { 
                                setDbaName(e.target.value);
                            }}
                            autoFocus={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    {<span>
                                        <IconButton onClick={() => {
                                            save();
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                                <Save />
                                            </Tooltip>
                                        </IconButton>

                                        <IconButton onClick={() => {
                                            setDbaId(null);
                                            setDbaName('');
                                        }}>
                                            <Tooltip id="tooltip-delete-dba" title="Cancel" placement="top">
                                                <Close />
                                            </Tooltip>
                                        </IconButton>

                                        <IconButton onClick={() => {
                                            setDbasForAdd(dbasForAdd.filter((db,j) => {
                                                return j !== i;
                                            }));
                                        }}>
                                            <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>
                                    </span>}
                                </InputAdornment>
                            }
                        />}
                        </form>
                    )
                })}
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                <Button color="primary" onClick={() => {
                        setOpenedNewDba(true);
                        setDbaId(null);
                        setDbaName('');
                    }}
                    ><i className="fas fa-plus"></i> Add DBA</Button>
            </GridItem>

            <GridItem xs={12} sm={12} md={12}><hr/></GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="red-text">{relatedCompaniesForAdd.length} {relatedCompaniesForAdd.length === 1 ? 'Company' : 'Companies'} :</h3>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {props.action !== 'View' && <Button color="primary" onClick={() => setOpenAddCompanyForm(true)}>
                    <i className="fas fa-plus"></i> Add Company
                </Button>}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                {/* {props.loadingRelatedCompanies && <div className="fade-loader-center">
                        <FadeLoader />
                </div>} */}
            </GridItem>

            {/* ADD MODE */}
            {relatedCompaniesForAdd.map((data, i) => {
                const {id, company_name, company_related_name, dba_name, relationship} = data;
                return (
                    <GridItem xs={12} sm={6} md={3} key={`cc-${data.id}`}>
                        <div style={{ fontSize: 16 }} className="boxed">
                            <div style={{float:'right'}}>
                                <>
                                    <IconButton onClick={() => {
                                        deleteRelatedCompany(data);
                                    }}>
                                        <Tooltip title="Delete" placement="top">
                                            <Delete/>
                                        </Tooltip>
                                    </IconButton>
                                </>
                            </div>

                            <span className="hoverable bold-on-hover" onClick={() => {
                                // props.setOpenedEditCompany(true);
                                // props.setCompanyID(cm.id);
                            }}>
                                <small>#{i+1}</small>
                                <h4><strong>{company_related_name || ''}</strong></h4>
                                {dba_name && <p><strong>dba {dba_name}</strong></p>}
                            </span>
                            <CustomNativeSelect
                                label="Relationship"
                                default={relationship}
                                options={["Affiliate", "Subsidiary", "Parent Company", "Franchisee"]}
                                choose={(value) => {
                                    setRelatedCompaniesForAdd(relatedCompaniesForAdd.map(obj => obj.id === id ? {...obj, relationship: value} : obj));
                                }}
                                info={relationship && dataObj.name ? `of ${dataObj.name}` : ''}
                            />
                        </div>
                    </GridItem>
                )
            })}
        </GridContainer>}

        {props.action !== 'Add' && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="red-text">{companyList.length} {companyDbas.length === 1 ? 'DBA' : 'DBAs'} :</h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={3}>
                {openedNewDba && <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        saveNewDba();
                    }}
                >
                    <CustomInput
                        label="New DBA"
                        value={dbaName}
                        onChange={(e) => { 
                            setDbaName(e.target.value);
                        }}
                        autoFocus={true}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={saveNewDba}>
                                    <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                        <Save/>
                                    </Tooltip>
                                </IconButton>

                                <IconButton onClick={() => {
                                    setOpenedNewDba(false);
                                    setDbaName('');
                                }}>
                                    <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                        <Close />
                                    </Tooltip>
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </form>}
                {companyList.sort((a, b) => (a.id > b.id ? -1 : 0)).map((dba,i) => {
                    if (!dba) return null;
                    console.log('dba', dba);
                    const disabled = dbaId !== dba.id;
                    const save = () => {
                        if (!dbaName) {
                            setMessage('Please enter DBA name');
                        } else {
                            setCompanyList(companyList.map((cl,j) => {
                                if (j === i) {
                                    const newCL = clone(cl);
                                    newCL.name = dbaName;
                                    return newCL;
                                }
                                return cl;
                            }))
                            props.updateCompanyDba({
                                company_id: dba.company_id,
                                name: dbaName,
                                id: dba.id
                            })
                            setDbaId(null);
                            setDbaName('');
                        }
                    }
                    return (
                    <form key={`company-dba-${i}`} onSubmit={(e) => {
                        e.preventDefault();
                        save();
                    }}>
                        {disabled && <CustomInput
                            label={`DBA # ${i+1}`}
                            value={dba.name}
                            disabled={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    {props.action === 'Edit' && <span>
                                        {dbaId !== dba.id && canEdit && <IconButton onClick={() => {
                                            setDbaId(dba.id);
                                            setDbaName(dba.name);
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Edit DBA" placement="top">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>}
                                    </span>}
                                </InputAdornment>
                            }
                        />}
                        {!disabled && <CustomInput
                            label={`DBA # ${i+1}`}
                            value={disabled ? dba.name : dbaName}
                            onChange={(e) => { 
                                setDbaName(e.target.value);
                            }}
                            autoFocus={true}
                            endAdornment={
                                <InputAdornment position="end">
                                    {props.action === 'Edit' && <span>
                                        {dbaId !== dba.id && canEdit && <IconButton onClick={() => {
                                            setDbaId(dba.id);
                                            setDbaName(dba.name);
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Edit DBA" placement="top">
                                                <Edit />
                                            </Tooltip>
                                        </IconButton>}

                                        {dbaId === dba.id && canEdit && <IconButton onClick={() => {
                                            save();
                                        }}>
                                            <Tooltip id="tooltip-save-dba" title="Save DBA" placement="top">
                                                <Save />
                                            </Tooltip>
                                        </IconButton>}

                                        {!disabled && <IconButton onClick={() => {
                                            setDbaId(null);
                                            setDbaName('');
                                        }}>
                                            <Tooltip id="tooltip-delete-dba" title="Cancel" placement="top">
                                                <Close />
                                            </Tooltip>
                                        </IconButton>}

                                        {!disabled && canDelete && <IconButton onClick={() => {
                                            setNotificationAsk(dba);
                                        }}>
                                            <Tooltip id="tooltip-delete-dba" title="Delete DBA" placement="top">
                                                <Delete />
                                            </Tooltip>
                                        </IconButton>}
                                    </span>}
                                </InputAdornment>
                            }
                        />}
                        </form>
                    )
                })}
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {props.action === 'Edit' && <Button color="primary" onClick={() => {
                    setOpenedNewDba(true);
                    setDbaId(null);
                    setDbaName('');
                }}
                ><i className="fas fa-plus"></i> Add DBA</Button>}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}><hr/></GridItem>
            
            <GridItem xs={12} sm={12} md={12}>
                <h3 className="red-text">{relatedCompanies.length} {relatedCompanies.length === 1 ? 'Company' : 'Companies'} :</h3>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                {props.action !== 'View' && <Button color="primary" onClick={() => setOpenAddCompanyForm(true)}>
                    <i className="fas fa-plus"></i> Add Company
                </Button>}
            </GridItem>

            <GridItem xs={12} sm={12} md={12}>
                {props.loadingRelatedCompanies && <div className="fade-loader-center">
                        <FadeLoader />
                </div>}
            </GridItem>

            {/* VIEW/EDIT MODE */}
            {!props.loadingRelatedCompanies && relatedCompanies.map((data, i) => {
                const {id, company_name, company_related_name, dba_name, relationship, dba_active} = data;
                return (
                    <GridItem xs={12} sm={6} md={3} key={`cc-${data.id}`}>
                        <div style={{ fontSize: 16 }} className="boxed">
                            <div style={{float:'right'}}>
                                {((canEdit && props.action !== "View") && editRelatedCompany !== id) && <IconButton onClick={() => {
                                    setEditRelatedCompany(id);
                                }}>
                                    <Tooltip id="tooltip-save-dba" title="Edit" placement="top">
                                        <Edit />
                                    </Tooltip>
                                </IconButton>}
                                {((canEdit && props.action !== "View") && editRelatedCompany === id) && 
                                <>
                                    <IconButton onClick={() => {
                                        updateRelatedCompany({
                                            id,
                                            relationship,
                                            company_id: dataObj.id
                                        });
                                    }}>
                                        <Tooltip title="Save" placement="top">
                                            <Save />
                                        </Tooltip>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        deleteRelatedCompany(data);
                                    }}>
                                        <Tooltip title="Delete" placement="top">
                                            <Delete/>
                                        </Tooltip>
                                    </IconButton>
                                </>
                                }
                            </div>

                            <span className="hoverable bold-on-hover" onClick={() => {
                                // props.setOpenedEditCompany(true);
                                // props.setCompanyID(cm.id);
                            }}>
                                <small>#{i+1}</small>
                                <h4><strong>{company_related_name || ''}</strong></h4>
                                {dba_name && <p>
                                    <strong>dba {dba_name}
                                        {!dba_active && <span className="red-text"> (removed)</span>}
                                    </strong>
                                </p>}
                            </span>
                            <CustomNativeSelect
                                disabled={editRelatedCompany !== id}
                                label="Relationship"
                                default={relationship}
                                options={["Affiliate", "Subsidiary", "Parent Company", "Franchisee"]}
                                choose={(value) => {
                                    setRelatedCompanies(relatedCompanies.map(obj => obj.id === id ? {...obj, relationship: value} : obj));
                                }}
                                info={relationship ? `of ${company_name}` : ''}
                            />
                        </div>
                    </GridItem>
                )
            })}
            
        </GridContainer>}

        {notificationAsk && <NotificationAsk 
            title="Are you sure?"
            message={`Are you sure you want to delete this DBA ${get(notificationAsk, 'name', '')} ?`}
            success={() => {
                props.deleteCompanyDba(notificationAsk.id);
                setNotificationAsk(null);
            }}
            open={notificationAsk ? true : false}
            close={() => {
                setNotificationAsk(null);
            }}
            success_button_text="Yes, Delete"
        />}

        {unlinkCompany && <NotificationAsk 
            title="Are you sure?"
            message={`Are you sure you want to remove this related Company - ${get(unlinkCompany, 'company_name', '')} ?`}
            success={() => {
                props.deleteCompanyCompany(unlinkCompany.id);
                setUnlinkCompany(null);
            }}
            open={deleteRelatedCompany ? true : false}
            close={() => {
                setUnlinkCompany(null);
            }}
            success_button_text="Yes, Delete"
        />}

        {openAddCompanyForm && <CustomDialogWithCompany
            indx="id"
            desc="name"
            label="Add Company"
            title="Add Company"
            askRelationship={true}
            open={openAddCompanyForm}
            close={() => {
                setOpenAddCompanyForm(false);
            }}
            create_and_choose={choose}
            choose={choose}
            loggedInUser={loggedInUser}
            prefill={props.prefill}
        />}
    </>
    )
}

const mapStateToProps = state => {
    const c = state.crm_companies
    return {
        companyDbas: c.companyDbas,
        loadingDBA: c.loadingDBA,
        companyCompanies: c.companyCompanies,
        loadingRelatedCompanies: c.loadingRelatedCompanies,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getCompanyDbas: (company_id) => {
            dispatch(actions.getCompanyDbas(company_id));
        },
        createCompanyDba: (data) => {
            dispatch(actions.createCompanyDba(data));
        },
        updateCompanyDba: (data) => {
            dispatch(actions.updateCompanyDba(data));
        },
        deleteCompanyDba: (id) => {
            dispatch(actions.deleteCompanyDba(id));
        },
        getCompanyCompanies: (company_id) => {
            dispatch(actions.getCompanyCompanies(company_id));
        },
        createCompanyCompany: (data) => {
            dispatch(actions.createCompanyCompany(data));
        },
        updateCompanyCompany: (data) => {
            dispatch(actions.updateCompanyCompany(data));
        },
        deleteCompanyCompany: (company_companies_id) => {
            dispatch(actions.deleteCompanyCompany(company_companies_id));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesFormCompanies);

