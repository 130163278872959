import React from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import { getSum } from "./CommissionSplitsLogic";
import { formatDollar } from "../../shared/utility";
import CommissionSplitsAction from "./CommissionSplitsActions";
import { createAddressObject } from '../CRM/Properties/PropertiesLogic';
import CustomInput from "../../components/CustomInput/CustomInputValidate";
import { TextField } from "@material-ui/core";
import { getUser } from "../../shared/authValidation";
import { statusDescriptionsDict } from "./CommissionSplitsLogic";

const CommissionSplitsFormFull = (props) => {
    const { dataObj, updateDataObj, setTab, setStep, currentUserIsAnAgentApprover, entryData, action, parentTab } = props;
    const property = dataObj ? createAddressObject(dataObj) : {};
    const viewOnly = entryData ? entryData.status == statusDescriptionsDict['Completed Docusign'] ? true : false : false;
    console.log('CommissionSplitsFormFull', props, property, viewOnly, entryData.status, statusDescriptionsDict['Completed Docusign']);

    const user = getUser();
    const isSubmitter = action !== 'add' ? 
        entryData ? 
            user.id === entryData.user_id ? 
                true 
            : 
                false 
        : 
            false
    :
        true; // if action = add "isSubmitter"

    return <div>
        <div style={{ padding: 40, maxWidth: 900, margin: "0 auto" }}>
            <div>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td className="col-10">
                                <img
                                    src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                                    style={{ width: 60 }}
                                />
                            </td>
                            <td className="col-90">
                                <div className="text-center">
                                    <h3>COMMISSION SPLITS</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {parentTab !== 'pending_associate_review' && <CommissionSplitsAction 
                        data={dataObj}
                        updateDataObj={updateDataObj}
                        save={props.save}
                        cancel={props.cancel}
                        setNotification={props.setNotification}
                        tab={props.listTab}
                        permissions={props.permissions}
                        history={props.history}
                        currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                        listTab={props.listTab}
                        viewOnly={viewOnly}
                    />}
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <div className="hoverable hover-gray" style={{marginTop:40}}>
                        <span>illi # </span>
                        <span className="bordered-input">{dataObj.illi_number}</span>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                    <div className="hoverable hover-gray" style={{marginTop:40}}>
                        <span>Date: </span>
                        <span className="bordered-input">{dataObj.date}</span>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={12} md={4}>
                    <div className="hoverable hover-gray" style={{marginTop:40}}>
                        <span>Submitted by </span>
                        <span className="bordered-input">{dataObj.submitted_by_name}</span>
                    </div>
                </GridItem>
            </GridContainer>
            <h3>PROJECT NAME AND LOCATION</h3>
            <div className="the-table mt-20">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-100"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>Project Name:</strong> {dataObj.project_name}
                            </td>
                        </tr>
                        <tr>
                            <td
                                className="col-100"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>Street Address:</strong> {property.street1} {property.street2}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-33 no-bt"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>City:</strong> {dataObj.city}
                            </td>
                            <td
                                className="col-33 no-bt"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>State:</strong> {dataObj.state}
                            </td>
                            <td
                                className="col-33 no-bt"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>Zip:</strong> {dataObj.zip}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-100 no-bt"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(1);
                                }}
                            >
                                <strong>Cross Streets:</strong> {dataObj.cross_streets}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <h3>CONTACTS/COMMISSIONS</h3>
            <div className="the-table mt-20">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-40"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(2);
                                }}
                            >
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(2);
                                }}
                            >
                                <strong>Associate Signature</strong>
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(2);
                                }}
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        {dataObj.contacts.map((c,i) => {
                            return <tr key={`contact-${i}`}>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(2);
                                    }}
                                >
                                    <strong>
                                    {c.referral_type}
                                    </strong>
                                </td>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(2);
                                    }}
                                >
                                    <strong>
                                    {i === 0 ? <span>Primary Associate</span> : <span>Associate {i+1}</span>}
                                    </strong>
                                </td>
                                <td
                                    className="col-40"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(2);
                                    }}
                                >
                                    {c.first_name} {c.last_name} ({c.email})
                                </td>
                                <td
                                    className="col-20"
                                >
                                </td>
                                <td
                                    className="col-20"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(2);
                                    }}
                                >
                                    {c.commission_percent} %
                                </td>
                            </tr>
                        })}
                        <tr>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-40"
                            >
                            </td>
                            <td
                                className="col-20"
                            >
                                <strong style={{float:'right'}}>TOTAL</strong>
                            </td>
                            <td
                                className="col-20"
                            >
                                {getSum(dataObj.contacts)} %
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            
            <div className="the-table mt-20">
                <h3>REFERRALS</h3>
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-10"
                            >
                                Brokerage Firm
                            </td>
                            <td
                                className="col-40"
                            >
                                Associate Name
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(3);
                                }}
                            >
                                <strong>Flat Fee</strong>
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(3);
                                }}
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        {dataObj.referrals.map((r,i) => {
                            return <tr key={`contact-${i}`}>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.referral_type}
                                </td>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.name ? r.name : ''}
                                </td>
                                <td
                                    className="col-40"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.first_name} {r.last_name} ({r.email})
                                </td>
                                <td
                                    className="col-20"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.commission_flat_amt ? `${formatDollar(r.commission_flat_amt)}` : ''}
                                </td>
                                <td
                                    className="col-20"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.commission_percent} %
                                </td>
                            </tr>
                        })}
                        <tr>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-40"
                            >
                                <strong style={{float:'right'}}>TOTAL</strong>
                            </td>
                            <td
                                className="col-20"
                            >
                                {getSum(dataObj.referrals, 'commission_flat_amt') ? formatDollar(getSum(dataObj.referrals, 'commission_flat_amt')) : ''}
                            </td>
                            <td
                                className="col-20"
                            >
                                {getSum(dataObj.referrals) ? `${getSum(dataObj.referrals)} %` : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>

                <h3>OUTSIDE BROKERS</h3>
                <table>
                    <tbody>
                        <tr>
                            <td className="col-10"></td>
                            <td
                                className="col-10"
                            >
                                Brokerage Firm
                            </td>
                            <td
                                className="col-40"
                            >
                                Associate Name
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(3);
                                }}
                            >
                                <strong>Flat Fee</strong>
                            </td>
                            <td
                                className="col-20"
                                onClick={() => {
                                    setTab('wizard');
                                    setStep(3);
                                }}
                            >
                                <strong>Commission %</strong>
                            </td>
                        </tr>
                        {dataObj.outside_brokers.map((r,i) => {
                            return <tr key={`contact-${i}`}>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.referral_type}
                                </td>
                                <td
                                    className="col-10"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.name ? r.name : ''}
                                </td>
                                <td
                                    className="col-40"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.first_name} {r.last_name} ({r.email})
                                </td>
                                <td
                                    className="col-20"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.commission_flat_amt ? `${formatDollar(r.commission_flat_amt)}` : ''}
                                </td>
                                <td
                                    className="col-20"
                                    onClick={() => {
                                        setTab('wizard');
                                        setStep(3);
                                    }}
                                >
                                    {r.commission_percent} %
                                </td>
                            </tr>
                        })}
                        <tr>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-10"
                            >
                            </td>
                            <td
                                className="col-40"
                            >
                                <strong style={{float:'right'}}>TOTAL</strong>
                            </td>
                            <td
                                className="col-20"
                            >
                                {getSum(dataObj.outside_brokers, 'commission_flat_amt') ? formatDollar(getSum(dataObj.outside_brokers, 'commission_flat_amt')) : ''}
                            </td>
                            <td
                                className="col-20"
                            >
                                {getSum(dataObj.outside_brokers) ? `${getSum(dataObj.outside_brokers)} %` : ''}
                            </td>
                        </tr>
                    </tbody>
                </table>
                
                <div style={{marginTop:40}}><u>{dataObj.date}</u><span style={{marginLeft:20}}>{dataObj.final_approver_name} Signature _______</span></div>
                <hr />
                <TextField
                    style={{ width: "100%" }}
                    id="multiline-flexible-notes"
                    label="Comments"
                    multiline
                    rowsMax="4"
                    value={dataObj.comments ? dataObj.comments : ''}
                    onChange={(e) => {
                        props.updateDataObj("comments", e.target.value);
                    }}
                    margin="normal"
                />
                <hr />
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        {parentTab !== 'pending_associate_review' && <CommissionSplitsAction 
                            data={dataObj}
                            updateDataObj={updateDataObj}
                            save={props.save}
                            cancel={props.cancel}
                            setNotification={props.setNotification}
                            tab={props.listTab}
                            permissions={props.permissions}
                            history={props.history}
                            currentUserIsAnAgentApprover={currentUserIsAnAgentApprover}
                            listTab={props.listTab}
                            noInternalNotes={true}
                            viewOnly={viewOnly}
                        />}
                    </GridItem>
                </GridContainer>
            </div>
        </div>
    </div>
}

export default CommissionSplitsFormFull;