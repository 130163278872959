import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PMAElement from './PMAElement';

const PMAMulti4 = (props) => {

    const { formData, setFormData, updateDataObj } = props;

    const renderElement = (field, startAdornment, endAdornment, two_decimals) => {
        return <PMAElement 
            updateDataObj={updateDataObj}
            data={formData}
            setData={setFormData}
            field={field}
            startAdornment={startAdornment}
            endAdornment={endAdornment}
            two_decimals={two_decimals ? two_decimals : false}
        />
    }

    return (
        <div>
            <center><h3>Agreement Details</h3></center>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                    {renderElement('commencement_date')}
                    {renderElement('monthly_fee_type')}
                    {formData.monthly_fee_type === 'Percentage' && renderElement('monthly_fee_percentage', null, '%')}
                    {formData.monthly_fee_type !== 'Percentage' && renderElement('monthly_fee_value', <span>$&nbsp;</span>, null, true)}
                    {renderElement('minimum_management_fee', <span>$&nbsp;</span>, null, true)}
                    {renderElement('set_up_fee', <span>$&nbsp;</span>, null, true)}
                </GridItem>
            </GridContainer>
            
        </div>
    );
}

export default PMAMulti4;