// @material-ui/icons
import Fingerprint from "@material-ui/icons/Fingerprint";
import ERTLOwnerLogin from "../views/Pages/ERTLOwnerLogin";
import ERTLOwnerReview from "../views/Pages/ERTLOwnerReview";
import ERTLOwnerReviewDone from '../views/Pages/ERTLOwnerReviewDone';

const pagesRoutes = [
    {
        path: "/review/ertl/login",
        name: "ERTL Review Client Login",
        component: ERTLOwnerLogin,
    },
    {
        path: "/review/ertl/done",
        name: "ERTL Review",
        component: ERTLOwnerReviewDone,
    },
    {
        path: "/review/ertl",
        name: "ERTL Review",
        component: ERTLOwnerReview,
    },
];

export default pagesRoutes;
