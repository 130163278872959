import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { TRBaseTemplate } from '../TRBaseTemplate';
import defaultTemplate from '../Templates/TRTemplatesModel';
import CustomSelect from '../../../components/CustomSelect/CustomSelect';
import { clone } from '../../../shared/utility';
import { get } from 'lodash';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import CloseComponent from "../../../components/Close/Close";
import { TextField } from "@material-ui/core";
import { Dialog } from "@material-ui/core";
import Button from '../../../components/CustomButtons/Button';
import TREntriesFormActions from './TREntriesFormActions';
import { fields } from './TREntriesModel';

const TREntriesFormFull = props => {
    const [templateData, setTemplateData] = useState(defaultTemplate); // the chosen template in its original form
    const [templateChosen, setTemplateChosen] = useState(null); // template ID of the chosen template
    const [initialDefaultTemplate, setInitialDefaultTemplate] = useState(false);
    const [openedField, setOpenedField] = useState(null);
    const [openedTextForm, setOpenedTextForm] = useState(false);
    const { dataObj, updateDataObj, setDataObj, setTab, setStep, entryData, setEntryData } = props;
    console.log('TREntriesFormFull', props);

    useEffect(() => {
        const template_id = get(props, 'entryData.template_id', null); // only the source / original template form used
        if (props.templates && template_id) {
            let theTemplateIdOriginal = null;
            props.templates.forEach(t => {
                if (t.id === template_id) {
                    theTemplateIdOriginal = t;
                }
            })
            if (theTemplateIdOriginal) {
                setTemplateData(theTemplateIdOriginal.data);
            }
        }
        
    }, [props.templates])

    useEffect(() => {
        // load template chosen or saved 

        // load templates
        setInitialDefaultTemplate(true);
        props.getTrTemplates();
        if (entryData) {
            props.getTrHistory(entryData.id);
        }
    }, []);

    let tr_id = null;
    if (props.tr_history) {
        props.tr_history.forEach(trh => {
            if (trh.action === 4) tr_id = trh.id;
            if (trh.action === 9) tr_id = trh.id;
        });
    }

    console.log('TRR HISTORY', tr_id);

    return <div>
        {entryData && <div>
            <GridContainer>
                <GridItem xs={12} sm={4} md={4}>
                    {<CustomSelect 
                        options={
                            props.templates.length > 0
                                ? props.templates
                                    .map((t) => {
                                        return {
                                            id: t.id,
                                            title: t.title,
                                            active: t.active,
                                            defaults:
                                                t.defaults,
                                        };
                                    })
                                    .filter((f) => {
                                        if (f.active === 0)
                                            return false;
                                        return true;
                                    })
                                    .sort(function (a, b) {
                                        if (
                                            a.title.toLowerCase() <
                                            b.title.toLowerCase()
                                        )
                                            return -1;
                                        if (
                                            a.title.toLowerCase() >
                                            b.title.toLowerCase()
                                        )
                                            return 1;
                                        return 0;
                                    })
                                : [
                                    {
                                        id: -1,
                                        title: "Original Base Template",
                                    },
                                ]
                        }
                        indx="id"
                        desc="title"
                        value={entryData.template_id}
                        choose={(e) => {
                            // set the template ID
                            if (entryData) {
                                const newEntryData = clone(entryData);
                                newEntryData.template_id = e;
                                setEntryData(newEntryData);
                            }
                            // set the templateData within the JSON type field data
                            let template = null;
                            console.log('looking for template id', e, props.templates);
                            props.templates.forEach(t => {
                                if (t.id === e) {
                                    template = t;
                                }
                            })
                            if (template) {
                                updateDataObj('templateData', template.data);
                                setTemplateData(template.data);
                            }
                        }}
                        label="Select Template"
                        default={entryData.template_id}
                    />}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>

            {entryData.template_id && <TREntriesFormActions 
                data={dataObj}
                updateDataObj={updateDataObj}
                save={props.save}
                cancel={props.cancel}
                setNotification={props.setNotification}
                tab={props.listTab}
                permissions={props.permissions}
                history={props.history}
                listTab={props.listTab}
                entryData={entryData}
                tr_id={tr_id}
            />}
            
            {entryData.template_id && <TRBaseTemplate 
                data={dataObj} // data from entry
                templateData={dataObj.templateData} // 
                templateText={templateData} // before template data (for comparison)
                action="Edit"
                openModal={(field) => {
                    console.log('opening field ->', dataObj.templateData[openedField]);
                    setOpenedField(field);
                    setOpenedTextForm(true);
                }}
                openDataModal={(field) => {
                    if (fields[field]) {
                        if (fields[field].hasOwnProperty('step')) {
                            props.setTab("wizard");
                            props.setStep(fields[field].step);
                        }
                    }
                }}
                setTab={props.setTab}
                setStep={props.setStep}
            />}

            {openedTextForm && (
				<Dialog
					open={openedTextForm}
					onClose={() => {
						setOpenedTextForm(false);
					}}
					maxWidth='md'
					fullWidth={true}
				>
					<div className='dialog-wrapper'>
						<CloseComponent
							close={() => {
								setOpenedTextForm(false);
							}}
						/>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<TextField
									label=''
									multiline
									rowsMax='4'
									value={dataObj.templateData[openedField]}
									onChange={(e) => {
										const newTemplateData = clone(dataObj);
										newTemplateData.templateData[openedField] = e.target.value;
										setDataObj(newTemplateData);
									}}
									style={{ width: '100%' }}
									margin='normal'
								/>
								<div>
								</div>
								<div className='mr-20'>
									<Button
										color='primary'
										onClick={() => {
											setOpenedTextForm(false);
										}}
									>
										OK
									</Button>
								</div>
							</GridItem>
						</GridContainer>
					</div>
				</Dialog>
			)}
        </div>}
    </div>
}

const mapStateToProps = state => {
	const tr = state.tr_templates;
    const te = state.tr_entries;
	return {
        templateData: tr.templateData,
        templates: tr.templates ? tr.templates : [],
        success_delete: te.success_delete,
        error_delete: te.error_delete,
        success_update: te.success_update,
        error_update: te.error_update,
        success: te.success,
        error: te.error,
        tr_history: te.tr_history
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getTrTemplates: (data) => {
            dispatch(actions.getTrTemplates(data));
        },
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TREntriesFormFull);