import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const commissionFields = FIELDS.Commision;

export const subFields = SUBFIELDS.Commision;

export const sectionNames = SECTION.Commission.names;

export const sectionRequirements = SECTION.Commission.requirements;

export const initialData = getInitialData(commissionFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
