import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import offerFields, { initialOfferData } from './OfferModel';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import OfferModal from "./OfferModal";
import TextField from '@material-ui/core/TextField';
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import Close from '@material-ui/icons/Close';
import FilesForm from './FilesForm';
import { useSaveOffer } from './OfferLogic';
import { Tabs, Tab } from "@material-ui/core";
import Loader from '../../../../components/Loader/Loader';
import { getUser } from '../../../../shared/authValidation';
import {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
const validate = {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}

const getValidations = (f) => {
    
    return offerFields[f].validations.map(v => validate[v])
}
const OfferForm = (props) => {
    const { offerData, action, setTab, canEdit, canDelete, setMsgsForAdd, setUserType,
        createOffer, updateOffer, propertyID, getOffers, deleteOffer,
        error, success } = props;
    const [data, setData] = useState(initialOfferData);
    const [formDataObj, setFormDataObj] = useState(null);
    const [openedModal, setOpenedModal] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [madeChanges, setMadeChanges] = useState(false);
    const [curTab, setCurTab] = useState('Info');

    useSaveOffer({
        madeChanges,
        updateOffer,
        data,
        setMadeChanges
    })

    const updateDataObj = (field, value) => {
        const newData = { ...data };
        newData[field] = value;
        setData(newData);
        setMadeChanges(true);
    }

    const doUpdate = () => {
        updateOffer(data);
        setMadeChanges(false);
    }

    const doAdd = () => {
        const newData = { ...data };
        delete newData.id;
        newData.property_id = propertyID;
        if(formDataObj){
            newData['formDataObj'] = formDataObj;
        }
        createOffer(newData);
        setMadeChanges(false);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevError = usePrevious(error);
    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (prevError === null && error) {
            setErrorMsg(error);
        }
    }, [error]);

    useEffect(() => {
        
        if (prevSuccess === null && success) {
            setSuccessMsg(success);
            if (action === 'Add') {
                setData({ ...initialOfferData });
                getOffers();
                setTab('List');
            }
        }
    }, [success]);

    useEffect(() => {
        if (data) {
            let errors = '';
            if (!data.offer_amount) {
                errors += 'Offer amount is required. ';
            }
            setErrorMsg(errors);
        }
    })

    useEffect(() => {
        if (offerData && (action === 'Edit' || action === 'View')) {
            const newOfferData = { ...offerData };
            setData(newOfferData);
        } else if (offerData && action === 'Add') {
            setData({ ...initialOfferData });
            setSuccessMsg('');
        }
    }, [action]);

    useEffect(() => {
        setMsgsForAdd({ error: null, success: null });
        setSuccessMsg('');
    }, [])

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            props.setOpenedAsk(true);
        } else {
            getOffers();
            setTab('List');
            if (props.user) {
                if (props.user.type === 'A') {
                    if (setUserType) setUserType('own');
                }
            }
        }
    }

    const getTabIndex = () => {
        if (curTab === 'Info') {
            return 0;
        }
        if (curTab === 'Files') {
            return 1;
        }
    }

    let show_illi_owned_message = false;
    const user_logged_in = getUser();
    if (user_logged_in) {
        if (user_logged_in.type) {
            if (user_logged_in.type === 'A') {
                if (props.offerData) {
                    if (props.offerData.property_user_id === 1) {
                        show_illi_owned_message = true;
                    }
                }
            }
        }
    }

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className="inline-block">
                        {<div
                            className="inline-block blue-text hoverable mr-20"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Tooltip title='Back to Offer List' placement='top'>
                                <ArrowBack />
                            </Tooltip>
                        </div>}
                        {action !== 'Add' && <div
                            className={`${action === 'View' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('View');
                            }}
                        >
                            <Tooltip
                                title="View Offer"
                                placement="top"
                            >
                                <Visibility />
                            </Tooltip>
                        </div>}
                        {(action !== 'Add' && canEdit) && <div
                            className={`${action === 'Edit' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('Edit');
                            }}
                        >
                            <Tooltip
                                title="Edit Offer"
                                placement="top"
                            >
                                <Edit />
                            </Tooltip>
                        </div>}
                        {action} Offer 
                            {props.property_name ? <span> for <strong>{`${props.property_name}`}</strong></span> : ''}
                            {data.name ? <span> for <strong>{`${data.name}`}</strong></span> : ''}
                    </h3>
                    {(action !== 'Add' && canDelete) && <span
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            float: 'right',
                            fontSize: 11,
                            fontWeight: 'bold'
                        }}
                        className="red-text hoverable"
                    >DELETE OFFER</span>}
                    {show_illi_owned_message && <div>
					    {<div><strong>illi Company Data</strong></div>}
                    </div>}
                </GridItem>
                {<Tabs
                    TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
                    style={{ marginBottom: 10 }}
                    value={getTabIndex()}
                >
                    <Tab
                        onClick={() => {
                            setCurTab('Info');
                        }}
                        style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={0}
                        label='Meeting Info'
                        icon={<i className="fas fa-info-circle font-22"></i>}
                    />
                    {props.propertyAction !== 'Add' && <Tab
                        onClick={() => {
                            setCurTab('Files');
                        }}
                        style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={1}
                        label='Files'
                        icon={<i className="fas fa-file font-22"></i>}
                    />}
                </Tabs>}
            </GridContainer>
            {curTab === 'Info' &&
                <div className={action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
                    <GridContainer>
                        <GridItem xs={3} sm={3} md={3}>
                            <CustomInput
                                label={offerFields.offer_amount.label}
                                value={data.offer_amount ? data.offer_amount : ''}
                                onChange={(e) => {
                                    updateDataObj('offer_amount', e.target.value);
                                }}
                                validate={getValidations('offer_amount')}
                                startAdornment="$ "
                            />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <CustomSelect
                                label={offerFields.offer_type.label}
                                options={offerFields.offer_type.options}
                                choose={(e, n) => {
                                    updateDataObj('offer_type', e);
                                }}
                                default={data.offer_type}
                            />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <CustomSelect
                                label={offerFields.offer_status.label}
                                options={offerFields.offer_status.options}
                                choose={(e, n) => {
                                    updateDataObj('offer_status', e);
                                }}
                                default={data.offer_status}
                            />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <CustomSelect
                                label={offerFields.offer_from.label}
                                options={offerFields.offer_from.options}
                                choose={(e, n) => {
                                    updateDataObj('offer_from', e);
                                }}
                                default={data.offer_from}
                            />
                        </GridItem>
                        <GridItem xs={3} sm={3} md={6}>
                            <div className="custom-textfield">
                                <TextField
                                    label={offerFields.finance_contingencies.label}
                                    multiline
                                    rowsMax="4"
                                    value={data.finance_contingencies}
                                    onChange={(e) => {
                                        updateDataObj('finance_contingencies', e.target.value)
                                    }}
                                    className=""
                                    margin="normal"
                                    validate={getValidations('finance_contingencies')}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={6}>
                            <div className="custom-textfield">
                                <TextField
                                    label={offerFields.inspection_contingencies.label}
                                    multiline
                                    rowsMax="4"
                                    value={data.inspection_contingencies}
                                    onChange={(e) => {
                                        updateDataObj('inspection_contingencies', e.target.value)
                                    }}
                                    className=""
                                    margin="normal"
                                    validate={getValidations('inspection_contingencies')}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <hr />
                        </GridItem>
                       {!props.loadingOffer && <GridItem xs={3} sm={12} md={12}>
                            {(errorMsg && !successMsg) && <div className="red-text" style={{ margin: 20 }}>{errorMsg}</div>}
                            {successMsg && <div className="green-text" style={{ margin: 20 }}>{successMsg}</div>}
                        </GridItem>}
                        {props.loadingOffer && <Loader />}
                        {(!props.loadingOffer && (action === 'Edit' || action === 'Add')) && <GridItem xs={12} sm={12} md={12}>
                            <Button color="primary"
                                disabled={errorMsg ? true : false}
                                onClick={() => {
                                    if (action === 'Edit') {
                                        doUpdate();
                                    } else {
                                        doAdd();
                                    }
                                }}
                                className="mr-20"
                            >{
                                    action === 'Edit' ? 'Update Offer' : 'Add New Offer'
                                }</Button>
                            <Button color="white"
                                onClick={() => {
                                    cancel();
                                }}
                            >CANCEL</Button>
                        </GridItem>}
                    </GridContainer>

                </div>
            }

            {curTab === 'Files' &&
                <FilesForm
                    offerData={props.offerData}
                    fileList={props.fileList}
                    getCrmFileList={props.getCrmFileList}
                    getCrmFile={props.getCrmFile}
                    createCrmFile={props.createCrmFile}
                    deleteCrmFile={props.deleteCrmFile}
                    contactSuccess={props.contactSuccess}
                    contactError={props.contactError}
                    contactLoading={props.contactLoading}
                    downloadedFile={props.downloadedFile}
                    formDataObj={formDataObj}
                    setFormDataObj={setFormDataObj}
                    action={action}
                />
            }

            {openedModal && <div>
                <OfferModal
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    data={data}
                    setTab={setTab}
                    error={error}
                    success={success}
                    prevSuccess={prevSuccess}
                    prevError={prevError}
                    deleteOffer={deleteOffer}
                    offerID={data.id}
                    getOffers={getOffers}
                />
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
	const p = state.crm_properties;
	return {
		loadingOffer: p.loadingOffer,
	}
}

export default connect(mapStateToProps, null)(OfferForm);