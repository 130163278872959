import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';

const ContactsAskModal = (props) => {
    const name = props.contactData ? `${props.contactData.first_name ? props.contactData.first_name : ''} 
        ${props.contactData.last_name ? props.contactData.last_name : ''}` : '';
    const contactID = props.contactData ? props.contactData.id : '';

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prevSuccess = usePrevious(props.success);

    useEffect(() => {
        console.log('successfull', prevSuccess, props.success);
        if (prevSuccess === null && props.success === 'Successfully deleted contact. ') {
            props.getContacts();
            props.clearMessages();
            props.close();
            props.closeForm();
            return;
        }
    }, [props.success]);

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'center'}}>Are you sure you want to delete this Contact?</div>
        </DialogTitle>
            
        <div style={{textAlign:'center',padding:20}}>
            <div style={{textAlign:'center',marginBottom:20}}>
                {name}
            </div>

            <Button
                color="danger"
                style={{marginRight:10}}
                onClick={() => {
                    if (contactID) props.deleteContact(contactID);
                }}
            >
                YES, DELETE
            </Button>
            <Button
                color="white"
                style={{marginRight:10}}
                onClick={() => {
                    props.close();
                }}
            >
                CANCEL
            </Button>
        </div>
        </Dialog>
    )
}

export default ContactsAskModal;