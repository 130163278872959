import React, {useEffect} from 'react';
import LessorsForm from './LessorsForm';

const Lessors = (props) => {
    const { updateDataObj, updateDataMultiObj, data } = props;

    return <div>
        {data.lessors ? data.lessors.map((dl,i) => {
            return <div key={`dlessor-${i}`}><LessorsForm
                obj={dl}
                index={i}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                data={data}
                getRsheetFiles={props.getRsheetFiles}
            /></div>
        })
        : null}
    </div>
}

export default Lessors;