import React, { useEffect, useState, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import PropertiesForm from 'containers/CRM/Properties/PropertiesForm';

const ContactsNewProperty = (props) => {
    const [action, setAction] = useState('View');
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [tab, setTab] = useState('Main');
    const { success_data, create_and_choose, close } = props;

    const openForm = (newAction) => {
        setAction(newAction);
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

    const prev_success_data = usePrevious(props.success_data);

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);

    }, [props.success]);

    const clearMessages = () => {
        setErrorMsg('');
        setSuccessMsg('');
    }

    useEffect(() => {
        if (prev_success_data === null && success_data) {
          create_and_choose(success_data);
          close();
        }
    }, [success_data]);

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth={false}
        fullWidth={true}
    >
        <DialogTitle>
            <div className="close-btn" onClick={() => { props.close() }}><Close /></div>
            <div style={{textAlign:'center'}}></div>
        </DialogTitle>
            
        <div style={{padding:20}}>
            {<PropertiesForm
                stage={1}
                action="Add"
                propertyData={props.thePropertyData} // set through component
                setMessage={props.setMessage}
                getPropertyContacts={() => {
                    
                    if (props.theContactData) {
                        props.getPropertyContacts(props.theContactData.id);
                    }
                }}
                setAction={setAction}
                error={errorMsg}
                success={successMsg}
                updateProperty={props.updateProperty}
                getProperties={props.getProperties}
                loading={props.loading}
                closeForm={() => {
                    if (props.theContactData) {
                        props.getPropertyContacts(props.theContactData.id);
                    }
                }}
                openForm={openForm}
                getContacts={props.getContacts}
                contactsData={props.contactsData}
                getContact={props.getContact}
                contactData={props.contactData}
                createProperty={props.createProperty}
                clearMessages={clearMessages}
                getContactNames={props.getContactNames}
                contactNames={props.contactNames}
                clearContactNames={props.clearContactNames}

                getPropertyShares={props.getPropertyShares}
                getPropertyUsernames={props.getPropertyUsernames}
                createPropertyShare={props.createPropertyShare}
                deletePropertyShare={props.deletePropertyShare}
                propertyShares={props.propertyShares}
                propertyUsernames={props.propertyUsernames}
                theContactData={props.theContactData} // for tracking if contact has been applied
                checkPropertyExistingAddress={props.checkPropertyExistingAddress}
                existingAddress={props.existingAddress}
                tab={tab}
                setTab={setTab}
                
                getPropertyUseType={props.getPropertyUseType}
                propertyUseTypes={props.propertyUseTypes}
                property_is_for_override={props.property_is_for_override}
            />}
            <div className="text-center">
                <Button
                    color="rose"
                    style={{marginRight:10}}
                    onClick={() => {
                        props.close();
                    }}
                    className="text-center"
                >
                    CLOSE PROPERTY
                </Button>
            </div>
        </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    const s = state.crm_properties;
    const c = state.crm_contacts;
    return {
        error: s.error,
        success: s.success,
        propertyData: s.propertyData,
        propertiesData: s.propertiesData,
        loading: s.loading,
        contactsData: c.contactsData,
        contactData: c.contactData,
        contactNames: c.contactNames,
        propertyShares: s.propertyShares,
        propertyUsernames: c.contactUsernames,
        propertyContacts: s.propertyContacts,
        success_data: s.success_data,
        existingAddress: s.existingAddress,
        propertyUseTypes: s.options,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      getProperties: (params) => {
        dispatch(actions.getProperties(params));
      },
      getProperty: (id) => {
        dispatch(actions.getProperty(id));
      },
      getPropertyContacts: (contact_id) => {
        dispatch(actions.getPropertyContacts(contact_id));
      },
      deleteProperty: (id) => {
        dispatch(actions.deleteProperty(id));
      },
      createProperty: (data, otherData) => {
        dispatch(actions.createProperty(data, otherData));
      },
      updateProperty: (data) => {
        dispatch(actions.updateProperty(data));
      },
      getContacts: (params) => {
        dispatch(actions.getContacts(params));
      },
      getContact: (id) => {
        dispatch(actions.getContact(id));
      },
      getContactNames: (data) => {
        dispatch(actions.getContactNames(data));
      },
      getPropertyShares: (property_id) => {
        
        dispatch(actions.getPropertyShares(property_id));
      },
      getPropertyUsernames: () => {
        dispatch(actions.getContactUsernames());
      },
      createPropertyShare: (data) => {
        
        dispatch(actions.createPropertyShare(data));
      },
      deletePropertyShare: (share_id) => {
        dispatch(actions.deletePropertyShare(share_id));
      },
      clearContactNames: () => {
        dispatch(actions.clearContactNames());
      },
      checkPropertyExistingAddress: (data) => {
        dispatch(actions.checkPropertyExistingAddress(data));
      },
      getPropertyUseType: () => {
        dispatch(actions.getOptions());
      },
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(ContactsNewProperty);

// getPropertyUseType={props.getPropertyUseType}
// propertyUseTypes={props.propertyUseTypes}