import React, {Component} from 'react';
import {connect} from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import extendedFormsStyle from 'assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.jsx';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import compose from 'recompose/compose';

class CustomMultipleSelect extends Component {
	constructor(props) {
		super(props);
		this.state = {
			multipleSelect: props.defaultValues ? props.defaultValues : [],
			inputName: props.name ? props.name : ''
		};
	}

	handleMultiple = (event) => {
		this.setState({multipleSelect: event.target.value});
		this.props.choose(event.target.value);
	};

	render() {
		const {classes, className, options, label, style = {}} = this.props;
		return (
			<FormControl fullWidth className={classes.selectFormControl}>
				<InputLabel htmlFor='multiple-select' className={classes.selectLabel + ' alignLeft'}>
					{label}
				</InputLabel>
				<Select
					name={this.state.inputName}
					multiple
					style={style}
					value={this.state.multipleSelect}
					onChange={this.handleMultiple}
					MenuProps={{className: classes.selectMenu}}
					classes={{select: classes.select}}
					className={className}
					inputProps={{
						name: 'multipleSelect',
						id: 'multiple-select',
					}}
				>
					{options.map((name, index) => (
						<MenuItem
							classes={{
								root: classes.selectMenuItem,
								selected: classes.selectMenuItemSelected
							}}
							name={name}
							key={index}
							value={name}
						>
							{name}
						</MenuItem>
					))}
				</Select>
			</FormControl>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		multipleSelect: state.multipleSelect
	};
};

export default compose(withStyles(extendedFormsStyle), connect(mapStateToProps, null))(CustomMultipleSelect);
