import React, { useState, useEffect } from "react";
import { get, isEmpty, set } from "lodash";

import { subFields } from "../RSheetsSubleaseModel";

import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomRadio from "components/CustomRadio/CustomRadio";
import CustomInputStates from "components/CustomInput/CustomInputStates";

import { required } from "shared/validation";
import { clone } from "shared/utility";

import RSheetsEditArray from "../../RSheetsEditArray";
import { renderLabel } from "../../RSheetsFormElement";


const LESSOR_FIELDS = [
    "company_name",
    "name",
    "last_name",
    "address1",
    "address2",
    "city",
    "state",
    "zip",
    "main_phone",
    "work_phone",
    "mobile_phone",
    "fax",
    "email",
    "agency_disclosure",
    "can_we_reach_out_for_testimonial",
    "can_we_reach_out_for_testimonial_comment",
]

const LessorsForm = (props) => {
    const { updateDataObj, updateDataMultiObj, data, index, obj } = props;

    const update = (field, value) => {
        const newData = clone(data);
        newData.lessors[index][field] = value;
        updateDataObj("lessors", newData.lessors);
    };

    const [editArray, setEditArray] = useState(false);
    const [dataFromArray, setDataFromArray] = useState({});
    const model = subFields.lessor;

    const isCompany = obj.id.toString().indexOf("c") !== -1;
    const field = "lessor_contacts";
    const agents = get(data, field, []).filter((agent) => {
		const company_id = get(agent, "company_id", "");
        return company_id.includes("c") && company_id === obj.id;
    });

    const element = (
		<div>
            <h4>Lessor Contact(s)</h4>
            {/* (Full Name, Address1,Address2,City,State,Zip,Phone,Fax,Email) */}
            <div className="bordered-list">
                <ul>
                    {agents.map((a, i) => {
                        return (
                            <li key={`adc-${i}`}>
                                <span
                                    className="red-text hoverable mr-20"
                                    onClick={() => {
                                        const idToRemove = get(agents, `[${i}].id`, "");
                                        const chosen = get(data, "chosen");
                                        const contact_ids = get(data, `chosen.${field}.contact`, []).filter(
                                            (id) => id !== idToRemove
                                        );
                                        set(chosen, `${field}.contact`, contact_ids);
                                        const newAgents = agents.filter((aa, ii) => {
                                            return aa.id !== a.id;
                                        });
                                        updateDataMultiObj({
                                            [field]: newAgents,
                                            chosen,
                                        });
                                    }}
                                >
                                    <i className="fas fa-trash hoverable"></i>
                                </span>
                                <span
                                    className="blue-text hoverable mr-20"
                                    onClick={() => {
                                        setDataFromArray({
                                            index: i,
                                            field: field,
                                            data: a,
                                        });
                                        setEditArray(true);
                                    }}
                                >
                                    <i className="fas fa-edit hoverable"></i>
                                </span>
                                <br />
                                <div>
                                    {renderLabel("name", a, "lessor", subFields, "First Name")}
                                    {renderLabel("last_name", a, "lessor", subFields, "Last Name")}
                                    {renderLabel("address1", a, "lessor", subFields)}
                                    {renderLabel("address2", a, "lessor", subFields)}
                                    {renderLabel("city", a, "lessor", subFields)}
                                    {renderLabel("state", a, "lessor", subFields)}
                                    {renderLabel("zip", a, "lessor", subFields)}
                                    {renderLabel("email", a, "lessor", subFields)}
                                    {renderLabel("main_phone", a, "lessor", subFields)}
                                </div>
                            </li>
                        );
                    })}
                </ul>
                {editArray && (
                    <RSheetsEditArray
                        editArray={editArray}
                        setEditArray={setEditArray}
                        dataFromArray={dataFromArray}
                        setDataFromArray={setDataFromArray}
                        data={data}
                        updateDataObj={updateDataObj}
                    />
                )}
            </div>
        </div>
    );

    useEffect(() => {
        if (data && data.id && obj && obj.id) {
            let namesArr = [];
            let folderPathsArr = [];
            if (data.referral_firm_w9) {
                let pathArr = data.referral_firm_w9_upload.split("/");
                namesArr.push(pathArr[2]);
                folderPathsArr.push(data.referral_firm_w9_upload);
            }
            props.getRsheetFiles({
                rsId: data.id,
                lesseeId: obj.id,
                fileList: namesArr,
                folderPaths: folderPathsArr,
            });
        }
    }, []);

    const LESSOR_FIELDS_FILTERED = LESSOR_FIELDS.filter(lf => {
        if (obj.phone_type) {
            if (obj.phone_type === 'Work') {
                if (lf === 'mobile_phone' || lf === 'main_phone') return false;
            } else if (obj.phone_type === 'Mobile') {
                if (lf === 'work_phone' || lf === 'main_phone') return false;
            } else if (obj.phone_type === 'Main') {
                if (lf === 'work_phone' || lf === 'mobile_phone') return false;
            }
        } else {
            if (lf === 'work_phone' || lf === 'mobile_phone') return false;
        }
        return true;
    })

    return (
        <div className="boxed mt-20">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{ textAlign: "right" }}>
                        <i
                            className="fas fa-trash red-text hoverable"
                            onClick={() => {
                                const idToRemove = get(data, `lessors[${index}].id`).toString();
                                const chosen = get(data, "chosen", {});
                                if (idToRemove.includes("c")) {
                                    const company_ids = get(chosen, "lessors.company", []).filter(
                                        (id) => id.toString() != idToRemove.substring(1)
                                    );
                                    set(chosen, "lessors.company", company_ids);
                                } else {
                                    const contact_ids = get(chosen, "lessors.contact", []).filter(
                                        (id) => id != idToRemove
                                    );
                                    set(chosen, "lessors.company", contact_ids);
                                }
                                const lessor_contacts = get(data, 'lessor_contacts', []).filter(lc => {
                                    const company_id = get(lc, 'company_id', '');
                                    return !(company_id && company_id.includes("c") && company_id === idToRemove)
                                })
                                updateDataMultiObj({
                                    lessors: data.lessors.filter((l, j) => {
                                        if (j === index) return false;
                                        return true;
                                    }),
                                    lessor_contacts,
                                    chosen,
                                });
                            }}
                        ></i>
                    </div>
                    <h4>
						<strong>
                            Lessor {index + 1} {isCompany ? "(Company)" : ""}
                        </strong>
                    </h4>
                </GridItem>
            </GridContainer>
            <GridContainer>
                {LESSOR_FIELDS_FILTERED.map((field, i) => {
                    let the_label = model[field].label;
                    if (obj.id) {
                        // if object is a Company no last name
                        if (isCompany) {
                            if (field === "last_name") return null;
                            if (field === "name") return null;
                        } else {
                            if (field === "company_name") return null;
                        }
                    }
                    const isRequired = model[field].validations
                        ? model[field].validations.indexOf("required") !== -1
                            ? true
                            : false
                        : false;
                    if (field === "can_we_reach_out_for_testimonial_comment") {
                        if (obj.can_we_reach_out_for_testimonial !== "Yes") return null;
                    }
                    if (field === "agency_disclosure" || field === "can_we_reach_out_for_testimonial") {
                        return (
                            <GridItem xs={3} md={3} sm={3} key={`field-${field}-${i}`}>
                                <div className={`${model[field].readonly ? "gray-bg-input" : ""}`}>
                                    <div className={`${isRequired && !data[field] ? "required-radio" : ""}`}>
                                        <CustomRadio
                                            label={[model[field].label].concat(model[field].options).join(",")}
                                            options={model[field].options}
                                            onChange={(e) => {
                                                update(field, e.target.value);
                                            }}
                                            value={obj[field]}
                                            readonly={model[field].readonly ? true : false}
                                        />
                                        {isRequired && !obj[field] && (
                                            <div className="red-text mb-20 small-text">Required</div>
                                        )}
                                    </div>
                                </div>
                            </GridItem>
                        );
                    }
                    if (model[field].type === "state") {
                        return (
                            <GridItem xs={3} sm={3} md={3} key={`field-${field}-${i}`}>
                                <CustomInputStates
                                    label={model[field].label}
                                    onChange={(val) => {
                                        update(field, val);
                                    }}
                                    value={obj[field]}
                                    validate={isRequired ? [required] : []}
                                />
                            </GridItem>
                        );
                    }
                    return (
                        <GridItem xs={3} md={3} sm={3} key={`field-${field}-${i}`}>
                            <CustomInput
                                label={the_label}
                                value={obj[field]}
                                onChange={(e) => {
                                    update(field, e.target.value);
                                }}
                                validate={isRequired ? [required] : []}
                            />
                        </GridItem>
                    );
                })}
            </GridContainer>

			{isCompany && !isEmpty(agents) && element}
        </div>
    );
};

export default LessorsForm;
