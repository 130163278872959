import React from 'react';
import LesseesForm from './LesseesForm';

const Lessees = (props) => {
    const { updateDataObj, updateDataMultiObj, data } = props;

    return <div>
        {data.lessees ? data.lessees.map((dl,i) => {
            return <div key={`dlessor-${i}`}><LesseesForm
                obj={dl}
                index={i}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                data={data}
                formDataObj={props.formDataObj}
                setFormDataObj={props.setFormDataObj}
                createRsheetFiles={props.createRsheetFiles}
                getRsheetFiles={props.getRsheetFiles}
                rsheetFiles={props.rsheetFiles}
                success={props.success}
                loading={props.loading}
                error={props.error}
            /></div>
        })
        : null}
    </div>
}

export default Lessees;
