const pdfTemplate = (data) => {
    return `<html><div style='border: 1px solid black'>
    <center>
        <h3>Notice To Employee</h3>
        <h4>Labor Code section 2810.5</h4>
    </center>
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center><b>EMPLOYEE</b></center>
    </div>
    <br />
    <div>Employee Name: <u>${data.employee_name}</u></div>
    <div>Start Date: <u>${data.start_date}</u></div>
    <br />
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center><b>EMPLOYER</b></center>
    </div>
    <br />
    <div>Legal Name of Hiring Employer: <u>${data.employer_legal_name}</u></div>
    <br />
    <div style='margin-left: 40px'>Is hiring employer a staffing agency/business (e.g., Temporary Services Agency;
        Employee Leasing Company; or Professional Employer Organization [PEO])? <u>${data.employer_staffing_agency}</u></div>
    <br />
    <div>Other Names Hiring Employer is "doing business as" (if applicable): <u>${data.employer_other_names}</u></div>
    <br />
    <div>Physical Address of Hiring Employer's Main Office: <u>${data.main_office_address}</u></div>
    <br />
    <div>Hiring Employer's Mailing Address (if different than above): <u>${data.employers_mailing_address}</u></div>
    <br />
    <div>Hiring Employer's Telephone Number: <u>${data.employer_phone_number}</u></div>
    <br />
    <div>
        If the hiring employer is a staffing agency/business (above box checked "Yes"), the following is the other
        entity for whom this employee will perform work:
        <div style='margin-left: 40px'>
            <div>Name: <u>${data.other_entity.name}</u></div>
            <div>Physical Address of Main Office: <u>${data.other_entity.main_office_address}</u></div>
            <div>Mailing Address: <u>${data.other_entity.mailing_address}</u></div>
            <div>Telephone Number: <u>${data.other_entity.phone_number}</u></div>
        </div>
    </div>
    <br />
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center><b>WAGE INFORMATION</b></center>
    </div>
    <br />
    <div>Rate(s) of Pay: <u>${data.wage_information.rates_of_pay}</u> Overtime Rate(s) of Pay:
    <u>${data.wage_information.overtime_pay}</u></div>
    <br />
    <div>Rate by (check box):
        ${data.wage_information.rate_by === '1' ? '<input checked type="checkbox">Hour' : '<input type="checkbox">Hour'}
        ${data.wage_information.rate_by === '2' ? '<input checked type="checkbox">Shift' : '<input type="checkbox">Shift'}
        ${data.wage_information.rate_by === '3' ? '<input checked type="checkbox">Day' : '<input type="checkbox">Day'}
        ${data.wage_information.rate_by === '4' ? '<input checked type="checkbox">Week' : '<input type="checkbox">Week'}
        ${data.wage_information.rate_by === '5' ? '<input checked type="checkbox">Salary' : '<input type="checkbox">Salary'}
        ${data.wage_information.rate_by === '6' ? '<input checked type="checkbox">Piece rate' : '<input type="checkbox">Piece rate'}
        ${data.wage_information.rate_by === '7' ? '<input checked type="checkbox">Commission' : '<input type="checkbox">Commission'}
    <br />
    <div>Other (provide specifics): <u>${data.wage_information.other_by}</u></div>
    <br />
    <div>Does a written agreement exist providing the rate(s) of pay? (check box):
    <u>${data.wage_information.written_agreement}</u></div>
    <br />
    <div>If yes, are all rate(s) of pay and bases thereof contained in that written agreement?
    <u>${data.wage_information.if_written}</u></div>
    <br />
    <div>Allowances, if any, claimed as part of minimum wage (including meal or lodging allowances):
    <u>${data.wage_information.allowances}</u></div>
    <br />
    <div style='margin-left: 40px'>(If the employee has signed the acknowledgment of receipt below, it does not
        constitute a “voluntary written
        agreement” as required under the law between the employer and employee in order to credit any meals or lodging
        against the minimum wage. Any such voluntary written agreement must be evidenced by a separate document.)
        Regular Payday: <u>${data.wage_information.regular_payday}</u></div>
    <br />
    <div
        style='page-break-before: always !important; border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center><b>WORKERS’ COMPENSATION</b></center>
    </div>
    <div>Insurance Carrier’s Name: <u>${data.workers_compensation.insurance_carriers_name}</u></div>
    <br />
    <div>Address: <u>${data.workers_compensation.address}</u></div><br />
    <div>Telephone Number: <u>${data.workers_compensation.phone_number}</u></div><br />
    <div>Policy No.: <u>${data.workers_compensation.policy_number}</u></div><br />
    <div>Self Insured?: <u>${data.workers_compensation.self_insured}</u> Self-Insured (Labor Code 3700) and Certificate Number
        for Consent to Self-Insure: <u>${data.workers_compensation.self_insured_cert_num}</u></div><br />
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center><b>PAID SICK LEAVE</b></center>
    </div>
    Unless exempt, the employee identified on this notice is entitled to minimum requirements for paid sick leave under
    state law which provides that an employee:
    <ol type="a">
        <li>
            May accrue paid sick leave and may request and use up to 3 days or 24 hours of accrued paid sick leave per
            year;
        </li>
        <li>
            May not be terminated or retaliated against for using or requesting the use of accrued paid sick leave; and
        </li>
        <li>
            Has the right to file a complaint against an employer who retaliates or discriminates against an employee
            for
            <ol>
                <li>
                    requesting or using accrued sick days;
                </li>
                <li>
                    attempting to exercise the right to use accrued paid sick days;
                </li>
                <li>
                    filing a complaint or alleging a violation of Article 1.5 section 245 et seq. of the California
                    Labor Code;
                </li>
                <li>
                    cooperating in an investigation or prosecution of an alleged violation of this Article or opposing
                    any policy
                    or practice or act that is prohibited by Article 1.5 section 245 et seq. of the California Labor
                    Code.
                </li>
            </ol>
        </li>
    </ol>
    The following applies to the employee identified on this notice: (Check one box)
    <form>
        <ol>
            <li>
                <input ${data.paid_sick_leave.check_box==='1' ? 'checked' : '' } type="checkbox"> Accrues paid sick
                leave only pursuant to the minimum requirements
                stated in Labor Code §245 et seq. with no
                other employer policy providing additional or different terms for accrual and use of paid sick leave.
            </li>
            <li>
                <input ${data.paid_sick_leave.check_box==='2' ? 'checked' : '' } type="checkbox"> Accrues paid sick
                leave pursuant to the employer’s policy which
                satisfies or exceeds the accrual, carryover, and use
                requirements of Labor Code §246
            </li>
            <li>
                <input ${data.paid_sick_leave.check_box==='3' ? 'checked' : '' } type="checkbox"> Employer provides no
                less than 24 hours (or 3 days) of paid sick
                leave at the beginning of each 12-month period.
            </li>
            <li>
                <input ${data.paid_sick_leave.check_box==='4' ? 'checked' : '' } type="checkbox"> The employee is exempt
                from paid sick leave protection by Labor
                Code §245.5. (State exemption and specific
                subsection for exemption): <u>${data.paid_sick_leave.exemption}</u>
            </li>
        </ol>
    </form>
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <center>
            <div><b>ACKNOWLEDGEMENT OF RECEIPT</b></div>
            <div><i>(Optional)</i></div>
        </center>
    </div>
    <center>
        <table style='width: 100%; margin-left: auto; margin-right: auto;'>
            <tbody>
                <tr>
                    <td>
                        <div><u>${data.employer_representative}</u></div>
                        <div><b>(PRINT NAME of Employer representative)</b></div>
                    </td>
                    <td>
                        <div><u>${data.employee_name}</u></div>
                        <div><b>(PRINT NAME of Employee)</b></div>
                    </td>
                </tr>
                <tr>
                    <td height="100em">
                        <div>
                            <ds-signature data-ds-role="Employer" />
                        </div>
                        <div><b>(SIGNATURE of Employer Representative)</b></div>
                    </td>
                    <td height="100em">
                        <div>
                            <ds-signature data-ds-role="Employee" />
                        </div>
                        <div><b>(SIGNATURE of Employee)</b></div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div><b>(Date)
                                <ds-date-signed data-ds-role="Employer" />
                            </b></div>
                    </td>
                    <td>
                        <div><b>(Date)
                                <ds-date-signed data-ds-role="Employee" />
                            </b></div>
                    </td>
                </tr>
            </tbody>
        </table>
    </center>
    <div>The employee’s signature on this notice merely constitutes acknowledgement of receipt.</div>
    <div style='border-top: 1px solid; border-bottom: 1px solid; background-color: #d9d9d9'>
        <br />
    </div>
    <br />
    <div>
        Labor Code section 2810.5(b) requires that the employer notify you in writing of any changes to the information
        set forth in this Notice within seven calendar days after the time of the changes, unless one of the following
        applies: (a) All changes are reflected on a timely wage statement furnished in accordance with Labor Code
        section 226; (b) Notice of all changes is provided in another writing required by law within seven days of the
        changes.
    </div>
</div></html>`;
}

export default pdfTemplate;