import React, { useEffect, useState } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import PMABaseTemplate from 'containers/PMA/SharedComponents/PMABaseTemplate';
import PMATemplatesList from './PMATemplatesList';
import PMAModal from '../SharedComponents/PMAModal';
import { Dialog } from '@material-ui/core';
import { textData } from 'containers/PMA/PMAObj';
import { initialFormData } from '../PMAObj';
import { clone } from 'shared/utility';
import CloseComponent from '../../../components/Close/Close';
import Notification from '../../../components/Notification/Notification';
import { TextField } from '@material-ui/core';
import { includes } from 'lodash';

const PMATemplates = (props) => {
	const [openedForm, setOpenedForm] = useState(false); // open template form
	const [openedTextForm, setOpenedTextForm] = useState(false); // open template field editor
	const [openedField, setOpenedField] = useState(''); // template field name
	const [templateEdited, setTemplateEdited] = useState(false); // activity monitor
	const [currentId, setCurrentId] = useState(-1);
	const [templateData, setTemplateData] = useState(textData); // template form data
    const [formData, setFormData] = useState(clone(initialFormData));
	const [templateOriginal, setTemplateOriginal] = useState(clone(textData));
	const [templateEntryData, setTemplateEntryData] = useState({});
	const [notification, setNotification] = useState('');
	const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false);
	const [action, setAction] = useState('');
	const [originalTitle, setOriginalTitle] = useState('');
	const { clearMessages, getPmaTemplates } = props;
	const [showBaseTemplate, setShowBaseTemplate] = useState(false);
	const [close, setClose] = useState(false);

	useEffect(() => {
		if (props.success) {
			if (props.success === 'Successfully gotten PMA templates') return;
			if (props.success === 'Successfully gotten template') return;
			if (props.success.indexOf('no alert') !== -1) {
				setOpenedForm(false);
				setAction('');
				getPmaTemplates();
				clearMessages();
				return;
			}
			setNotification(props.success);
			if (close) {
				setOpenedForm(false);
				setAction('');
				setClose(false);
			}
			getPmaTemplates();
			clearMessages();
		}
	}, [props.success]);

	useEffect(() => {
		if (props.error) {
			setNotification(props.error);
			setAction('');
			setOpenedForm(false);
			clearMessages();
		}
	}, [props.error]);

	useEffect(() => {
		if (props.template) {
			setTemplateData(props.template.data);
			setTemplateOriginal(clone(props.template.data));
			setTemplateEntryData(props.template);
			setCurrentId(props.template.id);
			setOriginalTitle(props.template.title);
		}
	}, [props.template]);

	console.log('PMATemplates', action, originalTitle, props);

	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h3>PMA Templates</h3>
					<Button
						style={{ display: 'inline-block' }}
						color='primary'
						onClick={() => {
							setOpenedForm(true);
							setTemplateData(textData);
							setAction('Add');
						}}
						className='mr-20'
					>
						ADD NEW PMA TEMPLATE
					</Button>
					<i className='fas fa-sync-alt hoverable' onClick={props.reload}></i>
					<PMATemplatesList
						templates={props.templates}
						getPmaTemplate={props.getPmaTemplate}
						updatePmaTemplate={props.updatePmaTemplate}
						deletePmaTemplate={props.deletePmaTemplate}
						setCurrentId={setCurrentId}
						setOpenedForm={setOpenedForm}
						setAction={setAction}
						setOpenedAskBeforeDelete2={setOpenedAskBeforeDelete}
						setNotification={setNotification}
						reload={() => {
							getPmaTemplates();
						}}
					/>
				</GridItem>
			</GridContainer>
			{openedForm && (
				<Dialog
					open={openedForm}
					onClose={() => {
						setOpenedForm(false);
						setAction('');
					}}
					fullScreen={true}
				>
					<div className='dialog-wrapper'>
						<center>
							<h3>
								<strong>TEMPLATE OUTPUT PREVIEW</strong>
							</h3>
						</center>
						<PMABaseTemplate
							editTemplateMode={true}
							templateData={templateData} // template form data
							setTemplateData={setTemplateData}
							updateTemplateObj={(field, value) => {
								const newTemplateData = clone(templateData);
								newTemplateData[field] = value;
								setTemplateData(newTemplateData);
							}}
							formData={formData}
                            setFormData={setFormData}
							//action="template"
							templateText={showBaseTemplate ? textData : templateOriginal} // before/original base template
							openModal={(field) => {
								setOpenedField(field);
								setOpenedTextForm(true);
							}}
							close={() => {
								setOpenedForm(false);
							}}
							action='Edit'
							isTemplateForm={true}
							originalTitle={originalTitle}
						/>
						<div className='bottom-dweller'>
							<Button 
								color={showBaseTemplate ? 'success' : 'white'}
								className="mr-20"
								onClick={() => {
									setShowBaseTemplate(!showBaseTemplate);
								}}
							>{showBaseTemplate ? 'COMPARE TO LOADED TEMPLATE' : 'COMPARE TO BASE TEMPLATE'}</Button>
							{action === 'Add' && (
								<Button
									className='mr-20'
									color='primary'
									onClick={() => {
										if (!templateData.title) return setNotification('Template Title is required');
										if (templateData.title.trim() === 'Base Template')
											return setNotification('Please do not name your template "Base Template"');
										props.createPmaTemplate({
											data: JSON.stringify(templateData),
											title: templateData.title,
										});
									}}
								>
									ADD NEW
								</Button>
							)}
							{action === 'Edit' && (
								<Button
									className='mr-20'
									color='success'
									onClick={() => {
										setClose(true);
										if (!templateData.title) return setNotification('Template Title is required');
										if (templateData.title.trim() === 'Base Template')
											return setNotification('Please do not name your template "Base Template"');
										if (templateData.title === originalTitle)
											return setNotification(
												'Duplicate titles are not allowed for new templates'
											);
										props.createPmaTemplate({
											data: JSON.stringify(templateData),
											title: templateData.title,
										});
									}}
								>
									SAVE AS
								</Button>
							)}
							{action === 'Edit' && originalTitle !== 'Base Template' && (
								<Button
									className='mr-20'
									color='primary'
									onClick={() => {
										if (!templateData.title) return setNotification('Template Title is required');
										if (templateData.title.trim() === 'Base Template')
											return setNotification('Please do not name your template "Base Template"');
										props.updatePmaTemplate({
											data: JSON.stringify(templateData),
											title: templateData.title,
											id: props.template.id,
										});
									}}
								>
									SAVE
								</Button>
							)}
							<Button
								className='mr-20'
								color='white'
								onClick={() => {
									setOpenedForm(false);
								}}
							>
								CANCEL
							</Button>
							{/* {(action === 'Edit' && templateData.title !== 'Base Template') && <Button
                        className="mr-20"
                        color="danger"
                        onClick={() => {
                            setOpenedAskBeforeDelete(true);
                        }}
                    >
                        DELETE
                    </Button>} */}
						</div>
					</div>
				</Dialog>
			)}

			{openedTextForm && (
				<Dialog
					open={openedTextForm}
					onClose={() => {
						setOpenedTextForm(false);
					}}
					maxWidth='md'
					fullWidth={true}
				>
					<div className='dialog-wrapper'>
						<CloseComponent
							close={() => {
								setOpenedTextForm(false);
							}}
						/>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<TextField
									label=''
									multiline
									rowsMax='4'
									value={templateData[openedField]}
									onChange={(e) => {
										const newTemplateData = clone(templateData);
										newTemplateData[openedField] = e.target.value;
										setTemplateData(newTemplateData);
									}}
									style={{ width: '100%' }}
									margin='normal'
								/>
								<div>
									<Button
										onClick={() => {
											const newTemplateData = clone(templateData);
											if (!templateData.additional_signatures || !Array.isArray(templateData.additional_signatures)) {
												newTemplateData.additional_signatures = [];
											}
                                            if (!includes(newTemplateData.additional_signatures, openedField)) {
                                                newTemplateData.additional_signatures.push(openedField);
                                            }
											setTemplateData(newTemplateData);
											setOpenedTextForm(false);
										}}
									>
										Add Owner's Signature
									</Button>
                                    <Button
										onClick={() => {
											const newTemplateData = clone(templateData);
											if (!templateData.additional_initials || !Array.isArray(templateData.additional_initials)) {
												newTemplateData.additional_initials = [];
											}
                                            if (!includes(newTemplateData.additional_initials, openedField)) {
                                                newTemplateData.additional_initials.push(openedField);
                                            }
											setTemplateData(newTemplateData);
											setOpenedTextForm(false);
										}}
									>
										Add Owner's Initials
									</Button>
								</div>
								<div className='mr-20'>
									<Button
										color='primary'
										onClick={() => {
											setOpenedTextForm(false, templateData);
										}}
									>
										OK
									</Button>
								</div>
							</GridItem>
						</GridContainer>
					</div>
				</Dialog>
			)}

			{notification && (
				<Notification
					open={notification ? true : false}
					close={() => {
						setNotification('');
					}}
					message={notification}
				/>
			)}

			{openedAskBeforeDelete && (
				<Dialog
					open={openedAskBeforeDelete}
					onClose={() => {
						setOpenedAskBeforeDelete(false);
					}}
				>
					<div className='dialog-wrapper2 text-center'>
						<h3>Are you sure you want to delete this template {templateData.title} ?</h3>
						<Button
							color='danger'
							className='mr-20'
							onClick={() => {
								props.deletePmaTemplate(currentId);
								setOpenedAskBeforeDelete(false);
							}}
						>
							YES, DELETE
						</Button>
						<Button
							color='white'
							onClick={() => {
								setOpenedAskBeforeDelete(false);
							}}
						>
							CANCEL
						</Button>
					</div>
				</Dialog>
			)}
		</div>
	);
};

export default PMATemplates;
