import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import Button from "components/CustomButtons/Button.jsx";

const ERTLModal = (props) => {
    const { currentId, setEditedObj, editedObj, setTemplateText, templateText, field } = props;
    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="md"
                fullWidth={true}
            >
                <div style={{ margin: 20 }}>
                    <div className="custom-textfield">
                        <TextField
                            label=''
                            multiline
                            rowsMax="4"
                            value={templateText[field]}
                            onChange={(e) => {
                                const newObj = { ...templateText };
                                newObj[field] = e.target.value;
                                
                                const newEditedObj = { ...editedObj };
                                newEditedObj[currentId] = { ...newObj }
                                setTemplateText(newObj);
                                setEditedObj(newEditedObj);
                            }}
                            margin="normal"
                        />
                    </div>
                    <br />
                    <Button color="rose" onClick={() => { props.close() }}>OK</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default ERTLModal;