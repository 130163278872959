import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const residentialFields = FIELDS.Residential;

export const subFields = SUBFIELDS.Residential;

export const sectionNames = SECTION.Residential.names;

export const sectionRequirements = SECTION.Residential.requirements;

export const initialData = getInitialData(residentialFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
