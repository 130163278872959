import * as actionTypes from './actionTypes';

// LOGIN 

export const loginUser = (user) => {
    return {
        type: actionTypes.AUTH_REQUEST,
        user
    }
}

export const loginUserStart = () => {
    return {
        type: actionTypes.AUTH_START
    }
}

export const loginUserSuccess = (user) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        user
    }
}

export const loginUserFail = (err) => {
    return {
        type: actionTypes.AUTH_FAILURE,
        err
    }
}

export const logoutUser = () => {
  return {
    type: actionTypes.AUTH_LOGOUT
  }
}

export const loginUserChecked = () => {
    return {
        type: actionTypes.AUTH_CHECKED
    }
}

// GET WORKSPACE USERS

export const getWorkspaceUsers = () => {
    return {
        type: actionTypes.GET_WORKSPACE_USERS
    }
}

export const getWorkspaceUsersStart = () => {
    return {
        type: actionTypes.GET_WORKSPACE_USERS_START
    }
}

export const getWorkspaceUsersSuccess = (userList) => {
    return {
        type: actionTypes.GET_WORKSPACE_USERS_SUCCESS,
        userList
    }
}

export const getWorkspaceUsersFail = (err) => {
    return {
        type: actionTypes.GET_WORKSPACE_USERS_FAIL,
        err
    }
}

// SYNC USERS

export const syncUsers = (userList) => {
    return {
        type: actionTypes.SYNC_USERS,
        userList
    }
}

export const syncUsersStart = () => {
    return {
        type: actionTypes.SYNC_USERS_START
    }
}

export const syncUsersSuccess = (response) => {
    return {
        type: actionTypes.SYNC_USERS_SUCCESS,
        response
    }
}

export const syncUsersFail = (err) => {
    return {
        type: actionTypes.SYNC_USERS_FAIL,
        err
    }
}

// CREATE NEW WORKSPACE USER

export const createWorkspaceUser = (userData) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_USER,
        userData
    }
}

export const createWorkspaceUserSuccess = (response) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_USER_SUCCESS,
        response
    }
}

export const createWorkspaceUserFail = (err) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_USER_FAIL,
        err
    }
}

export const createWorkspaceUserStart = () => {
    return {
        type: actionTypes.CREATE_WORKSPACE_USER_START
    }
}

// CREATE_USER_FROM_WORKSPACE 

export const createUserFromWorkspace = (data) => {
    return {
        type: actionTypes.CREATE_USER_FROM_WORKSPACE,
        data
    }
}

export const createUserFromWorkspaceStart = () => {
    return {
        type: actionTypes.CREATE_USER_FROM_WORKSPACE_START
    }
}

export const createUserFromWorkspaceSuccess = (response) => {
    return {
        type: actionTypes.CREATE_USER_FROM_WORKSPACE_SUCCESS,
        response
    }
}

export const createUserFromWorkspaceFail = (err) => {
    return {
        type: actionTypes.CREATE_USER_FROM_WORKSPACE_FAIL,
        err
    }
}

// DELETE WORKSPACE USER 

export const deleteWorkspaceUser = (id, delete_contact) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_USER,
        id,
        delete_contact
    }
}

export const deleteWorkspaceUserStart = () => {
    return {
        type: actionTypes.DELETE_WORKSPACE_USER_START,
    }
}

export const deleteWorkspaceUserSuccess = (response) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_USER_SUCCESS,
        response
    }
}

export const deleteWorkspaceUserFail = (err) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_USER_FAIL,
        err
    }
}

// UPDATE WORKSPACE USER 

export const updateWorkspaceUser = (userDetails) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_USER,
        userDetails
    }
}

export const updateWorkspaceUserStart = () => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_USER_START
    }
}

export const updateWorkspaceUserSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_USER_SUCCESS,
        response
    }
}

export const updateWorkspaceUserFail = (err) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_USER_FAIL,
        err
    }
}

// CHECK USERNAME 

export const checkUsername = (username) => {
    return {
        type: actionTypes.CHECK_USERNAME,
        username
    }
}

export const checkUsernameStart = () => {
    return {
        type: actionTypes.CHECK_USERNAME_START
    }
}

export const checkUsernameSuccess = (response) => {
    return {
        type: actionTypes.CHECK_USERNAME_SUCCESS,
        response
    }
}

export const checkUsernameFail = (err) => {
    return {
        type: actionTypes.CHECK_USERNAME_FAIL,
        err
    }
}

export const clearUsername = () => {
    return {
        type: actionTypes.CLEAR_USERNAME
    }
}

// GET WORKSPACE GROUPS 

export const getWorkspaceGroups = () => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUPS
    }
}

export const getWorkspaceGroupsStart = () => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUPS_START
    }
}

export const getWorkspaceGroupsSuccess = (groupList) => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUPS_SUCCESS,
        groupList
    }
}

export const getWorkspaceGroupsFail = (err) => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUPS_FAIL,
        err
    }
}

// CREATE WORKSPACE GROUP 

export const createWorkspaceGroup = (groupData) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP,
        groupData
    }
}

export const createWorkspaceGroupStart = () => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_START
    }
}

export const createWorkspaceGroupSuccess = (response) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_SUCCESS,
        response
    }
}

export const createWorkspaceGroupFail = (err) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_FAIL,
        err
    }
}

// DELETE WORKSPACE GROUP 

export const deleteWorkspaceGroup = (groupKey) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP,
        groupKey
    }
}

export const deleteWorkspaceGroupStart = () => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_START
    }
}

export const deleteWorkspaceGroupSuccess = (response) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_SUCCESS,
        response
    }
}

export const deleteWorkspaceGroupFail = (err) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_FAIL,
        err
    }
}

// UPDATE WORKSPACE GROUP

export const updateWorkspaceGroup = (groupData) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_GROUP,
        groupData
    }
}

export const updateWorkspaceGroupStart = () => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_GROUP_START
    }
}

export const updateWorkspaceGroupSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_GROUP_SUCCESS,
        response
    }
}

export const updateWorkspaceGroupFail = (err) => {
    return {
        type: actionTypes.UPDATE_WORKSPACE_GROUP_FAIL,
        err
    }
}

// GET WORKSPACE GROUP MEMBERS 

export const getWorkspaceGroupMembers = (groupKey) => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUP_MEMBERS,
        groupKey
    }
}

export const getWorkspaceGroupMembersStart = () => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUP_MEMBERS_START
    }
}

export const getWorkspaceGroupMembersSuccess = (memberList) => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUP_MEMBERS_SUCCESS,
        memberList
    }
}

export const getWorkspaceGroupMembersFail = (err) => {
    return {
        type: actionTypes.GET_WORKSPACE_GROUP_MEMBERS_FAIL,
        err
    }
}

// CREATE WORKSPACE GROUP MEMBER

export const createWorkspaceGroupMember = (groupData, memberData) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_MEMBER,
        groupData,
        memberData
    }
}

export const createWorkspaceGroupMemberStart = () => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_START
    }
}

export const createWorkspaceGroupMemberSuccess = (response, groupKey) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_SUCCESS,
        response,
        groupKey
    }
}

export const createWorkspaceGroupMemberFail = (err) => {
    return {
        type: actionTypes.CREATE_WORKSPACE_GROUP_MEMBER_FAIL,
        err
    }
}

// DELETE WORKSPACE GROUP MEMBER

export const deleteWorkspaceGroupMember = (groupKey, memberKey, memberEmail) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_MEMBER,
        groupKey,
        memberKey,
        memberEmail
    }
}

export const deleteWorkspaceGroupMemberStart = () => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_START
    }
}

export const deleteWorkspaceGroupMemberSuccess = (memberEmail, groupKey) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_SUCCESS,
        memberEmail,
        groupKey
    }
}

export const deleteWorkspaceGroupMemberFail = (err) => {
    return {
        type: actionTypes.DELETE_WORKSPACE_GROUP_MEMBER_FAIL,
        err
    }
}

