import React from 'react';
import ImporterContactsListTable from './ImporterContactsListTable';

const ImporterContactsList = (props) => {
	const {
		data,
		contactsToImport,
		handleSetContactToImport,
		loading,
		openContactImportModal
	} = props;

	return (
		<div className='illi-table'>
			<ImporterContactsListTable
				contactsToImport={contactsToImport}
				data={data}
				handleSetContactToImport={handleSetContactToImport}
				loading={loading}
				openContactImportModal={openContactImportModal}
			/>
		</div>
	);
};

export default ImporterContactsList;
