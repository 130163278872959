import Card from 'components/Card/Card.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import React from 'react';
import { Redirect } from "react-router-dom";
import checkPermissions from "../../shared/checkPermissions";
import checkPermissionsActions from "../../shared/checkPermissionsActions";
import PropertiesImporter from '../../containers/CRM/Properties/PropertiesImporter';
import { getParameterByName } from "shared/utility";
import ContactsImportPage from 'views/Pages/ContactsImportPage.jsx';
import { clone } from '../../shared/utility';
import CoStarPropertyImporter from '../../containers/CRM/Properties/CoStar/CoStarPropertyImporter';

class PropertyImporterPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: '',
            subTab: ''
        }
    }

    setTab = (tab, subTab) => {
        this.setState({ tab, subTab: subTab ? subTab : '' });
        let url = `/crm/importer?tab=${tab}`;
        if (subTab) url += `&subtab=${subTab}`;
        this.props.history.push(url);
    }

    setSubTab = (subTab) => {
        this.setState({ subTab });
        this.props.history.push(`/crm/importer?tab=${this.state.tab}&subtab=${subTab}`);
    }

    componentDidMount() {
        const queryTab = getParameterByName('tab', window.location.href);
        const subTab = getParameterByName('subtab', window.location.href) ? decodeURI(getParameterByName('subtab', window.location.href)) : '';
        console.log('queryTab', queryTab, subTab);
        if (queryTab) {
          this.setTab(queryTab, subTab);
        }
    }

    render() {
        const crmPermitted = checkPermissions("CRM");
        if (!crmPermitted) {
            return <Redirect to="/dashboard" />;
        }
        const crmAllowed = checkPermissionsActions("CRM");
        const propertyImporterAllowed = crmAllowed.hasOwnProperty('property_importer');
        const otherSourcesImporterAllowed = crmAllowed.hasOwnProperty('othersources_importer');
        const costarImporterAllowed = crmAllowed.hasOwnProperty('costar_importer');
        const allowed =  propertyImporterAllowed || otherSourcesImporterAllowed || costarImporterAllowed;
        if (!allowed) return <Redirect to="/dashboard" />;

        return (
            <div style={{ marginTop: 20 }}>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                        <Card>
                            <CardHeader color='rose' icon>
                                {otherSourcesImporterAllowed && <CardIcon
                                    color={this.state.tab === 'import-from-other-sources' ? 'success' : 'rose'}
                                    onClick={() => {
                                        this.setTab('import-from-other-sources');
                                    }}
                                    className='hovered'
                                >
                                    <strong>Import from Other Sources</strong>
                                </CardIcon>}
                                {propertyImporterAllowed && <CardIcon
                                    color={this.state.tab === 'import-properties-from-sheet' ? 'success' : 'rose'}
                                    onClick={() => {
                                        this.setTab('import-properties-from-sheet');
                                    }}
                                    className='hovered'
                                >
                                    <strong>Import Properties from Sheet</strong>
                                </CardIcon>}
                                {costarImporterAllowed && <CardIcon
                                    color={this.state.tab === 'import-properties-from-costar-sheet' ? 'success' : 'rose'}
                                    onClick={() => {
                                        this.setTab('import-properties-from-costar-sheet');
                                    }}
                                    className='hovered'
                                >
                                    <strong>Import Properties from CoStar Sheet</strong>
                                </CardIcon>}
                            </CardHeader>
                            <CardBody>
                                {this.state.tab === 'import-properties-from-sheet' && <PropertiesImporter />}
                                {this.state.tab === 'import-from-other-sources' && <ContactsImportPage
                                    subTab={this.state.subTab}
                                    setSubTab={this.setSubTab}
                                />}
                                {this.state.tab === 'import-properties-from-costar-sheet' && <CoStarPropertyImporter />}
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }
}

export default PropertyImporterPage;
