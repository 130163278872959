import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Cached from "@material-ui/icons/Cached";

class MaintenancePage extends React.Component {

  render() {
    if (!checkPermissions('RFIDhistory')) {
      return <Redirect to="/dashboard" />;
    }

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <div className="pointed"><Cached /></div>
            </CardIcon>
          </CardHeader>
          <CardBody>
            <h1>Maintenance Mode</h1>
            <h4>We’ll be back soon!</h4>
            <p>Sorry for the inconvenience but we’re performing some maintenance at the moment.</p>
            <p>If you need to you can always <a href="mailto:helpdesk@illicre.com">contact us</a>, otherwise we’ll be back online shortly!</p>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
    );
  }
}

export default connect(null, null)(MaintenancePage);
