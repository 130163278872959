import { clone } from "../../shared/utility";
import moment from "moment";
import { listingFields } from "./ListingModel";

// Listing Status
export const LISTING_STATUS = {
    DRAFT: 0,
    PENDING: 1,
    APPROVED: 2,
    DECLINED: 3,
    PENDING_FINAL_APPROVAL_LEVEL_2: 4,
    PENDING_FINAL_APPROVAL_LEVEL_3: 5,
    PENDING_FINAL_APPROVAL_LEVEL_4: 6,
};

export const STATUS = {
    0: "Draft",
    1: "Pending",
    2: "Approved",
    3: "Declined",
    4: "Pending Level 2",
    5: "Pending Level 3",
    6: "Pending Level 4",
};

export const SECTION_NAMES = {
    ERTL: "ERTL Information",
    LISTING: "Listing Information",
    PROPERTY: "Property Information",
    AGENT: "Agent List",
    ADDITIONAL: "Additional Information",
};

// set defaults
const defaults = {};
Object.keys(listingFields).forEach((field) => {
    if (field === "date") {
        defaults.date = moment().format("MM/DD/YYYY");
    } else {
        defaults[field] = "";
    }
});
export const defaultData = defaults;

export const getDefaultData = (fieldModels) => {
    const d = {};
    Object.keys(fieldModels).forEach((field) => {
        if (field === "date") {
            d.date = moment().format("MM/DD/YYYY");
        } else {
            d[field] = "";
        }
    });
    return d
}

export const checkData = (data) => {
    console.log('loading data', data);
    // makes sure any new default data will get added to the data object to prevent errors
    const cd = clone(data);
    const newData = {};
    if (data) {
        Object.keys(defaultData).forEach((field) => {
            if (cd.hasOwnProperty(field)) {
                newData[field] = cd[field];
            } else {
                newData[field] = defaultData[field];
            }
        });
        Object.keys(data).forEach(field => {
            newData[field] = data[field];
        })
    }
    console.log('loaded data', newData);
    return newData;
};

export const textData = {
    form1: "NEW LISTING SUBMISSION FORM",
    illi: "illi#:",
    date: "Date:",
    submitted: "Submitted By:",
    title: "TITLE AND LOCATION",
    tag: "Tagline/Title of Posting:",
    street: "Street Address:",
    city: "City:",
    state: "State:",
    zip: "Zip:",
    cross: "Cross Street:",
    signed: "ERTL Signed?",
    expire: "ERTL Expiration:",
    renew: "Auto Renewal:",

    space_name: "SPACE DETAIL",
    space_unit: "Unit #",
    space_sf: "SF",
    space_rent: "Rent per SF",
    space_date: "Date Available",
    space_lease: "Lease Type",
    space_nnn: "Monthly NNN",
    space_sub: "Sub Lease",
    space_min: "Min Divisible",
    space_max: "Max Contiguous",
    space_term: "Lease Term (Months)",
    space_prop: "Property Type",
    space_bo: "Build Out",

    property_name: "PROPERTY INFORMATION",
    property_detail: "Property Details",
    property_detailReq: "(at least 4 features required) - i.e high ceilings",
    property_amenity: "Area Amenities",
    property_amenityReq: "(at least 4 features required)",
    property_parking: "Parking Details",
    property_addition: "ADDITIONAL HIGHLIGHTS (SEARCH TAGS)",

    contact_name: "LISTING CONTACTS",
    contact_agent1: "Primary Agent",
    contact_agent2: "Agent 2",
    contact_agent3: "Agent 3",
    contact_agent4: "Agent 4",

    date2: "Date",
    sig: "Todd Nathanson Signature:",

    form2: "COMMISSION SPLITS",
    illi2: "illi#:",
    date3: "Date:",
    submitted2: "Submitted By:",

    project_name: "PROJECT NAME AND LOCATION",
    project_project: "Project Name",
    project_street: "Street Address",
    project_city: "City:",
    project_state: "State:",
    project_zip: "Zip:",
    project_cross: "Cross Street:",
    project_signed: "ERTL Signed?",
    project_expire: "ERTL Expiration:",
    project_renew: "Auto Renewal:",

    contact2_name: "CONTACTS/SUBMISSION SPLITS",
    contact2_agent1: "Primary Agent",
    contact2_agent2: "Agent 2",
    contact2_agent3: "Agent 3",
    contact2_agent4: "Agent 4",
    contact2_com: "Comm. %",
    contact2_com2: "Comm. %",
    contact2_ref: "Referrals:",
    contact2_total: "Total",

    date4: "Date",
    sig2: "Todd Nathanson Signature:",
};
