import React, { useState } from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';
import { Tooltip } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import Button from '../../../components/CustomButtons/Button';
import { Dialog } from '@material-ui/core';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import { FormLabel, FormControlLabel, FormGroup, Checkbox } from '@material-ui/core';

const PMATemplatesList = (props) => {
    
    const {  templates, getPmaTemplate, deletePmaTemplate, setOpenedForm, setAction, setOpenedAskBeforeDelete2,
            setCurrentId, setNotification } = props;
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(null);

    const data = templates ? templates.map((l, key) => { // map users to table rows
        const d = l.data;
        return ({
            id: l.id,
            title: l.title,
            date_created: formatDate2(l.date_created),
            templateData: l,
            name: l.user_id === 1 ? 'illi' : l.name,
            default: <div>
                <FormGroup row className="mt-15">
                     <FormControlLabel
                        control={
                        <Checkbox
                            checked={l.defaults === 1 ? true : false}
                            onChange={() => {
                                let previously_checked = null;
                                if (l.active === 0) {
                                    return setNotification('Disabled templates cannot be set to default')
                                }
                                templates.forEach(t => {
                                    if (t.defaults === 1) {
                                        previously_checked = t.id;
                                    }
                                })
                                if (previously_checked) props.updatePmaTemplate({
                                    id: previously_checked,
                                    defaults: 0
                                }, true);
                                setTimeout(() => {
                                    props.updatePmaTemplate({
                                        id: l.id,
                                        defaults: 1
                                    }, true);
                                }, 800);
                            }}
                            color="default"
                        />
                        }
                        label="Default"
                    />
                </FormGroup>
            </div>,
            enable: <div>
                <CustomRadio
                            label=",Enable,Disable"
                            options={[1,0]}
                            onChange={(e, val) => {
                                // check all templates
                                
                                if (val === 0) {
                                    if (l.defaults === 1) return setNotification('Default template cannot be disabled');
                                    let found_enabled = false;
                                    templates.forEach(t => {
                                        if (t.id !== l.id) {
                                            if (t.active === 1) found_enabled = true;
                                        }
                                    })
                                    
                                    if (found_enabled) {
                                        props.updatePmaTemplate({
                                            active: val,
                                            id: l.id
                                        }, true)
                                    } else {
                                        setNotification('At least one template should be enabled');
                                    }
                                } else {
                                    props.updatePmaTemplate({
                                        active: 1,
                                        id: l.id
                                    }, true)
                                }
                            }}
                            value={l.active}
                        />
            </div>,
            actions: <div>{<div>
                        {<Tooltip
                                title="Edit"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    getPmaTemplate(l.id);
                                    setAction('Edit');
                                    setOpenedForm(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <Edit />
                            </Button>
                          </Tooltip>
                        }
                        {(l.defaults !== 1 && !l.has_been_used)  && <Tooltip
                                title="Delete"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    setCurrentId(l.id);
                                    setOpenedAskBeforeDelete2(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <Delete />
                            </Button>
                          </Tooltip>
                        }
                        </div>}</div>
        })
    }) : [];

    const columns = [
        {
            Header: "Title",
            accessor: "title",
        },
        {
            Header: "User",
            accessor: "name"
        },
        {
            Header: "Date Created",
            accessor: "date_created"
        },
        {
            Header: "Default",
            accessor: "default"
        },
        {
            Header: "Status",
            accessor: "enable" 
        },
        {
            Header: "Actions",
            accessor: "actions",
        }
    ]

    return (
    <div>
        <h3 style={{display:'inline',marginRight:20}}>{props.tab === 'Entries' ? 'Drafts' : props.tab}</h3>
        <div className="table-adjust23">
            <ReactTable 
                filterable
                className='-striped' 
                showPaginationTop 
                showPaginationBottom 
                resizable={true} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
            />
        </div>

        {openedAskBeforeDelete && <Dialog
            open={openedAskBeforeDelete}
            onClose={() => {
                setOpenedAskBeforeDelete(null);
            }}
            maxWidth="md"
            fullWidth={true}
        >
        <div style={{padding:40}} className="text-center">
            <h4>Are you sure you want to delete this template?</h4>
            <hr />
            <Button className="mr-20" color="success" onClick={() => {
                deletePmaTemplate(openedAskBeforeDelete)
                setOpenedAskBeforeDelete(null);
            }}>DELETE</Button>
            <Button color="white" onClick={() => {
                setOpenedAskBeforeDelete(null);
            }}>CANCEL</Button>
        </div>
    </Dialog>}
    </div>
    )
}

export default PMATemplatesList;