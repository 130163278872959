import React, { useState, useEffect } from 'react';
import Table from "components/Table/Table.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import Cached from "@material-ui/icons/Cached";
import moment from 'moment';
import { textData } from "containers/ERTL/ERTLObj";
import { Tooltip } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import CustomRadio from '../../../components/CustomRadio/CustomRadio';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import Notification from '../../../components/Notification/Notification';

const ERTLFormList = (props) => {
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false);
    const [notification, setNotification] = useState('');
    const [currentId, setCurrentId] = useState(null);
    const [templatesArr, setTemplatesArr] = useState([]);
    const { templatesData } = props;

    useEffect(() => {
        if (templatesData) {
            const templatesDat = templatesData;
            setTemplatesArr(templatesDat.map((cd, i) => {
                const l = cd;
                return [
                    cd.id === -1 ? 
                    <div className="hoverable" onClick={() => props.openForm('View', cd)}>
                        Original Template
                    </div> :
                    <div className="hoverable" onClick={() => props.openForm('View', cd)}>
                       {cd.title ? cd.title : ''}
                    </div>,
                    <div className="hoverable"
                        onClick={() => {
                            props.openForm('View', cd);
                        }}>
                        {cd.id !== -1 ? moment(cd.created_date).format('MM/DD/YYYY HH:mm:ss') : 'N/A'}
                    </div>,
                    <div className="hoverable"
                        onClick={() => {
                            props.openForm('View', cd);
                        }}>
                        {cd.name ? cd.name : 'N/A'}
                    </div>,
                    <div>
                        <FormGroup row className="mt-15">
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={l.defaults === 1 ? true : false}
                                    onChange={() => {
                                        let previously_checked = null;
                                        if (l.active === 0) {
                                            return setNotification('Disabled templates cannot be set to default')
                                        }
                                        templatesData.forEach(t => {
                                            if (t.defaults === 1) {
                                                previously_checked = t.id;
                                            }
                                        })
                                        if (previously_checked) props.updateErtlTemplate({
                                            id: previously_checked,
                                            defaults: 0
                                        }, true);
                                        setTimeout(() => {
                                            props.setTarget(true);
                                            props.updateErtlTemplate({
                                                id: l.id,
                                                defaults: 1
                                            }, true);
                                        }, 800);
                                    }}
                                    color="default"
                                />
                                }
                                label="Default"
                            />
                        </FormGroup>
                    </div>,
                    <div>
                        <CustomRadio
                                    label=",Enable,Disable"
                                    options={[1,0]}
                                    onChange={(e, val) => {
                                        // check all templates
                                        
                                        if (val === 0) {
                                            if (cd.defaults === 1) return setNotification('Default template cannot be disabled');
                                            let found_enabled = false;
                                            templatesData.forEach(t => {
                                                if (t.id !== cd.id) {
                                                    if (t.active === 1) found_enabled = true;
                                                }
                                            })
                                            if (found_enabled) {
                                                props.updateErtlTemplate({
                                                    active: val,
                                                    id: cd.id
                                                }, true)
                                            } else {
                                                setNotification('At least one template should be enabled');
                                            }
                                        } else {
                                            props.setTarget(true);
                                            props.updateErtlTemplate({
                                                active: 1,
                                                id: cd.id
                                            }, true)
                                        }
                                    }}
                                    value={cd.active}
                                />
                    </div>,
                    <div className="hoverable">
                        {<Tooltip
                                title="Edit"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.openForm('View', cd);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <Edit />
                            </Button>
                          </Tooltip>
                        }
                        {(cd.defaults !== 1 && cd.title !== 'Base Template') && <Tooltip
                                title="Delete"
                                placement="top"
                            ><Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.setCurrentId(cd.id);
                                    props.setOpenedAskBeforeDelete(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <Delete />
                            </Button>
                          </Tooltip>
                        }
                    </div>
                ]
            }))
        }
    }, [templatesData]);

    

    return (<div className="illi-table2">
        <form onSubmit={(e) => { e.preventDefault(); props.getTemplates(); }}>
            <GridContainer alignItems="center">
                <GridItem xs={12} sm={2} md={2}>
                    <h3 style={{ display: 'inline' }}>ERTL Templates</h3>
                    <Button
                        justIcon
                        round
                        simple
                        onClick={() => {
                            props.getTemplates();
                        }}
                        color="info"
                    >
                        <Cached />
                    </Button>
                    <Button color="primary" onClick={() => {
                        props.openForm('View',{
                            id: -1,
                            created_date: '',
                            data:textData
                        })
                    }}>ADD NEW ERTL TEMPLATE</Button>
                </GridItem>
            </GridContainer>
        </form>
        <div className="illi-table">
            {templatesArr.length !== 0 && <Table
                tableHeaderColor="primary"
                tableHead={[<strong>Title</strong>,
                    <strong>User</strong>, 
                    <strong>Date Created</strong>,
                    <strong>Default</strong>, 
                    <strong>Status</strong>, 
                    <strong>Actions</strong>]}
                tableData={templatesArr}
                coloredColls={[2]}
                colorsColls={["primary"]}
            />}
            <div className="text-center" style={{paddingTop:20}}>
                <em>{templatesArr.length === 0 ? 'No revised templates found' : ''}</em>
            </div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={3}>
                    <CustomSelect
                        label="Rows per page"
                        options={[10, 15, 30, 50]}
                        choose={(e, n) => {
                            props.setRows(e)
                        }}
                        default={props.rows}
                    />
                </GridItem>
            </GridContainer>
        </div>
        {notification && <Notification 
            open={notification ? true : false}
            close={() => { setNotification('') }}
            message={notification}
        />}
    </div>);

}

export default ERTLFormList;