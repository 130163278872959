import React, { useEffect, useState, useRef } from "react";
import { Dialog } from "@material-ui/core";
import { first, get } from "lodash";
import moment from "moment";

import Button from "components/CustomButtons/Button";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.jsx";

import {
	clone,
	commatize,
	formatDollar,
	getDateDiffs2,
	timestampToDate,
	validateDates,
	validateDatesAndRange
} from "shared/utility";

import {
	calculateEstimatedAbatedTable,
    calculateEstimatedAbatedValues,
    calculateEstimatedTermsTotal,
    isDateBetween,
    isSameOrBefore,
    calculateLeaseLogic_RS as calculateLeaseLogic,
} from "../../RSheetsLogic";

import TermForm from "./TermForm";

const EstimatedTermAbated = (props) => {
    const ref = useRef(null);
    const [rangeError, setRangeError] = useState("");

    // FIXME - Delete later
    const [showRangeTable, setShowRangeTable] = useState(false);
    const [showRangesTable, setShowRangesTable] = useState(null);

    const [askDeleteRange, setAskDeleteRange] = useState(null);
    const {
        year_index,
        data,
        updateDataObj,
        setData,
        localData,
        setLocalData,
        editRange,
        setEditRange,
        scrollRef,
        handleScrollClick,
        setScrollRef,
    } = props;
    const year_index_num = parseInt(year_index);
    const i = year_index_num;
    const lt = data.abated_rent_ranges ? data.abated_rent_ranges[year_index_num] : null;
    const the_term = data.abated_rent_ranges;
    const lease_term = data.lease_term;
    const field = "abated_rent_ranges";
    const isEstimated = true;

    useEffect(() => {
        if (scrollRef) {
            handleScrollClick(ref);
            setScrollRef(null);
        }
    }, [scrollRef]);

    useEffect(() => {
        const rangesTable = the_term.map((lt) => {
            return lt.ranges.map(() => {
                return { open: false };
            });
        });
        setShowRangesTable(rangesTable);
    }, [the_term]);

    if (!lt) return null;

    const isShowingRanges = (yearIndex, rangeIndex) =>
        get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, { open: false }).open;

    const showRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = true;
            setShowRangesTable(newShowRangesTable);
        }
    };

    const hideRanges = (yearIndex, rangeIndex) => {
        const newShowRangesTable = clone(showRangesTable);
        const canOpen = get(showRangesTable, `[${yearIndex}][${rangeIndex}]`, false);
        if (canOpen) {
            newShowRangesTable[yearIndex][rangeIndex].open = false;
            setShowRangesTable(newShowRangesTable);
        }
    };

    let abated_total = 0;

    const abated_term = data.abated_rent_ranges;
    const checkMonths = (yearIndex, isAbated = true) => {
        if (isEstimated) {
            if (!localData.month1 || !localData.month2 || !localData.day1 || !localData.day2) {
                return <div style={{ color: "red" }}>Month Start, Month End, Day Start and Day End are required!</div>;
            }
            const start = parseInt(get(localData, "month1", "").split(" ")[1]);
            const end = parseInt(get(localData, "month2", "").split(" ")[1]);

            const dayStart = parseInt(get(localData, "day1", "").split(" ")[1]);
            const dayEnd = parseInt(get(localData, "day2", "").split(" ")[1]);

            if (end < start) {
                return <div style={{ color: "red" }}>Month End must be after Month Start</div>;
            } else if (end === start && dayEnd <= dayStart) {
                return <div style={{ color: "red" }}>Day End must be after Day Start</div>;
            }
        }

        let yearRanges = isAbated
            ? get(abated_term, `[${yearIndex}].ranges`, [])
            : get(lease_term, `[${yearIndex}].ranges`, []);
        yearRanges = clone(yearRanges);
        if (editRange && editRange.year_index !== -1 && editRange.range_index !== -1) {
            yearRanges.splice(editRange.range_index, 1);
        }
        if (yearRanges.length >= 1 && editRange.range_index !== 0) {
            const firstRange = first(yearRanges);

            if (isEstimated) {
                if (isSameOrBefore(localData.month1, localData.day1, firstRange.month1, firstRange.day1)) {
                    return <div style={{ color: "red" }}>Month Start and Day Start must be after first range</div>;
                }
            } else {
                if (moment(localData.date1).isSameOrBefore(moment(firstRange.date1))) {
                    return <div style={{ color: "red" }}>Date Start must be after first range</div>;
                }
            }
        }

        let error = null;

        yearRanges.forEach((range) => {
            if (isEstimated) {
                if (
                    isDateBetween(
                        { month: localData.month1, day: localData.day1 },
                        { month: range.month1, day: range.day1 },
                        { month: range.month2, day: range.day2 },
                        true
                    )
                ) {
                    if (!error) {
                        error = (
                            <div style={{ color: "red" }}>
                                Month Start and Day Start must not in between other ranges
                            </div>
                        );
                    }
                }
            } else {
                if (isDateBetween(localData.date1, range.date1, range.date2)) {
                    if (!error) {
                        error = <div style={{ color: "red" }}>Date Start must not be between other ranges</div>;
                    }
                }
            }
        });

        return error;
    };

    let total = 0;
    lt.ranges.forEach((ltt) => {
        const ranges = calculateEstimatedAbatedValues({ lease_term, abated_range: ltt });
        total += calculateEstimatedTermsTotal(ranges).totalTermRent;
    });

    return (
        <div>
            <div className="boxed" style={{ backgroundColor: "#fafafa" }}>
                {lt.ranges.length === 0 && (
                    <div className="text-center">
                        <em>No Ranges</em>
                    </div>
                )}
                {lt.ranges.map((ltt, j) => {
                    let totalCommission = 0;
                    const { value, month1, month2, day1, day2, commissionRate, usePercentage, percent } = ltt;
                    const isRequired = true;

                    const lease_term = get(data, "lease_term", []);
                    const abatedRanges = calculateEstimatedAbatedValues({ lease_term, abated_range: ltt });
                    const totalAbated = calculateEstimatedTermsTotal(abatedRanges);
                    const tableData = calculateEstimatedAbatedTable(abatedRanges);

                    const calcs = calculateLeaseLogic({ day1, day2, value, data });

                    let allTotal = 0;

                    calcs.forEach((cc) => {
                        allTotal += cc.calcRounded;
                        abated_total += cc.calcRounded;
                    });

                    const length = getDateDiffs2(moment(day1), moment(day2));
                    const allMonths = length.months ? (length.days ? length.months + 1 : length.months) : 0;

                    totalCommission += commissionRate ? commissionRate * 0.01 * allTotal : 0;
                    const year_start = lt.year_start;
                    const year_end = lt.year_end;
                    const dates_passed = validateDatesAndRange(localData.day1, localData.day2, year_start, year_end);
                    const dates_are_in_range = validateDatesAndRange(localData.day1, localData.day2, year_start, year_end);

                    let current_year_range = "";
                    if (year_start && year_end) {
                        current_year_range = `${timestampToDate(year_start * 1000)} - ${timestampToDate(
                            year_end * 1000
                        )}`;
                    }
                    let range_requirements_passed = true;
                    if (field === "lease_term") {
                        if (
                            !dates_passed ||
                            !dates_are_in_range ||
                            !localData.day1 ||
                            !localData.day2 ||
                            !localData.commissionRate ||
                            !localData.value
                        ) {
                            range_requirements_passed = false;
                        }
                    } else {
                        if (
                            !dates_passed ||
                            !dates_are_in_range ||
                            !localData.day1 ||
                            !localData.day2 ||
                            !localData.value
                        ) {
                            range_requirements_passed = false;
                        }
                    }
                    return (
                        <div key={`ranges-${j}=${i}`} ref={scrollRef === `${j}-${i}` ? ref : null}>
                            <h4 className="bold black-text mr-20" style={{ display: "inline-block" }}>
                                Range #{j + 1}
                            </h4>
                            {(editRange.range_index !== j || editRange.year_index !== i) && (
                                <Button
                                    color="rose"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: i,
                                            range_index: j,
                                        });
                                        setLocalData({
                                            value,
                                            month1,
                                            month2,
                                            day1,
                                            day2,
                                            commissionRate,
                                            usePercentage,
                                            percent,
											year_index: i,
                                            range_index: j,
                                        });
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-edit hoverable"></i> EDIT
                                    </span>
                                </Button>
                            )}
                            {editRange.range_index === j && editRange.year_index === i && (
                                <Button
                                    disabled={!checkMonths(i) && (localData.value || (localData.usePercentage && localData.percent))  ? false : true}
                                    color="primary"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: -1,
                                            range_index: -1,
                                        });
                                        const newData = clone(data);
                                        newData[field][editRange.year_index].ranges[editRange.range_index] = {
                                            value: localData.value,
                                            month1: localData.month1,
                                            month2: localData.month2,
                                            day1: localData.day1,
                                            day2: localData.day2,
                                            commissionRate: localData.commissionRate,
                                            usePercentage: localData.usePercentage,
                                            percent: localData.percent
                                        };
                                        setData(newData);
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-check hoverable"></i> SAVE
                                    </span>
                                </Button>
                            )}
                            {editRange.range_index === j && editRange.year_index === i && (
                                <Button
                                    color="white"
                                    onClick={() => {
                                        setEditRange({
                                            year_index: -1,
                                            range_index: -1,
                                        });
                                        if (field === "lease_term") {
                                            if (!day1 || !day2 || !value || !commissionRate) {
                                                setAskDeleteRange({ j, i });
                                            }
                                        } else {
                                            if (!day1 || !day2 || !value) {
                                                setAskDeleteRange({ j, i });
                                            }
                                        }
                                    }}
                                >
                                    <span className="">
                                        <i className="fas fa-times hoverable"></i> CANCEL
                                    </span>
                                </Button>
                            )}
                            <Button
                                color="white"
                                onClick={() => {
                                    setAskDeleteRange({ j, i });
                                }}
                            >
                                <span className="">
                                    <i className="fas fa-trash hoverable"></i> DELETE
                                </span>
                            </Button>
                            <div>
                                {(editRange.range_index !== j || editRange.year_index !== i) && (
                                    <div className="boxed mt-20 white-bg">
                                        <GridContainer>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Monthly $<br />
                                                    Value
                                                </div>
                                                <strong>$ {commatize(value, true)}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Month
                                                    <br />
                                                    Start
                                                </div>
                                                <strong>{month1}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Date
                                                    <br />
                                                    Start
                                                </div>
                                                <strong>{day1}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Month
                                                    <br />
                                                    End
                                                </div>
                                                <strong>{month2}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={2} md={2}>
                                                <div>
                                                    Date
                                                    <br />
                                                    End
                                                </div>
                                                <strong>{day2}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: "10px" }}>
                                                <div>Total Abated Commission:</div>
                                                <strong>{formatDollar(totalAbated.totalCommission, true)}</strong>
                                            </GridItem>
                                            <GridItem xs={12} sm={6} md={6} style={{ marginTop: "10px" }}>
                                                <div>Total Rent Abated:</div>
                                                <span
                                                    onClick={() => {
                                                        if (isShowingRanges(i, j)) {
                                                            hideRanges(i, j);
                                                        } else {
                                                            showRanges(i, j);
                                                        }
                                                    }}
                                                    className="text-underline hovered black-text bold"
                                                >
                                                    {formatDollar(totalAbated.totalTermRent, true)}
                                                </span>
                                            </GridItem>
                                        </GridContainer>
                                    </div>
                                )}
                                {isShowingRanges(i, j) && (
                                    <Table
                                        tableHead={[
                                            "Start Date",
                                            "Num of Days",
                                            "Commission Rate",
                                            "Total Abated Commission",
                                            "Total Abated Rent",
                                        ]}
                                        tableData={tableData}
                                    />
                                )}
                            </div>
                            {editRange && (
                                <div>
                                    {editRange.range_index === j && editRange.year_index === i && (
                                        <div className="boxed mt-20 white-bg" style={{ backgroundColor: "white" }}>
                                            <TermForm
                                                data={data}
                                                value={localData.value}
                                                month1={localData.month1}
                                                month2={localData.month2}
                                                day1={localData.day1}
                                                day2={localData.day2}
                                                commissionRate={localData.commissionRate}
                                                usePercentage={localData.usePercentage}
                                                percent={localData.percent}
                                                update={(fieldType, val) => {
                                                    const newLocalData = clone(localData);
                                                    newLocalData[fieldType] = val;
                                                    setLocalData(newLocalData);
                                                }}
                                                updateMultiFields={(obj) => {
                                                    const newLocalData = clone(localData);
                                                    Object.keys(obj).forEach((key) => {
                                                        newLocalData[key] = obj[key];
                                                    });
                                                    setLocalData(newLocalData);
                                                }}
                                                isRequired={isRequired}
                                                allTotal={allTotal}
                                                setShowRangeTable={setShowRangeTable}
                                                showRangeTable={showRangeTable}
                                                field={field}
                                                currentYear={i + 1}
                                                isEstimated
                                                isAbated
                                            />
                                            <hr />
                                            <em>
                                                * Requirements : Start date must be earlier than End date. All fields
                                                are required.
                                            </em>
                                            {checkMonths(i)}
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
                {lt.ranges.length > 0 && (
                    <div>
                        <h4>
                            Year {i + 1} Total Annual Amount Abated (Estimated):
                            <strong>{formatDollar(total, true)}</strong>
                        </h4>
                    </div>
                )}
                {rangeError && <div className="red-text mt-20">{rangeError}</div>}
                <hr />
            </div>

            {askDeleteRange && (
                <Dialog
                    open={askDeleteRange ? true : false}
                    onClose={() => {
                        setAskDeleteRange(null);
                    }}
                    fullWidth={true}
                    maxWidth="sm"
                >
                    <div className="dialog-wrapper text-center">
                        <h4>Are you sure you want to delete this range?</h4>
                        <Button
                            color="primary"
                            onClick={() => {
                                const { i, j } = askDeleteRange;
                                const newTerm = clone(the_term);
                                newTerm[i].ranges = newTerm[i].ranges.filter((r, ii) => {
                                    if (ii !== j) return true;
                                });
                                updateDataObj(field, newTerm);
                                setAskDeleteRange(null);
                            }}
                        >
                            Yes
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setAskDeleteRange(null);
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default EstimatedTermAbated;
