import * as actionTypes from './actionTypes';

// GET_ONBOARDINGS

export const getOnboardings = (data) => {
    return {
        type: actionTypes.GET_ONBOARDINGS,
        data
    }
}

export const getOnboardingsStart = () => {
    return {
        type: actionTypes.GET_ONBOARDINGS_START
    }
}

export const getOnboardingsSuccess = (response) => {
    return {
        type: actionTypes.GET_ONBOARDINGS_SUCCESS,
        response
    }
}

export const getOnboardingsFail = (err) => {
    return {
        type: actionTypes.GET_ONBOARDINGS_FAIL,
        err
    }
}

// GET_OB_PDF

export const getObPdf = (data) => {
    return {
        type: actionTypes.GET_OB_PDF,
        data
    }
}

export const getObPdfStart = () => {
    return {
        type: actionTypes.GET_OB_PDF_START
    }
}

export const getObPdfSuccess = (response) => {
    return {
        type: actionTypes.GET_OB_PDF_SUCCESS,
        response
    }
}

export const getObPdfFail = (err) => {
    return {
        type: actionTypes.GET_OB_PDF_FAIL,
        err
    }
}

// CREATE_NTE_ENTRY

export const createNteEntry = (data) => {
    return {
        type: actionTypes.CREATE_NTE_ENTRY,
        data
    }
}

export const createNteEntryStart = () => {
    return {
        type: actionTypes.CREATE_NTE_ENTRY_START
    }
}

export const createNteEntrySuccess = (response) => {
    return {
        type: actionTypes.CREATE_NTE_ENTRY_SUCCESS,
        response
    }
}

export const createNteEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_NTE_ENTRY_FAIL,
        err
    }
}

// UPDATE_ONBOARDING_ENTRY

export const updateOnboardingEntry = (data) => {
    return {
        type: actionTypes.UPDATE_ONBOARDING_ENTRY,
        data
    }
}

export const updateOnboardingEntryStart = () => {
    return {
        type: actionTypes.UPDATE_ONBOARDING_ENTRY_START
    }
}

export const updateOnboardingEntrySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_ONBOARDING_ENTRY_SUCCESS,
        response
    }
}

export const updateOnboardingEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_ONBOARDING_ENTRY_FAIL,
        err
    }
}

// GET_OB_WORKSPACE_USER

export const getObWorkspaceUser = (data) => {
    return {
        type: actionTypes.GET_OB_WORKSPACE_USER,
        data
    }
}

export const getObWorkspaceUserStart = () => {
    return {
        type: actionTypes.GET_OB_WORKSPACE_USER_START
    }
}

export const getObWorkspaceUserSuccess = (response) => {
    return {
        type: actionTypes.GET_OB_WORKSPACE_USER_SUCCESS,
        response
    }
}

export const getObWorkspaceUserFail = (err) => {
    return {
        type: actionTypes.GET_OB_WORKSPACE_USER_FAIL,
        err
    }
}