
import React, { useState, useEffect } from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from "components/Grid/GridItem.jsx";
import IconButton from '@material-ui/core/IconButton';
import { partnerFields } from "./ContactsObj";
import Button from "components/CustomButtons/Button.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import { clone } from "shared/utility";
import * as actions from 'store/actions';
import { connect } from "react-redux";
import {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
} from "shared/validation";
const validate = {
    min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers,
    max4, max10, max15, max50, max100, max250, max500
}
const getValidations = (f) => {
    return partnerFields[f].validations.map(v => validate[v])
}

const ContactsFormPartners = (props) => {
    const { updatePartners, partnersData, getContactData, contactData2 } = props;
    const [addContact, setAddContact] = useState(false);
    const [target, setTarget] = useState(false);
    
    useEffect(() => {
        if (target) {
            if (contactData2) {
                if (partnersData.length > 0) {
                    let newPartnersData = clone(partnersData);
                    newPartnersData.push({
                        first_name: contactData2.first_name,
                        last_name: contactData2.last_name,
                        email: contactData2.email,
                        phone: contactData2.main_phone 
                    })
                    props.setPartnersData(newPartnersData);
                    setTarget(false);
                } else {
                    props.setPartnersData([
                        {
                            first_name: contactData2.first_name,
                            last_name: contactData2.last_name,
                            email: contactData2.email,
                            phone: contactData2.main_phone 
                        }
                    ]);
                    setTarget(false);
                }
            }
        }
    }, [contactData2]);

    return (
        <div>
            <h4 className="bold">Partners</h4>
            {props.action !== 'View' &&
                <Button
                    color="primary"
                    onClick={() => {
                        setAddContact(true);
                    }}
                ><i className="fa fa-plus"></i> ADD PARTNER</Button>}
            <GridContainer>
            {partnersData.map((p, i) => {
                return (
                    <GridItem xs={12} sm={12} md={6} key={`pd-${i}`} className="boxed">
                        <GridContainer>
                            <GridItem xs={12} sm={6} md={6}>
                                    <div className="bold" style={{ marginTop: 20 }}>
                                        <h3 style={{ marginRight: 40, display:'inline-block' }}>Partner #{i + 1}</h3>
                                        <IconButton edge="end" color="default"
                                            onClick={() => {
                                                if (props.action === 'View') return;
                                                updatePartners('delete', '', i);
                                            }} aria-label="Close">
                                            <i className="fas fa-trash" style={{color:'red'}}></i>
                                        </IconButton>
                                    </div>
                                    <CustomInputValidate
                                        disabled
                                        label="First Name"
                                        value={p.first_name}
                                        validate={getValidations('first_name')}
                                    />
                                    <CustomInputValidate
                                        disabled
                                        label="Last Name"
                                        value={p.last_name}
                                        validate={getValidations('last_name')}
                                    />
                                    <CustomInputValidate
                                        label="Email"
                                        value={p.email}
                                        validate={getValidations('email')}
                                        onChange={(e) => {
                                            if (props.action === 'View') return;
                                            props.updatePartners('email', e.target.value, i)
                                        }}
                                    />
                                    <CustomInputValidate
                                        label="Phone"
                                        value={p.phone}
                                        validate={getValidations('phone')}
                                        onChange={(e) => {
                                            if (props.action === 'View') return;
                                            props.updatePartners('phone', e.target.value, i)
                                        }}
                                    />
                            </GridItem>
                            <GridItem xs={12} sm={6} md={6}>
                                <div style={{marginTop:80}}>
                                    <CustomInputValidate
                                        label="Notes"
                                        value={p.notes}
                                        onChange={(e) => {
                                            if (props.action === 'View') return;
                                            props.updatePartners('notes', e.target.value, i)
                                        }}
                                        validate={getValidations('notes')}
                                    />
                                </div>
                            </GridItem>
                        </GridContainer>
                        <hr />
                    </GridItem>
                )
            })}
            {partnersData.length === 0 &&
                <h4 className="bold">No Partners</h4>
            }
            </GridContainer>

            {addContact && <CustomDialog
                open={addContact}
                close={() => { setAddContact(false) }}
                choose={(the_contact_data) => {
                    setTarget(true);
                    getContactData(the_contact_data.id);
                    // props.updatePartners('first_name', null, -1);
                    setAddContact(false)
                }}
                create_and_choose={(success_data) => {
                    
                    setTarget(true);
                    getContactData(success_data.id);
                    // props.updatePartners('first_name', null, -1);
                    setAddContact(false)
                }}
                peopleType="contacts"
                title="Select Contact"
                label="Search Contacts"
            />}

        </div>
    );
}

const mapStateToProps = state => {
    return {
      contactData2: state.crm_contacts.contactData2,
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
      getContactData: (data) => {
        dispatch(actions.getContactData(data))
      }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ContactsFormPartners);