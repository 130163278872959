import React, { useEffect, useState } from 'react';
import { Cached } from "@material-ui/icons";
import moment from 'moment';
import ClipLoader from 'react-spinners/ClipLoader';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { getUser } from '../../../shared/authValidation';
import { clone } from 'shared/utility';

import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomInput from "components/CustomInput/CustomInputValidate.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";

import contactFields from "./ContactsObj";
import ContactsListTable from './ContactsListTable';

const ContactsList = (props) => {
    const [dataArr, setDataArr] = useState([]);
    const [userType, setUserType] = useState('contactsData');
    const [userPerms, setUserPerms] = useState(null);

    useEffect(() => {
        const user = getUser();
        if (user) {
            setUserPerms(user);
            if (user.type === 'A') {
                setUserType('agentData');
                props.getContacts();
            }
        }
    }, []);

    useEffect(() => {
        if (props.data) {
                setDataArr(props.data[userType].map((cd,i) => {
                    const open = () => {
                        const contactdata = clone(cd);
                        if (props.clearCRMMessages) props.clearCRMMessages();
                        //props.clearContacts();
                        
                        props.openForm('View',contactdata);
                        props.getPropertyContacts(contactdata.id);
                    }
                    return [
                        offset+(i+1),
                        <div className="hoverable" onClick={() => { open() }}>
                            {cd.first_name} {cd.middle}
                        </div>,
                        <div className="hoverable" onClick={() => { open() }}>
                            {cd.last_name}
                        </div>,
                        <div className="hoverable" onClick={() => { open() }} style={{maxWidth:500}}>
                            {cd.contact_type}
                        </div>,
                        <div className="hoverable" onClick={() => { open() }}>
                            {cd.companies ? 
                                cd.companies.length > 0 ? 
                                    cd.companies.map((cc, i) => {
                                        return <div key={`${cc.name}-${i}`}>{cc.name}</div>;
                                    })
                                :
                                    ''
                            : 
                                ''}
                        </div>,
                        //cd.main_phone ? cd.main_phone : 'N/A',
                        moment(cd.created_date).format('MM/DD/YYYY')
                    ]
                }))
        }
    }, [props.data]);
    
    const { rows, offset } = props;
    const page_num = Math.ceil((offset + 1) / rows) ;
    const page_count = Math.ceil(props[userType === 'contactsData' ? 'contactsCount' : 'agentDataCount'] / rows);
    
    const pages_links = isNaN(page_count) ?  null : Array(page_count).fill().map((element, index) => index + 1).map(num => {
        if (page_count > 10) {
            const four = page_num + 4;
            const four2 = page_num - 4;
            if (num > four || num < four2) {
                return null;
            }
        }
        return (
            <span
            onClick={() => {
                props.setOffset((num - 1) * rows);
            }}
            className={num === page_num ? 'tiny-box current' : 'hoverable tiny-box'} key={num} style={{
                marginRight: -1
            }}>{num}</span>
        )
    });

    const showBack = page_num === 1 ? false : true;
    const showForward = page_num === page_count ? false : true;

    const countField = userType === 'contactsData' ? 'contactsCount' : 'agentDataCount';

    let allowIlliDBAccess = false;
    if (userPerms) {
        if (userPerms.type !== 'A') {
            allowIlliDBAccess = true;
        } else {
            if (userPerms.permObj) {
                if (userPerms.permObj.hasOwnProperty('CRM agent_access_illi_db')) {
                    allowIlliDBAccess = true;
                }
            }
        }   
    }

const renderNav = () => {
    return <div className="text-center">
    {props.data[userType].length > 0 && <p>Showing {offset + 1} to {(offset +
        props.data[userType].length)} of {props.data[countField] ? props.data[countField].toLocaleString() : ''} contact(s)</p>}
    {props.data[userType].length > 0 && <div style={{marginTop:20}}>
        <span
            onClick={() => {
                props.setOffset(0);
            }}
            style={{borderRight: !showBack ? '1px solid #ccc' : '0'}}
            className="hoverable tiny-box">
            First
        </span>
        <span
            onClick={() => {
                if (showBack) {
                    props.setOffset(((page_num - 1) - 1) * rows);
                }
            }}
            style={{ opacity:showBack ? 1 : 0.2, marginRight: -1 }}
            className={showBack ? 'hoverable tiny-box' : 'tiny-box'}>
            Previous
        </span>
        {pages_links}
        {page_count > 10 ? ' . . . ' : ''}
        <span
            onClick={() => {
                if (showForward) {
                    const offset = ((page_num + 1) - 1) * rows;
                    
                    props.setOffset(offset);
                }
            }}
            style={{ opacity:showForward ? 1 : 0.2, marginRight: -1 }}
            className={showForward ? 'hoverable tiny-box' : 'tiny-box'}>
            Next
        </span>
        <span
            onClick={() => {
                const offset = (page_count - 1) * rows;
                
                props.setOffset(offset);
            }}
            style={{borderLeft:'1px solid #ccc'}}
            className="hoverable tiny-box">
            Last
        </span>
    </div>}
</div>
}

    return (<div className="illi-table">
    <form onSubmit={(e) => { e.preventDefault(); 
        props.getContacts(true); 
    }}>
        <GridContainer>
            <GridItem xs={12} sm={2} md={2}>
                <h3 style={{display:'inline-block'}}>Contacts</h3><br />
                <Button
                    size="sm"
                    onClick={() => {
                        props.resetFilters();
                        setTimeout(() => {
                            props.getContacts();
                        }, 500);
                    }}
                    color="info"
                >
                    <Cached /> Clear Search
                </Button>
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
                <CustomInput
                    label="Search by First Name"
                    value={props.filters.first_name}
                    onChange={(e) => { props.setFilters('first_name', e.target.value) }}
                />
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
                <CustomInput
                    label="Search by Last Name"
                    value={props.filters.last_name}
                    onChange={(e) => { props.setFilters('last_name', e.target.value) }}
                />
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
                <CustomSelect
                        label="Search by Contact Type"
                        options={[''].concat(contactFields.contact_type.options)}
                        choose={(e, n) => {
                            props.setFilters('contact_type', e)
                        }}
                        default={props.rows}
                    />
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
                <CustomInput
                    label="Search by Main Phone"
                    value={props.filters.main_phone}
                    onChange={(e) => { props.setFilters('main_phone', e.target.value) }}
                />
            </GridItem>
            <GridItem xs={12} sm={2} md={2}>
                <Button color="warning" type="submit" className="outlined-btn">
                    SEARCH
                </Button>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                {renderNav()}
            </GridItem>
            
        </GridContainer>
    </form>
    {userPerms && <div>
        {userPerms.type === 'A' && <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {allowIlliDBAccess && <Button
                    style={{marginRight:20}}
                    color={userType === 'contactsData' ? 'primary' : 'white'}
                    onClick={() => {
                        setUserType('contactsData');
                        props.setOffset(0);
                        props.getContacts();
                    }}
                >illi Contacts</Button>}
                <Button
                    color={userType !== 'contactsData' ? 'primary' : 'white'}
                    onClick={() => {
                        setUserType('agentData');
                        props.setOffset(0);
                        props.getContacts();
                    }}
                >Your Contacts</Button>
                <hr />
            </GridItem>
        </GridContainer>}
    </div>}
        {!props.loading && <ContactsListTable 
            userType={userType}
            dataArr={dataArr}
            setSort={props.setSort}
            sortDirection={props.sortDirection}
            sortBy={props.sortBy}
            data={props.data}
        />}
        <div className="text-center">
            {props.loading && <div><ClipLoader /></div>}
            <em>{(props[userType].length === 0 && !props.loading) ? 'No contacts found' : ''}</em>
        </div>
        <GridContainer>
            <GridItem xs={12} sm={12} md={3}>
                <CustomSelect
                        label="Rows per page"
                        options={[10,15,20,25,30]}
                        choose={(e, n) => {
                            props.setRows(e)
                        }}
                        default={rows}
                    />
            </GridItem>
        </GridContainer>
        <div>
            {renderNav()}
        </div>
    </div>);
}


const mapDispatchToProps = dispatch => {
    return {
      clearContacts: () => {
        dispatch(actions.clearContacts());
      }
    }
  }
  
  export default connect(null, mapDispatchToProps)(ContactsList);
