import React from 'react';
import PropTypes from 'prop-types';
import Button from 'components/CustomButtons/Button.jsx';
import {Dialog, DialogContent, DialogContentText, DialogActions, DialogTitle } from '@material-ui/core';

const CustomDialogConfirm = ({openConfirm, closeConfirm, deleteAppointment, deleteId, deleteType, title, body, saveRoutingSheet, setShowAddModal, onSaveForm, setCurrentTab, getRoutingSheetClear}) => {
	return (
		<Dialog open={openConfirm} onEscapeKeyDown={() => closeConfirm()}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText>{body}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button
					color='primary'
					onClick={() => {
						if (deleteAppointment) {
							deleteAppointment(deleteId, deleteType);
							closeConfirm(false);
						} else if (saveRoutingSheet) {
							closeConfirm(false);
							setShowAddModal(false);
							setCurrentTab('View/Edit');
							getRoutingSheetClear()
						}
					}}
				>
					OK
				</Button>
				{/* {saveRoutingSheet ? (
					<Button
						onClick={() => {
							onSaveForm();
							closeConfirm(false);
							setShowAddModal(false);
							setCurrentTab('View/Edit');
							getRoutingSheetClear()
						}}
					>
						Save
					</Button>
				) : null} */}
				<Button 
					color="white"
					onClick={() => closeConfirm(false)} autoFocus>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

CustomDialogConfirm.propTypes = {
	openConfirm: PropTypes.bool.isRequired,
	closeConfirm: PropTypes.func.isRequired,
	deleteAppointment: PropTypes.func,
	deleteId: PropTypes.string
};

export default CustomDialogConfirm;
