import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { TextField } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import { clone } from 'lodash';
import Element from '../ListingElement';

const ListingModal = (props) => {
	const {
		field,
		updateDataObj,
		formData,
		setFormData,
		fieldModels,
	} = props;

	const renderElement = (f, access) => {
		return (
			<Element
				updateDataObj={updateDataObj}
				data={formData}
				setData={setFormData}
				field={f}
				access={access}
				fieldModels={fieldModels}
			/>
		);
	};

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='md' fullWidth={true}>
				<div style={{ margin: 20 }}>
					<div className='custom-textfield'>
						{field && renderElement(field)}
					</div>
					<br />
					<Button
						color='rose'
						onClick={() => {
							props.close();
						}}
					>
						OK
					</Button>
				</div>
			</Dialog>
		</div>
	);
};

export default ListingModal;
