import * as actionTypes from '../actions/actionTypes';
import {
    updateObject
} from '../../shared/utility';

const initialState = {
    showingsData: [],
    showingData: null,
    error: null,
    success: null,
    loading: false
}

// GET ALL SHOWINGS

const getShowingsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: null,
    })
}

const getShowingsSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Got showings',
        showingsData: action.response
    })
}

const getShowingsFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    })
}

// GET_SHOWING

const getShowingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getShowingSuccess = (state, action) => {
    return updateObject(state, {
        showingsData: action.response
    })
}

const getShowingFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE SHOWING

const createShowingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    });
}

const createShowingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Created showing. ',
        loading: false
    });
}

const createShowingFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    });
}

// DELETE SHOWING 

const deleteShowingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    });
}

const deleteShowingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Deleted showing. '
    });
}

const deleteShowingFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    });
}

// UPDATE SHOWING 

const updateShowingStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const updateShowingSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Updated showing.',
        loading: false
    })
}

const updateShowingFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_SHOWINGS_START:
            return getShowingsStart(state, action);
        case actionTypes.GET_SHOWINGS_SUCCESS:
            return getShowingsSuccess(state, action);
        case actionTypes.GET_SHOWINGS_FAIL:
            return getShowingsFail(state, action);

        case actionTypes.GET_SHOWING_START:
            return getShowingStart(state, action);
        case actionTypes.GET_SHOWING_SUCCESS:
            return getShowingSuccess(state, action);
        case actionTypes.GET_SHOWING_FAIL:
            return getShowingFail(state, action);

        case actionTypes.CREATE_SHOWING_START:
            return createShowingStart(state, action);
        case actionTypes.CREATE_SHOWING_SUCCESS:
            return createShowingSuccess(state, action);
        case actionTypes.CREATE_SHOWING_FAIL:
            return createShowingFail(state, action);

        case actionTypes.DELETE_SHOWING_START:
            return deleteShowingStart(state, action);
        case actionTypes.DELETE_SHOWING_SUCCESS:
            return deleteShowingSuccess(state, action);
        case actionTypes.DELETE_SHOWING_FAIL:
            return deleteShowingFail(state, action);

        case actionTypes.UPDATE_SHOWING_START:
            return updateShowingStart(state, action);
        case actionTypes.UPDATE_SHOWING_SUCCESS:
            return updateShowingSuccess(state, action);
        case actionTypes.UPDATE_SHOWING_FAIL:
            return updateShowingFail(state, action);

        default:
            return state;
    }
};

export default reducer;