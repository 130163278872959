import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const leaseFields = FIELDS.Lease;

export const subFields = SUBFIELDS.Lease;

export const sectionNames = SECTION.Lease.names;

export const sectionRequirements = SECTION.Lease.requirements;

export const initialData = getInitialData(leaseFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
