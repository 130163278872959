import React from "react";
import { useEffect } from "react";
import { connect } from "react-redux";

const ERTLOwnerReviewDone = (props) => {
    const { clientError, success } = props;

    useEffect(() => {
        if (!clientError && !success) {
            props.history.push(`/`);
        }
    }, []);

    return (
        <div
            style={{
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <h3>
                {clientError ? clientError : ""}
                {success ? success : ""}
            </h3>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        clientErtl: state.ertl_entries.clientErtl,
        clientError: state.ertl_entries.clientError,
        success: state.ertl_entries.success,
    };
};

export default connect(mapStateToProps)(ERTLOwnerReviewDone);
