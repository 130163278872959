import React, { useState } from 'react'
import { Edit } from '@material-ui/icons';
import NTEDataModal from './NTEDataModal';

const NoticeToEmployee = (props) => {

    const { formData, setFormData } = props;

    const [openedDataModal, setOpenedDataModal] = useState(false);
    const [openedDataField, setOpenedDataField] = useState('');

    const openDataModal = (field) => {
        setOpenedDataField(field);
        setOpenedDataModal(true);
    }

    const formInput = (field) => {
        let fieldSplit = field.split('.');
        let newField = fieldSplit.length > 1 ? formData[fieldSplit[0]][fieldSplit[1]] : formData[field];
        return (
            <span
                style={{ display: 'inline-block' }}
                className="hoverable"
                onClick={() => {
                    openDataModal(field);
                }}>
                <input className="hoverable" type="text" name={newField} value={newField} disabled />
                <Edit style={{ fontSize: 18 }} color="action" />
            </span>
        )
    }

    const checkInput = (field) => {
        const orig = field.slice(0, -1) // Field without trailing number
        const num = field.slice(-1); // Trailing Number
        let fieldSplit = orig.split('.');
        let newField = fieldSplit.length > 1 ? formData[fieldSplit[0]][fieldSplit[1]] : formData[orig];
        return (
            <span
                style={{ display: 'inline-block' }}
                className="hoverable"
                onClick={() => {
                    openDataModal(orig);
                }}>

                {num === formData.paid_sick_leave.check_box && fieldSplit[1] === 'check_box' ?
                    <input checked className="hoverable" type="checkbox" name={newField} value={newField} disabled />
                    : fieldSplit[1] === 'check_box' ? <input className="hoverable" type="checkbox" name={newField} value={newField} disabled />
                        : null}
                {num === formData.wage_information.rate_by && fieldSplit[1] === 'rate_by' ?
                    <input checked className="hoverable" type="checkbox" name={newField} value={newField} disabled />
                    : fieldSplit[1] === 'rate_by' ? <input className="hoverable" type="checkbox" name={newField} value={newField} disabled />
                        : null}
                <Edit style={{ fontSize: 18 }} color="action" />
            </span>
        )
    }

    return (
        <div style={{ border: '1px solid black' }}>
            <NTEDataModal
                open={openedDataModal}
                close={() => {
                    setOpenedDataModal(false);
                }}
                field={openedDataField}
                formData={formData}
                setFormData={setFormData}
            />
            <center>
                <h3>Notice To Employee</h3>
                <h4>Labor Code section 2810.5</h4>
            </center>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center><b>EMPLOYEE</b></center>
            </div>
            <br />
            <div>Employee Name: {formInput('employee_name')}</div>
            <div>Start Date: {formInput('start_date')}</div>
            <br />
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center><b>EMPLOYER</b></center>
            </div>
            <div>Legal Name of Hiring Employer: {formInput('employer_legal_name')}</div>
            <div style={{ marginLeft: '40px' }}>Is hiring employer a staffing agency/business (e.g., Temporary Services Agency; Employee Leasing Company; or Professional Employer Organization [PEO])? {formInput('employer_staffing_agency')}</div>
            <div>Other Names Hiring Employer is "doing business as" (if applicable): {formInput('employer_other_names')}</div>
            <div>Physical Address of Hiring Employer's Main Office: {formInput('main_office_address')}</div>
            <div>Hiring Employer's Mailing Address (if different than above): {formInput('employers_mailing_address')}</div>
            <div>Hiring Employer's Telephone Number: {formInput('employer_phone_number')}</div>
            <br />
            <div>
                If the hiring employer is a staffing agency/business (above box checked "Yes"), the following is the other entity for whom this employee will perform work:
                {formData.employer_staffing_agency === 'Yes' &&
                    <div style={{ marginLeft: '40px' }}>
                        <div>Name: {formInput('other_entity.name')}</div>
                        <div>Physical Address of Main Office: {formInput('other_entity.main_office_address')}</div>
                        <div>Mailing Address: {formInput('other_entity.mailing_address')}</div>
                        <div>Telephone Number: {formInput('other_entity.phone_number')}</div>
                    </div>}
            </div>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center><b>WAGE INFORMATION</b></center>
            </div>
            <div>Rate(s) of Pay: {formInput('wage_information.rates_of_pay')} Overtime Rate(s) of Pay: {formInput('wage_information.overtime_pay')}</div>
            <div>Rate by (check box): {checkInput('wage_information.rate_by1')}Hour {checkInput('wage_information.rate_by2')}Shift {checkInput('wage_information.rate_by3')}Day {checkInput('wage_information.rate_by4')}Week {checkInput('wage_information.rate_by5')}Salary {checkInput('wage_information.rate_by6')}Piece Rate {checkInput('wage_information.rate_by7')}Commission</div>
            <div>Other (provide specifics): {formInput('wage_information.other_by')}</div>
            <div>Does a written agreement exist providing the rate(s) of pay? (check box): {formInput('wage_information.written_agreement')}</div>
            {formData.wage_information.written_agreement === 'Yes' && <div style={{ marginLeft: '40px' }}>If yes, are all rate(s) of pay and bases thereof contained in that written agreement? {formInput('wage_information.if_written')}</div>}
            <div>Allowances, if any, claimed as part of minimum wage (including meal or lodging allowances): {formInput('wage_information.allowances')}</div>
            <div style={{ marginLeft: '40px' }}>(If the employee has signed the acknowledgment of receipt below, it does not constitute a “voluntary written
                agreement” as required under the law between the employer and employee in order to credit any meals or lodging
                against the minimum wage. Any such voluntary written agreement must be evidenced by a separate document.)
            </div>
            <div>Regular Payday: {formInput('wage_information.regular_payday')}</div>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center><b>WORKERS’ COMPENSATION</b></center>
            </div>
            <div>Insurance Carrier’s Name: {formInput('workers_compensation.insurance_carriers_name')}</div>
            <div>Address: {formInput('workers_compensation.address')}</div>
            <div>Telephone Number: {formInput('workers_compensation.phone_number')}</div>
            <div>Policy No.: {formInput('workers_compensation.policy_number')}</div>
            <div>Self Insured?: {formInput('workers_compensation.self_insured')} {formData.workers_compensation.self_insured === 'Yes' && <p style={{ marginLeft: '40px' }}>Self-Insured (Labor Code 3700) and Certificate Number for Consent to Self-Insure: {formInput('workers_compensation.self_insured_cert_num')}</p>}</div>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center><b>PAID SICK LEAVE</b></center>
            </div>
            Unless exempt, the employee identified on this notice is entitled to minimum requirements for paid sick leave under state law which provides that an employee:
            <ol type="a">
                <li>
                    May accrue paid sick leave and may request and use up to 3 days or 24 hours of accrued paid sick leave per year;
                </li>
                <li>
                    May not be terminated or retaliated against for using or requesting the use of accrued paid sick leave; and
                </li>
                <li>
                    Has the right to file a complaint against an employer who retaliates or discriminates against an employee for
                    <ol>
                        <li>
                            requesting or using accrued sick days;
                        </li>
                        <li>
                            attempting to exercise the right to use accrued paid sick days;
                        </li>
                        <li>
                            filing a complaint or alleging a violation of Article 1.5 section 245 et seq. of the California Labor Code;
                        </li>
                        <li>
                            cooperating in an investigation or prosecution of an alleged violation of this Article or opposing any policy
                            or practice or act that is prohibited by Article 1.5 section 245 et seq. of the California Labor Code.
                        </li>
                    </ol>
                </li>
            </ol>
            The following applies to the employee identified on this notice: (Check one box)
            <ol>
                <li>
                    {checkInput('paid_sick_leave.check_box1')} Accrues paid sick leave only pursuant to the minimum requirements stated in Labor Code §245 et seq. with no
                    other employer policy providing additional or different terms for accrual and use of paid sick leave.
                </li>
                <li>
                    {checkInput('paid_sick_leave.check_box2')} Accrues paid sick leave pursuant to the employer’s policy which satisfies or exceeds the accrual, carryover, and use
                    requirements of Labor Code §246
                </li>
                <li>
                    {checkInput('paid_sick_leave.check_box3')} Employer provides no less than 24 hours (or 3 days) of paid sick leave at the beginning of each 12-month period.
                </li>
                <li>
                    {checkInput('paid_sick_leave.check_box4')} The employee is exempt from paid sick leave protection by Labor Code §245.5. (State exemption and specific
                    subsection for exemption): {formInput('paid_sick_leave.exemption')}
                </li>
            </ol>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <center>
                    <div><b>ACKNOWLEDGEMENT OF RECEIPT</b></div>
                    <div><i>(Optional)</i></div>
                </center>
            </div>
            <center>
                <table style={{ width: '60%', marginLeft: 'auto', marginRight: 'auto' }}>
                    <tbody>
                        <tr>
                            <td>
                                <div>{formInput('employer_representative')}</div>
                                <div><b>(PRINT NAME of Employer representative)</b></div>
                            </td>
                            <td>
                                <div>{formInput('employee_name')}</div>
                                <div><b>(PRINT NAME of Employee)</b></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                                <div><b>(SIGNATURE of Employer Representative)</b></div>
                            </td>
                            <td>
                                <div></div>
                                <div><b>(SIGNATURE of Employee)</b></div>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <div></div>
                                <div><b>(Date)</b></div>
                            </td>
                            <td>
                                <div></div>
                                <div><b>(Date)</b></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </center>
            <div>The employee’s signature on this notice merely constitutes acknowledgement of receipt.</div>
            <div style={{ borderTop: '1px solid', borderBottom: '1px solid', backgroundColor: '#d9d9d9' }}>
                <br />
            </div>
            <br />
            <div>
                Labor Code section 2810.5(b) requires that the employer notify you in writing of any changes to the information
                set forth in this Notice within seven calendar days after the time of the changes, unless one of the following
                applies: (a) All changes are reflected on a timely wage statement furnished in accordance with Labor Code
                section 226; (b) Notice of all changes is provided in another writing required by law within seven days of the
                changes.
            </div>
        </div>
    );
}

export default NoticeToEmployee;