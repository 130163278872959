import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const tenantRepFields = FIELDS.TenantRep;

export const subFields = SUBFIELDS.TenantRep;

export const sectionNames = SECTION.TenantRep.names;

export const sectionRequirements = SECTION.TenantRep.requirements;

export const initialData = getInitialData(tenantRepFields)

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);

