export const fields = {
    date: {
        label: 'Date',
        type: 'date',
        validations: ['required'],
        step: 1
    },
    type_of_property: {
        label: 'Type of Property',
        type: 'select',
        validations: ['required'],
        step: 1
    },
    location: {
        label: 'Location',
        type: 'input',
        validations: ['required'],
        step: 1
    },
    tenants: {
        label: 'Tenants',
        type: 'array',
        validations: ['required'],
        step: 2
    },
    period_commencing: {
        label: 'Date commencing',
        type: 'date',
        validations: ['required'],
        step: 3
    },
    period_ending_on: {
        label: 'Date ending on',
        type: 'date',
        validations: ['required'],
        step: 3
    },
    project_name: {
        label: 'Project Name',
        type: 'input',
        validations: ['required']
    },
    street1: {
        label: 'Street1',
        type: 'input',
        validations: ['required']
    },
    street2: {
        label: 'Street2',
        type: 'input',
        validations: ['required']
    },
    city: {
        label: 'City',
        type: 'input',
        validations: ['required']
    },
    state: {
        label: 'State',
        type: 'input',
        validations: ['required']
    },
    zip: {
        label: 'Zip',
        type: 'input',
        validations: ['required']
    },
    final_approver_name: {
        label: 'Final Approver/Signer Name',
        type: 'input',
        validations: ['required']
    },
    final_approver_email: {
        label: 'Final Approver/Signer Email',
        type: 'input',
        validations: ['required']
    },
    final_approver_position: {
        label: 'Final Approver/Signer Title',
        type: 'input',
        validations: ['required']
    }
}

const defaultObjData = {};
Object.keys(fields).forEach(field => {
    defaultObjData[field] = '';
    if (field === 'tenants') {
        defaultObjData[field] = []
    }
});
export const defaultTRData = defaultObjData;

export const defaultTenantData = {
    name: '',
    title: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    email: ''
}