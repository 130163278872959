import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from 'components/CustomSelect/CustomSelect';
import { clone } from 'shared/utility';

const PMAMulti9 = (props) => {

    const { formData, setFormData, approvers } = props;

    const renderElement = () => {
        return <CustomSelect
            label="Select "
            options={approvers.map(a => {
                return `${a.name} - ${a.email}`;
            })}
            choose={(e, n) => {
                const newData = clone(formData);
                newData.president = e.split(' - ')[0];
                newData.president_email = e.split(' - ')[1];
                setFormData(newData);
            }}
            default={(formData.president && formData.president_email) ? `${formData.president} - ${formData.president_email}` : ''}
        />
    }

    return (
        <div>
            <center><h3>Approver Details</h3></center>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={6}>
                    {renderElement()}
                </GridItem>
            </GridContainer>
            
        </div>
    );
}

export default PMAMulti9;