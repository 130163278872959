const COMMON_SUBFIELDS = {
	lessee: {
		company_name: {
			label: 'Lessee Company Name',
			type: 'input',
		},
		name: {
			label: 'Lessee First Name',
			type: 'input',
		},
		last_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		dba_name: {
			label: 'DBA',
			type: 'input',
		},
		address1: {
			label: 'Address',
			type: 'input',
			validations: ['required']
		},
		address2: {
			label: 'Address 2',
			type: 'input',
		},
		city: {
			label: 'City',
			type: 'input',
			validations: ['required']
		},
		state: {
			label: 'State',
			type: 'state',
			validations: ['required']
		},
		zip: {
			label: 'Zip',
			type: 'input',
			validations: ['required']
		},
		main_phone: {
			label: 'Main Phone',
			type: 'input',
			validations: ['required']
		},
		work_phone: {
			label: 'Work Phone',
			type: 'input',
			validations: []
		},
		mobile_phone: {
			label: 'Mobile Phone',
			type: 'input',
			validations: []
		},
		fax: {
			label: 'Fax',
			type: 'input',
		},
		email: {
			label: 'Email',
			type: 'input',
			validations: ['required']
		},
		agency_disclosure: {
			label: 'Agency Disclosure',
			type: 'radio',
			options: ['Yes', 'No'],
			validations: ['required']
		},
		llc_corp_verified: {
			label: 'LLC / Corp Verified',
			type: 'radio',
			options: ['Yes', 'N/A']
		},
		upload_sos: {
			label: 'S.O.S.',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg']
		},
		spousal_consent: {
			label: 'Spousal Consent',
			type: 'radio',
			options: ['Yes', 'N/A'],
		},
		upload_spousal_id: {
			label: 'Spousal ID',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg'],
		},
		guarantor: {
			label: 'Guarantor',
			type: 'radio',
			options: ['Yes', 'N/A'],
			validations: ['required']
		},
		guarantor_name: {
			label: 'Guarantor Name',
			type: 'input',
			validations: ['required']
		},
		upload_guarantor_id: {
			label: 'Guarantor ID',
			type: 'file_upload',
			filetypes: ['pdf', 'png', 'jpg']
		},
		guarantor_spouse_name: {
			label: 'Guarantor Spouse Name',
			type: 'input',
		},
		can_we_reach_out_for_testimonial: {
			label: 'Can we reach out for testimonial ?',
			type: 'radio',
			options: ['Yes', 'No'],
			validations: ['required'],
		},
		can_we_reach_out_for_testimonial_comment: {
			label: 'Comment',
			type: 'input',
			validations: [],
		},
		contact_type: {
			label: 'Type',
			type: 'multi-select',
		},
	},
	lessor: {
		id: {
			label: 'ID',
			type: 'input',
			readonly: true,
		},
		company_name: {
			label: 'Lessor Company Name',
			type: 'input',
		},
		name: {
			label: 'Lessor First Name',
			type: 'input',
		},
		last_name: {
			label: 'Lessee Last Name',
			type: 'input',
		},
		dba_name: {
			label: 'DBA',
			type: 'input',
		},
		address1: {
			label: 'Address',
			type: 'input',
			validations: ['required'],
		},
		address2: {
			label: 'Address 2',
			type: 'input',
		},
		city: {
			label: 'City',
			type: 'input',
			validations: ['required'],
		},
		state: {
			label: 'State',
			type: 'state',
			validations: ['required'],
		},
		zip: {
			label: 'Zip',
			type: 'input',
			validations: ['required'],
		},
		main_phone: {
			label: 'Main Phone',
			type: 'input',
			validations: ['required'],
		},
		work_phone: {
			label: 'Work Phone',
			type: 'input',
			validations: []
		},
		mobile_phone: {
			label: 'Mobile Phone',
			type: 'input',
			validations: []
		},
		fax: {
			label: 'Fax',
			type: 'input',
		},
		email: {
			label: 'Email',
			type: 'input',
			validations: ['required'],
		},
		agency_disclosure: {
			label: 'Agency Disclosure',
			type: 'radio',
			options: ['Yes', 'No'],
		},
		can_we_reach_out_for_testimonial: {
			label: 'Can we reach out for testimonial ?',
			type: 'radio',
			options: ['Yes', 'No'],
			validations: ['required'],
		},
		can_we_reach_out_for_testimonial_comment: {
			label: 'Comment',
			type: 'input',
		},
		contact_type: {
			label: 'Type',
			type: 'multi-select',
		},
	},
	lessee_contacts: {
		name: {
			label: 'Name',
			type: 'input',
		},
		last_name: {
			label: 'Last Name',
			type: 'input',
		},
		email: {
			label: 'Email',
			type: 'input',
		},
		address1: {
			label: 'Address',
			type: 'input',
		},
		address2: {
			label: 'Address 2',
			type: 'input',
		},
		city: {
			label: 'City',
			type: 'input',
		},
		state: {
			label: 'State',
			type: 'state',
		},
		zip: {
			label: 'Zip',
			type: 'input',
		},
		contact_type: {
			label: 'Type',
			type: 'multi-select',
		},
		main_phone: {
			label: 'Main Phone',
			type: 'input',
		},
	},
	lessor_contacts: {
		name: {
			label: 'Name',
			type: 'input',
			validations: ['required'],
		},
		email: {
			label: 'Email',
			type: 'input',
			validations: ['required'],
		},
		address1: {
			label: 'Address',
			type: 'input',
			validations: ['required'],
		},
		contact_type: {
			label: 'Type',
			type: 'multi-select',
		},
	},
	commission_splits: {
		name: {
			label: 'Name',
			type: 'input',
			validations: ['max50', 'no_numbers', 'no_special_chars'],
		},
		percentage: {
			label: 'Percentage',
			type: 'input',
			validations: ['no_letters', 'no_special_chars', 'max3'],
		},
	},
};

export const SUBFIELDS = {
	// * Commission
	Commision: {
		lessee: COMMON_SUBFIELDS.lessee,
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
		// lessee: {
		// 	name: COMMON_SUBFIELDS.lessee.name,
		// 	last_name: COMMON_SUBFIELDS.lessee.last_name,
		// 	address1: COMMON_SUBFIELDS.lessee.address1,
		// 	address2: COMMON_SUBFIELDS.lessee.address2,
		// 	city: COMMON_SUBFIELDS.lessee.city,
		// 	state: COMMON_SUBFIELDS.lessee.state,
		// 	zip: COMMON_SUBFIELDS.lessee.zip,
		// 	main_phone: COMMON_SUBFIELDS.lessee.main_phone,
		// 	fax: COMMON_SUBFIELDS.lessee.fax,
		// 	email: COMMON_SUBFIELDS.lessee.email,
		// },
		// lessor: {
		// 	id: COMMON_SUBFIELDS.lessor.id,
		// 	name: COMMON_SUBFIELDS.lessor.name,
		// 	last_name: COMMON_SUBFIELDS.lessor.last_name,
		// 	address1: COMMON_SUBFIELDS.lessor.address1,
		// 	address2: COMMON_SUBFIELDS.lessor.address2,
		// 	city: COMMON_SUBFIELDS.lessor.city,
		// 	state: COMMON_SUBFIELDS.lessor.state,
		// 	zip: COMMON_SUBFIELDS.lessor.zip,
		// 	main_phone: COMMON_SUBFIELDS.lessor.main_phone,
		// 	fax: COMMON_SUBFIELDS.lessor.fax,
		// 	email: COMMON_SUBFIELDS.lessor.email,
		// 	agency_disclosure: COMMON_SUBFIELDS.lessor.agency_disclosure,
		// 	can_we_reach_out_for_testimonial:
		// 		COMMON_SUBFIELDS.lessor.can_we_reach_out_for_testimonial,
		// 	can_we_reach_out_for_testimonial_comment:
		// 		COMMON_SUBFIELDS.lessor.can_we_reach_out_for_testimonial_comment,
		// 	contact_type: COMMON_SUBFIELDS.lessor.contact_type,
		// },
		// lessee_contacts: {
		// 	name: COMMON_SUBFIELDS.lessee_contacts.name,
		// 	email: COMMON_SUBFIELDS.lessee_contacts.email,
		// 	address1: COMMON_SUBFIELDS.lessee_contacts.address1,
		// 	contact_type: COMMON_SUBFIELDS.lessee_contacts.contact_type,
		// },
		// lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		// commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * Consulting
	Consulting: {
		lessee: {
			...COMMON_SUBFIELDS.lessee,
			company_name: {
				label: 'Lessor/Buyer Company Name',
				type: 'input',
			},
			name: {
				label: 'Assignee First Name',
				type: 'input',
			},
			last_name: {
				label: 'Assignee Last Name',
				type: 'input',
			},
		},
		lessor: {
			...COMMON_SUBFIELDS.lessor,
			company_name: {
				label: 'Lessor/Seller Company Name',
				type: 'input',
				validations: ['required'],
			},
			name: {
				label: 'Lessor/Seller First Name',
				type: 'input',
				validations: ['required'],
			},
			last_name: {
				label: 'Lessor/Buyer Last Name',
				type: 'input',
				validations: ['required'],
			},
		},
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * Lease
	Lease: {
		lessee: COMMON_SUBFIELDS.lessee,
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * LeaseAssignmentFee
	LeaseAssignmentFee: {
		lessee: {
			...COMMON_SUBFIELDS.lessee,
			company_name: {
				label: 'Assignee Company Name',
				type: 'input',
			},
			name: {
				label: 'Assignee First Name',
				type: 'input',
			},
			last_name: {
				label: 'Assignee Last Name',
				type: 'input',
			},
		},
		lessor: COMMON_SUBFIELDS.lessor,
		assignor: {
			...COMMON_SUBFIELDS.lessee,
			company_name: {
				label: 'Assignor Company Name',
				type: 'input',
				validations: ['required'],
			},
			name: {
				label: 'Assignor First Name',
				type: 'input',
			},
			last_name: {
				label: 'Assignor Last Name',
				type: 'input',
			},
		},
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: {
			name: COMMON_SUBFIELDS.lessor_contacts.name,
			email: COMMON_SUBFIELDS.lessor_contacts.email,
			address1: COMMON_SUBFIELDS.lessor_contacts.address1,
			contact_type: COMMON_SUBFIELDS.lessor_contacts.contact_type,

			last_name: COMMON_SUBFIELDS.lessee_contacts.last_name,
			address2: COMMON_SUBFIELDS.lessee_contacts.address2,
			city: COMMON_SUBFIELDS.lessee_contacts.city,
			state: COMMON_SUBFIELDS.lessee_contacts.state,
			zip: COMMON_SUBFIELDS.lessee_contacts.zip,
			main_phone: COMMON_SUBFIELDS.lessee_contacts.main_phone,
		},
		assignor_contacts: COMMON_SUBFIELDS.lessee_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * NoListing
	NoListing: {
		lessee: COMMON_SUBFIELDS.lessee,
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * Residential
	Residential: {
		lessee: COMMON_SUBFIELDS.lessee,
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * Sale
	Sale: {
		lessee: {
			name: {
				label: 'Buyer First Name',
				type: 'input',
			},
			last_name: {
				label: 'Buyer Last Name',
				type: 'input',
			},
			company_name: COMMON_SUBFIELDS.lessee.company_name,
			address1: COMMON_SUBFIELDS.lessee.address1,
			address2: COMMON_SUBFIELDS.lessee.address2,
			city: COMMON_SUBFIELDS.lessee.city,
			state: COMMON_SUBFIELDS.lessee.state,
			zip: COMMON_SUBFIELDS.lessee.zip,
			main_phone: COMMON_SUBFIELDS.lessee.main_phone,
			work_phone: COMMON_SUBFIELDS.lessee.work_phone,
			mobile_phone: COMMON_SUBFIELDS.lessee.mobile_phone,
			fax: COMMON_SUBFIELDS.lessee.fax,
			email: COMMON_SUBFIELDS.lessee.email,
			agency_disclosure: COMMON_SUBFIELDS.lessee.agency_disclosure,
			llc_corp_verified: COMMON_SUBFIELDS.lessee.llc_corp_verified,
			upload_sos: COMMON_SUBFIELDS.lessee.upload_sos,
			spousal_consent: COMMON_SUBFIELDS.lessee.spousal_consent,
			upload_spousal_id: COMMON_SUBFIELDS.lessee.upload_spousal_id,
			guarantor: COMMON_SUBFIELDS.lessee.guarantor,
			guarantor_name: COMMON_SUBFIELDS.lessee.guarantor_name,
			upload_guarantor_id: COMMON_SUBFIELDS.lessee.upload_guarantor_id,
			guarantor_spouse_name: COMMON_SUBFIELDS.lessee.guarantor_spouse_name,
			can_we_reach_out_for_testimonial:
				COMMON_SUBFIELDS.lessee.can_we_reach_out_for_testimonial,
			can_we_reach_out_for_testimonial_comment:
				COMMON_SUBFIELDS.lessee.can_we_reach_out_for_testimonial_comment,
			contact_type: COMMON_SUBFIELDS.lessee.contact_type,
		},
		lessor: {
			name: {
				label: 'Seller First Name',
				type: 'input',
				validations: ['required'],
			},
			last_name: {
				label: 'Buyer Last Name',
				type: 'input',
				validations: ['required'],
			},
			id: COMMON_SUBFIELDS.lessor.id,
			company_name: COMMON_SUBFIELDS.lessee.company_name,
			address1: COMMON_SUBFIELDS.lessor.address1,
			address2: COMMON_SUBFIELDS.lessor.address2,
			city: COMMON_SUBFIELDS.lessor.city,
			state: COMMON_SUBFIELDS.lessor.state,
			zip: COMMON_SUBFIELDS.lessor.zip,
			main_phone: COMMON_SUBFIELDS.lessor.main_phone,
			work_phone: COMMON_SUBFIELDS.lessee.work_phone,
			mobile_phone: COMMON_SUBFIELDS.lessee.mobile_phone,
			fax: COMMON_SUBFIELDS.lessor.fax,
			email: COMMON_SUBFIELDS.lessor.email,
			agency_disclosure: COMMON_SUBFIELDS.lessor.agency_disclosure,
			can_we_reach_out_for_testimonial:
				COMMON_SUBFIELDS.lessor.can_we_reach_out_for_testimonial,
			can_we_reach_out_for_testimonial_comment:
				COMMON_SUBFIELDS.lessor.can_we_reach_out_for_testimonial_comment,
			contact_type: COMMON_SUBFIELDS.lessor.contact_type,
		},
		lessee_contacts: {
			name: COMMON_SUBFIELDS.lessee_contacts.name,
			email: COMMON_SUBFIELDS.lessee_contacts.email,
			address1: COMMON_SUBFIELDS.lessee_contacts.address1,
			contact_type: COMMON_SUBFIELDS.lessee_contacts.contact_type,
		},
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * Sublease
	Sublease: {
		lessee: {
			...COMMON_SUBFIELDS.lessee,
			name: {
				label: 'Assignee First Name',
				type: 'input',
			},
			last_name: {
				label: 'Assignee Last Name',
				type: 'input',
			},
		},
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
	// * TenantRep
	TenantRep: {
		lessee: COMMON_SUBFIELDS.lessee,
		lessor: COMMON_SUBFIELDS.lessor,
		lessee_contacts: COMMON_SUBFIELDS.lessee_contacts,
		lessor_contacts: COMMON_SUBFIELDS.lessor_contacts,
		commission_splits: COMMON_SUBFIELDS.commission_splits,
	},
};
