import React, { useState, useEffect } from 'react'
import { List, ListItem } from '@material-ui/core';
import { CheckCircle, Close } from '@material-ui/icons';
import Button from "components/CustomButtons/Button.jsx";
import NoticeToEmployee from './NoticeToEmployee';
import { getUserID } from 'shared/authValidation';
import { FadeLoader } from 'react-spinners';
import { Dialog } from '@material-ui/core';

const sections = {
    employee: [
        {
            field: 'mls_compliance_form',
            label: 'MLS Compliance Form'
        },  
        {
            field: 'notice_to_employee',
            label: 'Notice to Employee Form'
        },
        {
            field: 'employee_agreement_nda',
            label: 'Employee Agreement / NDA' // not yet available
        },
        {
            field: 'resume',
            label: 'Resume (Upload)'
        },
        {
            field: 'i9_and_docs',
            label: 'I9 and Docs (Upload)'
        },
        {
            field: 'upload_background_check',
            label: 'Upload Background Check Report / Option'
        }
    ],
    agent: [
        {
            field: 'mls_compliance_form',
            label: 'MLS Compliance Form'
        }, 
        {
            field: 'notice_to_employee',
            label: 'Notice to Employee Form'
        },
        {
            field: 'resume',
            label: 'Resume (Upload)'
        },
        {
            field: 'dre_status',
            label: 'DRE Status'
        },
        {
            field: 'independent_contractor',
            label: 'Independent Contractor Agreement' // Docusign
        },
        {
            field: 'fee_schedule',
            label: 'Fee Schedule'
        }
    ]
};

const OnboardingOverview = (props) => {

    const { currentEntry, getObPdf, pdfData, createNteEntry, updateOnboardingEntry, getObWorkspaceUser, userData } = props;

    const [topRef, setTopRef] = useState(null);
    const [currentEntryState, setCurrentEntryState] = useState(currentEntry.data);
    const [tab, setTab] = useState('Overview');

    useEffect(() => {
        getObWorkspaceUser(currentEntry.mail);
    }, [])

    useEffect(() => {
        if(topRef){
            topRef.scrollIntoView(true);
        }
        setCurrentEntryState(currentEntry);
        
    }, [currentEntry, tab, userData])

    if (userData) {
        if (!userData.custom_fields_data) {
            return <div className="text-center">
                <div style={{display:'inline-block'}}>
                    <FadeLoader />
                </div>
            </div>
        }
    }

    const custom_data = userData ? 
        userData.custom_fields_data ? 
            userData.custom_fields_data
        :
            {} 
    : 
        {}

    // if not employee, then agent
    const userType = custom_data.is_illi_employee ? 
        custom_data.is_illi_employee === 'Employee' ? 
            'employee' 
        : 
            'agent' 
    : 
        'agent';

    const has_dre = custom_data.DRE ? 
        true
    : 
        false;

    return (
        <div ref={setTopRef}>
            <h4><strong>Onboarding Requirements</strong> - {currentEntryState ? currentEntryState.name : ''} ({userType === 'employee' ? 'Employee' : 'Agent'})</h4>
            {tab === 'Overview' && currentEntryState &&
                <List >
                {sections[userType].map((sec,i) => {
                    const section = {
                        label: sec.label,
                        field: sec.field,
                        done: currentEntryState.data ? 
                                currentEntryState.data[sec.field] ?
                                    currentEntryState.data[sec.field]
                                :
                                    false
                            : 
                                false
                    }
                    if (sec.field === 'mls_compliance_form') {
                        if (!has_dre) {
                            return null;
                        }
                    }
                    return <ListItem key={`section-${i}`}>
                        <p style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }} 
                            className="hoverable hover-red"
                            onClick={() => setTab(section.field)}
                        >
                            {section.done ? 
                                <CheckCircle /> 
                            : 
                                <i className="fas fa-times-circle font-22"></i>
                            }
                            <span className="ml-20">{section.label}</span> 
                        </p>
                    </ListItem>
                })}
                </List>
            }
            {tab === 'notice_to_employee' &&
            <Dialog
                open={tab === 'notice_to_employee' ? true : false}
                onClose={() => {
                    setTab('Overview');
                }}
                maxWidth={false}
            >
                <div className="dialog-wrapper">
                    <div className="close-btn" onClick={() => { setTab('Overview') }}><Close /></div>
                    <NoticeToEmployee
                        currentEntry={currentEntry}
                        pdfData={pdfData}
                        getObPdf={getObPdf}
                        createNteEntry={createNteEntry}
                        updateOnboardingEntry={updateOnboardingEntry}
                        setTab={setTab}
                        userData={userData}
                    />
                </div>
            </Dialog>
            }
        </div>
    )
};

export default OnboardingOverview;