import React from "react";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect";
import {
    min1,
    min5,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max50,
    max100,
    max250,
    max500,
    equal100,
} from "shared/validation";
import Datetime from "react-datetime";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { listingFields } from "./ListingModel";
import { get, isEmpty, last, sortBy } from "lodash";
import ListingHistoryModal from "./ListingHistoryModal";
import { useState } from "react";
import { canEdit } from './ListingLogic';

const validate = {
    min1,
    min5,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max50,
    max100,
    max250,
    max500,
    equal100,
};

const getValidations = (f, fieldModels) => {
    const f_arr = f.split(".");
    let model;
    if (f_arr.length > 1) {
        model = fieldModels[f_arr[0]][f_arr[1]];
    } else {
        model = fieldModels[f];
    }
    if (model.validations) return model.validations.map((v) => validate[v]);
    return [];
};

const ListingElement = (props) => {
    const {
        updateDataObj,
        data,
        field,
        options,
        startAdornment,
        endAdornment,
        access,
        two_decimals,
        historyData,
        fieldModels,
    } = props;

    const [opened, setOpened] = useState(false);
    const open = () => setOpened(true);
    const close = () => setOpened(false);

    let model;
    const field_arr = field.split(".");
    let is_obj = false;
    if (field_arr.length > 1) {
        model = fieldModels[field_arr[0]][field_arr[1]];
        is_obj = true;
    } else {
        model = fieldModels[field];
    }
    if (!field || !model) return null;
    let element = [];
    const value = is_obj ? data[field_arr[0]][field_arr[1]] : data[field];

    let fieldHistory = [];
    let lastDifference;
    if (model.showHistory && !isEmpty(historyData)) {
        fieldHistory = sortBy(
            historyData
                .map((history) => {
                    const value = get(history, `data.${field}`, "");
                    return { value, created_date: history.created_date };
                })
                .filter((i) => i.value && i.value !== value),
            "created_date"
        );
        lastDifference = last(fieldHistory);
    }

    if (model.type === "input") {
        let allowed = true;
        if (access) {
            if (access === "no") {
                allowed = false;
            }
        }
        const validations =
            field === "pma_id"
                ? getValidations(field, fieldModels).concat([required])
                : getValidations(field, fieldModels);
        element.push(
            <div className={`${lastDifference && "custom-input-with-history"}`} key={`elem-${element.length + 1}`}>
                <CustomInputValidate
                    label={model.label}
                    value={value}
                    onChange={
                        model.canEdit === false
                            ? null
                            : (e) => {
                                  if (two_decimals) {
                                      if (!e.target.value) {
                                          return updateDataObj(
                                              field,
                                              e.target.value
                                          );
                                      }
                                      let str = e.target.value
                                          .split(".")
                                          .join("")
                                          .split(",")
                                          .join("");
                                      str = parseFloat(str).toString();
                                      var x = "";
                                      var len = str.length;
                                      if (len > 2) {
                                          x =
                                              str.substring(0, len - 2) +
                                              "." +
                                              str.substring(len - 2);
                                      } else if (len === 2) {
                                          x = "0." + str;
                                      } else {
                                          x = "0.0" + str;
                                      }
                                      const result = parseFloat(
                                          x.replace(/,/g, "")
                                      ).toLocaleString();
                                      updateDataObj(field, result);
                                  } else {
                                      updateDataObj(field, e.target.value);
                                  }
                              }
                    }
                    validate={validations}
                    type={model.number_only ? "number" : "text"}
                    startAdornment={startAdornment}
                    endAdornment={endAdornment}
                    readonly={allowed && model.canEdit !== false ? false : true}
                />
                {lastDifference && (
                    <div className="input-history" onClick={open}>
                        {lastDifference.value}
                    </div>
                )}
            </div>
        );
    } else if (model.type === "date") {
        let error = "";
        const validator = model.validations;
        if (validator) {
            if (validator.length > 0) {
                validator.forEach((valfield) => {
                    if (validate[valfield]) {
                        const val = validate[valfield];
                        if (val) {
                            const err = val(data[field]);
                            if (err) {
                                error = err;
                            }
                        }
                    }
                });
            }
        }
        element.push(
            <div className={`custom relative ${error ? "errored" : ""}`} key={`elem3-${element.length + 1}`}>
                {value && <div className="tiny-label">{model.label}</div>}
                <Datetime
                    inputProps={{
                        placeholder: model.label,
                    }}
                    closeOnSelect={true}
                    onChange={(e) => {
                        if (typeof e === "object") {
                            const stringDate = e.format("MMMM D, YYYY");
                            return updateDataObj(field, stringDate);
                        }
                        updateDataObj(field, e);
                    }}
                    timeFormat={false}
                    value={value ? value : ""}
                />
                {error ? <div className="red-text mt-10">Required</div> : null}
            </div>
        );
    } else if (model.type === "radio") {
        element.push(
            <div className="mt-20 mb-20" key={`elemq-${element.length + 1}`}>
                <CustomRadio
                    initialValue={value}
                    label={`${model.label},${model.options.join(",")}`}
                    options={model.options}
                    onChange={(e) => {
                        updateDataObj(field, e.target.value);
                    }}
                    value={value}
                    add_break_to_label={true}
                />
                {model.required && !value ? (
                    <div className="red-text">
                        <small>Required</small>
                    </div>
                ) : null}
            </div>
        );
    } else if (model.type === "select") {
        let error = "";
        const validator = model.validations;
        if (validator) {
            if (validator.length > 0) {
                validator.forEach((valfield) => {
                    if (validate[valfield]) {
                        const val = validate[valfield];
                        if (val) {
                            const err = val(value);
                            if (err) {
                                error = err;
                            }
                        }
                    }
                });
            }
        }
        element.push(
            <div key={`elemw-${element.length + 1}`}>
                <CustomSelect
                    label={model.label}
                    options={options ? options : model.options}
                    choose={(e, n) => {
                        updateDataObj(field, e);
                    }}
                    default={value}
                />
                {error ? <div className="red-text mt-10">Required</div> : null}
            </div>
        );
    }
    return (
        <>
            {element}
            <ListingHistoryModal
                history={fieldHistory}
                isOpen={opened}
                close={close}
            />
        </>
    );
};

export default ListingElement;
