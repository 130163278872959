import * as actionTypes from './actionTypes';

// GET ALL SHOWINGS USING PARAMS

export const getShowings = (data) => {
    return {
        type: actionTypes.GET_SHOWINGS,
        data
    }
}

export const getShowingsStart = () => {
    return {
        type: actionTypes.GET_SHOWINGS_START
    }
}

export const getShowingsSuccess = (response) => {
    return {
        type: actionTypes.GET_SHOWINGS_SUCCESS,
        response
    }
}

export const getShowingsFail = (err) => {
    return {
        type: actionTypes.GET_SHOWINGS_FAIL,
        err
    }
}

// GET_SHOWING 

export const getShowing = (data) => {
    return {
        type: actionTypes.GET_SHOWING,
        data
    }
}

export const getShowingStart = () => {
    return {
        type: actionTypes.GET_SHOWING_START
    }
}

export const getShowingSuccess = (response) => {
    return {
        type: actionTypes.GET_SHOWING_SUCCESS,
        response
    }
}

export const getShowingFail = (err) => {
    return {
        type: actionTypes.GET_SHOWING_FAIL,
        err
    }
}

// CREATE SHOWING

export const createShowing = (data) => {
    return {
        type: actionTypes.CREATE_SHOWING,
        data
    }
}

export const createShowingStart = () => {
    return {
        type: actionTypes.CREATE_SHOWING_START
    }
}

export const createShowingSuccess = (response) => {
    return {
        type: actionTypes.CREATE_SHOWING_SUCCESS,
        response
    }
}

export const createShowingFail = (err) => {
    return {
        type: actionTypes.CREATE_SHOWING_FAIL,
        err
    }
}

// UPDATE SHOWING 

export const updateShowing = (data) => {
    return {
        type: actionTypes.UPDATE_SHOWING,
        data
    }
}

export const updateShowingStart = () => {
    return {
        type: actionTypes.UPDATE_SHOWING_START
    }
}

export const updateShowingSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_SHOWING_SUCCESS,
        response
    }
}

export const updateShowingFail = (err) => {
    return {
        type: actionTypes.UPDATE_SHOWING_FAIL,
        err
    }
}

// DELETE SHOWING

export const deleteShowing = (data) => {
    return {
        type: actionTypes.DELETE_SHOWING,
        data
    }
}

export const deleteShowingStart = () => {
    return {
        type: actionTypes.DELETE_SHOWING_START
    }
}

export const deleteShowingSuccess = (response) => {
    return {
        type: actionTypes.DELETE_SHOWING_SUCCESS,
        response
    }
}

export const deleteShowingFail = (err) => {
    return {
        type: actionTypes.DELETE_SHOWING_FAIL,
        err
    }
}