import { clone, get } from "lodash";
import { LISTING_STATUS, SECTION_NAMES } from "./ListingObj";
import { requiredFields } from "./Steps/Step5";
import React, { Component }  from 'react';

const permObjs = [
    "Listing approve level_1",
    "Listing approve level_2",
    "Listing approve level_3",
    "Listing approve level_4",
];

const removeSplitter = (word) => {
    return word.split('_|_')[0];
}

const hasPermission = (permission, user) => {
    const permObj = get(user, "permObj", {});
    if (permObj.hasOwnProperty(permObjs[3])) {
        return true;
    }

    if (permission === permObjs[0]) {
        return (
            permObj.hasOwnProperty(permObjs[2]) ||
            permObj.hasOwnProperty(permObjs[1]) ||
            permObj.hasOwnProperty(permObjs[0])
        );
    }

    if (permission === permObjs[1]) {
        return (
            permObj.hasOwnProperty(permObjs[2]) ||
            permObj.hasOwnProperty(permObjs[1])
        );
    }

    if (permission === permObjs[2]) {
        return permObj.hasOwnProperty(permObjs[2]);
    }

    if (permission === permObjs[3]) {
        return permObj.hasOwnProperty(permObjs[3]);
    }
};

export const canEdit = (status, user, agentList = []) => {
    const permObj = get(user, "permObj", {});

    // const agentEmails = agentList.map(agent => agent.primaryEmail);
    const agentEmails = [];
    const isAgent = agentEmails.includes(user.email);

    if (status === LISTING_STATUS.PENDING) {
        return isAgent;
    }

    if (status === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_2) {
        return hasPermission("Listing approve level_2", user);
    }

    if (status === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_3) {
        return hasPermission("Listing approve level_3", user);
    }

    if (status === LISTING_STATUS.PENDING_FINAL_APPROVAL_LEVEL_4) {
        return hasPermission("Listing approve level_4", user);
    }
};

export const generateSections = (data, func, fieldModels) => {
    let allowToSubmit = true;
    let sections = [];
    Object.values(SECTION_NAMES).forEach((section) => {
        if (data) {
            let complete = true;
            if (section === SECTION_NAMES.LISTING) {
                if (!data.title) {
                    allowToSubmit = false;
                    complete = false;
                }
            } else if (section === SECTION_NAMES.ERTL) {
                if (data.signed === "") {
                    allowToSubmit = false;
                    complete = false;
                }
            } else if (section === SECTION_NAMES.AGENT) {
                if (!data.agentList || data.agentList.length === 0) {
                    allowToSubmit = false;
                    complete = false;
                }
            } else if (section === SECTION_NAMES.PROPERTY) {
            } else if (section === SECTION_NAMES.ADDITIONAL) {
                requiredFields(fieldModels).forEach((field) => {
                    if (!data[field]) {
                        complete = false;
                        allowToSubmit = false;
                    }
                });
            }
            sections.push({
                name: section,
                complete,
            });
        }
    });

    func(allowToSubmit);
    return sections;
};

export const getDifferences = (original, newstring, shouldShowIt, author) => {
    if (original === newstring) return newstring;
    // make sure duplicate words are tracked accurately
    let instances1 = {};
    let instances2 = {};
    const o_arr = original
        ? original
              .split(" ")
              .filter((r) => r !== "")
              .map((r, i) => {
                  if (!instances1.hasOwnProperty(r)) {
                      instances1[r] = [1];
                      return `${r}_|_${0}`;
                  } else {
                      instances1[r].push(1);
                      return `${r}_|_${instances1[r].length - 1}`;
                  }
              })
        : [];
    const n_arr = newstring
        ? newstring
              .split(" ")
              .filter((r) => r !== "")
              .map((r, i) => {
                  if (!instances2.hasOwnProperty(r)) {
                      instances2[r] = [1];
                      return `${r}_|_${0}`;
                  } else {
                      instances2[r].push(1);
                      return `${r}_|_${instances2[r].length - 1}`;
                  }
              })
        : [];
    let newarr = [];
    let erased = [];
    n_arr.forEach((n) => {
        if (o_arr.indexOf(n) === -1) {
            newarr.push(n);
        }
    });
    o_arr.forEach((o) => {
        if (n_arr.indexOf(o) === -1) {
            erased.push(o);
        }
    });
    return (
        <span>
            {n_arr.map((nn, i) => {
                const addedStyles = { color: "black" };
                if (newarr.indexOf(nn) !== -1 && shouldShowIt) {
                    addedStyles.color = author
                        ? author === "Approver"
                            ? "#147ae3"
                            : "red"
                        : "red";
                    addedStyles.fontWeight = "bold";
                    addedStyles.fontSize = 19;
                    addedStyles.textDecoration = "underline";
                }
                return (
                    <span key={`${nn}-s-${i}`} style={addedStyles}>
                        {removeSplitter(nn)}{" "}
                    </span>
                );
            })}
            {erased.length > 0 && shouldShowIt && (
                <span>
                    <br />
                    <span
                        style={{
                            display: "inline-block",
                            padding: 5,
                            backgroundColor: "#ffecec",
                            marginTop: 5,
                        }}
                    >
                        {o_arr.map((nn, i) => {
                            const addedStyles = { color: "black" };
                            if (
                                erased.indexOf(nn) !== -1 &&
                                erased.length > 0 &&
                                shouldShowIt
                            ) {
                                addedStyles.color = "red";
                                addedStyles.fontWeight = "bold";
                            }
                            return (
                                <span key={`${nn}-n-${i}`} style={addedStyles}>
                                    {removeSplitter(nn)}{" "}
                                </span>
                            );
                        })}
                    </span>
                </span>
            )}
        </span>
    );
};

export const generateHTML = (listingFormData, textInput, current_id) => {
    const agentList = get(listingFormData, "agentList", []);

    const formInput = (field) => listingFormData[field];

    const html = `
    <html>
    <head>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,500;1,400&display=swap" rel="stylesheet">
        <style type="text/css">
        .underlined2,
        .underline {
            border-bottom: 1px solid #484848;
        }
        .margin-top {
            margin-top: 50px;
        }
    
        .left-margin {
            margin-left: 50px;
        }
		.x-margin {
			margin: 0 10px;
		}
    
        body {
            margin-right: 50px;
            font-size: 13px;
            color: #484848;
        }
    
        .center {
            margin-left: auto;
            margin-right: auto;
        }
    
        .pageFooter-first {
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            padding: 1rem
        }
    
        .tab {
            margin-left: 10px;
        }
    
        .pb_before {
            page-break-before: always !important;
        }
    
        ol {
            counter-reset: list;
            padding-left: 1em;
        }
    
        table {
            border: solid #000;
            border-collapse: collapse;
            font-size: 10px;
        }
    
        table td + td {
            border-left: 1px solid #000;
            padding-left: 10px;
        }
        .page-break {
            page-break-after:always;
        }
        .text-center {
            text-align:center !important;
        }
        hr {
            border-bottom: 1px solid #ccc;
        }
        .white-text {
            color: white;
        }
        .pdfheader {
            width: 100%;
            margin: 0 auto;
        }
        .rcolumn {
            float: left;
            width: 50%;
          }
          
          /* Clear floats after the columns */
          .rrow:after {
            content: "";
            display: table;
            clear: both;
          }
          .the-table {
            padding: 20px; 
            border: 3px solid rgb(0, 0, 0);
          }
          table.the-table {
            table-layout: fixed;
          }
          .the-table th,
          .the-table td {
              padding: 15px 20px;
              width: 50%;
          }
          .border-left {
            border-left: 2px solid rgb(0, 0, 0);
          }
          .border-right {
            border-right: 2px solid rgb(0, 0, 0);
          }
          .very-small {
              font-size: 5px;
          }
          .small {
              font-size: 10px;
          }
          p {
              margin-bottom:5px;
              margin-top: 5px;
          }
          #ertl-number {
              text-align: right;
          }
          body {
                font-family: 'Open Sans', sans-serif;
                font-size: 10px;
                line-height: 1.5;
          }
          .times p,
          .times div {
                font-family: "Times New Roman", Times, serif !important;
          }
          .font-14 {
              font-size: 14px;
          }
          .font-10 {
              font-size: 10px;
          }
          .small {
              font-size: 9px;
          }

		  .date {
			margin: 0 auto;
			width: 70%;
			display: flex;
			justify-content: space-between;
		  }

		.listing-table {
		width: 100%;
		border-collapse: collapse; }
		
		.listing-table td, .listing-table th {
			border: 1px solid black;
			padding: 5px;
			height: 40px;
		}
		.width-15 {
			width: 15%;
		}
		.width-10 {
			width: 10%;
		}
		.width-17 {
			width: 17%;
		}
		width-20 {
			width: 20%;
		}
		width-50 {
			width: 50%;
		}

		.custom-d-flex {
			display: flex;
			justify-content: space-between;
		}
		.red-text {
			color: #da3b2f !important; }

        </style>
    </head>
    
    <body>
	<div class='times font-14'>
		<div class="" id="pageHeader-first"> 
			<img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png' width="100" height="110" /> 
		</div>
	<div class="text-center">
		<h3>${textInput.form1}</h3>
		<div class="date">
			<div class="x-margin">
				${textInput.illi}
				${formInput("illi")}
			</div>
			<div class="x-margin">
				${textInput.date}
				${formInput("date")}
			</div>
			<div class="x-margin">
				${textInput.submitted}
				${formInput("submitted")}
			</div>
		</div>
	</div>

	<h4>${textInput.title}</h4>
	<table class='listing-table'>
		<tbody>
			<tr>
				<th class="width-15">${textInput.tag}</th>
				<td colspan="5">${formInput("tag")}</td>
				<th class="width-10">${textInput.signed}</th>
				<td>${formInput("signed")}</td>
			</tr>
			<tr>
				<th>${textInput.street}</th>
			<td colspan="5">${formInput("address")}</td>
				<th>${textInput.expire}</th>
				<td>${formInput("expire")}</td>
			</tr>
			<tr>
				<th>${textInput.city}</th>
				<td>${formInput("city")}</td>
				<th style={{ width: '10%' }}>${textInput.state}</th>
				<td>${formInput("state")}</td>
				<th style={{ width: '10%' }}>${textInput.zip}</th>
				<td>${formInput("zip")}</td>
				<th>${textInput.renew}</th>
				<td>${formInput("renew")}</td>
			</tr>
			<tr>
				<th>${textInput.cross}</th>
				<td colspan="7">${formInput("cross")}</td>
			</tr>
		</tbody>
	</table>
	<br />

	<h4>${textInput.space_name}</h4>
	<table class='listing-table'>
		<thead>
			<tr>
				<th>${textInput.space_unit}</th>
				<th>${textInput.space_sf}</th>
				<th>${textInput.space_rent}</th>
				<th>${textInput.space_date}</th>
				<th>${textInput.space_lease}</th>
				<th>${textInput.space_nnn}</th>
				<th>${textInput.space_sub}</th>
				<th>${textInput.space_min}</th>
				<th>${textInput.space_max}</th>
				<th>${textInput.space_term}</th>
				<th>${textInput.space_prop}</th>
				<th>${textInput.space_bo}</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>${formInput("space_unit")}</td>
				<td>${formInput("space_sf")}</td>
				<td>${formInput("space_rent")}</td>
				<td>${formInput("space_date")}</td>
				<td>${formInput("space_lease")}</td>
				<td>${formInput("space_nnn")}</td>
				<td>${formInput("space_sub")}</td>
				<td>${formInput("space_min")}</td>
				<td>${formInput("space_max")}</td>
				<td>${formInput("space_term")}</td>
				<td>${formInput("space_prop")}</td>
				<td>${formInput("space_bo")}</td>
			</tr>
		</tbody>
	</table>

	<br />

	<h4>${textInput.property_name}</h4>
	<table class='listing-table'>
		<thead>
			<tr>
				<th>
					<div class="custom-d-flex">
						<span>${textInput.property_detail}</span>
						<span class='red-text'>${textInput.property_detailReq}</span>
					</div>
				</th>
				<th>
					<div class="custom-d-flex">
						<span>${textInput.property_amenity}</span>
						<span class='red-text'>${textInput.property_amenityReq}</span>
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr>
				<td>${formInput("property_detail")}</td>
				<td>${formInput("property_amenity")}</td>
			</tr>
		</tbody>
	</table>

	<br />

	<table class='listing-table'>
		<tbody>
			<tr>
				<th class="width-17">${textInput.property_parking}</th>
				<td>${formInput("property_parking")}</td>
			</tr>
		</tbody>
	</table>

	<br />

	<table class='listing-table'>
		<tbody>
			<tr>
				<th>${textInput.property_addition}</th>
			</tr>
			<tr>
				<td>${formInput("property_addition")}</td>
			</tr>
		</tbody>
	</table>

	<br />

	<h4>${textInput.contact_name}</h4>
	<table class='listing-table width-50'>
		<tbody>
            ${agentList.map(
                (agent, index) => `<tr>
                <th class="width-20">${textInput[`contact_agent${index + 1}`]}</th>
                <td>${formInput(`contact_agent${index + 1}`)}</td>
             </tr>`
            )}
		</tbody>
	</table>

	<br />
	<br />

	<div class="date">
		<span>${textInput.date2}</span>
		<span>${textInput.sig}</span>
	</div>


	<div style="page-break-after:always;"></div>

	<div class="" id="pageHeader-first"> 
			<img src='https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png' width="100" height="110" /> 
	</div>

	<div class="text-center">
		<h3>${textInput.form2}</h3>
		<div class="date">
			<div  class="x-margin">
				${textInput.illi}
				${formInput("illi")}
			</div>
			<div class="x-margin">
				${textInput.date}
				${formInput("date")}
			</div>
			<div class="x-margin">
				${textInput.submitted}
				${formInput("submitted")}
			</div>
		</div>
	</div>

	<br />
	<br />

	<h4>${textInput.project_name}</h4>
	<table class='listing-table'>
		<tbody>
			<tr>
				<th class="width-15">${textInput.project_project}</th>
				<td colspan="5">${formInput("project_project")}</td>
				<th class="width-10">${textInput.project_signed}</th>
				<td>${formInput("project_signed")}</td>
			</tr>
			<tr>
				<th>${textInput.street}</th>
				<td colspan="5">${formInput("street")}</td>
				<th>${textInput.expire}</th>
				<td>${formInput("expire")}</td>
			</tr>
			<tr>
				<th>${textInput.project_city}</th>
				<td>${formInput("project_city")}</td>
				<th class="width-10">${textInput.project_state}</th>
				<td>${formInput("project_state")}</td>
				<th class="width-10">${textInput.project_zip}</th>
				<td>${formInput("project_zip")}</td>
				<th>${textInput.project_renew}</th>
				<td>${formInput("project_renew")}</td>
			</tr>
			<tr>
				<th>${textInput.project_cross}</th>
				<td colspan="7">${formInput("project_cross")}</td>
			</tr>
		</tbody>
	</table>

	<br />
	<br />

	<h4>${textInput.contact2_name}</h4>
	<table class='listing-table'>
        <thead>
            <tr>
                <th></th>
                <th></th>
                <th>${textInput.contact2_com}</th>
				<td></td>
				<td></td>
				<th>${textInput.contact2_com2}</th>
            </tr>
        </thead>
		<tbody>
            ${agentList.map((agent, index) => `<tr>
                    <th>
                        ${textInput[`contact_agent${index + 1}`]}
                    </th>
                    <td></td>
                    <td>${formInput(`agent_com_${index + 1}`)}</td>
                    <th>${textInput.contact2_ref}</th>
                    <td></td>
                    <td></td>
                </tr>`
            )}
			
			<tr>
				<td></td>
				<th>${textInput.contact2_total}</th>
				<td>${formInput("total_com")}</td>
				<td></td>
				<td></td>
				<td></td>
			</tr>
		</tbody>
	</table>

	<br />
	<br />

	<div class="date">
		<span>${textInput.date2}</span>
		<span>${textInput.sig}</span>
	</div>
</div>
    </body>
    </html>
    `;

    return { html };
};
