import React, { useEffect, useState } from "react";
import Button from "../../../components/CustomButtons/Button";
import CustomRadio from "components/CustomRadio/CustomRadio";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import Notification from "../../../components/Notification/Notification";
import NotificationAsk from "../../../components/Notification/NotificationAsk";
import Loader from "../../../components/Loader/Loader";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Table from "components/Table/Table.jsx";
import { IconButton } from "@material-ui/core";
import CustomInput from "components/CustomInput/CustomInputValidate";
import { getUser } from "../../../shared/authValidation";
import { Dialog } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import moment from "moment";
import { Tooltip } from "@material-ui/core";

const statusDescriptions = {
    0: 'Not Yet Reviewed',
    1: 'Denied',
    2: 'Approved'
}

const InternalReview = (props) => {
    const user = getUser();
    const { updateDataObj, data, readonly } = props;
    const [msg, setMsg] = useState('');
    const [otherUsers, setOtherUsers] = useState([]);
    const [internalReviewers, setInternalReviewers] = useState([]);
    const [filter, setFilter] = useState('');
    const [askDeleteReview, setAskDeleteReview] = useState(null);
    const [openedAddUser, setOpenedAddUser] = useState(false);
    const [updatedRsheet, setUpdatedRsheet] = useState(false);
    const [updatedRsheetAndClose, setUpdatedRsheetAndClose] = useState(false);

    useEffect(() => {
        if (props.internal_reviewers_choices) {
            setOtherUsers(props.internal_reviewers_choices.filter(irc => {
                if (irc.id === user.id) return false;
                if (!irc.review) return true;
            }));
            setInternalReviewers(props.internal_reviewers_choices.filter(irc => {
                if (irc.id === user.id) return false;
                if (irc.review) return true;
            }));
        }
    }, [props.internal_reviewers_choices]);

    useEffect(() => {
        if (props.success_crud) {
            if (updatedRsheet) {
                if (props.success_crud === 'successfully updated') {
                    setMsg('Updated routing sheet');
                } 
                setUpdatedRsheet(false);
            }
            if (updatedRsheetAndClose) {
                if (props.success_crud === 'successfully updated') {
                    setMsg('Updated routing sheet');
                }
            }
        }
    }, [props.success_crud]);

    useEffect(() => {
        if (data.id) {
            props.getRsInternalReviewers(data.id);
            props.getRsHistories(data.id);
        }
    }, []);

    console.log('internalReviewers', internalReviewers);

    return <div>
        {props.internal_reviewers_loading && <div><Loader /></div>}

        {!props.internal_reviewers_loading && <div>
            <CustomRadio
                label="Require Internal Review?,Yes,No"
                options={['yes','no']}
                onChange={(e) => {
                    if (!data.id) {
                        return setMsg('Please save routing sheet first');
                    }
                    updateDataObj('require_internal_review', e.target.value);
                    if (e.target.value === 'yes' && data.id) {
                        // load users
                        props.getRsInternalReviewers(data.id);
                    }
                    if (data.id) {
                        setTimeout(() => {
                            const updateObj = {
                                id: data.id,
                                json_data: JSON.stringify(data)
                            };
                            props.updateRsheet(updateObj);
                        }, 500);
                    }
                }}
                value={data.require_internal_review}
                readonly={readonly ? true : false}
            />
            {data.require_internal_review === 'yes' && <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Button color="primary" style={{marginRight:20}} onClick={() => {
                        setOpenedAddUser(true);
                    }}><i className="fa fa-plus"></i> ADD NEW REVIEWER</Button>
                    {(data.id && internalReviewers.length > 0 && props.rsheetData.status !== 11) && <Button
                        color="white"
                        onClick={() => {
                            const recordHistory = {
                                status: 11,
                                json_data: data,
                                rs_id: data.id
                            }
                            const updateObj = {
                                id: data.id,
                                json_data: JSON.stringify(data),
                                status: 11,
                                project_name: data.project_name
                            };
                            setUpdatedRsheetAndClose(true);
                            props.updateRsheet(updateObj, null, recordHistory);
                        }}
                    >SET ROUTING SHEET FOR INTERNAL REVIEW</Button>}
                    <div className="boxed" style={{marginTop:20}}>
                        <h4>Internal Reviewer(s)</h4>
                        <Table
                            tableHeaderColor="primary"
                            tableHead={['Name / Email','Status','Date / Time','Notes','Actions']}
                            tableData={internalReviewers.map((ir,x) => {
                                let note = '';
                                if (props.history_list) {
                                    props.history_list.forEach(hl => {
                                        if (hl.user_id === ir.id) {
                                            note = hl.notes ? hl.notes : '';
                                        }
                                    });
                                }
                                const ir_review = ir.review ? statusDescriptions[ir.review.status] : '';
                                let resubmitBtn = null;
                                if (ir_review) {
                                    if (ir_review === 'Denied') {
                                        resubmitBtn = <Tooltip title="Re-submit" placement="top"><IconButton
                                            onClick={() => {
                                                props.createRsInternalReviewer({
                                                    id: ir.review.id,
                                                    rs_id: data.id,
                                                    user_id: ir.review.user_id,
                                                    status: 0
                                                });
                                                const updateObj = {
                                                    id: data.id,
                                                    json_data: JSON.stringify(data),
                                                    status: 11,
                                                    project_name: data.project_name
                                                };
                                                setUpdatedRsheetAndClose(true);
                                                props.updateRsheet(updateObj, null, null);
                                            }}
                                        >
                                            <i className="fa fa-envelope"></i>
                                        </IconButton></Tooltip>
                                    } 
                                }
                                return [
                                    <div>{`${x+1}. ${ir.name} - ${ir.mail}`}</div>,
                                    ir_review,
                                    moment(ir.review.timestamp.replace("T", " ")).subtract(8, 'h').format("MM/DD/YYYY h:mm a"),
                                    note,
                                    <div>
                                        <Tooltip title="Remove" placement="top">
                                            <IconButton
                                                style={{marginRight:20}}
                                                onClick={() => {
                                                    setAskDeleteReview({
                                                        rs_id: data.id,
                                                        id: ir.review.id
                                                    });
                                                }}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </IconButton>
                                        </Tooltip>
                                        {resubmitBtn}
                                    </div>
                                ];
                            })}
                            coloredColls={[1]}
                            colorsColls={["primary"]}
                        />
                        {internalReviewers.length === 0 && <div><em>* No Reviewers *</em></div>}
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {openedAddUser && <Dialog
                        open={openedAddUser}
                        keepMounted  
                        maxWidth="md" 
                        fullWidth={true}
                    >
                        <div className="close-btn" onClick={() => { setOpenedAddUser(false) }}><Close /></div>
                        <div style={{padding:20,marginTop:60}} className="text-center">
                            <h4>Other Users</h4>
                            <CustomInput
                                label="Search Users"
                                value={filter}
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                            />
                            <div style={{height:500, overflow:'scroll'}} className="hover-row-gray">
                                <Table
                                    tableHeaderColor="primary"
                                    tableHead={['Name','Add']}
                                    tableData={otherUsers.filter(ou => {
                                        if (!filter) return true;
                                        if (filter) {
                                            const lowercaseName = ou.name.toLowerCase();
                                            if (lowercaseName.indexOf(filter.toLowerCase()) !== -1) {
                                                return true;
                                            }
                                        }
                                    }).map((or,x) => {
                                        return [
                                            `${x+1}. ${or.name} - ${or.mail} - ${or.type}`,
                                            <IconButton
                                                onClick={() => {
                                                    props.createRsInternalReviewer({
                                                        rs_id: data.id,
                                                        user_id: or.id
                                                    });
                                                    setOpenedAddUser(false)
                                                }}  
                                            >
                                                <i className="fa fa-plus"></i>
                                            </IconButton>
                                        ];
                                    })}
                                    coloredColls={[1]}
                                    colorsColls={["primary"]}
                                />
                            </div>
                        </div>
                    </Dialog>}
                </GridItem>
            </GridContainer>}
            {!data.require_internal_review && <div className="red-text"><small>Required</small></div>}
        </div>}

        {msg && <Notification 
            message={msg}
            open={msg ? true : false}
            close={() => {
                setMsg('');
                if (updatedRsheetAndClose) {
                    setUpdatedRsheetAndClose(false);
                    props.close();
                }
            }}
        />}

        {askDeleteReview && <NotificationAsk 
            title="Are you sure?"
            message="Do you want to remove this reviewer"
            success={() => {
                props.deleteRsInternalReviewer(askDeleteReview);
                setAskDeleteReview(null);
            }}
            open={askDeleteReview ? true : false}
            close={() => {
                setAskDeleteReview(null);
            }}
        />}
    </div>
}

const mapStateToProps = state => {
    return {
        error_reviewers: state.rs.error_reviewers,
        success_reviewers: state.rs.success_reviewers,
        internal_reviewers_choices: state.rs.internal_reviewers_choices,
        internal_reviewers_loading: state.rs.internal_reviewers_loading,
        success_crud: state.rs.success_crud,
        history_list: state.rs.history_list,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRsInternalReviewers: (data) => {
            dispatch(actions.getRsInternalReviewers(data));
        },
        createRsInternalReviewer: (data) => {
            dispatch(actions.createRsInternalReviewer(data));
        },
        deleteRsInternalReviewer: (data) => {
            dispatch(actions.deleteRsInternalReviewer(data));
        },
        updateRsheet: (params, invoiceData = null, recordHistory) => {
            dispatch(actions.updateRsheet(params, invoiceData, recordHistory));
        },
        getRsHistories: (data) => {
            dispatch(actions.getRsHistories(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InternalReview);