import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import ERTLelement from "./ERTLelement";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { clone } from "../../../shared/utility";

const ERTLTab4 = (props) => {
    const { ertlFormData, updateData, setErtlFormData } = props;

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
                <h3>Other Data</h3>
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="made_by_and_between"
                    update={updateData}
                />
                <small>
                    * e.g. Riverside Oaks, LP, a CA Limited Partnership and
                    Richard Kaufman Revocable Trust,
                </small>
            </GridItem>
            <GridItem xs={4} sm={6} md={6}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="name_of_place"
                    update={updateData}
                />
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="total_sqft_footage"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={4} sm={6} md={6}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="total_current_vacant_space"
                    update={updateData}
                />
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="minimum_suite_size_available_for_lease"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                    field="initial_term_begin"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                    field="initial_term_expiration"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="approved_lease_terms"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <ERTLelement
                    ertlFormData={ertlFormData}
                    field="approved_lease_terms_lease_type"
                    update={updateData}
                />
            </GridItem>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
                <CustomSelect 
                    options={props.approvers ? props.approvers.map(a => {
                        return a.name;
                    }) : []}
                    default={ertlFormData.final_approver}
                    choose={(e) => {
                        console.log('choosing', e);
                        const newData = clone(ertlFormData);
                        let obj = null;
                        if (props.approvers) {
                            props.approvers.forEach(pa => {
                                if (pa.name === e) {
                                    obj = pa;
                                }
                            })
                        }
                        if (obj) {
                            newData.final_approver = obj.name;
                            newData.final_approver_email = obj.email;
                            newData.final_approver_phone = obj.phone;
                            newData.final_approver_title = obj.title;
                        }
                        setErtlFormData(newData);
                    }}
                    label="Select a Final Approver"
                />
                {!ertlFormData.final_approver && <div style={{color:'red'}}>Required</div>}
                {ertlFormData.final_approver && <div>
                    Email : <strong>{ertlFormData.final_approver_email}</strong><br />
                    Phone : <strong>{ertlFormData.final_approver_phone}</strong><br />
                    Title : <strong>{ertlFormData.final_approver_title}</strong><br />
                </div>}
            </GridItem>
        </GridContainer>
    );
};

export default ERTLTab4;
