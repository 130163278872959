import React, {useEffect, useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import PropTypes from 'prop-types';

const CustomDialog = (props) => {
	const [choice, setChoice] = useState(null);
	const {indx, desc, open, close, title, options: opts, label, current, routing} = props;

	const options = choice
		? props.options
				.filter((o) => {
					if (choice) {
						if (props.desc) {
							const l = o[props.desc].toLowerCase();
							const c = choice.toLowerCase();
							if (l.indexOf(c) === 0) {
								
								return true;
							}
						} else {
							const l = o.toLowerCase();
							const c = choice.toLowerCase();
							if (l.indexOf(c) === 0) {
								
								return true;
							}
						}
					}
					return false;
				})
				.sort()
		: props.options;

	

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
				<DialogTitle>
					<div style={{textAlign: 'center'}}>{props.title}</div>
				</DialogTitle>

				<div style={{textAlign: 'center', padding: 20}}>
					<CustomInput
						label={props.label}
						value={choice}
						onChange={(e) => {
							setChoice(e.target.value);
						}}
					/>

					{options && (
						<div style={{height: 300, overflowY: 'scroll', padding: 10, backgroundColor: '#f1f1f1'}}>
							<List component='nav'>
								{options.map((o, i) => {
									
									const desc = props.desc ? o[props.desc] : o;
									return (
										<ListItem
											button
											key={props.indx ? `${o[indx]}-${i}` : `${o}-${i}`}
											onClick={() => {
												if (props.indx) {
													return props.choose(o[indx]);
												}
												props.choose(o);
											}}
										>
											{props.icon === 'person' && (
												<ListItemIcon>
													<Person />
												</ListItemIcon>
											)}
											{props.icon !== 'person' && props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}
											<ListItemText
												inset
												primary={
													routing && routing === 'property'
														// ? `${o.name} ${o.street1 !== '' ? ` - ${o.street1}` : ''}${o.city !== '' ? `, ${o.city}` : ''}${o.state !== '' ? `, ${o.state}` : ''} ${o.zip}`
														? `${o.name}`
														: routing && routing === 'contact'
														? `${o.first_name} ${o.last_name}${o.company_name ? ` - ${o.company_name}` : ''}`
														: routing && routing === 'lease'
														? `Lease #${o.id}: ${new Date(o.start_date).toLocaleDateString('en-US', {dateStyle: 'short'})} - ${new Date(o.expiration_date).toLocaleDateString('en-US', {dateStyle: 'short'})}`
														: desc
												}
											/>
											<ListItemSecondaryAction>{current === desc && <Star />}</ListItemSecondaryAction>
										</ListItem>
									);
								})}
							</List>
						</div>
					)}

					<Button
						color='white'
						style={{marginRight: 10, marginTop: 20}}
						onClick={() => {
							props.close();
						}}
					>
						CLOSE
					</Button>
				</div>
			</Dialog>
		</div>
	);
};

CustomDialog.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	choose: PropTypes.func.isRequired,
	indx: PropTypes.string,
	desc: PropTypes.string,
	current: PropTypes.string
};

export default CustomDialog;
