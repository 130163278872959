import React, { useState } from "react";
import GridContainer from "../../components/Grid/GridContainer";
import GridItem from "../../components/Grid/GridItem";
import originalTextData from "./Templates/TRTemplatesModel"; // default template text
import { get } from "lodash";
import { createTenantName, getDifferences } from "./Entries/TREntriesLogic";
import { commatize } from "../../shared/utility";
import { fields } from "./Entries/TREntriesModel";
import { addDashes } from "../../shared/utility";
import { defaultTenantData } from "./Entries/TREntriesModel";

export const TRBaseTemplate = props => {
    const [shouldShow, setShouldShow] = useState(true);
    const { 
        data,        // entry data
        templateData, // after - template data
        templateText, // before - template data
        openModal
    } = props;
    const template = get(data, 'template', {});

    const textData = templateData;

    const text = {};
    Object.keys(textData).forEach((f) => {
        text[f] = `${textData[f]}`;
    });

    const textInputs = {};
    const style =
        props.origin === "view"
            ? {}
            : { borderBottom: "1px solid rgb(251 251 251)" };

    let changedSections = [];
    if (props.historyDiffData) {
        props.historyDiffData.forEach((hdd) => {
            if (hdd) {
                if (hdd.templateDataChanges) {
                    if (Object.keys(hdd.templateDataChanges).length > 0) {
                        Object.keys(hdd.templateDataChanges).forEach((hddt) => {
                            if (changedSections.indexOf(hddt) === -1) {
                                changedSections.push(hddt);
                            }
                        });
                    }
                }
            }
        });
    }

    const getHistoryDiffs = (history, f, shouldShow) => {
        console.log('orig -> get', history, f, shouldShow)
        const author = props.origin === "approver" ? "Approver" : "";
        if (changedSections.indexOf(f) === -1) {
            return getDifferences(
                textData[f],
                templateText[f],
                shouldShow,
                author
            );
        }
        const lastHistory =
            history.length > 0 ? history[history.length - 1] : null;

        return (
            <div>
                {(props.origin === "edit" || props.origin === "approver") &&
                    getDifferences(
                        lastHistory ? lastHistory.templateText[f] : textData[f],
                        templateText[f],
                        shouldShow,
                        author
                    )}
                {history
                    .map((h, i) => {
                        if (h.templateDataChanges) {
                            if (
                                Object.keys(h.templateDataChanges).length === 0
                            ) {
                                return null;
                            }
                        }
                        let previous = textData[f];
                        if (i !== 0) {
                            previous = history[i - 1].templateText[f];
                        }
                        return (
                            <div
                                key={`${i}-history`}
                                className="tiny-boxed parentable"
                            >
                                <small className="gray-text author-block">
                                    {formatDate3(h.created_date)} MODIFIED BY{" "}
                                    {h.author}
                                </small>
                                {getDifferences(
                                    previous,
                                    h.templateText[f],
                                    shouldShow,
                                    h.author
                                )}
                            </div>
                        );
                    })
                    .reverse()}
            </div>
        );
    };

    Object.keys(originalTextData).forEach((f) => {
        if (f === "additional_signatures" || f === "additional_initials") {
        } else {
            let val = !props.historyDiffData
                ? getDifferences(templateText[f], textData[f], shouldShow)
                : "";
            if (!val) {
                val = originalTextData[f] ? originalTextData[f] : '';
            }
            const historyVal = props.historyDiffData
                ? getHistoryDiffs(props.historyDiffData, f, shouldShow)
                : "";
            textInputs[f] = (
                <span
                    key={`input-${f}`}
                    style={style}
                    onClick={() => {
                        if (props.templateOnClick) {
                            return props.templateOnClick(f);
                        }
                        if (props.origin !== "view") {
                            if (
                                props.action === "Edit" ||
                                props.action === "Add"
                            )
                                openModal(f);
                        }
                    }}
                    className="hoverable gray-hover bluish-hover text-input"
                >
                    {val}
                    {historyVal}
                </span>
            );
        }
    });

    const formInput = (
        field,
        addDollarSign,
        addComma,
        addPercent,
        addTheDashes
    ) => {
        let fieldSplit = field.split(".");
        let newField =
            fieldSplit.length > 1
                ? data[fieldSplit[0]][fieldSplit[1]]
                : data[field];
        const model =
            fieldSplit.length > 1
                ? fields[fieldSplit[0]][fieldSplit[1]]
                : fields[field];
        if (props.action === "template") {
            return (
                <span className="field-gray">{model ? model.label : ""}</span>
            );
        }
        if (addTheDashes) {
            return (
                <span
                    className={`hovered line-block ${
                        newField ? "green-block" : "red-block"
                    }`}
                    style={{
                        display: "inline-block",
                        margin: "10px 10px 0",
                        minWidth: 100,
                        minHeight: 30,
                        textIndent: 0
                    }}
                    onClick={() => {
                        if (props.openDataModal) props.openDataModal(field);
                    }}
                >
                    {!newField && (
                        <span className="gray-text">
                            {model ? model.label : ""}
                        </span>
                    )}
                    {newField && (
                        <span>
                            <strong>{addDashes(newField)}</strong>
                        </span>
                    )}
                </span>
            );
        }
        return (
            <span
                className={`hovered line-block ${
                    newField ? "green-block" : "red-block"
                }`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                    textIndent: 0
                }}
                onClick={() => {
                    if (props.openDataModal) props.openDataModal(field);
                }}
            >
                {!newField && (
                    <span className="gray-text">
                        {model ? model.label : ""}
                    </span>
                )}
                {newField && (
                    <span>
                        <strong>
                            {addDollarSign ? "$ " : ""}
                            {addComma
                                ? `${commatize(newField)}${
                                      addPercent ? "%" : ""
                                  }`
                                : `${newField}${addPercent ? "%" : ""}`}
                        </strong>
                    </span>
                )}
            </span>
        );
    }

    // textInputs = React elements
    // textData = raw template data in JSON form, from Template Data chosen
    console.log('textInputs', textInputs, textData);

    const renderText = (field) => {
        let content = textInputs[field] ? textInputs[field] : '';
        if (!content) {
            if (originalTextData) {
                if (originalTextData[field]) {
                    content = originalTextData[field];
                }
            }
        }
        return content;
    };

    const tenants = data.tenants.length > 0 ? data.tenants : [defaultTenantData];

    const tenant = tenants[0] ? tenants[0] : null;

    const goToStep = (step) => {
        if (props.setTab && props.setStep) {
            props.setTab('wizard');
            props.setStep(step);
        }
    }

    return <div>
        <div className="boxed">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{padding: '50px 120px'}}>
                        <div className="text-center">
                            <h2>THANK YOU LETTER</h2>
                        </div>
                        <div>

                            <img
                                src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                                style={{ width: "100px", height: "110px" }}
                            />
                            <br /><br />
                            <span className="hovered line-block green-block width-100">
                                Thank You Date
                            </span>

                            {tenant && <p>
                                {createTenantName(tenant)}<br />
                                {tenant.address}<br />
                                {tenant.city}, {tenant.state} {tenant.zip}<br />
                            </p>}

                        </div>

                        <div style={{fontSize: '18px', lineHeight: '25px', marginTop: '100px'}}>
                            <p style={{marginBottom: '25px'}}><strong>{textInputs.ty_letter_page_1_title}</strong></p>
                            <p style={{marginBottom: '25px'}}>
                                {textInputs.ty_letter_page_1_dear} 
                                <span
                                    onClick={() => { goToStep(1) }} 
                                    className="hovered line-block green-block width-100">{tenants.map(t => t.first_name).join(', ')}</span>
                            </p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_body1}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_body2}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_body3}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_body4} <span
                                onClick={() => { goToStep(3) }}
                            >{formInput('final_approver_email')}</span> {textInputs.ty_letter_page_1_body5}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_closing}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.ty_letter_page_1_closing_company}</p>
                            <div style={{height: '100px'}}></div>
                            <span onClick={() => { goToStep(3) }}>{formInput('final_approver_name')}</span>
                            <br/>
                            <span onClick={() => { goToStep(3) }}>{formInput('final_approver_position')}</span>
                        </div>

                        <div style={{marginTop: '100px'}}>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div>{textInputs.ty_letter_page_1_address1}</div>
                                <div style={{width: '4px', height: '4px', background: 'silver', borderRadius: '100%', margin: '0 5px'}}/>
                                <div>{textInputs.ty_letter_page_1_address2}</div>
                                <div style={{width: '4px', height: '4px', background: 'silver', borderRadius: '100%', margin: '0 5px'}}/>
                                <div>{textInputs.ty_letter_page_1_city_state_zip}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div>{textInputs.ty_letter_page_1_phone}</div>
                                <div style={{width: '4px', height: '4px', background: 'silver', borderRadius: '100%', margin: '0 5px'}}/>
                                <div>{textInputs.ty_letter_page_1_fax}</div>
                            </div>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div>{textInputs.ty_letter_page_1_website}</div>
                            </div>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
        
        {/* MAIN PAGE 1 */}
        <div className="boxed">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{padding: '50px 120px'}}>
                        <div className="text-center">
                            <h2>LISTING AGREEMENT</h2>
                        </div>
                        <div>
                            <img
                                src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                                style={{ width: "100px", height: "110px" }}
                            />
                        </div>
                        <div className="text-center">{textInputs.main_page_1_address}</div>
                        <h4 className="text-center" style={{fontSize: '25px', margin: '25px'}}><strong>{textInputs.main_page_1_title}</strong></h4>
                        <div style={{fontSize: '14px', lineHeight: '25px', textAlign: 'justify'}}>
                            <p style={{marginBottom: '25px'}}>
                                <span className="hovered line-block green-block width-100" onClick={() => { goToStep(2) }}>
                                    {tenants.map(t => {
                                        return createTenantName(t);
                                    }).join(', ')}
                                </span> {textInputs.main_page_1_opening} {formInput('period_commencing')} {textInputs.main_page_1_opening_2} {formInput('period_ending_on')} {textInputs.main_page_1_opening_3}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_property}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req1} {formInput('type_of_property')}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req2} {formInput('location')}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req3}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req4}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req5}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req6}</p>
                            <p style={{marginBottom: '25px'}}>{textInputs.main_page_1_req7}</p>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>

        {/* MAIN PAGE 2 */}
        <div className="boxed">
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{padding: '50px 120px'}}>
                        <div>
                            <img
                                src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                                style={{ width: "100px", height: "110px" }}
                            />
                        </div>
                        <div className="text-center" style={{marginBottom:100}}>{textInputs.main_page_1_address}</div>
                        <div style={{fontSize: '14px'}}>
                            <GridContainer>
                                <GridItem xs={12} sm={6} md={6}>
                                    <div className="boxed">
                                        <p>
                                            {textInputs.main_page_2_broker}<br />
                                            {textInputs.main_page_2_broker_1}
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_by}
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_name_printed} 
                                            <span onClick={() => { goToStep(3) }}>{formInput('final_approver_name')}</span>
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_title} 
                                            <span onClick={() => { goToStep(3) }}>{formInput('final_approver_position')}</span>
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_address} <span className="underlined">{textInputs.main_page_2_broker_address_1}</span>
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_city_state_zip} <span className="underlined">
                                                {textInputs.main_page_2_broker_city_state_zip_1}
                                                </span>
                                        </p>
                                        <p>
                                            {textInputs.main_page_2_broker_phone} <span className="underlined">
                                                {textInputs.main_page_2_broker_phone_1}
                                            </span>
                                        </p>
                                        <p>
                                            <span onClick={() => { goToStep(3) }}>Email: {formInput('final_approver_email')}</span>
                                        </p>
                                    </div>
                                </GridItem>
                                {tenants.map((t,i) => {
                                    const tenantName = createTenantName(t);
                                    return <GridItem xs={12} sm={6} md={6} key={`tenant-${i}`}>
                                        <div className="boxed hovered" onClick={() => { goToStep(2) }}>
                                            <p>
                                                {textInputs.main_page_2_tenant}<br />
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_by}
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_name_printed} <span className="hovered line-block green-block width-100">{tenantName}</span>
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_title} <span className="hovered line-block green-block width-100">{t.title}</span>
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_address} <span className="hovered line-block green-block width-100">{t.address}</span>
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_city_state_zip} 
                                                <span className="hovered line-block green-block width-100">{t.city}, {t.state} {t.zip}</span>
                                            </p>
                                            <p>
                                                {textInputs.main_page_2_tenant_phone} 
                                                <span className="hovered line-block green-block width-100">{t.phone}</span>
                                            </p>
                                            <p>
                                                Email: <span className="hovered line-block green-block width-100">{t.email}</span>
                                            </p>
                                        </div>
                                    </GridItem>
                                })}
                            </GridContainer>
                            <br />
                            <br />
                            <p>{textInputs.main_page_2_legal}</p>
                        </div>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </div>
}