import { Dialog, DialogActions, DialogContent, DialogTitle } from "@material-ui/core";
import { Close } from '@material-ui/icons';
import React from "react";

const PropertiesLoopnetDialog = (props) => {
    const { open, onClose, src } = props;
    return (
        <Dialog open={open} onClose={onClose} keepMounted maxWidth={false} fullWidth={true}>
            <DialogActions>
					<Close className='hoverable' style={{marginTop: '.7rem'}} onClick={onClose} />
				</DialogActions>
            <DialogTitle>Loopnet Property</DialogTitle>
            <DialogContent>
                <div>
                    <iframe
                        src={src}
                        frameBorder="0"
                        width='100%'
                        height='100%'
                        style={{minHeight: 'calc(100vh - 245px)'}}
                    ></iframe>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default PropertiesLoopnetDialog;
