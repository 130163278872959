import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

import { get, isEmpty, isEqual, clone } from 'lodash';

import { Close } from '@material-ui/icons';
import { Dialog, DialogTitle, Checkbox } from '@material-ui/core'

import Button from "components/CustomButtons/Button.jsx";
import Loader from "components/Loader/Loader";

const SaveBack = (props) => {
    const [theData, setTheData] = useState([]);

    // const ChangesDataLoading = () => (
    //     <>
    //         {props.contactChangesLoading && <tr><td></td><td></td><td><FadeLoader /></td><td></td></tr>}
    //         {props.propertyChangesLoading && <tr><td></td><td></td><td><FadeLoader /></td><td></td></tr>}
    //         {props.companyChangesLoading && <tr><td></td><td></td><td><FadeLoader /></td><td></td></tr>}
    //     </>
    // )

    useEffect(() => {
        // load DB data
        // load new data
        let newData = [];
        if (props.contactChangesData) {
            props.contactChangesData.forEach((ccd,i) => {
                newData.push({
                    type: 'Contact',
                    before: <div key={`cd1-a-${i}`}>
                        {Object.keys(ccd.before).map((cdb, j) => {
                            const style = { color: 'black' };
                            if (ccd.before[cdb] !== ccd.after[cdb]) {
                                style.color = 'red';
                            }
                            const field = cdb.replace('_',' ').toUpperCase();
                            if (cdb === 'id') return <div key={`cd1-${j}-${i}`}></div>
                            return <div key={`cd1-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{ccd.before[cdb]}</span></div>
                        })}
                    </div>,
                    after: <div key={`cd1-b-${i}`}>
                        {Object.keys(ccd.after).map((cdb, j) => {
                            const style = { color: 'black' };
                            if (ccd.before[cdb] !== ccd.after[cdb]) {
                                style.color = 'red';
                            }
                            const field = cdb.replace('_',' ').toUpperCase();
                            if (cdb === 'id') return <div key={`cd2-${j}-${i}`}></div>
                            return <div key={`cd2-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{ccd.after[cdb]}</span></div>
                        })}
                    </div>,
                    ccd,
                    checked: false,
                    id: get(ccd, 'after.id', null)
                });
            });
        }
        if (props.companyChangesData) {
            props.companyChangesData.forEach((ccd,i) => {
                newData.push({
                    type: 'Company',
                    before: <div key={`cd1-a-${i}`}>
                        {Object.keys(ccd.before).map((cdb, j) => {
                            const style = { color: 'black' };
                            if (ccd.before[cdb] !== ccd.after[cdb]) {
                                style.color = 'red';
                            }
                            const field = cdb.replace('_',' ').toUpperCase();
                            if (cdb === 'id') return <div key={`cd1-${j}-${i}`}></div>
                            return <div key={`cd1-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{ccd.before[cdb]}</span></div>
                        })}
                    </div>,
                    after: <div key={`cd1-b-${i}`}>
                        {Object.keys(ccd.after).map((cdb, j) => {
                            const style = { color: 'black' };
                            if (ccd.before[cdb] !== ccd.after[cdb]) {
                                style.color = 'red';
                            }
                            const field = cdb.replace('_',' ').toUpperCase();
                            if (cdb === 'id') return <div key={`cd2-${j}-${i}`}></div>
                            return <div key={`cd2-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{ccd.after[cdb]}</span></div>
                        })}
                    </div>,
                    ccd,
                    checked: false,
                    id: get(ccd, 'after.id', null)
                });
            });
        }
        if (props.propertyChangesData) {
            props.propertyChangesData.forEach((pcd,i) => {
                newData.push({
                    type: 'Property',
                    before: <div key={`pcd1-a-${i}`}>
                        {Object.keys(pcd.before).map((pdb, j) => {
                            const style = { color: 'black' };
                            if (pcd.before[pdb] !== pcd.after[pdb]) {
                                style.color = 'red';
                            }
                            const field = pdb.replace('_',' ').toUpperCase();
                            if (pdb === 'id') return <div key={`pcd1-${j}-${i}`}></div>
                            return <div key={`pcd1-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{pcd.before[pdb]}</span></div>
                        })}
                    </div>,
                    after: <div key={`pcd1-b-${i}`}>
                        {Object.keys(pcd.after).map((pdb, j) => {
                            const style = { color: 'black' };
                            if (pcd.before[pdb] !== pcd.after[pdb]) {
                                style.color = 'red';
                            }
                            const field = pdb.replace('_',' ').toUpperCase();
                            if (pdb === 'id') return <div key={`pcd2-${j}-${i}`}></div>
                            return <div key={`pcd2-${j}-${i}`}><strong>{field}</strong>: <span style={style}>{pcd.after[pdb]}</span></div>
                        })}
                    </div>,
                    pcd,
                    checked: false,
                    id: get(pcd, 'after.id', null)
                });
            })
        }
        newData = newData.filter((obj) => {
            const before = get(obj, 'ccd.before', {});
            const after = get(obj, 'ccd.after', {});

            return !isEqual(before, after);
        })
        setTheData(newData);
    }, [props.contactChangesData, props.companyChangesData, props.propertyChangesData]);

    let hasChecked = false;
    theData.forEach(td => {
        if (td.checked) hasChecked = true;
    })

    const rand = () => {
        return (Math.random() + 1).toString(36).substring(7);
    }

    if (isEmpty(theData)) return null;

    const changesData = get(props, 'data.changesDataDeclined', null);

    return (
        <Dialog
            open={props.open}
            keepMounted
            onClose={props.close}
            maxWidth={false}
            fullWidth={true}
        >
            <DialogTitle>
                <div className="close-btn" onClick={() => { props.close() }}><Close /></div>
                <div style={{textAlign:'center'}}></div>
            </DialogTitle>

            <div style={{padding:20}}>
                <h2 style={{textAlign:'center'}}>CRM Change Detected</h2>
                <p style={{textAlign:'center'}}>The following have Changed. Please check the checkbox of what you want to update and click Save.</p>
                <hr />
                <table style={{margin:'0 auto'}}>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>Before</th>
                            <th>Changed</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {theData.map((d,i) => {
                            console.log('changesDat', changesData, d);
                            if (changesData && d.id) {
                                if (d.type === 'Contact') {
                                    if (changesData.contactChanges) {
                                        if (changesData.contactChanges.indexOf(d.id) !== -1) {
                                            return null;
                                        }
                                    }
                                } else if (d.type === 'Company') {
                                    if (changesData.companyChanges) {
                                        if (changesData.companyChanges.indexOf(d.id) !== -1) {
                                            return null;
                                        }
                                    }
                                } else if (d.type === 'Property') {
                                    if (changesData.companyChanges) {
                                        if (changesData.companyChanges.indexOf(d.id) !== -1) {
                                            return null;
                                        }
                                    }
                                }
                            }
                            return (
                                <>
                                    {/* <ChangesDataLoading /> */}
                                    <tr key={`x-chrow-${i}-`}>
                                        <td key={`xcheckboxsy-${i}-`} style={{padding:10}}>
                                            {d.type}
                                        </td>
                                        <td key={`echeckboxsa-${i}-`} style={{padding:10}}>
                                            {d.before}
                                        </td>
                                        <td key={`rcheckboxsb-${i}-`} style={{padding:10}}>
                                            {d.after}
                                        </td>
                                        <td key={`vcheckboxs-${i}-`} style={{filter:'grayscale(1)'}}>
                                            <Checkbox
                                                key={`checkbox-${i}-`}
                                                checked={d.checked}
                                                onChange={() => {
                                                    const newTheData = clone(theData);
                                                    console.log('the new data 1', theData);
                                                    newTheData[i].checked = !newTheData[i].checked;
                                                    console.log('the new data 2', newTheData);
                                                    setTheData(newTheData);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                </>
                            )
                        })}
                    </tbody>
                </table>
                <div className="text-center">
                    {hasChecked && <Button
                        color="rose"
                        style={{marginRight:10}}
                        onClick={() => {
                            let forUpdating = []; // contacts
                            let forUpdatingProperty = []; // properties
                            let forUpdatingCompany = []; // companies
                            theData.forEach(td => {
                                if (td.checked) {
                                    if (td.type === 'Contact') {
                                        forUpdating.push(td.ccd.after);
                                    }
                                    if (td.type === 'Property') {
                                        forUpdatingProperty.push(td.pcd.after);
                                    }
                                    if (td.type === 'Company') {
                                        forUpdatingCompany.push(td.ccd.after);
                                    }
                                }
                            })
                            if (forUpdating.length > 0) props.saveContactChanges(forUpdating);
                            if (forUpdatingProperty.length > 0) props.savePropertyChanges(forUpdatingProperty);
                            if (forUpdatingCompany.length > 0) props.saveCompanyChanges(forUpdatingCompany);

                            if (props.closeEverything) props.closeEverything();
                            props.close();
                        }}
                        className="text-center"
                    >
                        SAVE
                    </Button>}
                    <Button
                        color="white"
                        style={{marginRight:10}}
                        onClick={() => {
                            props.close();
                        }}
                        className="text-center"
                    >
                        CLOSE
                    </Button>
                    <Button
                        color="danger"
                        style={{marginRight:10}}
                        onClick={() => {
                            props.decline()
                        }}
                        className="text-center"
                        disabled={props.contactChangesLoading || props.propertyChangesLoading || props.companyChangesLoading}
                    >
                        DECLINE
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

const mapStateToProps = state => {
    const c = state.crm_contacts;
    const p = state.crm_properties;
    const m = state.crm_companies;
    return {
        error: c.contactChangesError,
        success: c.contactChangesSuccess,
        p_error: p.propertyChangesError,
        p_success: p.propertyChangesSuccess,
        contactChangesData: c.contactChangesData,
        propertyChangesData: p.propertyChangesData,
        companyChangesData: m.companyChangesData,
        contactChangesLoading: c.contactChangesLoading,
        propertyChangesLoading: p.propertyChangesLoading,
        companyChangesLoading: m.companyChangesLoading,
    }
  }

const mapDispatchToProps = dispatch => {
    return {
        getContactChanges: (data) => {
            dispatch(actions.getContactChanges(data));
        },
        saveContactChanges: (data) => {
            dispatch(actions.saveContactChanges(data));
        },
        getPropertyChanges: (data) => {
            dispatch(actions.getPropertyChanges(data));
        },
        savePropertyChanges: (data) => {
            dispatch(actions.savePropertyChanges(data));
        },
        saveCompanyChanges: (data) => {
            dispatch(actions.saveCompanyChanges(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaveBack);
