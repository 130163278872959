import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';


const ListingModal = (props) => {
    const [message, setMessage] = useState('');
    const listing_id = props.listingID;

    useEffect(() => {
        
        if ((props.prevSuccess !== props.success) && props.success && props.prevSuccess !== undefined) {
            if (props.success.indexOf('Deleted') !== -1) {
                props.getListings();
                setMessage(<div className="success-message">{props.success}</div>);
                setTimeout(() => {
                    props.close();
                    props.setTab('List');
                }, 1000);
            }
        }
    }, [props.success]);

    useEffect(() => {
        if (props.prevError === null && props.error) {
            setMessage(<div className="error-message">{props.error}</div>);
        }
    }, [props.error]);

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'center'}}>Are you sure you want to delete this Listing?</div>
        </DialogTitle>
            
        <div style={{textAlign:'center',padding:20}}>
            <div style={{textAlign:'center',marginBottom:20}}>
                {message && <div>{message}</div>}
            </div>

            <Button
                color="danger"
                style={{marginRight:10}}
                onClick={() => {
                    if (listing_id || listing_id === 0) props.deleteListing(listing_id);
                }}
            >
                YES, DELETE
            </Button>
            <Button
                color="white"
                style={{marginRight:10}}
                onClick={() => {
                    props.close();
                }}
            >
                CANCEL
            </Button>
        </div>
        </Dialog>
    )
}

export default ListingModal;