import React, { useEffect, useState } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "components/CustomButtons/Button.jsx";
import Delete from "@material-ui/icons/Delete";
import { Dialog } from '@material-ui/core';
import * as actions from "store/actions";
import { connect } from "react-redux";
import { clone } from "shared/utility";
import CustomDialogChooser from 'components/CustomDialog/CustomDialogChooser';

const PropertiesFormOwner = (props) => {
    const { getPropertyToContact, contact_properties, addPropertyToContact, dataObj, 
            success, error, contactsForAdd, setContactsForAddNow, getCompanyProperties, company_properties,
            companyForAdd, setCompanyForAddNow, createCompanyProperties, updateCompany, updateContact
    } = props;
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);
    const [contacts, setContacts] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [openedSearchModal, setOpenedSearchModal] = useState(false);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false);
    const [currentDelete, setCurrentDelete] = useState(null);
    const [openedChooser, setOpenedChooser] = useState(false);

    const choose = (the_contact_data) => {
        console.log('choosing ->', the_contact_data);
        if (the_contact_data.company_data) {
            // company has been chosen 
            
            if (props.action !== 'Add') {
                // save right away
                createCompanyProperties({
                    is_company: false,
                    property_id: dataObj.id,
                    company_id: the_contact_data.company_data.id,
                    company_type: 'OWN'
                })
                // update company - make sure company is designated as owner
                let current_contact_type = the_contact_data.company_data.contact_type ? the_contact_data.company_data.contact_type.split(',') : [];
                if (current_contact_type.indexOf('Property Owner') === -1) {
                    updateCompany({
                        id: the_contact_data.company_data.id,
                        contact_type: current_contact_type.concat(['Property Owner']).join(',')
                    })
                }
            } else {
                // Add Mode
                const newCompanyForAdd = clone(companyForAdd).concat([
                    {
                        company_data: the_contact_data.company_data,
                        company_id: the_contact_data.company_data.id,
                        company_type: 'OWN'
                    }
                ])
                setCompanyForAddNow(newCompanyForAdd);
                // update company - make sure company is designated as owner
                let current_contact_type = the_contact_data.company_data.contact_type ? the_contact_data.company_data.contact_type.split(',') : [];
                if (current_contact_type.indexOf('Property Owner') === -1) {
                    updateCompany({
                        id: the_contact_data.company_data.id,
                        contact_type: current_contact_type.concat(['Property Owner']).join(',')
                    })
                }
            }
        } else {
            // contact has been chosen
            if (props.action !== 'Add') {
                // save right away
                setLoading2(true);
                addPropertyToContact({
                    contact_type: 'OWN',
                    contact_id: the_contact_data.id,
                    property_id: dataObj.id,
                    from_property: true
                }, 'property')
                // update contact - make sure he is designated as owner
                let current_contact_type = the_contact_data.contact_type ? the_contact_data.contact_type.split(',') : [];
                if (current_contact_type.indexOf('Property Owner') === -1) {
                    updateContact({
                        id: the_contact_data.id,
                        contact_type: current_contact_type.concat(['Property Owner']).join(',')
                    })
                }
            } else {
                // Add Mode
                const newContactsForAdd = clone(contactsForAdd).concat([
                    {
                        contact_type: 'OWN',
                        contact_id: the_contact_data.id,
                        contact_data: the_contact_data
                    }
                ]);
                setContactsForAddNow(newContactsForAdd);
                // update contact - make sure he is designated as owner
                let current_contact_type = the_contact_data.contact_type ? the_contact_data.contact_type.split(',') : [];
                if (current_contact_type.indexOf('Property Owner') === -1) {
                    updateContact({
                        id: the_contact_data.id,
                        contact_type: current_contact_type.concat(['Property Owner']).join(',')
                    })
                }
            }
        }
        setOpenedChooser(false)
    }

    useEffect(() => {
        // Edit Mode
        if (dataObj.id) {
            getPropertyToContact(dataObj.id, 'property', 'others');
            getCompanyProperties({
                property_id: dataObj.id
            });
        } else {
            // Add Mode 
            setContacts(contactsForAdd.filter(f => {
                if (f.contact_type === 'OWN') return true;
                return false;
            }))
        }
    }, []);

    useEffect(() => {
        // Edit Mode
        if (contact_properties && props.action !== 'Add') {
            setContacts(contact_properties.filter(cp => {
                if (cp.contact_type === 'OWN') return true;
                return false;
            }));
        }
    }, [contact_properties]);

    useEffect(() => {
        // Edit Mode
        if (company_properties && props.action !== 'Add') {
            const newCompanyProperties = company_properties.filter(cp => {
                if (cp.company_type === 'OWN') return true;
                return false;
            });
            
            setCompanies(newCompanyProperties);
        }
    }, [company_properties]);

    useEffect(() => {
        setContacts(contactsForAdd.filter(f => {
            if (f.contact_type === 'OWN') return true;
            return false;
        }))
    }, [contactsForAdd]);

    useEffect(() => {
        setCompanies(companyForAdd.filter(f => {
            if (f.company_type === 'OWN') return true;
            return false;
        }))
    }, [companyForAdd]);

    useEffect(() => {
        if (loading2) {
            if (success === 'Successfully added property') {
                getPropertyToContact(dataObj.id, 'property', 'others');
            }
            if (success === 'Successfully removed property') {
                getPropertyToContact(dataObj.id, 'property', 'others');
            }
        }
    }, [success, loading2]);

    

    return <div>
        <div className={props.action === 'View' ? 'no-click-disabled lightgray-bg-disabled enclosure' : 'enclosure'}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {props.action !== 'View' && <Button style={{marginBottom:20}} color="primary" onClick={() => {
                            setOpenedChooser(true);
                        }}><i className="fa fa-plus"></i> Add Owner</Button>}
                    <hr />
                    <h3>{contacts.length} Contact Owner(s)</h3>
                    <List component="nav">
							{contacts.map((c, i) => {
                                const o = c.contact_data ? c.contact_data : {};
                                const name = o.name ? 
                                    o.name 
                                : 
                                    `${o.first_name ? o.first_name : ''} ${o.last_name ? o.last_name : ''}`;
								return (
									<ListItem 
										button
										key={`${o.name}-${i}`}
									>
										{props.action !== 'View' && <ListItemIcon>
											<div 
                                                className="hoverable red-text"
                                                onClick={() => {
                                                    if (props.action !== 'Add') {
                                                        setOpenedAskBeforeDelete(true);
                                                        setCurrentDelete(c);
                                                    } else {
                                                        setContactsForAddNow(contactsForAdd.filter((f) => {
                                                            if (f.contact_data) {
                                                                if (f.contact_data.id === o.id) {
                                                                    if (f.contact_type === 'OWN') {
                                                                        return false;
                                                                    }
                                                                }
                                                            }
                                                            return true;
                                                        }));
                                                    }
                                                }}
                                            >
                                                <Delete />
                                            </div>
										</ListItemIcon>}
										<ListItemText inset primary={name} 
                                            onClick={() => {
                                                
                                                props.setOpenedEditContact(true);
                                                props.setContactID(o.id);
                                            }}
                                        />
									</ListItem>
								);
							})}
						</List>
                        <hr />
                        <h3>{companies.length} Company Owner(s)</h3>
                        <List component="nav">
                                {companies.map((c, i) => {
                                    const o = c.company_data ? c.company_data : {};
                                    const name = o.name;
                                    return (
                                        <ListItem 
                                            button
                                            key={`${o.name}-${i}`}
                                        >
                                            {props.action !== 'View' && <ListItemIcon>
                                                <div 
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        if (props.action !== 'Add') {
                                                            setOpenedAskBeforeDelete(true);
                                                            setCurrentDelete(c);
                                                        } else {
                                                            setCompanyForAddNow(companyForAdd.filter((f) => {
                                                                if (f.company_data) {
                                                                    if (f.company_data.id === o.id) {
                                                                        if (f.company_type === 'OWN') {
                                                                            return false;
                                                                        }
                                                                    }
                                                                }
                                                                return true;
                                                            }));
                                                        }
                                                    }}
                                                >
                                                    <Delete />
                                                </div>
                                            </ListItemIcon>}
                                            <ListItemText inset primary={name} 
                                                onClick={() => {
                                                    props.setOpenedEditCompany(true);
                                                    props.setCompanyID(o.id);
                                                }}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                </GridItem>

                {openedChooser && <CustomDialogChooser
                    open={openedChooser}
                    close={() => { setOpenedChooser(false) }}
                    choose={choose}
                    create_and_choose={choose}
                    peopleType="owners"
                    title="Select Contact or Company"
                    label="Search Contacts/Company"
                    noCompanyContacts={true}
                />}

                {openedSearchModal && <CustomDialog 
                    open={openedSearchModal}
                    close={() => { setOpenedSearchModal(false) }}
                    choose={(the_contact_data) => {
                        console.log('choosing', the_contact_data);
                        if (props.action !== 'Add') {
                            // save right away
                            setLoading2(true);
                            addPropertyToContact({
                                contact_type: 'OWN',
                                contact_id: the_contact_data.id,
                                property_id: dataObj.id
                            }, 'property')
                        } else {
                            // Add Mode
                            const newContactsForAdd = clone(contactsForAdd).concat([
                                {
                                    contact_type: 'OWN',
                                    contact_id: the_contact_data.id,
                                    contact_data: the_contact_data
                                }
                            ]);
                            setContactsForAddNow(newContactsForAdd);
                        }
                        setOpenedSearchModal(false)
                    }}
                    peopleType="owners"
                    create_and_choose={(the_contact_data) => {
                        
                        if (props.action !== 'Add') {
                            // save right away
                            setLoading2(true);
                            addPropertyToContact({
                                contact_type: 'OWN',
                                contact_id: the_contact_data.id,
                                property_id: dataObj.id
                            }, 'property')
                        } else {
                            // Add Mode
                            const newContactsForAdd = clone(contactsForAdd).concat([
                                {
                                    contact_type: 'OWN',
                                    contact_id: the_contact_data.id,
                                    contact_data: the_contact_data
                                }
                            ]);
                            setContactsForAddNow(newContactsForAdd);
                        }
                        setOpenedSearchModal(false)
                    }}
                    title="Select Owner"
                    label="Search Owners"
                />}

                {openedAskBeforeDelete && <Dialog 
                    open={openedAskBeforeDelete}
                    onClose={() => {
                        setOpenedAskBeforeDelete(false);
                    }}
                >
                    <div className="dialog-wrapper text-center">
                        <h3>Are you sure you want to delete this owner ?</h3>
                        <Button color="primary" onClick={() => { 
                            if (currentDelete) {
                                if (currentDelete.id && !currentDelete.company_data) {
                                    if (props.action !== 'Add') {
                                        setLoading2(true);
                                        props.removePropertyToContact(currentDelete.id, 'property', dataObj.id);
                                    }
                                } else if (currentDelete.id && currentDelete.company_data) {
                                    if (props.action !== 'Add') {
                                        setLoading2(true);
                                        props.deleteCompanyProperties({
                                            id: currentDelete.id,
                                            property_id: currentDelete.property_id,
                                            is_company: false
                                        });
                                    }
                                }
                            }
                            setOpenedAskBeforeDelete(false) }}>YES, DELETE</Button>
                        <Button color="white" onClick={() => { setOpenedAskBeforeDelete(false) }}>CANCEL</Button>
                    </div>
                </Dialog>}
            </GridContainer>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        contact_properties: state.crm_contacts.contact_properties,
        company_properties: state.crm_companies.companyPropertiesData ? state.crm_companies.companyPropertiesData : [],
        success: state.crm_contacts.success,
        error: state.crm_contacts.error
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        getPropertyToContact: (data, type, kind)  => {
            dispatch(actions.getPropertyToContact(data, type, kind));
        },
        removePropertyToContact: (data, data_type, data_type_id) => {
            dispatch(actions.removePropertyToContact(data, data_type, data_type_id));
        },
        addPropertyToContact: (data, data_type) => {
            dispatch(actions.addPropertyToContact(data, data_type));
        },
        getCompanyProperties: (data) => {
            dispatch(actions.getCompanyProperties(data));
        },
        createCompanyProperties: (data) => {
            dispatch(actions.createCompanyProperties(data));
        },
        deleteCompanyProperties: (data) => {
            dispatch(actions.deleteCompanyProperties(data));
        },
        updateCompany: (data, partnersData, addressData) => {
            dispatch(actions.updateCompany(data, partnersData, addressData));
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PropertiesFormOwner);