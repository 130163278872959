import React, { useEffect, useState } from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';
import { Tooltip } from '@material-ui/core';
import Button from '../../../components/CustomButtons/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import download from 'downloadjs';
import ERTLEditTemplate from 'containers/ERTL/FormTemplates/ERTLEditTemplate';
import ERTLFormWizard from '../FormWizard/ERTLFormWizard';
import { Close } from '@material-ui/icons';
import { Dialog } from '@material-ui/core';
import { clone } from 'shared/utility';

const historyAction = {
    0: 'Pending owners signature',
    1: 'Signed, pending other signatures',
    2: 'Pending illi signature',
    3: 'Complete',
    4: 'Modified for resubmission by approver',
    5: 'Entry Edited',
    6: 'Submitter sent to owner for approval', 
    7: 'Owner submitted back to submitter',
}

/*
*   Submitter starts with draft and submits to owner for approval - Status # 6
*   Owner gets link and submits form - Status # 7
*   Once submitter sends to Docusign - All owners have submitted - Status # 0
*/

const ERTLHistory = (props) => {
    const [downloadPDF, setDownloadPDF] = useState(false);
    const [downloadHistoryEntry, setDownloadHistoryEntry] = useState(false);
    const [openedForm, setOpenedForm] = useState(false);
    const [templateData, setTemplateData] = useState(null);
    const [ertlData, setErtlData] = useState(null);

    useEffect(() => {
        if (props.permissions) {
            if (props.permissions.hasOwnProperty('approve')) {
                return props.getErtlHistory('all');
            }
        }
        props.getErtlHistory('own');
    }, []);

    useEffect(() => {
        if (downloadPDF || downloadHistoryEntry) {
            if (props.error) {
                
                alert('File not found');
            }
        }
    }, [props.error]);

    useEffect(() => {
        if (props.pdfHistoryData && downloadPDF) {
            const url = window.URL.createObjectURL(new Blob([new Uint8Array(props.pdfHistoryData.data).buffer]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `ertl-entry-${new Date().getTime()}.pdf`);
            document.body.appendChild(link);
            link.click();
            //download(props.pdfHistoryData.data, `ertl_history_.pdf`, 'application/pdf');  
            setDownloadPDF(false);
        }   
    }, [props.pdfHistoryData]);

    useEffect(() => {
        if (props.historyEntryData && downloadHistoryEntry) {
            
            const dat = props.historyEntryData;
            if (dat.json_data) {
                if (dat.json_data.templateData) {
                    
                    setTemplateData(dat.json_data.templateData);
                    const newERTLData = {};
                    newERTLData.data = clone(dat.json_data);
                    newERTLData.id = dat.entry_id;
                    setErtlData(newERTLData);
                    setOpenedForm(true);
                }
            }
            setDownloadHistoryEntry(false);
        }
    }, [props.historyEntryData]);

    const {  historyData } = props;

    const data = historyData ? historyData.map((l, key) => { // map users to table rows
        return ({
            id: l.entry_id,
            created_date: formatDate2(l.created_date),
            action: historyAction[l.action],
            owners: l.owners,
            name: l.name,
            data: l,
            actions: <div>
                        {
                            <Tooltip
                                title="View Template"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getErtlHistoryEntry(l.id);
                                    setDownloadHistoryEntry(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-eye font-22"></i>
                            </Button>
                          </Tooltip>
                        }
                        {
                            <Tooltip
                                title="Download PDF"
                                placement="top"
                            >
                            <Button
                                justIcon
                                round
                                simple
                                onClick={() => {
                                    props.getErtlPdf(l.id);
                                    setDownloadPDF(true);
                                }}
                                color="primary"
                                className="mr-20"
                            >
                                <i className="fas fa-arrow-down font-22"></i>
                            </Button>
                          </Tooltip>
                        }
                        </div>
        })
    }) : [];

    const columns = [
        {
            Header: "ERTL #",
            accessor: "id" 
        },
        {
            Header: "Date",
            accessor: "created_date"
        },
        {
            Header: "Submitter",
            accessor: "name"
        },
        {
            Header: "Action",
            accessor: "action"
        },
        {
            Header: "Owners",
            accessor: "owners"
        },
        {
            Header: "Actions",
            accessor: "actions"
        }
    ]

    

    return (
        <div>
            <h3 style={{display:'inline-block',marginRight:10}}>History</h3> <i className="fas fa-sync-alt hoverable" onClick={() => {
                    if (props.permissions) {
                        if (props.permissions.hasOwnProperty('approve')) {
                            return props.getErtlHistory('all');
                        }
                    }
                    props.getErtlHistory('own');
                }}></i>
            <div className="table-adjust23">
            <ReactTable 
                filterable
                className='-striped' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
            />

            {(openedForm && ertlData) && <Dialog
                open={openedForm}
                onClose={() => {
                    setOpenedForm(false);
                }}
                maxWidth={false}
                fullWidth={true}
            >
                    <div className="dialog-wrapper">
                        <div className="close-btn" onClick={() => { setOpenedForm(false) }}><Close /></div>
                        <ERTLFormWizard
                            getTemplates={() => { }}
                            templatesData={[]}
                            templateData={{data:templateData}}
                            templatesCount={props.templatesCount}
                            createTemplate={props.createTemplate}
                            getEntries={props.getEntries}
                            createEntry={props.createEntry}
                            updateEntry={props.updateEntry}
                            entriesData={props.entriesData}
                            getEntryPdf={props.getEntryPdf}
                            pdfData={props.pdfData}
                            setParentTab={() => {
                                setOpenedForm(false);
                            }}
                            ertlData={ertlData}
                            origin="view"
                        />
                    </div>
                    {/* <ERTLEditTemplate
                        closeForm={() => setOpenedForm(false)}
                        templateText={templateData}
                        setTemplateText={() => {}}
                        setEditedObj={() => {}}
                        noActions={true}
                    /> */}
            </Dialog>}
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        historyData: state.ertl_entries.historyData,
        pdfHistoryData: state.ertl_entries.pdfHistoryData,
        historyEntryData: state.ertl_entries.historyEntryData,
        error: state.ertl_entries.error
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        getErtlHistory: (type) => {
            dispatch(actions.getErtlHistory(type));
        },
        getErtlPdf: (id) => {
            dispatch(actions.getErtlPdf(id));
        },
        getErtlHistoryEntry: (id) => {
            dispatch(actions.getErtlHistoryEntry(id));
        }
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(ERTLHistory);