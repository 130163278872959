import React, { useEffect, useState, useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import ContactsForm from "containers/CRM/Contacts/ContactsForm";
import { connect } from "react-redux";
import * as actions from "store/actions";
import Button from "components/CustomButtons/Button.jsx";
import { DialogTitle } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import CustomDialogClose from "../../../components/CustomDialog/CustomDialogClose";
import { saveContactNow } from '../Contacts/ContactsLogic';

const CompaniesEditContact = (props) => {
    const [action, setAction] = useState("View");
    const [errorMsg, setErrorMsg] = useState("");
    const [successMsg, setSuccessMsg] = useState("");
    const [newData, setNewData] = useState(null);
    const [openedAsk, setOpenedAsk] = useState(false);
    const [saveContactsFormValues, setSaveContactsFormValues] = useState({
      cfDataObj: {},
      cfAddrData: {},
      cfPartnersData: {},
      madeChanges: false,
    });

    const openAskModal = (cd) => {
        setOpenedAsk(true);
        setNewData(cd);
    };

    const closeAskModal = (cd) => {
        setOpenedAsk(false);
        setNewData(cd);
    };

    useEffect(() => {
        if (props.contactData) {
            setNewData(props.contactData);
        }
    }, [props.contactData]);

    useEffect(() => {
        if (props.contactId) {
            props.getContact(props.contactId);
        }
    }, [props.contactId]);

    useEffect(() => {
        setErrorMsg(props.error);
    }, [props.error]);

    useEffect(() => {
        setSuccessMsg(props.success);
    }, [props.success]);

    const clearMessages = () => {
        setErrorMsg("");
        setSuccessMsg("");
    };

    const openForm = (newAction) => {
        setAction(newAction);
    };

    return (
        <div>
            <Dialog open={props.open} keepMounted onClose={props.close} maxWidth={false} fullWidth={true}>
                {/* <DialogTitle>
                    <div
                        className="close-btn"
                        onClick={() => {
                            props.close();
                        }}
                    >
                        <Close />
                    </div>
                </DialogTitle> */}
                <div style={{ padding: 20 }}>
                    {newData && (
                        <ContactsForm
                            noDelete={true}
                            action={action}
                            closeForm={() => {
                                props.close();
                            }}
                            openForm={openForm}
                            contactData={newData}
                            propertyContacts={props.propertyContacts}
                            getContacts={() => {}}
                            contactOptions={props.contactOptions}
                            addressData={props.addressData}
                            getAddresses={props.getAddresses}
                            partnersData={props.partnersData}
                            getPartnersData={props.getPartnersData}
                            deleteContact={props.deleteContact}
                            openAskModal={() => {}}
                            updateContact={props.updateContact}
                            getContactShares={props.getContactShares}
                            getContactUsernames={props.getContactUsernames}
                            createContactShare={props.createContactShare}
                            deleteContactShare={props.deleteContactShare}
                            contactShares={props.contactShares}
                            contactUsernames={props.contactUsernames}
                            success={successMsg}
                            error={errorMsg}
							              setOpenedAsk={setOpenedAsk}
                            clearMessages={clearMessages}
                            setSaveContactsFormValues={setSaveContactsFormValues}
                            showCloseBtn
                        />
                    )}
                    <div className="text-center">
                        <Button
                            color="primary"
                            onClick={() => {
                                props.close();
                            }}
                        >
                            CLOSE
                        </Button>
                    </div>
                </div>
            </Dialog>

            {openedAsk && (
                <CustomDialogClose
                    ok_go={() => {
                        props.close();
                    }}
                    ok_save={() => {
                        saveContactNow({
                            dataObj: saveContactsFormValues.cfDataObj,
                            addressData: saveContactsFormValues.cfAddressData,
                            partnersData: saveContactsFormValues.cfPartnersData,
                            madeChanges: saveContactsFormValues.madeChanges,
                            action: action,
                            currentAddressData: props.addressData,
                            currentPartnersData: props.partnersData,
                            updateContact: props.updateContact,
                        });
                        props.close()
                    }}
                    open={openedAsk}
                    close={() => {
                        setOpenedAsk(false);
                    }}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        error: state.crm_contacts.error,
        success: state.crm_contacts.success,
        contactsData: state.crm_contacts.contactsData,
        contactOptions: state.crm_contacts.contactOptions,
        contactsCount: state.crm_contacts.contactsCount,
        contactData: state.crm_contacts.contactData,
        addressData: state.crm_contacts.addressData,
        partnersData: state.crm_contacts.partnersData,
        contactShares: state.crm_contacts.contactShares,
        contactUsernames: state.crm_contacts.contactUsernames,
        success_data: state.crm_contacts.success_data,
        propertyContacts: state.crm_properties.propertyContacts,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getContact: (contact_id) => {
            dispatch(actions.getContact(contact_id));
        },
        createContact: (data, partnersData, addressData) => {
            dispatch(actions.createContact(data, partnersData, addressData));
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
        deleteContact: (id) => {
            dispatch(actions.deleteContact(id));
        },
        getAddresses: (contact_id) => {
            dispatch(actions.getAddresses(contact_id));
        },
        getPartnersData: (contact_id) => {
            dispatch(actions.getPartners(contact_id));
        },
        getContactOptions: () => {
            dispatch(actions.getContactOptions());
        },
        getContactShares: (contact_id) => {
            dispatch(actions.getContactShares(contact_id));
        },
        getContactUsernames: () => {
            dispatch(actions.getContactUsernames());
        },
        createContactShare: (data) => {
            dispatch(actions.createContactShare(data));
        },
        deleteContactShare: (share_id) => {
            dispatch(actions.deleteContactShare(share_id));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompaniesEditContact);
