import React, {useEffect} from 'react';
import LessorsForm from './LessorsForm';

const Lessors = (props) => {
    const { updateDataObj, updateDataMultiObj, data, field } = props;
    let sfield = 'lessors';
    if (field === 'assignors') sfield = 'assignors';
    return <div>
        {data[sfield] ? data[sfield].map((dl,i) => {
            return <div key={`dlessor-${i}`}><LessorsForm
                obj={dl}
                index={i}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                data={data}
                getRsheetFiles={props.getRsheetFiles}
                sfield={sfield}
            /></div>
        })
        : null}
    </div>
}

export default Lessors;
