import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    templatesData: [],
    templatesCount: null,
    error: null,
    success: null,
    loading: false,
}

// GET TEMPLATE

const getTemplatesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        templatesData: [],
        error: null,
        success: null,
    });
}

const getTemplatesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Successfully got templates',
        templatesData: action.data.templatesData,
        templatesCount: action.data.templatesCount
    });
}

const getTemplatesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_TEMPLATE

const getTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got template',
        templateData: action.response
    })
}

const getTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE TEMPLATE

const createTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Template Created'
    })
}

const createTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE TEMPLATE

const deleteTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    });
}

const deleteTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully deleted template. '
    });
}

const deleteTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    });
}

// UPDATE_ERTL_TEMPLATE

const updateErtlTemplateStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateErtlTemplateSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully updated ERTL template'
    })
}

const updateErtlTemplateFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_TEMPLATES_START:
            return getTemplatesStart(state, action);
        case actionTypes.GET_TEMPLATES_SUCCESS:
            return getTemplatesSuccess(state, action);
        case actionTypes.GET_TEMPLATES_FAIL:
            return getTemplatesFail(state, action);
        case actionTypes.GET_TEMPLATE_START:
            return getTemplateStart(state, action);
        case actionTypes.GET_TEMPLATE_SUCCESS:
            return getTemplateSuccess(state, action);
        case actionTypes.GET_TEMPLATE_FAIL:
            return getTemplateFail(state, action);

        case actionTypes.CREATE_TEMPLATE_START:
            return createTemplateStart(state, action);
        case actionTypes.CREATE_TEMPLATE_SUCCESS:
            return createTemplateSuccess(state, action);
        case actionTypes.CREATE_TEMPLATE_FAIL:
            return createTemplateFail(state, action);
            
        case actionTypes.DELETE_TEMPLATE_START:
            return deleteTemplateStart(state, action);
        case actionTypes.DELETE_TEMPLATE_SUCCESS:
            return deleteTemplateSuccess(state, action);
        case actionTypes.DELETE_TEMPLATE_FAIL:
            return deleteTemplateFail(state, action);

        case actionTypes.UPDATE_ERTL_TEMPLATE_START:
            return updateErtlTemplateStart(state, action);
        case actionTypes.UPDATE_ERTL_TEMPLATE_SUCCESS:
            return updateErtlTemplateSuccess(state, action);
        case actionTypes.UPDATE_ERTL_TEMPLATE_FAIL:
            return updateErtlTemplateFail(state, action);

        default: return state;
    }
};

export default reducer;