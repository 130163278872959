export const fields = {
    pdf_attachment: {
        label: 'PDF Attachment',
        type: 'file',
        validations: []
    },
    submitted_by_name: {
        label: 'Submitted by Firstname',
        type: 'input',
        validations: ['required'],
        readonly: true
    },
    date: {
        label: 'Date',
        type: 'date',
        validations: ['required']
    },
    illi_number: {
        label: 'illi Number',
        type: 'input',
        validations: ['required'],
        readonly: true
    },
    project_name: {
        label: 'Project Name',
        type: 'input',
        validations: ['required'],
        readonly: true
    },
    street1: {
        label: 'Street 1',
        type: 'input',
        validations: ['required'],
        readonly: true
    },
    street2: {
        label: 'Street 2',
        type: 'input',
        validations: ['max50'],
        readonly: true
    },
    zip: {
        label: 'Zip',
        type: 'input',
        validations: ['max20','required'],
        readonly: true
    },
    city: {
        label: 'City',
        type: 'input',
        validations: ['max20','required'],
        readonly: true
    },
    state: {
        label: 'State',
        type: 'states',
        validations: ['required'],
        readonly: true
    },
    cross_streets: {
        label: 'Cross Streets',
        type: 'input',
        validations: ['max50']
    },
    contacts: {
        label: 'Contacts',
        type: 'array',
        validations: ['required']
    },
    referrals: {
        label: 'Referrals',
        type: 'array',
        validations: ['required']
    },
    outside_brokers: {
        label: 'Outside Brokers',
        type: 'array',
        validations: ['required']
    },
    final_approver_name: {
        label: 'Final Approver Name',
        type: 'input',
        validations: ['required']
    },
    final_approver_email: {
        label: 'Final Approver Email',
        type: 'input',
        validations: ['required']
    },
    final_approver_position: {
        label: 'Final Approver Position',
        type: 'input',
        validations: ['required']
    },
}

const defaultDataObj = {};
Object.keys(fields).forEach(field => {
    const theType = fields[field].type;
    if (theType !== 'object' && theType !== 'array') {
        defaultDataObj[field] = '';
    } else {
        if (theType === 'object') {
            defaultDataObj[field] = {};
        } else if (theType === 'array') {
            defaultDataObj[field] = [];
        }
    }
})

export const defaultData = defaultDataObj;