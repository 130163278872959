import React, { useState, useEffect } from 'react';
import { first, get } from "lodash";
import { Dialog, DialogTitle } from "@material-ui/core";

import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import Table from "components/Table/Table.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";


const CONTACT_TYPES = {
    Owner: "OWN",
    Buyer: "OTHER",
    Tenant: "OTHER",
    Vendor: "OTHER",
    "Property Manager": "OTHER",
    "Real Estate Agent": "REA",
    "Real Estate Broker": "OTHER",
    Other: "OTHER",
};

const COMPANY_TYPES = {
    Owner: 'OWN'
}

const DialPadPropertyReview = (props) => {
    const {
        contactToUpdate,
        contactToImport,
        companyToUpdate,
        contactId,
        contactData,
        propertyToUpdate,
        setPropertyToUpdate,
        companyData,
        property,
        company,
        createCompanyProperties,
        addPropertyToContact,
    } = props;

    const [openedContactAskModal, setOpenedContactAskModal] = useState(false);
    const [openedCompanyAskModal, setOpenedCompanyAskModal] = useState(false);

    const [contactType, setContactType] = useState('');

    const openContactAskModel = () => setOpenedContactAskModal(true);

    const closeContactAskModal = () => setOpenedContactAskModal(false);

    const handleAssignContact = () => {
        const contactPropertyForm = {
            contact_id: contactData.id,
            property_id: property.id,
            contact_type: contactType,
        }
        addPropertyToContact(contactPropertyForm);
        setPropertyToUpdate({
            action: "UPDATE",
            data: property,
        });
        closeContactAskModal();
    };

    const openCompanyAskModal = () => setOpenedCompanyAskModal(true);
    const closeCompanyAskModal = () => setOpenedCompanyAskModal(false);

    const handleAssignCompany = () => {
        const companyPropertyForm = {
            company_id: company.id,
            property_id: property.id,
            company_type: 'OWN'
        }
        createCompanyProperties(companyPropertyForm);
        // const newData = clone(propertyToUpdate);
        // newData.push({
        //     action: "UPDATE",
        //     data: property,
        // });
        setPropertyToUpdate({
            action: "UPDATE",
            data: property,
        });
        closeCompanyAskModal();
    }

    const contactToImportInfoTable = contactToUpdate && (
        <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
            <Table
                tableData={[
                    ["First Name", "first_name"],
                    ["Last Name", "last_name"],
                    ["Company", "company_name"],
                    ["Phone", "primary_phone"],
                    ["Email", "primary_email"],
                ].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        contactToImport[field[1]],
                    ];
                })}
            />
            <Button onClick={openContactAskModel} size="sm">
                Assign contact
            </Button>
        </div>
    );

    const companyToImportInfoTable = company && (
        <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
            <Table
                tableData={[["Company Name", "company_name"]].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        contactToImport[field[1]],
                    ];
                })}
            />
            <Button onClick={openCompanyAskModal} size="sm">
                Assign company
            </Button>
        </div>
    );

    return (
        <div>
            {contactToImportInfoTable}
            {companyToImportInfoTable}

            {openedContactAskModal && (
                <Dialog open={openedContactAskModal} keepMounted onClose={closeContactAskModal}>
                    <DialogTitle>Are you sure to assign contact to this property?</DialogTitle>

                    <div style={{ textAlign: "center", padding: 20 }}>
                        <h3>{contactData ? `${contactData.first_name} ${contactData.last_name}` : ""}</h3>

                        <CustomSelect
                            label="Contact Type"
                            options={Object.keys(CONTACT_TYPES)}
                            choose={(e, n) => {
                                setContactType(CONTACT_TYPES[e]);
                            }}
                        />
                        <Button color="danger" onClick={handleAssignContact}>
                            Confirm
                        </Button>
                        <Button onClick={closeContactAskModal}>Cancel</Button>
                    </div>
                </Dialog>
            )}

            {openedCompanyAskModal && (
                <Dialog open={openedCompanyAskModal} keepMounted onClose={closeContactAskModal}>
                    <DialogTitle>Are you sure to assign this company to this property?</DialogTitle>

                    <div style={{ textAlign: "center", padding: 20 }}>
                        <Button color="danger" onClick={handleAssignCompany}>
                            Confirm
                        </Button>
                        <Button onClick={closeContactAskModal}>Cancel</Button>
                    </div>
                </Dialog>
            )}
        </div>
    );
};

export default DialPadPropertyReview;
