import React, { useState, useEffect } from 'react';
import ERTLEditTemplate from './ERTLEditTemplate';
import ERTLFormList from './ERTLFormList';
import Button from '../../../components/CustomButtons/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { Dialog } from '@material-ui/core';

const ERTLFormTemplates = (props) => {

    const [openedForm, setOpenedForm] = useState(null);
    const [templateTitle, setTemplateTitle] = useState('');
    const [templateText, setTemplateText] = useState(null);
    const [editedObj, setEditedObj] = useState({});
    const [currentId, setCurrentId] = useState(null);
    const [target, setTarget] = useState(false);
    const [openedAskBeforeDelete, setOpenedAskBeforeDelete] = useState(false)
    const { success, getTemplates } = props;

    useEffect(() => {
        if (target) {
            if (success === 'Template Created' ||
                success === 'Successfully updated ERTL template'
            ) {
                getTemplates();
                setTarget(false);
            }
        }
    }, [target, success, getTemplates]);

    useEffect(() => {
        props.getTemplates();
    }, []);

    const openForm = (action, cd) => {

        // No previous edits detected. 
        if (cd && !editedObj[cd.id]) {
            setTemplateText(cd.data);
        }
        if (cd && cd.title) {
            setTemplateTitle(cd.title);
        } else {
            setTemplateTitle('');
        }

        // If a previously edited state is found for this specific template,
        // we will keep that in editedObj state for the user unless refresh.
        if(editedObj[cd.id]){
            
            setTemplateText(editedObj[cd.id])
        }

        setCurrentId(cd.id);
        setOpenedForm(true);
    }

    const closeForm = () => {
        setTemplateText(null);
        setOpenedForm(false);
        setCurrentId(null);
    }

    return (
        <div>
            {!openedForm && <ERTLFormList
                templatesData={props.templatesData}
                templatesCount={props.templatesCount}
                getTemplates={props.getTemplates}
                updateErtlTemplate={(data) => {
                    setTarget(true);
                    props.updateErtlTemplate(data)
                }}
                setRows={props.setRows}
                setOffset={props.setOffset}
                rows={props.rows}
                offset={props.offset}
                openForm={openForm}
                setOpenedAskBeforeDelete={setOpenedAskBeforeDelete}
                setCurrentId={setCurrentId}
                setTarget={setTarget}
            />}
            {openedForm && 
        <Dialog
            open={openedForm}
            onClose={closeForm}
            maxWidth={false}
            fullWidth={true}
        >
            <div className="dialog-wrapper">
                <ERTLEditTemplate
                    createTemplate={(a, b) => {
                        setTarget(true);
                        props.createTemplate(a, b);
                    }}
                    closeForm={closeForm}
                    templateText={templateText}
                    setTemplateText={setTemplateText}
                    updateErtlTemplate={(a,b) => {
                        setTarget(true);
                        props.updateErtlTemplate(a,b);
                    }}
                    setEditedObj={setEditedObj}
                    editedObj={editedObj}
                    currentId={currentId}
                    templateTitle={templateTitle}
                />
            </div>
        </Dialog>
            }

        {openedAskBeforeDelete && <Dialog
            open={openedAskBeforeDelete}
            onClose={() => {
                setOpenedAskBeforeDelete(false);
            }}
        >
            <div className="dialog-wrapper2 text-center">
                <h3>Are you sure you want to delete this template ?</h3>
                <Button color="danger" className="mr-20" onClick={() => {
                    props.deleteErtlTemplate(currentId);
                    setOpenedAskBeforeDelete(false);
                }}>YES, DELETE</Button>
                <Button color="white" onClick={() => {
                    setOpenedAskBeforeDelete(false);
                }}>CANCEL</Button>
            </div>
        </Dialog>}

    </div>
    )
}

const mapStateToProps = state => {
    return {
        error: state.ertl_templates.error,
        success: state.ertl_templates.success,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateErtlTemplate: (data) => {
            dispatch(actions.updateErtlTemplate(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(ERTLFormTemplates);