import React, {Component} from 'react';
import CustomInput from 'components/CustomInput/CustomInput2.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const InputField = (props) => {
	const {classes, isDollar} = props;
	let error = '';
	if (props.validate) {
		if (props.validate.length > 0) {
			props.validate.forEach((val) => {
				if(val !== undefined) {
					const err = val(props.value);
					if (err) {
						error = err;
					}
				}
			});
		}
	}

	const inputProps = {
		onChange: (e) => {
			if (props.isCurrency) {
				const isNum = /-?[0-9]/.test(e.target.value);
				if (!isNum) return;
				let str = e.target.value.split('.').join('').split(',').join(''); // remove dot and comma
				str = parseFloat(str).toString();
				var x = '';
				var len = str.length;
				if (len > 2) {
					x = str.substring(0, len-2) + "." + str.substring(len-2);
				} else if (len === 2) {
					x = "0."+str;
				} else {
					x = "0.0"+str;
				}
				
				let result = parseFloat(x.replace(/,/g, '')).toLocaleString();
				if (result) {
					if (result.indexOf('.') === -1) {
						result += '.00'; 
					}
				}
				const result_arr = result || result == 0 ? result.split('.') : [result];
				if (result_arr[1]) {
					if (result_arr[1].length === 1) {
						result = result + '0';
					}
				}
				if (props.onChange) props.onChange({ target: { value:result } });
				return;
			}
			const start = e.target.selectionStart,
				end = e.target.selectionEnd;
			if (props.normalize) {
				e.target.value = props.normalize(e.target.value);
				e.target.setSelectionRange(start, end);
			}
			if (!props.readonly) {
				if (props.onChange) props.onChange(e);
			}
			if (props.updateError) {
				props.updateError(error);
			}
		},
		onClick: () => {
			if (props.onClick) props.onClick();
		},
		type: props.number_only ? 'number' : 'text',
		autoCapitalize: props.uppercase ? "true" : "false",
		value: props.value ? props.value : '',
		autoFocus: props.autoFocus ? true : false,
		role: 'presentation',
		autoComplete: 'nope',
		disabled: props.disabled ? true : props.readonly ? true : false,
		readOnly: props.readonly ? true : false,
		startAdornment: props.startAdornment ? <InputAdornment position="end">{props.startAdornment}<span> </span></InputAdornment> : null,
		endAdornment: props.endAdornment ? <InputAdornment position="start">{props.endAdornment}<span> </span></InputAdornment> : null
	};

	if (props.type) {
		inputProps.type = props.type;
	}

	return (
		<div className={`${(props.readonly && props.readonly_show_gray) ? 'gray-bg-input' : ''}`}>
			<CustomInput
				rows={props.rows ? props.rows : 1}
				labelText={props.label}
				id={props.name ? props.name : 'a' + makeid(5)}
				formControlProps={{
					fullWidth: true
				}}
				name={props.name}
				inputProps={inputProps}
				error={error ? true : false}
				isDollar={isDollar}
				noDollarSign={props.noDollarSign}
			/>
			{props.additionalError && (
				<div className='red ii' style={{marginTop: 0, fontSize: '10px'}}>
					{props.additionalError}
				</div>
			)}
			{error && (
				<div className='red aa' style={{marginTop: 0, fontSize: '10px'}}>
					{error}
				</div>
			)}
			{!error && (
				<div className='red bb' style={{marginTop: 0, opacity: 0, fontSize: '10px'}}></div>
			)}
			{props.similar && !error ? props.similar : null}
		</div>
	);
};

export default InputField;
