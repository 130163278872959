import React from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';
import { statusTypes } from './PendingModel';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";

const PendingList = (props) => {
    const { entriesData, setTab, setCurrentEntry } = props;
    const data = entriesData ? entriesData.map((l) => { // map users to table rows
        return ({
            id: l.id,
            date_modified: l.date_modified ? formatDate2(l.date_modified) : '',
            date_created: l.date_created ? formatDate2(l.date_created) : '',
            username: l.name,
            email: l.mail,
            status: l.status ? statusTypes[l.status] : '',
            data: l
        })
    }) : [];

    const columns = [
        {
            Header: "#",
            accessor: "id"
        },
        {
            Header: "Date Created",
            accessor: "date_created"
        },
        {
            Header: "Date Modified",
            accessor: "date_modified"
        },
        {
            Header: "Username",
            accessor: "username"
        },
        {
            Header: "Email",
            accessor: "email"
        },
        {
            Header: "Status",
            accessor: "status"
        },
    ]

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <div className="table-adjust2">
                    <ReactTable
                        filterable
                        className='-striped -highlight'
                        showPaginationTop
                        showPaginationBottom
                        resizable={false}
                        defaultPageSize={10}
                        pageSizeOptions={[10, 20, 30]}
                        data={data}
                        columns={columns}
                        getTrProps={(state, rowInfo) => {
                            if (rowInfo && rowInfo.row) {
                                return {
                                    onClick: (e) => {
                                        if (rowInfo && rowInfo.original) {
                                            
                                            // open onboarding
                                            
                                            setCurrentEntry({currentEntry: rowInfo.original.data});
                                            setTab('Onboarding')
                                        }
                                    }
                                }
                            } else {
                                return {}
                            }
                        }}
                    />
                </div>
            </GridItem>
        </GridContainer>
    )
}

export default PendingList;