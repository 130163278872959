import React from "react";
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';

// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Heading from "components/Heading/Heading.jsx";
import Timeline from "components/Timeline/Timeline.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import { stories } from "variables/general.jsx";
import ReactDOM from "react-dom";
import CardHeader from "components/Card/CardHeader.jsx";
import SupervisedUserCircle from "@material-ui/icons/AccountCircle";
import CardIcon from "components/Card/CardIcon.jsx";
import regularFormsStyle from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";

import UserAdmin from 'containers/UserAdmin/UserAdmin';
import GroupsAdmin from 'containers/UserAdmin/GroupsAdmin';
import checkPermissions from '../../shared/checkPermissions';

class UserAdminPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'Users'
    }
  }
  componentDidMount() {
    this.props.checkMaintenanceMode();
    this.props.getWorkspaceGroups();
  }
  render() {
    if (!checkPermissions('UserAdmin')) {
      return <Redirect to={{
          pathname: '/dashboard' }
        } />;
    }
    if (this.props.maintenanceMode === 'yes') return <Redirect to="/components/maintenance_page" />

    return (
      <div style={{marginTop:20}}>
        <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="rose" icon>
                  <CardIcon
                    color={this.state.tab === 'Users' ? 'success' : 'rose'}
                    onClick={() => {
                      this.setState({tab:'Users'});
                    }}
                    style={{marginRight:20}}
                    className="hovered"
                  >
                    <strong>USERS</strong>
                  </CardIcon>
                  <CardIcon
                    color={this.state.tab === 'Groups' ? 'success' : 'rose'}
                    onClick={() => {
                      this.setState({tab:'Groups'});
                    }}
                    className="hovered"
                  >
                    <strong>GROUPS</strong>
                  </CardIcon>
            </CardHeader>
            <CardBody>
        {this.state.tab === 'Users' && <UserAdmin 
          getWorkspaceUsers={this.props.getWorkspaceUsers}
          groupList={this.props.groupList}
        />}
        {this.state.tab === 'Groups' && <GroupsAdmin 
          getWorkspaceGroups={this.props.getWorkspaceGroups}
          createWorkspaceGroup={this.props.createWorkspaceGroup}
          deleteWorkspaceGroup={this.props.deleteWorkspaceGroup}
          updateWorkspaceGroup={this.props.updateWorkspaceGroup}
          getWorkspaceGroupMembers={this.props.getWorkspaceGroupMembers}
          createWorkspaceGroupMember={this.props.createWorkspaceGroupMember}
          deleteWorkspaceGroupMember={this.props.deleteWorkspaceGroupMember}
          getWorkspaceUsers={this.props.getWorkspaceUsers}
          groupList={this.props.groupList}
          memberList={this.props.memberList}
          userList={this.props.userList}
          loading={this.props.loading}
          popupMessage={this.props.popupMessage}
        />}
      </CardBody>
          </Card>
        </GridItem>
        </GridContainer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    maintenanceMode: state.userPerms.maintenanceMode,
    groupList: state.user.groupList,
    memberList: state.user.memberList,
    userList: state.user.userList,
    loading: state.user.loading,
    popupMessage: state.user.popupMessage
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getWorkspaceUsers: () => dispatch(actions.getWorkspaceUsers()),
    getWorkspaceGroups: () => dispatch(actions.getWorkspaceGroups()),
    createWorkspaceGroup: (groupData) => dispatch(actions.createWorkspaceGroup(groupData)),
    deleteWorkspaceGroup: (id) => dispatch(actions.deleteWorkspaceGroup(id)),
    updateWorkspaceGroup: (groupData) => dispatch(actions.updateWorkspaceGroup(groupData)),
    createWorkspaceGroupMember: (groupData, userData) => dispatch(actions.createWorkspaceGroupMember(groupData, userData)),
    deleteWorkspaceGroupMember: (groupKey, userKey, memberEmail) => dispatch(actions.deleteWorkspaceGroupMember(groupKey, userKey, memberEmail)),
    getWorkspaceGroupMembers: (groupKey) => dispatch(actions.getWorkspaceGroupMembers(groupKey)),
    checkMaintenanceMode: () => {
      dispatch(actions.checkMaintenanceMode());
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserAdminPage);
