import React from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";
import CustomDialogWithRoutingSheet from "../../../components/CustomDialog/CustomDialogWithRoutingSheet";
import { useState } from "react";
import { get } from "lodash";
import { IconButton } from "@material-ui/core";
import Table from "components/Table/Table";
import { useEffect } from 'react';
import { isEstimatedLease } from '../../RSheets/RSheetsLogic';
import { formatDollar } from '../../../shared/utility';

const RS_TABLE_HEADER = ["Project Name", 'Name', 'Lease Term', 'Rent'];

const InvoiceRoutingSheet = (props) => {
    const {
        formData,
        setFormData,
        updateDataObj,
        listingHistory,
        fieldModels,
        routingSheet,
        setRoutingSheet
    } = props;

    const [openedModal, setOpenedModal] = useState(false);

    const openModal = () => setOpenedModal(true);
    const closeModal = () => setOpenedModal(false);

    const chooseRoutingSheet = (rs) => {
        const newRs = clone(rs);
        setRoutingSheet(newRs);
    };

    const tableData = RS_TABLE_HEADER.map((header) => {
        const rsData = get(routingSheet, 'json_data', {});
        const table = [];
        let output = "";

        if (header === 'Project Name') {
            output = get(routingSheet, 'project_name', "");
        } else if (header === 'Name') {
            output = get(rsData, 'business_name', "");
        } else if (header === 'Project Address') {
            output = get(rsData, 'project_address', "");
        } else if (header === 'Lease Term') {
            const end = get(rsData, 'lease_end_date', "");
            let start = get(rsData, 'lease_start_date', "");

            if (start !== '' && end !== '') {
                output = `${start} - ${end}`;
            }

            if (rsData && isEstimatedLease(rsData)) {
                if (get(rsData, 'lease_start_date_estimated_tbd', '') !== '') {
                    const lease_length = get(rsData, 'lease_length', "");
                    output = `${lease_length} months`;
                } else {
                    const lease_length = get(rsData, 'lease_length', "");
                    output = `${start}, ${lease_length} months`
                }
            }
        } else if (header === 'Rent') {
            output = formatDollar(get(rsData, 'lease_term[0].ranges[0].value', 0));
        }

        return [
            <div style={{ textAlign: "right" }}>
                <strong>{header}</strong>
            </div>,
            output,
        ];

    })


    return (
        <div>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <hr />
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    {/* <Button color="primary" onClick={openModal}>
                        SELECT ROUTING SHEET
                    </Button> */}

                    <CustomDialogWithRoutingSheet
                        title="Routing Sheet"
                        label="Search for Routing Sheet"
                        options={[]}
                        close={closeModal}
                        open={openedModal}
                        choose={chooseRoutingSheet}
                        canGenerateInvoiceOnly
                    />
                </GridItem>
            </GridContainer>

            {routingSheet && (
                <React.Fragment>
                    <h3 className="text-center">RS Data</h3>
                    <IconButton
                        onClick={() => setRoutingSheet(null)}
                        style={{ display: "block", marginLeft: "auto" }}
                    >
                        <i className="fas fa-trash-alt red-text"></i>
                    </IconButton>

                    <div style={{ lineHeight: 1.5 }} className="mt-20">
                        <Table
                            tableData={tableData}
                        />
                    </div>
                </React.Fragment>
            )}
        </div>
    );
};

export default InvoiceRoutingSheet;
