import React from "react";
import { fields as model } from "./Entries/TREntriesModel";
import CustomInput from "components/CustomInput/CustomInputValidate";
import CustomInputStates from "../../components/CustomInput/CustomInputStates";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import Datetime from "react-datetime";
import {
    email,
    max10,
    max100,
    max15,
    max20,
    max250,
    max4,
    max50,
    max500,
    min1,
    min10,
    min4,
    min6,
    no_letters,
    no_numbers,
    no_special_chars,
    required,
	percentage,
} from "shared/validation";

const validate = {
    min1,
    min6,
    min4,
    min10,
    email,
    required,
    no_letters,
    no_special_chars,
    no_numbers,
    max4,
    max10,
    max15,
    max20,
    max50,
    max100,
    max250,
    max500,
	percentage,
};

const getValidations = (f, extraValidations = []) => {
    const fields = model;
    let validations = [];
    if (model[f].validations) {
        validations = fields[f].validations;
    }
    validations = validations.concat(extraValidations);
    return validations.map((v) => validate[v]);
};

const TRElement = (props) => {
    const { field, updateDataObj, dataObj, setDataObj, options } = props;
    const data = dataObj;
    const fields = model;
    const fieldsArr = Object.keys(fields);
    for (let i = 0; i < fieldsArr.length; i++) {
        const f = fieldsArr[i];
        const isRequired = fields[f].validations ? 
            fields[f].validations.indexOf('required') !== -1 ?
                true
            : 
                false
        :
            false;
        if (f === field) {
            if (fields[f].type === 'input') {
                return <CustomInput
                    label={fields[f].label}
                    value={dataObj[field] ? dataObj[field] : ""}
                    onChange={(e) => {
                        updateDataObj(field, e.target.value);
                    }}
                    validate={getValidations(field)}
                    readonly={fields[field].readonly ? true : false}
                    readonly_show_gray={true}
                    startAdornment={fields[field].prefix ? fields[field].prefix : false}
                    isDollar={fields[field].dollar ? true : false}
                    endAdornment={fields[field].suffix ? fields[field].suffix : false}
                />
            }
            if (fields[f].type === 'states') {
                return <CustomInputStates
                    label={model[field].label}
                    onChange={(val) => {
                        updateDataObj(field, val);
                    }}
                    value={data[field]}
                    validate={getValidations(field)}
                    readonly={model[field].readonly ? true : false}
                />
            }
            if (fields[f].type === 'date') {
                return <div className={`custom relative ${!model[field].readonly && isRequired && !data[field] ? "required-date" : ""}`}>
                    {data[field] && <div className="tiny-label">{model[field].label}</div>}
                    <div className="hoverable gray-text date-clear">
                        {data[field] && (
                            <small
                                onClick={() => {
                                    updateDataObj(field, "");
                                }}
                            >
                                clear
                            </small>
                        )}
                    </div>
                        <Datetime
                            inputProps={{
                                placeholder: model[field].label,
                                disabled: model[field].readonly,
                                style: {
                                    color: !model[field].readonly ? "#999" : "#555",
                                },
                            }}
                            closeOnSelect={true}
                            onChange={(e) => {
                                let stringDate = e;
                                if (typeof e === "object") {
                                    stringDate = e.format("MM/DD/YYYY");
                                }
                                return updateDataObj(field, stringDate);
                            }}
                            timeFormat={false}
                            value={data[field] ? data[field] : ""}
                        />
                    </div>
            }
            if (fields[f].type === 'select') {
                console.log('got herr')
                let error = '';
                const value = data[field];
                const validator = model[field].validations;
                if (validator) {
                    if (validator.length > 0) {
                        validator.forEach((valfield) => {
                            if (validate[valfield]) {
                                const val = validate[valfield];
                                if (val) {
                                    const err = val(value);
                                    if (err) {
                                        error = err;
                                    }
                                }
                            }
                        });
                    }
                }
                return <div className="goteem">
                        <CustomSelect
                            label={model[field].label}
                            options={options ? options : model.options}
                            choose={(e, n) => {
                                updateDataObj(field, e);
                            }}
                            default={value}
                        />
                        {error ? 
                        <div className="red-text mt-10">Required</div>
                    :
                        null}
                    </div>
            }
        }
    }
    return null;
}

export default TRElement;