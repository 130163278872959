import React from "react";
import { useState, useEffect, useRef } from 'react';
import Dialog from '@material-ui/core/Dialog';
import CustomInput from "../../components/CustomInput/CustomInput.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "../../components/CustomButtons/Button.jsx";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Close from "@material-ui/icons/Close";
import ControlPoint from "@material-ui/icons/ControlPoint";
import Tooltip from "@material-ui/core/Tooltip";

const GroupsAdminMembers = (props) => {
    const [memberSearch, setMemberSearch] = useState('');
    const [outsiderSearch, setOutsiderSearch] = useState('');
    const [memberList, setMemberList] = useState([]);
    const [outsiderList, setOutsiderList] = useState([]);

    useEffect(() => {
        props.getWorkspaceGroupMembers(groupData.id);
        props.getWorkspaceUsers();
    }, []);

    useEffect(() => {
        if (props.memberList) {
            setMemberList(props.memberList);
            const indexes = props.memberList.map(m => m.id);
            if (props.userList) {
                setOutsiderList(props.userList.filter(u => {
                    if (indexes.indexOf(u.id) === -1) {
                        return true;
                    }
                }));
            }
        } else {
            setMemberList([]);
            if (props.userList) {
                setOutsiderList(props.userList);
            }
        }
    }, [props.memberList, props.userList]);

    const groupData = props.groupData;
    if (!groupData.name) return null;
    
    return (
    <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth={false}
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'right'}}>
                <Button
                    justIcon
                    style={{textAlign:'right'}}
                    round
                    simple
                    onClick={() => {
                        props.close();
                    }}
                    color="primary"
                >
                    <Close />
                </Button>
            </div>
            <div style={{textAlign:'center'}}>{groupData.name} Group ({groupData.email})</div>
        </DialogTitle>
        <div style={{paddingBottom:40,paddingLeft:40,paddingRight:40}}>
            <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                    <p>{memberList.length} group member(s).</p>
                    <div style={{marginBottom:20}}>
                        <CustomInput
                            labelText="Search current members"
                            inputProps={{
                                value:memberSearch,
                                onChange: (e) => {
                                    setMemberSearch(e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className="groupbox">
                        {memberList.length > 0 ? memberList.map(m => {
                            let name = '';
                            if (props.userList) {
                                props.userList.forEach(u => {
                                    if (u.id === m.id) {
                                        name = '- ' + u.name.givenName + ' ' + u.name.familyName;
                                    }
                                });
                            }
                            const combined = `${m.email} ${name}`;
                            const combined_lower = combined.toLowerCase();
                            const display = combined_lower.indexOf(memberSearch.toLowerCase()) !== -1 ? 'block' : 'none';
                            return (
                                <List component="nav" dense={true} key={m.id} style={{display}}>
                                    <ListItem>
                                        <Tooltip
                                            title="Delete"
                                            placement="bottom"
                                        >
                                            <Button
                                                justIcon
                                                style={{marginRight:20}}
                                                round
                                                simple
                                                onClick={() => {
                                                    props.deleteWorkspaceGroupMember(props.groupData.id, m.id, m.email);
                                                }}
                                                color="danger"
                                            >
                                                <Close />
                                            </Button>
                                        </Tooltip>
                                        {combined}
                                    </ListItem>
                                </List>
                            )
                        }) : null}
                    </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                    <p>{outsiderList.length} other user(s).</p>
                    <div style={{marginBottom:20}}>
                        <CustomInput
                            labelText="Search non-member to add"
                            inputProps={{
                                value:outsiderSearch,
                                onChange: (e) => {
                                    setOutsiderSearch(e.target.value);
                                }
                            }}
                        />
                    </div>
                    <div className="groupbox">
                        <List component="nav" dense={true} >
                        {outsiderList.length > 0 ? outsiderList.map(u => {
                            const combined = `${u.primaryEmail} - ${u.name.givenName} ${u.name.familyName}`;
                            const combined_lower = combined.toLowerCase();
                            const display = combined_lower.indexOf(outsiderSearch.toLowerCase()) !== -1 ? 'block' : 'none';
                            return (
                                <ListItem style={{display}} key={u.id}>
                                    <Tooltip
                                            title="Add"
                                            placement="bottom"
                                        >
                                        <Button
                                            justIcon
                                            style={{marginRight:20}}
                                            round
                                            simple
                                            onClick={() => {
                                                props.createWorkspaceGroupMember(groupData, {
                                                    type: 'USER',
                                                    email: u.primaryEmail,
                                                    role: 'MEMBER',
                                                    id: u.id
                                                });
                                            }}
                                            color="primary"
                                        >
                                            <ControlPoint />
                                        </Button>
                                    </Tooltip>
                                    {combined}
                                </ListItem>
                            )
                        }) : null}
                        </List>
                    </div>
                </GridItem>
            </GridContainer>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <div style={{textAlign:'center',marginTop:20}}>
                        <Button
                            color="primary"
                            onClick={() => props.close()}
                        >
                            Close
                        </Button>
                    </div>
                </GridItem>
            </GridContainer>
        </div>
    </Dialog>
    )
}

export default GroupsAdminMembers;