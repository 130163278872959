import React from "react";
import Table from "../../components/Table/Table";

const GCIHistoryTable = props => {
    console.log('GCIHistoryTable', props)
    return <>
        <Table
            tableHeaderColor="primary"
            tableHead={[
                    'Commission Levels',
                    'Percentage',
                    'Threshold'
                ]}
            tableData={props.tableData.map(td => {
                console.log('tdd', props.id, td.id);
                const style = {};
                if (props.id) {
                    if (td.id == props.id) {
                        style.color = 'red';
                    }
                }
                return [
                    <div style={style}>Level {td.commission_level}</div>,
                    <div style={style}>{td.percentage}</div>,
                    <div style={style}>{td.threshold}</div>
                ]
            })}
        />
    </>
}

export default GCIHistoryTable;