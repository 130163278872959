import React from 'react';
import { FadeLoader } from 'react-spinners';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

const Loader = (props) => {
    const style = {};
    if (props.center) {
        style.width = '100%';
    }
    return <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
            <div className="text-center mt-20 mb-20" style={style}>
                <div style={{margin:'0 auto',display:'inline-block'}}>
                    <FadeLoader />
                    {props.centerMessage && <div style={{marginTop:20}}>{props.centerMessage}</div>}
                </div>
            </div>
        </GridItem>
    </GridContainer>
}

export default Loader;