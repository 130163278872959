import React from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Datetime from 'react-datetime';
import CustomSelect from 'components/CustomSelect/CustomSelect.jsx';
import CustomInputValidate from 'components/CustomInput/CustomInputValidate.jsx';
import CustomRadio from 'components/CustomRadio/CustomRadio';
import {min1, min6, min4, min10, email, required, no_letters, no_special_chars, no_numbers, max4, max10, max15, max50, max100, max250, max500} from 'shared/validation';
import contactFields from './ContactsObj';
import TextField from '@material-ui/core/TextField';

const validate = {
	min1,
	min6,
	min4,
	min10,
	email,
	required,
	no_letters,
	no_special_chars,
	no_numbers,
	max4,
	max10,
	max15,
	max50,
	max100,
	max250,
	max500
};

const getValidations = (f) => {
	
	return contactFields[f].validations.map((v) => validate[v]);
};

const ContactsFormInformation = (props) => {
	const {updateDataObj, dataObj} = props;
	return (
		<div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<h4 className='bold'>Information</h4>
				</GridItem>
				<GridItem xs={12} sm={4} md={3}>
					<div className='custom'>
						<div className='custom relative'>
							{props.dataObj.last_attempted_contact_date && <div className='tiny-label'>Last Attempted Contact Date</div>}
							<Datetime
								inputProps={{
									placeholder: 'Last Attempted Contact Date'
								}}
								closeOnSelect={true}
								onChange={(e) => {
									if (props.action === 'View') return;
									if (typeof e === 'object') {
										const stringDate = e.format('MM/DD/YYYY');
										return props.updateDataObj('last_attempted_contact_date', stringDate);
									}
									props.updateDataObj('last_attempted_contact_date', e);
								}}
								timeFormat={false}
								value={dataObj.last_attempted_contact_date}
							/>
						</div>
					</div>
					<div className='custom'>
						<div className='custom relative'>
							{props.dataObj.last_contacted_date && <div className='tiny-label'>Last Contacted Date</div>}
							<Datetime
								inputProps={{
									placeholder: 'Last Contacted Date'
								}}
								closeOnSelect={true}
								onChange={(e) => {
									if (props.action === 'View') return;
									if (typeof e === 'object') {
										const stringDate = e.format('MM/DD/YYYY');
										return props.updateDataObj('last_contacted_date', stringDate);
									}
									props.updateDataObj('last_contacted_date', e);
								}}
								timeFormat={false}
								value={dataObj.last_contacted_date}
							/>
						</div>
					</div>
					<div style={{marginTop: 20}}>
						<CustomRadio
							label='Is Past Client?,Yes,No'
							options={['Yes', 'No']}
							onChange={(e) => {
								if (props.action === 'View') return;
								updateDataObj('is_past_client', e.target.value);
							}}
							value={dataObj.is_past_client}
						/>
					</div>
					<CustomSelect
						label='Lead Type'
						options={contactFields.lead_source_category.options}
						choose={(e, n) => {
							if (props.action === 'View') return;
							updateDataObj('lead_source_category', e);
						}}
						default={dataObj.lead_source_category}
					/>
					<CustomSelect
						label='Lead Status'
						options={contactFields.lead_status.options}
						choose={(e, n) => {
							if (props.action === 'View') return;
							updateDataObj('lead_status', e);
						}}
						default={dataObj.lead_status}
					/>
				</GridItem>
				<GridItem xs={12} sm={4} md={6}>
					<div className='custom-textfield'>
						<TextField
							label='Email Bounced Reason'
							multiline
							rowsMax='4'
							value={dataObj.email_bounced_reason}
							onChange={(e) => {
								if (dataObj.email_bounced_reason.length < 250 && props.action !== 'View') {
									props.updateDataObj('email_bounced_reason', e.target.value);
								}
							}}
							className=''
							margin='normal'
						/>
					</div>
					<div className='custom-textfield'>
						<TextField
							label='Lead Source'
							multiline
							rowsMax='4'
							value={dataObj.lead_source}
							onChange={(e) => {
								if (dataObj.lead_source.length < 250 && props.action !== 'View') {
									props.updateDataObj('lead_source', e.target.value);
								}
							}}
							className=''
							margin='normal'
						/>
					</div>
					<div className='custom-textfield'>
						<TextField
							label='Lead Source Detail'
							multiline
							rowsMax='4'
							value={props.dataObj.lead_source_detail}
							onChange={(e) => {
								if (dataObj.lead_source_detail.length < 250 && props.action !== 'View') {
									props.updateDataObj('lead_source_detail', e.target.value);
								}
							}}
							className=''
							margin='normal'
						/>
					</div>
					<div className='custom-textfield'>
						<TextField
							label='Referral Source'
							multiline
							rowsMax='4'
							value={props.dataObj.referral_source}
							onChange={(e) => {
								if (dataObj.referral_source.length < 250 && props.action !== 'View') {
									props.updateDataObj('referral_source', e.target.value);
								}
							}}
							className=''
							margin='normal'
						/>
					</div>
					<div className='custom-textfield'>
						<TextField
							label='Relocating Time Frame'
							multiline
							rowsMax='4'
							value={props.dataObj.relocating_time_frame}
							onChange={(e) => {
								if (dataObj.relocating_time_frame.length < 250 && props.action !== 'View') {
									props.updateDataObj('relocating_time_frame', e.target.value);
								}
							}}
							className=''
							margin='normal'
						/>
					</div>
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					<div className='custom-textfield'>
						<TextField
							label='Notes'
							multiline
							rowsMax='5000'
							value={dataObj.notes}
							onChange={(e) => {
								props.updateDataObj('notes', e.target.value);
							}}
							className=''
							margin='normal'
						/>
					</div>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default ContactsFormInformation;
