// NOTE : DEPRECATED 10/4/2023

import React, { useState, useEffect } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import download from 'downloadjs';

const FileLoading = (props) => {
	const {
		success,
		error,
		loading,
		rsheetFiles,
		objName,
		fileName,
		formDataObj,
	} = props;
	const [fileFailed, setFileFailed] = useState(false);

	useEffect(() => {
		if (success === 'Successfully retrieved files.') {
			if (
				rsheetFiles &&
				rsheetFiles.data &&
				!rsheetFiles.data[fileName] &&
				!loading
			) {
				setFileFailed(true);
			}
		}
		if (error === 'Failed to retrieve rsheet files.') {
			if (
				(!rsheetFiles || !rsheetFiles.data || !rsheetFiles.data[fileName]) &&
				!loading
			) {
				setFileFailed(true);
			}
		}
	}, [success, error]);

	return (
		<div>
			{fileName && (
				<div className='mt-20 mb-20'>
					{rsheetFiles && rsheetFiles.data && rsheetFiles.data[fileName] && (
						<i
							className='fas fa-download hoverable'
							onClick={() => {
								let newBuff = Buffer.from(
									JSON.parse(rsheetFiles.data[fileName]),
									'ArrayBuffer'
								).toString('binary');
								download(newBuff, fileName);
							}}
						></i>
					)}
					{!(rsheetFiles && rsheetFiles.data && rsheetFiles.data[fileName]) &&
						!(
							formDataObj &&
							formDataObj[objName] &&
							formDataObj[objName][fileName]
						) &&
						loading && <ClipLoader size={15} color={'#123abc'} />}
					{formDataObj &&
						formDataObj[objName] &&
						formDataObj[objName][fileName] && (
							<i
								class='fas fa-download hoverable'
								onClick={() => {
									if (formDataObj && formDataObj[objName]) {
										download(
											formDataObj[objName][fileName].get('file'),
											fileName
										);
									}
								}}
							></i>
						)}
					{rsheetFiles &&
						rsheetFiles.data &&
						!rsheetFiles.data[fileName] &&
						!(
							formDataObj &&
							formDataObj[objName] &&
							formDataObj[objName][fileName]
						) &&
						!loading && (
							<span style={{ color: 'Tomato' }}>
								<i class='fa fa-times' aria-hidden='true'></i>
							</span>
						)}
					{!loading && fileFailed && !rsheetFiles && (
						<span style={{ color: 'Tomato' }}>
							<i class='fa fa-times' aria-hidden='true'></i>
						</span>
					)}{' '}
					{fileName}
				</div>
			)}
		</div>
	);
};

export default FileLoading;
