import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import Loader from '../../../../components/Loader/Loader';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomInputValidate from "components/CustomInput/CustomInputValidate.jsx";
import Datetime from "react-datetime";
import Delete from "@material-ui/icons/Delete";
import Person from "@material-ui/icons/Person";
import Button from "components/CustomButtons/Button.jsx";
import Edit from "@material-ui/icons/Edit";
import Visibility from "@material-ui/icons/Visibility";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowBack from "@material-ui/icons/ArrowBack";
import { initialLeaseData, leaseFields } from './LeaseModel';
import { formatDate2, formatDateForBackend, capitalize } from "shared/utility";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LeaseModal from "./LeaseModal";
import moment from 'moment';
import Close from '@material-ui/icons/Close';
import FilesForm from './FilesForm';
import { Tabs, Tab } from "@material-ui/core";
import { clone } from 'shared/utility';
import { useSaveLease } from './LeaseLogic';
import { no_letters, } from "shared/validation";
import { getUser } from '../../../../shared/authValidation';
const validate = { no_letters };

const LeaseForm = (props) => {
    const { leaseData, action, setTab, canEdit, canDelete, setMsgsForAdd, setUserType,
        createLease, updateLease, propertyID, getLeases, deleteLease,
        error, success } = props;

    const [data, setData] = useState(initialLeaseData);
    const [formDataObj, setFormDataObj] = useState(null);
    const [openedSearchModal, setOpenedSearchModal] = useState(false);
    const [openedModal, setOpenedModal] = useState(false);
    const [peopleType, setPeopleType] = useState(null);
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');
    const [curTab, setCurTab] = useState('Info');
    const [madeChanges, setMadeChanges] = useState(false);

    useSaveLease({
        madeChanges,
        updateLease,
        data,
        setMadeChanges
    })

    const updateDataObj = (field, value) => {
        const newData = clone(data);
        newData[field] = value;
        setData(newData);
        setMadeChanges(true);
    }

    const cleanData = (data) => {
        const newLeaseData = clone(data);
        ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
            if (newLeaseData[d]) {
                newLeaseData[d] = formatDateForBackend(newLeaseData[d]);
            } else {
                delete newLeaseData[d];
            }
        });
        if (newLeaseData.other_data) {
            newLeaseData.other_data = JSON.stringify(newLeaseData.other_data);
        }
        return newLeaseData;
    }

    const doUpdate = () => {
        setMadeChanges(false);
        updateLease(cleanData(data));
    }

    const doAdd = () => {
        setMadeChanges(false);
        const newData = clone(data);
        delete newData.id;
        newData.property_id = propertyID;
        if (formDataObj) {
            newData['formDataObj'] = formDataObj;
        }
        createLease(cleanData(newData));
    }

    function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
            ref.current = value;
        });
        return ref.current;
    }

    const prevError = usePrevious(error);
    const prevSuccess = usePrevious(success);

    useEffect(() => {
        if (prevError === null && error) {
            setErrorMsg(error);
        }
    }, [error, prevError]);

    useEffect(() => {
        if (prevSuccess !== success && success && prevSuccess !== undefined) {
            setSuccessMsg(success);
            if (action === 'Add') {
                setData(clone(initialLeaseData));
                getLeases();
                setTab('List');
            }
        }
    }, [success, prevSuccess, action, getLeases, setTab]);

    useEffect(() => {
        if (data) {
            let errors = '';
            ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
                const dFormatted = capitalize(d.toLowerCase().split('_').join(' '));
                if (data[d]) {
                    const isRealDate = moment(data[d], "MM/DD/YYYY", true).isValid();
                    if (!isRealDate) {
                        errors += `${dFormatted} must be a valid MM/DD/YYYY formatted date. `;
                    }
                }
                if (d === 'start_date' || d === 'expiration_date') {
                    if (!data[d]) {
                        errors += `${dFormatted} is required. `;
                    }
                }
            });
            setErrorMsg(errors);
        }
    }, [data])

    useEffect(() => {
        if (leaseData && (action === 'Edit' || action === 'View')) {
            const newLeaseData = clone(leaseData);
            // reformat to MM/DD/YYYY
            ['start_date', 'expiration_date', 'expiration_alert_date', 'extension_date'].forEach(d => {
                if (newLeaseData[d]) newLeaseData[d] = formatDate2(newLeaseData[d]);
            })
            if (!newLeaseData.other_data) newLeaseData.other_data = {
                agents: [],
                lessees: [],
                lessors: []
            }
            setData(newLeaseData);
        } else if (leaseData && action === 'Add') {
            setData(clone(initialLeaseData));
            setSuccessMsg('');
        }
    }, [action]);

    useEffect(() => {
        setMsgsForAdd({ error: null, success: null });
        setSuccessMsg('');
    }, [])

    const cancel = () => {
        if (madeChanges && props.setOpenedAsk) {
            props.setOpenedAsk(true);
        } else {
            getLeases();
            setTab('List');
            if (props.user) {
                if (props.user.type === 'A') {
                    if (setUserType) setUserType('own');
                }
            }
        }
    }

    const getTabIndex = () => {
        if (curTab === 'Info') {
            return 0;
        }
        if (curTab === 'Files') {
            return 1;
        }
    }

    const getValidations = (f) => {
        return leaseFields[f].validations.map(v => validate[v])
    }

    let show_illi_owned_message = false;
    const user_logged_in = getUser();
    if (user_logged_in) {
        if (user_logged_in.type) {
            if (user_logged_in.type === 'A') {
                if (props.leaseData) {
                    if (props.leaseData.property_user_id === 1) {
                        show_illi_owned_message = true;
                    }
                }
            }
        }
    }


    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h3 className="inline-block">
                        {<div
                            className="inline-block blue-text hoverable mr-20"
                            onClick={() => {
                                cancel();
                            }}
                        >
                            <Tooltip title='Back to Lease List' placement='top'>
                                <ArrowBack />
                            </Tooltip>
                        </div>}
                        {action !== 'Add' && <div
                            className={`${action === 'View' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('View');
                            }}
                        >
                            <Tooltip
                                title="View Lease"
                                placement="top"
                            >
                                <Visibility />
                            </Tooltip>
                        </div>}
                        {(action !== 'Add' && canEdit) && <div
                            className={`${action === 'Edit' ? 'orange' : 'lightgray'}-text inline-block mr-20 hoverable`}
                            onClick={() => {
                                setTab('Edit');
                            }}
                        >
                            <Tooltip
                                title="Edit Lease"
                                placement="top"
                            >
                                <Edit />
                            </Tooltip>
                        </div>}
                        {action} Lease 
                        {props.property_name ? 
                            <span> for <strong>{`${props.property_name}`}</strong></span> : ''}
                        {data.name ? 
                            <span> for <strong>{`${data.name}`}</strong></span> : ''}
                    </h3>
                    {(action !== 'Add' && canDelete) && <span
                        onClick={() => {
                            setOpenedModal(true);
                        }}
                        style={{
                            marginLeft: 20,
                            marginTop: 20,
                            float: 'right',
                            fontSize: 11,
                            fontWeight: 'bold'
                        }}
                        className="red-text hoverable"
                    >DELETE LEASE</span>}
                    {show_illi_owned_message && <div>
					    {<div><strong>illi Company Data</strong></div>}
                    </div>}
                </GridItem>
                {<Tabs
                    TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
                    style={{ marginBottom: 10 }}
                    value={getTabIndex()}
                >
                    <Tab
                        onClick={() => {
                            setCurTab('Info');
                        }}
                        style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={0}
                        label='Lease Info'
                        icon={<i className="fas fa-info-circle font-22"></i>}
                    />
                    {props.propertyAction !== 'Add' && <Tab
                        onClick={() => {
                            setCurTab('Files');
                        }}
                        style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
                        tabIndex={1}
                        label='Files'
                        icon={<i className="fas fa-file font-22"></i>}
                    />}
                </Tabs>}
            </GridContainer>
            {curTab === 'Info' &&
                <div className={action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
                    <GridContainer>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.start_date && <div className="tiny-label">Start Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Start Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('start_date', stringDate);
                                        }
                                        updateDataObj('start_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.start_date ? data.start_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.expiration_date && <div className="tiny-label">Expiration Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Expiration Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('expiration_date', stringDate);;
                                        }
                                        updateDataObj('expiration_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.expiration_date ? data.expiration_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.expiration_alert_date && <div className="tiny-label">Expiration Alert Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Expiration Alert Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('expiration_alert_date', stringDate);;
                                        }
                                        updateDataObj('expiration_alert_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.expiration_alert_date ? data.expiration_alert_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={3} md={3}>
                            <div className="custom relative">
                                {data.extension_date && <div className="tiny-label">Extension Date</div>}
                                <Datetime
                                    inputProps={{
                                        placeholder: "Extension Date",
                                    }}
                                    closeOnSelect={true}
                                    onChange={(e) => {
                                        if (typeof e === 'object') {
                                            const stringDate = e.format('MM/DD/YYYY');
                                            return updateDataObj('extension_date', stringDate);;
                                        }
                                        updateDataObj('other_data.extension_date', e)
                                    }}
                                    timeFormat={false}
                                    value={data.extension_date ? data.extension_date : ''}
                                />
                            </div>
                        </GridItem>
                        <GridItem>
                            <div className='tiny-label'>Unit Number</div>
                                <CustomInputValidate
                                    label="Unit Number"
                                    value={data.other_data.unit_number}
                                    onChange={(e) => {
                                        updateDataObj('other_data', {
                                            ...data.other_data,
                                            unit_number: e.target.value
                                        });
                                    }}
                                    validate={getValidations('unit_number')}
                                />
                        </GridItem>
                        <GridItem>
                            <div className='tiny-label'>Exclusions</div>
                                <CustomInputValidate
                                    label="Exclusions"
                                    value={data.other_data.exclusions}
                                    onChange={(e) => {
                                        updateDataObj('other_data', {
                                            ...data.other_data,
                                            exclusions: e.target.value
                                        });
                                    }}
                                    validate={getValidations('exclusions')}
                                />
                        </GridItem>
                        <GridItem>
                            <div className='tiny-label'>Term</div>
                                <CustomInputValidate
                                    label="Term"
                                    value={data.other_data.term}
                                    onChange={(e) => {
                                        updateDataObj('other_data', {
                                            ...data.other_data,
                                            term: e.target.value
                                        });
                                    }}
                                    validate={getValidations('term')}
                                />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                            <hr />
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className="carded">
                                <h4>{data.other_data.agents.length} Agent(s)</h4>
                                {action !== 'View' && <Button color="primary"
                                    onClick={() => {
                                        setPeopleType('agents');
                                        setOpenedSearchModal(true);
                                    }}
                                ><i className="fa fa-plus"></i> Add Agent</Button>}
                                <List>
                                    {data.other_data.agents.map((a, i) => {
                                        return <ListItem
                                            button
                                            key={`agent-${i}`}
                                        >
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText inset primary={a.name} />
                                            <ListItemSecondaryAction>
                                                <Delete
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        const newObj = clone(data.other_data);
                                                        newObj.agents = data.other_data.agents.filter(ag => {
                                                            if (ag.id === a.id) {
                                                                return false;
                                                            }
                                                            return true;
                                                        });
                                                        updateDataObj('other_data', newObj);
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })}
                                </List>
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className="carded">
                                <h4>{data.other_data.lessees.length} Lessee(s)</h4>
                                {action !== 'View' && <Button color="primary"
                                    onClick={() => {
                                        setPeopleType('lessees');
                                        setOpenedSearchModal(true);
                                    }}
                                ><i className="fa fa-plus"></i> Add Lessee</Button>}
                                <List>
                                    {data.other_data.lessees.map((a, i) => {
                                        return <ListItem
                                            button
                                            key={`lessee-${i}`}
                                        >
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText inset primary={a.name} />
                                            <ListItemSecondaryAction>
                                                <Delete
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        updateDataObj('other_data', {
                                                            ...data.other_data,
                                                            lessees: data.other_data.lessees.filter(ag => {
                                                                if (ag.id === a.id) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            })
                                                        });
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })}
                                </List>
                            </div>
                        </GridItem>
                        <GridItem xs={3} sm={4} md={4}>
                            <div className="carded">
                                <h4>{data.other_data.lessors.length} Lessor(s)</h4>
                                {action !== 'View' && <Button color="primary"
                                    onClick={() => {
                                        setPeopleType('lessors');
                                        setOpenedSearchModal(true);
                                    }}
                                ><i className="fa fa-plus"></i> Add</Button>}
                                <List>
                                    {data.other_data.lessors.map((a, i) => {
                                        return <ListItem
                                            button
                                            key={`lessor-${i}`}
                                        >
                                            <ListItemIcon>
                                                <Person />
                                            </ListItemIcon>
                                            <ListItemText inset primary={a.name} />
                                            <ListItemSecondaryAction>
                                                <Delete
                                                    className="hoverable red-text"
                                                    onClick={() => {
                                                        updateDataObj('other_data', {
                                                            ...data.other_data,
                                                            lessors: data.other_data.lessors.filter(ag => {
                                                                if (ag.id === a.id) {
                                                                    return false;
                                                                }
                                                                return true;
                                                            })
                                                        });
                                                    }}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    })}
                                </List>
                            </div>
                        </GridItem>
                        {!props.loadingLease && <GridItem xs={12} sm={12} md={12}>
                            {(errorMsg && !successMsg) && <div className="red-text" style={{ margin: 20 }}>{errorMsg}</div>}
                            {successMsg && <div className="green-text" style={{ margin: 20 }}>{successMsg}</div>}
                        </GridItem>}
                        {props.loadingLease && <Loader />}
                        {(!props.loadingLease && (action === 'Edit' || action === 'Add')) && <GridItem xs={12} sm={12} md={12}>
                            <hr />
                            <Button color="primary"
                                disabled={errorMsg ? true : false}
                                onClick={() => {
                                    if (action === 'Edit') {
                                        doUpdate();
                                    } else {
                                        doAdd();
                                    }
                                }}
                                className="mr-20"
                            >{
                                    action === 'Edit' ? 'Update Lease' : 'Add New Lease'
                                }</Button>
                            <Button color="white"
                                onClick={() => {
                                    cancel();
                                }}
                            >CANCEL</Button>
                        </GridItem>}
                    </GridContainer>

                    {openedSearchModal && <CustomDialog
                        open={openedSearchModal}
                        close={() => { setOpenedSearchModal(false) }}
                        choose={(the_contact_data) => {
                            const newObj = clone(data.other_data);
                            newObj[peopleType] = data.other_data[peopleType].concat([{
                                id: the_contact_data.id,
                                name: the_contact_data.name
                            }]);
                            updateDataObj('other_data', newObj);
                            setOpenedSearchModal(false)
                        }}
                        create_and_choose={(success_data) => {
                            const newObj = clone(data.other_data);
                            newObj[peopleType] = data.other_data[peopleType].concat([success_data]);
                            updateDataObj('other_data', newObj);
                            setOpenedSearchModal(false)
                        }}
                        peopleType={peopleType}
                        title={`Select ${peopleType}`}
                        label={`Search ${peopleType}`}
                        current=""
                    />}
                </div>
            }

            {curTab === 'Files' &&
                <FilesForm
                    leaseData={props.leaseData}
                    fileList={props.fileList}
                    getCrmFileList={props.getCrmFileList}
                    getCrmFile={props.getCrmFile}
                    createCrmFile={props.createCrmFile}
                    deleteCrmFile={props.deleteCrmFile}
                    contactSuccess={props.contactSuccess}
                    contactError={props.contactError}
                    contactLoading={props.contactLoading}
                    downloadedFile={props.downloadedFile}
                    formDataObj={formDataObj}
                    setFormDataObj={(data) => {
                        setMadeChanges(true);
                        setFormDataObj(data);
                    }}
                    action={action}
                />
            }

            {openedModal && <div>
                <LeaseModal
                    open={openedModal}
                    close={() => {
                        setOpenedModal(false);
                    }}
                    data={data}
                    setTab={setTab}
                    error={error}
                    success={success}
                    prevSuccess={prevSuccess}
                    prevError={prevError}
                    deleteLease={deleteLease}
                    leaseID={data.id}
                    getLeases={getLeases}
                />
            </div>}
        </div>
    )
}

const mapStateToProps = state => {
	const p = state.crm_properties;
	return {
		loadingLease: p.loadingLease,
	}
}

export default connect(mapStateToProps, null)(LeaseForm);