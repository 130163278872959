import Dialog from "@material-ui/core/Dialog";
import Close from "@material-ui/icons/Close";
import Button from "components/CustomButtons/Button.jsx";
import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { LISTING_STATUS } from "./ListingObj";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ListingPdfModal = (props) => {
    const {
        canApprove,
        viewOnly,
        pdfData,
        open,
        close,
        action,
        handleDecline,
        handleApprove,
        handleFinalApprove,
    } = props;

    const downloadPDF = () => {
        const url = window.URL.createObjectURL(
            new Blob([new Uint8Array(pdfData.data).buffer])
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
            "download",
            `listing-entry-${new Date().getTime()}.pdf`
        );
        document.body.appendChild(link);
        link.click();
    };
    return (
        <div className="parentable">
            <Dialog
                open={open}
                onClose={close}
                keepMounted
                maxWidth={false}
                fullWidth={false}
            >
                <div
                    className="close-btn"
                    onClick={() => {
                        close();
                    }}
                >
                    <Close />
                </div>
                <div style={{ margin: 20 }}>
                    <ListingPdfViewer pdfData={pdfData} />
                    <br />
                    <div className="text-center bottom-dweller">
                        <Button
                            color="white"
                            style={{ marginRight: 20 }}
                            onClick={() => {
                                downloadPDF();
                            }}
                        >
                            DOWNLOAD PDF
                        </Button>
                        {!viewOnly && action === LISTING_STATUS.PENDING && (
                            <React.Fragment>
                                <Button
                                    color="danger"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleApprove();
                                        close();
                                    }}
                                >
                                    APPROVE
                                </Button>
                                <Button
                                    color="white"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleDecline();
                                        close();
                                    }}
                                >
                                    DECLINE
                                </Button>
                            </React.Fragment>
                        )}
                        {canApprove && (
                            <React.Fragment>
                                <Button
                                    color="danger"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleFinalApprove();
                                        close();
                                    }}
                                >
                                    APPROVE
                                </Button>
                                <Button
                                    color="white"
                                    style={{ marginRight: 20 }}
                                    onClick={() => {
                                        handleDecline();
                                        close();
                                    }}
                                >
                                    DECLINE
                                </Button>
                            </React.Fragment>
                        )}
                    </div>
                </div>
            </Dialog>
        </div>
    );
};

const ListingPdfViewer = (props) => {
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    const onDocumentLoadSuccess = (dat) => {
        const { numPages } = dat;
        
        setNumPages(numPages);
    };

    const renderNav = () => {
        return (
            <div className="text-center">
                <div>
                    Page {pageNumber} of {numPages}
                </div>
                <hr />
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber - 1);
                    }}
                    disabled={pageNumber === 1 ? true : false}
                    className="mr-20"
                >
                    <i className="fas fa-arrow-left"></i>
                </Button>
                <Button
                    onClick={() => {
                        setPageNumber(pageNumber + 1);
                    }}
                    disabled={pageNumber === numPages ? true : false}
                >
                    <i className="fas fa-arrow-right"></i>
                </Button>
            </div>
        );
    };

    
    return (
        <Document file={props.pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            {renderNav()}
            <div className="light-border">
                <Page pageNumber={pageNumber} width={1000} />
            </div>
            {renderNav()}
        </Document>
    );
};

export default ListingPdfModal;
