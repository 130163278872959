import { useState, useEffect } from "react";
import React from "react";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import Button from "components/CustomButtons/Button";
import Notification from "components/Notification/NotificationFixedTop";
import NotificationAsk from "components/Notification/NotificationAsk";
import { get } from "lodash";
import axios from "store/axios/axios-files";
import { makeid } from "../../shared/utility";
import { IconButton } from "@material-ui/core";
import { ClipLoader } from "react-spinners";
import CustomTextArea from "components/CustomTextArea/CustomTextArea.jsx";

// REQUIRED PROPS
// --------------
// update - function
// remove - function
// field - string field name
// the_filename <optional>
// type = "crm" or "rs"

const PhotoUploader = (props) => {
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState('');
    const [ask, setAsk] = useState(false);
    const [photoPreview, setPhotoPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');
    const [beforeNotes, setBeforeNotes] = useState('');

    console.log('PhotoUploader', props)

    const handleChange = (event) => {
        setFile(event.target.files[0])
    }

    const refresh = async (the_filename) => {
        if (the_filename) {
            const the_arr = the_filename.split(',')
            if (the_arr.length > 1) {
                the_file = the_arr[1];
                the_id = the_arr[0];
                the_date = the_arr[2] ? the_arr[2] : '';
                setLoading(true);
                try {
                    const response = await axios.get('/download?id='+the_id+'&type=crm', {responseType: 'blob'});
                    if (response.data) {
                        const src = URL.createObjectURL(response.data);
                        setPhotoPreview(src);
                    }
                    const response_filedata = await axios.get(`/data?ids=${the_id}`);
                    if (response_filedata.data) {
                        const dat = response_filedata.data;
                        if (dat.length > 0) {
                            const noty = dat[0].notes ? dat[0].notes : '';
                            setNotes(noty)
                            setBeforeNotes(noty)
                        }
                    }
                    setLoading(false);
                } catch(e) {
                    setNotification('There was an error downloading the file, please try again.');
                    setLoading(false);
                }
                
            }
        }
    }

    const uploadFile = async (data) => {
        setLoading(true);
        console.log('action', data, )
        try {
            const formData = new FormData();
            const originalname = data.file.name;
            const newname = `${makeid(5)}-${originalname}`;
            formData.append("file", data.file, newname);
            formData.append("sfiletype", data.type);
            formData.append("notes", data.notes ? data.notes : '');
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const rdata = response.data;
            const file_uploaded = get(rdata, 'file_data', null);
            const date_file_uploaded = get(rdata, 'date_created', null);
            const notes = get(rdata, 'notes', '');
            setNotes(notes);
            props.update(`${file_uploaded},${date_file_uploaded}`);
            refresh(`${file_uploaded},${date_file_uploaded}`);
        } catch(e) {
            console.log(e);
            setNotification('error uploading, please try again');
        }
        setLoading(false);
        setFile(null);
    }

    const saveNotes = async () => {
        if (notes) {
            try {
                await axios.post('/update_notes', {
                    id: the_id,
                    notes
                });
                setNotification('Updated notes');
                setTimeout(() => {
                    setNotification('');
                }, 4000);
                setBeforeNotes(notes);
            } catch(e) {
                setNotification('Error updating notes, please try again.');
            }
        }
    }

    useEffect(() => {
        const the_filename = get(props, 'the_filename', null);
        refresh(the_filename);
    }, []);

    let the_file = '';
    let the_id = '';
    let the_date = '';
    const the_filename = get(props, 'the_filename', null);
    if (the_filename) {
        const the_arr = the_filename.split(',')
        if (the_arr.length > 1) {
            the_file = the_arr[1];
            the_id = the_arr[0];
            the_date = the_arr[2] ? the_arr[2] : '';
        }
    }
    
    if (loading) return <div style={{margin:10}}><ClipLoader /></div>;

    return <div style={{position:'relative',padding:10}}>
        {props.title && <h4 style={{fontSize:15,fontWeight:'bold'}}>{props.title}</h4>}

        {(photoPreview && the_filename) && <img src={photoPreview} style={{maxWidth:'100%'}} />}

        {(props.disabled) && <div style={{marginTop:10,marginBottom:10,fontWeight:'bold'}}>{notes}</div>}

        {(the_file && props.updateNotes && !props.disabled) && <div><CustomTextArea 
            value={notes}
            onChange={(e) => {
                setNotes(e.target.value);
            }}
        />
            {(beforeNotes !== notes && the_id) && <Button size="sm" color="rose" onClick={saveNotes}>Save Notes</Button>}
        </div>}

        {(!the_file && props.update && !props.disabled) && <div>
            <div style={{marginBottom:10}}><input 
                type="file"
                onChange={handleChange}
                style={{width:'100%'}}
                accept="image/*"
            /></div>
            <div className="boxed">
                <small>Notes:</small>
                <CustomTextArea 
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value);
                    }}
                />
            </div>
            {file && <Button size={props.button_size ? 'lg' : 'sm'} color="primary" className="mr-20" onClick={() => {
                uploadFile({
                    type: props.type,
                    file,
                    notes
                })
            }}>UPLOAD</Button>}
            {props.close && <Button color="white" onClick={() => {
                props.close();
            }}>CANCEL</Button>}
        </div>}
        {the_file && <div style={{textAlign:'right'}}>
        {(props.remove && !props.disabled) && <div style={{position:'absolute',top:'-11px',right:5}}><i className="fa fa-trash red-text hoverable" style={{float:'right'}} onClick={() => {
            setAsk(true);
        }}></i></div>}

        {!props.hide_filename && <div style={{overflow:'hidden'}}><em>{the_file}</em></div>}
        {(!props.hide_date && the_date) && <div style={{overflow:'hidden'}}><em><small>Uploaded {the_date}</small></em></div>}

        <IconButton onClick={() => {
            props.getFileUpload({ id:the_id, type: props.type });
        }}><i className="fa fa-arrow-down red-text"></i></IconButton>
        </div>}

        {notification && <Notification 
            open={notification ? true : false}
            close={() => { setNotification(''); }}
            message={notification}
        />}

        {ask && <NotificationAsk 
            open={ask}
            close={() => { setAsk(false) }}
            title="Are you sure?"
            message={`Do you want to remove this photo "${the_file}" ?`}
            success={() => {
                props.remove();
                setAsk(false);
            }}
        />}
    </div>
}

const mapStateToProps = state => {
    return {
        loading: state.files.loading,
        error_upload: state.files.error_upload,
        success_upload: state.files.success_upload,
        file_uploaded: state.files.file_uploaded,
        date_file_uploaded: state.files.date_file_uploaded,
        photo_preview: state.files.photo_preview
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFileUpload: (data) => {
            dispatch(actions.createFileUpload(data));
        },
        getFileUpload: (data) => {
            dispatch(actions.getFileUpload(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PhotoUploader);