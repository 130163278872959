import React, { useRef, useEffect } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import PhotoUploader from "components/FileUploader/PhotoUploader";
import Button from "../../../components/CustomButtons/Button";

const PropertiesFormPhotos = props => {
    const { updateDataObj, dataObj, action } = props;
    const divRef = useRef(null);

    const additional_images = dataObj.additional_image_files ? JSON.parse(dataObj.additional_image_files) : [];

    return <>
        <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
                <h3>Featured Image</h3>
                <PhotoUploader 
                    disabled={props.action === 'View' ? true : false}
                    title=""
                    update={(val) => {
                        updateDataObj('featured_image_file', val, true);
                    }}
                    remove={() => {
                        updateDataObj('featured_image_file', '', true);
                    }}
                    the_filename={dataObj.featured_image_file}
                    type="crm"
                    canEdit={props.canEdit}
                    updateNotes={props.updateNotes}
                />
                {(!dataObj.featured_image_file && action === 'View') && <div><em>- None -</em></div>}
            </GridItem>
        </GridContainer>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                <hr />
                <h3>Additional Image(s)</h3>
                {props.action !== 'View' && <Button
                    color="rose"
                    onClick={() => {
                        let arr = dataObj.additional_image_files ? JSON.parse(dataObj.additional_image_files) : [];
                        arr.push({
                            file: ''
                        })
                        updateDataObj('additional_image_files', JSON.stringify(arr));
                        divRef.current.scrollIntoView({ behavior: 'smooth' });
                    }}
                >Add</Button>}
                {!dataObj.additional_images && action !== 'View'}
                <GridContainer>
                {additional_images.length === 0 && <div><em>- None -</em></div>}
                {additional_images.map((aif,x) => {
                    return <GridItem xs={4} sm={4} md={4} key={`aif-${x}`}>
                            <div style={{padding:20,border:aif.file ? 'none' : '1px solid #da3b2f'}}>
                                <PhotoUploader 
                                    disabled={props.action === 'View' ? true : false}
                                    title={aif.file ? `Photo #${x+1}` : ''}
                                    update={(val) => {
                                        const arr = JSON.parse(dataObj.additional_image_files).map((aiff,xx) => {
                                            if (xx === x) {
                                                return {
                                                    file: val
                                                }
                                            }
                                            return aiff;
                                        })
                                        updateDataObj('additional_image_files', JSON.stringify(arr), true);
                                    }}
                                    remove={() => {
                                        const arr = JSON.parse(dataObj.additional_image_files).filter((aiff,xx) => {
                                            if (xx !== x) return true;
                                        });
                                        updateDataObj('additional_image_files', JSON.stringify(arr), true);
                                    }}
                                    the_filename={aif.file}
                                    type="crm"
                                    canEdit={props.canEdit}
                                    updateNotes={props.updateNotes}
                                />
                            </div>
                    </GridItem>
                })}
                <div ref={divRef}></div>
                </GridContainer>
            </GridItem>
        </GridContainer>
    </>
}

export default PropertiesFormPhotos;