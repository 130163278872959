import {put} from 'redux-saga/effects';
import axios from '../axios/axios-crm';
import * as actions from '../actions/crm_appointments';

export function* getAppointmentsSaga(action) {
	yield put(actions.getAppointmentsStart());
	try {
		let url = `/appointments?oo=1`;
		if (action.params) {
			const fields = Object.keys(action.params);
			for (let i = 0; i < fields.length; i++) {
				const f = fields[i];
				if (action.params[f]) url += `&${f}=${action.params[f]}`;
			}
		}
		console.log('url', url)
		const response = yield axios.get(url);
		yield put(actions.getAppointmentsSuccess(response.data));
	} catch (err) {
		yield put(actions.getAppointmentsFail(err));
	}
}

export function* getAppointmentSaga(action) {
	yield put(actions.getAppointmentStart());
	try {
		let url = `/appointment/?id=${action.id}`;
		const response = yield axios.get(url);
		yield put(actions.getAppointmentSuccess(response.data));
	} catch (err) {
		yield put(actions.getAppointmentFail(err));
	}
}

export function* createAppointmentSaga(action) {
	yield put(actions.createAppointmentStart());
	try {
		const response = yield axios.post(`/appointment`, action.data);
		
		yield put(actions.createAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.createAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.createAppointmentFail(`Failed to create appointment`));
	}
}

export function* updateAppointmentSaga(action) {
	yield put(actions.updateAppointmentStart());
	try {
		const response = yield axios.put(`/appointment`, action.data);
		yield put(actions.updateAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.updateAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.updateAppointmentFail(`Failed to update appointment`));
	}
}

export function* deleteAppointmentSaga(action) {
	yield put(actions.deleteAppointmentStart());
	try {
		const response = yield axios.delete(`/appointment/?id=${action.id}`);
		yield put(actions.deleteAppointmentSuccess(response.data));
	} catch (err) {
		if (err.response) {
			if (err.response.data) {
				
				return yield put(actions.deleteAppointmentFail(err.response.data));
			}
		}
		return yield put(actions.deleteAppointmentFail(`Failed to update appointment`));
	}
}

export function* getCalendarsSaga(action) {
    yield put(actions.getCalendarsStart());
    try {
        const response = yield axios.get('/calendars');
        yield put(actions.getCalendarsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getCalendarsFail(e.response.data));
            }
          }
        yield put(actions.getCalendarsFail(e));
    }
}


export function* updateDefaultCalendarSaga(action) {
    yield put(actions.updateDefaultCalendarStart());
    try {
        const response = yield axios.put('/default_calendar', action.data);
        yield put(actions.updateDefaultCalendarSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateDefaultCalendarFail(e.response.data));
            }
          }
        yield put(actions.updateDefaultCalendarFail(e));
    }
}
