
import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import contactFields from "./CompaniesObj";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import CustomRadio from "components/CustomRadio/CustomRadio";
import Datetime from "react-datetime";

const CompaniesFormPreferences = (props) => {
    const { updateDataObj, dataObj } = props;
    return (
        <div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <h4 className="bold">Preferences</h4>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomRadio
                        label="Do Not Call,Yes,No"
                        options={['Yes','No']}
                        onChange={(e) => {
                            if (props.action === 'View') return;
                            updateDataObj('do_not_call', e.target.value);
                        }}
                        value={dataObj.do_not_call}
                    />
                    <CustomRadio
                        label="Looking to Buy?,Yes,No"
                        options={['Yes','No']}
                        onChange={(e) => {
                            if (props.action === 'View') return;
                            updateDataObj('looking_to_buy', e.target.value);
                        }}
                        value={dataObj.looking_to_buy}
                    />
                    <CustomRadio
                        label="Opted out of Email?,Yes,No"
                        options={['Yes','No']}
                        onChange={(e) => {
                            if (props.action === 'View') return;
                            updateDataObj('opted_out_of_email', e.target.value);
                        }}
                        value={dataObj.opted_out_of_email}
                    />
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>
                    <CustomSelect
                        label="Preferred Contact Method"
                        options={contactFields.preferred_contact_method.options}
                        choose={(e, n) => {
                            
                            if (props.action === 'View') return;
                            updateDataObj('preferred_contact_method', e);
                        }}
                        default={dataObj.preferred_contact_method}
                    />
                    <CustomSelect
                        label="Price Range"
                        options={contactFields.price_range.options}
                        choose={(e, n) => {
                            
                            if (props.action === 'View') return;
                            updateDataObj('price_range', e);
                        }}
                        default={dataObj.price_range}
                    />
                    <div className="custom">
                        <div className="custom relative">
                            {dataObj.preferred_contact_time && 
                                <div className="tiny-label">Preferred Contact Start Time</div>}
                            <Datetime
                                inputProps={{
                                    placeholder: "Preferred Contact Start Time",
                                }}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    if (props.action === 'View') return;
                                    if (typeof e === 'object') {
                                        props.updateDataObj('preferred_contact_time', e.format("hh:mm A"))
                                    } else {
                                        props.updateDataObj('preferred_contact_time', e)
                                    }
                                }}
                                timeFormat={true}
                                dateFormat={false}
                                value={dataObj.preferred_contact_time}
                            />
                        </div>
                    </div>
                    <div className="custom">
                        <div className="custom relative">
                            {dataObj.preferred_contact_endtime && 
                                <div className="tiny-label">Preferred Contact End Time</div>}
                            <Datetime
                                inputProps={{
                                    placeholder: "Preferred Contact End Time",
                                }}
                                closeOnSelect={true}
                                onChange={(e) => {
                                    if (props.action === 'View') return;
                                    if (typeof e === 'object') {
                                        props.updateDataObj('preferred_contact_endtime', e.format("hh:mm A"))
                                    } else {
                                        props.updateDataObj('preferred_contact_endtime', e)
                                    }
                                }}
                                timeFormat={true}
                                dateFormat={false}
                                value={dataObj.preferred_contact_endtime}
                            />
                        </div>
                    </div>
                </GridItem>
                <GridItem xs={12} sm={4} md={3}>

                </GridItem>
            </GridContainer>
        </div>
    );
}

export default CompaniesFormPreferences;