import React, { useState, useEffect } from "react";
import InvoiceFormInfo from "./Steps/InvoiceFormInfo";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button";
import { ArrowBack, ArrowForward } from "@material-ui/icons";
import { generateSections } from "./InvoiceObj";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import InvoiceRoutingSheet from "./Steps/InvoiceRoutingSheet";
import InvoiceTerms from './Steps/InvoiceTerms';
import { clone } from 'lodash';
import CloseComponent from '../../components/Close/Close';

const InvoiceFormWizard = (props) => {
    console.log('InvoiceFormWizard', props)
    const {
        closeForm,
        fieldModels,
        formData,
        setAllowToSubmit,
        setFormData,
        setTab,
        updateDataObj,
        routingSheet,
        setRoutingSheet,
    } = props;

    const [currForm, setCurrForm] = useState(1);
    const [sections, setSections] = useState([]);

    useEffect(() => {
        setSections(generateSections(formData, routingSheet, setAllowToSubmit, fieldModels));
    }, [formData]);

    const renderNav = () => {
        return (
            <div style={{ marginBottom: 10 }}>
                <CloseComponent close={() => closeForm()} />
                <Button
                    style={{ marginRight: 20 }}
                    onClick={() => {
                        setCurrForm(currForm - 1);
                    }}
                    disabled={currForm === 1 ? true : false}
                >
                    <ArrowBack /> Previous
                </Button>
                <Button
                    className="mr-20"
                    onClick={() => {
                        if (currForm !== 2) return setCurrForm(currForm + 1);
                        setTab("full");
                    }}
                >
                    Next <ArrowForward />
                </Button>
            </div>
        );
    };

    return (
        <div>
            <GridContainer>
                <GridItem xs={8} sm={8} md={8}>
                    {renderNav()}
                   
                    {currForm === 1 && (
                        <InvoiceRoutingSheet
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            routingSheet={routingSheet}
                            setRoutingSheet={setRoutingSheet}
                            permissions={props.permissions}
                        />
                    )}
                     {currForm === 2 && (
                        <InvoiceFormInfo
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            permissions={props.permissions}
                        />
                    )}
                    {/* {currForm === 3 && (
                        <InvoiceTerms
                            formData={formData}
                            setFormData={setFormData}
                            updateDataObj={updateDataObj}
                            routingSheet={routingSheet}
                            setRoutingSheet={setRoutingSheet}
                        />
                    )} */}
                    {renderNav()}
                </GridItem>

                <GridItem xs={12} sm={3} md={3}>
                    {/* Tracker */}
                    <List component="nav">
                        {sections.map((section, index) => {
                            return (
                                <ListItem
                                    key={`li-${index}`}
                                    button
                                    onClick={() => {
                                        setCurrForm(index + 1);
                                    }}
                                >
                                    <ListItemIcon>
                                        {section.complete ? (
                                            <i
                                                className={`fas fa-check font-22 ${
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }`}
                                            ></i>
                                        ) : (
                                            <i
                                                className={`fas fa-times font-22 ${
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }`}
                                            ></i>
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        inset
                                        primary={
                                            <div
                                                className={
                                                    currForm - 1 === index
                                                        ? "bold red-text"
                                                        : ""
                                                }
                                            >
                                                {section.name}
                                            </div>
                                        }
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                </GridItem>
            </GridContainer>
        </div>
    );
};

export default InvoiceFormWizard;
