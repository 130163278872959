import React from 'react';
import UserMatrix from "containers/UserPerms/UserMatrix";
import NavPills from "components/NavPills/NavPills3.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import permsObj from "shared/permsObj";

const UserSubPermissions = props => {
  const { classes } = props;
  let collection1 = [],
      collection2 = [],
      isAllMainChecked = true;
  Object.keys(permsObj[props.main]).forEach((po) => {
    const arr = permsObj[props.main][po];
    
    arr.forEach((a) => {
      if (a.hasDBs) {
        collection1.push(a.id);
      } else {
        collection2.push(a.id);
      }
    });
  });

  collection1.forEach((co) => {
    // if (props.permObj.hasOwnProperty(co)) {
    //   if (props.permObj[co].length !== dbList.length) {
    //     isAllMainChecked = false;
    //   }
    // }
  });

  // collect all present [null] in permObj
  const allNoDBs = Object.keys(props.permObj).filter((po) => {
    if (props.permObj[po][0] === null) {
      return true;
    }
    return false;
  }).map(andd => parseInt(andd));

  collection2.forEach((co2) => {
    if (allNoDBs.indexOf(parseInt(co2)) === -1) {
      isAllMainChecked = false;
    }
  })

  return (
  <div className="notopmargin-navpill">
    <div style={{marginBottom:'20px',marginLeft:'8px'}}>Select ALL {props.main} Permissions
      <Checkbox
        tabIndex={-1}
        checkedIcon={<Check className={classes.checkedIcon} />}
        icon={<Check className={classes.uncheckedIcon} />}
        classes={{checked: classes.checked}}
        checked={isAllMainChecked}
        onClick={() => {
          let collection_nodbs = [],
              collection_dbs = [],
              newPermsObj = JSON.parse(JSON.stringify(props.permObj));

          Object.keys(permsObj[props.main]).forEach((po) => {
            const arr = permsObj[props.main][po];
            arr.forEach((a) => {
              if (a.hasDBs) {
                collection_dbs.push(a.id);
              } else {
                collection_nodbs.push(a.id);
              }
            });
          });

          collection_nodbs.forEach((co) => {
            if (isAllMainChecked) {
              delete newPermsObj[co];
            } else {
              newPermsObj[co] = [null];
            }
          });

          collection_dbs.forEach((co) => {
            
          });

          props.choosePerm(newPermsObj);
        }}
      />
    </div>
    <NavPills
      color="rose"
      tabs={Object.keys(permsObj[props.main]).map((sub) => {
        
        return {
          tabButton: sub,
          tabContent: <UserMatrix
                actions={permsObj[props.main][sub]}
                choosePerm={props.choosePerm}
                permObj={props.permObj}
              />
        }
      })}
    />
  </div>
  );
}

export default withStyles(styles)(UserSubPermissions);
