import React, { useEffect, useState } from "react";
import { TextField } from "@material-ui/core";
import { clone } from "lodash";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { createDocusignObj, generatePDF } from "./CommissionSplitsLogic";
import { generateSections } from "./CommissionSplitsLogic";
import Button from "components/CustomButtons/Button";
import CommissionSplitsPDFPreview from "./CommisionsSplitsPDFPreview";
import { statusDescriptionsDict } from "./CommissionSplitsLogic";

const CommissionSplitsAction = props => {
    const [openedPDFPreview, setOpenedPDFPreview] = useState(false);
    const [loadedPDF, setLoadedPDF] = useState(false);

    const { data, history, currentUserIsAnAgentApprover, listTab } = props;

    useEffect(() => {
        if (props.pdf_buffer && loadedPDF) {
            setOpenedPDFPreview(true);
            setLoadedPDF(false);
            // set PDF buffer to null
        }
    }, [props.pdf_buffer]);

    const permissionsArr = props.permissions ? Object.keys(props.permissions) : [];

    const submit = (action, newDocusignObj) => {
        if (newDocusignObj) {
            props.save(
                null, 
                {
                    action: statusDescriptionsDict[action],
                    message: data.notes ? data.notes : ''
                },
                true, 
                action,
                newDocusignObj
            )
        } else {
            props.save(
                null, 
                {
                    action: statusDescriptionsDict[action],
                    message: data.notes ? data.notes : ''
                },
                true, 
                action,
                newDocusignObj
            )
        }
    }

    console.log('datt', data, permissionsArr);

    return <div>
            {!props.noInternalNotes &&
                <div>
                    <TextField
                        style={{ width: "100%" }}
                        id="multiline-flexible-notes"
                        label="Internal Notes"
                        multiline
                        rowsMax="4"
                        value={data.internal_notes ? data.internal_notes : ''}
                        onChange={(e) => {
                            props.updateDataObj("internal_notes", e.target.value);
                        }}
                        margin="normal"
                    />
                    <hr />
                </div>
            }
            <Button
                color="primary"
                className="mr-20"
                onClick={() => {
                    setLoadedPDF(true);
                    props.createCommissionSplitsPdf({
                        htmlContent: generatePDF(data),
                    });
                }}
            >
                PREVIEW PDF
            </Button>
            {(!props.viewOnly && !currentUserIsAnAgentApprover && listTab !== 'pending_signature')  && (
                <Button
                    color="white"
                    className="mr-20"
                    onClick={() => {
                        if (!data.property_id || !data.project_name) {
                            return props.setNotification('Please select a property to begin saving.')
                        }
                        props.save();
                    }}
                >
                    SAVE
                </Button>
            )}

            {(!props.viewOnly && !currentUserIsAnAgentApprover && listTab !== 'pending_signature') && (
                <Button
                    color="success"
                    className="mr-20"
                    onClick={() => {
                        const sections = generateSections(data, history);
                        let errors = [];
                        sections.forEach(section => {
                            if (section.errors.length > 0) {
                                errors = errors.concat(clone(section.errors));
                            }
                        })
                        const emails = data.contacts.map(c => c.email).concat([
                            data.final_approver_email
                        ]);
                        console.log('email check', emails);
                        const emailsObj = {};
                        let hasDuplicateEmails = false;
                        emails.forEach(em => {
                           if (emailsObj[em]) {
                                hasDuplicateEmails = true;
                           } else { 
                                emailsObj[em] = 1;
                           }
                        })
                        if (hasDuplicateEmails) errors.push('All emails must be unique');
                        console.log('errors', errors);
                        if (errors.length > 0) {
                            return props.setNotification(errors.map((e,i) => {
                                return <div key={`n-${i}`}>
                                    {e}
                                </div>
                            }))
                        }
                        if (permissionsArr.indexOf('approve_level_2') !== -1) {
                            const newDocusignObj = createDocusignObj(data);
                            submit('Approved, Sent for Docusign Signature', newDocusignObj);
                        } else if (permissionsArr.indexOf('approve_level_1') !== -1) {
                            submit('Submitted to Final Approver', { status: 2 });
                        } else {
                            submit('Submitted to Approver Level 1', { status: 10 });
                        }
                    }}
                >
                    {(permissionsArr.indexOf('approve_level_2') !== -1 || permissionsArr.indexOf('approve_level_1') !== -1)  ? 
                        permissionsArr.indexOf('approve_level_2') !== -1 ? 
                            'SEND FOR SIGNATURE' 
                        :
                            'SEND TO FINAL APPROVAL'
                    : 
                        'SUBMIT'}
                </Button>
            )}

            {!props.viewOnly && 
                listTab !== 'pending_signature' && 
                props.tab !== "my_drafts" && 
                props.tab !== "user_drafts" && 
                (permissionsArr.indexOf('approve_level_2') !== -1 || permissionsArr.indexOf('approve_level_1') !== -1) &&
                (
                <Button
                    color="white"
                    className="mr-20"
                    onClick={() => {
                        if (permissionsArr.indexOf('approve_level_2') !== -1) {
                            submit('Rejected by Final Approver', { status: 7 });
                        } else if (permissionsArr.indexOf('approve_level_1') !== -1) {
                            submit('Rejected by Approver Level 1', { status: 6 });
                        }
                    }}
                >
                    DENY
                </Button>
            )}

            {openedPDFPreview && (
                <CommissionSplitsPDFPreview
                    close={() => {
                        setOpenedPDFPreview(false);
                    }}
                    open={openedPDFPreview}
                    pdfData={props.pdf_buffer ? props.pdf_buffer : null}
                />
            )}
    </div>
}

const mapStateToProps = (state) => {
    return {
        error_pdf: state.commission_splits.error_pdf,
        success_pdf: state.commission_splits.success_pdf,
        pdf_buffer: state.commission_splits.pdf_buffer,
        loading_pdf: state.commission_splits.loading_pdf
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createCommissionSplitsPdf: (params) => {
            dispatch(actions.createCommissionSplitsPdf(params));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommissionSplitsAction);