import React from 'react';
import UserSubBulkMatrix from "containers/UserPerms/UserSubBulkMatrix";
import NavPills from "components/NavPills/NavPills3.jsx";
import Checkbox from '@material-ui/core/Checkbox';
import Check from '@material-ui/icons/Check';
import { withStyles } from '@material-ui/core/styles';
import styles from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.jsx";
import permsObj from "shared/permsObj";

const UserSubBulkFilter = props => {
  const { classes } = props;
  let collection1 = [],
      collection2 = [],
      isAllMainChecked = true;
  Object.keys(permsObj[props.main]).forEach((po) => {
    const arr = permsObj[props.main][po];
    
    arr.forEach((a) => {
      if (a.hasDBs) {
        collection1.push(a.id);
      } else {
        collection2.push(a.id);
      }
    });
  });

  collection1.forEach((co) => {
    // if (props.filterObj.hasOwnProperty(co)) {
    //   if (props.filterObj[co].length !== dbList.length) {
    //     isAllMainChecked = false;
    //   }
    // }
  });

  // collect all present [null] in permObj
  const allNoDBs = Object.keys(props.filterObj).filter((po) => {
    if (props.filterObj[po][0] === null) {
      return true;
    }
    return false;
  }).map(andd => parseInt(andd));

  collection2.forEach((co2) => {
    if (allNoDBs.indexOf(parseInt(co2)) === -1) {
      isAllMainChecked = false;
    }
  })

  return (
  <div className="notopmargin-navpill">
    <NavPills
      color="rose"
      tabs={Object.keys(permsObj[props.main]).map((sub) => {
        return {
          tabButton: sub,
          tabContent: <UserSubBulkMatrix
                actions={permsObj[props.main][sub]}
                chooseFilter={props.chooseFilter}
                chooseBulk={props.chooseBulk}
                chooseRemove={props.chooseRemove}
                filterObj={props.filterObj}
                bulkObj={props.bulkObj}
                removeObj={props.removeObj}
              />
        }
      })}
    />
  </div>
  );
}

export default withStyles(styles)(UserSubBulkFilter);
