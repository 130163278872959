import React from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import propertyFields from './PropertiesObj';

const PropertiesFormStages = (props) => {
    const { updateDataObj, dataObj } = props;
    
    return (
    <div className={props.action === 'View' ? 'no-click lightgray-bg enclosure' : 'enclosure'}>
        <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                <CustomSelect
                    label={propertyFields.purchasing_stage.label}
                    options={propertyFields.purchasing_stage.options}
                    choose={(e, n) => {
                        if (props.action === 'View') return;
                        updateDataObj('purchasing_stage', e);
                    }}
                    default={dataObj.purchasing_stage}
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomSelect
                    label={propertyFields.selling_stage.label}
                    options={propertyFields.selling_stage.options}
                    choose={(e, n) => {
                        if (props.action === 'View') return;
                        updateDataObj('selling_stage', e);
                    }}
                    default={dataObj.selling_stage}
                />
            </GridItem>
            <GridItem xs={12} sm={3} md={3}>
                <CustomSelect
                    label={propertyFields.leasing_stage.label}
                    options={propertyFields.leasing_stage.options}
                    choose={(e, n) => {
                        if (props.action === 'View') return;
                        updateDataObj('leasing_stage', e);
                    }}
                    default={dataObj.leasing_stage}
                />
            </GridItem>
        </GridContainer>
    </div>
    )
}

export default PropertiesFormStages;