import React, { useEffect, useState } from "react";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { clone } from "shared/utility";
import * as actions from "store/actions";
import moment from "moment";
import Element from "../ListingElement";
import Table from "components/Table/Table";
import CustomDialogWithERTL from "../../../components/CustomDialog/CustomDialogWithERTL";
import { connect } from "react-redux";
import { getStatusType } from "../../ERTL/FormWizard/ERTLModel";
import { IconButton } from "@material-ui/core";

const Step2 = (props) => {
    const {
        ertl,
        setErtl,
        entriesData,
        formData,
        getEntries,
        setFormData,
        updateDataObj,
        fieldModels,
        setCurrForm,
    } = props;

    const [openedDialog, setOpenedDialog] = useState(false);

    const openDialog = () => setOpenedDialog(true);
    const closeDialog = () => setOpenedDialog(false);

    useEffect(() => {
        getEntries();
    }, []);

    useEffect(() => {
        if (ertl) {
            const newFormData = clone(formData);
            newFormData.signed = "Yes";
            setFormData(newFormData);
        }
    }, [ertl]);

    return (
        <div>
            <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    <Button color="primary" onClick={openDialog}>
                        SELECT ERTL
                    </Button>
                    {!ertl && (
                        <Button
                            style={{ marginLeft: 10 }}
                            color="white"
                            onClick={() => {
                                const newFormData = clone(formData);
                                newFormData.signed = "No";
                                setFormData(newFormData);
                                setCurrForm(2);
                            }}
                        >
                            GENERATE WITHOUT ERTL
                        </Button>
                    )}
                    <hr />

                    {ertl && (
                        <React.Fragment>
                            <h3 className="text-center">ERTL Data</h3>
                            <IconButton
                                onClick={() => setErtl(null)}
                                style={{ display: "block", marginLeft: "auto" }}
                            >
                                <i className="fas fa-trash-alt red-text"></i>
                            </IconButton>

                            <div style={{ lineHeight: 1.5 }} className="mt-20">
                                <Table
                                    tableData={[
                                        ["Owners", "owners"],
                                        ["Address", "address"],
                                        ["Status", "status"],
                                        ["Submitter", "name"],
                                        ["Approver", "approver"],
                                    ].map((m) => {
                                        const { data } = ertl;
                                        const output = {
                                            owners: (
                                                <div>
                                                    {data.p3_owner_contact_names
                                                        ? data.p3_owner_contact_names
                                                              .split(",")
                                                              .map((n, i) => {
                                                                  return (
                                                                      <div
                                                                          key={`${n}-contact`}
                                                                      >
                                                                          {n}
                                                                          <br />
                                                                          {data.p3_owner_contact_emails
                                                                              ? data.p3_owner_contact_emails.split(
                                                                                    ","
                                                                                )[
                                                                                    i
                                                                                ]
                                                                              : null}
                                                                      </div>
                                                                  );
                                                              })
                                                        : null}
                                                </div>
                                            ),
                                            address: `${data.p1_property_address1} ${data.p1_property_address2} ${data.p1_property_city} 
											${data.p1_property_state} ${data.p1_property_zipcode}`,
                                            submitter: ertl.name,
                                            status: getStatusType(ertl.status),
                                            approver: (
                                                <div>
                                                    {data.p1_writer_name
                                                        ? data.p1_writer_name
                                                        : null}
                                                    <br />
                                                    {data.p1_writer_email
                                                        ? data.p1_writer_email
                                                        : null}
                                                </div>
                                            ),
                                        };

                                        return [
                                            <div style={{ textAlign: "right" }}>
                                                <strong>{m[0]}</strong>
                                            </div>,
                                            output[m[1]],
                                        ];
                                    })}
                                />
                            </div>
                        </React.Fragment>
                    )}
                </GridItem>
                <GridItem xs={12} sm={12} md={12}></GridItem>
            </GridContainer>

            <CustomDialogWithERTL
                open={openedDialog}
                close={closeDialog}
                title="Select ERTL"
                label="Search for ERTL"
                choose={(data) => {
                    setErtl(data);
                    closeDialog();
                }}
                options={entriesData}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        entriesData: state.ertl_entries.entriesData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getEntries: () => dispatch(actions.getEntries()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2);
