import { SECTION, FIELDS } from "../RSheetsFieldsModel";
import { SUBFIELDS } from "../RSheetsSubfieldsModel";
import { getInitialSubFieldsData, getInitialData } from "../../../shared/utility";

export const consultingFields = FIELDS.Consulting;

export const subFields = SUBFIELDS.Consulting;

export const sectionNames = SECTION.Consulting.names;

export const sectionRequirements = SECTION.Consulting.requirements;

export const initialData = getInitialData(consultingFields);

export const initialLesseeData = getInitialSubFieldsData(subFields.lessee);

export const initialLessorData = getInitialSubFieldsData(subFields.lessor);
