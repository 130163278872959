import React, { useEffect, useState, useCallback } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import _, { property } from 'lodash';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import { clone, cloneJ } from '../../shared/utility';
import Person from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Tabs, Tab } from '@material-ui/core';
import CustomDialogWithCompany from './CustomDialogWithCompany';
import CustomDialogWithContact from './CustomDialogWithContact';
import { Checkbox } from '@material-ui/core';
import CustomSelect from '../CustomSelect/CustomSelect';
import CustomNativeSelect from '../CustomSelect/CustomNativeSelect';
import Notification from '../Notification/Notification';
import { get, isEmpty } from 'lodash';
import { createAddressObject } from '../../containers/CRM/Properties/PropertiesLogic';

const getContactDataAddress = (cd) => {
	const obj = {};
	if (!cd) return obj;
	if (cd.addresses) {
		for (let i = 0; i < cd.addresses.length; i++) {
			const cda = cd.addresses[i];
			if (cda.type === 'M') {
				obj.address1 = cda.street1;
				obj.address2 = cda.street2;
				obj.city = cda.city;
				obj.state = cda.state;
				obj.zip = cda.zip;
				break;
			}
		}
	}
	return obj;
}

const CustomDialogChooser = (props) => {
	const [overrideContactPhone, setOverrideContactPhone] = useState(null);
	const [hadChosen, setHadChosen] = useState(false);
	const [tab, setCurrentTab] = useState(props.no_company ? 'Contact' : '');
	const [target, setTarget] = useState(false); // wait for data results
	const [theDba, setTheDba] = useState(''); // company DBA
	const [theContactDba, setTheContactDba] = useState('') // contact DBA
	const [openedChooseContactDba, setOpenedChooseContactDba] = useState(null);
	const [notification, setNotification] = useState('');
	const [data, setData] = useState({
		company_data: null,
		signers: [],
		property: null,
		dbas: [],
		dbaChosen: theDba,
		care_of_company_id: null
	});
	const [contactData, setContactData] = useState(null);
	const [currentCompany, setCurrentCompany] = useState(null);
	const [currentContact, setCurrentContact] = useState(null);
	const {
		companyData,
		companyContactsData,
		companyPropertiesData,
		companyDbas,
		getCompany,
		getCompanyContacts,
		getCompanyProperties,
		getCompanyDbas,
		no_property,
		allowNoContacts,
		allowMultipleContacts = true,
		singleContactOnly,
		skipDba,
		getContactCompanies,
		contactCompanies,
		showContactCompaniesTab,
		getCompanyEntities,
		queryFor,
		exclusive_right_to_represent_buyer
	} = props;

	const contactDataAddress = getContactDataAddress(contactData);

	useEffect(() => {
		if (companyContactsData && target) {
			if (companyData) {
				if (!props.noCompanyContacts) {
					setCurrentTab('Company Contacts');
					const newData = clone(data);
					newData.company_data = companyData;
					setTarget(false);
				} else {
					const newData = clone(data);
					if (!newData.company_data) {
						newData.company_data = companyData;
					}
					props.choose(newData);
					props.close();
				}
			}
		}
	}, [companyContactsData, companyData]);

	const getTabIndex = () => {
		if (tab === 'Contact') return 0;
		if (tab === 'Company') return 1;
		if (tab === 'Company Contacts') return 2;
		if (tab === 'Contact Companies') return 3;
		return 2;
	};

	const choose_company = (o) => {
		setTarget(true);
		setCurrentCompany(o);
		getCompany(o.id);
		getCompanyContacts(o.id);
		getCompanyProperties(o.id);
		getCompanyDbas(o.id);
		if (props.queryFor) {
			if (props.queryFor === 'billing_information') {
				getCompanyEntities(o.id);
			}
		}
	};

	const isInData = (id) => {
		let found = false;
		data.signers.forEach((s) => {
			if (s.id === id) found = true;
		});
		return found;
	};

	const choose = (chosen) => {
		console.log('chosen', chosen);
		if (showContactCompaniesTab) {
			setContactData(chosen);
			getContactCompanies(chosen.id); // fetch contact's related companies
			setCurrentTab('Contact Companies');
			props.getContactEntities(chosen.id); // fetch contact's address entities
			setCurrentContact(chosen);
		} else {
			console.log('Choosing contact -->', chosen);
			const dbas = get(chosen, 'dbas', []).filter(dba => dba.active !== 0);
			if (dbas.length > 0 && !skipDba) {
				setOpenedChooseContactDba({ chosen, dbas });
			} else {
				props.choose(chosen);
			}
		}
	};

	let propertyManagerChoices = null;
	// FOR ROUTING SHEETS
	if (props.queryFor === 'billing_information' && props.company_entities && currentCompany) {
		if (props.company_entities[currentCompany.id]) {
			if (props.company_entities[currentCompany.id].length > 0) {
				const pm_choices = props.company_entities[currentCompany.id].filter(ce => {
					if (ce.entity_type === 'P') return true;
				});
				propertyManagerChoices = pm_choices.length > 0 ? cloneJ(pm_choices) : null;
			}
		}
	}
	if (props.queryFor === 'billing_information' && props.contact_entities && currentContact) {
		console.log('chc', props.contact_entities[currentContact.id], currentContact);
		if (props.contact_entities[currentContact.id]) {
			if (props.contact_entities[currentContact.id].length > 0) {
				const pm_choices = props.contact_entities[currentContact.id].filter(ce => {
					if (ce.entity_type === 'P') return true;
				});
				propertyManagerChoices = pm_choices.length > 0 ? cloneJ(pm_choices) : null;
			}
		}
	}

	console.log('CustomDialogChooser', props, data, propertyManagerChoices);
	console.log('Current', currentContact, currentCompany, theContactDba);

	let contactDataOptions = [];
	if (contactData) {
		const { main_phone, work_phone, mobile_phone } = contactData; 
		const arr = [
			{ 
				id: `Main - ${main_phone}`,
				val: main_phone
			}, 
			{
				id: `Work - ${work_phone}`,
				val: work_phone
			}, 
			{
				id: `Mobile - ${mobile_phone}`,
				val: mobile_phone
			}
		].filter(a => {
			if (!a.val) {
				return false;
			}
			return true;
		});
		if (arr.length > 1 && main_phone) {
			contactDataOptions = arr;
		}  
	}

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth={showContactCompaniesTab ? 'md' : 'sm'} fullWidth={true}>
				<DialogTitle>
					<div style={{ textAlign: 'center' }}>{props.title}</div>
					{props.additional_description && <div style={{ textAlign: 'center',fontSize:10 }}>{props.additional_description}</div>}
					<Tabs
						TabIndicatorProps={{
							style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' },
						}}
						style={{ marginBottom: 10 }}
						value={getTabIndex()}
					>
						{
							<Tab
								onClick={() => {
									setCurrentTab('Contact');
								}}
								style={{ color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
								tabIndex={0}
								label='Contact'
								icon={<Person />}
							/>
						}
						{!props.no_company &&
							<Tab
								onClick={() => {
									setCurrentTab('Company');
								}}
								style={{ color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
								tabIndex={1}
								label='Companies'
								icon={<PersonAddIcon />}
							/>
						}
						{!props.no_company && (
							<Tab
								onClick={() => {
									if (hadChosen && companyContactsData && companyData && !props.noCompanyContacts && !props.no_company) {
										setCurrentTab('Company Contacts');
									}
								}}
								style={{ 
									color: getTabIndex() === 2 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
									opacity: (hadChosen && companyContactsData && companyData && !props.noCompanyContacts && !props.no_company) ? 1 : 0.2 
								}}
								tabIndex={2}
								label='Company Contacts'
								icon={<PersonAddIcon />}
							/>
						)}
						{
							showContactCompaniesTab && <Tab
								onClick={() => {
									setCurrentTab('Contact Companies');
								}}
								disabled={!contactData}
								style={{ 
									color: getTabIndex() === 2 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
									opacity: (contactData) ? 1 : 0.2 
								}}
								tabIndex={3}
								label='Contact Companies'
								icon={<Person />}
							/>
						}
					</Tabs>
				</DialogTitle>
				<div className='dialog-wrapper3'>
					{tab === 'Contact' && (
						<div>
							{!openedChooseContactDba && <CustomDialogWithContact
								open={props.open}
								close={props.close}
								choose={choose}
								create_and_choose={choose}
								title=''
								label='Search Contacts'
								no_dialog={true}
								prefill={props.prefill}
								withAddresses={props.withAddresses}
								showContactTypes={props.showContactTypes ? true : false}
								filterByContactTypes={props.filterByContactTypes}
								showFilters={props.showFilters ? true : false}
								exclusive_right_to_represent_buyer={exclusive_right_to_represent_buyer}
							/>}
							{openedChooseContactDba && !showContactCompaniesTab && <div>
								<CustomSelect 
									label="Select a DBA or 'none'"
									options={get(openedChooseContactDba, 'dbas', [{id:null,name:''}])}
									indx="id"
									desc="name"
									default={theContactDba ? theContactDba.id : null}
									choose={(dba_id) => {
										let dbaObj = null;
										openedChooseContactDba.dbas.forEach(dba => {
											if (dba.id === dba_id) {
												dbaObj = clone(dba);
											}
										})
										setTheContactDba(dbaObj);
									}}
								/>
								<Button
									color="primary"
									style={{marginTop:20, marginRight:20}}
									onClick={() => {
										props.choose(openedChooseContactDba.chosen, null, theContactDba);
										props.close();
									}}
								>SUBMIT</Button>
								<Button
									style={{marginTop:20}}
									color="white"
									onClick={() => {
										setOpenedChooseContactDba(null);
									}}
								>
									CANCEL
								</Button>
							</div>}
						</div>
					)}
					{tab === 'Company' && (
						<div>
							<CustomDialogWithCompany
								open={props.open}
								close={props.close}
								choose={(o) => {
                                    setHadChosen(true);
									choose_company(o);
								}}
								create_and_choose={(o) => {
									setHadChosen(true);
									choose_company(o);
								}}
								title=''
								label='Search Company'
								no_dialog={true}
								disableChoosingDBA={true}
								fromCustomDialogChooser={true}
								filterByContactTypes={props.filterByContactTypes ? props.filterByContactTypes : null}
								exclusive_right_to_represent_buyer={exclusive_right_to_represent_buyer}
							/>
						</div>
					)}
					{tab === 'Contact Companies' && showContactCompaniesTab && (
					<>
						<div>
							<div>
								<div className='boxed'>
									<p>
									Contact: {contactData.name || `${contactData.first_name} ${contactData.last_name}` || ''}<br />
									Address 1 : <strong>{contactDataAddress.address1}</strong><br />
									Address 2 : <strong>{contactDataAddress.address2}</strong><br />
									City : <strong>{contactDataAddress.city}</strong><br />
									State : <strong>{contactDataAddress.state}</strong><br />
									Zip : <strong>{contactDataAddress.zip}</strong><br />
									Main Phone : <strong>{contactData.main_phone}</strong><br />
									Work Phone: <strong>{contactData.work_phone}</strong><br />
									Mobile Phone: <strong>{contactData.mobile_phone}</strong><br />
									Email : <strong>{contactData.email}</strong><br />
									</p>

									{contactDataOptions.length > 0 && <CustomSelect 
										choose={(chosen) => {
											console.log('chosen', chosen);
											const arr = chosen.split(' - ');
											setOverrideContactPhone({
												type: arr[0],
												phone: arr[1]
											})
										}}
										options={contactDataOptions}
										label="Choose Phone Number"
										indx="id"
										desc="id"
									/>}
								</div>
							</div>
							{contactData.dbas && <div>
								{contactData.dbas.filter(dba => dba.active !== 0).length === 0 && <div style={{margin:20}}>
									<small>* This contact does not have DBAs.</small>
								</div>}
							</div>}
							<div>
								{contactData.dbas && <div>
									{!skipDba && contactData.dbas.filter(dba => dba.active !== 0).length > 0 && <div style={{marginBottom:40}}>
										<h4>Choose a Contact DBA</h4>
										<CustomSelect 
											choose={(contactDba) => {
												setTheContactDba(contactData.dbas.find(i => i.id === contactDba));
											}}
											options={[{
												id: 0,
												name: 'none'
											}].concat(contactData.dbas.filter(dba => dba.active !== 0))}
											label="Choose Contact DBA"
											indx="id"
											desc="name"
										/>
									</div>}
								</div>}
								{contactCompanies.length > 0 && <div>
									<h4>Choose a Company from this Contact (optional)</h4>
									{contactCompanies.map((cc, i) => {
										return (
											<div key={`${i}-contact-companies`} className='boxed'>
												<Checkbox
													onClick={() => {
														// toggle check/uncheck
														const company = contactData.company_id === cc.company_id ? null : cc.company_id;
														const companyData = contactData.company_id === cc.company_id ? null : cc.company_data;
														setContactData({...contactData, company_id: company, company_dbas: cc.dbas,
															company_dats: {
																...cc,
																company_data: cc.company_data,
																contact_data: contactData,
															}});
													}}
													checked={contactData.company_id === cc.company_id}
													className='grayed'
												/>
												Company #{i + 1}
												<br />
												Company Name : {cc.name}
												<br />
												{cc.dbas.length > 0 && <CustomNativeSelect 
													choose={(dbaId) => {
														setContactData({...contactData, company_dba_id: dbaId})
													}}
													options={[{
														id: 0,
														name: 'none'
													}].concat(cc.dbas)}
													disabled={contactData.company_id !== cc.company_id}
													label="Choose a DBA"
													indx="id"
													desc="name"
												/>}
											</div>
										);
									})}
								</div>}
							</div>

							{(propertyManagerChoices && currentContact) && <div className="boxed">
								<h4>This contact has a Property Manager</h4>
								{propertyManagerChoices.map((pmc,i) => {
									const contact_name = `${currentContact.first_name} ${currentContact.last_name}`;
									const pm_company_name = get(pmc, 'company_data.name', null);
									const pm_company_id = get(pmc, 'company_data.id', null);

									const pm_company_address1 = get(pmc, 'company_data.mailing_address1', null);
									const pm_company_address2 = get(pmc, 'company_data.mailing_address2', null);
									const pm_company_city = get(pmc, 'company_data.mailing_city', null);
									const pm_company_state = get(pmc, 'company_data.mailing_state', null);
									const pm_company_zip = get(pmc, 'company_data.mailing_zip', null);

									const pm_contact_firstname = get(pmc, 'contact_data.first_name', null);
									const pm_contact_lastname = get(pmc, 'contact_data.last_name', null);
									const pm_contact_id = get(pmc, 'contact_data.id', null);
									let company_contact_data = null;
									if (pmc.company_contacts) {
										pmc.company_contacts.forEach(cc => {
											if (cc.contact_id === pm_contact_id) {
												company_contact_data = cloneJ(cc);
											}
										})
									}
									const pm_contact_phone = get(company_contact_data, 'phone', '');
									const pm_contact_email = get(company_contact_data, 'email', '');
									const pm_contact_department = get(company_contact_data, 'department', '');

									console.log('PM choices', {
										contact_name,
										pm_company_name,
										pm_company_id,
										pm_contact_firstname,
										pm_contact_lastname,
										pm_contact_id,
										pm_contact_phone,
										pm_contact_email,
										pm_contact_department
									});
									return <div key={`pm_cont_${i}`}>
										{pm_company_name && <div>
											<Checkbox 
												onClick={() => {
													const newData = cloneJ(data);
													newData.care_of_company_id = pm_company_id;
													newData.care_of_company = get(pmc, 'company_data', null);
													setData(newData);
												}}
												checked={(data.care_of_company_id === pm_company_id && pm_company_id) ? true : false}
												className='grayed'
											/> Use this Property Management Company & its address<br />
											Company Name : <strong>{pm_company_name}</strong><br />
											Address 1 : <strong>{pm_company_address1}</strong><br />
											Address 2 : <strong>{pm_company_address2}</strong><br />
											City : <strong>{pm_company_city}</strong><br />
											State : <strong>{pm_company_state}</strong><br />
											Zip : <strong>{pm_company_zip}</strong><br />
										</div>}
										{!isEmpty(pmc.contact_data) && <div>
										<Checkbox
											onClick={() => {
												const ccc = pmc.contact_data;
												const newData = cloneJ(data);
												newData.company_data = get(props, 'companyData', null) ? cloneJ(props.companyData) : null;
												//newData.care_of_company = get(pmc, 'company_data', null);
												if (!isInData(ccc.id)) {
													ccc.company_dats = company_contact_data;
													console.log('singleCOntact', singleContactOnly, queryFor)
													if (singleContactOnly || queryFor === 'billing_information') {
														newData.signers = [ccc]
													} else {
														newData.signers.push(ccc);
													}
												} else {
													newData.signers = newData.signers.filter((s) => {
														if (s.id === pm_contact_id) return false;
														return true;
													});
												}
												setData(newData);
											}}
											checked={data.signers.filter(s => {
												if (s.id === pm_contact_id) {
													return true;
												}
											}).length > 0 ? true : false}
											className='grayed'
										/> Property Management Contact<br />
										Contact Name: <strong>{pm_contact_firstname} {pm_contact_lastname}</strong><br />
										Contact Department: <strong>{pm_contact_department}</strong><br />
										Contact Email: <strong>{pm_contact_email}</strong><br />
										Contact Phone: <strong>{pm_contact_phone}</strong><br />
										</div>}
									</div>
								})}
							</div>}

							<Button
								onClick={() => {
									const newContactData = clone(contactData);
									console.log('chosen data in Contact Companies', newContactData, theContactDba);
									if (data.signers) {
										if (data.signers.length > 0) {
											newContactData.overrideContacts = clone(data.signers);
										}
									}
									if (data.care_of_company) {
										newContactData.overrideCompany = clone(data.care_of_company);
									}
									if (overrideContactPhone) {
										newContactData.overrideContactPhone = overrideContactPhone;
									}
									props.choose(newContactData, null, theContactDba);
									props.close();
								}}
								color='primary'
							>
								CHOOSE DATA
							</Button>
						</div>
					</>
					)}
					{tab === 'Company Contacts' && (
					<>
						<div>
							<div>
								<div className='boxed'>
									<p>
										<strong>DEFAULT COMPANY &amp; ADDRESS</strong><br />
										Company: <strong>{companyData ? companyData.name : ''}</strong><br />
										Address 1: <strong>{companyData ? companyData.mailing_address1 : ''}</strong><br />
										Address 2: <strong>{companyData ? companyData.mailing_address2 : ''}</strong><br />
										City: <strong>{companyData ? companyData.mailing_city : ''}</strong><br />
										State: <strong>{companyData ? companyData.mailing_state : ''}</strong><br />
										Zip: <strong>{companyData ? companyData.mailing_zip : ''}</strong>
									</p>
								</div>
							</div>
							{companyDbas.filter(dba => dba.active !== 0).length === 0 && <div style={{margin:20}}>
								<small>* This company does not have DBAs.</small>
							</div>}
							{!props.should_skip_company_contact && <div>
								{!skipDba && companyDbas.filter(dba => dba.active !== 0).length > 0 && <div style={{marginBottom:40}}>
									<h4>Choose a DBA</h4>
									<CustomSelect 
										default={theDba}
										choose={(dbaChosen) => {
											setTheDba(dbaChosen);
										}}
										options={[{
											id: 0,
											name: 'none'
										}].concat(companyDbas.filter(dba => dba.active !== 0))}
										label="Choose DBA"
										indx="id"
										desc="name"
									/>
								</div>}
								{companyContactsData.length === 0 && <div style={{margin:20}}>
									<small>* This company does not have associated contacts.</small>
								</div>}
								{companyContactsData.length > 0 && <div>
									<h4>Choose a Contact from this Company</h4>
									{companyContactsData.map((ccd, i) => {
										const c = ccd.contact_data;
										return (
											<div key={`${i}-cc`} className='boxed'>
												<Checkbox
													onClick={() => {
														const newData = clone(data);
														const ccc = clone(c);
														if (!isInData(ccc.id)) {
															ccc.company_dats = ccd;
															// if (allowMultipleContacts) {
															// 	newData.signers.push(ccc);
															// } else {
															// 	newData.signers = [ccc]
															// }
															if (singleContactOnly || queryFor === 'billing_information') {
																newData.signers = [ccc]
															} else {
																newData.signers.push(ccc);
															}
														} else {
															newData.signers = newData.signers.filter((s) => {
																if (s.id === c.id) return false;
																return true;
															});
														}
														setData(newData);
													}}
													checked={isInData(c.id)}
													className='grayed'
												/>
												Contact #{i + 1}
												<br />
												First Name : <strong>{c.first_name}</strong>
												<br />
												Last Name : <strong>{c.last_name}</strong>
												<br />
												Department : <strong>{ccd.department}</strong>
												<br />
												Title : <strong>{ccd.title}</strong>
												<br />
												Email : <strong>{ccd.email}</strong>
												<br />
												Phone : <strong>{ccd.phone}</strong>
												<br />
											</div>
										);
									})}
								</div>}

								<hr />

								{!no_property && <div>
									{companyPropertiesData &&
										companyPropertiesData.map((cpd, i) => {
											
											const p = cpd.property_data;
											const propertyObj = createAddressObject(p);
											return (
												<div key={`${i}-cp`} className='boxed'>
													<Checkbox
														onClick={() => {
															const newData = clone(data);
															const ccp = clone(p);
															if (newData.property && newData.property.id === ccp.id) {
																newData.property = null;
															} else {
																newData.property = ccp;
															}
															setData(newData);
														}}
														checked={data.property && p.id === data.property.id ? true : false}
														className='grayed'
													/>
													Property #{i + 1}
													<br />
													Name : {p.name}
													<br />
													illi Number : {p.illi_number}
													<br />
													Company Type : {p.use_type}
													<br />
													Address 1: {propertyObj.street1}
													<br />
													Address 2: {propertyObj.street2}
													<br />
													City : {p.city}
													<br />
													Zip : {p.zip}
													<br />
												</div>
											);
										})}
									</div>}
							</div>}

							{propertyManagerChoices && <div className="boxed">
								<h4>Click to choose this company's Property Manager</h4>
								{propertyManagerChoices.map((pmc,i) => {
									console.log('pmc', pmc)
									const company_name = get(props, 'companyData.name', '');
									const pm_company_name = get(pmc, 'company_data.name', null);
									const pm_company_address1 = get(pmc, 'company_data.mailing_address1', null);
									const pm_company_address2 = get(pmc, 'company_data.mailing_address2', null);
									const pm_company_city = get(pmc, 'company_data.mailing_city', null);
									const pm_company_state = get(pmc, 'company_data.mailing_state', null);
									const pm_company_zip = get(pmc, 'company_data.mailing_zip', null);

									const pm_company_id = get(pmc, 'company_data.id', null);
									const pm_contact_firstname = get(pmc, 'contact_data.first_name', null);
									const pm_contact_lastname = get(pmc, 'contact_data.last_name', null);
									const pm_contact_id = get(pmc, 'contact_data.id', null);
									let company_contact_data = null;
									if (pmc.company_contacts) {
										pmc.company_contacts.forEach(cc => {
											if (cc.contact_id === pm_contact_id) {
												company_contact_data = cloneJ(cc);
											}
										})
									}
									const pm_contact_phone = get(company_contact_data, 'phone', '');
									const pm_contact_email = get(company_contact_data, 'email', '');
									const pm_contact_department = get(company_contact_data, 'department', '');
									return <div key={`pm_cont_${i}`}>
										<Checkbox 
											onClick={() => {
												const newData = cloneJ(data);
												newData.care_of_company_id = pm_company_id;
												newData.care_of_company = get(pmc, 'company_data', null);
												setData(newData);
											}}
											checked={data.care_of_company_id === pm_company_id ? true : false}
											className='grayed'
										/> Use Property Management Company and its address<br />
										Company Name : <strong>{pm_company_name}</strong><br />
										Address 1 : <strong>{pm_company_address1}</strong><br />
										Address 2 : <strong>{pm_company_address2}</strong><br />
										City : <strong>{pm_company_city}</strong><br />
										State : <strong>{pm_company_state}</strong><br />
										Zip : <strong>{pm_company_zip}</strong><br />
										<Checkbox
											onClick={() => {
												const ccc = pmc.contact_data;
												const newData = cloneJ(data);
												newData.company_data = get(props, 'companyData', null) ? cloneJ(props.companyData) : null;
												//newData.care_of_company = get(pmc, 'company_data', null);
												if (!isInData(ccc.id)) {
													ccc.company_dats = company_contact_data;
													// if (allowMultipleContacts) {
													// 	newData.signers.push(ccc);
													// } else {
													// 	newData.signers = [ccc]
													// }
													console.log('singleCOntact', singleContactOnly, queryFor)
													if (singleContactOnly || queryFor === 'billing_information') {
														newData.signers = [ccc]
													} else {
														newData.signers.push(ccc);
													}
												} else {
													newData.signers = newData.signers.filter((s) => {
														if (s.id === pm_contact_id) return false;
														return true;
													});
												}
												setData(newData);
											}}
											checked={data.signers.filter(s => {
												if (s.id === pm_contact_id) {
													return true;
												}
											}).length > 0 ? true : false}
											className='grayed'
										/> Use this Property Management Contact<br />
										Contact Name: <strong>{pm_contact_firstname} {pm_contact_lastname}</strong><br />
										Contact Department: <strong>{pm_contact_department}</strong><br />
										Contact Email: <strong>{pm_contact_email}</strong><br />
										Contact Phone: <strong>{pm_contact_phone}</strong><br />
									</div>
								})}
							</div>}

							<Button
								onClick={() => {
									if (props.forceContact) {
										if (data.signers.length === 0) {
											return setNotification('One Contact is required');
										}
									}
									const newData = clone(data);
									if (!newData.company_data) {
										newData.company_data = companyData;
									}
									newData.dba_data = companyDbas;
									newData.dba_id = theDba;
									props.choose(newData);
									props.close();
								}}
								color='primary'
								disabled={((data.signers.length === 0 && !allowNoContacts) && !data.property && !props.should_skip_company_contact) ? true : false}
							>
								CHOOSE DATA
							</Button>
						</div>
					</>
					)}
				</div>
			</Dialog>

			{notification && <Notification 
				message={notification}
				open={notification ? true : false}
				close={() => {
					setNotification('');
				}}
			/>}
		</div>
	);
};

const mapStateToProps = (state) => {
	const c = state.crm_companies;
	return {
		options: c.companiesData,
		loading: c.loading,
		illi_companies: c.illi_companies,
		own_companies: c.own_companies,
		own_shared_companies: c.own_shared_companies,
		companyContactsData: c.companyContactsData,
		companyPropertiesData: c.companyPropertiesData,
		companyData: c.companyData,
		contactsList: c.contactsList,
		companyDbas: c.companyDbas,
		contactCompanies: state.crm_contacts.contactCompanies,
		company_entities: c.company_entities,
		contact_entities: state.crm_contacts.contact_entities
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getCompanies: (params) => {
			dispatch(actions.getCompanies(params));
		},
		getCompanyContacts: (company_id) => {
			dispatch(actions.getCompanyContacts({ company_id }));
		},
		getCompany: (id) => {
			dispatch(actions.getCompany(id));
		},
		getCompanyProperties: (company_id) => {
			dispatch(
				actions.getCompanyProperties({
					is_company: true,
					company_id,
				})
			);
		},
		getCompanyContactsDetail: (company_id) => {
			dispatch(actions.getCompanyContactsDetail(company_id))
		},
		getCompanyDbas: (company_id) => {
			dispatch(actions.getCompanyDbas(company_id))
		},
		getContactCompanies: (contact_id) => {
			dispatch(actions.getContactCompanies(contact_id))
		},
		getCompanyEntities: (company_id) => {
			dispatch(actions.getCompanyEntities(company_id));
		},
		getContactEntities: (contactId) => dispatch(actions.getContactEntities(contactId)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogChooser);
