import React, {useEffect, useState, useCallback} from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import _, { get } from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import { Tab, Tabs } from '@material-ui/core';
import { getUser } from '../../shared/authValidation';
import ContactsNewCompany from '../../containers/CRM/Contacts/ContactsNewCompany';
import CompaniesForm from '../../containers/CRM/Companies/CompaniesForm';
import { IconButton } from '@material-ui/core';
import Notification from '../Notification/Notification';
import crmObj from '../../containers/CRM/crmObj';
import Close from '@material-ui/icons/Close';
import CustomSelect from '../CustomSelect/CustomSelect';

const CustomDialogWithCompany = (props) => {
    const [openedNewCompany, setOpenedNewCompany] = useState(false);
    const [userType, setUserType] = useState('E');
    const [currentTab, setCurrentTab] = useState('illi');
	const [openedChooseType, setOpenedChooseType] = useState(null);
	const [openedChooseDBA, setOpenedChooseDBA] = useState(null);
	const [openedChoose, setOpenedChoose] = useState(null);
	const [theType, setTheType] = useState('');
	const [theDba, setTheDba] = useState('');
	const [subType, setSubtype] = useState('');
	const [acronymType, setAcronymtype] = useState('');
	const [choice, setChoice] = useState('');
	const [options, setOptions] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [contactTypesFilter, setContactTypesFilter] = useState([]);
	const [target, setTarget] = useState(false);
	const [formDataObj, setFormDataObj] = useState({});
	const [viewCompany, setViewCompany] = useState(false);
	const [notification, setNotification] = useState('');
	const [theRelationship, setTheRelationship] = useState('');
	const [shouldPrefillCompany, setShouldPrefillCompany] = useState('');
    const { loading, illi_companies, own_companies, own_shared_companies, companyData, getCompany, askRelationship } = props;

	const getDbaOptions = (dbaObj) => {
		let options = [
			{ dba_id: 0, name: 'none'}
		];
		const arr = get(dbaObj, 'dbas', [])
			.filter(dba => dba.active !== 0)
			.map(m => { return {
			dba_id: m.id,
			name: m.name
		}});
		const finalOptions = options.concat(arr);
		return finalOptions;
	}

    const searchAPI = (the_choice) => {
		const params = {
			names_only: 'All',
			search_term: the_choice,	
		};
		if (props.exclusive_right_to_represent_buyer) {
			params.exclusive_right = true;
		}
		props.getCompanies(params)
	}

	const searchIt = useCallback(_.debounce(searchAPI, 600), []);

    const filterIt = (arr) => {
		if (!arr) return [];
		console.log('have to filter this', arr);
		return arr.filter(ff => {
			let pass = false;
			if (contactTypesFilter.length > 0) {
			  const ctype = ff.contact_type ? ff.contact_type : '';
			  if (!ctype) return false;
			  contactTypesFilter.forEach(ctf => {
				  if (ff.contact_type.indexOf(ctf) !== -1) {
					  pass = true;
				  }
			  })
			} else {
				// no filters, show all
				return true;
			}
			return pass;
		})
	}

    useEffect(() => {
		const userPerms = getUser();
		if (userPerms) {
			setUserType(userPerms.type);
			if (userPerms.type === 'A') {
				setCurrentTab('Agent');
			}
		}
		if (props.prefill) {
			if (props.prefill.company_name) {
				setChoice(props.prefill.company_name);
			}
		}
		if (props.filterByContactTypes) {
			if (props.filterByContactTypes.length > 0) {
				setContactTypesFilter(props.filterByContactTypes);
			}
		}
	}, []);

    useEffect(() => {
		if (choice) {
			if (choice.length > 1) {
				searchIt(choice);
			}
		}
	}, [choice]);

    useEffect(() => {
		if (illi_companies && own_companies && own_shared_companies) {
			renderOptions();
		}
	}, [illi_companies, own_companies, own_shared_companies]);

	useEffect(() => {
		renderOptions();
	}, [currentTab]);

	useEffect(() => {
		if (companyData && target) {
			setFormDataObj(companyData);
			setTarget(false);
		}
	}, [companyData]);

    const oo = options;

	let hide_illi_tab = false;
	if (props.loggedInUser) {
		// shown if in CRM association editing mode (e.g. company properties, contact properties)
		if (props.loggedInUser.type === 'A') {
			hide_illi_tab = true;
		}
	}

    const renderOptions = () => {
		if (currentTab === 'illi') {
			let theOptions = filterIt(illi_companies ? illi_companies : []);
			if (props.exclusive_right_to_represent_buyer) {
				theOptions = theOptions.filter(t => {
					if (t.exclusive_right_to_represent_buyer === 'Yes') return true;
				})
			}
			setOptions(theOptions);
	   }
	   if (currentTab !== 'illi') {
			let theOptions = filterIt(own_companies ? own_companies.concat(own_shared_companies) : []);
			if (props.exclusive_right_to_represent_buyer) {
				theOptions = theOptions.filter(t => {
					if (t.exclusive_right_to_represent_buyer === 'Yes') return true;
				})
			}
		   setOptions(theOptions);
	  }
	}

	let prefillCompanyName = '';
	if (props.prefill) {
		if (props.prefill.company_name) {
			prefillCompanyName = props.prefill.company_name;
		}
	}

	const dbas_options = get(openedChoose, 'dbas', []);

	const renderContent = () => {
		return <div>
				<DialogTitle>
					<div style={{textAlign: 'center'}}>{props.title}</div>
                    {/* Set no_new_company = true to prevent new company button from showing */}
                    {(!props.no_new_company && !prefillCompanyName) && <Button className="outlined-btn"
                        size="sm"
                        onClick={() => {
                            setOpenedNewCompany(true);
							setShouldPrefillCompany(false);
                        }}
						style={{marginRight:20}}
                    ><i className="fa fa-plus"></i> CREATE NEW COMPANY</Button>}
					{(!props.no_new_company && prefillCompanyName) && <Button className="outlined-btn"
                        size="sm"
                        onClick={() => {
                            setOpenedNewCompany(true);
							setShouldPrefillCompany(true);
                        }}
                    ><i className="fa fa-plus"></i> CREATE NEW COMPANY</Button>}
				</DialogTitle>
				{<div style={{margin:'0 20px'}}>
					{userType !== 'E' && <Tabs
						TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
						style={{ marginBottom: 10 }}
						value={currentTab === 'illi' ? 0 : 1}>
						{<Tab
							onClick={() => {
								setCurrentTab('illi');
							}}
							style={{ 
								color: currentTab === 'illi' ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
								display: hide_illi_tab ? 'none' : 'block'
							}}
							tabIndex={0}
							label='illi Companies'
							icon={<i className="fas fa-building"></i>}
						/>}
						{<Tab
							onClick={() => {
								setCurrentTab('Agent');
							}}
							style={{ color: currentTab !== 'illi' ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
							tabIndex={1}
							label='Your Companies'
							icon={<i className="fas fa-user"></i>}
						/>}
					</Tabs>}
				</div>}
				<div style={{textAlign: 'center', padding: 20}}>
					<CustomInput
						label={props.label}
						value={choice}
						onChange={(e) => {
							setChoice(e.target.value);
						}}
						autoFocus={true}
					/>
					<Button color="white" className="mt-10 mb-20" onClick={() => {
						setShowFilters(!showFilters);
					}}>{showFilters ? 'Hide' : 'Show'} Filters</Button>

					{(showFilters && contactTypesFilter.length > 0) ? <div style={{marginTop:20,marginBottom:20}}>{contactTypesFilter.join(', ')}</div> : null}
					
					{options && <div style={{height: !choice ? 100 : 300, overflowY: 'scroll', padding: 10, backgroundColor: '#f1f1f1'}}>
						{loading && <div className="text-center"><ClipLoader /></div>}
						{!choice && <div style={{marginTop:20}}><em>Enter letters or numbers from the company's name<br />you are searching for in the line above</em></div>}
						{(choice && options) && <div>
							{oo.length === 0 && <div><em>0 Results</em></div>}	
						</div>}
						{(!loading && choice) && <div>
							{oo.length > 0 && <div><em>{oo.length.toLocaleString()} Result(s)</em></div>}	
						</div>}
						{(!loading && choice) && <List component="nav">
							{options.map((o, i) => {
								
								const desc = o.name;
								return (
									<ListItem 
										button
										key={`company-option-${i}`}
										onClick={() => {
											const dbas = get(o, 'dbas', []);
											console.log('choosing -> company', props.askForType, (dbas.length > 0 && !props.disableChoosingDBA), askRelationship);
											if (props.askForType || (dbas.length > 0 && !props.disableChoosingDBA) || askRelationship) {
												setOpenedChoose({
													dbas,
													askForType: true,
													chosen: o
												});
											} else {
												props.choose(o, null, dbas);
											}
										}}
									>
										<ListItemText inset primary={desc} secondary={
											o.matched_dbas && o.matched_dbas
											.split(',')
											.map((i, x) => <span key={`matched-dba-${x}`} style={{display: 'block'}}>DBA: {i}</span>)
										}/>
										<ListItemSecondaryAction>
											<IconButton onClick={() => {
													
													getCompany(o.id);
													setViewCompany(true);
												}}>
												<i className="fa fa-eye"></i>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})}
						</List>}
					</div>}

					<ContactsNewCompany 
						open={openedNewCompany}
                        close={() => {
                            setOpenedNewCompany(false);
                        }}
                        create_and_choose={(chosen, dba_data) => {
							console.log('chose new company', chosen, props.fromCustomDialogChooser);
							const dbas = dba_data ? dba_data : [];
							if ((props.askForType || dbas.length > 0 || askRelationship) && !props.fromCustomDialogChooser) {
								setOpenedChoose({
									dbas,
									askForType: props.askForType,
									chosen
								});
							} else {
								props.choose(chosen);
							}
						}}
						shouldPrefillCompany={shouldPrefillCompany}
						prefill={props.prefill ? {
							name: props.prefill ? props.prefill.company_name : ''
						} : null}
					/>

					{(companyData && viewCompany) && (
						<Dialog
						open={companyData && viewCompany}
						keepMounted
						maxWidth={false}
						fullWidth={true}
						fullScreen={true}
					>
						<div className="dialog-wrapper">
							<CompaniesForm
								action="Edit"
								closeForm={() => {
									setViewCompany(false);
									if (choice) {
										searchIt(choice);
									}
								}}
								// openForm={openForm}
								// openAskModal={openAskModal}
								success={props.success}
								updateCompany={props.updateCompany}
								// deleteCompany={props.deleteCompany}
								error={props.error}
								companyData={companyData}
								loading={props.loading}
								formDataObj={formDataObj}
								setFormDataObj={setFormDataObj}
								// setOpenedAsk={setOpenedAsk}
								showCloseBtn={true}
								notification={notification}
								setNotification={setNotification}
							/>
						</div>
					</Dialog>
					)}

					{notification && <Notification 
						open={notification ? true : false}
						close={() => { setNotification('') }}
						message={notification}
					/>}

					{<Button
						color='white'
						style={{marginRight: 10, marginTop: 20}}
						onClick={() => {
							props.close();
						}}
					>
						CLOSE
					</Button>}
				</div>

				{/* CHOOSE */}
				{openedChoose && <Dialog
                    open={true} keepMounted onClose={() => { setOpenedChoose(null); }} 
                    maxWidth='sm' fullWidth={true}
                >
                    <DialogTitle>
                        <div className="close-btn" onClick={() => { setOpenedChoose(null); }}><Close /></div>
                        <div style={{ textAlign: 'center' }}>Choose for this Company</div>
                    </DialogTitle>
                    <div className="dialog-body" style={{ margin: '0 20px', minHeight:400 }}>
                        
						{/* CHOOSE TYPE */}
						{props.askForType && <div style={{marginBottom:20}} className="boxed">
							<CustomSelect 
								default={theType}
								choose={(typeChosen) => {
									setTheType(typeChosen);
									if (typeChosen === 'Other') {
										setSubtype('');
									} else {
										setSubtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].subtype);
									}
									setAcronymtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].type);
								}}
								options={props.typeRestriction ? 
										Object.keys(crmObj.contactOrCompanyPropertySubTypes).filter(field => {
											if (crmObj.contactOrCompanyPropertySubTypes[field].type === props.typeRestriction) {
												return true;
											}
										})
									: 
										crmObj.contactOrCompanyPropertyTypes}
								label="Choose one Type"
							/>
							{theType === 'Other' && <CustomInput
								label="Enter Other type"
								value={subType}
								onChange={(e) => {
									setSubtype(e.target.value);
								}}
							/>}
						</div>}

						{/* company-to-company relationship */}
						{askRelationship && <div className="boxed">
							<h4>Choose relationship</h4>
							<CustomSelect
									label="Choose one"
									default={theRelationship}
									options={["Affiliate", "Subsidiary", "Parent Company", "Franchisee"]}
									choose={(value) => {
											setTheRelationship(value);
										}
									}
							/>
						</div>}

						{dbas_options.length > 0 && <div className="boxed">
							<h4>Choose a DBA</h4>
							<p>Choose "none" if you don't need a DBA to use for this company</p>
							<CustomSelect 
								default={theDba ? theDba.id : ''}
								choose={(dba_id) => {
									if (dba_id === 0) {
										return setTheDba(0);
									}
									let obj = null;
									get(openedChoose, 'dbas', []).forEach(opt => {
										if (opt.id === dba_id) obj = opt;
									})
									setTheDba(obj);
								}}
								options={getDbaOptions(openedChoose)}
								desc="name"
								indx="dba_id"
								label="Choose one"
							/>
						</div>}

                        <Button color="white"
                            onClick={() => {
								let typeObj = null;
								let dbaObj = null;
								if (props.askForType) {
									if (theType === 'Other') {
										if (!subType) {
											return setNotification('Please enter an "Other" type.');
										}
									}
									typeObj = {
										theType, 
										acronymType, 
										subType
									};
									console.log('typeObj', typeObj);
								}
								dbaObj = theDba ? theDba : null;
								console.log('choosing ->', openedChoose.chosen, typeObj, dbaObj)
                                props.choose(openedChoose.chosen, typeObj, dbaObj, theRelationship);
                                props.close();
                            }}
                        >SUBMIT</Button>
                    </div>
                </Dialog>}
		</div>
	}

	if (props.no_dialog) {
		return <div>
			{renderContent()}
		</div>
	}

    return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
				{renderContent()}
			</Dialog>
		</div>
	);
}

const mapStateToProps = state => {
    const c = state.crm_companies;
    return {
        options: c.companiesData,
		loading: c.loading,
		illi_companies: c.illi_companies,
		own_companies: c.own_companies,
		own_shared_companies: c.own_shared_companies,
		companyData: c.companyData,
		success_data_dba: c.success_data_dba,
		error: c.error,
		success: c.success,
		loading: c.loading
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getCompanies: (params) => {
            dispatch(actions.getCompanies(params));
        },
		getCompany: (id) => {
			dispatch(actions.getCompany(id));
		},
		updateCompany: (data) => {
			dispatch(actions.updateCompany(data));
		}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogWithCompany);
