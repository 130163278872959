import { Dialog } from "@material-ui/core";
import { debounce } from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { clone } from "shared/utility";
import * as actions from "store/actions";

import DialPadMergeContact from "./DialPadMergeContact";
import DialPadMergeReview from "./DialPadMergeReview";
import DialPadMergeCompany from "./DialPadMergeCompany";
import { ArrowBack, ArrowForward, Close } from "@material-ui/icons";
import DialPadMergeProperty from "./DialPadMergeProperty";
import DialPadMergeSummary from "./DialPadMergeSummary";
import DialPadCompanyMergeReview from "./DialPadCompanyMergeReview";
import DialPadPropertyReview from "./DialPadPropertyReview";

const steps = [
    "Find/Merge contact",
    "Contact Merge Review",
    "Company",
    "Company Merge Review",
    "Property",
    "Property Review",
    "Summary",
];

const ContactDialogChooser = (props) => {
    const {
        addPropertyToContact,
        checkPropertyExistingAddress,
        clearContactNames,
        clearCompanyCRMMessages,
        clearContactCRMMessages,
        clearPropertyCRMMessages,
        companyError,
        companyOptions,
        companySuccess,
        contactData,
        contactError,
        contactOptions,
        contactSuccess,
        contactToImport,
        createCompany,
        createCompanyContacts,
        createContact,
        createProperty,
        getCompanies,
        getContact,
        getContacts,
        getProperties,
        getPropertyUseType,
        getPropertyUsernames,
        illi_contacts,
        insertId,
        loading,
        open,
        onClose,
        propertyError,
        propertyOptions,
        propertySuccess,
        setAlreadyImportedList,
        updateContact,
        updateCompany,
        companyData,
        createCompanyProperties,
    } = props;

    const [tab, setTab] = useState(steps[0]);

    const [contact, setContact] = useState(null);
    const [company, setCompany] = useState(null);
    const [property, setProperty] = useState(null);

    // Contact after edit fields
    const [contactForm, setContactForm] = useState(null);
    const [companyForm, setCompanyForm] = useState(null);

    // Save contact id for later actions
    const [contactId, setContactId] = useState(null);
    const [contactInsertId, setContactInsertId] = useState(null);

    const [contactToUpdate, setContactToUpdate] = useState(null);
    const [companyToUpdate, setCompanyToUpdate] = useState(null);
    const [propertyToUpdate, setPropertyToUpdate] = useState([]);

    const [feedbackMessage, setFeedbackMessage] = useState(null);
    const [openedFeedbackModal, setOpenedFeedbackModal] = useState(false);

    const closeFeedbackModal = () => {
        setOpenedFeedbackModal(false);
        setFeedbackMessage(null);
        clearCompanyCRMMessages();
        clearContactCRMMessages();
        clearPropertyCRMMessages();
    };

    const allowNext = tab !== steps[steps.length - 1];
    const allowBack = tab !== steps[0];

    useEffect(() => {
        if (insertId) {
            setContactInsertId(insertId);
        }
    }, [insertId]);

    useEffect(() => {
        if (contactToImport) {
            const newContactForm = {
                company_name: contactToImport.company_name,
                first_name: contactToImport.first_name,
                last_name: contactToImport.last_name,
                email: contactToImport.primary_email,
                main_phone: contactToImport.primary_phone,
                title: contactToImport.job_title,
            };
            setContactForm(newContactForm);
            setCompanyForm({
                name: contactToImport.company_name,
            });
        }
    }, [contactToImport]);

    useEffect(() => {
        if (contactInsertId) {
            setContactId(contactInsertId);
            if (setAlreadyImportedList) {
                setAlreadyImportedList(contactToImport.id);
            }
            goTo(2);
        }
    }, [contactInsertId]);

    useEffect(() => {
        if (contact) {
            const newContactForm = clone(contactForm);
            newContactForm.id = contact.id;
            setContactForm(newContactForm);
        }
    }, [contact]);

    useEffect(() => {
        if (companyData) {
            setCompany(companyData);
        }
    }, [companyData])

    useEffect(() => {
        let message = "";
        if (contactSuccess && typeof contactSuccess === "string" && !contactSuccess.includes("got")) {
            message = contactSuccess;
        } else if (companySuccess && typeof companySuccess === "string" && !companySuccess.includes("got")) {
            message = companySuccess;
        } else if (propertySuccess && typeof propertySuccess === "string" && !propertySuccess.includes("got")) {
            message = propertySuccess;
        } else if (contactError && typeof contactError === "string" && !contactError.includes("got")) {
            message = contactError;
        } else if (companyError && typeof companyError === "string" && !companyError.includes("got")) {
            message = companyError;
        } else if (propertyError && typeof propertyError === "string" && !propertyError.includes("got")) {
            message = propertyError;
        }
        setFeedbackMessage(message);
    }, [contactSuccess, contactError, companySuccess, companyError, propertySuccess, propertyError]);

    useEffect(() => {
        if (feedbackMessage) {
            setOpenedFeedbackModal(true);
        }
    }, [feedbackMessage]);

    const goNext = () => {
        const index = steps.findIndex((item) => item === tab);
        if (index === -1 || index === steps.length - 1) return;

        let nextStep = steps[index + 1];
        if (index === 0 && !contact) {
            nextStep = steps[index + 2];
        } else if (nextStep === 5 && !property) {
            nextStep++;
        } else if (nextStep === 3 && !company) {
            nextStep++;
        }
        setTab(nextStep);
    };

    const goBack = () => {
        const index = steps.findIndex((item) => item === tab);
        if (index === -1 || index === 0) return;

        let previousStep = steps[index - 1];
        if (index === 2 && !contact) {
            previousStep = steps[index - 2];
        }
        setTab(previousStep);
    };

    const goTo = (index) => {
        setTab(steps[index]);
    };

    const handleCreateCompany = (data) => {
        createCompany(data);
        setCompanyToUpdate({
            action: "CREATE",
            data: data,
        });
        setCompany(data)
        goTo(4)
    };

    const handleCreateProperty = (data) => {
        createProperty(data);
        const newData = clone(propertyToUpdate);
        newData.push({
            action: "CREATE",
            data: data,
        });
        setProperty(data);
        setPropertyToUpdate(newData);
    };

    const handleCreateContact = (data) => {
        createContact(data);
        setContact(data);
        setContactToUpdate({
            action: "CREATE",
            data: data,
        });
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={onClose} maxWidth={false} fullWidth={true} fullScreen keepMounted>
                <div style={{ padding: 20 }}>
                    <div className="close-btn" onClick={onClose}>
                        <Close />
                    </div>

                    <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                            <Card>
                                <CardHeader color="rose" icon>
                                    <CardIcon
                                        color={tab === steps[0] ? "success" : "rose"}
                                        onClick={() => {
                                            setTab(steps[0]);
                                        }}
                                        className="hovered"
                                    >
                                        <strong>{steps[0]}</strong>
                                    </CardIcon>

                                    {steps.slice(1).map((step, i) => (
                                        <CardIcon
                                            key={i}
                                            color={tab === step ? "success" : "rose"}
                                            onClick={() => {
                                                if (contact) {
                                                    setTab(step);
                                                }
                                            }}
                                            style={{
                                                cursor: `${
                                                    contact || (i === 2 && !contactInsertId) ? "pointer" : "not-allowed"
                                                }`,
                                            }}
                                        >
                                            <strong>{step}</strong>
                                        </CardIcon>
                                    ))}
                                </CardHeader>

                                <div style={{ marginTop: 20, textAlign: "center" }}>
                                    <Button disabled={!allowBack} onClick={goBack} size="sm">
                                        <ArrowBack /> Back
                                    </Button>
                                    <Button disabled={!allowNext} onClick={goNext} size="sm">
                                        <ArrowForward /> Next
                                    </Button>
                                </div>

                                <CardBody>
                                    {tab === steps[0] && (
                                        <DialPadMergeContact
                                            contactOptions={contactOptions}
                                            contactToImport={contactToImport}
                                            createContact={handleCreateContact}
                                            getContacts={getContacts}
                                            illi_contacts={illi_contacts}
                                            loading={loading}
                                            onContactClick={(contact) => {
                                                setContact(contact);
                                                setTab("Contact Merge Review");
                                            }}
                                            updateContact={updateContact}
                                            handleSkip={() => {
                                                setTab("Company");
                                            }}
                                            contactToUpdate={contactToUpdate}
                                            setContactToUpdate={setContactToUpdate}
                                        />
                                    )}

                                    {tab === steps[1] && (
                                        <DialPadMergeReview
                                            contact={contact}
                                            contactForm={contactForm}
                                            contactToImport={contactToImport}
                                            goTo={goTo}
                                            setContactForm={setContactForm}
                                            setAlreadyImportedList={
                                                setAlreadyImportedList ? setAlreadyImportedList : null
                                            }
                                            setContactId={setContactId}
                                            updateContact={updateContact}
                                            contactToUpdate={contactToUpdate}
                                            setContactToUpdate={setContactToUpdate}
                                        />
                                    )}

                                    {tab === steps[2] && (
                                        <DialPadMergeCompany
                                            companyOptions={companyOptions}
                                            contactData={contactData}
                                            contactId={contactId}
                                            contactToImport={contactToImport}
                                            createCompany={handleCreateCompany}
                                            createCompanyContacts={createCompanyContacts}
                                            getCompanies={getCompanies}
                                            getContact={getContact}
                                            setCompanyToUpdate={setCompanyToUpdate}
                                            companyToUpdate={companyToUpdate}
                                            handleSkip={() => {
                                                if (!contactToUpdate) {
                                                    setTab("Summary");
                                                } else {
                                                    setTab("Property");
                                                }
                                            }}
                                            contactToUpdate={contactToUpdate}
                                            setCompany={setCompany}
                                            company={company}
                                            goTo={goTo}
                                            contact={contact}
                                        />
                                    )}

                                    {tab === steps[3] && (
                                        <DialPadCompanyMergeReview
                                            company={company}
                                            companyForm={companyForm}
                                            setCompanyForm={setCompanyForm}
                                            goTo={goTo}
                                            updateCompany={updateCompany}
                                            setCompanyToUpdate={setCompanyToUpdate}
                                            companyToUpdate={companyToUpdate}
                                            contactToUpdate={contactToUpdate}
                                            contactToImport={contactToImport}
                                            setContactId={setContactId}
                                            contactId={contactId}
                                            contactData={contactData}
                                            createCompanyContacts={createCompanyContacts}
                                            getContact={getContact}
                                        />
                                    )}

                                    {tab === steps[4] && (
                                        <DialPadMergeProperty
                                            addPropertyToContact={addPropertyToContact}
                                            checkPropertyExistingAddress={checkPropertyExistingAddress}
                                            clearContactNames={clearContactNames}
                                            contactData={contactData}
                                            contactId={contactId}
                                            createProperty={handleCreateProperty}
                                            getContact={getContact}
                                            getProperties={getProperties}
                                            getPropertyUseType={getPropertyUseType}
                                            getPropertyUsernames={getPropertyUsernames}
                                            propertyOptions={propertyOptions}
                                            setPropertyToUpdate={setPropertyToUpdate}
                                            propertyToUpdate={propertyToUpdate}
                                            handleSkip={() => {
                                                setTab("Summary");
                                            }}
                                            setProperty={setProperty}
                                            goTo={goTo}
                                        />
                                    )}

                                    {tab === steps[5] && (
                                        <DialPadPropertyReview
                                            contact={contact}
                                            company={company}
                                            goTo={goTo}
                                            contactToUpdate={contactToUpdate}
                                            contactToImport={contactToImport}
                                            companyToUpdate={companyToUpdate}
                                            contactId={contactId}
                                            contactData={contactData}
                                            propertyToUpdate={propertyToUpdate}
                                            setPropertyToUpdate={setPropertyToUpdate}
                                            property={property}
                                            createCompanyProperties={createCompanyProperties}
                                            addPropertyToContact={addPropertyToContact}
                                        />
                                    )}

                                    {tab === steps[6] && (
                                        <DialPadMergeSummary
                                            contactToUpdate={contactToUpdate}
                                            companyToUpdate={companyToUpdate}
                                            propertyToUpdate={propertyToUpdate}
                                            onClose={onClose}
                                        />
                                    )}
                                </CardBody>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </Dialog>

            {feedbackMessage && feedbackMessage.toString().toLowerCase() !== 'loaded options' && (
                <Dialog open={openedFeedbackModal} maxWidth={false} fullWidth={true}>
                    <div style={{ textAlign: "center", padding: 20 }}>
                        <p>{feedbackMessage}</p>
                        <Button color="primary" onClick={closeFeedbackModal}>
                            OK
                        </Button>
                    </div>
                </Dialog>
            )}
        </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    const contact = state.crm_contacts;
    const company = state.crm_companies;
    const property = state.crm_properties;
    return {
        contactData: contact.contactData,
        contactOptions: contact.contactsData,
        contactSuccess: contact.success,
        contactError: contact.error,
        insertId: contact.insertId,
        loading: contact.loading,
        illi_contacts: contact.illi_contacts,
        own_contacts: contact.own_contacts,
        own_shared_contacts: contact.own_shared_contacts,

        companyError: company.error,
        companySuccess: company.success,
        companyOptions: company.companiesData,
        companyData: company.companyData,

        propertyOptions: property.propertiesData,
        propertyError: property.error,
        propertySuccess: property.success,
    };
};

const mapDispatchToProps = (dispatch) => {
    const getContacts = (params) => {
        dispatch(actions.getContacts(params));
    };
    const getCompanies = (params) => dispatch(actions.getCompanies(params));
    const getProperties = (params) => dispatch(actions.getProperties(params));
    return {
        getContacts: debounce(getContacts, 500),
        getCompanies: debounce(getCompanies, 500),
        getProperties: debounce(getProperties, 500),
        addPropertyToContact: (data) => {
            dispatch(actions.addPropertyToContact(data));
        },
        checkPropertyExistingAddress: (data) => {
            dispatch(actions.checkPropertyExistingAddress(data));
        },
        clearContactCRMMessages: () => {
            dispatch(actions.clearCRMMessages());
        },
        clearContactNames: () => {
            dispatch(actions.clearContactNames());
        },
        clearPropertyCRMMessages: () => {
            dispatch(actions.clearPropertyCRMMessages());
        },
        clearCompanyCRMMessages: () => {
            dispatch(actions.clearCompanyCRMMessages());
        },
        createContact: (data, partnersData, addressData) => {
            dispatch(actions.createContact(data, partnersData, addressData));
        },
        createCompany: (data, partnersData, addressData, appointmentsData, sharesData, formDataObj) => {
            dispatch(actions.createCompany(data, partnersData, addressData, appointmentsData, sharesData, formDataObj));
        },
        createCompanyContacts: (data) => {
            dispatch(actions.createCompanyContacts(data));
        },
        createProperty: (data, otherData) => {
            dispatch(actions.createProperty(data, otherData));
        },
        getContact: (id) => {
            dispatch(actions.getContact(id));
        },
        getPropertyUseType: () => {
            dispatch(actions.getOptions());
        },
        getPropertyUsernames: () => {
            dispatch(actions.getContactUsernames());
        },
        updateContact: (data, partnersData, addressData) => {
            dispatch(actions.updateContact(data, partnersData, addressData));
        },
        updateCompany: (data) => {
            dispatch(actions.updateCompany(data));
        },
        createCompanyProperties: data => {
            dispatch(actions.createCompanyProperties(data))
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactDialogChooser);
