import * as actionTypes from './actionTypes';

// GET_LISTING_ENTRIES

export const getListingEntries = (data) => {
	return {
		type: actionTypes.GET_LISTING_ENTRIES,
		data,
	};
};

export const getListingEntriesStart = () => {
	return {
		type: actionTypes.GET_LISTING_ENTRIES_START,
	};
};

export const getListingEntriesSuccess = (response) => {
	return {
		type: actionTypes.GET_LISTING_ENTRIES_SUCCESS,
		response,
	};
};

export const getListingEntriesFail = (err) => {
	return {
		type: actionTypes.GET_LISTING_ENTRIES_FAIL,
		err,
	};
};

// GET_LISTING_ENTRY 

export const getListingEntry = (data) => {
    return {
        type: actionTypes.GET_LISTING_ENTRY,
        data
    }
}

export const getListingEntryStart = () => {
    return {
        type: actionTypes.GET_LISTING_ENTRY_START
    }
}

export const getListingEntrySuccess = (response) => {
    return {
        type: actionTypes.GET_LISTING_ENTRY_SUCCESS,
        response
    }
}

export const getListingEntryFail = (err) => {
    return {
        type: actionTypes.GET_LISTING_ENTRY_FAIL,
        err
    }
}

// UPDATE_LISTING_ENTRY

export const updateListingEntry = (data) => {
	return {
		type: actionTypes.UPDATE_LISTING_ENTRY,
		data,
	};
};

export const updateListingEntryStart = () => {
	return {
		type: actionTypes.UPDATE_LISTING_ENTRY_START,
	};
};

export const updateListingEntrySuccess = (response) => {
	return {
		type: actionTypes.UPDATE_LISTING_ENTRY_SUCCESS,
		response,
	};
};

export const updateListingEntryFail = (err) => {
	return {
		type: actionTypes.UPDATE_LISTING_ENTRY_FAIL,
		err,
	};
};

// CREATE_LISTING_ENTRY

export const createListingEntry = (data) => {
	return {
		type: actionTypes.CREATE_LISTING_ENTRY,
		data,
	};
};

export const createListingEntryStart = () => {
	return {
		type: actionTypes.CREATE_LISTING_ENTRY_START,
	};
};

export const createListingEntrySuccess = (response) => {
	return {
		type: actionTypes.CREATE_LISTING_ENTRY_SUCCESS,
		response,
	};
};

export const createListingEntryFail = (err) => {
	return {
		type: actionTypes.CREATE_LISTING_ENTRY_FAIL,
		err,
	};
};

// DELETE_LISTING_ENTRY

export const deleteListingEntry = (data) => {
	return {
		type: actionTypes.DELETE_LISTING_ENTRY,
		data,
	};
};

export const deleteListingEntryStart = () => {
	return {
		type: actionTypes.DELETE_LISTING_ENTRY_START,
	};
};

export const deleteListingEntrySuccess = (response) => {
	return {
		type: actionTypes.DELETE_LISTING_ENTRY_SUCCESS,
		response,
	};
};

export const deleteListingEntryFail = (err) => {
	return {
		type: actionTypes.DELETE_LISTING_ENTRY_FAIL,
		err,
	};
};

// GET_LISTING_PDF

export const getListingPdf = (data) => {
	return {
		type: actionTypes.GET_LISTING_PDF,
		data,
	};
};

export const getListingPdfStart = () => {
	return {
		type: actionTypes.GET_LISTING_PDF_START,
	};
};

export const getListingPdfSuccess = (response) => {
	return {
		type: actionTypes.GET_LISTING_PDF_SUCCESS,
		response,
	};
};

export const getListingPdfFail = (err) => {
	return {
		type: actionTypes.GET_LISTING_PDF_FAIL,
		err,
	};
};

// GET_LISTING_HISTORY

export const getListingHistory = (data) => {
	return {
		type: actionTypes.GET_LISTING_HISTORY,
		data,
	};
};

export const getListingHistoryStart = () => {
	return {
		type: actionTypes.GET_LISTING_HISTORY_START,
	};
};

export const getListingHistorySuccess = (response) => {
	return {
		type: actionTypes.GET_LISTING_HISTORY_SUCCESS,
		response,
	};
};

export const getListingHistoryFail = (err) => {
	return {
		type: actionTypes.GET_LISTING_HISTORY_FAIL,
		err,
	};
};
