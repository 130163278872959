import React from 'react';
import { useEffect, useState } from 'react';
import { FadeLoader } from 'react-spinners';
import Tooltip from "@material-ui/core/Tooltip";
import Edit from "@material-ui/icons/Edit";
import Close from "@material-ui/icons/Close";
import Group from "@material-ui/icons/Group";
import ReactTable from "react-table";
import Button from "../../components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import GroupsAdminModal from './GroupsAdminModal';
import GroupsAdminAskModal from './GroupsAdminAskModal';
import GroupsAdminMembers from './GroupsAdminMembers.js';
import Dialog from '@material-ui/core/Dialog';
import { css } from 'react-emotion';
const override = css`
            display: block;
            margin: 0 auto;
            border-color: red;
        `;
const Popup = (props) => {
    const message = props.message ? 
            props.message.indexOf('Successfully') !== -1 ? 
                <div>{props.message}</div>
                :
                <div style={{color:'red'}}>{props.message}</div>
            : 
            null;
    return (
        <Dialog
            open={props.open}
            keepMounted
            onClose={props.close}
            maxWidth="sm"
            fullWidth={true}
        >
            <div style={{padding:20}}>
                <div style={{textAlign:'center',marginBottom:20}}>{message}</div>
                <div style={{textAlign:'center'}}>
                    <Button 
                        color="success"
                        onClick={() => props.close()}
                        style={{width:100,textAlign:'center'}}
                    >
                        OK
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}
const GroupsAdmin = (props) => {
    const [openedModal, setOpenedModal] = useState(false);
    const [openedAskModal, setOpenedAskModal] = useState(false);
    const [openedPopup, setOpenedPopup] = useState(false);
    const [openedMembers, setOpenedMembers] = useState(false);
    const [groupData, setGroupData] = useState({ action: 'Add' });

    const closeMembersModal = () => {
        props.getWorkspaceGroups();
        setOpenedMembers(false);
    }

    const openMembersModal = (groupData) => {
        setOpenedMembers(true);
        setGroupData(groupData);
    }

    const openModal = (modalType, groupData) => {
        if (modalType === 'Add') {
            setOpenedModal(true);
            setGroupData({
                name: '',
                description: '',
                email: '',
                action: 'Add'
            });
        }
        if (modalType === 'Edit') {
            setOpenedModal(true);
            setGroupData({ ...groupData, action: 'Edit' });
        }
    }

    const closeModal = () => { setOpenedModal(false) };

    const openAskModal = (groupData) => {
        setOpenedAskModal(true);
        setGroupData(groupData);
    }

    const closeAskModal = () => { setOpenedAskModal(false) };

    useEffect(() => {
        props.getWorkspaceGroups();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.popupMessage) {
            setOpenedPopup(true);
        } else {
            setOpenedPopup(false);
        }
    }, [props.popupMessage]);

    const loader = <FadeLoader
        className={override}
        sizeUnit={"px"}
        size={150}
        color={'#123abc'}
        loading={true}
    />

    const table = props.groupList ? <ReactTable
    data={
        props.groupList.map((group, key) => { // map users to table rows
            return ({
                id: key,
                name: group.name,
                email: group.email,
                count: group.directMembersCount,
                description: group.description,
                actions: (
                    <div className="actions-right">
                    <Tooltip
                        title="Members"
                        placement="top"
                    >
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                                openMembersModal(group);
                            }}
                            color="primary"
                        >
                            <Group />
                        </Button>
                    </Tooltip>
                    <Tooltip
                        title="Edit"
                        placement="top"
                    >
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                                openModal('Edit', group);
                            }}
                            color="primary"
                        >
                            <Edit />
                        </Button>
                    </Tooltip>
                        {" "}
                    <Tooltip
                        title="Remove"
                        placement="top"
                    >
                        <Button
                            justIcon
                            round
                            simple
                            onClick={() => {
                                openAskModal(group);
                            }}
                            color="danger"
                        >
                            <Close />
                        </Button>
                    </Tooltip>
                    </div>
                )
            })
        })
    }
    filterable
    columns={[
        {
            Header: "Name",
            accessor: "name",
            filterMethod: (filter, row) => {
            var filterValue = filter.value.toLowerCase();
            if (filterValue.length > 0) {
                return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            } else {
                return true;
            }
            }
        },
        {
            Header: "Email",
            accessor: "email",
            filterMethod: (filter, row) => {
            var filterValue = filter.value.toLowerCase();
            if (filterValue.length > 0 && row[filter.id]) {
                return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            } else if (filterValue.length > 0 && !row[filter.id]) {
                return false;
            } else {
                return true;
            }
            }
        },
        {
            Header: "Description",
            accessor: "description",
            filterMethod: (filter, row) => {
            var filterValue = filter.value.toLowerCase();
            if (filterValue.length > 0 && row[filter.id]) {
                return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            } else if (filterValue.length > 0 && !row[filter.id]) {
                return false;
            } else {
                return true;
            }
            }
        },
        {
            Header: "Members",
            accessor: "count",
            filterMethod: (filter, row) => {
            var filterValue = filter.value.toLowerCase();
            if (filterValue.length > 0 && row[filter.id]) {
                return row[filter.id].toLowerCase().indexOf(filterValue.toLowerCase()) !== -1;
            } else if (filterValue.length > 0 && !row[filter.id]) {
                return false;
            } else {
                return true;
            }
            }
        },
        {
            Header: "",
            accessor: "actions",
            sortable: false,
            filterable: false,
        }
    ]}
    defaultPageSize={10}
    showPaginationTop
    showPaginationBottom={false}
    className="-striped"
/> : null;

    return (
    <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
        <Button
            color="rose"
            round
            style={{marginRight:10}}
            onClick={() => openModal('Add')}>
            Add Group
        </Button>
        <Button
            color="white"
            round
            onClick={() => props.getWorkspaceGroups()}>
            Refresh
        </Button>
        {props.loading && loader}
        {!props.loading && table}
      </GridItem>
      {openedModal && <GroupsAdminModal 
        open={openedModal}
        close={closeModal}
        action={groupData.action}
        groupData={groupData}
        createWorkspaceGroup={props.createWorkspaceGroup}
        updateWorkspaceGroup={props.updateWorkspaceGroup}
      />}
      {openedAskModal && <GroupsAdminAskModal 
        open={openedAskModal}
        close={closeAskModal}
        groupData={groupData}
        deleteWorkspaceGroup={props.deleteWorkspaceGroup}
      />}
      {openedMembers && <GroupsAdminMembers 
        open={openedMembers}
        close={closeMembersModal}
        groupData={groupData}
        createWorkspaceGroupMember={props.createWorkspaceGroupMember}
        deleteWorkspaceGroupMember={props.deleteWorkspaceGroupMember}
        getWorkspaceGroupMembers={props.getWorkspaceGroupMembers}
        getWorkspaceUsers={props.getWorkspaceUsers}
        memberList={props.memberList}
        userList={props.userList}
      />}
      {openedPopup && <Popup 
        open={openedPopup}
        close={() => { setOpenedPopup(false) }}
        message={props.popupMessage}
      />}
    </GridContainer>
    )
}

export default GroupsAdmin;