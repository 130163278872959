import React, { useState, useEffect } from "react";
import Button from "../../components/CustomButtons/Button";
import axios from "store/axios/axios-userperms";
import { permsSimple } from "../../shared/permsObj";
import { Checkbox, IconButton } from "@material-ui/core";
import GridItem from "../../components/Grid/GridItem";
import GridContainer from "../../components/Grid/GridContainer";
import CustomInput from "../../components/CustomInput/CustomInputValidate";
import Table from "components/Table/TableSmall.jsx";
import { clone } from "../../shared/utility";
import NotificationAsk from "../../components/Notification/NotificationAsk";
import Notification from "../../components/Notification/Notification";
import NotificationFixedTop from "components/Notification/NotificationFixedTop";
import { Close } from "@material-ui/icons";
import UserRolesMatrix from "./UserRolesMatrix";

const UserRoles = props => {
    const [roles, setRoles] = useState([]);
    const [action, setAction] = useState('list');
    const [name, setName] = useState('');
    const [data, setData] = useState([]);
    const [askDelete, setAskDelete] = useState(null);
    const [editData, setEditData] = useState(null);
    const [notification, setNotification] = useState('');
    const [notificationFixed, setNotificationFixed] = useState('');
    const [currentUserData, setCurrentUserData] = useState(null);

    const loadRoles = async () => {
        try {
            const results = await axios.get(`/roles`);
            const roles_result = results ? results.data : [];
            if (roles_result.length > 0) {
                setRoles(roles_result);
            }
        } catch(e) {
            setNotification('Error, could not get roles.')
        }
    }

    const addRole = async (obj) => {
        try {
            await axios.post(`/role`, obj);
            loadRoles();
        } catch(e) {
            setNotification('Error, could not create role.')
        }
    }

    const updateRole = async (obj) => {
        try {
            await axios.put(`/role`, obj);
            loadRoles();
            setNotificationFixed('Role updated.');
            setTimeout(() => {
                setNotificationFixed('');
            }, 2000);
        } catch(e) {
            setNotification('Error, could not update role.')
        }
    }

    const deleteRole = async (obj) => {
        try {
            await axios.delete(`/role?id=${obj.id}`, obj);
            loadRoles();
        } catch(e) {
            setNotification('Error, could not delete role.')
        }
    }

    const loadCurrentUserData = async (id) => {
        try {
            const currentUserDataQuery = await axios.get('/user/'+id);
            console.log('currentUserDataQuery', currentUserDataQuery.data);
            if (currentUserDataQuery.data) {
                let userDataArr = [];
                currentUserDataQuery.data.forEach(d => {
                    userDataArr.push(d.perm_id)
                });
                setCurrentUserData(userDataArr);
            } else {
                throw "No user roles loaded";
            }
            loadRoles();
        } catch(e) {
            setNotification('Error, could not load roles.')
        }
    }

    const applyRole = async (obj) => {
        try {
            console.log('applying role to user', obj)
            await axios.post('/user', obj);
            loadRoles();
            setNotificationFixed('Applied Role to user.');
            setTimeout(() => {
                setNotificationFixed('');
            }, 2000);
        } catch(e) {
            setNotification('Error, could not apply role to user.')
        }
    }

    useEffect(() => {
        // load 
        loadRoles();
        console.log('props', props);
        if (props.currentUser) {
            loadCurrentUserData(props.currentUser.id)
        }
    }, []);

    console.log('Roles', roles, data, props, currentUserData)

    return <>
        {<IconButton
            onClick={() => {
                props.close();
            }}
            style={{float:'right'}}
        ><Close /></IconButton>}
        <h1>Roles</h1>
        {action === 'list' && <Button
            className="mr-20"
            onClick={() => {
                setName('');
                setData([]);
                setAction('add');
            }}
        >ADD NEW</Button>}
        {(action === 'list' && currentUserData) && <Button
            color="rose"
            onClick={() => {
                setName('');
                const newData = currentUserData.concat([]);
                console.log('newData', newData);
                setData(newData);
                setAction('add');
            }}
        >CREATE NEW USER ROLE FROM {props.currentUser.name}'s PERMISSIONS</Button>}

        {action === 'list' && <div className="boxed"><Table 
            tableHeaderColor="primary"
            tableHead={[
              '#',
              <strong>Name</strong>,
              <strong>Actions</strong>
          ]}
            tableData={roles.map(r => {
                return [
                    r.id,
                    r.name,
                    <>
                        <IconButton
                            onClick={() => {
                                setAction('edit');
                                setEditData({
                                    id: r.id,
                                    name: r.name,
                                    data: JSON.parse(r.data)
                                });
                            }}
                            className="mr-20"
                        >
                            <i className="fa fa-edit"></i>
                        </IconButton>
                        <IconButton
                            className="mr-20"
                            onClick={() => {
                                setAskDelete({
                                    id: r.id,
                                    name: r.name
                                })
                            }}
                        >
                            <i className="fa fa-trash"></i>
                        </IconButton>
                        {(props.currentUser && currentUserData) && <Button
                            color="success"
                            className="mr-20"
                            onClick={() => {
                                const r_data = JSON.parse(r.data);
                                applyRole({
                                    user_id: currentUserData.id,
                                    user_perms: {
                                        add: r_data.map(cud => {
                                            return {
                                                user_id: currentUserData.id,
                                                perm_id: cud,
                                                db_id: null
                                            }
                                        }),
                                        remove: []
                                    }
                                })
                            }}
                        >
                            APPLY THIS ROLE TO {props.currentUser.name}
                        </Button>}
                        {/* {(props.currentUser && currentUserData) && <Button
                            color="success"
                            className="mr-20"
                            onClick={() => {
                                setAction('edit');
                                setEditData({
                                    id: r.id,
                                    name: r.name,
                                    data: JSON.parse(r.data)
                                });
                            }}
                        >
                            COPY CURRENT USER'S PERMISSIONS
                        </Button>} */}
                    </>
                ]
            })}
        /></div>}

        {action === 'add' && <div className="boxed" style={{padding:20}}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {<Button color="success" disabled={(name && data.length > 0) ? false : true} 
                        className="mr-20" 
                        onClick={() => {
                            const newRole = {
                                name,
                                data: JSON.stringify(data)
                            };
                            console.log('newRole', newRole);
                            addRole(newRole);
                            setAction('list')
                    }}>CREATE NEW ROLE</Button>}
                    <Button color="white" onClick={() => {
                        setAction('list')
                        setEditData(null);
                    }}>CANCEL</Button>
                    <CustomInput    
                        value={name}
                        onChange={(e) => {
                            setName(e.target.value)
                        }}
                        label="Role Name"
                    />
                </GridItem>
                {<UserRolesMatrix 
                    data={data}
                    setData={setData}
                />}
            </GridContainer>
        </div>}

        {(action === 'edit' && editData) && <div className="boxed" style={{padding:20}}>
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    {<Button color="success" disabled={(editData.name && editData.data.length > 0) ? false : true} onClick={() => {
                        const updatedRole = {
                            name: editData.name,
                            data: JSON.stringify(editData.data),
                            id: editData.id
                        };
                        console.log('updatedRole', updatedRole);
                        updateRole(updatedRole);
                        setAction('list');
                    }}>UPDATE ROLE</Button>}
                    <Button color="white" onClick={() => {
                        setAction('list');
                    }}>CANCEL</Button>
                    <CustomInput    
                        value={editData.name}
                        onChange={(e) => {
                            setEditData({
                                name: e.target.value,
                                data: editData.data
                            })
                        }}
                        label="Role Name"
                    />
                </GridItem>
                {<UserRolesMatrix 
                    data={editData.data}
                    setData={(newData) => {
                        const newEditData = clone(editData);
                        newEditData.data = newData;
                        setEditData(newEditData);
                    }}
                />}
                {/* {permsSimple.map((pm, i) => {
                    return <GridItem xs={12} sm={6} md={6} key={`checkbox-${i}`} className="grayed"><Checkbox 
                        checked={editData.data.indexOf(pm.id) !== -1 ? true : false}
                        onChange={() => {
                            if (editData.data.indexOf(pm.id) === -1) {
                                const newEditData = clone(editData);
                                newEditData.data = newEditData.data.concat([pm.id]);
                                setEditData(newEditData);
                            } else {
                                setData(data.filter((d) => d !== pm.id));
                                const newEditData = clone(editData);
                                newEditData.data = newEditData.data.filter((d) => d !== pm.id)
                                setEditData(newEditData);
                            }
                        }}
                    /> - {pm.name}
                </GridItem>
                })} */}
            </GridContainer>
        </div>}

        {askDelete && <NotificationAsk 
            open={true}
            close={() => {
                setAskDelete(null);
            }}
            title="Are you sure?"
            message="Do you want to delete this role?"
            success={() => {
                deleteRole({
                    id: askDelete.id
                })
                setAskDelete(null);
            }}
        />}

        {notification && <Notification 
            message={notification}
            open={true}
            close={() => {
                setNotification('');
            }}
        />}

        {notificationFixed && <NotificationFixedTop 
            message={notificationFixed}
            open={true}
            close={() => {
                setNotificationFixed('');
            }}
        />}
    </>
}

export default UserRoles;