import * as actionTypes from './actionTypes';

// GET ALL PROPERTIES USING PARAMS

export const getProperties = (params) => {
    return {
        type: actionTypes.GET_PROPERTIES,
        params
    }
}

export const getPropertiesSuccess = (response, cs_approved_properties) => {
    return {
        type: actionTypes.GET_PROPERTIES_SUCCESS,
        response,
        cs_approved_properties
    }
}

export const getPropertiesFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTIES_FAIL,
        err
    }
}

export const getPropertiesStart = () => {
    return {
        type: actionTypes.GET_PROPERTIES_START
    }
}

// GET PROPERTY

export const getProperty = (id) => {
    return {
        type: actionTypes.GET_PROPERTY,
        id
    }
}

export const getPropertyStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_START
    }
}

export const getPropertySuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_SUCCESS,
        response
    }
}

export const getPropertyFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_FAIL,
        err
    }
}

// GET_PROPERTY_CONTACTS

export const getPropertyContacts = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_CONTACTS,
        data
    }
}

export const getPropertyContactsStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_CONTACTS_START
    }
}

export const getPropertyContactsSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_CONTACTS_SUCCESS,
        response
    }
}

export const getPropertyContactsFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_CONTACTS_FAIL,
        err
    }
}

// GET_PROPERTY_NAMES

export const getPropertyNames = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_NAMES,
        data
    }
}

export const getPropertyNamesStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_NAMES_START
    }
}

export const getPropertyNamesSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_NAMES_SUCCESS,
        response
    }
}

export const getPropertyNamesFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_NAMES_FAIL,
        err
    }
}

// CREATE PROPERTY

export const createProperty = (data, otherData) => {
    return {
        type: actionTypes.CREATE_PROPERTY,
        data,
        otherData
    }
}

export const createPropertyStart = () => {
    return {
        type: actionTypes.CREATE_PROPERTY_START
    }
}

export const createPropertySuccess = (response) => {
    return {
        type: actionTypes.CREATE_PROPERTY_SUCCESS,
        response
    }
}

export const createPropertyFail = (err) => {
    return {
        type: actionTypes.CREATE_PROPERTY_FAIL,
        err
    }
}

// UPDATE PROPERTY

export const updateProperty = (data) => {
    return {
        type: actionTypes.UPDATE_PROPERTY,
        data
    }
}

export const updatePropertyStart = () => {
    return {
        type: actionTypes.UPDATE_PROPERTY_START
    }
}

export const updatePropertySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_PROPERTY_SUCCESS,
        response
    }
}

export const updatePropertyFail = (err) => {
    return {
        type: actionTypes.UPDATE_PROPERTY_FAIL,
        err
    }
}

// DELETE PROPERTY

export const deleteProperty = (id) => {
    return {
        type: actionTypes.DELETE_PROPERTY,
        id
    }
}

export const deletePropertyStart = () => {
    return {
        type: actionTypes.DELETE_PROPERTY_START
    }
}

export const deletePropertySuccess = (response) => {
    return {
        type: actionTypes.DELETE_PROPERTY_SUCCESS,
        response
    }
}

export const deletePropertyFail = (err) => {
    return {
        type: actionTypes.DELETE_PROPERTY_FAIL,
        err
    }
}

// GET PROPERTY SHARES

export const getPropertyShares = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_SHARES,
        data
    }
}

export const getPropertySharesStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_SHARES_START
    }
}

export const getPropertySharesSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_SHARES_SUCCESS,
        response
    }
}

export const getPropertySharesFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_SHARES_FAIL,
        err
    }
}

// CREATE_PROPERTY_SHARE

export const createPropertyShare = (data) => {
    return {
        type: actionTypes.CREATE_PROPERTY_SHARE,
        data
    }
}

export const createPropertyShareStart = () => {
    return {
        type: actionTypes.CREATE_PROPERTY_SHARE_START
    }
}

export const createPropertyShareSuccess = (response) => {
    return {
        type: actionTypes.CREATE_PROPERTY_SHARE_SUCCESS,
        response
    }
}

export const createPropertyShareFail = (err) => {
    return {
        type: actionTypes.CREATE_PROPERTY_SHARE_FAIL,
        err
    }
}

// DELETE_PROPERTY_SHARE

export const deletePropertyShare = (data) => {
    return {
        type: actionTypes.DELETE_PROPERTY_SHARE,
        data
    }
}

export const deletePropertyShareStart = () => {
    return {
        type: actionTypes.DELETE_PROPERTY_SHARE_START
    }
}

export const deletePropertyShareSuccess = (response) => {
    return {
        type: actionTypes.DELETE_PROPERTY_SHARE_SUCCESS,
        response
    }
}

export const deletePropertyShareFail = (err) => {
    return {
        type: actionTypes.DELETE_PROPERTY_SHARE_FAIL,
        err
    }
}

// GET_LEASES

export const getLeases = (data) => {
    return {
        type: actionTypes.GET_LEASES,
        data
    }
}

export const getLeasesStart = () => {
    return {
        type: actionTypes.GET_LEASES_START
    }
}

export const getLeasesSuccess = (response) => {
    return {
        type: actionTypes.GET_LEASES_SUCCESS,
        response
    }
}

export const getLeasesFail = (err) => {
    return {
        type: actionTypes.GET_LEASES_FAIL,
        err
    }
}

// GET_LEASE

export const getLease = (data) => {
    return {
        type: actionTypes.GET_LEASE,
        data
    }
}

export const getLeaseStart = () => {
    return {
        type: actionTypes.GET_LEASE_START
    }
}

export const getLeaseSuccess = (response) => {
    return {
        type: actionTypes.GET_LEASE_SUCCESS,
        response
    }
}

export const getLeaseFail = (err) => {
    return {
        type: actionTypes.GET_LEASE_FAIL,
        err
    }
}

// CREATE_LEASE

export const createLease = (data) => {
    return {
        type: actionTypes.CREATE_LEASE,
        data
    }
}

export const createLeaseStart = () => {
    return {
        type: actionTypes.CREATE_LEASE_START
    }
}

export const createLeaseSuccess = (response) => {
    return {
        type: actionTypes.CREATE_LEASE_SUCCESS,
        response
    }
}

export const createLeaseFail = (err) => {
    return {
        type: actionTypes.CREATE_LEASE_FAIL,
        err
    }
}

// UPDATE_LEASE

export const updateLease = (data) => {
    return {
        type: actionTypes.UPDATE_LEASE,
        data
    }
}

export const updateLeaseStart = () => {
    return {
        type: actionTypes.UPDATE_LEASE_START
    }
}

export const updateLeaseSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_LEASE_SUCCESS,
        response
    }
}

export const updateLeaseFail = (err) => {
    return {
        type: actionTypes.UPDATE_LEASE_FAIL,
        err
    }
}

// DELETE_LEASE

export const deleteLease = (data) => {
    return {
        type: actionTypes.DELETE_LEASE,
        data
    }
}

export const deleteLeaseStart = () => {
    return {
        type: actionTypes.DELETE_LEASE_START
    }
}

export const deleteLeaseSuccess = (response) => {
    return {
        type: actionTypes.DELETE_LEASE_SUCCESS,
        response
    }
}

export const deleteLeaseFail = (err) => {
    return {
        type: actionTypes.DELETE_LEASE_FAIL,
        err
    }
}

// GET_LISTINGS

export const getListings = (data) => {
    return {
        type: actionTypes.GET_LISTINGS,
        data
    }
}

export const getListingsStart = () => {
    return {
        type: actionTypes.GET_LISTINGS_START
    }
}

export const getListingsSuccess = (response) => {
    return {
        type: actionTypes.GET_LISTINGS_SUCCESS,
        response
    }
}

export const getListingsFail = (err) => {
    return {
        type: actionTypes.GET_LISTINGS_FAIL,
        err
    }
}

// GET_LISTING

export const getListing = (data) => {
    return {
        type: actionTypes.GET_LISTING,
        data
    }
}

export const getListingStart = () => {
    return {
        type: actionTypes.GET_LISTING_START
    }
}

export const getListingSuccess = (response) => {
    return {
        type: actionTypes.GET_LISTING_SUCCESS,
        response
    }
}

export const getListingFail = (err) => {
    return {
        type: actionTypes.GET_LISTING_FAIL,
        err
    }
}

// CREATE_LISTING

export const createListing = (data) => {
    return {
        type: actionTypes.CREATE_LISTING,
        data
    }
}

export const createListingStart = () => {
    return {
        type: actionTypes.CREATE_LISTING_START
    }
}

export const createListingSuccess = (response) => {
    return {
        type: actionTypes.CREATE_LISTING_SUCCESS,
        response
    }
}

export const createListingFail = (err) => {
    return {
        type: actionTypes.CREATE_LISTING_FAIL,
        err
    }
}

// UPDATE_LISTING

export const updateListing = (data) => {
    return {
        type: actionTypes.UPDATE_LISTING,
        data
    }
}

export const updateListingStart = () => {
    return {
        type: actionTypes.UPDATE_LISTING_START
    }
}

export const updateListingSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_LISTING_SUCCESS,
        response
    }
}

export const updateListingFail = (err) => {
    return {
        type: actionTypes.UPDATE_LISTING_FAIL,
        err
    }
}

// DELETE_LISTING

export const deleteListing = (data) => {
    return {
        type: actionTypes.DELETE_LISTING,
        data
    }
}

export const deleteListingStart = () => {
    return {
        type: actionTypes.DELETE_LISTING_START
    }
}

export const deleteListingSuccess = (response) => {
    return {
        type: actionTypes.DELETE_LISTING_SUCCESS,
        response
    }
}

export const deleteListingFail = (err) => {
    return {
        type: actionTypes.DELETE_LISTING_FAIL,
        err
    }
}

// GET_MEETINGS

export const getMeetings = (data) => {
    return {
        type: actionTypes.GET_MEETINGS,
        data
    }
}

export const getMeetingsStart = () => {
    return {
        type: actionTypes.GET_MEETINGS_START
    }
}

export const getMeetingsSuccess = (response) => {
    return {
        type: actionTypes.GET_MEETINGS_SUCCESS,
        response
    }
}

export const getMeetingsFail = (err) => {
    return {
        type: actionTypes.GET_MEETINGS_FAIL,
        err
    }
}

// GET_MEETING

export const getMeeting = (data) => {
    return {
        type: actionTypes.GET_MEETING,
        data
    }
}

export const getMeetingStart = () => {
    return {
        type: actionTypes.GET_MEETING_START
    }
}

export const getMeetingSuccess = (response) => {
    return {
        type: actionTypes.GET_MEETING_SUCCESS,
        response
    }
}

export const getMeetingFail = (err) => {
    return {
        type: actionTypes.GET_MEETING_FAIL,
        err
    }
}

// CREATE_MEETING

export const createMeeting = (data) => {
    return {
        type: actionTypes.CREATE_MEETING,
        data
    }
}

export const createMeetingStart = () => {
    return {
        type: actionTypes.CREATE_MEETING_START
    }
}

export const createMeetingSuccess = (response) => {
    return {
        type: actionTypes.CREATE_MEETING_SUCCESS,
        response
    }
}

export const createMeetingFail = (err) => {
    return {
        type: actionTypes.CREATE_MEETING_FAIL,
        err
    }
}

// UPDATE_MEETING

export const updateMeeting = (data) => {
    return {
        type: actionTypes.UPDATE_MEETING,
        data
    }
}

export const updateMeetingStart = () => {
    return {
        type: actionTypes.UPDATE_MEETING_START
    }
}

export const updateMeetingSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_MEETING_SUCCESS,
        response
    }
}

export const updateMeetingFail = (err) => {
    return {
        type: actionTypes.UPDATE_MEETING_FAIL,
        err
    }
}

// DELETE_MEETING

export const deleteMeeting = (data) => {
    return {
        type: actionTypes.DELETE_MEETING,
        data
    }
}

export const deleteMeetingStart = () => {
    return {
        type: actionTypes.DELETE_MEETING_START
    }
}

export const deleteMeetingSuccess = (response) => {
    return {
        type: actionTypes.DELETE_MEETING_SUCCESS,
        response
    }
}

export const deleteMeetingFail = (err) => {
    return {
        type: actionTypes.DELETE_MEETING_FAIL,
        err
    }
}

// GET_OFFERS

export const getOffers = (data) => {
    return {
        type: actionTypes.GET_OFFERS,
        data
    }
}

export const getOffersStart = () => {
    return {
        type: actionTypes.GET_OFFERS_START
    }
}

export const getOffersSuccess = (response) => {
    return {
        type: actionTypes.GET_OFFERS_SUCCESS,
        response
    }
}

export const getOffersFail = (err) => {
    return {
        type: actionTypes.GET_OFFERS_FAIL,
        err
    }
}

// GET_OFFER

export const getOffer = (data) => {
    return {
        type: actionTypes.GET_OFFER,
        data
    }
}

export const getOfferStart = () => {
    return {
        type: actionTypes.GET_OFFER_START
    }
}

export const getOfferSuccess = (response) => {
    return {
        type: actionTypes.GET_OFFER_SUCCESS,
        response
    }
}

export const getOfferFail = (err) => {
    return {
        type: actionTypes.GET_OFFER_FAIL,
        err
    }
}

// CREATE_OFFER

export const createOffer = (data) => {
    return {
        type: actionTypes.CREATE_OFFER,
        data
    }
}

export const createOfferStart = () => {
    return {
        type: actionTypes.CREATE_OFFER_START
    }
}

export const createOfferSuccess = (response) => {
    return {
        type: actionTypes.CREATE_OFFER_SUCCESS,
        response
    }
}

export const createOfferFail = (err) => {
    return {
        type: actionTypes.CREATE_OFFER_FAIL,
        err
    }
}

// UPDATE_OFFER

export const updateOffer = (data) => {
    return {
        type: actionTypes.UPDATE_OFFER,
        data
    }
}

export const updateOfferStart = () => {
    return {
        type: actionTypes.UPDATE_OFFER_START
    }
}

export const updateOfferSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_OFFER_SUCCESS,
        response
    }
}

export const updateOfferFail = (err) => {
    return {
        type: actionTypes.UPDATE_OFFER_FAIL,
        err
    }
}

// DELETE_OFFER

export const deleteOffer = (data) => {
    return {
        type: actionTypes.DELETE_OFFER,
        data
    }
}

export const deleteOfferStart = () => {
    return {
        type: actionTypes.DELETE_OFFER_START
    }
}

export const deleteOfferSuccess = (response) => {
    return {
        type: actionTypes.DELETE_OFFER_SUCCESS,
        response
    }
}

export const deleteOfferFail = (err) => {
    return {
        type: actionTypes.DELETE_OFFER_FAIL,
        err
    }
}

// GET_SUB_PROPERTIES

export const getSubProperties = (data, is_illi) => {
    return {
        type: actionTypes.GET_SUB_PROPERTIES,
        data,
        is_illi
    }
}

export const getSubPropertiesStart = () => {
    return {
        type: actionTypes.GET_SUB_PROPERTIES_START
    }
}

export const getSubPropertiesSuccess = (response) => {
    return {
        type: actionTypes.GET_SUB_PROPERTIES_SUCCESS,
        response
    }
}

export const getSubPropertiesFail = (err) => {
    return {
        type: actionTypes.GET_SUB_PROPERTIES_FAIL,
        err
    }
}

export const checkPropertyExistingAddress = (data) => ({
  type: actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS,
  data,
});

export const checkPropertyExistingAddressStart = () => ({
  type: actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_START,
});

export const checkPropertyExistingAddressSuccess = (response) => ({
  type: actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_SUCCESS,
  response,
});

export const checkPropertyExistingAddressFail = (err) => ({
  type: actionTypes.CHECK_PROPERTY_EXISTING_ADDRESS_FAIL,
  err,
});


// GET OPTIONS
export const getOptions = (data) => {
    return {
        type: actionTypes.GET_OPTIONS,
        data
    }
}

export const getOptionsStart = () => {
    return {
        type: actionTypes.GET_OPTIONS_START
    }
}

export const getOptionsSuccess = (response) => {
    return {
        type: actionTypes.GET_OPTIONS_SUCCESS,
        response
    }
}

export const getOptionsFail = (err) => {
    return {
        type: actionTypes.GET_OPTIONS_FAIL,
        err
    }
}

export const clearPropertyCRMMessages = () => {
    return {
        type: actionTypes.CLEAR_PROPERTY_CRM_MESSAGES
    }
}

// GET_PROPERTY_CHANGES 

export const getPropertyChanges = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_CHANGES,
        data
    }
}

export const getPropertyChangesStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_CHANGES_START
    }
}

export const getPropertyChangesSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_CHANGES_SUCCESS,
        response
    }
}

export const getPropertyChangesFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_CHANGES_FAIL,
        err
    }
}

// SAVE_PROPERTY_CHANGES 

export const savePropertyChanges = (data) => {
    return {
        type: actionTypes.SAVE_PROPERTY_CHANGES,
        data
    }
}

export const savePropertyChangesStart = () => {
    return {
        type: actionTypes.SAVE_PROPERTY_CHANGES_START
    }
}

export const savePropertyChangesSuccess = (response) => {
    return {
        type: actionTypes.SAVE_PROPERTY_CHANGES_SUCCESS,
        response
    }
}

export const savePropertyChangesFail = (err) => {
    return {
        type: actionTypes.SAVE_PROPERTY_CHANGES_FAIL,
        err
    }
}

export const setPropertyChanges = (data) => {
    return {
        type: actionTypes.SET_PROPERTY_CHANGES,
        data
    }
}
        
// GET_PROPERTY_OWNERS_CONTACTS 

export const getPropertyOwnersContacts = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_OWNERS_CONTACTS,
        data
    }
}

// GET_PROPERTY_OWNERS_CONTACTS 

export const getPropertyOwnersContactsStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_OWNERS_CONTACTS_START
    }
}

export const getPropertyOwnersContactsSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_OWNERS_CONTACTS_SUCCESS,
        response
    }
}

export const getPropertyOwnersContactsFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_OWNERS_CONTACTS_FAIL,
        err
    }
}

// IMPORT_PROPERTIES 

export const importProperties = (data, checkOnly) => {
    return {
        type: actionTypes.IMPORT_PROPERTIES,
        data,
        checkOnly
    }
}

export const importPropertiesStart = () => {
    return {
        type: actionTypes.IMPORT_PROPERTIES_START
    }
}

export const importPropertiesSuccess = (response) => {
    return {
        type: actionTypes.IMPORT_PROPERTIES_SUCCESS,
        response
    }
}

export const importPropertiesFail = (err) => {
    return {
        type: actionTypes.IMPORT_PROPERTIES_FAIL,
        err
    }
}

export const setImportProperties = (new_import_data) => {
    return {
        type: actionTypes.SET_IMPORT_PROPERTIES,
        new_import_data
    }
}

// IMPORT_COSTAR_PROPERTIES 

export const importCostarProperties = (data) => {
    return {
        type: actionTypes.IMPORT_COSTAR_PROPERTIES,
        data
    }
}

export const importCostarPropertiesStart = () => {
    return {
        type: actionTypes.IMPORT_COSTAR_PROPERTIES_START
    }
}

export const importCostarPropertiesSuccess = (response) => {
    return {
        type: actionTypes.IMPORT_COSTAR_PROPERTIES_SUCCESS,
        response
    }
}

export const importCostarPropertiesFail = (err) => {
    return {
        type: actionTypes.IMPORT_COSTAR_PROPERTIES_FAIL,
        err
    }
}

// CREATE_SEND_TO_MARKETING 

export const createSendToMarketing = (data) => {
    return {
        type: actionTypes.CREATE_SEND_TO_MARKETING,
        data
    }
}

export const createSendToMarketingStart = () => {
    return {
        type: actionTypes.CREATE_SEND_TO_MARKETING_START
    }
}

export const createSendToMarketingSuccess = (response) => {
    return {
        type: actionTypes.CREATE_SEND_TO_MARKETING_SUCCESS,
        response
    }
}

export const createSendToMarketingFail = (err) => {
    return {
        type: actionTypes.CREATE_SEND_TO_MARKETING_FAIL,
        err
    }
}

// GET_PROPERTY_DOCUMENTS 

export const getPropertyDocuments = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_DOCUMENTS,
        data
    }
}

export const getPropertyDocumentsStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_DOCUMENTS_START
    }
}

export const getPropertyDocumentsSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_DOCUMENTS_SUCCESS,
        response
    }
}

export const getPropertyDocumentsFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_DOCUMENTS_FAIL,
        err
    }
}

// CHECK_ILLI_NUMBER_FOR_PROPERTY 

export const checkIlliNumberForProperty = (data) => {
    return {
        type: actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY,
        data
    }
}

export const checkIlliNumberForPropertyStart = () => {
    return {
        type: actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_START
    }
}

export const checkIlliNumberForPropertySuccess = (response) => {
    return {
        type: actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_SUCCESS,
        response
    }
}

export const checkIlliNumberForPropertyFail = (err) => {
    return {
        type: actionTypes.CHECK_ILLI_NUMBER_FOR_PROPERTY_FAIL,
        err
    }
}

// GET_PROPERTY_FILES 

export const getPropertyFiles = (data) => {
    return {
        type: actionTypes.GET_PROPERTY_FILES,
        data
    }
}

export const getPropertyFilesStart = () => {
    return {
        type: actionTypes.GET_PROPERTY_FILES_START
    }
}

export const getPropertyFilesSuccess = (response) => {
    return {
        type: actionTypes.GET_PROPERTY_FILES_SUCCESS,
        response
    }
}

export const getPropertyFilesFail = (err) => {
    return {
        type: actionTypes.GET_PROPERTY_FILES_FAIL,
        err
    }
}