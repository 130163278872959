import * as actionTypes from '../actions/actionTypes';
import { updateObject, clone } from '../../shared/utility';

const initialState = {
    error: null,
    success: null,
    success_crud: null,
    loadingEntries: false,
    loading: false,
    rsheetsData: [],
    rsheetData: null,
    pdfBuffer: null,
    rsheetFiles: null,
    rsTypes: [],
    nextIlliNumber: null,
    isDuplicatingIlliNumber: false,
    rsheetRevision: null,
    error_history: null,
    success_history: null,
    loading_history: false,
    error_reviewers: null,
    success_reviewers: null,
    internal_reviewers_choices: [],
    internal_reviewers_loading: false
}

const clearRSheets = (state, action) => {
    return updateObject(state, clone(initialState));
}

const clearRSheetsMessages = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const clearRsheetData = (state, action) => {
    return updateObject(state, {
        rsheetData: null
    })
}

// GET_RSHEETS

const getRsheetsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loadingEntries: true,
        rsheetsData: []
    })
}

const getRsheetsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got Sheets',
        rsheetsData: action.response,
        loadingEntries: false
    })
}

const getRsheetsFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loadingEntries: false
    })
}

// GET_RSHEET

const getRsheetStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        rsheetData: null
    })
}

const getRsheetSuccess = (state, action) => {
    const rsheetData = action.response;
    if (rsheetData) {
        // add id to json_data
        const json_data = rsheetData.json_data;
        if (json_data) {
            json_data.id = rsheetData.id;
        }
        const payments = json_data.payment_schedule ? json_data.payment_schedule : [];
        
        // Refactor payments for old data
        if (payments.length > 0 && !Array.isArray(payments[0])) {
            const newPayments = [clone(payments)];
            json_data.payment_schedule = newPayments
        }
        rsheetData.json_data = json_data;
    }
    
    return updateObject(state, {
        success: 'Got Sheet',
        rsheetData
    })
}

const getRsheetFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_RSHEET

const createRsheetStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        success_crud: null
    })
}

const createRsheetSuccess = (state, action) => {
    let id = ''
    if (action.response) {
        if (action.response.insertId) id = action.response.insertId;
    }
    return updateObject(state, {
        success: `Created Sheet, ${id ? `illi #${id}` : ''}`,
        success_crud: 'successfully created',
    })
}

const createRsheetFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_RSHEET

const updateRsheetStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        success_crud: null
    })
}

const updateRsheetSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Updated Sheet',
        success_crud: 'successfully updated',
    })
}

const updateRsheetFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_RSHEET

const deleteRsheetStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        success_crud: null
    })
}

const deleteRsheetSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Deleted sheet',
        success_crud: 'successfully deleted'
    })
}

const deleteRsheetFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_RSHEET_PDF

const createRsheetPdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        pdfBuffer: null
    })
}

const createRsheetPdfSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Created PDF',
        pdfBuffer: action.response
    })
}

const createRsheetPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_RSHEET_FILES

const createRsheetFilesStart = (state, action) => {
    return updateObject(state, {
        success: 'Successfully uploaded document',
        loading: false
    })
}

const createRsheetFilesSuccess = (state, action) => {
    return updateObject(state, {
        error: 'Failed to upload document',
        loading: false
    })
}

const createRsheetFilesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_RSHEET_FILES

const getRsheetFilesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const getRsheetFilesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully retrieved files.',
        loading: false,
        rsheetFiles: action.response
    })
}

const getRsheetFilesFail = (state, action) => {
    return updateObject(state, {
        error: 'Failed to retrieve rsheet files.',
        loading: false
    })
}

// GET_RS_TYPES

const getRsTypesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getRsTypesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        rsTypes: action.response,
    })
}

const getRsTypesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_NEXT_RS_ILLI_NUMBER

const getNextRsIlliNumberStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        nextIlliNumber: null,
    })
}

const getNextRsIlliNumberSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        nextIlliNumber: action.response.illi_number
    })
}

const getNextRsIlliNumberFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CHECK_RS_ILLI_NUMBER

const checkRsIlliNumberStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        isDuplicatingIlliNumber: false,
    })
}

const checkRsIlliNumberSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        isDuplicatingIlliNumber: action.response.is_duplicating,
    })
}

const checkRsIlliNumberFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_RSHEET_REVISION

const createRsheetRevisionStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        rsheetRevision: null
    })
}

const createRsheetRevisionSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully created revision',
        rsheetRevision: action.response ? action.response.rsheetRevision : null
    })
}

const createRsheetRevisionFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_RS_HISTORIES

const getRsHistoriesStart = (state, action) => {
    return updateObject(state, {
        error_history: null,
        success_history: null,
        loading_history: true,
        history_list: []
    })
}

const getRsHistoriesSuccess = (state, action) => {
    return updateObject(state, {
        success_history: 'Success',
        loading_history: false,
        history_list: action.response
    })
}

const getRsHistoriesFail = (state, action) => {
    return updateObject(state, {
        loading_history: false,
        error_history: action.err
    })
}

// CREATE_RS_HISTORY

const createRsHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createRsHistorySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const createRsHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_RS_HISTORY

const updateRsHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateRsHistorySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const updateRsHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_RS_HISTORY

const deleteRsHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteRsHistorySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const deleteRsHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_RS_INTERNAL_REVIEWERS

const getRsInternalReviewersStart = (state, action) => {
    return updateObject(state, {
        error_reviewers: null,
        success_reviewers: null,
        internal_reviewers_loading: true,
        internal_reviewers_choices: []
    })
}

const getRsInternalReviewersSuccess = (state, action) => {
    return updateObject(state, {
        success_reviewers: 'Success',
        internal_reviewers_choices: action.response,
        internal_reviewers_loading: false
    })
}

const getRsInternalReviewersFail = (state, action) => {
    return updateObject(state, {
        error_reviewers: action.err,
        internal_reviewers_loading: false
    })
}

// CREATE_RS_INTERNAL_REVIEWER

const createRsInternalReviewerStart = (state, action) => {
    return updateObject(state, {
        error_reviewers: null,
        success_reviewers: null,
        internal_reviewers_loading: true,
        internal_reviewers_choices: []
    })
}

const createRsInternalReviewerSuccess = (state, action) => {
    return updateObject(state, {
        success_reviewers: 'Success',
        internal_reviewers_choices: action.response,
        internal_reviewers_loading: false
    })
}

const createRsInternalReviewerFail = (state, action) => {
    return updateObject(state, {
        error_reviewers: action.err,
        internal_reviewers_loading: false
    })
}

// UPDATE_RS_INTERNAL_REVIEWER

const updateRsInternalReviewerStart = (state, action) => {
    return updateObject(state, {
        error_reviewers: null,
        success_reviewers: null,
        internal_reviewers_loading: true,
        internal_reviewers_choices: []
    })
}

const updateRsInternalReviewerSuccess = (state, action) => {
    return updateObject(state, {
        success_reviewers: 'Success',
        internal_reviewers_choices: action.response,
        internal_reviewers_loading: false
    })
}

const updateRsInternalReviewerFail = (state, action) => {
    return updateObject(state, {
        error_reviewers: action.err,
        internal_reviewers_loading: false
    })
}

// DELETE_RS_INTERNAL_REVIEWER

const deleteRsInternalReviewerStart = (state, action) => {
    return updateObject(state, {
        error_reviewers: null,
        success_reviewers: null,
        internal_reviewers_loading: true,
        internal_reviewers_choices: []
    })
}

const deleteRsInternalReviewerSuccess = (state, action) => {
    return updateObject(state, {
        success_reviewers: 'Success',
        internal_reviewers_choices: action.response,
        internal_reviewers_loading: false
    })
}

const deleteRsInternalReviewerFail = (state, action) => {
    return updateObject(state, {
        error_reviewers: action.err,
        internal_reviewers_loading: false
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_RSHEETS_START:
            return getRsheetsStart(state, action);
        case actionTypes.GET_RSHEETS_SUCCESS:
            return getRsheetsSuccess(state, action);
        case actionTypes.GET_RSHEETS_FAIL:
            return getRsheetsFail(state, action);

        case actionTypes.GET_RSHEET_START:
            return getRsheetStart(state, action);
        case actionTypes.GET_RSHEET_SUCCESS:
            return getRsheetSuccess(state, action);
        case actionTypes.GET_RSHEET_FAIL:
            return getRsheetFail(state, action);

        case actionTypes.CREATE_RSHEET_START:
            return createRsheetStart(state, action);
        case actionTypes.CREATE_RSHEET_SUCCESS:
            return createRsheetSuccess(state, action);
        case actionTypes.CREATE_RSHEET_FAIL:
            return createRsheetFail(state, action);

        case actionTypes.UPDATE_RSHEET_START:
            return updateRsheetStart(state, action);
        case actionTypes.UPDATE_RSHEET_SUCCESS:
            return updateRsheetSuccess(state, action);
        case actionTypes.UPDATE_RSHEET_FAIL:
            return updateRsheetFail(state, action);

        case actionTypes.DELETE_RSHEET_START:
            return deleteRsheetStart(state, action);
        case actionTypes.DELETE_RSHEET_SUCCESS:
            return deleteRsheetSuccess(state, action);
        case actionTypes.DELETE_RSHEET_FAIL:
            return deleteRsheetFail(state, action);

        case actionTypes.CREATE_RSHEET_PDF_START:
            return createRsheetPdfStart(state, action);
        case actionTypes.CREATE_RSHEET_PDF_SUCCESS:
            return createRsheetPdfSuccess(state, action);
        case actionTypes.CREATE_RSHEET_PDF_FAIL:
            return createRsheetPdfFail(state, action);

        case actionTypes.CREATE_RSHEET_FILES_START:
            return createRsheetFilesStart(state, action);
        case actionTypes.CREATE_RSHEET_FILES_SUCCESS:
            return createRsheetFilesSuccess(state, action);
        case actionTypes.CREATE_RSHEET_FILES_FAIL:
            return createRsheetFilesFail(state, action);

        case actionTypes.CLEAR_RSHEETS:
            return clearRSheets(state, action);

        case actionTypes.GET_RSHEET_FILES_START:
            return getRsheetFilesStart(state, action);
        case actionTypes.GET_RSHEET_FILES_SUCCESS:
            return getRsheetFilesSuccess(state, action);
        case actionTypes.GET_RSHEET_FILES_FAIL:
            return getRsheetFilesFail(state, action);

        case actionTypes.CLEAR_RSHEETS_MESSAGES:
            return clearRSheetsMessages(state, action);

        case actionTypes.CLEAR_RSHEET_DATA:
            return clearRsheetData(state, action)

        case actionTypes.GET_RS_TYPES_START:
            return getRsTypesStart(state, action);
        case actionTypes.GET_RS_TYPES_SUCCESS:
            return getRsTypesSuccess(state, action);
        case actionTypes.GET_RS_TYPES_FAIL:
            return getRsTypesFail(state, action);

        case actionTypes.GET_NEXT_RS_ILLI_NUMBER_START:
            return getNextRsIlliNumberStart(state, action);
        case actionTypes.GET_NEXT_RS_ILLI_NUMBER_SUCCESS:
            return getNextRsIlliNumberSuccess(state, action);
        case actionTypes.GET_NEXT_RS_ILLI_NUMBER_FAIL:
            return getNextRsIlliNumberFail(state, action);

        case actionTypes.CHECK_RS_ILLI_NUMBER_START:
            return checkRsIlliNumberStart(state, action);
        case actionTypes.CHECK_RS_ILLI_NUMBER_SUCCESS:
            return checkRsIlliNumberSuccess(state, action);
        case actionTypes.CHECK_RS_ILLI_NUMBER_FAIL:
            return checkRsIlliNumberFail(state, action);

        case actionTypes.CREATE_RSHEET_REVISION_START:
            return createRsheetRevisionStart(state, action);
        case actionTypes.CREATE_RSHEET_REVISION_SUCCESS:
            return createRsheetRevisionSuccess(state, action);
        case actionTypes.CREATE_RSHEET_REVISION_FAIL:
            return createRsheetRevisionFail(state, action);

        case actionTypes.GET_RS_HISTORIES_START:
            return getRsHistoriesStart(state, action);
        case actionTypes.GET_RS_HISTORIES_SUCCESS:
            return getRsHistoriesSuccess(state, action);
        case actionTypes.GET_RS_HISTORIES_FAIL:
            return getRsHistoriesFail(state, action);

        case actionTypes.CREATE_RS_HISTORY_START:
            return createRsHistoryStart(state, action);
        case actionTypes.CREATE_RS_HISTORY_SUCCESS:
            return createRsHistorySuccess(state, action);
        case actionTypes.CREATE_RS_HISTORY_FAIL:
            return createRsHistoryFail(state, action);

        case actionTypes.UPDATE_RS_HISTORY_START:
            return updateRsHistoryStart(state, action);
        case actionTypes.UPDATE_RS_HISTORY_SUCCESS:
            return updateRsHistorySuccess(state, action);
        case actionTypes.UPDATE_RS_HISTORY_FAIL:
            return updateRsHistoryFail(state, action);

        case actionTypes.DELETE_RS_HISTORY_START:
            return deleteRsHistoryStart(state, action);
        case actionTypes.DELETE_RS_HISTORY_SUCCESS:
            return deleteRsHistorySuccess(state, action);
        case actionTypes.DELETE_RS_HISTORY_FAIL:
            return deleteRsHistoryFail(state, action);

        case actionTypes.GET_RS_INTERNAL_REVIEWERS_START:
            return getRsInternalReviewersStart(state, action);
        case actionTypes.GET_RS_INTERNAL_REVIEWERS_SUCCESS:
            return getRsInternalReviewersSuccess(state, action);
        case actionTypes.GET_RS_INTERNAL_REVIEWERS_FAIL:
            return getRsInternalReviewersFail(state, action);

        case actionTypes.CREATE_RS_INTERNAL_REVIEWER_START:
            return createRsInternalReviewerStart(state, action);
        case actionTypes.CREATE_RS_INTERNAL_REVIEWER_SUCCESS:
            return createRsInternalReviewerSuccess(state, action);
        case actionTypes.CREATE_RS_INTERNAL_REVIEWER_FAIL:
            return createRsInternalReviewerFail(state, action);

        case actionTypes.UPDATE_RS_INTERNAL_REVIEWER_START:
            return updateRsInternalReviewerStart(state, action);
        case actionTypes.UPDATE_RS_INTERNAL_REVIEWER_SUCCESS:
            return updateRsInternalReviewerSuccess(state, action);
        case actionTypes.UPDATE_RS_INTERNAL_REVIEWER_FAIL:
            return updateRsInternalReviewerFail(state, action);

        case actionTypes.DELETE_RS_INTERNAL_REVIEWER_START:
            return deleteRsInternalReviewerStart(state, action);
        case actionTypes.DELETE_RS_INTERNAL_REVIEWER_SUCCESS:
            return deleteRsInternalReviewerSuccess(state, action);
        case actionTypes.DELETE_RS_INTERNAL_REVIEWER_FAIL:
            return deleteRsInternalReviewerFail(state, action);

        default:
            return state;
    }
};

export default reducer;