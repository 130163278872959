import React, { useEffect, useState } from "react";
import { residentialFields, subFields } from "./RSheetsResidentialModel";
import Button from "components/CustomButtons/Button";
import RSheetsResidentialFormActions from "./RSheetsResidentialFormActions";
import FormElement from "../RSheetsFormElement";
import { Dialog } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { connect } from "react-redux";
import * as actions from "store/actions";
import { commatize } from "shared/utility";
import { commissionCalc } from "../CommonCustomElements/CommissionLogic";
import {
    calculateAbatedValues,
    calculateAdjustedCommission,
    calculateEstimatedAbatedRanges,
    calculateEstimatedTermsTotal,
    calculateTermsTotal,
    generateCommissionSplitData,
    getPaymentAmount,
    isEstimatedLease,
    isFlatFeeCommissionEnabled,
    shouldGetPaymentAmount,
    calculateAbatedRanges_RS as calculateAbatedRanges,
    calculateStartingRent,
    getDateDifference,
    getAbatedTotal,
    newYearlyCalculation,
    calculateYearAbatedRanges,
} from "../RSheetsLogic";
import { get, isEmpty, sortBy } from "lodash";
import { formatDollar, parseFloatString, twoDecimals } from "../../../shared/utility";

import Lessees from './CustomElements/Lessees';
import Lessors from './CustomElements/Lessors';

import InvoicePayment from '../CommonCustomElements/InvoicePayment';
import FileLoading from '../CommonCustomElements/FileLoading';
import FileUpload from '../CommonCustomElements/FileUpload';
import Term from '../CommonCustomElements/Term/Term';
import CommissionAdjustmentTable from '../CommonCustomElements/CommissionAdjustmentTable';
import termLogic from '../CommonCustomElements/Term/TermLogicMain';
import { RentTotals, TermElement, AbatedElement } from "../CommonCustomElements/Term/TermLogicMain";
import OtherReferrals from "../CommonCustomElements/OtherReferrals";
import BrokersFullForm from "../CommonCustomElements/BrokersLogic";
import { PresplitsFormFull } from "../CommonCustomElements/PreSplitsForm";
import OutsideBrokersForm, { ReferralsForm } from "../CommonCustomElements/OthersForm";
import { getUser } from "../../../shared/authValidation";
import { CommissionSplitsAssociateFull } from "../CommonCustomElements/CommissionSplits";
import { CommissionDistributionFull } from "../CommonCustomElements/CommissionDistribution";

const renderLabel = (field, data, subType, overrideLabel) => {
    if (overrideLabel) {
        return (
            <div>
                <strong>{overrideLabel}</strong> : {data[field]}
            </div>
        );
    }
    return (
        <div>
            <strong>{subFields[subType][field] ? subFields[subType][field].label : ''}</strong> : {data[field]}
        </div>
    );
};

const RSheetsResidentialFormFull = (props) => {
    const [fieldEdit, setFieldEditValue] = useState(null);
    const [editOtherReferrals, setEditOtherReferrals] = useState(null);
    const [calculatedAbatedRanges, setCalculatedAbatedRanges] = useState(null);
    const { data, updateDataObj, updateDataMultiObj, setData } = props;

    const calcs = termLogic.calculateLeaseTerm(data);
    const invoice_calcs = termLogic.calculateInvoiceRows(calcs, data);
    console.log('RSheetsLeaseFormFull', fieldEdit, props, calcs, invoice_calcs);

    const diffObj = getDateDifference(data);

    // FIXME - DEPRECATED
    const isFlatFee = data.flat_fee_commission === 'Yes';

    const shouldShowRanges = !isFlatFeeCommissionEnabled(data);

    const hideStartingRentPerSqft = get(data, 'flat_fee_space', '') === 'Yes';

    const hasLesseeAssociate = get(data, 'illi_represented_lessee', '') !== 'No'
    const hasLessorAssociate = get(data, 'illi_represented_lessor', '') !== 'No'

    useEffect(() => {
        generateCommissionSplitData(data, setData)
    }, [])

    useEffect(() => {
        const calc = calculateAbatedRanges({
            lease_term: data.lease_term,
            abated: data.abated_rent_ranges,
        });
        setCalculatedAbatedRanges(calc);
    }, [data.lease_term, data.abated_rent_ranges]);

    const setFieldEdit = (field) => {
        if (!props.viewOnly) setFieldEditValue(field);
    };
    const make = (field, showLabel = true) => {
        const prefix =
            residentialFields[field].prefix && residentialFields[field].prefix !== "$" ? (
                <span>{residentialFields[field].prefix}</span>
            ) : (
                ""
            );
        let value = get(data, field, "");

        if (residentialFields[field].type === "date2") {
            const date_is_estimated = !!data[`${field}_estimated`];
            const tbd = residentialFields[`${field}_estimated_tbd`];
            const dataArr = data[`${field}_estimated_tbd`] ? data[`${field}_estimated_tbd`].split(",") : [];
            value =
                data[`${field}_estimated`] && dataArr.indexOf("TBD") !== -1 ? "TBD" : data[field] ? data[field] : "";
            return (
                <span className="hoverable fullform">
                    {showLabel && (
                        <strong>
                            {residentialFields[field].label} : {prefix}
                        </strong>
                    )}
                    <span className="editables">
                        {value} {date_is_estimated && value !== "TBD" ? "(Estimated)" : ""}
                    </span>
                </span>
            );
        }

        return (
            <span className="hoverable fullform">
                {showLabel && (
                    <strong>
                        {residentialFields[field].label} : {prefix}
                    </strong>
                )}
                <span className="editables">{value}</span>
            </span>
        );
    };
    const makeCheckboxes = (field) => {
        return (
            <span>
                {residentialFields[field].options.map((o, i) => {
                    if (!o) return null;
                    if (data[field] === 0) {
                        return (
                            <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                                <input type="checkbox" key={`${o}-checkbox-${field}`} checked={true} readOnly />
                                &nbsp;{o}
                            </span>
                        );
                    }
                    if (data[field]) {
                        if (data[field].indexOf(o) !== -1) {
                            return (
                                <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                                    <input type="checkbox" key={`${o}-checkbox-${field}`} checked={true} readOnly />
                                    &nbsp;{o}
                                </span>
                            );
                        }
                    }
                    return (
                        <span className="grayed ml-10 hoverable fullform" key={`${field}-${o}-${i}`}>
                            <input type="checkbox" key={`${o}-checkbox-${field}`} checked={false} readOnly />
                            &nbsp;{o}
                        </span>
                    );
                })}
            </span>
        );
    };

    const makeArray = (field, key) => {
        if (!data[field]) return null;
        if (key === "multi-dates") {
            return (
                <ul className="list-unstyled">
                    {data[field].map((m, i) => {
                        let all_total = 0;
                        let total_abated = 0;
                        if (field === "lease_term") {
                            if (data.abated_rent_ranges) {
                                if (data.abated_rent_ranges[i]) {
                                    const tot = getAbatedTotal(data.abated_rent_ranges[i]);
                                    total_abated += tot;
                                }
                            }
                        }
                        return (
                            <li key={`${i}-${field}-year`}>
                                <div>
                                    <h4>Year {m.year_num}</h4>
                                    {m.ranges.length === 0 && (
                                        <div>
                                            <em>None</em>
                                        </div>
                                    )}
                                    {m.ranges.map((mr, j) => {
                                        const { value, month1, month2, day1, day2, date1, date2, commissionRate } = mr;

                                        const lease_term = get(data, "lease_term", []);

                                        const abatedRanges = calculateAbatedValues({
                                            lease_term,
                                            abated_range: mr,
                                        });
                    
                                        const totalAbated = calculateTermsTotal(abatedRanges);

                                        const term = isEstimated ? (
                                            <span>
                                                {month1} {day1} - {month2} - {day2}
                                            </span>
                                        ) : (
                                            <span>
                                                {mr.date1} to {mr.date2}
                                            </span>
                                        );

                                        return (
                                            <div key={`${j}-range-${i}`}>
                                                {term} {formatDollar(totalAbated.totalTermRent, true)}
                                                {field === "lease_term" && (
                                                    <div>
                                                        {commissionRate ? commatize(commissionRate, true) : 0}%
                                                        commission rate
                                                    </div>
                                                )}
                                                <hr />
                                            </div>
                                        );
                                    })}
                                </div>
                            </li>
                        );
                    })}
                </ul>
            );
        } else if (key === "multi-contact") {
            if (field === "lessee_contacts" || field === "lessor_contacts") {
                return (
                    <ul className="list-unstyled">
                        {data[field].map((m, i) => {
                            const a = m;
                            let contactPhone = renderLabel("main_phone", a, field, 'Main Phone');
                            if (a.phone_type === 'Mobile') {
                                contactPhone = renderLabel("mobile_phone", a, field, 'Mobile Phone');
                            } else if (a.phone_type === 'Work') {
                                contactPhone = renderLabel("work_phone", a, field, 'Work Phone');
                            }
                            return (
                                <li key={`${i}-${field}`}>
                                    <div className="bordered-thin">
                                        {field === "lessee_contacts" && (
                                            <div>
                                                {renderLabel("name", a, "lessee")}
                                                {renderLabel("last_name", a, "lessee")}
                                                {renderLabel("dba_name", a, "lessee")}
                                                {renderLabel("address1", a, "lessee")}
                                                {renderLabel("address2", a, "lessee")}
                                                {renderLabel("city", a, "lessee")}
                                                {renderLabel("state", a, "lessee")}
                                                {renderLabel("zip", a, "lessee")}
                                                {renderLabel("email", a, "lessee")}
                                                {contactPhone}
                                            </div>
                                        )}
                                        {field === "lessor_contacts" && (
                                            <div>
                                                {renderLabel("name", a, "lessor")}
                                                {renderLabel("last_name", a, "lessor")}
                                                {renderLabel("dba_name", a, "lessor")}
                                                {renderLabel("address1", a, "lessor")}
                                                {renderLabel("address2", a, "lessor")}
                                                {renderLabel("city", a, "lessor")}
                                                {renderLabel("state", a, "lessor")}
                                                {renderLabel("zip", a, "lessor")}
                                                {renderLabel("email", a, "lessor")}
                                                {contactPhone}
                                            </div>
                                        )}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                );
            } else {
                return (
                    <ul className="list-unstyled">
                        {data[field].map((m, i) => {
                            return (
                                <li key={`${i}-${field}`}>
                                    <div className="bordered-thin">
                                        Name: {m.name} {m.last_name}
                                        <br />
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                );
            }
        } else if (key === "name") {
            let html = ``;
            if (data[field]) {
                html = data[field]
                    .map((m) => {
                        const dba = m?.dba_name ? ` DBA ${m.dba_name}` : '';
                        return m.name + " " + m.last_name + dba;
                    })
                    .join(", ");
            }
            return html;
        } else {
            let html = ``;
            if (data[field]) {
                html = data[field].map((m) => m[key]).join(", ");
            }
            return html;
        }
    };

    const renderElement = (field) => {
        return (
            <FormElement
                field={field}
                data={data}
                updateDataObj={updateDataObj}
                updateDataMultiObj={updateDataMultiObj}
                sheetType="Residential"
                setData={setData}
                components={{
                    FileLoading: FileLoading,
                    FileUpload: FileUpload,
                    InvoicePayment: InvoicePayment,
                    Lessees: Lessees,
                    Lessors: Lessors,
                    Term: Term,
                }}
                calcs={calcs}
                canEditInvoiceRelatedData={props.canEditInvoiceRelatedData}
            />
        );
    };

    const renderLessors = () => {
        const model = subFields.lessor;
        const sort = [
            "company_name",
            "name",
            "last_name",
            "dba_name",
            "phone",
            "email",
            "address1",
            "address2",
            "state",
            "city",
            "zip",
        ];
        return (
            <div>
                {data.lessors.map((dl, i) => {
                    const sortedKey = sortBy(Object.keys(dl), (item) => {
                        const index = sort.indexOf(item);
                        return index === -1 ? Infinity : index;
                    });
                    return (
                        <div key={`${i}-lessor`}>
                            <h4>Lessor {i + 1}</h4>
                            {sortedKey.map((field) => {
                                // HIDE UNSELECTED
                                if (dl.phone_type) {
                                    if (dl.phone_type === 'Work') {
                                        if (field === 'mobile_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Mobile') {
                                        if (field === 'work_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Main') {
                                        if (field === 'work_phone' || field === 'mobile_phone') return null;
                                    }
                                } else {
                                    if (field === 'work_phone' || field === 'mobile_phone') return null;
                                }
                                
                                if (field === "id" || !model[field]) return null;
                                if (dl.company_name) {
                                    if (field === "name" || field === "last_name") return null;
                                } else {
                                    if (field === "company_name") return null;
                                }
                                return (
                                    <div key={`${field}-lfield`}>
                                        <strong>{model[field].label}</strong> :{" "}
                                        <span>{dl[field] ? dl[field] : "n/a"}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderLessees = () => {
        const model = subFields.lessee;
        const sort = [
            "company_name",
            "name",
            "last_name",
            "dba_name",
            "phone",
            "email",
            "address1",
            "address2",
            "state",
            "city",
            "zip",
        ];
        return (
            <div>
                {data.lessees.map((dl, i) => {
                    const sortedKey = sortBy(Object.keys(dl), (item) => {
                        const index = sort.indexOf(item);
                        return index === -1 ? Infinity : index;
                    });
                    return (
                        <div key={`${i}-lessee`}>
                            <h4>Lessee {i + 1}</h4>
                            {sortedKey.map((field) => {
                                // HIDE UNSELECTED
                                if (dl.phone_type) {
                                    if (dl.phone_type === 'Work') {
                                        if (field === 'mobile_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Mobile') {
                                        if (field === 'work_phone' || field === 'main_phone') return null;
                                    } else if (dl.phone_type === 'Main') {
                                        if (field === 'work_phone' || field === 'mobile_phone') return null;
                                    }
                                } else {
                                    if (field === 'work_phone' || field === 'mobile_phone') return null;
                                }

                                if (field === "id" || !model[field]) return null;
                                if (dl.company_name) {
                                    if (field === "name" || field === "last_name") return null;
                                } else {
                                    if (field === "company_name") return null;
                                }
                                return (
                                    <div key={`${field}-lfield`}>
                                        <strong>{model[field].label}</strong> :{" "}
                                        <span>{dl[field] ? dl[field] : "n/a"}</span>
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}
            </div>
        );
    };
    
    const isEstimated = isEstimatedLease(data);
    console.log('Form Full', props)

    const all_commission_calculations = commissionCalc.calculate(data);
    console.log('all_commission_calculations', all_commission_calculations);

    return <div style={{padding:40,maxWidth:1000,margin:'0 auto'}}>
        <RSheetsResidentialFormActions 
            data={data}
            id={null}
            viewOnly={props.viewOnly}
            setOpenedForm={props.setOpenedForm}
            setFormDataObj={props.setFormDataObj}
            setActivityTracker={props.setActivityTracker}
            closeTheForm={props.closeTheForm}
            level={props.level}
            tab={props.tab}
            save={props.save}
            updateDataObj={props.updateDataObj}
        />
        <div>
            <table style={{ width: '100%' }}>
                <tbody>
                    <tr>
                        <td className="col-10"><img src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png" style={{ width: 60 }} /></td>
                        <td className="col-90">
                            <div className="text-center"><h3>ROUTING SHEET - RESIDENTIAL</h3></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-20" onClick={() => { setFieldEdit('date') }}>{make('date')}</td>
                        <td className="col-60" onClick={() => { setFieldEdit('project_name') }}>{make('project_name')}</td>
                        <td className="col-20" onClick={() => { setFieldEdit('illi_number') }}>{make('illi_number')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-80 no-bt" onClick={() => { setFieldEdit('project_address') }}>{make('project_address')}</td>
                        <td className="col-20 no-bt" onClick={() => { setFieldEdit('pm_number') }}>{make('pm_number')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-80 no-bt bold" onClick={() => { setFieldEdit('la_city_verification') }}>
                            Is Property Located in the County of L.A ?</td>
                        <td className="col-20 no-bt" onClick={() => { setFieldEdit('la_city_verification') }}>
                            {makeCheckboxes('la_city_verification')}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-30 no-bt" onClick={() => { setFieldEdit('listings_procured_by') }}>
                            <strong>Listings Procured By : </strong>
                            {makeArray('listings_procured_by', 'name')}
                        </td>
                        <td className="col-30 no-bt" onClick={() => { setFieldEdit('lead_source') }}>{make('lead_source')}</td>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('deal_generated_by') }}>
                            <strong>Deal Generated By : </strong>
                            {makeCheckboxes("deal_generated_by")}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('illi_represented_lessor') }}>
                            <strong>illi Represented Lessor :</strong>
                            {makeCheckboxes('illi_represented_lessor')}
                        </td>
                        <td className="col-60 no-bt" onClick={() => { setFieldEdit('illi_represented_lessor_agents') }}><strong>Agent(s)</strong> : {makeArray('illi_represented_lessor_agents', 'name')}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('illi_represented_lessee') }}>
                            <strong>illi Represented Lessee : </strong>
                            {makeCheckboxes('illi_represented_lessee')}
                        </td>
                        <td className="col-60 no-bt" onClick={() => { setFieldEdit('illi_represented_lessee_agents') }}>
                            <strong>Agent(s)</strong> : {makeArray('illi_represented_lessee_agents', 'name')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <br />
        <br />
        <div>
            <h4 style={{ textDecoration: 'underline' }}>PROPERTY INFORMATION</h4>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-60" onClick={() => { setFieldEdit('premises_address') }}>
                            {make('premises_address')}
                        </td>
                        <td className="col-40" onClick={() => { setFieldEdit('unit_number') }}>
                            {make('unit_number')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('city') }}>
                            {make('city')}
                        </td>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('zip') }}>
                            {make('zip')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-60 no-bt" onClick={() => { setFieldEdit('county') }}>
                            {make('county')}
                        </td>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('loopnet_marketing_updated') }}>
                            <strong>MLS Updated? : </strong>
                            {makeCheckboxes('loopnet_marketing_updated')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />
        <br />
        <div>
            <h4 style={{ textDecoration: 'underline' }}>LEASE INFORMATION</h4>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50" onClick={() => { setFieldEdit('lease_start_date') }}>
                            {make('lease_start_date')}
                        </td>
                        <td className="col-50" onClick={() => { setFieldEdit('date_lease_signed') }}>
                            {make('date_lease_signed')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('lease_end_date') }}>
                            {make('lease_end_date')}
                        </td>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('occupancy_date') }}>
                            {make('occupancy_date')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        {!hideStartingRentPerSqft && <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt" onClick={() => { }}>
                        </td>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('rent_commencement') }}>
                            {make('rent_commencement')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}
        <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("contingency");
                                }}
                            >
                                <div>
                                    <strong>Contingency</strong> {makeCheckboxes("contingency")}
                                </div>
                            </td>
                            {data.contingency === "Yes" ? (
                                <td
                                    className="col-50 no-bt"
                                    onClick={() => {
                                        setFieldEdit("contingency_detail");
                                    }}
                                >
                                    {make("contingency_detail")}
                                </td>
                            ) : (
                                <td className="col-50 no-bt">
                                    <strong>Contingency Detail:</strong>
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
        	<div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <td
                                className="col-50 no-bt"
                                onClick={() => {
                                    setFieldEdit("flat_fee_commission");
                                }}
                            >
                                <div>
                                    <strong>Flat Fee Commission</strong> {makeCheckboxes("flat_fee_commission")}
                                </div>
                            </td>
                            {data.flat_fee_commission === "Yes" ? (
                                <td
                                    className="col-50 no-bt"
                                    onClick={() => {
                                        setFieldEdit("flat_fee_commission_amount");
                                    }}
                                >
                                    {make("flat_fee_commission_amount")}
                                </td>
                            ) : (
                                <td className="col-50 no-bt">
                                    <strong>Flat Fee Commission Amount:</strong> N/A
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </div>
			<div className='the-table'>
				<table>
					<tbody>
                        {data.flat_fee_commission !== 'Yes' && <tr>
							<td
								className='col-50 no-bt'
								onClick={() => {
									setFieldEdit('rate_type');
								}}
							>
								<div>
									<strong>Rental Increases</strong> {makeCheckboxes('rate_type')}
								</div>
							</td>
							{data.rate_type === 'Fixed Percentage' ? (
								<td
									className='col-50 no-bt'
									onClick={() => {
										setFieldEdit('rate_type_cpi_percentage');
									}}
								>
									{make('rate_type_cpi_percentage')}
								</td>
							) : (
								<td className='col-50 no-bt'>
									<strong>Percentage Rate:</strong> N/A
								</td>
							)}
						</tr>}
					</tbody>
				</table>
			</div>
		{shouldShowRanges && (data.abated_rent_ranges || data.lease_term) && <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt align-top-left">
                            <strong>Lease Term : </strong>
                            {!isEstimated && diffObj ? (
                                <div>
                                    {diffObj.years} Years, {diffObj.months} Months, {diffObj.days} Days
                                </div>
                            ) : (
                                ""
                            )}
                            {isEstimated && get(data, "lease_length", "")
                                ? `${get(data, "lease_length", "")} Months`
                                : ""}
                            <div>
                                <TermElement 
                                    calcs={calcs}
                                />
                            </div>
                        </td>
                        <td className="col-50 no-bt align-top-left">
                            <strong>Abated Rent : </strong> {data.abated_rent_ranges.length > 0 ? 'Yes' : 'No'}
                            <div>
                                <AbatedElement 
                                    calcs={calcs}
                                />
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}
        {shouldShowRanges && (data.abated_rent_ranges || data.lease_term) && <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-100 no-bt align-top-left" onClick={() => { }}>
                            <div>
                                <div><RentTotals calcs={calcs} /></div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>}

        {!shouldShowRanges && <CommissionAdjustmentTable data={data} />}

        <br />
        <br />
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSORS</h4>
                        </td>
                        <td className="col-5 no-bt no-bb no-br"></td>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSEES</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        {/* {Lessee and Lessor} */}
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45" style={{ verticalAlign: 'top' }} onClick={() => { setFieldEdit('lessors') }}>
                            {renderLessors()}
                        </td>
                        <td className="col-5 no-bt no-bb"></td>
                        <td className="col-45" style={{ verticalAlign: 'top' }} onClick={() => { setFieldEdit('lessees') }}>
                            {renderLessees()}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />

        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSOR CONTACT(S)</h4>
                        </td>
                        <td className="col-5 no-bt no-bb no-br"></td>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSEE CONTACT(S)</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        {data.lessor_contacts && <td className="col-45" onClick={() => { setFieldEdit('lessor_contacts') }}>
                            {makeArray('lessor_contacts', 'multi-contact')}
                            {data.lessor_contacts.length === 0 && <div className="text-center"><em>N/A</em></div>}
                        </td>}
                        <td className="col-5 no-bt no-bb"></td>
                        {data.lessee_contacts && <td className="col-45" onClick={() => { setFieldEdit('lessee_contacts') }}>
                            {makeArray('lessee_contacts', 'multi-contact')}
                            {data.lessee_contacts.length === 0 && <div className="text-center"><em>N/A</em></div>}
                        </td>}
                    </tr>
                </tbody>
            </table>
        </div>
        <br />

        <div className="the-table">
            <table width="100%">
                <tbody>
                    <tr>
                        <td className="col-45 no-bt no-br no-bl no-bb vtop"
                            onClick={() => {
                                props.setStep(5);
                            }}
                        >
                            <BrokersFullForm 
                                data={data}
                                title="LESSOR"
                                field="lessor_outside_broker"
                            />
                        </td>
                        <td className="col-5 no-bt no-bb no-br"></td>
                        <td className="col-45 no-bt no-br no-bl no-bb vtop"
                            onClick={() => {
                                props.setStep(4);
                            }}
                        >
                            <BrokersFullForm 
                                data={data}
                                title="LESSEE"
                                field="outside_broker"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

       
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSOR REFERRAL</h4>
                        </td>
                        <td className="col-5 no-bt no-bb no-br"></td>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>LESSEE REFERRAL</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        {['name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].map((ff, i) => {
            let r = `referral_firm_${ff}`;
            let o = `lessee_referral_firm_${ff}`;
            const r_phone_type = data.referral_firm_phone_type;
            const o_phone_type = data.lessee_referral_firm_phone_type;
            const add = i === 0 ? `` : `no-bt`;
            if (ff === 'main_phone') {
                if (r_phone_type === 'Work') {
                    r = `referral_firm_work_phone`;
                } else if (r_phone_type === 'Mobile') {
                    r = `referral_firm_mobile_phone`;
                }
                if (o_phone_type === 'Work') {
                    o = `lessee_referral_firm_work_phone`;
                } else if (o_phone_type === 'Mobile') {
                    o = `lessee_referral_firm_mobile_phone`;
                }
                return <div className="the-table" key={`${ff}-rto`}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(r) }}>
                                    {make(r)}
                                </td>
                                <td className="col-5 no-bt no-bb"></td>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(o) }}>
                                    {make(o)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            if (ff === 'w9') {
                return <div className="the-table" key={`${ff}-rto`}>
                    <table>
                        <tbody>
                            <tr>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(r) }}>
                                    <strong>W9 : </strong>
                                    {makeCheckboxes(r)}
                                </td>
                                <td className="col-5 no-bt no-bb"></td>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(o) }}>
                                    <strong>W9 : </strong>
                                    {makeCheckboxes(o)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            }
            return <div className="the-table" key={`${ff}-rto`}>
                <table>
                    <tbody>
                        <tr>
                            {ff === 'contact_dba_name' ? <>
                                <td className={`col-45 ${add}`}>
                                    <span className="hoverable fullform">
                                        <strong>Payee DBA : </strong>
                                        <span className="editables">{data[r]}</span>
                                    </span>
                                </td>
                                <td className="col-5 no-bt no-bb"></td>
                                <td className={`col-45 ${add}`}>
                                <span className="hoverable fullform">
                                    <strong>Payee DBA : </strong>
                                    <span className="editables">{data[o]}</span>
                                </span>
                                </td>
                            </> : 
                            <>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(r) }}>
                                    {make(r)}
                                </td>
                                <td className="col-5 no-bt no-bb"></td>
                                <td className={`col-45 ${add}`} onClick={() => { setFieldEdit(o) }}>
                                    {make(o)}
                                </td>
                            </>
                    }
                        </tr>
                    </tbody>
                </table>
            </div>
        })}
        <br />
            
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>OTHER LESSOR REFERRAL(S)</h4>
                        </td>
                        <td className="col-5 no-bt no-bb no-br"></td>
                        <td className="col-45 no-bt no-br no-bl no-bb">
                            <h4 style={{ textDecoration: 'underline' }}>OTHER LESSEE REFERRAL(S)</h4>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-45" onClick={() => { data.referral_firm_id && setEditOtherReferrals('lessor') }}>
                            {data?.lessor_referrals && data?.lessor_referrals.map((ref, i) => {
                                return <div>
                                    <h4>Referral #{i + 2}</h4>
                                    {['company_name', 'name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'fax', 'email', 'taxid', 'w9'].map((fld) => {
                                        const field = `referral_firm_${fld}`;
                                        return <div>
                                            <strong>{residentialFields[field].label}</strong> : {ref[fld]}
                                        </div>
                                    })}
                                </div>
                            })}
                            {(!data?.lessor_referrals || data?.lessor_referrals.length === 0) && <div className="text-center"><em>N/A</em></div>}
                        </td>
                        <td className="col-5 no-bt no-bb"></td>
                            <td className="col-45" onClick={() => { data.lessee_referral_firm_id && setEditOtherReferrals('lessee') }}>
                            {data?.lessee_referrals && data?.lessee_referrals.map((ref, i) => {
                                return <div>
                                    <h4>Referral #{i + 2}</h4>
                                    {['company_name', 'name', 'lastname', 'contact_dba_name', 'address1', 'city', 'state', 'zip', 'contact', 'main_phone', 'work_phone', 'mobile_phone', 'fax', 'email', 'taxid', 'w9'].filter(lf => {
                                        const phone_type = ref.phone_type;
                                        if (phone_type) {
                                            if (phone_type === 'Work') {
                                                if (lf === 'mobile_phone' || lf === 'main_phone') return false;
                                            } else if (phone_type === 'Mobile') {
                                                if (lf === 'work_phone' || lf === 'main_phone') return false;
                                            } else if (phone_type === 'Main') {
                                                if (lf === 'work_phone' || lf === 'mobile_phone') return false;
                                            }
                                        } else {
                                            if (lf === 'work_phone' || lf === 'mobile_phone') return false;
                                        }
                                        return true;
                                    }).map((fld) => {
                                        const field = `lessee_referral_firm_${fld}`;
                                        return <div>
                                            <strong>{residentialFields[field].label}</strong> : {ref[fld]}
                                        </div>
                                    })}
                                </div>
                            })}
                            {(!data?.lessee_referrals || data?.lessee_referrals.length === 0) && <div className="text-center"><em>N/A</em></div>}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <br />

        <div>
            <h4 style={{ textDecoration: "underline" }}>COMMISSION DISTRIBUTION PRE-SPLIT</h4>
        </div>
        <PresplitsFormFull 
            data={data}
        />
        <br />
        <br />
        <CommissionDistributionFull 
            fieldType="lessor"
            name="LESSOR"
            hasLessorAssociate={hasLessorAssociate}
            data={data}
        />
        <div className="mt-10">
            <OutsideBrokersForm 
                tableData={all_commission_calculations.lessor_outside_brokers}
            />
        </div>
        <div className="mt-10">
            <ReferralsForm 
                tableData={all_commission_calculations.lessor_referrals}
            />
        </div>
        <br />

        <br />
        <CommissionDistributionFull 
            fieldType="lessee"
            name="LESSEE"
            hasLesseeAssociate={hasLesseeAssociate}
            data={data}
        />
        <div className="mt-10">
            <OutsideBrokersForm 
                tableData={all_commission_calculations.lessee_outside_brokers}
            />
        </div>
        <div className="mt-10">
            <ReferralsForm 
                tableData={all_commission_calculations.lessee_referrals}
            />
        </div>
        <br />
        <br />

        <CommissionSplitsAssociateFull 
            all_commission_calculations={all_commission_calculations}
        />

        <br />
        <br />


        <div>
            <h4 style={{textDecoration:'underline'}}>BILLING INFORMATION</h4>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-30" onClick={() => { setFieldEdit('billing_attention') }}>
                            {make('billing_attention')}
                        </td>
                        <td className="col-30" onClick={() => { setFieldEdit('billing_attention_lastname') }}>
                            {make('billing_attention_lastname')}
                        </td>
                        <td className="col-40">
                            <span className="hoverable fullform">
                                <strong>Attention DBA : </strong>
                                <span className="editables">{data.billing_attention_dba_name}</span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('billing_company') }}>
                            {make('billing_company')}
                        </td>
                        <td className="col-50 no-bt">
                            <span className="hoverable fullform">
                                <strong>Billing Company DBA : </strong>
                                <span className="editables">{data.billing_dba_name}</span>
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('billing_address1') }}>
                            {make('billing_address1')}
                        </td>
                        <td className="col-50 no-bt" onClick={() => { setFieldEdit('billing_address2') }}>
                            {make('billing_address2')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('billing_city') }}>
                            {make('billing_city')}
                        </td>
                        <td className="col-40 no-bt" onClick={() => { setFieldEdit('billing_state') }}>
                            {make('billing_state')}
                        </td>
                        <td className="col-20 no-bt" onClick={() => { setFieldEdit('billing_zip') }}>
                            {make('billing_zip')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-20 no-bt" onClick={() => { setFieldEdit('billing_phone') }}>
                            {make('billing_phone')}
                        </td>
                        <td className="col-20 no-bt" onClick={() => { setFieldEdit('billing_fax') }}>
                            {make('billing_fax')}
                        </td>
                        <td className="col-60 no-bt" onClick={() => { setFieldEdit('billing_email') }}>
                            {make('billing_email')}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <br />
        <br />
        <div>
            <h4 style={{ textDecoration: 'underline' }}>COPY OF INVOICE</h4>
        </div>
        <div className="the-table">
            <table>
                <tbody>
                    <tr>
                        <td className="col-100" onClick={() => { setFieldEdit('copy_of_invoice') }}>
                            <strong>Copy of Invoice : </strong>
                            {makeCheckboxes('copy_of_invoice')}
                        </td>
                    </tr>
                    {data.copy_of_invoice.indexOf('Other') !== -1 && <tr>
                        <td className="col-100" onClick={() => { setFieldEdit('copy_of_invoice_other') }}>
                            <strong>Other : </strong>
                            {make('copy_of_invoice_other')}
                        </td>
                    </tr>}
                </tbody>
            </table>
        </div>

        <br />
        <br />
        {data.payment_schedule && <div onClick={() => { setFieldEdit('payment_schedule') }}>
            <h4 style={{ textDecoration: 'underline' }}>PAYMENT SCHEDULE</h4>
            {data.payment_schedule.length === 0 &&
                <div style={{ marginTop: 20 }} onClick={() => { setFieldEdit('payment_schedule') }}>
                    <div className="the-table">
                        <table>
                            <tbody>
                                <tr>
                                    <td className="col-100">No Payment Schedule Entries</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>}
        </div>}
        <div
                className="the-table"
                onClick={() => {
                    setFieldEdit("payment_schedule");
                }}
            >
                <table>
                    <tbody>
                        {data.payment_schedule.map((iv, iv_index) => {
                            if (!iv) return null;
                            if (!Array.isArray(iv)) return null;
                            return iv.map((ps, i) => {
                                return (
                                    <tr key={`ps-${i}`}>
                                        <td className="col-20">
                                            <strong>Invoice {iv_index + 1}</strong><br />
                                            <strong>Payment {i + 1}</strong>
                                        </td>
                                        <td className="col-80">
                                            <strong>Value:</strong>{" "}
                                            {shouldGetPaymentAmount(ps.option) ? formatDollar(getPaymentAmount(data, ps.option)) : ps.value}
                                            <br />
                                            <strong>Option:</strong> {ps.option} <br />
                                            {ps.misc && (
                                                <div>
                                                    <strong>Other Option:</strong> {ps.misc}
                                                </div>
                                            )}
                                            {ps.notes && <div><strong>Notes:</strong> {ps.notes}</div>}
                                        </td>
                                    </tr>
                                );
                            });
                        })}
                    </tbody>
                </table>
            </div>

        <br />
            <br />
            <div>
                <h4 style={{ textDecoration: "underline" }}>COMMENTS AND SPECIAL INSTRUCTIONS</h4>
                <div
                    style={{ marginTop: 20 }}
                    onClick={() => {
                        setFieldEdit("comments_and_special_instructions");
                    }}
                >
                    <div className="the-table">
                        <table>
                            <tbody>
                                <tr>
                                    {data.comments_and_special_instructions &&
                                    data.comments_and_special_instructions.length === 0 ? (
                                        <td className="col-100">No Comments and Special Instructions</td>
                                    ) : (
                                        <td className="col-100">{data.comments_and_special_instructions}</td>
                                    )}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        {fieldEdit && <Dialog
            open={fieldEdit ? true : false}
            onClose={() => setFieldEdit(null)}
            fullWidth={true}
            maxWidth="md"
        >
            <div style={{ padding: 40, margin: '0 auto', width: '100%', maxWidth: '1400px', minHeight: 500 }}>
                <div className="close-btn" onClick={() => { setFieldEdit(null) }}><Close /></div>
                <h2>{residentialFields[fieldEdit].label}</h2>
                {(fieldEdit === 'lease_term' || fieldEdit === 'abated_rent_ranges') ? 
                    renderElement(fieldEdit,
                    null, 
                    null, 
                    null, 
                    null, 
                    [], 
                    null, 
                    null,
                    calcs)
                :
                    renderElement(fieldEdit)}
                <hr />
                <Button color="primary" onClick={() => { setFieldEdit(null) }}>OK</Button>
                <Button color="white" onClick={() => { setFieldEdit(null) }}>CLOSE</Button>
            </div>
        </Dialog>}

        {editOtherReferrals && <Dialog
            open={editOtherReferrals ? true : false}
            onClose={() => setEditOtherReferrals(null)}
            fullWidth={true}
            maxWidth="md"
        >
            <div style={{ padding: 40, margin: '0 auto', width: '100%', maxWidth: '1400px', minHeight: 500 }}>
                <div className="close-btn" onClick={() => { setEditOtherReferrals(null) }}><Close /></div>
                <OtherReferrals data={data} setData={setData} title={`OTHER ${editOtherReferrals.toUpperCase()} REFERRAL(S)`} name={`${editOtherReferrals}_referrals`} />
                <Button color="primary" onClick={() => { setEditOtherReferrals(null) }}>OK</Button>
                <Button color="white" onClick={() => { setEditOtherReferrals(null) }}>CLOSE</Button>
            </div>
        </Dialog>}
    </div>
};

const mapStateToProps = (state) => {
    return {
        error: state.rs.error,
        success: state.rs.success,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createRsheet: (data) => {
            dispatch(actions.createRsheet(data));
        },
        getRsheets: (params) => {
            dispatch(actions.getRsheets(params));
        },
        updateRsheet: (params) => {
            dispatch(actions.updateRsheet(params));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RSheetsResidentialFormFull);
