import React from 'react';
import GridContainer from '../../../components/Grid/GridContainer';
import GridItem from '../../../components/Grid/GridItem';
import Button from '../../../components/CustomButtons/Button';
import { clone, difference, findIndex, get } from 'lodash';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import { useState } from 'react';
import { Dialog } from '@material-ui/core';
import { Close } from '@material-ui/icons';

const Step3 = (props) => {
	const {
		addPropertyToContact,
		setQueryFor,
		getContacts,
		setOpenedContactModal,
		renderElement,
		data,
		setData,
		getPropertyOwnersContacts,
		propertyOwnersContacts,
	} = props;

	const [openedModal, setOpenedModal] = useState(false);
	const [newOwner, setNewOwner] = useState(null);

	const openModal = () => setOpenedModal(true);
	const closeModal = () => setOpenedModal(false);

	const property_id = get(data, 'property_id', null);

	const lessors = get(data, 'lessors', []);

	useEffect(() => {
		const idList = propertyOwnersContacts.map((o) => `c${o.id}`);
		const lessorIds = lessors.map((l) => l.id);

		const diff = difference(lessorIds, idList);

		if (diff.length !== 0) {
			setNewOwner(diff[0]);
			openModal();
		}
	}, [lessors]);

	useEffect(() => {
		if (property_id) {
			getPropertyOwnersContacts(property_id);
		}
	}, [property_id]);

	const handleAddOwner = (owner) => {
		const company_id = `c${owner.id}`;
		const newData = clone(data);

		const lessorList = get(newData, 'lessors', []);
		const isDuplicating =
			lessorList.filter((l) => l.id === company_id).length !== 0;

		if (isDuplicating) return;

		const newLessor = {
			id: company_id,
			name: owner.fist_name,
			last_name: owner.last_name,
			main_phone: owner.main_phone,
			fax: owner.fax,
			email: owner.email,
			agency_disclosure: '',
			can_we_reach_out_for_testimonial: '',
			can_we_reach_out_for_testimonial_comment: '',
		};
		const address = get(owner, 'addresses[0]', {});
		newLessor.address1 = get(address, 'street1', '');
		newLessor.address2 = get(address, 'street2', '');
		newLessor.city = get(address, 'city', '');
		newLessor.state = get(address, 'state', '');
		newLessor.zip = get(address, 'zip', '');
		newData.lessors = newData.lessors.concat([newLessor]);

		setData(newData);
	};

	const handleUpdatePropertyContact = () => {
		addPropertyToContact(
			{
				contact_type: 'OWN',
				contact_id: newOwner,
				property_id: property_id,
				from_property: true,
			},
			'property'
		);
		closeModal();
	};

	const renderOwners = () => {
		return propertyOwnersContacts.map((owner) => {
			const name = `${get(owner, 'first_name', '')} ${get(
				owner,
				'last_name',
				''
			)}`;

			return (
				<GridContainer>
					<GridItem xs={6} sm={6} md={6}>
						{name}
					</GridItem>
					<GridItem xs={6} sm={6} md={6}>
						<Button color='primary' onClick={() => handleAddOwner(owner)}>
							Add
						</Button>
					</GridItem>
				</GridContainer>
			);
		});
	};

	return (
		<GridContainer>
			{/* Lessor */}
			{!property_id && (
				<GridItem xs={12} sm={12} md={12}>
					<div className='red'>Add Property First</div>
				</GridItem>
			)}

			<GridItem xs={12} sm={12} md={12}>
				<Button
					color='primary'
					onClick={() => {
						setQueryFor('lessors');
						getContacts({
							names_only: 'All',
						});
						setOpenedContactModal(true);
					}}
					disabled={!property_id}
				>
					ADD LESSOR
				</Button>
			</GridItem>

			<GridItem xs={12} sm={12} md={12}>
				<div className='boxed'>{renderOwners()}</div>
			</GridItem>

			{renderElement('lessors')}

			{openedModal && (
				<Dialog
					open={openedModal}
					onClose={closeModal}
					fullWidth={true}
					maxWidth='md'
				>
					<div style={{ padding: 40 }}>
						<div className='close-btn' onClick={closeModal}>
							<Close />
						</div>
						<h4>
							Do you want to update the ownership on that property with this new
							owner
						</h4>

						<div className='text-center'>
							<Button color='primary' onClick={handleUpdatePropertyContact}>
								UPDATE
							</Button>
							<Button color='white' onClick={closeModal}>
								CLOSE
							</Button>
						</div>
					</div>
				</Dialog>
			)}
		</GridContainer>
	);
};

const mapStateToProps = (state) => {
	return {
		propertyOwnersContacts: state.crm_properties.propertyOwnersContacts,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		addPropertyToContact: (data, data_type) => {
			dispatch(actions.addPropertyToContact(data, data_type));
		},
		getPropertyOwnersContacts: (id) => {
			dispatch(actions.getPropertyOwnersContacts(id));
		},
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Step3);
