
import { put } from 'redux-saga/effects';
import axios from '../axios/axios-pma';
import * as actions from '../actions/pma';

export function* getPmaEntriesSaga(action) {
  yield put(actions.getPmaEntriesStart());
  
  if (action.data) {
    if (action.data.status) {
      if (action.data.exhibit) {
        try {
          const response = yield axios.get(`/entries/${action.data.status}?exhibit=true`);
          yield put(actions.getPmaEntriesSuccess(response.data));
        } catch (err) {
          yield put(actions.getPmaEntriesFail(err));
        }
      }
      else {
        try {
          const response = yield axios.get(`/entries/${action.data.status}`);
          yield put(actions.getPmaEntriesSuccess(response.data));
        } catch (err) {
          yield put(actions.getPmaEntriesFail(err));
        }
      }
    } else if (action.data.approver) {
      // get approver entries, status = 3, 6
      try {
        const response = yield axios.get(`/entries/?type=approver`);
        
        yield put(actions.getPmaEntriesSuccess(response.data));
      } catch (err) {
        yield put(actions.getPmaEntriesFail(err));
      }
    }
  } else {
    // get own entries by user_id
    try {
      const response = yield axios.get(`/entries/`);
      
      yield put(actions.getPmaEntriesSuccess(response.data));
    } catch (err) {
      yield put(actions.getPmaEntriesFail(err));
    }
    
  }
}

export function* getPmaEntrySaga(action) {
  yield put(actions.getPmaEntryStart());
  try {
      const response = yield axios.get('/entry?id='+action.data);
      yield put(actions.getPmaEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaEntryFail(e.response.data));
          }
        }
      yield put(actions.getPmaEntryFail(e));
  }
}

export function* createPmaEntrySaga(action) {
  yield put(actions.createPmaEntryStart());
  try {
    const data = action.data.data;
    let validEmail = true;

    if (!data.owner.email || !data.president_email) {
      validEmail = false;
    }

    if (data.other_owners) {
      data.other_owners.forEach(oo => {
        if (!oo.email) {
          validEmail = false;
        }
      })
    }

    if (!validEmail) {
      return yield put(actions.createPmaEntryFail('Invalid Email.'))
    }
    
    const response = yield axios.post('/entry', action.data);
    console.log('response -> entry', response.data);
    let pmaData = null;
    if (response) {
      if (response.data) {
        pmaData = response.data.pmaData;
      }
    }
    let successsMsg = 'PMA created. ';
    if (action.data) {
      if (action.data.status === 1) successsMsg = 'PMA draft created. ';
      if (action.data.status === 2) {
        successsMsg += `Submitted PMA. `;
      }
    }
    yield put(actions.createPmaEntrySuccess(successsMsg, pmaData));
  } catch (e) {
    if (e.response) {
      if (e.response.data) {
        return yield put(actions.createPmaEntryFail(e.response.data));
      }
    }
    yield put(actions.createPmaEntryFail(e));
  }
}

export function* updatePmaEntrySaga(action) {
  yield put(actions.updatePmaEntryStart());
  try {
    let successMsg = '';
    if (action.data) {
      if (action.data.status === 2) {
        successMsg += `Submitted PMA. `;
      }
    }
    const response = yield axios.put('/entry', action.data);
    if (response.data) {
      if (response.data.msg === 'OK') {
        successMsg += `Successfully updated pma entry. `;
      }
    }

    if (successMsg && action.data) {
      if (action.data.resend) {
        return yield put(actions.updatePmaEntrySuccess(successMsg, 'Successfully resent PMA entry.', response.data.pmaData));
      }
    }
    if (!successMsg) return yield put(actions.updatePmaEntryFail('Something went wrong updating the pma entry'));
    return yield put(actions.updatePmaEntrySuccess(successMsg, '', response.data.pmaData));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updatePmaEntryFail(e.response.data));
          }
        }
      yield put(actions.updatePmaEntryFail(e));
  }
}

export function* deletePmaEntrySaga(action) {
  yield put(actions.deletePmaEntryStart());
  try {
      const response = yield axios.delete('/entry?id='+action.data);
      yield put(actions.deletePmaEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deletePmaEntryFail(e.response.data));
          }
        }
      yield put(actions.deletePmaEntryFail(e));
  }
}

export function* getPmaPdfSaga(action) {
  yield put(actions.getPmaPdfStart());
  try {
      const response = yield axios.post(`/entry/pdf`, action.data);
      
      yield put(actions.getPmaPdfSuccess(response));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaPdfFail(e.response.data));
          }
        }
      yield put(actions.getPmaPdfFail(e));
  }
}

export function* getPmaHistorySaga(action) {
    yield put(actions.getPmaHistoryStart());
    try {
        const response = yield axios.get('/history?type='+action.data);
        yield put(actions.getPmaHistorySuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getPmaHistoryFail(e.response.data));
            }
          }
        yield put(actions.getPmaHistoryFail(e));
    }
}

export function* getPmaApproversSaga(action) {
  yield put(actions.getPmaApproversStart());
  try {
      const response = yield axios.get('/approvers');
      yield put(actions.getPmaApproversSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaApproversFail(e.response.data));
          }
        }
      yield put(actions.getPmaApproversFail(e));
  }
}

export function* getPmaSigningUrlSaga(action) {
  yield put(actions.getPmaSigningUrlStart());
  try {
      const response = yield axios.get('/signing_url?id='+action.data);
      yield put(actions.getPmaSigningUrlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaSigningUrlFail(e.response.data));
          }
        }
      yield put(actions.getPmaSigningUrlFail(e));
  }
}

export function* getPmaHistoryPdfSaga(action) {
  yield put(actions.getPmaHistoryPdfStart());
  try {
      const response = yield axios.post('/history_pdf', action.data);
      yield put(actions.getPmaHistoryPdfSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaHistoryPdfFail(e.response.data));
          }
        }
      yield put(actions.getPmaHistoryPdfFail(e));
  }
}

export function* getPmaTemplatesSaga(action) {
  yield put(actions.getPmaTemplatesStart());
  try {
    let url = `/templates`;
      if (action.data) {
        if (action.data.type) {
          url += `?type=${action.data.type}`;
        }
      }
      const response = yield axios.get(url);
      yield put(actions.getPmaTemplatesSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaTemplatesFail(e.response.data));
          }
        }
      yield put(actions.getPmaTemplatesFail(e));
  }
}

export function* getPmaTemplateSaga(action) {
  yield put(actions.getPmaTemplateStart());
  try {
      const response = yield axios.get('/template?id='+action.data);
      yield put(actions.getPmaTemplateSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaTemplateFail(e.response.data));
          }
        }
      yield put(actions.getPmaTemplateFail(e));
  }
}

export function* createPmaTemplateSaga(action) {
  yield put(actions.createPmaTemplateStart());
  try {
      const response = yield axios.post('/template', action.data);
      yield put(actions.createPmaTemplateSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.createPmaTemplateFail(e.response.data));
          }
        }
      yield put(actions.createPmaTemplateFail(e));
  }
}

export function* updatePmaTemplateSaga(action) {
  yield put(actions.updatePmaTemplateStart());
  try {
      
      const response = yield axios.put('/template', action.data);
      yield put(actions.updatePmaTemplateSuccess(response.data, action.no_message));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updatePmaTemplateFail(e.response.data));
          }
        }
      yield put(actions.updatePmaTemplateFail(e));
  }
}

export function* deletePmaTemplateSaga(action) {
  yield put(actions.deletePmaTemplateStart());
  try {
      const response = yield axios.delete('/template?id='+action.data);
      yield put(actions.deletePmaTemplateSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deletePmaTemplateFail(e.response.data));
          }
        }
      yield put(actions.deletePmaTemplateFail(e));
  }
}

export function* resendPmaApproverNotificationSaga(action) {
  yield put(actions.resendPmaApproverNotificationStart());
  try {
      const response = yield axios.get('/pma_approver_notification?id='+action.data);
      yield put(actions.resendPmaApproverNotificationSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.resendPmaApproverNotificationFail(e.response.data));
          }
        }
      yield put(actions.resendPmaApproverNotificationFail(e));
  }
}

export function* getPmaFileSaga(action) {
  yield put(actions.getPmaFileStart());
  try {
      const response = yield axios.post('/entry/file', action.data);
      yield put(actions.getPmaFileSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getPmaFileFail(e.response.data));
          }
        }
      yield put(actions.getPmaFileFail(e));
  }
}

export function* voidPmaEntrySaga(action) {
  yield put(actions.voidPmaEntryStart());
  try {
      const response = yield axios.put('/entry/void?id=' + action.data);
      yield put(actions.voidPmaEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.voidPmaEntryFail(e.response.data));
          }
        }
      yield put(actions.voidPmaEntryFail(e));
  }
}