import React from "react";
import CommissionSplits from "../CommonCustomElements/CommissionSplits";

const Step6 = (props) => {
    const { data, updateDataObj, setData } = props;
    return (
        <div>
            <CommissionSplits
                data={data}
                updateDataObj={updateDataObj}
                setData={setData}
                getContacts={props.getContacts}
                getContact={props.getContact}
                contactData={props.contactData}
                contactsData={props.contactsData}
                lessorLabel={props.lessorLabel}
                lesseeLabel={props.lesseeLabel}
            />
        </div>
    );
};

export default Step6;
