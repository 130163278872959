import React from "react";
import { Checkbox } from "@material-ui/core";

const CustomCheckbox = props => {
    return <div style={{filter:'grayscale(1)'}}>
        <Checkbox
            checked={props.checked}
            onChange={() => {
                props.onChange();
            }}
        /> {props.label}
    </div>
}

export default CustomCheckbox;