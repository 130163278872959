import React, { useState, useEffect } from "react";
import Button from "../../../components/CustomButtons/Button";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import { DialogContent, Dialog, DialogTitle } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Datetime from "react-datetime";
import {
	getAppointmentClear,
	createAppointment,
	updateAppointment,
} from "../../../store/actions/crm_appointments";
import { clone, updateTheObj } from "shared/utility";
import CustomDialog from "components/CustomDialog/CustomDialogWithContact";
import Delete from "@material-ui/icons/Delete";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";

const ContactsCreateAppointmentDialog = (props) => {
	const {
		indx,
		desc,
		open,
		close,
		title,
		options,
		label,
		passedData,
		// crm_appointments: {appointmentData, loading},
		appointmentData,
		getAppointmentClear,
		editMode,
		createAppointment,
		contactName,
		contactId,
		setUpdateState,
		setCreateState,
		updateAppointment,
		getContact,
		emailContacts
	} = props;
	const [editingAppt, setEditingAppt] = useState({
		summary: "",
		description: "",
		start: !editMode ? '' : "",
		end: !editMode ? '' : "",
		invitees: []
	});
	const [formErrors, setFormErrors] = useState({
		error1: false,
		error2: false,
		error3: false,
		error4: false,
	});
	const [openedSearchModal, setOpenedSearchModal] = useState(false);

	const { summary, description, start, end } = editingAppt;
	const { error1, error2, error3, error4 } = formErrors;
	const dataInvalid =
		summary === "" || description === "" || start === "" || end === "";

	

	useEffect(() => {
		if (appointmentData) {
			
			if (appointmentData.attendees) {
				if (appointmentData.attendees.length > 0) {
					
					getContact(null, appointmentData.attendees.map(a => a.email).join(','));
				}
			}
			const descArr = appointmentData.description
				.split("---")
				.filter((item) => item !== "");
			const formattedDescription = descArr[descArr.length - 3].trim();
			setEditingAppt({
				id: contactId,
				name: contactName,
				summary: appointmentData.summary,
				description: formattedDescription,
				start: new Date(appointmentData.start.dateTime).toLocaleString(
					"en-US",
					{
						dateStyle: "short",
						timeStyle: "short",
						timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
					}
				),
				end: new Date(appointmentData.end.dateTime).toLocaleString("en-US", {
					dateStyle: "short",
					timeStyle: "short",
					timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
				}),
				eventId: appointmentData.id,
				invitees: appointmentData.attendees ? appointmentData.attendees : [], // emails only
			});
		} else {
			setEditingAppt(updateTheObj(editingAppt, {
				id: contactId,
				name: contactName,
				invitees: []
			}))
		}
	}, [appointmentData]);

	const onSubmit = () => {
		if (dataInvalid) {
			setFormErrors({
				error1: start === "",
				error2: false,
				error3: summary === "",
				error4: description === "",
			});
		} else {
			
			if (editMode) {
				updateAppointment(editingAppt);
				setUpdateState(true);
				close();
				getAppointmentClear();
			} else {
				createAppointment(editingAppt);
				setCreateState(true);
				close();
				getAppointmentClear();
			}
		}
	};

	const optionsVar = editingAppt
		? options
				.filter((o) => {
					if (editingAppt) {
						if (desc) {
							const l = o[desc].toLowerCase();
							const c = editingAppt.toLowerCase();
							if (l.indexOf(c) === 0) {
								
								return true;
							}
						} else {
							const l = o.toLowerCase();
							const c = editingAppt.toLowerCase();
							if (l.indexOf(c) === 0) {
								
								return true;
							}
						}
					}
					return false;
				})
				.sort()
		: options;

	console.log('appointments', start, end);

	return (
		<div>
			<Dialog
				open={open}
				onClose={close}
				maxWidth="md"
				fullWidth={true}
				onEscapeKeyDown={() => getAppointmentClear()}
			>
				<DialogTitle>
					<div style={{ textAlign: "center", fontSize: "1.8rem" }}>{title}</div>
				</DialogTitle>
				<DialogContent style={{ height: 600 }}>
					<div style={{ textAlign: "center", padding: 20 }}>
						<div style={{ display: "flex", padding: ".75rem 0" }}>
							<div style={{ flex: 1, marginLeft: ".45", textAlign: "left" }}>
								<label>Start</label>
								<Datetime
									inputProps={{ readOnly: true }}
									value={start}
									defaultValue={editMode ? new Date(start) : new Date()}
									locale="en-US"
									onChange={(e) => {
										setEditingAppt(updateTheObj(editingAppt, {
											start: e
										}));
										setFormErrors(updateTheObj(formErrors, {
											error1: false
										}));
									}}
								/>
								{error1 ? (
									<p className="error-message">Start value is required</p>
								) : null}
							</div>
							{/* {<div style={{ flex: 1, marginLeft: ".45rem", textAlign: "left" }}>
								<label>End</label>
								<Datetime
									inputProps={{ readOnly: true }}
									value={end}
									defaultValue={editMode ? new Date(end) : new Date()}
									locale="en-US"
									onChange={(e) => {
										setEditingAppt(updateTheObj(editingAppt, {
											end: e
										}));
										setFormErrors(updateTheObj(formErrors, {
											error2: false
										}));
									}}
								/>
								{error2 ? (
									<p className="error-message">End value is required</p>
								) : null}
							</div>} */}
						</div>
						<CustomInput
							label="Summary"
							value={summary}
							name="summary"
							onChange={(e) => {
								setEditingAppt(updateTheObj(editingAppt, {
									summary: e.target.value
								}));
								setFormErrors(updateTheObj(formErrors, {
									error3: false
								}));
							}}
						/>
						{error3 ? (
							<p
								style={{ textAlign: "left", marginTop: "-1rem" }}
								className="error-message"
							>
								Appointment summary is required
							</p>
						) : null}
						<CustomInput
							error={true}
							label="Description"
							value={description}
							onChange={(e) => {
								setEditingAppt(updateTheObj(editingAppt, {
									description: e.target.value
								}));
								setFormErrors(updateTheObj(formErrors, {
									error4: false
								}));
							}}
							name="description"
						/>
						{error4 ? (
							<p
								style={{ textAlign: "left", marginTop: "-1rem" }}
								className="error-message"
							>
								Appointment description is required
							</p>
						) : null}

						<div>
							<Button
								style={{ marginBottom: 20 }}
								color="primary"
								onClick={() => {
									setOpenedSearchModal(true);
								}}
							>
								<i className="fa fa-plus"></i> Add Invitee
							</Button>
						</div>

						<h4><strong>Invitees :</strong></h4>
						<List component="nav">
							{editingAppt.invitees.length === 0 && <div key={`ii`}><em>0 invitees.</em></div>}
							{editingAppt.invitees.map((o, i) => {
								let name = o.name ? o.name : '';
								if (emailContacts) {
									if (emailContacts.length > 0) {
										for (let x = 0; x < emailContacts.length; x++) {
											const ec = emailContacts[x];
											if (ec.email === o.email) {
												name = (ec.first_name ? ec.first_name : '') + ' ' + (ec.last_name ? ec.last_name : '');
												break;
											}
										}
									}
								}
								return (
									<ListItem button key={o.id}>
										<ListItemIcon>
											<div
												className="hoverable red-text"
												onClick={() => {
													const newEditingAppt = clone(editingAppt);
													newEditingAppt.invitees = newEditingAppt.invitees.filter((inv) =>{
														if (inv.email === o.email) return false;
														return true;
													})
													setEditingAppt(newEditingAppt);
												}}
											>
												<Delete />
											</div>
										</ListItemIcon>
										<ListItemText
											inset
											primary={`${o.email} ${name ? ` - ${name}` : ''}`}
											onClick={() => {
												
											}}
										/>
									</ListItem>
								);
							})}
						</List>

						{openedSearchModal && (
							<CustomDialog
								open={openedSearchModal}
								close={() => {
									setOpenedSearchModal(false);
								}}
								choose={(the_contact_data) => {
									if (the_contact_data.email) {
										const newEditingAppt = clone(editingAppt);
										newEditingAppt.invitees = newEditingAppt.invitees.concat([{
											email: the_contact_data.email,
											id: the_contact_data.id,
											name: `${the_contact_data.first_name} ${the_contact_data.last_name}`
										}]);
										setEditingAppt(newEditingAppt);
										setOpenedSearchModal(false);
									}
								}}
								create_and_choose={(the_contact_data) => {
									if (the_contact_data.email) {
										const newEditingAppt = clone(editingAppt);
										newEditingAppt.invitees = newEditingAppt.invitees.concat([{
											email: the_contact_data.email,
											id: the_contact_data.id,
											name: `${the_contact_data.first_name} ${the_contact_data.last_name}`
										}]);
										setEditingAppt(newEditingAppt);
										setOpenedSearchModal(false);
									}
								}}
								title="Select Invitee"
								label="Search Invitees"
							/>
						)}

						<Button
							color="success"
							style={{ marginRight: 10, marginTop: 20 }}
							onClick={onSubmit}
						>
							{editMode ? "Update" : "Save"}
						</Button>
						<Button
							color="white"
							style={{ marginRight: 10, marginTop: 20 }}
							onClick={() => {
								getAppointmentClear();
								close();
							}}
						>
							Close
						</Button>
					</div>
				</DialogContent>
			</Dialog>
		</div>
	);
};

ContactsCreateAppointmentDialog.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string,
	options: PropTypes.array.isRequired,
	close: PropTypes.func,
	open: PropTypes.bool,
	choose: PropTypes.func,
	indx: PropTypes.string,
	desc: PropTypes.string,
	crm_appointments: PropTypes.object.isRequired,
	getAppointmentClear: PropTypes.func.isRequired,
	createAppointment: PropTypes.func.isRequired,
	updateAppointment: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
	crm_appointments: state.crm_appointments,
});

export default connect(mapStateToProps, {
	getAppointmentClear,
	createAppointment,
	updateAppointment,
})(ContactsCreateAppointmentDialog);
