import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import { Dialog } from "@material-ui/core";
import Button from "components/CustomButtons/Button";
import PMAFormWizard from "../../containers/PMA/FormWizard/PMAFormWizard";
import PMAHistory from "containers/PMA/PMAEntries/PMAHistory";
import PMAList from "containers/PMA/FormWizard/PMAList";
import PMATemplates from "../../containers/PMA/PMATemplates/PMATemplates";
import { Tooltip } from "@material-ui/core";
import { ArrowBack } from "@material-ui/icons";
import { getParameterByName } from "shared/utility";
import { getUser } from "../../shared/authValidation";

class PMAPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'Entries',
      success: '',
      error: '',
      offset: 0,
      rows: 10,
      formAction: 'Add',
      pmaData: null,
      finalApproval: null,
      isWizardOpen: false,
      count: 0,
      prefill_property_data: null,
      preload_property_data: false
    }
  }

  setTab = (tab) => {
    this.setState({ tab });
    this.props.history.push(`/pma?tab=${tab}`);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.propertyData !== this.props.propertyData) {
      const property_id = getParameterByName('property_id', window.location.href);
      if (property_id) {
        const obj = { formAction: 'Add', isWizardOpen: true, prefill_property_data: this.props.propertyData };
        obj.pmaData = null;
        this.setState(obj)
      }
    }
    if (prevProps.success !== this.props.success) {
      if (!prevProps.success) {
        this.setState({ success: this.props.success });
      }
    }
    if (prevProps.error !== this.props.error) {
      if (!prevProps.error) {
        this.setState({ error: this.props.error });
      }
    }

    if (prevState.success !== this.state.success) {
      if (this.state.success === 'Successfully deleted PMA') {
        this.props.getPmaEntries({status:1});
      }
    }

    if (prevProps.entryData !== this.props.entryData) {
      console.log('entryData changed', prevProps.entryData, this.props.entryData);
      if (this.props.entryData) {
        const obj = {};
        if (this.state.preload_property_data) {
          obj.preload_property_data = false;
          window.history.replaceState(null, null, '/pma');
        }
        if (Array.isArray(this.props.entryData)) {
          if (this.props.entryData.length > 0) {
            this.setState({ pmaData: this.props.entryData[0] });
          }
        } else {
          this.setState({ pmaData: this.props.entryData });
        }
      }
    }
  }

  clearMessages = () => {
    this.setState({ error: null, success: null });
  }

  openFormFillWizard = (formAction, preload_property_data) => {
    const obj = { formAction, isWizardOpen: true };
    if (preload_property_data) {
      obj.preload_property_data = true;
    }
    if (formAction === 'Add') obj.pmaData = null;
    this.setState(obj)
  }

  closeFormFillWizard = () => {
    this.setState({isWizardOpen: false})
  }

  componentDidMount() {
    this.props.getPmaEntries({status:1});
    this.props.getPmaApprovers();
    this.props.getPmaTemplates({type:'all'});
    const action = getParameterByName('p', window.location.href);
    if (action === 'new') {
        const permitted = checkPermissions("PMA");
        if (permitted) {
          console.log('')
            const property_id = getParameterByName('property_id', window.location.href);
            if (property_id) {
              this.props.getProperty(property_id);
            }
        }
    } else if (action === 'open') {
      const permitted = checkPermissions("PMA");
        if (permitted) {
          // /pma?p=open&pma_id=<PMA ID>
            const pma_id = getParameterByName('pma_id', window.location.href);
            if (pma_id) {
              this.props.getPmaEntry(pma_id);
              this.openFormFillWizard('Edit', true);
            }
        }
    }
    const finalApproval = getParameterByName('final_approval', window.location.href);
    if (finalApproval) {
      this.setTab('Pending Final Approval');
      this.props.getPmaEntries({status:3});
      this.props.getPmaTemplates({type:'all'});
      this.setState({ finalApproval });
    }
  }

  getTabReload = (tab) => {
    console.log('reloading', tab, new Date().getTime());
    if (tab === 'Pending') {
      return this.getPendingOwnerSignature();
    } else if (tab === 'Pending Final Approval') {
      return this.props.getPmaEntries({approver:true});
    } else if (tab === 'Pending Exhibit A') {
      return this.props.getPmaEntries({status: 6, exhibit: true});
    }
    return this.props.getPmaEntries({status:1});
  }

  getPendingOwnerSignature = () => {
    this.props.getPmaEntries({status:2});
    this.props.getPmaTemplates({type:'all'});
  }

  render() {
    console.log('PMAPage', this.props);
    const loggedInUser = getUser();
    const pmaPermitted = checkPermissions('PMA');
    if (!pmaPermitted) {
      return <Redirect to="/dashboard" />;
    }

    const permissions = checkPermissionsActions('PMA');
    
    const { tab, isWizardOpen } = this.state;

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                {<CardIcon
                  color={tab === 'Entries' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Entries');
                    this.props.getPmaEntries({status:1});
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Entries</strong>
                </CardIcon>}
                {permissions.hasOwnProperty('edit_form_template') && <CardIcon
                  color={tab === 'Templates' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Templates');
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Templates</strong>
                </CardIcon>}
                {<CardIcon 
                  color={tab === 'Pending' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Pending');
                    this.getPendingOwnerSignature();
                  }}
                  className="hovered"
                >
                  <strong>Pending Owner(s) Signature</strong>
                </CardIcon>}
                {<CardIcon 
                  color={tab === 'Pending Final Approval' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Pending Final Approval');
                    this.props.getPmaEntries({status:3});
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Pending Final Approval</strong>
                </CardIcon>}
                <CardIcon 
                  color={tab === 'Pending Exhibit A' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Pending Exhibit A');
                    this.props.getPmaEntries({status: 6, exhibit: true});
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Pending Exhibit A</strong>
                </CardIcon>
                <CardIcon 
                  color={tab === 'Completed PMAs' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Completed PMAs');
                    this.props.getPmaEntries({status: 6});
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Completed PMAs</strong>
                </CardIcon>
                <CardIcon
                  color={tab === 'History' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('History');
                  }}
                  className="hovered"
                >
                  <strong>History</strong>
                </CardIcon>
                <CardIcon
                  color={tab === 'Voided' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setTab('Voided');
                    this.props.getPmaEntries({status:4});
                    this.props.getPmaTemplates({type:'all'});
                  }}
                  className="hovered"
                >
                  <strong>Voided</strong>
                </CardIcon>
              </CardHeader>
              <CardBody>
                {isWizardOpen && 
              <Dialog
                  open={isWizardOpen}
                  onClose={this.closeFormFillWizard}
                  fullWidth={true}
                  maxWidth={false}
                  fullScreen={true}
              >
                <div className="dialog-wrapper">
                <div
                  style={{ marginRight: 20, marginBottom: 10 }}
                  className="inline-block blue-text hoverable"
                  onClick={() => {
                    this.getTabReload(tab);
                    this.closeFormFillWizard();
                  }}
                >
                  <Tooltip
                    id="tooltip-top-start1"
                    title="Back to List"
                    placement="top"
                  >
                    <ArrowBack />
                  </Tooltip>
                </div>
                  <PMAFormWizard
                    getPmaEntry={this.props.getPmaEntry}
                    getPmaEntries={this.props.getPmaEntries}
                    createPmaEntry={this.props.createPmaEntry}
                    entriesData={this.props.entriesData}
                    entryData={this.props.entryData}
                    getPmaPdf={this.props.getPmaPdf}
                    pdfData={this.props.pdfData}
                    updatePmaEntry={this.props.updatePmaEntry}
                    success={this.state.success}
                    error={this.state.error}
                    clearMessages={this.clearMessages}
                    createDocusign={this.props.createDocusign}
                    action={this.state.formAction}
                    setParentTab={this.setTab}
                    parentTab={tab}
                    pmaData={this.state.pmaData}
                    setPmaData={(pmaData) => { this.setState({pmaData}) }}
                    pmaURL={this.props.pmaURL}
                    getPmaSigningUrl={this.props.getPmaSigningUrl}
                    reload={() => { this.getTabReload(tab) }}
                    templates={this.props.templates}
                    file={this.props.file}
                    getPmaFile={this.props.getPmaFile}
                    clearPmaFile={this.props.clearPmaFile}
                    origin={tab}
                    closeFormFillWizard={this.closeFormFillWizard}
                    prefill_property_data={this.state.prefill_property_data}
                    reset_prefill_data={() => {
                      this.setState({
                        prefill_property_data: null
                      })
                    }}
                  />
                </div>
              </Dialog>}
                {(tab === 'Entries' || tab === 'Pending' || tab === 'Pending Final Approval' || tab === 'Pending Exhibit A' || tab === 'Completed PMAs' || tab === 'Voided') &&
                <div>
                    {tab === 'Entries' && <Button color="primary"
                      className="mb-20"
                      onClick={() => {
                        this.openFormFillWizard('Add');
                      }}
                    >ADD NEW PMA ENTRY</Button>}
                    <PMAList 
                      loggedInUser={loggedInUser}
                      pmaEntries={this.props.entriesData}
                      setPmaData={(pmaData) => {
                        //this.setState({pmaData})
                        this.props.getPmaEntry(pmaData.id);
                      }}
                      setTab={this.setTab}
                      tab={tab}
                      deletePmaEntry={(id) => {
                        this.props.deletePmaEntry(id);
                      }}
                      openFormFillWizard={this.openFormFillWizard}
                      pmaURL={this.props.pmaURL}
                      getPmaSigningUrl={this.props.getPmaSigningUrl}
                      updatePmaEntry={this.props.updatePmaEntry}
                      getPmaEntry={this.props.getPmaEntry}
                      reload={() => { this.getTabReload(tab) }}
                      loading={this.props.loading}
                      success={this.state.success}
                      error={this.state.error}
                      clearMessages={this.clearMessages}
                      finalApproval={this.state.finalApproval}
                      resendPmaApproverNotification={this.props.resendPmaApproverNotification}
                      resendPmaApproverNotificationMessage={this.props.resendPmaApproverNotificationMessage}
                      getPmaFile={this.props.getPmaFile}
                      voidPmaEntry={this.props.voidPmaEntry}
                    />
                </div>}
                {tab === 'History' && 
                    <PMAHistory 
                        entriesData={this.props.entriesData}
                        getPmaEntry={this.props.getPmaEntry}
                        getPmaHistoryPdf={this.props.getPmaHistoryPdf}
                        updatePmaEntry={this.props.updatePmaEntry}
                        historyPDF={this.props.historyPDF}
                        permissions={this.props.permissions}
                        loading={this.props.loading}
                    />}
                {tab === 'Templates' && 
                    <PMATemplates 
                        error={this.props.error}
                        success={this.props.success}
                        templates={this.props.templates}
                        template={this.props.template}
                        getPmaTemplates={this.props.getPmaTemplates}
                        getPmaTemplate={this.props.getPmaTemplate}
                        createPmaTemplate={this.props.createPmaTemplate}
                        updatePmaTemplate={this.props.updatePmaTemplate}
                        deletePmaTemplate={this.props.deletePmaTemplate}
                        clearMessages={this.props.clearPmaMessages}
                    />}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    error: state.pma.error,
    success: state.pma.success,
    loading: state.pma.loading,
    entriesData: state.pma.entriesData,
    entryData: state.pma.entryData,
    pdfData: state.pma.pdfData,
    pmaURL: state.pma.signing_url,
    historyPDF: state.pma.historyPDF,
    templates: state.pma.templates,
    template: state.pma.template,
    resendPmaApproverNotificationMessage: state.pma.resendPmaApproverNotificationMessage,
    file: state.pma.file,
    propertyData: state.crm_properties.propertyData
  }
}

const mapDispatchToProps = dispatch => {
  return {
    clearPmaMessages: () => {
      dispatch(actions.clearPmaMessages());
    },
    getPmaApprovers: () => {
      dispatch(actions.getPmaApprovers());
    },
    getPmaEntries: (data) => {
      dispatch(actions.getPmaEntries(data));
    },
    getPmaEntry: (data) => {
      dispatch(actions.getPmaEntry(data));
    },
    createPmaEntry: (data) => { dispatch(actions.createPmaEntry(data)); },
    getPmaPdf: async (data) => { dispatch(actions.getPmaPdf(data)); },
    updatePmaEntry: (data) => {
      dispatch(actions.updatePmaEntry(data));
    },
    deletePmaEntry: (id) => {
      dispatch(actions.deletePmaEntry(id));
    },
    createDocusign: (data) => { dispatch(actions.createDocusign(data)); },
    getPmaSigningUrl: (id) => {
      dispatch(actions.getPmaSigningUrl(id));
    },
    getPmaHistoryPdf: (data) => {
      dispatch(actions.getPmaHistoryPdf(data));
    },
    getPmaTemplates: (data) => {
      dispatch(actions.getPmaTemplates(data));
    },
    getPmaTemplate: (data) => {
      dispatch(actions.getPmaTemplate(data));
    },
    createPmaTemplate: (data) => {
      dispatch(actions.createPmaTemplate(data));
    },
    deletePmaTemplate: (data) => {
      dispatch(actions.deletePmaTemplate(data));
    },
    updatePmaTemplate: (data, no_message) => {
      dispatch(actions.updatePmaTemplate(data, no_message));
    },
    resendPmaApproverNotification: (id) => {
      dispatch(actions.resendPmaApproverNotification(id));
    },
    getPmaFile: (id) => {
      dispatch(actions.getPmaFile(id));
    },
    clearPmaFile: () => {
      dispatch(actions.getPmaFileStart());
    },
    voidPmaEntry: (id) => {
      dispatch(actions.voidPmaEntry(id))
    },
    getProperty: (data) => {
      dispatch(actions.getProperty(data));
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PMAPage);
