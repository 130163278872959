import { put } from 'redux-saga/effects';
import axios from '../axios/axios-ertl';
import * as actions from '../actions/ertl_entries';
import { clone } from 'shared/utility';

export function* getEntriesSaga(action) {
  yield put(actions.getEntriesStart());
  try {
    const response = yield axios.get('/entries?type='+action.data);
    
    yield put(actions.getEntriesSuccess(response.data));
  } catch (err) {
    yield put(actions.getEntriesFail(err));
  }
}

export function* getEntriesByStatusSaga(action) {
  yield put(actions.getEntriesByStatusStart());
  try {
    let status = action.data.status;
    if (Array.isArray(status)) {
      status = status.join(',');
    }
    let query = `/entries/status?status=${status}`;
    if (action.own) {
      query += `&own=true`;
    }
    const response = yield axios.get(query);
    
    yield put(actions.getEntriesByStatusSuccess(response.data));
  } catch (err) {
    yield put(actions.getEntriesByStatusFail(err));
  }
}

export function* createEntrySaga(action) {
  yield put(actions.createEntryStart());
  // add contact
  try {
    const newData = clone(action.data);
    const response = yield axios.post('/entry', newData);
    if (response) {
      if (response.data) {
        
        return yield put(actions.createEntrySuccess(response.data));
      }
    }
  } catch (err) {
    if (err.response) {
      if (err.response.data) {
        
        return yield put(actions.createEntryFail(err.response.data));
      }
    }
    return yield put(actions.createEntryFail('Failed to add entry'));
  }
}

export function* updateEntryStatusSaga(action) {
  
  yield put(actions.updateEntryStatusStart());
  try {
    const obj = {
      status: action.data.status,
      data: action.data.data ? JSON.stringify(action.data.data) : null,
      template_id: action.data.template_id ? action.data.template_id : null,
      html: action.data.html ? action.data.html : null,
      tabs: action.data.tabs ? action.data.tabs : null,
    };
    if (action.data.envelope_id) obj.envelope_id = action.data.envelope_id;
    if (action.data.submitter_id) obj.submitter_id = action.data.submitter_id;
    
    const response = yield axios.put(`/entry/${action.data.id}`, obj);
    yield put(actions.updateEntryStatusSuccess(response.data));
  } catch (err) {
    yield put(actions.updateEntryStatusFail(err));
  }
}

export function* getEntryPdfSaga(action) {
  
  yield put(actions.getEntryPdfStart());
  try {
      const response = yield axios.post(`/entry/pdf/${action.data.id}`, {
        htmlTemplate: action.data.entry
      });
      yield put(actions.getEntryPdfSuccess(response));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getEntryPdfFail(e.response.data));
          }
        }
      yield put(actions.getEntryPdfFail(e));
  }
}

export function* deleteErtlEntrySaga(action) {
  yield put(actions.deleteErtlEntryStart());
  try {
      const response = yield axios.delete('/entry?id='+action.data);
      yield put(actions.deleteErtlEntrySuccess('Successfully deleted Entry'));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.deleteErtlEntryFail(e.response.data));
          }
        }
      yield put(actions.deleteErtlEntryFail(e));
  }
}

export function* getErtlHistorySaga(action) {
  yield put(actions.getErtlHistoryStart());
  try {
      let url = '/history?type='+action.data;
      if (action.data === 'id') {
        url += `&id=${action.entry_id}`;
      }
      const response = yield axios.get(url);
      yield put(actions.getErtlHistorySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlHistoryFail(e.response.data));
          }
        }
      yield put(actions.getErtlHistoryFail(e));
  }
}

export function* getErtlPdfSaga(action) {
  yield put(actions.getErtlPdfStart());
  try {
    const url = '/history_pdf?id='+action.data;
    console.log('url', url);
      // const response = yield axios.get(url, {
      //   responseType: 'arraybuffer',
      //   headers: {
      //       Accept: 'application/pdf',
      //   },
      // });
      const response = yield axios.get(url);
      console.log('got history data', response.data);
      yield put(actions.getErtlPdfSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlPdfFail(e.response.data));
          }
        }
      yield put(actions.getErtlPdfFail(e));
  }
}

export function* getErtlHistoryEntrySaga(action) {
  yield put(actions.getErtlHistoryEntryStart());
  try {
      const response = yield axios.get('/history_entry?id='+action.data);
      yield put(actions.getErtlHistoryEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlHistoryEntryFail(e.response.data));
          }
        }
      yield put(actions.getErtlHistoryEntryFail(e));
  }
}

export function* getErtlUrlSaga(action) {
  yield put(actions.getErtlUrlStart());
  try {
    const url = '/signing_url?id='+action.data;
    console.log('signing url', url);
      const response = yield axios.get(url);
      yield put(actions.getErtlUrlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlUrlFail(e.response.data));
          }
        }
      yield put(actions.getErtlUrlFail(e));
  }
}

export function* getClientErtlSaga(action) {
  yield put(actions.getClientErtlStart());
  try {
      const {token} = action.data;

      const response = yield axios.get(`/client_ertl?token=${token}`);
      yield put(actions.getClientErtlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getClientErtlFail(e.response.data));
          }
        }
      yield put(actions.getClientErtlFail(e));
  }
}

export function* updateClientErtlEntrySaga(action) {
  yield put(actions.updateClientErtlEntryStart());
  try {
      const response = yield axios.put('/client_ertl', action.data);
      yield put(actions.updateClientErtlEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateClientErtlEntryFail(e.response.data));
          }
        }
      yield put(actions.updateClientErtlEntryFail(e));
  }
}

export function* sendErtlReviewLinkSaga(action) {
  yield put(actions.sendErtlReviewLinkStart());
  try {
      const response = yield axios.put('/client_ertl/send_url?id=' + action.data);
      yield put(actions.sendErtlReviewLinkSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.sendErtlReviewLinkFail(e.response.data));
          }
        }
      yield put(actions.sendErtlReviewLinkFail(e));
  }
}


export function* getErtlEntrySaga(action) {
  yield put(actions.getErtlEntryStart());
  try {
      const response = yield axios.get('/entry?id='+action.data);
      yield put(actions.getErtlEntrySuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlEntryFail(e.response.data));
          }
        }
      yield put(actions.getErtlEntryFail(e));
  }
}

export function* getErtlApproversSaga(action) {
  yield put(actions.getErtlApproversStart());
  try {
      const response = yield axios.get('/approvers');
      yield put(actions.getErtlApproversSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.getErtlApproversFail(e.response.data));
          }
        }
      yield put(actions.getErtlApproversFail(e));
  }
}

export function* updateErtlSaga(action) {
  yield put(actions.updateErtlStart());
  try {
      const data = action.data;
      const response = yield axios.put('/entry', data);
      if (data) {
        if (data.resendReviewLink) {
          const response_resend_link = yield axios.put('/client_ertl/send_url?id=' + data.id);
          console.log('response_resend_link', response_resend_link.data);
        }
      }
      yield put(actions.updateErtlSuccess(response.data));
  } catch (e) {
      if (e.response) {
          if (e.response.data) {
            return yield put(actions.updateErtlFail(e.response.data));
          }
        }
      yield put(actions.updateErtlFail(e));
  }
}