import React from 'react';
import { Dialog, DialogContent, TextField, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
import Datetime from 'react-datetime';
import CustomSelect from "components/CustomSelect/CustomSelect.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { pmaFields } from '../PMAObj';
import PMAElement from '../FormWizard/PMAElement';

const PMADataModal = (props) => {
    const { field, formData, setFormData, updateDataObj } = props;
    
    let fieldSplit = field.split('.');
    let newField = fieldSplit.length > 1 ? formData[fieldSplit[0]][fieldSplit[1]] : formData[field];
    
    const placeHolder = fieldSplit.length > 1 ? pmaFields.labels[fieldSplit[0]][fieldSplit[1]] : pmaFields.labels[field];
    return (
        <div>
            <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth="md"
                fullWidth={true}
            >
                <div style={{ margin: 20, minHeight:400, padding:40 }}> 
                    <PMAElement 
                        updateDataObj={updateDataObj}
                        data={formData}
                        setData={setFormData}
                        field={field}
                    />
                    <br />
                    <Button color="rose" onClick={() => { 
                        if (props.saveDraft) {
                            props.saveDraft();
                        }
                        setTimeout(() => {
                            props.close() 
                        }, 500);
                    }}>SAVE</Button>
                </div>
            </Dialog>
        </div>
    )
}

export default PMADataModal;