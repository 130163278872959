import React, { useState, useEffect } from "react";
import Button from "components/CustomButtons/Button.jsx";
import BaseTemplate from "../SharedComponents/PMABaseTemplate";
import PMAPdfModal from "../SharedComponents/PMAPdfModal";
import pdfTemplate, {
    allowAdditionalSignature,
    renderExhibitA,
    textData,
    pdfCover
} from "../PMAObj";
import { clone } from "shared/utility";
import CloseComponent from "../../../components/Close/Close";
import { Divider, TextField } from "@material-ui/core";
import GridItem from "../../../components/Grid/GridItem";
import GridContainer from "../../../components/Grid/GridContainer";
import { Dialog } from "@material-ui/core";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { get, includes, isEmpty } from "lodash";
import Notification from "../../../components/Notification/Notification";
import { generateOwnerIDs, generateSections } from './PMALogic';

function containsDuplicates(a) {
    for (let i = 0; i < a.length; i++) {
        if (a.indexOf(a[i]) !== a.lastIndexOf(a[i])) {
            return true;
        }
    }
    return false;
}

const PMAFullForm = (props) => {
	
	const { formData, setFormData, updateDataObj, setOpenLoading, 
        templateData, setTemplateData, templateOriginal, setTemplateOriginal,
        templateChosen, setTemplateChosen
    } = props;

    const [openedPdfModal, setOpenedPdfModal] = useState(false);
    const [localPdfData, setLocalPdfData] = useState(null);
    const [pdfAction, setPdfAction] = useState("view");
    const [openedTextForm, setOpenedTextForm] = useState(false);
    const [openedField, setOpenedField] = useState("");
    const [openedExhibitForm, setOpenedExhibitForm] = useState(false);
    const [openedExhibitEmail, setOpenedExhibitEmail] = useState(null);

    const [alertMsg, setAlertMsg] = useState("");

    const printPdf = (buffer) => {
        const pdfBlob = new Blob([new Uint8Array(buffer)], {
            type: "application/pdf",
        });
        let pdfFrame = document.body.appendChild(
            document.createElement("iframe")
        );
        pdfFrame.style.display = "none";
        pdfFrame.onload = () => void pdfFrame.contentWindow.print();
        pdfFrame.src = window.URL.createObjectURL(pdfBlob);
    };

    const options =
        props.templates.length > 0
            ? props.templates
                  .map((t) => {
                      return {
                          id: t.id,
                          title: t.title,
                          active: t.active,
                          defaults: t.defaults,
                          data: t.data
                      };
                  })
                  .filter((f) => {
                      if (f.active === 0) return false;
                      return true;
                  })
                  .sort(function (a, b) {
                      if (a.title.toLowerCase() < b.title.toLowerCase())
                          return -1;
                      if (a.title.toLowerCase() > b.title.toLowerCase())
                          return 1;
                      return 0;
                  })
            : [
                  {
                      id: -1,
                      title: "Original Base Template",
                  },
              ];

	useEffect(() => {
		if (formData) {
			
			if (formData.templateData) {
				if (!Array.isArray(formData.templateData.additional_signatures)) {
					formData.templateData.additional_signatures = [];
				}
				if (!Array.isArray(formData.templateData.additional_initials)) {
					formData.templateData.additional_initials = [];
				}

                console.log('settingthetemplateData', formData.templateData.id, options);
				setTemplateData(formData.templateData);
	
                let defaultTemplate = null;
                if (formData.templateData.id) {
                    defaultTemplate = options.find((tm) => tm.id === formData.templateData.id);
                } else {
                    defaultTemplate = options.find((tm) => tm.title === formData.templateData.title);
                }
                if (!defaultTemplate) {
                    if (options) {
                        options.forEach((t) => {
                            if (t.defaults === 1) defaultTemplate = t;
                        });
                    }
                }
				if (defaultTemplate) {
                    setTemplateChosen(defaultTemplate.id);
                    setTemplateOriginal(defaultTemplate.data);
                } else {
                    setTemplateOriginal(clone(formData.templateData));
                }
			} else {
				let the_default = null;
				if (props.templates) {
					props.templates.forEach((t) => {
						if (t.defaults === 1) the_default = t;
					});
					
					if (the_default) {
						setTemplateChosen(the_default.id);
						setTemplateData(the_default.data);
						setTemplateOriginal(clone(the_default.data));
					}
				}
			}
		}
	}, []);

    useEffect(() => {
        if (!openedPdfModal) {
            props.clearPmaFile();
        }
    }, [openedPdfModal])

    useEffect(() => {
        if (props.pdfData) {
            if (pdfAction === "view") {
                setLocalPdfData(props.pdfData);
            } else {
                printPdf(props.pdfData.data);
            }
        }
    }, [props.pdfData, props.entriesData]);

    const openPdfModal = () => {
        setOpenedPdfModal(true);
    };

    const updateTemplateObj = (field, value) => {
        const newTemplateData = clone(templateData);
        newTemplateData[field] = value;
        setTemplateData(newTemplateData);
    };

    const updateExhibitData = (email, exhibit_data) => {
        const newFormData = clone(formData);
        if (newFormData.signing_ceremony) {
            for (let i = 0; i < newFormData.signing_ceremony.length; i++) {
                const sc = newFormData.signing_ceremony[i];
                if (sc.email === email) {
                    newFormData.signing_ceremony[i].exhibit_data =
                        clone(exhibit_data);
                }
            }
        }
        setFormData(newFormData);
    };

    const extractExhibitData = (email) => {
        let exhibit_data = {};
        if (formData.signing_ceremony) {
            for (let i = 0; i < formData.signing_ceremony.length; i++) {
                const sc = formData.signing_ceremony[i];
                if (sc.email === email) {
                    exhibit_data = sc.exhibit_data;
                }
            }
        }
        return exhibit_data;
    };

    const setTemplateChosenTo = (templateID) => {
        setTemplateChosen(templateID);
        if (templateID === -1) {
            setTemplateData(textData);
            setTemplateOriginal(clone(textData));
        } else {
            for (let i = 0; i < props.templates.length; i++) {
                const tm = props.templates[i];
                if (tm.id === templateID) {
                    setTemplateData(tm.data);
                    setTemplateOriginal(clone(tm.data));
                }
            }
        }
    };

    return (
        <div>
            <div style={{ padding: 20, backgroundColor: "#fafafa" }}>
                <center>
                    <h3>
                        <strong>FORM OUTPUT PREVIEW</strong>
                    </h3>
                </center>
                {(props.action === "Add" || props.action === "Edit") && (
                    <div>
                        {props.templates ? (
                            <GridContainer>
                                <GridItem xs={12} sm={4} md={4}>
                                    <CustomSelect
                                        options={
                                            props.templates.length > 0
                                                ? props.templates
                                                      .map((t) => {
                                                          return {
                                                              id: t.id,
                                                              title: t.title,
                                                              active: t.active,
                                                              defaults:
                                                                  t.defaults,
                                                          };
                                                      })
                                                      .filter((f) => {
                                                          if (f.active === 0)
                                                              return false;
                                                          return true;
                                                      })
                                                      .sort(function (a, b) {
                                                          if (
                                                              a.title.toLowerCase() <
                                                              b.title.toLowerCase()
                                                          )
                                                              return -1;
                                                          if (
                                                              a.title.toLowerCase() >
                                                              b.title.toLowerCase()
                                                          )
                                                              return 1;
                                                          return 0;
                                                      })
                                                : [
                                                      {
                                                          id: -1,
                                                          title: "Original Base Template",
                                                      },
                                                  ]
                                        }
                                        indx="id"
                                        desc="title"
                                        value={templateChosen}
                                        choose={(e) => {
                                            setTemplateChosenTo(e);
                                        }}
                                        label="Select Template"
                                        default={templateChosen}
                                    />
                                </GridItem>
                            </GridContainer>
                        ) : null}
                    </div>
                )}
                <BaseTemplate
                    close={props.close}
                    saveDraft={props.saveDraft}
                    formData={formData} // form data
                    setFormData={setFormData} // update form data
                    updateDataObj={updateDataObj} // update form data by field
                    templateData={templateData} // template form data
                    setTemplateData={setTemplateData} // update template form data
                    templateText={templateOriginal} // original base template or chosen template
                    updateTemplateObj={updateTemplateObj} // update template data by field
                    openModal={(field) => {
                        setOpenedField(field);
                        setOpenedTextForm(true);
                    }}
                    extractExhibitData={extractExhibitData}
                    updateExhibitData={updateExhibitData}
                    action={props.action}
                    setCurrForm={props.setCurrForm}
                    setTab={props.setTab}
                    compareWithTemplate={true}
                />
                <div className="bottom-dweller">
                    {(props.action === "Add" || props.action === "Edit") && (
                        <Button
                            color="white"
                            className="mr-20"
                            onClick={() => {
                                setOpenLoading(true);
                                props.saveDraft(templateData, templateChosen);
                            }}
                            style={{ marginTop: 10 }}
                        >
                            <i className="fas fa-save"></i> Save PMA Draft
                        </Button>
                    )}
                    {(props.action === "Add" || props.action === "Edit") && (
                        <Button
                            color="success"
                            className="mr-20"
                            onClick={() => {
                                // check if all requirements are completed

                                if (!formData) return;
                                const sections = generateSections(formData);
                                let complete = true;
                                sections.forEach(sec => {
                                    if (sec.name !== 'Additional Notifications') {
                                        if (!sec.complete) complete = false;
                                    }
                                });
                                if (!complete) return setAlertMsg("Please enter all required data");
                                if (!formData.owner) return setAlertMsg("Please enter an owner.");;
                                if (!formData.president) return setAlertMsg("Please select an approver.");;
                                if (!formData.president_email) return setAlertMsg("Please select an approver email.");;
                                if (formData.property) {
                                    if (!formData.property.address) {
                                        return setAlertMsg("Please enter a property address");
                                    }
                                }
                                // check if names have multiple emails 
                                const namesCollection = {
                                    [formData.owner.name]: [formData.owner.email]
                                };
                                if (formData) {
                                    if (formData.other_owners) {
                                        formData.other_owners.forEach((oo) => {
                                            if (namesCollection.hasOwnProperty(oo.name)) {
                                                if (namesCollection[oo.name].indexOf(oo.email) === -1) {
                                                    namesCollection[oo.name].push(oo.email);
                                                }
                                            } else {
                                                namesCollection[oo.name] = [oo.email];
                                            }
                                        });
                                    }
                                    if (
                                        formData.president &&
                                        formData.president_email
                                    ) {
                                        namesCollection[formData.president] = [formData.president_email];
                                    }
                                }
                                let hasDuplicateEmail = false;
                                let missingEmail = false;
                                Object.keys(namesCollection).forEach(name => {
                                    const emails = namesCollection[name];
                                    if (emails.length > 1) {
                                        hasDuplicateEmail = true;
                                    }
                                    emails.forEach(em => {
                                        if (!em) missingEmail = true;
                                    })
                                })

                                if (missingEmail) {
                                    return setAlertMsg(
                                        "Please make sure all owners and president have emails."
                                    );
                                }

                                const newFormData = clone(formData);
                                newFormData.templateData = templateData;
                                const ownerIDs = generateOwnerIDs(formData);
                                const newObj = {
                                    data: newFormData,
                                    status: 2,
                                    coverTemplate: pdfCover(
                                        formData,
                                        templateData
                                    ),
                                    htmlTemplate: pdfTemplate(
                                        formData,
                                        templateData
                                    ),
                                    tabs: {
                                        [formData.owner.email]: {
                                            signHereTabs: [
                                                {
                                                    anchorString:
                                                        "**_owner1_signature_**",
                                                },
                                            ],
                                            dateSignedTabs: [
                                                {
                                                    anchorString:
                                                        "**_owner1_signature_date_**",
                                                },
                                            ],
                                            initialHereTabs: [
                                                {
                                                    anchorString:
                                                        "**_owner1_initial_**",
                                                },
                                            ],
                                        },
                                        [formData.president_email]: {
                                            signHereTabs: [
                                                {
                                                    anchorString:
                                                        "**_approver_signature_**",
                                                },
                                            ],
                                            dateSignedTabs: [
                                                {
                                                    anchorString:
                                                        "**_approver_signature_date_**",
                                                },
                                            ],
                                        },
                                    },
                                };
                                if (formData.other_owners) {
                                    if (formData.other_owners.length > 0) {
                                        formData.other_owners.forEach(
                                            (oo, i) => {
                                                const owner_id = ownerIDs[oo.email];
                                                newObj.tabs[oo.email] = {
                                                    signHereTabs: [
                                                        {
                                                            anchorString: `**_owner${owner_id}_signature_**`,
                                                        },
                                                    ],
                                                    dateSignedTabs: [
                                                        {
                                                            anchorString: `**_owner${owner_id}_signature_date_**`,
                                                        },
                                                    ],
                                                    initialHereTabs: [
                                                        {
                                                            anchorString: `**_owner${owner_id}_initial_**`,
                                                        },
                                                    ],
                                                };
                                            }
                                        );
                                    }
                                }

                                setOpenLoading(true);
                                if (formData.id) {
                                    newObj.id = formData.id;
                                    props.updatePmaEntry(newObj);
                                } else {
                                    props.createPmaEntry(newObj);
                                }
                            }}
                            style={{ marginTop: 10 }}
                        >
                            Submit PMA
                        </Button>
                    )}
                    {props.action === "View and Sign" && (
                        <Button
                            color="success"
                            className="mr-20"
                            onClick={() => {
                                // render docusign view
                            }}
                            style={{ marginTop: 10 }}
                        >
                            Sign PMA
                        </Button>
                    )}
                    {(!openedPdfModal && props.pmaData) && (
                        <span>
                            <Button
                                className="mr-20"
                                color="rose"
                                onClick={() => {
                                    
                                    let template = pdfTemplate(
                                        clone(formData),
                                        templateData
                                    );
                                    props.getPmaPdf({
                                        data: template,
                                        form_data: formData,
                                        cover: pdfCover(clone(formData),templateData)
                                    });
                                    setPdfAction("print");
                                }}
                                style={{ marginTop: 10 }}
                            >
                                Print PDF
                            </Button>
                        </span>
                    )}
                    {(!openedPdfModal && props.pmaData) && (
                        <span>
                            <Button
                                color="white"
                                onClick={() => {
                                    const signing_ceremony = get(
                                        props.pmaData,
                                        "data.signing_ceremony",
                                        []
                                    );
                                    const president_email = get(
                                        props.pmaData,
                                        "data.president_email",
                                        ""
                                    );
                                    const completed = signing_ceremony.reduce(
                                        (valid, sc) => {
                                            let complete = true;
                                            if (sc.email !== president_email) {
                                                if (sc.signed !== 1) {
                                                    complete = false;
                                                }
                                            }
                                            return valid && complete;
                                        },
                                        true
                                    ) && signing_ceremony.filter(sc => sc.exhibit_data).length !== 0;
                                    if (props.pmaData) {
                                        if (props.pmaData.status === 1) {
                                            let template = pdfTemplate(
                                                clone(formData),
                                                templateData
                                            );
                                            props.getPmaPdf({
                                                data: template,
                                                form_data: formData,
                                                cover: pdfCover(clone(formData),templateData)
                                            });
                                        } else {
                                            const exhibitA =
                                            renderExhibitA(signing_ceremony);
                                            props.getPmaFile({
                                                entry_id: props.pmaData.id,
                                                exhibitA,
                                            });
                                        }
    
                                        openPdfModal(true);
                                    }
                                }}
                                style={{ marginTop: 10 }}
                            >
                                View PDF
                            </Button>
                        </span>
                    )}
                    {openedPdfModal && (
                        <div>
                            <PMAPdfModal
                                pdfData={localPdfData}
                                open={openedPdfModal}
                                close={() => {
                                    setOpenedPdfModal(false);
                                    setLocalPdfData(null);
                                }}
                                file={props.file}
                            />
                        </div>
                    )}
                </div>
            </div>

            {openedTextForm && (
                <Dialog
                    open={openedTextForm}
                    onClose={() => {
                        setOpenedTextForm(false);
                    }}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <div className="dialog-wrapper">
                        <CloseComponent
                            close={() => {
                                setOpenedTextForm(false);
                            }}
                        />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    label=""
                                    multiline
                                    rowsMax="4"
                                    value={templateData[openedField]}
                                    onChange={(e) => {
                                        const newTemplateData =
                                            clone(templateData);
                                        newTemplateData[openedField] =
                                            e.target.value;
                                        setTemplateData(newTemplateData);
                                    }}
                                    style={{ width: "100%" }}
                                    margin="normal"
                                />
                                {includes(
                                    allowAdditionalSignature,
                                    openedField
                                ) && (
                                    <React.Fragment>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    const newTemplateData =
                                                        clone(templateData);
                                                    const newFormData =
                                                        clone(formData);
                                                    if (
                                                        !newTemplateData.additional_signatures ||
                                                        !Array.isArray(
                                                            newTemplateData.additional_signatures
                                                        )
                                                    ) {
                                                        newTemplateData.additional_signatures =
                                                            [openedField];
                                                    } else if (
                                                        !includes(
                                                            newTemplateData.additional_signatures,
                                                            openedField
                                                        )
                                                    ) {
                                                        newTemplateData.additional_signatures.push(
                                                            openedField
                                                        );
                                                    }
                                                    newFormData.additional_signatures =
                                                        clone(
                                                            newTemplateData.additional_signatures
                                                        );
                                                    setFormData(newFormData);
                                                    setTemplateData(
                                                        newTemplateData
                                                    );
                                                }}
                                            >
                                                Add Owner's Signatures
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    const newFormData =
                                                        clone(formData);
                                                    const newTemplateData =
                                                        clone(templateData);
                                                    if (
                                                        !newTemplateData.additional_initials ||
                                                        !Array.isArray(
                                                            newTemplateData.additional_initials
                                                        )
                                                    ) {
                                                        newTemplateData.additional_initials =
                                                            [openedField];
                                                    } else if (
                                                        !includes(
                                                            newTemplateData.additional_initials,
                                                            openedField
                                                        )
                                                    ) {
                                                        newTemplateData.additional_initials.push(
                                                            openedField
                                                        );
                                                    }
                                                    newFormData.additional_initials =
                                                        newTemplateData.additional_initials;
                                                    setFormData(newFormData);
                                                    setTemplateData(
                                                        newTemplateData
                                                    );
                                                }}
                                            >
                                                Add Owner's Initials
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                )}

                                <div className="mr-20">
                                    <Button
                                        className="mr-20"
                                        color="primary"
                                        onClick={() => {
                                            props.saveDraft(templateData, templateChosen);
                                            setOpenedTextForm(false);
                                        }}
                                    >
                                        SAVE
                                    </Button>
                                    <Button
                                        color="white"
                                        onClick={() => {
                                            setOpenedTextForm(false);
                                        }}
                                    >
                                        CANCEL
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Dialog>
            )}

            {openedExhibitForm && (
                <Dialog
                    open={openedExhibitForm}
                    onClose={() => {
                        setOpenedExhibitForm(false);
                    }}
                    maxWidth="md"
                    fullWidth={true}
                >
                    <div className="dialog-wrapper">
                        <CloseComponent
                            close={() => {
                                setOpenedExhibitForm(false);
                            }}
                        />
                        <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                                <TextField
                                    label=""
                                    multiline
                                    rowsMax="4"
                                    value={extractExhibitData(
                                        openedExhibitEmail
                                    )}
                                    onChange={(e) => {}}
                                    style={{ width: "100%" }}
                                    margin="normal"
                                />
                                <div className="mr-20">
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            setOpenedExhibitForm(false);
                                        }}
                                    >
                                        OK
                                    </Button>
                                </div>
                            </GridItem>
                        </GridContainer>
                    </div>
                </Dialog>
            )}

            {alertMsg && (
                <Notification
                    message={alertMsg}
                    open={alertMsg ? true : false}
                    close={() => {
                        setAlertMsg("");
                    }}
                />
            )}
        </div>
    );
};

export default PMAFullForm;
