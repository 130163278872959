import isUserLoggedIn from './authValidation';

const checkPermission = (section, noLogin) => {
  const permissions = isUserLoggedIn();
  
  if (!permissions) {
    section = section ? section : '';
    if (noLogin) {
      //window.location.reload(true);
      window.location.href = '/pages/login-page/?o='+section;
    }
    return false;
  } else {
    if (section) {
      const permissionFound = permissions.indexOf(section);
      
      if (permissionFound === -1) {
        return false;
      }
    }
  }
  return true;
}

export default checkPermission;
