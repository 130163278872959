import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Button from "components/CustomButtons/Button.jsx";
import OfferList from "./OfferList";
import OfferForm from "./OfferForm";

import Dialog from '@material-ui/core/Dialog';
import CustomDialogClose from 'components/CustomDialog/CustomDialogClose';

const Offer = (props) => {
    const [tab, setTab] = useState('List');
    const [offerData, setOfferData] = useState(null);
    const [msgsForAdd, setMsgsForAdd] = useState({error:null,success:null});
    const [openedAsk, setOpenedAsk] = useState(false);

    const { offersData, dataObj, getOffers, canEdit, canDelete, contactsData, getContacts,
        createOffer, updateOffer, deleteOffer, error, success,
        propertyAction, setOffersForAdd, offersForAdd } = props;

    const addForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newData = { ...data, id:offersForAdd.length };
        const newList = [ ...offersForAdd, newData ];
        
        setOffersForAdd(newList);
        setMsgsForAdd({error:null,success:'Added Offer'});
    }
    const updateForAdd = (data) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...offersForAdd ].map((l,i) => {
            if (i === data.id) return data;
            return l;
        })
        
        setOffersForAdd(newList);
        setMsgsForAdd({error:null,success:'Updated Offer'});
    }
    const deleteForAdd = (index) => {
        setMsgsForAdd({error:null,success:null});
        const newList = [ ...offersForAdd ].filter((f,i) => {
            if (i === index) return false;
            return true;
        })
        setOffersForAdd(newList);
        setMsgsForAdd({error:null,success:'Deleted Offer'});
    }

    useEffect(() => {
        if (dataObj) {
            if (dataObj.id) {
                getOffers(dataObj.id);
            }
        }
    }, [])

    const showForm = (tab === 'View' || tab === 'Add' || tab === 'Edit');

    return (
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {/* <Button
                    color={(tab === 'List' || tab === 'Edit' || tab === 'View') ? 'success' : 'primary'}
                    onClick={() => {
                        getOffers(dataObj.id);
                        setTab('List');
                    }}
                    style={{marginRight:10}}
                >
                    View / Edit Offers
                </Button> */}
                {props.propertyAction !== 'View' && <Button
                    color={tab === 'Add' ? 'success' : 'primary'}
                    onClick={() => {
                        setTab('Add');
                    }}
                >
                    <i className="fa fa-plus"></i> Add Offer
                </Button>}
                <hr />
                {tab === 'List' && <OfferList
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    offersData={propertyAction !== 'Add' ? offersData : offersForAdd}
                    offerData={offerData}
                    setOfferData={setOfferData}
                    setTab={setTab}
                />}
                {showForm &&
            <Dialog
                open={showForm}
                keepMounted
                maxWidth={false}
                fullWidth={true}
                fullScreen={true}
            >
              <div className="dialog-wrapper">
                <OfferForm
                    offerData={offerData}
                    action={tab}
                    setTab={setTab}
                    canEdit={propertyAction !== 'Add' ? canEdit : true}
                    canDelete={propertyAction !== 'Add' ? canDelete : true}
                    getContacts={getContacts}
                    contactsData={contactsData}
                    setMsgsForAdd={setMsgsForAdd}

                    updateOffer={propertyAction !== 'Add' ? updateOffer : updateForAdd}
                    createOffer={propertyAction !== 'Add' ? createOffer : addForAdd}
                    deleteOffer={propertyAction !== 'Add' ? deleteOffer : deleteForAdd}
                    getOffers={() => {
                        if (propertyAction !== 'Add') {
                            getOffers(dataObj.id);
                        }
                    }}

                    propertyID={dataObj.id}
                    error={propertyAction !== 'Add' ? error : msgsForAdd.error}
                    success={propertyAction !== 'Add' ? success : msgsForAdd.success}
                    contactLoading={props.contactLoading}

                    setOpenedAsk={setOpenedAsk}
                    showCloseBtn={true}

                    propertyAction={propertyAction}
                />
              </div>
            </Dialog>
            }
            </GridItem>
            {openedAsk && <CustomDialogClose
                        ok_go={() => {
                            setTab('List');
                        }}
                        open={openedAsk}
                        close={() => {
                            setOpenedAsk(false);
                        }}
                    />}
        </GridContainer>
    )
}

const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        error: p.error,
        success: p.success,
        offersData: p.offersData,
        offerData: p.offerData,
        loading: p.loading,
        contactsData: c.contactsData,
        contactLoading: c.loading,
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
      getOffers: (property_id) => {
          dispatch(actions.getOffers(property_id));
      },
      getOffer: (id) => {
          dispatch(actions.getOffer(id));
      },
      createOffer: (data) => {
          dispatch(actions.createOffer(data));
      },
      updateOffer: (data) => {
          dispatch(actions.updateOffer(data));
      },
      deleteOffer: (id) => {
          dispatch(actions.deleteOffer(id));
      },
      getContacts: (params) => {
          dispatch(actions.getContacts(params));
      }
    }
  }

  export default connect(mapStateToProps, mapDispatchToProps)(Offer);
