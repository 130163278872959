import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    entriesData: [],
    pdfData: [],
    userData: [],
    error: null,
    success: null,
    loading: false,
}

// GET_ONBOARDINGS

const getOnboardingsStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        success: null,
        error: null
    })
}

const getOnboardingsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got onboarding pending entries',
        entriesData: action.response,
        loading: false
    })
}

const getOnboardingsFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// GET_OB_PDF

const getObPdfStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        pdfData: [],
        error: null,
        success: null,
    })
}

const getObPdfSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Successfully got entry pdf',
        pdfData: action.response.data,
    })
}

const getObPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_NTE_ENTRY

const createNteEntryStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        success: null,
        error: null
    })
}

const createNteEntrySuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Successfully got nte entry',
        pdfData: action.response.data,
    })
}

const createNteEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// UPDATE_ONBOARDING_ENTRY

const updateOnboardingEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const updateOnboardingEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Updated Onboarding Entry.'
    })
}

const updateOnboardingEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_OB_WORKSPACE_USER

const getObWorkspaceUserStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        userData: [],
        error: null,
        success: null,
    })
}

const getObWorkspaceUserSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got workspace user data',
        userData: action.response.data,
        loading: false
    })
}

const getObWorkspaceUserFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ONBOARDINGS_START:
            return getOnboardingsStart(state, action);
        case actionTypes.GET_ONBOARDINGS_SUCCESS:
            return getOnboardingsSuccess(state, action);
        case actionTypes.GET_ONBOARDINGS_FAIL:
            return getOnboardingsFail(state, action);
        case actionTypes.GET_OB_PDF_START:
            return getObPdfStart(state, action);
        case actionTypes.GET_OB_PDF_SUCCESS:
            return getObPdfSuccess(state, action);
        case actionTypes.GET_OB_PDF_FAIL:
            return getObPdfFail(state, action);
        case actionTypes.CREATE_NTE_ENTRY_START:
            return createNteEntryStart(state, action);
        case actionTypes.CREATE_NTE_ENTRY_SUCCESS:
            return createNteEntrySuccess(state, action);
        case actionTypes.CREATE_NTE_ENTRY_FAIL:
            return createNteEntryFail(state, action);
        case actionTypes.UPDATE_ONBOARDING_ENTRY_START:
            return updateOnboardingEntryStart(state, action);
        case actionTypes.UPDATE_ONBOARDING_ENTRY_SUCCESS:
            return updateOnboardingEntrySuccess(state, action);
        case actionTypes.UPDATE_ONBOARDING_ENTRY_FAIL:
            return updateOnboardingEntryFail(state, action);
        case actionTypes.GET_OB_WORKSPACE_USER_START:
            return getObWorkspaceUserStart(state, action);
        case actionTypes.GET_OB_WORKSPACE_USER_SUCCESS:
            return getObWorkspaceUserSuccess(state, action);
        case actionTypes.GET_OB_WORKSPACE_USER_FAIL:
            return getObWorkspaceUserFail(state, action);
        default: return state;
    }
};

export default reducer;