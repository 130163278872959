import React, { useEffect, useState } from "react";
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import TREntriesList from "./TREntriesList";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import Button from "../../../components/CustomButtons/Button";
import TREntriesForm from "./TREntriesForm";
import { Cached } from "@material-ui/icons";

// this.state.tab === 'my_drafts' ||
// this.state.tab === 'pending_my_approval' ||
// this.state.tab === 'pending_final_signature' ||
// this.state.tab === 'my_completed' ||
// this.state.tab === 'all_completed'

const titleDesc = {
    'my_draft': 'My Draft',
    'completed': 'Completed',
}

const TREntries = props => {
    const [entryData, setEntryData] = useState(null);
    const [formData, setFormData] = useState(null);
    const [openedForm, setOpenedForm] = useState(null);
    const [formAction, setFormAction] = useState(null);
    const { tab, authorizations } = props;

    const reload = () => {
        console.log('loading entries...', tab);
        if (tab === 'my_drafts') {
            props.getTrEntries({
                status: [1],
                own: true
            });
        } else if (tab === 'pending_my_approval') {
            props.getTrEntries({
                status: [4]
            });
        } else if (tab === 'pending_final_signature') {
            props.getTrEntries({
                status: [7]
            });
        } else if (tab === 'my_completed') {
            props.getTrEntries({
                status: [9],
                own: true
            });
        } else if (tab === 'all_completed') {
            props.getTrEntries({
                status: [9]
            });
        } else if (tab === 'pending_signature') {
            props.getTrEntries({
                status: [2]
            });
        }
      }

    useEffect(() => {
        if (props.entryData) {
            setEntryData(props.entryData);
        }
    }, [props.entryData]);

    useEffect(() => {
        if (tab) {
            reload();
        }
    }, [tab]);

    return <div>
        <h4>{titleDesc[tab]}</h4>
        <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
                {tab === 'my_drafts' && <Button color="primary"
                    onClick={() => {
                        setOpenedForm(true);
                        setFormAction('add');
                        setEntryData(null);
                        setFormData(null);
                    }}
                >ADD NEW</Button>}

                <div>
                    <Button color="primary" size="sm" className="mr-20" onClick={() => {
                        reload();
                    }}><Cached /> Reload</Button>
                </div>

                <TREntriesList 
                    data={props.data}
                    tab={tab}
                    authorizations={authorizations}
                    deleteTrEntry={props.deleteTrEntry}
                    openEntry={(entryData) => {
                        setEntryData(entryData);
                        setFormData(entryData.data);
                        setOpenedForm(true);
                        setFormAction('edit');
                    }}
                    reload={reload}
                    success_delete={props.success_delete}
                    error_delete={props.error_delete}
                    getTrSigningUrl={props.getTrSigningUrl}
                    signing_url={props.signing_url}
                  />

                  {openedForm && <TREntriesForm 
                    entryData={entryData}
                    setEntryData={setEntryData}
                    formData={formData}
                    formAction={formAction}
                    close={() => {
                        reload();
                        setOpenedForm(false);
                    }}
                    listTab={tab}
                    viewOnly={(
                    props.tab === 'pending_signature' || 
                    props.tab === 'my_completed' ||
                    props.tab === 'all_completed'
                    ) ? true : false}
                  />}
            </GridItem>
        </GridContainer>
    </div>
}

const mapStateToProps = state => {
	return {
        data: state.tr_entries.entries,
        entryData: state.tr_entries.entryData,
        success_delete: state.tr_entries.success_delete,
        error_delete: state.tr_entries.error_delete,
        signing_url: state.tr_entries.signing_url
	}
}

const mapDispatchToProps = dispatch => {
    return {
        getTrEntries: (data) => {
            dispatch(actions.getTrEntries(data));
        },
        deleteTrEntry: (data) => {
            dispatch(actions.deleteTrEntry(data));
        },
        getTrSigningUrl: (data) => {
            dispatch(actions.getTrSigningUrl(data));
        }
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(TREntries);