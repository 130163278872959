const defaultTemplate = {
    title: '',

    ty_letter_page_1_title: 'Re: Exclusive Authorization to Represent Buyer',
    ty_letter_page_1_dear: 'Dear',
    ty_letter_page_1_dear2: ',',
    ty_letter_page_1_body1: 'I wanted to write and thank you again for choosing illi Commercial Real Estate for your leasing needs.',
    ty_letter_page_1_body2: 'Enclosed is your copy of our fully executed Exclusive Authorization to Represent Buyer.',
    ty_letter_page_1_body3: 'We value the trust you have placed in our company and look forward to working with you.',
    ty_letter_page_1_body4: 'You are in good hands with James and Jason. However, If you have any additional needs. I can be reached at 818-501-2240, or feel free to email me at:',
    ty_letter_page_1_body5: '.',
    ty_letter_page_1_closing: 'Sincerely,',
    ty_letter_page_1_closing_company: 'illi COMMERCIAL REAL ESTATE',
    ty_letter_page_1_address1: '5990 Sepulveda Boulevard',
    ty_letter_page_1_address2: 'Suite 600',
    ty_letter_page_1_city_state_zip: 'Sherman Oaks CA 91411',
    ty_letter_page_1_phone: 'Phone: 818.501.2212',
    ty_letter_page_1_fax: 'Fax: 818.501.2202',
    ty_letter_page_1_website: 'www.illicre.com',

    main_page_1_address: '5990 Sepulveda Blvd. Suite 600, Sherman Oaks, California, 91411',
    main_page_1_title: 'EXCLUSIVE AUTHORIZATION TO REPRESENT TENANT',
    main_page_1_opening: '("Tenant(s)") hereby grants to VII Commercial Corp., a California corporation d/b/a "illi Commercial Real Estate" ("Broker") the exclusive right to select property and negotiate for its lease on behalf of Tenant for a period commencing',
    main_page_1_opening_2: 'and ending at midnight on',
    main_page_1_opening_3: '.',
    main_page_1_property: 'The property requirements are as follows:',
    main_page_1_req1: '1. TYPE OF PROPERTY:',
    main_page_1_req2: '2. LOCATION:',
    main_page_1_req3: `3. In consideration of this exclusive authorization to represent Tenant (hereinafter "Authorization"), Broker agrees to diligently pursue the procurement of property acceptable to Tenant. Broker is authorized only: a) To select properties that substantially meet the requirements set forth above; b) to present those properties to tenant; c) on Tenant's approval, to negotiate for their lease, but not to commit Tenant to the Lease of any property or to sign any instrument on behalf of Tenant without Tenant's prior consent. Tenant agrees to cooperate fully with Broker in carrying out the intent of this Authorization and to immediately refer to Broker all offers, potential leads, inquiries or any other possible leasing information from any source whatsoever. Any and all negotiations for leasing during the term hereof shall be through Broker.`,
    main_page_1_req4: `4. It is the intent of the parties hereto that the Landlord of such property leased by Tenant shall pay a commission to Broker in an amount calculated on the basis of six (6%) percent of the total rent for the entire term of the Lease, or in accordance with the appropriate provisions set forth in a Commission Agreement between Landlord and Broker, whichever is applicable (hereinafter referred to as the "Commission"). It is agreed by both parties to this Authorization that, subject to the provisions below, Tenant shall be under no obligation to pay any commissions on any transactions that may be consummated under the above provisions of this Authorization.`,
    main_page_1_req5: `5. If within one hundred eighty (180) days after the expiration of this Authorization or any extension thereof, Tenant enters into an agreement to lease a property previously submitted to Tenant by Broker or with any entity with whom Broker has negotiated or communicated in an effort to effect a lease and whose description or name appear on any list of such properties, persons or entities which Broker shall have mailed to Tenant at the address stated below any time within ten (10) days following such expiration, then Broker shall be deemed to be the procuring cause of said transaction and entitled to be paid a full commission from Landlord. With respect to any lease by Tenant hereunder, Tenant agrees to: a) identify Broker as the procuring cause thereof; b) inform Landlord that Broker is entitled to be paid said Commission; c) diligently and in good faith to seek to have any such Landlord negotiate through Broker, and pay said Commission to Broker, and d) cooperate with Broker in establishing Broker as the procuring cause of any such lease, provided, however, that in lieu of complying fully and completely with provisions (a) through (d) hereof, and in the event Landlord does not pay said commission to Broker, Tenant shall, at its sole discretion, have the option instead to pay to Broker said Commission.`,
    main_page_1_req6: `6. No amendments or alterations in the terms hereof or withdrawal of this Authorization shall be valid or binding unless made in writing and signed by both Tenant and an officer of Broker, and Tenant and Broker agree that there are no statements, representations, inducements or promises made or relied upon by one or the other, except as expressly stated herein.`,
    main_page_1_req7: `7. In the event of any controversy related to, concerning or arising out of, this Exclusive Authorization to Represent Tenant, or any facts based upon or involving same, the prevailing party, whether in court or by way of out-of-court settlement, shall be entitled to recover from the non-prevailing party or parties such prevailing party's attorney's fees, court costs, expert witness fees and/or other expenses relating to such controversy, including attorney's fees and court and other costs, including fees and costs on appeal, if any.`,

    main_page_2_broker: `BROKER: VII Commercial Corp., d/b/a`,
    main_page_2_broker_1: 'illi Commercial Real Estate',
    main_page_2_broker_by: 'By:',
    main_page_2_broker_name_printed: 'Name Printed:',
    main_page_2_broker_title: 'Title:',
    main_page_2_broker_address: 'Address:',
    main_page_2_broker_address_1: '5990 Sepulveda Blvd., Suite 600',
    main_page_2_broker_city_state_zip: 'City, State, Zip:',
    main_page_2_broker_city_state_zip_1: 'Sherman Oaks, CA 91411',
    main_page_2_broker_phone: 'Phone:',
    main_page_2_broker_phone_1: '818-501-2212',

    main_page_2_tenant: 'TENANT:',
    main_page_2_tenant_by: 'By:',
    main_page_2_tenant_name_printed: 'Name Printed:',
    main_page_2_tenant_title: 'Title:',
    main_page_2_tenant_address: 'Address:',
    main_page_2_tenant_city_state_zip: 'City, State, Zip:',
    main_page_2_tenant_phone: 'Phone:',
    main_page_2_legal: 'NO REPRESENTATIONS ARE MADE BY AGENT AS TO THE LEGAL EFFECT OR VALIDITY OF THIS AGREEMENT. A REAL ESTATE BROKER IS QUALIFIED TO GIVE ADVICE IN REAL ESTATE MATTERS ONLY. IF YOU DESIRE LEGAL ADVICE, CONSULT YOUR ATTORNEY.',
}

export default defaultTemplate;