import Checkbox from "@material-ui/core/Checkbox";
import React from "react";
import ReactTable from "react-table";

const ImporterContactsListTable = (props) => {
    const { data, loading, handleSetContactToImport, contactsToImport } =
        props;

    const listItems = data.map((contact) => {
        const isChecked = contactsToImport.some(c => {
            return c.id === contact.id;
        });
        return {
            ...contact,
            action: (
                <div>
                    <Checkbox color="default" onChange={() => handleSetContactToImport(contact)} checked={isChecked} />
                </div>
            ),
        };
    });

    const columns = [
        // {
        // 	Header: '#',
        // 	accessor: 'id',
        // },
        {
            Header: "First Name",
            accessor: "first_name",
            // minWidth: 200
        },
        {
            Header: "Last Name",
            accessor: "last_name",
        },
        {
            Header: "Company",
            accessor: "company",
        },
        {
            Header: "Email",
            accessor: "primary_email",
        },
        {
            Header: "Phone",
            accessor: "primary_phone",
        },
        {
            Header: "Actions",
            accessor: "action",
            sortable: false,
            filterable: false,
        },
    ];

    return (
        <div className="table-adjust23 table-hoverable">
            <ReactTable
                loading={loading}
                filterable
                className="-striped"
                showPaginationTop
                showPaginationBottom
                resizable={true}
                defaultPageSize={10}
                pageSizeOptions={[10, 20, 30]}
                data={listItems}
                columns={columns}
            />
        </div>
    );
};

export default ImporterContactsListTable;
