import React, { useState, useEffect } from 'react';
import Close from '@material-ui/icons/Close';
import Notification from 'components/Notification/Notification';
import Button from "components/CustomButtons/Button.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { IconButton, TextField } from '@material-ui/core';
import { clone } from 'shared/utility';
import { getUsername } from 'shared/utility';
import { getUserID } from 'shared/authValidation';
import download from 'downloadjs';
import ClipLoader from "react-spinners/ClipLoader";

const FilesForm = (props) => {

    const { leaseData, createCrmFile, downloadedFile, contactSuccess, contactError,
        action, formDataObj, setFormDataObj, fileList, contactLoading } = props;

    const [selectedFiles, setSelectedFiles] = useState([]);
    const [successStatus, setSuccessStatus] = useState(null);
    const [fileNames, setFileNames] = useState([]);
    const [errorFile, setErrorFile] = useState(null);
    const [downloadFileName, setDownloadFileName] = useState(null);

    useEffect(() => {
        if (contactSuccess === 'Successfully uploaded document') {
            setSuccessStatus(contactSuccess);
        }
        if (contactError === 'Failed to upload document') {
            setSuccessStatus('Failed to upload document');
        }
        if (contactSuccess === 'Successfully deleted CRM file.') {
            let name = leaseData.id + '-' + leaseData.property_name;
            props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }, [contactSuccess, contactError]);

    useEffect(() => {
        if (downloadedFile && downloadedFile.data && downloadedFile.data.length && downloadFileName) {
            let fileBuffer = Buffer.from(props.downloadedFile, 'ArrayBuffer').toString('binary');
            download(fileBuffer, downloadFileName);
        }
    }, [downloadedFile])

    useEffect(() => {
        setDownloadFileName(null);
        if (props.leaseData) {
            let name = leaseData.id + '-' + leaseData.property_name;
            if (props.getCrmFileList) props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }, [])

    const cancel = () => {
        props.closeForm()
    }

    const handleMultipleFileChange = (e) => {
        setSelectedFiles([])
        const allowableFileExtensions = ['.pdf', '.doc', '.docx', '.png', '.jpg', '.jpeg'];
        let files = e.target.files;
        let selectedArray = [...selectedFiles];
        let namesArr = [];
        let formData = new FormData();

        Object.keys(files).forEach((key) => {
            let file = files[key];
            let extension = file.name.match(/(?:\.([^.]+))?$/);
            let originalName = file.name.replace(extension[0], '');
            if (allowableFileExtensions.includes(extension[0]) && leaseData) {
                const createdDate = new Date().toISOString().split('T')[0];
                let currentFileName = originalName + '-' + createdDate + extension[0];
                namesArr.push(currentFileName);
                setFileNames(namesArr);
                let user = getUsername().replace(/ /g, '.');
                formData.append('User', user);
                formData.append('name', leaseData.property_name);
                formData.append('Folder', 'Leases');
                formData.append('SystemNumber', leaseData.id)
                formData.append('UserId', getUserID());
                formData.append('file', files[key], currentFileName);
                selectedArray.push(formData);
                setSelectedFiles(formData);
                let newFormDataObj = clone(formDataObj) || {};

                // Replacing existing file if it's already on the formDataObj.
                for (const key in newFormDataObj) {
                    if (key.substring(0, key.indexOf("-")) === currentFileName.substring(0, currentFileName.indexOf("-"))) {
                        delete newFormDataObj[key];
                    }
                }
                newFormDataObj[currentFileName] = formData;
                setFormDataObj(newFormDataObj)
            } else {
                document.getElementById('multi_docs').value = ''
                files = null;
                e.target.files = null;
                setSelectedFiles(null);
                setErrorFile(true);
            }
        });
        if (namesArr && namesArr.length && action === "Edit") {
            createCrmFile(formData)
        }
    }

    const closeAlert = () => {
        setSuccessStatus(null);
        setSelectedFiles([]);
        setErrorFile(null);
        if (props.leaseData) {
            let name = props.leaseData.id + '-' + props.leaseData.property_name;
            props.getCrmFileList({ id: name, userId: getUserID() });
        }
    }

    return (
        <div>
            {props.showCloseBtn && <div className="close-btn" onClick={() => { cancel() }}><Close /></div>}

            <GridContainer spacing={0} align="center" justify="center">
                <GridItem xs={12} sm={12} md={12}>
                    {action === 'Edit' ? <h3>Upload Files</h3> : <h3>Property Files</h3>}
                </GridItem>
                {action === 'Edit' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <input
                            accept="application/pdf, image/jpeg, image/png"
                            style={{ display: 'none' }}
                            id="multi_docs"
                            multiple
                            type="file"
                            onChange={handleMultipleFileChange}
                        />
                        <label htmlFor="multi_docs">
                            <Button variant="raised" component="span" color="rose">
                                Upload
                            </Button>
                        </label>
                    </GridItem>
                }
                {action === 'Add' &&
                    <GridItem xs={12} sm={12} md={12}>
                        <input
                            accept="application/pdf, image/jpeg, image/png"
                            style={{ display: 'none' }}
                            id="multi_docs"
                            multiple
                            type="file"
                            onChange={handleMultipleFileChange}
                        />
                        <label htmlFor="multi_docs">
                            <Button variant="raised" component="span" color="rose">
                                Upload
                            </Button>
                        </label>
                    </GridItem>
                }
                {fileList && !fileList.length && !formDataObj && !contactLoading &&
                    <GridItem>
                        <p><em>No files to display.</em></p>
                    </GridItem>
                }
                {contactLoading &&
                    <ClipLoader />
                }
                {fileList && (fileList.length > 0) && !contactLoading && action !== 'Add' &&
                    <GridItem container xs={6} sm={6} md={6} justify="center" alignItems="center" direction="column">
                        <h3>Uploaded Files</h3>
                        <List>
                            {
                                fileList.map((file, index) => {
                                    return (
                                        <ListItem
                                            key={`${index}-${file.name}`}
                                            role={undefined}
                                            dense
                                        >
                                            {action === 'Edit' &&
                                                <ListItemIcon>
                                                    <IconButton
                                                        onClick={() => {
                                                            props.deleteCrmFile(file.id);
                                                        }}
                                                    >
                                                        <i className="fas fa-trash-alt red-text"></i>
                                                    </IconButton>
                                                </ListItemIcon>
                                            }
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        // Download the file from backend / google drive
                                                        setDownloadFileName(file.name);
                                                        props.getCrmFile({ name: file.name, id: file.id });
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-down blue-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText primary={`${file.name}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                {formDataObj && Object.keys(formDataObj).length > 0 && action === 'Add' &&
                    <GridItem container xs={6} sm={6} md={6} justify="center" alignItems="center" direction="column">
                        <h3>Uploaded Files</h3>
                        <List>
                            {
                                Object.keys(formDataObj).map((key, index) => {
                                    return (
                                        <ListItem
                                            key={`${index}-${key}`}
                                            role={undefined}
                                            dense
                                        >
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        let newFormDataObj = clone(formDataObj) || {};
                                                        delete newFormDataObj[key];
                                                        setFormDataObj(newFormDataObj);
                                                    }}
                                                >
                                                    <i className="fas fa-trash-alt red-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemIcon>
                                                <IconButton
                                                    onClick={() => {
                                                        download(formDataObj[key].get('file'), key);
                                                    }}
                                                >
                                                    <i className="fas fa-arrow-down blue-text"></i>
                                                </IconButton>
                                            </ListItemIcon>
                                            <ListItemText primary={`${key}`} />
                                        </ListItem>
                                    )
                                })
                            }
                        </List>
                    </GridItem>
                }
                <GridItem xs={12} sm={12} md={12}>
                    {successStatus === 'Successfully uploaded document' &&
                        <Notification
                            open={true}
                            message="Successfully uploaded document"
                            close={closeAlert}
                        />
                    }
                    {successStatus === 'Failed to upload document' &&
                        <Notification
                            open={true}
                            message="Failed to upload document"
                            close={closeAlert}
                        />
                    }
                    {errorFile &&
                        <Notification
                            open={true}
                            message="Please upload a valid file with the following extensions: .pdf, .doc, .docx, .png, .jpg, or .jpeg"
                            close={closeAlert}
                        />
                    }
                </GridItem>
            </GridContainer>
        </div>
    )

}

export default FilesForm;