import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../shared/utility';

const initialState = {
    entryData: null,
    entryData_success: null,
    entryData_error: null,
    entriesData: [],
    statusEntries: [],
    pdfData: [],
    pdfHistoryData: null,
    historyData: [],
    historyEntryData: null,
    error: null,
    success: null,
    loading: false,
    loadingCreate: false,
    ertlURL: null,
    clientErtl: null,
    clientError: null,
    clientEmail: null,
    error_approvers: null,
    success_approvers: null,
    approvers: null,

    error_update: null,
    success_update: null,
    loading_update: null
}

const getEntriesByStatusStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        statusEntries: [],
        error: null,
        success: null,
    });
}

const getEntriesByStatusSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Successfully got Entries',
        statusEntries: action.data.entriesData,
        statusEntriesCount: action.data.entriesCount
    });
}

const getEntriesByStatusFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET All Entries For User
const getEntriesStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        entriesData: [],
        error: null,
        success: null,
    });
}

const getEntriesSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: 'Successfully got Entries',
        entriesData: action.data
    });
}

const getEntriesFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE ENTRY
const createEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loadingCreate: true
    })
}

const createEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'ENTRY Created',
        loadingCreate: false
    })
}

const createEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loadingCreate: false
    })
}

// UPDATE ENTRY STATUS
// CREATE ENTRY
const updateEntryStatusStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        error: null,
        success: null
    })
}

const updateEntryStatusSuccess = (state, action) => {
    return updateObject(state, {
        loading: false,
        success: action.response ? action.response.msg : 'Successfully updated ERTL'
    })
}

const updateEntryStatusFail = (state, action) => {
    return updateObject(state, {
        loading: false,
        error: action.err
    })
}

// Get a PDF generated from entry and template
const getEntryPdfStart = (state, action) => {
    return updateObject(state, {
        loading: true,
        pdfData: [],
        error: null,
        success: null,
    })
}

const getEntryPdfSuccess = (state, action) => {
    
    return updateObject(state, {
        loading: false,
        success: 'Successfully got entry pdf',
        pdfData: action.response.data,
    })
}

const getEntryPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// DELETE_ERTL_ENTRY

const deleteErtlEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const deleteErtlEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: action.response
    })
}

const deleteErtlEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_ERTL_HISTORY

const getErtlHistoryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getErtlHistorySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got History',
        historyData: action.response
    })
}

const getErtlHistoryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_ERTL_PDF

const getErtlPdfStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getErtlPdfSuccess = (state, action) => {
    console.log('getting response', action.response);
    return updateObject(state, {
        success: 'Got History PDF',
        pdfHistoryData: action.response
    })
}

const getErtlPdfFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_ERTL_HISTORY_ENTRY

const getErtlHistoryEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getErtlHistoryEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Got ERTL History Entry',
        historyEntryData: action.response
    })
}

const getErtlHistoryEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_ERTL_URL

const getErtlUrlStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const getErtlUrlSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully gotten URL',
        ertlURL: action.response.url ? action.response.url : null
    })
}

const getErtlUrlFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_CLIENT_ERTL

const getClientErtlStart = (state, action) => {
    return updateObject(state, {
        clientError: null,
        success: null,
        clientEmail: null,
    })
}

const getClientErtlSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        clientErtl: action.response.clientErtl,
        clientEmail: action.response.clientEmail
    })
}

const getClientErtlFail = (state, action) => {
    return updateObject(state, {
        clientError: action.err,
    })
}

// UPDATE_CLIENT_ERTL_ENTRY

const updateClientErtlEntryStart = (state, action) => {
    return updateObject(state, {
        clientError: null,
        success: null
    })
}

const updateClientErtlEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success'
    })
}

const updateClientErtlEntryFail = (state, action) => {
    return updateObject(state, {
        clientError: action.err
    })
}

// SEND_ERTL_REVIEW_LINK

const sendErtlReviewLinkStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const sendErtlReviewLinkSuccess = (state, action) => {
    return updateObject(state, {
        success: action.response,
    })
}

const sendErtlReviewLinkFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// GET_ERTL_ENTRY

const getErtlEntryStart = (state, action) => {
    return updateObject(state, {
        entryData_error: null,
        entryData_success: null,
        entryData: null
    })
}

const getErtlEntrySuccess = (state, action) => {
    return updateObject(state, {
        entryData_success: 'Success',
        entryData: action.response
    })
}

const getErtlEntryFail = (state, action) => {
    return updateObject(state, {
        entryData_error: action.err
    })
}

// GET_ERTL_APPROVERS

const getErtlApproversStart = (state, action) => {
    return updateObject(state, {
        error_approvers: null,
        success_approvers: null,
        approvers: null
    })
}

const getErtlApproversSuccess = (state, action) => {
    return updateObject(state, {
        success_approvers: 'Success',
        approvers: action.response
    })
}

const getErtlApproversFail = (state, action) => {
    return updateObject(state, {
        error_approvers: action.err
    })
}

// UPDATE_ERTL

const updateErtlStart = (state, action) => {
    return updateObject(state, {
        error_update: null,
        success_update: null,
        loading_update: null
    })
}

const updateErtlSuccess = (state, action) => {
    return updateObject(state, {
        success_update: 'Success'
    })
}

const updateErtlFail = (state, action) => {
    return updateObject(state, {
        error_update: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_ENTRIES_START:
            return getEntriesStart(state, action);
        case actionTypes.GET_ENTRIES_SUCCESS:
            return getEntriesSuccess(state, action);
        case actionTypes.GET_ENTRIES_FAIL:
            return getEntriesFail(state, action);
        case actionTypes.GET_ENTRIES_BY_STATUS_START:
            return getEntriesByStatusStart(state, action);
        case actionTypes.GET_ENTRIES_BY_STATUS_SUCCESS:
            return getEntriesByStatusSuccess(state, action);
        case actionTypes.GET_ENTRIES_BY_STATUS_FAIL:
            return getEntriesByStatusFail(state, action);
        case actionTypes.CREATE_ENTRY_START:
            return createEntryStart(state, action);
        case actionTypes.CREATE_ENTRY_SUCCESS:
            return createEntrySuccess(state, action);
        case actionTypes.CREATE_ENTRY_FAIL:
            return createEntryFail(state, action);
        case actionTypes.GET_ENTRY_PDF_START:
            return getEntryPdfStart(state, action);
        case actionTypes.GET_ENTRY_PDF_SUCCESS:
            return getEntryPdfSuccess(state, action);
        case actionTypes.GET_ENTRY_PDF_FAIL:
            return getEntryPdfFail(state, action);
        case actionTypes.UPDATE_ENTRY_STATUS_START:
            return updateEntryStatusStart(state, action);
        case actionTypes.UPDATE_ENTRY_STATUS_SUCCESS:
            return  updateEntryStatusSuccess(state, action);
        case actionTypes.UPDATE_ENTRY_STATUS_FAIL:
            return  updateEntryStatusFail(state, action);

        case actionTypes.DELETE_ERTL_ENTRY_START:
            return deleteErtlEntryStart(state, action);
        case actionTypes.DELETE_ERTL_ENTRY_SUCCESS:
            return deleteErtlEntrySuccess(state, action);
        case actionTypes.DELETE_ERTL_ENTRY_FAIL:
            return deleteErtlEntryFail(state, action);

        case actionTypes.GET_ERTL_HISTORY_START:
            return getErtlHistoryStart(state, action);
        case actionTypes.GET_ERTL_HISTORY_SUCCESS:
            return getErtlHistorySuccess(state, action);
        case actionTypes.GET_ERTL_HISTORY_FAIL:
            return getErtlHistoryFail(state, action);

        case actionTypes.GET_ERTL_PDF_START:
            return getErtlPdfStart(state, action);
        case actionTypes.GET_ERTL_PDF_SUCCESS:
            return getErtlPdfSuccess(state, action);
        case actionTypes.GET_ERTL_PDF_FAIL:
            return getErtlPdfFail(state, action);

        case actionTypes.GET_ERTL_HISTORY_ENTRY_START:
            return getErtlHistoryEntryStart(state, action);
        case actionTypes.GET_ERTL_HISTORY_ENTRY_SUCCESS:
            return getErtlHistoryEntrySuccess(state, action);
        case actionTypes.GET_ERTL_HISTORY_ENTRY_FAIL:
            return getErtlHistoryEntryFail(state, action);
        
        case actionTypes.GET_ERTL_URL_START:
            return getErtlUrlStart(state, action);
        case actionTypes.GET_ERTL_URL_SUCCESS:
            return getErtlUrlSuccess(state, action);
        case actionTypes.GET_ERTL_URL_FAIL:
            return getErtlUrlFail(state, action);

        case actionTypes.GET_CLIENT_ERTL_START:
            return getClientErtlStart(state, action);
        case actionTypes.GET_CLIENT_ERTL_SUCCESS:
            return getClientErtlSuccess(state, action);
        case actionTypes.GET_CLIENT_ERTL_FAIL:
            return getClientErtlFail(state, action);

        case actionTypes.UPDATE_CLIENT_ERTL_ENTRY_START:
            return updateClientErtlEntryStart(state, action);
        case actionTypes.UPDATE_CLIENT_ERTL_ENTRY_SUCCESS:
            return updateClientErtlEntrySuccess(state, action);
        case actionTypes.UPDATE_CLIENT_ERTL_ENTRY_FAIL:
            return updateClientErtlEntryFail(state, action);

        case actionTypes.SEND_ERTL_REVIEW_LINK_START:
            return sendErtlReviewLinkStart(state, action);
        case actionTypes.SEND_ERTL_REVIEW_LINK_SUCCESS:
            return sendErtlReviewLinkSuccess(state, action);
        case actionTypes.SEND_ERTL_REVIEW_LINK_FAIL:
            return sendErtlReviewLinkFail(state, action);

        case actionTypes.GET_ERTL_ENTRY_START:
            return getErtlEntryStart(state, action);
        case actionTypes.GET_ERTL_ENTRY_SUCCESS:
            return getErtlEntrySuccess(state, action);
        case actionTypes.GET_ERTL_ENTRY_FAIL:
            return getErtlEntryFail(state, action);

        case actionTypes.GET_ERTL_APPROVERS_START:
            return getErtlApproversStart(state, action);
        case actionTypes.GET_ERTL_APPROVERS_SUCCESS:
            return getErtlApproversSuccess(state, action);
        case actionTypes.GET_ERTL_APPROVERS_FAIL:
            return getErtlApproversFail(state, action);

        case actionTypes.UPDATE_ERTL_START:
            return updateErtlStart(state, action);
        case actionTypes.UPDATE_ERTL_SUCCESS:
            return updateErtlSuccess(state, action);
        case actionTypes.UPDATE_ERTL_FAIL:
            return updateErtlFail(state, action);

        default: return state;
    }
};

export default reducer;