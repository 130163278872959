import { useState } from "react";
import React from "react";
import { connect } from 'react-redux';
import * as actions from "store/actions";
import Button from "components/CustomButtons/Button";
import Notification from "components/Notification/Notification";
import NotificationAsk from "components/Notification/NotificationAsk";
import { get } from "lodash";
import Loader from "components/Loader/Loader";
import axios from "store/axios/axios-files";
import { makeid } from "../../shared/utility";
import { IconButton } from "@material-ui/core";

// REQUIRED PROPS
// --------------
// update - function
// remove - function
// field - string field name
// the_filename <optional>
// type = "crm" or "rs"

const FileUploader = (props) => {
    const [file, setFile] = useState(null);
    const [notification, setNotification] = useState('');
    const [ask, setAsk] = useState(false);
    const [loading, setLoading] = useState(false);
    const [notes, setNotes] = useState('');

    console.log('FileUploader', props)

    const handleChange = (event) => {
        setFile(event.target.files[0])
    }

    const uploadFile = async (data) => {
        setLoading(true);
        try {
            const formData = new FormData();
            const originalname = data.file.name;
            const newname = `${makeid(5)}-${originalname}`;
            formData.append("file", data.file, newname);
            formData.append("sfiletype", data.type);
            formData.append("notes", data.notes ? data.notes : '');
            const response = await axios.post('/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            const rdata = response.data;
            const file_uploaded = get(rdata, 'file_data', null);
            const date_file_uploaded = get(rdata, 'date_created', null);
            const notes = get(rdata, 'notes', '');
            setNotes(notes);
            props.update(`${file_uploaded},${date_file_uploaded}`);
        } catch(e) {
            console.log(e);
            setNotification('error uploading, please try again');
        }
        setLoading(false);
        setFile(null);
    }

    let the_file = '';
    let the_id = '';
    let the_date = '';
    const the_filename = get(props, 'the_filename', null);
    if (the_filename) {
        const the_arr = the_filename.split(',')
        if (the_arr.length > 1) {
            the_file = the_arr[1];
            the_id = the_arr[0];
            the_date = the_arr[2] ? the_arr[2] : '';
        }
    }
    
    if (loading) return <div style={{margin:10}}><Loader /></div>;

    return <div style={{position:'relative'}}>
        {props.title && <h4 style={{fontSize:15,fontWeight:'bold'}}>{props.title}</h4>}

        {(!the_file && props.update && !props.disabled) && <div>
            <div style={{marginBottom:10}}><input 
                type="file"
                onChange={handleChange}
                style={{width:'100%'}}
                accept="image/*"
            /></div>
            {file && <Button size={props.button_size ? 'lg' : 'sm'} color="primary" className="mr-20" onClick={() => {
                uploadFile({
                    type: props.type,
                    file
                })
            }}>UPLOAD</Button>}
            {props.close && <Button color="white" onClick={() => {
                props.close();
            }}>CANCEL</Button>}
        </div>}
        {the_file && <div>
        {(props.remove && !props.disabled) && <div style={{position:'absolute',top:'-11px',right:5}}><i className="fa fa-trash red-text hoverable" style={{float:'right'}} onClick={() => {
            setAsk(true);
        }}></i></div>}

        {the_file && <div>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor" class="bi bi-file-earmark" viewBox="0 0 16 16">
                <path d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5z"/>
            </svg>
        </div>}

        {!props.hide_filename && <div style={{overflow:'hidden'}}><em>{the_file}</em></div>}
        {(!props.hide_date && the_date) && <div style={{overflow:'hidden'}}><em><small>Uploaded {the_date}</small></em></div>}
        
        <IconButton onClick={() => {
            props.getFileUpload({ id:the_id, type: props.type });
        }}><i className="fa fa-arrow-down red-text"></i></IconButton>
        </div>}

        {notification && <Notification 
            open={notification ? true : false}
            close={() => { setNotification(''); }}
            message={notification}
        />}

        {ask && <NotificationAsk 
            open={ask}
            close={() => { setAsk(false) }}
            title="Are you sure?"
            message={`Do you want to remove this file "${the_file}" ?`}
            success={() => {
                props.remove();
                setAsk(false);
            }}
        />}
    </div>
}

const mapStateToProps = state => {
    return {
        loading: state.files.loading,
        error_upload: state.files.error_upload,
        success_upload: state.files.success_upload,
        file_uploaded: state.files.file_uploaded,
        date_file_uploaded: state.files.date_file_uploaded
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        createFileUpload: (data) => {
            dispatch(actions.createFileUpload(data));
        },
        getFileUpload: (data) => {
            dispatch(actions.getFileUpload(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FileUploader);