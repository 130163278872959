import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import * as actions from "store/actions/index";
import ERTLFullForm from './ERTLFullForm';
import ERTLMultiForm from './ERTLMultiForm';
import Button from "components/CustomButtons/Button.jsx";
import { Dialog } from '@material-ui/core';
import { initialERTLdata } from './ERTLModel';
import { clone } from 'shared/utility';
import { Tabs, Tab } from '@material-ui/core';
import moment from 'moment';
import ArrowBack from "@material-ui/icons/ArrowBack";
import Tooltip from "@material-ui/core/Tooltip";
import { textData } from "containers/ERTL/ERTLObj";
import jwt_decode from "jwt-decode";
import { Close } from '@material-ui/icons';

const ERTLFormWizard = (props) => {
    const [templateText, setTemplateText] = useState(textData);
    const [editedObj, setEditedObj] = useState({});
    const [tab, setTab] = useState('wizard');
    const [ertlFormData, setErtlFormData2] = useState(clone(initialERTLdata));
    const [ertlEntryData, setErtlEntryData] = useState(null);
    const [activityTracker, setActivityTracker] = useState(false);
    const [openedAskBeforeClose, setOpenedAskBeforeClose] = useState(false);
    const [ownerNames, setOwnerNames2] = useState([]);
    const [templateEdited, setTemplateEdited] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState(-1);
    const [saveDraft, setSaveDraft] = useState(false);


    const setErtlFormData = (newData) => {
        setActivityTracker(true);
        setErtlFormData2(newData);
    }

    const setOwnerNames = (value) => {
        setOwnerNames2(value);

    }

    const integrateData = () => {
        // integrates currently logged in person
        const newData = clone(ertlFormData);
        if (props.ertlData) {
            const data = props.ertlData.data;
            Object.keys(data).forEach(field => {
                newData[field] = data[field];
            });
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = jwt_decode(token);
                if (decoded.name) {
                    let exists = false;
                    newData.agents.forEach(ag => {
                        if (ag.email === decoded.mail) exists = true;
                    })
                    if (!exists) {
                        newData.agents = newData.agents.concat([{
                            name: decoded.name,
                            email: decoded.mail
                        }])
                    }
                }
            }
            setErtlFormData2(newData);
            setErtlEntryData(props.ertlData);
        }
    }

    useEffect(() => {
        console.log('history data changing ->', props.historyData);
    }, [props.historyData]);

    useEffect(() => {
        // if approver go to tab Full Form 
        if (props.origin === 'approver' || props.origin === 'view' || props.origin === 'Agent Review') {
            setTab('full');
        }
        // set defaults 
        if (!props.ertlData) {
            const newData = clone(ertlFormData);
            const now = moment().format('MMMM D, YYYY');
            newData.p2_date_as_of = now;
            newData.p1_date = now;
            const token = localStorage.getItem('token');
            if (token) {
                const decoded = jwt_decode(token);
                if (decoded.name) {
                    let exists = false;
                    newData.agents.forEach(ag => {
                        if (ag.email === decoded.mail) exists = true;
                    })
                    if (!exists) {
                        newData.agents = newData.agents.concat([{
                            name: decoded.name,
                            email: decoded.mail
                        }])
                    }
                }
            }
            setErtlFormData2(newData);
        } else {
            
            integrateData();
        }

    }, []);

    useEffect(() => {
        props.getTemplates();
        props.getErtlApprovers();
    }, []);

    const getTabIndex = () => {
        const obj = {
            wizard: 0,
            full: 1
        }
		return obj[tab];
	}

    console.log('ERTLFormWizard', props, ertlFormData);

    const close = () => {
        if (activityTracker) {
            return setOpenedAskBeforeClose(true);
        }
        props.setParentTab(props.origin ? props.origin : '');
    }

    return (
        <div>
            <div className="close-btn" onClick={() => { 
                close();
             }}><Close /></div>
            <div>
            <span onClick={() => { 
                if (activityTracker) {
                    return setOpenedAskBeforeClose(true);
                }
                props.setParentTab(props.origin ? props.origin : '');
            }} className="hoverable blue-text">
                <Tooltip title='Close Window' placement='top'>
                    <ArrowBack />
                </Tooltip>
            </span>
            {(props.origin !== 'view' && props.origin !== 'approver') && <Tabs 
						TabIndicatorProps={{style: {background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f'}}} 
						style={{marginBottom:10}} 
						value={getTabIndex()}>
						<Tab
							onClick={() => {
								setTab('wizard')
							}}
							style={{color: getTabIndex() === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'}}
							tabIndex={0}
							label='ERTL Wizard'
                            icon={<i className="fas fa-magic font-22"></i>}
						/>
						{<Tab
							onClick={() => {
								setTab('full');
							}}
							style={{color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'}}
							tabIndex={1}
							label='Full Form'
                            icon={<i className="fas fa-newspaper font-22"></i>}
						/>}
            </Tabs>}
            {(props.origin === 'view' || props.origin === 'approver') && <Tabs 
						TabIndicatorProps={{style: {background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f'}}} 
						style={{marginBottom:10}} 
						value={0}>
						{<Tab
							onClick={() => {
								setTab('full');
							}}
							style={{color: getTabIndex() === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)'}}
							tabIndex={1}
							label='Full Form'
                            icon={<i className="fas fa-newspaper font-22"></i>}
						/>}
            </Tabs>}
            </div>
            {tab === "wizard" &&
                <ERTLMultiForm
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                    setMainTab={setTab}
                    ownerNames={ownerNames}
                    setOwnerNames={setOwnerNames}
                    origin={props.origin}
                    
                    selectedTemplate={selectedTemplate}
                    templateText={templateText}
                    templateEdited={templateEdited}
                    setTemplateEdited={setTemplateEdited}
                    
                    ertlData={props.ertlData}
                    updateEntry={props.updateEntry}
                    saveDraft={saveDraft}
                    setSaveDraft={setSaveDraft}
                    signReview={props.signReview}
                    
                    prefill_property_id={props.prefill_property_id}
                    reset_prefill_property_id={props.reset_prefill_property_id}
                />
            }
            {tab === "full" &&
                <ERTLFullForm
                    templatesData={props.templatesData}
                    templateData={props.templateData}
                    templateText={templateText}
                    setTemplateText={setTemplateText}
                    ertlFormData={ertlFormData}
                    setErtlFormData={setErtlFormData}
                    editedObj={editedObj}
                    setEditedObj={setEditedObj}
                    createTemplate={props.createTemplate}
                    getTemplates={props.getTemplates}
                    createEntry={props.createEntry}
                    updateEntry={props.updateEntry}
                    getEntries={props.getEntries}
                    getEntryPdf={props.getEntryPdf}
                    pdfData={props.pdfData}
                    ertlData={props.ertlData}
                    ertlEntryData={ertlEntryData}
                    setTab={setTab}
                    setParentTab={props.setParentTab}
                    deleteEntry={props.deleteEntry}
                    origin={props.origin}
                    signReview={props.signReview}
                    reload={props.reload}
                    setWait={props.setWait}

                    templateEdited={templateEdited}
                    setTemplateEdited={setTemplateEdited}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}

                    historyDiffData={props.historyDiffData}
                    historyData={props.historyData}
                    getErtlPdf={props.getErtlPdf}
                    pdfHistoryData={props.pdfHistoryData}

                    saveDraft={saveDraft}
                    setSaveDraft={setSaveDraft}

                    updateErtlEntry={props.updateErtlEntry}
                    success_update={props.success_update}
                    error_update={props.error_update}
                    loading_update={props.loading_update}

                    sendErtlReviewLink={props.sendErtlReviewLink}
                    close={close}
                />}

                {openedAskBeforeClose && <Dialog
                            open={openedAskBeforeClose}
                            onClose={() => {
                                setOpenedAskBeforeClose(false);
                            }}
                            maxWidth="md"
                            fullWidth={true}
                        >
                        <div style={{padding:40}} className="text-center">
                            <h4>Are you sure you want to close this window?</h4>
                            <hr />
                            <Button className="mr-20" color="primary" onClick={() => {
                                setSaveDraft(true);
                                setOpenedAskBeforeClose(false);
                                setTimeout(() => {
                                    props.setParentTab(props.origin ? props.origin : '');
                                }, 2000)
                            }}>SAVE AND CLOSE</Button>
                            <Button className="mr-20" color="success" onClick={() => {
                                setOpenedAskBeforeClose(false);
                                props.setParentTab(props.origin ? props.origin : '');
                            }}>CLOSE WITHOUT SAVING</Button>
                            <Button color="white" onClick={() => {
                                setOpenedAskBeforeClose(false);
                            }}>CANCEL</Button>
                        </div>
                    </Dialog>}
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        success_update: state.ertl_entries.success_update,
        error_update: state.ertl_entries.error_update,
        loading_update: state.ertl_entries.loading_update,
        historyData: state.ertl_entries.historyData,
        pdfHistoryData: state.ertl_entries.pdfHistoryData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getErtlApprovers: () => {
            dispatch(actions.getErtlApprovers());
        },
        updateErtlEntry: (data) => {
            dispatch(actions.updateErtl(data));
        },
        sendErtlReviewLink: (id) => {
            dispatch(actions.sendErtlReviewLink(id));
        },
        getErtlPdf: (data) => {
            dispatch(actions.getErtlPdf(data));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ERTLFormWizard);

