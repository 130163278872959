import React from "react";
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import checkPermissions from '../../shared/checkPermissions';
import checkPermissionsActions from '../../shared/checkPermissionsActions';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import Card from "components/Card/Card.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import Closings from "../../containers/CRM/Closings/Closings";
import * as actions from '../../store/actions';

class CRMContactsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: 'View',
      contactData: null,
      formDataObj: null,
      offset: 0,
      rows: 10,
      sort_by: '',
      sort_direction: '',
      filters: {
        first_name: '',
        last_name: '',
        email: '',
        main_phone: '',
        contact_type: ''
      },
      success: '',
      error: '',
      openedAsk: false,
    }
  }

  setOpenedAsk = (openedAsk) => {
    this.setState({ openedAsk });
  }

  setFilters = (field, value) => {
    const newFilters = { ...this.state.filters };
    newFilters[field] = value;
    this.setState({ filters: newFilters });
  }

  setRows = (rows) => {
    this.setState({ rows });
  }

  setOffset = (offset) => {
    this.setState({ offset });
  }

  setFormDataObj = (formDataObj) => {
    this.setState({ formDataObj });
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.offset !== this.state.offset || prevState.rows !== this.state.rows) {
      this.getContactsParams();
    }
    if (prevState.sort_by !== this.state.sort_by || prevState.sort_direction !== this.state.sort_direction) {
      this.getContactsParams();
    }
    if (prevProps.success !== this.props.success) {
      if (!prevProps.success) {
        this.setState({ success: this.props.success });
      }
    }
    if (prevProps.error !== this.props.error) {
      if (!prevProps.error) {
        this.setState({ error: this.props.error });
      }
    }
  }

  clearMessages = () => {
    this.setState({ error: null, success: null });
  }

  closeTab = () => {
    this.setState({ tab: '' });
  }

  openTab = (tab, subTab) => {
    const n = { tab };
    if (subTab) n.subTab = subTab;
    this.setState(n);
  }

  closeForm = () => {
    this.setState({ tab: 'View' })
  }

  componentDidMount() {
  }

  render() {
    
    const crmPermitted = checkPermissions('CRM');
    if (!crmPermitted) {
      return <Redirect to="/dashboard" />;
    }
    const crmAllowed = checkPermissionsActions('CRM');
    

    return (
      <div style={{ marginTop: 20 }}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardHeader color="rose" icon>
                <CardIcon
                  color={this.state.tab === 'View' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({ tab: 'View' });
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>View / Edit Closing</strong>
                </CardIcon>
                <CardIcon
                  color={this.state.tab === 'Add' ? 'success' : 'rose'}
                  onClick={() => {
                    this.setState({ tab: 'Add' });
                  }}
                  className="hovered hovered-shadow"
                >
                  <strong>Add Closing</strong>
                </CardIcon>
              </CardHeader>
              <CardBody>   
                  {<Closings />}
              </CardBody>
            </Card>
          </GridItem>
        </GridContainer>

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
  }
}

const mapDispatchToProps = dispatch => {
  return {

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CRMContactsPage);
