import * as actionTypes from '../actions/actionTypes';
import {
    updateObject
} from '../../shared/utility';
import { get } from 'lodash';

const initialState = {
    loading: false,

    error: null,
    success: null,

    entries: [],


}

// GET_GCI_ENTRIES

const getGciEntriesStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        entries: [],
        loading: true
    })
}

const getGciEntriesSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        entries: action.response,
        loading: false
    })
}

const getGciEntriesFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// DELETE_GCI_ENTRY

const deleteGciEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true,
    })
}

const deleteGciEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading: false,
    })
}

const deleteGciEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false,
    })
}

// CREATE_GCI_ENTRY

const createGciEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true,
    })
}

const createGciEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading: false
    })
}

const createGciEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// UPDATE_GCI_ENTRY

const updateGciEntryStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        loading: true
    })
}

const updateGciEntrySuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        loading: false
    })
}

const updateGciEntryFail = (state, action) => {
    return updateObject(state, {
        error: action.err,
        loading: false
    })
}

// GET_EARNED_COMMISSIONS

const getEarnedCommissionsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null,
        earned_commissions: []
    })
}

const getEarnedCommissionsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Success',
        earned_commissions: action.response
    })
}

const getEarnedCommissionsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

// CREATE_EARNED_COMMISSIONS

const createEarnedCommissionsStart = (state, action) => {
    return updateObject(state, {
        error: null,
        success: null
    })
}

const createEarnedCommissionsSuccess = (state, action) => {
    return updateObject(state, {
        success: 'Successfully created earned commission entry'
    })
}

const createEarnedCommissionsFail = (state, action) => {
    return updateObject(state, {
        error: action.err
    })
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_GCI_ENTRIES_START:
            return getGciEntriesStart(state, action);
        case actionTypes.GET_GCI_ENTRIES_SUCCESS:
            return getGciEntriesSuccess(state, action);
        case actionTypes.GET_GCI_ENTRIES_FAIL:
            return getGciEntriesFail(state, action);

        case actionTypes.CREATE_GCI_ENTRY_START:
            return createGciEntryStart(state, action);
        case actionTypes.CREATE_GCI_ENTRY_SUCCESS:
            return createGciEntrySuccess(state, action);
        case actionTypes.CREATE_GCI_ENTRY_FAIL:
            return createGciEntryFail(state, action);

        case actionTypes.DELETE_GCI_ENTRY_START:
            return deleteGciEntryStart(state, action);
        case actionTypes.DELETE_GCI_ENTRY_SUCCESS:
            return deleteGciEntrySuccess(state, action);
        case actionTypes.DELETE_GCI_ENTRY_FAIL:
            return deleteGciEntryFail(state, action);

        case actionTypes.UPDATE_GCI_ENTRY_START:
            return updateGciEntryStart(state, action);
        case actionTypes.UPDATE_GCI_ENTRY_SUCCESS:
            return updateGciEntrySuccess(state, action);
        case actionTypes.UPDATE_GCI_ENTRY_FAIL:
            return updateGciEntryFail(state, action);

        case actionTypes.GET_EARNED_COMMISSIONS_START:
            return getEarnedCommissionsStart(state, action);
        case actionTypes.GET_EARNED_COMMISSIONS_SUCCESS:
            return getEarnedCommissionsSuccess(state, action);
        case actionTypes.GET_EARNED_COMMISSIONS_FAIL:
            return getEarnedCommissionsFail(state, action);

        case actionTypes.CREATE_EARNED_COMMISSIONS_START:
            return createEarnedCommissionsStart(state, action);
        case actionTypes.CREATE_EARNED_COMMISSIONS_SUCCESS:
            return createEarnedCommissionsSuccess(state, action);
        case actionTypes.CREATE_EARNED_COMMISSIONS_FAIL:
            return createEarnedCommissionsFail(state, action);

        default:
            return state;
    }
};

export default reducer;