import React from "react";
import { Dialog } from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import RSheetsFormElement from "./RSheetsFormElement";
import { useState } from "react";

const RSheetSignRequestForm = (props) => {
    const {
        data,
        setData,
        updateDataObj,
        fieldEdit,
        setFieldEditValue,
        make,
        makeCheckboxes,
        setFieldEdit,
    } = props;

    return (
        <div style={{ padding: 40, maxWidth: 900, margin: "0 auto" }}>
            <div>
                <table style={{ width: "100%" }}>
                    <tbody>
                        <tr>
                            <td className="col-10">
                                <img
                                    src="https://illicre.com/wp-content/themes/zoacres/assets/illi-logo-simple.png"
                                    style={{ width: 60 }}
                                />
                            </td>
                            <td className="col-90">
                                <div className="text-center">
                                    <h3>SIGN REQUEST</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flexBasis: "30%" }}>
                    <span>
                        <strong>illi#:</strong>
                    </span>
                    <span
                        className="col-20"
                        onClick={() => {
                            setFieldEdit("illi_number");
                        }}
                    >
                        {make("illi_number", false)}
                    </span>
                </div>
                <div style={{ flexBasis: "30%" }}>
                    <span>
                        <strong>Date:</strong>
                    </span>
                    <span
                        className="col-20"
                        onClick={() => {
                            setFieldEdit("date");
                        }}
                    >
                        {make("date", false)}
                    </span>
                </div>
                <div style={{ flexBasis: "30%" }}>
                    <span>
                        <strong>Submitted By:</strong>
                    </span>
                </div>
            </div>

            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <th className="col-20">Street Address:</th>
                            <td
                                colSpan={5}
                                onClick={() => {
                                    setFieldEdit("project_address");
                                }}
                            >
                                {make("project_address", false)}
                            </td>
                        </tr>
                        <tr>
                            <th className="col-20">City:</th>
                            <td
                                onClick={() => {
                                    setFieldEdit("city");
                                }}
                            >
                                {make("city", false)}
                            </td>
                            <th className="col-20">State:</th>
                            <td
                                onClick={() => {
                                    setFieldEdit("state");
                                }}
                            >
                                {make("state", false)}
                            </td>
                            <th className="col-20">Zip:</th>
                            <td
                                onClick={() => {
                                    setFieldEdit("zip");
                                }}
                            >
                                {make("zip", false)}
                            </td>
                        </tr>
                        <tr>
                            <th className="col-20">Cross Street:</th>
                            <td colSpan={5}></td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div>
                <div className="col-50 inline-block">
                    <span>Access Necessary?</span>
                    <span
                        className="col-80"
                        onClick={() => {
                            setFieldEdit("access_necessary");
                        }}
                    >
                        {makeCheckboxes("access_necessary")}
                    </span>
                </div>
                <div className="col-50 inline-block">
                    <span>Contact:</span>
                    <span
                        className="col-80"
                        onClick={() => {
                            setFieldEdit("contact");
                        }}
                    >
                        {make("contact", false)}
                    </span>
                </div>
            </div>

            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <th className="col-20">Order Type:</th>
                            <td
                                className="col-80"
                                onClick={() => {
                                    setFieldEdit("order_type");
                                }}
                            >
                                {makeCheckboxes("order_type")}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div>
                <h4>MAINTENANCE DESCRIPTION / SPECIAL INSTRUCTIONS</h4>
                <div className="the-table">
                    <span
                        onClick={() => {
                            setFieldEdit("maintenance_description");
                        }}
                    >
                        {make("maintenance_description", false)}
                    </span>
                </div>
            </div>

            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <th className="col-20">Sign Size:</th>
                            <td
                                onClick={() => {
                                    setFieldEdit("sign_size");
                                }}
                            >
                                {make("sign_size", false)}
                            </td>
                            <th className="col-20">Location:</th>
                            <td
                                onClick={() => {
                                    setFieldEdit("location");
                                }}
                            >
                                {make("location", false)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div className="the-table">
                <table>
                    <tbody>
                        <tr>
                            <th className="col-20">
                                Reason for sign request or change:
                            </th>
                            <td
                                className="col-80"
                                onClick={() => {
                                    setFieldEdit("sign_request_reason");
                                }}
                            >
                                {make("sign_request_reason", false)}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <br />

            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ flexBasis: "30%" }}>
                    <span>Date: </span>
                    <span
                        className="col-20"
                        onClick={() => {
                            setFieldEdit("date");
                        }}
                    >
                        {make("date", false)}
                    </span>
                </div>
                <div style={{ flexBasis: "30%" }}>
                    <span>Todd Nathanson Signature:</span>
                </div>
            </div>
        </div>
    );
};

export default RSheetSignRequestForm;
