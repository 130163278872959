import React from "react";
import { useState, useEffect, useRef } from "react";
import CompaniesList from "./CompaniesList";
import CompaniesAskModal from "./CompaniesAskModal";
import CompaniesForm from "./CompaniesForm";
import { saveContactNow } from '../Contacts/ContactsLogic';
import Notification from '../../../components/Notification/Notification.js';
import NotificationFixedTop from '../../../components/Notification/NotificationFixedTop';
import Dialog from "@material-ui/core/Dialog";
import CustomDialogClose from "components/CustomDialog/CustomDialogClose";
import { set } from "lodash";

const Companies = (props) => {
	const [openedAskModal, setOpenedAskModal] = useState(false);
	const [openedForm, setOpenedForm] = useState(null);
	const [companyData, setCompanyData] = useState(null);
	const [action, setAction] = useState(null);
	const [notification, setNotification] = useState(false);
	const [notification2, setNotification2] = useState('');
	const [openedAsk, setOpenedAsk] = useState(false);
	const [closeOnUpdate, setCloseOnUpdate] = useState(false);
	const [saveCompaniesFormValues, setSaveCompaniesFormValues] = useState({
		cfDataObj: {},
		cfAddrData: {},
		cfPartnersData: {},
		madeChanges: false,
	});

	const prev_company_data = usePrevious(props.companyData);
	const prev_success = usePrevious(props.success);

	function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }
	
	const openForm = (action, cd) => {
		if (cd) setCompanyData(cd);
		setOpenedForm(true);
		setAction(action);
	};

	const closeForm = () => {
		setCompanyData(null);
		setOpenedForm(null);
		setAction(null);
		
		props.closeFrom();
	};

	const openAskModal = (cd) => {
		setOpenedAskModal(true);
		setCompanyData(cd);
	};

	const closeAskModal = (cd) => {
		setOpenedAskModal(false);
		setCompanyData(cd);
	};

	useEffect(() => {
		if (props.location_search) {
			// if query parameter has company_id load and open the company
			const params = new URLSearchParams(props.location_search);
			const company_id = params.get('company_id');
			if (company_id) {
				props.getCompany(company_id);
			}
		}
		props.getCompanies();
		// props.clearMessages();
	}, []);

	useEffect(() => {
		// Autoloads company for Edit mode after creation
		if ((props.companyData !== prev_company_data) && props.companyData) {
			if (typeof props.companyData === 'object') {
				const url_arr = document.URL.split('?');
				if (url_arr.length > 1) {
					const url = `?${url_arr[1]}`;
					const params = new URLSearchParams(url);
					const company_id = params.get('company_id')
					if (company_id && props.companyData.companyData) {
						if (parseInt(company_id) == props.companyData.companyData.id) {
							openForm('Edit', props.companyData.companyData);
							// change URL, remove company_id query param
							window.history.replaceState(null, null, "/crm/companies");
							setNotification2('Successfully added company.');
						}
					}
				}
			}
		}
	}, [props.companyData]);

	useEffect(() => {
		if (!openedForm) {
			props.getCompanies();
			// props.clearMessages();
		}
	}, [openedForm]);

	useEffect(() => {
		if (props.success === 'Successfully updated company' && closeOnUpdate) {
			setCloseOnUpdate(false);
			closeForm();
		}
	}, [props.success]);

	const dataIsComplete = companyData
		? (companyData.name)
			? true
			: false
		: false;

	return (
		<div>
			{!openedForm && (
				<CompaniesList
					data={props.companiesData}
					companiesCount={props.companiesCount}
					agentData={props.agentData} 
					agentDataCount={props.agentDataCount}
					getCompanies={props.getCompanies}
					getCompany={props.getCompany}
					deleteCompany={props.deleteContact}
					openForm={openForm}
					openAskModal={openAskModal}
					setRows={props.setRows}
					setCompanyData={setCompanyData}
					setOffset={props.setOffset}
					rows={props.rows}
					offset={props.offset}
					sortDirection={props.sortDirection}
					sortBy={props.sortBy}
					filters={props.filters}
					setFilters={props.setFilters}
					resetFilters={props.resetFilters}
					setSort={props.setSort}
					loading={props.loading}
				/>
			)}
			{dataIsComplete && openedForm && (
				<Dialog
					open={dataIsComplete && openedForm}
					keepMounted
					maxWidth={false}
					fullWidth={true}
					fullScreen={true}
				>
					<div className="dialog-wrapper">
						<CompaniesForm
							action={action}
							closeForm={closeForm}
							openForm={openForm}
							openAskModal={openAskModal}
							success={props.success}
							updateCompany={props.updateCompany}
							setSaveCompaniesFormValues={setSaveCompaniesFormValues}
							deleteCompany={props.deleteCompany}
							error={props.error}
							companyData={companyData}
							loading={props.loading}
							formDataObj={props.formDataObj}
							setFormDataObj={props.setFormDataObj}
							setOpenedAsk={setOpenedAsk}
							showCloseBtn={true}
							notification={notification}
							setNotification={setNotification}
							createCrmFile={props.createCrmFile}
							deleteCrmFile={props.deleteCrmFile}
							getCrmFileList={props.getCrmFileList}
							getCrmFile={props.getCrmFile}
							fileList={props.fileList}
							downloadedFile={props.downloadedFile}
							contactError={props.contactError}
                      		contactSuccess={props.contactSuccess}
                      		contactLoading={props.contactLoading}
							contactOptions={props.contactOptions}
						/>
					</div>
				</Dialog>
			)}
			{openedAskModal && (
				<CompaniesAskModal
					open={openedAskModal}
					close={closeAskModal}
					closeForm={closeForm}
					companyData={companyData}
					deleteCompany={props.deleteCompany}
					getCompanies={props.getCompanies}
					success={props.success}
					error={props.error}
					clearMessages={props.clearMessages}
					notification={notification}
					setNotification={setNotification}
					setOpenedForm={setOpenedForm}
				/>
			)}
			{openedAsk && (
				<CustomDialogClose
					ok_go={() => {
						closeForm();
					}}
					ok_save={() => {
						props.updateCompany(saveCompaniesFormValues.cfDataObj);
						setCloseOnUpdate(true);
					}}
					open={openedAsk}
					close={() => {
						setOpenedAsk(false);
					}}
				/>
			)}
			{notification !== false &&
				(
					<Notification
						open={notification ? true : false}
						message={<h4>{notification}</h4>}
						close={() => {
							
							setNotification(false);
							if (notification === "Successfully created company.") {
								closeForm();
							};
						}}
					/>
				)}

				{notification2 &&
					(
						<NotificationFixedTop
							open={notification2 ? true : false}
							message={<div>{notification2}</div>}
							close={() => {
								setNotification2('');
							}}
							autoClose={3000}
						/>
					)}
		</div>
	);
};

export default Companies;
