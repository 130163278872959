import { IconButton } from '@material-ui/core';
import Button from 'components/CustomButtons/Button.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Table from 'components/Table/Table';
import { findIndex, get, isEmpty } from 'lodash';
import React, { useState } from 'react';
import { clone } from 'shared/utility';
import CustomDialogWithContact from '../../../components/CustomDialog/CustomDialogWithContact';
import CustomDialogWithUser from '../../../components/CustomDialog/CustomDialogWithUser';

const Step4 = (props) => {
	const { agentList, setAgentList, formData, setFormData, updateDataObj } = props;

	const [openedDialog, setOpenedDialog] = useState(false);

	const openDialog = () => setOpenedDialog(true);
	const closeDialog = () => setOpenedDialog(false);

	const removeAgent = (agent) => {
		let newAgentList = clone(agentList);
		newAgentList = newAgentList.filter(a => agent.id !== a.id);
		setAgentList(newAgentList);
	}

	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<Button color='primary' onClick={openDialog}>
						SELECT AGENTS
					</Button>
					<hr />
				</GridItem>
				<GridItem xs={12} sm={12} md={12}>
					{!isEmpty(agentList) && (
						<React.Fragment>
							<h3 className='text-center'>Agent List Data</h3>
							<GridContainer>
								{agentList.map((agent) => {
									return (
										<GridItem xs={12} sm={12} md={6} key={agent.id}>
											<div style={{ lineHeight: 1.5, border: '1px solid black', padding: 20 }} className='mt-20'>
												<IconButton
													onClick={() => {removeAgent(agent)}}
													style={{ display: 'block', marginLeft: 'auto', marginBottom: '20px' }}
												>
													<i className='fas fa-trash-alt red-text'></i>
												</IconButton>
												<Table
													tableData={[
														['Name', 'name.fullName'],
														['Email', 'primaryEmail'],
													].map((m) => {
														return [
															<div style={{ textAlign: 'right' }}>
																<strong>{m[0]}</strong>
															</div>,
															get(agent, m[1]),
														];
													})}
												/>
											</div>
										</GridItem>
									);
								})}
							</GridContainer>
						</React.Fragment>
					)}
				</GridItem>
			</GridContainer>

			<CustomDialogWithUser
				open={openedDialog}
				close={closeDialog}
				title='Select Agent'
				label='Search for Agent'
				choose={(data) => {
					const index = findIndex(agentList, { id: data.id });
					if (index === -1) {
						const newAgentList = clone(agentList);
						newAgentList.push(data);
						setAgentList(newAgentList);
					}
					closeDialog();
				}}
			/>
		</div>
	);
};

export default Step4;
