import { fields } from './TREntriesModel';
import { cloneJ, getCompanyDBAData, formatDollar } from 'shared/utility';
import { createAddressObject } from 'containers/CRM/Properties/PropertiesLogic';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';

export const removeSplitter = (word) => {
    return word.split('_|_')[0];
}

const addBreaks = (string) => {
    if (!string) return null;
    if (typeof string === 'number') return null;
    // adds line breaks 
    if (string.indexOf('<>') !== -1) {
        
    }
    if (string === '<br>') return <div style={{marginTop:20}}></div>;
    return <span 
        dangerouslySetInnerHTML={{__html: string.replace(/[\r\n]/gm, '<br />')}}
    />;
}

export const dontSaveArr = ['my_completed','all_completed','pending_signature'];

export const sectionNames = {
    1: 'Property Information',
    2: 'Tenants',
    3: 'Other Info'
}

export const statusDescriptions = {
    1: 'Saved to Draft',
    2: 'Submitted to Tenants for Signature',
    3: 'Signed by Tenant',
    4: 'Submitted to Approver Level 1',
    5: 'Rejected by Approver Level 1',
    6: 'Rejected by Tenant',
    7: 'Pending Final Approval',
    8: 'Rejected by Final Approver',
    9: 'Completed',

    // 10: 'Submitted to Approver Level 1',
    // 11: 'Approved, Sent for Docusign Signature',
    // 12: 'Pending Final Signature',
    // 13: 'Completed Docusign',
    // 14: 'Rejected Docusign by Agent',
    // 15: 'Initialed by Agent'
}

const obj = {};
Object.keys(statusDescriptions).forEach(key => {
    obj[statusDescriptions[key]] = key;
})
export const statusDescriptionsDict = obj;

const findLabel = (field) => {
    let fieldLabel = '';
    for (let i = 0; i < Object.keys(fields).length; i++) {
        const f = Object.keys(fields)[i];
        if (f === field) {
            if (fields[f].label) {
                fieldLabel = fields[f].label;
                break;
            }
        }
    }
    return fieldLabel;
}

export const isDateDifferenceCorrect = (data) => {
    let correct = false;
    if (data.period_commencing && data.period_ending_on) {
        const start = moment(data.period_commencing, 'MM/DD/YYYY').valueOf();
        const end = moment(data.period_ending_on, 'MM/DD/YYYY').valueOf();
        if (start < end) {
            correct = true;
        }
    }
    return correct;
}

export const generateSections = (data) => {
    // checking if data is complete
	let sections = [];
	Object.values(sectionNames).forEach((v, index) => {
		if (data) {
			let complete = true;
            let errors = [];
            if (v === 'Property Information') {
                // Required to have a value
                [
                    'date','type_of_property','location',
                ].forEach(field => {
                    if (!data[field]) {
                        complete = false;
                        const fieldLabel = findLabel(field);
                        errors.push(`${fieldLabel} is required`);
                    }
                });
            }
            if (v === 'Tenants') {
                if (data.tenants.length === 0) {
                    complete = false;
                    errors.push('Tenants are required');
                } else {
                    const emails = data.tenants.map(c => c.email).filter(c => {
                        if (c) return true;
                    });
                    console.log('emails', emails, data.tenants)
                    if (emails.length !== data.tenants.length) {
                        complete = false;
                        errors.push('All Tenants should have an email');
                    }
                }
            }

            if (v === 'Other Info') {
                if (!data.period_ending_on || !data.period_commencing) {
                    errors.push('Period dates required');
                    complete = false;
                }
                if (data.period_ending_on && data.period_commencing) {
                    const correct = isDateDifferenceCorrect(data);
                    if (!correct) {
                        errors.push('Please make sure the dates are correct');
                        complete = false;
                    }
                }
                if (!data.final_approver_name || !data.final_approver_email || !data.final_approver_position) {
                    errors.push('Final Approver/Signer is required');
                    complete = false;
                }
            }

			sections.push({
				name: v,
				complete,
                errors
			});
		}
	});
    console.log('sections', sections);
	return sections;
}

export const chooseContactCompanyObj = (obj) => {
    const { chosen, updateDataObj, dataObj, queryFor, chosenDBA } = obj;
    console.log('chosen', chosen);
    let chosenType = null;
    if (chosen.first_name) chosenType = 'contact';
    if (chosen.company_data && chosen.signers) {
        chosenType = 'company';
    } 
    
    if (queryFor.type === 'tenants') {
        //  ONLY show contacts or companies that have -  illi has exclusive right to represent = yes.
        if (chosenType === 'contact') {
            const contact_data = chosen;
            const obj = {
                contact_id: contact_data.id,
                first_name: contact_data.first_name,
                last_name: contact_data.last_name,
                email: contact_data.email,
                dba_id: chosenDBA ? chosenDBA.id : '', 
                dba_name: chosenDBA ? chosenDBA.name : '',
                dba_active: chosenDBA ? chosenDBA.id ? 1 : '' : '',
                phone: contact_data.main_phone,
                address: '',
                address2: '',
                city: '',
                state: '',
                zip: '',
                title: contact_data.title
            };
            if (chosen.addresses) {
                for (let x = 0; x < chosen.addresses.length; x++) {
                    const ca = chosen.addresses[x];
                    if (ca.type === 'M') {
                        obj.contact_address_id = ca.id;
                        obj.address = ca.street1;
                        obj.address2 = ca.street2;
                        obj.city = ca.city;
                        obj.state = ca.state;
                        obj.zip = ca.zip;
                    }
                }
            }
            updateDataObj(queryFor.type, dataObj[queryFor.type].concat([
                obj
            ]))
        } else if (chosenType === 'company') {
            const contact_data = chosen.signers[0] ? chosen.signers[0] : null;
            const company_data = chosen.company_data;
            const dba_id = chosen.dba_id;
            const dba_data = chosen.dba_data ? 
                chosen.dba_data.length > 0 ? 
                    getCompanyDBAData(dba_id, chosen.dba_data) 
                : 
                    null 
            : 
                null;
            if (contact_data) {
                const company_contact_id = get(contact_data, 'company_dats.id');
                const company_contact_email = get(contact_data, 'company_dats.email');
                const company_contact_title = get(contact_data, 'company_dats.title');
                const company_contact_phone = get(contact_data, 'company_dats.phone');
                const obj = {
                    company_id: company_data.id,
                    contact_id: contact_data.id,
                    company_contact_id: company_contact_email ? company_contact_id : '',

                    // COMPANY DATA
                    name: company_data.name,
                    address: company_data.mailing_address1,
                    address2: company_data.mailing_address2,
                    city: company_data.mailing_city,
                    state: company_data.mailing_state,
                    zip: company_data.mailing_zip,

                    // CONTACT DATA
                    first_name: contact_data.first_name,
                    last_name: contact_data.last_name,

                    // COMPANY CONTACT
                    email: company_contact_email ? company_contact_email : contact_data.email,
                    title: company_contact_title,
                    phone: company_contact_phone,
                    
                    commission_percent: '',
                    commission_flat_amt: '',

                    dba_id: dba_id ? dba_id : '', 
                    dba_name: dba_data ? dba_data.name : '',
                    dba_active: dba_id ? 1 : '',
                };

                updateDataObj(queryFor.type, dataObj[queryFor.type].concat([
                    obj
                ]))
            }
        }
    }
}

export const createTenantName = (data) => {
    let name = `${data.first_name ? data.first_name : ''} ${data.last_name ? data.last_name : ''}`;
    if (data.name) {
        name = name + ' of ' + data.name;
    }
    if (data.dba_name && data.dba_active) {
        name = name + ' DBA ' + data.dba_name;
    }
    return name;
}

export const createDocusignObj = (formData) => {
    const json_data = cloneJ(formData);
    json_data.thankyouTemplate = generatePDFThankYou(formData);
    const newObj = {
        submitDocusign: true, 
        data: JSON.stringify(json_data),
        status: statusDescriptionsDict['Submitted to Tenants for Signature'],
        htmlTemplate: generatePDF(formData),
        tabs: {
            [formData.final_approver_email]: {
                signHereTabs: [
                    {
                        anchorString:
                            "**_final_approver_signature_**",
                    },
                ],
                dateSignedTabs: [
                    {
                        anchorString:
                            "**_final_approver_date_**",
                    },
                ],
            },
        },
    };
    if (formData.tenants) {
        if (formData.tenants.length > 0) {
            formData.tenants.forEach(
                (oo, i) => {
                    newObj.tabs[oo.email] = {
                        signHereTabs: [
                            {
                                anchorString: `**_tenants_${i}_signature_**`,
                            },
                        ],
                        dateSignedTabs: [
                            {
                                anchorString: `**_tenants_${i}_signature_date_**`,
                            },
                        ]
                    };
                }
            );
        }
    }
    return newObj;
}

export const getDifferences = (original, newstring, shouldShowIt, author) => {
    // 
    // 
    if (original === newstring) return addBreaks(newstring);
    // make sure duplicate words are tracked accurately
    let instances1 = {};
    let instances2 = {};
    console.log('orig', original);
    const o_arr = original ? original
        .split(/[\n\r\s]/gm)
        .map(r => {
            if (!r) return '<br>';
            return r;
        })
        .map((r, i) => {
            if (!instances1.hasOwnProperty(r)) {
                instances1[r] = [1];
                return `${r}_|_${0}`;
            } else {
                instances1[r].push(1);
                return `${r}_|_${instances1[r].length - 1}`;
            }

        }) : [];
    const n_arr = newstring ? newstring
        .split(/[\n\r\s]/gm)
        .map(r => {
            if (!r) return '<br>';
            return r;
        })
        .map((r, i) => {
            if (!instances2.hasOwnProperty(r)) {
                instances2[r] = [1];
                return `${r}_|_${0}`;
            } else {
                instances2[r].push(1);
                return `${r}_|_${instances2[r].length - 1}`;
            }
        }) : [];
    let newarr = [];
    let erased = [];
    n_arr.forEach(n => {
        const o_arr2 = o_arr.map(m => {
            return m;
            //return m.replace(/[\r\n]/gm, '');
        })
        if (o_arr2.indexOf(n) === -1) {
            newarr.push(n);
        }
    })
    o_arr.forEach(o => {
        const n_arr2 = n_arr.map(m => {
            return m;
            //return m.replace(/[\r\n]/gm, '');
        })
        if (n_arr2.indexOf(o) === -1) {
            erased.push(o);
        }
    })
    const sttr = Object.keys(instances1).join(' ');
    // 
    // 
    // 
    // 
    // 
    return <span>
        {n_arr.map((nn, i) => {
            const addedStyles = { color: 'black' };
            const nnew = nn;// .replace(/[\r\n]/gm, '');
            if (newarr.indexOf(nnew) !== -1 && shouldShowIt) {
                addedStyles.color = author ? 
                                        author === 'Approver' ? 
                                            '#147ae3' 
                                        : 
                                            'red' 
                                    : 
                                        'red';
                addedStyles.fontWeight = 'bold';
                addedStyles.fontSize = 19;
                addedStyles.textDecoration = 'underline';
            }
            return <span key={`${nn}-s-${i}`} style={addedStyles} className="n_arr">{addBreaks(removeSplitter(nn))} </span>
        })}
        {(erased.length > 0 && shouldShowIt) &&
            <span><br />
                <span style={{ display: 'inline-block', padding: 5, backgroundColor: '#ffecec', marginTop: 5 }}>
                    {o_arr.map((nn, i) => {
                        const addedStyles = { color: 'black' };
                        if (erased.indexOf(nn) !== -1 && (erased.length > 0 && shouldShowIt)) {
                            addedStyles.color = 'red';
                            addedStyles.fontWeight = 'bold';
                        }
                        return <span key={`${nn}-n-${i}`} style={addedStyles} className="o_arr">{addBreaks(removeSplitter(nn))} </span>
                    })}
                </span>
            </span>}
    </span>
}

const styles = `
<style>
.row {
    display: flex;
}
body {
    font-size: 10px;
    font-family: 'Arial', sans-serif;
}
td {
    vertical-align: top;
}
.list-unstyled {
    list-style: none;
}
.grayed {
    -webkit-filter: grayscale(1);
}
.mr-20 {
    margin-right: 20px;
}
.mr-10 {
    margin-right: 10px;
}
.ml-10 {
    margin-left: 10px;
}
.col-5 {
    width: 5%;
}
.col-10 {
    width: 10%;
}
.col-15 {
    width: 15%;
}
.col-20 {
    width: 20%;
}

.col-30 {
    width: 30%;
}

.col-40 {
    width: 40%;
}

.col-50 {
    width: 50%;
    flex: 0 0 auto;

.col-60 {
    width: 60%;
}

.col-70 {
    width: 70%;
}

.col-80 {
    width: 80%;
}

.col-90 {
    width: 90%;
}

.col-100 {
    width: 100%;
}

.b-t {
    border-top: 1px solid black;
}

.b-b {
    border-bottom: 1px solid black;
}

.b-r {
    border-right: 1px solid black;
}

.b-l {
    border-right: 1px solid black;
}

.the-table table {
    width: 100%;
}

.the-table table, .the-table th, .the-table td {
    border: 1px solid #878787;
    border-collapse: collapse;
    font-size: 10px;
}

.the-table td {
    padding: 5px;
}

.no-bt {
    border-top: 1px solid rgba(0,0,0,0) !important;
}
.no-bb {
    border-bottom: 1px solid rgba(0,0,0,0) !important;
}
.the-table .no-br {
    border-right: 1px solid rgba(0,0,0,0) !important;
}
.the-table table {
    table-layout: fixed;
}
.the-table .no-bl {
    border-left: 1px solid rgba(0,0,0,0) !important;
}
.bold {
    font-weight: bold;
}
.text-center {
    text-align: center;
}
.underlined {
    border-bottom: 1px solid #ccc;
    display: inline-block;
}
.bordered-thin {
    border: 1px solid #ccc;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
}
.pagebreak {
    page-break-after: always;
}
.small-table-text td,
.small-table-text th {
    font-size: 9px;
}
.mt-10 {
    margin-top: 10px;
}
.white-text {
    color: white !important;
}
.mb-25 {
    margin-bottom: 25px;
}
.min-width {
    min-width: 120px;
}
.bold {
    font-weight: bold !important;
}
.boxed {
    border: 1px solid black !important;
    padding: 30px;
}
.text-center {
    text-align: center;
}
</style>
`;

export const generatePDFThankYou = (dataObj) => {
    const textInputs = {};
    if (dataObj) {
        if (dataObj.templateData) {
            Object.keys(dataObj.templateData).forEach(field => {
                textInputs[field] = dataObj.templateData[field] ? dataObj.templateData[field] : '';
            });
        }
    }
    const formInput = (field) => {
        let val = '';
        if (dataObj[field]) {
            val = dataObj[field];
        }
        return val;
    }
    const tenants = dataObj.tenants ? dataObj.tenants : [];
    const tenant = tenants[0] ? tenants[0] : null;
    const dateNow = moment().format('MM/DD/YYYY');
    return `
        <html>
            <head>
                ${styles}
            </head>
            <body>
                <div>
                    <img
                        src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                        style="width:100px;height:110px"
                    />
                    <br />
                    <div style="font-size:12px;line-height:18px">
                        <p>${formInput("thankyou_date") ? formInput('thankyou_date') : dateNow}</p>
                        ${tenant ? `<p>
                            ${createTenantName(tenant)}<br />
                            ${tenant.address}<br />
                            ${tenant.city}, ${tenant.state} ${tenant.zip}<br />
                        </p>` : ``}
                    </div>
                </div>

                <div
                    style="font-size:12px;line-height:18px;margin-top:50px"
                >
                    <p><strong>${textInputs.ty_letter_page_1_title}</strong></p>
                    <p>
                        ${textInputs.ty_letter_page_1_dear} 
                        <span>${tenants.map(t => t.first_name).join(', ')}</span>
                    </p>
                    <p>${textInputs.ty_letter_page_1_body1}</p>
                    <p>${textInputs.ty_letter_page_1_body2}</p>
                    <p>${textInputs.ty_letter_page_1_body3}</p>
                    <p>${textInputs.ty_letter_page_1_body4} ${formInput('final_approver_email')}${textInputs.ty_letter_page_1_body5}</p>
                    <p>${textInputs.ty_letter_page_1_closing}</p>
                    <p>${textInputs.ty_letter_page_1_closing_company}</p>
                    <div style="height:50px"></div>

                    <span style="color:white !important;border-bottom: 1px solid #ccc">**_final_approver_signature_**</span><br>
                    <span class="">${formInput('final_approver_name')}</span>
                    <br/>
                    ${formInput('final_approver_position')}

                    <div style="margin-top:100px;text-align:center">
                        <div>
                            <span>${textInputs.ty_letter_page_1_address1}</span> &nbsp;
                            <span>${textInputs.ty_letter_page_1_address2}</span> &nbsp;
                            <span>${textInputs.ty_letter_page_1_city_state_zip}</span><br>
                            <span>${textInputs.ty_letter_page_1_phone}</span> &nbsp;
                            <span>${textInputs.ty_letter_page_1_fax}</span> &nbsp;<br>
                            <span>${textInputs.ty_letter_page_1_website}</span>
                        </div>
                    </div>
                </div>
            </body>
        </html>
    `;
}

export const generatePDF = (dataObj) => {
    const textInputs = {};
    if (dataObj) {
        if (dataObj.templateData) {
            Object.keys(dataObj.templateData).forEach(field => {
                textInputs[field] = dataObj.templateData[field] ? dataObj.templateData[field] : '';
            });
        }
    }
    const formInput = (field) => {
        let val = '';
        if (dataObj[field]) {
            val = dataObj[field];
        }
        return val;
    }
    const tenants = dataObj.tenants ? dataObj.tenants : [];
    return `
<html>
    <head>
        ${styles}
    </head>
<body>
    <div>
        <img
            src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
            style="width:80px;"
        />
        <br />
        <div style="text-align:center">
            <div style="text-align:center">${textInputs.main_page_1_address}</div>
        </div>
        <div style="text-align:center">
            <h4 
                style="font-size:16px;margin:25px;text-align:center"
            ><strong>${textInputs.main_page_1_title}</strong></h4>
        </div>

        <div 
            style="font-size:12px;line-height:18px;text-align:justify"
        >
            <p class="mb-25">
                <span class="underlined">
                    ${tenants.map(t => {
                        return createTenantName(t);
                    }).join(', ')}
                </span> ${textInputs.main_page_1_opening} <strong>${formInput('period_commencing')}</strong> ${textInputs.main_page_1_opening_2} <strong>${formInput('period_ending_on')}</strong> ${textInputs.main_page_1_opening_3}</p>
            <p class="mb-25">${textInputs.main_page_1_property}</p>
            <p class="mb-25">${textInputs.main_page_1_req1} <strong>${formInput('type_of_property')}</strong></p>
            <p class="mb-25">${textInputs.main_page_1_req2} <strong>${formInput('location')}</strong></p>
            <p class="mb-25">${textInputs.main_page_1_req3}</p>
            <p class="mb-25">${textInputs.main_page_1_req4}</p>
            <p class="mb-25">${textInputs.main_page_1_req5}</p>
            <p class="mb-25">${textInputs.main_page_1_req6}</p>
            <p class="mb-25">${textInputs.main_page_1_req7}</p>
        </div>
        <div style="page-break-after:always;"></div>

        <img
            src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
            style="width:100px;height:110px"
        />
        <br />
        <div class="text-center" style="text-align:center">
            <span style="text-align:center">${textInputs.main_page_1_address}</span>
        </div>
        <div style="margin-top:40px;">
            <div class="row">
                <div class="col-50">
                    <div style="padding:30px; margin-right:20px">
                        <p>
                            ${textInputs.main_page_2_broker}<br />
                            ${textInputs.main_page_2_broker_1}
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_by} <span style="color:white;border-bottom:1px solid #ccc;min-width:140px">**_final_approver_signature_**  **_final_approver_date_**</span>
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_name_printed} <strong style="border-bottom:1px solid #ccc;min-width:140px">${formInput('final_approver_name')}</strong>
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_title} <strong style="border-bottom:1px solid #ccc;min-width:140px">${formInput('final_approver_position')}</strong>
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_address} <span style="border-bottom:1px solid #ccc;min-width:140px">${textInputs.main_page_2_broker_address_1}</span>
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_city_state_zip} <span style="border-bottom:1px solid #ccc;min-width:140px">
                                ${textInputs.main_page_2_broker_city_state_zip_1}
                                </span>
                        </p>
                        <p>
                            ${textInputs.main_page_2_broker_phone} <span style="border-bottom:1px solid #ccc;min-width:140px">
                                ${textInputs.main_page_2_broker_phone_1}
                            </span>
                        </p>
                    </div>
                </div>
                ${tenants.filter((f,i) => {
                    if (i === 0) return true;
                }).map((t,i) => {
                    const tenantName = createTenantName(t);
                    return `<div class="col-50">
                        <div style="padding:30px">
                            <p>
                                ${textInputs.main_page_2_tenant}<br />
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_by} <span style="color:white;border-bottom:1px solid #ccc;min-width:140px">**_tenants_${i}_signature_**  **_tenants_${i}_signature_date_**</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_name_printed} <span style="border-bottom:1px solid #ccc;min-width:140px">${tenantName}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_title} <span style="border-bottom:1px solid #ccc;min-width:140px">${t.title ? t.title : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_address} <span style="border-bottom:1px solid #ccc;min-width:140px">${t.address ? t.address : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_city_state_zip} 
                                <span style="border-bottom:1px solid #ccc;min-width:140px">${t.city ? t.city : ''}, ${t.state ? t.state : ''} ${t.zip ? t.zip : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_phone} 
                                <span style="border-bottom:1px solid #ccc;min-width:140px">${t.phone ? t.phone : ''}</span>
                            </p>
                        </div>
                    </div>`;
                }).join('')}
            </div>
            <div class="row">
                ${tenants.filter((f,i) => {
                    if (i > 0) return true;
                }).map((t,i) => {
                    const tenantName = createTenantName(t);
                    return `<div class="col-50">
                        <div style="padding:30px">
                            <p>
                                ${textInputs.main_page_2_tenant}<br />
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_by} <span style="color:white;border-bottom:1px solid #ccc;min-width:140px">**_tenants_${i}_signature_**  **_tenants_${i}_signature_date_**</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_name_printed} <span style="border-bottom:1px solid #ccc;min-width:140px">${tenantName}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_title} <span style="border-bottom:1px solid #ccc;min-width:140px">${t.title ? t.title : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_address} <span style="border-bottom:1px solid #ccc;min-width:140px">${t.address ? t.address : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_city_state_zip} 
                                <span style="border-bottom:1px solid #ccc;min-width:140px">${t.city ? t.city : ''}, ${t.state ? t.state : ''} ${t.zip ? t.zip : ''}</span>
                            </p>
                            <p>
                                ${textInputs.main_page_2_tenant_phone} 
                                <span style="border-bottom:1px solid #ccc;min-width:140px">${t.phone ? t.phone : ''}</span>
                            </p>
                        </div>
                    </div>`;
                }).join('')}
            </div>
            <br />
            <br />
            <p>${textInputs.main_page_2_legal}</p>
        </div>
    </div>
  </body>
</html>
    `;
}