import React from "react";
import GridContainer from "../../../components/Grid/GridContainer";
import GridItem from "../../../components/Grid/GridItem";
import CustomInput from "../../../components/CustomInput/CustomInputValidate";
import { clone, parseFloatString, round2dec, twoDecimals } from "../../../shared/utility";
import { calculateBrokerCommission } from "./BrokersLogic";

const CommissionDistribution = props => {
    const { data, setData, 
        label_one, label_two, 
        field_one, field_two  // lessee lessor
    } = props;

    const total = (data[field_one] ? parseFloat(data[field_one]) : 0) + 
        (data[field_two] ? parseFloat(data[field_two]) : 0);
    return <>
        <GridContainer>
            <GridItem xs={12} sm={3} md={3}>
                <h3>Commission Distribution</h3>
                <div className="group-list">
                    <div className="bordered-list">
                        <ul>
                            <li>
                                <CustomInput
                                    label={label_one}
                                    value={data[field_one]}
                                    onChange={(e) => {
                                        const newData = clone(data);
                                        newData[field_one] = e.target.value;
                                        setData(newData);
                                    }}
                                    validate={[]}
                                    endAdornment="%"
                                    isCurrency={true}
                                />
                            </li>
                            <li>
                                <CustomInput
                                    label={label_two}
                                    value={data[field_two]}
                                    onChange={(e) => {
                                        const newData = clone(data);
                                        newData[field_two] = e.target.value;
                                        setData(newData);
                                    }}
                                    validate={[]}
                                    endAdornment="%"
                                    isCurrency={true}
                                />
                            </li>
                            <li>
                                <div className="mb-20">
                                    TOTAL : <span style={{color:total === 100 ? 'green' : 'red'}}>{round2dec(total)}</span> %
                                </div>
                                {total !== 100 && <div><em>Total must be 100%</em></div>}
                            </li>
                        </ul>
                    </div>
                </div>
            </GridItem>
        </GridContainer>
    </>
}

export default CommissionDistribution;

export const CommissionDistributionFull = props => {
    const { 
        name, 
        hasLessorAssociate,
        hasLesseeAssociate,
        data
    } = props;

    if (name === 'lessor') {
        const renderCommissionsLessor = () => {
            let tot = 0;
            if (data.commission_splits_agent) {
                data.commission_splits_agent.forEach(csa => {
                    tot += parseFloatString(csa.Percentage)
                })
            }
    
            return (
                <tbody>
                    <tr>
                        <td className="col-40">
                            <strong>Name</strong>
                        </td>
                        <td className="col-60">
                            <strong>Percentage</strong>
                        </td>
                    </tr>
                    {data.commission_splits_agent.map((c, i) => {
                        return (
                            <tr key={`${i}-agent`}>
                                <td className="col-40">{c.Name}</td>
                                <td className="col-60">{twoDecimals(parseFloatString(c.Percentage))} %</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="col-40">
                            <strong>Total</strong>
                        </td>
                        <td className="col-60">{twoDecimals(tot)} %</td>
                    </tr>
                </tbody>
            );
        };
        
        const renderCommissionsOtherLessor = () => {
            return (
                <tbody>
                    <tr>
                        <td className="col-40">
                            <strong>Name</strong>
                        </td>
                        <td className="col-30">
                            <strong>Percentage</strong>
                        </td>
                        <td className="col-30">
                            <strong>Commission Value</strong>
                        </td>
                    </tr>
                    <tr>
                        <td className="col-40">Lessor Referral</td>
                        <td className="col-30"></td>
                        <td className="col-30">
                            {data.referral_firm_fee_amount_choice === "Value"
                                ? data.referral_firm_fee_amount
                                : data.referral_firm_fee_amount_percentage + " %"}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-40">Lessor - Outside Broker(s)</td>
                        <td className="col-30"></td>
                        <td className="col-30">{calculateBrokerCommission('lessor_outside_broker', data)}</td>
                    </tr>
                </tbody>
            );
        };
    
        return <>
            <div>
                <h4 style={{ textDecoration: "underline" }}>COMMISSION DISTRIBUTION {name.toUpperCase()}</h4>
            </div>
            {hasLessorAssociate && <div className="the-table">
                <table>{renderCommissionsLessor()}</table>
            </div>}
            <div className="the-table mt-10" style={{display:'none'}}>
                <table>{renderCommissionsOtherLessor()}</table>
            </div>
        </>
    }

    if (name === 'lessee') {
        const renderCommissionsLessee = () => {
            let tot = 0;
            if (data.commission_splits_agent2) {
                data.commission_splits_agent2.forEach(csa => {
                    tot += parseFloatString(csa.Percentage)
                })
            }
    
            return (
                <tbody>
                    <tr>
                        <td className="col-40">
                            <strong>Name</strong>
                        </td>
                        <td className="col-60">
                            <strong>Percentage</strong>
                        </td>
                    </tr>
                    {data.commission_splits_agent2.map((c, i) => {
                        return (
                            <tr key={`${i}-agent`}>
                                <td className="col-40">{c.Name}</td>
                                <td className="col-60">{twoDecimals(parseFloatString(c.Percentage))} %</td>
                            </tr>
                        );
                    })}
                    <tr>
                        <td className="col-40">
                            <strong>Total</strong>
                        </td>
                        <td className="col-60">{twoDecimals(tot)} %</td>
                    </tr>
                </tbody>
            );
        };
        
        const renderCommissionsOtherLessee = () => {
            return (
                <tbody>
                    <tr>
                        <td className="col-40">
                            <strong>Name</strong>
                        </td>
                        <td className="col-60">
                            <strong>Value</strong>
                        </td>
                    </tr>
                    <tr>
                        <td className="col-40">Lessee Referral</td>
                        <td className="col-60">
                            {data.lessee_referral_firm_fee_amount_choice === "Value"
                                ? data.lessee_referral_firm_fee_amount
                                : data.lessee_referral_firm_fee_amount_percentage + " %"}
                        </td>
                    </tr>
                    <tr>
                        <td className="col-40">Lessee - Outside Broker</td>
                        <td className="col-60">{calculateBrokerCommission('outside_broker',data)}</td>
                    </tr>
                </tbody>
            );
        };

        return <>
            <div>
                <h4 style={{ textDecoration: 'underline' }}>COMMISSION DISTRIBUTION {name.toUpperCase()}</h4>
            </div>
            {hasLesseeAssociate && <div className="the-table">
                <table>
                    {renderCommissionsLessee()}
                </table>
            </div>}
            <div className="the-table mt-10" style={{display:'none'}}>
                <table>
                    {renderCommissionsOtherLessee()}
                </table>
            </div>
        </>
    }

    return null;
};

export const CommissionDistributionPDF = props => {
    return <>

    </>
}