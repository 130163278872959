import React, {Component} from 'react';
import CustomInput from 'components/CustomInput/CustomInput2.jsx';
import InputAdornment from '@material-ui/core/InputAdornment';
import { TextField } from '@material-ui/core';

function makeid(length) {
	var result = '';
	var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	var charactersLength = characters.length;
	for (var i = 0; i < length; i++) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
	}
	return result;
}

const TextAreaField = (props) => {
	const {classes, isDollar} = props;
	let error = '';
	if (props.validate) {
		if (props.validate.length > 0) {
			props.validate.forEach((val) => {
				const err = val(props.value);
				if (err) {
					error = err;
				}
			});
		}
	}

	const inputProps = {
		onChange: (e) => {
			const start = e.target.selectionStart,
				end = e.target.selectionEnd;
			if (props.normalize) {
				e.target.value = props.normalize(e.target.value);
				e.target.setSelectionRange(start, end);
			}
			if (!props.readonly) {
				props.onChange(e);
			}
			if (props.updateError) {
				props.updateError(error);
			}
		},
		onClick: () => {
			if (props.onClick) props.onClick();
		},
		type: props.number_only ? 'number' : 'text',
		autoCapitalize: props.uppercase ? "true" : "false",
		value: props.value ? props.value : '',
		autoFocus: props.autoFocus ? true : false,
		role: 'presentation',
		autoComplete: 'nope',
		disabled: props.disabled ? true : props.readonly ? true : false,
		readOnly: props.readonly ? true : false,
	};

	if (props.type) {
		inputProps.type = props.type;
	}

	return (
		<div className={`${(props.readonly && props.readonly_show_gray) ? 'gray-bg-input' : ''}`}>
            <div className="custom-textfield">
                <TextField
                    label={props.label}
                    multiline
                    rowsMax="4"
                    value={inputProps.value}
                    onChange={inputProps.onChange}
					inputProps={inputProps}
                    rows={4}
                    margin="normal"
                />
            </div>
			{props.additionalError && (
				<div className='red ii' style={{marginTop: 0, fontSize: '10px'}}>
					{props.additionalError}
				</div>
			)}
			{error && (
				<div className='red aa' style={{marginTop: 0, fontSize: '10px'}}>
					{error}
				</div>
			)}
			{!error && (
				<div className='red bb' style={{marginTop: 0, opacity: 0, fontSize: '10px'}}></div>
			)}
			{props.similar && !error ? props.similar : null}
		</div>
	);
};

export default TextAreaField;
