import jwt_decode from 'jwt-decode';

const checkActions = (section) => {
  const token = localStorage.getItem('token');
  if (token) {
    const decoded = jwt_decode(token);
    let actions = {};
    
    Object.keys(decoded.permObj).forEach((p) => {
      const sectionArr = p.split(' ').filter((o,i) => i !== 0);
      const section_perm = p.split(' ')[0],
            action = sectionArr.join('_');

      if (section_perm === section) {
        if (!actions.hasOwnProperty(action)) {
          actions[action] = decoded.permObj[p];
        }
      }
    });
    
    return actions ? actions : {};
  }
}

export default checkActions;
