import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from "@material-ui/core/DialogTitle";
import Close from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { PropertiesFormSpaces } from "../../containers/CRM/Properties/PropertiesFormSpaces";

const CustomDialogClose = (props) => {

    return (
        <Dialog
                open={props.open}
                keepMounted
                onClose={props.close}
                maxWidth='sm'
                fullWidth={true}
            >
            <DialogTitle>
                <div className="close-btn" onClick={() => { props.close('cancel') }}><Close /></div>
                <div className="text-center blue-text" style={{fontSize:24}}>Are you sure?</div>
            </DialogTitle>
            <div style={{padding:20}}>
                <div className="text-center">Any unsaved progress/work will be lost.</div>
                <hr />
                <div className="text-center">
                <Button color="primary"
                        className="mr-20"
                        onClick={() => {
                            if (props.ok_save) props.ok_save();
                            props.close('save');
                        }}>Save and Close</Button>
                    <Button color="success"
                        className="mr-20"
                        onClick={() => {
                            if (props.ok_go) props.ok_go();
                            props.close('close');
                        }}>OK, CLOSE</Button>

					<Button
						color="white"
						onClick={() => {
							props.close('cancel');
						}}
					>
						CANCEL
					</Button>
				</div>
			</div>
		</Dialog>
	);
};

CustomDialogClose.propTypes = {
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	ok_go: PropTypes.func.isRequired,
};

export default CustomDialogClose;
