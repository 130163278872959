import React from "react";
import Datetime from "react-datetime";

const CustomDateInput = (props) => {
    const { onChange, timeFormat = false, value, showClear, label, is_readonly, isRequired, handleClear } = props;

    return (
        <div className={`custom relative ${!is_readonly && isRequired && !value ? "required-date" : ""}`}>
            {value && <div className="tiny-label">{label}</div>}
            <div className="hoverable gray-text date-clear">
                {value && showClear && handleClear && (
                    <small
                        onClick={handleClear}
                    >
                        clear
                    </small>
                )}
            </div>
            <Datetime
                inputProps={{
                    placeholder: label,
                    disabled: is_readonly,
                    style: {
                        color: !is_readonly ? "#999" : "#555",
                    },
                }}
                closeOnSelect={true}
                onChange={onChange}
                timeFormat={timeFormat}
                value={value}
            />
            {!is_readonly && isRequired && !value && (
                <div className="red-text">
                    <small>Required</small>
                </div>
            )}
        </div>
    );
};

export default CustomDateInput;
