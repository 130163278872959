// NOTE : DEPRECATED 10/4/2023

import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import Close from '@material-ui/icons/Close';
import mime from 'mime-types';
import { clone } from 'shared/utility';
import { getTimestamp } from '../../../shared/utility';

const FileUpload = (props) => {
	const {
		uploadModalOpen,
		setUploadModalOpen,
		success,
		error,
		fieldObj,
		updateDataObj,
		data,
		formDataObj,
		setFormDataObj,
		obj,
		index,
	} = props;

	const [successStatus, setSuccessStatus] = useState(null);
	const [errorFile, setErrorFile] = useState(null);
	const [selectedFile, setSelectedFile] = useState(null);
	const [fileTypeArr, setFileTypeArr] = useState([]);

	useEffect(() => {}, [data]);

	useEffect(() => {
		if (success === 'Successfully uploaded document') {
			setSuccessStatus(success);
		}
		if (error === 'Failed to upload document') {
			setSuccessStatus('Failed to upload document');
		}
	}, [success, error]);

	const handleFileChange = (e) => {
		let formData = new FormData();
		const allowableFileExtensions = fieldObj.filetypes;
		let file = e.target.files[0];
		let extension = file && file.name ? file.name.match(/(?:\.([^.]+))?$/) : '';
		if (allowableFileExtensions.includes(extension[1])) {
			if (fieldObj && fieldObj.label) {
				if (fieldObj.label === 'S.O.S.') {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					// update data object
					let newDataObj = clone(data.lessees);
					newDataObj[index]['upload_sos'] = currFileName;
					updateDataObj('lessees', newDataObj);

					// Update formDataObject
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`lease-${obj.id}`] || {};
					formData.append('lessee_id', obj.id);
					formData.append('filename', currFileName);
					formData.append('file', file, currFileName);
					for (const key in currentObj) {
						if (
							key.substring(0, key.indexOf('-')) ===
							currFileName.substring(0, currFileName.indexOf('-'))
						) {
							delete currentObj[key];
						}
					}
					currentObj[currFileName] = formData;
					newFormDataObj[`lease-${obj.id}`] = currentObj;
					setFormDataObj(newFormDataObj);
				}
				if (fieldObj.label === 'Spousal ID') {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					let newDataObj = clone(data.lessees);
					newDataObj[index]['upload_spousal_id'] = currFileName;
					updateDataObj('lessees', newDataObj);
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`lease-${obj.id}`] || {};
					formData.append('lessee_id', obj.id);
					formData.append('filename', currFileName);
					formData.append('file', file, currFileName);
					for (const key in currentObj) {
						if (
							key.substring(0, key.indexOf('-')) ===
							currFileName.substring(0, currFileName.indexOf('-'))
						) {
							delete currentObj[key];
						}
					}
					currentObj[currFileName] = formData;
					newFormDataObj[`lease-${obj.id}`] = currentObj;
					setFormDataObj(newFormDataObj);
				}
				if (fieldObj.label === 'Guarantor ID') {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					let newDataObj = clone(data.lessees);
					newDataObj[index]['upload_guarantor_id'] = currFileName;
					updateDataObj('lessees', newDataObj);
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`lease-${obj.id}`] || {};
					formData.append('lessee_id', obj.id);
					formData.append('filename', currFileName);
					formData.append('file', file, currFileName);
					for (const key in currentObj) {
						if (
							key.substring(0, key.indexOf('-')) ===
							currFileName.substring(0, currFileName.indexOf('-'))
						) {
							delete currentObj[key];
						}
					}
					currentObj[currFileName] = formData;
					newFormDataObj[`lease-${obj.id}`] = currentObj;
					setFormDataObj(newFormDataObj);
				}
				if (
					fieldObj.label === 'W9' &&
					props.field === 'outside_broker_w9_upload'
				) {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					formData.append('filename', currFileName);
					formData.append(
						'folders',
						currFileName + ':' + 'lessees/outside_broker'
					);
					formData.append('file', file, currFileName);
					updateDataObj(
						'outside_broker_w9_upload',
						'lessees/outside_broker/' + currFileName
					);
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`w9`] || {};
					currentObj[currFileName] = formData;
					newFormDataObj['w9'] = currentObj;
					setFormDataObj(newFormDataObj);
				}
				if (
					fieldObj.label === 'Referral W9' &&
					props.field === 'lessee_referral_firm_w9_upload'
				) {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					formData.append('filename', currFileName);
					formData.append(
						'folders',
						currFileName + ':' + 'lessees/lessee_referral'
					);
					formData.append('file', file, currFileName);
					updateDataObj(
						'lessee_referral_firm_w9_upload',
						'lessees/lessee_referral/' + currFileName
					);
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`w9`] || {};
					currentObj[currFileName] = formData;
					newFormDataObj['w9'] = currentObj;
					setFormDataObj(newFormDataObj);
				}
				if (
					fieldObj.label === 'Referral W9' &&
					props.field === 'referral_firm_w9_upload'
				) {
					let currFileName =
						fieldObj.label + '-' + getTimestamp() + extension[0];
					formData.append('filename', currFileName);
					formData.append(
						'folders',
						currFileName + ':' + 'lessor/lessor_referral'
					);
					formData.append('file', file, currFileName);
					updateDataObj(
						'referral_firm_w9_upload',
						'lessor/lessor_referral/' + currFileName
					);
					let newFormDataObj = clone(formDataObj) || {};
					let currentObj = newFormDataObj[`w9`] || {};
					currentObj[currFileName] = formData;
					newFormDataObj['w9'] = currentObj;
					setFormDataObj(newFormDataObj);
				}
			}
		} else {
			document.getElementById('inputid').value = '';
			e.target.file = '';
			file = null;
			setSelectedFile(null);
			setErrorFile(true);
		}
	};

	useEffect(() => {
		if (fieldObj && fieldObj.filetypes.length) {
			const currTypes = fieldObj.filetypes.map((fileType) => {
				return mime.lookup(fileType);
			});
			setFileTypeArr(currTypes.toString());
		}
	}, [fieldObj]);

	return (
		<Dialog
			open={uploadModalOpen}
			onClose={() => {
				setUploadModalOpen(false);
			}}
		>
			<DialogContent style={{ minHeight: '20vh' }}>
				<div
					className='close-btn'
					onClick={() => {
						setUploadModalOpen(false);
					}}
				>
					<Close />
				</div>
				<GridContainer>
					<GridItem xs={12} sm={12} md={12}>
						{fieldObj.label}
					</GridItem>
					<GridItem xs={12} sm={12} md={12}>
						<form className='the-file-upload'>
							<input
								type='file'
								name='file'
								id='inputid'
								onChange={handleFileChange}
								accept={fileTypeArr}
							/>
						</form>
					</GridItem>
				</GridContainer>
			</DialogContent>
		</Dialog>
	);
};

export default FileUpload;
