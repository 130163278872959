import * as actionTypes from './actionTypes';

// GET_INVOICE_ENTRIES 

export const getInvoiceEntries = (data, rs_id) => {
    return {
        type: actionTypes.GET_INVOICE_ENTRIES,
        data,
        rs_id
    }
}

export const getInvoiceEntriesStart = () => {
    return {
        type: actionTypes.GET_INVOICE_ENTRIES_START
    }
}

export const getInvoiceEntriesSuccess = (response) => {
    return {
        type: actionTypes.GET_INVOICE_ENTRIES_SUCCESS,
        response
    }
}

export const getInvoiceEntriesFail = (err) => {
    return {
        type: actionTypes.GET_INVOICE_ENTRIES_FAIL,
        err
    }
}

// UPDATE_INVOICE_ENTRY 

export const updateInvoiceEntry = (data) => {
    return {
        type: actionTypes.UPDATE_INVOICE_ENTRY,
        data
    }
}

export const updateInvoiceEntryStart = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_ENTRY_START
    }
}

export const updateInvoiceEntrySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_INVOICE_ENTRY_SUCCESS,
        response
    }
}

export const updateInvoiceEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_INVOICE_ENTRY_FAIL,
        err
    }
}

// CREATE_INVOICE_ENTRY 

export const createInvoiceEntry = (data) => {
    return {
        type: actionTypes.CREATE_INVOICE_ENTRY,
        data
    }
}

export const createInvoiceEntryStart = () => {
    return {
        type: actionTypes.CREATE_INVOICE_ENTRY_START
    }
}

export const createInvoiceEntrySuccess = (response) => {
    return {
        type: actionTypes.CREATE_INVOICE_ENTRY_SUCCESS,
        response
    }
}

export const createInvoiceEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_INVOICE_ENTRY_FAIL,
        err
    }
}

// DELETE_INVOICE_ENTRY 

export const deleteInvoiceEntry = (data) => {
    return {
        type: actionTypes.DELETE_INVOICE_ENTRY,
        data
    }
}

export const deleteInvoiceEntryStart = () => {
    return {
        type: actionTypes.DELETE_INVOICE_ENTRY_START
    }
}

export const deleteInvoiceEntrySuccess = (response) => {
    return {
        type: actionTypes.DELETE_INVOICE_ENTRY_SUCCESS,
        response
    }
}

export const deleteInvoiceEntryFail = (err) => {
    return {
        type: actionTypes.DELETE_INVOICE_ENTRY_FAIL,
        err
    }
}

// GET_INVOICE_PDF 

export const getInvoicePdf = (data) => {
    return {
        type: actionTypes.GET_INVOICE_PDF,
        data
    }
}

export const getInvoicePdfStart = () => {
    return {
        type: actionTypes.GET_INVOICE_PDF_START
    }
}

export const getInvoicePdfSuccess = (response) => {
    return {
        type: actionTypes.GET_INVOICE_PDF_SUCCESS,
        response
    }
}

export const getInvoicePdfFail = (err) => {
    return {
        type: actionTypes.GET_INVOICE_PDF_FAIL,
        err
    }
}

export const resetInvoiceID = () => {
    return {
        type: actionTypes.RESET_INVOICE_ID
    }
}

export const clearInvoicePdfData = () => {
    return {
        type: actionTypes.CLEAR_INVOICE_PDF_DATA
    }
}

// RESEND_INVOICE_EMAIL 

export const resendInvoiceEmail = (data) => {
    return {
        type: actionTypes.RESEND_INVOICE_EMAIL,
        data
    }
}

export const resendInvoiceEmailStart = () => {
    return {
        type: actionTypes.RESEND_INVOICE_EMAIL_START,
    }
}

export const resendInvoiceEmailSuccess = (response) => {
    return {
        type: actionTypes.RESEND_INVOICE_EMAIL_SUCCESS,
    }
}

export const resendInvoiceEmailFail = (err) => {
    return {
        type: actionTypes.RESEND_INVOICE_EMAIL_FAIL,
        err
    }
}

// VOID_INVOICES 

export const voidInvoices = (data, sendNotificationTo) => {
    return {
        type: actionTypes.VOID_INVOICES,
        data,
        sendNotificationTo
    }
}

export const voidInvoicesStart = () => {
    return {
        type: actionTypes.VOID_INVOICES_START
    }
}

export const voidInvoicesSuccess = (response) => {
    return {
        type: actionTypes.VOID_INVOICES_SUCCESS,
        response
    }
}

export const voidInvoicesFail = (err) => {
    return {
        type: actionTypes.VOID_INVOICES_FAIL,
        err
    }
}

// CLEAR_INVOICES 

export const clearInvoices = (data) => {
    return {
        type: actionTypes.CLEAR_INVOICES,
        data
    }
}

export const clearInvoicesStart = () => {
    return {
        type: actionTypes.CLEAR_INVOICES_START
    }
}

export const clearInvoicesSuccess = (response) => {
    return {
        type: actionTypes.CLEAR_INVOICES_SUCCESS,
        response
    }
}

export const clearInvoicesFail = (err) => {
    return {
        type: actionTypes.CLEAR_INVOICES_FAIL,
        err
    }
}

// GET_INVOICE_PAYMENTS 

export const getInvoicePayments = (data) => {
    return {
        type: actionTypes.GET_INVOICE_PAYMENTS,
        data
    }
}

export const getInvoicePaymentsStart = () => {
    return {
        type: actionTypes.GET_INVOICE_PAYMENTS_START
    }
}

export const getInvoicePaymentsSuccess = (response) => {
    return {
        type: actionTypes.GET_INVOICE_PAYMENTS_SUCCESS,
        response
    }
}

export const getInvoicePaymentsFail = (err) => {
    return {
        type: actionTypes.GET_INVOICE_PAYMENTS_FAIL,
        err
    }
}

// CREATE_INVOICE_PAYMENT 

export const createInvoicePayment = (data, otherData) => {
    return {
        type: actionTypes.CREATE_INVOICE_PAYMENT,
        data,
        otherData
    }
}

export const createInvoicePaymentStart = () => {
    return {
        type: actionTypes.CREATE_INVOICE_PAYMENT_START
    }
}

export const createInvoicePaymentSuccess = (response) => {
    return {
        type: actionTypes.CREATE_INVOICE_PAYMENT_SUCCESS,
        response
    }
}

export const createInvoicePaymentFail = (err) => {
    return {
        type: actionTypes.CREATE_INVOICE_PAYMENT_FAIL,
        err
    }
}

// UPDATE_INVOICE_PAYMENTS 

export const updateInvoicePayment = (data) => {
    return {
        type: actionTypes.UPDATE_INVOICE_PAYMENT,
        data
    }
}

export const updateInvoicePaymentStart = () => {
    return {
        type: actionTypes.UPDATE_INVOICE_PAYMENT_START
    }
}

export const updateInvoicePaymentSuccess = (response) => {
    return {
        type: actionTypes.UPDATE_INVOICE_PAYMENT_SUCCESS,
        response
    }
}

export const updateInvoicePaymentFail = (err) => {
    return {
        type: actionTypes.UPDATE_INVOICE_PAYMENT_FAIL,
        err
    }
}