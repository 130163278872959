import React, { useState, useEffect } from 'react';
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import { ListItem, List, ListItemText, ListItemIcon, Grid } from '@material-ui/core';
import PMAMulti1 from './PMAMulti1';
import PMAMulti3 from './PMAMulti3';
import PMAMulti4 from './PMAMulti4';
import PMAMulti10 from './PMAMulti10';
import PMAMulti11 from './PMAMulti11';
import * as actions from 'store/actions';
import { connect } from 'react-redux';
import { clone } from "shared/utility";
import { generateSections } from './PMALogic';
import { ArrowBack, ArrowForward } from '@material-ui/icons';
import Button from '../../../components/CustomButtons/Button';

const PMAMultiForm = (props) => {

    const { formData, setFormData, setFormData2, updateDataObj, setTab } = props;
    const [tabId, setTabId] = useState(null);
    const [propertyChosen, setPropertyChosen] = useState(null);
    const { currForm, setCurrForm } = props;
    const [dataCheck, setDataCheck] = useState({
        contacts: [],
        properties: []
    });

    const updateDataCheck = (type, obj) => {
        const newDataCheck = clone(dataCheck);
        let found = false;
        if (obj.id) {
            newDataCheck[type].forEach(ndt => {
                if (ndt.id) {
                    if (ndt.id === obj.id) {
                        found = true;
                    }
                }
            })
        }
        if (!found) {
            newDataCheck[type] = newDataCheck[type].concat([obj]);
            setDataCheck(newDataCheck);
        }
    }

    

    useEffect(() => {
        if (!formData.president && props.approvers) {
            if (props.approvers.length > 0) {
                // set default approver
                const approver = props.approvers[0];
                const newData = clone(formData);
                newData.president = approver.name;
                newData.president_email = approver.email;
                setFormData2(newData);
            }
        }
        if (props.prefill_property_data) {
            setCurrForm(2);
        }
    }, [])

    const updateData = (field, value) => {
        const data = clone(formData);
        data[field] = value;
        props.setFormData(data);
    }

    const sections = generateSections(formData);
    

    const renderNav = () => {
        return <div style={{marginBottom:10}}>
                    <Button
                        style={{marginRight:20}}
                        onClick={() => {
                            setCurrForm(currForm - 1);
                            props.saveDraft(null, null, true);
                        }} 
                        disabled={currForm === 1 ? true : false}
                    ><ArrowBack /> Previous</Button>
                    <Button
                        className="mr-20"
                        onClick={() => {
                            if (currForm === 5) {
                                props.moveToFullForm();
                            } else {
                                setCurrForm(currForm + 1);
                                props.saveDraft(null, null, true);
                            }
                        }}
                    >Next <ArrowForward /></Button>
                    {props.activity && <Button color="white"
                    onClick={() => {
                        props.setOpenLoading(true);
                        props.saveDraft();
                    }}
                ><i className="fas fa-save"></i> Save PMA Draft</Button>}
                </div>
    }

    return (
        <div>
            {renderNav()}
            <GridContainer>
            <GridItem xs={8} sm={8} md={8}>
                {currForm === 1 &&
                    <PMAMulti1
                        formData={formData}
                        setFormData={setFormData}
                        setFormData2={setFormData2}
                        getContactNames={props.getContactNames}
                        contactNames={props.contactNames}
                        getContacts={props.getContacts}
                        getContact={props.getContact}
                        tabId={tabId}
                        setTabId={setTabId}
                        updateData={updateData}
                        contactsData={props.contactsData}
                        contactData={props.contactData}
                        updateDataObj={updateDataObj}
                        dataCheck={dataCheck}
                        updateDataCheck={updateDataCheck}
                    />}
                {currForm === 2 &&
                    <PMAMulti3
                    formData={formData}
                    setFormData={setFormData}
                    getContactNames={props.getContactNames}
                    getPropertyNames={props.getPropertyNames}
                    propertyNames={props.propertyNames}
                    contactData={props.contactData}
                    propertyChosen={propertyChosen}
                    setPropertyChosen={setPropertyChosen}
                    updateDataObj={updateDataObj}
                    prefill_property_data={props.prefill_property_data}
                    reset_prefill_data={props.reset_prefill_data}
                />}
                {currForm === 3 &&
                    <PMAMulti4
                        formData={formData}
                        setFormData={setFormData}
                        updateDataObj={updateDataObj}
                    />}
                {currForm === 4 &&
                    <PMAMulti10
                        formData={formData}
                        setFormData={setFormData}
                        approvers={props.approvers ? props.approvers : []}
                    />}
                {currForm === 5 &&
                    <PMAMulti11
                        formData={formData}
                        setFormData={setFormData}
                    />}
                </GridItem>
                <GridItem xs={12} sm={3} md={3}>
                    {/* Tracker */}
                    <List component="nav">
                        {sections.map((s, i) => {
                            return <ListItem key={`li-${i}`} button onClick={() => {
                                setCurrForm(i + 1);
                            }}>
                                <ListItemIcon>
                                    {s.complete ? <i className={`fas fa-check font-22 ${(currForm - 1) === i ? 'bold red-text' : ''}`}></i>
                                        : <i className={`fas fa-times font-22 ${(currForm - 1) === i ? 'bold red-text' : ''}`}></i>}
                                </ListItemIcon>
                                <ListItemText inset primary={<div className={(currForm - 1) === i ? 'bold red-text' : ''}>
                                    {s.name}
                                </div>} />
                            </ListItem>
                        })}
                    </List>
                </GridItem>
            </GridContainer>
            <hr />
            {renderNav()}
            <hr />
        </div>
    )
}


const mapStateToProps = state => {
    const p = state.crm_properties;
    const c = state.crm_contacts;
    return {
        propertyNames: p.propertyNames,
        propertyContacts: p.propertyContacts,
        propertyData: p.propertyData,
        contactData: c.contactData,
        contactsData: c.contactsData,
        contactNames: c.contactNames,
        approvers: state.pma.approvers,
        propertyData: p.propertyData
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getPropertyNames: () => {
            dispatch(actions.getPropertyNames());
        },
        getPropertyContacts: (contact_id) => {
            dispatch(actions.getPropertyContacts(contact_id));
        },
        getContactNames: (contacts) => {
            dispatch(actions.getContactNames(contacts));
        },
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
        getContact: (id) => {
            dispatch(actions.getContact(id));
        },
        getProperty: (data) => {
            dispatch(actions.getProperty(data));
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PMAMultiForm);