import { get } from "lodash";
import React from "react";

export const ListingBaseTemplate = (props) => {
    const {
        formData,
        setOpenedField,
        setOpenedModal,
        templateText,
        fieldModels,
    } = props;

    const formInput = (field) => {
        let newField = formData[field];

        const canOverwrite = get(fieldModels, `${field}.canOverwrite`, true);
        const goToTab = get(fieldModels, `${field}.goToTab`, null);

        return (
            <span
                className={`${!canOverwrite ? "hovered" : ""} line-block ${
                    newField ? "green-block" : "red-block"
                }`}
                style={{
                    display: "inline-block",
                    margin: "10px 10px 0",
                    minWidth: 100,
                    minHeight: 30,
                }}
                onClick={() => {
                    if (canOverwrite) {
                        setOpenedField(field);
                        setOpenedModal(true);
                    };

                    if (goToTab) {
                        goToTab();
                    }
                }}
            >
                {!newField && (
                    <span className="gray-text">
                        {fieldModels ? fieldModels[field].label : ""}
                    </span>
                )}
                {newField && (
                    <span>
                        <strong>{newField}</strong>
                    </span>
                )}
            </span>
        );
    };

    const textInput = {};
    Object.keys(templateText).forEach((field) => {
        textInput[field] = (
            <span
                key={`input-${field}`}
                // style={style}

                className="hoverable gray-hover bluish-hover text-input"
            >
                {templateText[field]}
            </span>
        );
    });

    const agentList = get(formData, "agentList", []);

    return (
        <div>
            <div className="times-new-roman font-16">
                <div>
                    <img
                        src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                        style={{ marginBottom: 20, width: 100 }}
                    />
                </div>
                <div style={{ textAlign: "center" }}>
                    <h3>{textInput.form1}</h3>
                    <div
                        style={{
                            margin: "0 auto",
                            width: "50%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            {textInput.illi}
                            {formInput("illi")}
                        </div>
                        <div>
                            {textInput.date}
                            {formInput("date")}
                        </div>
                        <div>
                            {textInput.submitted}
                            {formInput("submitted")}
                        </div>
                    </div>
                </div>

                <h4>{textInput.title}</h4>
                <table className="listing-table">
                    <tbody>
                        <tr>
                            <th style={{ width: "15%" }}>{textInput.tag}</th>
                            <td colSpan={5}>{formInput("tag")}</td>
                            <th style={{ width: "10%" }}>{textInput.signed}</th>
                            <td>{formInput("signed")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.street}</th>
                            <td colSpan={5}>{formInput("address")}</td>
                            <th>{textInput.expire}</th>
                            <td>{formInput("expire")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.city}</th>
                            <td>{formInput("city")}</td>
                            <th style={{ width: "10%" }}>{textInput.state}</th>
                            <td>{formInput("state")}</td>
                            <th style={{ width: "10%" }}>{textInput.zip}</th>
                            <td>{formInput("zip")}</td>
                            <th>{textInput.renew}</th>
                            <td>{formInput("renew")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.cross}</th>
                            <td colSpan={7}>{formInput("cross")}</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />

                <h4>{textInput.space_name}</h4>
                <table className="listing-table">
                    <thead>
                        <tr>
                            <th>{textInput.space_unit}</th>
                            <th>{textInput.space_sf}</th>
                            <th>{textInput.space_rent}</th>
                            <th>{textInput.space_date}</th>
                            <th>{textInput.space_lease}</th>
                            <th>{textInput.space_nnn}</th>
                            <th>{textInput.space_sub}</th>
                            <th>{textInput.space_min}</th>
                            <th>{textInput.space_max}</th>
                            <th>{textInput.space_term}</th>
                            <th>{textInput.space_prop}</th>
                            <th>{textInput.space_bo}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{formInput("space_unit")}</td>
                            <td>{formInput("space_sf")}</td>
                            <td>{formInput("space_rent")}</td>
                            <td>{formInput("space_date")}</td>
                            <td>{formInput("space_lease")}</td>
                            <td>{formInput("space_nnn")}</td>
                            <td>{formInput("space_sub")}</td>
                            <td>{formInput("space_min")}</td>
                            <td>{formInput("space_max")}</td>
                            <td>{formInput("space_term")}</td>
                            <td>{formInput("space_prop")}</td>
                            <td>{formInput("space_bo")}</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <h4>{textInput.property_name}</h4>
                <table className="listing-table">
                    <thead>
                        <tr>
                            <th>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>{textInput.property_detail}</span>
                                    <span className="red-text">
                                        {textInput.property_detailReq}
                                    </span>
                                </div>
                            </th>
                            <th>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    <span>{textInput.property_amenity}</span>
                                    <span className="red-text">
                                        {textInput.property_amenityReq}
                                    </span>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{formInput("property_detail")}</td>
                            <td>{formInput("property_amenity")}</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="listing-table">
                    <tbody>
                        <tr>
                            <th style={{ width: "17%" }}>
                                {textInput.property_parking}
                            </th>
                            <td>{formInput("property_parking")}</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <table className="listing-table">
                    <tbody>
                        <tr>
                            <th>{textInput.property_addition}</th>
                        </tr>
                        <tr>
                            <td>{formInput("property_addition")}</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <h4>{textInput.contact_name}</h4>
                <table style={{ width: "50%" }} className="listing-table">
                    <tbody>
                        {agentList.map((agent, index) => (
                            <tr key={index}>
                                <th style={{ width: "20%" }}>
                                    {textInput[`contact_agent${index + 1}`]}
                                </th>
                                <td>
                                    {formInput(`contact_agent${index + 1}`)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <br />
                <br />

                <div
                    style={{
                        margin: "0 auto",
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <span>{textInput.date2}</span>
                    <span>{textInput.sig}</span>
                </div>

                <br />
                <br />
                <br />
                <br />

                <div>
                    <img
                        src="https://illicre.com/wp-content/uploads/2020/04/admin-ajax-3-1-1.png"
                        style={{ marginBottom: 20, width: 100 }}
                    />
                </div>

                <div style={{ textAlign: "center" }}>
                    <h3>{textInput.form2}</h3>
                    <div
                        style={{
                            margin: "0 auto",
                            width: "50%",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                    >
                        <div>
                            {textInput.illi}
                            {formInput("illi")}
                        </div>
                        <div>
                            {textInput.date}
                            {formInput("date")}
                        </div>
                        <div>
                            {textInput.submitted}
                            {formInput("submitted")}
                        </div>
                    </div>
                </div>

                <br />
                <br />

                <h4>{textInput.project_name}</h4>
                <table className="listing-table">
                    <tbody>
                        <tr>
                            <th style={{ width: "15%" }}>
                                {textInput.project_project}
                            </th>
                            <td colSpan={5}>{formInput("project_project")}</td>
                            <th style={{ width: "10%" }}>
                                {textInput.project_signed}
                            </th>
                            <td>{formInput("project_signed")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.street}</th>
                            <td colSpan={5}>{formInput("street")}</td>
                            <th>{textInput.expire}</th>
                            <td>{formInput("expire")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.project_city}</th>
                            <td>{formInput("project_city")}</td>
                            <th style={{ width: "10%" }}>
                                {textInput.project_state}
                            </th>
                            <td>{formInput("project_state")}</td>
                            <th style={{ width: "10%" }}>
                                {textInput.project_zip}
                            </th>
                            <td>{formInput("project_zip")}</td>
                            <th>{textInput.project_renew}</th>
                            <td>{formInput("project_renew")}</td>
                        </tr>
                        <tr>
                            <th>{textInput.project_cross}</th>
                            <td colSpan={7}>{formInput("project_cross")}</td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <h4>{textInput.contact2_name}</h4>
                <table className="listing-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th></th>
                            <th>{textInput.contact2_com}</th>
                            <th></th>
                            <th></th>
                            <th>{textInput.contact2_com2}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {agentList.map((agent, index) => (
                            <tr key={`agent2-${index}`}>
                                <th>
                                    {textInput[`contact_agent${index + 1}`]}
                                </th>
                                <td></td>
                                <td>{formInput(`agent_com_${index + 1}`)}</td>
                                <th>{textInput.contact2_ref}</th>
                                <td></td>
                                <td></td>
                            </tr>
                        ))}
                        <tr>
                            <td></td>
                            <th>{textInput.contact2_total}</th>
                            <td>{formInput("total_com")}</td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

                <br />
                <br />

                <div
                    style={{
                        margin: "0 auto",
                        width: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                >
                    <span>{textInput.date2}</span>
                    <span>{textInput.sig}</span>
                </div>
            </div>
        </div>
    );
};
