import React, { useEffect, useState, useRef } from 'react';
import { clone } from 'shared/utility';
import { formatDateForBackend } from "shared/utility";

export const useSaveShowing = (props) => {
    const { madeChanges, updateShowing, data, setMadeChanges } = props;

    const TIME = 300000;

    useEffect(() => {
        const interval = setInterval(() => {
            if (madeChanges) {
                const newData = { id: data.id, date: data.date, notes: data.notes };
                if (newData.date) {
                    newData.date = formatDateForBackend(newData.date);
                }
                updateShowing(newData);
                setMadeChanges(false);
            }
        }, TIME);
        return () => clearInterval(interval);
    }, [madeChanges, data]);
}