import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import { listingFields } from '../ListingModel';
import { cloneDeep } from 'lodash';

const ListingDataModal = (props) => {
	const { field, formData, setFormData, fieldModels } = props;
	let fieldSplit = field.split('.');
	let newField = fieldSplit.length > 1 ? formData[fieldSplit[0]][fieldSplit[1]] : formData[field];

	const updateData = (field, value) => {
		const data = cloneDeep(formData);
		data[field] = value;
		setFormData(data);
	};

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='md' fullWidth={true}>
				<div style={{ margin: 20, height: '60vh' }} className='date-overflow'>
					<h4 className='text-center'>{fieldModels[field] ? fieldModels[field].label : ''}</h4>
					{/* <ERTLelement ertlFormData={listingFormData} field={field} update={updateData} autoFocus={true} /> */}
					<br />
					<Button
						color='rose'
						onClick={() => {
							updateData(field)
							props.close();
						}}
					>
						OK
					</Button>
				</div>
			</Dialog>
		</div>
	);
};

export default ListingDataModal;
