import { put } from "redux-saga/effects";
import axios from "../axios/axios-gci";
import * as actions from "../actions/gci";

export function* getGciEntriesSaga(action) {
    yield put(actions.getGciEntriesStart());
    try {
        if (action.data) {
            const response = yield axios.get('/entries?id='+action.data);
            yield put(actions.getGciEntriesSuccess(response.data));
        } else {
            const response = yield axios.get('/entries');
            yield put(actions.getGciEntriesSuccess(response.data));
        }
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getGciEntriesFail(e.response.data));
            }
          }
        yield put(actions.getGciEntriesFail(e));
    }
}

export function* createGciEntrySaga(action) {
    yield put(actions.createGciEntryStart());
    try {
        const response = yield axios.post('/entry', action.data);
        yield put(actions.createGciEntrySuccess(response.data));
        const response2 = yield axios.get('/entries');
        yield put(actions.getGciEntriesSuccess(response2.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createGciEntryFail(e.response.data));
            }
          }
        yield put(actions.createGciEntryFail(e));
    }
}

export function* deleteGciEntrySaga(action) {
    yield put(actions.deleteGciEntryStart());
    try {
        const gci_id = action.data.id;
        yield axios.put('/users', { gci_id });
        const response = yield axios.delete('/entry?id='+gci_id);
        yield put(actions.deleteGciEntrySuccess(response.data));
        const response2 = yield axios.get('/entries');
        yield put(actions.getGciEntriesSuccess(response2.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.deleteGciEntryFail(e.response.data));
            }
          }
        yield put(actions.deleteGciEntryFail(e));
    }
}

export function* updateGciEntrySaga(action) {
    yield put(actions.updateGciEntryStart());
    try {
        const response = yield axios.put('/entry', action.data);
        yield put(actions.updateGciEntrySuccess(response.data));
        const response2 = yield axios.get('/entries');
        yield put(actions.getGciEntriesSuccess(response2.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.updateGciEntryFail(e.response.data));
            }
          }
        yield put(actions.updateGciEntryFail(e));
    }
}

export function* getEarnedCommissionsSaga(action) {
    yield put(actions.getEarnedCommissionsStart());
    try {
        const user_id = action.data.user_id;
        const response = yield axios.get('/earned_commissions?user_id='+user_id);
        yield put(actions.getEarnedCommissionsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.getEarnedCommissionsFail(e.response.data));
            }
          }
        yield put(actions.getEarnedCommissionsFail(e));
    }
}

export function* createEarnedCommissionsSaga(action) {
    yield put(actions.createEarnedCommissionsStart());
    try {
        const response = yield axios.post('/earned_commissions', action.data);
        yield put(actions.createEarnedCommissionsSuccess(response.data));
    } catch (e) {
        if (e.response) {
            if (e.response.data) {
              return yield put(actions.createEarnedCommissionsFail(e.response.data));
            }
          }
        yield put(actions.createEarnedCommissionsFail(e));
    }
}