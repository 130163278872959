import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import ContactsAppointments from './ContactsAppointments';
import ContactsAppointmentsAdd from './ContactsAppointmentsAdd';
import Button from '../../../components/CustomButtons/Button';
import {Add} from '@material-ui/icons';
import {Tabs, Tab} from '@material-ui/core';
import {getAppointments} from '../../../store/actions/crm_appointments';
import AppointmentMain from './Appointment/AppointmentMain';

const ContactsFormAppointments = ({contactData, action, getAppointments, appointmentsForAdd, setAppointmentsForAdd, getContacts, contactsData, canEdit}) => {

	return (
		<div className='illi-table'>
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<AppointmentMain 
						contactData={contactData}
						canEdit={canEdit}
						action={action}
					/>					
				</GridItem>
			</GridContainer>
		</div>
	);
};
export default connect(null, {getAppointments})(ContactsFormAppointments);
