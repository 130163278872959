import React, { useState } from "react";

import Button from "components/CustomButtons/Button.jsx";
import Table from "components/Table/Table.jsx";
import { clone } from "lodash";
import { translateBinary } from "../CRM/Contacts/ContactsObj";
import { capitalizeAll, removeWhitespace } from "../../shared/utility";
import { cleanSubtypes } from "../CRM/Contacts/ContactsLogic";

const DialPadMergeReview = (props) => {
    const {
        contact,
        contactToImport,
        contactForm,
        goTo,
        setAlreadyImportedList,
        setContactForm,
        setContactId,
        updateContact,
		setContactToUpdate
    } = props;

    const handleMerge = () => {
        const binariedData = translateBinary(contactForm);
        binariedData.first_name = capitalizeAll(binariedData.first_name);
        binariedData.last_name = capitalizeAll(binariedData.last_name);
        if (binariedData.metadata) {
            binariedData.metadata = JSON.stringify(binariedData.metadata);
        }
        setContactId(binariedData.id);
        updateContact(removeWhitespace(cleanSubtypes(binariedData)));
        if (setAlreadyImportedList) {
            setAlreadyImportedList(contactToImport.id);
        }
		setContactToUpdate({
            action: 'MERGE',
            data: binariedData
        })
        goTo(2);
    };

    const renderInput = (field) => {
        return (
            <input
                className="input-on-table"
                value={contactForm[field]}
                onChange={(e) => {
                    const { value } = e.target;
                    const newContactForm = clone(contactForm);
                    newContactForm[field] = value;
                    setContactForm(newContactForm);
                }}
            />
        );
    };

    return (
        <div>
            <h3>Before</h3>
            <div style={{ lineHeight: 1.5 }} className="mt-20 boxed">
                <Table
                    tableData={[
                        ["First Name", "first_name"],
                        ["Last Name", "last_name"],
                        ["Company", "company_name"],
                        ["Title", "title"],
                        ["Email", "email"],
                        ["Phone", "main_phone"],
                    ].map((field) => {
                        return [
                            <div style={{ textAlign: "right" }}>
                                <strong>{field[0]}</strong>
                            </div>,
                            contact[field[1]],
                        ];
                    })}
                />
            </div>

            <h3>After</h3>
            <Table
                tableData={[
                    ["First Name", "first_name"],
                    ["Last Name", "last_name"],
                    ["Company", "company_name"],
                    ["Title", "title"],
                    ["Email", "email"],
                    ["Phone", "main_phone"],
                ].map((field) => {
                    return [
                        <div style={{ textAlign: "right" }}>
                            <strong>{field[0]}</strong>
                        </div>,
                        renderInput(field[1]),
                    ];
                })}
            />

            <div style={{ textAlign: "center" }}>
                <Button color="primary" onClick={handleMerge}>
                    Save
                </Button>
            </div>
        </div>
    );
};

export default DialPadMergeReview;
