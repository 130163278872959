import * as actionTypes from './actionTypes';

// GET_GCI_ENTRIES 

export const getGciEntries = (data) => {
    return {
        type: actionTypes.GET_GCI_ENTRIES,
        data
    }
}

export const getGciEntriesStart = () => {
    return {
        type: actionTypes.GET_GCI_ENTRIES_START
    }
}

export const getGciEntriesSuccess = (response) => {
    return {
        type: actionTypes.GET_GCI_ENTRIES_SUCCESS,
        response
    }
}

export const getGciEntriesFail = (err) => {
    return {
        type: actionTypes.GET_GCI_ENTRIES_FAIL,
        err
    }
}

// DELETE_GCI_ENTRY 

export const deleteGciEntry = (data) => {
    return {
        type: actionTypes.DELETE_GCI_ENTRY,
        data
    }
}

export const deleteGciEntryStart = () => {
    return {
        type: actionTypes.DELETE_GCI_ENTRY_START
    }
}

export const deleteGciEntrySuccess = (response) => {
    return {
        type: actionTypes.DELETE_GCI_ENTRY_SUCCESS,
        response
    }
}

export const deleteGciEntryFail = (err) => {
    return {
        type: actionTypes.DELETE_GCI_ENTRY_FAIL,
        err
    }
}

// CREATE_GCI_ENTRY 

export const createGciEntry = (data) => {
    return {
        type: actionTypes.CREATE_GCI_ENTRY,
        data
    }
}

export const createGciEntryStart = () => {
    return {
        type: actionTypes.CREATE_GCI_ENTRY_START
    }
}

export const createGciEntrySuccess = (response) => {
    return {
        type: actionTypes.CREATE_GCI_ENTRY_SUCCESS,
        response
    }
}

export const createGciEntryFail = (err) => {
    return {
        type: actionTypes.CREATE_GCI_ENTRY_FAIL,
        err
    }
}

// UPDATE_GCI_ENTRY 

export const updateGciEntry = (data) => {
    return {
        type: actionTypes.UPDATE_GCI_ENTRY,
        data
    }
}

export const updateGciEntryStart = () => {
    return {
        type: actionTypes.UPDATE_GCI_ENTRY_START
    }
}

export const updateGciEntrySuccess = (response) => {
    return {
        type: actionTypes.UPDATE_GCI_ENTRY_SUCCESS,
        response
    }
}

export const updateGciEntryFail = (err) => {
    return {
        type: actionTypes.UPDATE_GCI_ENTRY_FAIL,
        err
    }
}

// GET_EARNED_COMMISSIONS 

export const getEarnedCommissions = (data) => {
    return {
        type: actionTypes.GET_EARNED_COMMISSIONS,
        data
    }
}

export const getEarnedCommissionsStart = () => {
    return {
        type: actionTypes.GET_EARNED_COMMISSIONS_START
    }
}

export const getEarnedCommissionsSuccess = (response) => {
    return {
        type: actionTypes.GET_EARNED_COMMISSIONS_SUCCESS,
        response
    }
}

export const getEarnedCommissionsFail = (err) => {
    return {
        type: actionTypes.GET_EARNED_COMMISSIONS_FAIL,
        err
    }
}

// CREATE_EARNED_COMMISSIONS 

export const createEarnedCommissions = (data) => {
    return {
        type: actionTypes.CREATE_EARNED_COMMISSIONS,
        data
    }
}

export const createEarnedCommissionsStart = () => {
    return {
        type: actionTypes.CREATE_EARNED_COMMISSIONS_START
    }
}

export const createEarnedCommissionsSuccess = (response) => {
    return {
        type: actionTypes.CREATE_EARNED_COMMISSIONS_SUCCESS,
        response
    }
}

export const createEarnedCommissionsFail = (err) => {
    return {
        type: actionTypes.CREATE_EARNED_COMMISSIONS_FAIL,
        err
    }
}