import React, { useState, useEffect } from "react";
import RSheetsList from "./RSheetsList";
import { formatDate2 } from "../../shared/utility";
import { statusDescriptions, typeDescriptions } from "./RSheetsLogic";
import { Tooltip } from "@material-ui/core";
import Button from "../../components/CustomButtons/Button";
import { Edit, ContentCopy, Close } from "@material-ui/icons";
import CustomMultipleSelect from "../../components/CustomSelect/CustomMultipleSelect";
import { TextField } from "@material-ui/core";
import moment from "moment-timezone";
import { Dialog } from "@material-ui/core";
import Loader from "components/Loader/Loader";

const RSheetsAllList = (props) => {
    const [rsTypeFilter, setRSTypeFilter] = useState([]);
    const [revisionStarted, setRevisionStarted] = useState(false);
    const [openedAsk, setOpenedAsk] = useState(null);
    const [notes, setNotes] = useState("");

    console.log('openedAsk', openedAsk);

    useEffect(() => {
        if (props.rsheetRevision && revisionStarted) {
            // go to drafts tab and "open revision ?"
            props.goToMyDrafts();
            props.setCurrentType(props.rsheetRevision.type);
            props.getRsheet(props.rsheetRevision.id);
            props.setViewOnly(false);
            props.setShowForm(true);
            props.setCurrentAction();
            setRevisionStarted(false);
        }
    }, [props.rsheetRevision]);
    const { list, tab } = props;
    const finalList = list.filter((l) => {
        if (rsTypeFilter.length === 0) {
            return true;
        } else {
            const ltype = l.type.toString();
            if (rsTypeFilter.indexOf(ltype) !== -1) {
                return true;
            }
        }
        return false;
    });
    const close = () => {
        setOpenedAsk(null);
        setNotes("");
    };
    return (
        <div>
            <Dialog
                open={openedAsk ? true : false}
                onClose={() => {
                    close();
                }}
                width="sm"
                fullWidth={true}
                keepMounted
            >
                <div className="dialog-wrapper">
                    <div>
                        <span
                            className="close-btn"
                            onClick={() => {
                                close();
                            }}
                        >
                            <Close />
                        </span>
                    </div>
                    <div className="custom-textfield">
                        <TextField
                            label="Notes"
                            multiline
                            rowsMax="4"
                            value={notes}
                            onChange={(e) => {
                                setNotes(e.target.value);
                            }}
                            rows={4}
                            margin="normal"
                        />
                    </div>
                    {!notes && <div className="red-text text-center">Please enter notes regarding this revision</div>}
                    <div className="text-center mt-20">
                        <Button
                            onClick={() => {
                                props.setCurrentType(openedAsk.type);
                                close();
                                setRevisionStarted(true);
                                props.createRsheetRevision({
                                    id: openedAsk.id,
                                    notes,
                                });
                                // record void history
                                props.createRsHistory({
                                    json_data: openedAsk.json_data,
                                    rs_id: openedAsk.id,
                                    status: 0
                                })
                            }}
                            disabled={notes ? false : true}
                            color="primary"
                            style={{ marginRight: 20 }}
                        >
                            CREATE REVISION
                        </Button>
                        <Button
                            color="white"
                            onClick={() => {
                                setOpenedAsk(null);
                                setNotes("");
                            }}
                        >
                            CANCEL
                        </Button>
                    </div>
                </div>
            </Dialog>

            {props.loadingEntries && <Loader center={true} />}
           
            {!props.loadingEntries && <RSheetsList
                data={finalList.map((l, i) => {
                    const { type, illi_number } = l;
                    let prefixed_illi_number = illi_number;
                    // ALREADY HAS PREFIX SAVED
                    // if (type === 5) {
                    //     // Tenant Rep
                    //     prefixed_illi_number = "TR" + illi_number.toString();
                    // } else if (type === 8) {
                    //     // No Listing
                    //     prefixed_illi_number = "NL" + illi_number.toString();
                    // }

                    const rs_type = typeDescriptions[l.type] ? typeDescriptions[l.type] : "";

                    return {
                        id: l.id,
                        name: l.name,
                        illi_number: prefixed_illi_number,
                        name: l.name,
                        project_name: l.project_name,
                        date_created_unix: moment(l.date_created.replace("T", " ")).unix(),
                        date_modified: formatDate2(l.date_modified),
                        rs_type,
                        title: l.title ? `${rs_type} - Revision ${l.title.split("_").length}` : rs_type,
                        status: statusDescriptions[l.status] ? statusDescriptions[l.status] : "",
                        actions: (
                            <div>
                                {((tab !== "user_drafts" && tab !== "my_drafts") || l.status === 11) &&
                                    <Tooltip title="View" placement="top">
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            onClick={() => {
                                                props.setCurrentType(l.type);
                                                props.getRsheet(l.id);
                                                props.setViewOnly(true);
                                                props.setShowForm(true);
                                                props.setCurrentAction();
                                                props.getRsInternalReviewers(l.id);
                                            }}
                                            color="primary"
                                            className="mr-20"
                                        >
                                            <i className="fas fa-eye font-22"></i>
                                        </Button>
                                    </Tooltip>
                                }
                                {/* Completed */}
                                {l.status === 9 && (
                                    <Tooltip title="Create Revision" placement="top">
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            onClick={() => {
                                                setOpenedAsk(l);
                                            }}
                                            color="primary"
                                            className="mr-20"
                                        >
                                            <ContentCopy />
                                        </Button>
                                    </Tooltip>
                                )}
                                {(l.status === 1 || l.status === 5 || (tab === "pending_my_drafts" && l.status !== 11)) && (
                                    <Tooltip title="Edit" placement="top">
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            onClick={() => {
                                                props.setCurrentType(l.type);
                                                props.getRsheet(l.id);
                                                props.setViewOnly(false);
                                                props.setShowForm(true);
                                                props.setCurrentAction();
                                                props.getRsInternalReviewers(l.id);
                                            }}
                                            color="primary"
                                            className="mr-20"
                                        >
                                            <Edit />
                                        </Button>
                                    </Tooltip>
                                )}
                                {/* Delete is only for Pure Drafts */}
                                {l.status === 1 && (
                                    <Tooltip title="Delete" placement="top">
                                        <Button
                                            justIcon
                                            round
                                            simple
                                            onClick={() => {
                                                props.setAskBeforeDelete({ id: l.id, status: l.status });
                                            }}
                                            color="primary"
                                            className="mr-20"
                                        >
                                            <i className="fas fa-trash font-22"></i>
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        ),
                    };
                })}
                columns={[
                    {
                        Header: "id #",
                        accessor: "id",
                        filterMethod: (filter, row) => {
                            console.log('f', filter.value, row.id);
                            if (filter.value) {
                                var filterValue = filter.value.toLowerCase();
                                if (filterValue.length > 0) {
                                    if (row.id) {
                                        if (row.id.toString().indexOf(filterValue) !== -1) {
                                            return true;
                                        }
                                    }
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    },
                    {
                        Header: "illi #",
                        accessor: "illi_number",
                        filterMethod: (filter, row) => {
                            if (filter.value) {
                                var filterValue = filter.value.toLowerCase();
                                if (filterValue.length > 0) {
                                    if (row.illi_number) {
                                        if (row.illi_number.toString().indexOf(filterValue) !== -1) {
                                            return true;
                                        }
                                    }
                                    return false;
                                } else {
                                    return true;
                                }
                            }
                        },
                    },
                    {
                        Header: "Project Name",
                        accessor: "project_name",
                        filterMethod: (filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ? String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) !== -1 : true;
                        }
                    },
                    {
                        Header: "Submitter",
                        accessor: "name",
                        filterMethod: (filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            return row[id] !== undefined ? String(row[id].toLowerCase()).indexOf(filter.value.toLowerCase()) !== -1 : true;
                        }
                    },
                    {
                        Header: "Date Created",
                        accessor: "date_created_unix",
                        Cell: (props) => {
                            return (
                                <div>
                                    {moment.unix(props.value).tz("America/Los_Angeles").format("MM/DD/YYYY h:mm a")}
                                </div>
                            );
                        },
                        filterMethod: (filter, row, column) => {
                            const id = filter.pivotId || filter.id;
                            if (row[id]) {
                                const formattedDate = moment.unix(row[id]).tz("America/Los_Angeles").format("MM/DD/YYYY h:mm a")
                                return formattedDate.toString().indexOf(filter.value.toLowerCase()) !== -1;
                            }
                            return false
                        }
                    },
                    {
                        Header: "RS Type / Title",
                        accessor: "title",
                        Filter: ({ column: { filterValue, setFilter } }) => {
                            return (
                                <CustomMultipleSelect
                                    options={Object.keys(typeDescriptions).map((type) => {
                                        return typeDescriptions[type];
                                    })}
                                    label=""
                                    choose={(e, n) => {
                                        const types = e.map((description) => {
                                            let type = 0;
                                            Object.keys(typeDescriptions).forEach((typed) => {
                                                if (typeDescriptions[typed] === description) {
                                                    type = typed;
                                                }
                                            });
                                            return type;
                                        });
                                        setRSTypeFilter(types);
                                    }}
                                    style={{margin: 0, padding: '5px'}}
                                    className="rs-list"
                                />
                            );
                        },
                    },
                    {
                        Header: "Status",
                        accessor: "status",
                    },
                    {
                        Header: "Actions",
                        accessor: "actions",
                    },
                ]}
            />}
        </div>
    );
};

export default RSheetsAllList;
