import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Close from '@material-ui/icons/Close';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import { FadeLoader } from 'react-spinners';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const CustomDialogWithERTL = (props) => {
	const [choice, setChoice] = useState(null);
	const { indx, desc, open, close, title, options: opts, label, current } = props;

	const options = choice ? opts.filter(option => {
		let valid = false;
		const {data} = option;
		Object.values(data).forEach(val => {
			if (val && val.toString().toLowerCase().includes(choice.toLowerCase())) {
				valid = true;
			}
		})
		return valid;
	}) : opts

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
				<DialogTitle>
					<div
						className='close-btn'
						onClick={() => {
							props.close();
						}}
					>
						<Close />
					</div>
					<div style={{ textAlign: 'center' }}>{props.title}</div>
				</DialogTitle>

				<div style={{ textAlign: 'center', padding: 20 }}>
					<CustomInput
						label={props.label}
						value={choice}
						onChange={(e) => {
							setChoice(e.target.value);
						}}
					/>

					{props.loading && (
						<div>
							<div style={{ margin: '0 auto', display: 'inline-block' }}>
								<FadeLoader />
							</div>
						</div>
					)}
					{!props.loading && (
						<div>
							{options && (
								<div
									style={{
										height: 300,
										overflowY: 'scroll',
										padding: 10,
										backgroundColor: '#f1f1f1',
									}}
								>
									{options.length === 0 && (
										<div>
											<em>No existing ERTL forms</em>
										</div>
									)}
									{options.length !== 0 && (
										<List component='nav'>
											{options.map((o, i) => {
												const d = o.data;
												const address = `${d.p1_property_address1} ${d.p1_property_address2} ${d.p1_property_city} 
									${d.p1_property_state} ${d.p1_property_zipcode}`;
												const owner = d.p3_owner_contact_names;
												return (
													<ListItem
														button
														key={`ertl-${i}`}
														onClick={() => {
															props.choose(o);
														}}
													>
														<ListItemText inset primary={owner} secondary={address} />
													</ListItem>
												);
											})}
										</List>
									)}
								</div>
							)}
						</div>
					)}

					<Button
						color='white'
						style={{ marginRight: 10, marginTop: 20 }}
						onClick={() => {
							props.close();
						}}
					>
						CLOSE
					</Button>
				</div>
			</Dialog>
		</div>
	);
};

CustomDialogWithERTL.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	choose: PropTypes.func.isRequired,
	indx: PropTypes.string,
	desc: PropTypes.string,
	current: PropTypes.string,
	icon: PropTypes.object,
};

const mapStateToProps = (state) => {
	return {};
};

export default connect(mapStateToProps, null)(CustomDialogWithERTL);
