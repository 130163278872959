import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import { Close } from '@material-ui/icons';

const Notification = (props) => {
    return <Dialog 
      open={props.open}
      keepMounted  
      maxWidth="sm" 
      fullWidth={true}
    >
      {!props.no_ok_button && <div className="close-btn" onClick={() => { props.close(); }}><Close /></div>}
      <div style={{padding:20,marginTop:60}} className="text-center">
        {props.message}
        {!props.no_ok_button && <hr />}
        {!props.no_ok_button && <Button color="primary" onClick={() => { props.close(); }}>OK</Button>}
      </div>
    </Dialog>
  }

  export default Notification;