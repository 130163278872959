import React from 'react';
import ReactTable from 'react-table';
import { formatDate2 } from 'shared/utility';

const ShowingList = (props) => {
    const { showingsData, setTab, setShowingData } = props;
    const data = showingsData ? showingsData.map((l, key) => { // map users to table rows
        return ({
            id: l.id,
            notes: l.notes,
            date: formatDate2(l.date),
            start_datetime2: moment(l.start_datetime.replace('.000Z','')).format('MM/DD/YYYY h:mma'),
            date_created: formatDate2(l.created_date),
            date_modified: formatDate2(l.modified_date),
            showingData: l,
            dummy: ''
        })
    }) : [];

    const columns = [
        {
            Header: "Showing ID #",
            accessor: "id" 
        },
        {
            Header: "Start Date",
            accessor: "start_datetime2"
        },
        {
            Header: "Notes",
            accessor: "notes"
        },
        {
            Header: "Date Created",
            accessor: "date_created"
        },
        {
            Header: "Guests",
            accessor: "guests"
        },
        {
            Header: "",
            accessor: "dummy"
        }
    ]

    console.log('showings list', data);

    return (
        <div className="table-adjust2">
            <ReactTable 
                filterable
                className='-striped -highlight' 
                showPaginationTop 
                showPaginationBottom 
                resizable={false} 
                defaultPageSize={10} 
                pageSizeOptions={[10, 20, 30]}
                data={data} 
                columns={columns} 
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          
                          if (rowInfo) {
                            if (rowInfo.original) {
                              setShowingData(rowInfo.original.showingData)
                              if (props.canEdit) {
                                setTab('Edit');
                              } else {
                                setTab('View');
                              }
                            }
                          }
                        }
                      }
                    } else {
                      return {}
                    }
                  }}
            />
        </div>
    )
}

export default ShowingList;