import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import _ from 'lodash';
import ClipLoader from 'react-spinners/ClipLoader';
import Dialog from '@material-ui/core/Dialog';
import Button from 'components/CustomButtons/Button.jsx';
import DialogTitle from '@material-ui/core/DialogTitle';
import CustomInput from 'components/CustomInput/CustomInputValidate.jsx';
import { IconButton } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Person from '@material-ui/icons/Person';
import Star from '@material-ui/icons/Star';
import PropTypes from 'prop-types';
import PropertiesNewContact from "containers/CRM/Properties/PropertiesNewContact";
import { clone } from '../../shared/utility';
import { getUser } from '../../shared/authValidation';
import { Tab, Tabs, FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import contactFields from '../../containers/CRM/Contacts/ContactsObj';
import { contactSubTypeDecider } from 'containers/CRM/Contacts/ContactsLogic';
import CustomSelect from '../CustomSelect/CustomSelect';
import crmObj from '../../containers/CRM/crmObj';
import Close from '@material-ui/icons/Close';
import Notification from '../Notification/Notification';
import PropertiesEditContact from '../../containers/CRM/Properties/PropertiesEditContact';
import { get } from 'lodash';

const contactTypesArr = contactFields.contact_type.options;
const contactTypesObj = contactFields.contact_type.keys;
const contactSubTypesObj = {};
contactTypesArr.forEach(cta => {
	contactSubTypesObj[cta] = '';
})

const vendorOptions = [];

const CustomDialogWithContact = (props) => {
	console.log('CustomDialogWithContact', props);
    const [openedNewContact, setOpenedNewContact] = useState(false);
	const [choice, setChoice] = useState(null);
	const [options, setOptions] = useState([]);
	const [userType, setUserType] = useState('E');
	const [currentTab, setCurrentTab] = useState('illi');
	const [contactTypesFilter, setContactTypesFilter] = useState([]);
	const [showFilters, setShowFilters] = useState(false);
	const [contactSubtype, setContactSubtype] = useState(contactSubTypesObj);
	const [openedChooseType, setOpenedChooseType] = useState(null);
	const [openedChoose, setOpenedChoose] = useState(null);
	const [theDba, setTheDba] = useState(null);
	const [theType, setTheType] = useState('');
	const [subType, setSubtype] = useState('');
	const [acronymType, setAcronymtype] = useState('');
	const [notification, setNotification] = useState('');
	const [openedExistingContact, setOpenedExistingContact] = useState(null);
	const [viewContact, setViewContact] = useState(false);
	const {indx, desc, open, close, title, options: opts, label, current, loading,
		illi_contacts, own_contacts, own_shared_contacts, showContactTypes
	} = props;

	function usePrevious(value) {
        const ref = useRef();
        useEffect(() => {
          ref.current = value;
        });
        return ref.current;
    }

	const prevPrefill = usePrevious(props.prefill);
	const prevContactData = usePrevious(props.contactData);

	useEffect(() => {
		const userPerms = getUser();
		if (userPerms) {
			setUserType(userPerms.type);
			if (userPerms.type === 'A') {
				setCurrentTab('Agent');
			}
		}
		if (props.filterByContactTypes) {
			if (props.filterByContactTypes.length > 0) {
				setContactTypesFilter(props.filterByContactTypes);
			}
		}
	}, []);

	useEffect(() => {
		if (props.prefill !== prevPrefill) {
			if (props.prefill) {
				if (props.prefill.first_name) {
					setChoice(props.prefill.first_name);
				}
			}
		}
	}, [props.prefill]);

	useEffect(() => {
		if (choice) {
			if (choice.length > 1) {
				searchIt(choice);
			}
		}
	}, [choice]);

	useEffect(() => {
		if (illi_contacts && own_contacts && own_shared_contacts) {
			renderOptions();
		}
	}, [illi_contacts, own_contacts, own_shared_contacts]);

	useEffect(() => {
		renderOptions();
	}, [currentTab, contactTypesFilter, contactSubtype]);

	useEffect(() => {	
		if (prevContactData === null && props.contactData) {
			console.log('got here to contact', props.overwrite_existing, props.askForType);
			if (!viewContact) {
				if (props.overwrite_existing) {
					// used for importing contacts / merging
					setOpenedExistingContact(props.contactData);
					return;
				}
				if (props.askForType) {
					setOpenedChooseType(props.contactData);
				} else {
					if (props.email_is_required) {
						if (!props.contactData.email) {
							return setNotification('A contact with an email is required.');
						}
					}
					props.choose(props.contactData);
				}
			} else {
				setOpenedExistingContact(props.contactData);
			}
		}
	}, [props.contactData]);

	let hide_illi_tab = false;
	if (props.loggedInUser) {
		// shown if in CRM association editing mode (e.g. company properties, contact properties)
		if (props.loggedInUser.type === 'A') {
			hide_illi_tab = true;
		}
	}

	const filterIt = (arr) => {
		return arr.filter(ff => {
			let pass = false;
			if (props.peopleType === 'agents') {
				// FILTER ASSOCIATES ONLY
				// WITH USER ID 
				if (ff.connect_user_id) {
					return true;
				} else {
					return false;
				}
			}
			if (contactTypesFilter.length > 0) {
			  const ctype = ff.contact_type ? ff.contact_type : '';
			  if (!ctype) return false;
			  contactTypesFilter.forEach(ctf => {
				  if (ff.contact_type.indexOf(ctf) !== -1) {
					  pass = true;
				  }
			  })
			} else {
				// no filters, show all
				return true;
			}
			return pass;
		})
	}

	const renderOptions = () => {
		if (currentTab === 'illi') {
			let theOptions = filterIt(illi_contacts.map(co => {
				const ct = clone(co);
				ct.name = co.first_name || co.last_name ? 
					`${co.first_name ? co.first_name : ''} ${co.last_name ? co.last_name : ''}`
				:
					co.company_name ? co.company_name : '';
				return ct;
			}));
			if (props.exclusive_right_to_represent_buyer) {
				theOptions = theOptions.filter(t => {
					if (t.exclusive_right_to_represent_buyer === 'Yes') return true;
				})
			}
			setOptions(theOptions)
	   }
	   if (currentTab !== 'illi') {
			let theOptions = filterIt(own_contacts.concat(own_shared_contacts).map(co => {
				const ct = clone(co);
				ct.name = co.first_name || co.last_name ? 
					`${co.first_name ? co.first_name : ''} ${co.last_name ? co.last_name : ''}`
				:
					co.company_name ? co.company_name : '';
				return ct;
			}));
			if (props.exclusive_right_to_represent_buyer) {
				theOptions = theOptions.filter(t => {
					if (t.exclusive_right_to_represent_buyer === 'Yes') return true;
				})
			}
		   setOptions(theOptions);
	  }
	}

	const searchAPI = (the_choice) => {
		const params = {
			names_only: 'All',
			search_term: the_choice
		}
		if (props.peopleType) {
			params.people_type = props.peopleType;
		}
		if (props.withAddresses) {
			params.addresses = 'All'
		}
		if (props.exclusive_right_to_represent_buyer) {
			params.exclusive_right = true;
		}

		props.getContacts(params)
	}

	const searchIt = useCallback(_.debounce(searchAPI, 600), []);

	const ct = currentTab === 'illi' ? 0 : 1;
	

	const oo = options.filter((o, i) => {
		if (currentTab === 'Agent' && o.user_id === 1) {
			return false;
		}
		if (currentTab === 'illi' && o.user_id !== 1) {
			return false;
		}
		return true;
	});

	const renderContent = () => {
		return <div>
				<DialogTitle>
					<div style={{textAlign: 'center'}}>{props.title}</div>
                    {!props.no_new_contact && <Button className="outlined-btn"
                        size="sm"
                        onClick={() => {
                            setOpenedNewContact(true);
                        }}
                    ><i className="fa fa-plus"></i> CREATE NEW CONTACT</Button>}
					{props.peopleType === 'agents' && <div className="red-text text-center" style={{marginTop:20}}>
						illi Associates Only
					</div>}
				</DialogTitle>
				{<div style={{margin:'0 20px'}}>
					{userType !== 'E' && <Tabs
						TabIndicatorProps={{ style: { background: '#da3b2f', borderBottom: '1px solid #da3b2f', color: '#da3b2f' } }}
						style={{ marginBottom: 10 }}
						value={currentTab === 'illi' ? 0 : 1}>
						{<Tab
							onClick={() => {
								setCurrentTab('illi');
							}}
							style={{ 
								color: ct === 0 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)',
								display: hide_illi_tab ? 'none' : 'block'
							}}
							tabIndex={0}
							label='illi Contacts'
							icon={<i className="fas fa-building"></i>}
						/>}
						{<Tab
							onClick={() => {
								setCurrentTab('Agent');
							}}
							style={{ color: ct === 1 ? '#da3b2f' : 'rgba(0, 0, 0, 0.87)' }}
							tabIndex={1}
							label='Your Contacts'
							icon={<i className="fas fa-user"></i>}
						/>}
					</Tabs>}
				</div>}
				<div style={{textAlign: 'center', padding: 20}}>
					<CustomInput
						label={props.label}
						value={choice}
						onChange={(e) => {
							setChoice(e.target.value);
						}}
						autoFocus={true}
					/>
					{!props.dontShowFilters && <Button color="white" className="mt-10 mb-20" onClick={() => {
						setShowFilters(!showFilters);
					}}>{showFilters ? 'Hide' : 'Show'} Filters</Button>}
					{(showContactTypes && showFilters) && <div style={{backgroundColor: '#f0f0f0', padding: '0 10px'}}>
							{contactTypesArr.map(f => {
								return <div key={`${f}-contact_types`}>
									<FormGroup row className="mt-15">
										<FormControlLabel
											control={
												<Checkbox
													checked={contactTypesFilter.indexOf(f) !== -1}
													onChange={() => {
														if (contactTypesFilter.indexOf(f) === -1) {
															setContactTypesFilter(contactTypesFilter.concat([f]));	
														} else {
															setContactTypesFilter(contactTypesFilter.filter(ctf => {
																if (ctf === f) {
																	return false;
																}
																return true;
															}));
														}
													}}
													color="default"
												/>
											}
											label={f}
										/>
										{contactTypesFilter.indexOf(f) !== -1 && <CustomSelect
											label={`${f} Subtype`}
											options={contactSubTypeDecider(f, vendorOptions)}
											choose={(e, n) => {
												const newObj = clone(contactSubTypesObj);
												newObj[f] = e;
												setContactSubtype(newObj);
											}}
											default={contactSubtype[f] ? contactSubtype[f] : ''}
										/>}
									</FormGroup>
								</div>
							})}
					</div>}
					{options && <div style={{height: !choice ? 100 : 300, overflowY: 'scroll', padding: 10, backgroundColor: '#f1f1f1'}}>
						{loading && <div className="text-center"><ClipLoader /></div>}
						{!choice && <div style={{marginTop:20}}><em>Enter letters or numbers from the contact's name<br />you are searching for in the line above</em></div>}
						{(choice && options) && <div>
							{oo.length === 0 && <div><em>0 Results</em></div>}	
						</div>}
						{(!loading && choice) && <div>
							{oo.length > 0 && <div><em>{oo.length.toLocaleString()} Result(s)</em></div>}	
						</div>}
						{(!loading && choice) && <List component="nav">
							{options.map((o, i) => {
								
								const desc = o.contact_type ? 
								o.contact_subtype ? 
									<span>{`${o.name}`}
										&nbsp;<small><em>({o.contact_type})</em></small>
										&nbsp;<small><em>({o.contact_subtype})</em></small>
									</span> 
								:
									<span>{`${o.name}`}&nbsp;<small><em>({o.contact_type})</em></small></span> 
						: 
							o.name;
								return (
									<ListItem 
										button
										key={`contact-option-${i}`}
										onClick={() => {
											props.getContact(o.id);
										}}
									>
										{props.icon === 'person' && <ListItemIcon>
											<Person />
										</ListItemIcon>}
										{(props.icon !== 'person' && props.icon) && <ListItemIcon>
											{props.icon}
										</ListItemIcon>}
										<ListItemText inset primary={desc} secondary={
											o.matched_dbas && o.matched_dbas
												.split(',')
												.map((i, x) => <span key={`matched-dba-${x}`} style={{display: 'block'}}>DBA: {i}</span>)
										}/>
										<ListItemSecondaryAction>
											<IconButton onClick={() => {
													setViewContact(true);
													props.getContact(o.id);
												}}>
												<i className="fa fa-eye"></i>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})}
						</List>}
					</div>}

					{openedChoose && <div>
						<Dialog
							open={true} keepMounted onClose={() => { setOpenedChoose(null); }} 
							maxWidth='sm' fullWidth={true}
						>
							<CustomSelect 
								default={theDba ? theDba.id : ''}
								choose={(dbaIdChosen) => {
									let dbaChosen = null;
									get(openedChoose, 'dbas', []).forEach(oc => {
										if (oc === dbaIdChosen) dbaChosen = oc;
									})
									setTheDba(dbaChosen);
								}}
								indx="id"
								desc="name"
								options={get(openedChoose, 'dbas', []).filter(dba => dba.active !== 0)}
								label="Choose a DBA"
							/>	
						<Button 
							color="white"
                            onClick={() => {
                                props.create_and_choose(openedChoose, null, theDba);
                                props.close();
                            }}
                        >SUBMIT</Button>
						</Dialog>
					</div>}

                    {<PropertiesNewContact 
                        open={openedNewContact}
                        close={() => {
                            setOpenedNewContact(false);
                        }}
                        peopleType={props.peopleType}
                        create_and_choose={(chosen) => {
							console.log('creating new contact', chosen);
							if (props.askForType) {
                                return setOpenedChooseType(chosen);
                            }
							// const dbas = get(chosen, 'dbas', []);
							// if (dbas.length > 0) {
							// 	return setOpenedChoose(chosen);
							// }
                            props.create_and_choose(chosen);
						}}
						prefill={props.prefill}
						showCompanyAssigned={props.showCompanyAssigned}
						fromCustomDialogs={true}
                    />}

					{(props.overwrite_existing && openedExistingContact && !viewContact) && <PropertiesEditContact
						open={openedExistingContact ? true : false}
						close={() => {
							setOpenedExistingContact(null);
							if (choice) {
								searchIt(choice);
							}
						}}
						contactID={openedExistingContact.id}
						prefill={props.prefill}
						choose={(chosen) => {
							if (props.email_is_required) {
								if (!chosen.email) {
									return setNotification('A contact with an email is required.');
								}
							}
							props.choose(chosen);
						}}
						showCompanyAssigned={props.showCompanyAssigned}
					/>}

					{(viewContact && openedExistingContact) && <PropertiesEditContact
						open={viewContact}
						close={() => {
							setViewContact(false);
						}}
						contactID={openedExistingContact.id}
						prefill={props.prefill}
						choose={(chosen) => {
							if (props.email_is_required) {
								if (!chosen.email) {
									return setNotification('A contact with an email is required.');
								}
							}
							props.choose(chosen);
						}}
						showCompanyAssigned={props.showCompanyAssigned}
					/>}

					{<Button
						color='white'
						style={{marginRight: 10, marginTop: 20}}
						onClick={() => {
							props.close();
						}}
					>
						CLOSE
					</Button>}

					{props.skipComponent ? props.skipComponent : null}
				</div>

				{notification && <Notification 
						open={notification ? true : false}
						close={() => { setNotification('') }}
						message={notification}
					/>}

				{openedChooseType && <Dialog
                    open={true} keepMounted onClose={() => { setOpenedChooseType(null); }} 
                    maxWidth='sm' fullWidth={true}
                >
                    <DialogTitle>
                        <div className="close-btn" onClick={() => { setOpenedChooseType(null); }}><Close /></div>
                        <div style={{ textAlign: 'center' }}>
							Choose Contact Type for this Contact
						</div>
                    </DialogTitle>
                    <div className="dialog-body" style={{ margin: '0 20px', minHeight:400 }}>
                        <CustomSelect 
                            default={theType}
                            choose={(typeChosen) => {
                                setTheType(typeChosen);
                                if (typeChosen === 'Other') {
                                    setSubtype('');
                                } else {
                                    setSubtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].subtype);
                                }
                                setAcronymtype(crmObj.contactOrCompanyPropertySubTypes[typeChosen].type);
                            }}
                            options={props.typeRestriction ? 
									Object.keys(crmObj.contactOrCompanyPropertySubTypes).filter(field => {
										if (crmObj.contactOrCompanyPropertySubTypes[field].type === props.typeRestriction) {
											return true;
										}
									})
								: 
									crmObj.contactOrCompanyPropertyTypes}
                            label="Choose one"
                        />
                        {theType === 'Other' && <CustomInput
                            label="Enter Other type"
                            value={subType}
                            onChange={(e) => {
                                setSubtype(e.target.value);
                            }}
                        />}
                        {theType && <Button color="white"
                            onClick={() => {
                                if (theType === 'Other') {
                                    if (!subType) {
                                        return setNotification('Please enter an "Other" type.');
                                    }
                                }
                                const typeObj = {
                                    theType, 
                                    acronymType, 
                                    subType
                                };
                                console.log('typeObj', typeObj);
                                props.choose(openedChooseType, typeObj)
                                props.close();
                            }}
                        >SUBMIT</Button>}
                    </div>
                </Dialog>}
		</div>
	}

	if (props.no_dialog) {
		return <div>{renderContent()}</div>
	}

	console.log('CustomDialogWithContact', props);

	return (
		<div>
			<Dialog open={props.open} keepMounted onClose={props.close} maxWidth='sm' fullWidth={true}>
				{renderContent()}
			</Dialog>
		</div>
	);
};

CustomDialogWithContact.propTypes = {
	title: PropTypes.string.isRequired,
	label: PropTypes.string.isRequired,
	close: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	choose: PropTypes.func.isRequired,
	indx: PropTypes.string,
	desc: PropTypes.string,
	current: PropTypes.string,
    icon: PropTypes.object
};

const mapStateToProps = state => {
    const c = state.crm_contacts;
    return {
        contactOptions: c.contactsData,
		loading: c.loading,
		illi_contacts: c.illi_contacts,
		own_contacts: c.own_contacts,
		own_shared_contacts: c.own_shared_contacts,
		contactData: c.contactData
    }
  }

  const mapDispatchToProps = dispatch => {
    return {
        getContacts: (params) => {
            dispatch(actions.getContacts(params));
        },
		getContact: (data) => {
			dispatch(actions.getContact(data));
		}
    }
  }

export default connect(mapStateToProps, mapDispatchToProps)(CustomDialogWithContact);
