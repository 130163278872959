import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from "components/CustomButtons/Button.jsx";
import DialogTitle from '@material-ui/core/DialogTitle';

const PropertyAskModal = (props) => {
    const [message, setMessage] = useState('');
    const name = props.propertyData ? props.propertyData.name : '';
    const property_id = props.propertyData ? props.propertyData.id : '';

    useEffect(() => {
        if (props.success === 'Successfully deleted property.') {
            props.getProperties();
            props.clearMessages();
            setMessage(<div className="success-message">{props.success}</div>);
            setTimeout(() => {
                props.close();
                window.location.reload();
            }, 1000);
        }
    }, [props.success]);

    useEffect(() => {
        if (props.error) {
            props.getProperties();
            props.clearMessages();
            setMessage(<div className="error-message">{props.error}</div>);
        }
    }, [props.error]);

    return (
        <Dialog
        open={props.open}
        keepMounted
        onClose={props.close}
        maxWidth="sm"
        fullWidth={true}
    >
        <DialogTitle>
            <div style={{textAlign:'center'}}>Are you sure you want to delete this Property?</div>
        </DialogTitle>
            
        <div style={{textAlign:'center',padding:20}}>
            <div style={{textAlign:'center',marginBottom:20}}>
                {!message && <div>{name}</div>}
                {message && <div>{message}</div>}
            </div>

            <Button
                color="danger"
                style={{marginRight:10}}
                onClick={() => {
                    if (property_id) props.deleteProperty(property_id);
                }}
            >
                YES, DELETE
            </Button>
            <Button
                color="white"
                style={{marginRight:10}}
                onClick={() => {
                    props.close();
                }}
            >
                CANCEL
            </Button>
        </div>
        </Dialog>
    )
}

export default PropertyAskModal;