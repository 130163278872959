import React from 'react';
import { Dialog } from '@material-ui/core';
import Button from '../CustomButtons/Button';
import { Close } from '@material-ui/icons';

const CustomSimpleDialog = (props) => {
  const style = {padding:20,marginTop:60};
  if (props.minHeight) style.minHeight = props.minHeight;
    return <Dialog 
      open={props.open}
      keepMounted  
      maxWidth="md" 
      fullWidth={true}
    >
      {!props.no_ok_button && <div className="close-btn" onClick={() => { props.close(); }}><Close /></div>}
      <div style={style}>
        {props.content}
        {!props.no_ok_button && <hr />}
        {!props.no_ok_button && <Button color="primary" onClick={() => { props.close(); }}>OK</Button>}
      </div>
    </Dialog>
  }

  export default CustomSimpleDialog;